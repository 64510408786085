import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div<{ isNoBorderRadius?: boolean }>`
    background-color: ${(props) =>
      !!props?.isNoBorderRadius ? "white" : "#f6f5ff"};
    width: 100%;
    /* padding: 0 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: ${(props) => (props.isNoBorderRadius ? "0" : "20px")};
    border-bottom: ${(props) =>
      props.isNoBorderRadius ? "1px solid #7A86A1" : ""};
    height: 40px;
    justify-content: space-around;
  `;

  export const Option = styled.div<{
    isSelected: boolean;
    isFirst: boolean;
    isLast: boolean;
    disabled?: boolean;
    isNoBorderRadius?: boolean;
  }>`
    font-size: 14px;
    background-color: ${(props) =>
      props.isSelected && !props.isNoBorderRadius
        ? props.theme.primaryColor
        : props.isSelected && props.isNoBorderRadius
          ? "none" //
          : !props.isSelected && !props.isNoBorderRadius
            ? "#F6F5FF"
            : "white"};
    width: 100%;
    min-width: 95px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    opacity: ${props => props?.disabled ? 0.3 : 1};

    border-left: ${(props) =>
      (props.isSelected && !props.isNoBorderRadius)
        || (!props.isSelected && props.isNoBorderRadius)
        || (props.isSelected && props.isNoBorderRadius)
        ? "none"
        : "solid 1px #efefeF"};
    border-right: ${(props) =>
      (props.isSelected && !props.isNoBorderRadius)
        || (!props.isSelected && props.isNoBorderRadius)
        || (props.isSelected && props.isNoBorderRadius)
        ? "none"
        : "solid 1px #efefeF"};
    color: ${(props) =>
      props.isSelected && !props.isNoBorderRadius
        ? "white"
        : props.theme.primaryColor
    };
    border-radius: ${(props) =>
      props.isSelected
        ? "10px"
        : props.isFirst
          ? "10px 0 0 10px"
          : props.isLast
            ? "0 10px 10px 0"
            : 0};

    &:hover {
      cursor: ${(props) => (props.disabled ? "" : "pointer")};
    }
    border-radius: ${(props) =>
      props.isNoBorderRadius ? "0" : "20px"}; //20px;
  `;
}
export default styles;
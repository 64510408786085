export const SessionIcon = () => {
    return (<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Rectangle_340" data-name="Rectangle 340" width="37" height="37" fill="none" />
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(1.529 4.564)">
            <path id="Path_500" data-name="Path 500" d="M12.844,19.688A6.844,6.844,0,1,0,6,12.844a6.844,6.844,0,0,0,6.844,6.844Zm0-10.646a3.8,3.8,0,1,1-3.8,3.8A3.8,3.8,0,0,1,12.844,9.042Z" transform="translate(-2.958 -6)" fill="#7031eb" />
            <path id="Path_501" data-name="Path 501" d="M33.323,20.646A5.323,5.323,0,1,0,28,15.323a5.323,5.323,0,0,0,5.323,5.323Zm0-7.6a2.281,2.281,0,1,1-2.281,2.281,2.205,2.205,0,0,1,2.281-2.281Z" transform="translate(-8.229 -6.958)" fill="#7031eb" />
            <path id="Path_502" data-name="Path 502" d="M35.459,28.585l-.684-.456a14.9,14.9,0,0,0-15.361,0l-.608.456a18.706,18.706,0,0,0-6.159-1.065,17.794,17.794,0,0,0-9.962,2.966L2,30.942v5.7a1.521,1.521,0,0,0,1.521,1.521h18.25a1.521,1.521,0,0,0,1.521-1.521V35.124H33.938A1.521,1.521,0,0,0,35.459,33.6ZM20.25,35.124H5.042V32.615a14.828,14.828,0,0,1,7.6-2.053,14.828,14.828,0,0,1,7.6,2.053Zm12.167-3.042H23.292V30.942l-.684-.456-.456-.3a11.635,11.635,0,0,1,10.266.152Z" transform="translate(-2 -10.791)" fill="#7031eb" />
        </g>
    </svg>);

};
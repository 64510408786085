import React from "react";
import OAuthPopup from "../o-auth-popup/OAuthPopup";
import {IntegrationPlatform} from "@models";


interface Props {
    integration?: IntegrationPlatform;
    onAuthorization: (params: URLSearchParams) => void;
}

const RefreshOAuth = (props: Props) => {
    let authUrl = '';
    let title = '';
    switch (props?.integration){
        case (IntegrationPlatform.SHOPIFY):
            title = 'Shopify'
            authUrl = 'https://apps.shopify.com/wideview';
            break
        default:
            break
    }
    return (
        <OAuthPopup
            title={title}
            height={800}
            width={1000}
            url={authUrl ?? ''}
            onResponse={(params) => {
                props?.onAuthorization(params!);
            }}
            onClose={() => {
                // console.log('closing!');
            }}
        >
                <a>Refresh</a>
        </OAuthPopup>
    );
    return <></>;
};

export default RefreshOAuth;

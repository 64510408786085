import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {AccountState} from "./state";

export const accountStateSelector = (state: RootState): AccountState =>
  state.account;

export const accountDataSelector = createSelector(
  accountStateSelector,
  (state) => state.data
);

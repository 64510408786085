import successMessageStyles from "./styles";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation(['translation', 'translation']);

    return (
        <successMessageStyles.Wrapper>
            <successMessageStyles.MessageWrapper>
                <successMessageStyles.Message>
                    {t('PAGE_NOT_FOUND', { ns: 'translation' })}
                </successMessageStyles.Message>
            </successMessageStyles.MessageWrapper>
        </successMessageStyles.Wrapper>
    );
};

import styles from "./styles";
import {DownloadButton} from "../download-button/DownloadButton";
import {Box, Thead, Tooltip} from "@chakra-ui/react";
import {ProgressBar} from "../progress-bar/ProgressBar";
import {Channel} from "../../models/channel";
import {Column, Row, useRowSelect, useSortBy, useTable} from "react-table";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {IconClearFiltersArrow} from "../../utils/icons/explore";
import {currencyFormat} from "utils/kpi-render/kpiCalculation";
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";

interface Table {
    name: string;
    value: number;
    shareOfTotal: number;
    margin?: number;
    totalGrossRevenue?: string;
    vsLastPeriod?: number;
}

interface Props {
    title?: string;
    headers: string[];
    data: Table[];
    icon: any;
    setSelectedRows?: any;
    noCurrencyFormat?: boolean;
    onRowClick?: (row: any) => void;
    displayVsLastPeriod?: boolean;
}

interface Data {
    col_0?: string;
    col_1?: number | string;
    col_2?: number | string;
    col_3?: number | string;
    col_4: number | string;
}

export const ExpenseTable = (props: Props) => {
    const [shadow, setShadow] = useState<boolean>(false);
    const tableRef = useRef<any>(null);

    useEffect(() => {

        const handleScroll = () => {
            const scrollTop = tableRef.current.scrollTop;

            if (scrollTop > 0) {
                setShadow(true);
            } else {
                setShadow(false);
            }
        };

        if (tableRef.current) {
            tableRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableRef.current) {
                tableRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const dataToDataTable = (data: Table[]): Data[] => {

        // @ts-ignore
        return data?.map((row, index) => {
            if (!row?.value) {
                return
            }
            return {
                col_0: row.name,
                col_1: row.value,
                col_2: row.margin,
                col_3: row.shareOfTotal,
                col_4: row?.vsLastPeriod,
            };
        })?.filter((row) => !!row);
    };

    const headersToColumns = (headers: string[]): Column<Data>[] => {
        return headers?.map((header, index) => {
            return {
                Header: header,
                accessor: "col_" + index.toString(),
                sortType: "basic",
            } as Column<Data>;
        });
    };

    // hooks
    const [selectedRows, setSelectedRows] = useState([] as Row<Data>[]);
    const data = useMemo<Data[]>(() => dataToDataTable(props.data), [props.data]);
    const columns = useMemo<Column<Data>[]>(
        () => headersToColumns(props.headers),
        [props.headers]
    );

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
        useTable({
            columns, data, initialState: {
                sortBy: [
                    {
                        id: "col_3",
                        desc: true
                    }
                ]
            },
        }, useSortBy, useRowSelect);
    const currentCurrency = useCurrencySign();

    // helpers

    const percentageFormat = (amount: number) => {
        if (!amount) {
            return 0 + "%"
        }
        return amount?.toFixed(2).toString() + "%";
    };

    const getProgressBarColor = (percentage: number) => {
        if (percentage < 90) {
            return "#F7686A";
        } else if (percentage < 100) {
            return "#F0BB09";
        }
        return "#A3DB81";
    };

    const selectedRow = (row: Row<Data>) => {
        row.toggleRowSelected();

        const isRowSelected = selectedRows.filter(
            (selectedRow) => selectedRow.id === row.id
        );
        if (isRowSelected?.length > 0) {
            setSelectedRows([]);
            !!props?.setSelectedRows && props?.setSelectedRows([]);
            return
        } else {
            setSelectedRows([row]);
            !!props?.setSelectedRows && props?.setSelectedRows([row]);
            return
        }

        /*if (!!isRowSelected && isRowSelected.length > 0) {
          setSelectedRows([
            ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
          ]);
          if (!!props?.setSelectedRows) {
            props?.setSelectedRows([
              ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
            ]);
          }
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);
            setSelectedRows(newSelected);
            if (!!props?.setSelectedRows) {
              props?.setSelectedRows(newSelected);
            }
        }*/
    };

    const clearFilters = () => {
        rows?.forEach((row) => {
            if (row.isSelected) {
                row.toggleRowSelected();
            }
            if (!!props?.setSelectedRows) {
                props?.setSelectedRows([]);
            }
        });
    };


    // renderers
    const renderClearFiltersButton = () => {
        return (
            <Tooltip label={'Reset'}>
                <styles.ClearFiltersButtonWrapper
                    onClick={() => {
                        setSelectedRows([] as Row<Data>[]);
                        clearFilters();
                    }}
                >
                    <IconClearFiltersArrow width={16} height={16}/>
                </styles.ClearFiltersButtonWrapper>
            </Tooltip>
        );
    };

    let totalValue = props?.data?.reduce((acc, currentValue) => acc + currentValue?.value, 0);
    let totalOfShare = props?.data?.reduce((acc, currentValue) => acc + currentValue?.shareOfTotal, 0);
    let totalLastPeriod = 0;
    if (selectedRows?.length > 0) {
        const fixedData = selectedRows?.map((item) => item?.original);
        totalValue = fixedData?.reduce((acc, currentValue: any) => acc + currentValue?.col_1, 0);
        totalOfShare = fixedData?.reduce((acc, currentValue: any) => acc + currentValue?.col_3, 0);
    }


    return (
        <styles.Wrapper>
            <styles.Card>
                <styles.Header>
                    <styles.Title>
                        <span style={{marginRight: "5px"}}>{props.icon}</span>
                        {props.title}
                    </styles.Title>
                    {selectedRows?.length > 0 ? renderClearFiltersButton() : <></>}
                    {/* <DownloadButton onClick={() => console.log('download')} /> */}
                </styles.Header>

                <styles.TableCustom  variant="simple" {...getTableProps()}>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <styles.TrCustom
                                {...{selected: true, ...headerGroup.getHeaderGroupProps()}}
                            >
                                {headerGroup.headers.map((column, i) => {
                                    return (
                                        <styles.ThCustom
                                            shadow={shadow}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <Box display={"flex"}>
                                                {column.render("Header")}

                                                <span>
                          {column.isSorted ? (
                              !column.isSortedDesc ? (
                                  <GoTriangleUp/>
                              ) : (
                                  <GoTriangleDown/>
                              )
                          ) : (
                              ""
                          )}
                        </span>
                                            </Box>
                                        </styles.ThCustom>
                                    );
                                })}
                            </styles.TrCustom>
                        ))}
                    </Thead>
                    <styles.TbodyCustom ref={tableRef}   {...getTableBodyProps()}>
                        {rows.map((row) => {
                            const defaults = {
                                transactionFees: "Transaction Fees",
                                discount: "Discount",
                                refunds: "Refunds",
                                totalTax: "Total Tax",
                                totalShipping: "Shipping",
                            };
                            prepareRow(row);
                            return (
                                <styles.TrCustom
                                    {...{
                                        selected:
                                            selectedRows?.some(
                                                (selectedRow) => selectedRow?.id === row?.id
                                            ) || selectedRows.length === 0,
                                        ...row.getRowProps(),
                                    }}
                                    highlightRow={selectedRows?.length > 0}
                                    onClick={() => {
                                        selectedRow(row);
                                        if (!!props?.onRowClick) {
                                            props?.onRowClick(row);
                                        }
                                    }}
                                >
                                    <styles.TdCustom>
                                        {!!row?.cells && row.cells[0].value in defaults
                                            ? defaults[row.cells[0].value]
                                            : row.cells[0].value === "Total Cogs"
                                                ? "Cost of Goods"
                                                : row.cells[0].value}
                                    </styles.TdCustom>
                                    <styles.TdCustom>
                                        {currencyFormat(row.cells[1]?.value, 0, currentCurrency)}{" "}
                                    </styles.TdCustom>
                                    <styles.TdCustom>
                                        {percentageFormat(row.cells[2]?.value)}
                                    </styles.TdCustom>
                                    <styles.TdCustom>
                                        {percentageFormat(row.cells[3]?.value)}
                                    </styles.TdCustom>
                                    {!!props?.displayVsLastPeriod && (
                                        <styles.TdCustom>
                                            {percentageFormat(row.cells[4]?.value)}
                                        </styles.TdCustom>
                                    )}
                                </styles.TrCustom>
                            );
                        })}
                    </styles.TbodyCustom>
                    <styles.TfootCustom>
                        <styles.TrCustomFooter>
                            <styles.TdCustomFooter>
                                <styles.GrandTotal>Grand Total
                                </styles.GrandTotal>
                            </styles.TdCustomFooter>
                            <styles.TdCustomFooter>
                                {currentCurrency +
                                    totalValue?.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                            </styles.TdCustomFooter>
                            <styles.TdCustomFooter>
                                {!!props?.data[0]?.totalGrossRevenue && !!totalValue
                                    ? (
                                    (totalValue / Number(props?.data[0]?.totalGrossRevenue)) *
                                    100
                                )?.toFixed(2) + "%"
                                    : 0 + "%"}
                            </styles.TdCustomFooter>
                            <styles.TdCustomFooter>{totalOfShare?.toFixed(2)}%</styles.TdCustomFooter>
                            {!!!!props?.displayVsLastPeriod &&
                                <styles.TdCustomFooter>
                                    {totalLastPeriod?.toLocaleString("en-us", {
                                        maximumFractionDigits: 2,
                                    })}
                                    %
                                </styles.TdCustomFooter>
                            }{" "}
                        </styles.TrCustomFooter>
                    </styles.TfootCustom>
                </styles.TableCustom>
            </styles.Card>
        </styles.Wrapper>
    );
};

import styled from "styled-components";
import { Button, Input } from "@components";
import { AuthButton } from "../../../components/auth-button";

export const SignUpWrapper = styled.div<{ img: string }>`
  background: url(${(props) => props.img})
    ${(props) => props.theme.primaryColor} round;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledInput = styled.input<{ border?: boolean }>``;

export const PasswordBar = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 50px;
  height: 5px;
  margin: 0 10px 0 0;
  position: relative;
  &:last-of-type {
    margin: 0;
  }
`;

export const LastBarHolder = styled.div<{ color: string }>`
  position: absolute;
  font-size: 13px;
  top: -9px;
  left: 50px;
  width: 38px;
  white-space: nowrap;
  text-align: center;
  color: ${(props) => props.color};
`;
export const TextHolder = styled.div<{ color: string }>`
  color: ${(props) => props.color};
`;
export const BarHolders = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-self: flex-start;
  margin: 0 0 0 25px;
`;

export const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// header
export const SignUpHeader = styled.div`
  display: flex;
  justify-content: center;
`;

// title
export const SignUpTitle = styled.span`
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
`;

// content
export const SignUpContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const SignUpCard = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 360px;
  overflow: hidden;
  //@media(max-width: 768px) {
  //  width: 90vw;
  //}
`;

export const SignUpImage = styled.div`
  background-image: url("https://wallpaperaccess.com/full/2593043.jpg");
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SignUpForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 10px 10px;
  //@media(max-width: 768px) {
  //  width: calc(100% - 20px);
  //}
`;

export const SignUpFormTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

export const SignUpFormInput = styled(Input)<{ border?: boolean }>`
  outline: ${(props) => (props.border ? ".2px solid #DB6182" : "")};
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
`;

export const ErrorMessage = styled.div`
  color: #db6182;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

export const SignUpShowPasswordButton = styled.div`
  height: 20px;
  width: 20px;
  background-color: #ffffff00;
  border-width: 0px;
`;

export const SignUpFormAgreement = styled.div`
  width: 100%;
  max-width: 300px;
  font-size: 12px;
  color: ${(props) => props.theme.descriptionTextColor};
  text-align: center;
  margin: 10px 0;
`;

export const SignUpFormAgreementText = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.descriptionTextColor};
`;

export const SignUpFormAgreementLink = styled.span`
  cursor: pointer;
  font-size: 10px;
  color: ${(props) => props.theme.linkColor};
`;

export const SignUpFormSubmitButton = styled(Button)<{ color?: string }>`
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0;
  width: 100%;
  background-color: ${(props) => (props.color ? "red" : null)};
  max-width: 300px;
  position: relative;
`;
export const ButtonDisable = styled.div`
  position: absolute;
  background-color: #e8e8e8;
  color: #888888;
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
  text-align: center;
  position: relative;
`;

export const SignUpFormOrText = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.descriptionTextColor};
  text-align: center;
  margin: 10px 0;
  text-transform: uppercase;
`;

export const SignUpFormButton = styled(AuthButton)`
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
  > a {
    font-size: 12px;
    > img {
      height: 14px;
      width: auto;
    }
  }
`;

export const SignUpSignInLink = styled.span`
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbf7ff 0% 0% no-repeat padding-box;
  font-size: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
`;

// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

// locals

import { PaymentsState, initalPayments } from "./state";
import {createTransactionFees, fetchPayments, fetchTransactionFees} from "./thunks";

// reducers

// fetch channels
function buildFetchPayments(builder: ActionReducerMapBuilder<PaymentsState>) {
  builder.addCase(fetchPayments.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(fetchPayments.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.payments = action?.payload;
    return state;
  });
  builder.addCase(fetchPayments.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    return state;
  });
}

function fetchTransactionFeesReducer(builder: ActionReducerMapBuilder<PaymentsState>) {
  builder.addCase(fetchTransactionFees.pending, (state, action) => {
    return state;
  });
  builder.addCase(fetchTransactionFees.fulfilled, (state, action) => {
   state.transactionFees = action.payload
    return state;
  });
  builder.addCase(fetchTransactionFees.rejected, (state, action) => {

    return state;
  });
}

function createTransactionFeesReducer(builder: ActionReducerMapBuilder<PaymentsState>) {
  builder.addCase(createTransactionFees.pending, (state, action) => {
    state.isSavingTransactionFeesLoading = true;
    return state;
  });
  builder.addCase(createTransactionFees.fulfilled, (state, action) => {
    state.isSavingTransactionFeesLoading = false;
    state.finishedSavingTransactionFees = true;
    state.transactionFees = action.payload
    return state;
  });
  builder.addCase(createTransactionFees.rejected, (state, action) => {
    state.finishedSavingTransactionFees = true;
    state.isSavingTransactionFeesLoading = false;
    return state;
  });
}

// slice
export const paymentsSlice = createSlice({
  name: "payments",
  initialState: initalPayments,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildFetchPayments(builder);

    fetchTransactionFeesReducer(builder);

    createTransactionFeesReducer(builder);
  },
});

// reducer
export const paymentsReducer = paymentsSlice.reducer;

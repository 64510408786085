/* eslint-disable array-callback-return */
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {organizationDataLanguageSelector, organizationSelector, userSelector,} from "@store";
import {BigWideviewCard} from "components/big-wideview-card/BigWideviewCard";
import {DataMode, PopoverPlacement, SmallWideviewCard,} from "components/small-wideview-card/SmallWideviewCard";
import {KpisEnum} from "models/kpi-settings";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  KpiGroupBy,
  KpiState,
  kpiStateSelector,
  setDailyStats,
  setMarketingStats,
  setTargetStats,
  summedKpiDataSelector,
  summedTargetStats,
} from "store/kpis";
import {getDatesInRange} from "utils/colors";
import {v4 as uuidv4} from "uuid";
import {currencyFormat} from "./kpiCalculation";
import {useLocation} from "react-router";
import {sumData} from "utils/get-months/getLastYear";
import {getChannelNameById} from "utils/get-channel-name/getChannelNameById";
import {channelsStateSelector} from "store/channels";
import {DictionaryAssinedKpiKeyUsersValue, User, UserAvatar} from "@models";
import {usersDataSelector} from "store/users";
import {leftArrow, rightArrow} from "../icons/three-dots";
import {Kpi} from "../../models/kpi";
import {useCurrencySign} from "../custom-hooks/useCurrencySign";
import {getTargetRatio} from "../../features/wideviews-financial/pnl-waterfall-chart/PnLWaterfallChart";
import {dateRangeSelector, isDefaultExploreOpenSelector} from "../../store/ui";
import {groupDataByKey} from "../group-by/group-by";
import {groupArrayByKey, sumGroupedDataByKey} from "../kpi-data-format/kpiDataFormat";
import {kpiService} from "../../services/kpi/kpi.service";

interface ExpandData {
  title: string;
  vsTarget: number;
  shareOfTotal: number;
}

export enum KpiPrefixEnum {
  NONE,
  CURRENCY,
  PERCENTAGE,
}

export enum KpiSection {
  MARKETING,
  FINANCE,
  WEBSITE,
  CUSTOMER_SUCCESS,
}

interface Props {
  popoverPlacement: PopoverPlacement;
  onClick?: (e: any) => void;
  kpiArray: KpisEnum[];

  bigKpi?: boolean;
  fromPinned?: boolean;
  setInitialLoad?: any;
  initialLoad?: boolean;
  startDate?: Date;
  endDate?: Date;
  doNotDisplayCard?: boolean;
  storesChannels?: string[];
  isComingSoon?: boolean;
  reducedShadow?: boolean;
}

enum CurrencyMode {
  UNSET,
  PERCENTAGE,
  CURRENCY,
}

interface CardProps {
  title?: string;
  value?: number;
  bigKpi?: boolean;
  vsTarget?: number;
  kpiNumber?: number;
  chartData?: number[];
  currencyMode?: CurrencyMode;
  popoverPlacement: PopoverPlacement;
  doNotDisplayCard?: boolean;
  expandData?: ExpandData[];
  avatarUserArray?: UserAvatar[];
  decimal?: number;
  isComingSoon?: boolean;
  reducedShadow?: boolean;
  hideTarget?: boolean;
  index?: number;
}

export const KpiRender = (props: Props) => {
  const [cardsArray, setCardsArray] = useState<
    {
      key?: number;
      title?: string;
      value?: number;
      bigKpi?: boolean;
      decimal?: number;
      vsTarget?: number;
      kpiNumber?: number;
      chartData?: number[];
      currencyMode: CurrencyMode;
      isComingSoon?: boolean;
      reducedShadow?: boolean;
      //doNotDisplayCard?: boolean;
    }[]
  >([]);

  const dispatch = useDispatch();
  const summedDataSelector = useSelector(summedKpiDataSelector);

  const dailyStatsKeys = {
    grossRevenue: "grossRevenue",
    orders: "orders",
    grossProfit: "grossProfit",
    netRevenue: "netRevenue",
    contributionProfit: "contributionProfit",
    marketingSpend: "marketingCost",
    sessions: "sessions",
  };

  const [loader, setLoader] = useState<boolean>(true);
  const currentCurrency = useCurrencySign();

  const user = useSelector(userSelector);
  const [bigKpisFrameIndex, setBigKpisFrameIndex] = useState(0);
  const [bigKpisCards, setBigKpisCards] = useState<any[]>([]);

  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const allKpis: KpiState = useSelector(kpiStateSelector);
  const [kpiData, setKpiData] = useState<any[]>([]);
  const [allTargetsData, setTargetsData] = useState<any[]>([]);
  const [marketingKpi, setMarketingKpi] = useState<any[]>([]);
  const [websiteKpi, setWebsiteKpi] = useState<any[]>([]);
  const channels = useSelector(channelsStateSelector);
  const { pathname } = useLocation();
  const [storeChannels, setStoreChannels] = useState<any[]>([]);
  const [summedTargetsWithChannels, setSummedTargetsWithChannels] = useState<
    any[]
  >([]);
  const [summedDailyStatsWithChannels, setSummedDailyStatsWithChannels] =
    useState<any[]>([]);

  const organizationId = useSelector(organizationSelector);
  const users = useSelector(usersDataSelector);
  const [usersForKpiRender, setUsersForKpiRender] = useState<any[]>([]);
  const [dictionary, setDictionary] =
    useState<DictionaryAssinedKpiKeyUsersValue>({});
  const [lastSevenDaysData, setLastSevenDaysData] = useState<Kpi[]>([]);
  const allSummedTargetStats = useSelector(summedTargetStats);
  const kpiMetaData = useSelector(kpiStateSelector);
  const [keyToGroupDataBy, setKeyToGroupDataBy] = useState<string>();
  const dates = useSelector(dateRangeSelector);
  const optionsForKpiGroupBy = {
    [KpiGroupBy.CHANNEL_DISPLAY_NAME]: "channelDisplayName",
    [KpiGroupBy.CHANNEL_ID]: "wvChannelId",
  }

  useEffect(() => {
    setKeyToGroupDataBy(optionsForKpiGroupBy[kpiMetaData.groupBy])
  }, [kpiMetaData?.groupBy])

  useEffect(() => {
    if (!!channels?.channels) {
      setStoreChannels(
        channels?.channels
          ?.filter((item) => !!item?.displayName)
          .map((item) => item?.id)
      );
    }
  }, [channels?.channels]);

  useEffect(() => {
    const usersData = users?.map((user) => {
      const currentWorkspaceIndex = user?.workspaces.findIndex(
        (workspace) => workspace?.organization === organizationId?.id
      );

      const currentWorkspace = user?.workspaces[currentWorkspaceIndex];

      if (!!currentWorkspace) {
        return returnCurrentWorkspaceObject(user, currentWorkspace);
      }
      return undefined;
    });

    if (!!usersData) {
      const filterUndefinedUser = usersData.filter(
        (item) => !!item && item?.assignedKpis?.length
      );
      const keyArray = getArrayRelevantKpiAssignedNumberValue();
      if (!!filterUndefinedUser && filterUndefinedUser.length && !!keyArray) {
        setUsersForKpiRender(usersData);
        let temp: DictionaryAssinedKpiKeyUsersValue = {};
        for (let i = 0; i < keyArray.length; i++) {
          temp[keyArray[i]] = [];
          filterUndefinedUser?.forEach((user, index) => {
            const keyAssignedKpiValueNumber = user?.assignedKpis?.find(
              (kpiItem) => keyArray[i] === kpiItem
            );
            if (!!keyAssignedKpiValueNumber) {
              temp[keyArray[i]].push(user);
            }
          });
        }
        setDictionary(temp);
      }
    }
  }, [organizationId?.id, users]);

  useEffect(() => {
    const targetsChannels: any = {};
    const dailyChannels: any = {};

    if(props?.bigKpi && !!allKpis?.bigWideviewCardsData){
      return
    }

    if (!!allTargetsData) {
      allTargetsData.forEach((item) => {
        if(!!keyToGroupDataBy){
          if (!!targetsChannels[item[keyToGroupDataBy]]) {
            targetsChannels[item[keyToGroupDataBy]].push(item);
          } else {
            targetsChannels[item[keyToGroupDataBy]] = [item];
          }
        }
      });
    }
    if (!!kpiData) {
      kpiData.forEach((item) => {
        if(!!keyToGroupDataBy)
        if (!!dailyChannels[item[keyToGroupDataBy]]) {
          dailyChannels[item[keyToGroupDataBy]].push(item);
        } else {
          dailyChannels[item[keyToGroupDataBy]] = [item];
        }
      });
    }
    const targetsData: any[] = [];
    const dailyData: any[] = [];
    Object.keys(targetsChannels).forEach((key) => {
      if (!!channels?.channels) {
        targetsData.push({
          ...sumData(targetsChannels[key], []),
          channelName: kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_ID ? getChannelNameById(key, channels?.channels) : key,
          channelId: key,
        });
      }
    });
    Object.keys(dailyChannels).forEach((key) => {
      if (!!channels?.channels) {
        dailyData.push({
          ...sumData(dailyChannels[key], ["activityDate", "displayName", "id"]),
          channelName: kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_ID ? getChannelNameById(key, channels?.channels) : key,
          channelId: key,
        });
      }
    });
    setSummedDailyStatsWithChannels(dailyData);
    setSummedTargetsWithChannels(targetsData);
  }, [allTargetsData, channels?.channels, kpiData, allKpis?.bigWideviewCardsData, props?.bigKpi]);

  useEffect(() => {
    const propsChannelNames = props?.storesChannels?.map((item) => {
      const foundChannel = channels?.channels?.find((channel) => channel?.id === item);
      return foundChannel?.displayName
    })
    if (!!props?.startDate && !!props?.endDate) {
      let filteredDataByDays: any[] = [];
      let filteredDataByDaysTargets: any[] = [];
      const selectedDays = getDatesInRange(props?.startDate, props?.endDate);

      allKpis?.data?.data?.forEach((item: any) => {
        if (!!props?.storesChannels) {
          if(kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME && !propsChannelNames?.includes(item?.channelDisplayName)){
           return
          }
          if (kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_ID && !props?.storesChannels.includes(item?.wvChannelId)) {
            return;
          }
        }
        if (selectedDays.includes(item?.activityDate.slice(0, 10))) {
          filteredDataByDays.push(item);
        }
      });
      allKpis?.targetData?.data?.forEach((item: any) => {
        if (!!props?.storesChannels) {
          if(kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME && !propsChannelNames?.includes(item?.channelDisplayName)){
            return
          }
          if (kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_ID && !props?.storesChannels.includes(item?.wvChannelId)) {
            return;
          }
        }
        if (selectedDays.includes(item?.activityDate.slice(0, 10))) {
          filteredDataByDaysTargets.push(item);
        }
      });
      setKpiData(filteredDataByDays);
      setTargetsData(filteredDataByDaysTargets);
    } else {

      if (!!allKpis?.data?.data) {


        const filterByChannel = allKpis?.data?.data?.filter((item) =>{
          if(kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME){
            return propsChannelNames?.includes(item?.channelDisplayName);
          }else {
            return props?.storesChannels?.includes(item?.wvChannelId)
          }
        }
        );
        setKpiData(filterByChannel);
      }
      if (!!allKpis?.targetData?.data) {
        if (!!props?.storesChannels) {
          const filterByChannel = allKpis?.targetData?.data?.filter((item) =>{
                if(kpiMetaData?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME){
                  return propsChannelNames?.includes(item?.channelDisplayName);
                }else {
                  return props?.storesChannels?.includes(item?.wvChannelId)
                }
              }
          );
          setTargetsData(filterByChannel);
        } else {
          setTargetsData(allKpis?.targetData?.data);
        }
      }
    }
  }, [
    allKpis?.data?.data,
    allKpis?.targetData?.data,
    props?.endDate,
    props?.startDate,
    props?.storesChannels,
    channels?.channels
  ]);

  useEffect(() => {
    if (!!kpiData) {
      setLoader(true);
      const keysToIgnore = [
        "wvChannelId",
        "purchasePoint",
        "date",
        "country",
        "createdAt",
        "updatedAt",
        "id",
        "activityDate",
        "market",
        "displayName",
        "currency",
      ];
      const summedTotalDailyStats = sumData(kpiData, keysToIgnore);
      dispatch(setDailyStats({ dailyStats: summedTotalDailyStats }));
      setLoader(false);
    }
    if (!!marketingKpi) {
      setLoader(true);
      const keysToIgnore = [
        "wvChannelId",
        "purchasePoint",
        "date",
        "country",
        "createdAt",
        "updatedAt",
        "id",
        "activityDate",
        "market",
        "imageName",
        "impressions",
        "image",
        "adId",
        "ad",
        "adSetId",
        "adsGroup",
        "campaignId",
        "campaignName",
        "account",
        "type",
        "platform",
      ];
      const summedTotalMarketingStats = sumData(marketingKpi, keysToIgnore);
      dispatch(
        setMarketingStats({ marketingStats: summedTotalMarketingStats })
      );
      setLoader(false);
    }
  }, [marketingKpi, kpiData, websiteKpi, dispatch]);

  useEffect(() => {
    if (!!allTargetsData) {
      setLoader(true);
      const keysToIgnore = [
        "wvChannelId",
        "purchasePoint",
        "date",
        "country",
        "createdAt",
        "updatedAt",
        "id",
        "activityDate",
        "market",
        "displayName",
        "currency",
      ];
      const tempTargetsArr: any[] = [];

      const kpiDataTargetsFilteredByChannel = allTargetsData.filter((item) => {
        return storeChannels.includes(item.wvChannelId);
      });

      const summedTotalTargetStats = sumData(
        kpiDataTargetsFilteredByChannel,
        keysToIgnore
      );
      dispatch(setTargetStats({ targetStats: summedTotalTargetStats }));
      setLoader(false);
    }
    if (!!marketingKpi) {
      setLoader(true);
      const keysToIgnore = [
        "wvChannelId",
        "purchasePoint",
        "date",
        "country",
        "createdAt",
        "updatedAt",
        "id",
        "activityDate",
        "market",
        "imageName",
        "impressions",
        "image",
        "adId",
        "ad",
        "adSetId",
        "adsGroup",
        "campaignId",
        "campaignName",
        "account",
        "type",
        "platform",
      ];
      const summedTotalMarketingStats = sumData(marketingKpi, keysToIgnore);
      dispatch(
        setMarketingStats({ marketingStats: summedTotalMarketingStats })
      );
      setLoader(false);
    }
  }, [allTargetsData, dispatch]);


  //helpers


  const checkLastWeekInData = () => {
    const startDate = dates?.startDate;
    const endDate = dates?.endDate;

    if (!startDate || !endDate) {
      return false; // return false if either startDate or endDate is undefined
    }

    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago from today

    const todayStr = today.toISOString().substring(0, 10);
    const lastWeekStr = lastWeek.toISOString().substring(0, 10);


    // Convert startDate and endDate strings to Date objects
    const startDateObj = new Date(startDate + "T00:00:00.000Z");
    const endDateObj = new Date(endDate + "T00:00:00.000Z");

    const todayDate = new Date(todayStr + "T00:00:00.000Z");
    const lastWeekDate = new Date(lastWeekStr + "T00:00:00.000Z");

    // Check if the last 7 days are between startDate and endDate
    if (lastWeekDate >= startDateObj && todayDate <= endDateObj) {
      return true;
    }

    return false;
  };

  const getLastWeekDataFromStore = () => {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago from today

    const todayStr = today.toISOString().substring(0, 10);
    const lastWeekStr = lastWeek.toISOString().substring(0, 10);

    const todayDate = new Date(todayStr + "T00:00:00.000Z");
    const lastWeekDate = new Date(lastWeekStr + "T00:00:00.000Z");
    const filteredKpiData = kpiData?.filter((item) => {
      const activityDate = new Date(item?.activityDate);
      return activityDate >= lastWeekDate && activityDate <= todayDate;
    })
    sumLastWeekData(filteredKpiData);
  }

  const sumLastWeekData = (data: Kpi[]) => {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago from today

    const lastWeekStr = lastWeek.toISOString().substring(0, 10);

    const lastWeekDate = new Date(lastWeekStr + "T00:00:00.000Z");

    const dateArray = Array.from({ length: 8 }, (_, i) => new Date(lastWeekDate.getTime() + i * 24 * 60 * 60 * 1000)).map((date) => date?.toISOString()?.slice(0,10) + "T00:00:00.000Z")


    const groupedData = groupArrayByKey(data, 'activityDate');
   const summedData = Object.keys(groupedData).map((key) => {
     const arrayToSum = groupedData[key];
     return {...sumData(arrayToSum, ['activityDate', 'sku', 'id', 'displayName', 'currency']), activityDate: key};
   })

    const newData = dateArray.map((date) => {
      const existingObj = summedData.find((obj) => obj?.activityDate === date);
      if (existingObj) {
        return existingObj;
      } else {
        return { activityDate: date
        };
      }
    })

    // sort newData array by activityDate, oldest date first
    newData.sort((a, b) => {
      const dateA = new Date(a?.activityDate).getTime();
      const dateB = new Date(b?.activityDate).getTime();
      return dateA - dateB;
    })

    newData?.pop();

    setLastSevenDaysData(newData)
  }




  const getArrayRelevantKpiAssignedNumberValue = () =>
    Object.values(KpisEnum).slice(Object.values(KpisEnum).length / 2 + 1);

  const returnCurrentWorkspaceObject = (user: User, currentWorkspace: any) => {
    return {
      userName: user?.firstName,
      userLastName: user?.lastName,
      userId: user?.id,
      workspaceId: currentWorkspace!.id!,
      assignedKpis: currentWorkspace?.assignedKpis,
    } as UserAvatar;
  };

  const shiftUndefined = (arr: any) => {
    let duplicate = [];
    for (let i = 0, len = arr.length, j = len - 1, k = 0; k <= j; i++) {
      const item = arr[i];
      if (item?.value === undefined || isNaN(item?.value)) {
        duplicate[j--] = item;
      } else {
        duplicate[k++] = item;
      }
    }
    return duplicate;
  };

  const nextBigKpisFrame = () => {
    setBigKpisFrameIndex(
      (bigKpisFrameIndex + 1) * 3 > bigKpisCards.length
        ? bigKpisFrameIndex
        : bigKpisFrameIndex + 1
    );
  };

  const previousBigKpisFrame = () => {
    setBigKpisFrameIndex(bigKpisFrameIndex - 1 < 0 ? 0 : bigKpisFrameIndex - 1);
  };

  const bigKpisFrame = (index: number) => {
    const numberOfCards = bigKpisCards.length;
    const reminder = (index * 3) % numberOfCards;
    /* console.log(numberOfCards);
                                        console.log(reminder);
                                        console.log(bigKpisCards.slice(index * 3, reminder > 0 ? reminder : 3)); */
    return bigKpisCards/*.slice(
      index * 3,
      reminder + index * 3 > 0 ? reminder + index * 3 : reminder + index * 3 + 3
    );*/
  };

  const cards = (
    dataArray: {
      key?: number;
      title?: string;
      value?: number;
      bigKpi?: boolean;
      kpiNumber?: number;
      decimal?: number;
      chartData?: number[];
      vsTarget?: number;
      currencyMode: CurrencyMode;
      expandData?: any;
      isComingSoon?: boolean;
      reducedShadow?: boolean;
    }[]
  ) => {
    return shiftUndefined(dataArray)?.map((item, i) => {
      return (
        <RenderCard
          expandData={item?.expandData}
          popoverPlacement={props.popoverPlacement}
          currencyMode={item?.currencyMode}
          key={i}
          index={i}
          title={item?.title}
          value={item?.value}
          vsTarget={item?.vsTarget}
          bigKpi={item?.bigKpi}
          kpiNumber={item?.kpiNumber}
          chartData={item?.chartData}
          decimal={item?.decimal}
          avatarUserArray={getAvatarArray(item?.kpiNumber)}
          isComingSoon={item?.isComingSoon}
          reducedShadow={item?.reducedShadow}
        />
      );
    });
  };

  const getAvatarArray = (indexKpiNumber?: number | string | KpisEnum) => {
    if (!!dictionary && !!indexKpiNumber) {
      if (!!dictionary[indexKpiNumber]) {
        return dictionary[indexKpiNumber];
      }
    }
    //else
    return [];
  };

  const getExpandedData = (key: string, totalValue: number) => {
    const allExpandData: any[] = [];
    if (key === "aov") {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
          (target) => target?.wvChannelId === item?.wvChannelId
        );
        const actualAov = item?.grossRevenue / item?.orders;
        const targetAov =
          findTargetChannel?.grossRevenue / findTargetChannel?.orders;
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget: !!targetAov
            ? parseFloat(((actualAov / targetAov) * 100).toFixed(2))
            : null,
          shareOfTotal: !!totalValue
            ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
            : null,
        });
      });
    }
    if (key === "cac") {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
            (target) => target?.wvChannelId === item?.wvChannelId
        );
        const actualCac = item?.marketingCost / item?.customers;
        const targetCac =
            findTargetChannel?.marketingCost / findTargetChannel?.customers;
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget: !!targetCac
              ? parseFloat(((actualCac / targetCac) * 100).toFixed(2))
              : null,
          shareOfTotal: !!totalValue
              ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
              : null,
        });
      });
    }
    if (key === "blendedRoas") {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
          (target) => target.wvChannelId === item.wvChannelId
        );
        const actualRoas = item?.grossRevenue / item?.marketingCost;
        const targetAov =
          findTargetChannel?.grossRevenue / findTargetChannel?.marketingCost;
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget: !!targetAov
            ? parseFloat(((actualRoas / targetAov) * 100).toFixed(2))
            : null,
          shareOfTotal: !!totalValue
            ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
            : null,
        });
      });
    }
    if (key === "blendedRoas") {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
            (target) => target.wvChannelId === item.wvChannelId
        );
        const actualRoas = item?.grossRevenue / item?.marketingCost;
        const targetAov =
            findTargetChannel?.grossRevenue / findTargetChannel?.marketingCost;
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget: !!targetAov
              ? parseFloat(((actualRoas / targetAov) * 100).toFixed(2))
              : null,
          shareOfTotal: !!totalValue
              ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
              : null,
        });
      });
    }
    if (key === "cpo") {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
          (target) => target.wvChannelId === item.wvChannelId
        );
        const actualCPO = item?.marketingCost / item?.orders;
        const targetCPO =
          findTargetChannel?.marketingCost / findTargetChannel?.orders;
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget: !!targetCPO
            ? parseFloat(((actualCPO / targetCPO) * 100).toFixed(2))
            : 0,
          shareOfTotal: !!totalValue
            ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
            : 0,
        });
      });
    } else {
      summedDailyStatsWithChannels.forEach((item) => {
        const findTargetChannel = summedTargetsWithChannels.find(
          (target) => target.wvChannelId === item.wvChannelId
        );

        /* if (!!findTargetChannel && key in findTargetChannel) {*/
        allExpandData.push({
          title: item?.wvChannelId,
          channelId: item?.wvChannelId,
          vsTarget:
            !!findTargetChannel &&
            key in findTargetChannel &&
            !!findTargetChannel[key]
              ? parseFloat(
                  ((item[key] / findTargetChannel[key]) * 100).toFixed(2)
                )
              : 0,
          shareOfTotal: !!totalValue
            ? parseFloat(((item[key] / totalValue) * 100).toFixed(2))
            : 0,
        });
        /*}*/
      });
    }
    return allExpandData;
  };

  useEffect(() => {
    const cardsArray: {
      key?: number;
      title?: string;
      value?: number;
      bigKpi?: boolean;
      kpiNumber?: number;
      chartData?: number[];
      vsTarget?: number;
      decimal?: number;
      currencyMode: CurrencyMode;
      expandData?: ExpandData[];
      isComingSoon?: boolean;
      reducedShadow?: boolean;
    }[] = [];
    props?.kpiArray?.forEach((kpi, i) => {
      switch (KpisEnum[kpi]) {
        case "BLENDED_ROAS":
          let blendedRoas;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.grossRevenue in summedDataSelector?.dailyStats &&
            dailyStatsKeys.marketingSpend in summedDataSelector?.dailyStats
          ) {
            blendedRoas =
              summedDataSelector?.dailyStats[dailyStatsKeys?.grossRevenue] /
              (!!summedDataSelector?.dailyStats[dailyStatsKeys?.marketingSpend]
                ? summedDataSelector?.dailyStats[dailyStatsKeys?.marketingSpend]
                : 0);
          }
          if (blendedRoas === Infinity) {
            blendedRoas = undefined;
          }
          let targetBlendedRoas: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.marketingSpend in allSummedTargetStats &&
            dailyStatsKeys?.grossRevenue in allSummedTargetStats
          ) {
            targetBlendedRoas =
              allSummedTargetStats[dailyStatsKeys?.grossRevenue] /
              allSummedTargetStats[dailyStatsKeys?.marketingSpend];
          }
          const blendedRoasTarget = getTargetRatio({
            actual: blendedRoas,
            target: targetBlendedRoas,
          });

          let blendedRoasChartData: number[] = [];
          if(!!lastSevenDaysData){
            blendedRoasChartData = lastSevenDaysData?.map((item) => !!item?.grossRevenue && !!item?.marketingCost ? item?.grossRevenue / item?.marketingCost : 0)
          }


          cardsArray.push({
            key: i,
            title: "Blended ROAS",
            value: !!summedDataSelector?.dailyStats?.marketingCost
              ? parseFloat(
                  (
                    summedDataSelector?.dailyStats?.grossRevenue /
                    summedDataSelector?.dailyStats?.marketingCost
                  ).toFixed(2)
                )
              : undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: blendedRoasTarget,
            chartData: blendedRoasChartData,
            currencyMode: CurrencyMode.UNSET,
            expandData: getExpandedData(
              "blendedRoas",
              summedDataSelector?.dailyStats?.grossRevenue /
                summedDataSelector?.dailyStats?.marketingCost
            ),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "CUSTOMER_ACQUISITION_COST":


          cardsArray.push({
            key: i,
            title: "CAC",
            value: !!summedDataSelector?.dailyStats?.marketingCost && !!summedDataSelector?.dailyStats?.customers
                ? parseFloat(
                    (
                        summedDataSelector?.dailyStats?.marketingCost /
                        summedDataSelector?.dailyStats?.customers
                    ).toFixed(2)
                )
                : undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: 10,
            chartData: [],
            currencyMode: CurrencyMode.UNSET,
            expandData: getExpandedData(
                "cac",
                summedDataSelector?.dailyStats?.marketingCost /
                summedDataSelector?.dailyStats?.customers
            ),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "GROSS_REVENUE":
          let grossRevenue: any;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.grossRevenue in summedDataSelector?.dailyStats
          ) {
            grossRevenue =
              summedDataSelector?.dailyStats[dailyStatsKeys?.grossRevenue];
          }
          let targetGrossRevenue: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.grossRevenue in allSummedTargetStats
          ) {
            targetGrossRevenue =
              allSummedTargetStats[dailyStatsKeys?.grossRevenue];
          }
          const grossRevenueTarget = getTargetRatio({
            actual: grossRevenue,
            target: targetGrossRevenue,
          });

          let graphData: number[] = [];

          if(props?.bigKpi){
            const foundGrossRevenueDataForBigWideviewCard = findDataForBigWideviewCards('grossRevenue');
            grossRevenue = foundGrossRevenueDataForBigWideviewCard?.value;
            if(!!foundGrossRevenueDataForBigWideviewCard?.points){
              graphData = foundGrossRevenueDataForBigWideviewCard?.points;
            }
          }

          cardsArray.push({
            key: i,
            title: "Gross Revenue",
            value: grossRevenue,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: grossRevenueTarget,
            chartData: graphData,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("grossRevenue", grossRevenue),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "NET_REVENUE":
          let netRevenue;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.netRevenue in summedDataSelector?.dailyStats
          ) {
            netRevenue =
              summedDataSelector?.dailyStats[dailyStatsKeys?.netRevenue];
          }
          let targetNetRevenue: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.netRevenue in allSummedTargetStats
          ) {
            targetNetRevenue = allSummedTargetStats[dailyStatsKeys?.netRevenue];
          }
          const netRevenueTarget = getTargetRatio({
            actual: netRevenue,
            target: targetNetRevenue,
          });

          let netRevenueGraphData: number[] = [];
          if(props?.bigKpi){
            const foundNetRevenueDataForBigWideviewCard = findDataForBigWideviewCards('netRevenue');
            netRevenue = foundNetRevenueDataForBigWideviewCard?.value;
            if(!!foundNetRevenueDataForBigWideviewCard?.points){
              netRevenueGraphData = foundNetRevenueDataForBigWideviewCard?.points;
            }
          }

          cardsArray.push({
            key: i,
            title: "Net Revenue",
            value: netRevenue,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: netRevenueGraphData,
            vsTarget: netRevenueTarget,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("netRevenue", netRevenue),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "GROSS_PROFIT":
          let grossProfit;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.grossProfit in summedDataSelector?.dailyStats
          ) {
            grossProfit =
              summedDataSelector?.dailyStats[dailyStatsKeys?.grossProfit];
          }
          let targetGrossProfit: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.grossProfit in allSummedTargetStats
          ) {
            targetGrossProfit =
              allSummedTargetStats[dailyStatsKeys?.grossProfit];
          }

          const grossProfitTarget = getTargetRatio({
            actual: grossProfit,
            target: targetGrossProfit,
          });

          let grossProfitChartData: number[] = [];
          if(props?.bigKpi){
            const foundGrossRevenueDataForBigWideviewCard = findDataForBigWideviewCards('grossProfit');
            grossProfit = foundGrossRevenueDataForBigWideviewCard?.value;
            if(!!foundGrossRevenueDataForBigWideviewCard?.points){
              grossProfitChartData = foundGrossRevenueDataForBigWideviewCard?.points;
            }
          }


          cardsArray.push({
            key: i,
            title: "Gross Profit",
            value: grossProfit,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: grossProfitChartData,
            vsTarget: grossProfitTarget,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("grossProfit", grossProfit),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "CONTRIBUTION_PROFIT":
          let contributionProfit;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.contributionProfit in summedDataSelector?.dailyStats
          ) {
            contributionProfit =
              summedDataSelector?.dailyStats[
                dailyStatsKeys?.contributionProfit
              ];
          }
          let targetContributionProfit: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.contributionProfit in allSummedTargetStats
          ) {
            targetContributionProfit =
              allSummedTargetStats[dailyStatsKeys?.contributionProfit];
          }

          const contProfitTarget = getTargetRatio({
            actual: contributionProfit,
            target: targetContributionProfit,
          });

          let contProfitChartData: number[] = [];
          if(!!lastSevenDaysData){
            contProfitChartData = lastSevenDaysData?.map((item) => item?.contributionProfit ?? 0)
          }


          cardsArray.push({
            key: i,
            title: "Contribution Profit",
            value: contributionProfit,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: contProfitChartData,
            vsTarget: contProfitTarget,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData(
              "contributionProfit",
              contributionProfit
            ),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "ORDERS":
          let orders;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.orders in summedDataSelector?.dailyStats
          ) {
            orders = summedDataSelector?.dailyStats[dailyStatsKeys?.orders];
          }
          let targetOrders: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.orders in allSummedTargetStats
          ) {
            targetOrders = allSummedTargetStats[dailyStatsKeys?.orders];
          }
          let orderGraphData: number[] = [];
          if(!!lastSevenDaysData){
            orderGraphData = lastSevenDaysData?.map((item) => item?.orders ?? 0)
          }

          const ordersTarget = getTargetRatio({
            actual: orders,
            target: targetOrders,
          });

          cardsArray.push({
            key: i,
            title: "Orders",
            value: orders !== undefined ? orders : undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: ordersTarget,
            chartData: orderGraphData,
            currencyMode: CurrencyMode.UNSET,
            expandData: getExpandedData("orders", orders),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;

        case "AVERAGE_ORDERS_VALUE":
          let aov;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.grossRevenue in summedDataSelector?.dailyStats &&
            dailyStatsKeys.orders in summedDataSelector?.dailyStats
          ) {
            aov =
              summedDataSelector?.dailyStats[dailyStatsKeys?.grossRevenue] /
              summedDataSelector?.dailyStats[dailyStatsKeys?.orders];
          }
          if (aov === Infinity) {
            aov = undefined;
          }
          let targetAov: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.grossRevenue in allSummedTargetStats &&
            dailyStatsKeys?.orders in allSummedTargetStats
          ) {
            targetAov =
              allSummedTargetStats[dailyStatsKeys?.grossRevenue] /
              allSummedTargetStats[dailyStatsKeys?.orders];
          }
          const aovTarget = getTargetRatio({
            actual: aov,
            target: targetAov,
          });

          let aovChartData: number[] = [];
          if(!!lastSevenDaysData){
            aovChartData = lastSevenDaysData?.map((item) => !!item?.orders && !!item?.grossRevenue ? item?.grossRevenue / item?.orders : 0)
          }

          cardsArray.push({
            key: i,
            title: "AOV",
            value: aov,
            decimal: 1,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: aovTarget,
            chartData: aovChartData,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("aov", aov ? aov : 0),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "CUSTOMER_ACQUISITION_COST":

         /* cardsArray.push({
            key: i,
            title: "AOV",
            value: aov,
            decimal: 1,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            vsTarget: aovTarget,
            chartData: aovChartData,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("aov", aov ? aov : 0),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });*/
          break;
        case "MARKETING_SPEND":
          let marketingSpend;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.marketingSpend in summedDataSelector?.dailyStats
          ) {
            marketingSpend =
              summedDataSelector?.dailyStats[dailyStatsKeys?.marketingSpend];
          }
          let targetMarketingSpend: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.marketingSpend in allSummedTargetStats
          ) {
            targetMarketingSpend =
              allSummedTargetStats[dailyStatsKeys?.marketingSpend];
          }

          const marketingSpendTarget = getTargetRatio({
            actual: marketingSpend,
            target: targetMarketingSpend,
          });

          let marketingGraphData: number[] = [];
          if(!!lastSevenDaysData){
            marketingGraphData = lastSevenDaysData?.map((item) => item?.marketingCost ?? 0)
          }
          cardsArray.push({
            key: i,
            title: "Marketing Spend",
            value: marketingSpend,
            bigKpi: props.bigKpi,
            vsTarget: marketingSpendTarget,
            kpiNumber: kpi,
            chartData: marketingGraphData,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("marketingCost", marketingSpend),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "COST_PER_ORDER":
          let cpo;
          if (
            !!summedDataSelector?.dailyStats &&
            dailyStatsKeys.marketingSpend in summedDataSelector?.dailyStats &&
            dailyStatsKeys.orders in summedDataSelector?.dailyStats
          ) {
            cpo =
              summedDataSelector?.dailyStats[dailyStatsKeys?.marketingSpend] /
              summedDataSelector?.dailyStats[dailyStatsKeys?.orders];
          }
          if (cpo === Infinity) {
            cpo = undefined;
          }
          let targetCpo: number = 0;
          if (
            !!allSummedTargetStats &&
            dailyStatsKeys?.marketingSpend in allSummedTargetStats &&
            dailyStatsKeys?.orders in allSummedTargetStats
          ) {
            targetCpo =
              allSummedTargetStats[dailyStatsKeys?.marketingSpend] /
              allSummedTargetStats[dailyStatsKeys?.orders];
          }
          const cpoTarget = getTargetRatio({
            actual: cpo,
            target: targetCpo,
          });

          let cpoChartData: number[] = [];
          if(!!lastSevenDaysData){
            cpoChartData = lastSevenDaysData?.map((item) => !!item?.marketingCost && !!item?.orders ? item?.marketingCost / item?.orders : 0)
          }

          cardsArray.push({
            key: i,
            title: "CPO",
            value: cpo,
            bigKpi: props.bigKpi,
            vsTarget: cpoTarget,
            kpiNumber: kpi,
            chartData: cpoChartData,
            decimal: 2,
            currencyMode: CurrencyMode.CURRENCY,
            expandData: getExpandedData("cpo", cpo ? cpo : 0),
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "INVENTORY_TURNOVER":
          cardsArray.push({
            key: i,
            title: "Inventory Turnover",
            value: undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: [],
            currencyMode: CurrencyMode.UNSET,
            expandData: undefined,
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "COGS_AVERAGE":
          cardsArray.push({
            key: i,
            title: "COGS Average",
            value: undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: [],
            currencyMode: CurrencyMode.CURRENCY,
            expandData: undefined,
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        case "AVERAGE_UNITS_SOLD":
          cardsArray.push({
            key: i,
            title: "Average Units Solid p/Day",
            value: undefined,
            bigKpi: props.bigKpi,
            kpiNumber: kpi,
            chartData: [],
            currencyMode: CurrencyMode.UNSET,
            expandData: undefined,
            isComingSoon: props.isComingSoon,
            reducedShadow: props?.reducedShadow,
          });
          break;
        default:
        // console.log();
      }
    });
    setCardsArray(cardsArray);
  }, [summedDataSelector?.dailyStats, props.bigKpi, props?.kpiArray, lastSevenDaysData]);

  useEffect(() => {
    //render big kpis cards

    if (!!props.bigKpi) {
      setBigKpisCards(cards(cardsArray));
    }
  }, [cardsArray, props.bigKpi]);

  const returnKpiNumber = (kpiNumber: number) => {
    if (!!props?.onClick) {
      props?.onClick(kpiNumber);
    }
  };

  const findDataForBigWideviewCards = (fieldName: string) => {
    if(!!allKpis?.bigWideviewCardsData){
      return allKpis?.bigWideviewCardsData?.find((cardData) => cardData?.name === fieldName);
    }
    return
  }

  const RenderCard = (props: CardProps) => {
    // console.log("props", props);
    let kpiValue = props?.value?.toString();

    let dataMode;
    if (
      !!props.decimal &&
      props.currencyMode === CurrencyMode.CURRENCY &&
      !!props.value
    ) {
      kpiValue = currencyFormat(props.value, props?.decimal, currentCurrency);
    }

    if (
      props.currencyMode === CurrencyMode.CURRENCY &&
      !!props.value &&
      !props.decimal
    ) {
      kpiValue = currencyFormat(props.value, 0, currentCurrency);
    }
    if (props.title === "") {
      // means server is sending back enum that does not exist
      return null;
    }
    if (
      props.value === undefined ||
      props.title === undefined ||
      isNaN(props?.value)
    ) {
      dataMode = DataMode.Broken;
      kpiValue = "";
    }
    if (!!props?.isComingSoon && props?.isComingSoon) {
      dataMode = DataMode.Broken;
      kpiValue = "";
    }
    if (props?.title === "Orders") {
      kpiValue = props?.value?.toLocaleString();
    }
    const allDeductionCards = [KpisEnum.MARKETING_SPEND, KpisEnum.COST_PER_ORDER]

    if (!!props.bigKpi) {
      return (
        <BigWideviewCard
          expandRows={props.expandData}
          key={uuidv4()}
          value={kpiValue || ""}
          title={props.title}
          kpiNumber={props.kpiNumber ?? 0 }
          targetPercentage={props?.vsTarget}
          dataMode={dataMode}
          hideTarget={true}
          index={props?.index}

          deductionTarget={!!props?.kpiNumber && allDeductionCards?.includes(props?.kpiNumber)}
          chartData={props.chartData}
          assignedKpisAvatar={
            !!props?.avatarUserArray ? props?.avatarUserArray : []
          }
        />
      );
    }

    return (
      <SmallWideviewCard
        doNotDisplayCard={
          pathname === "/admin/panel/organization" ? true : false
        }
        popoverPlacemnt={props.popoverPlacement}
        onClick={(e) => returnKpiNumber(e)}
        key={uuidv4()}
        value={kpiValue || ""}
        title={props.title}
        dataMode={dataMode}
        targetPercentage={props?.vsTarget}
        userAvatarUrl={user?.image}
        kpiNumber={props.kpiNumber}
        reducedShadow={props?.reducedShadow}
        hideTarget={true}
        user={user}
        deductionTarget={!!props?.kpiNumber && allDeductionCards?.includes(props?.kpiNumber)}
        assinedKpisAvatar={
          !!props?.avatarUserArray ? props?.avatarUserArray : []
        }
      />
    );
  };

  return (
    <>
      {loader ? (
        <Box
          display={"flex"}
          width="100%"
          height={"140px"}
          justifyContent="center"
        >
          <Spinner color="#7331EC" mt={25} />
        </Box>
      ) : props.bigKpi ? (
        <Flex flexWrap={'wrap'}>
          {bigKpisCards}
        </Flex>
      ) : (
        <>{cards(cardsArray)}</>
      )}
    </>
  );
};

import styled from "styled-components";

export namespace styles {
  export const StyledTitle = styled.div<{ margin?: number }>`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => props.theme.primaryColor};
    font-size: 21px;
    font-weight: bold;
    margin: ${(props) =>
      props?.margin ? `${props?.margin}px 0 4px` : "5px 0"};
  `;
  export const StyledText = styled.div`
    color: #7a86a1;
    font-size: 15px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const StyledSaveButton = styled.button`
    height: 38px;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
  `;
  export const CloseIcon = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    &:hover{
      cursor: pointer;
    }
  `;
  export const StyledDiscardButton = styled.button`
    height: 38px;
    /* background-color: ${(props) => props.theme.primaryColor}; */
    color: #7a86a1;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 15px;
    outline: 1px solid #7a86a1;
  `;
  export const ApplyOnUpdateButton = styled.button`
    height: 38px;
    color: ${(props) => props.theme.primaryColor};
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 15px;
    outline: 1px solid #7a86a1;
    &:hover{
      cursor: pointer;
    }
  `;
  export const CustomFooter = styled.div`
    display: flex;
    padding: 0 0 0;
    margin: 30px auto 0;
    width: 70%;

    justify-content: space-between;
  `;
  export const CustomFooterApply = styled.div`
    display: flex;
    padding: 0 0 0;
    margin: 30px auto 18px;
    width: 58%;
    justify-content: space-between;
  `;
  export const SystemUpdateText = styled.div`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 14px;
  `;
}
export default styles;

import { organizationDataLanguageSelector, organizationSelector } from '@store';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';
import { ComparisonTable } from 'store/website';
import { currencyOptions } from 'utils/currency/currencyOptions';
import { currencyFormat } from 'utils/kpi-render/kpiCalculation';
import { styles } from './styles';
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";
import { Tooltip } from '@chakra-ui/react';

export interface PerformanceTableInterface {
    source?:string;
    revenue?: number;
    sessions?:number;
    traffic_source?:string;
    medium?:string;
    users?:number;
    transactions?:number;
    cr?: number;
    aov?: number;
}

interface Props {
    data: PerformanceTableInterface[];
    selectedTab: number;
    setSelectedTab: (selectedTab: number) => void,
    setFilteredData: (propsData: ComparisonTable[]) => void;
    headers: string[];

}

interface Data {
    col_0?: string;  // traffic
    col_1?: string;  // source
    col_2?: string;  // medium
    col_3?: number;  // sessions
    col_4?: number;  // users
    col_5?: number; // transactions
    col_6?: number; // revenue
    col_7?: string; // cr
    col_8?: number; // aov
}

interface filters {
    website: string[],
    productPage: string[],
    sourceMedium: string[],
}



export const PerformanceComparisonNewTable = (props: Props) => {
    //hooks
    const [filters, setFilters] = useState<filters>({ website: [], productPage: [], sourceMedium: [] });
    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const currency = useCurrencySign();

    //helpers
    const dataToDataTable = (data: PerformanceTableInterface[]): Data[] => {
        return data.map((row, i) => {
            return ({
                    col_0: row?.traffic_source,
                    col_1: row?.source,
                    col_2: row?.medium,
                    col_3: row?.sessions,
                    col_4: row?.users,
                    col_5: row?.transactions,
                    col_6: !!row?.revenue ? parseInt(row?.revenue.toFixed(2)) : 0,
                    col_7: !!row?.cr ? row?.cr.toFixed(2) +"%" : 0+"%",
                    col_8: !!row?.aov ? parseFloat(row?.aov.toFixed(2)) : 0,
            });
        });
    };

    const headersToColumns = (headers: string[],): Column<Data>[] => {
        return headers.map((header, index) => {
            if (header === "id") {
                return {
                    Header: header, accessor: 'col_' + index.toString(), sortType: 'basic', show: false,
                } as Column<Data>;
            }

            return {
                Header: header, accessor: 'col_' + index.toString(), sortType: 'basic',
                Footer: info => {
                    const totalColumn = useMemo(
                        () => {
                            let sum = 0;
                            for (const row of info.rows) {
                                const value = row.values[info?.column?.id];
                                if (typeof value === 'number') {
                                    sum += value;
                                }
                            }
                            return !sum ? '-' : sum;
                        },
                        [info.rows]
                    );
                    if(info?.column?.Header === "Revenue"){
                        return <>{typeof totalColumn === "number" ? currency + totalColumn?.toLocaleString() : totalColumn}</>
                    }
                    return <>{typeof totalColumn === "number" ? totalColumn?.toLocaleString() : totalColumn}</>
                }
            } as Column<Data>;
        });
    };

    const data = useMemo<Data[]>(() => dataToDataTable(props?.data), [props?.data]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props?.headers), [props?.headers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
        allColumns
    } = useTable({
        columns, data
    }, useSortBy, useRowSelect);

    filters.website = selectedRowTab0.map((item) => (item.cells[0].value));


    const filterByRow = (row: Row<Data>) => {
            const isRowSelected = selectedRowTab0.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab0([...(selectedRowTab0.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab0];
                newSelected.push(row);
                setSelectedRowTab0(newSelected);
            }
    };

    const tabButtonsContainer = () => {
        return (
            <styles.TabButtonsContainer>
                <styles.TabButton {...props.selectedTab === 0 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(0);
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('SOURCE_MEDIUM', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters?.website.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 0 ? { selected: true } : { selected: false }}>
                                {(filters?.website.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
            </styles.TabButtonsContainer >
        );
    };

    const renderTable = () => {
        return (<styles.StyledTable  {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <styles.TrCustom
                        {...{ ...headerGroup.getHeaderGroupProps() }}>
                        {
                            headerGroup.headers.map(column => {
                                return (
                                    <styles.styledTh style={{ display: "flex", }} {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                                        <styles.StyledHeaderWrapper>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <GoTriangleUp />
                                                    : <GoTriangleDown />
                                                : null}
                                        </styles.StyledHeaderWrapper>
                                    </styles.styledTh>
                                );
                            }
                            )}
                    </styles.TrCustom>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab0.some((element) => element.id === row.id) || selectedRowTab0.length === 0,
                                    ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}>
                                    {row.cells.map(cell => {
                                        if(cell?.value.length > 30){
                                            return (
                                                <styles.styledTd {...cell.getCellProps()}>
                                                    <Tooltip label={cell?.value}>
                                                        {cell.value.slice(0,30)+"..."}
                                                    </Tooltip>
                                                </styles.styledTd>
                                            )
                                        }
                                        if(cell?.column?.Header === "Revenue"){
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? currency + cell?.value?.toLocaleString() : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {typeof cell?.value === "number" ? cell?.value?.toLocaleString() : cell?.value}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            )})}
            </tbody>
            <tfoot>
                {footerGroups.map(group => (
                    <styles.footerTr style={{ position: "sticky", bottom: 0 }} {...group.getFooterGroupProps()}>
                        {group.headers.map(column => {
                            if (column.id === "col_0") {
                                return (<styles.StyledTotal  {...column.getFooterProps()}>
                                    {t('GRAND_TOTAL', { ns: 'translation' })}</styles.StyledTotal>);
                            }

                            return (< styles.FooterTd  {...column.getFooterProps()}>{column.render('Footer')}</styles.FooterTd>);
                        })}
                    </styles.footerTr>
                ))}
            </tfoot>

        </styles.StyledTable >);
    };

    return (<>
        {tabButtonsContainer()}
        <styles.Styles>
            <styles.TableScroll>
                {renderTable()}
            </styles.TableScroll>
        </styles.Styles>
    </>);
};


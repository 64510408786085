import styles from './styles';

import MenuGenericButton, {MenuGenericProps} from "../menu-generic/MenuGenericButton";
import React, {useEffect} from "react";
import {Box, Icon, PopoverTrigger, useDisclosure} from "@chakra-ui/react";
import {threeDots} from "../../utils/icons/three-dots";


export const ThreeDotMenuButton = (props: MenuGenericProps) => {
    //hooks
    const {onOpen, onClose, isOpen} = useDisclosure();

    //helpers

    //renders

    return <>
                <MenuGenericButton callbackId={props?.callbackId} data={props?.data} size={props?.size} onOpen={onOpen} onClose={onClose} isOpen={isOpen} trigger= {
                    (
                        <styles.ThreeDotsMenuButton size={props?.size}>
                            <Icon as={threeDots} color={'grey'}/>
                            </styles.ThreeDotsMenuButton>
                    )} />


    </>
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { IntegrationPlatform, IntegrationType } from "@models";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {addIntegration, closeAccountsModal, organizationSelector} from "@store";
import { CloseModalIcon } from "../../../../utils/icons/save-modal";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {RiCheckboxCircleLine} from "react-icons/ri";

interface Props {
  isOpen?: boolean;
  onOpen: any;
  onClose: any;
  /*object: any;*/
  /*accounts: any[] | undefined;*/
}

enum ModalType {
    FACEBOOK,
    GOOGLE
}

const GoogleAdsAccountsModal = (props: Props) => {
    const dispatch = useDispatch();
    const [allAccounts, setAllAccounts] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const organization = useSelector(organizationSelector);
    const [modalType, setModalType] = useState<ModalType>()

    const [object, setObject] = useState<any>({})

    useEffect(() => {
        if(!organization?.isOpenAccountsModal){
            setAllAccounts([])
        }
        if(!!organization && !!organization?.isOpenAccountsModal){

            if(!!organization?.googleAdsAccounts?.accounts && !!organization?.googleAdsAccounts?.object){
                setObject(organization?.googleAdsAccounts?.object);
                const googleAccountsNotSelected = organization?.googleAdsAccounts?.accounts?.map((account) => {
                    return {...account, isActive: false}
                })
                setAllAccounts(googleAccountsNotSelected);
                setModalType(ModalType.GOOGLE)
            }

            if(!!organization?.facebookAdsAccounts?.accounts && !!organization?.facebookAdsAccounts?.object){
                setObject(organization?.facebookAdsAccounts?.object);
                const facebookAccountsNotSelected = organization?.facebookAdsAccounts?.accounts?.map((account) => {
                    return {...account, isActive: false}
                })
                setAllAccounts(facebookAccountsNotSelected);
                setModalType(ModalType.FACEBOOK)
                /*const copyOfIntegration = JSON.parse(JSON.stringify(organization?.facebookAdsAccounts?.integration));
                const facebookAccounts: any = organization?.facebookAdsAccounts?.accounts;
                copyOfIntegration['facebookAdsAccounts'] = Object.values(facebookAccounts);*/
            }

        }
    }, [organization?.googleAdsAccounts, organization?.facebookAdsAccounts, organization?.isOpenAccountsModal])


    const handleClickOnRow = (id?: number) => {
        setAllAccounts(prev => {
            return prev.map(account => {
               if(typeof account?.id === 'string'){
                   if (account.id === id?.toString()) {
                       return {
                           ...account,
                           isActive: !account.isActive
                       }
                   }

               }
                if (account.id === id) {
                    return {
                        ...account,
                        isActive: !account.isActive
                    }
                }
                return account
            })
        })
    }
    
    const getIntegrationName = (platform: IntegrationPlatform) :string => {
        switch (platform) {
          case IntegrationPlatform.FACEBOOK_ADS:
            return "Facebook Ads";
          case IntegrationPlatform.GOOGLE_ADS:
              return 'Google Ads'
            default:
                return ''
        }
    }
    
    const handleSubmit = async () => {
        setLoading(true);
        try{
            allAccounts?.forEach((account) => {
                let integrationPlatform = IntegrationPlatform.GOOGLE_ADS;
                let accountName = account?.descriptiveName;

                if('authResponse' in object){
                    integrationPlatform = IntegrationPlatform.FACEBOOK_ADS;
                    accountName = account?.name;
                }


                if(account?.isActive){
                    const integration = {
                            name: getIntegrationName(integrationPlatform) + ` ${!!accountName ? accountName : ''}`,
                            type: IntegrationType.MARKETING,
                            platform: 'authResponse' in object ? IntegrationPlatform.FACEBOOK_ADS : IntegrationPlatform.GOOGLE_ADS,
                            object: object,
                            accountId: account?.id,

                        }
                        if('authResponse' in object){
                            integration['facebookAdsAccounts'] = [account]
                        }else{
                            integration['googleAdsAccounts'] = account
                        }
                    dispatch(addIntegration({
                        integration: integration,
                        organizationId: organization?.id
                    }));
                }
            })
        }
        catch (e) {
            
        }
        finally {
            setLoading(false);
            dispatch(closeAccountsModal());

        }
    }

    const renderRow = ({name, id, isActive, key}: {name?: string, id?:number, isActive?: boolean, key: number}) => {
       return (<styles.ChannelItem key={key} onClick={() => {
           handleClickOnRow(id);
        }}>
            <Box w={2}/>
            {isActive ? <RiCheckboxCircleLine size={23} color='#7031EB'/>
                : <IoIosCloseCircleOutline size={23} color="#BAC0CE"/>}
            {name}
        </styles.ChannelItem>)
    }

    return (
      <Modal isOpen={!!props?.isOpen} onClose={props?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
              <Flex justifyContent={'space-between'}>
                Select the accounts to connect:
                  <Box  onClick={() => {
                      dispatch(closeAccountsModal());
                  }}>
                    <CloseModalIcon />
                  </Box>

              </Flex>
          </ModalHeader>
          <ModalBody>
            {allAccounts?.map((item, i) => {
                let renderFacebookName = ""
                let googleAdsName = ""
                if(modalType === ModalType.FACEBOOK){
                    if(item?.name === item?.account_id){
                        renderFacebookName = item?.name;
                    }
                   else {
                        renderFacebookName = `${item?.name} (${item?.account_id})`;
                    }
                }
                if(modalType === ModalType.GOOGLE){
                    if(!!item?.descriptiveName){
                        googleAdsName = `${item?.descriptiveName} (${item?.id})`
                    }
                    else {
                        googleAdsName = item?.id
                    }
                }
              return renderRow({
                name: googleAdsName || renderFacebookName,
                id: item?.id,
                isActive: item?.isActive,
                key: i
              });
            })}
          </ModalBody>

          <ModalFooter>
            <styles.StyledButton active={allAccounts?.some((account) => account?.isActive)} disabled={!(allAccounts?.some((account) => account?.isActive))} onClick={handleSubmit}>{loading || organization?.isLoadingAccountModal ? <Spinner />: 'Apply'}</styles.StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};

export default GoogleAdsAccountsModal;

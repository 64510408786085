// @ts-ignore
import {getDatesInRange} from "../colors";

interface DataType {
    [key: string]: any;
}

export const calculateTotalForExplore = (arr: DataType[] | undefined, key?: string,
                                         selectedChannels?: {original?: {callbackId?: string}}[],
                                         selectedProducts?: {original?: {callbackId?: string}}[],
                                         chartSelectedDate?: {startDate?: Date, endDate?: Date}
                                         ): number => {
    if (!Array.isArray(arr) || typeof key !== 'string') {
        return 0;
    }


    if(!!selectedChannels && selectedChannels?.length > 0){
        const mappedSelectedChannels = selectedChannels?.map((item) => item?.original?.callbackId);
        arr = arr?.filter((kpiItem) => {
            return mappedSelectedChannels?.includes(kpiItem?.wvChannelId)
        })

    }

    if(!!selectedProducts && selectedProducts?.length > 0){
        const mappedSelectedProducts = selectedProducts?.map((item) => item?.original?.callbackId);
        arr = arr?.filter((kpiItem) => {
            return mappedSelectedProducts?.includes(kpiItem?.wvProductId)
        })

    }

    if(!!chartSelectedDate?.startDate && !!chartSelectedDate?.endDate){
        const allDatesInRangeOfSelect = getDatesInRange(chartSelectedDate?.startDate, chartSelectedDate?.endDate);
        arr = arr?.filter((kpiItem) => {
            return allDatesInRangeOfSelect?.includes(kpiItem?.activityDate?.slice(0,10))
        })
    }

    const getSumForKey = (key?: string) => {
        if(!!arr && !!key){
            let sum = 0;
            for (const obj of arr) {

                if (key in obj) {
                    const value = obj[key];
                    if (typeof value === 'number' && !isNaN(value)) {
                        sum += value;
                    }
                }

            }
            return sum
        }
        return 0
    }

    const customMetrics = ['aov', 'cpo', 'blendedRoas'];

    if(key === 'aov'){
        const totalGrossRevenue = getSumForKey('grossRevenue');
        const totalOrders = getSumForKey('orders');
        if(!!totalGrossRevenue && !!totalOrders){
            return totalGrossRevenue / totalOrders
        }
        else{
            return 0
        }
    }

   return getSumForKey(key)
}

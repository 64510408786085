import {createAsyncThunk} from "@reduxjs/toolkit";
import {CreatePnlDto, getPnlDataLanguage, organizationService, pnlService} from "@services";
import {DataLanguage, triggerAirflowDailyStats} from "@store";
import {kpiService} from "../../services/kpi/kpi.service";
import {isHttpCodeSuccess} from "../../utils/network/helpers";
import {Metric} from "../../models/pnl-language";
import {resetMoveLoader} from "./actions";
import {addToasts} from "../ui";
import {Toast, ToastStatus} from "../../components/toast/Toast";

// export const fetchFinancialData = createAsyncThunk<any, any>(
//   "financial/fetchFinancialData",
//   async (payload, thunkAPI) => {
//     const response = getFinancialData();
//     return response;
//   }
// );


// export const fetchPnlDataLanguage = createAsyncThunk<any, { organizationId: string }>(
//   "financial/fetchPnlDataLanguage",
//   async ({ organizationId }: {organizationId: string}, thunkAPI) => {
//     const response = await pnlService.fetchPnl(organizationId);
//     return response;
//   }
// );


export const fetchPnlDataLanguage = createAsyncThunk<
    any,
    {  organizationId: string }
    >(
    "pnl/fetchPnlData",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.fetchPnl(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const fetchDraftPnlDataLanguage = createAsyncThunk<
    any,
    {  organizationId: string }
    >(
    "pnl/fetchDraftPnlData",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.fetchDraftPnl(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const fetchPnlDataLanguageNames = createAsyncThunk<
    any,
    {  organizationId: string }
    >(
    "pnl/fetchPnlDataNames",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.fetchPnlNames(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const fetchFields = createAsyncThunk<
    any,
    {  organizationId: string }
    >(
    "pnl/fetchFields",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.fetchFields(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data?.data
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const fetchPnlsDisplayName = createAsyncThunk<
    any,
    {  organizationId: string }
    >(
    "pnl/fetchPnlDataDisplayNames",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.fetchMetricsDisplayNames(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const createPnl = createAsyncThunk<
    any,
    {  organizationId: string, dto: Metric }
    >(
    "pnl/createPnlData",
    async ({  organizationId, dto }, thunkAPI) => {
        try {
            const response = await pnlService.createPnl(organizationId, dto);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data[0]
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const moveMetric = createAsyncThunk<
    any,
    {  organizationId: string, dto: {metricName: string, bucketName?: string}[] }
    >(
    "pnl/moveMetrics",
    async ({  organizationId, dto }, thunkAPI) => {
        try {
            const response = await pnlService.moveMetrics(organizationId, dto);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
        finally {
            thunkAPI.dispatch(resetMoveLoader())
        }
    }
);




export const deletePnl = createAsyncThunk<
    any,
    {  organizationId: string, id: string }
    >(
    "pnl/deletePnl",
    async ({  organizationId, id }, thunkAPI) => {
        try {
            const response = await pnlService.deletePnl(organizationId, id);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const editPnl = createAsyncThunk<
    any,
    {  organizationId: string, id: string, dto: Metric }
    >(
    "pnl/editPnl",
    async ({  organizationId, id, dto }, thunkAPI) => {
        try {
            const response = await pnlService.editPnl(organizationId, id, dto);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const applyDraftPnls = createAsyncThunk<
    any,
    {  organizationId: string,  }
    >(
    "pnl/applyDraftPnl",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.applyDraftPnl(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(fetchPnlDataLanguageNames({organizationId: organizationId}))
                thunkAPI.dispatch(fetchDraftPnlDataLanguage({organizationId: organizationId}))
                if(response?.data?.airflow){
                    thunkAPI.dispatch(triggerAirflowDailyStats({organizationId: organizationId}))
                }
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Changes in progress',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))

                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const discardDraftPnl = createAsyncThunk<
    any,
    {  organizationId: string,  }
    >(
    "pnl/applyDraftPnl",
    async ({  organizationId }, thunkAPI) => {
        try {
            const response = await pnlService.discardDraftPnl(organizationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);






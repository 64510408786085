import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DepartmentsEnum} from "@services";
import {fetchKpiData, fetchLastPeriod, setExploreLoading} from "../../store/kpis";
import {organizationSelector} from "@store";
import {dateRangeSelector} from "../../store/ui";
import {getListOfMetrics} from "../get-metric-list/getMetricList";
import {pnlDataLanguageSelector} from "../../store/pnl";

function useFetchLastPeriod(metricToFetch?: string) {
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const datesSelector = useSelector(dateRangeSelector);
    const pnls = useSelector(pnlDataLanguageSelector)?.data;

    const formatDate = (date: Date) => {
      /*  const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
        const day = String(date.getDate()).padStart(2, '0');

        // Format the date as 'YYYY-MM-DD'
        return `${year}-${month}-${day}`;*/
        return date?.toISOString();

    }


    useEffect(() => {
        dispatch(setExploreLoading({isLoading: true}));

        setTimeout(() => {

            if (organization?.id && datesSelector?.startDate && datesSelector?.endDate && !!metricToFetch) {

                let fromDate = new Date(`${datesSelector.startDate}T00:00:00.000Z`);
                let toDate = new Date(`${datesSelector.endDate}T23:59:59.999Z`);

                const difference = toDate.getTime() - fromDate.getTime();
                const lastPeriodStart = new Date(fromDate.getTime() - difference).toISOString().slice(0, 10);
                const lastPeriodEnd = new Date(toDate.getTime() - difference - 1).toISOString().slice(0, 10);

                dispatch(
                    fetchLastPeriod({
                        organizationId: organization?.id,
                        fromDate: lastPeriodStart,
                        toDate: lastPeriodEnd,
                        kpisType: DepartmentsEnum.COMPANY,
                        fields: getListOfMetrics(metricToFetch, pnls),
                        type: 0
                    })
                );
            }
        }, 1000);
    }, [organization?.id, datesSelector?.startDate, datesSelector?.endDate, dispatch, metricToFetch]);

}

export default useFetchLastPeriod;

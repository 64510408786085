import styled from "styled-components";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    //background-color: ${(props) => props.theme.backgroundColor};
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    border-radius: 12px 0 0 0;
  `;

  export const CustomSpacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
  `;

  export const MenuItemStyled = styled(MenuItem)<{ bold?: boolean }>`
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
    height: 45px;
    border-bottom: 1px solid #eeeeee;
    max-width: 550px;
    &:last-of-type {
      border-bottom: 0;
    }
    font-family: ${(props) => props.theme.fonts.menu};
  `;
  export const FlagHolder = styled.div`
    max-width: 30px;
    margin: 0 7px 0 0;
    padding-top: 0;
    padding-bottom: 0;
  `;

  export const MenuItemDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: black;
  `;

  export const menuTitle = styled.div`
    margin: 0 0 0 12.8px;
  `;

  export const SelectButton = styled(MenuButton)<{ bold?: boolean }>`
    height: 50px;
    border: 1px solid gray !important;
    width: 100%;
    border-radius: 15px !important;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.menu};
    span {
      display: flex;
      justify-content: start;
      padding: 0px 15px;
    }
  `;
  export const StyledMenu = styled(Menu)`
    max-width: 550px;
    font-family: ${(props) => props.theme.fonts.menu};
  `;

  export const SelectMenuList = styled(MenuList)`
    height: 100%;

    max-height: 200px;
    overflow-y: auto;
    width: 550px;
    font-family: ${(props) => props.theme.fonts.menu};
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
  `;
}

export default styles;

import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {AmazonState} from "./state";

// sign-up state
export const amazonStateSelector = (state: RootState): AmazonState => state.amazon;

// code
export const codeSelector = createSelector(amazonStateSelector, (state) => state.code);

// sign-up error
export const amazonErrorSelector = createSelector(amazonStateSelector, (state) => state.error);

// loading
export const amazonLoadingSelector = createSelector(amazonStateSelector, (state) => state.loading);

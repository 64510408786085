import { Accordion, AccordionPanel } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 320px;
    height: auto;
    border-radius: 0 0 10px 10px;
    background-color: white;
  `;
  export const Panel = styled(AccordionPanel)`
    max-height: 350px;
    padding: 0;
    overflow-y: hidden;
    &:hover {
      overflow-y: scroll;
    }
  `;
  export const TextContainer = styled.div`
  font-size: 16px;
    color: white;
  `
  export const TotalBottom = styled.div`
    width: 100%;
    height: 60px;
    background-color: #fbf7ff;
    display: flex;
    align-items: center;
  `;
  export const Star = styled.div`
    height: 32px;
    width: 32px;
    background-color: #eee3fb;
    border-radius: 50%;
    margin: 0 0 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  export const BottomText = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 8px;
  `;
  export const BottomTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #7031eb;
  `;
  export const BottomSubtitle = styled.div`
    font-size: 11px;
    color: #7a86a1;
  `;
  export const Spacer = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e8e8e8;
    margin: 17px 0 0 0;
  `;
  export const SpacerBottom = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e8e8e8;
  `;
  export const ButtonWrapper = styled.div`
    display: flex;
  `;
  export const BlueTop = styled.div`
    background-color: #7031eb;
    height: 12px;
    margin: 0;
    border-radius: 10px 10px 0 0;
  `;
  export const StyledAccordion = styled(Accordion)`
    &:focus {
      box-shadow: none;
    }
  `;

  export const Title = styled.div`
    font-family: ${(props) => props.theme.fonts.header};
    font-weight: bold;
    width: 90%;
    align-self: flex-start;
    text-align: left;
    margin: 15px auto 0;
  `;

  export const StyledToDoItem = styled.div`
    width: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    min-height: 89px;
    border: 1px solid #70707038;
    border-radius: 10px;
    justify-content: space-between;
  `;
  export const StyledButton = styled.div`
    &:focus {
      box-shadow: none !important;
    }
    border-bottom: 0;
    border-radius: "0 0 10px 10px";
    padding: 0%;
    flex-direction: column;
  `;
  export const Circle = styled.div<{ status: boolean }>`
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 2px solid #cccccc;
    background-color: ${(props) => (props.status ? "#7031EB" : null)};
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  export const ToDoRow = styled.div`
    width: 95%;
    margin: 15px 0 0 9px;
    display: flex;
    align-items: center;
  `;
  export const ToDoButton = styled.div`
    width: 88px;
    border-radius: 20px;
    height: 28px;
    background-color: #f5f0fe;
    color: #1a0989;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin: 0 8.5px 10px 0;
  `;
  export const ToDoText = styled.div<{ status: boolean }>`
    margin: 0 0 0 8px;
    text-decoration: ${(props) => (props.status ? "line-through" : null)};
    color: ${(props) => (props.status ? "#A9A9A9" : "black")};
  `;
}

export default styles;

import { createAction } from "@reduxjs/toolkit";
import { CurrencyCode } from "./state";

export const clearOrganizationState = createAction("organization/clearState");

export const changeCurrency = createAction<{ currency: CurrencyCode }>(
  "organization/changeCurrency"
);
export const createMarketingMappingElementLocal = createAction<{ data: any }>(
  "organization/dataLanguageMarketingMappingLocal"
);
export const newMarketingMapping = createAction<{ data: any }>(
  "organization/newMarketingMappingLocal"
);

export const setDisplayCurrency = createAction<{chosenCurrency: CurrencyCode}>(
    "organization/setDisplayCurrency"
)
export const displayUpdateButton = createAction(
    "organization/setDisplayUpdateButton"
)

export const closeAccountsModal = createAction(
    "organization/closeAccountsModal"
)

export const removePnlElementFromData = createAction<{name: string}>(
    "dataLanguage/removePnlElement"
)

export const cleanSavedIntegration = createAction("organization/cleanSavedIntegrationLoader")

export const cleanUpPnlLoaders = createAction('pnl/CleanUpLoaders');

export const cleanUpGeneralSettingsLoader = createAction('generalSettings/CleanUpLoaders');
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Bottom,
    Card,
    Content,
    Grid,
    Item,
    ItemIcon,
    LoadMorePlatformsButton,
    NextButton,
    PrimaryTitle,
    SecondaryTitle,
    SkipButton,
    Text,
    Wrapper,
    ItemHolder
} from "./styles";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { getAmazonSellerRefreshToken } from "../../../store/amazon-seller";
import { addIntegration, organizationSelector } from "@store";
import { IntegrationPlatform, IntegrationType } from "@models";
import OAuthPopup, { IPopupProps } from "../../../components/o-auth-popup/OAuthPopup";
import { amazonSellerUri, defaultShopifyShopName, fetchShopifyOAuthUrlUrl } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { facebookAdsAuthenticate } from "../../../store/facebook";
import React, { useState } from "react";
import { ShopifyExtraDetails } from "./ShopifyExtraDetails";
import { useNavigate } from "react-router";
import { ShopifyNameDialog } from "dialogs/shopify-name-dialog/ShopifyNameDialog";
import {getAmazonAdsRefreshToken} from "../../../store/amazon-ads";


export type IIntegrationProps = IPopupProps & {
    isOAuthPopup: boolean;
    extraDetailsComponent?: any;
};


export const FirstIntegration = () => {

    const back = () => {
        setCurrentStep(1);
    };

    // integrations props
    const integrations: IIntegrationProps[] = [
        {
            url: ``,
            /* url: `${fetchShopifyOAuthUrlUrl}?shop=${defaultShopifyShopName}`, */
            title: 'Shopify',
            platform: IntegrationPlatform?.SHOPIFY,
            width: 800,
            height: 1000,
            onClose: () => console.log('shopify'),

            onResponse: (params?: URLSearchParams) => sendShopifyOAuthFromSearchParams(params!),
            children: null,
            imageUrl: 'https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png',
            isOAuthPopup: true,
            /*  extraDetailsComponent: () => <RenderShopifyNameDialog /> */
            extraDetailsComponent: <ShopifyExtraDetails back={back} />
        },
        {
            url: amazonSellerUri,
            title: 'Amazon Seller',
            platform: IntegrationPlatform?.AMAZON_SELLER,
            width: 800,
            height: 1000,
            onClose: () => console.log('Amazon Seller'),
            onResponse: (params?: URLSearchParams) => getAmazonSellerRefreshTokenFromSearchParams(params!),
            children: null,
            imageUrl: 'https://play-lh.googleusercontent.com/rMV2x9_l1iwF1YL-7oe1VerLHz8TgQBt7VwPRg-4JuVw0cQ5xqgYkPefb0EQeUUdOSbi',
            isOAuthPopup: true
        },
        /* {
            url: ``,
            title: 'Facebook Ads',
            platform: IntegrationPlatform?.FACEBOOK_ADS,
            width: 800,
            height: 1000,
            onClose: () => console.log('Facebook Ads'),
            onResponse: (params?: URLSearchParams) => facebookAdsOAuthAuthentication(),
            children: null,
            imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/768px-Facebook_icon_2013.svg.png',
            isOAuthPopup: false
        },
        {
            url: ``,
            title: 'Amazon Ads',
            platform: IntegrationPlatform?.AMAZON_ADS,
            width: 800,
            height: 1000,
            onClose: () => console.log('Amazon Ads'),
            onResponse: (params?: URLSearchParams) => amazonAdsOAuthAuthentication(),
            children: null,
            imageUrl: 'https://assets.website-files.com/6064b31ff49a2d31e0493af1/612ffb66e42653f9d9422da1_amazonads.png',
            isOAuthPopup: false
        },
        {
            url: ``,
            title: 'Google Ads',
            platform: IntegrationPlatform?.GOOGLE_ADS,
            width: 800,
            height: 1000,
            onClose: () => console.log('Google Ads'),
            onResponse: (params?: URLSearchParams) => sendGoogleAdsOAuthFromSearchParams(params!),
            children: null,
            imageUrl: 'https://play-lh.googleusercontent.com/gDLgYeTaZUTQXa9hu0f4OgxAJedWvpcz3puIj3rgWwYhUjsruRfmKWheF1Wm-oVvPgDe=s360-rw',
            isOAuthPopup: true
        } */
    ];


    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const [selectedPlatform, setSelectedPlatform] = useState({} as (IIntegrationProps));
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();


    // selectors

    // effects

    // helpers
    const requestIntegration = () => {
        console.log('request integration');
    };

    const skip = () => {
        navigate('/onboarding/first-invitation');
    };

    // integrations
    const getAmazonSellerRefreshTokenFromSearchParams = (params: URLSearchParams) => {
        const spApiOAuthCode = params.get('spapi_oauth_code');
        const state = params.get('state');
        const sellingPartnerId = params.get('selling_partner_id');
        if (!!spApiOAuthCode && !!state && !!sellingPartnerId) {
            dispatch(getAmazonSellerRefreshToken({
                code: spApiOAuthCode,
                state: state,
                sellingPartnerId: sellingPartnerId
            }));
        }
    };

    const sendShopifyOAuthFromSearchParams = (params: URLSearchParams) => {
        const shopifyOAuthData = params.get('result');
        try {
            if (!!shopifyOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.STORES,
                        platform: IntegrationPlatform.SHOPIFY,
                        object: JSON.parse(shopifyOAuthData),
                    },
                    organizationId: organization?.id,
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sendGoogleAdsOAuthFromSearchParams = (params: URLSearchParams) => {
        // @ts-ignore
        const googleAdsOAuthData = params.get('result');
        try {
            if (!!googleAdsOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.MARKETING,
                        platform: IntegrationPlatform.GOOGLE_ADS,
                        object: JSON.parse(googleAdsOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const facebookAdsOAuthAuthentication = () => {
        dispatch(facebookAdsAuthenticate({}));
    };

    /*const amazonAdsOAuthAuthentication = () => {
        dispatch(getAmazonAdsRefreshToken({}));
    };*/

    // renders
    const renderRequestIntegrationLink = () => {
        return <Flex marginTop={2}>
            <a onClick={() => requestIntegration()} href={void (0)}>
                {`${t('REQUEST_AN_INTEGRATION', { ns: 'translation' })}`}
            </a>
        </Flex>;

    };

    const renderLoadMoreButton = () => {
        return <LoadMorePlatformsButton>
            {t('LOAD_MORE_PLATFORMS', { ns: 'translation' })}
        </LoadMorePlatformsButton>;
    };

    const renderConnectButton = () => {
        const selectedIntegration = selectedPlatform;
        if (selectedIntegration?.isOAuthPopup && !selectedIntegration?.extraDetailsComponent) {
            return <OAuthPopup
                title={selectedIntegration?.title}
                height={800}
                width={1000}
                url={selectedIntegration?.url ?? ''}
                onResponse={
                    (params) => {
                        selectedIntegration?.onResponse(params);
                    }
                }
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <NextButton disabled={!selectedIntegration?.platform}>
                    {t('CONNECT', { ns: 'translation' })}
                </NextButton>
            </OAuthPopup>;
        } else {
            return <NextButton onClick={() => {
                if (!!selectedIntegration?.extraDetailsComponent) {
                    setCurrentStep(2);
                } else if (!!selectedIntegration?.onResponse) {
                    selectedIntegration?.onResponse();
                }
            }} disabled={!selectedIntegration?.platform}>
                {t('CONNECT', { ns: 'translation' })}
            </NextButton>;
        }

    };

    const renderBottom = () => {
        return (
            <Bottom>
                <SkipButton onClick={() => {
                    skip();
                }}>
                    {t('SKIP', { ns: 'translation' })}
                </SkipButton>
                {renderConnectButton()}
            </Bottom>
        );
    };

    const renderConnectorsGrid = () => {
        return (
            <>
                <Grid>
                    {integrations?.map((integration, index) => {
                        return <ItemHolder>
                            <Item key={index}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedPlatform(integration);
                                }}
                                selected={selectedPlatform?.platform === integration?.platform}>
                                <ItemIcon
                                    src={integration?.imageUrl}
                                    alt={integration?.title} />

                            </Item>
                            {integration?.title}
                        </ItemHolder>;

                    })}
                </Grid>
            </>
        );
    };



    const renderCard = () => {
        return (
            <Card>
                <Text>

                    <PrimaryTitle>
                        {`${t('CHOOSE_YOUR_SHOP_PLATFORM', { ns: 'translation' })}`}
                    </PrimaryTitle>
                    <SecondaryTitle>
                        {`${t('LETS_GET_STARTED_WITH_YOUR_FIRST_STORE', { ns: 'translation' })}`}
                    </SecondaryTitle>
                </Text>
                {/*<ConnectorsPopup/>*/}
                {renderConnectorsGrid()}
                {/* {renderLoadMoreButton()} */}
                {/* {renderRequestIntegrationLink()} */}
                {renderBottom()}
            </Card>
        );
    };

    return (
        <Wrapper img={process.env.PUBLIC_URL + '/assets/onboarding-background.png'}>

            <Content>
                {currentStep === 1 ? renderCard() : selectedPlatform?.extraDetailsComponent}
            </Content>
        </Wrapper>
    );
};

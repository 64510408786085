import React, {useEffect, useState} from 'react';
import {DragSourceMonitor, useDrag, useDrop} from 'react-dnd';
import { ItemTypes } from './itemTypes';
import styles from "./styles";
import {
    Box,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, Text, Tooltip, useDisclosure, useTheme
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {
    deletePnl, moveMetric,
    pnlDataLanguageDisplayNamesSelector, pnlDataLanguageDraftSelector,
    pnlDataLanguageNamesSelector,
    pnlDataLanguageSelector
} from "../../store/pnl";
import {organizationSelector} from "@store";
import {openApplyBar} from "../../store/ui";
import {FiTrash} from "react-icons/fi";
import {BsFillPencilFill} from "react-icons/bs";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import InformationWrapper from "../InformationWrapper/InformationWrapper";

export interface CardProps {
    id?: string;
    text?: string;
    index?: number;
    cardId?: string;
    type?: string;
    moveCard?: (dragIndex: number, hoverIndex: number) => void;
    fieldName?: string;
    isDefault?: boolean;
    currentContainer?: string;
    handleSetCards: (item: { id: string; index: number }, containerId: string) => any
    callback?: () => any
    setMetricIdToEdit: (id: string) => void;
}


const Card: React.FC<CardProps> = ({ id, text, index, type
                                       , moveCard, cardId, isDefault, currentContainer, handleSetCards, callback, setMetricIdToEdit }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const dispatch = useDispatch();
    const organizationId = useSelector(organizationSelector)?.id
    const allPnls = useSelector(pnlDataLanguageDraftSelector)?.data;
    const allPnlDisplayNames = useSelector(pnlDataLanguageDisplayNamesSelector)?.data;
    const organization = useSelector(organizationSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const theme = useTheme();
    const [{ isDragging }, drag] = useDrag({
        canDrag: isDefault ? false : undefined,
        /*canDrag: false,*/
        end<DragObject, DropResult>(draggedItem: DragObject, monitor: DragSourceMonitor<DragObject, DropResult>): void {
        },
        isDragging<DragObject, DropResult>(monitor: DragSourceMonitor<DragObject, DropResult>): boolean {
            return false;
        },
        options: undefined,
        previewOptions: undefined,
        type: type!,
        item: { type: type, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    });

    const [, drop] = useDrop({
        accept: type!,
        hover(item: { id: string; index: number }, monitor) {
            if (!ref.current) {
                return;
            }
            if(index !== undefined){
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
                if(!!moveCard){
                    moveCard(dragIndex, hoverIndex);
                }
                item.index = hoverIndex;
            }

        },
    });

    drag(drop(ref));

    // if user clicked outside of card close menu
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleClick = () => {

        setIsMenuVisible(!isMenuVisible);
    };

    const handleOutsideClick = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsMenuVisible(false);
        }
    };

    const moveCardToBank = () => {
        if(!!id && index !== undefined){
            const cardData = {type, id, index}
            handleSetCards(cardData, 'bank')
        }

    }

    const handleClickOnRemove = () => {
        if(currentContainer === 'bank'){
            onOpen()
        }
        else {
            if(!isDefault){
                const foundMetric = allPnls?.find((metric) => metric?.fieldName === id);
                if(!!organization?.id && !!foundMetric?.fieldName){
                    // move card to bank
                    dispatch(moveMetric({organizationId: organization?.id, dto: [{bucketName: '', metricName: foundMetric?.fieldName}]}))
                }

                moveCardToBank()
            }
        }
    }

    const handleRemoveCard = () => {
        if(!!organizationId && !!cardId && !isDefault){
            dispatch(deletePnl({organizationId:organizationId, id: cardId}));
            dispatch(openApplyBar({isApplyBarOpen: true}));
        }
    }

    const renderMenu = () => {
       return (
           <styles.MenuWrapper>
               <Flex>
                   <styles.IconHolder onClick={() => {
                       if(!!cardId){
                           setMetricIdToEdit(cardId)
                       }
                   }}>

                       <BsFillPencilFill size={'15px'} color={'#777'}/>
                   </styles.IconHolder>
                   <styles.IconHolder onClick={handleClickOnRemove}>
                       <FiTrash size={'15px'} color={'#777'} />
                   </styles.IconHolder>
               </Flex>

        </styles.MenuWrapper>)
    }

    const renderCustomToolTip = () => {
        return (
                <Popover placement={'top'} trigger={"hover"}>
                    <PopoverTrigger>
                        <styles.CogsTicket>
                            Custom
                        </styles.CogsTicket>
                    </PopoverTrigger>
                    <PopoverContent   maxW={'max-content'}>
                        <PopoverArrow />
                        <PopoverHeader>
                            <Flex alignItems={"center"}>
                                <Box>{text}</Box>
                                <Box>
                                    <styles.CogsTicket>
                                        Custom
                                    </styles.CogsTicket>
                                </Box>
                            </Flex>
                        </PopoverHeader>
                        <PopoverBody maxW={'max-content'}>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
        )
    }

    const renderBadge = (title?: string, i?: number) => {

        return (
            <Box key={i} maxWidth={'170px'}>
                <Tooltip label={title}>
                    <Flex backgroundColor={theme?.primaryColor}   borderRadius={'18px'} color={'white'} padding={'2px 7px'}>
                        <Text whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>
                            {title}
                        </Text>
                    </Flex>
                </Tooltip>
            </Box>

        )

    }

    const renderBadgesByFieldName = (fieldName: string) => {
        const allCogsPnl = allPnls?.find((pnl) => pnl?.fieldName === fieldName)?.expression?.filter((expression) => !expression?.isOperator);
        const fixedCogsNames = allCogsPnl?.map((pnl) => {
            const foundName = allPnlDisplayNames?.find((name: any) => name?.fieldName === pnl?.operand)?.displayName;
            return foundName
        })

        return (
            <styles.CogsHolder>
                {fixedCogsNames?.map((name: string | undefined, i: number) => {
                    return renderBadge(name, i)
                })}
            </styles.CogsHolder>
        )
    }
    
    const openableExplores = ['Cost of Goods', 'Transaction Fees', 'Digital Marketing Spend'];

    return (
      <>
        <ConfirmModal
          onOpen={onOpen}
          isOpen={isOpen}
          onDiscard={onClose}
          onConfirm={handleRemoveCard}
        />
        <styles.ExpensesCardWrapper
          onMouseEnter={() => {
            setIsMenuVisible(true);
          }}
          onMouseLeave={() => {
            setIsMenuVisible(false);
          }}
          onClick={handleClick}
          isDragging={isDragging}
          ref={ref}
          style={{ opacity: 1 }}
        >
          <styles.CardTitleWrapper>
            <Flex
              w={"100%"}
              gap={"4px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Flex alignItems={"center"}>
                <styles.IconMdDragIndicatorPlaceholder>
                  {/*<BsPinAngle size={"28px"} />*/}
                  <styles.IconMdDragIndicator size={"36px"} />
                </styles.IconMdDragIndicatorPlaceholder>
                <styles.CardTitle>
                  <InformationWrapper text={text} displayLongText />
                </styles.CardTitle>

                {text === "Cost of Goods" &&
                  renderBadgesByFieldName("totalCogs")}
                {text === "Digital Marketing Spend" &&
                  renderBadgesByFieldName("digitalMarketingSpend")}
              </Flex>

              {!!text && openableExplores?.includes(text) && <styles.IconMdOpenInNew onClick={() => {
                  if(!!callback){
                      callback()
                  }
              }}/>}

              {isMenuVisible && !isDefault && renderMenu()}

              {/*{!isDefault && renderCustomToolTip()}*/}
            </Flex>
          </styles.CardTitleWrapper>
        </styles.ExpensesCardWrapper>
      </>
    );
};

export default Card;
import {createSlice} from "@reduxjs/toolkit";
import {initialKpiState, KpiState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {
    cleanGraphData,
    cleanUpCustomTargetsLoaders,
    clearKpiState,
    finishLoadingMarketing,
    resetPnlPageFilers,
    setDailyStats, setExploreLoading,
    setMarketingStats,
    setPnlPageFilters,
    setTargetStats,
} from "./actions";
import {
    createCustomTargets, fetchBigWideviewCardsData,
    fetchBlendedRoasReports,
    fetchBlendedRoasTableConfig, fetchCacTableConfig,
    fetchCpoReports,
    fetchCpoTableConfig,
    fetchCustomerReports,
    fetchCustomTargets, fetchExploreData,
    fetchGraphData,
    fetchKpiData, fetchLastPeriod,
    fetchMarketingKpi,
    fetchMarketingMappingByCampaign,
    fetchPnlReports,
    fetchPnlTableConfig,
    putCustomTargets,
} from "./thunks";
import {PnlReportGroupBy} from "../../services/kpi/kpi.service";
import {setAppLoading, UIState} from "../ui";
import {Kpi} from "../../models/kpi";

function buildCleanKpiStateReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(clearKpiState, (state, action) => {
        state.data = undefined;
        state.financeKpi = undefined;
        state.marketing = undefined;
        state.website = undefined;
        state.targetData = undefined;
        state.summedData = undefined;
        state.kpiRenderLoader = false;
        state.marketingStatsLoading = false;
        state.marketingLoader = false;
        return state;
    });
}

function buildFinishMarketingLoader(
    builder: ActionReducerMapBuilder<KpiState>
) {
    builder.addCase(finishLoadingMarketing, (state, action) => {
        state.marketingLoader = action.payload.marketingLoader;
        return state;
    });
}

function buildSetPnlPageFilter(
    builder: ActionReducerMapBuilder<KpiState>
) {
    builder.addCase(setPnlPageFilters, (state, action) => {
        if (state.pnlPageFilters?.groupBy !== undefined) {
            state.pnlPageFilters.groupBy = action.payload.groupBy;
        }
        return state;
    });
}

function buildResetPnlPageFilter(
    builder: ActionReducerMapBuilder<KpiState>
) {
    builder.addCase(resetPnlPageFilers, (state, action) => {
        if (state.pnlPageFilters?.groupBy !== undefined) {
            state.pnlPageFilters.groupBy = PnlReportGroupBy.DAY;
        }
        return state;
    });
}

function buildCleanCustomTargetsLoaders(
    builder: ActionReducerMapBuilder<KpiState>
) {
    builder.addCase(cleanUpCustomTargetsLoaders, (state, action) => {
        state.customTargetsTable.isSuccess = undefined;
        state.customTargetsTable.isLoading = false;
        return state;
    });
}

function buildSetDailyStats(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(setDailyStats, (state, action) => {
        if (!!state?.summedData) {
            state.summedData.dailyStats = action.payload.dailyStats;
        }
        return state;
    });
}

function buildSetTargetStats(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(setTargetStats, (state, action) => {
        if (!!state?.summedData) {
            state.summedData.targetStats = action.payload.targetStats;
        }
        return state;
    });
}

function buildSetMarketingStats(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(setMarketingStats, (state, action) => {
        if (!!state?.summedData) {
            state.summedData.marketingStats = action.payload.marketingStats;
        }
        return state;
    });
}

function buildCleanGraphData(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(cleanGraphData, (state, action) => {
        state.graphData = undefined;
        return state;
    });
}


function buildFetchkpiDataReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchKpiData.pending, (state, action) => {
            state.loading = true;
            state.marketingLoader = true;
            if (action.meta.arg.kpisType === 0) {
                state.exploreLoading = true;
            }
            return state;
        })
        .addCase(fetchKpiData.fulfilled, (state, action) => {
            if (action.meta.arg.kpisType === 0 && action.meta.arg.type === 1) {
                state.forecast = action.payload;
                return state;
            }
            if (action.meta.arg.kpisType === 0 && action.meta.arg.type === 2) {
                state.targetData = action.payload
                return state;
            }
            if (action.meta.arg.kpisType === 0) {
                state.data = action.payload
                state.loading = false;
                return state;
            }

            if (action.meta.arg.kpisType === 1) {
                state.marketing = action.payload;
                state.marketingLoader = false;
                return state;
            }
            if (action.meta.arg.kpisType === 2) {
                state.financeKpi = action.payload;
                /* state.loading = false; */
                return state;
            }
            if (action.meta.arg.kpisType === 3) {
                state.website = action.payload;
                /* state.loading = false; */
                return state;
            }
            state.data = action.payload;
            /* state.loading = false; */
            return state;
        })
        .addCase(fetchKpiData.rejected, (state, action) => {
            state.loading = false;
            if (action.meta.arg.kpisType === 0) {
            }
            return state;
        });
}

function buildFetchExploreData(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchExploreData.pending, (state, action) => {
            /*state.loading = true;
            state.marketingLoader = true;*/
            if (action.meta.arg.kpisType === 0) {
                state.exploreLoading = true;
            }
            return state;
        })
        .addCase(fetchExploreData.fulfilled, (state, action) => {
            if (action.meta.arg.kpisType === 0) {
                state.exploreData = action.payload
                state.loading = false;
                state.exploreLoading = false;
                return state;
            }

            state.exploreData = action.payload;
            /* state.loading = false; */
            return state;
        })
        .addCase(fetchExploreData.rejected, (state, action) => {
            state.loading = false;
            if (action.meta.arg.kpisType === 0) {
                state.exploreLoading = false;
            }
            return state;
        });
}



function buildFetchLastPeriodDataReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchLastPeriod.pending, (state, action) => {
            //state.loading = true;
            return state;
        })
        .addCase(fetchLastPeriod.fulfilled, (state, action) => {
            state.lastPeriod = action.payload;
            return state;
        })
        .addCase(fetchLastPeriod.rejected, (state, action) => {
            //state.loading = false;
            return state;
        });
}


function buildFetchMarketingKpiReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchMarketingKpi.pending, (state, action) => {
            state.marketingLoader = true;
            return state;
        })
        .addCase(fetchMarketingKpi.fulfilled, (state, action) => {

            state.marketing = action.payload;
            state.marketingLoader = false;
            return state;
        })
        .addCase(fetchMarketingKpi.rejected, (state, action) => {
            state.marketingLoader = false;
            return state;
        });
}

function buildFetchBigWideviewCardsDataReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchBigWideviewCardsData.pending, (state, action) => {
            state.loading = true;
            state.bigWideviewCardsData = [
                {
                    name: 'grossRevenue'
                },
                {
                    name: 'contributedProfit'
                },
                {
                    name: 'marketingCost'
                },
                {
                    name: 'blendedRoas'
                },
                {
                    name: 'orders'
                },
                {
                    name: 'quantity'
                },
                {
                    name: 'aov'
                },
                {
                    name: 'cpo'
                },
                {
                    name: 'netRevenue'
                },
                {
                    name: 'grossProfit'
                }
            ]
            return state;
        })
        .addCase(fetchBigWideviewCardsData.fulfilled, (state, action) => {
            state.bigWideviewCardsData = action.payload;
            console.log(action.payload);
            state.loading = false;
            return state;
        })
        .addCase(fetchBigWideviewCardsData.rejected, (state, action) => {
            state.loading = false;
            return state;
        });
}


function buildFetchPnlReports(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchPnlReports.pending, (state, action) => {
            state.isPnlTableLoading = true;
            if (!!state?.pnlReports?.data) {
                state.pnlReports.data = []
            }
            return state;
        })
        .addCase(fetchPnlReports.fulfilled, (state, action) => {
            state.isPnlTableLoading = false;
            state.pnlReports = action.payload;
            return state;
        })
        .addCase(fetchPnlReports.rejected, (state, action) => {
            state.isPnlTableLoading = false;
            return state;
        });
}

function buildFetchBlendedRoasReports(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchBlendedRoasReports.pending, (state, action) => {
            state.isBlendedRoasTableLoading = true;
            if (!!state?.pnlReports?.data) {
                state.pnlReports.data = []
            }
            return state;
        })
        .addCase(fetchBlendedRoasReports.fulfilled, (state, action) => {
            state.isBlendedRoasTableLoading = false;
            state.blendedRoasReports = action.payload;
            return state;
        })
        .addCase(fetchBlendedRoasReports.rejected, (state, action) => {
            state.isBlendedRoasTableLoading = false;
            return state;
        });
}

function buildFetchCpoReports(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchCpoReports.pending, (state, action) => {
            if (!!state?.cpoReports?.data) {
                state.cpoReports.data = []
            }
            return state;
        })
        .addCase(fetchCpoReports.fulfilled, (state, action) => {
            state.cpoReports = action.payload;
            return state;
        })
        .addCase(fetchCpoReports.rejected, (state, action) => {
            return state;
        });
}

function buildFetchCustomersReport(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchCustomerReports.pending, (state, action) => {
            if (!!state?.customersReport?.data) {
                state.customersReport.data = []
            }
            return state;
        })
        .addCase(fetchCustomerReports.fulfilled, (state, action) => {
            state.customersReport = action.payload;
            return state;
        })
        .addCase(fetchCustomerReports.rejected, (state, action) => {
            return state;
        });
}

function buildFetchPnlTableConfig(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchPnlTableConfig.pending, (state, action) => {
            return state;
        })
        .addCase(fetchPnlTableConfig.fulfilled, (state, action) => {
            console.log('action')
            console.log(action)
            state.pnlTableConfig = action.payload;
            return state;
        })
        .addCase(fetchPnlTableConfig.rejected, (state, action) => {
            return state;
        });
}

function buildFetchCpoTableConfig(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchCpoTableConfig.pending, (state, action) => {
            return state;
        })
        .addCase(fetchCpoTableConfig.fulfilled, (state, action) => {

            state.cpoTableConfig = action.payload;
            return state;
        })
        .addCase(fetchCpoTableConfig.rejected, (state, action) => {
            return state;
        });
}

function buildFetchCacTableConfig(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchCacTableConfig.pending, (state, action) => {
            return state;
        })
        .addCase(fetchCacTableConfig.fulfilled, (state, action) => {

            state.cacTableConfig = action.payload;
            return state;
        })
        .addCase(fetchCacTableConfig.rejected, (state, action) => {
            return state;
        });
}

function buildFetchBlendedRoasTableConfig(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchBlendedRoasTableConfig.pending, (state, action) => {
            return state;
        })
        .addCase(fetchBlendedRoasTableConfig.fulfilled, (state, action) => {

            state.blendedRoasTableConfig = action.payload;
            return state;
        })
        .addCase(fetchBlendedRoasTableConfig.rejected, (state, action) => {
            return state;
        });
}


function buildFetchGraphData(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchGraphData.pending, (state, action) => {
            return state;
        })
        .addCase(fetchGraphData.fulfilled, (state, action) => {

            state.graphData = action.payload?.data;
            return state;
        })
        .addCase(fetchGraphData.rejected, (state, action) => {
            return state;
        });
}


function buildFetchCustomTargets(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchCustomTargets.pending, (state, action) => {
            return state;
        })
        .addCase(fetchCustomTargets.fulfilled, (state, action) => {
            state.customTargetsTable = action.payload.data[0];
            return state;
        })
        .addCase(fetchCustomTargets.rejected, (state, action) => {
            state.customTargetsTable = {};
            state.loading = false;
            return state;
        });
}

function buildCreateCustomTargets(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(createCustomTargets.pending, (state, action) => {
            state.customTargetsTable.isLoading = true;
            state.customTargetsTable.isSuccess = false;
            return state;
        })
        .addCase(createCustomTargets.fulfilled, (state, action) => {
            state.customTargetsTable = action.payload.data[0];
            state.customTargetsTable.isLoading = false;
            state.customTargetsTable.isSuccess = true;
            return state;
        })
        .addCase(createCustomTargets.rejected, (state, action) => {
            state.customTargetsTable = {};
            state.loading = false;
            state.customTargetsTable.isLoading = false;
            state.customTargetsTable.isSuccess = false;
            return state;
        });
}

function fetchMarketingMappingData(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(fetchMarketingMappingByCampaign.pending, (state, action) => {
            state.marketingLoader = true;
            return state;
        })
        .addCase(fetchMarketingMappingByCampaign.fulfilled, (state, action) => {
            state.marketingMappingData = action?.payload;
            state.marketingLoader = false;

            return state;
        })
        .addCase(fetchMarketingMappingByCampaign.rejected, (state, action) => {
            state.marketingLoader = false;

            return state;
        });
}


function buildEditCustomTargetsStatsReducer(builder: ActionReducerMapBuilder<KpiState>) {
    builder
        .addCase(putCustomTargets.pending, (state, action) => {
            state.customTargetsTable.isLoading = true;
            state.customTargetsTable.isSuccess = false;
            return state;
        })
        .addCase(putCustomTargets.fulfilled, (state, action) => {
            state.customTargetsTable = action.payload.data[0];
            state.customTargetsTable.isLoading = false;
            state.customTargetsTable.isSuccess = true;
            return state;
        })
        .addCase(putCustomTargets.rejected, (state, action) => {
            state.customTargetsTable = {};
            state.loading = false;
            state.customTargetsTable.isLoading = false;
            state.customTargetsTable.isSuccess = false;
            return state;
        });
}

function buildSetExploreLoader(builder: ActionReducerMapBuilder<KpiState>) {
    builder.addCase(setExploreLoading, (state, action) => {
        state.exploreLoading = action?.payload.isLoading;
    });
}


export const kpiSlice = createSlice({
    name: "kpi",
    initialState: initialKpiState,
    reducers: {},
    extraReducers: (builder) => {
        // non async
        buildFinishMarketingLoader(builder);

        buildSetPnlPageFilter(builder);

        buildResetPnlPageFilter(builder);

        buildCleanGraphData(builder);

        buildCleanKpiStateReducer(builder);

        buildSetDailyStats(builder);

        buildSetTargetStats(builder);

        buildCreateCustomTargets(builder);

        buildCleanCustomTargetsLoaders(builder);

        /* buildCleanKpiStateReducer(builder); */
        // async
        buildFetchkpiDataReducer(builder);

        buildSetMarketingStats(builder);

        buildFetchCustomTargets(builder);

        buildEditCustomTargetsStatsReducer(builder);

        fetchMarketingMappingData(builder);

        buildFetchMarketingKpiReducer(builder);

        buildFetchGraphData(builder);

        buildFetchPnlReports(builder);

        buildFetchPnlTableConfig(builder);

        buildFetchBlendedRoasTableConfig(builder);

        buildFetchBlendedRoasReports(builder);

        buildFetchCpoTableConfig(builder);

        buildFetchCpoReports(builder);

        buildFetchCustomersReport(builder);

        buildFetchCacTableConfig(builder);

        buildFetchBigWideviewCardsDataReducer(builder);

        buildFetchLastPeriodDataReducer(builder);

        buildSetExploreLoader(builder);

        buildFetchExploreData(builder);
    },
});

// reducer
export const kpiReducer = kpiSlice.reducer;

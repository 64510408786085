import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";

const fetchAlerts = async ({organizationId}: {organizationId: string}): Promise<AxiosResponse<any, {organizationId: string}>> => {
  return axios.get<any>(`${apiBaseUrl}/organization/${organizationId}/alert/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const createAlert = async ({organizationId, data}: {organizationId: string, data: any}): Promise<AxiosResponse<any, {organizationId: string, data: string}>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/alert/`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deleteAlert = async ({organizationId, id}: {organizationId: string, id: string}): Promise<AxiosResponse<any, {organizationId: string, id: string}>> => {
  return axios.delete<any>(`${apiBaseUrl}/organization/${organizationId}/alert/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const editAlert = async ({organizationId, data}: {organizationId: string, data: any}): Promise<AxiosResponse<any, {organizationId: string, data: any}>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/alert/${data.id}`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const alertsService = {
  fetchAlerts,
  createAlert,
  deleteAlert,
  editAlert,
};

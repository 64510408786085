import { User } from "@models";
import { KpisEnum } from "models/kpi-settings";

// state

export interface Effort {
  createdAt?: string;
  kpiMetric?: string;
  assignee?: any;
  description?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  id?: string;
  kpi: KpisEnum;
}

export interface EffortsState {
  loading: boolean;
  data: Effort[];
  itemToRemove: string;
}

// initial state
export const initalEffortsState: EffortsState = {
  loading: false,
  data: [],
  itemToRemove: "",
};

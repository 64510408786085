import styled from 'styled-components';
import {Spinner} from "@chakra-ui/react";

export namespace styles {

    export const Wrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    `;

    export const MessageWrapper = styled.div<{ success: boolean }>`
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.success ? props.theme.successBackgroundColor : props.theme.errorBackgroundColor};
      padding: 20px;
      border-radius: 12px;
    `;

    export const Message = styled.span<{ success: boolean }>`
      font-size: 14px;
      color: ${props => props.success ? props.theme.successColor : props.theme.errorColor};
    `;

    export const Loader = styled(Spinner)`
      height: 48px;
      width: 48px;
      color: ${props => props.theme.primaryColor ?? '#7331EC'};
    `;
}

export default styles;

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { t } from "i18next";
import { useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ExploresEnum,
    isSecondaryExploreOpenSelector,
    openApplyBar,
    openApplyModal,
    openDefaultExploreView, openSecondaryApplyBar, openSecondaryApplyModal,
    openSecondaryExplore
} from "store/ui";
import { CloseModalIcon, SaveModalIcon } from "utils/icons/save-modal";
import { styles } from './styles';
interface Props {
    onOpen: any;
    isOpen: any;
    onClose: any;
    onApply: () => void;
    nextUpdateModal?: true;
    onCancelClick?: () => void;
}

export const ApplyChangesModal = (props: Props) => {
    const dispatch = useDispatch();
    const initialRef = useRef(null);
    const secondaryExplore = useSelector(isSecondaryExploreOpenSelector);
    const SaveChangesModal = () => {
        return (
            <>
                <Flex margin={[0, "auto"]} w={"80%"} textAlign={"center"} alignItems={"center"} flexDirection={"column"}>
                    <SaveModalIcon />
                    <styles.StyledTitle>
                        {t("APPLY_CHANGES_BEFORE_YOU_GO", { ns: "translation" })}
                    </styles.StyledTitle>
                    <styles.StyledText>
                        {t("YOUR_UNSAVED_CHANGES_WILL_BE_LOST_._SAVE_CHANGES_BEFORE_CLOSING_?", { ns: "translation" })}
                    </styles.StyledText>

                </Flex>
                <styles.CustomFooter>
                    <styles.StyledDiscardButton onClick={() => {
                        props?.onClose();
                        if(secondaryExplore?.isSecondaryExploreOpen && secondaryExplore.exploreEnum === ExploresEnum?.COGS){
                            dispatch(openSecondaryExplore({ isOpen: false }));
                            dispatch(openApplyModal({ isApplyModalOpen: false }));
                            dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));
                            return
                        }
                        dispatch(openApplyModal({ isApplyModalOpen: false }));
                        dispatch(openDefaultExploreView({ isOpen: false }));
                        dispatch(openSecondaryExplore({ isOpen: false }));
                        dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));

                    }}>
                        {t("DISCARD", { ns: "translation" })}
                    </styles.StyledDiscardButton>
                    <styles.StyledSaveButton ref={initialRef} onClick={() => {
                        props.onApply();
                        if(secondaryExplore?.isSecondaryExploreOpen && secondaryExplore.exploreEnum === ExploresEnum?.COGS){
                            dispatch(openSecondaryExplore({ isOpen: false }));
                            dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));
                            dispatch(openApplyModal({ isApplyModalOpen: false }));
                            return
                        }
                        dispatch(openDefaultExploreView({ isOpen: false }));
                        dispatch(openSecondaryExplore({ isOpen: false }));
                        dispatch(openApplyBar({ isApplyBarOpen: false }));
                        dispatch(openSecondaryApplyBar({ isApplyBarOpen: false }));
                        dispatch(openApplyModal({ isApplyModalOpen: false }));
                        dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));

                    }}>
                        {t("YES_APPLY_CHANGES", { ns: "translation" })}
                    </styles.StyledSaveButton>
                </styles.CustomFooter>
            </>
        );
    };

    const SaveChangesOnNextUpdate = () => {
        return (
            <>
                <Flex margin={[0, "auto"]} w={"90%"} textAlign={"center"} alignItems={"center"} flexDirection={"column"}>
                    {/* <SaveModalIcon /> */}
                    <styles.StyledTitle margin={30}>
                        {t("SAVE_CHANGES?", { ns: "translation" })}
                    </styles.StyledTitle>
                    <styles.StyledText>
                        {t("You're about to change settings, would you like to apply them now or on the next system update?", { ns: "translation" })}
                    </styles.StyledText>
                </Flex>
                <styles.CustomFooterApply>
                    <styles.ApplyOnUpdateButton onClick={(e) => {
                        dispatch(openApplyModal({ isApplyModalOpen: false }));
                        props?.onClose();
                        !!props?.onCancelClick && props?.onCancelClick()
                    }}>
                        {t("CANCEL", { ns: "translation" })}
                    </styles.ApplyOnUpdateButton>
                    <styles.StyledSaveButton ref={initialRef} onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation()
                        props.onApply();
                        dispatch(openDefaultExploreView({ isOpen: false }));
                        dispatch(openSecondaryExplore({ isOpen: false }));
                        dispatch(openApplyBar({ isApplyBarOpen: false }));
                        dispatch(openApplyModal({ isApplyModalOpen: false }));
                        dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));

                    }}>
                        {t("APPLY_NOW", { ns: "translation" })}
                    </styles.StyledSaveButton>
                </styles.CustomFooterApply>
            </>
        );
    };

    return (
        <>
            {/* <Button onClick={props.onOpen}>Open Modal</Button> */}
            <Modal
                initialFocusRef={initialRef}
                isOpen={props.isOpen}
                onClose={props.onClose}
            >
                <ModalOverlay />
                <ModalContent position={"relative"}>
                    {/* <ModalHeader>Create your account</ModalHeader> */}
                    <styles.CloseIcon onClick={() => {
                        dispatch(openApplyModal({ isApplyModalOpen: false }));
                        dispatch(openSecondaryApplyModal({ isApplyModalOpen: false }));
                        props?.onClose();
                    }}>
                        <CloseModalIcon />
                    </styles.CloseIcon>
                    <ModalBody pb={6}>
                        {props.nextUpdateModal ? <SaveChangesOnNextUpdate /> : <SaveChangesModal />}
                    </ModalBody>
                    {/*{props.nextUpdateModal ?
                        <ModalFooter w={"100%"} borderTop="1px solid #E3E2E9" justifyContent={"center"}>
                            <styles.SystemUpdateText>
                                {t("NEXT_SYSTEM_UPDATE_IN", { ns: "translation" })} 00:28:35
                            </styles.SystemUpdateText>
                        </ModalFooter>
                        : <></>}*/}
                </ModalContent>
            </Modal>
        </>
    );
};
import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { KpiRender } from "../../../utils/kpi-render/kpiRender";
import { KpisEnum } from "../../../models/kpi-settings";
import { PopoverPlacement } from "../../../components/small-wideview-card/SmallWideviewCard";
import InventoryOverviewTable from "./inventory-overview-table/InventoryOverviewTable";
import {
    clearOperation, fetchHandlingStats, fetchInventoryData,
    fetchOperationData,
    operationsStateSelector,
} from "../../../store/operation";
import { useDispatch, useSelector } from "react-redux";
import HandlingOverviewTable from "./handling-overview-table/HandlingOverviewTable";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { organizationSelector } from "@store";
import { DepartmentsEnum } from "../../../services/operations/operation.service";
import { SearchInput } from "../../../components/search-input/SearchInput";
import { DatepickerRange } from "../../../components/datepicker-range/DatepickerRange";
import { DateRange } from "react-day-picker";
import { dateRangeSelector } from "../../../store/ui";
import { debounce } from "../../../utils/debounce/debounce";
import { MoreInfoButton } from "../../../components/more-info-button/MoreInfoButton";
import { DownloadButton } from "../../../components/download-button/DownloadButton";
import {getCsv} from "../../../store/ui/thunks";
import OperationKpis from "../operation-kpis/OperationKpis";


interface Props {
}

const defaultPageSize = 50

const InventorySection = (props: Props) => {
    const operationData = useSelector(operationsStateSelector);

    const [handlingQuery, setHandlingQuery] = useState<string>("");
    const [handlingStatusCode, setHandlingStatusCode] = useState<string>("Incomplete");
    const [handlingSize, setHandlingSize] = useState<number>(defaultPageSize);
    const [handlingPage, setHandlingPage] = useState<number>(0);
    const [handlingHasMore, setHandlingHasMore] = useState<any>(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [sortedHeader, setSortedHeader] = useState<{headerName?: any, ascending?: boolean, key?: string}>({headerName: "Order Date", ascending: false, key: 'activityDate'});

    const [inventoryQuery, setInventoryQuery] = useState<string>("");
    const [countries, setCountries] = useState<string[]>([]);
    const [singleDate, setSingleDate] = useState<Date>();
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const dates = useSelector(dateRangeSelector);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    useEffect(() => {
        if(!!operationData?.filters){
            const defaults = ['Lumen Device', 'Lumen Device (A)', 'Lumen Device (S)', 'Lumen Device - Used-Used', 'Lumen Device-Used', 'Lumen device'];
            const defaultsFilters = operationData?.filters?.filter((item) => defaults?.includes(item));
            setSelectedProducts(defaultsFilters);
        }

    }, [operationData?.filters])
    useEffect(() => {
        dispatch(clearOperation());
    }, [])

    useEffect(() => {
        const today = new Date();
        const offset = today?.getTimezoneOffset();
        const newFromDate = new Date(today?.getTime() - (offset*60*1000));
        setSingleDate(newFromDate);
    }, [])


    useEffect(() => {
        if (!!operationData.metadata?.purchaseStats && !!operationData?.data?.purchaseStats) {
            if (handlingStatusCode === "Incomplete") {
                setHandlingHasMore(operationData.metadata.purchaseStats.incomplete > operationData.data.purchaseStats.length);
            }
            if (handlingStatusCode === "Pending Approval") {
                setHandlingHasMore(operationData.metadata.purchaseStats.pendingApproval > operationData.data.purchaseStats.length);
            }
            if (handlingStatusCode === "Pending Fulfillment") {
                setHandlingHasMore(operationData.metadata.purchaseStats.pendingFulfillment > operationData.data.purchaseStats.length);
            }
            if (handlingStatusCode === "Fulfilled") {
                setHandlingHasMore(operationData.metadata.purchaseStats.fulfilled > operationData.data.purchaseStats.length);
            }
            if (handlingStatusCode === "Cancelled") {
                setHandlingHasMore(operationData.metadata.purchaseStats.cancelled > operationData.data.purchaseStats.length);
            }
            if (handlingStatusCode === "Returns") {
                setHandlingHasMore(operationData.metadata.purchaseStats.returns > operationData.data.purchaseStats.length);
            }
        }
    }, [operationData])

    useEffect(() => {
        !!organization?.id && !!dates?.startDate && !!dates?.endDate &&
            dispatch(
                fetchHandlingStats({
                    organizationId: organization?.id,
                    fromDate: new Date(dates.startDate).toISOString(),
                    toDate: new Date(dates.endDate).toISOString(),
                    query: !!handlingQuery ? handlingQuery : undefined,
                    statusCode: handlingStatusCode, //the first tab
                    size: handlingSize,
                    page: handlingPage,
                    sort: [`${[sortedHeader?.key]}:${sortedHeader?.ascending ?1 :-1}`]
                })
            );
    }, [organization?.id, dates?.startDate, dates?.endDate, handlingQuery, handlingPage, sortedHeader]);

    useEffect(() => {
        !!organization?.id && !!singleDate && !!selectedProducts && dispatch(
            fetchInventoryData({
                organizationId: organization?.id,
                fromDate: singleDate?.toISOString()?.slice(0, 10) + "T00:00:00.000Z",
                toDate: singleDate?.toISOString()?.slice(0, 10) + "T23:59:59.999Z",
                query: !!inventoryQuery ? inventoryQuery : undefined,
                description: selectedProducts
            })
        );
    }, [dispatch, organization?.id, inventoryQuery, singleDate, selectedProducts])

    const fetchMorePurchasesData = () => {

        if (!operationData?.purchaseStatsLoading) {
            setHandlingPage(handlingPage + 1);
        }
    };

    const onTabChanged = (statusCode: string) => {
        setHandlingStatusCode(statusCode);
        setHandlingPage(0);
        setHandlingSize(defaultPageSize)
        if (!!organization?.id && !!dates?.startDate && !!dates?.endDate) {
            dispatch(
                fetchHandlingStats({
                    organizationId: organization?.id,
                    fromDate: new Date(dates.startDate).toISOString(),
                    toDate: new Date(dates.endDate).toISOString(),
                    query: !!handlingQuery ? handlingQuery : undefined,
                    statusCode: statusCode,
                    size: handlingSize,
                    page: handlingPage
                })
            );
        }
    }


    const updateDebounce = debounce((text: string) => {
        setHandlingQuery(text);
    })


    const header = () => {
        return (
            <styles.HeaderWrapper>
                <Flex>

                    <styles.Title>Handling Overview
                        <div style={{ alignSelf: 'start' }}><MoreInfoButton onClick={() => { return }} /></div>
                    </styles.Title>
                    <Box w={6} />
                    <SearchInput
                        placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"}
                        onChange={(e) => {
                            setHandlingPage(0);
                            updateDebounce(e)
                        }}
                        onSearch={(e) => {
                        }}
                    />
                </Flex>
                <Flex>
                    {<DownloadButton fileName={"handling_overview"} serviceCallback={() => {
                        !!organization?.id && !!dates?.startDate && !!dates?.endDate &&
                        dispatch(getCsv({
                                organizationId: organization?.id,
                                fromDate:  new Date(dates?.startDate).toISOString(),
                                toDate: new Date(dates?.endDate).toISOString(),
                                type: 0,
                                kpisType: DepartmentsEnum.PURCHASE,
                                query: handlingQuery,
                                statusCode: handlingStatusCode,
                                fileName: `handling_overview${handlingStatusCode}`,
                            }
                        ))
                    }} onClick={() => { }} />}
                    <Box w={3} />

                    <DatepickerRange onApply={(range) => {
                    }} />
                    <Box w={25} />
                </Flex>

            </styles.HeaderWrapper>
        );
    };

    const kpiSection = () => {
        return (
            <styles.KpisHolder>
                <KpiRender
                    popoverPlacement={PopoverPlacement.TOP}
                    kpiArray={[
                        KpisEnum.AVERAGE_UNITS_SOLD,
                        KpisEnum.COGS_AVERAGE,
                        KpisEnum.INVENTORY_TURNOVER,
                    ]}
                />
            </styles.KpisHolder>
        );
    };


    const Loader = () => {
        return (
            <Flex h={150} w={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Spinner />
            </Flex>
        );
    };
    return (
        <styles.Wrapper>
           {/* <OperationKpis
                kpisToDisplay={[
                    KpisEnum.NUMBER_OF_DEVICES,
                    KpisEnum.INCOMPLETE_ORDERS
                ]}
            />*/}
            {!!operationData?.data?.inventoryData ? (
                <InventoryOverviewTable setDate={setSingleDate}
                    chosenDate={singleDate}
                    setInventoryQuery={setInventoryQuery}
                    tableData={operationData.data.inventoryData}
                    countries={countries}
                    setCountries={(countries) => {
                        setCountries(countries)
                    }}
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                />
            ) : (
                <Loader />
            )}
            <Box w={"100%"} h={"170px"} />
            {header()}
            {!!operationData?.data?.purchaseStats ? (
                <HandlingOverviewTable
                    onTabChanged={onTabChanged}
                    tableData={operationData.data.purchaseStats}
                    fetchMoreData={fetchMorePurchasesData}
                    handlingHasMore={handlingHasMore}
                    setSortedHeader={setSortedHeader}
                    sortedHeader={sortedHeader}
                    handlingStatusCode={handlingStatusCode}
                    searchQuery={handlingQuery}
                    setPage={setHandlingPage}

                />
            ) : (
                <Loader />
            )}
        </styles.Wrapper>
    );
};

export default InventorySection;

import styled from "styled-components";
import { Props } from "./AccountHealthButton";

export namespace styles {
  export const AccountHealthWrapper = styled.div<Props>`
    background-color: ${(props) => props.theme.backgroundColor ?? "white"};
    border-radius: 10px;
    box-shadow: 50px 13px 54px #695f9714;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    margin: 10px;
    width: 150px;
    height: 150px;
    position: relative;
    &:hover {
      cursor: pointer;
      box-shadow: unset;
      transition: box-shadow .3s;

    }
  `;
  export const AccountHealthWrapperComingSoon = styled.div<Props>`
    background-color: ${(props) => props.theme.backgroundColor ?? "white"};
    border-radius: 10px;
    box-shadow: 50px 13px 54px #695f9714;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    margin: 10px;
    width: 150px;
    height: 150px;
    position: relative;
  `;

  export const ComingSoonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #bebbd5;
    opacity: 0.85;
    border-radius: 10px;
  `;

  export const Text = styled.div`
    display: flex;
    position: absolute;
    z-index: 2;
    color: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.fonts.roboto.regular};

    font-weight: bold;
  `;

  export const ComingSoonWrapperText = styled.div`
    backdrop-filter: blur(1px);
    width: 100%;
    height: 100%;
    position: absolute;
  `;

  export const Title = styled.div<Props>`
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const Icon = styled.div<Props>`
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
}

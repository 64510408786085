import React, {useContext, useState} from 'react';
import {ThemeContext, ThemeProvider} from "styled-components";
import {DarkTheme, LightTheme} from "@styles";

type Props = {
    theme: any;
};

export const StyledThemeProvider: React.FC<Props> = (props) => {
    const [theme, setTheme] = useState(props?.theme);
    const setThemeById = (themeId: string) => {
        switch (themeId) {
            case 'light':
                setTheme(LightTheme);
                break;
            case 'dark':
                setTheme(DarkTheme);
                break;
            default:
                setTheme(LightTheme);
                break;
        }
    };
    return (
        <ThemeProvider theme={{...theme, setTheme: setThemeById}}>
            {props.children}
        </ThemeProvider>
    );
}

export function useTheme() {
    return useContext(ThemeContext);
}

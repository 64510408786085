import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import {AccountIntegrations} from "../../store/account";

const fetchChannels = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(`${apiBaseUrl}/organization/${organizationId}/channels`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const fetchChannelById = async ({
  organizationId,
  channelId,
}: {
  organizationId: string;
  channelId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${apiBaseUrl}/organization/${organizationId}/channels/${channelId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const fetchAccounts = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(`${apiBaseUrl}/organization/${organizationId}/accounts`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};


export const createAccounts = async (
    organization: string,
    accounts: AccountIntegrations[]
): Promise<AxiosResponse<AccountIntegrations>> => {
  return axios.post<AccountIntegrations>(
      `${apiBaseUrl}/organization/${organization}/accounts`,
      accounts,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

export const updateDisplayName = async (
    organization: string,
    accountId: string,
    displayName: string
): Promise<AxiosResponse<AccountIntegrations>> => {
  return axios.put<AccountIntegrations>(
      `${apiBaseUrl}/organization/${organization}/accounts/${accountId}`,
      {displayName},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};


const changeChannelName = async ({
  organizationId,
  body,
}: {
  organizationId: string;
  body: { id?: string; displayName: string }[];
}): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/channels`,
    ...[body],
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const channelsService = {
  fetchChannels,
  fetchChannelById,
  fetchAccounts,
  changeChannelName,
  createAccounts,
  updateDisplayName
};

// local

import { IntegrationPlatform, IntegrationType } from "@models";

// state
export interface Channel {
  name?: string;
  integrationId?: string;
  platformType?: IntegrationPlatform;
  displayName?: string;
  isDefault?: boolean;
  type?: IntegrationType;
  createAt?: string;
  updatedAt?: string;
  id?: string;
}

export interface ChannelsState {
  channels: Channel[];
  error?: any;
  loading?: boolean;
}

// initial state
export const initalChannelsState: ChannelsState = {
  error: undefined,
  loading: false,
  channels: [],
};

import inviteMenuStyles from "./styles";
import {
    Avatar,
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    authSelector,
    deleteUserPending,
    inviteUser,
    organizationSelector,
    pendingLoadingSelector,
    userSelector
} from "@store";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import { TiDelete } from "react-icons/ti";
import { DepartmentsEnum, RolesEnum } from "@services";
import { AiOutlineClose } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { GrRefresh } from "react-icons/gr";
import {openApplyModal} from "../../../store/ui";
import {CloseModalIcon} from "../../../utils/icons/save-modal";
import {getUserColorById, gradientDictionary} from "../../../utils/colors";

interface Props {
    message?: string;
    isOpen: any;
    onOpen: any;
    onClose: any;
}

interface Users {
    email: string,
    permission: boolean;
    id: string;
}


enum Permission {
    EDITOR,
    VIEWER
}

interface PropsChangeButton {
    id: string,
    title: string;
}

interface ExistUserOrPendingUser {
    email: string;
    firstName?: string;
    lastName?: string;
    id?: string;
}

/* const schema = yup.object().shape({
    email: yup.string().required().email(),
});
 */
export const InviteMenu = (props: Props) => {

    const organization = useSelector(organizationSelector);
    const pendingLoading = useSelector(pendingLoadingSelector);
    const [editorPermission, setEditorPermission] = useState(true);
    const [users, setUsers] = useState<Users[]>([]);
    const [userDropdown, setUserDropdown] = useState();
    const [currentEmail, setCurrentEmail] = useState<string>();
    const [emailInput, setEmailInput] = useState<string>("");
    const [usersAndPendings, setUsersAndPendings] = useState<ExistUserOrPendingUser[]>([]);
    const [closeMenu, setCloseMenu] = useState(false);
    const listInnerRef: any = useRef();
    const [width, setWidth] = useState(0);

    const currentUser = useSelector(userSelector);

    const inputRef: any = useRef(null);
    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    useLayoutEffect(() => {
        setWidth(inputRef?.current?.offsetWidth);
    }, [props?.isOpen]);

    /*     const { register, handleSubmit } = useForm({
            resolver: yupResolver(schema)
        }); */

    const dispatch = useDispatch();

    useEffect(() => {
        setUsers([]);
    }, [usersAndPendings]);





    useEffect(() => {
        if (!!organization?.pendingInvitations && !!organization.users) {
            const pendingUsers = organization?.pendingInvitations.map((item) => { return { email: item.email, id: item.id, pending: true }; });
            const existingUsers = organization?.users.map((item) => {
                if (!!item?.firstName && !!item?.lastName) {
                    return { email: item.email || "", firstName: item.firstName || "", lastName: item.lastName || "", id: item.id };
                }
                return {
                    email: item.email || "", firstName: item.firstName || "", lastName: item.lastName || "", id: item.id, image: ""
                };
            });

            /*  setUsersAndPendings(shuffle([...pendingUsers, ...existingUsers])); */
            setUsersAndPendings([...pendingUsers, ...existingUsers]);
        }
    }, [organization?.pendingInvitations, organization?.users]);

    // helpers

    const getEditorQuery = () => {
        switch (editorPermission) {
            case (true):
                return RolesEnum.EDITOR;
            case (false):
                return RolesEnum.VIEWER;
            default:
                return RolesEnum.VIEWER;
        }
    };

    const submitForm = (e: any) => {
        e.preventDefault();
        if (!!organization) {
            users.forEach((item) => {
                dispatch(inviteUser({
                    email: item.email,
                    organizationId: organization?.id,
                    role: getEditorQuery(),
                    department: DepartmentsEnum.COMPANY,
                    inviterEmail: currentUser?.email,
                    inviterName: currentUser?.firstName,
                }));
            });
        }
    };

    function shuffle<T>(array: T[]): T[] {
        let currentIndex = array.length, randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex !== 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    };


    const onChange = (event: any) => {
        setEmailInput(event.target.value);

        if (event.target.checkValidity()) {
            setCurrentEmail(event.target.value);
        }
        else {
            setCurrentEmail(undefined);
        }
    };

    const addUser = (user: Users) => {
        const activeUsersEmail = usersAndPendings?.map((user) => user?.email);
        if(!activeUsersEmail?.includes(user?.email)){
            setUsers(users => [user, ...users]);
            setCurrentEmail(undefined);
            setEmailInput("");
        }
    }



    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!!currentEmail) {
                if (users.some((user) => user.email === currentEmail)) {
                    /* const newUsers = users.filter((user) => ) */
                    return;

                }
                const user = { email: currentEmail, permission: editorPermission, id: uuidv4() };
                addUser(user)

            }
            if (emailInput === "" && users.length > 0) {
                submitForm(e);
            }
        }
    };

    const handleClickDropdown = () => {
        if (!!currentEmail) {
            if (users.some((user) => user.email === currentEmail)) {
                return;
            }
            const user = { email: currentEmail, permission: editorPermission, id: uuidv4() };
            addUser(user)
        }
    };

    // render

    const renderNewEmailDropdown = () => {
        return (
            <inviteMenuStyles.DropDownWrapper w={width} onClick={handleClickDropdown}>
                <inviteMenuStyles.DropDownText>
                    {currentEmail}
                </inviteMenuStyles.DropDownText>
            </inviteMenuStyles.DropDownWrapper>
        );
    };

    const renderInviteEmailInput = () => {
        return (
            <inviteMenuStyles.InviteEmailInput ref={inputRef} value={emailInput} onChange={onChange} type={"email"} required placeholder={'Add Email'} />
        );
    };

    const changeEditor = (id: string, permission: Permission) => {
        const getPremission = (title: Permission) => {
            switch (title) {
                case (Permission.EDITOR):
                    return true;
                case (Permission.VIEWER):
                    return false;
                default:
                    return false;
            }
        };
        const index = users.findIndex(object => {
            return object.id === id;
        });
        const copyOfUsers = users;
        copyOfUsers[index].permission = getPremission(permission);
        setUsers(copyOfUsers);
    };

    const removeUser = (id: string) => {
        const filtered = users.filter((item) => item.id !== id);
        setUsers(filtered);

    };

    const RenderChangeButton = (props: PropsChangeButton) => {
        const [titles, setTitle] = useState<string>(props.title);
        return (<Menu>
            {
                ({ onClose }) => {
                    if (closeMenu) {
                        onClose();
                    }
                    return (
                        <>
                            <MenuButton as={Button} rightIcon={<IoIosArrowDown />}>
                                {titles}
                            </MenuButton>
                            <MenuList >
                                <MenuItem onClick={() => {
                                    changeEditor(props.id, Permission.EDITOR);
                                    setTitle("Editor");
                                }}>Editor</MenuItem>
                                <MenuItem onClick={() => {
                                    changeEditor(props.id, Permission.VIEWER);
                                    setTitle("Viewer");
                                }}>Viewer</MenuItem>
                                <MenuItem onClick={() => removeUser(props.id)}>
                                    Remove
                                </MenuItem>
                            </MenuList>
                        </>);
                }
            }

        </Menu>);
    };

    const RenderSwitchForm = () => {

        return (<Menu>
            <MenuButton border={"1px solid #707070"} backgroundColor={"white"} padding={0} w={110} as={Button}>
                <Flex justifyContent={"space-between"} w={"75%"} margin={"0 auto"} alignItems={"center"}>
                    {editorPermission ? "Editor" : "Viewer"}
                    <IoIosArrowDown />
                </Flex>
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => {
                    setEditorPermission(true);
                }}>Editor</MenuItem>
                <MenuItem onClick={() => {
                    setEditorPermission(false);
                }}>Viewer</MenuItem>
            </MenuList>
        </Menu>);
    };

    const onScroll = () => {
        if (listInnerRef.current) {
            /* const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current; */
            /* setCloseMenu(!closeMenu); */
            /*   if (scrollTop + clientHeight === scrollHeight) {
                  console.log("reached bottom");
              } */
        }
    };

    const onResend = (user: { email: string, id: string, pending: boolean; }) => {
        if (user?.pending && !!organization?.id) {
            dispatch(inviteUser({
                email: user.email,
                organizationId: organization?.id,
                role: RolesEnum.EDITOR,
                department: DepartmentsEnum.COMPANY
            }));
        }
    };

    const onRemove = (id: string) => {
        const newDataToRender = usersAndPendings.filter((item) => item?.id !== id);
        setUsersAndPendings(newDataToRender);
    };

    const onRemoveFromNonActiveUsers = (id: string) => {
        const newUsers = users.filter((user) => user.id !== id);
        setUsers(newUsers);
    };

    const RenderUsersList = (props: { array: any[], activeList: boolean; }) => {

        return (
            <inviteMenuStyles.UsersWrapper>
                {props.array.map((user, index) => {
                    return <inviteMenuStyles.UserContainer key={index}>
                        <inviteMenuStyles.UserTitle>
                            <inviteMenuStyles.UserName>
                                {/* {user?.firstName + ' ' + user?.lastName} */}
                            </inviteMenuStyles.UserName>
                            <inviteMenuStyles.UserEmail>
                                {user?.email}
                            </inviteMenuStyles.UserEmail>
                        </inviteMenuStyles.UserTitle>
                        <inviteMenuStyles.UserDetails>
                            <inviteMenuStyles.UserRole>
                                {/* {user?.workspaces![0].role} */}
                            </inviteMenuStyles.UserRole>
                            {/* {!props.activeList ? (<inviteMenuStyles.UserPermissions>
                                {user?.premission ? <RenderChangeButton title="Editor" id={user.id} /> : <RenderChangeButton title="Viewer" id={user.id} />}
                            </inviteMenuStyles.UserPermissions>) : <></>} */}

                        </inviteMenuStyles.UserDetails>
                        <AiOutlineClose onClick={(e) => {
                            onRemoveFromNonActiveUsers(user.id);
                        }} />
                    </inviteMenuStyles.UserContainer>;
                })}

            </inviteMenuStyles.UsersWrapper>
        );
    };
    const RenderExistingUsers = (props: { array: any[]; }) => {

        return (
            <inviteMenuStyles.UsersWrapperActive>
                {props.array.map((user, index) => {
                    return <inviteMenuStyles.UserContainer key={index}>
                        {!!user?.firstName && !!user?.lastName ?
                            <inviteMenuStyles.ImageContainer>
                                <Avatar size='sm' name={user?.firstName + " " + user?.lastName} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]} />
                            </inviteMenuStyles.ImageContainer>
                            : !!user?.firstName ? <inviteMenuStyles.ImageContainer>
                                <Avatar size='sm' name={user?.firstName} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]}/>
                            </inviteMenuStyles.ImageContainer> : <inviteMenuStyles.ImageContainer>
                                <Avatar size='sm' src='https://bit.ly/broken-link' />
                            </inviteMenuStyles.ImageContainer>}
                        <Box w={2} />
                        <Flex w={"100%"} justifyContent={"space-between"}>
                            <Flex>
                                {/* {!!user?.firstName && !!user?.lastName ? <inviteMenuStyles.CustomSpacer width={10} /> : <></>} */}
                                <inviteMenuStyles.UserTitle>
                                    <inviteMenuStyles.UserName>
                                        {user?.email}

                                    </inviteMenuStyles.UserName>
                                    <inviteMenuStyles.UserEmail>
                                        {!user?.pending ? (user?.firstName + ' ' + user?.lastName) : <inviteMenuStyles.Pending>Pending Invite</inviteMenuStyles.Pending>}

                                    </inviteMenuStyles.UserEmail>
                                </inviteMenuStyles.UserTitle>
                            </Flex>
                            {user?.pending && !!organization?.id && !!user?.id ?
                                (<AiOutlineClose onClick={(e) => {
                                    onRemove(user.id);
                                    dispatch(deleteUserPending({ organizationId: organization.id, userId: user.id }));
                                }} />)
                                : <></>}

                        </Flex>
                        <Box w={4} />

                    </inviteMenuStyles.UserContainer>;

                })

                }
            </inviteMenuStyles.UsersWrapperActive >
        );
    };

    const renderInviteForm = () => {
        return (
            <inviteMenuStyles.InviteForm onKeyPress={(e) => { handleKeyPress(e); }} /* onSubmit={(e) => (submitForm(e))} */>
                <inviteMenuStyles.InputWrapper>
                    {renderInviteEmailInput()}
                    {/* <RenderSwitchForm /> */}
                    {/*  <inviteMenuStyles.PermissionWrapper>
                        <inviteMenuStyles.PermissionsSwitch
                            onChange={() => setEditorPermission(!editorPermission)} colorScheme='#7031EB' />
                        <label>{editorPermission ? 'Editor' : 'Viewer'}</label>
                    </inviteMenuStyles.PermissionWrapper> */}
                    {!!currentEmail ? renderNewEmailDropdown() : null}
                </inviteMenuStyles.InputWrapper>
                <inviteMenuStyles.UsersContainer /* onScroll={onScroll} ref={listInnerRef} */ >
                    <RenderUsersList array={users} activeList={false} />
                </inviteMenuStyles.UsersContainer>
                <inviteMenuStyles.SendButton notActive={users.length === 0} disabled={users.length === 0} onClick={(e) => submitForm(e)}>
                    {pendingLoading ? <Spinner mt={"2px"} /> : t('SEND', { ns: 'translation' })}

                </inviteMenuStyles.SendButton>
            </inviteMenuStyles.InviteForm>
        );
    };

    const renderActiveUsers = () => {
        return (
            <inviteMenuStyles.UsersContainerActive >
                <RenderExistingUsers array={usersAndPendings} />
            </inviteMenuStyles.UsersContainerActive>


        );
    };


    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={10} minH={"70vh"}>
                <ModalBody>
                    <inviteMenuStyles.Wrapper>
                        <inviteMenuStyles.StyledTitle>
                            <Flex mb={15} position={"relative"} justifyContent={"space-between"} alignItems={"center"} >
                                {t('INVITE_TEAM_MEMBERS', {ns: 'translation'})}
                                <inviteMenuStyles.CloseIcon onClick={() => {
                                    props?.onClose();
                                }}>
                                    <CloseModalIcon />
                                </inviteMenuStyles.CloseIcon>
                                {/*<ModalCloseButton position={"unset"} />*/}
                            </Flex>
                        </inviteMenuStyles.StyledTitle>
                        {renderInviteForm()}
                        <inviteMenuStyles.Spacer />
                        <inviteMenuStyles.StyledTitle>
                            <Flex mt={1} mb={2}>
                                {t('ACTIVE_TEAM_MEMBERS', {ns: 'translation'})}
                            </Flex>
                        </inviteMenuStyles.StyledTitle>
                        {renderActiveUsers()}
                    </inviteMenuStyles.Wrapper>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};


import {
    CardTypes, Metric,
    PercentageFrom,
    PnlDataLanguageItem,
    PnlSections,
} from "../../models/pnl-language";
import axios, {AxiosResponse} from "axios";
import {apiBaseUrl} from "@constants";
import {Expression} from "../../store/pnl";

export interface CreatePnlDto {
    fieldName: string;
    isDefault: boolean;
    isPercentage: boolean;
    expression: Expression[];
    displayName?: string;
    frequency?: any;
}

export const getPnlDataLanguage = () => {
  return {};
};

const fetchPnl = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
      `${apiBaseUrl}/organization/${organizationId}/metrics`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const fetchDraftPnl = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.get<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const applyDraftPnl = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.put<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics/publish`,
        {},
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const discardDraftPnl = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.put<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics/reset`,
        {},
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const fetchPnlNames = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.get<any>(
        `${apiBaseUrl}/organization/${organizationId}/metrics?namesOnly=true`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const fetchFields = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.get<any>(
        `${apiBaseUrl}/organization/${organizationId}/metrics/fields-list`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const fetchMetricsDisplayNames = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
    return axios.get<any>(
        `${apiBaseUrl}/organization/${organizationId}/metrics/field-names`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const createPnl = async (
    organizationId: string,
    dto: Metric
): Promise<AxiosResponse<any>> => {
    return axios.post<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics`,
        dto,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const moveMetrics = async (
    organizationId: string,
    dto: {metricName: string, bucketName?: string}[]
): Promise<AxiosResponse<any>> => {
    return axios.put<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics/move`,
        dto,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const deletePnl = async (
    organizationId: string,
    pnlId: string
): Promise<AxiosResponse<any>> => {
    return axios.delete<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics/${pnlId}`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

const editPnl = async (
    organizationId: string,
    pnlId: string,
    dto: Metric
): Promise<AxiosResponse<any>> => {
    return axios.put<any>(
        `${apiBaseUrl}/organization/${organizationId}/draft-metrics/${pnlId}`,
        dto,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};



export const pnlService = {
    fetchPnl,
    createPnl,
    deletePnl,
    fetchPnlNames,
    fetchMetricsDisplayNames,
    fetchFields,
    moveMetrics,
    fetchDraftPnl,
    editPnl,
    applyDraftPnl,
    discardDraftPnl
};
export const SaveModalIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="164.693" height="110.678" viewBox="0 0 164.693 110.678">
            <g id="Group_11599" data-name="Group 11599" transform="translate(27.616 -33.355)">
                <path id="Path_12572" data-name="Path 12572" d="M213.779,266.589c-4.7,0-6.719,6.807-11.319,7.775-2.054.432-4.15-.428-6.249-.465s-4.609,1.487-4.166,3.539l40.015-.6a18.406,18.406,0,0,1-10.976-5.156C218.915,269.521,216.838,266.587,213.779,266.589Z" transform="translate(-190.232 -233.234)" fill="#f0f0f0" />
                <path id="Path_12573" data-name="Path 12573" d="M623.223,151.2a10.071,10.071,0,0,1-9.932-8.411l0-.02.013-.015a9.722,9.722,0,0,0,1.544-2.569,6.547,6.547,0,0,1-1.611,1.473l-.063.042,0-.076c-.008-.167-.012-.333-.012-.493a10.012,10.012,0,0,1,4.748-8.549,14.232,14.232,0,1,1,23.783-14.329l.013.038-.037.015a7,7,0,0,1-2.714.572,9.719,9.719,0,0,0,2.991.362h.035l.008.034a14.286,14.286,0,0,1,.424,3.459q0,.063,0,.126a6.453,6.453,0,0,0,2.131,4.811,10.067,10.067,0,0,1-.47,15.3l-.021.017-.024-.011a8.1,8.1,0,0,1-3.231-2.355,9.871,9.871,0,0,0,2.051,3.133l.038.039-.047.028a10.077,10.077,0,0,1-9.936.118,10.106,10.106,0,0,1-9.674,7.267Z" transform="translate(-533.53 -23.458)" fill="#f3effc" />
                <path id="Path_12574" data-name="Path 12574" d="M682.668,393.063a.183.183,0,0,1-.181-.157,61.248,61.248,0,0,1-.085-12.842c.631-6.739,2.659-16.446,8.733-24.417a.183.183,0,1,1,.291.222c-6.022,7.9-8.033,17.538-8.66,24.23a60.832,60.832,0,0,0,.083,12.756.183.183,0,0,1-.181.209Z" transform="translate(-596.562 -249.281)" fill="#3f3d56" />
                <path id="Path_12575" data-name="Path 12575" d="M721.887,430.59a.183.183,0,0,1-.108-.331,25.217,25.217,0,0,1,5.566-2.834,19.576,19.576,0,0,1,12.549-.613.183.183,0,1,1-.111.349,19.215,19.215,0,0,0-12.312.609,24.8,24.8,0,0,0-5.477,2.786A.182.182,0,0,1,721.887,430.59Z" transform="translate(-632.745 -313.684)" fill="#3f3d56" />
                <path id="Path_12576" data-name="Path 12576" d="M294.736,613.5l1.517.475,10.177-32.52a1.68,1.68,0,0,0-2.017,1.124Z" transform="translate(-271.296 -481.622)" fill="#3f3d56" />
                <path id="Path_12577" data-name="Path 12577" d="M428.168,458.734h-6.709v-19.72h6.709a4.847,4.847,0,0,1,4.841,4.841v10.037A4.847,4.847,0,0,1,428.168,458.734Z" transform="translate(-371.282 -369.279)" fill="#2f2e41" />
                <path id="Path_12578" data-name="Path 12578" d="M445.67,451.857a.846.846,0,0,1-.845-.845v-7.324h1.69v7.324A.846.846,0,0,1,445.67,451.857Z" transform="translate(-389.717 -372.966)" fill="#3f3d56" />
                <path id="Path_12579" data-name="Path 12579" d="M436.114,506.171H431.2a1.068,1.068,0,0,1-1.067-1.067v-2.937A1.068,1.068,0,0,1,431.2,501.1h4.909a1.068,1.068,0,0,1,1.067,1.067V505.1A1.068,1.068,0,0,1,436.114,506.171Z" transform="translate(-378.129 -418.265)" fill="#3f3d56" />
                <path id="Path_12580" data-name="Path 12580" d="M388.613,385.54a.5.5,0,0,1-.316-.113.517.517,0,0,1-.19-.369,16.127,16.127,0,0,1,1.281-7.382,6.478,6.478,0,0,1,2.455-3.184,5.791,5.791,0,0,1,5.006-.176,5.045,5.045,0,0,1,2.333,1.579,6.241,6.241,0,0,1,1.027,3.134,28.09,28.09,0,0,1,.137,5.023.49.49,0,0,1-.271.412.536.536,0,0,1-.532-.035,1.1,1.1,0,0,0-.1-.057,1.18,1.18,0,0,0-1.084.014,2.425,2.425,0,0,0-.345.248l-.666.539a.514.514,0,0,1-.586.037,3.612,3.612,0,0,0-1.577-.466,10.065,10.065,0,0,0-2.2.159l-4.3.631A.521.521,0,0,1,388.613,385.54Z" transform="translate(-344.941 -317.856)" fill="#2f2e41" />
                <path id="Path_12581" data-name="Path 12581" d="M191.576,627.785h-2.3l-1.093-8.863h3.392Z" transform="translate(-148.479 -488.334)" fill="#ffb6b6" />
                <path id="Path_12582" data-name="Path 12582" d="M363.978,768.747h-1.631l-.291-1.539-.745,1.539h-4.325a.972.972,0,0,1-.552-1.772l3.454-2.385v-1.556l3.633.217Z" transform="translate(-319.645 -624.932)" fill="#2f2e41" />
                <path id="Path_12583" data-name="Path 12583" d="M305.058,621.059l-2.118.891L298.5,614.2l3.126-1.314Z" transform="translate(-235.515 -483.574)" fill="#ffb6b6" />
                <path id="Path_12584" data-name="Path 12584" d="M497.508,758.624l-1.5.632-.865-1.306-.09,1.708-3.987,1.676a.972.972,0,0,1-1.2-1.419l2.259-3.537-.6-1.435,3.433-1.208Z" transform="translate(-425.135 -617.595)" fill="#2f2e41" />
                <path id="Path_12585" data-name="Path 12585" d="M173.815,374.265l-.659,8.822-4.367,13.175-1.754-1.02,2.38-13.4.808-7.73Z" transform="translate(-131.792 -295.179)" fill="#ffb6b6" />
                <circle id="Ellipse_124" data-name="Ellipse 124" cx="1.838" cy="1.838" r="1.838" transform="translate(34.141 99.329)" fill="#ffb6b6" />
                <path id="Path_12586" data-name="Path 12586" d="M376.111,526.575v2.433s3.767,6.638,2.233,9.486L384,553.963s7.669,7.669,7.012,13.147l.438,1.753-5.04,2.191-1.1-1.534-8.545-14.68-7.231-13.366-1.315,15.776s.876,12.27-1.534,13.585l-.219,1.972h-5.115v-1.875s-1.9-6.671-.8-11.71l-.438-21.254s1.972-8.984,4.382-10.517l.876-2.191Z" transform="translate(-322.881 -437.327)" fill="#2f2e41" />
                <path id="Path_12587" data-name="Path 12587" d="M390.309,434.2l-4.776-2.236-1.379-2.4-4.636.263-1.4,2.29-4.126.836.606,14.914a2.292,2.292,0,0,0-.272,1.547c.145.935.88.2.145.935s-1.289.366-.92,1.01S373.01,453,373.01,453s11.738,5.978,15.054-1.649c0,0,.654-2.231-.451-6.905S390.309,434.2,390.309,434.2Z" transform="translate(-333.055 -361.819)" fill="#e4e4e4" />
                <path id="Path_12588" data-name="Path 12588" d="M367.491,446.57l-1.356-.95s-1.876.77-1.939,2.328a58.877,58.877,0,0,1-1.141,6.22l4.282,2.3Z" transform="translate(-325.2 -374.491)" fill="#e4e4e4" />
                <path id="Path_12589" data-name="Path 12589" d="M240.351,377.75l-.658,8.822-7.308,10.969-1.754-1.02,5.32-11.193.808-7.73Z" transform="translate(-181.969 -297.928)" fill="#ffb6b6" />
                <path id="Path_12590" data-name="Path 12590" d="M437.865,452.043l2.234-.5a2.632,2.632,0,0,1,1.53,2.452c.184,2.022.338,5.541.338,5.541l-4.688,3.448-1.164-4.945Z" transform="translate(-382.845 -379.165)" fill="#e4e4e4" />
                <circle id="Ellipse_125" data-name="Ellipse 125" cx="1.838" cy="1.838" r="1.838" transform="translate(47.375 97.858)" fill="#ffb6b6" />
                <circle id="Ellipse_126" data-name="Ellipse 126" cx="4.283" cy="4.283" r="4.283" transform="translate(44.08 58.388)" fill="#ffb7b7" />
                <path id="Path_12591" data-name="Path 12591" d="M388.387,380.745h0l-1.639,0a.489.489,0,0,1-.349-.149.473.473,0,0,1-.134-.347,5.631,5.631,0,0,1,2.62-4.512h0a5.47,5.47,0,0,1,8.31,4.373.482.482,0,0,1-.461.509c-1.9.08-4,.119-6.817.126h0a.481.481,0,0,1-.447-.3l-.045-.111a.3.3,0,0,0-.566.043A.481.481,0,0,1,388.387,380.745Z" transform="translate(-343.513 -318.72)" fill="#2f2e41" />
                <path id="Path_12592" data-name="Path 12592" d="M433.482,458.75a5.744,5.744,0,0,1-1.654-4.207,10.551,10.551,0,0,1,1.462-5c.5-.932,1.074-1.824,1.646-2.714.192-.3-.285-.576-.476-.278-1.936,3.011-4.068,6.577-2.819,10.278a5.611,5.611,0,0,0,1.451,2.309c.258.243.649-.146.39-.39Z" transform="translate(-379.021 -375.127)" fill="#3f3d56" />
                <path id="Path_12593" data-name="Path 12593" d="M253.673,659.875a5.284,5.284,0,0,0-2.924-.073l-.006,0a5.359,5.359,0,0,0-2.038,1.033c-.035.028-.068.057-.1.085a5.309,5.309,0,0,0-1.612,2.45L242.9,676.448a5.311,5.311,0,0,0-.06,2.982l.048.165a5.375,5.375,0,0,0,.942,1.784.03.03,0,0,1,.006.009,5.315,5.315,0,0,0,2.56,1.741l7.718,2.415,7.277-23.254Z" transform="translate(-230.204 -543.347)" fill="#7031eb" />
                <path id="Path_12594" data-name="Path 12594" d="M315.247,582.4l-9.939,31.761a.795.795,0,0,0,1.517.475l9.439-30.163A1.68,1.68,0,0,0,315.247,582.4Z" transform="translate(-279.609 -482.409)" fill="#3f3d56" />
                <circle id="Ellipse_127" data-name="Ellipse 127" cx="1.423" cy="1.423" r="1.423" transform="translate(21.819 141.094)" fill="#3f3d56" />
                <circle id="Ellipse_128" data-name="Ellipse 128" cx="1.423" cy="1.423" r="1.423" transform="translate(12.551 138.194)" fill="#3f3d56" />
                <path id="Path_12595" data-name="Path 12595" d="M251.439,660.443c-1.474,4.621-5.335,16.7-6.913,21.586a.032.032,0,0,0-.006-.009,5.375,5.375,0,0,1-.942-1.784l-.048-.165c1.692-5.306,4.525-14.254,5.764-18.508.033-.029.066-.057.1-.085a5.359,5.359,0,0,1,2.038-1.033Z" transform="translate(-230.894 -543.988)" fill="#3f3d56" />
                <path id="Path_12596" data-name="Path 12596" d="M320.469,789.268H183.895a.251.251,0,1,1,0-.5H320.469a.251.251,0,0,1,0,.5Z" transform="translate(-183.643 -645.235)" fill="#cacaca" />
                <path id="Path_12597" data-name="Path 12597" d="M389.084,147.678c-2.169-2.157-4.246-5.09-7.3-5.089-4.7,0-6.719,6.808-11.319,7.775-2.054.432-4.15-.428-6.249-.465s-4.609,1.487-4.166,3.539l40.015-.6a18.406,18.406,0,0,1-10.976-5.156Z" transform="translate(-387.61 -69.077)" fill="#f0f0f0" />
                <path id="Path_12598" data-name="Path 12598" d="M837.533,124.054c-4.431,0-6.335,6.418-10.671,7.33-1.937.407-3.913-.4-5.892-.439s-4.346,1.4-3.928,3.336l37.726-.569a17.353,17.353,0,0,1-10.348-4.861C842.375,126.818,840.417,124.053,837.533,124.054Z" transform="translate(-718.876 -63.56)" fill="#f0f0f0" />
            </g>
        </svg>

    );
};

export const CloseModalIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 0)">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_211" data-name="Rectangle 211" width="24" height="23" transform="translate(0 0)" fill="none" />
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(0.979 0.479)">
                    <path id="Path_165" data-name="Path 165" d="M12.771,3.958a8.812,8.812,0,1,1-8.812,8.813,8.813,8.813,0,0,1,8.812-8.813m0-1.958A10.722,10.722,0,1,0,20.4,5.14,10.771,10.771,0,0,0,12.771,2Z" transform="translate(-2 -2)" fill="#1a0989" />
                    <path id="Path_166" data-name="Path 166" d="M20.778,19.385l2.742-2.693a1.028,1.028,0,0,0,.1-1.322.93.93,0,0,0-1.469-.1l-2.742,2.742-2.742-2.742a.93.93,0,0,0-1.469.1,1.028,1.028,0,0,0,.1,1.322l2.742,2.693-2.742,2.693a1.028,1.028,0,0,0-.1,1.322.93.93,0,0,0,1.469.1l2.742-2.742L22.148,23.5a.93.93,0,0,0,1.469-.1,1.028,1.028,0,0,0-.1-1.322Z" transform="translate(-8.636 -8.615)" fill="#1a0989" />
                </g>
            </g>
        </svg>

    );
};
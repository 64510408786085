import successMessageStyles from "./styles";
import { useCallback, useEffect, useState } from "react";
import { authService } from "@services";
import { useNavigate } from "react-router";
import { Flex, Spinner } from "@chakra-ui/react";

interface Props {
    message?: string;
}

export const UserVerified = (props: Props) => {

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const verifyUser = useCallback(async () => {
        setLoading(true);
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const token = params.get('token');
        if (!token) {
            return false;
        }
        try {
            const response = await authService.verifyUser(token);
            setLoading(false);
            setTimeout(() => {
                navigate('/auth/sign-in');
            }, 5000);
            return !!response;
        } catch (err) {
            setLoading(false);
            return false;
        }
    }, [navigate]);

    // let verifyUser = async (): Promise<boolean> => {
    //     setLoading(true);
    //     const currentUrl = window.location.href;
    //     const url = new URL(currentUrl);
    //     const params = new URLSearchParams(url.search);
    //     const token = params.get('token');
    //     if (!token) {
    //         return false;
    //     }
    //     try {
    //         const response = await authService.verifyUser(token);
    //         setLoading(false);
    //         setTimeout(() => {
    //             navigate('/auth/sign-in');
    //         }, 5000)
    //         return !!response;
    //     } catch (err) {
    //         console.log(err);
    //         setLoading(false);
    //         return false;
    //     }
    // }

    useEffect(() => {
        verifyUser().then(response => {
            setSuccess(response);
        }).catch(err => {
        });
    }, [verifyUser]);

    return (
        loading ? <successMessageStyles.Loader /> :
            <successMessageStyles.Wrapper>
                <successMessageStyles.MessageWrapper success={success}>
                    <successMessageStyles.Message success={true}>
                        {success ? <Flex flexDirection={"column"}>
                            <div>Success!</div>
                            <Spinner />
                        </Flex> : 'Error!'}
                    </successMessageStyles.Message>
                </successMessageStyles.MessageWrapper>
            </successMessageStyles.Wrapper>
    );
};

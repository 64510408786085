// state
import {Alert} from "../../models/alerts";

export interface AlertsState {
  loading: boolean;
  data: Alert[];
}

// initial state
export const initalAlertsState: AlertsState = {
  loading: false,
  data: [],
};

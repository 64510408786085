import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Progress, Spinner
} from "@chakra-ui/react";
import lottieLoader from "../../utils/lottie-animations/loading-success-white.json";
import Dropzone from "./dropzone/Dropzone";
import {styles} from './styles'
import axios from "axios";
import {operationService} from "../../services/operations/operation.service";
import {useDispatch, useSelector} from "react-redux";
import {organizationSelector} from "@store";
import lottieAnimation from "../../utils/lottie-animations/loading-success-white.json";
import {LottieRef} from "lottie-react";
import {AiOutlineClose} from "react-icons/ai";
import {CloseModalIcon} from "../../utils/icons/save-modal";
import {operationsStateSelector, uploadFilesThunk} from "../../store/operation";
interface Props {
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
    title?: string;
    maxFiles: number;
    subTitle?: string;
    filesAccept?: {[key:string]: string[]};
    maxSize?: number;
    actionCallback?: (file: File[]) => void;
    isLoading?: boolean;
    messageDisplay?: string;
}

const FileUploadDialog = (props: Props) => {
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const [progress, setProgress] = useState(0);
    const organization = useSelector(organizationSelector);
    const animationRef = useRef<any>(null);
    const dispatch = useDispatch();
    const operation = useSelector(operationsStateSelector);
    /*useResetStateByProgress(progress, setDroppedFiles);*/

    useEffect(() => {
        setDroppedFiles([]);
        setProgress(0);
    },[props?.isOpen]);

    const uploadFiles = async () => {
        setProgress(0);
        const formData = new FormData();
        droppedFiles?.forEach((file) => {
            formData?.append('file', file);
        })
        if(props?.actionCallback){
            props?.actionCallback(droppedFiles)
        }else {
            !!organization?.id && await dispatch(uploadFilesThunk({organizationId: organization?.id, data: formData}))
        }
    }



    return (
        <Modal isOpen={props?.isOpen} onClose={props?.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex>
                        <styles.ModalTitleHolder>
                            {props?.title ?? "Upload Files"}
                            <styles.ModalSubTitle>
                                {props?.subTitle ?? `CSV and ZIP only, up to 25mb`}
                            </styles.ModalSubTitle>
                        </styles.ModalTitleHolder>
                        <Flex _hover={{
                          cursor: "pointer"
                        }} onClick={props?.onClose}>
                            <CloseModalIcon />
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Dropzone setDroppedFiles={setDroppedFiles} droppedFiles={droppedFiles} progress={progress} maxFiles={props?.maxFiles} maxSize={props?.maxSize} accept={props?.filesAccept ?? {
                        'application/zip': ['.zip'],
                        'text/csv': ['.csv'],
                    }}/>
                </ModalBody>

                <ModalFooter>

                    <Flex w={"100%"} flexDirection={"column"}>
                        <Flex alignSelf={"flex-end"} w={"max-content"}  flexDirection={"column"}>
                            <styles.UploadButton disabled={droppedFiles?.length === 0 || progress === 100} onClick={uploadFiles}>
                                {props?.isLoading || operation?.isFilesUploading ? <Spinner /> : "Upload"}
                                {/*{ progress > 0 ? <styles.LottieStyle loop={false} ref={animationRef} animationData={lottieAnimation}/> : "Upload"}*/}
                            </styles.UploadButton>
                            <Flex  alignSelf={"center"}  maxW={'55%'}>
                                {props?.messageDisplay}
                                {operation?.filesUploadError ? "Error" : ""}
                            </Flex>
                        </Flex>

                       {/* <Flex flexDirection={"column"}>
                            {progress === 100 ? "Files Uploaded" : ""}
                            <Progress colorScheme='purple' hasStripe value={progress} />
                        </Flex>*/}
                    </Flex>
                </ModalFooter>

            </ModalContent>
        </Modal>
    );
};

export default FileUploadDialog;

import { MenuItem, MenuList } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
  export const Info = styled.div`
    display: flex;
    align-items: center;
  `;

  export const HeaderIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `;

  export const Title = styled.div`
    color: black;
    font-size: 25px;
    font-weight: 600;
    margin-right: 5px;
    display: flex;
  `;

  export const StyledMenuList = styled(MenuList)`
    padding-top: 0;
    padding-bottom: 0;
  `;

  export const HeaderButton = styled.div`
    padding: 5px;
    margin: 0 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border: solid 1px #ebebeb;
    &:hover {
      cursor: pointer;
    }
  `;

  export const HeaderButtonText = styled.div`
    font-size: 12px;
    margin: 0 10px;
  `;
  export const SectionOneWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 91%;
  `;
  export const SectionTwoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 91%;
    margin: 31px 0 0 0;
  `;
  export const SectionOneLeft = styled.div`
    display: flex;
    flex-direction: column;
    height: 206px;
    justify-content: space-between;
  `;
  export const SectionOneRight = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 188px;
    max-height: 206px;
    justify-content: space-between;
  `;
  export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
  `;
  export const Image = styled.img<{ src: string }>`
    width: 85px;
    height: 91px;
    src: ${(props) => props.src};
    border-radius: 50%;
  `;
  export const ImageTitle = styled.div`
    font-size: 15px;
    color: #31394d;
    margin: 0 0 0 11px;
    display: flex;
    flex-direction: column;
  `;
  export const ImageSubTitle = styled.div`
    font-size: 13px;
    color: #31394d;
  `;

  export const StyledMenuItem = styled(MenuItem)`
    width: 606px;
    border-bottom: 1px solid #31394d;
    &:last-of-type {
      border: none;
    }
  `;
  export const Divider = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: #31394d;
    margin: 35px 0 0 0;
    opacity: 0.3;
  `;
  export const SectionTwoLeft = styled.div`
    display: flex;
    width: 476px;
    flex-direction: column;
  `;
  export const SectionTwoRight = styled.div`
    display: flex;
    margin: 0 0 0 188px;
    justify-content: flex-end;

    flex-direction: column;
  `;

  export const SectionTwoTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
  `;
  export const SectionTwoSubtitle = styled.div`
    font-size: 16;
    color: #929bb2;
    letter-spacing: 0;
  `;
  export const StyledButton = styled(Button)`
    background-color: ${(props) => props.theme.primaryColor} !important;
    color: white;
    border-radius: 10px !important;
    width: 188px !important;
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
    &:hover {
      background-color: ${(props) => props.theme.primaryColor} !important;
    }
  `;
  export const StyledButtonDisabled = styled(Button)`
    background-color: gray !important;
    color: black;
    border-radius: 10px !important;
    width: 188px !important;
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
    /*  &:hover {
      background-color: ${(props) => props.theme.primaryColor} !important;
    } */
  `;
}

export default styles;

import integrationsStyles from "./styles";
import {IntegrationsTable} from "./integrtions-table/IntegrationsTable";
import {AddIntegrations} from "./add-integrations/AddIntegrations";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {addIntegration, getGoogleAdsAccounts, organizationSelector} from "@store";
import {fetchSystemEvent, fetchSystemEventIntegrations, SystemEventsType} from "../../store/system-events";
import {integrationSelector} from "../../store/integrations";
import GoogleAdsAccountsModal from "./integrtions-table/google-ads-accounts-modal/GoogleAdsAccountsModal";
import {Button, useDisclosure} from "@chakra-ui/react";
import {Integration} from "@models";

export const Integrations = () => {
    const organization = useSelector(organizationSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();




    const dispatch = useDispatch();
    const integrations = useSelector(integrationSelector);
      useEffect(() => {
             const integrationsArray = integrations?.integrations?.map((item) => item?.id)?.filter((integrationId) => !!integrationId);
         if (!!organization?.id && !!integrationsArray) {
             // @ts-ignore
             dispatch(fetchSystemEventIntegrations({ organizationId: organization?.id, integrations: integrationsArray }));
         }
     }, [dispatch, organization?.id, integrations?.integrations]);

    // hooks


    // helpers

    // renderers



    return (
        <integrationsStyles.Wrapper>
            <integrationsStyles.SmallerWrapperBackground>
                <GoogleAdsAccountsModal onClose={onClose} onOpen={onOpen} isOpen={organization?.isOpenAccountsModal}/>
                {<IntegrationsTable />}
                {<AddIntegrations />}
            </integrationsStyles.SmallerWrapperBackground>
        </integrationsStyles.Wrapper >
    );
};

import successMessageStyles from "./styles";
import { useEffect, useState } from "react";
import { organizationService } from "@services";
import { useNavigate } from "react-router";
import { Spinner } from "@chakra-ui/react";

interface Props {
  message?: string;
}

export const InviteVerified = (props: Props) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const verifyUser = async (): Promise<any> => {
    setLoading(true);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const token = params.get("token");
    if (!token) {
      return false;
    }
    try {
      const response = await organizationService.addUser(token);
      setLoading(false);
      if (!!response.data?.organization) {
        return !!response.data?.organization;
      } else {
        if (!response.data.isUserExist) {
          navigate("/auth/sign-up", {
            state: { token: token, email: response.data.email },
          });
        }
      }
    } catch (err) {
      setLoading(false);
      return false;
    }

  };

  useEffect(() => {
    verifyUser()
      .then((response) => {
        setSuccess(response);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if(success){
      redirectToApp()
    }
  }, [success])

  const redirectToApp = () => {
    navigate('/admin')
  }

  return loading ? (
    <successMessageStyles.Loader />
  ) : (
    <successMessageStyles.Wrapper>
      <successMessageStyles.MessageWrapper success={success}>
        <successMessageStyles.Message success={true}>
          {success ? (
            <>
              Success!
            </>
          ) : (
            "Error!"
          )}
        </successMessageStyles.Message>
      </successMessageStyles.MessageWrapper>
    </successMessageStyles.Wrapper>
  );
};

import React, { useEffect, useState } from 'react';
import styles from './styles';

import {Box, Modal, ModalContent, ModalOverlay, Radio, RadioGroup} from '@chakra-ui/react';
import { Cogs } from 'models/products';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { addCogs, editCogs, editProducts, productsDataSelector, updateProducts } from 'store/products';
import {
    customCogsDataSelector,
    customCogsSelector,
    organizationDataLanguageSelector,
    organizationSelector
} from '@store';
import { currencyOptions } from 'utils/currency/currencyOptions';
import {useCurrencySign} from "../../../../utils/custom-hooks/useCurrencySign";
import {pnlDataLanguageNamesSelector} from "../../../../store/pnl";


interface Props {
    isOpen: boolean,
    onApply: (newCog: Cogs) => void,
    onClose: () => void;
    isPercentage?: boolean;
    name?: string;
    fromFob?: boolean;
    editModal?: boolean;
    setUpdate?: any;
    setApplyFlag?: any;
    data: any;
}

export const AddColumnModal = (props: Props) => {
    const products = useSelector(productsDataSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation(['translation', 'translation']);
    const [sameNameError, setSameNameError] = useState<boolean>(false);
    const [validity, setValidity] = useState<boolean>(false);
    const organization = useSelector(organizationSelector);

    const { isOpen, onClose } = props;
    const currentCurrency = useCurrencySign();
    const allMetricNames = useSelector(pnlDataLanguageNamesSelector)?.data;
    const [fromFob, setFromFob] = useState('fob');
    const [isPercentage, setIsPercentage] = useState(true);
    const [name, setName] = useState('');
    const [initialName, setInitialName] = useState<string>("");
    const allCogs = useSelector(customCogsDataSelector);
    useEffect(() => {
        setFromFob('fob');
        setIsPercentage(true);
        setName('');
        setSameNameError(false);
    }, [isOpen]);
    useEffect(() => {
        setValidity(name?.length > 0)
    }, [name])

    useEffect(() => {

        if (props.isPercentage === false) {
            setIsPercentage(false);
        }
        if (props?.name) {
            setName(props?.name);
            setInitialName(props?.name);
        }
        if (props?.fromFob === false) {
            setFromFob('sellingPrice');
        }
    }, [isOpen, props?.fromFob, props.isPercentage, props?.name]);


    useEffect(() => {
        if(!!allCogs && !!allMetricNames){
          const defaults =  ['fob', 'updatesAt', 'product', 'channel', 'sellingprice'];
          const allCogsNames= allCogs?.map((item) => item?.name?.toLowerCase()).concat(defaults);
          const allMetricNamesLowerCase = allMetricNames?.map((item) => item?.toLowerCase());
          const isSameName: boolean = allCogsNames?.includes(name?.toLowerCase()) || allMetricNamesLowerCase?.includes(name?.toLowerCase())
          setSameNameError(isSameName)

        }
    }, [name, allMetricNames])

    const createNewCogsColumn = () => {
        if(!sameNameError){
            if(!!name){
                const cogs = {
                    name: name,
                    isPercentage: isPercentage,
                    fromFob: fromFob === 'fob',
                    value: 0
                };
                if (props.editModal) {
                    !!organization?.id && !!dataLanguage?.id && dispatch(editCogs({ organizationId: organization?.id, cogsName: initialName, cogs, dataLanguageId: dataLanguage?.id }));
                } else {
                    !!organization?.id && !!dataLanguage?.id && dispatch(addCogs({ organizationId: organization?.id, cogs, dataLanguageId: dataLanguage?.id}));
                }
                onClose();
            }
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius={10} bg={'transparent'}>
                <styles.Wrapper>
                    <styles.Header>
                        <styles.TypeButton isFocus={!isPercentage} onClick={() => setIsPercentage(false)}>
                            <div>{t("BY_VALUE", { ns: "translation" })}</div>
                            <div>{currentCurrency}</div>
                        </styles.TypeButton>
                        <styles.TypeButton isFocus={isPercentage} onClick={() => setIsPercentage(true)}>
                            <div>{t("BY_PERCENTAGE", { ns: "translation" })}</div>
                            <div>{"%"}</div>
                        </styles.TypeButton>
                    </styles.Header>
                    <styles.Content>
                        <styles.Title>
                            {t("NAME", { ns: "translation" })}:
                        </styles.Title>
                        <styles.Input value={name} placeholder={'Name'} onChange={e => setName(e.target.value)} />
                        {
                            isPercentage &&
                            <>
                                <styles.Title>
                                    {t("PERCENTAGE_FROM", { ns: "translation" })}
                                </styles.Title>
                                <RadioGroup h={'100%'} w={'100%'} onChange={setFromFob} value={fromFob}>
                                    <styles.RadioButtonsWrapper>
                                        <Radio size={'lg'} colorScheme={'purple'} value={'fob'}>{t("FOB", { ns: "translation" })}</Radio>
                                        <Radio size={'lg'} colorScheme={'purple'} value={'sellingPrice'}>
                                            {t("SELLING_PRICE", { ns: "translation" })}
                                        </Radio>
                                    </styles.RadioButtonsWrapper>

                                </RadioGroup>
                            </>
                        }
                    </styles.Content>
                    <styles.FlexBox>
                    {sameNameError ? "Error - COGS Names must be unique" : ""}
                    </styles.FlexBox>
                    <styles.Footer>
                        <styles.FooterButton onClick={onClose}>
                            {t("CANCEL", { ns: "translation" })}
                        </styles.FooterButton>
                        <styles.FooterButton disabled={sameNameError && !validity} isApply={!sameNameError && validity} onClick={createNewCogsColumn}>
                            {t("APPLY", { ns: "translation" })}
                        </styles.FooterButton>
                    </styles.Footer>
                </styles.Wrapper>
            </ModalContent>
        </Modal>
    );
};


export default AddColumnModal;

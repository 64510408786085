import {collectorsBaseUrl} from "./general";

export const shopifyUrl = `${collectorsBaseUrl}/shopify`;

// endpoints
export const fetchShopifyOAuthUrlUrl = `${shopifyUrl}/oauth`;
export const setShopifyOAuthDataUrl = `${shopifyUrl}/oauth/callback`;

// shop
// export const defaultShopifyShopName = 'wideview-test-1.myshopify.com';
export const defaultShopifyShopName = 'wideview-dev.myshopify.com';

import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {UsersState} from "./state";

export const usersData = (state: RootState): UsersState => state.usersKpis;

export const usersDataSelector = createSelector(
  usersData,
  (state) => state.data
);

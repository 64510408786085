import styles from "./styles";
import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Checkbox, CheckboxGroup, Flex,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    useCheckboxGroup,
    useDisclosure
} from '@chakra-ui/react';
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {useTheme} from "@styles";
import {getChannelNameById} from "../../utils/get-channel-name/getChannelNameById";
import {useSelector} from "react-redux";
import {channelsStateSelector} from "../../store/channels";

export interface Props {
    title: string;
    icon?: (color: string) => JSX.Element;
    options?: string[];
    setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
    defaultCheckItems?: string[];
    displayButtons?: true;
    onApply?: (e?:string[])=> void;
    onCancel? :()=> void;
    displayChannelName?: true;
    createMetricModal?: boolean;
    borderRadius?: number;
    backgroundColor?: string;
    boldText?: boolean;
}

export const CheckboxMenuSmall = (props: Props) => {

    //hooks
    const channels = useSelector(channelsStateSelector);

    const { t } = useTranslation(['translation', 'translation']);
    const theme = useTheme();
    const { value, getCheckboxProps } = useCheckboxGroup();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    useEffect(() => {
        if (!!props?.defaultCheckItems) {
            setCheckedItems(props?.defaultCheckItems);
        }
    }, [isOpen, props?.defaultCheckItems]);

    //helpers
    const onClickHelper = (e: any) => {

    if (checkedItems.includes(e.target?.value)) {
        const newItems = checkedItems.filter((item) => item !== e.target?.value);
        setCheckedItems(newItems);
        !!props?.setSelectedItems && props?.setSelectedItems(newItems);
    }
    else {
        setCheckedItems(prev => {
            const newData = [...prev, e.target?.value]
            !!props?.setSelectedItems && props?.setSelectedItems(newData);
            return newData
        });

    }
};

    //renders
    const renderOpenMenuButton = (isOpen: boolean) => {
        return (
            <styles.OpenMenuButton backgroundColor={props?.backgroundColor} isBold={props?.boldText} createMetricModal={props?.createMetricModal} borderRadius={props?.borderRadius} isOpen={isOpen}>
                {props.icon !== undefined ? (props.icon(isOpen ? "white" : theme.primaryColor)) : <></>}
                {props?.createMetricModal ? <Box w={'100%'} position={'relative'}>
                        <Flex justifyContent={'center'} w={'100%'}>
                            {t(props.title, { ns: 'translation' })}
                    </Flex>

                    <Box right={'-3px'} top={'50%'} transform={'translateY(-50%)'} position={'absolute'}>
                        {
                            isOpen ?
                                <IoIosArrowUp color={"#555"}/>
                                :
                                <IoIosArrowDown color={"#555"}/>
                        }
                    </Box>

                    </Box>
                    :
                    <>
                    <div style={{ width: "5px" }} />
                {t(props.title, { ns: 'translation' })}
                    <div style={{ width: "5px" }} />
                {
                    isOpen ?
                    <IoIosArrowUp />
                    :
                    <IoIosArrowDown />
                }
                    </>
                }

            </styles.OpenMenuButton>
        );
    };

    const displayButtons = () => {
        if (props.displayButtons) {
          return (
            <styles.ButtonsWrapper>
              { !props?.createMetricModal &&
                <styles.CancelButton
                  onClick={() => {
                    !!props.onCancel && props.onCancel();
                    onClose();
                  }}
                >
                  {t("CANCEL", { ns: "translation" })}
                </styles.CancelButton>
              }
              <div style={{ width: "10px" }} />
              <styles.ApplyButton
                onClick={() => {
                  onClose();
                  !!props.onApply && props.onApply(checkedItems);
                }}
              >
                {t("APPLY", { ns: "translation" })}
              </styles.ApplyButton>
            </styles.ButtonsWrapper>
          );
        } else {
          return <></>;
        }
    }

    function arraysEqualWithoutNulls(arr1: any[], arr2: any[]): boolean {
        // Remove null values from the arrays
        const filteredArr1 = arr1.filter((item) => item !== null);
        const filteredArr2 = arr2.filter((item) => item !== null);

        if (filteredArr1.length !== filteredArr2.length) {
            return false;
        }

        const sortedArr1 = filteredArr1.slice().sort();
        const sortedArr2 = filteredArr2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    const handleSelectAllClick = () => {
        if(!!props?.options){
            if(arraysEqualWithoutNulls(checkedItems, props?.options)){
                setCheckedItems([]);
                !!props?.setSelectedItems && props?.setSelectedItems([]);
            }else{
                setCheckedItems(props?.options);
                !!props?.setSelectedItems && props?.setSelectedItems(props?.options);

            }
        }
    }



    return (
        <Popover  isOpen={isOpen} onOpen={onOpen}
                 onClose={onClose} placement={'bottom'}>
            {({ isOpen, onClose }) => (
                <Box zIndex={51} position={'relative'}>
                    <PopoverTrigger>
                        {renderOpenMenuButton(isOpen)}
                    </PopoverTrigger>
                    <PopoverContent
                        width={`auto`}
                        borderWidth={0}
                        borderRadius={19}
                        padding={3}
                        zIndex={50}
                        _focus={{ boxShadow: "5px 31px 54px #695F9736" }}
                        position="relative"
                        boxShadow={'5px 31px 54px #695F9736'}>
                        <Box maxH={"250px"} overflow={"auto"}>
                            <CheckboxGroup colorScheme='purple' value={checkedItems} >
                                <Stack>
                                    <Checkbox onChange={handleSelectAllClick} isChecked={!!props?.options && arraysEqualWithoutNulls(checkedItems, props?.options)}>
                                        Select All
                                    </Checkbox>
                                    {props?.options?.map((item, i) => {
                                        if(!item){
                                            return
                                        }
                                        return (
                                            <Checkbox
                                                value={item}
                                                onChange={onClickHelper}
                                            >
                                                {props?.displayChannelName ? getChannelNameById(item, channels?.channels) : item }
                                            </Checkbox>
                                        );
                                    })}
                                </Stack>
                            </CheckboxGroup>
                        </Box>
                        <div style={{ height: "10px" }} />
                        {displayButtons()}
                    </PopoverContent>
                </Box>
            )}
        </Popover>
    );
};

import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  /* export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    scroll-behavior: smooth;
  `; */

  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    margin-bottom: 50px;
    //padding: 0px 18px;
  `;
  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 21px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
    margin: 55px 0 0 0;
  `;
  export const LoaderWrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
  `;

  export const TopBarWrapper = styled.div`
    width: 90%;
    display: flex;
  `;
}

export default styles;

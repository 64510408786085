import styled from "styled-components";

export namespace Styles {
    export const Wrapper = styled.div`
      background-color: ${props => props.theme.backgroundColor};
      display: flex;
      justify-content: start;
      align-items: start;
      flex-direction: column;
      padding: 20px;
    `;

    export const ButtonWrapper = styled.div`
      margin-right: 10px;
      margin-top: 10px;
    `
}

export default Styles;

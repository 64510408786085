import styled from "styled-components";

export namespace styles {
    export const PremiumWrapper = styled.div<{fixedTopRight?: boolean, width?: string, height?: string}>`
      color: ${props => props?.theme?.primaryColor};
      max-width: ${props => props?.width ? props?.width : '25px'};
      max-height: ${props => props?.height ? props?.height : '25px'};
      border-radius: 50%;
      right: ${props => props?.fixedTopRight ? '.5rem' : ''};
      top: ${props => props?.fixedTopRight ? '.5rem' : ''};
      position: ${props => props?.fixedTopRight ? 'absolute' : ''};;
    `

}

export default styles;

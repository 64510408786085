export const WideviewLogoWIcon = (size: { width: number; height: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size.width ?? 45, height: size.height ?? 45 }}
      width="60"
      height="60"
      viewBox="0 -12 55 60"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="1.28"
          x2="0.033"
          y2="-0.021"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#5e00ff" />
          <stop offset="0.256" stopColor="#7416ff" />
          <stop offset="0.79" stopColor="#ac50ff" />
          <stop offset="1" stopColor="#c569ff" />
        </linearGradient>
      </defs>
      <g id="logoMark">
        <path
          id="logoMark_PathItem_"
          data-name="logoMark &lt;PathItem&gt;"
          d="M46.657,0A7.1,7.1,0,0,1,52.9,10.5L41.282,33.721a7.1,7.1,0,0,1-12.92.007l-1.484-2.966L25.4,33.721a7.1,7.1,0,0,1-12.919.007L.864,10.5A7.075,7.075,0,0,1,0,7.1H0A7.1,7.1,0,0,1,7.1,0H46.657Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="logoMark_PathItem_2"
          data-name="logoMark &lt;PathItem&gt;"
          d="M121.9,10.5,110.283,33.721a7.1,7.1,0,0,1-12.92.007l-1.484-2.966,13.484-26.94A7.1,7.1,0,0,1,115.656,0h0A7.1,7.1,0,0,1,121.9,10.5Z"
          transform="translate(-69)"
          fill="#00005b"
        />
        <path
          id="logoMark_PathItem_3"
          data-name="logoMark &lt;PathItem&gt;"
          d="M56.5,30.762,41.163,0H76.179L71.413,1.809Z"
          transform="translate(-29.624)"
          fill="#00005b"
          opacity="0.183"
        />
      </g>
    </svg>
  );
};

// state
export interface WebsiteData {
  id: number;
  link: string;
  pageViews: number;
  sessions: number;
  avgTimeOnPage: number;
  entrances: number;
  bounceRate: number;
  exit: number;
  pageValue: number;
  ecommCr: number;
  transactions: number;
  revenue: number;
  users: number;
  exits: number;
}
export interface TableData {
  salesChannel: any;
  products: any;
  title: string;
  url: any;
  subRows: any;
  browser: string;
  country: string;
  ages: string;
  users: number;
  revenue: number;
  gender: string;
}

export interface ComparisonTable {
  type: string;
  productPage: string;
  users: number;
  sourceMedium: string;
  sessions: number;
  transactions: number;
  revenue: number;
  cr: number;
  aov: number;
}

export interface FunnelGraph {
  sessions: number;
  product: number;
  cart: number;
  checkout: number;
  transactions: number;
}

export interface WebsiteUserTableData {
  rows: TableData[] | undefined;
}

export interface WebsitePerformanceTableData {
  data: ComparisonTable[] | undefined;
}

export interface WebsiteState {
  data: {
    data: WebsiteData[] | undefined;
    userAnalysis: WebsiteUserTableData;
    performanceData: WebsitePerformanceTableData;
    funnelData: {
      data: FunnelGraph[] | undefined;
    };
  };
  websiteData: {

  }
}

export enum WebsiteEvents {
  VIEW_ITEM_LIST,
  BEGIN_CHECKOUT,
  ADD_SHIPPING_INFO,
  ADD_PAYMENT_INFO,
  PURCHASE,
  ADD_TO_CART,
  VIEW_ITEM,
  SELECT_ITEM,
  REMOVE_FROM_CART
}

export interface WebsiteEvent {
  activityDate?:string;
  eventName?:WebsiteEvents;
  browser?:string;
  city?: string;
  category?:string;
  continent?:string;
  country?:string;
  currency?:string;
  medium?:string;

}

// initial state
export const initalWebsiteState: WebsiteState = {
  data: {
    data: undefined,
    userAnalysis: { rows: undefined },
    performanceData: { data: undefined },
    funnelData: { data: undefined },
  },
  websiteData: {

  }
};

import styled from "styled-components";
import {MenuButton, Spinner } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
    justify-content: space-between;
  `;
  export const HeaderHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;

  export const IconShareWrapper = styled.div`
    margin: 0 10px;
  `;

  export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  `;

  export const HeaderIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `;

  export const Title = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    display: flex;
  `;
  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
  `;

  export const Description = styled.div``;

  export const Content = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
  `;

  export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px 0;
    justify-content: space-between;
  `;
  export const RowTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const RowTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
  `;
  export const RowDescription = styled.div``;
  export const RowButton = styled.div`
    border-radius: 30px;
    width: 110px;
    margin: 0 0 0 12px;

    height: 41px;
    border: solid 2px #e2e2e2;
    box-shadow: 0px 13px 16px #0000000a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 25px;

    &:hover {
      cursor: pointer;
    }
  `;

  export const RowButtonText = styled.div`
    font-size: 14px;
  `;

  export const RowButtonIcon = styled.div`
    font-size: 14px;
  `;

  export const RowOkIcon = styled.div``;

  export const ButtonsWrapper = styled.div`
    display: flex;
    position: relative;
    width: 90%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
  export const ButtonsTitle = styled.div`
    color: #7a86a1;
    font-size: 14px;
  `;
  export const StyledMenuButton = styled(MenuButton)`
  `
  export const ButtonsTitleRight = styled.div`
    color: #7a86a1;
    font-size: 14px;
    margin: 0 0 0 14px;
  `;

  export const CheckBoxContainer = styled.div`
    margin: 0 0 0 12px;
  `;

  export const ButtonsYear = styled.div``;
  export const Toggle = styled.div`
    width: 190px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  `;
  export const LockButton = styled.div``;
  export const CardWrapper = styled.div`
    width: 320px;
    height: 145px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 5px 31px 54px #695f9726;
    border-radius: 10px;
  `;
  export const CardTitle = styled.div`
    font-size: 28px;
    font-weight: bold;
    height: 32px;
  `;
  export const CardSubtitle = styled.div`
    font-size: 17px;
    color: #7a86a1;
    height: 20px;
  `;

  export const CardVsLastYear = styled.div`
    width: 140px;
    text-align: center;
    background-color: #e8f6df;
    font-size: 14px;
    margin: 25px 0 11px 15px;
    color: #67c52b;
    border-radius: 50px;
    align-self: flex-start;
  `;
  export const CardHolder = styled.div`
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin: 46px auto 0;
  `;
}

export default styles;

import styled from "styled-components";
import { Avatar, Icon } from "@chakra-ui/react";

export namespace styles {
  export const Wrappers = styled.div`
    //background-color: ${(props) => props.theme.backgroundColor};
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    border-radius: 12px 0 0 0;
    padding: 0 10px;
  `;

  export const UserSessionsWrapper = styled.div`
  width: 100%;
  `;


  export const UserSessionWrapper = styled.div`
    width: 100%;
    margin: 10px 0;
    &:hover {
      cursor: pointer;
    }
  `;
  export const UserSessionsScroll = styled.div`
  display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  `

  export const UserSessionMenuButton = styled(Icon)`
    margin: 5px;
  `;

  export const OrganizationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const OrganizationName = styled.span`
    margin: 5px;
    font-weight: bold;
  `;

  export const OrganizationAvatar = styled(Avatar)`
    //height: 20px!important;
    //width: 20px!important;
    border-radius: 5px !important;
    margin: 5px;
  `;

  export const BottomButton = styled.button`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const UserSession = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
  export const UserSessionFirstRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px;
  `;
  export const UserSessionEmail = styled.span`
    margin: 5px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #464a53;
    font-size: 10px;
  `;
  export const UserSessionDotsButton = styled.div`
    width: 30px;
    height: 30px;
    color: #7a86a1;
  `;
  export const UserSessionName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
  export const UserSessionLogoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
  export const UserSessionLogo = styled.img``;
  export const UserSessionLogoLetter = styled.div`
    border-radius: 9px;
    background-color: ${(props) => props.theme.primaryColor};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    color: white;
  `;
}

export default styles;

export const DarkTheme = {
    id: 'dark',
    primaryColor: '#484dff',
    primaryShadow: `rgba(149, 157, 165, 0.3) 0px 8px 24px`,

    // general
    backgroundColor: '#f9f9ff',
    borderColor: '#f1f1f5',

    // text
    textColor: '#161825',
    titleColor: '#161825',
    descriptionTextColor: '#92929e',

    // input
    inputBackgroundColor: '#fafafb',
    inputColor: '#92929e',
    inputBorderColor: '#f1f1f5',

    // link
    linkColor: '#484dff',
}

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 95%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding: 20px;
  `;
  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
  `;

  export const HeaderHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  export const HeaderIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `;

  export const Title = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    display: flex;
  `;
  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
  `;
}

export default styles;

import { MoreInfoButton } from 'components/more-info-button/MoreInfoButton';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { openDefaultExploreView } from 'store/ui';
import { IconCloseOverlay } from 'utils/icons/account-health';
import { IconGeneral, IconMarketing } from 'utils/icons/explore';
import { SalesChannelsTable } from './sales-channels-table/SalesChannelsTable';
import { styles } from './styles';
import {SalesChannelsRound} from "../../utils/icons/account-health-icons/sales-channels-round";



export const SalesChannelsOverlay = () => {
    const dispatch = useDispatch();
    const Header = () => {
        return (<styles.Header>
            <styles.HeaderHolder>
                <styles.HeaderIcon>
                    {/*<span style={{ width: '50px', height: '50px' }}>*/}
                        <SalesChannelsRound />
                    {/*</span>*/}
                </styles.HeaderIcon>
                <styles.TitleWrapper>
                    <styles.Title>
                        {t('SALES_CHANNELS', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => { }} />
                        {/* <MoreInfoDialog
                    /> */}
                    </styles.Title>
                    <styles.SubTitle>
                        {/*{t('PLEASE_MAP_AND_ALLOCATE_SPECIFIC SPENDS_TO_CHANNELS_AND_PRODUCTS', { ns: 'translation' })}*/}
                    </styles.SubTitle>
                </styles.TitleWrapper>
            </styles.HeaderHolder>

            <styles.HeaderIcon>
                <span style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
                    <button onClick={() => { dispatch(openDefaultExploreView({ isOpen: false })); }}>
                        <IconCloseOverlay />
                    </button>
                </span>
            </styles.HeaderIcon>
        </styles.Header>);
    };

    return (
        <styles.Wrapper>
            <Header />
            <SalesChannelsTable />
        </styles.Wrapper>
    );
};


import { useState, useEffect } from 'react';
import styles from './styles';
import { Ads } from './ads/Ads';
import { Pagination } from './pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { MarketingKpi } from '@models';
import { Box, Flex } from '@chakra-ui/react';
import { Toggle, ToggleOption } from 'components/toggle/Toggle';


interface Props {
  kpiMarketing?: MarketingKpi[];
}

enum FilterMediaBy {
  ALL,
  IMAGES,
  VIDEO
}

export const CreativePerformance = (props: Props) => {
  //state
  const [ads, setAds] = useState<MarketingKpi[]>([]);
  const [loading, setLoading] = useState(false); //since fetching data from the api
  const { t } = useTranslation(['translation', 'translation']);
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage, setAdsPerPage] = useState(8);
  const [filterMediaByStatus, setFilterMediaByStatus] = useState<FilterMediaBy>(FilterMediaBy.ALL);
  const mediaVideoQueryWords = ["dailymotion", "youtube", "youtu", "vimeo", "soundcloud", "mixcloud", "wistia", "vidyard", "twitch", "mp4"];

  useEffect(() => {
    setLoading(true);

    if (!!props?.kpiMarketing) {
      if (filterMediaByStatus === FilterMediaBy.IMAGES) {
        const imagesFilter =
          props?.kpiMarketing
            .filter((item) => {
              return isImage(item?.imageUrl)
            });
        setAds(imagesFilter);
        setCurrentPage(1);
      }

      else if (filterMediaByStatus === FilterMediaBy.VIDEO) {
        const videoFilter =
          props?.kpiMarketing
            .filter((item) => isVideo(item?.imageUrl));

        setAds(videoFilter);
        setCurrentPage(1);
      }

      else {
        setAds(props?.kpiMarketing);
      }
    }
    setLoading(false);
  }, [props?.kpiMarketing, filterMediaByStatus]);

  //helper
  const isVideo = (url?: string) =>
    mediaVideoQueryWords
      .some((mediaChannel) => url?.match(new RegExp(mediaChannel, "i")));

  const isImage = (url?: string) => {
    return !!url && /(?:jpe?g|gif|png)$/i.test(new URL(url).pathname);
  }

  const getIndexOfLastAd = currentPage * adsPerPage;
  const getIndexOfFirstAd = getIndexOfLastAd - adsPerPage;
  const getCurrentAds = ads.slice(getIndexOfFirstAd, getIndexOfLastAd + 1); //slice the number of the ad that we want

  const changePage = (pageNumber: number) => setCurrentPage(pageNumber);

  //render
  return (
    <styles.Section>
      <styles.WrapperInside>
        <styles.Header>
          <styles.Title>{t('CREATIVE_PERFORMANCE', { ns: 'translation' })}</styles.Title>
        </styles.Header>
        <Flex>
          {/*<Box w="20"></Box>
          <styles.WrapperToggle>
            <Toggle options={
              [
                { index: "all", type: FilterMediaBy.ALL, title: `${t('IMAGES', { ns: 'translation' })} + ${t('VIDEOS', { ns: 'translation' })}` },
                { index: "images", type: FilterMediaBy.IMAGES, title: t('IMAGES', { ns: 'translation' }) },
                { index: "videos", type: FilterMediaBy.VIDEO, title: t('VIDEOS', { ns: 'translation' }) },
              ]
            }
              onChange={(option: ToggleOption) => setFilterMediaByStatus(option.type)}
              isNoBorderRadius
            />
          </styles.WrapperToggle>*/}
        </Flex>

        <Ads ads={getCurrentAds} loading={loading} />
        <Pagination
          currentPage={currentPage}
          adsPerPage={adsPerPage}
          totalAds={!!ads?.length ? ads?.length : 0}
          changePage={changePage}
          maxLength={7}
        />
      </styles.WrapperInside>
    </styles.Section >
  )
};
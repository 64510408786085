export const WideViewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="189.49" height="33.19" viewBox="0 0 189.49 33.19">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" y1="4.863" x2="-0.47" y2="-0.521" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#5e00ff" />
                    <stop offset="0.711" stopColor="#7417ff" />
                    <stop offset="0.808" stopColor="#ad50ff" />
                    <stop offset="1" stopColor="#c569ff" />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 0)">
                <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
                    <path id="Path_1774" data-name="Path 1774" d="M131.068,36.786h-5.175l-3.726-9.577L118.5,36.786H113.31L108,20.857l4.658-.193L116,32.2l4.064-11.324,4.272-.222,4.016,11.585,3.379-11.406,4.629-.193Z" transform="translate(-55.868 -10.677)" fill="#fff" />
                    <path id="Path_1775" data-name="Path 1775" d="M170.832,12.643a2.737,2.737,0,1,1,1.961-.8A2.751,2.751,0,0,1,170.832,12.643Zm2.216,17.16H168.7V13.874l4.344-.193Z" transform="translate(-86.952 -3.694)" fill="#fff" />
                    <path id="Path_1776" data-name="Path 1776" d="M196.732,29.252a13.931,13.931,0,0,1-5.044,1.028c-5.527,0-9-3.307-9-8.259s3.408-8.254,8.9-8.254a14.452,14.452,0,0,1,4.755.965V7.82l4.373.227V30.155h-3.862Zm-.377-3.92V18.811a13.231,13.231,0,0,0-4.344-.9c-4.692,0-4.885,2.959-4.885,4.113s.193,4.117,4.885,4.117a12.415,12.415,0,0,0,4.344-.806Z" transform="translate(-94.505 -4.045)" fill="#fff" />
                    <path id="Path_1777" data-name="Path 1777" d="M241.24,30.359l-12.068-.068c.579,1.125,1.863,2.22,4.726,2.22a15.828,15.828,0,0,0,5.2-.9l1.221,3.862a16.383,16.383,0,0,1-6.478,1.183c-5.527,0-9.475-3.307-9.475-8.259s3.659-8.254,9.171-8.254c4.113,0,7.805,2.665,7.805,7.68C241.366,29.07,241.24,30.359,241.24,30.359Zm-12.222-3.6,7.936-.063c0-1.578-1.347-2.414-3.34-2.414C230.664,24.282,229.5,25.57,229.018,26.758Z" transform="translate(-116.066 -10.418)" fill="#fff" />
                    <path id="Path_1778" data-name="Path 1778" d="M266.889,36.812,260.72,20.883l2.182-.1,5.4,14.172,5.459-14.172,2.158-.1-6.2,16.122Z" transform="translate(-134.87 -10.703)" fill="#fff" />
                    <path id="Path_1779" data-name="Path 1779" d="M295.586,11.752a1.366,1.366,0,1,1,1.347-1.385A1.366,1.366,0,0,1,295.586,11.752Zm-1.062,3.017,2.09-.1v16.1h-2.09Z" transform="translate(-152.189 -4.666)" fill="#fff" />
                    <path id="Path_1780" data-name="Path 1780" d="M320.46,29.7l-14.655-.068c.55,3.152,3.215,5.146,7.105,5.146a16.2,16.2,0,0,0,6.275-1.125l.5,1.883a18.454,18.454,0,0,1-6.845,1.154c-5.527,0-9.253-3.307-9.253-8.259s3.533-8.254,9.06-8.254c4.113,0,7.94,2.665,7.94,7.68C320.591,28.516,320.46,29.7,320.46,29.7Zm-14.742-1.8,12.729-.193a5.342,5.342,0,0,0-5.691-5.657C308.441,22.053,305.936,24.3,305.719,27.9Z" transform="translate(-157.046 -10.439)" fill="#fff" />
                    <path id="Path_1781" data-name="Path 1781" d="M338.94,20.827l2.187-.1,4.436,14.138,4.991-14.042,2.732-.13,4.885,14.172,4.5-14.172,2.153-.1-5.1,16.166h-2.9l-4.875-13.627-5.01,13.627h-2.925Z" transform="translate(-175.333 -10.656)" fill="#fff" />
                    <path id="Path_1782" data-name="Path 1782" d="M40.9,0a6.222,6.222,0,0,1,5.464,9.2L36.2,29.556a6.232,6.232,0,0,1-11.329,0l-1.3-2.6-1.3,2.592a6.227,6.227,0,0,1-11.324,0L.758,9.205A6.222,6.222,0,0,1,0,6.222H0A6.217,6.217,0,0,1,6.227,0Z" transform="translate(0 0)" fill="url(#linear-gradient)" />
                    <path id="Path_1783" data-name="Path 1783" d="M71.628,9.2,61.468,29.556a6.232,6.232,0,0,1-11.329,0l-1.3-2.6L60.647,3.35A6.212,6.212,0,0,1,66.159,0h0a6.222,6.222,0,0,1,5.469,9.2Z" transform="translate(-25.265 0)" fill="#00005b" />
                    <path id="Path_1784" data-name="Path 1784" d="M34.4,26.964,20.95,0h30.7L47.47,1.583Z" transform="translate(-10.837 0)" fill="#00005b" opacity="0.2" />
                </g>
            </g>
        </svg>

    );
};

export const WideviewCardPin = () => {
    return (
        <svg id="Component_131_1" data-name="Component 131 – 1" xmlns="http://www.w3.org/2000/svg" width="26.333" height="26.415" viewBox="0 0 30.333 30.415">
            <circle id="Ellipse_117dsadsa" data-name="Ellipse 117dsadsa" cx="15" cy="15" r="15" transform="translate(0.07)" fill="#e8e8e8" opacity="0.374" />
            <g id="Layer_2" data-name="Layer 2" transform="matrix(0.848, -0.53, 0.53, 0.848, 0.125, 11.946)">
                <g id="invisible_box" data-name="invisible box" transform="translate(0 -0.235)">
                    <rect id="Rectangle_1042" data-name="Rectangle 1042" width="22.013" height="22.013" transform="translate(0 0)" fill="none" />
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(3.658 1.66)">
                    <path id="Path_12565" data-name="Path 12565" d="M21.717,15.431H21.58l-2.149-3.886V5.829h.457a.914.914,0,0,0,0-1.829H10.743a.914.914,0,1,0,0,1.829H11.2v5.715L9.052,15.431H8.914a.914.914,0,0,0,0,1.829H14.4v5.029a.914.914,0,1,0,1.829,0V17.259h5.487a.914.914,0,0,0,0-1.829ZM17.6,5.829V9.944H13.029V5.829Zm-4.572,5.944H17.6l1.92,3.658H11.109Z" transform="translate(-8 -4)" fill="#c0bdcc" />
                </g>
            </g>
        </svg>

    );
};
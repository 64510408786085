// state
export enum NotificationState {
  UNREAD,
  READ,
  CLICKED,
  IGNORE,
}

interface NotificationPost {
  title: string;
  text: string;
  image: string;
  user: string;
  organization: string;
}



export enum NotificationStatus {
  SYSTEM,
  BROKEN,
  WARNING,
}

export interface Notification {
  title?: string;
  text?: string;
  image?: string;
  state?: NotificationState;
  status?: NotificationStatus;
  user?: string;
  organization?: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
}

export interface Notifications {
  data?: Notification[];
  loading?: boolean;
  count?: number;
}

export interface NotificationChangeState {
  notifications: string[];
  state: NotificationState;
}

// initial state
export const initialNotificationsState: Notifications = {
  loading: false,
  data: undefined,
  count: undefined,
};

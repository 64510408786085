import styles from "./styles"
import {ColumnDefinitionType} from "./WideviewTable";
import {useResizeColumns} from "react-table";
import {background, Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {ThreeDotMenuButton} from "../3dot-menu-button/ThreeDotMenuButton";
import {MenuButton} from "../menu-generic/MenuGenericButton";
import InfiniteScroll from "react-infinite-scroll-component";

export interface TableRowsProps<T,K extends keyof T> {
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T,K>>;
    menuButton?: any;
}

export const TableRows = <T, K extends keyof T>(props: TableRowsProps<T, K>) => {
    const rows = props.data.map((row, index) => {
        return(
            <>
                <styles.TrCustom key={`row-${index}`}>
                    {props.columns.map((column, index2) => {
                        if (column.key === "id" && !!props.menuButton) {
                            const currentRow : any = row;
                            return <styles.TdCustom key={`cell-${index2}`}>
                                <Box key={index2} padding="0"
                                     width={"100%"} height={21}>
                                    {<ThreeDotMenuButton data={props.menuButton} size={10} callbackId={currentRow?.id}/>}
                                </Box>
                            </styles.TdCustom>
                        }
                        else {
                            return (
                                <styles.TdCustom key={`cell-${index2}`}>
                                    <Box key={index2} padding="0"
                                         width={"100%"} height={21}>
                                        {row[column.key]}
                                    </Box>
                                </styles.TdCustom>
                            );
                        }
                    }
                    )}
                </styles.TrCustom>
            </>
          )
    });

    return (
        <styles.TbodyCustom>
            {rows}
            <Box>
            </Box>
        </styles.TbodyCustom>
    );
};

export default TableRows
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import {productsStateSelector} from "../products";

// organization state
export const organizationStateSelector = (state: RootState) =>
  state.organization;

// organization
export const organizationSelector = createSelector(
  organizationStateSelector,
  (state) => state.organization
);

export const organizationIntegrationsSelector = createSelector(
    organizationStateSelector,
    (state) => state.organization?.integrations
);
// holidays
export const holidaysSelector = createSelector(
  organizationStateSelector,
  (state) => state?.organization?.holidays
);

// organization error
export const organizationErrorSelector = createSelector(
  organizationStateSelector,
  (state) => state.error
);

// loading
export const organizationLoadingSelector = createSelector(
  organizationStateSelector,
  (state) => state.loading
);
export const generalSettingsLoadingSelector = createSelector(
    organizationStateSelector,
    state => state?.dataLanguage?.generalSettings?.isLoading
)

export const displayUpdateButtonSelector = createSelector(
    organizationStateSelector,
    state => state?.dataLanguage?.displayUpdate
)

export const pendingLoadingSelector = createSelector(
  organizationStateSelector,
  (state) => state.pendingLoader
);

export const customCogsSelector = createSelector(
  organizationStateSelector,
  (state) => state.organization?.customCogs
);
export const customCogsDataSelector = createSelector(
  organizationStateSelector,
  (state) => state.organization?.customCogsColumns
);

export const chosenCurrencyCurrencySelector = createSelector(
    organizationStateSelector,
    state => state?.dataLanguage?.generalSettings?.chosenCurrency
)
// orgnization data language

export const organizationDataLanguageSelector = createSelector(
  organizationStateSelector,
  (state) => state?.dataLanguage
);

export const organizationDataLanguageMappingSelector = createSelector(
    organizationStateSelector,
    (state) => state?.dataLanguage?.marketingMapping?.elements
);

export const pnlPutLoadingSelector = createSelector(
    organizationStateSelector,
    state => state?.dataLanguage?.pnlSettings?.isPutLoading
)
export const pnlPostLoadingSelector = createSelector(
    organizationStateSelector,
    state => state?.dataLanguage?.pnlSettings?.isPostLoading
)
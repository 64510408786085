import styles from "./styles";
import { DownloadButton } from "../../../download-button/DownloadButton";
import { Thead } from "@chakra-ui/react";
import { Column, Row, useRowSelect, useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { IconClearFiltersArrow } from "../../../../utils/icons/explore";
import { CurrencyCode, organizationDataLanguageSelector, organizationSelector } from "@store";
import { currency } from "components/general-overlay/GeneralOverlay";
import { useSelector } from "react-redux";
import { currencyOptions } from "utils/currency/currencyOptions";
import {useCurrencySign} from "../../../../utils/custom-hooks/useCurrencySign";

interface Props {
    title?: string;
    headers: string[];
    data: ColumnData[];
    icon: any;
    selectedRows: any;
    selectedRow: any;
}

interface Data {
    col_0: string;
    col_1: number;
    col_2: number | string;
    col_3: number;
}

export interface ColumnData {
    website: string;
    sessions: number;
    sharesOfTotal: number;
    vsLastYear: number;
}


export const SessionsTable = (props: Props) => {

    const dataToDataTable = (data: ColumnData[]): Data[] => {
        return data.map((row, index) => {
            return {
                col_0: row.website,
                col_1: row.sessions,
                col_2: row.sharesOfTotal,
                col_3: row.vsLastYear,
            };
        });
    };

    const headersToColumns = (headers: string[]): Column<Data>[] => {
        return headers.map((header, index) => {
            return { Header: header, accessor: 'col_' + index.toString(), sortType: 'basic' } as Column<Data>;
        });
    };

    // hooks
    const [selectedRows, setSelectedRows] = useState([] as Row<Data>[]);
    const data = useMemo<Data[]>(() => dataToDataTable(props.data), [props.data]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props.headers), [props.headers]);
    const organizationId = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const currentCurrency = useCurrencySign();


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, }, useSortBy, useRowSelect);


    // helpers
    const percentageFormat = (amount: number) => {
        return amount?.toFixed(2).toString() + '%';
    };

    const getProgressBarColor = (percentage: number) => {
        if (percentage < 90) {
            return "#F7686A";
        } else if (percentage < 100) {
            return "#F0BB09";
        }
        return "#A3DB81";
    };

    const selectedRow = (row: Row<Data>) => {
        row.toggleRowSelected();
        const isRowSelected = selectedRows.filter(selectedRow => selectedRow.id === row.id);
        if (!!isRowSelected && isRowSelected.length > 0) {
            setSelectedRows([...(selectedRows.filter(selectedRow => selectedRow.id !== row.id))]);
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);
            setSelectedRows(newSelected);
        }
    };

    const clearFilters = () => {
        rows.forEach(row => {
            if (row.isSelected) {
                row.toggleRowSelected();
            }
        });
    };

    // renderers
    const renderClearFiltersButton = () => {
        return <styles.ClearFiltersButtonWrapper onClick={() => {
            setSelectedRows([] as Row<Data>[]);
            clearFilters();
        }}>
            <IconClearFiltersArrow />
        </styles.ClearFiltersButtonWrapper>;
    };

    return <styles.Wrapper>
        <styles.Card>
            <styles.Header>
                <styles.Title>
                    <span style={{ marginRight: '5px' }}>
                        {props.icon}
                    </span>
                    {props.title}
                </styles.Title>
                {selectedRows?.length > 0 ? renderClearFiltersButton() : <></>}
                <DownloadButton onClick={() => console.log('download')} />
            </styles.Header>

            <styles.TableCustom variant='simple' {...getTableProps()}>
                <Thead>
                    {headerGroups.map(headerGroup => (
                        <styles.TrCustom

                            {...{ selected: true, ...headerGroup.getHeaderGroupProps() }}
                        >
                            {
                                headerGroup.headers.map(column => {
                                    return (
                                        <styles.ThCustom {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}

                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <GoTriangleUp />
                                                        : <GoTriangleDown />
                                                    : ''}
                                            </span>
                                        </styles.ThCustom>
                                    );
                                })}
                        </styles.TrCustom>
                    ))}

                </Thead>
                <styles.TbodyCustom  {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row);
                            return <styles.TrCustom
                                {...{ selected: props.selectedRows.length === 0 || props.selectedRows.some((item: { id: string; }) => item.id === row.id), ...row.getRowProps() }}
                                onClick={() => {
                                    props.selectedRow(row);
                                }}>
                                <styles.TdCustom>{row.cells[0].value}</styles.TdCustom>
                                <styles.TdCustom>{(row.cells[1].value)}</styles.TdCustom>
                                <styles.TdCustom>{percentageFormat(row.cells[2].value)}</styles.TdCustom>
                                <styles.TdCustom>{percentageFormat(row.cells[3].value)}</styles.TdCustom>
                            </styles.TrCustom>;
                        })}
                </styles.TbodyCustom>
            </styles.TableCustom>

        </styles.Card>
    </styles.Wrapper>;
};

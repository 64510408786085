import { apiBaseUrl } from "@constants";
import {CurrencyCode, DataLanguage} from "@store";
import axios, { AxiosResponse } from "axios";
import { PlanningData } from "components/Planning/Planning";
import { Kpi } from "models/kpi";
import {Channel} from "../../store/channels";
import {KpisEnum} from "../../models/kpi-settings";

export interface KpiWorkspace {
  pinnedKpis: {
    marketing?: number[];
    finance?: number[];
    operation?: number[];
    website?: number[];
  };
  userId: string;
  workspaceId: string;
}

interface KpiResponse {
  count: number;
  data: Kpi[];
  filters: any;
  metadata: any;
}

export enum PnlReportShowBy{
  DATE,
  CHANNEL,
  PRODUCT
}

export enum PnlReportGroupBy{
  DAY,
  WEEK,
  MONTH
}

export enum BlendedRoasReportShowBy{
  GROSS_REVENUE,
  NET_REVENUE,
  GROSS_PROFIT
}


export interface PnlReportQueryDto{
  fromDate: string;
  toDate: string;
  channels: string[];
  showBy: PnlReportShowBy;
  groupBy: PnlReportGroupBy;
}

export interface BlendedRoasReportsQueryDto{
  fromDate: string;
  toDate: string;
  channels?: string[];
  showBy: 3;
  showByReport: BlendedRoasReportShowBy;
}

export interface SummarizeResponseDto {
  value?: number;
  lastPeriod?: number;
  shareOfTotal?: number;
  byChannels?: SummarizeResponseDto[];
  pointsLastPeriod?: number[];
  points?: number[],
  name?: string;
  displayName?: string;
  kpiNumber?: KpisEnum;
}



const fetchPnlReports = async (
    {organizationId, searchQueryDto}: {organizationId: string, searchQueryDto: PnlReportQueryDto}
): Promise<AxiosResponse<any>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/reports/pnl-json`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: searchQueryDto,
  });
};

const fetchBlendedRoasReports = async (
    {organizationId, searchQueryDto}: {organizationId: string, searchQueryDto: BlendedRoasReportsQueryDto}
): Promise<AxiosResponse<any>> => {
  searchQueryDto['fields'] = ['grossRevenue', 'netRevenue', 'grossProfit']

  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/reports/marketing-json`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: searchQueryDto,
  });
};

const fetchCustomerReports = async (
    {organizationId, searchQueryDto}: {organizationId: string, searchQueryDto: BlendedRoasReportsQueryDto}
): Promise<AxiosResponse<any>> => {
  searchQueryDto['fields'] = ['customers']

  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/reports/marketing-json`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: searchQueryDto,
  });
};


const fetchCpoReports = async (
    {organizationId, searchQueryDto}: {organizationId: string, searchQueryDto: BlendedRoasReportsQueryDto}
): Promise<AxiosResponse<any>> => {
  searchQueryDto['fields'] = ['orders']

  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/reports/marketing-json`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: searchQueryDto,
  });
};

const fetchPnlTableConfig = async (
    {organizationId}: {organizationId: string}
): Promise<AxiosResponse<any>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/standard-table-metadata/pnl`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const fetchBlendedRoasTableConfig = async (
    {organizationId}: {organizationId: string}
): Promise<AxiosResponse<any>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/standard-table-metadata/blendedRoas`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const fetchCacTableConfig = async (
    {organizationId}: {organizationId: string}
): Promise<AxiosResponse<any>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/standard-table-metadata/cac`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const fetchCpoTableConfig = async (
    {organizationId}: {organizationId: string}
): Promise<AxiosResponse<any>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/standard-table-metadata/cpo`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const fetchKpiData = async (
  organizationId: string,
  fromDate: string,
  toDate: string,
  type?: number,
  kpisType?: number,
  pnlFields?: boolean,
  cogsFields?: boolean,
  fields?: string[],
  allDepartments?: boolean,
): Promise<AxiosResponse<KpiResponse>> => {

  return axios.get<KpiResponse>(`${apiBaseUrl}/data/${organizationId}/kpis`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: {
      fromDate: new Date(fromDate)?.toISOString()?.slice(0,10)+"T00:00:00.000Z",
      toDate: new Date(toDate)?.toISOString()?.slice(0,10)+"T23:59:59.999Z",
      channels: "",
      type,
      kpisType,
      pnlFields,
      cogsFields,
      fields,
      allDepartments,
    },
  });
};

const fetchBigWideviewCardsData = async (
    organizationId: string,
    fromDate: string,
    toDate: string,
    channels: string[]
): Promise<AxiosResponse<SummarizeResponseDto>> => {
  //quantity === unitSolds
  const fields = ['grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit', 'orders', 'marketingCost', 'aov', 'cpo', 'blendedRoas', 'quantity'];
  const queryString = fields.map(field => `fields=${encodeURIComponent(field)}`).join('&');
  const channelsQueryString = channels.map(field => `channels=${encodeURIComponent(field)}`).join('&');

  return axios.get<SummarizeResponseDto>(`${apiBaseUrl}/organization/${organizationId}/metrics/summarized?${queryString}&${channelsQueryString}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: {
      fromDate: new Date(fromDate)?.toISOString()?.slice(0,10)+"T00:00:00.000Z",
      toDate: new Date(toDate)?.toISOString()?.slice(0,10)+"T23:59:59.999Z",

    },
  });
};

const fetchDailyStats = async (
    organizationId: string,
    fromDate: string,
    toDate: string,
    channels?: Channel[],
    fields?: string[],
): Promise<AxiosResponse<KpiResponse>> => {

  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/metrics/daily-stats`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: {
      fromDate: new Date(fromDate)?.toISOString()?.slice(0,10)+"T00:00:00.000Z",
      toDate: new Date(toDate)?.toISOString()?.slice(0,10)+"T23:59:59.999Z",
      fields,

    },
  });
};

const fetchGraphData = async (
    organizationId: string,
    fromDate: string,
    toDate: string,
   channels?: string[]
): Promise<AxiosResponse<KpiResponse>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/data/${organizationId}/kpis-finance`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: {
      fromDate: new Date(fromDate)?.toISOString()?.slice(0,10)+"T00:00:00.000Z",
      toDate: new Date(toDate)?.toISOString()?.slice(0,10)+"T23:59:59.999Z",
      channels: channels,
    },
  });
};

const fetchMarketingKpi = async (
    organizationId: string,
    fromDate: string,
    toDate: string,
): Promise<AxiosResponse<KpiResponse>> => {
  return axios.get<KpiResponse>(`${apiBaseUrl}/organization/${organizationId}/marketing`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    params: {
      fromDate: new Date(fromDate)?.toISOString()?.slice(0,10)+"T00:00:00.000Z",
      toDate: new Date(toDate)?.toISOString()?.slice(0,10)+"T23:59:59.999Z",
    },
  });
};


const fetchMarketingMappingData = async (
    organizationId: string
): Promise<AxiosResponse<Kpi>> => {
  return axios.get<Kpi>(`${apiBaseUrl}/organization/${organizationId}/marketing/marketing-mapping`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const fetchCustomTargets = async (
  organizationId: string,
  year: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${apiBaseUrl}/organization/${organizationId}/custom-targets-settings?year=${year}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const putCustomTargets = async (
  organizationId: string,
  data: PlanningData[],
  channels: any[],
  id?: string,
  year?: string,
  currency?: CurrencyCode

): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/custom-targets-settings`,
    {
      data,
      channels,
      id,
      year,
      currency
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const createCustomTargets = async (
    organizationId: string,
    data: PlanningData[],
    channels: any[],
    year: string,
    currency: CurrencyCode
): Promise<AxiosResponse<any>> => {
  return axios.post<any>(
      `${apiBaseUrl}/organization/${organizationId}/custom-targets-settings`,
      {
        data,
        channels,
        year,
        currency
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const assignTopKpi = async ({
  data,
  dataLanguageId,
  organizationId,
}: {
  data: DataLanguage;
  dataLanguageId: string;
  organizationId: string;

}): Promise<AxiosResponse<any>> => {
  console.log(data)
  return axios.put<KpiWorkspace>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}`,
    {
      ...data,
    }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

export const kpiService = {
  fetchKpiData,
  assignTopKpi,
  fetchCustomTargets,
  putCustomTargets,
  createCustomTargets,
  fetchMarketingMappingData,
  fetchMarketingKpi,
  fetchGraphData,
  fetchPnlReports,
  fetchPnlTableConfig,
  fetchDailyStats,
  fetchBlendedRoasTableConfig,
  fetchBlendedRoasReports,
  fetchCpoTableConfig,
  fetchCpoReports,
  fetchCustomerReports,
  fetchCacTableConfig,
  fetchBigWideviewCardsData
};

import styled from "styled-components";

export namespace styles {
    export const Wrapper = styled.div`
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 40px;
    `;

    export const Header = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `

    export const FooterWrapper = styled.div`
    color: #000000;
      border-radius: 0 0 30px 30px;
      height: 55px;
      width: 99.2%;
      box-shadow: 2px 15px 54px #695F9714;
      position: absolute;
      left: 10px;
      bottom: -55px;
      display: flex;
      align-items: center;
      z-index: 0;
      font-family: ${props => props?.theme.fonts?.roboto?.regular};
    `

    export const Info = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
    `

    export const HeaderIcon = styled.div`
      display: flex;
      flex-direction: row;
      margin: 10px;
    `

    export const TitleWrapper = styled.div`
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
    `

    export const Title = styled.div`
      color: black;
      font-size: 20px;
      font-weight: 600;
      margin-right: 5px;
      display: flex;
    `
    export const SubTitle = styled.div`
      color: black;
      font-size: 16px;
      font-weight: normal;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
    `

    export const SubTitleBold = styled.div`
      color: black;
      font-size: 16px;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      font-weight: bold;
    `

    export const Description = styled.div`

    `
    export const MissingCogsBar = styled.div`
      background-color: #F7686A30;
      padding: 15px;
      border-radius: 3px;
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    `

    export const MissingCogsHeadline = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
    `

    export const MissingCogsText = styled.div`
      margin-left: 15px;
      font-size: 20px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #F7686A;
    `

    export const Content = styled.div`
      width: 100%;
      height: 100%;
      padding: 0 0 80px;
    `

    export const OptionsBar = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: max-content;
    `

    export const OptionsSection = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    `

    export const UploadIcon = styled.div`
    background-color: white;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
  &:hover{
    cursor: pointer;
  }
  `

    export const UploadCSV = styled.button`
      background: white;
      color: black;
      padding: 10px 20px;
      font-size: 14px;
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #EBEBEB;
      border-radius: 19px;
    `

    export const FiltersMenu = styled.div`
      background: white;
      color: black;
      padding: 10px 20px;
      font-size: 14px;
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #EBEBEB;
      border-radius: 19px;
      margin-left: 20px;
    `


}

export default styles;

/* eslint-disable jsx-a11y/anchor-is-valid */
import {Bottom, Card, Content, Image, NextButton, PrimaryTitle, SecondaryTitle, Text, Wrapper,} from "./styles";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";


export const OrganizationCreatedLoader = () => {

    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const organization = useSelector(organizationSelector);

    // selectors

    // effects

    // helpers
    const skip = () => {
        navigate('/onboarding/first-invitation');
    }


    // renders
    const renderBottom = () => {
        return (
            <Bottom>
                <NextButton onClick={() => {
                    skip();
                }}>
                    {t('DIVE_RIGHT_IN', {ns: 'translation'})}
                </NextButton>
            </Bottom>
        )
    }

    const renderCard = () => {
        return (
            <Card>
                <Image src={process.env.PUBLIC_URL + '/assets/onboarding-creation.png'}>

                </Image>

                <Text>
                    <PrimaryTitle>
                        {`${t('YOUR_ORGANIZATION_IS_BEING_CREATED', {ns: 'translation'})}`}
                    </PrimaryTitle>
                    <SecondaryTitle>
                        {`${t('WERE_WORKING_ON_FETCHING_YOUR_DATA_IT_MAY_TAKE_SOME_TIME', {ns: 'translation'})}`}
                    </SecondaryTitle>
                </Text>
                {renderBottom()}
            </Card>
        );
    }

    return (
        <Wrapper img={process.env.PUBLIC_URL + '/assets/onboarding-background.png'}>
            <Content>
                {renderCard()}
            </Content>
        </Wrapper>
    );
};

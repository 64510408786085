import styles from "./styles";
import { getColorByPercentage } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import {useTheme} from "@chakra-ui/react";
//import { useTheme } from "@styles";

export interface Props {
    color?: string;
    percentage: number;
    tiny?: boolean;

    targetFontSize?: number;

    deductionTarget?: boolean;
}


export const TargetBadge = (props: Props) => {
    const { t } = useTranslation();
     const theme = useTheme();
    const color = props.color ?? getColorByPercentage(props.percentage, theme, props?.deductionTarget);

    // hooks

    // helper

    // renderers
    return (
        <styles.TargetBadge tiny={props?.tiny} color={color}>
            <styles.TargetText className={'shifted-text'} fontSize={props?.targetFontSize} color={color}>
                {`${props.percentage?.toLocaleString("en", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}% ${t('VS')} ${t('TGT')}`}
            </styles.TargetText>
        </styles.TargetBadge>
    );

};


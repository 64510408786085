// local
import { User } from "@models";

// state
export interface AuthState {
  user?: User;
  // workspaces?: Workspace[]
  token?: String;
  loading: boolean;
  error?: any;
  workspaces?: any;
  resetPasswordLoading: boolean;
  resetPasswordSuccess: any;
  forgotPasswordLoading: boolean,
  forgotPasswordSuccess: any;
  loadingUpdateAvatar: boolean,
  successUpdateAvatar?: boolean;
}

// initial state
export const initialAuthState: AuthState = {
  user: undefined,
  token: undefined,
  loading: false,
  error: undefined,
  workspaces: undefined,
  resetPasswordSuccess: undefined,
  resetPasswordLoading: false,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: undefined,
  loadingUpdateAvatar: false,
  successUpdateAvatar: undefined
};

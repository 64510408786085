import styled from 'styled-components';

export namespace styles {

    export const Wrapper = styled.div<{ isOpen: boolean, isFocus: boolean }>`
      background: ${(props => props.isOpen ? props.theme.primaryColor : "white")};
      color: ${(props => props.isOpen ? "white" : "black")};
      padding: 5px 20px;
      font-size: 14px;
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 0.5px solid;
      border-radius: 19px;
        /*box-shadow: ${(props => props.isFocus ? '0 0 0 0.5px #7031eb' : 'unset')};*/

      border-color: ${(props => !props.isFocus ? '#d2c4c4' : '#7031eb')};
    `
    export const SearchInput = styled.input`
      width: 200px;
      margin-left: 10px;
      font-size: 16px;

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #000000;
      }

      &:focus::placeholder {
        color: transparent !important;
      }

      &:focus-visible {
        outline: unset !important;
      }

      &:focus {
        box-shadow: unset !important;
        border-color: unset !important;
      }
    `

    export const searchButton = styled.button`
      transform: scale(1.5, 1.5);
      padding: 5px;
    `


}

export default styles;



// state
export interface GoogleState {
    code?: string | null;
    loading: boolean;
    error?: string | null;
}

// initial state
export const initialGoogleState: GoogleState = {
    code: null,
    loading: false,
    error: '',
}

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { MoreInfoButton } from "../../components/more-info-button/MoreInfoButton";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { useState } from "react";
import { AccountHealthButton } from "../../components/account-health-button";
import { ExploresEnum, openDefaultExploreView } from "../../store/ui";
import { useDispatch } from "react-redux";
import { IconCOGS } from "../../utils/icons/account-health";
import { useTheme } from "@styles";
import {Box} from "@chakra-ui/react";
import {CogsIcon} from "../../utils/icons/account-health-icons/cogs";

export const Operations = () => {
  // hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(["translation", "translation"]);
  const strategyMoreInfoDialogProps = {
    title: "Operations",
    //subtitle: 'How to use this view?',
    description:
      "In this section, you`ll define the production costs and ensure efficient production tracking for your business.",
    //tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
        videoTitle: 'Best Practices',
        videoSubtitle: 'How to use this view?',
        videoDescription: '5 min explanatory video from our COO',
        videoLinkTitle: 'Click to watch'*/
  } as MoreInfoDialogProps;
  const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] =
    useState(false);

  const buttons = [
    {
      icon: (
        <span
          style={{ color: theme?.primaryColor, width: "100%", height: "100%" }}
        >
          <CogsIcon />
        </span>
      ),
      title: "COGS",
      callback: () => {
        dispatch(
          openDefaultExploreView({
            isOpen: true,
            ExploresEnum: ExploresEnum.COGS,
          })
        );
      },
    },
  ];

  // helpers
  const openStrategyMoreInfoDialog = (isOpen: boolean) => {
    setIsStrategyMoreInfoDialogOpen(isOpen);
  };

  // renderers

  const renderButtons = () => {
    return (
      <styles.ButtonsWrapper>
        {buttons.map((button, index) => {
          return (
            <AccountHealthButton
              key={index}
              title={button.title}
              icon={button.icon}
              callback={button.callback}
            />
          );
        })}
      </styles.ButtonsWrapper>
    );
  };

  //open overlay

  return (
    <styles.Wrapper>
      <Box ml={5}>
      <styles.Title>
        {t("OPERATIONS", { ns: "translation" })}
        <MoreInfoButton onClick={() => openStrategyMoreInfoDialog(true)} />
        <MoreInfoDialog
          props={strategyMoreInfoDialogProps}
          isDialogOpen={isStrategyMoreInfoDialogOpen}
          onClose={() => setIsStrategyMoreInfoDialogOpen(false)}
        />
      </styles.Title>
      <styles.Description>
        {t(
          "SET_TOP_METRICS_FOR_THE_WHOLE_ORGANIZATION_IN_LINE_WITH_YOUR_BUSINESS_STRATEGY",
          { ns: "translation" }
        )}
      </styles.Description>

      {renderButtons()}
      </Box>
    </styles.Wrapper>
  );
};

import {Column, useSortBy, useTable} from "react-table";
import styles from "./styles"
import InfiniteScroll from "react-infinite-scroll-component";
import {Box, Spinner} from "@chakra-ui/react";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import TableHeader from "./wideview-table-header";
import TableRows, {TableRowsProps} from "./wideview-table-rows";
import MenuGenericButton, {MenuButton, MenuGenericProps} from "../menu-generic/MenuGenericButton";

export interface TableProps<T, K extends keyof T> {
    title?: string;
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T,K>>;
    menuButton?: any;
}

export interface ColumnDefinitionType<T, K extends keyof T> {
    key: K;
    header?: string;
}

export const Table = <T, K extends keyof T>(props: TableProps<T, K>) => {
    const [items, setItems] = useState<any>([]);
    const [empty, setEmpty] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    useEffect(() => {
        setItems([...props.data].reverse().slice(0, 5));
        setEmpty(false);
        //console.log(props.data); //TODO check kpi
    }, [props.data]);

    const fetchMoreData = () => {
        setLoader(true);
        setTimeout(() => {
            setItems(items.concat([...props.data].reverse().slice(items.length, items.length + 5)));
            setLoader(false);
        }, 1500);
        if ([...props.data].reverse().slice(items.length, items.length + 5).length === 0) {
            setEmpty(true);
        }
    };

        return (
            <styles.Wrapper>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={!empty}
                    scrollableTarget="scrollableDiv"
                    loader={<></>}
                >
                    <styles.TableCustom>
                        <TableHeader columns={props.columns}/>
                        <TableRows data={props.data} columns={props.columns} menuButton={props.menuButton}/>
                    </styles.TableCustom>
                </InfiniteScroll>
            </styles.Wrapper>
        );
};
export default Table;


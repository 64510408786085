import { Checkbox, Popover, PopoverTrigger } from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { styles } from './checkboxStyles';

interface Props {
    title: string;
    defaultChecked: boolean;
    setChecked: (e: any) => void;
}

export default function DefaultCheckbox(props: Props) {

    return (
        <Popover>
            <PopoverTrigger>
                <styles.styledButton /* error={props.platformDisable ? props.platformError : false} */>
                    {props.title}
                    <MdArrowDropDown />
                </styles.styledButton>
            </PopoverTrigger>

            <styles.StyledPopoverContent w={"max-content"} padding={2} paddingTop={0.5} paddingBottom={0.5}  >
                <Checkbox onChange={(e) => props.setChecked(e.target.checked)} defaultChecked={props.defaultChecked}>
                    All {props.title}
                </Checkbox>
            </styles.StyledPopoverContent>

        </Popover >
    );
}

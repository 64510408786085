import styles from "./styles";
import {ChangeEvent, useEffect, useState} from "react";
import { GoSearch } from "react-icons/go";
import { useTranslation } from "react-i18next";

interface Props {
  onChange: (newText: string) => void;
  onSearch: (newText: string) => void;
  placeholder: string;
  value?: string;
}

export const SearchInput = (props: Props) => {
  // hooks
  const { t } = useTranslation(["translation", "translation"]);
  const [inputIsFocus, setInputIsFocus] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>("");

/*  useEffect(() => {

    props.onChange(searchInputValue);

  }, [searchInputValue]);*/

  // helpers
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(e.target.value);
        props.onChange(e.target.value);
    }

  // renderers

  return (
    <styles.Wrapper isOpen={false} isFocus={inputIsFocus}>
      <styles.searchButton onClick={() => props.onSearch(searchInputValue)}>
        <GoSearch fill={'#7a86a1'}/>
      </styles.searchButton>
      <styles.SearchInput
        onChange={handleChange}
        onFocus={() => {setInputIsFocus(true)}}
        onBlur={() => {setInputIsFocus(false)}}
        value={searchInputValue}
        placeholder={t(props.placeholder, { ns: "translation" })}
      />
    </styles.Wrapper>
  );
};

import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const paymentsStateSelector = (state: RootState) => state.payments;

export const paymentsSelector = createSelector(
  paymentsStateSelector,
  (state) => state.payments
);

export const transactionFeesSelector = createSelector(
    paymentsStateSelector,
    (state) => state.transactionFees
);


import {createSlice} from "@reduxjs/toolkit";
import {initalWebsiteState, WebsiteState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {clearWebsiteState} from "./actions";
import {fetchWebsiteData} from "./thunks";

function buildCleanMarketingStateReducer(
  builder: ActionReducerMapBuilder<WebsiteState>
) {
  builder.addCase(clearWebsiteState, (state, action) => {
    state.data.data = undefined;
    return state;
  });
}

function buildFetchWebsiteDataReducer(
  builder: ActionReducerMapBuilder<WebsiteState>
) {
  builder
    .addCase(fetchWebsiteData.pending, (state, action) => {
      state.data.data = undefined;
      return state;
    })
    .addCase(fetchWebsiteData.fulfilled, (state, action) => {
      state.data = action.payload;
      return state;
    })
    .addCase(fetchWebsiteData.rejected, (state, action) => {
      state.data.data = undefined;
      return state;
    });
}

export const websiteSlice = createSlice({
  name: "website",
  initialState: initalWebsiteState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanMarketingStateReducer(builder);

    // async
    buildFetchWebsiteDataReducer(builder);
  },
});

// reducer
export const websiteReducer = websiteSlice.reducer;

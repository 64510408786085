import {Status} from "../../components/efforts-table/EffortsTable";
import {KpisEnum} from "../../models/kpi-settings";

const REVERSE_KPIS = [KpisEnum.COST_PER_ORDER];

export function getColorByPercentageNew(
    percentage: number,
    theme: any,
    kpiNumber: KpisEnum = KpisEnum.NULL) {
    const isReverse = REVERSE_KPIS.includes(kpiNumber);
    if (percentage > 0) {
        return !isReverse ? theme.successColor : theme.errorColor;
    } else if (percentage < 0) {
        return !isReverse ? theme.errorColor : theme.successColor;
    } else {
        return theme.textColor;
    }
}

export function getColorByPercentage(
    percentage: number,
    theme: any,
    deductionTarget?: boolean
) {
    const BOTTOM_THRESHOLD = 55;
    const TOP_THRESHOLD = 80;
    if (deductionTarget) {
        if (percentage >= 0 && percentage <= BOTTOM_THRESHOLD) {
            return theme.successColor;
        } else if (percentage > BOTTOM_THRESHOLD && percentage <= TOP_THRESHOLD) {
            return theme.warningColor;
        } else if (percentage > TOP_THRESHOLD) {
            return theme.errorColor;
        }
    }
    if (percentage >= 0 && percentage <= BOTTOM_THRESHOLD) {
        return theme.errorColor;
    } else if (percentage > BOTTOM_THRESHOLD && percentage <= TOP_THRESHOLD) {
        return theme.warningColor;
    } else if (percentage > TOP_THRESHOLD) {
        return theme.successColor;
    }
}

export function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
        dates.push(new Date(date).toISOString().slice(0, 10));
        date.setDate(date.getDate() + 1);
    }
    return dates;
}

function reverse(s: string): string {
    return s.split("").reverse().join("");
}

export const gradientDictionary: { [index: number]: string } = {
    0: "linear-gradient(45deg, #FF9A9E 0%, #FAD0C4 99%, #FAD0C4 100%)",
    1: "linear-gradient(to right, #FFECD2 0%, #FCB69F 100%)",
    2: "linear-gradient(to top, #FBC2EB 0%, #A6C1EE 100%)",
    3: "linear-gradient(120deg, #A6C0FE 0%, #F68084 100%)",
    4: "linear-gradient(120deg, #E0C3FC 0%, #8EC5FC 100%)",
    5: "linear-gradient(to top, #D299C2 0%, #FEF9D7 100%)",
    6: "linear-gradient(to top, #A18CD1 0%, #FBC2EB 100%)",
    7: "linear-gradient(to top, #F68084 0%, #FED6E3 100%)",
    8: "linear-gradient(to top, #CD9CF2 0%, #F6F3FF 100%)",
    9: "linear-gradient(to top, #ACCBEE 0%, #E7F0FD 100%)",
};
// takes user id and finds the last number in the id to randomize the color
export const getUserColorById = (userId?: string): string => {
    if (!userId) {
        return gradientDictionary[0];
    }
    const reversedId = reverse(userId);
    const indexOfFirstNumberInString = reversedId.search(/[0-9]/);
    const firstNumber = Number(reversedId[indexOfFirstNumberInString]);
    if (firstNumber in gradientDictionary) {
        return gradientDictionary[firstNumber];
    } else {
        return gradientDictionary[0];
    }
};

export const getColor = (status: Status) => {
    let backgroundColor: string = "";
    let textColor: string = "";
    switch (status) {
        case Status.EXPIRED:
            backgroundColor = "#E8E8E8";
            textColor = "#777777";
            break;
        case Status.EXPIRING_SOON:
            backgroundColor = "#FFD0D0";
            textColor = "#FF0000";
            break;
        case Status.IN_PROGRESS:
            backgroundColor = "#FFF5D3";
            textColor = "#F0BB22";
            break;
        case Status.SOON:
            backgroundColor = "#D5BFFF";
            textColor = "#7031EB";
            break;
    }
    return {backgroundColor, textColor};
};

import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import {SystemEvent, SystemEventsType} from "../../store/system-events";


const fetchSystemEventsIntegrations = async ({  organizationId, integrations} : {organizationId: string, integrations?: string[]}) => {
  const params:any = {};

  if(!!integrations){
    params['integrations'] = integrations;
  }

  let integrationsStr = ''
  integrations?.map((integration, index) => {
    if(index === 0){
      integrationsStr += `integrations=${integration}`
    }else{
      integrationsStr += `&integrations=${integration}`
    }
  })

  return axios.get<SystemEvent>(
      `${apiBaseUrl}/organization/${organizationId}/system-events/integrations?${integrationsStr}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  )
}


const fetchSystemEvents = async ({
  organizationId,
    query,
    type,
}: {
  organizationId: string;
  query?:string;
  type?:SystemEventsType;
  latest?: boolean;
}): Promise<AxiosResponse<any>> => {
  const params = {};
  if(!!query){
    params["query"] = query
  }
  if(type !== undefined){
    params["type"] = type
  }
  return axios.get<SystemEvent>(
    `${apiBaseUrl}/organization/${organizationId}/system-events/`,
    {
      params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};


export const systemEventsService = {
  fetchSystemEvents,
  fetchSystemEventsIntegrations
};

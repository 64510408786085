import React, {useEffect, useState} from "react";
import {PaymentMethodTableRow} from "./TransactionFeesExplore";
import {Cell, Row, useExpanded, useTable} from "react-table";
import {Box, Flex, Input, InputGroup, InputLeftElement, Tooltip} from "@chakra-ui/react";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {useSelector} from "react-redux";
import {isSecondaryExploreOpenSelector} from "../../../store/ui";
import { styles } from "./styles";
import {channelsStateSelector} from "../../../store/channels";
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";

interface Props {
    paymentMethodsRows: PaymentMethodTableRow[];
    updateCell: ({wvChannelId, paymentMethodId, value, isPercentageInput} :
                     {wvChannelId: string, paymentMethodId: string, value: number, isPercentageInput: boolean}) => void;
    updateParentCell: ({wvChannelId, value, isPercentageInput} :
                     {wvChannelId: string, value: number, isPercentageInput: boolean}) => void;
}

const TransactionFeesTable = (props: Props) => {
    const isSecondaryExploreOpen = useSelector(isSecondaryExploreOpenSelector);
    const currentCurrency = useCurrencySign();
    const channels = useSelector(channelsStateSelector)?.channels;
    const [allExpendedRows, setAllExpendedRows] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        setAllExpendedRows({});
    }, [isSecondaryExploreOpen?.isSecondaryExploreOpen]);

    const onRowClick = (row: Row) => {
        if (row?.canExpand) {
            if (row?.id in allExpendedRows) {
                setAllExpendedRows((prev) => {
                    return { ...prev, [row.id]: !prev[row?.id] };
                });
            } else {
                setAllExpendedRows((prev) => {
                    return { ...prev, [row.id]: true };
                });
            }
        }
    };

    function Table({
                       columns: userColumns,
                       data,
                       onRowClick,
                   }: {
        columns: any;
        data: any;
        onRowClick: any;
    }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            state: { expanded },
        } = useTable(
            {
                columns: userColumns,
                data,
                initialState: { expanded: allExpendedRows },
            },
            useExpanded // Use the useExpanded plugin hook
        );

        const tableRows = React.useMemo(
            () =>
                rows?.map((row: any, i) => {
                    prepareRow(row);
                    return (
                        <styles.StyledTr
                            depth={row.depth > 0}

                            {...row.getRowProps()}
                        >
                            {row.cells.map((cell: Cell, i: number) => {
                                if (cell.column.id === "expander") {
                                    return;
                                }

                                const renderChannelName = () => {
                                    let renderContent = '';
                                    if(row?.depth === 0){
                                        const channelName = channels?.find((channel) => channel?.id ===  row?.original?.wvChannelId)?.displayName
                                        renderContent = channelName ?? `Error (${row?.original?.wvChannelId})`
                                    }
                                    if(row?.depth === 1){
                                        renderContent = row?.original?.paymentMethodName
                                    }

                                     return (<>
                                         {renderContent}
                                    </>)
                                }
                                const RenderPercentageInput = () => {
                                    const [value, setValue] = useState(row?.original?.percentage);

                                    const handleOnCellChange = (value: number) => {
                                        if(row?.depth === 1){
                                            props?.updateCell(
                                                {isPercentageInput: true, wvChannelId: row?.original?.wvChannelId, value, paymentMethodId: row?.original?.paymentMethodId})
                                        }
                                        if(row?.depth === 0){
                                            props?.updateParentCell(
                                                {isPercentageInput: true, wvChannelId: row?.original?.wvChannelId, value})
                                        }

                                    }
                                    if(row?.depth === 0){
                                        return (<Flex w={'100%'} justifyContent={'center'}>
                                            <Box w={'275px'} h={'auto'} />
                                        </Flex>)
                                    }

                                    return (
                                        <Flex w={'100%'} justifyContent={'center'}>
                                            <InputGroup maxW={'50%'}>
                                                <InputLeftElement zIndex={0} pointerEvents='none' color='gray.400' children={"%"} />
                                                <Input onChange={(e) => setValue(e?.target?.valueAsNumber)} onBlur={(e) => {
                                                    handleOnCellChange(e?.target?.valueAsNumber)
                                                }} value={value} type={'number'}/>
                                            </InputGroup>
                                        </Flex>
                                    )
                                }

                                const RenderAmountInput = () => {
                                    const [value, setValue] = useState(row?.original?.amount);

                                    const handleOnCellChange = (value: number) => {
                                        if(row?.depth === 1){
                                            props?.updateCell(
                                                {isPercentageInput: false, wvChannelId: row?.original?.wvChannelId, value, paymentMethodId: row?.original?.paymentMethodId})
                                        }
                                        if(row?.depth === 0){
                                            props?.updateParentCell(
                                                {isPercentageInput: false, wvChannelId: row?.original?.wvChannelId, value})
                                        }

                                    }
                                    if(row?.depth === 0){
                                       return (<Flex w={'100%'} justifyContent={'center'}>
                                          <Box w={'275px'} h={'auto'} />
                                        </Flex>)
                                    }

                                    return (
                                        <Flex w={'100%'} justifyContent={'center'}>
                                            <InputGroup maxW={'50%'}>
                                                <InputLeftElement zIndex={0} pointerEvents='none' color='gray.400' children={currentCurrency} />
                                                <Input onChange={(e) => setValue(e?.target?.valueAsNumber)} onBlur={(e) => {
                                                    handleOnCellChange(e?.target?.valueAsNumber)
                                                }} value={value} type={'number'}/>
                                            </InputGroup>
                                        </Flex>

                                    )
                                }

                                const renderExpandIcon = () => {
                                    return (
                                        <Flex alignItems={"center"}>
                                            <Box w={10} />
                                            {cell.render("Cell")}
                                            {row.isExpanded &&
                                            cell.column.id === "title" &&
                                            row.depth !== 1 ? (
                                                <Flex flexDirection={"column"}>
                                                    <Box h={"1px"} w={1} />
                                                    <IoIosArrowDown />
                                                </Flex>
                                            ) : (
                                                ""
                                            )}

                                            {!row.isExpanded &&
                                            cell.column.id === "title" &&
                                            row.depth !== 1 ? (
                                                <Flex flexDirection={"column"}>
                                                    <Box h={"1px"} w={1} />
                                                    <IoIosArrowForward />
                                                </Flex>
                                            ) : (
                                                ""
                                            )}
                                        </Flex>
                                    )
                                }
                                return (
                                    <styles.StyledTitleTd
                                        {...row.getToggleRowExpandedProps({ title: undefined })}
                                        {...cell.getCellProps()}
                                        onClick={() => {
                                            if(cell.column.id === "title"){
                                                onRowClick(row);
                                            }
                                        }}
                                    >
                                        <div
                                            style={
                                                cell.column.id === "title"
                                                    ? {
                                                        fontWeight: "bold",
                                                        padding: `0 0 0 ${row.depth+1 * 25}px`,
                                                        display: "flex",
                                                        alignItems: "center",} : {}}>
                                            {cell?.column?.id === 'title' && renderChannelName()}
                                            {cell?.column?.id === 'byPercentage'  && <RenderPercentageInput />}
                                            {cell?.column?.id === 'byValue'  && <RenderAmountInput />}
                                            {renderExpandIcon()}
                                        </div>
                                    </styles.StyledTitleTd>
                                );
                            })}
                        </styles.StyledTr>
                    );
                }),
            [rows]
        );

        return (
            <styles.StyledTable {...getTableProps()}>
                <styles.StyledThead>
                    {headerGroups.map((headerGroup, i) => (
                        <styles.StyledHeaderTr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => {
                                if (column.id === "expander") {
                                    return;
                                }
                                return (
                                    <styles.StyledTh
                                        style={column.id === "title" ? { textAlign: "left", padding: '0 0 0 25px' } : {}}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </styles.StyledTh>
                                );
                            })}
                        </styles.StyledHeaderTr>
                    ))}
                </styles.StyledThead>
                <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </styles.StyledTable>
        );
    }

    const getHeaders = () => {
        return [
            {
                id: "expander", // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
                    <span {...getToggleAllRowsExpandedProps()}></span>
                ),
                Cell: ({ row }: any) =>
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                                title: undefined,
                            })}
                        ></span>
                    ) : null,
            },
            {
                Header: "Channel",
                accessor: "title",
            },
            {
                Header: "%",
                accessor: "byPercentage",
            },
            {
                Header: currentCurrency,
                accessor: "byValue",
            },

        ];
    };

    const columns = React.useMemo(() => getHeaders(), [currentCurrency]);

    const tableData = React.useMemo(() => props?.paymentMethodsRows, [props?.paymentMethodsRows]);

    return <>
        < Table columns={columns} data={tableData} onRowClick={onRowClick}/>
    </>;
};

export default TransactionFeesTable;

import styled from "styled-components";
import { Props } from "../auth-button";

export const DownloadButtonWrapper = styled.button<Props>`
  min-width: 45px;
  height: 45px;
  border: 1px solid #ebebeb;
  border-radius: 19px;
  background: white;
  display: flex;
  justify-self: center;
  align-items: center;
`;

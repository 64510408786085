import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationService } from "@services";
import { channelsService } from "services/channels/channels.service";

// local
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import {DepartmentsEnum, operationService} from "../../services/operations/operation.service";
import {AccountIntegrations} from "../account";
import {accountService} from "../../services/account/account.service";

export const fetchAccounts = createAsyncThunk<any, { organizationId: string }>(
  "organization/fetchAccounts",
  async ({ organizationId }, thunkAPI) => {
    try {
      const response = await channelsService.fetchAccounts({
        organizationId: organizationId,
      });


      if (isHttpCodeSuccess(response.status) && !!response?.data) {

          return response.data;
      } else {
        throw new Error();
      }
    } catch (err) {

      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCsv = createAsyncThunk<any, {
    organizationId: string,
    fromDate: string,
    toDate: string,
    type?: number,
    kpisType?:
        DepartmentsEnum.PURCHASE
        | DepartmentsEnum.TRANSPORTATION
        | DepartmentsEnum.INVENTORY
        | DepartmentsEnum.LAST_MILE,
    query?: string,
    statusCode?: string,
    deliveryStatus?: string,
    fileName?: string }>(
    "organization/getCsv",
    async ({
               organizationId,
               fromDate,
               toDate,
               type,
               kpisType,
               query,
               statusCode,
               deliveryStatus,
               fileName}, thunkAPI) => {
        try {
            const response = await operationService.getCsv({organizationId, fromDate, toDate, type, kpisType, query, statusCode, deliveryStatus, fileName});

            if (isHttpCodeSuccess(response.status) && !!response?.data) {

                return response.data;
            } else {
                throw new Error();
            }
        } catch (err) {

            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const createAccount =  createAsyncThunk<any, {orgId: string, accounts: AccountIntegrations[]}>(
    "account/createAccountData",
    async ({orgId, accounts}: {orgId: string, accounts: AccountIntegrations[]}, thunkAPI) => {
        const response = await channelsService.createAccounts(orgId, accounts);
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            thunkAPI.dispatch(fetchAccounts({organizationId: orgId}))
            return response.data;
        } else {
            throw new Error();
        }
    }
);

export const updateAccountDisplayName =  createAsyncThunk<any, {orgId: string, accountId: string, displayName: string}>(
    "account/updateAccountDisplayName",
    async ({orgId, accountId, displayName}: {orgId: string, accountId: string, displayName: string}, thunkAPI) => {
        const response = await channelsService.updateDisplayName(orgId, accountId, displayName);
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            thunkAPI.dispatch(fetchAccounts({organizationId: orgId}))
            return response.data;
        } else {
            throw new Error();
        }
    }
);

export const getProductCogsCsv = createAsyncThunk<any, {
    organizationId: string,
   }>(
    "organization/getCsvProducts",
    async ({
               organizationId,
             }, thunkAPI) => {
        try {
            const response = await operationService.getProductCogsCsv({organizationId});

            if (isHttpCodeSuccess(response.status) && !!response?.data) {

                return response.data;
            } else {
                throw new Error();
            }
        } catch (err) {

            return thunkAPI.rejectWithValue(err);
        }
    }
);


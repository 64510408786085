import { useState, useEffect } from 'react';
import {Field} from "../../components/download-button/DownloadButton";
// @ts-ignore
import {Parser} from 'json2csv';
import {formatIsoDate} from "../date-format/dateFormat";

type Props = {
    data: any[];
    fields?: Field[];
};

export function useGenerateCSV(props: Props) {
    const [csv, setCsv] = useState('');

    useEffect(() => {
        if (props.data) {
            try {
                if (!props.fields) {
                    const json2csvParser = new Parser({ quote: "" });
                    const csv = json2csvParser.parse(props.data);
                    setCsv(csv);
                } else {
                    const headers = props.fields.map(field => field.label);
                    const newData = props.data.map((item: any) => {
                        const newItem = {};
                        props?.fields?.forEach(field => {
                            if (field.value in item && item[field.value] !== undefined) {
                                let value = item[field.value];
                                if (field.isoFormat) {
                                    value = formatIsoDate(value);
                                }
                                if (field.customCallback) {
                                    value = field.customCallback(value);
                                }
                                newItem[field.label] = value;
                            } else {
                                newItem[field.label] = "";
                            }
                        });
                        return newItem;
                    });

                    const json2csvParser = new Parser({
                        fields: headers,
                        quote: ""
                    });
                    console.log(props?.fields)
                    const csv = json2csvParser.parse(newData);
                    setCsv(csv);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, [props.data, props.fields]);


    return [csv, setCsv];
}
import styles from './styles';
// import {useTranslation} from "react-i18next";
import {MenuItem, Modal, ModalOverlay} from "@chakra-ui/react";
import {getIntegrationPlatformRoundLogo, IntegrationPlatform} from "@models";
import OAuthPopup from "../../components/o-auth-popup/OAuthPopup";
import lottieAnimation from '../../utils/lottie-animations/line-lottie.json';

import {
    amazonEUAppId,
    amazonSellerMarketplaces,
    AmazonSellerMarketplacesCode,
    amazonUSAppId
} from "@constants";
import {useLayoutEffect, useRef, useState} from 'react';
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";

export interface ShopifyNameDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onAuthorization: (params: URLSearchParams) => void;
}

/* const shopifyNameSchema = yup.object().shape({
    shopifyName: yup.string().required(),
}); */

export const AmazonSellerDialog = (props: ShopifyNameDialogProps) => {
    const [region, setRegion] = useState<{flag?: JSX.Element, marketplace: string, sellerCenterURL: string, code: AmazonSellerMarketplacesCode}>(amazonSellerMarketplaces[0]);
    const [menuWidth, setMenuWidth] = useState<number>(0);

    const menuRef = useRef<any>(null);

    useLayoutEffect(() => {
        setMenuWidth(menuRef?.current?.offsetWidth);
    }, []);

    // hooks
    // const {t} = useTranslation(['translation', 'translation']);
    /* const { register, getValues, formState } = useForm({
        resolver: yupResolver(shopifyNameSchema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    }); */

    // helpers

    // renderers
    const renderTitle = () => {
        return (
            <styles.TitleWrapper>
                <styles.Title>Connect to Amazon Seller</styles.Title>
            </styles.TitleWrapper>
        );
    };

    const renderConnection = () => {
        return (
            <styles.ConnectionWrapper>
                <styles.AvatarWrapper>
                    <styles.AvatarLetters>W</styles.AvatarLetters>
                </styles.AvatarWrapper>
                <styles.LottieStyle animationData={lottieAnimation} />
                <styles.ConnectionLine>
                </styles.ConnectionLine>
                <styles.ShopifyIconWrapper>
                    <styles.ShopifyIcon src={getIntegrationPlatformRoundLogo(IntegrationPlatform.AMAZON_SELLER)} />
                </styles.ShopifyIconWrapper>
            </styles.ConnectionWrapper>
        );
    };

    const renderSelectInput = () => {

        const options = amazonSellerMarketplaces?.map((item,i) => {
            return <MenuItem  onClick={() => {setRegion(item)}} key={i} value={item?.marketplace}>
                <styles.FlagHolder>
                {item?.flag}
                </styles.FlagHolder>
                {item?.marketplace}
            </MenuItem>
        })
        return(
        <styles.StyledMenu >
            {
                ({isOpen}) => (
                    <>
                        <styles.StyledMenuButton >
                            <styles.StyledWrapper ref={menuRef}>
                                <styles.MenuHolder>
                                    <styles.FlagHolder>
                                        {region?.flag}
                                    </styles.FlagHolder>
                                    {region?.marketplace}
                                </styles.MenuHolder>
                                <div>
                                    {isOpen ? <AiOutlineUp/> : <AiOutlineDown />}
                                </div>
                            </styles.StyledWrapper>
                        </styles.StyledMenuButton>
                        <styles.StyledMenuList width={menuWidth}>
                            {options}
                        </styles.StyledMenuList>
                    </>
                )
            }
        </styles.StyledMenu>
        )
    }

    const renderInput = () => {
        return (
            <styles.InputContainer>
                <styles.InputLabelWrapper>
                    <styles.InputLabel>Your marketplace</styles.InputLabel>
                </styles.InputLabelWrapper>
                {/*<styles.InputWrapper>*/}
                   {/* <styles.InputPrefixWrapper>*/}
                {renderSelectInput()}

                      {/*  <styles.StyledInput
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            type={'text'}
                        />*/}
                    {/*</styles.InputPrefixWrapper>*/}
                   {/* <styles.InputDividerWrapper />
                    <styles.InputSuffixWrapper>*/}
                        {/*<styles.InputSuffix>{'.myshopify.com'}</styles.InputSuffix>*/}
                    {/*</styles.InputSuffixWrapper>*/}
                {/*</styles.InputWrapper>*/}
            </styles.InputContainer>
        );
    };

    const renderAuthorizeButtons = () => {
        /*  if (!formState.isValid) {
             return (
                 <styles.AuthorizeButton>
                     <a href={''}>Authorized</a>
                 </styles.AuthorizeButton>
             );
         } */
        let amazonAppId = amazonUSAppId;
        if(region?.code === AmazonSellerMarketplacesCode.UK){
            amazonAppId = amazonEUAppId;
        }
        const amazonSellerUri = `${region.sellerCenterURL}/apps/authorize/consent?application_id=${amazonAppId}&state=${region?.code}&version=beta`;
        return (
            <OAuthPopup
                title={'Amazon'}
                height={800}
                width={1000}
                url={amazonSellerUri ?? ''}
                onResponse={(params) => {
                    props?.onAuthorization(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <styles.AuthorizeButton>
                   Authorize
                </styles.AuthorizeButton>
            </OAuthPopup>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsWrapper>
                <styles.BackButton onClick={() => props?.onClose()} buttonStyle={'outlined'}>
                   Cancel
                </styles.BackButton>
                {renderAuthorizeButtons()}
            </styles.ButtonsWrapper>
        );
    };

    const renderDialogContent = () => {
        return (
            <styles.ContentWrapper>
                {renderTitle()}
                {renderConnection()}
                {renderInput()}
            </styles.ContentWrapper>
        );
    };

    const renderDialogFooter = () => {
        return (
            <styles.FooterWrapper>
                {renderButtons()}
            </styles.FooterWrapper>
        );
    };

    return (
        <Modal isOpen={props.isDialogOpen} onClose={() => props.onClose()}>
            <ModalOverlay onClick={() => props?.onClose()} />
            <styles.Wrapper>
                {renderDialogContent()}
                {renderDialogFooter()}
            </styles.Wrapper>
        </Modal>
    );
};

import axios, { AxiosResponse } from "axios";
import {
  FacebookAdsAccount,
  GoogleAdsAccount,
  Integration,
  IntegrationPlatform,
  IntegrationType,
  Organization
} from "@models";
import {
  addIntegrationUrl,
  addUserUrl,
  apiBaseUrl,
  organizationFetchUrl,
  organizationUrl,
} from "@constants";
import {
  CurrencyCode,
  Holidays,
  MarketingMapping,
  MarketingMappingElement,
  PnlElement,
  Timezone,
} from "@store";
import { AddUserResponse } from "models/add-user-response";
import {Channel} from "../../store/channels";
import {Tooltip} from "../../store/tooltips";

export interface GeneralSettingsApiCall {
  timezone: Timezone;
  chosenCurrency: CurrencyCode;
  currency: CurrencyCode;
  id: string;
}

export class CreateChannelsDto{
  id?: string
  name?: string;
  displayName?: string;
  integrationId?: string;
  type?: IntegrationType;
  isDefault?: boolean;
  platformType?: IntegrationPlatform;
}

export interface ShareKpiData {
  recipients: string[];
  sharedLink: string;
  kpiTitle: string;
}

export enum RolesEnum {
  ADMIN = "admin",
  REGULAR = "regular",
  EDITOR = "editor",
  VIEWER = "viewer",
}

export enum DepartmentsEnum {
  COMPANY,
  MARKETING,
  FINANCE,
  WEBSITE,
  OPERATION,
  CUSTOMER_SUCCESS,
}

export interface CogsType {
  name?: string;
  isPercentage?: boolean;
  fromFob?: boolean;
  _id?: string;
  createdAt?: string;
}

const fetchOrganization = async (
  organizationId: string
): Promise<AxiosResponse<Organization>> => {
  return axios.get<Organization>(`${organizationFetchUrl}/${organizationId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
const fetchDataLanguage = async ({
  organizationId,
  dataLanguageId,
}: {
  organizationId: string;
  dataLanguageId: string;
}): Promise<AxiosResponse<Organization>> => {
  return axios.get<Organization>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const fetchCustomCogs = async (
  organizationId: string
): Promise<AxiosResponse<string[]>> => {
  return axios.get<string[]>(
    `${apiBaseUrl}/organization/${organizationId}/product/cogs-names`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const fetchProductsCount = async (
    organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
      `${apiBaseUrl}/organization/${organizationId}/product/count`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const fetchCustomCogsData = async (
  organizationId: string
): Promise<AxiosResponse<CogsType[]>> => {
  return axios.get<CogsType[]>(
    `${apiBaseUrl}/organization/${organizationId}/product/cogs`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const triggerAirflow = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${apiBaseUrl}/organization/${organizationId}/data-update`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};


const triggerAirflowDailyStats = async ({
                                organizationId,
                              }: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
      `${apiBaseUrl}/organization/${organizationId}/data-update/daily-stats`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};




const fetchHolidays = async ({
  organizationId,
  dataLanguageId,
  fromDate,
  toDate,
}: {
  organizationId: string;
  dataLanguageId: string;
  fromDate?: string;
  toDate?: string;
}): Promise<AxiosResponse<Holidays[]>> => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
  };
  return axios.get<Holidays[]>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/holidays`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: params,
    }
  );
};

const createPnlElement = async (
  data: PnlElement,
  dataLanguageId: string,
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}/pnl-element`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const createMarketingElement = async (
  data: MarketingMapping,
  dataLanguageId: string,
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/marketing-mapping`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const getMarketingElements = async (
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${apiBaseUrl}/organization/${organizationId}/marketing-mapping`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const shareKpiByEmail = async ({
  data,
  organizationId,
  image
}: {
  data: ShareKpiData;
  organizationId: string;
  image?: string
}): Promise<AxiosResponse<any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/share-kpi`,
    {
      ...data,
      image: image
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const createOrganization = async (
  organization: Organization
): Promise<AxiosResponse<Organization>> => {
  return axios.post<Organization>(`${organizationUrl}`, organization, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const addIntegration = async ({
  organizationId,
  integration,
}: {
  organizationId?: string;
  integration: Integration;
}): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
    `${addIntegrationUrl}/integration`,
    integration,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
};

const refreshIntegration = async ({
                                organizationId,
                                integrationId,
                                    integration
                              }: {
  organizationId: string;
  integrationId: string;
  integration: Integration;
}): Promise<AxiosResponse<Organization>> => {
  return axios.put<Organization>(
      `${addIntegrationUrl}/${organizationId}/integration/${integrationId}`,
      integration,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const getGoogleAdsAccountsV2 = async ({
                                    organizationId,
                                    object
                                  }: {
  organizationId: string;
  object: any;
}): Promise<AxiosResponse<{accounts?: GoogleAdsAccount[], object?: any}>> => {
  return axios.put<{ accounts?: GoogleAdsAccount[]; object?: any }>(
    `${addIntegrationUrl}/${organizationId}/g-a/accounts`,
    { object },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};


const editMarketingChannels = async (
  data: MarketingMapping,
  dataLanguageId: string,
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/marketing-mapping/`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const deleteIntegration = async (
  organizationId: string,
  integrationId: string
): Promise<AxiosResponse<Organization>> => {
  return axios.delete<Organization>(
    `${addIntegrationUrl}/${organizationId}/integration/${integrationId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const deletePnlElement = async (
  organizationId: string,
  dataLanguageId: string,
  elementId: string
): Promise<AxiosResponse<any>> => {
  return axios.delete<Organization>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}/pnl-element/${elementId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const inviteUser = async (
  organizationId: string,
  email: string,
  role: RolesEnum,
  department: DepartmentsEnum,
  inviterEmail?: string,
  inviterName?: string
): Promise<AxiosResponse<Organization>> => {
  return axios.put<Organization>(
    `${organizationUrl}/${organizationId}/invite?email=${encodeURIComponent(
      email
    )}&role=${role}&department=${department}&inviterEmail=${inviterEmail}&inviterName=${inviterName}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deleteUserPending = async (
  organizationId: string,
  userId: string
): Promise<AxiosResponse<Organization>> => {
  return axios.delete<Organization>(
    `${organizationUrl}/${organizationId}/invite/${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const changeTimezoneAndCurrency = async ({
  generalSettings,
  organizationId,
}: {
  generalSettings: GeneralSettingsApiCall;
  organizationId: string;
}): Promise<AxiosResponse<Organization>> => {
  return axios.put<Organization>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${generalSettings.id}`,
    {
      generalSettings: {
        timezone: generalSettings.timezone,
        chosenCurrency: generalSettings.chosenCurrency,
        currency: generalSettings.currency,
      },
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const putPnlSettings = async ({
  dataLanguageId,
  data,
  organizationId,
}: {
  dataLanguageId: string;
  data: any;
  organizationId: string;
}): Promise<AxiosResponse<Organization>> => {
  return axios.put<Organization>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}`,
    {
      pnlSettings: data,
      updatePlanning: true,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const disableApplyButton = async ({
  dataLanguageId,
  organizationId,
  display,
}: {
  dataLanguageId: string;
  organizationId: string;
  display: boolean;
}): Promise<AxiosResponse<Organization>> => {
  return axios.put<Organization>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/${dataLanguageId}`,
    {
      displayUpdate: display,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const addUser = async (
  token: string
): Promise<AxiosResponse<AddUserResponse>> => {
  return axios.put<AddUserResponse>(`${addUserUrl}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const fetchUsers = async (
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(`${organizationFetchUrl}/${organizationId}/users`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const fetchTodos = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${apiBaseUrl}/organization/${organizationId}/data-language/todos`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const fetchTooltips = async ({
                            organizationId,
                          }: {
  organizationId: string;
}): Promise<AxiosResponse<Tooltip>> => {
  return axios.get<Tooltip>(
      `${apiBaseUrl}/tooltips/dict`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const changeIntegrationName = async (
    organizationId: string,
    integrationId: string,
    name: string
): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
      `${organizationFetchUrl}/${organizationId}/integration/${integrationId}`,
      {name},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};
const fetchIntegrations = async (
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${organizationFetchUrl}/${organizationId}/integrations`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const triggerBackfill = async ({
  organizationId,
  integrationId
 }: {
  organizationId: string;
  integrationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
      `${apiBaseUrl}/organization/${organizationId}/integration/${integrationId}/backfill`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const createChannel = async ({
                                 organizationId,
                                 channelDto
                               }: {
  organizationId: string;
  channelDto: CreateChannelsDto[];
}): Promise<AxiosResponse<Channel>> => {
  return axios.post<any>(
      `${apiBaseUrl}/organization/${organizationId}/channels`,
        channelDto,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const getGoogleAdsAccounts = async ({
                                 organizationId,
                                 integrationId
                               }: {
  organizationId: string;
  integrationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
      `${apiBaseUrl}/organization/${organizationId}/g-a/accounts/${integrationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const getFacebookAdsAccounts = async ({
                                      organizationId,
                                      integrationId
                                    }: {
  organizationId: string;
  integrationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<any>(
    `${apiBaseUrl}/organization/${organizationId}/f-a/accounts/${integrationId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const getFacebookAdsAccountsV2 = async ({
                                        organizationId,
                                        object
                                      }: {
  organizationId: string;
  object: any;
}): Promise<AxiosResponse<{accounts?: FacebookAdsAccount[], object?: any}>> => {
  return axios.put<{accounts?: FacebookAdsAccount[], object?: any}>(
      `${addIntegrationUrl}/${organizationId}/f-a/accounts`,
      {object},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const updateFacebookAdsIntegration = async ({
                                          integrationId,
                                          object
                                        }: {
  integrationId: string;
  object: any;
}): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
      `${addIntegrationUrl}/integration/facebook/${integrationId}`,
      {object},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const uploadOrganizationImage = (
    organizationId: string,
    data: FormData,
) => {
  return axios.put(
      `${apiBaseUrl}/organization/${organizationId}/avatar/upload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
  );
};

const getAmazonAdsAccounts = (
    organizationId: string,
    integrationId: string,
) => {
  return axios.get(
      `${apiBaseUrl}/organization/${organizationId}/a-a/${integrationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

export const organizationService = {
  fetchOrganization,
  createOrganization,
  addIntegration,
  deleteIntegration,
  inviteUser,
  addUser,
  changeTimezoneAndCurrency,
  fetchUsers,
  changeIntegrationName,
  fetchIntegrations,
  deleteUserPending,
  fetchTodos,
  putPnlSettings,
  shareKpiByEmail,
  fetchDataLanguage,
  createMarketingElement,
  editMarketingChannels,
  fetchCustomCogs,
  getMarketingElements,
  fetchHolidays,
  fetchCustomCogsData,
  triggerAirflow,
  createPnlElement,
  disableApplyButton,
  deletePnlElement,
  triggerBackfill,
  uploadOrganizationImage,
  refreshIntegration,
  getGoogleAdsAccounts,
  getFacebookAdsAccounts,
  getGoogleAdsAccountsV2,
  getFacebookAdsAccountsV2,
  fetchProductsCount,
  createChannel,
  updateFacebookAdsIntegration,
  triggerAirflowDailyStats,
  fetchTooltips,
  getAmazonAdsAccounts
};

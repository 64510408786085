export const getPaginationItemsToPresent = (
    currentPage: number,

    maxLength: number,
    lastPage: number
) => {
    const pageNumbers: Array<number> = [];

    pageNumbers.push(currentPage);

    return pageNumbers;
};

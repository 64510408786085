import React, { useEffect, useRef, useState } from 'react';
import { Column, useRowSelect, useTable } from 'react-table';
import cogsCells from "./cogs-cells/CogsCells";
import { Cogs, Product } from "../../../models/products";
import styles from './styles';

import {Flex, useDisclosure,} from '@chakra-ui/react';
import AddColumnModal from "./add-column-modal/AddColumnModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    cleanUpLoader,
    createProductCogsCell,
    editProducts,
    fetchProducts, fetchProductsMetaData, postLoadingSelector,
    productsCountSelector,
    productsDataSelector, productsLoadingSelector, updateProductCogsCell,
    updateProducts
} from "../../../store/products";
import {
    customCogsDataSelector,
    customCogsSelector,
    fetchCustomCogs, fetchCustomCogsData,
    organizationDataLanguageSelector,
    organizationSelector
} from "@store";
import ApplyChangesBar from "../../apply-changes-bar/ApplyChangesBar";
import { currencyOptions } from 'utils/currency/currencyOptions';
import {
    isApplyBarOpenSelector,
    isApplyModalOpenSelector,
    isDefaultExploreOpenSelector,
    isGlobalLoadingSelector,
    isSecondaryExploreOpenSelector,
    openApplyBar, selectedChannelsSelector
} from 'store/ui';
import { ApplyChangesModal } from 'components/apply-changes-modal/ApplyChangesModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';
import {getChannelNameById} from "../../../utils/get-channel-name/getChannelNameById";
import {channelsStateSelector} from "../../../store/channels";



const [LAST_UPDATE, PRODUCT, CHANNEL, SELLING_PRICE, FOB, ADD, TOTAL_COST] = ['LAST_UPDATE', 'PRODUCT', 'CHANNEL', 'SELLING_PRICE', 'FOB', 'ADD', 'TOTAL_COST'];

const CogsTable = ({setTableLength, query, products, setApplyFlag, setCounter, counter, setStoredChanges, setSelectedSort, selectedSort, selectedSortArrow, setSelectedSortArrow
}: {setTableLength: (e:number) => void, setStoredChanges: (e:any) => void, query: string, products: Product[] | undefined,
    setApplyFlag: any, setCounter: any, counter: number; setSelectedSort:any; selectedSort: string, setSelectedSortArrow: any, selectedSortArrow: any }) => {

    // hooks
    const [updatesCounter, setUpdatesCounter] = useState(new Set<number>());
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const organizationId = useSelector(organizationSelector);
    const { t } = useTranslation(['translation', 'translation']);
    const [rowData, setRowData] = useState<Product[]>([]);
    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const isApplyModalOpen = useSelector(isApplyModalOpenSelector);
    const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
    const isSecondaryExploreOpen = useSelector(isSecondaryExploreOpenSelector);
    const [changesItems, setChangesItems] = useState<string[]>([]);
    const [empty, setEmpty] = useState<any>(false);
    const [changedCells, setChangedCells] = useState<any[]>([])
    const productsData = useSelector(productsDataSelector);
    const productsCounter = useSelector(productsCountSelector);
    const customCogsData = useSelector(customCogsDataSelector);
    const globalLoader = useSelector(isGlobalLoadingSelector);
    const productsLoader = useSelector(productsLoadingSelector);
    const postLoader = useSelector(postLoadingSelector);
    const channelsData = useSelector(channelsStateSelector);
    const selectedChannels = useSelector(selectedChannelsSelector);



    useEffect(() => {
        setChangedCells([]);
    }, [isExploreOpen?.isDefaultExploreOpen, isSecondaryExploreOpen]);

    useEffect(() => {
        if (!!products && !!customCogsData) {
            const newData = products?.map((item: Product) => {
                return {...item,
                    fob: changedCells?.some((cell) => cell?.fob && cell?.productId === item?.id)
                        ? changedCells?.find((cell) => cell?.fob && cell?.productId === item?.id)?.value : item?.fob,
                    newCogs: [...item?.newCogs,...customCogsData?.map((cog, i) => {
                        const allPreviousRowCogsNames = item?.newCogs?.map((element) => element?.name);
                        if(allPreviousRowCogsNames?.includes(cog?.name)){
                            return
                        }else {
                            return {
                                cogsId: cog?._id,
                                isPercentage: cog?.isPercentage,
                                fromFob: cog?.fromFob ,
                                name: cog?.name,
                                productId: item?.id,
                                value:  0,
                            }
                        }
                    })]};

            });
            const addCellChangesData = newData?.map((item: any) => {
                return {
                    ...item,
                    newCogs: item?.newCogs?.map((cog: any) => {
                        const newFoundCell = changedCells?.find((changedCell) => !changedCell?.fob && changedCell?.productId === cog?.productId && changedCell?.cogsId === cog?.cogsId);
                        if(!!newFoundCell){
                            return {...cog, value: newFoundCell?.value}
                        }else {
                            return cog
                        }
                    })
                }
            });

            setTableLength(addCellChangesData?.length);
            setRowData(addCellChangesData);
        }
    }, [isExploreOpen.isDefaultExploreOpen, isSecondaryExploreOpen.isSecondaryExploreOpen, products, changedCells, customCogsData]);

    useEffect(() => {
        setUpdatesCounter(new Set([]));
    }, [isExploreOpen.isDefaultExploreOpen, isSecondaryExploreOpen.isSecondaryExploreOpen]);

    const add = uuidv4();
    const total = uuidv4();

    const returnLatestDate = (dates: string[]): string | null => {
        if (dates.length === 0) {
            return null; // return null if array is empty
        }

        let latestDate = new Date(dates[0]);

        for (let i = 1; i < dates.length; i++) {
            if (dates[i]) {
                const currentDate = new Date(dates[i]);
                if (currentDate > latestDate) {
                    latestDate = currentDate;
                }
            }
        }

        return latestDate ? latestDate.toISOString() : null; // return latest date as ISO string or null
    };

    // helpers
    const productsToDataTable = (products: Product[]): any[] => {
        let dataTable = products.map((product, i) => {
            const allCogsUpdateDate = product?.newCogs?.map((item) => item?.updatedAt);
            const allDates = [product?.updatedAt, ...allCogsUpdateDate]?.filter((item) => !!item);
            // @ts-ignore
            const latestDate = returnLatestDate(allDates);
            let rowProduct = {
                index: i,
                updatesAt: latestDate,
                product: {
                    name: product.productTitle,
                    sku: product.sku,
                    imageUrl: product.imageUrl,
                    asin: product?.asin
                },
                channel: getChannelNameById(product?.wvChannelId, channelsData?.channels) ?? "-",
                sellingPrice: product?.itemPrice,
                fob: product.fob,
                [add]: '',
                [total]: !!product?.newCogs ? product?.newCogs?.map(cog => cog?.name) : "-",
            };
            product?.newCogs?.forEach((cog: any) => {
                if(!!cog?.name){
                rowProduct[cog?.name] = cog;
                }
            });
            return (rowProduct);
        });
        return dataTable;
    };


    const productsToHeaders = (products: Product[]): any => {
        if (products.length === 0)
            return [];
        if(!!customCogsData){
        return ([
            {
                Header: () => cogsCells.HeaderCell({ header: LAST_UPDATE,
                    setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                accessor: 'updatesAt',
                Cell: (value: string) => cogsCells.LastUpdateView(value),
                sortKey: 'updatedAt'
            }, {
                Header: () => cogsCells.HeaderCell({ header: PRODUCT,
                    setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                accessor: 'product',
                Cell: cogsCells.ProductOverViewCell,
                sortKey: 'productTitle'

                }, {
                Header: () => cogsCells.HeaderCell({ header: CHANNEL,
                    setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                accessor: 'channel',
                Cell: cogsCells.DefaultView,
                    sortKey: 'channel'

                }, {
                Header: () => cogsCells.HeaderCell({ header: SELLING_PRICE,
                    setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                accessor: 'sellingPrice',
                Cell: cogsCells.DefaultView,
                sortKey: 'itemPrice'


                }, {
                Header: () => cogsCells.HeaderCell({ header: FOB, editable: false, editBulk: editBulk,
                    setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                accessor: 'fob',
                Cell: cogsCells.FobCell,
                sortKey: 'fob'

                },
               ...customCogsData?.map(cog => {
                return ({
                    Header: () => cogsCells.HeaderCell({ header: cog?.name,
                        editable: false, editBulk: editBulk, isPercentage: cog?.isPercentage, fromFob: cog?.fromFob,
                        setUpdate: setUpdatesCounter, setApplyFlag: setApplyFlag, rowData, setPage: setCounter, displayEdit: true,
                        setSelectedSort: setSelectedSortArrow, selectedSort: selectedSortArrow }),
                    accessor: !!cog?.name ? cog?.name : "-",
                    Cell: cogsCells.CogsCell,
                });
            }),
            {
                Header: () => cogsCells.AddColumn({ header: ADD, onClick: onOpen }),
                accessor: add,
                Cell: cogsCells.DefaultView
            }, {
                Header: () => cogsCells.HeaderTotalCell({ header: TOTAL_COST }),
                accessor: total,
                    isTotal: true,
                Cell: cogsCells.TotalCostView
            }
        ]
        )}
    };


    const updateCell = (rowIndex: number, columnId: string, value: (any)) => {
        if(!isApplyBarOpen){
            dispatch(openApplyBar({isApplyBarOpen: true}))
        }
        const foundCog = rowData[rowIndex]?.newCogs?.find((cog) => cog?.name === columnId)
        const objToPush = {productId: rowData[rowIndex]?.id, cogsId: foundCog?.cogsId, productCogsId: foundCog?.productCogsId, value: columnId === "fob" ? value : value?.value, fob: columnId === "fob" };
        setChangedCells((prev) => {
            if(objToPush?.fob){
                const foundFobIndex = prev?.findIndex((item) => item?.fob && item?.productId === objToPush?.productId);
                if(foundFobIndex > -1){
                        const oldArr = prev?.slice();
                        oldArr[foundFobIndex] = objToPush
                        return oldArr
                }else {
                        return [...prev, objToPush]
                }
            }
            const elementIndex = prev?.findIndex((item) => item?.productId === objToPush?.productId && item?.cogsId === objToPush?.cogsId);
            if(elementIndex > -1){
                const oldArr = prev?.slice();
                oldArr[elementIndex] = objToPush
                return oldArr
            }else {
                return [...prev, objToPush]
            }

            return prev
        })


        setRowData(rowData?.map((product, index) => {
            let updatedProduct = { ...product };
            if (index === rowIndex) {
                const newChangedProduts = [...changesItems, product.id];
                setChangesItems(newChangedProduts);
                if ((typeof value) === 'number') {
                    if (updatedProduct[columnId] !== value) {
                        setUpdatesCounter(prev => new Set(prev.add(rowIndex)));
                        updatedProduct[columnId] = value;
                    }
                } else {
                    const updatedCog = value as Cogs;
                    updatedProduct.newCogs = updatedProduct.newCogs.map(cog => {
                        if (cog?.name === updatedCog?.name && cog?.value !== updatedCog?.value) {
                            setUpdatesCounter(prev => new Set(prev.add(rowIndex)));
                            return updatedCog;
                        }
                        return cog;
                    });
                }
            }
            return updatedProduct;
        }));
        const copyOfItem = JSON.parse(JSON.stringify(rowData[rowIndex]));
        if(columnId === "fob"){
            copyOfItem[columnId] = value;
        }else {
            const indexOfCogs = copyOfItem.newCogs?.findIndex((item: any) => item?.name === columnId);
            if(indexOfCogs > -1){
            copyOfItem.newCogs[indexOfCogs]['value'] = value?.value;
            }
        }
    };


const handleFobItemsDispatch = () => {
    const allFobItemsToPut = changedCells?.filter((item) => item?.fob);
    if(allFobItemsToPut?.length > 0){
        const formattedData = allFobItemsToPut?.map((item) => {return {fob: item?.value, _id: item?.productId}});
        organizationId?.id && !!dataLanguage?.id && dispatch(updateProducts({ organizationId: organizationId.id, products: formattedData, dataLanguageId: dataLanguage?.id }))
    }
}
const handlePostCellEdit = () => {
    const allItemsToPost = changedCells?.filter((item) => !item?.productCogsId && !item?.fob);
    if(allItemsToPost?.length > 0){
        const formattedData = allItemsToPost?.map((item) => {return {value: item?.value, cogsId: item?.cogsId, productId: item?.productId}});
        organizationId?.id && !!dataLanguage?.id && dispatch(createProductCogsCell({ organizationId: organizationId.id, products: formattedData, dataLanguageId: dataLanguage?.id }))
    }
}
const handlePutCellEdit = () => {
    const allItemsToPut = changedCells?.filter((item) => item?.productCogsId && !item?.fob);
    if(allItemsToPut?.length > 0){
        const formattedData = allItemsToPut?.map((item) => {return {value: item?.value, productCogsId: item?.productCogsId}});
        organizationId?.id && !!dataLanguage?.id && dispatch(updateProductCogsCell({ organizationId: organizationId.id, products: formattedData, dataLanguageId: dataLanguage?.id }))
    }
}


    const updateDataServer = () => {
        handleFobItemsDispatch();
        handlePostCellEdit();
        handlePutCellEdit();
        /*organizationId?.id && dispatch(fetchProductsMetaData({ organizationId: organizationId.id }))*/

        !!organization?.id && dispatch(fetchCustomCogsData({ organizationId: organization?.id }));
    };

    const addNewColumn = (newCog: Cogs) => {
        const obj = JSON.parse(JSON.stringify(rowData));
        const newData = (obj.map((product: { [x: string]: any[]; cogs: any; }): any => {
            const newCogs = [...product?.cogs, newCog];
            product["cogs"] = newCogs;
            return product;
        }));

        setRowData(newData);
        setApplyFlag((prev: number) => prev + 1);
        setUpdatesCounter(new Set(Array.from(Array(rowData.length).keys())));

    };

    const [tableData, setTableData] = useState({
        columns: [] as Column<any>[],
        data: [] as any[]
    });

    useEffect(() => {
        setTableData({ columns: productsToHeaders(rowData), data: productsToDataTable(rowData) });
    }, [rowData]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds },
    } = useTable({ ...tableData, updateCell, },
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => <cogsCells.IndeterminateCheckbox
                        isHeader={true} {...getToggleAllRowsSelectedProps()} />,
                    Cell: ({ row }) => <cogsCells.IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                },
                ...columns,
            ]);
        });


    const editBulk = (name: string, value: number) => {
        setRowData(prevState => prevState.map((product, index) => {
            let updatedProduct = { ...product };
            if (name === 'FOB') {
                updatedProduct.fob = value;
            } else {
                updatedProduct.newCogs = updatedProduct.newCogs.map(cog => {
                    if (cog.name === name) {
                        let updatedCog = { ...cog };
                        updatedCog.value = value;
                        return updatedCog;
                    }
                    return cog;
                });
            }
            return updatedProduct;
        }));
        /* setUpdatesCounter(prev => new Set(prev.add(rowIndex))); */
    };


   /* const fetchMoreData = () => {
        !!organizationId?.id && dispatch(fetchProducts({ organizationId: organizationId?.id, size: 5, page: counter + 1, query: query, sort: [selectedSort], infiniteScroll: true, channels: selectedChannels}));
        setCounter((prev: number) => prev + 1);
    };*/

    const successFlag = postLoader?.isPostRequestSuccess || productsLoader?.isPutRequestSuccess;
    // helpers
    if (!products)
        return <></>;
    return (


          <>
                <AddColumnModal isOpen={isOpen} onClose={onClose} onApply={addNewColumn} data={rowData} />
                <ApplyChangesModal onOpen={onOpen} isOpen={isApplyModalOpen} onClose={onClose} onApply={updateDataServer} />
                {isApplyBarOpen ?
                    <ApplyChangesBar onClose={() => dispatch(cleanUpLoader())} finishedLoading={!postLoader?.isPostRequestLoading && !productsLoader?.isPutRequestLoading && successFlag} onApplyChanges={updateDataServer} title={"PRODUCT_UPDATED"} />

                    : <></>}
              <styles.ScrollDiv id="scrollableDivProducts">
                <styles.CogsTable {...getTableProps()}>
                    <thead style={{position:"sticky", top:0, zIndex: 5}}>
                        {headerGroups.map(headerGroup => (
                            <styles.StyledTr style={{position:"sticky", top:0 , zIndex: 5}} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => {
                                    let displayArrow = selectedSort?.includes(column?.sortKey);
                                    const displayArrowDown = selectedSort?.includes('-1')
                                    if (column?.isTotal)
                                        return <styles.CogsTh style={{
                                            position: "sticky",
                                            right: '0px',
                                            zIndex: 25,
                                            top: "0px",
                                        }}  {...column.getHeaderProps()} >{column.render('Header')}</styles.CogsTh>;
                                    else
                                        return <styles.CogsTh onClick={() => {
                                        if(!!column?.sortKey){
                                            let sortValue = -1;
                                            let sort = `${column?.sortKey}:${sortValue}`;
                                            if(selectedSort?.includes(column?.sortKey) && selectedSort?.includes("-1")){
                                                let sortValue = 1;
                                                sort = `${column?.sortKey}:${sortValue}`;
                                            }
                                            if(selectedSort?.includes(column?.sortKey) && !selectedSort?.includes("-1")){
                                                sort = ``;
                                            }

                                            setSelectedSort(sort)
                                        }}}   {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                            {/*<span>
                                                {displayArrow ? displayArrowDown ? <FaSortDown /> : <FaSortUp />  : ''}
                                            </span>*/}
                                    </styles.CogsTh>;

                                })}
                            </styles.StyledTr>
                        ))}
                    </thead>
                    <styles.StyledTbody  {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <styles.StyledTr  {...row.getRowProps()} key={i}>
                                    {row.cells.map((cell: any) => {
                                        if (cell.column?.isTotal)
                                            return <styles.CogsTd style={{
                                                position: "sticky",
                                               right: '0px',
                                                zIndex: 1
                                            }}  {...cell.getCellProps()}>{cell.render('Cell')}</styles.CogsTd>;
                                        else
                                            return <styles.CogsTd {...cell.getCellProps()}>{cell.render("Cell")}</styles.CogsTd>;
                                    })}
                                </styles.StyledTr>
                            );
                        })}
                    </styles.StyledTbody>
                </styles.CogsTable>
              </styles.ScrollDiv>
          </>
    );
};

export default CogsTable;

import styles from './styles';
// import {useTranslation} from "react-i18next";
import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {IconMoreInfo} from "../../utils/icons/account-health";
import {CloseModalIcon} from "../../utils/icons/save-modal";

export class MoreInfoDialogProps {
    title?: string;
    subtitle?: string;
    description?: string;
    tip?: string;
    videoUrl?: string;
    videoTitle?: string;
    videoSubtitle?: string;
    videoDescription?: string;
    videoLinkTitle?: string;
}

export const MoreInfoDialog = (props: {
    props: MoreInfoDialogProps,
    isDialogOpen: boolean,
    onClose: () => void;
}) => {
    // hooks
    // const {t} = useTranslation(['translation', 'translation']);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // helpers
    const onCloseDialog = () => {
        props.onClose();
        onClose();
    };
    // renderers

    const renderVideoSection = () => {
        return props.props.videoUrl && <styles.VideoSection>
            <styles.Video>
                <ReactPlayer url={props.props.videoUrl} width={'100%'} height={'100%'} />
            </styles.Video>
            <styles.VideoDetails>
                <styles.VideoTitle>
                    {props.props.videoTitle}
                </styles.VideoTitle>
                <styles.VideoSubTitle>
                    {props.props.videoSubtitle}
                </styles.VideoSubTitle>
                <styles.VideoDescription>

                    {props.props.videoDescription}
                </styles.VideoDescription>
                <styles.VideoLink target={'_blank'} href={props.props.videoUrl}>
                    {props.props.videoLinkTitle}
                </styles.VideoLink>
            </styles.VideoDetails>
        </styles.VideoSection>;
    };

    return (
      <Modal isOpen={props.isDialogOpen} onClose={onCloseDialog}>
        <ModalOverlay />
        <styles.CustomModalContent>
          <styles.Wrapper id={'popover-content-wrapper'}>
            <styles.Header>
              <styles.Title>
                {props.props.title}
                <styles.CloseIconHolder onClick={onCloseDialog}>
                  <CloseModalIcon />
                </styles.CloseIconHolder>
              </styles.Title>

              <styles.SubTitle>{props.props.subtitle}</styles.SubTitle>
            </styles.Header>
            <styles.Content>
              {!!props.props.description &&
                <styles.Description
                  dangerouslySetInnerHTML={{ __html: props.props.description }}
                ></styles.Description>
              }
              {renderVideoSection()}

              {/*<styles.LearnMoreButton>
                            Learn More
    </styles.LearnMoreButton>*/}
            </styles.Content>
            {/*<styles.TipSection>*/}
            {/*  {props?.props?.tip ? (*/}
            {/*    <>*/}
            {/*      <IconMoreInfo />*/}
            {/*      &nbsp;{props?.props?.tip}*/}
            {/*    </>*/}
            {/*  ) : (*/}
            {/*    ""*/}
            {/*  )}*/}
            {/*</styles.TipSection>*/}
          </styles.Wrapper>
        </styles.CustomModalContent>
      </Modal>
    );
};

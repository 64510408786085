import { Button, Flex, Icon, MenuButton } from '@chakra-ui/react';
import { changeCurrencyAndTime, CurrencyCode, organizationDataLanguageSelector } from '@store';
import { SVGProps, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { currencyOptions } from 'utils/currency/currencyOptions';
import { renderFlag } from 'utils/icons/flags/flags';
import { styles } from './styles';
import { IoIosArrowForward } from "react-icons/io";
import { openApplyBar } from 'store/ui';




interface Props {
    data: {
        value: {
            sign: string;
            name: string;
            code: string;
            flag: SVGProps<SVGElement>;
            enum: CurrencyCode;
        };
    }[];
    setItem: (e: any) => void;
    item: any;
}

export const CurrencyMenu = (props: Props) => {

    // hooks
    /* const [selectedOption, setSelectedOption] = useState<any>(currencyOptions[0].value); */

    const [newData, setData] = useState(props.data);
    const dispatch = useDispatch();
    const generalLanguagedata = useSelector(organizationDataLanguageSelector);
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    /* useEffect(() => {
        const index = currencyOptions.findIndex((item) => item.value.enum === generalLanguagedata?.generalSettings?.currency);
        setSelectedOption(currencyOptions[index]?.value);
    }, [generalLanguagedata?.generalSettings?.currency]); */


    // helpers



    // renderers

    return (
        <styles.StyledMenu >
            <MenuButton _focus={{boxShadow: "none !important"}} padding={"0 25px 0 25px"} w={"450px"} borderRadius={"30px"} border="solid 2px #e2e2e2" as={Button} backgroundColor="white">
                <styles.RowButton>
                    <Flex justifyContent={"space-between"} w={"100%"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            <styles.FlagHolder>
                                {props?.item.flag}
                            </styles.FlagHolder>
                            {props?.item.code}
                            <styles.CustomSpacer width={7} />
                            {props?.item.name}
                        </Flex>
                        <styles.RowButtonIcon>
                            <IoIosArrowForward />
                        </styles.RowButtonIcon>
                    </Flex>
                </styles.RowButton>

            </MenuButton>
            {/*  <input value={searchValue} type={"text"} onChange={e => handleOnChange(e)} placeholder={"Type to search..."} /> */}
            <styles.SelectMenuList  >
                <styles.menuTitle >Popular currencies</styles.menuTitle>
                {
                    newData?.map((option, index) => {
                        if (index <= 3) {
                            return (
                                <styles.MenuItemStyled key={index} onClick={() => {
                                    /* setSelectedOption(option.value); */
                                    props.setItem(option.value);
                                    dispatch(openApplyBar({ isApplyBarOpen: true }));


                                    /* updateTimeAndCurrency(option.value.enum, props.selectedTimeZone.timezone, props.selectedTimeZone.id.toString()); */
                                }}>
                                    <styles.FlagHolder>{option.value.flag ? option.value.flag : null}</styles.FlagHolder>
                                    {option.value.code} {option.value.name}
                                </styles.MenuItemStyled>
                            );
                        } return null;
                    })
                }
                <styles.menuTitle>All currencies</styles.menuTitle>
                {
                    newData.map((option, index) => {
                        if (index > 3) {
                            return (
                                <styles.MenuItemStyled key={index} onClick={() => {
                                    /* setSelectedOption(option.value); */
                                    props.setItem(option.value);
                                    dispatch(openApplyBar({ isApplyBarOpen: true }));

                                    /* updateTimeAndCurrency(option.value.enum, props.selectedTimeZone.timezone, props.selectedTimeZone.id.toString()) */
                                }}>
                                    <styles.FlagHolder>{option.value.flag ? option.value.flag : null}</styles.FlagHolder>
                                    {option.value.code} {option.value.name}
                                </styles.MenuItemStyled>
                            );
                        } return null;
                    })
                }
            </styles.SelectMenuList>
        </styles.StyledMenu>
    );
};

import React, { useEffect, useState } from "react";
import { Cell, useSortBy, useTable } from "react-table";
import { styles } from "./styles";

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getCarrierNameByEnum,
  getCarrierSvgByEnum,
} from "../../../../utils/carrier/helpers";
import {
  clearEventModalData,
  fetchModalTrackingDataThunks,
  LastMile,
  operationsStateSelector,
} from "../../../../store/operation";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatIsoDate } from "../../../../utils/date-format/dateFormat";
import { useCurrencySign } from "../../../../utils/custom-hooks/useCurrencySign";
import { organizationSelector } from "@store";
import { CloseModalIcon } from "../../../../utils/icons/save-modal";
import {BsCheck2, BsThreeDotsVertical} from "react-icons/bs";
import {FiAlertTriangle} from "react-icons/fi";

interface Props {
  tableData?: LastMile[];
  onTabChanged: (statusCode: string) => void;
  fetchMoreData: () => void;
  handlingHasMore: boolean;
  setSortedHeader: (e?: any) => void;
  sortedHeader: any;

  setPage: any;

  setSize: any;
}

const TableHeaders = [
  {
    Header: "Order ID",
    accessor: "orderId",
    keyToFetch: "orderId",
  },
  {
    Header: "Order Date",
    accessor: "orderDate",
    keyToFetch: "activityDate",
  },
  {
    Header: "Pick Up",
    accessor: "pickUpTime",
    keyToFetch: "pickUpTime",
  },
  {
    Header: "Shipping ID",
    accessor: "shippingId",
    keyToFetch: "trackingNumber",
  },
  {
    Header: "Shipping Carrier",
    accessor: "courier",
    keyToFetch: "courier",
  },
  {
    Header: "Ships From",
    accessor: "shipsFrom",
    keyToFetch: "displayWarehouseName",
  },
  {
    Header: "Ships To (Country)",
    accessor: "shipsToCountry",
    keyToFetch: "shipCountry",
  },
  {
    Header: "Ships To (City)",
    accessor: "shipsToCity",
    keyToFetch: "shipCity",
  },
  {
    Header: "Shipping Cost",
    accessor: "shippingCost",
    keyToFetch: "totalAmount",
  },
  {
    Header: "Other Costs",
    accessor: "otherCosts",
    keyToFetch: "otherCosts",
  },
  {
    Header: "Arrival",
    accessor: "arrivalTime",
    keyToFetch: "arrivalTime",
  },
  {
    Header: "Total Days Of Delivery",
    accessor: "totalDaysOfDelivery",
    keyToFetch: "totalTimeOfDelivery",
  },
  {
    Header: "Total Time Of Order To Delivery",
    accessor: "totalTimeOrderToDelivery",
    keyToFetch: "totalTimeOrderToDelivery",
  },
  {
    Header: "Status",
    accessor: "status",
    keyToFetch: "deliveryStatus",
  },
  {
    Header: "Tags",
    accessor: "tags",
  },
];

interface TableDataInterface {
  status?: string;
  orderId?: string;
  orderDate?: string;
  courier?: string;
  shipsFrom?: string;
  shipsTo?: string;
  productsUnit?: string | number;
  shippingCost?: string | number;
  subRows?: any[];
  urlTracking?: string;

  totalTimeOrderToDelivery?: string;
  totalDaysOfDelievery?: string;
  id?: string;
}

enum Tabs {
  DELIVERED,
  IN_TRANSIT,
}

interface SelectedTab {
  name?: string;
  tab: Tabs;
  keyName?: string;
  color?: string;
  backgroundColor?: string;
}

const TabsData = [
  {
    name: "Delivered",
    tab: Tabs.DELIVERED,
    keyName: "Delivered",
    color: "#69ab60",
    backgroundColor: "#cff6c8",
  },
  {
    name: "In Transit",
    tab: Tabs.IN_TRANSIT,
    keyName: "In Transit",
    color: "#F09122",
    backgroundColor: "#FFE781",
  },
];

const LastMileTable = (props: Props) => {
  const operationState = useSelector(operationsStateSelector);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(TabsData[0]);
  const currentCurrency = useCurrencySign();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const [clickedRowDataToFetch, setClickedRowDataToFetch] = useState<{
    orderId: string;
    trackingNumber: string;
  }>();

  useEffect(() => {
    if (!isOpen) {
      dispatch(clearEventModalData());
    }
  }, [isOpen]);

  const otherCostsCalculation = (item: LastMile) => {


    let result: string | number = "-";
    if (typeof checkValues(item?.taxesFee) === "number") {
      if(result === "-"){
        result = 0
      }
      if(typeof result === "number"){
        result += item?.taxesFee;
      }
    }
    if (typeof checkValues(item?.otherFee) === "number") {
      if(result === "-"){
        result = 0
      }
      if(typeof result ==="number"){
        result += item?.otherFee;
      }
    }
    if (typeof checkValues(item?.dutiesFee) === "number") {
      if(result === "-"){
        result = 0
      }
      if(typeof result ==="number"){
        result += item?.dutiesFee;
      }
    }
    if(typeof result === "number"){
      return currentCurrency + result?.toFixed(2)
    }

    return result;
  };

  const getStringFromDeliveryDate = (dateObject: any): string => {
    if (!dateObject) {
      return "-";
    }
    if (!dateObject?.days && !dateObject?.hours && !dateObject?.minutes) {
      return "-";
    }
    return `${!!dateObject?.days ? `${dateObject?.days} days,` : ""} ${
      !!dateObject?.hours ? `${dateObject?.hours} hours and` : ""
    } ${dateObject?.minutes} minutes`;
  };

  const dataToDataTable = (data?: LastMile[]): TableDataInterface[] => {
    if (!!data) {
      const filteredData = data.filter((item) => {
        return item?.deliveryStatus === getStatusNameByEnum(selectedTab?.tab);
      });

      const tableData = filteredData.map((item) => {
        return {
          status: checkValues(item?.deliveryStatus),
          orderId: checkValues(item?.orderId),
          orderDate: checkValues(
            formatIsoDate(item?.activityDate?.slice(0, 10))
          ),
          courier: checkValues(item?.courier),
          tags: item?.tagList,
          shipsFrom: checkValues(item?.displayWarehouseName),
          shippingId: checkValues(item?.trackingNumber),
          shippingCost:
            typeof checkValues(item?.totalAmount) === "number"
              ? currentCurrency +
                parseFloat(checkValues(item?.totalAmount)?.toFixed(2))
              : checkValues(item?.totalAmount),
          otherCosts: otherCostsCalculation(item),
          shipsToCity: checkValues(item?.shipCity),
          shipsToCountry: checkValues(item?.shipCountry),
          pickUpTime: checkValues(
            formatIsoDate(item?.pickUpTime?.slice(0, 10))
          ),
          arrivalTime: checkValues(
            formatIsoDate(item?.arrivalTime?.slice(0, 10))
          ),
          tracking: checkValues(item?.trackingNumber),
          urlTracking: item?.urlTracking,
          orderLink: item?.urlOrder,
          totalDaysOfDelivery: getStringFromDeliveryDate(
            item?.totalTimeOfDelivery
          ),
          totalTimeOrderToDelivery: getStringFromDeliveryDate(
            item?.totalTimeOrderToDelivery
          ),
          notTheSameStatus: item?.notTheSameStatus,
          id: item?.id,
          subRows: [],
        };
      });
      return tableData;
    } else {
      return [];
    }
  };

  const checkValues = (value: any) => {
    if (value !== undefined && value !== null) {
      return value;
    } else {
      return "-";
    }
  };

  const getStatusNameByEnum = (key: Tabs): string => {
    switch (key) {
      case Tabs.DELIVERED:
        return "Delivered";
      case Tabs.IN_TRANSIT:
        return "In-Transit";
      /* case Tabs.PENDING_APPROVAL:
                return "Pending Approval";
            case Tabs.PENDING_FULFILLMENT:
                return "Pending Fulfillment";
            case Tabs.CANCELLED:
                return "Cancelled";*/
      default:
        return "";
    }
  };

  const columns = React.useMemo(
    () => [
      /*{
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          isAllRowsExpanded: any;
          getToggleAllRowsExpandedProps: any;
        }) => (
          <span /!*{...getToggleAllRowsExpandedProps()}*!/>
            {/!*{isAllRowsExpanded ? "👇" : "👉"}*!/}
          </span>
        ),
        Cell: ({ row }: { row: Row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
              title={""}
            >
              {row.isExpanded ? "👇" : "👉"}
            </span>
          ) : null,
      },*/
      ...TableHeaders,
    ],
    []
  );

  const data = React.useMemo(
    () => dataToDataTable(props.tableData),
    [props.tableData, selectedTab]
  );

  const getRowsCount = (tabName: string) => {
    if (tabName === "Delivered") {
      return operationState.metadata?.lastMileStats?.delivered ?? 0;
    }
    if (tabName === "In Transit") {
      return operationState.metadata?.lastMileStats?.inTransit ?? 0;
    }

    return 0;
  };

  const MenuListRender = () => {
    return (
      <MenuList>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </MenuList>
    );
  };

  const renderTabs = () => {
    return (
      <styles.TabWrapper>
        {TabsData.map((item) => {
          return (
            <styles.Tab
              color={item?.color ? item?.color : "#d9d9d9"}
              selected={selectedTab.tab === item.tab}
              onClick={() => {
                props.onTabChanged(item.name);
                setSelectedTab(item);
              }}
            >
              <Box ml={10}>{item?.name}</Box>
              <styles.NumberBadge
                active={selectedTab.tab === item.tab}
                color={item?.color ? item?.color : "black"}
                backgroundColor={
                  item?.backgroundColor ? item?.backgroundColor : "#DDDDDD"
                }
              >
                {getRowsCount(item?.name)}
              </styles.NumberBadge>
            </styles.Tab>
          );
        })}
      </styles.TabWrapper>
    );
  };

  function Table({ columns: userColumns, data }: { columns: any; data: any }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      state: { expanded },
    } = useTable(
      {
        columns: userColumns,
        data,
      },
      useSortBy
      /*useExpanded */
    );

    type Column<T> = {
      Header: string;
      accessor: keyof T;
      keyToFetch?: string;
    };

    const handleHeaderClick = (column: Column<{ keyToFetch?: string }>) => {
      props?.setPage(0);
      if (column?.Header === props?.sortedHeader?.headerName) {
        props?.setSortedHeader((prev: any) => {
          return {
            headerName: column?.Header,
            ascending: !prev?.ascending,
            key: column?.keyToFetch,
          };
        });
      } else {
        props?.setSortedHeader({
          headerName: column?.Header,
          ascending: true,
          key: column?.keyToFetch,
        });
      }
      const params = {};
      if (props?.sortedHeader?.ascending) {
        params["ascending"] = true;
        params["descending"] = undefined;
      }
      if (!props?.sortedHeader?.ascending) {
        params["ascending"] = undefined;
        params["descending"] = true;
      }
    };

    return (
      <InfiniteScroll
        dataLength={props?.tableData?.length ?? 0}
        next={props?.fetchMoreData}
        hasMore={props?.handlingHasMore}
        scrollableTarget="scrollableDivHandlingInventory"
        loader={
          <Flex
            h={150}
            w={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Flex>
        }
      >
        <styles.StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  if (column.id === "expander") {
                    return <></>;
                  }
                  return (
                    <styles.HeaderTh
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                      onClick={() => {
                        handleHeaderClick(column);
                      }}
                    >
                      <Flex
                        textAlign={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {column.render("Header")}
                        <Box w={1} />
                        <span>
                          {props?.sortedHeader?.headerName ===
                          column?.Header ? (
                            !props?.sortedHeader?.ascending ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </styles.HeaderTh>
                  );
                })}
              </styles.HeaderTr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <styles.TableTr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => {
                    if (cell.column.id === "expander") {
                      return <></>;
                    }
                    if (cell.column.id === "warehouse") {
                      return (
                        <styles.TableTd {...cell.getCellProps()} title={""}>
                          <Tooltip label={!!cell?.value ? cell?.value : ""}>
                            {!!cell?.value
                              ? cell?.value?.length > 45
                                ? cell?.value.slice(0, 10) + "..."
                                : cell?.value
                              : ""}
                          </Tooltip>
                        </styles.TableTd>
                      );
                    }
                    if (
                      cell.column.id === "orderId" ||
                      cell.column?.id === "shippingId"
                    ) {
                      return (
                        <styles.TableCell {...cell.getCellProps()} title={""}>
                          <styles.OrderLink
                            target={"_blank"}
                            href={
                              cell.column.id === "orderId"
                                ? row?.original?.orderLink ?? ""
                                : row?.original?.urlTracking ?? ""
                            }
                          >
                            {cell.render("Cell")}
                          </styles.OrderLink>
                        </styles.TableCell>
                      );
                    }
                    if (cell.column.id === "options") {
                      return (
                        <styles.TableCell {...cell.getCellProps()}>
                          <Menu placement="start-start">
                            {({ isOpen }) => (
                              <>
                                <MenuButton padding={0}>
                                  <styles.ThreeDotHolder>
                                    <BsThreeDotsVertical />
                                  </styles.ThreeDotHolder>
                                </MenuButton>
                                <MenuListRender />
                              </>
                            )}
                          </Menu>
                        </styles.TableCell>
                      );
                    }
                    if (cell.column.id === "tags") {
                      return (
                        <styles.TableCell {...cell.getCellProps()} title={""}>
                          {cell?.value?.map((title?: string) => (
                            <styles.Tag>{title}</styles.Tag>
                          ))}
                        </styles.TableCell>
                      );
                    }
                    if (cell.column.id === "status") {
                      return (
                        <styles.StatusCell
                          {...cell.getCellProps()}
                          title={""}
                          onClick={() => {
                            onOpen();
                            if (
                              !!row?.original?.orderId &&
                              !!row?.original?.tracking
                            ) {
                              !!organization?.id &&
                                dispatch(
                                  fetchModalTrackingDataThunks({
                                    organizationId: organization?.id,
                                    orderId: row?.original?.orderId,
                                    trackingNumber: row?.original?.tracking,
                                  })
                                );
                              setClickedRowDataToFetch({
                                orderId: row?.original?.orderId,
                                trackingNumber: row?.original?.tracking,
                              });
                            }
                          }}
                        >
                          <styles.statusCell
                            color={
                              !!selectedTab?.color
                                ? selectedTab?.color
                                : "black"
                            }
                          >
                            <styles.StatusBadge
                              color={selectedTab?.color + "50"}
                            >
                              <Flex
                                w={"100%"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                {!!row?.original?.notTheSameStatus && (
                                  <Box mr={"2px"}>
                                    {
                                      <FiAlertTriangle
                                        color={selectedTab?.color}
                                      />
                                    }
                                  </Box>
                                )}
                                <Box>{cell.render("Cell")}</Box>
                              </Flex>
                            </styles.StatusBadge>
                          </styles.statusCell>
                        </styles.StatusCell>
                      );
                    }
                    if (cell.column.id === "carrier") {
                      return (
                        <styles.FlexCell {...cell.getCellProps()}>
                          <styles.FlexDiv>
                            {getCarrierSvgByEnum(cell.value)}
                            {getCarrierNameByEnum(cell.value)}
                          </styles.FlexDiv>
                        </styles.FlexCell>
                      );
                    }
                    if (cell.column.id === "productsUnit") {
                      return (
                        <td {...cell.getCellProps()} title={""}>
                          {cell?.value?.toLocaleString("en-US")}
                        </td>
                      );
                    }
                    return (
                      <styles.TableCell {...cell.getCellProps()} title={""}>
                        {cell.render("Cell")}
                      </styles.TableCell>
                    );
                  })}
                </styles.TableTr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group: any) => {
              return (
                <styles.FooterTr {...group.getFooterGroupProps()}>
                  {group.headers.map((column: any) => {
                    if (column.id === "expander") {
                      return <></>;
                    }
                    return (
                      <styles.StyledTotalFooter {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </styles.StyledTotalFooter>
                    );
                  })}
                </styles.FooterTr>
              );
            })}
          </tfoot>
        </styles.StyledTable>
      </InfiniteScroll>
    );
  }

  const StatusModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Box paddingLeft={"15px"}>
                <styles.ModalTitle>Shipping Updates</styles.ModalTitle>
                <styles.ModalSubTitle>
                  ({clickedRowDataToFetch?.trackingNumber})
                </styles.ModalSubTitle>
              </Box>
              <Box onClick={onClose}>
                <CloseModalIcon />
              </Box>
            </Flex>
          </ModalHeader>
          <ModalBody maxH={425} overflowY={"auto"}>
            <styles.ModalBodyWrapper>
              {operationState?.modalTrackingEvents?.map((event, i) => {
                const date = new Date(event?.date);
                const dayOptions = { weekday: "long" };
                // @ts-ignore
                const dayName = date?.toLocaleDateString("en-US", dayOptions);

                let options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };

                // @ts-ignore
                let formatter = new Intl.DateTimeFormat("en-US", options);
                let formattedDate = formatter.format(date);
                return (
                  <Flex
                    borderBottom={"1px solid #70707032"}
                    w={"100%"}
                    h={"100%"}
                    justifyContent={"space-between"}
                  >
                    <styles.ModalBodyPartContainer>
                      <div>{dayName}</div>
                      <styles.BoldText>{formattedDate}</styles.BoldText>
                      <div>{event?.time?.slice(0, 5)} Local time</div>
                    </styles.ModalBodyPartContainer>
                    <styles.CenterIconHolder
                      first={i === 0}
                      last={
                        i === operationState!.modalTrackingEvents!.length - 1
                      }
                    >
                      <styles.Circle>
                        <Flex justifyContent={"center"} w={"100%"}>
                          <BsCheck2 color={"white"} />
                        </Flex>
                      </styles.Circle>
                    </styles.CenterIconHolder>
                    <styles.ModalBodyPartContainer>
                      <div>{event?.typeCode}</div>
                      <styles.BoldText>{event?.description}</styles.BoldText>
                      <div>
                        {event?.serviceArea[0]?.description +
                          " (" +
                          event?.serviceArea[0]?.code +
                          ")"}
                      </div>
                    </styles.ModalBodyPartContainer>
                  </Flex>
                );
              })}
            </styles.ModalBodyWrapper>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <styles.PaddingAndTableWrapper>
      <StatusModal />
      {renderTabs()}
      <styles.Wrapper id={"scrollableDivHandlingInventory"}>
        <Table columns={columns} data={data} />
      </styles.Wrapper>
    </styles.PaddingAndTableWrapper>
  );
};

// @ts-ignore
export default LastMileTable;

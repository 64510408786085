import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./styles";
import { Bar } from "react-chartjs-2";
import {
  PopoverPlacement,
  Size,
  SmallWideviewCard,
} from "../../../components/small-wideview-card/SmallWideviewCard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ExploresEnum, openDefaultExploreView } from "../../../store/ui";
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from "@chakra-ui/react";
import { Unit } from "../WideViewsFinancial";
import { organizationDataLanguageSelector } from "@store";
import { currencyFormat } from "utils/kpi-render/kpiCalculation";
import { KpisEnum } from "models/kpi-settings";
import { TargetText } from "components/target-text/TargetText";
import { useCurrencySign } from "../../../utils/custom-hooks/useCurrencySign";

export interface Props {
  unit: Unit;
  actual: any;
  targets: any;
}

enum Type {
  DEDUCTIONS,
  COGS,
  MARKETING,
}

interface BarProps {
  height: number;
  target?: number;
  actual?: number;
  color: string;
  hoverColor?: boolean;
  onClick: (e?: any) => void;
}

interface FloatingBarProps {
  height: number;
  bottom: number;
  value: number;
  margin: number;
  unit: Unit;
  type: Type;
  hoverColor?: boolean;
}

interface FloatingTarget {
  height: number;
  color: string;
  small?: boolean;
  value: number;
  margin: number;
  unit: Unit;
  showOnlyValue?: boolean;
}

interface DeductionProps {
  unit: Unit;
  value: string;
  margin: string;
  title: string;
  vsTarget: number;
}

interface PopoverProps {
  title: string;
  unit: Unit;
  target: string;
  actual: string;
  actualMargin?: string;
  targetMargin?: string;
  vsTarget: number;

  deductionTarget?: boolean;
}

export const getTargetRatio = ({
  actual,
  target,
}: {
  actual?: number;
  target?: number;
}): number => {
  if (!actual || !target) {
    return 0;
  }
  if (target === 0) {
    return 0;
  }
  if (actual > 0 && target > 0) {
    return Math.round((actual / target) * 100);
  }
  if (target < 0 && actual > 0) {
    return Math.round(((target - actual) / target) * 100);
  }
  if (target < 0 && actual < 0) {
    return Math.round(((target - actual) / target) * 100 + 100);
  }

  return Math.round((actual / target) * 100);
};

export const PnLWaterfallChart = (props: Props) => {
  // hooks
  const [displayTarget, setDisplayTarget] = useState(false);
  const currentCurrency = useCurrencySign();
  const { t } = useTranslation(["translation", "translation"]);
  const dispatch = useDispatch();
  const WrapperRef = useRef<any>(null);
  const [dimensions, setDimensions] = useState(0);
  const [barHover, setBarHover] = useState({
    grossRevenue: false,
    deductions: false,
    netRevenue: false,
    cogs: false,
    grossProfit: false,
    marketingSpend: false,
    contributionProfit: false,
  });
  const [flag, setFlag] = useState<boolean>(false);
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const [ACTUAL, setACTUAL] = useState<{
    grossRevenue: number;
    netRevenue: number;
    grossProfit: number;
    contributionProfit: number;
  }>({ grossRevenue: 0, netRevenue: 0, grossProfit: 0, contributionProfit: 0 });
  const [TARGETS, setTARGETS] = useState<{
    grossRevenue: number;
    netRevenue: number;
    grossProfit: number;
    contributionProfit: number;
  }>({ grossRevenue: 0, netRevenue: 0, grossProfit: 0, contributionProfit: 0 });

  useEffect(() => {
    if (!!props.actual && !!props.targets) {
      setACTUAL(props.actual);
      setTARGETS(props.targets);
    }
  }, [props.actual, props.targets]);

  useEffect(() => {
    if (barHover) {
      if (
        barHover.grossRevenue ||
        barHover.netRevenue ||
        barHover.grossProfit ||
        barHover.contributionProfit
      ) {
        setFlag(true);
        return;
      } else setFlag(false);
      return;
    }
    setFlag(false);
  }, [barHover]);

  useLayoutEffect(() => {
    if (WrapperRef.current) {
      setDimensions(WrapperRef.current?.offsetWidth);
    }
  }, [WrapperRef.current?.offsetWidth]);
  //helpers

  //renders
  const MAX_BAR_HEIGHT = 310;
  const PERCANGE_OF_HEIGHT = MAX_BAR_HEIGHT / 100;
  const BAR_WIDTH = 125; // if needs to be changed change also on styles width of bars

  const COLORS = {
    greenBar: "#DDF2D0",
    redBar: "#F7686A4D",
    floatingGreen: "#61C322",
    floatingRed: "#F7686A",
  };

  const getBarColor = (actual: number, target: number) => {
    if (actual >= target) {
      return COLORS.greenBar;
    }
    return COLORS.redBar;
  };

  const BARS_DATA = {
    // %
    firstBar: {
      height: (ACTUAL.grossRevenue / ACTUAL.grossRevenue) * 100,
      color: getBarColor(ACTUAL.grossRevenue, TARGETS.grossRevenue),
    },
    secondBar: {
      height: (ACTUAL.netRevenue / ACTUAL.grossRevenue) * 100,
      color: getBarColor(ACTUAL.netRevenue, TARGETS.netRevenue),
    },
    thirdBar: {
      height: (ACTUAL.grossProfit / ACTUAL.grossRevenue) * 100,
      color: getBarColor(ACTUAL.grossProfit, TARGETS.grossProfit),
    },
    fourthBar: {
      height: (ACTUAL.contributionProfit / ACTUAL.grossRevenue) * 100,
      color: getBarColor(ACTUAL.contributionProfit, TARGETS.contributionProfit),
    },
  };

  const percentageFormat = (amount: number) => {
    if (isNaN(amount)) {
      return "-";
    }
    return Math.trunc(amount)?.toString() + "%";
  };

  const DeductionCard = (props: DeductionProps) => {
    return (
      <styles.DeductionCard>
        <styles.DeductionWrapper>
          <styles.DeductionTitle>({props.title})</styles.DeductionTitle>
          <div>
            ({props.unit === Unit.MARGIN ? props.margin : props.value})
            <Box h={9} />
            {/* {isNaN(props?.vsTarget) ? <TargetBadge percentage={0} /> : <TargetBadge percentage={props?.vsTarget ?? 0} />} //TODO .V2*/}
          </div>
          <Box h={1} />
        </styles.DeductionWrapper>
      </styles.DeductionCard>
    );
  };

  const Bar = (props: BarProps) => {
    return (
      <styles.Bar
        onClick={props.onClick}
        hoverColor={props?.hoverColor}
        height={props.height > 0 ? props.height : 20}
        color={props.color}
      />
    );
  };

  const getHeightOfDeductionBar = {
    [Type.MARKETING]: PERCANGE_OF_HEIGHT * BARS_DATA.thirdBar.height,
    [Type.COGS]: PERCANGE_OF_HEIGHT * BARS_DATA.secondBar.height,
    [Type.DEDUCTIONS]: PERCANGE_OF_HEIGHT * BARS_DATA.firstBar.height,
  };
  const FloatingBar = (props: FloatingBarProps) => {
    /* PERCANGE_OF_HEIGHT * BARS_DATA.thirdBar.height */
    return (
      <styles.FloatingBar
        hoverColor={props.hoverColor}
        onMouseEnter={() => {
          setBarHover({
            grossRevenue: false,
            deductions: props.type === Type.DEDUCTIONS,
            netRevenue: false,
            cogs: props.type === Type.COGS,
            grossProfit: false,
            contributionProfit: false,
            marketingSpend: props.type === Type.MARKETING,
          });
        }}
        onMouseLeave={() => {
          setBarHover({
            grossRevenue: false,
            deductions: false,
            netRevenue: false,
            cogs: false,
            grossProfit: false,
            marketingSpend: false,
            contributionProfit: false,
          });
        }}
        height={
          props.bottom > 0 ? props.height : getHeightOfDeductionBar[props.type]
        }
        bottom={props.bottom > 0 ? props.bottom : 0}
      >
        <Box marginBottom={props?.height < 20 ? "22px" : 0}>
          {/* (TGT)
        {props.unit === Unit.MARGIN
          ? percentageFormat(props.margin)
          : currencyFormat(props.value, 2, currentCurrency)}*/}
        </Box>
      </styles.FloatingBar>
    );
  };
  const FloatingTarget = (props: FloatingTarget) => {
    return (
      <styles.FloatingTarget
          displayTarget={displayTarget}
        color={props.color}
        width={props.small
            ? BAR_WIDTH + 15
            : BAR_WIDTH * 2 + (dimensions / 7 - BAR_WIDTH) + 15
        }
        bottom={props.height > 0 ? props.height : 0}
      >
        <styles.FloatHolder>
          <styles.FloatText>
            TGT{" "}
            {props.unit === Unit.MARGIN
              ? props?.showOnlyValue
                ? currencyFormat(props.value, 0, currentCurrency)
                : percentageFormat(props.margin)
              : currencyFormat(props.value, 0, currentCurrency)}
          </styles.FloatText>
        </styles.FloatHolder>
      </styles.FloatingTarget>
    );
  };

  const checkTargetLine = (target: number, actual: number) => {
    if (target / actual > 1.1) {
      return 1.04 * 100 * PERCANGE_OF_HEIGHT;
    } else {
      return (target / actual) * 100 * PERCANGE_OF_HEIGHT;
    }
  };
  const floatingColor = (color: string) => {
    switch (color) {
      case COLORS.greenBar:
        return COLORS.floatingGreen;
      case COLORS.redBar:
        return COLORS.floatingRed;
      default:
        return COLORS.floatingRed;
    }
  };

  const BottomCards = () => {
    return (
      <styles.CardsWrapper>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: true,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <SmallWideviewCard
            popoverPlacemnt={PopoverPlacement.TOP}
            hovered={barHover.grossRevenue}
            hideTarget={!displayTarget}
            targetPercentage={getTargetRatio({
              actual: ACTUAL.grossRevenue,
              target: TARGETS.grossRevenue,
            })}
            size={Size.Tiny}
            kpiNumber={KpisEnum.GROSS_REVENUE}
            value={
              props.unit === Unit.MARGIN
                ? currencyFormat(ACTUAL.grossRevenue, 0, currentCurrency)
                : currencyFormat(ACTUAL.grossRevenue, 0, currentCurrency)
            }
            title={t("GROSS_REVENUE", { ns: "translation" })}
          />
        </Box>

        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: true,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          height={10}
          position={"relative"}
        >
          <DeductionCard
            vsTarget={
              ((TARGETS.grossRevenue - TARGETS.netRevenue) /
                (ACTUAL.grossRevenue - ACTUAL.netRevenue)) *
              100
            }
            title={t("DEDUCTIONS", { ns: "translation" })}
            unit={props.unit}
            value={
              isNaN(ACTUAL.grossRevenue - ACTUAL.netRevenue)
                ? "-"
                : currencyFormat(
                    ACTUAL.grossRevenue - ACTUAL.netRevenue,
                    0,
                    currentCurrency
                  )
            }
            margin={percentageFormat(((ACTUAL.grossRevenue - ACTUAL.netRevenue) / ACTUAL.grossRevenue) * 100)}
          />
        </Box>

        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: true,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <SmallWideviewCard
            popoverPlacemnt={PopoverPlacement.TOP}
            hovered={barHover.netRevenue}
            hideTarget={!displayTarget}
            targetPercentage={getTargetRatio({
              actual: ACTUAL.netRevenue,
              target: TARGETS.netRevenue,
            })}
            size={Size.Tiny}
            defaultStateToExplore={props.unit}
            kpiNumber={KpisEnum.NET_REVENUE}
            value={
              props.unit === Unit.MARGIN
                ? percentageFormat(
                    (ACTUAL.netRevenue / ACTUAL.grossRevenue) * 100
                  )
                : currencyFormat(ACTUAL.netRevenue, 0, currentCurrency)
            }
            title={
              props?.unit === Unit.VALUE
                ? t("NET_REVENUE", { ns: "translation" })
                : "Net Margin"
            }
          />
        </Box>

        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: true,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <DeductionCard
            vsTarget={
              ((TARGETS.netRevenue - TARGETS.grossProfit) /
                (ACTUAL.netRevenue - ACTUAL.grossProfit)) *
              100
            }
            title={t("COGS", { ns: "translation" })}
            unit={props.unit}
            value={
              isNaN(ACTUAL.netRevenue - ACTUAL.grossProfit)
                ? "-"
                : currencyFormat(
                    ACTUAL.netRevenue - ACTUAL.grossProfit,
                    0,
                    currentCurrency
                  )
            }
            margin={percentageFormat(
              ((ACTUAL.netRevenue - ACTUAL.grossProfit) / ACTUAL.grossRevenue) *
                100
            )}
          />
        </Box>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: true,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <SmallWideviewCard
            popoverPlacemnt={PopoverPlacement.TOP}
            hovered={barHover.grossProfit}
            size={Size.Tiny}
            hideTarget={!displayTarget}
            targetPercentage={getTargetRatio({
              actual: ACTUAL.grossProfit,
              target: TARGETS.grossProfit,
            })}
            kpiNumber={KpisEnum.GROSS_PROFIT}
            defaultStateToExplore={props.unit}
            value={
              props.unit === Unit.MARGIN
                ? percentageFormat(
                    (ACTUAL.grossProfit / ACTUAL.grossRevenue) * 100
                  )
                : currencyFormat(ACTUAL.grossProfit, 0, currentCurrency)
            }
            title={t("GROSS_PROFIT", { ns: "translation" })}
          />
        </Box>
        <Box
          /* onClick={() => {
            dispatch(
              openDefaultExploreView({
                isOpen: true,
                ExploresEnum: ExploresEnum.GROSS_REVENUE,
              })
            );
          }}*/
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: true,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <DeductionCard
            vsTarget={
              ((TARGETS.grossProfit - TARGETS.contributionProfit) /
                (ACTUAL.grossProfit - ACTUAL.contributionProfit)) *
              100
            }
            title={t("Marketing & Others", { ns: "translation" })}
            unit={props.unit}
            value={
              isNaN(ACTUAL.grossProfit - ACTUAL.contributionProfit)
                ? "-"
                : currencyFormat(
                    ACTUAL.grossProfit - ACTUAL.contributionProfit,
                    0,
                    currentCurrency
                  )
            }
            margin={percentageFormat(
              ((ACTUAL.grossProfit - ACTUAL.contributionProfit) / ACTUAL.grossRevenue) *
                100
            )}
          />
        </Box>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: true,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <SmallWideviewCard
            popoverPlacemnt={PopoverPlacement.TOP}
            hovered={barHover.contributionProfit}
            size={Size.Tiny}
            hideTarget={!displayTarget}
            defaultStateToExplore={props.unit}
            targetPercentage={getTargetRatio({
              actual: ACTUAL.contributionProfit,
              target: TARGETS.contributionProfit,
            })}
            kpiNumber={KpisEnum.CONTRIBUTION_PROFIT}
            value={
              props.unit === Unit.MARGIN
                ? percentageFormat(
                    (ACTUAL.contributionProfit / ACTUAL.grossRevenue) * 100
                  )
                : currencyFormat(ACTUAL.contributionProfit, 0, currentCurrency)
            }
            title={t("CONT_PROFIT", { ns: "translation" })}
          />
        </Box>
      </styles.CardsWrapper>
    );
  };

  const PopoverContentData = (props: PopoverProps) => {
    const flag = props.actual.slice(1) === "NaN";
    return (
      <>
        <PopoverArrow />
        <PopoverBody
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
        >
          <styles.StyledPopoverBody>
            <styles.StyledPopoverTitle>{props.title}</styles.StyledPopoverTitle>
            <styles.PopoverNumbers>
              <styles.PopoverNumber>
                <Box fontSize={17} fontWeight="bold">
                  {props.unit === Unit.MARGIN
                    ? props.actualMargin
                    : flag
                    ? "-"
                    : props.actual}
                </Box>
                <Box fontSize={11} fontWeight="bold">
                  {t("ACTUAL", { ns: "translation" })}
                </Box>
              </styles.PopoverNumber>
              {displayTarget &&
                <styles.PopoverNumber>
                  <Box fontSize={17} fontWeight="bold">
                    {props.unit === Unit.MARGIN
                      ? props.targetMargin
                      : flag
                      ? "-"
                      : props.target}
                  </Box>
                  <Box fontSize={11} fontWeight="bold">
                    {t("TGT", { ns: "translation" })}
                  </Box>
                </styles.PopoverNumber>
              }
              {
                  displayTarget && <styles.PopoverNumber>
                  <Box fontWeight="bold">
                    {isNaN(props.vsTarget) ? (
                      "-"
                    ) : (
                      <TargetText
                        deductionTarget={props?.deductionTarget}
                        percentage={
                          isFinite(props?.vsTarget)
                            ? Math.trunc(props.vsTarget)
                            : 0
                        }
                      />
                    )}
                  </Box>
                  <Box fontSize={11}>{t("VS_TGT", { ns: "translation" })}</Box>
                </styles.PopoverNumber>
              }
            </styles.PopoverNumbers>
          </styles.StyledPopoverBody>
        </PopoverBody>
      </>
    );
  };

  return (
    <styles.Wrapper>
      <styles.BarsWrapper ref={WrapperRef}>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: true,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          width={BAR_WIDTH}
          position={"relative"}
        >
          <Popover
            isOpen={barHover.grossRevenue}
            placement="top"
            trigger="click"
          >
            <PopoverTrigger>
              <div />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                vsTarget={getTargetRatio({
                  actual: ACTUAL.grossRevenue,
                  target: TARGETS.grossRevenue,
                })}
                actualMargin={currencyFormat(
                  ACTUAL.grossRevenue,
                  0,
                  currentCurrency
                )}
                targetMargin={currencyFormat(
                  TARGETS.grossRevenue,
                  0,
                  currentCurrency
                )}
                target={currencyFormat(
                  TARGETS.grossRevenue,
                  0,
                  currentCurrency
                )}
                actual={currencyFormat(ACTUAL.grossRevenue, 0, currentCurrency)}
                unit={props.unit}
                title={t("GROSS_REVENUE", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          <Bar
            onClick={() => {
              dispatch(
                openDefaultExploreView({
                  isOpen: true,
                  ExploresEnum: ExploresEnum.GROSS_REVENUE,
                })
              );
            }}
            height={PERCANGE_OF_HEIGHT * BARS_DATA.firstBar.height}
            hoverColor={barHover.grossRevenue}
            color={BARS_DATA.firstBar.color}
          />

          <FloatingTarget
            unit={props.unit}
            margin={TARGETS.grossRevenue}
            value={TARGETS.grossRevenue}
            small
            height={checkTargetLine(TARGETS.grossRevenue, ACTUAL.grossRevenue)}
            color={floatingColor(BARS_DATA.firstBar.color)}
            showOnlyValue={true}
          />
        </Box>

        <Box
          display={"flex"}
          justifyContent="center"
          width={BAR_WIDTH}
          position={"relative"}
        >
          <Popover isOpen={barHover.deductions} placement="top" trigger="click">
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  bottom:
                    BARS_DATA.secondBar.height * PERCANGE_OF_HEIGHT +
                    (MAX_BAR_HEIGHT -
                      BARS_DATA.secondBar.height * PERCANGE_OF_HEIGHT),
                }}
              />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                deductionTarget={true}
                vsTarget={getTargetRatio({
                  actual: ACTUAL.grossRevenue - ACTUAL.netRevenue,
                  target: TARGETS.grossRevenue - TARGETS.netRevenue,
                })}
                actualMargin={percentageFormat(
                  ((ACTUAL.grossRevenue - ACTUAL.netRevenue) /
                    ACTUAL.grossRevenue) *
                    100
                )}
                targetMargin={percentageFormat(
                  ((TARGETS.grossRevenue - TARGETS.netRevenue) /
                    TARGETS.grossRevenue) *
                    100
                )}
                target={currencyFormat(
                  TARGETS.grossRevenue - TARGETS.netRevenue,
                  0,
                  currentCurrency
                )}
                actual={currencyFormat(
                  ACTUAL.grossRevenue - ACTUAL.netRevenue,
                  0,
                  currentCurrency
                )}
                unit={props.unit}
                title={t("DEDUCTIONS", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          {isNaN(TARGETS.grossRevenue - TARGETS.netRevenue) ? (
            <></>
          ) : (
            <FloatingBar
              hoverColor={barHover.deductions}
              type={Type.DEDUCTIONS}
              unit={props.unit}
              value={TARGETS.grossRevenue - TARGETS.netRevenue}
              margin={
                (TARGETS.grossRevenue / TARGETS.grossRevenue) * 100 -
                (TARGETS.netRevenue / TARGETS.grossRevenue) * 100
              }
              height={
                MAX_BAR_HEIGHT - BARS_DATA.secondBar.height * PERCANGE_OF_HEIGHT
              }
              bottom={BARS_DATA.secondBar.height * PERCANGE_OF_HEIGHT}
            />
          )}
          <FloatingTarget
            unit={props.unit}
            margin={(TARGETS.netRevenue / TARGETS.grossRevenue) * 100}
            value={TARGETS.netRevenue}
            height={checkTargetLine(TARGETS.netRevenue, ACTUAL.grossRevenue)}
            color={floatingColor(BARS_DATA.secondBar.color)}
          />
        </Box>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: true,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
        >
          <Popover isOpen={barHover.netRevenue} placement="top" trigger="click">
            <PopoverTrigger>
              <div />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                vsTarget={getTargetRatio({
                  actual: ACTUAL.netRevenue,
                  target: TARGETS.netRevenue,
                })}
                targetMargin={percentageFormat(
                  (TARGETS.netRevenue / TARGETS.grossRevenue) * 100
                )}
                actualMargin={percentageFormat(
                  (ACTUAL.netRevenue / ACTUAL.grossRevenue) * 100
                )}
                target={currencyFormat(TARGETS.netRevenue, 0, currentCurrency)}
                actual={currencyFormat(ACTUAL.netRevenue, 0, currentCurrency)}
                unit={props.unit}
                title={t("NET_REVENUE", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          <Bar
            onClick={() => {
              dispatch(
                openDefaultExploreView({
                  isOpen: true,
                  ExploresEnum: ExploresEnum.NET_REVENUE,
                })
              );
            }}
            hoverColor={barHover.netRevenue}
            height={BARS_DATA.secondBar.height * PERCANGE_OF_HEIGHT}
            color={BARS_DATA.secondBar.color}
          />
        </Box>

        <Box
          display={"flex"}
          justifyContent="center"
          width={BAR_WIDTH}
          position={"relative"}
        >
          <Popover isOpen={barHover.cogs} placement="top" trigger="click">
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  bottom:
                    BARS_DATA.thirdBar.height * PERCANGE_OF_HEIGHT +
                    (BARS_DATA.secondBar.height - BARS_DATA.thirdBar.height) *
                      PERCANGE_OF_HEIGHT,
                }}
              />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                deductionTarget={true}
                targetMargin={percentageFormat(
                  ((TARGETS.netRevenue - TARGETS.grossProfit) /
                    TARGETS.grossRevenue) *
                    100
                )}
                actualMargin={percentageFormat(
                  ((ACTUAL.netRevenue - ACTUAL.grossProfit) /
                    ACTUAL.grossRevenue) *
                    100
                )}
                vsTarget={getTargetRatio({
                  actual: ACTUAL.netRevenue - ACTUAL.grossProfit,
                  target: TARGETS.netRevenue - TARGETS.grossProfit,
                })}
                target={currencyFormat(
                  TARGETS.netRevenue - TARGETS.grossProfit,
                  0,
                  currentCurrency
                )}
                actual={currencyFormat(
                  ACTUAL.netRevenue - ACTUAL.grossProfit,
                  0,
                  currentCurrency
                )}
                unit={props.unit}
                title={t("COGS", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          {isNaN(TARGETS.netRevenue - TARGETS.grossProfit) ? (
            <></>
          ) : (
            <FloatingBar
              hoverColor={barHover.cogs}
              type={Type.COGS}
              unit={props.unit}
              value={TARGETS.netRevenue - TARGETS.grossProfit}
              margin={
                (TARGETS.netRevenue / TARGETS.grossRevenue) * 100 -
                (TARGETS.grossProfit / TARGETS.grossRevenue) * 100
              }
              height={
                (BARS_DATA.secondBar.height - BARS_DATA.thirdBar.height) *
                PERCANGE_OF_HEIGHT
              }
              bottom={BARS_DATA.thirdBar.height * PERCANGE_OF_HEIGHT}
            />
          )}
          <FloatingTarget
            unit={props.unit}
            margin={(TARGETS.grossProfit / TARGETS.grossRevenue) * 100}
            value={TARGETS.grossProfit}
            height={checkTargetLine(TARGETS.grossProfit, ACTUAL.grossRevenue)}
            color={floatingColor(BARS_DATA.thirdBar.color)}
          />
        </Box>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: true,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
        >
          <Popover
            isOpen={barHover.grossProfit}
            placement="top"
            trigger="click"
          >
            <PopoverTrigger>
              <div />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                vsTarget={getTargetRatio({
                  actual: ACTUAL.grossProfit,
                  target: TARGETS.grossProfit,
                })}
                actualMargin={percentageFormat(
                  (ACTUAL.grossProfit / ACTUAL.grossRevenue) * 100
                )}
                targetMargin={percentageFormat(
                  (TARGETS.grossProfit / TARGETS.grossRevenue) * 100
                )}
                target={currencyFormat(TARGETS.grossProfit, 0, currentCurrency)}
                actual={currencyFormat(ACTUAL.grossProfit, 0, currentCurrency)}
                unit={props.unit}
                title={t("GROSS_PROFIT", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          <Bar
            onClick={() => {
              dispatch(
                openDefaultExploreView({
                  isOpen: true,
                  ExploresEnum: ExploresEnum.GROSS_REVENUE,
                })
              );
            }}
            hoverColor={barHover.grossProfit}
            height={PERCANGE_OF_HEIGHT * BARS_DATA.thirdBar.height}
            color={BARS_DATA.thirdBar.color}
          />
        </Box>

        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: true,
              contributionProfit: false,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
          display={"flex"}
          justifyContent="center"
          width={BAR_WIDTH}
          position={"relative"}
        >
          <Popover
            isOpen={barHover.marketingSpend}
            placement="top"
            trigger="click"
          >
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  bottom:
                    PERCANGE_OF_HEIGHT * BARS_DATA.fourthBar.height +
                    (BARS_DATA.thirdBar.height - BARS_DATA.fourthBar.height) *
                      PERCANGE_OF_HEIGHT,
                }}
              />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                deductionTarget={true}
                targetMargin={percentageFormat(
                  ((TARGETS.grossProfit - TARGETS.contributionProfit) /
                    TARGETS.grossRevenue) *
                    100
                )}
                actualMargin={percentageFormat(
                  ((ACTUAL.grossProfit - ACTUAL.contributionProfit) /
                    ACTUAL.grossRevenue) *
                    100
                )}
                vsTarget={getTargetRatio({
                  actual: ACTUAL.grossProfit - ACTUAL.contributionProfit,
                  target: TARGETS.grossProfit - TARGETS.contributionProfit,
                })}
                target={currencyFormat(
                  TARGETS.grossProfit - TARGETS.contributionProfit,
                  0,
                  currentCurrency
                )}
                actual={currencyFormat(
                  ACTUAL.grossProfit - ACTUAL.contributionProfit,
                  0,
                  currentCurrency
                )}
                unit={props.unit}
                title={t("MARKETING_SPEND", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          {isNaN(TARGETS.grossProfit - TARGETS.contributionProfit) ? (
            <></>
          ) : (
            <FloatingBar
              hoverColor={barHover.marketingSpend}
              type={Type.MARKETING}
              unit={props.unit}
              value={TARGETS.grossProfit - TARGETS.contributionProfit}
              margin={
                TARGETS.grossProfit / TARGETS.grossRevenue -
                TARGETS.contributionProfit / TARGETS.grossRevenue
              }
              height={
                (BARS_DATA.thirdBar.height - BARS_DATA.fourthBar.height) *
                PERCANGE_OF_HEIGHT
              }
              bottom={PERCANGE_OF_HEIGHT * BARS_DATA.fourthBar.height}
            />
          )}
          <FloatingTarget
            unit={props.unit}
            margin={(TARGETS.contributionProfit / TARGETS.grossRevenue) * 100}
            value={TARGETS.contributionProfit}
            height={checkTargetLine(TARGETS.contributionProfit, ACTUAL.grossRevenue)}
            color={floatingColor(BARS_DATA.fourthBar.color)}
          />
        </Box>
        <Box
          onMouseEnter={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: true,
            });
          }}
          onMouseLeave={() => {
            setBarHover({
              grossRevenue: false,
              deductions: false,
              netRevenue: false,
              cogs: false,
              grossProfit: false,
              marketingSpend: false,
              contributionProfit: false,
            });
          }}
        >

          <Popover isOpen={barHover.contributionProfit} placement="top" trigger="click">
            <PopoverTrigger>
              <div />
            </PopoverTrigger>
            <styles.StyledPopoverContent>
              <PopoverContentData
                vsTarget={getTargetRatio({
                  actual: ACTUAL.contributionProfit,
                  target: TARGETS.contributionProfit,
                })}
                actualMargin={percentageFormat(
                  (ACTUAL.contributionProfit / ACTUAL.grossRevenue) * 100
                )}
                targetMargin={percentageFormat(
                  (TARGETS.contributionProfit / TARGETS.grossRevenue) * 100
                )}
                target={currencyFormat(TARGETS.contributionProfit, 0, currentCurrency)}
                actual={currencyFormat(ACTUAL.contributionProfit, 0, currentCurrency)}
                unit={props.unit}
                title={t("CONT_PROFIT", { ns: "translation" })}
              />
            </styles.StyledPopoverContent>
          </Popover>
          <Bar
            onClick={() => {
              dispatch(
                openDefaultExploreView({
                  isOpen: true,
                  ExploresEnum: ExploresEnum.NET_REVENUE,
                })
              );
            }}
            hoverColor={barHover.contributionProfit}
            height={PERCANGE_OF_HEIGHT * BARS_DATA.fourthBar.height}
            color={BARS_DATA.fourthBar.color}
          />
        </Box>
      </styles.BarsWrapper>
      <styles.BottomLine />
      <BottomCards />
      {/*<Box height={flag ? 0 : "0px"} />*/}
    </styles.Wrapper>
  );
};

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    max-height: 450px;

    overflow-y: scroll;
    padding: 0rem 10px 0.2rem 10px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.roboto.regular} !important;

  `;

  export const PnLTable = styled.table`
    width: 100%;
    display: block;
  `;

  export const PnLThead = styled.thead`
    border-bottom: gray;
    top: 0;
    z-index: 1;
    display: block;
    position: -webkit-sticky;
    position: sticky;
  `;
  export const PnLTrHead = styled.tr`
    background-color: rgb(249, 249, 254);
    padding: 1rem 0;
    position: sticky;
    top: 0;
    width: 100%;
    position: -webkit-sticky; /* Safari */
    display: block;
  `;

  export const PnLTbody = styled.tbody`
    /* border-bottom: darkred;
    display: table-row-group;
    overflow-y: scroll; */
    display: block;
    min-height: 350px;
  `;

  export const PnLTfoot = styled.tfoot`
    background-color: white;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    box-shadow: ${(props) => props.theme.fullShadow};
    display: block;
    bottom: 0;
  `;

  export const PnLTh = styled.th<{ width: number }>`
    width: ${(props) => props.width + "px"};
    position: -webkit-sticky; /* Safari */
    position: sticky;
    /* padding: 0.5rem; */
    color: #c0bdcc;
    font-weight: normal;
    border-bottom: 1px solid #ebebeb;
    font-size: 0.9rem;
  `;

  export const PnLTd = styled.td<{ width: number }>`
    margin: 0;
    /* padding: 0.5rem; */
    width: ${(props) => props.width + "px"};

    :first-child {
      color: gray;
    }
  
  `;


  export const PnLTdName = styled.td<{ width: number }>`
    margin: 0;
    /* padding: 0.5rem; */
    width: ${(props) => props.width + "px"};

    :first-child {
      color: gray;
    }

  `;

  export const NameHolder = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `

  export const PnLThDirection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

  export const TdDiv = styled.td<{ width: number }>`
    /* padding: 5px 0px; */

    width: ${(props) => props.width + "px"};
  `;

  export const TdDivRevenue = styled.td<{ width: number }>`
    background-color: rgba(97, 195, 34, 0.26);
    padding: 5px 0px;
    width: ${(props) => props.width + "px"};
  `;

  export const TdDivMargin = styled.td<{ width: number }>`
    background-color: rgba(97, 195, 34, 0.09);
    padding: 5px 0px;
    width: ${(props) => props.width + "px"};
  `;

  export const FooterTdDivBold = styled.div<{ width: number }>`
    background-color: rgba(112, 49, 235, 0.26);
    width: ${(props) => props.width + "px"};

    padding: 5px 0px;
  `;

  export const TdGrandTotal = styled.div`
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    position: absolute;
    border-radius: 5px;
    white-space: nowrap;
    transform: scale(1.2, 1.1);
    font-size: 0.9rem;
    padding: 10px 0px;
    position: absolute;
    /* margin-left: auto;
    margin-right: auto; */
    left: 0;
    right: 0;
    /* text-align: center; */
    top: -3px;
    box-shadow: ${(props) => props.theme.fullShadow};
  `;
  export const WidthTd = styled.td<{ width: number }>`
    width: ${(props) => props.width + "px"};
    position: relative;
  `;

  export const Title = styled.div`
    font-size: 21px;
    font-weight: bold;
    border-bottom: solid 1px;
    margin-bottom: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  `;

  export const PnLTr = styled.tr`
    &:nth-child(even) {
      background-color: rgba(122, 134, 161, 0.06);
    }
    padding: 3px 0;
    display: block;
  `;
  export const PnLTrFooter = styled.tr<{ width: number }>`
    background-color: rgb(249, 249, 254);
    box-shadow: ${(props) => props.theme.fullShadow};
    position: sticky;
    padding: 3px 0 !important;
    top: 0;
    width: ${(props) => props.width + "px"};
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    
  `;
}

export default styles;

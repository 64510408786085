import styled from "styled-components";
import { PopoverContent, Slide } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    min-height: max-content;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 515px;
  `;

  export const Card = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
  `;

  export const Bar = styled.div<{
    height: number;
    color: string;
    hoverColor?: boolean;
  }>`
    height: ${(props) => props.height + "px"};
    background-color: ${(props) => props.color};
    width: 125px;
    border-radius: 10px 10px 0 0;
    filter: ${(props) => (props?.hoverColor ? "saturate(2)" : "saturate(1)")};
    &:hover {
      cursor: pointer;
    }
  `;

  export const FloatingBar = styled.div<{
    height: number;
    bottom: number;
    hoverColor?: boolean;
  }>`
    position: absolute;
    z-index: 0;
    bottom: ${(props) => props.bottom + "px"};
    width: 125px;
    background-color: #a6abb5;
    height: ${(props) => props.height + "px"};
    border-radius: 10px 10px 0 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: ${(props) =>
      props?.hoverColor ? "brightness(130%)" : "brightness(135%)"};
  `;
  export const Spacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
  `;
  export const BarHolder = styled.div<{ width: number }>`
    display: flex;
    justify-content: space-around;
    width: ${(props) => props.width + "px"};
  `;
  export const FloatText = styled.div`
    position: absolute;
    bottom: 0px;
    right: 19px;
    font-size: 14px;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    font-weight: 500;
    color: #7A86A1;
  `;

  export const BarsWrapper = styled.div`
    width: 90%;
    padding: 45px 0 0 0;
    max-height: 400px;
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-around;
    /* overflow: hidden */
  `;
  export const StyledPopoverContent = styled(PopoverContent)`
    top: 0;
    min-width: max-content !important; // 300 with targets
    max-width: max-content !important; // 300 with targets
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
    /*  @keyframes slideDown {
      from {
        transform: translateY(0);
        opacity: 0;
      }
      to {
        transform: translateY(50%);
        opacity: 1;
      }
    }
    animation: slideDown 1.5s; */
  `;
  export const StyledPopoverTitle = styled.div`
    color: #7a86a1;
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const PopoverNumbers = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `;
  export const PopoverNumber = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const StyledPopoverBody = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const CardsWrapper = styled.div`
    width: 90%;
    padding: 15px 0 15px 0;
    max-height: 400px;
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: space-around;
  `;
  export const DeductionWrapper = styled.div`
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `;
  export const DeductionCard = styled.div`
    width: 100%;
    border-radius: 10px;
    height: 105px;
    background-color: #F4F8FF;
    outline: 1px solid #e8e8e8;
  `;
  export const DeductionTitle = styled.div`
    color: #7a86a1;
    font-size: 12px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    margin: 6px 0 0 0;
  `;

  export const FloatingTarget = styled.div<{
    bottom: number;
    color: string;
    width: number;
    displayTarget?: boolean
  }>`
    height: 2px;
    z-index: 1;
    /*background: ${(props) => `repeating-linear-gradient(
      to right,
      ${props.color} 0,
      ${props.color} 10px,
      transparent 6px,
      transparent 13px
    )`};*/
      background: ${(props) => `repeating-linear-gradient(
      to right,
      #7A86A1 0,
      #7A86A1 10px,
      transparent 6px,
      transparent 13px
    )`};
    bottom: ${(props) => props.bottom + "px"};
    left: -7.5px;
    width: ${(props) => props.width + "px"};
    position: absolute;
    display: ${props => props?.displayTarget ? 'flex' : 'none'};

    justify-content: center;
    align-items: center;
  `;
  export const FloatHolder = styled.div`
    width: 100%;
    position: relative;
    z-index: 3;
  `;
  export const BottomLine = styled.div`
    width: 90%;
    height: 2px;
    background-color: #dedede;
  `;
}

export default styles;

import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { clearSystemEventState, setNewSystemEvents } from "./actions";
import {fetchSystemEvent, fetchSystemEventIntegrations} from "./thunks";
import {initialSystemEventsState, SystemEventState, SystemEventsType} from "./state";



function buildFetchSystemEventsReducer(
  builder: ActionReducerMapBuilder<SystemEventState>
) {
  builder
    .addCase(fetchSystemEvent.pending, (state, action) => {
      state.loading = true;
      return state;
    })
    .addCase(fetchSystemEvent.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      return state;
    })
    .addCase(fetchSystemEvent.rejected, (state, action) => {
      state.loading = false;
      return state;
    });
}

function buildFetchSystemEventsIntegrationsReducer(
    builder: ActionReducerMapBuilder<SystemEventState>
) {
  builder
      .addCase(fetchSystemEventIntegrations.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(fetchSystemEventIntegrations.fulfilled, (state, action) => {
          state.integrationData = action.payload;
          state.loading = false;
          return state;

      })
      .addCase(fetchSystemEventIntegrations.rejected, (state, action) => {
        state.loading = false;
        return state;
      });
}


export const systemEventsSlice = createSlice({
  name: "systemEvents",
  initialState: initialSystemEventsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    // async
    buildFetchSystemEventsReducer(builder);

    buildFetchSystemEventsIntegrationsReducer(builder);

    /* buildDeleteEffort(builder); */
  },
});

// reducer
export const systemEventsReducer = systemEventsSlice.reducer;

import {InputWrapper} from "./styles";
import {ReactNode} from "react";

interface Props {
    className?: string;
    children?: ReactNode;
}

export const Input = ({className, children}: Props) => {
    return (
        <InputWrapper className={className}>
            {children}
        </InputWrapper>
    );
};

import { integrationButton } from "./styles";
import {
    Flex,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader, PopoverTrigger
} from "@chakra-ui/react";
import {FiLock, FiMoreVertical} from "react-icons/fi";
import {premiumLogo} from "../../constants/integration";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import PremiumIcon from "../premium-icon/PremiumIcon";


export interface Props {
    children?: any;
    image?: string;
    /* description?: string; */
    title?: string;
    callback?: () => void;
    badgeText?: string;
    premium?: true
}

export const IntegrationButton = ({ image, /* description, */ title, callback, badgeText, premium }: Props) => {

    // renderers
    const renderButtonImage = () => {
        return (
            <integrationButton.ImageWrapper>
                {badgeText && !premium && renderButtonImageBadge()}
                <integrationButton.Image premium={premium} src={image}></integrationButton.Image>
            </integrationButton.ImageWrapper>
        );
    };

    const renderButtonImageBadge = () => {
        return (
            <integrationButton.ImageBadge>
                <integrationButton.ImageBadgeText>
                    {badgeText}
                </integrationButton.ImageBadgeText>
            </integrationButton.ImageBadge>
        );
    };

    const renderButtonInfo = () => {
        return (
            <integrationButton.Info>
                <integrationButton.Title premium={premium}>
                    {title}
                </integrationButton.Title>
                <integrationButton.Description>
                     {/*{description}*/}
                </integrationButton.Description>
            </integrationButton.Info>
        );
    };

    const renderButtonMoreButton = () => {
        return (
            <integrationButton.More>
                <Icon as={FiMoreVertical} />
            </integrationButton.More>
        );
    };

    return (
      <integrationButton.Wrapper
        premium={premium}
        onClick={() => {
          callback && !premium ? callback() : console.log("error");
        }}
      >
        {renderButtonImage()}
        {renderButtonInfo()}
        {!premium && renderButtonMoreButton()}
        {premium && <PremiumIcon fixedTopRight={true} />}
      </integrationButton.Wrapper>
    );
};

import styled from "styled-components";
import {keyframes, Table, Tbody, Td, Tfoot, Th, Tr} from "@chakra-ui/react";

export namespace styles {
    export const Wrapper = styled.div`
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
    `;

    export const Card = styled.div`
      background-color: white;
      width: 100%;
      height: 100%;
      padding: 20px;
      //box-shadow: 2px 13px 54px #695f9714;
      box-shadow: 3px 3px 10px #c6c6c6;
      border-radius: 20px;
    `;

    export const Header = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `;

    export const Title = styled.div`
      width: 100%;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      font-family: ${(props) => props?.theme?.fonts?.header};
      font-size: 21px;
      margin-bottom: 7px;
      font-weight: bold;
    `;


    const fadeIn = keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `;

    const fadeOut = keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `;


    export const TableCustom = styled(Table)`
      max-height: 300px;
    `;

    export const TbodyCustom = styled(Tbody)`
      display: block;
      height: 300px;
      overflow-y: auto;


      ::-webkit-scrollbar-track {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }


      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: transparent;
        opacity: 0;
        animation: ${fadeOut} 2s forwards!important;
      }

      &:hover {
        ::-webkit-scrollbar-track {
          background: #ebebeb!important;
          display: block!important;
        }

        ::-webkit-scrollbar-thumb {
          background: #00005B!important;
          display: block!important;
          opacity: 1!important;
          animation: ${fadeIn} 2s forwards!important;
        }
      }
    `;

    export const TfootCustom = styled(Tfoot)`

    `;

    export const ThCustom = styled(Th)<{shadow?: boolean}>`
      text-transform: capitalize !important;
      table-layout: fixed;
      user-select: none;
      
      text-align: left !important;
      color: #7a86a1 !important;
      padding: 15px 0 10px !important;
      font-size: 11px !important;
      border-bottom: 1px solid #dcdcdc63 !important;
      font-family: ${(props) => props.theme.fonts.roboto.regular};

      box-shadow: ${(props) => props.shadow ? '0px 4px 6px #dcdcdc63' : 'none'} !important;
      transition: box-shadow 0.3s ease-in-out !important;
    `;

    export const TrCustom = styled(Tr)<{ selected?: boolean, highlightRow?: boolean }>`
      border: none !important;
      display: table;
      width: 100%;
      table-layout: fixed;
      opacity: ${(props) => (props.selected ? 1 : 0.5)};
      background-color: ${(props) => props.highlightRow && props?.selected ? '#7031EB07' : 'unset'};

      &:hover {
        cursor: pointer;
      }
    `;
    export const TrCustomFooter = styled(Tr)`
      border: none !important;
      display: table;
      width: 100%;
      table-layout: fixed;
      opacity: 1;
      font-weight: bold;
      padding: 0px;
    `;

    export const TdCustom = styled(Td)`
      border: none !important;
      font-size: 12px;
      padding: 16px 6px !important;
    `;

    export const TdCustomFooter = styled(Td)`
      border: none !important;
      font-size: 12px;
      padding: 16px 6px !important;

      background-color: white;
      border-top: 1px solid #dcdcdc63!important;
      z-index: 8;
    `;


    export const GrandTotal = styled.div`
      font-size: 12px;
      padding: 6px 6px !important;
      background-color: #7031eb;
      color: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    export const ClearFiltersButtonWrapper = styled.div`
      width: 26px;
      height: 26px;
      border: 1px solid #ebebeb;
      border-radius: 19px;
      background: #7031EB07;
      display: flex;
      flex-direction: row;
      padding: 5px;
      justify-content: space-around;
      align-items: center;
      margin: 0 10px 0 10px;
      align-self: center;
      
      &:hover {
        cursor: pointer;
        background: #7031EB10;
      }
    `;
}

export default styles;

import marketingIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform,
    IntegrationType,
} from "@models";
import { addIntegration, organizationSelector } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { IntegrationButton } from "../../../components/integration-button";
import { googleAnalyticsConnectUrl } from "@constants";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import { integrationSelector } from "store/integrations";

export const WebsiteIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const integrations = useSelector(integrationSelector);

    // helpers
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    const sendGoogleAnalyticsOAuthFromSearchParams = (params: URLSearchParams) => {
        // @ts-ignore
        const googleAdsOAuthData = params.get('result');
        console.log(googleAdsOAuthData);
        try {
            if (!!googleAdsOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: 'Google Analytics',
                        type: IntegrationType.WEBSITE,
                        platform: IntegrationPlatform.GOOGLE_ANALYTICS,
                        object: JSON.parse(googleAdsOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };


    // renderers
    const renderWebsiteIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('WEBSITE', { ns: 'translation' })}
                </Text>
                <Flex direction={"row"}>
                    {renderGoogleAnalyticsConnector()}
                </Flex>
            </Flex>
        );
    };

    const renderGoogleAnalyticsConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.GOOGLE_ANALYTICS);
        const googleAnalyticsOAuthUrl = `${googleAnalyticsConnectUrl}`;
        return (
            <OAuthPopup
                title={'Google Analytics'}
                height={800}
                width={1000}
                url={googleAnalyticsOAuthUrl ?? ''}
                onResponse={(params) => {
                    sendGoogleAnalyticsOAuthFromSearchParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        /*premium={true}*/
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            </OAuthPopup>
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderWebsiteIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

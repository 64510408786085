import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { PnlState } from "./state";

export const pnlStateSelector = (state: RootState): PnlState => state.pnl;

export const pnlDataLanguageSelector = createSelector(
  pnlStateSelector,
  (state) => state.data
);

export const pnlDataLanguageDraftSelector = createSelector(
    pnlStateSelector,
    (state) => state.draftData
);

export const pnlDataLanguageNamesSelector = createSelector(
    pnlStateSelector,
    (state) => state.names
);

export const pnlDataLanguageDisplayNamesSelector = createSelector(
    pnlStateSelector,
    (state) => state.displayNames
);

export const fieldsSelector = createSelector(
    pnlStateSelector,
    (state) => state.fields
);

export const isFinishedMovingLoading = createSelector(
    pnlStateSelector,
    (state) => state.isFinishedMoveLoad
);




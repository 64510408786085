import { IntegrationPlatform, IntegrationType } from "./integration";
import {PnlElementFrequencyEnum} from "../components/pnl-overlay-new/add-custom-expens/AddCustomExpense";

export enum PercentageFrom {
  GrossSales = "GROSS_REVENUE",
  NetSales = "NET_REVENUE",
  GrossProfit = "GROSS_PROFIT",
  MarketingSpend = "MARKETING_SPEND",
}

export enum PnlSections {
  ExpensesBank = "EXPENSES_BANK",
  GrossSales = "GROSS_SALES",
  NetSales = "NET_SALES",
  GrossProfit = "GROSS_PROFIT",
}

export interface CardTypes {
  ALL: string;
  BY_GROSS_REVENUE: string;
  BY_NET_REVENUE: string;
  BY_GROSS_PROFIT: string;
  COGS: string;
}

export enum Operator {
  PLUS = '+',
  MINUS = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
  EXPONENT = '^',
  MODULO = '%'
}


export interface Expression{
  isOperator?: boolean;
  order?: number;
  operand?: string | Operator; //name of the field or a constant value
  isNumber?: boolean;

}

export interface Metric {
  id?: string;
  createdAt?: string;
  changedAt?: string;
  fieldName: string;
  isDefault: boolean;
  isPercentage: boolean;
  expression: Expression[];
  isDisplayable: boolean;
  displayName: string;
  channels?: string[];
  frequency: PnlElementFrequencyEnum;
  startDate?: string;
  endDate?: string;
  fixedAmount?: number;
  isChanged?: boolean;

}


export interface PnlDataLanguageItem {
  id?: string;
  updatedAt: string;
  name: string;
  isPercentage: boolean;
  percentageFrom: PercentageFrom;
  channel: string;
  amount: number;
  frequency: number;
  group: PnlSections;
  type: string;
  isDefault?: boolean;
  startDate?: string;
  endDate?: string;
  startDateInfinity?: boolean;
  endDateInfinity?: boolean;
}

export interface TransactionFeeItem {
  id?: string;
  channel: string;
  paymentMethod: string;
  percentage: number;
  amount: number;
  immutable?: boolean;
}

export interface PnlDataLanguageSettingSettings {
  pnlItems: PnlDataLanguageItem[];
  transactionFees: TransactionFeeItem[];
}

export interface Payment {
  name?: string;
  code?: number;
  createAt?: string;
  updatedAt?: string;
  id?: string;
  wvChannelId?: string;
  isSelected?: boolean;
}

export interface Channel {
  name?: string;
  displayName?: string;
  integrationId?: string;
  platformType?: IntegrationPlatform;
  createAt?: string;
  type?: IntegrationType;
  updatedAt?: string;
  id?: string;
}

//TODO
export interface DictionaryKeyChannelValuePayment {
  [key: string]: Payment[];
}

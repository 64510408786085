import {createAction} from "@reduxjs/toolkit";


export enum BreakdownBuckets {
    PLATFORM,
    ACCOUNT,
    CAMPAIGN,
    AD_SET,
    AD

}

export const clearMarketingState = createAction('marketing/clearState')
export const setMarketingTableRows = createAction<{
    rows: string[];
    bucket: BreakdownBuckets
}>('marketing/setRows')
// local

//import { IntegrationType } from "@models";

// state
export interface Payment {
  name?: string;
  code?: number;
  createAt?: string;
  updatedAt?: string;
  id?: string;
  wvChannelId?: string;
}

export interface TransactionFee{
  updatedAt?: string;
  wvChannelId?: string;
  wvPaymentMethodId?: string;
  percentage?: number;
  amount?: number;
  startDate?: string;
  endDate?: string;
}



export interface PaymentsState {
  payments: Payment[];
  error?: any;
  loading?: boolean;
  transactionFees?: TransactionFee[]
  isSavingTransactionFeesLoading?: boolean;
  finishedSavingTransactionFees?: boolean;
}

// initial state
export const initalPayments: PaymentsState = {
  error: undefined,
  loading: false,
  payments: [],
  transactionFees: [],
  isSavingTransactionFeesLoading: false,
  finishedSavingTransactionFees: false,
};

import React, { useEffect, useState } from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip, TooltipItem,
} from 'chart.js';
import { Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import { CurrencyCode, organizationDataLanguageSelector, organizationSelector } from '@store';
import { currency } from 'components/general-overlay/GeneralOverlay';
import { IoIosArrowDown } from 'react-icons/io';
import { currencyOptions } from 'utils/currency/currencyOptions';
import {useTranslation} from "react-i18next";
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface PlanningItem {
    id?: string;
    title?: string;
    january?: number;
    february?: number;
    march?: number;
    april?: number;
    may?: number;
    june?: number;
    july?: number;
    august?: number;
    september?: number;
    october?: number;
    november?: number;
    december?: number;
    total?: number;
}

interface PlanningData {
    title?: string;
    id?: string;

    january?: number;
    february?: number;
    march?: number;
    april?: number;
    may?: number;
    june?: number;
    july?: number;
    august?: number;
    september?: number;
    october?: number;
    november?: number;
    december?: number;
    total?: number;
    subRows?: PlanningItem[];
}

export interface KpiItem {
    customers: number;
    orders: number;
    market: string;
    purchasePoint: string;
    country: string;
    source: string;
    activityDate: string;
    grossRevenue: number;
}

interface Props {

    planningDataYearly: PlanningData[];
    forecastData: PlanningData[];
    setTotalOrders: any;
    setTotalRevenue: any;
    chartTitle: string;
    setChartTitle: any;
    historyData: PlanningData[];
}






const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];




export const PlanningGraph = (props: Props) => {
    const [filteredByMonthsData, setFilteredByMonthsData] = useState({});
    const [chartData, setChartData] = useState<number[]>([]);
    const [plannedChartData, setPlannedChartData] = useState<number[]>([]);
    const [forecastChartData, setForecastChartData] = useState<number[]>([]);
    const [chartOptions, setChartOptions] = useState<string[]>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organizationId = useSelector(organizationSelector);
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [flag, setFlag] = useState(false);
    const { t } = useTranslation(["translation", "translation"]);
    const currentCurrency = useCurrencySign();


    const formatDataToChart = (data: any) => {
        const dataDict = {
            "january": { value: 0, index: 0 },
            "february": { value: 0, index: 1 },
            "march": { value: 0, index: 2 },
            "april": { value: 0, index: 3 },
            "may": { value: 0, index: 4 },
            "june": { value: 0, index: 5 },
            "july": { value: 0, index: 6 },
            "august": { value: 0, index: 7 },
            "september": { value: 0, index: 8 },
            "october": { value: 0, index: 9 },
            "november": { value: 0, index: 10 },
            "december": { value: 0, index: 11 },

        };
        const currentObj = data.findIndex((item: any) => item.title === props.chartTitle);
        let dataObj: any;
        if (currentObj > -1) {
            dataObj = data[currentObj];
        } else {
            const subRowData = data.map((item: any) => item.subRows?.find((subRow: any) => subRow.title === props.chartTitle));
            dataObj = subRowData.filter((item: any) => !!item)[0];
        }
        const copyOfDataDict = { ...dataDict };
        Object.keys(copyOfDataDict).forEach((key) => {
            if (!!dataObj && key in dataObj && key in copyOfDataDict && !!dataObj[key]) {
                copyOfDataDict[key].value = dataObj[key];
            }
        });
        const sortable: any[] = [];

        Object.keys(copyOfDataDict).forEach((key) => {
            sortable.push(copyOfDataDict[key]);
        });
        const planningData = sortable.sort((a: any, b: any) => a.index - b.index).map((item, index, array) => parseFloat(item.value.toFixed(2)));

        return planningData;

    };
    useEffect(() => {
        if (!!props.planningDataYearly && !!props.chartTitle) {
            if (!!formatDataToChart(props.planningDataYearly)) {
                setPlannedChartData(formatDataToChart(props.planningDataYearly));
            }
        }
    }, [props.planningDataYearly, props.chartTitle, flag]);

    useEffect(() => {
        if (!!props.forecastData && !!props.chartTitle) {
            if (!!formatDataToChart(props.forecastData)) {
                setForecastChartData(formatDataToChart(props.forecastData));
            }
        }
    }, [props.forecastData, props.chartTitle, flag]);

    useEffect(() => {
        if (!!props.historyData && !!props.chartTitle) {
            if (!!formatDataToChart(props.historyData)) {
                setChartData(formatDataToChart(props.historyData));
            }
        }
    }, [props.chartTitle, props.historyData, flag]);


    const data = {
        labels,
        datasets: [
            {
                label: 'Trending (Forecast)',
                data: forecastChartData,
                borderColor: '#BF9CEF',
                backgroundColor: '#BF9CEF',
                borderDash: [11, 5],
                fill: false,
                pointRadius: 0,
            },
            {
                label: 'Historical',
                data: chartData,
                borderColor: "#D1D4F8",
                backgroundColor: "#D1D4F8",
                pointRadius: 0,

            },
            {
                label: 'Plan',
                data: plannedChartData,
                borderColor: '#1A0989',
                backgroundColor: '#1A0989',
                pointBorderColor: "#1A0989", // blue point border
                pointBackgroundColor: "white", // wite point fill
                pointBorderWidth: 1, // point border width
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
       /* hover: {
            mode: 'nearest' as const,
            intersect: true
        },*/
        plugins: {
            legend: {
                position: 'top' as const,
                /* align: "start" as const, */
                lineDash: 1,
                lineWidth: .5,
                labels: {
                    boxHeight: 1,
                    boxBorder: 1,

                    /*usePointStyle: true,*/
                    pointStyle: 'line',
                }
            },
            title: {
                display: true,
                text: '',
            },
            tooltip: {
                intersect: false,
                mode: 'index' as const,
                backgroundColor: "white" as const,
                position: 'nearest' as const,
                borderWidth: 1,
                padding: 10,
                bodyColor: "#000000" as const,
                color: 'black' as const,
                titleColor: 'black' as const,
                callbacks: {
                    label: function(context:any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            if(props?.chartTitle === "Orders"){
                                label = (context.parsed.y).toLocaleString()
                            }else {
                                label = currentCurrency + (context.parsed.y).toLocaleString()
                            }
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    callback: function (label: any, index: any, labels: any) {
                        if (label > 10000) {
                            return currentCurrency + label / 1000 + "k";
                        }
                        if (label < -10000) {
                            return currentCurrency + label / 1000 + "k";

                        }
                        return currentCurrency + label;

                    }
                },
                scaleLabel: {
                    display: true,
                    labelString: '1k = 1000'
                },

            },
            /*  y1: {
     
                 position: "right" as const,
                 grid: {
                     drawBorder: false,
                 },
                 ticks: {
                     callback: function (label: any, index: any, labels: any) {
                         if (label === 0) {
                             return 0;
                         }
                         if (label < 1000) {
                             return label;
                         }
                         return label / 1000 + 'k';
                     }
                 },
                 scaleLabel: {
                     display: true,
                     labelString: '1k = 1000'
                 },
                 stacked: true,
             } */
        }
    };



    useEffect(() => {
        const chartOptions: string[] = [];

        props.planningDataYearly.forEach((row) => {
            !!row.title && chartOptions.push(row.title);
            if (!!row.subRows && row.subRows?.length > 0) {
                row.subRows.forEach((subRow) => {
                    !!subRow.title && chartOptions.push(t(subRow?.title, {ns: 'translation'}));
                });
            }
        });
        setChartOptions(chartOptions);
    }, [props.planningDataYearly]);

    useEffect(() => {
        if (!!chartOptions) {
            /* props.setChartTitle(chartOptions[0]); */
        }
    }, [chartOptions, props.setChartTitle]);

    const renderMenu = () => {
        return (
            <Menu>
                <MenuButton>
                    <styles.RowButton>
                        <styles.RowButtonText>
                            {props.chartTitle}
                        </styles.RowButtonText>
                        <styles.RowButtonIcon>
                            <IoIosArrowDown />
                        </styles.RowButtonIcon>
                    </styles.RowButton>

                </MenuButton>
                <MenuList style={{ margin: "0 0 0 15px", maxHeight: 300, overflowY: "scroll" }}>
                    {chartOptions.map((item) => {
                        return <MenuItem onClick={() => {
                            props.setChartTitle(item);
                            setFlag(prev => !prev);
                        }}>{item}</MenuItem>;
                    })}
                </MenuList>
            </Menu>
        );
    };


    return (
        <styles.Wrapper>
            {renderMenu()}
            {!!props.planningDataYearly && !!props.chartTitle ? <Line options={options} data={data} /> : <></>}
        </styles.Wrapper>
    );
};

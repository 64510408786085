import {apiBaseUrl} from "./general";

// sign-up url
export const authUrl = `${apiBaseUrl}/auth`;

// endpoints
export const signUpUrl = `${authUrl}/register`;
export const signInUrl = `${authUrl}/login`;
export const authenticateUrl = `${authUrl}/check`;
export const verifyUserUrl = `${authUrl}/verify`;

// local storage
export const localStorageTokenKey = `token`;
export const localStorageUserKey = `user`;

import { createAction } from "@reduxjs/toolkit";
import { DEFAULTS_DATES } from "components/datepicker-range/DatepickerRange";
import { KpisEnum } from "models/kpi-settings";
import { ExploresEnum } from "./state";
import {Toast} from "../../components/toast/Toast";

// clear state
export const clearUIState = createAction("ui/clearState");

export const setGlobalLoading = createAction<{ isLoading: boolean }>(
  "ui/setGlobalLoading"
);

export const setAppLoading = createAction<{ isLoading: boolean }>(
  "ui/setAppLoading"
);

export const setNavigationTab = createAction<{ tab?: string }>(
  "ui/setNavigationTab"
);

// open explore view
export const openDefaultExploreView = createAction<{
  isOpen: boolean;
  ExploresEnum?: ExploresEnum;
  stateToTransfer?: any;
}>("ui/openDefaultExploreView");
// open explore view
export const openSecondaryExplore = createAction<{
  isOpen: boolean;
  ExploresEnum?: ExploresEnum;
}>("ui/openSeconderyExploreView");

export const setCardSelected = createAction<{
  isSelected: boolean;
  kpisEnumWorkspace?: KpisEnum;
  kpisEnumMarketing?: KpisEnum;
}>("ui/setCardSelected");

export const openApplyBar = createAction<{ isApplyBarOpen: boolean }>(
  "ui/openApplyBar"
);
export const clearApplyBarState = createAction("ui/openApplyBarClearState");

export const openApplyModal = createAction<{ isApplyModalOpen: boolean }>(
  "ui/openApplyModal"
);

export const openSecondaryApplyBar = createAction<{ isApplyBarOpen: boolean }>(
    "ui/openSecondaryApplyBar"
);

export const openSecondaryApplyModal = createAction<{ isApplyModalOpen: boolean }>(
    "ui/openSecondaryApplyModal"
);

export const clearApplyModalState = createAction("ui/openApplyModalClearState");

export const setDateRangeState = createAction<{
  startDate?: string;
  endDate?: string;
  preset?: DEFAULTS_DATES;
}>("ui/setDateRangeState");
export const clearDateRangeState = createAction("ui/clearDateRangeState");
export const setChannels = createAction<{ channels?: any[] }>(
  "ui/setChannelsData"
);

export const addToasts = createAction<{ toasts?: Toast[] }>(
    "ui/addToasts"
);
export const removeToasts = createAction<{ toasts?: Toast[] }>(
    "ui/removeToasts"
);


import styled from "styled-components";
import tinycolor from "tinycolor2";

export namespace styles {
  export const TargetBadge = styled.div<{ color?: string }>``;

  export const TargetText = styled.span<{ color?: string }>`
    color: ${(props) => props.color};
    font-size: 17px;
    //text-align: center;
    display: inline-block;
    display: flex;
    flex-direction: column;
  `;
}

export default styles;

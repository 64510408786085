import styles from "./styles"
import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useMemo, useState} from "react";
import WideviewDataTableHeader from "./wideview-data-table-header";
import WideviewDataTableRows from "./wideview-data-table-rows";
import {camelCaseToWords, checkISODateInString} from "../../utils/strings/helpers";
import {useSelector} from "react-redux";
import {dateRangeSelector} from "../../store/ui";

export interface TableProps<T, K extends keyof T> {
    title?: string;
    data: Array<T>;
    // columns: Array<ColumnDefinitionType<T,K>>;
    // menuButton?: any;
}

export interface ColumnDefinitionType<T, K extends keyof T> {
    key: K;
    header?: string;
    type: any;
}

export const WideviewDataTable = <T, K extends keyof T>(props: TableProps<T, K>) => {
    const [items, setItems] = useState<any[]>([]);
    const [empty, setEmpty] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [columns, setColumns] = useState<Array<ColumnDefinitionType<any, keyof any>>>([])
    const dates = useSelector(dateRangeSelector);
    const createHeaders = (items: any[]) => {
        const headers: Array<ColumnDefinitionType<any, keyof any>> = []
        if (items?.length > 0){
            Object.keys(items[0])?.forEach((fieldName: string) => {
                let type = typeof items[0][fieldName]
                let isDate = false;
                if(type === 'string'){
                    isDate = checkISODateInString(items[0][fieldName]);
                }
                headers.push({
                    key: fieldName,
                    header: camelCaseToWords(fieldName),
                    type: isDate ? 'date' : type
                })
            })
        }
        headers.push({
            key: "id",
            type: 'string'
        })
        setColumns(headers)
    }

    const getAmountOfDays = () : number => {
        const firstDateAsString = dates?.startDate;
        const endDateAsString = dates?.endDate;
        if(!!firstDateAsString && !!endDateAsString){
            const startDate = new Date(firstDateAsString);
            const endDate = new Date(endDateAsString);
            const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            return diffDays;
        }
        return 32
    }

    useEffect(() => {
        // setItems([...props.data].reverse().slice(0, 5));
        // setEmpty(false);

            createHeaders(props.data);
        //console.log(props.data); //TODO check kpi
    }, [props.data, dates]);

    // const fetchMoreData = () => {
    //     setLoader(true);
    //     setTimeout(() => {
    //         setItems(items.concat([...props.data].reverse().slice(items.length, items.length + 5)));
    //         setLoader(false);
    //     }, 1500);
    //     if ([...props.data].reverse().slice(items.length, items.length + 5).length === 0) {
    //         setEmpty(true);
    //     }
    // };

        return (
            <styles.Wrapper>
                {/*<InfiniteScroll*/}
                {/*    dataLength={items.length}*/}
                {/*    next={fetchMoreData}*/}
                {/*    hasMore={!empty}*/}
                {/*    scrollableTarget="scrollableDiv"*/}
                {/*    loader={<></>}*/}
                {/*>*/}
                    <styles.TableCustom>
                        <WideviewDataTableHeader columns={columns}/>
                        {(getAmountOfDays() < 31) && <WideviewDataTableRows data={props.data} columns={columns}/>}
                    </styles.TableCustom>
                {/*</InfiniteScroll>*/}
            </styles.Wrapper>
        );
};
export default WideviewDataTable;


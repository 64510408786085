import styles from "./styles";
import { MoreInfoButton } from "../../components/more-info-button/MoreInfoButton";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DatepickerRange } from "../../components/datepicker-range/DatepickerRange";
import OperationsChain from "./operations-chain/OperationsChain";
import {
  inventoryIcon,
  lastMileShippingIcon,
  manufcaturingIcon,
  transportationIcon,
} from "../../utils/icons/opeartion-icons";
import {Box, Flex, Spinner, useDisclosure} from "@chakra-ui/react";
import TransportationSection from "./transportation-section/TransportationSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperationData, operationsSelector } from "../../store/operation";
import InventorySection from "./inventory-section/InventorySection";
import { organizationSelector } from "@store";
import { DepartmentsEnum } from "../../services/operations/operation.service";
import ManufacturingSection from "./manufacturing-section/ManufacturingSection";
import LastMileSection from "./last-mile-section/LastMileSection";
import {BsUpload} from "react-icons/bs";
import FileUploadDialog from "../../dialogs/file-upload-dialog/FileUploadDialog";

const strategyMoreInfoDialogProps = {
  title: "Operations",
  //subtitle: 'How to use this view?',
  description:
    "In this section, you`ll define the production costs and ensure efficient production tracking for your business.",
  //tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
  /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
                                                                                                                          videoTitle: 'Best Practices',
                                                                                                                          videoSubtitle: 'How to use this view?',
                                                                                                                          videoDescription: '5 min explanatory video from our COO',
                                                                                                                          videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export enum SupplyChainStep {
  MANUFACTURING,
  TRANSPORTATION,
  INVENTORY,
  LAST_MILE_SHIPPING,
}

export const Operation = () => {
  //hooks
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation(["translation", "translation"]);
  const [itemsToDisplay, setItemsToDisplay] = useState<
    { title: string; icon: JSX.Element; option: SupplyChainStep, disable?: boolean }[]
  >([]);
  const [selectedSupplyChainStep, setSelectedSupplyChainStep] =
    useState<SupplyChainStep>(SupplyChainStep.MANUFACTURING);
  const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] =
    useState(false);

  useEffect(() => {
    const ItemsToDisplay = [
      {
        title: "Manufacturing",
        icon: manufcaturingIcon(
          selectedSupplyChainStep === SupplyChainStep.MANUFACTURING
            ? "#210C91"
            : "#BDC3D0"
        ),
        option: SupplyChainStep.MANUFACTURING,
      },
      {
        title: "Transportation",
        icon: transportationIcon(
          selectedSupplyChainStep === SupplyChainStep.TRANSPORTATION
            ? "#210C91"
            : "#BDC3D0"
        ),
        option: SupplyChainStep.TRANSPORTATION,
      },
      {
        title: "Inventory",
        icon: inventoryIcon(
          selectedSupplyChainStep === SupplyChainStep.INVENTORY
            ? "#210C91"
            : "#BBC1CF"
        ),
        option: SupplyChainStep.INVENTORY,
      },
      {
        title: "Last Mile Shipping",
        icon: lastMileShippingIcon(
          selectedSupplyChainStep === SupplyChainStep.LAST_MILE_SHIPPING
            ? "#210C91"
            : "#BBC1CF"
        ),
        option: SupplyChainStep.LAST_MILE_SHIPPING,
      },
    ];
    setItemsToDisplay(ItemsToDisplay);
  }, [selectedSupplyChainStep]);

  //helpers
  const openStrategyMoreInfoDialog = (isOpen: boolean) => {
    setIsStrategyMoreInfoDialogOpen(isOpen);
  };

  //render
  const renderHeader = () => {
    return (
      <styles.HeaderWrapper>
        <styles.Title>
          {t("YOUR_SUPPLY_CHAIN", { ns: "translation" })}
          <MoreInfoButton onClick={() => openStrategyMoreInfoDialog(true)} />
          <MoreInfoDialog
            props={strategyMoreInfoDialogProps}
            isDialogOpen={isStrategyMoreInfoDialogOpen}
            onClose={() => setIsStrategyMoreInfoDialogOpen(false)}
          />
        </styles.Title>
      <Flex >
        {selectedSupplyChainStep === SupplyChainStep.LAST_MILE_SHIPPING ?
            <>
            <styles.UploadIcon onClick={onOpen}>
              <BsUpload />
            </styles.UploadIcon>
              <Box h={"45px"}  w={2}/>
            </>
            : <></>}

        { selectedSupplyChainStep !== SupplyChainStep.INVENTORY ? <DatepickerRange backgroundColor="white" onApply={(range) => {
        }}/> : <Box h={"45px"}  w={2}/>}
      </Flex>
      </styles.HeaderWrapper>

  );
  };
  const renderButtons = () => {
    return (
      <styles.ButtonsHolder>
        {!!itemsToDisplay ? (
          <OperationsChain
            setOption={setSelectedSupplyChainStep}
            cards={itemsToDisplay}
            currentSelectedItem={selectedSupplyChainStep}
          />
        ) : (
          <Spinner />
        )}
      </styles.ButtonsHolder>
    );
  };
  const renderSection = () => {
    switch (selectedSupplyChainStep) {
      case SupplyChainStep.TRANSPORTATION:
        return <TransportationSection />;
      case SupplyChainStep.INVENTORY:
        return <InventorySection />;
      case SupplyChainStep.MANUFACTURING:
        return <ManufacturingSection />;
      case SupplyChainStep.LAST_MILE_SHIPPING:
        return <LastMileSection />
      default:
        return (
          <>
            <Spinner />
          </>
        );
    }
  };

  return (
    <styles.Wrapper>
      <FileUploadDialog maxFiles={5} title={"Upload Invoices"} onOpen={onOpen} onClose={onClose} isOpen={isOpen} />

      <styles.SmallerWrapperBackground>
        {renderHeader()}

        {renderButtons()}
        <Box h={7} />
        {renderSection()}
      </styles.SmallerWrapperBackground>
    </styles.Wrapper>
  );
};

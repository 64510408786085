import { User } from "./user";

export enum KpisEnum {
  NULL,
  GROSS_REVENUE,
  NET_REVENUE,
  GROSS_PROFIT,
  CONTRIBUTION_PROFIT,
  GROSS_MARGIN,
  ORDERS,
  AVERAGE_ORDERS_VALUE, //average orders value - aov
  UNITS_SOLD,
  CUSTOMERS,
  NEW_CUSTOMER_ORDER,
  RETURNING_CUSTOMER_ORDERS,
  CUSTOMER_LIFETIME_VALUE, //customer lifetime value - clv
  COST_OF_GOODS_SOLD, //cost of goods sold - cogs
  SHOPPING_CART_ABANDONMENT_RATE,
  RETURNS,
  REPLACEMENTS,
  INVENTORY_TURNOVER,
  AVERAGE_INVENTORY,
  DAYS_SALES_OF_INVENTORY, //days sales of inventory - dsi
  DAYS_SALE_OUTSTANDING, //days sale outstanding - dso
  DAYS_PAYABLE_OUTSTANDING, //days payable outstanding - dpo
  CASH_CONVERSION_CYCLE, //cash conversion cycle - ccc
  MARKETING_SPEND,
  RETURN_ON_AD_SPEND, //return on ad spend - roas
  MARKETING_EFFICIENCY_RATIO, //marketing efficiency ratio - mer
  ADVERTISING_COST_OF_SALES, //advertising cost of sales - ACOS
  COST_PER_CLICK, // cost per click - cpc
  COST_PER_ORDER, //cost per order - cpo
  COST_PER_MILE, //cost per thousand (cost per mile) - cpm
  CUSTOMER_ACQUISITION_COST, //customer acquisition cost - cac
  AIO, //??
  USERS,
  NEW_USERS,
  CLICKS,
  SESSIONS,
  COST_PER_SESSION,
  CONVERSION_RATE,
  BLENDED_ROAS,
  COGS_AVERAGE,
  AVERAGE_UNITS_SOLD,
  PENDING_DEVICES,
  HOLD_DEVICES,
  APPROVED_DEVICES,
  NUMBER_OF_DEVICES,
  INCOMPLETE_ORDERS,
  REMAINING_COST
}

export interface KpiSettings {
  kpi: KpisEnum;
  target: number;
  owner?: User;
}

import {createAsyncThunk} from "@reduxjs/toolkit";
import {PlanningData} from "components/Planning/Planning";
import {
    BlendedRoasReportsQueryDto,
    kpiService, PnlReportGroupBy,
    PnlReportQueryDto,
    PnlReportShowBy,
} from "services/kpi/kpi.service";
import {
    CurrencyCode,
    DataLanguage,
    disableApplyButton,
} from "store/organization";
import {isHttpCodeSuccess} from "utils/network/helpers";
import {Channel} from "../channels";
import {getFormattedKpi} from "../../utils/kpi-data-format/kpiDataFormat";
import {GroupBy} from "../../features/workspace/Workspace";
import {operationService} from "../../services/operations/operation.service";
import {financeService} from "@services";
import {isGlobalLoadingSelector, setAppLoading, setGlobalLoading} from "../ui";
import {pnlConfig} from "./pnl-config";
import {cpoConfig} from "./cpo-config";
import {blendedRoasConfig} from "./blended-roas-config";

export const fetchKpiData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        type?: number;
        kpisType?: number;
        channels?: Channel[];
        pnlFields?: boolean;
        cogsFields?: boolean;
        fields?: string[];
        allDepartments?: boolean;

    }
>(
    "kpi/fetchKpiData",
    async ({
               organizationId, fromDate, toDate,
               type, kpisType, channels, pnlFields, cogsFields
               , fields, allDepartments
           }, thunkAPI) => {
        if (kpisType === 0 && type === 0 || !type && kpisType === 0) {

            const response = await kpiService.fetchDailyStats(organizationId,
                fromDate,
                toDate, channels, fields)
            return response.data;

        } else {
            const response = await kpiService.fetchKpiData(
                organizationId,
                fromDate,
                toDate,
                type,
                kpisType,
                pnlFields,
                cogsFields,
                fields,
                allDepartments
            );

            const formattedData = response;
            if (!!channels) {
                formattedData["data"]["data"] = getFormattedKpi(response.data?.data, channels);
            }
            return response.data;
        }


    }
);

export const fetchLastPeriod = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        type?: number;
        kpisType?: number;
        channels?: Channel[];
        pnlFields?: boolean;
        cogsFields?: boolean;
        fields?: string[];
        allDepartments?: boolean;

    }
    >(
    "kpi/fetchLastPeriod",
    async ({
               organizationId, fromDate, toDate,
               type, kpisType, channels, pnlFields, cogsFields
               , fields, allDepartments
           }, thunkAPI) => {
        if (kpisType === 0 && type === 0 || !type && kpisType === 0) {

            const response = await kpiService.fetchDailyStats(organizationId,
                fromDate,
                toDate, channels, fields)
            return response.data;

        } else {
            const response = await kpiService.fetchKpiData(
                organizationId,
                fromDate,
                toDate,
                type,
                kpisType,
                pnlFields,
                cogsFields,
                fields,
                allDepartments
            );

            const formattedData = response;
            if (!!channels) {
                formattedData["data"]["data"] = getFormattedKpi(response.data?.data, channels);
            }
            return response.data;
        }


    }
);


export const fetchExploreData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        type?: number;
        kpisType?: number;
        channels?: Channel[];
        pnlFields?: boolean;
        cogsFields?: boolean;
        fields?: string[];
        allDepartments?: boolean;

    }
    >(
    "kpi/fetchExploreData",
    async ({
               organizationId, fromDate, toDate,
               type, kpisType, channels, pnlFields, cogsFields
               , fields, allDepartments
           }, thunkAPI) => {
        if (kpisType === 0 && type === 0 || !type && kpisType === 0) {

            const response = await kpiService.fetchDailyStats(organizationId,
                fromDate,
                toDate, channels, fields)
            return response.data;

        } else {
            const response = await kpiService.fetchKpiData(
                organizationId,
                fromDate,
                toDate,
                type,
                kpisType,
                pnlFields,
                cogsFields,
                fields,
                allDepartments
            );

            const formattedData = response;
            if (!!channels) {
                formattedData["data"]["data"] = getFormattedKpi(response.data?.data, channels);
            }
            return response.data;
        }


    }
);

export const fetchBigWideviewCardsData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        channels: string[]

    }
    >(
    "kpi/fetchBigWideviewCardsData",
    async ({
               organizationId, fromDate, toDate, channels
           }, thunkAPI) => {

            const response = await kpiService.fetchBigWideviewCardsData(
                organizationId,
                fromDate,
                toDate,
                channels
            );

            return response.data;
    }
);

export const fetchGraphData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        channels?: string[];
    }
>(
    "kpi/fetchGraphData",
    async ({organizationId, fromDate, toDate, channels}, thunkAPI) => {
        const response = await kpiService.fetchGraphData(
            organizationId,
            fromDate,
            toDate,
            channels
        );
        return response.data;
    }
);

export const fetchPnlReports = createAsyncThunk<
    any,
    {
        organizationId: string;
        searchQueryDto: PnlReportQueryDto
    }
>(
    "kpi/fetchPnlReports",
    async ({organizationId, searchQueryDto}, thunkAPI) => {
        const response = await kpiService.fetchPnlReports(
            {organizationId, searchQueryDto: searchQueryDto}
        );

        const data = response?.data['data'];
        const reorderedData = data.map((obj: { [key: string]: any }) => {
            const {name, ...rest} = obj;
            return {name, ...rest};
        });

        return {...response.data, data: reorderedData}
    }
);

export const fetchBlendedRoasReports = createAsyncThunk<
    any,
    {
        organizationId: string;
        searchQueryDto: BlendedRoasReportsQueryDto
    }
    >(
    "kpi/fetchBlendedRoasReports",
    async ({organizationId, searchQueryDto}, thunkAPI) => {
        const response = await kpiService.fetchBlendedRoasReports(
            {organizationId, searchQueryDto: searchQueryDto}
        );

        const data = response?.data['data'];
        const reorderedData = data.map((obj: { [key: string]: any }) => {
            const {name, ...rest} = obj;
            return {name, ...rest};
        });

        return {...response.data, data: reorderedData}
    }
);

export const fetchCustomerReports = createAsyncThunk<
    any,
    {
        organizationId: string;
        searchQueryDto: BlendedRoasReportsQueryDto
    }
    >(
    "kpi/fetchCustomersReports",
    async ({organizationId, searchQueryDto}, thunkAPI) => {
        const response = await kpiService.fetchCustomerReports(
            {organizationId, searchQueryDto: searchQueryDto}
        );

        const data = response?.data['data'];
        const reorderedData = data.map((obj: { [key: string]: any }) => {
            const {name, ...rest} = obj;
            return {name, ...rest};
        });

        return {...response.data, data: reorderedData}
    }
)

export const fetchCpoReports = createAsyncThunk<
    any,
    {
        organizationId: string;
        searchQueryDto: BlendedRoasReportsQueryDto
    }
    >(
    "kpi/fetchCpoReports",
    async ({organizationId, searchQueryDto}, thunkAPI) => {
        const response = await kpiService.fetchCpoReports(
            {organizationId, searchQueryDto: searchQueryDto}
        );

        const data = response?.data['data'];
        const reorderedData = data.map((obj: { [key: string]: any }) => {
            const {name, ...rest} = obj;
            return {name, ...rest};
        });

        return {...response.data, data: reorderedData}
    }
);

export const fetchPnlTableConfig = createAsyncThunk<
    any,
    {
        organizationId: string;
    }
>(
    "kpi/fetchPnlTableConfig",
    async ({organizationId}, thunkAPI) => {
        /*const response = await kpiService.fetchPnlTableConfig(
            {organizationId}
        );
        console.log(response?.data)*/

        /*return response?.data*/
        return {data: pnlConfig}

    }
);

export const fetchCpoTableConfig = createAsyncThunk<
    any,
    {
        organizationId: string;
    }
    >(
    "kpi/fetchCpoTableConfig",
    async ({organizationId}, thunkAPI) => {
        /*const response = await kpiService.fetchCpoTableConfig(
            {organizationId}
        );


        return response?.data*/
        return {data: cpoConfig}
    }
);




export const fetchBlendedRoasTableConfig = createAsyncThunk<
    any,
    {
        organizationId: string;
    }
    >(
    "kpi/fetchBlendedRoasTableConfig",
    async ({organizationId}, thunkAPI) => {
        /*const response = await kpiService.fetchBlendedRoasTableConfig(
            {organizationId}
        );


        return response?.data*/
        return {data: blendedRoasConfig}

    }
);

export const fetchCacTableConfig = createAsyncThunk<
    any,
    {
        organizationId: string;
    }
    >(
    "kpi/fetchCacTableConfig",
    async ({organizationId}, thunkAPI) => {
        const response = await kpiService.fetchCacTableConfig(
            {organizationId}
        );


        return response?.data
    }
);



export const fetchMarketingKpi = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        channels?: Channel[]
    }
>(
    "kpi/fetchMarketingKpi",
    async ({organizationId, fromDate, toDate, channels}, thunkAPI) => {
        const response = await kpiService.fetchMarketingKpi(
            organizationId,
            fromDate,
            toDate,
        );

        const formattedData = response;
        if (!!channels) {
            formattedData["data"]["data"] = getFormattedKpi(response.data?.data, channels);
        }
        return response.data;
    }
);

export const fetchMarketingMappingByCampaign = createAsyncThunk<
    any,
    {
        organizationId: string;
    }
>(
    "kpi/fethcMarketingMappingData",
    async ({organizationId}, thunkAPI) => {
        const response = await kpiService.fetchMarketingMappingData(
            organizationId,
        );
        return response.data;
    }
);

export const fetchCustomTargets = createAsyncThunk<
    any,
    {
        organizationId: string;
        year: string;
    }
>("kpi/fetchCustomTargets", async ({organizationId, year}, thunkAPI) => {
    const response = await kpiService.fetchCustomTargets(organizationId, year);
    return response.data;
});

export const putCustomTargets = createAsyncThunk<
    any,
    {
        organizationId: string;
        data: PlanningData[];
        channels: any[];
        id?: string;
        year: string;
        dataLanguageId: string
        currency: CurrencyCode
    }
>(
    "kpi/putCustomTargets",
    async (
        {
            organizationId,
            data,
            channels,
            id,
            year,
            dataLanguageId,
            currency
        }: {
            organizationId: string;
            data: PlanningData[];
            channels: string[];
            id?: string;
            year: string;
            dataLanguageId: string;
            currency: CurrencyCode
        },
        thunkAPI
    ) => {
        const response = await kpiService.putCustomTargets(
            organizationId,
            data,
            channels,
            id,
            year,
            currency
        );
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))

            !!organizationId &&
            thunkAPI.dispatch(
                fetchCustomTargets({organizationId: organizationId, year})
            );
        }
        return response.data;
    }
);


export const createCustomTargets = createAsyncThunk<
    any,
    {
        organizationId: string;
        data: PlanningData[];
        channels: any[];
        id?: string;
        year: string;
        currency: CurrencyCode;
        dataLanguageId: string
    }
>(
    "kpi/createCustomTargets",
    async (
        {
            organizationId,
            data,
            channels,
            id,
            year,
            currency,
            dataLanguageId
        }: {
            organizationId: string;
            data: PlanningData[];
            channels: string[];
            id?: string;
            year: string;
            currency: CurrencyCode,
            dataLanguageId: string
        },
        thunkAPI
    ) => {
        const response = await kpiService.createCustomTargets(
            organizationId,
            data,
            channels,
            year,
            currency
        );
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))

            !!organizationId &&
            thunkAPI.dispatch(
                fetchCustomTargets({organizationId: organizationId, year})
            );
        }
        return response.data;
    }
);


export const assignTopKpi = createAsyncThunk<
    any,
    { data: DataLanguage; dataLanguageId: any; organizationId: string }
>(
    "kpi/assignTopKpi",
    async ({data, dataLanguageId, organizationId}, thunkAPI) => {
        try {
            const response = await kpiService.assignTopKpi({
                data,
                dataLanguageId,
                organizationId,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);


export const downloadPnlCsv = createAsyncThunk<any, {
    organizationId: string,
    fromDate: string,
    toDate: string,
    channels?: string[],
    fields?: string[],
    groupBy?: PnlReportGroupBy,
    showBy?: PnlReportShowBy,
    fileName?: string,
    currencySign?: string;
}>(
    "financial/downloadPnlCsv",
    async ({
               organizationId,
               fromDate,
               toDate,
               channels,
               fields,
               groupBy,
               showBy,
               fileName,
               currencySign
           }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppLoading({isLoading: true}))
            const response = await financeService.downloadPnlCsv({
                organizationId,
                fromDate,
                toDate,
                channels,
                fields,
                groupBy,
                showBy,
                fileName,
                currencySign
            });

            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(setAppLoading({isLoading: false}))

                return response.data;
            } else {
                thunkAPI.dispatch(setAppLoading({isLoading: false}))

                throw new Error();
            }
        } catch (err) {
            thunkAPI.dispatch(setAppLoading({isLoading: false}))

            return thunkAPI.rejectWithValue(err);
        }
    }
);


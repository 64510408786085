import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router";
import {authenticate, authLoadingSelector, organizationSelector, tokenSelector, userSelector} from "@store";
import {useEffect, useState} from "react";
import {Loading} from "../../../components/loading/Loading";

export const AdminGuard = (props: any) => {
    const [loading, setLoading] = useState(true);
    const authLoading = useSelector(authLoadingSelector);
    const user = useSelector(userSelector);
    const token = useSelector(tokenSelector);
    const currentOrganization = useSelector(organizationSelector);

    // hooks
    const dispatch = useDispatch();

    // effects
    useEffect(() => {
        if (!user?.id || !token) {
            dispatch(authenticate({ token: null, organizationIdFromStore: currentOrganization?.id }));
        }
        setLoading(false);
    }, [currentOrganization?.id, dispatch, token, user]);

    if (loading || !!authLoading) {
        return (<Loading />);
    } else if (!user?.workspaces || user?.workspaces?.length === 0) {
        return (<Navigate to="/onboarding/setup" replace />);
    } else if (!!user?.id && !!token) {
        return (<Outlet />);
    } else {
        return (<Navigate to="/auth" replace />);
    }
};

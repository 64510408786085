import Lottie from "lottie-react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
  `;

  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    //border-bottom: solid 1px;
    margin-bottom: 20px;
    scroll-behavior: smooth;
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;

  export const AssignedKpisContent = styled.div<{ val?: number }>`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: start;
    padding: 0 15px 8px;
    /*overflow-x: auto;*/
    scroll-behavior: smooth;
    &:last-of-type {
      padding: 0 15px 18px;
    }
    overflow-x: scroll;
    
    ::-webkit-scrollbar-track
    {
      border-radius: 10px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb{
      background-color: transparent;
    }

    :hover {
      ::-webkit-scrollbar-thumb{
        background-color: #00005B;
      }
      overflow-x: scroll;
      /*padding: 30px 30px 24px 30px;*/
    }
  `;

  export const RelativeWrapper = styled.div<{ val?: number }>`
    position: relative;
    opacity: 1;
    margin: 0 0 10px 0;
  `;

  export const DepartmentRowWrapper = styled.div<{
    val?: number;
    isComingSoon?: boolean;
  }>`
    display: flex;
    align-items: center;
    scroll-behavior: smooth;
    /*opacity: ${(props) =>
      !!props?.isComingSoon && props?.isComingSoon ? 0.38 : 1};*/
    flex-wrap: wrap;
    //position: relative;
  `;

  export const ComingSoonWrapper = styled.div<{ val?: number }>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    opacity: 0;
    z-index: 4;
    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
    }
    //display: flex;
  `;

  export const ComingSoonContainer = styled.div<{ val?: number }>`
    outline: 1px solid transparent;
    display: flex;
    justify-content: flex-start;
    border-radius: 40px;
    padding: 15px 10px;
    width: 538px;
    max-width: 100%;
    height: 75px;
    align-self: center;
    position: relative;
    background: hsl(221.5, 17.2%, 55.5%, 0.09);
    margin: 0 calc(35% - 10px);
  `;

  export const TextComingSoon = styled.span`
    color: #1a0989;
    font-size: 1.5625em;
    text-align: center;
    align-self: center;
    font-weight: 700;
    opacity: 1;
    width: 50%;
  `;
  export const LottieStyle = styled(Lottie)`
    position: absolute;
    bottom: -128px;
    left: 14%;
    opacity: 1;
    align-self: center;
  `;
}

export default styles;

import wideViewsStyles from "./styles";
import { OverTimeGraph } from "../../over-time-graph/OverTimeGraph";
import { useTranslation } from "react-i18next";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import {
  IconAcrossChannels,
  IconCalendar,
  IconClearFiltersArrow,
  IconDiamond,
  IconShare,
} from "../../../utils/icons/explore";
import { TargetBadge } from "../../target-badge/TargetBadge";
import React, { useEffect, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketingKpi, kpiStateSelector } from "store/kpis";
import { Kpi } from "models/kpi";
import {
  organizationDataLanguageMappingSelector,
  organizationDataLanguageSelector,
  organizationSelector,
} from "@store";
import { Row } from "react-table";
import { Effort, effortsSelector } from "store/efforts";
import { sumData } from "utils/get-months/getLastYear";
import {
  dateRangeSelector,
  ExploresEnum,
  openDefaultExploreView,
} from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import {
  DatepickerRange,
  DEFAULTS_DATES,
} from "components/datepicker-range/DatepickerRange";
import { getProgressBarColor } from "components/across-table/AcrossTable";
import { SourcesTable } from "./SourcesTable/SourcesTable";
import {
  currencyFormat,
  getOvertimeGraphDataForExplore,
} from "utils/kpi-render/kpiCalculation";
import { currencyOptions } from "utils/currency/currencyOptions";
import { Box, useDisclosure } from "@chakra-ui/react";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { KpisEnum } from "models/kpi-settings";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";
import { GroupBy } from "features/workspace/Workspace";
import { getDatesInRange } from "utils/colors";
import { channelSelector, channelsStateSelector } from "store/channels";
import { getChannelNameById } from "utils/get-channel-name/getChannelNameById";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import MoreInformation from "components/more-information/MoreInformation";
import { columns } from "../../../utils/effortTableHeaders/headers";
import {
  ExploreTableDataEnum,
  ExploreTableFooterTypeEnum,
  GenericExploreTable,
} from "../../generic-explore-table/GenericExploreTable";
import { ProgressBar } from "../../progress-bar/ProgressBar";
import { linkMarketingMappingToKpiData } from "../../../utils/kpi-data-format/kpiDataFormat";
import InformationWrapper from "../../InformationWrapper/InformationWrapper";
import useFetchDailyStats from "../../../utils/custom-hooks/useFetchDailyStats";

export interface ExploreProps {
    title?: string;

}

const marketingSpendExploreMoreInfoDialogProps = {
    title: 'Marketing Spend Explore',
    subtitle: 'On this page, you can identify the primary driver of any KPI.\nExploring your KPI will help you understand the main factors you should focus on in order to improve your results.',
    description: "\nThe exploration structure:\n\nWhen - The timeframe you want to focus on.\n\nWhere - The primary sources with the most impact on your performance.\n Why - Identify sub metrics to understand precise KPI elements.\n What - Actions you can focus on in order to improve overall.",
    //tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;


export const MarketingSpendExplore = (props: ExploreProps) => {
    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const kpis = useSelector(kpiStateSelector);
    const {isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose} = useDisclosure();  //
    const [ganttLabels, setGanttLabels] = useState<string[]>([])
    useFetchDailyStats('marketingCost')

    const {isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose} = useDisclosure();  //
    const datesSelector = useSelector(dateRangeSelector);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [sourceTableData, setSourceTableData] = useState<any>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organization = useSelector(organizationSelector);
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [totalMarketingSpend, setTotalMarketingSpend] = useState(0);
    const organizationId = useSelector(organizationSelector);
    const channelsData = useSelector(channelsStateSelector);
    const [graphData, setGraphData] = useState<any>();
    const marketingMapping = useSelector(organizationDataLanguageMappingSelector);

    const [acrossChannelsData, setAcrossChannelsData] = useState<any[]>([]);
    const [currentCurrency, setCurrentCurrency] = useState<string>("$");
    const [filteredByDatesData, setFilteredByDatesData] = useState<Kpi[]>([]);
    const [filteredByDatesTargets, setFilteredByDatesTargets] = useState<Kpi[]>([]);
    const [filteredByDatesMarketing, setFilteredByDatesMarketing] = useState<any[]>([]);
    const effortsData = useSelector(effortsSelector);
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const [isMarketingSpendExploreMoreInfoDialogOpen, setIsMarketingSpendExploreMoreInfoDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const channels = useSelector(channelSelector);
    const [vsTarget, setVsTarget] = useState(0);
    const [selectedMarketingRows, setSelectedMarketingRows] = useState<any[]>([]);


    useEffect(() => {
        const summedMarketingSpendByKpiData = sumData(filteredByDatesData, []);
        const summedMarketingSpendTargetKpiData = sumData(filteredByDatesTargets, []);
        const vsTarget = summedMarketingSpendByKpiData["marketingCost"] / summedMarketingSpendTargetKpiData["marketingCost"] * 100;
        setVsTarget(vsTarget)
    }, [filteredByDatesData, filteredByDatesTargets]);

    useEffect(() => {
        if(!kpis?.marketing?.data && !!datesSelector?.startDate && !!datesSelector?.endDate){
            dispatch(
                fetchMarketingKpi({
                    organizationId: organizationId!.id,
                    fromDate: datesSelector?.startDate,
                    toDate: datesSelector?.endDate,
                    channels: []
                })
            )
        }

    }, [kpis?.marketing?.data, datesSelector?.startDate, datesSelector?.endDate])




    useEffect(() => {
        //filter efforts by kpi
        if (!!effortsData) {
            const filteredEfforts = effortsData?.data.filter((item) => {
                return item?.kpi === KpisEnum.MARKETING_SPEND;
            });
            setEffortsTable(filteredEfforts);
        }

    }, [effortsData]);

    useEffect(() => {
        if (!!kpis.exploreData?.data) {
            const allOrgChannels = channels?.map((item) => item?.id);
            const filteredDataByOrg = kpis?.exploreData?.data?.filter((item) => allOrgChannels?.includes(item?.wvChannelId));

            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = filteredDataByOrg.filter((item) => {
                    return datesRange.includes(item.activityDate.slice(0, 10));
                });
                setFilteredByDatesData(filteredData);
            } else {

                setFilteredByDatesData(filteredDataByOrg);
            }
        }
    }, [kpis.exploreData?.data, startDate, endDate, channels]);

    useEffect(() => {
        if (!!kpis.targetData?.data) {
            const allOrgChannels = channels?.map((item) => item?.id);
            const filteredDataByOrg = kpis?.targetData?.data?.filter((item) => allOrgChannels?.includes(item?.wvChannelId));

            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = filteredDataByOrg.filter((item) => {
                    return datesRange.includes(item.activityDate.slice(0, 10));
                });
                setFilteredByDatesTargets(filteredData);
            } else {

                setFilteredByDatesTargets(filteredDataByOrg);
            }
        }
    }, [kpis.targetData?.data, startDate, endDate, channels]);

    useEffect(() => {
        if (!!kpis.exploreData?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.exploreData?.data.filter((item) => {
                    if (!!item?.activityDate) {
                        return datesRange.includes(item?.activityDate.slice(0, 10));
                    }
                    return;
                });
                setFilteredByDatesMarketing(filteredData);
            } else {
                setFilteredByDatesMarketing(kpis?.exploreData?.data);
            }
        }
    }, [kpis?.exploreData?.data, startDate, endDate, datesSelector.startDate, datesSelector.endDate]);

    useEffect(() => {
        let totalMarketing = 0;
        if (!!filteredByDatesData) {
            filteredByDatesData?.forEach((item) => {
                if(!!item?.marketingCost){
                    if (selectedMarketingRows?.length > 0 && selectedMarketingRows?.includes(item?.wvChannelId)) {
                        totalMarketing = totalMarketing + item?.marketingCost;
                    } else if(selectedMarketingRows?.length === 0) {
                        totalMarketing = totalMarketing + item?.marketingCost;
                    }
                }

            });
            if(selectedRows?.length === 0){
            setTotalMarketingSpend(totalMarketing);
            }else {
                const selectedChannelsIds= selectedRows?.map((item) => item?.original?.callbackId);
                let totalMarketingSpend = 0;
                const filteredDataBySelectedRows = filteredByDatesData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
                filteredDataBySelectedRows?.forEach((item) => {
                    if (!!item?.marketingCost) {
                        totalMarketingSpend = totalMarketingSpend + item?.marketingCost;
                    }
                });
                setTotalMarketingSpend(totalMarketingSpend);

            }
        }
    }, [filteredByDatesData, selectedRows, selectedMarketingRows]);

    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }

    }, [dataLanguage]);

    useEffect(() => {
        if (!!filteredByDatesMarketing) {
            const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'platform', 'impressions', 'imageName', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];
            const summedData = sumData(filteredByDatesMarketing, keysToIgnore);
            if (summedData?.marketingCost !== undefined && summedData?.grossRevenue !== undefined) {
                const graphDataToRender = {
                    title: "Spend",
                    value: currencyFormat(summedData["marketingCost"], 2, currentCurrency),
                    vsTargetPercentage: summedData["marketingCost"] / summedData["marketingCost"],
                    isRoot: true,
                    children: [
                        {
                            title: "Gross Revenue",
                            value: currencyFormat(summedData['grossRevenue'], 2, currentCurrency),
                            vsTargetPercentage: summedData['grossRevenue'] / summedData['grossRevenue'],
                            isRoot: false,
                            children: []
                        },
                        {
                            title: "ACOS",
                            value: isNaN(summedData["marketingCost"] / summedData['grossRevenue']) ||
                            summedData['grossRevenue'] === 0 ? 0 + "%" :
                                ((summedData["marketingCost"] / summedData['grossRevenue']) * 100)?.toFixed(2) + "%",
                            vsTargetPercentage: 95,
                            isRoot: false,
                            children: []
                        }
                    ]
                };
                setGraphData(graphDataToRender);
            }
        }

    }, [currentCurrency, filteredByDatesMarketing, startDate, endDate, datesSelector.startDate , datesSelector.endDate]);

    useEffect(() => {

        if (!!kpis?.exploreData?.data && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const allOrgChannels = channels?.map((item) => item?.id);
            let filteredData = kpis?.exploreData?.data?.filter((item) => allOrgChannels?.includes(item?.wvChannelId));
            if (selectedMarketingRows?.length > 0) {
                filteredData = filteredData?.filter((item) => selectedMarketingRows?.includes(item?.wvChannelId))
            }
            if(selectedRows?.length === 0){
                const graphData = getOvertimeGraphDataForExplore(filteredData,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "marketingCost");
                setActualDataChart(graphData.data);
                setLabels(graphData.labels);
            }
            else {
                const selectedChannelsIds= selectedRows?.map((item) => item?.original?.callbackId);
                const filteredDataBySelectedRows = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
                const graphData = getOvertimeGraphDataForExplore(filteredDataBySelectedRows,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "marketingCost");
                setActualDataChart(graphData.data);
                setLabels(graphData.labels);
            }
        }
    }, [kpis?.exploreData?.data, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, selectedRows, channels, selectedMarketingRows]);


    useEffect(() => {

        if (!!kpis?.targetData?.data && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const allOrgChannels = channels?.map((item) => item?.id);
            const filteredData = kpis?.targetData?.data?.filter((item) => allOrgChannels?.includes(item?.wvChannelId));
            if(selectedRows?.length === 0){
                const graphData = getOvertimeGraphDataForExplore(filteredData,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "marketingCost");
                setTargetDataChart(graphData.data);
                setLabels(graphData.labels);
            }
            else {
                const selectedChannelsIds= selectedRows?.map((item) => item?.original?.callbackId);
                const filteredDataBySelectedRows = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
                const graphData = getOvertimeGraphDataForExplore(filteredDataBySelectedRows,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "marketingCost");
                setTargetDataChart(graphData.data);
                setLabels(graphData.labels);
            }
        }
    }, [kpis?.targetData?.data, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, selectedRows, channels]);

    const renderPlatformName = (name?: string) => {
        switch (name){
            case("AmazonAds"):
                return 'Amazon Ads'
            case("GoogleAds"):
                return 'Google Ads'
            default:
                return name
        }
    }

    const filteredMarketingData = () => {
        if(!!kpis?.marketing?.data && !!marketingMapping){
            const selectedChannels = selectedRows?.map((row) => row?.original?.callbackId);
            if(selectedChannels?.length > 0){
                const data = linkMarketingMappingToKpiData(kpis?.marketing?.data, selectedChannels, marketingMapping)
                return data
            }else {
                return kpis?.marketing?.data
            }
            return kpis?.marketing?.data
        }
        return []
    }

    const transformMarketingData = () => {
        const newData = filteredMarketingData()?.map((item) => {
            if (!!item?.platform && !!item?.wvAccountId && !!item?.adId && !!item?.campaignName && !!item?.adSetId) {
                let wvChannelId: string | undefined = '';
                if(marketingMapping?.some((mappingElement) => mappingElement?.accountId === item?.wvAccountId)){
                    const foundElement = marketingMapping?.find((mappingElement) => mappingElement?.accountId === item?.wvAccountId);
                    wvChannelId = Array.isArray(foundElement?.salesChannels) ? foundElement?.salesChannels[0] : '';
                }
                if(marketingMapping?.some((mappingElement) => mappingElement?.campaignId === item?.campaignId)){
                    const foundElement = marketingMapping?.find((mappingElement) => mappingElement?.campaignId === item?.campaignId);
                    wvChannelId = Array.isArray(foundElement?.salesChannels) ? foundElement?.salesChannels[0] : '';
                }

                return {
                    wvChannelId,
                    platform: item?.platform,
                    account: item?.platform + item?.wvAccountId,
                    campaign: item?.platform + item?.wvAccountId + item?.campaignName,
                    adSet: item?.platform + item?.wvAccountId + item?.campaignName + item?.adSetId,
                    adId: item?.platform + item?.wvAccountId + item?.campaignName + item?.adSetId + item?.adId,
                    spend: item?.spend,
                    originalAccount: item?.wvAccountId,
                    originalCampaign: item?.campaignId,
                    originalAdSet: item?.adSetId,
                    originalAdId: item?.adId,
                    displayName: {
                        platform: renderPlatformName(item?.platform),
                        account: item?.accountName,
                        campaign: item?.campaignName,
                        adSet: item?.adsSetName,
                        adId: !!item?.adName ? item?.adName : item?.adId
                    }
                };
            } else return;
        });
        return newData
    }

    useEffect(() => {
        if (!!kpis?.marketing) {
            setSourceTableData(transformMarketingData()?.filter((item) => !!item));
        }
    }, [kpis?.marketing, selectedRows]);

    useEffect(() => {
        if (!!filteredByDatesData && !!channelsData.channels) {

            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];

            const rowData: { channelName?: string, grossRevenue: number, marketingCost: number, shareOfTotal?: number, vsTarget?: number, impactOnTarget?: number; callbackId?:string }[] = [];
            const rowDataTargets: { channelName?: string, grossRevenue: number, marketingCost: number, shareOfTotal?: number, vsTarget?: number, impactOnTarget?: number; callbackId?:string }[] = [];
            const channels = {};
            const targetChannels = {};
            filteredByDatesData?.forEach((item) => {
                if (!!channels[item?.wvChannelId]) {
                    channels[item?.wvChannelId]?.push(item);
                } else {
                    channels[item?.wvChannelId] = [item];
                }
            });

            filteredByDatesTargets?.forEach((item) => {
                if (!!targetChannels[item?.wvChannelId]) {
                    targetChannels[item?.wvChannelId]?.push(item);
                } else {
                    targetChannels[item?.wvChannelId] = [item];
                }
            });

            Object.keys(channels).forEach((key) => {
                const summedChannel = sumData(channels[key], keysToIgnore);
                rowData.push({
                    grossRevenue: summedChannel["grossRevenue"],
                    marketingCost: summedChannel["marketingCost"],
                    channelName: getChannelNameById(key, channelsData?.channels),
                    callbackId: key
                });
            });
            Object.keys(targetChannels).forEach((key) => {
                const summedChannel = sumData(targetChannels[key], keysToIgnore);
                rowDataTargets.push({
                    grossRevenue: summedChannel["grossRevenue"],
                    marketingCost: summedChannel["marketingCost"],
                    channelName: getChannelNameById(key, channelsData?.channels),
                    callbackId: key
                });
            });
            let totalMarketingCost = 0;

            rowData?.forEach((item) => {
                if (typeof item?.marketingCost === "number") {
                    totalMarketingCost = totalMarketingCost + item?.marketingCost;
                }
            });
            filteredByDatesTargets?.forEach((target)=> {
                if(!!target?.marketingCost){

                }
            })
            let totalGrossRevenue = 0;
            let totalMarketingSpend = 0;
            const newRowData = rowData?.map((item) => {
                if(!!item?.grossRevenue){
                  totalGrossRevenue += item?.grossRevenue;
                }
                if(!!item?.marketingCost){
                   totalMarketingSpend += item?.marketingCost;
                }


                const foundTargetForThisChannel = rowDataTargets?.find((target) => target?.callbackId === item?.callbackId)?.marketingCost;
                return {
                    ...item,
                    marketingSpend: item?.marketingCost,
                    grossRevenue: item?.grossRevenue,
                    shareOfTotal: isNaN(item?.marketingCost / totalMarketingCost) ? 0 : (item?.marketingCost / totalMarketingCost) * 100,
                    spendVsTarget: !!foundTargetForThisChannel && !!item?.marketingCost ? (item?.marketingCost / foundTargetForThisChannel * 100) : 0,
                    target: foundTargetForThisChannel,
                    acos: !!item?.grossRevenue && !!item?.marketingCost ? item?.marketingCost / item?.grossRevenue * 100 : 0,

                };
            });

            setAcrossChannelsData(newRowData);
        }
    }, [channelsData?.channels, filteredByDatesData]);

    const onApplyDates = () => {

    };


    // selectors

    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(new Date(datesSelector.startDate));
            setEndDate(new Date(datesSelector.endDate));
        }
    };


    // helpers


    const openShareModal = () => {
        onShareOpen();
    };
    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton onClick={() => openShareModal()}>
            <IconShare/>
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', {ns: 'translation'})}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderDatePicker = () => {
        return <wideViewsStyles.HeaderButton>
            <IconCalendar/>
            <wideViewsStyles.HeaderButtonText>
                {'This Month'}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow/>
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', {ns: 'translation'})}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({isOpen: false}))}>
                    <IconCloseOverlay/>
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    <InformationWrapper text={t('MARKETING_SPEND', {ns: 'translation'})}/>
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', {ns: 'translation'})}
                    <MoreInformation
                        moreInfoDialogProps={marketingSpendExploreMoreInfoDialogProps}
                        isMoreInfoDialogOpen={isMarketingSpendExploreMoreInfoDialogOpen}
                        setIsMoreInfoDialogOpen={setIsMarketingSpendExploreMoreInfoDialogOpen}
                    />
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {/*{renderResetFilters()}*/}
                    <DatepickerRange onApply={(e) => {
                        onApplyDates();
                    }} defaultsDate={DEFAULTS_DATES.LAST_30_DAYS}/>
                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currencyFormat(totalMarketingSpend, 2, currentCurrency)}
                </wideViewsStyles.Value>
                {/* <wideViewsStyles.BadgeWrapper>
                    <TargetBadge  deductionTarget={true} percentage={vsTarget} />
                </wideViewsStyles.BadgeWrapper>*/}
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };


    return (
        <>
            <ShareKpi exploreEnum={ExploresEnum.MARKETING_SPEND} isOpen={isShareOpen} onClose={onShareClose}
                      onOpen={onShareOpen}/>
            <EffortsModal dataToShow={{kpi: "Marketing Spend"}} isOpen={isEffortsOpen} onClose={onEffortsClose}
                          onOpen={onEffortsOpen}/>

            <wideViewsStyles.Wrapper>
                {renderHeader()}
                <wideViewsStyles.SectionOne>
                    <OverTimeGraph exploreGraph={true} displayGantt={true} setGanttLabels={setGanttLabels} clearFilters={resetFilters} groupByStatus={groupByStatus}
                                   setGroupBy={setGroupByStatus} kpiEnum={KpisEnum.MARKETING_SPEND}
                                   setStartDate={setStartDate} setEndDate={setEndDate} actual={actualDataChart}
                                   target={targetDataChart} labels={labels}/>
                </wideViewsStyles.SectionOne>
                <wideViewsStyles.SectionTwo>
                    <Box w={"50%"}>
                        <GenericExploreTable
                            icon={<IconAcrossChannels />}
                            setSelectedRows={setSelectedRows}
                            data={acrossChannelsData}
                            channelsTable={true}
                            defaultSortByKey={"shareOfTotal"}
                            headers={[
                                {
                                    header: "Channel",
                                    accessor: "channelName",
                                    footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                                },
                                {
                                    header: "Marketing Spend",
                                    accessor: "marketingSpend",
                                    cellType: ExploreTableDataEnum.CURRENCY,
                                    footerType: ExploreTableFooterTypeEnum.SUM,
                                },
                                {
                                    header: "Share of total",
                                    accessor: "shareOfTotal",
                                    footerType: ExploreTableFooterTypeEnum.SUM,
                                    cellType: ExploreTableDataEnum.PERCENTAGE,
                                },

                               /* {
                                    header: "Spend Vs Target",
                                    accessor: "spendVsTarget",
                                    cell: (info, row: any) => {
                                        return (
                                            <>
                                                {info?.toFixed(2)+"%"}
                                <div style={{ width: "40px" }}>
                                    <ProgressBar
                                        color={getProgressBarColor(info)}
                                        percentage={info}
                                    />
                                </div></>)

                                    }

                                },*/
                                {
                                    header: "ACOS",
                                    accessor: "acos",
                                    cellType: ExploreTableDataEnum.PERCENTAGE,
                                    footer: (value, rows) => {
                                        let totalMarketingSpend = 0;
                                        let totalGrossRevenue = 0;
                                        rows?.forEach((row: Row<{marketingSpend?: number, grossRevenue?: number}> ) => {
                                            if (!row.original.marketingSpend) {
                                                row.original.marketingSpend = 0;
                                            }
                                            if (!row.original.grossRevenue) {
                                                row.original.grossRevenue = 0;
                                            }
                                            totalMarketingSpend += row.original.marketingSpend;
                                            totalGrossRevenue += row.original.grossRevenue;
                                        })
                                        return !!totalMarketingSpend && !!totalGrossRevenue ? (totalMarketingSpend / totalGrossRevenue * 100) : 0
                                    },
                                },

                            ]}
                            height={427}
                            tableHeight={350}
                            title={t("ACROSS_CHANNELS", { ns: "translation" })}
                        />

                    </Box>
                    <Box w={"35px"}/>
                    <SourcesTable
                        icon={<IconAcrossChannels/>}
                        title={t('ACROSS_SOURCES', {ns: 'translation'})}
                        headers={["Platform", "Spend", "Share of Total"/* , "Impact on Target" *//* , "Tab" */]}
                        data={sourceTableData}
                        setSelectedRows={setSelectedMarketingRows}
                        isLoading={kpis?.marketingLoader}
                    />
                    <div style={{width: '20px'}}/>

                </wideViewsStyles.SectionTwo>
               {/* <wideViewsStyles.SectionThree>
                    {!!graphData ?
                        <Graph graphData={graphData} title={t('SUPPORTING_KPIS', {ns: 'translation'})}/> : <></>}
                </wideViewsStyles.SectionThree>*/}
              {/*  <wideViewsStyles.SectionFour>
                    <styles.Card>
                        <styles.Header>
                            <styles.Title>
                                <span style={{marginRight: '5px'}}>
                                    {<IconDiamond/>}
                                </span>
                                {t('EFFORTS_IN_PROGRESS', {ns: 'translation'})}
                            </styles.Title>
                            <DownloadButton onClick={() => console.log('download')}/>
                        </styles.Header>
                        {effortsTable !== undefined ? <EffortsTable
                            title={t('EFFORTS_IN_PROGRESS', {ns: 'translation'})}
                            data={effortsTable}
                            cameFromExplore
                            onOpen={onEffortsOpen}
                            headers={columns}
                        /> : <></>}
                    </styles.Card>
                </wideViewsStyles.SectionFour>*/}
            </wideViewsStyles.Wrapper>
        </>
    );
};

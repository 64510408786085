import styled from "styled-components";
import Lottie from "lottie-react";


export namespace styles {
    export const UploadButton = styled.button`
      background-color: ${props => props?.theme?.primaryColor};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 10px 20px;
      width: 120px;
      color: white;
      margin-bottom: 15px;
      align-self: flex-end;
      height: 44px;
      &:hover{
        opacity: .8;
      }
      &:active{
        opacity: .8;
      }
    `

    export const StyledInput = styled.input`
    &:focus{
      border-color: unset !important;
      box-shadow: unset !important;
      outline: none !important;
    }
    
    `

    export const ModalTitle = styled.div`
    `
    export const ModalSubTitle = styled.div`
      font-family: ${props => props?.theme?.fonts?.header};
      font-weight: lighter;
      font-size: 16px;
    `
    export const ModalTitleHolder = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${props => props?.theme?.fonts?.header};
      width: 100%;
      flex-direction: column;

    `
    export const LottieStyle = styled(Lottie)`
    //height: 40vh;
    width: 32%;
  `;
}

export default styles;

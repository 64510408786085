export const linksIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g id="Group_11606" data-name="Group 11606" transform="translate(-1017.688 -709.688)">
                <g id="Layer_2" data-name="Layer 2" transform="translate(1017.814 709.814)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_1067" data-name="Rectangle 1067" width="26" height="26" transform="translate(-0.126 -0.126)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(2.123 2.146)">
                        <path id="Path_12620" data-name="Path 12620" d="M9.291,31.464a5.364,5.364,0,0,1-3.755-1.556,5.257,5.257,0,0,1,0-7.51L9.4,18.536a5.257,5.257,0,0,1,7.51,0,1.073,1.073,0,0,1-1.5,1.5,3.219,3.219,0,0,0-4.506,0L7.038,23.9a3.186,3.186,0,1,0,4.506,4.506l2.414-2.521a1.073,1.073,0,0,1,1.5,1.5l-2.414,2.521a5.364,5.364,0,0,1-3.755,1.556Z" transform="translate(-3.959 -10.007)" fill="#7031eb"/>
                        <path id="Path_12621" data-name="Path 12621" d="M23.469,18.483a5.364,5.364,0,0,1-3.755-1.556,1.073,1.073,0,0,1,1.5-1.5,3.219,3.219,0,0,0,4.506,0l3.862-3.862a3.186,3.186,0,1,0-4.506-4.506L22.665,9.579a1.073,1.073,0,0,1-1.5-1.5l2.414-2.521a5.31,5.31,0,1,1,7.51,7.51l-3.862,3.862a5.364,5.364,0,0,1-3.755,1.556Z" transform="translate(-11.163 -4)" fill="#7031eb"/>
                    </g>
                </g>
            </g>
        </svg>

    );
};
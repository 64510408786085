import {
  Box,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { organizationDataLanguageSelector } from "@store";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Column, useExpanded, useTable } from "react-table";
import { currencyOptions } from "utils/currency/currencyOptions";
import { currencyFormat } from "utils/kpi-render/kpiCalculation";
import { styles } from "./styles";
import { useStartExpanded } from "./custom-hook-expand/useStartExpanded";
import { useTranslation } from "react-i18next";
import {isApplyBarOpenSelector, openApplyBar, openApplyModal} from "store/ui";

export interface PlanningTableData {
  editableCell?: boolean;
  title?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  type?: TypeOfRow;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  total?: number;
  subRows?: PlanningItem[];
  noCurrency?: boolean;
}

enum TypeOfRow {
  REVENUE,
  DEDUCTION,
}

interface Data {
  col_0?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  col_13?: number;
  type?: TypeOfRow;
  editableCell?: boolean;
}

interface PlanningItem {
  title?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  total?: number;
  editableCell?: boolean;
  noCurrency?: boolean;
}

interface PlanningData {
  title?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  total?: number;
  rowId?: string;
  editableCell?: boolean;
  subRows?: PlanningItem[];
}

interface Props {
  data: PlanningTableData[];
  setChartPlannedData: any;
  setGraphData: any;
  setApplyBarFlag: any;
}

export const dataToDataTable = (data: PlanningTableData[]): Data[] => {
  return data.map((row, i) => {
    return {
      type: row?.type,
      col_0: row?.title,
      january: row?.january,
      february: row?.february,
      march: row?.march,
      april: row?.april,
      may: row?.may,
      june: row?.june,
      july: row?.july,
      august: row?.august,
      september: row?.september,
      october: row?.october,
      november: row?.november,
      december: row?.december,
      editableCell: row?.editableCell,
      noCurrency: row?.noCurrency,
      subRows: row?.subRows?.map((subRow) => {
        if (!!row?.subRows?.length && row?.subRows?.length > 0) {
          return {
            col_0: subRow?.title,
            january: subRow?.january,
            february: subRow?.february,
            march: subRow?.march,
            april: subRow?.april,
            may: subRow?.may,
            june: subRow?.june,
            july: subRow?.july,
            august: subRow?.august,
            september: subRow?.september,
            october: subRow?.october,
            november: subRow?.november,
            december: subRow?.december,
            editableCell: subRow?.editableCell,
            noCurrency: row?.noCurrency,
          };
        }
        return <></>;
      }),
    };
  });
};

export const PlanningTable = (props: Props) => {
  const [initialTableData, setInitialTableData] = useState<any[]>([]);
  const [newTableData, setNewTableData] = useState<PlanningData[]>([]);
  const [currentCurrency, setCurrentCurrency] = useState<string>("$");
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
  useEffect(() => {
    if (!!props?.data) {
      setInitialTableData(props.data);
      setNewTableData(props.data);
      props.setGraphData(props.data);
    }
  }, [props.setGraphData, props.data]);

  useEffect(() => {
    // get currency sign
    if (
      dataLanguage?.generalSettings?.currency !== undefined &&
      !!dataLanguage?.generalSettings
    ) {
      const currencySign = currencyOptions.filter(
        (item) => item.value.enum === dataLanguage!.generalSettings!.currency
      );
      setCurrentCurrency(currencySign[0].value.sign);
    }
  }, [dataLanguage]);

  function Table({ columns: userColumns, data }: { columns: any; data: any }) {
    const { t } = useTranslation(["translation", "translation"]);
    const dispatch = useDispatch();

    const expandAll: { [key: string]: boolean } = {};
    data?.forEach((item: any, i: number) => {
      expandAll[i] = true;
    });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns: userColumns,
          data,
          initialState: { expanded: expandAll },
          /* initialState: { startExpanded } */
        },
        useExpanded
        /* useStartExpanded */
      );

    function localeStringToNumber(str: string) {
      return Number(str?.replace(/[^0-9.-]+/g, ''));
    }


    const handleDataChange = (column: any, event: any, key: string) => {

      let inputValue = event?.target?.value;
      if(typeof inputValue === "string"){
        inputValue = localeStringToNumber(inputValue);
      }
      if(inputValue.length === 0){
        return
      }
      if (!!column?.id) {
        if (inputValue < 0) {
          return;
        }

        const deepCopyOfData = JSON.parse(JSON.stringify(newTableData));
        const indexOfMarketingSpend = deepCopyOfData.findIndex(
          (item: any) => item?.title === "Marketing Spend"
        );
        const indexOfGrossRevenue = deepCopyOfData.findIndex(
          (item: any) => item?.title === "Gross Revenue"
        );
        const indexOfOrders = deepCopyOfData[indexOfGrossRevenue][
          "subRows"
        ].findIndex((item: any) => item?.title === "Orders");
        const currentDeductionIndex = initialTableData.findIndex(
          (item) => item.title === "Deductions"
        );
        const indexOfRoas = deepCopyOfData[indexOfMarketingSpend][
          "subRows"
        ].findIndex((item: any) => item?.title === "ROAS");
        const initialRevenueIndex = initialTableData.findIndex(
          (item) => item.title === "Gross Revenue"
        );
        const netRevenueIndex = initialTableData.findIndex(
          (item) => item.title === "Net Revenue"
        );
        const cogsIndex = initialTableData.findIndex(
          (item) => item.title === "COGS"
        );
       /* const customExpensedIndex = initialTableData.findIndex(
          (item) => item?.rowId === "netRevenueExpenses"
        );*/
        const grossProfitIndex = initialTableData.findIndex(
          (item) => item?.title === "Gross Profit"
        );
        const customSecondExpensedIndex = initialTableData.findIndex(
          (item) => item?.rowId === "grossProfitExpenses"
        );
        const contProfitIndex = initialTableData.findIndex(
          (item) => item?.title === "Contribution Profit"
        );
        const indexOfAov = deepCopyOfData[indexOfGrossRevenue][
          "subRows"
        ].findIndex((item: any) => item?.title === "AOV");
        const indexOfCpo = deepCopyOfData[indexOfMarketingSpend][
          "subRows"
        ].findIndex((item: any) => item?.title === "CPO");

        const initialGrossRevenue =
          deepCopyOfData[indexOfGrossRevenue][column?.id];
        if (
          key === "Marketing Spend" &&
          !!column?.id &&
          deepCopyOfData[indexOfMarketingSpend][column?.id] !==
            parseFloat(inputValue)
        ) {
          if (indexOfMarketingSpend > -1 && !!column?.id) {
            deepCopyOfData[indexOfMarketingSpend][column?.id] = parseFloat(
                inputValue
            );
            const indexOfCpo = deepCopyOfData[indexOfMarketingSpend][
              "subRows"
            ].findIndex((item: any) => item?.title === "CPO");
            const currentCpo =
              deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][
                column?.id
              ];

            if (indexOfGrossRevenue > -1) {
              const indexOfOrders = deepCopyOfData[indexOfGrossRevenue][
                "subRows"
              ].findIndex((item: any) => item?.title === "Orders");
              const indexOfAov = deepCopyOfData[indexOfGrossRevenue][
                "subRows"
              ].findIndex((item: any) => item?.title === "AOV");
              const currentAov =
                deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
                  column?.id
                ];
              const newOrders = Math.floor(inputValue / currentCpo); // current Orders
              deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
                column?.id
              ] = newOrders;
              deepCopyOfData[indexOfGrossRevenue][column?.id] =
                newOrders * currentAov;
              deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
                column?.id
              ] = !!parseFloat(inputValue)
                ? (newOrders * currentAov) / parseFloat(inputValue)
                : 0;
            }
          }
          const revenuePercantage =
            deepCopyOfData[indexOfGrossRevenue][column?.id];

          if (currentDeductionIndex > -1) {
            const percantageOfDeduction =
              (deepCopyOfData[currentDeductionIndex][column?.id] /
                initialGrossRevenue) *
              100;
            deepCopyOfData[currentDeductionIndex][column?.id] =
              (percantageOfDeduction * revenuePercantage) / 100;

            deepCopyOfData[currentDeductionIndex]["subRows"] = deepCopyOfData[
              currentDeductionIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: (percantage * revenuePercantage) / 100,
                };
              } else {
                return item;
              }
            });
          }
          if (cogsIndex > -1) {
            const percantageOfCogs =
              (deepCopyOfData[cogsIndex][column?.id] / initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;
            deepCopyOfData[cogsIndex][column?.id] =
              revenuePercantage * percantageOfCogs;
            deepCopyOfData[cogsIndex]["subRows"] = deepCopyOfData[cogsIndex][
              "subRows"
            ].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }
          /*if (customExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customExpensedIndex]["subRows"] = deepCopyOfData[
              customExpensedIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }*/
          if (customSecondExpensedIndex > -1) {
            const percentageOfCustom =
              (deepCopyOfData[customSecondExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercentage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              revenuePercentage * percentageOfCustom;
            deepCopyOfData[customSecondExpensedIndex]["subRows"] =
              deepCopyOfData[customSecondExpensedIndex]["subRows"].map(
                (item: any) => {
                  if (!!column?.id && item[column?.id]) {
                    const percentage =
                      (item[column?.id] / initialGrossRevenue) * 100;
                    return {
                      ...item,
                      [column?.id]: percentage * revenuePercentage,
                    };
                  } else {
                    return item;
                  }
                }
              );
          }

          if (
            indexOfGrossRevenue > -1 &&
            currentDeductionIndex > -1 &&
            cogsIndex > -1 &&
            /*customExpensedIndex > -1 &&*/
            customSecondExpensedIndex > -1 &&
            indexOfMarketingSpend > -1
          ) {
            const newNetRevenue =
              deepCopyOfData[indexOfGrossRevenue][column?.id] -
              deepCopyOfData[currentDeductionIndex][column?.id];
            const newGrossProfit =
              newNetRevenue -
              deepCopyOfData[cogsIndex][column?.id] /*-
              deepCopyOfData[customExpensedIndex][column?.id];*/
            const newContProfit =
              newGrossProfit -
              deepCopyOfData[customSecondExpensedIndex][column?.id] -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
            setNewTableData(deepCopyOfData);
            props.setGraphData(deepCopyOfData);
            if(!isApplyBarOpen){
            dispatch(openApplyBar({ isApplyBarOpen: true }));
            }
          }
        }
        if (
          key === "Orders" &&
          !!column?.id &&
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
            column?.id
          ] !== parseFloat(inputValue)
        ) {
          // change cpo by old MS / with new Orders
          const indexOfCpo = deepCopyOfData[indexOfMarketingSpend][
            "subRows"
          ].findIndex((item: any) => item?.title === "CPO");
          const indexOfRoas = deepCopyOfData[indexOfMarketingSpend][
            "subRows"
          ].findIndex((item: any) => item?.title === "ROAS");
          const indexOfAov = deepCopyOfData[indexOfGrossRevenue][
            "subRows"
          ].findIndex((item: any) => item?.title === "AOV");
          const currentMarketingSpend =
            deepCopyOfData[indexOfMarketingSpend][column?.id];
          const newGrossRevenue =
            parseFloat(inputValue) *
            deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
              column?.id
            ];
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][
            column?.id
          ] = currentMarketingSpend / parseFloat(inputValue);
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
            column?.id
          ] = parseFloat(inputValue);
          deepCopyOfData[indexOfGrossRevenue][column?.id] = newGrossRevenue;
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
            column?.id
          ] = !!currentMarketingSpend
            ? newGrossRevenue / currentMarketingSpend
            : 0;

          const revenuePercantage =
            deepCopyOfData[indexOfGrossRevenue][column?.id];

          if (currentDeductionIndex > -1) {
            const percantageOfDeduction =
              (deepCopyOfData[currentDeductionIndex][column?.id] /
                initialGrossRevenue) *
              100;
            deepCopyOfData[currentDeductionIndex][column?.id] =
              (percantageOfDeduction * revenuePercantage) / 100;

            deepCopyOfData[currentDeductionIndex]["subRows"] = deepCopyOfData[
              currentDeductionIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: (percantage * revenuePercantage) / 100,
                };
              } else {
                return item;
              }
            });
          }
          if (cogsIndex > -1) {
            const percantageOfCogs =
              (deepCopyOfData[cogsIndex][column?.id] / initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;
            deepCopyOfData[cogsIndex][column?.id] =
              revenuePercantage * percantageOfCogs;
            deepCopyOfData[cogsIndex]["subRows"] = deepCopyOfData[cogsIndex][
              "subRows"
            ].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }

          /*if (customExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customExpensedIndex]["subRows"] = deepCopyOfData[
              customExpensedIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }*/

          if (customSecondExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customSecondExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customSecondExpensedIndex]["subRows"] =
              deepCopyOfData[customSecondExpensedIndex]["subRows"].map(
                (item: any) => {
                  if (!!column?.id && item[column?.id]) {
                    const percantage =
                      (item[column?.id] / initialGrossRevenue) * 100;
                    return {
                      ...item,
                      [column?.id]: percantage * revenuePercantage,
                    };
                  } else {
                    return item;
                  }
                }
              );
          }
          if (
            cogsIndex > -1 &&
            /*customExpensedIndex > -1 &&*/
            currentDeductionIndex > -1 &&
            indexOfGrossRevenue > -1 &&
            customSecondExpensedIndex > -1 &&
            indexOfMarketingSpend > -1
          ) {
            const newNetRevenue =
              deepCopyOfData[indexOfGrossRevenue][column?.id] -
              deepCopyOfData[currentDeductionIndex][column?.id];
            const newGrossProfit =
              newNetRevenue -
              deepCopyOfData[cogsIndex][column?.id] /*-
              deepCopyOfData[customExpensedIndex][column?.id];*/
            const newContProfit =
              newGrossProfit -
              deepCopyOfData[customSecondExpensedIndex][column?.id] -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
            setNewTableData(deepCopyOfData);
            props.setGraphData(deepCopyOfData);
            if(!isApplyBarOpen){
              dispatch(openApplyBar({ isApplyBarOpen: true }));
            }          }
        }

        if (
          key === "AOV" &&
          !!column?.id &&
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
            column?.id
          ] !== parseFloat(inputValue)
        ) {
          // change cpo by old MS / with new Orders
          const indexOfRoas = deepCopyOfData[indexOfMarketingSpend][
            "subRows"
          ].findIndex((item: any) => item?.title === "ROAS");
          const indexOfOrders = deepCopyOfData[indexOfGrossRevenue][
            "subRows"
          ].findIndex((item: any) => item?.title === "Orders");
          const indexOfAov = deepCopyOfData[indexOfGrossRevenue][
            "subRows"
          ].findIndex((item: any) => item?.title === "AOV");
          const currentMarketingSpend =
            deepCopyOfData[indexOfMarketingSpend][column?.id];
          const initialGrossRevenuePerMonth =
            initialTableData[initialRevenueIndex][column?.id];
          const newGrossRevenue =
            parseFloat(inputValue) *
            deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
              column?.id
            ];
          /* deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][column?.id] = currentMarketingSpend / parseFloat(e?.target?.value); */
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
            column?.id
          ] = parseFloat(inputValue);
          deepCopyOfData[indexOfGrossRevenue][column?.id] = newGrossRevenue;
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
            column?.id
          ] = !!currentMarketingSpend
            ? newGrossRevenue / currentMarketingSpend
            : 0;

          const revenuePercantage =
            deepCopyOfData[indexOfGrossRevenue][column?.id];

          if (currentDeductionIndex > -1) {
            const percantageOfDeduction =
              (deepCopyOfData[currentDeductionIndex][column?.id] /
                initialGrossRevenue) *
              100;
            deepCopyOfData[currentDeductionIndex][column?.id] =
              (percantageOfDeduction * revenuePercantage) / 100;

            deepCopyOfData[currentDeductionIndex]["subRows"] = deepCopyOfData[
              currentDeductionIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: (percantage * revenuePercantage) / 100,
                };
              } else {
                return item;
              }
            });
          }
          if (cogsIndex > -1) {
            const percantageOfCogs =
              (deepCopyOfData[cogsIndex][column?.id] / initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;
            deepCopyOfData[cogsIndex][column?.id] =
              revenuePercantage * percantageOfCogs;
            deepCopyOfData[cogsIndex]["subRows"] = deepCopyOfData[cogsIndex][
              "subRows"
            ].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }

         /* if (customExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customExpensedIndex]["subRows"] = deepCopyOfData[
              customExpensedIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }*/

          if (customSecondExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customSecondExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customSecondExpensedIndex]["subRows"] =
              deepCopyOfData[customSecondExpensedIndex]["subRows"].map(
                (item: any) => {
                  if (!!column?.id && item[column?.id]) {
                    const percantage =
                      (item[column?.id] / initialGrossRevenue) * 100;
                    return {
                      ...item,
                      [column?.id]: percantage * revenuePercantage,
                    };
                  } else {
                    return item;
                  }
                }
              );
          }
          if (
            indexOfGrossRevenue > -1 &&
            currentDeductionIndex > -1 &&
            cogsIndex > -1 &&
            /*customExpensedIndex > -1 &&*/
            customSecondExpensedIndex > -1 &&
            indexOfMarketingSpend > -1
          ) {
            const newNetRevenue =
              deepCopyOfData[indexOfGrossRevenue][column?.id] -
              deepCopyOfData[currentDeductionIndex][column?.id];
            const newGrossProfit =
              newNetRevenue -
              deepCopyOfData[cogsIndex][column?.id]
              /*deepCopyOfData[customExpensedIndex][column?.id];*/
            const newContProfit =
              newGrossProfit -
              deepCopyOfData[customSecondExpensedIndex][column?.id] -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
            setNewTableData(deepCopyOfData);
            props.setGraphData(deepCopyOfData);
            if(!isApplyBarOpen){
              dispatch(openApplyBar({ isApplyBarOpen: true }));
            }          }
        }
        if (
          key === "CPO" &&
          !!column?.id &&
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][
            column?.id
          ] !== parseFloat(inputValue)
        ) {

          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][
            column?.id
          ] = parseFloat(inputValue);
          const newOrders =
            deepCopyOfData[indexOfMarketingSpend][column?.id] /
            parseFloat(inputValue);
          const newGrossRevenue =
            newOrders *
            deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
              column?.id
            ];
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
            column?.id
          ] = newOrders;
          deepCopyOfData[indexOfGrossRevenue][column?.id] = newGrossRevenue;
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
            column?.id
          ] =
            newGrossRevenue / deepCopyOfData[indexOfMarketingSpend][column?.id];

          const revenuePercantage =
            deepCopyOfData[indexOfGrossRevenue][column?.id];

          if (currentDeductionIndex > -1) {
            const percantageOfDeduction =
              (deepCopyOfData[currentDeductionIndex][column?.id] /
                initialGrossRevenue) *
              100;
            deepCopyOfData[currentDeductionIndex][column?.id] =
              (percantageOfDeduction * revenuePercantage) / 100;

            deepCopyOfData[currentDeductionIndex]["subRows"] = deepCopyOfData[
              currentDeductionIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: (percantage * revenuePercantage) / 100,
                };
              } else {
                return item;
              }
            });
          }
          if (cogsIndex > -1) {
            const percantageOfCogs =
              (deepCopyOfData[cogsIndex][column?.id] / initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;
            deepCopyOfData[cogsIndex][column?.id] =
              revenuePercantage * percantageOfCogs;
            deepCopyOfData[cogsIndex]["subRows"] = deepCopyOfData[cogsIndex][
              "subRows"
            ].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }

          /*if (customExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customExpensedIndex]["subRows"] = deepCopyOfData[
              customExpensedIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }*/

          if (customSecondExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customSecondExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customSecondExpensedIndex]["subRows"] =
              deepCopyOfData[customSecondExpensedIndex]["subRows"].map(
                (item: any) => {
                  if (!!column?.id && item[column?.id]) {
                    const percantage =
                      (item[column?.id] / initialGrossRevenue) * 100;
                    return {
                      ...item,
                      [column?.id]: percantage * revenuePercantage,
                    };
                  } else {
                    return item;
                  }
                }
              );
          }
          if (
            indexOfGrossRevenue > -1 &&
            currentDeductionIndex > -1 &&
            cogsIndex > -1 &&
            /*customExpensedIndex > -1 &&*/
            customSecondExpensedIndex > -1 &&
            indexOfMarketingSpend > -1
          ) {
            const newNetRevenue =
              deepCopyOfData[indexOfGrossRevenue][column?.id] -
              deepCopyOfData[currentDeductionIndex][column?.id];
            const newGrossProfit =
              newNetRevenue -
              deepCopyOfData[cogsIndex][column?.id] /*-*/
              /*deepCopyOfData[customExpensedIndex][column?.id];*/
            const newContProfit =
              newGrossProfit -
              deepCopyOfData[customSecondExpensedIndex][column?.id] -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
            setNewTableData(deepCopyOfData);
            props.setGraphData(deepCopyOfData);
            if(!isApplyBarOpen){
              dispatch(openApplyBar({ isApplyBarOpen: true }));
            }          }
        }
        if (
          key === "ROAS" &&
          !!column?.id &&
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
            column?.id
          ] !== parseFloat(inputValue)
        ) {
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfRoas][
            column?.id
          ] = parseFloat(inputValue);
          const newGrossRevenue =
            parseFloat(inputValue) *
            deepCopyOfData[indexOfMarketingSpend][column?.id];
          deepCopyOfData[indexOfGrossRevenue][column?.id] = newGrossRevenue;
          const newOrders =
            newGrossRevenue /
            deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfAov][
              column?.id
            ];
          deepCopyOfData[indexOfGrossRevenue]["subRows"][indexOfOrders][
            column?.id
          ] = newOrders;
          deepCopyOfData[indexOfMarketingSpend]["subRows"][indexOfCpo][
            column?.id
          ] = deepCopyOfData[indexOfMarketingSpend][column?.id] / newOrders;
          const revenuePercantage =
            deepCopyOfData[indexOfGrossRevenue][column?.id];

          if (currentDeductionIndex > -1) {
            const percantageOfDeduction =
              (deepCopyOfData[currentDeductionIndex][column?.id] /
                initialGrossRevenue) *
              100;
            deepCopyOfData[currentDeductionIndex][column?.id] =
              (percantageOfDeduction * revenuePercantage) / 100;

            deepCopyOfData[currentDeductionIndex]["subRows"] = deepCopyOfData[
              currentDeductionIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: (percantage * revenuePercantage) / 100,
                };
              } else {
                return item;
              }
            });
          }
          if (cogsIndex > -1) {
            const percantageOfCogs =
              (deepCopyOfData[cogsIndex][column?.id] / initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;
            deepCopyOfData[cogsIndex][column?.id] =
              revenuePercantage * percantageOfCogs;
            deepCopyOfData[cogsIndex]["subRows"] = deepCopyOfData[cogsIndex][
              "subRows"
            ].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }

          /*if (customExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customExpensedIndex]["subRows"] = deepCopyOfData[
              customExpensedIndex
            ]["subRows"].map((item: any) => {
              if (!!column?.id && item[column?.id]) {
                const percantage =
                  (item[column?.id] / initialGrossRevenue) * 100;
                return {
                  ...item,
                  [column?.id]: percantage * revenuePercantage,
                };
              } else {
                return item;
              }
            });
          }*/

          if (customSecondExpensedIndex > -1) {
            const percantgeOfCustom =
              (deepCopyOfData[customSecondExpensedIndex][column?.id] /
                initialGrossRevenue) *
              100;
            const revenuePercantage =
              deepCopyOfData[indexOfGrossRevenue][column?.id] / 100;

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              revenuePercantage * percantgeOfCustom;
            deepCopyOfData[customSecondExpensedIndex]["subRows"] =
              deepCopyOfData[customSecondExpensedIndex]["subRows"].map(
                (item: any) => {
                  if (!!column?.id && item[column?.id]) {
                    const percantage =
                      (item[column?.id] / initialGrossRevenue) * 100;
                    return {
                      ...item,
                      [column?.id]: percantage * revenuePercantage,
                    };
                  } else {
                    return item;
                  }
                }
              );
          }
          if (
            indexOfGrossRevenue > -1 &&
            currentDeductionIndex > -1 &&
            cogsIndex > -1 &&
            /*customExpensedIndex > -1 &&*/
            customSecondExpensedIndex > -1 &&
            indexOfMarketingSpend > -1
          ) {
            const newNetRevenue =
              deepCopyOfData[indexOfGrossRevenue][column?.id] -
              deepCopyOfData[currentDeductionIndex][column?.id];
            const newGrossProfit =
              newNetRevenue -
              deepCopyOfData[cogsIndex][column?.id] /*-
              deepCopyOfData[customExpensedIndex][column?.id];*/
            const newContProfit =
              newGrossProfit -
              deepCopyOfData[customSecondExpensedIndex][column?.id] -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;

            setNewTableData(deepCopyOfData);
            props.setGraphData(deepCopyOfData);
            if(!isApplyBarOpen){
              dispatch(openApplyBar({ isApplyBarOpen: true }));
            }          }
        }

        if (
          deepCopyOfData[currentDeductionIndex].subRows.findIndex(
            (item: any) => item?.title === key
          ) > -1
        ) {
          // first deductions edit
          const indexOfCurrentDeduction = deepCopyOfData[
            currentDeductionIndex
          ].subRows.findIndex((item: any) => item?.title === key);
          if (
            indexOfCurrentDeduction > -1 &&
            !!column?.id &&
            deepCopyOfData[currentDeductionIndex].subRows[
              indexOfCurrentDeduction
            ][column?.id] !== parseFloat(inputValue)
          ) {
            if (
              deepCopyOfData[currentDeductionIndex]?.subRows[
                indexOfCurrentDeduction
              ][column?.id] === undefined &&
              parseFloat(inputValue) === 0
            ) {
              return;
            }
            deepCopyOfData[currentDeductionIndex].subRows[
              indexOfCurrentDeduction
            ][column?.id] = parseFloat(inputValue);
            let totalFirstDeduction = 0;
            let totalCogs = 0;
            let customExpenses = 0;
            let secondCustomExpenses = 0;
            deepCopyOfData[currentDeductionIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  totalFirstDeduction =
                    totalFirstDeduction +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );
            /*deepCopyOfData[customExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  customExpenses =
                    customExpenses + (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );*/
            deepCopyOfData[cogsIndex]?.subRows?.forEach((row: any) => {
              if (!!column?.id) {
                totalCogs =
                  totalCogs + (!!row[column?.id] ? row[column?.id] : 0);
              }
            });
            deepCopyOfData[customSecondExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  secondCustomExpenses =
                    secondCustomExpenses +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );
            if (
              currentDeductionIndex > -1 &&
              indexOfGrossRevenue > -1 &&
              indexOfMarketingSpend > -1 &&
              netRevenueIndex > -1 &&
              grossProfitIndex > -1 &&
              contProfitIndex > -1
            ) {
              deepCopyOfData[currentDeductionIndex][column?.id] =
                totalFirstDeduction;
              const newNetRevenue =
                deepCopyOfData[indexOfGrossRevenue][column?.id] -
                totalFirstDeduction;
              const newGrossProfit = newNetRevenue - totalCogs - customExpenses;
              const newContProfit =
                newGrossProfit -
                secondCustomExpenses -
                deepCopyOfData[indexOfMarketingSpend][column?.id];
              deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
              deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
              deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
              setNewTableData(deepCopyOfData);
              props.setGraphData(deepCopyOfData);
              if(!isApplyBarOpen){
                dispatch(openApplyBar({ isApplyBarOpen: true }));
              }            }
          }
        }

        if (
          deepCopyOfData[cogsIndex]?.subRows?.findIndex(
            (item: any) => item?.title === key
          ) > -1
        ) {
          // second deductions edit
          const indexOfCurrentDeduction = deepCopyOfData[
            cogsIndex
          ]?.subRows?.findIndex((item: any) => item?.title === key);
          if (
            deepCopyOfData[cogsIndex]?.subRows[indexOfCurrentDeduction][
              column?.id
            ] === undefined &&
            parseFloat(inputValue) === 0
          ) {
            return;
          }
          if (cogsIndex > -1 && !!column?.id) {
            deepCopyOfData[cogsIndex].subRows[indexOfCurrentDeduction][
              column?.id
            ] = parseFloat(inputValue);
            let totalFirstDeduction = 0;
            let customExpenses = 0;
            let secondCustomExpenses = 0;
            deepCopyOfData[cogsIndex]?.subRows?.forEach((row: any) => {
              if (!!column?.id) {
                totalFirstDeduction =
                  totalFirstDeduction +
                  (!!row[column?.id] ? row[column?.id] : 0);
              }
            }); // cogs sub rows data

            /*deepCopyOfData[customExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  customExpenses =
                    customExpenses + (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );*/
            deepCopyOfData[customSecondExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  secondCustomExpenses =
                    secondCustomExpenses +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );
            deepCopyOfData[cogsIndex][column?.id] = totalFirstDeduction;
            const newNetRevenue = deepCopyOfData[netRevenueIndex][column?.id];
            const newGrossProfit =
              newNetRevenue - totalFirstDeduction - customExpenses;
            const newContProfit =
              newGrossProfit -
              secondCustomExpenses -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
          }
          setNewTableData(deepCopyOfData);
          props.setGraphData(deepCopyOfData);
          if(!isApplyBarOpen){
            dispatch(openApplyBar({ isApplyBarOpen: true }));
          }        }

        if (
          /*deepCopyOfData[customExpensedIndex]?.subRows.findIndex(
            (item: any) => item?.title === key
          ) > -1*/
            true
        ) {
          // custom expense deductions edit
          /*const indexOfCurrentDeduction = deepCopyOfData[
            customExpensedIndex
          ]?.subRows.findIndex((item: any) => item?.title === key);*/
          if (
            /*deepCopyOfData[customExpensedIndex]?.subRows[
              indexOfCurrentDeduction
            ][column?.id] === undefined &&
            parseFloat(e.target.value) === 0*/
              true
          ) {

          }
          if (!!column?.id) {
            /*deepCopyOfData[customExpensedIndex].subRows[
              indexOfCurrentDeduction
            ][column?.id] = parseFloat(e.target.value);*/
            let totalFirstDeduction = 0;
            let secondCustomExpenses = 0;
            let totalCogs = 0;

            /*deepCopyOfData[customExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  totalFirstDeduction =
                    totalFirstDeduction +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            ); // custom expense row data*/

            deepCopyOfData[cogsIndex]?.subRows?.forEach((row: any) => {
              if (!!column?.id) {
                totalCogs =
                  totalCogs + (!!row[column?.id] ? row[column?.id] : 0);
              }
            });

            deepCopyOfData[customSecondExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  secondCustomExpenses =
                    secondCustomExpenses +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            );
            /*deepCopyOfData[customExpensedIndex][column?.id] =
              totalFirstDeduction;*/

            const newNetRevenue =  deepCopyOfData[netRevenueIndex][column?.id];
            const newGrossProfit =
              newNetRevenue - totalFirstDeduction - totalCogs;
            const newContProfit =
              newGrossProfit -
              secondCustomExpenses -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[netRevenueIndex][column?.id] = newNetRevenue;
            deepCopyOfData[grossProfitIndex][column?.id] = newGrossProfit;
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
          }
          setNewTableData(deepCopyOfData);
          props.setGraphData(deepCopyOfData);
          if(!isApplyBarOpen){
            dispatch(openApplyBar({ isApplyBarOpen: true }));
          }        }

        if (
          deepCopyOfData[customSecondExpensedIndex]?.subRows?.findIndex(
            (item: any) => item?.title === key
          ) > -1
        ) {
          // custom expense deductions edit
          console.log(deepCopyOfData[customSecondExpensedIndex][column?.id]);
          const indexOfCurrentDeduction = deepCopyOfData[
            customSecondExpensedIndex
          ]?.subRows?.findIndex((item: any) => item?.title === key);
          if (
            deepCopyOfData[customSecondExpensedIndex]?.subRows[
              indexOfCurrentDeduction
            ][column?.id] === undefined &&
            parseFloat(inputValue) === 0
          ) {
            return;
          }
          if (!!column?.id) {
            deepCopyOfData[customSecondExpensedIndex].subRows[
              indexOfCurrentDeduction
            ][column?.id] = parseFloat(inputValue);
            let totalFirstDeduction = 0;

            deepCopyOfData[customSecondExpensedIndex]?.subRows?.forEach(
              (row: any) => {
                if (!!column?.id) {
                  totalFirstDeduction =
                    totalFirstDeduction +
                    (!!row[column?.id] ? row[column?.id] : 0);
                }
              }
            ); // custom expense row data

            deepCopyOfData[customSecondExpensedIndex][column?.id] =
              totalFirstDeduction;
            const newContProfit =
              deepCopyOfData[grossProfitIndex][column?.id] -
              totalFirstDeduction -
              deepCopyOfData[indexOfMarketingSpend][column?.id];
            deepCopyOfData[contProfitIndex][column?.id] = newContProfit;
          }
          setNewTableData(deepCopyOfData);
          props.setGraphData(deepCopyOfData);
          if(!isApplyBarOpen){
            dispatch(openApplyBar({ isApplyBarOpen: true }));
          }        }
      }
    };

    const InputCell = ({childrenString, row, cell} : {childrenString: any, row: any, cell:any}) => {
      const [focused, setFocused] = useState(false);
      const localeStringToNumber = (str: string) => (str?.replace(/\D/g,''));

      return (
          <InputGroup
              width={"100%"}
              display="flex"
              justifyContent={"center"}
          >
            <InputLeftElement
                zIndex={0}
                pointerEvents="none"
                color="gray.400"
                children={childrenString}
            />

            <styles.TableInput
                onFocus={() => setFocused(true)}
                defaultValue={
              typeof cell?.value === "string" ?
                  parseFloat(localeStringToNumber(cell?.value))
                  :
                  !isNaN(cell.value) || !!cell.value
                      ? typeof cell?.value === "number"
                          ? row?.original?.col_0 === "ROAS"  || row?.original?.col_0 === "CPO" || row?.original?.col_0 === "AOV" ?
                              focused ?
                              parseFloat(cell?.value.toFixed(2))
                                  : cell?.value.toLocaleString()
                                  :
                              focused ? parseFloat(cell?.value.toFixed(0)) :
                              parseInt(cell?.value?.toFixed(0)).toLocaleString()
                          : 0
                      : 0
                }
                type={focused ? "text" : "text"}
                onBlur={(e: any) => {
                  handleDataChange(
                      cell.column,
                      typeof e === "string" ? parseFloat(localeStringToNumber(e)) : e,
                      row.original["col_0"]
                  );
                  setFocused(false);

                }}

            />
          </InputGroup>
      )
    }

    return (
      <>
        <styles.StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.Header === "Title") {
                    return (
                      <styles.ThPlaceHolder
                        {...column.getHeaderProps()}
                      ></styles.ThPlaceHolder>
                    );
                  }
                  if (column.Header === "Total") {
                    return (
                      <styles.StyledTotal {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </styles.StyledTotal>
                    );
                  }
                  if (column.id === "expender") {
                    return <></>;
                  }
                  return (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i) => {
              prepareRow(row);

              return (
                <>
                  <styles.StyledTr
                    isSubRow={row.depth === 0}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell: Cell, i: number) => {
                      if (cell.column.id === "expender") {
                        return <></>;
                      }
                      if (cell.column.Header === "Total") {

                        let totalValue = 0;
                        row.cells.forEach((cell: Cell) => {
                          if (typeof cell?.value === "number") {
                            totalValue = cell?.value + totalValue;
                          }
                        });

                        if(row?.original?.col_0 === "Orders"){
                          return(
                              <styles.StyledTotalFooter>
                                {parseInt(totalValue.toFixed(0)).toLocaleString("en-US")}
                              </styles.StyledTotalFooter>
                          )
                        }
                        if(row?.original?.col_0 === "AOV"){
                          let totalGrossRevenue = 0;
                          let totalOrders = 0;
                          const grossRevenueRow = rows?.find((row) => row?.original["col_0"] === "Gross Revenue");
                          const ordersRow = rows?.find((row) => row?.original["col_0"] === "Orders");
                          grossRevenueRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalGrossRevenue = cell?.value + totalGrossRevenue;
                            }
                          })
                          ordersRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalOrders = cell?.value + totalOrders;
                            }
                          })

                          return(
                              <styles.StyledTotalFooter>
                                {currentCurrency +(parseFloat((totalGrossRevenue / totalOrders)?.toFixed(2)))?.toLocaleString("en-US")}
                              </styles.StyledTotalFooter>
                          )
                        }
                        if(row?.original?.col_0 === "CPO"){
                          let totalMarketingSpend = 0;
                          let totalOrders = 0;
                          const marketingSpendRow = rows?.find((row) => row?.original["col_0"] === "Marketing Spend");
                          const ordersRow = rows?.find((row) => row?.original["col_0"] === "Orders");
                          marketingSpendRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalMarketingSpend = cell?.value + totalMarketingSpend;
                            }
                          })
                          ordersRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalOrders = cell?.value + totalOrders;
                            }
                          })

                          return(
                              <styles.StyledTotalFooter>
                                {currentCurrency + (parseFloat((totalMarketingSpend / totalOrders)?.toFixed(2)))?.toLocaleString("en-US")}
                              </styles.StyledTotalFooter>
                          )
                        }
                        if(row?.original?.col_0 === "ROAS"){
                          let totalMarketingSpend = 0;
                          let totalGrossRevenue = 0;
                          const marketingSpendRow = rows?.find((row) => row?.original["col_0"] === "Marketing Spend");
                          const grossRevenueRow = rows?.find((row) => row?.original["col_0"] === "Gross Revenue");
                          marketingSpendRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalMarketingSpend = cell?.value + totalMarketingSpend;
                            }
                          })
                          grossRevenueRow?.cells.forEach((cell:any) => {
                            if (typeof cell?.value === "number") {
                              totalGrossRevenue = cell?.value + totalGrossRevenue;
                            }
                          })
                          return(
                              <styles.StyledTotalFooter>
                                {(parseFloat((totalGrossRevenue / totalMarketingSpend)?.toFixed(2)))?.toLocaleString("en-US")}
                              </styles.StyledTotalFooter>
                          )
                        }
                        if (!!row?.original?.noCurrency) {
                          return (
                            <styles.StyledTotalFooter>
                              {isNaN(totalValue)
                                ? totalValue.toLocaleString("en-US")
                                : 0}
                            </styles.StyledTotalFooter>
                          );
                        }
                        return (
                          <styles.StyledTotalFooter>
                            {currencyFormat(
                              isNaN(totalValue) ? 0 : totalValue,
                              0,
                              currentCurrency
                            )}
                          </styles.StyledTotalFooter>
                        );
                      }
                      if (cell.column.Header === "Title") {
                        return (
                          <styles.StyledTitle
                            {...row.getToggleRowExpandedProps()}
                            isDeduction={
                              row.original["type"] === TypeOfRow.DEDUCTION
                            }
                            isSubRow={row.depth === 0}
                            style={{ display: "flex", alignItems: "center" }}
                            {...cell.getCellProps()}
                          >
                            <Box w={5} />
                            <styles.TitleMargin isSubRow={row.depth === 0}>
                              {cell?.value === "freeOnBoard" ? "FOB" :
                                  cell?.value === "Custom Expenses" ? "Marketing & Others" :
                                      cell?.value === "Marketing Spend" ? "Digital Marketing Spend" :
                                      t(cell.value, { ns: "translation" })
                              }
                            </styles.TitleMargin>
                            <span>
                              {row.subRows.length > 0 ? (
                                row.isExpanded ? (
                                  <IoIosArrowDown
                                    style={{ margin: "0 0 0 10px" }}
                                  />
                                ) : (
                                  <IoIosArrowForward
                                    style={{ margin: "0 0 0 10px" }}
                                  />
                                )
                              ) : null}
                            </span>
                          </styles.StyledTitle>
                        );
                      }
                      if (
                        row.original["editableCell"] &&
                        cell.column.id !== "col_13"
                      ) {
                        let childrenString = currentCurrency;
                        if (
                          row?.original?.col_0 === "Orders" ||
                          row?.original?.col_0 === "ROAS" /*||*/
                           /* row?.original?.col_0 === "CPO" ||*/
                           /* row?.original?.col_0 === "AOV"*/
                        ) {
                          childrenString = "";
                        }
                        return (
                          <td
                            style={{ position: "relative" }}
                            {...cell.getCellProps()}
                          >
                            <InputCell cell={cell} row={row} childrenString={childrenString}/>
                          </td>
                        );
                      }
                      return (
                        <td
                          style={{ position: "relative" }}
                          {...cell.getCellProps()}
                        >
                          {/* {cell.render("Cell")} */}

                          {currencyFormat(
                            isNaN(cell.value) || !isFinite(cell.value)
                              ? 0
                              : cell.value,
                            0,
                            currentCurrency
                          )}
                        </td>
                      );
                    })}
                  </styles.StyledTr>
                </>
              );
            })}
          </tbody>
        </styles.StyledTable>
      </>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expender", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <></>
        ),
        Cell: ({ row }: any) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span>{/* {row.isExpanded ? "👇" : "👉"} */}</span>
          ) : null,
      },
      {
        Header: "Title",
        accessor: "col_0",
      },
      {
        Header: "January",
        accessor: "january",
      },
      {
        Header: "February",
        accessor: "february",
      },
      {
        Header: "March",
        accessor: "march",
      },
      {
        Header: "April",
        accessor: "april",
      },
      {
        Header: "May",
        accessor: "may",
      },
      {
        Header: "June",
        accessor: "june",
      },
      {
        Header: "July",
        accessor: "july",
      },
      {
        Header: "August",
        accessor: "august",
      },
      {
        Header: "September",
        accessor: "september",
      },
      {
        Header: "October",
        accessor: "october",
      },
      {
        Header: "November",
        accessor: "november",
      },
      {
        Header: "December",
        accessor: "december",
      },
      {
        Header: "Total",
        accessor: "col_13",
      },
    ],
    []
  );

  const data = React.useMemo(
    () => dataToDataTable(newTableData),
    [newTableData]
  );
  return (
    <>
      <styles.Table>
        <styles.titleHolder>
          {/* <styles.Title>Planning Breakdown</styles.Title> */}
          {/*  <Stack direction='row'>
                        <Switch onChange={() => { setTimeLineTable(!timeLineTable); }} colorScheme='teal' size='lg' aria-label="on" value={"on"} />
                    </Stack> */}
        </styles.titleHolder>

        {!!data ? <Table columns={columns} data={data} /> : <Spinner />}
      </styles.Table>
    </>
  );
};

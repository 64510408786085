import {collectorsBaseUrl} from "./general";
import {ilFlag} from "../utils/flags/flags-svg/il";
import {aeFlag, brFlag, caFlag, esFlag, mxFlag} from "../utils/flags";
import {usFlag} from "../utils/flags/flags-svg/us";
import {deFlag, frFlag, gbFlag, itFlag, nlFlag} from "../utils/flags/flags-svg/gb";
import {seFlag} from "../utils/flags/flags-svg/se";
import {beFlag, egFlag, plFlag} from "../utils/flags/flags-svg/pl";
import {jpFlag} from "../utils/flags/flags-svg/jp";
import {auFlag} from "../utils/flags/flags-svg/au";
import {sgFlag} from "../utils/flags/flags-svg/sg";
import {inFlag} from "../utils/flags/flags-svg/in";
import {saFlag, trFlag} from "../utils/flags/flags-svg/tr";
import {AmazonAdsRegions} from "../store/amazon-ads";

// amazon seller
export const amazonUSAppId = 'amzn1.sp.solution.792e38b1-658c-4ce2-ab8e-07ae8b0d0dec';//'amzn1.sp.solution.74c9e659-227f-45e6-a4e9-79ebd94e7caa' -- raven app;
export const amazonEUAppId = 'amzn1.sp.solution.d12d4203-4433-4624-a890-0d5ea233e422';

export const amazonAppState = 'US';
export const amazonSellerUri = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${amazonUSAppId}&state=${amazonAppState}&version=beta`;

// amazon-seller-seller seller refresh token
export const getAmazonSellerRefreshTokenUrl = `${collectorsBaseUrl}/amazon-sp/oauth/callback`;

// amazon ads
export const amazonAppClientId = 'amzn1.application-oa2-client.f583860b5045418198ea27180d59da96';
export const amazonAppClientSecret = '71731e51f145fac6acfd45a78d0be089a1517d932e75f4a032aeb7b0c94334a6';

// amazon-seller-seller ads refresh token
export const getAmazonAdsRefreshTokenUrl = `${collectorsBaseUrl}/amazon-ads/oauth`;

// skubana
export const skubanaUri = `https://demo.skubana.com/appstore/apps/wideview`

export const amazonAdsRegion = [
    {region: "North America (NA)", URL: "https://www.amazon.com/ap/oa", code: AmazonAdsRegions.NORTH_AMERICA},
    {region: "Europe (EU)", URL: "https://eu.account.amazon.com/ap/oa", code: AmazonAdsRegions.EUROPE},
    {region: "Far East (FE)", URL: "https://apac.account.amazon.com/ap/oa", code: AmazonAdsRegions.FAR_EAST},

]

export enum AmazonSellerMarketplacesCode {
    US,
    UK,
    Canada,
    Mexico,
    Brazil,
    Spain,
    France,
    Netherlands,
    Germany,
    Italy,
    Sweden,
    Poland,
    Egypt,
    Turkey,
    SaudiArabia,
    UAE,
    India,
    Belgium,
    Singapore,
    Australia,
    Japan
}

export enum AmazonSellerRegion{
    NA,
    FE,
    EU
}

export const amazonSellerMarketplaceRegion = [
    {
        marketplace: "North America",
        sellerCenterURL: "https://sellercentral.amazon.com",
        code: AmazonSellerRegion.NA
    },
    {
        marketplace: "Europe",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        code: AmazonSellerRegion.EU
    },
    {
        marketplace: "Far East",
        sellerCenterURL: "https://sellercentral.amazon.co.jp",
        code: AmazonSellerRegion.FE
    },
]

export const amazonSellerMarketplaces = [
    {
        marketplace: "US",
        sellerCenterURL: "https://sellercentral.amazon.com",
        flag: usFlag(),
        code: AmazonSellerMarketplacesCode.US
    },
    {
        marketplace: "UK",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        flag: gbFlag(), code: AmazonSellerMarketplacesCode.UK
    },
    {
        marketplace: "Canada",
        sellerCenterURL: "https://sellercentral.amazon.ca",
        flag: caFlag(), code: AmazonSellerMarketplacesCode.Canada
    },
    {
        marketplace: "Mexico",
        sellerCenterURL: "https://sellercentral.amazon.com.mx",
        flag: mxFlag(), code: AmazonSellerMarketplacesCode.Mexico

    },
    {
        marketplace: "Brazil",
        sellerCenterURL: "https://sellercentral.amazon.com.br",
        flag: brFlag(), code: AmazonSellerMarketplacesCode.Brazil
    },
    {
        marketplace: "Spain",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        flag: esFlag(), code: AmazonSellerMarketplacesCode.Spain
    },
    {
        marketplace: "France",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        flag: frFlag(), code: AmazonSellerMarketplacesCode.France
    },
    {
        marketplace: "Netherlands",
        sellerCenterURL: "https://sellercentral.amazon.nl",
        flag: nlFlag(), code: AmazonSellerMarketplacesCode.Netherlands
    },
    {
        marketplace: "Germany",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        flag: deFlag(), code: AmazonSellerMarketplacesCode.Germany

    },
    {
        marketplace: "Italy",
        sellerCenterURL: "https://sellercentral-europe.amazon.com",
        flag: itFlag(), code: AmazonSellerMarketplacesCode.Italy
    },
    {
        marketplace: "Sweden",
        sellerCenterURL: "https://sellercentral.amazon.se",
        flag: seFlag(), code: AmazonSellerMarketplacesCode.Sweden

    },
    {
        marketplace: "Poland",
        sellerCenterURL: "https://sellercentral.amazon.pl",
        flag: plFlag(), code: AmazonSellerMarketplacesCode.Poland
    },
    {
        marketplace: "Egypt",
        sellerCenterURL: "https://sellercentral.amazon.eg",
        flag: egFlag(), code: AmazonSellerMarketplacesCode.Egypt
    },
    {
        marketplace: "Turkey",
        sellerCenterURL: "https://sellercentral.amazon.com.tr",
        flag: trFlag(), code: AmazonSellerMarketplacesCode.Turkey
    },
    {
        marketplace: "Saudi Arabia",
        sellerCenterURL: "https://sellercentral.amazon.sa",
        flag: saFlag(), code: AmazonSellerMarketplacesCode.SaudiArabia
    },
    {
        marketplace: "U.A.E.",
        sellerCenterURL: "https://sellercentral.amazon.ae",
        flag: aeFlag(), code: AmazonSellerMarketplacesCode.UAE
    },
    {
        marketplace: "India",
        sellerCenterURL: "https://sellercentral.amazon.in",
        flag: inFlag(), code: AmazonSellerMarketplacesCode.India
    },
    {
        marketplace: "Belgium",
        sellerCenterURL: "https://sellercentral.amazon.com.be",
        flag: beFlag(), code: AmazonSellerMarketplacesCode.Belgium
    },
    {
        marketplace: "Singapore",
        sellerCenterURL: "https://sellercentral.amazon.sg",
        flag: sgFlag(), code: AmazonSellerMarketplacesCode.Singapore
    },
    {
        marketplace: "Australia",
        sellerCenterURL: "https://sellercentral.amazon.com.au",
        flag: auFlag(),
        code: AmazonSellerMarketplacesCode.Australia
    },
    {
        marketplace: "Japan",
        sellerCenterURL: "https://sellercentral.amazon.co.jp",
        flag: jpFlag(),
        code: AmazonSellerMarketplacesCode.Japan
    },
]


import styles from './styles';
// import {useTranslation} from "react-i18next";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import React from 'react';
import { useTranslation } from "react-i18next";

export const CreateAlertDialog = (props: {
    props: {},
    isDialogOpen: boolean,
    onClose: () => void;
}) => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    // helpers
    const onCloseDialog = () => {
        props.onClose();
    };

    // renderers

    return (
        <Modal isOpen={props.isDialogOpen} onClose={onCloseDialog}>
            <ModalOverlay />
            <styles.CustomModalContent>
                <styles.Wrapper>
                    <styles.CloseButton onClick={onCloseDialog}>
                    </styles.CloseButton>
                    <styles.Header>
                        <styles.TitleWrapper>
                            <styles.HorizontalBar />
                            <styles.Title>
                                {t('CREATE_ALERT', { ns: 'translation' })}
                            </styles.Title>
                        </styles.TitleWrapper>
                    </styles.Header>
                    <styles.Content>

                    </styles.Content>
                    <styles.Footer>
                        <styles.ApplyButton onClick={() => {
                            onCloseDialog();
                        }}>
                            {t('Create', { ns: 'translation' })}
                        </styles.ApplyButton>
                    </styles.Footer>
                </styles.Wrapper>
            </styles.CustomModalContent>
        </Modal>
    );
};

import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import { Kpi } from "../../models/kpi";
import { saveAs } from "file-saver";
import React from "react";
import {TransportationData} from "../../features/operation/transportation-section/shipping-table/ShippingTable";
import {InventoryData} from "../../store/operation";

/*const fetchOperations = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(`${apiBaseUrl}/organization/${organizationId}/channels`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};*/

export enum CarrierEnum {
  DHL,
  UPS,
  FEDEX,
}

export interface EditPurchaseOrder {
  paid: number;
  id: string;
}

export enum DepartmentsEnum {
  COMPANY,
  MARKETING,
  ORDERS,
  WEBSITE,
  OPERATION,
  CUSTOMER_SUCCESS,
  PURCHASE,
  TRANSPORTATION,
  INVENTORY,
  LAST_MILE,
}

export interface TransportationDto {
  organizationId: string, fromDate?: string, toDate?: string, query?: string, page?: number, size?: number, sort?: string[], description?: string[]
}

export interface LastMileDto {
  organizationId: string, fromDate?: string, toDate?: string, query?: string, page?: number, size?: number, sort?: string[], deliveryStatus?: string; tags?: string[]
}

export interface HandlingDto {
  organizationId: string, fromDate?: string, toDate?: string, query?: string, page?: number, size?: number, sort?: string[], statusCode?: string
}

export enum PurchaseOrderStatus {
  COMPLETED,
  IN_PROGRESS,
  PENDING,
}

export interface PurchaseOrder {
  id?: string;
  purchaseOrderId?: string;
  issueDate?: string;
  projectNumber?: string;
  supplier?: string;
  createdAt?: string;
  unitsAmount?: number;
  totalCost?: number;
  paid?: number;
  totalDelivered?: number;
  status?: PurchaseOrderStatus;

  remainingUnits?: number | string;
}

export interface AsnOrderEdit {
  id: string;
  shippingCost: number;
}

const fetchOperationData = async (
  organizationId: string,
  fromDate: string,
  toDate: string,
  type?: number,
  kpisType?:
    | DepartmentsEnum.PURCHASE
    | DepartmentsEnum.TRANSPORTATION
    | DepartmentsEnum.INVENTORY
    | DepartmentsEnum.LAST_MILE,
  query?: string,
  statusCode?: string,
  page?: number,
  size?: number,
  deliveryStatus?: string,
  tags?: string[],
  sort?: string[],
  description?: string[]
): Promise<AxiosResponse<Kpi>> => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
    channels: "",
    type,
    kpisType,
  };

  if (query !== null) {
    params["query"] = query;
  }
  if (page !== null) {
    params["page"] = page;
  }
  if (size !== null) {
    params["size"] = size;
  }
  if (statusCode !== null) {
    params["statusCode"] = statusCode;
  }
  if (deliveryStatus !== null) {
    params["deliveryStatus"] = deliveryStatus;
  }
  let tagsStr = "";
  tags?.map((integration, index) => {
    if (index === 0) {
      tagsStr += `tags=${integration}`;
    } else {
      tagsStr += `&tags=${integration}`;
    }
  });
  let sortStr = "";
  sort?.map((integration, index) => {
    if (index === 0) {
      sortStr += `sort=${integration}`;
    } else {
      sortStr += `&sort=${integration}`;
    }
  });

  let productsStr = "";
  description?.map((integration, index) => {
    if (index === 0) {
      productsStr += `description=${integration}`;
    } else {
      productsStr += `&description=${integration}`;
    }
  });
  return axios.get<Kpi>(
    `${apiBaseUrl}/data/${organizationId}/kpis?${tagsStr}&${sortStr}&${productsStr}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        ...params,
      },
    }
  );
};

const fetchInventoryData = async ({organizationId, fromDate, toDate, query, sort, description}: TransportationDto) => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
    channels: "",
  };

  if (query !== null) {
    params["query"] = query;
  }

  const sortStr = sort?.map(integration => `sort=${integration}`).join('&');
  const productsStr = description?.map(integration => `description=${integration}`).join('&');

  return axios.get<{data?: InventoryData[], filters?: string[]}>(
      `${apiBaseUrl}/organization/${organizationId}/inventory?${sortStr ?? ''}${productsStr ? '&' + productsStr : ''}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          ...params,
        },
      }
  );

}

const fetchTransportationData = async ({organizationId, fromDate, toDate, query , page , size, sort, description}: TransportationDto) => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
    channels: "",
  };

  if (query !== null) {
    params["query"] = query;
  }
  if (page !== null) {
    params["page"] = page;
  }
  if (size !== null) {
    params["size"] = size;
  }
  const sortStr = sort?.map(integration => `sort=${integration}`).join('&');
  const productsStr = description?.map(integration => `description=${integration}`).join('&');

  return axios.get<{data?: TransportationData, filters?: string[]}>(
      `${apiBaseUrl}/organization/${organizationId}/transportation?${sortStr ?? ''}${productsStr ? '&' + productsStr : ''}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          ...params,
        },
      }
  );
}

const fetchLastMileData = async ({organizationId, fromDate, toDate, query , page , size, sort, deliveryStatus, tags}: LastMileDto) => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
    channels: "",
  };

  if (query !== null) {
    params["query"] = query;
  }
  if (page !== null) {
    params["page"] = page;
  }
  if (size !== null) {
    params["size"] = size;
  }
  const sortStr = sort?.map(integration => `sort=${integration}`).join('&');
  const productsStr =`deliveryStatus=${deliveryStatus}`
  let tagsStr = "";
  tags?.map((tag, index) => {
    if (index === 0) {
      tagsStr += `tags=${tag}`;
    } else {
      tagsStr += `&tags=${tag}`;
    }
  });
  console.log(tags)

  return axios.get<{data?: TransportationData, filters?: string[]}>(
      `${apiBaseUrl}/organization/${organizationId}/last-mile?${sortStr ?? ''}${productsStr ? '&' + productsStr : ''}&${tagsStr ?? ''}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          ...params,
        },
      }
  );
}

const fetchHandlingStatus = async ({organizationId, fromDate, toDate, query ,size, page , statusCode, sort}: HandlingDto) => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
    channels: "",
  };

  if (query !== null) {
    params["query"] = query;
  }
  if (page !== null) {
    params["page"] = page;
  }
  if (size !== null) {
    params["size"] = size;
  }
  if (size !== null) {
    params["statusCode"] = statusCode;
  }
  const sortStr = sort?.map(integration => `sort=${integration}`).join('&');

  return axios.get<{data?: any[], filters?: string[]}>(
      `${apiBaseUrl}/organization/${organizationId}/purchases?${sortStr ?? ''}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          ...params,
        },
      }
  );
}

const fetchCountries = async (organizationId: string) => {

  return axios.get(
      `${apiBaseUrl}/organization/${organizationId}/inventory/countries`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  )
}


const getCsv = ({
  organizationId,
  fromDate,
  toDate,
  type,
  kpisType,
  query,
  statusCode,
  deliveryStatus,
  fileName,
}: {
  organizationId: string;
  fromDate: string;
  toDate: string;
  type?: number;
  kpisType?:
    | DepartmentsEnum.PURCHASE
    | DepartmentsEnum.TRANSPORTATION
    | DepartmentsEnum.INVENTORY
    | DepartmentsEnum.LAST_MILE;
  query?: string;
  statusCode?: string;
  deliveryStatus?: string;
  fileName?: string;
}): any => {
  const params = {
    fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
    toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",

    channels: "",
    type,
    kpisType,
    statusCode,
    deliveryStatus,
    query,
  };

  let urlToFetchData = `${apiBaseUrl}/data/${organizationId}/kpis/download`;
  if(kpisType === DepartmentsEnum.PURCHASE){
    urlToFetchData = `${apiBaseUrl}/organization/${organizationId}/purchases/download`;
  }
  if(kpisType === DepartmentsEnum.LAST_MILE){
    urlToFetchData = `${apiBaseUrl}/organization/${organizationId}/last-mile/download`;
  }

  return axios
    .get<any>(urlToFetchData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/octet-stream;",
        "Content-Disposition": "attachment",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: params,
    })
    .then((response) => {
      const csv =
        "data:text/csv;charset=utf-8,%EF%BB%BF" +
        encodeURIComponent(response.data);
      saveAs(csv, `${fileName}.csv`);
    });
};

const getProductCogsCsv = ({
                  organizationId
                }: {
  organizationId: string;
}): any => {

  return axios
      .get<any>(`${apiBaseUrl}/organization/${organizationId}/product/download`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/octet-stream;",
          "Content-Disposition": "attachment",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const csv =
            "data:text/csv;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(response.data);
        saveAs(csv, `products.csv`);
      });
};

const fetchLastMileMetaData = async (organizationId: string) => {
  return axios.get<string[]>(
    `${apiBaseUrl}/organization/${organizationId}/last-mile/tags`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const fetchManufacturingData = async (
  organizationId: string,
  query?: string,
  fromDate?: string,
  toDate?: string
): Promise<AxiosResponse<any>> => {
  const params = {
    query,
    fromDate,
    toDate,
  };

  return axios.get<PurchaseOrder>(
    `${apiBaseUrl}/organization/${organizationId}/operations/purchase-order`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params,
    }
  );
};

const fetchModalTrackingData = async (
  organizationId: string,
  orderId: string,
  trackingNumber: string
): Promise<AxiosResponse<any>> => {
  const params = {
    orderId,
    trackingNumber,
  };

  return axios.get<PurchaseOrder>(
    `${apiBaseUrl}/organization/${organizationId}/operations/tracking`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params,
    }
  );
};

const createPo = async ({
  organizationId,
  data,
}: {
  organizationId: string;
  data: PurchaseOrder[];
}): Promise<AxiosResponse<any, any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/operations/purchase-order`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const editPurchaseOrder = async ({
  organizationId,
  data,
}: {
  organizationId: string;
  data: PurchaseOrder;
}): Promise<AxiosResponse<any, any>> => {
  console.log(data);
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/operations/purchase-order`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const editAsnOrder = async ({
  organizationId,
  data,
}: {
  organizationId: string;
  data: AsnOrderEdit;
}): Promise<AxiosResponse<any, any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/operations/transportation`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deletePurchaseOrder = async ({
  organizationId,
  elementId,
}: {
  organizationId: string;
  elementId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.delete<any>(
    `${apiBaseUrl}/organization/${organizationId}/operations/purchase-order/${elementId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const uploadMultipleFiles = (
  organizationId: string,
  data: FormData,
  setProgress?: React.Dispatch<React.SetStateAction<number>>
) => {
  return axios.post(
    `${apiBaseUrl}/organization/${organizationId}/operations/invoices/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let realPercent = Math.floor((loaded * 100) / total);
        let percent = 0;
        if (realPercent === 100) {
          const interval = setInterval(() => {
            if (percent < 100) {
              percent++;
              if (setProgress) {
                setProgress(percent);
              }
            } else {
              clearInterval(interval);
            }
          }, 22); // update progress every 50 milliseconds
        }
      },
    }
  );
};

export const operationService = {
  fetchOperationData,
  fetchManufacturingData,
  createPo,
  deletePurchaseOrder,
  editPurchaseOrder,
  editAsnOrder,
  getCsv,
  fetchLastMileMetaData,
  uploadMultipleFiles,
  fetchModalTrackingData,
  fetchTransportationData,
  fetchInventoryData,
  fetchHandlingStatus,
  fetchLastMileData,
  fetchCountries,
  getProductCogsCsv
};

import {createAsyncThunk} from "@reduxjs/toolkit";
import {shopifyService} from "@services";
import {isHttpCodeSuccess} from "../../utils/network/helpers";

export const fetchShopifyOAuthUrl = createAsyncThunk<string, {
    shopName: string | null;
}>(
    'shopify/fetchShopifyOAuthUrl',
    async ({shopName}, thunkAPI) => {
        try {
            const response = await shopifyService.fetchShopifyOAuthUrl(shopName);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data?.oauthUrl;
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);


import styles from "./styles"
import {background, Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {ThreeDotMenuButton} from "../3dot-menu-button/ThreeDotMenuButton";
import {ColumnDefinitionType} from "./WideviewDataTable";
import {formatIsoDate} from "../../utils/date-format/dateFormat";

export interface WideviewTableRowsProps<T,K extends keyof T> {
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T,K>>;
}

export const WideviewTableRows = <T, K extends keyof T>(props: WideviewTableRowsProps<T, K>) => {

    //helpers
    const fixedValue = (row: T, column: ColumnDefinitionType<T,K>) => {
        if(column.type === 'number'){
            return (+row[column.key]).toFixed(2);
        }else if(column.type === 'date'){
            return row[column.key];// formatIsoDate((row[column.key]).toString());
        }else{
            return row[column.key];
        }
    }

    const rows = props.data.map((row, index) => {
        return(
                <styles.TrCustom key={`row-${index}`}>
                    {props.columns.map((column, index2) => {
                        // if (column.key === "id" && !!props.menuButton) {
                        //     const currentRow : any = row;
                        //     return <styles.TdCustom key={`cell-${index2}`}>
                        //         <Box key={index2} padding="0"
                        //              width={"100%"} height={21}>
                        //             {<ThreeDotMenuButton data={props.menuButton} size={10} callbackId={currentRow?.id}/>}
                        //         </Box>
                        //     </styles.TdCustom>
                        // }
                        // else {
                            return (
                                <styles.TdCustom key={`cell-${index2}`}>
                                    <Box key={index2} padding="0"
                                         width={"100%"}>
                                        {/*{row[column.key]}*/}
                                        {fixedValue(row, column)}
                                    </Box>
                                </styles.TdCustom>
                            );
                        // }
                    }
                    )}
                </styles.TrCustom>
          )
    });

    return (
        <styles.TbodyCustom>
            {rows}
            <Box>
            </Box>
        </styles.TbodyCustom>
    );
};

export default WideviewTableRows

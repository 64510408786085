import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ExploresEnum, isDefaultExploreOpenSelector, openDefaultExploreView } from "store/ui";
import { IconCloseOverlay } from "utils/icons/account-health";
import { useDispatch, useSelector } from "react-redux";
import {
    authSelector,
    organizationSelector,
    resetPassword,
    resetPasswordLoadingSelector,
    resetPasswordStatus,
    resetPasswordStatusAction,
    updateUser,
    userSelector
} from "@store";
import React, { useEffect, useState } from "react";
import {Avatar, Button, Input, Menu, MenuButton, MenuList, Spinner, useDisclosure} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Workspace } from "@models";
import { DepartmentsEnum } from "@services";
import {getUserColorById, gradientDictionary} from "../../../utils/colors";
import AvatarUploadDialog from "../../../dialogs/avatar-upload-dialog/AvatarUploadDialog";


export interface ExploreProps {
    title?: string;
}

interface MenuProps {
    title: string,
    items: any[],
    item: any,
    value: string,
    setItem: React.Dispatch<React.SetStateAction<any>>;
}

export const ProfileExplore = (props: ExploreProps) => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const resetPasswordLoading = useSelector(resetPasswordLoadingSelector);
    const resetPasswordSuccess = useSelector(resetPasswordStatus);
    const organization = useSelector(organizationSelector);
    const [department, setDepartment] = useState<string>('Company');
    const [nameInput, setNameInput] = useState<string>("");
    const explores = useSelector(isDefaultExploreOpenSelector);
    const departments = ["Company", "Marketing", "Finance", "Website"];
    const [formValidity, setFormValidity] = useState(false);
    const auth = useSelector(authSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();



    useEffect(() => {

        setFormValidity(nameInput?.length > 0);

    }, [nameInput?.length]);
    // helpers

    useEffect(() => {
        dispatch(resetPasswordStatusAction({ resetPasswordSuccess: false }));
    }, [explores.isDefaultExploreOpen]);

    useEffect(() => {
        let defaultValue = "";
        if (!!user?.firstName) {
            defaultValue = user?.firstName;
            setNameInput(defaultValue);
        }
        if (!!user?.firstName && !!user?.lastName) {
            defaultValue = user?.firstName + " " + user?.lastName;
            setNameInput(defaultValue);
        }
        else {
            setNameInput(defaultValue);
        }
    }, [user?.firstName, user?.lastName]);

    useEffect(() => {

        const copyOfUser = JSON.parse(JSON.stringify(user));
        const indexOfWorkspace = copyOfUser?.workspaces.findIndex((workspace: Workspace) => workspace.organization === organization?.id);
        const setDefaultDepartment = () => {
            switch (copyOfUser.workspaces[indexOfWorkspace].departments[0]) {
                case (DepartmentsEnum.COMPANY):
                    setDepartment("Company");
                    break;
                case (DepartmentsEnum.MARKETING):
                    setDepartment("Marketing");
                    break;
                case (DepartmentsEnum.FINANCE):
                    setDepartment("Finance");
                    break;
                case (DepartmentsEnum.WEBSITE):
                    setDepartment("Website");
                    break;
                default:
                    setDepartment("Company");
                    break;
            }
        };
        setDefaultDepartment();

    }, [user]);

    const getDepartmentEnum = () => {

        switch (department) {
            case ("Company"):
                return DepartmentsEnum.COMPANY;
            case ("Marketing"):
                return DepartmentsEnum.MARKETING;
            case ("Finance"):
                return DepartmentsEnum.FINANCE;
            case ("Website"):
                return DepartmentsEnum.WEBSITE;
            default:
                return DepartmentsEnum.COMPANY;
        }
    };

    const onApply = () => {
        let firstName;
        let lastName;
        if (!!nameInput) {
            const splitted = nameInput.split(' ');
            if (splitted?.length > 1) {
                firstName = splitted[0];
                splitted.shift();
                lastName = splitted.join(' ');
            } else {
                firstName = splitted[0];

            }
        }

        const copyOfUser = JSON.parse(JSON.stringify(user));
        copyOfUser["firstName"] = firstName;
        copyOfUser["lastName"] = lastName;
        const indexOfWorkspace = copyOfUser?.workspaces.findIndex((workspace: Workspace) => workspace.organization === organization?.id);
        copyOfUser.workspaces[indexOfWorkspace].departments = [getDepartmentEnum()];
        dispatch(updateUser({ userId: user?.id, user: copyOfUser, organizationId: organization?.id }));
        dispatch(openDefaultExploreView({ isOpen: false }));
    };

    const handleResetPassword = () => {
        dispatch(resetPassword({ email: user?.email }));
    };


    // renderers
    const renderInputName = (title: string) => {
        let defaultValue;
        if (!!user?.firstName && !!user.lastName) {
            defaultValue = user?.firstName + " " + user?.lastName;

        }
        else {
            defaultValue = "";
        }
        return <div>
            <div style={{ fontSize: 14, marginTop: 10, marginBottom: 9, fontWeight: "bold" }}>{title}</div>
            <Input onChange={e => setNameInput(e.target.value)} value={nameInput} type={"text"} w={476} borderRadius="50px" placeholder='Your Name' size='md' />
        </div>;
    };
    const renderInputPassword = (title: string, placeholder: string) => {
        return <div>
            <div style={{ fontSize: 14, marginBottom: 9, fontWeight: "bold" }}>{title}</div>
            <Input mb={4} type={"password"} w={606} borderRadius="50px" placeholder={placeholder} size='md' />
        </div>;
    };
    const renderInput = (title: string, defaultValue: string) => {
        return <div>
            <div style={{ fontSize: 14, marginBottom: 9, fontWeight: "bold" }}>{title}</div>
            <div style={{ width: 600, borderRadius: "50px", border: "1px solid #E2E8F0", height: 38, display: "flex", alignItems: "center" }}>
                <div style={{ margin: "0 0 0 16px" }}>
                    {defaultValue}
                </div>
            </div>
        </div>;

    };
    const RenderInputMenu = ({ title, items, item, setItem, value }: MenuProps) => {
        return (
            <div>
                <div style={{ fontSize: 14, marginBottom: 9, fontWeight: "bolder" }}>{title}</div>
                <Menu>
                    {({ isOpen }) => (
                        <>
                            <MenuButton colorScheme="white" border={"1px solid"} borderColor={"inherit"} borderRadius={50} fontWeight="normal" textAlign="left" color={"#31394d"} w={'100%'} isActive={isOpen} as={Button}
                                rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}>
                                {/* {(item ? t(item, { ns: 'translation' }) : t("SELECT", { ns: 'translation' }))} */}
                                {department}
                            </MenuButton>
                            <MenuList padding={0}>
                                {items.map((item, index) => <styles.StyledMenuItem w={606} key={index}
                                    onClick={() => setDepartment(item)}>{t(item, { ns: 'translation' })}</styles.StyledMenuItem>)}
                            </MenuList>
                        </>
                    )}
                </Menu>
            </div>
        );
    };

    const renderHeader = () => {
        return <styles.Header>
            <styles.Info>
                <styles.TitleWrapper>
                    <styles.Title>
                        {t('PROFILE_SETTINGS', { ns: 'translation' })}</styles.Title>
                </styles.TitleWrapper>
            </styles.Info>
            <styles.HeaderIcon>
                <span style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
                    {/* <button onClick={() => { dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.PROFILE })); }}>
                        <IconCloseOverlay />
                    </button> */}
                    <button onClick={() => {
                        dispatch(openDefaultExploreView({ isOpen: false }));

                    }}>
                        <IconCloseOverlay />
                    </button>
                </span>
            </styles.HeaderIcon>
        </styles.Header>;
    };

    const [avatarHover, setAvatarHover] = useState(false);

    const onAvatarHoverStart = () => {
        setAvatarHover(true)
    }

    const onAvatarHoverEnd = () => {
        setAvatarHover(false)
    }

    const renderImage = () => {
        if (!!user?.firstName && !!user?.lastName) {
            return (
                <Avatar _hover={{
                    cursor: "pointer"
                }}  opacity={avatarHover ? 0.7 : 1} onMouseEnter={onAvatarHoverStart} onMouseLeave={onAvatarHoverEnd} onClick={onOpen} src={auth?.user?.image} margin={[0, 3]} size={"xl"} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]} name={user?.firstName + " " + user?.lastName} />
        )}
        if (!!user?.firstName) {
            return (
                <Avatar _hover={{
                    cursor: "pointer"
                }} opacity={avatarHover ? 0.7 : 1} onMouseEnter={onAvatarHoverStart} onMouseLeave={onAvatarHoverEnd} onClick={onOpen} src={auth?.user?.image} margin={[0, 3]} size={"xl"} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]} name={user?.firstName} />
            );
        }
        else {
            return (
                <Avatar _hover={{
                    cursor: "pointer"
                }} opacity={avatarHover ? 0.7 : 1} onMouseEnter={onAvatarHoverStart} onMouseLeave={onAvatarHoverEnd} onClick={onOpen} src={auth?.user?.image} margin={[0, 3]} size={"xl"} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]} name={"User"} />
            );
        }
    };

    const sectionOne = () => {
        let email = "";
        if (!!user?.email) {
            email = user.email;
        }
        return (
            <styles.SectionOneWrapper>
                <styles.SectionOneLeft>
                    <styles.ImageContainer>
                        {renderImage()}
                        {/*  <styles.ImageTitle>
                            {t('UPDATE_PROFILE_PICTURE', { ns: 'translation' })}
                            <styles.ImageSubTitle>
                                {t('UPLOAD', { ns: 'translation' })} {t('OR_IMPORT_FROM', { ns: 'translation' })} {t('LINKEDIN', { ns: 'translation' })}
                            </styles.ImageSubTitle>
                        </styles.ImageTitle> // TODO .V3*/}
                    </styles.ImageContainer>
                    {renderInputName("Your Name")}
                </styles.SectionOneLeft>
                <styles.SectionOneRight>
                    {renderInput("Email Address", email)}
                    <RenderInputMenu value={department} title={"Departments"} items={[...departments]} item={department}
                        setItem={setDepartment} />
                </styles.SectionOneRight>
            </styles.SectionOneWrapper>
        );
    };

    const sectionTwo = () => {
        return (
            <styles.SectionTwoWrapper>
                <styles.SectionTwoLeft>
                    <styles.SectionTwoTitle>
                        {t('CHANGE_PASSWORD', { ns: 'translation' })}
                    </styles.SectionTwoTitle>
                    <styles.SectionTwoSubtitle>
                        <p>{t('FORGOT_YOUR_PASSWORD_?_NO_PROBLEM_!', { ns: 'translation' })}</p>
                        <p>{t('WE_WILL_SEND_YOU_RESET_PASSWORD_LINK_TO_YOUR_EMAIL_ADDRESS', { ns: 'translation' })}</p>
                    </styles.SectionTwoSubtitle>
                    <Button _focus={{ boxShadow: "unset" }} onClick={handleResetPassword} mt={5} border={"1px solid #7031EB"} color={"#7031EB"} backgroundColor={"white"} h={50} borderRadius={50} w={191} colorScheme='#7031EB' size='lg'>
                        {resetPasswordLoading ? <Spinner /> : resetPasswordSuccess ? "Email Sent" : <>{t('RESET_PASSWORD', { ns: 'translation' })}</>}
                        {/* {resetPasswordSuccess ? "Email Sent" : <>{t('RESET_PASSWORD', { ns: 'translation' })}</>} */}

                    </Button>

                </styles.SectionTwoLeft>
                <styles.SectionTwoRight>
                    {/*  {renderInputPassword("Current Password", "Input current password")}
                    {renderInputPassword("New Password", "Input new password")}
                    {renderInputPassword("Confirm New Password", "Input new password")} */}
                    {formValidity ? <styles.StyledButton onClick={() => { onApply(); }} >
                        {t('UPDATE_SETTINGS', { ns: 'translation' })}
                    </styles.StyledButton> : <styles.StyledButtonDisabled  >
                        {t('UPDATE_SETTINGS', { ns: 'translation' })}
                    </styles.StyledButtonDisabled>}

                </styles.SectionTwoRight>

            </styles.SectionTwoWrapper>
        );
    };
    return (
        <styles.Wrapper>
            <AvatarUploadDialog onClose={onClose} onOpen={onOpen} isOpen={isOpen}/>
            {renderHeader()}
            {sectionOne()}
            <styles.Divider />
            {sectionTwo()}
        </styles.Wrapper>
    );
};

export const pnlConfig = [
    {
        "_id" : "64bfbb123c5e4d57c73a633d",
        "tableName" : "pnl",
        "rows" : [
            {
                "fieldName" : "Gross Revenue",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    },
                    {
                        "key" : "rowBackgroundColor",
                        "value" : "#eef4ff"
                    },
                    {
                        "key" : "tooltip",
                        "value" : "Gross revenue"
                    }
                ]
            },
            {
                "fieldName" : "Contribution Profit",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    },
                    {
                        "key" : "rowBackgroundColor",
                        "value" : "#eef4ff"
                    },
                    {
                        "key" : "percentageFrom",
                        "value" : "Gross Revenue"
                    },
                    {
                        "key" : "percentageFromTitle",
                        "value" : "(% of GR)"
                    },
                    {
                        "key" : "percentageFromBolder",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Gross Profit",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    },
                    {
                        "key" : "rowBackgroundColor",
                        "value" : "#eef4ff"
                    },
                    {
                        "key" : "percentageFrom",
                        "value" : "Gross Revenue"
                    },
                    {
                        "key" : "percentageFromTitle",
                        "value" : "(% of GR)"
                    },
                    {
                        "key" : "percentageFromBolder",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Net Revenue",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    },
                    {
                        "key" : "rowBackgroundColor",
                        "value" : "#eef4ff"
                    },
                    {
                        "key" : "percentageFrom",
                        "value" : "Gross Revenue"
                    },
                    {
                        "key" : "percentageFromTitle",
                        "value" : "(% of GR)"
                    },
                    {
                        "key" : "percentageFromBolder",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Discount / Promotion",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Transaction Fees",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refunds Cost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Cost of Goods",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    },
                    {
                        "key" : "displayCurrencyForAllSubRows",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Marketing Cost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Shipping Charge",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Tax Charge",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Per Unit Fulfillment Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Referral Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Storage Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Removal Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Subscription",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Disposal Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Long Term Storage Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Sales Tax Collection Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Warehouse Damage",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reversal Reimbursement",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FBA Shipping Chargeback",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Item Price",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Shipping",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Tax",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refunded FBA Referral Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refunded Promotion",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refunded FBA Shipping Chargeback",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Gift Wrap Charge",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Amazon Fees",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    },
                    {
                        "key" : "boldTitle",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Duties Charge",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Duties",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refund Handling Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Gift Wrap Chargeback",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Gift Wrap",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Item Sub Total",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Item Sub Total",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Digital Marketing Spend",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Google Ads Cost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Facebook Ads Cost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Amazon Ads Cost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Custom Marketing Expense",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "AllRows",
                "attribute" : [
                    {
                        "key" : "boldTotal",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Reimbursed Goodwill",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Refund Adjustments",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Inbound Transportation",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Compensated Clawback",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Non Subscription Fee Adj",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Warehouse Lost",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Lightning Deal Fee",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            }
        ],
    }

]
import {ColumnDefinitionType} from "./WideviewTable";
import styles from "./styles";
import React from "react";
import {useTranslation} from "react-i18next";


export interface TableHeaderProps<T, K extends keyof T> {
    columns: Array<ColumnDefinitionType<T, K>>;
}

const TableHeader = <T, K extends keyof T> ({columns} : TableHeaderProps<T,K>) => {
    const { t } = useTranslation(['translation', 'translation']);

    const headers = columns.map((column, index) => {
            return (
                <styles.ThCustom
                    key={`headCell-${index}`}
                    // style={styles.ThCustom}
                    >
                    {t(`${column?.header}`, { ns: 'translation' }) !== 'undefined' ? t(`${column?.header}`, { ns: 'translation' })  : ''}
                 </styles.ThCustom>
    );
    });

    return (
        <thead>
        <styles.HeaderTr>
        {headers}
        </styles.HeaderTr>
        </thead>
    );
};

export default TableHeader;
import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import {TransactionFee} from "../../store/payments";

const fetchPayments = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${apiBaseUrl}/organization/${organizationId}/payment-methods/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const fetchTransactionFees = async ({
                               organizationId,
                             }: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(
      `${apiBaseUrl}/organization/${organizationId}/transaction-fees/`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const createTransactionFees = async ({
                                        organizationId,
                                        transactionFees
                                    }: {
    organizationId: string;
    transactionFees: TransactionFee[]
}): Promise<AxiosResponse<any>> => {
    return axios.post(
        `${apiBaseUrl}/organization/${organizationId}/transaction-fees/`,
        transactionFees,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }
    );
};

export const paymentsService = {
  fetchPayments,
  fetchTransactionFees,
    createTransactionFees
};

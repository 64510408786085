import {ColumnDefinitionType} from "../../components/wideview-table/WideviewTable";
import {Effort} from "../../store/efforts";
import {t} from "i18next";


export const columns: ColumnDefinitionType<Effort, keyof Effort>[] = [
    {
        key: "createdAt",
        header: t("CREATED", { ns: "translation" })
    },
    {
        key: "kpiMetric",
        header:  t("TITLE", { ns: "translation" })
    },
    {
        key: "description",
        header: t("DESCRIPTION", { ns: "translation" })
    },
    {
        key: "assignee",
        header: t("OWNER", { ns: "translation" })
    },
    {
        key: "status",
        header: t("STATUS", { ns: "translation" })
    },
    {
        key: "startDate",
        header: t("START_DATE", { ns: "translation" })
    },
    {
        key: "endDate",
        header: t("END_DATE", { ns: "translation" })
    },
    {
        key: "id"
    }
]
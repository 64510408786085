// imports
import {createSlice} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearFacebookState} from "./actions";
import {FacebookState, initialFacebookState} from "./state";

// reducers
function buildCleanFacebookStateReducer(builder: ActionReducerMapBuilder<FacebookState>) {
    builder.addCase(clearFacebookState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.code = undefined;
        return state;
    });
}


// slice
export const facebookSlice = createSlice({
    name: 'facebook',
    initialState: initialFacebookState,
    reducers: {},
    extraReducers: (builder => {
        // non async
        buildCleanFacebookStateReducer(builder);

        // async
    }),
});

// reducer
export const facebookReducer = facebookSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationService } from "@services";

// local
import { isHttpCodeSuccess } from "../../utils/network/helpers";

export const fetchTodos = createAsyncThunk<any, { organizationId: string }>(
  "organization/fetchTodos",
  async ({ organizationId }) => {
    try {
      
      const response = await organizationService.fetchTodos({
        organizationId: organizationId,
      });

      if (isHttpCodeSuccess(response.status) && !!response?.data) {
        return {
          ...response?.data,
          id: response?.data?.id,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      return err;
    }
  }
);

import {createAsyncThunk} from "@reduxjs/toolkit";
import {getFinancialData} from "@services";

export const fetchFinancialData = createAsyncThunk<any, any>(
  "financial/fetchFinancialData",
  async (payload, thunkAPI) => {
    const response = getFinancialData();
    return response;
  }
);

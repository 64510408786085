import styled from "styled-components";

export namespace styles {
    export const StyledTr = styled.tr<{ depth?: boolean }>`
      text-align: left;
      border-spacing: 0px !important;
      background-color: ${(props) => (props.depth ? "white" : "#E5D5FA4D")};
    `;
    export const StyledTd = styled.td<{ width?: number }>`
       width: ${(props) => (props?.width ? props?.width + "%" : "14%")};
      //overflow: hidden;
      //text-align: center;
      //white-space: nowrap;
      //text-overflow: ellipsis;
    `;

    export const StyledTdTitleIntegration = styled.td<{ width?: number }>`
      width: ${(props) => (props?.width ? props?.width + "%" : "14%")};
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 0 0 0 20px;
      height: 56px;
    `

    export const StyledTdTitleAccount = styled.td<{ width?: number }>`
      width: ${(props) => (props?.width ? props?.width + "%" : "14%")};
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 0 0 0 80px;
      height: 56px;
    `

    export const StyledTdTitleCampaign = styled.td<{ width?: number }>`
      width: ${(props) => (props?.width ? props?.width + "%" : "14%")};
      font-weight: 300;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding: 0 0 0 110px;
    `

    export const ModalUrlTitle = styled.div`
      color: #1a0989;
      font-family: ${(props) => props?.theme?.fonts?.roboto?.regular};
      font-size: 19px;
      margin-left: 6px;
    `;
    export const StyledTitleTd = styled.td<{ width?: number }>`
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
    `;
    export const StyledTdURL = styled.td`
      width: 11%;
      text-align: center;
    `;
    export const ImageWraper = styled.div`
      margin: 0 7px 0 0;
    `;
    export const Url = styled.a`
      text-decoration: underline;
      color: #3955ef;
      padding: 20px 15px;
    `;
    export const ModalHeaderFlex = styled.div`
      display: flex;
      padding: 20px 8px;
    `;
    export const UrlRow = styled.div`
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
    `;
    export const StyledDiv = styled.div`
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
    `;
    export const StyledDivHolder = styled.div`
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      text-overflow: ellipsis;
    `;
    export const CampaignTitleHolder = styled.div`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%;
    `;
    export const Edit = styled.div`
      text-align: center;
      letter-spacing: 0px;
      color: white;
      background-color: #7031eb;
      border-radius: 15px;
      width: 60px;

      &:hover {
        cursor: pointer;
      }
    `;
    export const TableHolder = styled.div`
      width: 95%;
      max-height: 75vh;
      overflow-y: auto;
      margin: 0 auto;
      padding: 0px 0 65px 0;
      border-radius: 30px;
      background-color: white;
      box-shadow: 2px 13px 54px #695f9714;
      ::-webkit-scrollbar-track {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
      }
    `;
    export const StyledTh = styled.th<{ w?: number; center?: boolean }>`
      text-align: center;
      color: #7a86a1;
      font-size: 15px;
      position: sticky;
      top: 0;
      background-color: white;
      padding: ${(props) => (props?.center ? "0.5rem 2.5rem" : "0.5rem")};

      text-align: ${(props) => (props?.center ? "left" : "center")};

      max-width: ${(props) => (props?.w ? props?.w + "%" : "")};
    `;
    export const StyledHeaderTr = styled.tr`
      border-bottom: 1px solid #d4d7df;
      height: 45px;
      background-color: white;
      position: sticky;
      width: 100%;
    `;
    export const StyledThead = styled.thead`
      position: sticky;
      padding: 0 10px;
    `;

    export const DefaultBadge = styled.div`
      width: max-content;
      border-radius: 10px;
      font-size: 10px;
      background-color: #f7f2fd;
      color: #7031eb;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      height: 80%;
      margin-left: 5px;
      margin-top: 5px;
    `;
    export const URLbutton = styled.button<{ disabled: boolean }>`
      border-radius: 15px;
      border: 1px solid;
      font-size: 14px;
      margin: 0 auto;
      padding: 3px 12px;
      display: flex;

      &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '#f1f0f0' : 'rgba(230, 230, 238, 0.5)')};
      }

      background-color: ${(props) => (props.disabled ? '#f1f0f0' : 'auto')};
      color: ${(props) => (props.disabled ? '#b0b0b0' : '#1a0989')};
      border-color: ${(props) => (props.disabled ? '#b0b0b0' : '#1a0989')};

    `;
    export const StyledTable = styled.table`
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      /*  minWidth: 500px;
      minHeight: 300px;*/
    `;


    export const SalesChannelsWrapper = styled.div`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;
    `

    export const ThreeDotsWrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: #dadada;
      color: white;
      height: 16px;
      width: 30px;
    `

    export const ThreeDots = styled.div`
      color: white;
      padding-bottom: 10px;
    `
}

export default styles;

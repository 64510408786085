import styles from "./styles";
import {DownloadButton} from "../download-button/DownloadButton";
import {Fragment, useState} from "react";
import {GraphNodeCard, GraphNodeCardProps} from "./GraphNodeCard";
import {Icon} from "@chakra-ui/react";
import {IoIosDownload} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {
    IconEcommerceFunnel,
    TreeArrowDown,
    TreeArrowDownFullWidth,
    TreeArrowUp,
    TreeArrowUpFullWidth
} from "../../utils/icons/explore";
import {v4 as uuidv4} from "uuid";

export interface Props {
    title?: string;
    graphData: GraphNodeCardProps;
}

export const Graph = (props: Props) => {

    const [componentId] = useState(`Graph${uuidv4()}`);
    const bftIndexing = (node: GraphNodeCardProps) => {
        const queue: GraphNodeCardProps[] = [];
        let index = 0;
        node.index = index;
        index++;
        queue.push(node);
        node.isOnPath = true;
        let level = 0;
        node.level = level;

        // node.title = `index: ${index}\n level: ${level}`; //TODO - for testing

        while (queue.length > 0) {
            const v = queue.shift();
            level = v!.level! + 1;
            v!.isVisible = true;
            for (let i = 0; i < (v!.children?.length ?? 0); i++) {
                let child = v!.children![i];
                if (!child.index) {
                    child.level = level;
                    child.index = index;
                    // child.title = `index: ${index}\n level: ${level}`; //TODO - for testing
                    child.isCollapsed = false;
                    index++;
                    queue.push(child);
                }
            }
        }
        return node;
    };

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const [data, setData] = useState(bftIndexing(props.graphData) as GraphNodeCardProps);

    // helpers
    const setSubtree = (root: GraphNodeCardProps, node: GraphNodeCardProps) => {
        const queue = [];
        queue.push(root);
        while (queue.length > 0) {
            let v = queue.shift();
            if (v!.index === node.index) {
                v = node;
                break;
            }
            v?.children?.forEach((child, childIndex) => {
                queue.push(child);
            });
        }
        setData({ ...root });
    };

    const collapseSubTree = (node: GraphNodeCardProps) => {
        const queue = [];
        queue.push(node);
        node.isVisible = true;
        while (queue.length > 0) {
            const v = queue.shift();
            v!.isCollapsed = true;
            v?.children?.forEach(child => {
                child.isCollapsed = true;
                child.isVisible = false;
                child.isOnPath = false;
                queue.push(child);
            });
        }
        setSubtree(data, node);
    };

    const expandSubTree = (node: GraphNodeCardProps, expandAll?: boolean) => {
        const queue = [];
        queue.push(node);
        node.isOnPath = true;
        node.isCollapsed = false;
        node.isVisible = true;
        while (queue.length > 0) {
            const v = queue.shift();
            v?.children?.forEach(child => {
                child.isCollapsed = true;
                child.isVisible = true;
                if (expandAll) {
                    child.isCollapsed = false;
                    queue.push(child);
                }
            });
        }
        setSubtree(data, node);
    };

    // renderers
  
    const renderArrows = (node: GraphNodeCardProps, xStart: number, xEnd: number, yStart: number, yEnd: number) => {


        if (node.children && node.children.length > 0) {
            switch (node.index) {
                case 0:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={yStart+1} yEnd={yEnd - 2} isArrow={true}>
                            <TreeArrowUp/>
                         </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={yStart + 2} yEnd={yEnd - 1} isArrow={true}>
                            <TreeArrowDown/>
                        </styles.BoardItemWrapper>
                    </>;
                case 1:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={1} yEnd={2} isArrow={true}>
                            <TreeArrowUp/>
                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={2} yEnd={3} isArrow={true}>
                            <TreeArrowDown/>
                        </styles.BoardItemWrapper>
                    </>;
                case 2:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={5} yEnd={6} isArrow={true}>
                            <TreeArrowUp/>
                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={6} yEnd={7} isArrow={true}>
                            <TreeArrowDown/>
                        </styles.BoardItemWrapper>
                    </>;
                case 3:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={0} yEnd={1} isArrow={true}>
                            <styles.BoardItemArrowUp>
                                <TreeArrowUpFullWidth/>
                            </styles.BoardItemArrowUp>

                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={1} yEnd={2} isArrow={true}>
                            <styles.BoardItemArrowDown>
                                <TreeArrowDownFullWidth/>
                            </styles.BoardItemArrowDown>

                        </styles.BoardItemWrapper>
                    </>;
                case 4:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={2} yEnd={3} isArrow={true}>
                            <styles.BoardItemArrowUp>
                                <TreeArrowUpFullWidth/>
                            </styles.BoardItemArrowUp>

                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={3} yEnd={4} isArrow={true}>
                            <styles.BoardItemArrowDown>
                                <TreeArrowDownFullWidth/>
                            </styles.BoardItemArrowDown>

                        </styles.BoardItemWrapper>
                    </>;
                case 5:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={4} yEnd={5} isArrow={true}>
                            <styles.BoardItemArrowUp>
                                <TreeArrowUpFullWidth/>
                            </styles.BoardItemArrowUp>

                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={5} yEnd={6} isArrow={true}>
                            <styles.BoardItemArrowDown>
                                <TreeArrowDownFullWidth/>
                            </styles.BoardItemArrowDown>

                        </styles.BoardItemWrapper>
                    </>;
                case 6:
                    return <>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={6} yEnd={7} isArrow={true}>
                            <styles.BoardItemArrowUp>
                                <TreeArrowUpFullWidth/>
                            </styles.BoardItemArrowUp>

                        </styles.BoardItemWrapper>
                        <styles.BoardItemWrapper xStart={xStart} xEnd={xEnd} yStart={7} yEnd={8} isArrow={true}>
                            <styles.BoardItemArrowDown>
                                <TreeArrowDownFullWidth/>
                            </styles.BoardItemArrowDown>

                        </styles.BoardItemWrapper>
                    </>;

                default:
                    return <></>
            }
        }
        return <></>;


    };

    const renderNodeWithArrows = (node: GraphNodeCardProps, level2: number, numberOfRows: number, key: number) => {
        const level = node.level!;
        let rowSize = Math.floor(numberOfRows / (Math.pow(2, level)));
        const smallestIndexInLevel = Math.pow(2, level) - 1;
        const yStart = (smallestIndexInLevel === node.index! ? 0 : ((node.index! - smallestIndexInLevel) )) * rowSize;
        const yEnd = yStart + rowSize;
        const xStart = level * 2;
        const xEnd = (level * 2) + 1;

        return (
            <>
                {node.isVisible ?
                    <styles.BoardItemWrapper key={node.index} xStart={xStart} xEnd={xEnd} yStart={yStart}
                                             yEnd={yEnd} isArrow={false}>
                        <styles.BoardItem>
                            <GraphNodeCard
                                title={(node?.title)}
                                value={(node?.value)}
                                vsTargetPercentage={(node?.vsTargetPercentage as number)}
                                onClick={() => node?.isCollapsed ? expandSubTree(node) : collapseSubTree(node)}
                                isCollapsed={false}
                                isVisible={node?.isVisible}
                                isRoot={(node?.isRoot)}
                                isOnPath={node?.isOnPath}
                            />
                        </styles.BoardItem>
                    </styles.BoardItemWrapper>
                    : <></>}
                {node.isVisible && !node.isCollapsed ? renderArrows(node, xStart + 1, xStart + 2, yStart, yEnd) : <></>}
            </>
        );
    };

    const renderRecursiveTree = (node: GraphNodeCardProps, treeDepth: number, index: number): any => {
        return <Fragment key={`${componentId}_depth_${treeDepth}_index_${index}`}>
            {renderNodeWithArrows(node, node.level!, treeDepth, 0)}
            {node.children!.map((child, childIndex) => renderRecursiveTree(child, treeDepth, childIndex))}
        </Fragment>;
    };

    const renderTree = (node: GraphNodeCardProps) => {
        const treeDepth = 4;
        // const treeDepth = 4;
        return (
            <styles.Board size={treeDepth}>
                {renderRecursiveTree(node, treeDepth, 0)}
            </styles.Board>
        );
    };

    const renderExpandAllButton = () => {
        return <styles.ExpandAllButtonWrapper onClick={() => expandSubTree(data, true)}>
            <Icon as={IoIosDownload} />
            <styles.ExpandAllText>
                {`${t('EXPAND_ALL', { ns: 'translation' })}`}
            </styles.ExpandAllText>
        </styles.ExpandAllButtonWrapper>;
    };

    return <styles.GraphWrapper>
        <styles.Card>
            <styles.Header>
                <styles.Title>
                    <span style={{ marginRight: '5px' }}>
                        <IconEcommerceFunnel />
                    </span>
                    {props.title}
                </styles.Title>
                {renderExpandAllButton()}
                <DownloadButton onClick={() => console.log('download')} />
            </styles.Header>
            {renderTree(data)}
        </styles.Card>
    </styles.GraphWrapper>;

};

export const threeDots = () => {
    return (
        <svg id="Component_18_170" data-name="Component 18 – 170" xmlns="http://www.w3.org/2000/svg" width="100%"
             height="25" viewBox="0 0 3.496 15.15">
            <g id="Group_355" data-name="Group 355" transform="translate(-1536.252 -842.425)">
                <g id="Group_752" data-name="Group 752" transform="translate(1.252 5.425)">
                    <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.748" cy="1.748" r="1.748"
                            transform="translate(1535 837)" fill="#c0bdcc"/>
                    <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.748" cy="1.748" r="1.748"
                            transform="translate(1535 842.827)" fill="#c0bdcc"/>
                    <circle id="Ellipse_24" data-name="Ellipse 24" cx="1.748" cy="1.748" r="1.748"
                            transform="translate(1535 848.654)" fill="#c0bdcc"/>
                </g>
            </g>
        </svg>

    );
};
export const leftArrow = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43" height="86"
             viewBox="0 0 43 86">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1048" data-name="Rectangle 1048" width="40" height="86"
                          transform="translate(333 606)" fill="#fff" stroke="#707070" stroke-width="1"/>
                </clipPath>
            </defs>
            <g id="Group_11602" data-name="Group 11602" transform="translate(-353 -605)">
                <g id="Group_11604" data-name="Group 11604">
                    <g id="Mask_Group_11" data-name="Mask Group 11" transform="translate(20 -1)"
                       clip-path="url(#clip-path)">
                        <circle id="Ellipse_134" data-name="Ellipse 134" cx="31" cy="31" r="31"
                                transform="translate(340 618)" fill="#fff"/>
                    </g>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(357.961 627.961)">
                        <g id="invisible_box" data-name="invisible box">
                            <rect id="Rectangle_1047" data-name="Rectangle 1047" width="38" height="38"
                                  transform="translate(0.039 0.039)" fill="none"/>
                        </g>
                        <g id="icons_Q2" data-name="icons Q2" transform="translate(12.68 7.934)">
                            <path id="Path_12655" data-name="Path 12655"
                                  d="M19.8,21.106,28.214,12.7a1.507,1.507,0,0,0-.159-2.38,1.666,1.666,0,0,0-2.142.159L16.473,20a1.507,1.507,0,0,0,0,2.221l9.44,9.52a1.666,1.666,0,0,0,2.142.159,1.507,1.507,0,0,0,.159-2.38Z"
                                  transform="translate(-15.985 -10.001)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export const rightArrow = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43" height="86"
             viewBox="0 0 43 86">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1048" data-name="Rectangle 1048" width="40" height="86"
                          transform="translate(333 606)" fill="#fff" stroke="#707070" stroke-width="1"/>
                </clipPath>
            </defs>
            <g id="Group_11605" data-name="Group 11605" transform="translate(396 691) rotate(180)">
                <g id="Group_11604" data-name="Group 11604">
                    <g id="Mask_Group_11" data-name="Mask Group 11" transform="translate(20 -1)"
                       clip-path="url(#clip-path)">
                        <circle id="Ellipse_134" data-name="Ellipse 134" cx="31" cy="31" r="31"
                                transform="translate(340 618)" fill="#fff"/>
                    </g>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(357.961 627.961)">
                        <g id="invisible_box" data-name="invisible box">
                            <rect id="Rectangle_1047" data-name="Rectangle 1047" width="38" height="38"
                                  transform="translate(0.039 0.039)" fill="none"/>
                        </g>
                        <g id="icons_Q2" data-name="icons Q2" transform="translate(12.68 7.934)">
                            <path id="Path_12655" data-name="Path 12655"
                                  d="M19.8,21.106,28.214,12.7a1.507,1.507,0,0,0-.159-2.38,1.666,1.666,0,0,0-2.142.159L16.473,20a1.507,1.507,0,0,0,0,2.221l9.44,9.52a1.666,1.666,0,0,0,2.142.159,1.507,1.507,0,0,0,.159-2.38Z"
                                  transform="translate(-15.985 -10.001)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
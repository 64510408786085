import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `;

  export const MessageWrapper = styled.div<{ success?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props?.success
        ? props.theme.successBackgroundColor
        : props?.theme?.errorBackgroundColor};
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
  `;

  export const Message = styled.span<{ success?: boolean }>`
    font-size: 14px;
    color: ${(props) =>
      props?.success ? props.theme.successColor : props?.theme?.errorColor};
  `;
}

export default styles;

import { createAction } from "@reduxjs/toolkit";

export const clearAlertsState = createAction("alerts/clearState");

export const updateLocalAlerts = createAction<{ data: any[] }>(
  "alerts/updateLocal"
);
export const deleteLocalAlert = createAction<{ id: string }>(
  "alerts/deleteAlert"
);

import axios, {AxiosResponse} from "axios";
import {fetchShopifyOAuthUrlUrl, setShopifyOAuthDataUrl} from "@constants";

const fetchShopifyOAuthUrl = async (shopName: string | null): Promise<AxiosResponse<{ oauthUrl: string }>> => {
    return axios.get<{ oauthUrl: string }>(`${fetchShopifyOAuthUrlUrl}`, {
        params: {
            shop: shopName
        },
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

const setShopifyOAuthData = async (query: {
    code: string | null;
    hmac: string | null;
    host: string | null;
    shop: string | null;
    state: string | null;
    timestamp: string | null;
}): Promise<AxiosResponse<any>> => {
    const axiosInstance = axios.create();
    return axiosInstance.get<any>(`${setShopifyOAuthDataUrl}`, {
        data: {
            query: query,
        },
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export const shopifyService = {
    fetchShopifyOAuthUrl,
    setShopifyOAuthData,
};

export const inviteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 0.073)">
                <g id="invisible_box" data-name="invisible box" transform="translate(0 -0.073)">
                    <rect id="Rectangle_1012" data-name="Rectangle 1012" width="22" height="22" transform="translate(0)" fill="none" />
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(0.84 2.654)">
                    <path id="Path_12542" data-name="Path 12542" d="M10.147,14.295A4.147,4.147,0,1,0,6,10.147,4.147,4.147,0,0,0,10.147,14.295Zm0-6.451a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,10.147,7.843Z" transform="translate(-4.157 -6)" fill="#7A86A1" />
                    <path id="Path_12543" data-name="Path 12543" d="M31.226,16.451A3.226,3.226,0,1,0,28,13.226a3.226,3.226,0,0,0,3.226,3.226Zm0-4.608a1.382,1.382,0,1,1-1.382,1.382,1.336,1.336,0,0,1,1.382-1.382Z" transform="translate(-16.019 -8.157)" fill="#7A86A1" />
                    <path id="Path_12544" data-name="Path 12544" d="M22.275,27.565l-.415-.276a9.032,9.032,0,0,0-9.308,0l-.369.276a11.336,11.336,0,0,0-3.733-.645,10.783,10.783,0,0,0-6.037,1.8L2,28.994V32.45a.922.922,0,0,0,.922.922H13.981a.922.922,0,0,0,.922-.922v-.922h6.451a.922.922,0,0,0,.922-.922Zm-9.216,3.963H3.843V30.008a8.986,8.986,0,0,1,4.608-1.244,8.986,8.986,0,0,1,4.608,1.244Zm7.373-1.843H14.9v-.691l-.415-.276-.276-.184a7.05,7.05,0,0,1,6.221.092Z" transform="translate(-2 -16.782)" fill="#7a86a1" />
                </g>
            </g>
        </svg>

    );
};
import styled from "styled-components";
import { Table, Tbody, Td, Th, Tr } from "@chakra-ui/react";

export namespace styles {
    export const Wrapper = styled.div`
      width: 100%;
      //border-radius: 30px 30px 0 0;
      font-size: 12px !important;
      max-height: 600px;
      overflow-y: auto;

      ::-webkit-scrollbar-track {
        margin-top: 65px !important;
      }
    `;

    export const Card = styled.div`
      background-color: white;
      width: 100%;
      padding: 20px;
      height: auto;
      box-shadow: 2px 13px 54px #695f9714;
      border-radius: 20px;
    `;

    export const Header = styled.tr`
      border-bottom: 1px solid #e3e6eb;
      height: 64px;
      position: sticky;
      top: 0;
      z-index: 5;
    `;

    export const Title = styled.div`
      width: 100%;
      color: black;
      font-size: 30px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    `;

    export const TableCustom = styled(Table)`
      max-height: 300px;
    `;

    export const TbodyCustom = styled(Tbody)`
      height: 300px;
      /*  ::-webkit-scrollbar {
        display: none;
      } */
    `;


    export const ThCustom = styled.th`
      color: #7a86a1;
      font-family: ${(props) => props.theme.fonts.roboto.regular};
      font-size: 12px;
      background-color: #ffffff;
      max-width: 95px;
      padding: 0 10px;
      font-weight: lighter;
      border-width: 1px;
    `;
    export const HeaderTr = styled.tr`
      border-bottom: 1px solid #e3e6eb;
      height: 64px;
      position: sticky;
      top: 0;
      z-index: 5;
    `;
    export const TrCustom = styled.tr`
      height: 60px;
      text-align: center;
      background-color: #fdfdff;
      border-bottom: 1px solid #e7eaee;
      z-index: 2;
      font-family: ${(props) => props.theme.fonts.roboto.regular};
    `;

    export const TdCustom = styled.td`
      max-width: 65px;
      font-size: 10px;
      text-align: center;
      border-width: 1px;
    `;

    export const TableCell = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
    `;
}

export default  styles;

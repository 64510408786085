import styles from "./styles";
import { Box, Flex, Menu, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, useDisclosure } from "@chakra-ui/react";
import {MenuButton} from "../menu-generic/MenuGenericButton";
import React, { useEffect, useMemo, useState } from "react";
import { Cell, Column, usePagination, useSortBy, useTable } from "react-table";
import {Alert, AlertStatus, AlertType} from "../../models/alerts";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {CreateAlert, TriggerOperator} from "../create-alert/CreateAlert";
import {useDispatch, useSelector} from "react-redux";
import { deleteAlerts, deleteLocalAlert, updateLocalAlerts } from "../../store/alerts";
import { KpisEnum } from "../../models/kpi-settings";
import {organizationStateSelector} from "@store";
import {ColumnDefinitionType, Table} from "../wideview-table/WideviewTable";
import {getKpiNameFromEnum} from "../../utils/kpi-names/kpiName";
import {formatIsoDate} from "../../utils/date-format/dateFormat";


interface Props {
    title?: string;
    headers: Array<ColumnDefinitionType<any, any>>;
    data: Alert[];
    icon?: any;
}

interface Data {
    col_0?: string;
    col_1?: string;
    col_2?: string;
    col_3?: string;
    col_4?: string;
}

interface DataToShow {
    title?: string;
    description?: string;
    isPrivate?: string;
    createdAt?: string;
    id: string;
}

export const AlertsTable = (props: Props) => {

    const dispatch = useDispatch();
    const [items, setItems] = useState<any>([]);
    const [empty, setEmpty] = useState<any>(false);
    const [alertData, setAlertData] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [alertToEdit, setAlertToEdit] = useState<any>();
    const disclosure = useDisclosure();
    const organization = useSelector(organizationStateSelector)

    useEffect(() => {
        setItems(props?.data)
        setEmpty(false);
    }, [props.data]);

    const triggerOperatorToTable = (opeartor: string) => {
        switch (opeartor) {
            case ("IS_GREATER_THEN"):
                return ">";
            case ("IS_SMALLER_THEN"):
                return "<";
            default:
                return "<";
        }
    };

    const dataToDataTable = (data: any[]): Data[] => {
        return data.map((row, index) => {
            return {
                col_0: row?.name,
                col_1: t(Object.values(KpisEnum)[row?.trigger[0].kpi]?.toString(), {ns: "translation"}) + triggerOperatorToTable(row?.trigger[0].operator) + row?.trigger[0].value,
                col_2: row?.isPrivate ? "Private" : "Public",
                col_3: row?.timeRange,
                col_4: row.id,
                col_5: row?.from
            };
        });
    };

    useEffect(() => {
        if (!!props?.data) {
            const newAlert = props?.data?.map((item, i) => {
                let description:string = "";
                if(!!item?.trigger){
                    description = getKpiNameFromEnum(item?.trigger[0]?.kpi)
                    + " " + t(item?.trigger[0]?.operator === TriggerOperator?.GreaterThen ? ">" : "<", { ns: 'translation' }) +
                        " " + item?.trigger[0]?.value
                }




                return {
                ...item,
                description: description,
                recipients: item?.isPrivate ? "Private" : "Public",
                triggered: formatIsoDate(item?.createdAt?.slice(0,10)),
                id: item?.id,
                /*tooltip: [{column: "description", label: (<> Rule : {description}, Trigger : {} </>)}]*/

            }
        })
        setAlertData(newAlert)
        }
    }, [props?.data]);

    const fetchMoreData = () => {
        /*setLoader(true);
        setTimeout(() => {
            setItems(items.concat([...props.data].reverse().slice(items.length, items.length + 5)));
            setLoader(false);
        }, 1500);
        if ([...props.data].reverse().slice(items.length, items.length + 5).length === 0) {
            setEmpty(true);
        }*/
    };

    const headersToColumns = (headers: Array<ColumnDefinitionType<Alert, keyof Alert>>): Column<Data>[] => {
        return headers.map((header, index) => {
            return {Header: header, accessor: 'col_' + index.toString(), sortType: 'basic'} as Column<Data>;
        });
    };

    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const data = useMemo<Data[]>(() => dataToDataTable(items), [items]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props.headers), [props.headers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // state: {pageIndex, pageSize}
    } = useTable({columns, data, initialState: {pageIndex: 0, pageSize: 5}}, useSortBy/*, usePagination*/);

    // helpers

    const handleDeleteAlert = (id: string) => {
        dispatch(deleteLocalAlert({id: id}));
        !!organization?.organization?.id && dispatch(deleteAlerts({
            organizationId: organization?.organization?.id,
            id
        }));
    };
    const handleEditAlert = (id: string) => {
        setAlertToEdit(props.data.find((item: any) => item.id === id));
        disclosure.onOpen();
    };

    // renderers

    const itemMenu = () => {
        const menuButtonsData: MenuButton[] = [
            {
                title: "Edit",
                onClick: (id:string) => handleEditAlert(id)
            },
            {
                title: "Delete",
                onClick: (id:string) => handleDeleteAlert(id)
            }
        ]
        return (
            menuButtonsData
        );
    };


    function replaceAll(str: string, find: string, replace: string) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    return (
        <>
      <Table data={alertData} columns={props.headers} menuButton={itemMenu()} />
            <CreateAlert alertToEdit={alertToEdit} {...disclosure}  />
        </>
    )
};

//     const renderCell = (cell: Cell<Data, any>) => {
//         if (cell.column.id === "col_1") {
//             const rowId = cell.row.original.col_4;
//             const rowData = props.data.filter((item: any) => item.id === rowId)[0];
//             return (
//                 <>
//                     <styles.TdCustom {...cell.getCellProps()}>
//
//                         <Popover trigger="hover" placement="top">
//                             <PopoverTrigger >
//                                 <styles.TableCell>
//                                     {
//                                         cell.value?.includes("AVERAGE_ORDERS_VALUE") ?
//                                          replaceAll(cell.value, "AVERAGE_ORDERS_VALUE", "AOV") :
//                                         replaceAll(cell.value, "COST_PER_ORDER", "CPO")
//                                     }
//                                     {/*{cell.render('Cell')}*/}
//                                 </styles.TableCell>
//                             </PopoverTrigger>
//                             <PopoverContent w={530}>
//                                 <PopoverArrow />
//                                 {/* <PopoverCloseButton /> */}
//                                 {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
//                                 <PopoverBody>
//                                     <styles.ToolTipBody >
//                                         <Flex flexDirection={"column"}>
//                                             <styles.ToolTipTitle>Rule:</styles.ToolTipTitle>
//                                             {getKpiNameFromEnum(rowData?.trigger[0]?.kpi) + " " + t(rowData?.trigger[0]?.operator, { ns: 'translation' }) + " " + rowData?.trigger[0]?.value}
//                                         </Flex>
//                                         <Flex flexDirection={"column"}>
//                                             <styles.ToolTipTitle>Trigger:</styles.ToolTipTitle>
//                                             {rowData?.from?.channel?.length > 0 && rowData?.from?.product?.length === 0 && rowData?.from?.platform?.length === 0 ?
//                                                 <>Channels: {rowData?.from.channel.map((item: string) => {
//                                                     if (item === "") {
//                                                         return "All Channels";
//                                                     }
//                                                     return item;
//                                                 })} </> : <></>}
//                                             {rowData?.from?.platform?.length > 0 && rowData?.from?.account?.length > 0 && rowData?.from?.campaign?.length > 0 && rowData?.from?.product?.length === 0 ?
//
//                                                 <>Platforms: {rowData?.from?.platform?.map((item: string) => {
//                                                     if (item === "") {
//                                                         return "All Platforms";
//                                                     }
//                                                     return item;
//                                                 })} | Accounts: {rowData?.from?.account?.map((item: string) => {
//                                                     if (item === "") {
//                                                         return "All Accounts";
//                                                     }
//                                                     return item;
//                                                 })}
//                                                     | Campaigns: {rowData?.from?.campaign?.map((item: string) => {
//                                                         if (item === "") {
//                                                             return "All Campaigns";
//                                                         }
//                                                         return item;
//                                                     })}
//                                                 </>
//
//                                                 : <></>}
//                                             {rowData?.from?.channel?.length > 0 && rowData?.from?.product?.length > 0 && rowData?.from?.platform?.length === 0 ? <>Channels: {rowData?.from?.channel?.map((item: string) => {
//                                                 if (item === "") {
//                                                     return "All Channels";
//                                                 }
//                                                 return item;
//                                             })} | Products: {rowData?.from?.product?.map((item: string) => {
//                                                 if (item === "") {
//                                                     return "All Products";
//                                                 }
//                                                 return item;
//                                             })}</> : <></>}
//                                             {/* {getKpiNameFromEnum(rowData?.trigger[0]?.kpi) + " " + t(rowData?.trigger[0]?.operator, { ns: 'translation' }) + " " + rowData?.trigger[0]?.value} */}
//                                         </Flex>
//                                     </styles.ToolTipBody>
//                                 </PopoverBody>
//                             </PopoverContent>
//                         </Popover>
//                     </styles.TdCustom>
//
//
//                 </>
//             );
//         }
//         return (
//             <styles.TdCustom {...cell.getCellProps()}>
//                 <styles.TableCell>
//                     {
//                         cell.column.Header === '' ?
//                             <ItemMenu id={cell.value} /> :
//                             cell.render('Cell')
//                     }
//                 </styles.TableCell>
//             </styles.TdCustom>
//         );
//     };
//
//     const renderTable = () => {
//
//         return (
//             <InfiniteScroll
//                 dataLength={items.length}
//                 next={fetchMoreData}
//                 hasMore={!empty}
//                 scrollableTarget="scrollableDivAlerts"
//                 loader={<></>}
//             >
//
//                 <styles.TableCustom variant='simple' {...getTableProps()}>
//
//                     <thead>
//                         {headerGroups.map(headerGroup => (
//                             <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
//                                 {
//                                     headerGroup.headers.map(column => (
//                                         <styles.ThCustom {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} >
//                                                 {column.render('Header')}
//                                                 <span>
//                                                     {column.isSorted
//                                                         ? column.isSortedDesc
//                                                             ? <VscTriangleDown />
//                                                             : <VscTriangleUp />
//                                                         : ''}
//                                                 </span>
//                                         </styles.ThCustom>
//                                     ))
//                                 }
//                             </styles.HeaderTr>
//                         ))}
//                     </thead>
//                     <styles.TbodyCustom id="scrollableDivAlerts" {...getTableBodyProps()}>
//                         {
//                             rows.map(row => {
//                                 prepareRow(row);
//                                 return (
//                                     <styles.TrCustom {...row.getRowProps()}>
//                                         {
//                                             row.cells.map(cell => {
//                                                 return renderCell(cell);
//                                             })
//                                         }
//                                     </styles.TrCustom>
//                                 );
//                             })
//                         }
//                         {loader ?
//                             <styles.TrCustom
//                                 role={"row"}
//                                 style={{ width: "100%", display: "flex", justifyContent: "center" }}>
//                                 <td>
//                                     <div id="my_box">
//                                         <Spinner />
//                                     </div>
//                                 </td>
//                             </styles.TrCustom>
//                             : <></>}
//                     </styles.TbodyCustom>
//                 </styles.TableCustom>
//             </InfiniteScroll>
//         );
//     };
//
//
//     return  <styles.Wrapper>
//         {renderTable()}
//         <CreateAlert alertToEdit={alertToEdit} {...disclosure}  />
//         </styles.Wrapper>;
// };

import styled from "styled-components";

export namespace styles {
    export const Wrapper = styled.div`
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: white;
    `;

    export const LoaderWrapper = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `;

    export const Header = styled.div`
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    `;

    export const TitleWrapper = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
    `;

    export const TitleStatic = styled.div`
      color: black;
      font-size: 20px;
      font-weight: 600;
      margin-right: 5px;
    `;

    export const TitleDynamic = styled.span`
      color: ${(props) => props.theme.primaryColor};
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
    `;

    export const ValueWrapper = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-bottom: 5px;
    `;

    export const Value = styled.div`
      font-size: 20px;
      margin-right: 10px;
      font-weight: 600;
    `;

    export const TargetText = styled.span<{ color?: string, fontSize?: number }>`
      color: ${props => props.color};
      font-size: ${props => props?.fontSize ? props?.fontSize + "px" : `min(calc(4px + 0.8vw), 12px)`};
      text-align: center;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: block;
      line-height: 1em;
      max-height: 1em;
      padding-top: 4px;
    `;

    export const Description = styled.div``;

    export const BadgeWrapper = styled.div``;

    export const HeaderButtonsWrapper = styled.div`
      display: flex;
      flex-direction: row;
      margin-left: auto;
      align-items: center;
    `;

    export const HeaderButton = styled.div`
      padding: 5px 12px;
      margin: 0 10px;
      height: 44px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      border: solid 1px #ebebeb;

      &:hover {
        cursor: pointer;
      }
    `;

    export const HeaderButtonText = styled.div`
      font-size: 14px;
      margin: 0 10px;
    `;

    export const ChipWrapper = styled.div<{ width?: string, color?: string }>`
      padding: 0 4px;
      min-width: max-content;
      background-color: ${(prop => prop?.color + '30')};
      filter: alpha(opacity=60);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 32px;
      font-size: 8px;
    `

    export const Chip = styled.div<{ color?: string }>`
      color: ${(prop => prop?.color)};
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    `
    export const ChipText = styled.div`
      font-size: 8px;
      margin-left: 2px;
    `

    export const LogoIconWrapper = styled.div`
      height: 12px;
      width: 12px;
      border-radius: 60px;
      color: white;
      box-shadow: 0px 14px 36px #060E8331;
    `;

    export const LogoIcon = styled.img`
      width: 100%;
      height: auto;
    `;

    export const Card = styled.div``;

    export const SectionOne = styled.div`
      width: 100%;
    `;

    export const SectionTwo = styled.div`
      width: 100%;
      display: flex;
      margin-top: 10px;
      flex-direction: row;
    `;
    export const SectionThree = styled.div`
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
    `;

    export const SectionFour = styled.div`
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: 80px;
    `;
}

export default styles;

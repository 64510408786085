export const notificationClock = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Rectangle_981" data-name="Rectangle 981" width="24" height="24" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(1.965 1.099)">
                <path id="Path_12499" data-name="Path 12499" d="M24.79,12.878a10.89,10.89,0,0,1-20.641,4.8.986.986,0,1,1,1.732-.94,8.91,8.91,0,1,0,1.386-9.8H9.89a1.039,1.039,0,0,1,1.039.841.99.99,0,0,1-.99,1.138H4.99A.99.99,0,0,1,4,7.928v-4.9a1.039,1.039,0,0,1,.841-1.039.99.99,0,0,1,1.138.99V5.4a10.89,10.89,0,0,1,18.81,7.474Z" transform="translate(-4 -1.977)" fill="#7031eb" />
                <path id="Path_12500" data-name="Path 12500" d="M27.94,21.9a.841.841,0,0,1-.495-.148L22,18.484V12.99a.99.99,0,0,1,1.98,0V17.4l4.455,2.673A.986.986,0,0,1,27.94,21.9Z" transform="translate(-13.09 -7.039)" fill="#7031eb" />
            </g>
        </svg>

    );
};
import styled from 'styled-components';
import {Props} from "../auth-button";

export namespace styles {

    export const GraphWrapper = styled.div`
      background-color: white;
      width: 100%;
      min-height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      box-shadow: 2px 13px 54px #695F9714;
    `

    export const Card = styled.div`
      background-color: #F6EFFF50;
      width: 100%;
      padding: 20px;
      min-height: 400px;
      height: auto;
      box-shadow: 2px 13px 54px #695F9714;
      border-radius: 20px;
    `;

    export const Header = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `;

    export const Board = styled.div<{ size: number }>`
      display: grid;
      grid-template-rows: ${props => {
        let value = '';
        const rowSize = (100 / (props.size)).toString() + '%';
        for (let i = 0; i < props.size; i++) {
          value += `[row${i.toString()}] ${rowSize} `;
        }
        return value;
      }};
      grid-template-columns: ${props => {
        let value = '';
        for (let i = 0; i < props.size; i++) {
          const colSize = i%2 === 0 ? '200px' : '90px';//(100 / (props.size * 2)).toString() + '%';
          value += `[col${i.toString()}] ${colSize} `;
        }
        return value;
      }};
      width: 100%;
      height: 100%;
      padding: 20px;
      min-height: ${props => (props.size*80).toString() + 'px'};
    `

    export const BoardItemWrapper = styled.div<{ xStart: number, xEnd: number, yStart: number, yEnd: number , isArrow: boolean}>`
      grid-column-start: ${props => 'col' + props.xStart.toString()};
      grid-column-end: ${props => 'col' + props.xEnd.toString()};
      grid-row-start: ${props => 'row' + props.yStart.toString()};
      grid-row-end: ${props => 'row' + props.yEnd.toString()};
      display: flex;
      align-items: ${props => props.isArrow ? 'center' : 'center'};
      justify-content: start;
      position: relative;
      -webkit-animation: animate 1s; /* Chrome, Safari, Opera */
      animation: animate 1s;

      @keyframes animate {
        0%   {opacity: 0;}
        100% { opacity: 1;}
      }
      @keyframes animate2 {
        0%   {opacity: 1;}
        100% { opacity: 0;}
      }
    `

    export const BoardItem = styled.div`
      
    `

    export const BoardItemArrowDown = styled.div`
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: end;
      align-self: flex-start;
    `

    export const BoardItemArrowUp = styled.div`
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: end;
      align-self: flex-end;
    `

    export const LineRightHalf = styled.hr`
      border: none;
      border-top: 2px dotted black;
      color: #fff;
      background-color: #fff;
      height: 1px;
      width: 50%;
    `

    export const LineRightHalfSuffix = styled.hr`
      border: none;
      border-top: 2px dotted black;
      color: #fff;
      background-color: #fff;
      height: 1px;
      width: 50%;
      margin-left: 50%;
    `

    export const LineUpFull = styled.div`
      border-left: 2px dotted black;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
    `

    export const LineUpHalf = styled.div`
      border-left: 2px dotted black;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 0;
    `

    export const LineUpHalfBottom = styled.div`
      border-left: 2px dotted black;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
    `

    export const LineDownHalf = styled.div`
      border-left: 2px dotted black;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
    `

    export const NodeAndArrows = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 100%;
    `

    export const Node = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 100%;
    `

    export const Arrows = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 100%;
    `

    export const Title = styled.div`
      width: 100%;
      color: black;
      font-size: 30px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      font-family: ${props => props?.theme?.fonts?.header};
      font-size: 21px;
      font-weight: bold;
    `;

    export const NodeWrapper = styled.div<{ isRoot: boolean, isOnPath?: boolean }>`
      background: ${props => props.isRoot ? props.theme.primaryColor : '#F4F8FF'} 0 0 no-repeat padding-box;
      opacity: ${props => props.isOnPath ? '1' : '0.6'};
      box-shadow: -0.62px 17px 24px #695F9719;
      border: solid ${props => !props.isRoot ? '3px' : 0}
        ${props => !props.isRoot ? props.theme.primaryColor : props.color};
      height: 80px;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 10px;
      border-radius: 10px;
      
      &:hover{
        cursor: pointer;
      }
    `;

    export const NodeCardTitle = styled.div<{ isRoot: boolean }>`
      font-size: 16px;
      color: ${props => props.isRoot ? 'white' : 'black'};
    `

    export const NodeCardValueWrapper = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `

    export const NodeCardValue = styled.div<{ isRoot?: boolean }>`
      font-size: 16px;
      color: ${props => props.isRoot ? 'white' : props.theme.primaryColor};
    `

    export const NodeCardBadge = styled.div<{ color: string, isRoot?: boolean }>`
      font-size: 9px;
      background-color: ${props => props.isRoot ? 'white' : props.color};
      opacity: ${props => props.isRoot ? 1 : 0.5};
      padding: 5px;
      border-radius: 15px;
      color: ${props => props.isRoot ? props.color : 'white'};
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `

    export const ExpandAllButtonWrapper = styled.button<Props>`
      width: 120px;
      height: 45px;
      border: 1px solid #EBEBEB;
      border-radius: 19px;
      background: white;
      display: flex;
      flex-direction: row;
      padding: 5px;
      justify-content: space-around;
      align-items: center;
      margin: 0 10px 0 10px;
    `;

    export const ExpandAllText = styled.div`
      font-size: 14px;
    `


}

export default styles;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationService } from "@services";
import { kpiService } from "services/kpi/kpi.service";
import { DataLanguage } from "store/organization";
import { isHttpCodeSuccess } from "utils/network/helpers";

export const fetchIntegrations = createAsyncThunk<any, any>(
  "integrations/fetchIntegrationsData",
  async ({ organizationId }, thunkAPI) => {
    const response = await organizationService.fetchIntegrations(
      organizationId
    );
    return response.data;
  }
);


// state

import {CogsMetaData, Product} from "../../models/products";

export interface ProductsState {
  data?: { data?: Product[]; count: number; page?: number, maxPage?: number; };
  productsReducedData: {
    data?: {productTitle?:string, id?:string, sku?:string, imageUrl?: string, variantTitle?: string, wvChannelId?: string}[]
  }
  isPostLoading?: {
    isPostRequestSuccess?: boolean
    isPostRequestLoading?: boolean
  };
  isPutLoading?: {
    isPutRequestLoading?: boolean,
    isPutRequestSuccess?: boolean,
  };
  metaData?: CogsMetaData;
  csvLoading: boolean;
  messageDisplay?: string;
}

// initial state
export const initialProductState: ProductsState = {
  data: {
    data: undefined,
    count: 0,
    page: 0,
  },
  productsReducedData: {
    data: []
  },
  isPostLoading: {
    isPostRequestLoading: false,
    isPostRequestSuccess: undefined,
  },
  isPutLoading: {
    isPutRequestLoading: false,
    isPutRequestSuccess: undefined
  },

  metaData: undefined,
  csvLoading: false,
  messageDisplay: '',
};

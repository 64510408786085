import { Modal, ModalCloseButton, ModalContent } from "@chakra-ui/modal";
import { Input, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const WrapperModal = styled(Modal)``;
  export const StyledModalContent = styled(ModalContent)`
    min-width: 700px;
    max-width: 705px;
    height: 435px;
    padding: 15px;
    border-radius: 10px !important;
  `;
  export const BodyWrapper = styled.div`
    width: 95%;
    margin: 0 auto;
  `;
  export const DatePickerInput = styled(Input)`
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  `;
  export const Title = styled.div`
    font-weight: bold;
    font-size: 19px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => props.theme.primaryColor};
  `;
  export const StyledCloseButton = styled(ModalCloseButton)`
    &:focus {
      // remove blue border from chakra
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;
  export const TitleInput = styled(Input)`
    /* text-decoration: underline; */
    &:focus {
      // remove blue border from chakra
      border-color: ${(props) => props.theme.primaryColor} !important;
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;
  export const DescriptionInput = styled(Input)`
    /* text-decoration: underline; */
    width: 300px !important;
    &:focus {
      // remove blue border from chakra
      border-color: ${(props) => props.theme.primaryColor} !important;
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;

  export const DateInput = styled.input`
    ::-webkit-datetime-edit {
      padding: 1em;
    }
    ::-webkit-datetime-edit-fields-wrapper {
      background: silver;
    }
    ::-webkit-datetime-edit-text {
      color: red;
      padding: 0 0.3em;
    }
    ::-webkit-datetime-edit-month-field {
      color: blue;
    }
    ::-webkit-datetime-edit-day-field {
      color: green;
    }
    ::-webkit-datetime-edit-year-field {
      color: purple;
    }
    ::-webkit-inner-spin-button {
      display: none;
    }
    ::-webkit-calendar-picker-indicator {
      background: orange;
    }
  `;
  export const Subtitle = styled.div`
    margin: 5px 0 0 0;
    font-size: 16px;
    font-weight: normal;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => props.theme.secondaryColor} !important;
  `;
  export const SubmitButton = styled.button<{ notValid: boolean }>`
    align-self: flex-end;
    background-color: ${(props) =>
      props.notValid ? "#E8E8E8" : props.theme.primaryColor} !important;
    color: ${(props) => (props.notValid ? "#888888" : "white")} !important;

    width: 125px;
    height: 39px;
    border-radius: 30px;
    color: white;
    &:hover {
      cursor: ${(props) => (props.notValid ? "default" : "pointer")};
    }
  `;

  export const StyledPopoverTrigger = styled(PopoverTrigger)`
    /* display: flex !important;
        justify-content: space-between;*/
  `;

  export const ButtonPopover = styled.div`
    border: 1px solid #e2e8f0;
    background-color: unset !important;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    min-width: 90px;
    white-space: nowrap;
    padding: 0 16px;
    //justify-content: space-between;
    gap: 10px;
    //overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
  `;

  export const StyledPopoverContent = styled(PopoverContent)`
    width: max-content;
    overflow-y: auto;
    max-height: 30vh;
    min-width: 90px;
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;

  export const option = styled.div`
    white-space: nowrap;
    width: 100%;
    padding: 5px 10px;
    text-overflow: ellipsis;
    border-bottom: 1px solid gray;
    &:first-of-type {
      padding: 5.5px 10px 5px;
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding: 5px 10px 5.5px;
      border-radius: 0 0 0.375rem 0.375rem;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 100px #e8e8e8 inset;
    }
  `;
}

export default styles;

import React from "react";
import {HiOutlineArrowRight} from "react-icons/hi";
import {Box} from "@chakra-ui/react";

export const IconCOGS = (props: { color?: string, width?: string, height?: string; }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 64 64">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#7031eb" />
                    <stop offset="1" stopColor="#4a55fb" />
                </linearGradient>
            </defs>

            <g id="Group_3471" data-name="Group 3471" transform="translate(-5 -375)">
                <path id="Path_1806" data-name="Path 1806" d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z" transform="translate(5 375)" fill="url(#linear-gradient)" />
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(15 386.001)">
                    <g id="Group_1137" data-name="Group 1137" transform="translate(6 1.999)">
                        <path id="Path_548" data-name="Path 548" d="M27.936,8.822l1.3-4.491A1.727,1.727,0,0,0,27.59,2.086h-.345l-5.7,3.023L14.722,2h-.432A1.641,1.641,0,0,0,12.65,4.073l1.9,5.182C9.454,13.054,6,20.308,6,25.836,6,33.694,13,40,21.545,40s15.545-6.3,15.545-14.163C37.09,20.136,33.29,12.45,27.936,8.822ZM21.545,36.544c-6.65,0-12.091-4.836-12.091-10.709a17.583,17.583,0,0,1,7.168-13.818L18.7,10.463l-.95-2.418L17.4,7.009l2.764,1.209,1.468.691,1.555-.777,1.555-.777-.173.432-.691,2.5,2.073,1.382c4.577,3.109,7.686,9.845,7.686,14.163C33.636,31.708,28.195,36.544,21.545,36.544Z" transform="translate(-6 -1.999)" fill="#fff" />
                        <path id="Path_549" data-name="Path 549" d="M23.647,23.5c-3.023-.6-3.023-1.123-3.023-1.555s.173-.518.432-.777a2.936,2.936,0,0,1,1.555-.345,5.441,5.441,0,0,1,2.85.777,1.555,1.555,0,0,0,2.332-.432,1.641,1.641,0,0,0-.345-2.418,7.859,7.859,0,0,0-2.677-1.036V15.727a1.727,1.727,0,1,0-3.454,0v1.9C18.724,18.059,17,19.786,17,22.118s2.85,4.232,5.613,4.75,2.85,1.123,2.85,1.555-.95,1.209-2.159,1.209h0a5.786,5.786,0,0,1-3.541-1.123,1.727,1.727,0,0,0-2.418.518,1.555,1.555,0,0,0,.259,2.245,9.413,9.413,0,0,0,3.714,1.554v1.9a1.727,1.727,0,1,0,3.454,0v-1.9A5.441,5.441,0,0,0,27.36,31.7a4.232,4.232,0,0,0,1.727-3.454h0C29.088,25.054,26.151,24.018,23.647,23.5Z" transform="translate(-7.497 -3.636)" fill="#fff" />
                    </g>
                </g>
            </g>
        </svg>

    );
};

export const IconMoreInfo = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 0.433)">
                <g id="invisible_box" data-name="invisible box" transform="translate(0 -0.195)">
                    <rect id="Rectangle_1072" data-name="Rectangle 1072" width="23" height="23" transform="translate(0 -0.239)" fill="none"/>
                </g>
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(0.942 0.895)">
                    <g id="Group_11754" data-name="Group 11754">
                        <path id="Path_12686" data-name="Path 12686" d="M12.364,22.729a10.317,10.317,0,1,0-7.343-3.022A10.365,10.365,0,0,0,12.364,22.729Zm0-18.845a8.48,8.48,0,1,1-8.48,8.48,8.48,8.48,0,0,1,8.48-8.48Z" transform="translate(-2 -2)" fill="#7031eb"/>
                        <path id="Path_12687" data-name="Path 12687" d="M22.942,19.538a.942.942,0,0,0,.942-.942V12.942a.942.942,0,1,0-1.884,0V18.6A.942.942,0,0,0,22.942,19.538Z" transform="translate(-12.578 -7.289)" fill="#7031eb"/>
                        <ellipse id="Ellipse_166" data-name="Ellipse 166" cx="0.5" cy="1.5" rx="0.5" ry="1.5" transform="translate(10.058 13.671)" fill="#7031eb"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export const IconAlert = (props: { color?: string, width?: string, height?: string; }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.722" height="39.721" viewBox="0 0 39.722 39.721">
            <g id="alert" opacity="0.667">
                <g id="Group_68" data-name="Group 68">
                    <path id="Path_27" data-name="Path 27" d="M19.861,0A19.861,19.861,0,1,0,39.722,19.861,19.862,19.862,0,0,0,19.861,0Zm1.986,29.791H17.875V25.819h3.972v3.972Zm0-7.944H17.875V9.93h3.972V21.847Z" fill="#f7686a" />
                </g>
            </g>
        </svg>
    );
};

export const IconCSV = (props: { color?: string, width?: string, height?: string; }) => {
    return (
        <svg id="np_csv-file_237095_000000" xmlns="http://www.w3.org/2000/svg" width="21.55" height="24.324" viewBox="0 0 21.55 24.324">
            <path id="Path_12379" data-name="Path 12379" d="M61.172,43.406H64.7v2.353H61.172Z" transform="translate(-47.378 -33.026)" fill="#464a53" />
            <path id="Path_12380" data-name="Path 12380" d="M61.172,56.754H64.7v2.353H61.172Z" transform="translate(-47.378 -42.766)" fill="#464a53" />
            <path id="Path_12381" data-name="Path 12381" d="M61.172,70.1H64.7v2.352H61.172Z" transform="translate(-47.378 -52.507)" fill="#464a53" />
            <path id="Path_12382" data-name="Path 12382" d="M43.473,70.1H47v2.352H43.473Z" transform="translate(-34.462 -52.507)" fill="#464a53" />
            <path id="Path_12383" data-name="Path 12383" d="M25.77,70.1H29.3v2.352H25.77Z" transform="translate(-21.544 -52.507)" fill="#464a53" />
            <path id="Path_12384" data-name="Path 12384" d="M33.234,49.9l1.2-1.615L35.615,49.9H37.8L35.5,46.967l2.12-2.651h-2.1l-1.082,1.427-1.026-1.427H31.226l2.158,2.712L31.136,49.9Z" transform="translate(-25.46 -33.69)" fill="#464a53" />
            <path id="Path_12385" data-name="Path 12385" d="M30.688,5H11.129a1,1,0,0,0-1,1V28.329a1,1,0,0,0,1,1H30.688a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1ZM23.929,6.772l.438,1.739c.035.138.072.285.109.437.034-.139.07-.281.108-.423l.458-1.754h1.212l-1.227,4.147H23.784L22.619,6.77ZM20.492,9.3A1.359,1.359,0,0,1,19.466,8.02a1.323,1.323,0,0,1,1.442-1.313,2.637,2.637,0,0,1,1.053.232l.146.074-.276,1-.222-.12a1.605,1.605,0,0,0-.666-.151c-.233,0-.252.157-.252.206,0,.08,0,.139.462.337a1.328,1.328,0,0,1,.973,1.286A1.347,1.347,0,0,1,20.6,10.916a2.689,2.689,0,0,1-1.16-.28l-.114-.071.17-1.034.25.145a2.077,2.077,0,0,0,.848.2c.2,0,.321-.093.321-.249,0-.069,0-.164-.424-.335Zm-2.876-2.59a2.035,2.035,0,0,1,.934.19l.13.073-.246,1.045-.221-.1a1.34,1.34,0,0,0-.562-.119c-.562,0-.885.374-.885,1.024s.324,1.008.889,1.008a1.6,1.6,0,0,0,.561-.1l.231-.1.185,1.042-.126.069a2.173,2.173,0,0,1-.966.184A1.886,1.886,0,0,1,15.564,8.87a2,2,0,0,1,2.052-2.163ZM30.3,27.937H11.521l0-15.546H30.3Z" transform="translate(-10.133 -5)" fill="#464a53" />
        </svg>

    );
};

export const IconCloseOverlay = (props: { color?: string, width?: string, height?: string; }) => {
    return (
    <Box backgroundColor={"white"} borderRadius={"20px"} border={"solid 1px #ebebeb"} padding={3}>
            <HiOutlineArrowRight fontSize={20} color={"7031eb"}/>
        </Box>
    )
};

export const IconReturn = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="131" height="132" viewBox="0 0 131 132">
            <defs>
                <filter id="bg" x="0" y="0" width="131" height="132" filterUnits="userSpaceOnUse">
                    <feOffset dx="1.389" dy="7.878" />
                    <feGaussianBlur stdDeviation="14.5" result="blur" />
                    <feFlood floodColor="#695f97" floodOpacity="0.141" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="button_add_new_project_hover_active_" data-name="button add new project (hover active)" transform="translate(-1278.889 -159.378)">
                <g transform="matrix(1, 0, 0, 1, 1278.89, 159.38)" filter="url(#bg)">
                    <rect id="bg-2" data-name="bg" width="44" height="45" rx="19" transform="translate(42.11 35.62)" fill="#fff" />
                </g>
                <g id="Layer_2" data-name="Layer 2" transform="translate(1328 203)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_158" data-name="Rectangle 158" width="31" height="31" fill="none" />
                    </g>
                    <g id="Q3_icons" data-name="Q3 icons" transform="translate(5.672 6.322)">
                        <path id="Path_103" data-name="Path 103" d="M20.35,10.39l7.728,7.793a1.234,1.234,0,0,1,0,1.818L20.35,27.795a1.364,1.364,0,0,1-1.753.13,1.234,1.234,0,0,1-.13-1.948l5.585-5.585H10.283a1.3,1.3,0,1,1,0-2.6H24.051l-5.585-5.585a1.234,1.234,0,0,1,.13-1.948,1.364,1.364,0,0,1,1.753.13Z" transform="translate(-8.985 -10.001)" fill="#7031eb" />
                    </g>
                </g>
            </g>
        </svg>

    );
};

export const IconKpiFramework = (props: { color?: string, width?: string, height?: string; }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} height={'100%'} viewBox="0 0 64 64">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#7031eb" />
                    <stop offset="1" stopColor="#4a55fb" />
                </linearGradient>
            </defs>
            <g id="Group_3471" data-name="Group 3471" transform="translate(-5 -375)">
                <path id="Path_1806" data-name="Path 1806" d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z" transform="translate(5 375)" fill="url(#linear-gradient)" />
                <g id="Layer_2" data-name="Layer 2" transform="translate(16.943 386.943)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_372" data-name="Rectangle 372" width="40" height="40" transform="translate(0.057 0.057)" fill="none" />
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(1.671 2.169)">
                        <g id="Group_1132" data-name="Group 1132">
                            <circle id="Ellipse_99" data-name="Ellipse 99" cx="5" cy="5" r="5" transform="translate(0.385 23.888)" fill="#fff" />
                            <path id="Path_523" data-name="Path 523" d="M23.014,31a5.014,5.014,0,1,0,5.014,5.014A5.014,5.014,0,0,0,23.014,31Zm0,6.686a1.671,1.671,0,1,1,1.671-1.671A1.671,1.671,0,0,1,23.014,37.686Z" transform="translate(-4.704 -7.263)" fill="#fff" />
                            <circle id="Ellipse_100" data-name="Ellipse 100" cx="5" cy="5" r="5" transform="translate(26.385 23.888)" fill="#fff" />
                            <path id="Path_524" data-name="Path 524" d="M32.24,16.806a1.588,1.588,0,0,0-2.507.167A1.755,1.755,0,0,0,29.9,19.23l3.343,3.259a1.588,1.588,0,0,0,2.34,0l3.343-3.259a1.922,1.922,0,0,0,.251-2.256,1.671,1.671,0,0,0-2.591-.167l-.5.5A15.046,15.046,0,1,0,6,17.976v1.671a1.671,1.671,0,0,0,3.343,0V17.976a11.706,11.706,0,1,1,23.4-.752Z" transform="translate(-2.732 -2.595)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};
export const IconMarketing = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="56.277" height="56.277" viewBox="0 0 56.277 56.277">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#7031eb" />
                    <stop offset="1" stopColor="#4a55fb" />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 9.159) rotate(-11)">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_386" data-name="Rectangle 386" width="48" height="48" fill="none" />
                </g>
                <g id="Layer_6" data-name="Layer 6">
                    <g id="Group_1136" data-name="Group 1136">
                        <path id="Path_535" data-name="Path 535" d="M34,6h-.6l-30,8.8a2,2,0,0,0-1.4,2v9a2.2,2.2,0,0,0,1.4,2l4.7,1.3L9.7,39.4A2.9,2.9,0,0,0,12.6,42h4.5a3.2,3.2,0,0,0,2.2-1,3.3,3.3,0,0,0,.6-2.6l-1.1-6.1,14.6,4.3H34a2,2,0,0,0,2-2V8A2,2,0,0,0,34,6ZM15.8,38H13.5l-1.2-7.6,2.1.6ZM32,32,6,24.3v-6l26-7.6Z" fill="url(#linear-gradient)" />
                        <path id="Path_536" data-name="Path 536" d="M40,15.3a1.5,1.5,0,0,0,.9-.2l4-2a2.012,2.012,0,1,0-1.8-3.6l-4,2a2,2,0,0,0-.9,2.7A2.1,2.1,0,0,0,40,15.3Z" fill="url(#linear-gradient)" />
                        <path id="Path_537" data-name="Path 537" d="M44.9,29.6l-4-2a2.1,2.1,0,0,0-2.7.8,2,2,0,0,0,.9,2.7l4,2a1.5,1.5,0,0,0,.9.2,2.1,2.1,0,0,0,1.8-1.1,1.9,1.9,0,0,0-.9-2.6Z" fill="url(#linear-gradient)" />
                        <path id="Path_538" data-name="Path 538" d="M40,23.3h4a2,2,0,0,0,0-4H40a2,2,0,0,0,0,4Z" fill="url(#linear-gradient)" />
                    </g>
                </g>
            </g>
        </svg>

    );
};
export const IconWarning = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.347" height="18.347" viewBox="0 0 18.347 18.347">
            <g id="Group_11222" data-name="Group 11222" transform="translate(-21.95 -3.653)">
                <g id="Group_642" data-name="Group 642" transform="translate(21.95 3.653)">
                    <circle id="Ellipse_42" data-name="Ellipse 42" cx="9.173" cy="9.173" r="9.173" fill="#f7686a" />
                </g>
                <g id="Layer_2" data-name="Layer 2" transform="translate(24.572 6.274)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_321" data-name="Rectangle 321" width="13.105" height="13.105" fill="none" />
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(5.071 1.374)">
                        <g id="Group_945" data-name="Group 945">
                            <circle id="Ellipse_90" data-name="Ellipse 90" cx="1.048" cy="1.048" r="1.048" transform="translate(0.171 8.062)" fill="#fff" />
                            <path id="Path_492" data-name="Path 492" d="M21.285,9.96h.135c.373-.01.676-.2.71-.448L22.7,4.2c0-.5-.605-.9-1.352-.9S20,3.7,20,4.2l.575,5.315C20.608,9.758,20.912,9.95,21.285,9.96Z" transform="translate(-20 -3.3)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

export const IconPlanning = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#7031eb" />
                    <stop offset="1" stopColor="#4a55fb" />
                </linearGradient>
            </defs>
            <g id="invisible_box" data-name="invisible box">
                <rect id="Rectangle_393" data-name="Rectangle 393" width="48" height="48" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2">
                <g id="Group_1138" data-name="Group 1138">
                    <path id="Path_550" data-name="Path 550" d="M26,6V4a2,2,0,0,0-2-2H6V44a2,2,0,0,0,4,0V26H22v2a2,2,0,0,0,2,2H42V6ZM38,18H34v4h4v4H34V22H30v4H26V22H22V18H18v4H14V18H10V14h4V10H10V6h4v4h4V6h4v4h4v4h4V10h4v4h4Z" fill="url(#linear-gradient)" />
                    <rect id="Rectangle_394" data-name="Rectangle 394" width="4" height="4" transform="translate(14 14)" fill="url(#linear-gradient)" />
                    <rect id="Rectangle_395" data-name="Rectangle 395" width="4" height="4" transform="translate(18 10)" fill="url(#linear-gradient)" />
                    <rect id="Rectangle_396" data-name="Rectangle 396" width="4" height="4" transform="translate(22 14)" fill="url(#linear-gradient)" />
                    <rect id="Rectangle_397" data-name="Rectangle 397" width="4" height="4" transform="translate(26 18)" fill="url(#linear-gradient)" />
                    <rect id="Rectangle_398" data-name="Rectangle 398" width="4" height="4" transform="translate(30 14)" fill="url(#linear-gradient)" />
                </g>
            </g>
        </svg>

    );
};

export const IconGear = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <g id="Group_11197" data-name="Group 11197" transform="translate(-0.008 -0.127)">
                <circle id="Ellipse_26" data-name="Ellipse 26" cx="14" cy="14" r="14" transform="translate(0.008 0.127)" fill="#7031eb" />
                <g id="Layer_2" data-name="Layer 2" transform="translate(5.851 5.97)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_959" data-name="Rectangle 959" width="16" height="16" transform="translate(0.157 0.157)" fill="none" />
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(0.68 0.68)">
                        <g id="Group_11217" data-name="Group 11217">
                            <path id="Path_12443" data-name="Path 12443" d="M14.984,11.245l1.869-.51a7.817,7.817,0,0,0,0-2.515l-1.869-.51a.612.612,0,0,1-.374-.884l.952-1.7a7,7,0,0,0-1.733-1.733l-1.7.952-.272.068a.612.612,0,0,1-.612-.442L10.735,2.1a7.817,7.817,0,0,0-2.515,0L7.71,3.972a.612.612,0,0,1-.612.442l-.272-.068-1.7-.952A7,7,0,0,0,3.394,5.127l.952,1.7a.612.612,0,0,1-.374.884L2.1,8.22a7.817,7.817,0,0,0,0,2.515l1.869.51a.612.612,0,0,1,.374.884l-.952,1.7a7,7,0,0,0,1.733,1.733l1.7-.952.272-.068a.612.612,0,0,1,.612.442l.51,1.869a7.817,7.817,0,0,0,2.515,0l.51-1.869a.612.612,0,0,1,.612-.442l.272.068,1.7.952a7,7,0,0,0,1.733-1.733l-.952-1.7a.612.612,0,0,1,.374-.884ZM14.576,9.92h-.068a2.073,2.073,0,0,0-1.156,1.122,1.937,1.937,0,0,0,.034,1.631h.034v.068l.51.85a1.02,1.02,0,0,1-.272.272l-.85-.51h-.1a1.835,1.835,0,0,0-.85-.2,2.039,2.039,0,0,0-1.9,1.326v.1h0L9.648,15.6h-.34l-.272-.986h0v-.068A2.039,2.039,0,0,0,7.1,13.182a1.835,1.835,0,0,0-.85.2h-.1l-.85.51a1.02,1.02,0,0,1-.272-.272l.51-.85v-.068h.034A1.937,1.937,0,0,0,5.6,11.075,2.073,2.073,0,0,0,4.447,9.954h-.1L3.36,9.648v-.34l.986-.272h.1A2.073,2.073,0,0,0,5.6,7.914a1.937,1.937,0,0,0-.034-1.631H5.535V6.215l-.51-.85L5.3,5.093l.85.51h.1a1.835,1.835,0,0,0,.85.2A2.039,2.039,0,0,0,9,4.481v-.1H9l.306-1.02h.34l.272.986h0v.068a2.039,2.039,0,0,0,1.937,1.36,1.835,1.835,0,0,0,.85-.2h.1l.85-.51a1.02,1.02,0,0,1,.272.272l-.51.85v.068h-.034a1.937,1.937,0,0,0-.034,1.631A2.073,2.073,0,0,0,14.508,9h.1l.986.272v.34l-.986.272Z" transform="translate(-2 -2)" fill="#fff" />
                            <path id="Path_12444" data-name="Path 12444" d="M18.059,15a3.059,3.059,0,1,0,3.059,3.059A3.059,3.059,0,0,0,18.059,15Zm0,4.758a1.7,1.7,0,1,1,1.7-1.7A1.7,1.7,0,0,1,18.059,19.758Z" transform="translate(-10.582 -10.582)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import {Tooltip} from "./state";
import {organizationService} from "@services";
import {isHttpCodeSuccess} from "../../utils/network/helpers";
const currentUrl = window.location.href;
export const fetchTooltips = createAsyncThunk<any, { organizationId : string}>(
    "organization/fetchToolTips",
    async ({ organizationId }) => {
        try {
            /*const tooltips : Tooltip = {

                'Gross Revenue': {
                    shortText: `<div>Total sales revenue including all dollars charged from customer (item price, tax, shipping, duties, etc). Before discounts and returns. 
                    You can see how exactly how this metric and calculated, and customize it, in your <span id="openPnl">P&L Language</span>.
                    This metric is essential to understand your maximum potential revenue and understand all deductions between it, and your Net Revenue.</div>`,
                    longText: `<div>Total sales revenue including all dollars charged from customer (tax, shipping, duties, etc) and before discounts and returns. You can see below the break down of the different charges that build this metric. 
                    Includes all your sales channels. Note that we convert all revenues to your currency of choice and time zone of choice, defined in your data language [link].  
                    You can see how exactly how this metric and calculated, and customize it, in your P&L Language [link]. 
                    This metric is essential to understand your maximum potential revenue and understand all deductions between it, and your Net Revenue.
                    Gross Revenue = [Item price * quantity] + [Tax charge] + [Shipping charge] + [Duties charge] + [Gift wrap charge] 
                    </div>`
                }
            };
            return tooltips
            }*/
            const response = await organizationService.fetchTooltips({
                organizationId: organizationId,
            });

            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            return err;
        }
    }
);

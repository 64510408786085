import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import {
    CogsType, CreateChannelsDto,
    DepartmentsEnum,
    GeneralSettingsApiCall,
    organizationService,
    RolesEnum,
    ShareKpiData,
} from "@services";
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import {
    AmazonAdsProfile,
    FacebookAdsAccount,
    GoogleAdsAccount,
    Integration,
    IntegrationPlatform,
    IntegrationStatus, IntegrationType,
    Organization,
    User
} from "@models";
import {Account, DataLanguage} from "./state";
import { signIn, switchOrganization } from "store/auth";
import { push } from "redux-first-history";
import { fetchIntegrations } from "store/integrations";
import { addDays } from "date-fns";
import { displayUpdateButton } from "./actions";
import { addToasts } from "../ui";
import { Toast, ToastStatus } from "../../components/toast/Toast";
import {Channel} from "../../models/channel";
import {AccountIntegrations} from "../account";
import {createAccount} from "../ui/thunks";

export const fetchHolidays = createAsyncThunk<
  any,
  {
    organizationId: string;
    dataLanguageId: string;
    fromDate: string;
    toDate: string;
  }
>(
  "organization/fetchHolidaysData",
  async ({ organizationId, dataLanguageId, fromDate, toDate }, thunkAPI) => {
    try {
      const response = await organizationService.fetchHolidays({
        organizationId: organizationId,
        dataLanguageId,
        fromDate,
        toDate,
      });
      // console.log(response);
      if (isHttpCodeSuccess(response.status) && !!response?.data) {
        return response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return err;
    }
  }
);

export const fetchOrganization = createAsyncThunk<
    Organization,
    { organizationId: string }
>("organization/fetchOrganization", async ({organizationId}, thunkAPI) => {
    try {
        const response = await organizationService.fetchOrganization(
            organizationId
        );

        if (!!response?.data?.dataLanguage) {
            thunkAPI.dispatch(
                fetchDataLanguage({
                    dataLanguageId: response.data.dataLanguage,
                    organizationId: organizationId,
                })
            );

            thunkAPI.dispatch(
                fetchHolidays({
                    dataLanguageId: response?.data?.dataLanguage,
                    organizationId: organizationId,
                    fromDate: addDays(new Date(), -30)?.toISOString().slice(0, 10),
                    toDate: new Date()?.toISOString().slice(0, 10),
                })
            );
        }
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            return {
                ...response?.data,
                id: response?.data?.id,
            };
        } else {
            throw new Error();
        }
    } catch (err) {
        console.log("Error", err);
        return thunkAPI.rejectWithValue("Error fetch organization");
    }
});
export const fetchDataLanguage = createAsyncThunk<
    Organization,
    { dataLanguageId: string; organizationId: string }
>(
    "organization/fetchDataLanguage",
    async ({dataLanguageId, organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.fetchDataLanguage({
                dataLanguageId,
                organizationId,
            });

            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(fetchMarketingMappingElementsView({organizationId: organizationId}));

                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error fetch organization");
        }
    }
);

export const fetchTodos = createAsyncThunk<any, { organizationId: string }>(
    "organization/fetchTodos",
    async ({organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.fetchTodos({
                organizationId: organizationId,
            });
            // console.log(response);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            return err;
        }
    }
);
export const fetchCustomCogs = createAsyncThunk<
    string[],
    { organizationId: string }
>("organization/fetchCustomCogs", async ({organizationId}, thunkAPI) => {
    try {
        const response = await organizationService.fetchCustomCogs(organizationId);
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            return response?.data;
        } else {
            throw new Error();
        }
    } catch (err) {
        console.log("Error", err);
        return thunkAPI.rejectWithValue("Error fetch organization");
    }
});

export const fetchCustomCogsData = createAsyncThunk<
    CogsType[],
    { organizationId: string }
>("organization/fetchCustomCogsData", async ({organizationId}, thunkAPI) => {
    try {
        const response = await organizationService.fetchCustomCogsData(
            organizationId
        );
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            return response?.data;
        } else {
            throw new Error();
        }
    } catch (err) {
        console.log("Error", err);
        return thunkAPI.rejectWithValue("Error fetch organization");
    }
});

export const fetchProductsCounts = createAsyncThunk<
    any,
    { organizationId: string }
    >("organization/fetchProductsCounts", async ({organizationId}, thunkAPI) => {
    try {
        const response = await organizationService.fetchProductsCount(
            organizationId
        );
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            return response?.data;
        } else {
            throw new Error();
        }
    } catch (err) {
        console.log("Error", err);
        return thunkAPI.rejectWithValue("Error fetch organization");
    }
});

export const fetchMarketingMappingElementsView = createAsyncThunk<any, any>(
    "organization/fetchMarketingElementsView",
    async ({organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.getMarketingElements(
                organizationId
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error fetch organization");
        }
    }
);

export const triggerBackfill = createAsyncThunk<
    Organization,
    { organizationId: string; integrationId: string }
>(
    "organization/triggerBackfill",
    async ({organizationId, integrationId}, thunkAPI) => {
        try {
            const response = await organizationService.triggerBackfill({
                organizationId: organizationId,
                integrationId: integrationId,
            })
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Backfill started',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                return {
                    ...response?.data
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error trigger backfill");
        }
    }
);

export const createChannel = createAsyncThunk<
    any,
    { organizationId: string; createChannelDto: CreateChannelsDto[] }
    >(
    "organization/createChannel",
    async ({organizationId, createChannelDto}, thunkAPI) => {
        try {
            const response = await organizationService.createChannel({
                organizationId: organizationId,
                channelDto: createChannelDto,
            })
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Sales channels created successfully',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                return response.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error trigger backfill");
        }
    }
);

export const getGoogleAdsAccounts = createAsyncThunk<
    any,
    { organizationId: string; integration: Integration }
    >(
    "organization/getGoogleAdsAccounts",
    async ({organizationId, integration}, thunkAPI) => {
        try {
            if(!!integration?.id){
                const response = await organizationService.getGoogleAdsAccounts({
                    organizationId: organizationId,
                    integrationId: integration?.id,
                })
                if (isHttpCodeSuccess(response.status) && !!response?.data) {

                    return {
                        ...response?.data
                    };
                } else {
                    throw new Error();
                }
            }

        } catch (err) {
            return thunkAPI.rejectWithValue("Error");
        }
    }
);

export const getFacebookAdsAccounts = createAsyncThunk<
    any,
    { organizationId: string; integration: Integration }
    >(
    "organization/getFacebookAdsAccounts",
    async ({organizationId, integration}, thunkAPI) => {
        try {
            if(!!integration?.id){
                const response = await organizationService.getFacebookAdsAccounts({
                    organizationId: organizationId,
                    integrationId: integration?.id,
                })
                if (isHttpCodeSuccess(response.status) && !!response?.data) {

                    return {
                        ...response?.data
                    };
                } else {
                    throw new Error();
                }
            }

        } catch (err) {
            return thunkAPI.rejectWithValue("Error");
        }
    }
);


export const addIntegration = createAsyncThunk<any, { organizationId?: string, integration: Integration }>(
    "organization/addIntegration",
    async ({organizationId, integration}, thunkAPI) => {
        try {
            const response = await organizationService.addIntegration({
                organizationId,
                integration,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Integration added successfully',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))

                if(!!response?.data){
                        const integrationToCreateAccountsFor = response?.data['integrations']?.at(-1);
                        if(integrationToCreateAccountsFor['platform'] === IntegrationPlatform.AMAZON_ADS){
                            const createAccountsDto: AccountIntegrations[] = integrationToCreateAccountsFor?.amazonAdsProfiles?.map((profile: AmazonAdsProfile) => {
                                return {
                                    platformType: integrationToCreateAccountsFor?.platform,
                                    name: profile?.accountInfo?.name,
                                    displayName: profile?.accountInfo?.name,
                                    accountId: profile?.profileId,
                                    status: IntegrationStatus.CONNECTED,
                                    integrationId: integrationToCreateAccountsFor?._id,
                                    countryCode: profile?.countryCode,
                                    currencyCode: profile?.currencyCode,
                                    type: integration?.type

                                }
                            })
                            !!organizationId && thunkAPI.dispatch(createAccount({orgId: organizationId, accounts: createAccountsDto}))
                        }
                    thunkAPI.dispatch(triggerBackfill({
                        organizationId: organizationId!,
                        integrationId: response?.data?.at(-1).id
                    }))

                }

                return {
                  data: response?.data,
                  id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const getGoogleAdsAccountsV2 = createAsyncThunk<{ accounts?: GoogleAdsAccount[], object?: any },
    { organizationId: string; object: any }
    >(
    "organization/getGoogleAdsAccountsV2",
    async ({organizationId, object}, thunkAPI) => {
        try {
            const response = await organizationService.getGoogleAdsAccountsV2({
                organizationId,
                object,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    accounts: response?.data?.accounts,
                    object: response?.data?.object
                };

            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const getAmazonAdsAccounts = createAsyncThunk<{ accounts?: AmazonAdsProfile[] },
    { organizationId: string; integrationId: string }
    >(
    "organization/getAmazonAdsAccounts",
    async ({organizationId, integrationId}, thunkAPI) => {
        try {
            const response = await organizationService.getAmazonAdsAccounts(organizationId, integrationId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                const profilesAsAccounts : AccountIntegrations[] = response?.data?.map((profile: AmazonAdsProfile) => {
                    return {
                        platform: IntegrationPlatform.AMAZON_ADS,
                        type: IntegrationType.MARKETING,
                        status: IntegrationStatus.CONNECTED,
                        countryCode: profile?.countryCode,
                        currencyCode: profile?.currencyCode,
                        displayName: profile?.accountInfo?.name,
                        name: profile?.accountInfo?.name,
                        accountId: profile?.profileId,
                        integrationId: integrationId
                    }
                })

                thunkAPI.dispatch(createAccount({orgId: organizationId, accounts: profilesAsAccounts}))
                return response?.data

            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);


export const getFacebookAdsAccountsV2 = createAsyncThunk<{ accounts?: FacebookAdsAccount[], object?: any },
    { organizationId: string; object: any }
    >(
    "organization/getFacebookAdsAccountsV2",
    async ({organizationId, object}, thunkAPI) => {
        try {
            const response = await organizationService.getFacebookAdsAccountsV2({
                organizationId,
                object,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    accounts: response?.data?.accounts,
                    object: response?.data?.object
                };

            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const updateFacebookAds = createAsyncThunk<any,
    { integrationId: string; object: any }
    >(
    "organization/updateFacebookAdsIntegration",
    async ({integrationId, object}, thunkAPI) => {
        try {
            const response = await organizationService.updateFacebookAdsIntegration({
                integrationId,
                object,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Integration Updated',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                return response?.data

            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const refreshIntegration = createAsyncThunk<
    any,
    { organizationId: string; integrationId: string; integration: Integration }
    >(
    "organization/refreshIntegration",
    async ({organizationId,integrationId, integration}, thunkAPI) => {
        try {
            const response = await organizationService.refreshIntegration({
                organizationId,
                integrationId,
                integration,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    data: response?.data,
                    id: response?.data?.id,
                };

            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);


export const deleteIntegration = createAsyncThunk<
    Organization,
    { organizationId: string; integrationId: string; onSuccess?: () => void }
>(
    "organization/deleteIntegration",
    async ({organizationId, integrationId, onSuccess}, thunkAPI) => {
        try {
            const response = await organizationService.deleteIntegration(
                organizationId,
                integrationId
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                if (!!onSuccess) onSuccess();
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);
export const deleteUserPending = createAsyncThunk<
    Organization,
    { organizationId: string; userId: string; onSuccess?: () => void }
>(
    "organization/deleteUserPending",
    async ({organizationId, userId, onSuccess}, thunkAPI) => {
        try {
            const response = await organizationService.deleteUserPending(
                organizationId,
                userId
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                if (!!onSuccess) onSuccess();
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error delete pending user");
        }
    }
);

export const inviteUser = createAsyncThunk<
    Organization,
    {
        organizationId: string;
        email: string;
        role: RolesEnum;
        department: DepartmentsEnum;
        inviterEmail?: string;
        inviterName?: string;
    }
>(
    "organization/inviteUser",
    async (
        {organizationId, email, role, department, inviterEmail, inviterName},
        thunkAPI
    ) => {
        try {
            const response = await organizationService.inviteUser(
                organizationId,
                email,
                role,
                department,
                inviterEmail,
                inviterName
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const editPnlSettings = createAsyncThunk<
    any,
    { dataLanguageId: string; data: any; organizationId: string }
>(
    "organization/changeDataLanguagePnlSettings",
    async ({dataLanguageId, data, organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.putPnlSettings({
                dataLanguageId,
                data,
                organizationId,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(
                    disableApplyButton({organizationId, dataLanguageId, display: true})
                );

                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const createPnlElement = createAsyncThunk<
    any,
    { dataLanguageId: string; data: any; organizationId: string }
>(
    "organization/createPnlElement",
    async ({dataLanguageId, data, organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.createPnlElement(
                data,
                dataLanguageId,
                organizationId
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                /*thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))*/

                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const changeCurrencyAndTime = createAsyncThunk<
    DataLanguage,
    {
        generalSettings: GeneralSettingsApiCall;
        organizationId: string;
        dataLanguageId: string;
    }
>(
    "organization/changeCurrencyAndTime",
    async ({generalSettings, organizationId, dataLanguageId}, thunkAPI) => {
        try {
            const response = await organizationService.changeTimezoneAndCurrency({
                generalSettings,
                organizationId,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(
                    disableApplyButton({organizationId, dataLanguageId, display: true})
                );
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);
export const changeIntegrationName = createAsyncThunk<any, any>(
    "organization/changeIntegrationName",
    async ({organizationId, integrationId, name}, thunkAPI) => {
        try {
            const response = await organizationService.changeIntegrationName(
                organizationId,
                integrationId,
                name
            );
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(
                    fetchIntegrations({organizationId: organizationId})
                );
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const addUser = createAsyncThunk<any, { token: string; user?: User }>(
    "organization/addUser",
    async ({token, user}, thunkAPI) => {
        try {
            const response = await organizationService.addUser(token);
            if (!!user) {
                thunkAPI.dispatch(
                    signIn({email: user.email, password: user.password})
                );
                thunkAPI.dispatch(push("/admin/panel"));
            }
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return {
                    ...response?.data,
                    id: response?.data?.id,
                };
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error add integration");
        }
    }
);

export const createOrganization = createAsyncThunk<
    Organization,
    { organization: Organization; onSuccess?: () => void }
>("organization/addUser", async ({organization, onSuccess}, thunkAPI) => {
    try {
        const response = await organizationService.createOrganization(organization);
        // console.log(response);
        if (isHttpCodeSuccess(response.status) && !!response?.data) {
            thunkAPI.dispatch(
                switchOrganization({organizationId: response?.data?.id})
            );
            /* thunkAPI.dispatch(switchOrganization({ddd: response?.data?.organizationId})); */
            if (onSuccess) {
                onSuccess();
            }
            return response?.data;
        } else {
            throw new Error();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err);
    }
});
export const createMarketingElement = createAsyncThunk<
    any,
    { data: any; dataLanguageId: string; organizationId: string }
>(
    "organization/addMarketingElement",
    async ({data, dataLanguageId, organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.createMarketingElement(
                data,
                dataLanguageId,
                organizationId
            );
            // console.log(response);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(
                    fetchMarketingMappingElementsView({organizationId: organizationId})
                );
                thunkAPI.dispatch(
                    disableApplyButton({organizationId, dataLanguageId, display: true})
                );

                return response?.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);
export const shareKpiByEmail = createAsyncThunk<
    any,
    { data: ShareKpiData; organizationId: string, image?: string }
>(
    "organization/shareKpiByEmail",
    async (
        {data, organizationId, image}: { data: ShareKpiData; organizationId: string, image?: string },
        thunkAPI
    ) => {
        try {
            const response = await organizationService.shareKpiByEmail({
                data,
                organizationId,
                image
            });
            // console.log(response);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                return response?.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);
export const editMarketingElement = createAsyncThunk<
    any,
    { data: any; dataLanguageId: string; organizationId: string }
>(
    "organization/editMarketingElement",
    async ({data, dataLanguageId, organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.editMarketingChannels(
                data,
                dataLanguageId,
                organizationId
            );
            // console.log(response);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                thunkAPI.dispatch(
                    fetchMarketingMappingElementsView({organizationId: organizationId})
                );
                thunkAPI.dispatch(
                    disableApplyButton({organizationId, dataLanguageId, display: true})
                );

                return response?.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);

export const disableApplyButton = createAsyncThunk<
    any,
    { organizationId: string; dataLanguageId: string; display: boolean }
>(
    "organization/disableApplyButton",
    async ({organizationId, dataLanguageId, display}, thunkAPI) => {
        try {
            const response = await organizationService.disableApplyButton({
                organizationId,
                dataLanguageId,
                display,
            });
            // console.log(response);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                if (display) {
                    thunkAPI.dispatch(displayUpdateButton());
                }
                return response?.data;
            } else {
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);

export const uploadOrganizationAvatarImage = createAsyncThunk<
    any,
    { organizationId: string; data: FormData}
    >(
    "users/uploadOrganizationAvatarImage",
    async (
        { data, organizationId}: {  data: FormData, organizationId: string },
        thunkAPI
    ) => {
        const response = await organizationService.uploadOrganizationImage(
            organizationId,
            data
        );
        thunkAPI.dispatch(fetchOrganization({organizationId: organizationId}))
        return response.data;
    }
);

export const triggerAirflow = createAsyncThunk<
    any,
    { organizationId: string; dataLanguageId: string }
>(
    "organization/triggerAirflow",
    async ({organizationId, dataLanguageId}, thunkAPI) => {
        try {
            const response = await organizationService.triggerAirflow({
                organizationId,
            });
            /*console.log(response);
             */
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                /* thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: false}))*/
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Changes in progress',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                return response?.data;
            } else {
                thunkAPI.dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Something went wrong',
                            isClosable: true,
                            status: ToastStatus.ERROR,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                throw new Error();
            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);

export const triggerAirflowDailyStats = createAsyncThunk<
    any,
    { organizationId: string; }
    >(
    "organization/triggerAirflowDailyStats",
    async ({organizationId}, thunkAPI) => {
        try {
            const response = await organizationService.triggerAirflowDailyStats({
                organizationId,
            });
            if (isHttpCodeSuccess(response.status) && !!response?.data) {

                return response?.data
            } else {

            }
        } catch (err) {
            console.log("Error", err);
            return thunkAPI.rejectWithValue("Error");
        }
    }
);

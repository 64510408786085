import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {FacebookState} from "./state";

// sign-up state
export const facebookStateSelector = (state: RootState): FacebookState => state.facebook;

// code
export const codeSelector = createSelector(facebookStateSelector, (state) => state.code);

// sign-up error
export const facebookErrorSelector = createSelector(facebookStateSelector, (state) => state.error);

// loading
export const facebookLoadingSelector = createSelector(facebookStateSelector, (state) => state.loading);

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  `;
  export const HeaderHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  export const HeaderIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `;

  export const Title = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    display: flex;
  `;
  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
  `;

  export const Description = styled.div``;

  export const Content = styled.div`
    width: calc(100% - 50px);
    height: 100%;
    padding: 20px;
    margin-left: 50px;
  `;

  export const Row = styled.div<{premium?: boolean}>`
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px 0;
    justify-content: space-between;
    /*opacity: ${props => props?.premium ? .5 : 1};*/
  `;
  export const RowTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const RowTitle = styled.div`
    font-weight: 600;
    display: flex;
    position: relative;
    align-items: center;
    gap: 15px;    
    font-size: 18px;
  `;
  export const RowDescription = styled.div``;
  export const RowButton = styled.div<{premium?: boolean}>`
    border-radius: 30px;
    width: 600px;
    height: 50px;
    opacity: ${props => props?.premium ? .5 : 1};
    border: solid 2px #e2e2e2;
    box-shadow: 0px 13px 16px #0000000a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 25px;

    &:hover {
      cursor: ${props => props?.premium ? '' : 'pointer'};
    }
  `;
  export const RowButtonText = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  `;
  export const RowButtonTextWrapper = styled.div`
    font-size: 14px;

    width: 85%;
  `;

  export const RowButtonIcon = styled.div`
    font-size: 14px;
  `;

  export const RowOkIcon = styled.div``;
}

export default styles;

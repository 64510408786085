// state

export enum AmazonAdsRegions {
    NORTH_AMERICA,
    EUROPE,
    FAR_EAST
}

export interface AmazonState {
    code?: string;
    loading: boolean;
    error?: string;
}

// initial state
export const initialAmazonState: AmazonState = {
    code: undefined,
    loading: false,
    error: undefined,
}

import wideViewsStyles from "./styles";
import {useTranslation} from "react-i18next";

export const Departments = () => {
    // hooks
    const {t} = useTranslation(['translation', 'translation']);

    // helpers

    // renderers

    return (
        <wideViewsStyles.Wrapper>
            {t('PAGE_NAME', {ns: 'translation'})}
        </wideViewsStyles.Wrapper>
    );
};

import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

// sign-up state
export const authStateSelector = (state: RootState) => state.auth;

// user
export const userSelector = createSelector(
  authStateSelector,
  (state) => state.user
);
export const resetPasswordLoadingSelector = createSelector(
  authStateSelector,
  (state) => state.resetPasswordLoading
);
export const resetPasswordStatus = createSelector(
  authStateSelector,
  (state) => state.resetPasswordSuccess
);
export const authSelector = createSelector(authStateSelector, (state) => state);

// user
export const tokenSelector = createSelector(
  authStateSelector,
  (state) => state.token
);

// sign-up error
export const authErrorSelector = createSelector(
  authStateSelector,
  (state) => state.error
);

// loading
export const authLoadingSelector = createSelector(
  authStateSelector,
  (state) => state.loading
);

export const currentWorkspaceSelector = createSelector(
  authStateSelector,
  (state) => state.user?.workspaces
);

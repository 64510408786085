import styled from 'styled-components';
import {Props} from './AuthButton';
import {Button} from "@components";

export const AuthButtonWrapper = styled(Button)<Props>`
  background-color: ${props => props.theme.backgroundColor};
  border:  ${props => {
    return `1px solid ${props.theme.borderColor}`;
  }};

  > a {
    color: ${props => props.theme.textColor};
    > img {
      height: 18px;
      width: auto;
    }
  }
`;

import styled from "styled-components";

export namespace styles {

  export const StyledTableHolder = styled.div`
    border-spacing: 0;
    width: 100%;
    height: 460px;
    position: relative;
    overflow: auto;
    ::-webkit-scrollbar-track {
      margin-top: 40px !important;
    }
  `;
  export const StyledTable = styled.table`
    border-spacing: 0;
    width: 100%;
    /*min-height: 300px;*/
    display: block;
   /* overflow-x: auto;
    overflow-y: auto;*/
    display: flex;
    flex-direction: column;
    position: relative;
    /*::-webkit-scrollbar-track {
      margin-top: 40px !important;
    }*/
  
  `;

// gr - target

  export const PnLTrFooter = styled.div<{ width: number }>`
    background-color: rgb(249, 249, 254);
    box-shadow: ${(props) => props.theme.fullShadow};
   /* position: sticky;*/
    padding: 3px 0 !important;
    position: sticky;
    bottom: 0;
   /* position: sticky;*/
   /* bottom: 0;*/
 /*   bottom: 0;
 
    display: block;*/
    width: ${(props) => props.width + "px"};
    min-width: 100%;
    z-index: 10;
    /*position: -webkit-sticky;
    position: sticky;
    */
  `;
  export const ToolTipSubtext = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => props.theme.primaryColor};
    font-size: 15px;
  `;
  export const ToolTip = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #7a86a1;
    font-weight: bold;
    font-size: 16px;
  `;
  export const StyledTh = styled.th<{ width: string }>`
    width: ${(props) => props.width};
    background-color: white;
    font-weight: lighter;
    font-size: 15px;
    color: #c0bdcc;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    min-width: 130px;
    margin: 0;
    padding: 0.7rem 0.5rem;
    /* position: -webkit-sticky; 
    position: sticky; */
    z-index: 11;
    border-bottom: 1px solid #c5c3d0;
    :last-child {
      border-right: 0;
    }
  `;
  export const StyledTbody = styled.tbody`
    /*display: block;*/
    margin: 10px 0 0 0;
    /*max-height: 350px;*/
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};

    /* overflow: auto; */
  `;
  export const StyledTr = styled.tr<{index?: number, width: number, placeholder?: true}>`
       background-color: ${props => !!props?.index && props?.index % 2 ? "#F6F5FF" : "unset"};
   /* border-top: 0.75px solid #c5c4cc;*/
    display: block;
    padding: 8px 0;
    width: ${props => props?.width+"px"};
    min-width: 100%;
    min-height: ${props => props?.placeholder ? "56px" : ""};
    
    &:hover {
      box-shadow: 0 13px 16px rgba(0, 0, 0, .1);
    position: relative;
      z-index: 5;
    }

    :last-child {
      td {
        border-bottom: 0;
      }
    }
  `;
  export const TdGrandTotal = styled.div`
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    position: absolute;
    border-radius: 5px;
    white-space: nowrap;
    transform: scale(1.2, 1.1);
    font-size: 0.9rem;
    padding: 10px 0px;
    
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
    min-width: max-content;
    /*left: 10px;
    right: 0;*/
    /* text-align: center; */
    top: -3px;
    box-shadow: ${(props) => props.theme.fullShadow};
  `;
  export const StyledThead = styled.thead`
    /*  border-bottom: gray; */
    /* display: block; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 11;
    /* position: -webkit-sticky; 
    position: sticky; */
    /*max-width: 250px;*/
    /* width: 100%; */
    /* border-bottom: 1px solid #c5c3d0; */
  `;
  export const StyledFoot = styled.tfoot`
    /*  border-bottom: gray; */
    /* display: block; */
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 11;
    /* position: -webkit-sticky; 
    position: sticky; */
    max-width: 250px;
    /* width: 100%; */
    /* border-bottom: 1px solid #c5c3d0; */
  `;
  export const StyledHeaderRow = styled.tr<{ tableWidth: number }>`
    display: block;
    /* position: -webkit-sticky; 
    position: sticky; */
    /*  width: 100%; */
    /* width: ${(props) => props.tableWidth + "px"}; */
    /* border-bottom: 1px solid #c5c3d0; */
  `;
  export const StyledTd = styled.th<{ width: string }>`
    width: ${(props) => props.width};
    min-width: 130px;
    margin: 0;
    position: relative;
    padding: 0.5rem;
    :last-child {
      border-right: 0;
    }
  `;
  export const StyledTdDate = styled.th<{ width: string }>`
    width: ${(props) => props.width};
    min-width: 130px;
    margin: 0;
    padding: 0.5rem;
    
    :last-child {
      border-right: 0;
    }
    color: #7a86a1;
  `;
  export const FirstColumn = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `
}

export default styles;

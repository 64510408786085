import React from 'react';
import { useDrop } from 'react-dnd';
import Card, {CardProps} from './Card';
import {ItemTypes} from "./itemTypes";
import styles from "./styles";
import {MoreInfoButton} from "../more-info-button/MoreInfoButton";
import {useDispatch, useSelector} from "react-redux";
import {addToasts, openApplyBar} from "../../store/ui";
import {moveMetric, Pnl, pnlDataLanguageDraftSelector, pnlDataLanguageSelector} from "../../store/pnl";
import {Toast, ToastStatus} from "../toast/Toast";
import {organizationSelector} from "@store";
import InformationWrapper from "../InformationWrapper/InformationWrapper";

interface ContainerProps {
    id?: string;
    name?: string;
    cards?: CardProps[];
    moveCard?: (dragIndex: number, hoverIndex: number) => void;
    setCards?: any;
    hiddenContainer?: boolean;
    accepts: string[],
    subTitle?: string;
    moreInfoOnClick: () => void,
    bankContainer?: boolean;
    setMetricIdToEdit: (id: string) => void;
}

const Container: React.FC<ContainerProps> = ({ id, name, cards,
                                                 moveCard, setCards, accepts,
                                                 subTitle, moreInfoOnClick, bankContainer, hiddenContainer, setMetricIdToEdit  }) => {
    const specificOrder = ['netRevenue', 'grossProfit', 'contributionProfit'];
    const dispatch = useDispatch();
    const pnlElements = useSelector(pnlDataLanguageDraftSelector);
    const organization = useSelector(organizationSelector);

    const handleSetCards = (item: { id: string; index: number }, containerId: string) => {
        setCards((updatedCards: CardProps[]) => {
            const newCards = updatedCards.map((card: CardProps) => {
                // dropped card
                if (card.id === item.id) {
                    if(card.fieldName !== containerId){
                        dispatch(openApplyBar({isApplyBarOpen: true}))
                    }
                    return {
                        ...card,
                        fieldName: containerId
                    };
                }
                return card;
            });


            return newCards?.sort((card) => {
                if(card?.isDefault){
                    return -1
                }
                else {
                    return 1
                }
            })

            const sortedCards = [...newCards].sort((a, b) => {
                if(!!a?.fieldName && !!b?.fieldName && a?.index !== undefined && b?.index !== undefined){
                    const indexA = specificOrder.indexOf(a?.fieldName);
                    const indexB = specificOrder.indexOf(b?.fieldName);

                    if (indexA !== -1 && indexB !== -1) {
                        return indexA - indexB || a?.index - b?.index;
                    } else if (indexA !== -1) {
                        return -1;
                    } else if (indexB !== -1) {
                        return 1;
                    }

                    return a.index - b.index;
                }
                return -1
            });


            const updatedSortedCards = sortedCards.map((card, index) => {
                return {
                    ...card,
                    index: index
                };
            });


            console.log(updatedSortedCards)
            return updatedSortedCards
        })

    }

    const getAllowByPercentage = (): string[] => {
        const mapping = {
            'netRevenue': ['grossRevenue'],
            'grossProfit': ['grossRevenue', 'netRevenue'],
            'contributionProfit': ['grossRevenue', 'netRevenue', 'grossProfit'],
            'bank': ['grossRevenue', 'netRevenue', 'grossProfit'],
        };
        if(!!id){
            return mapping[id]
        }
        return []
    };

    const [, drop] = useDrop({
        accept: accepts,
        drop(item: { id: string; index: number }) {

            const foundMetric = pnlElements?.data?.find((metric) => metric?.fieldName === item?.id);
            if(!!organization?.id && !!foundMetric?.fieldName){
                dispatch(moveMetric({organizationId: organization?.id, dto: [{bucketName: id === 'bank' ? '' : id, metricName: foundMetric?.fieldName}]}))
            }


            if(foundMetric?.isPercentage){
                const percentageBy = foundMetric?.expression[0]?.operand;
                const allowedList = getAllowByPercentage();

                if(!!percentageBy && allowedList?.includes(percentageBy)){
                    if(!!id){
                        handleSetCards(item, id);

                    }
                }

                else {
                    dispatch(addToasts({
                        toasts: [
                            {
                                title: 'Invalid Placement',
                                isClosable: true,
                                status: ToastStatus.ERROR,
                                duration: 2500 //in milliseconds
                            } as Toast
                        ]
                    }))
                    return
                }
            }

            if(!!id){
                handleSetCards(item, id)

            }
            if(!!moveCard && !!cards){
                moveCard(item.index, cards.length);
            }
        },
    });

    const renderName = () => {
        return (
            <styles.ExpensesSectionTitle>
                <InformationWrapper text={name} displayLongText/>
                {/*<MoreInfoButton onClick={moreInfoOnClick} />*/}
            </styles.ExpensesSectionTitle>
        )
    }
    const renderSubtitle = () => {
        return (
            <styles.TiltedText>{subTitle}</styles.TiltedText>
        )
    }

    const renderCards = () => {
      return cards
        ?.filter((card) => card.fieldName === id)
        .map((card, index) => (
          <Card
            fieldName={card.fieldName}
            key={card.id}
            id={card.id}
            type={card.type}
            text={card.text}
            index={index}
            cardId={card?.cardId}
            moveCard={moveCard}
            currentContainer={id}
            isDefault={card?.isDefault}
            handleSetCards={handleSetCards}
            callback={card?.callback}
            setMetricIdToEdit={setMetricIdToEdit}
          />
        ));
    };

    return (
      <>
        {!!name && renderName()}
        {
         !hiddenContainer &&  <styles.Bucket bankBucket={bankContainer} ref={drop}>
            {!!subTitle && renderSubtitle()}
            {renderCards()}
          </styles.Bucket>
        }
      </>
    );
};

export default Container;
import bigWideviewCardStyles from "./styles";
import {
    AvatarGroup,
    Box,
    Flex,
    Popover,
    PopoverContent,
    PopoverTrigger, Skeleton, SkeletonCircle, SkeletonText,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";

import {ProgressBar} from "../progress-bar/ProgressBar";
import {TargetBadge} from "../target-badge/TargetBadge";
import {useTheme} from "@styles";
import {
    getColorByPercentage, getColorByPercentageNew,
    getUserColorById,
    gradientDictionary,
} from "../../utils/colors";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
/* import { openExploreView } from "../../store/ui"; */
import {Line} from "react-chartjs-2";
import {UserAvatar, Workspace} from "@models";
import {
    authSelector,
    organizationSelector,
    pinKpi,
    userSelector,
} from "@store";
import {dateRangeSelector, ExploresEnum, openDefaultExploreView} from "store/ui";
import {CreateAlert} from "components/create-alert/CreateAlert";
import {KpisEnum} from "models/kpi-settings";
import {ShareKpi} from "components/share-kpi/ShareKpi";
import {getChannelNameById} from "utils/get-channel-name/getChannelNameById";
import {channelsStateSelector} from "store/channels";
import {MenuButton} from "../menu-generic/MenuGenericButton";
import {ThreeDotMenuButton} from "../3dot-menu-button/ThreeDotMenuButton";
import {usersDataSelector} from "../../store/users";
import {VscDebugDisconnect} from "react-icons/vsc";
import {
    BsChevronCompactDown,
    BsClockHistory,
    BsFillLightningChargeFill,
} from "react-icons/bs";
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";
import InformationWrapper from "../InformationWrapper/InformationWrapper";
import {kpisLoader} from "../../store/kpis";

interface ExpandRow {
    title?: string;
    value?: number;
    shareOfTotal?: number;
}

interface Props {
    title?: string;
    value?: string;
    targetPercentage?: number;
    userFullName?: string;
    userAvatarUrl?: string;
    enumType?: string;
    kpiNumber?: number;
    chartData?: number[];
    lastPeriodChartData?: number[];
    dataMode?: DataMode;
    expandRows?: ExpandRow[];
    assignedKpisAvatar?: UserAvatar[];
    hideTarget?: boolean;
    deductionTarget?: boolean;
    index?: number;
    stateToTransferToExplore?: any;
}


export enum DataMode {
    NotUpToDate,
    NotSet,
    Broken
}

enum Section {
    MARKETING,
    FINANCE,
    WEBSITE
}

interface DateRange {
    startDate?: string,
    endDate?: string
}

// this is the inner circle with whatever you want inside
export const BigWideviewCard = (props: Props) => {
    //consts


    //hooks

    const {t} = useTranslation();

    const [expand, setExpand] = useState(false);
    const {isOpen: isAlertsOpen, onOpen: onAlertsOpen, onClose: onAlertsClose} = useDisclosure();  //
    const {isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose} = useDisclosure();  //

    // const isExploreOpen = useSelector(isExploreOpenSelector)
    const {onOpen, onClose, isOpen} = useDisclosure();
    const [isInsidePinnedKpi, setIsInsidePinnedKpi] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const user = useSelector(userSelector);
    const organization = useSelector(organizationSelector);
    const channels = useSelector(channelsStateSelector);
    const auth = useSelector(authSelector);
    const allUsers = useSelector(usersDataSelector);
    const currentCurrency = useCurrencySign();
    const currentDateRange = useSelector(dateRangeSelector);
    const [lastPeriodDateRange, setLastPeriodDateRange] = useState<DateRange>({startDate: '', endDate: ''});
    const isLoading = useSelector(kpisLoader);

    //helpers
    const setExpandCard = (isExpand: boolean) => {
        setExpand(isExpand);
    };

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
        const day = String(date.getDate()).padStart(2, '0');

        // Format the date as 'YYYY-MM-DD'
        return `${year}-${month}-${day}`;
    }
    const formatDateString = (date: string) => {
        // const year = date.getFullYear();
        // const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
        // const day = String(date.getDate()).padStart(2, '0');

        // Format the date as 'YYYY-MM-DD'
        return date;//`${year}-${month}-${day}`;
    }

    useEffect(() => {
        if (!!currentDateRange && !!currentDateRange.startDate && !!currentDateRange.endDate) {
            // Convert date strings to Date objects
            const fromDate = new Date(`${currentDateRange.startDate}T00:00:00.000Z`);
            const toDate = new Date(`${currentDateRange.endDate}T23:59:59.999Z`);

            // Compute the difference in milliseconds
            const difference = toDate.getTime() - fromDate.getTime();

            // Subtract the difference to get the last period
            const lastPeriodStart = new Date(fromDate.getTime() - difference).toISOString().slice(0, 10);
            const lastPeriodEnd = new Date(toDate.getTime() - difference - 1).toISOString().slice(0, 10);


            setLastPeriodDateRange({
                startDate: formatDateString(lastPeriodStart),
                endDate: formatDateString(lastPeriodEnd)
            })
        } else {
            setLastPeriodDateRange({
                startDate: '',
                endDate: ''
            })
        }
    }, [currentDateRange]);

    useEffect(() => {
        const currentWorkspace = user?.workspaces?.find((workspace) => workspace.organization === organization?.id);
        if (!!currentWorkspace?.pinnedKpis && props.kpiNumber !== undefined) {
            const copyOfWorkspace: Workspace = JSON.parse(JSON.stringify(currentWorkspace)); // deep copy of object
            if (!!copyOfWorkspace.pinnedKpis) {
                const currentTypePinnedKpi: number[] = copyOfWorkspace.pinnedKpis;
                if (!currentTypePinnedKpi.includes(props.kpiNumber)) {
                    setIsInsidePinnedKpi(true);
                }
            }
        }
    }, [organization?.id, props.kpiNumber, user?.workspaces]);

    const pinKpiHelper = () => {
        if (user?.workspaces !== undefined && organization?.id !== undefined) {
            const currentWorkspace = user?.workspaces?.find((workspace) => workspace.organization === organization?.id);
            const copyOfWorkspace: Workspace = JSON.parse(JSON.stringify(currentWorkspace)); // deep copy of object
            if (!!copyOfWorkspace?.pinnedKpis && props.kpiNumber !== undefined) {
                const currentTypePinnedKpi: number[] = copyOfWorkspace.pinnedKpis;
                if (isInsidePinnedKpi) {
                    currentTypePinnedKpi.push(props.kpiNumber);
                }
                if (!isInsidePinnedKpi) {
                    const index = currentTypePinnedKpi.indexOf(props.kpiNumber);
                    if (index > -1) {
                        currentTypePinnedKpi.splice(index, 1);
                    }
                }
            }
            if (!!user?.id && !!currentWorkspace && typeof currentWorkspace.id === "string") {
                dispatch(pinKpi({data: copyOfWorkspace, userId: user?.id, workspaceId: currentWorkspace.id}));
                onClose();
            }
        }
    };

    const renderUserNamesTooltip = () => {

        if (!!props?.assignedKpisAvatar) {
            return props?.assignedKpisAvatar?.map(nameUser => `${nameUser?.userName}`);
        }
        return [t('NO_USER_ASSIGN', {ns: 'translation'})]
    }

    // content
    const renderAvatar = () => {

        return (
            <AvatarGroup left={"20px"} size="sm" max={1}>
                {!!props?.assignedKpisAvatar?.length &&
                props?.assignedKpisAvatar?.length > 0 ? (
                    props?.assignedKpisAvatar.map((user, i) => {
                        const foundUserImage = allUsers?.find((currentUser) => currentUser?.id === user?.userId);

                        return (<>
                                <Tooltip
                                    label={renderUserNamesTooltip().join(", ")}
                                    placement="bottom"
                                    aria-label="A tooltip wide views"
                                >
                                    <bigWideviewCardStyles.UserAvatar
                                        key={i}
                                        color={"white"}
                                        src={foundUserImage?.image}

                                        background={
                                            !!user?.userId
                                                ? getUserColorById(user?.userId)
                                                : !!user?.userName
                                                    ? gradientDictionary[0]
                                                    : null
                                        }
                                        name={
                                            !!user?.userName && !!user?.userLastName
                                                ? user?.userName + " " + user?.userLastName
                                                : !!user?.userName
                                                    ? user?.userName
                                                    : ""
                                        }
                                        size="sm"
                                    />
                                </Tooltip>
                            </>
                        )
                    })
                ) : (
                    <>
                        <Tooltip placement="bottom">
                            <bigWideviewCardStyles.UserAvatar size={"sm"} name={""}/>
                        </Tooltip>
                    </>
                )}
            </AvatarGroup>
        );
    };

    const renderDataModeIcon = (dataMode: DataMode) => {

        const tooltipTitles = ['DATA_ISNT_UP_TO_DATE', 'CONNECTION_IS_MISSING', 'OOPS_SOMETHING_IS_BROKEN'];

        const DataModeIcon = () => {
            return (
                dataMode === DataMode.NotSet ?
                    <bigWideviewCardStyles.DataModeIconWrapper color={'#F1EAFF'}>
                        <VscDebugDisconnect fontWeight={'bold'} size={'16px'} color={theme.primaryColor}/>
                    </bigWideviewCardStyles.DataModeIconWrapper>
                    :
                    <Flex alignItems={"center"}>
                        <bigWideviewCardStyles.NoDataText>
                            No Data
                        </bigWideviewCardStyles.NoDataText>
                        <Box w={2}/>
                        <bigWideviewCardStyles.DataModeIconWrapper color={'#7031EB'}>
                            {dataMode === DataMode.Broken ?
                                <BsFillLightningChargeFill size={'16px'} color={'#fff'}/>
                                :
                                <BsClockHistory size={'16px'} color={'#fff'}/>}
                        </bigWideviewCardStyles.DataModeIconWrapper>
                    </Flex>
            );
        };

        return (
            <Popover trigger={'hover'} placement='top'>
                <PopoverTrigger>
                    <div>
                        <DataModeIcon/>
                    </div>
                </PopoverTrigger>
                <PopoverContent borderRadius={30} w={"max-content"}>
                    <bigWideviewCardStyles.Tooltip>
                        <bigWideviewCardStyles.TooltipHeader>
                            {/* <DataModeIcon /> */}
                            <div style={{width: '10px'}}/>
                            <bigWideviewCardStyles.TooltipTitle>
                                {t(tooltipTitles[dataMode], {ns: 'translation'})}
                            </bigWideviewCardStyles.TooltipTitle>
                        </bigWideviewCardStyles.TooltipHeader>
                        <bigWideviewCardStyles.TooltipSection>
                            <bigWideviewCardStyles.TooltipLastUpdate>
                                {/*  Last Update: 11/06/22 on 10:46 AM */}
                            </bigWideviewCardStyles.TooltipLastUpdate>
                            <div style={{width: '10px'}}/>
                            <bigWideviewCardStyles.TooltipMoreinfo>
                                {t('LETS_FIX_IT', {ns: 'translation'})}
                            </bigWideviewCardStyles.TooltipMoreinfo>
                        </bigWideviewCardStyles.TooltipSection>
                    </bigWideviewCardStyles.Tooltip>
                </PopoverContent>
            </Popover>

        );
    };

    const menuButtonsData: MenuButton[] = [
        {
            title: !isInsidePinnedKpi ? t('UNPIN', {ns: 'translation'}) : t('PIN_TO_WORKSPACE', {ns: 'translation'}),
            onClick: () => pinKpiHelper()
        },
        {
            title: t('SHARE', {ns: 'translation'}),
            onClick: () => openShareModal(),
            disabled: true
        },
        {
            title: t('CREATE_ALERT', {ns: 'translation'}),
            onClick: () => openCreateNewAlertModal(),
            disabled: true
        }
    ];

    const renderMenuButton = (size: number) => {

        return (
            <Box mt={"4px"} ml={3}>
                <ThreeDotMenuButton data={menuButtonsData} size={size}/>
            </Box>

        );
    };

    const isDisplayCurrency = () => {
        const noCurrencyCards = ['Orders', 'Unit Sold', 'Quantity', 'Blended ROAS']
        return !!props?.title && !(noCurrencyCards?.includes(props?.title));
    }

    const getGraphGradient = (color: string, alphaHex: string) => {
        const ctx = (document.getElementById('bigWideviewCardGraph') as HTMLCanvasElement)?.getContext("2d");
        const gradient = ctx?.createLinearGradient(0, 90, 1, 0);
        if (props.dataMode === DataMode.Broken) {

        } else {
            gradient?.addColorStop(0, `#ffffff${alphaHex}`);
            gradient?.addColorStop(1, `${color}${alphaHex}`);
        }
        return gradient;
    };
    const getSectionFromTitle = () => {
        switch (props?.enumType) {
            case ("marketing"):
                return Section.MARKETING;
            case ("finance"):
                return Section.FINANCE;
            case ("website"):
                return Section.WEBSITE;
            default:
                return Section.MARKETING;
        }
    };

    const openCreateNewAlertModal = () => {
        onAlertsOpen();
    };
    const openShareModal = () => {
        onShareOpen();
    };

    const renderGraph = () => {
        let colorOfGraph = theme.errorColor;
        let backgroundColor = theme.errorBackgroundColor;
        if (props?.deductionTarget) {
            colorOfGraph = theme.successColor;
            backgroundColor = theme.successBackgroundColor;
        }
        if (!!props?.chartData) {
            const firstValue = props?.chartData[0];
            const lastValue = props?.chartData[props?.chartData?.length - 1];

            if (lastValue > firstValue) {
                if (props?.deductionTarget) {
                    colorOfGraph = theme.errorColor;
                    backgroundColor = theme.errorBackgroundColor;
                } else {
                    colorOfGraph = theme.successColor;
                    backgroundColor = theme.successBackgroundColor;
                }
            }
        }

        const data = {
            labels: props?.chartData,
            datasets: [
                {
                    label: "Data",
                    borderWidth: 0.5,
                    data: props?.chartData,
                    fill: true,
                    backgroundColor: getGraphGradient(theme.primaryColor, '33'),
                    borderColor: theme.primaryColor,
                    tension: 0.4
                },
                {
                    label: "Last Period",
                    borderWidth: 1,
                    data: props?.lastPeriodChartData,
                    fill: true,
                    borderDash: [5, 5],
                    backgroundColor: "#fffffa",
                    borderColor: "#b493ef",
                    tension: 0.4
                },
            ]
        };
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false
                    }
                },
                y: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false
                    }
                },
                datalabels: {
                    display: false
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            }
        };

        const barKpis = [KpisEnum.GROSS_REVENUE, KpisEnum.NET_REVENUE, KpisEnum.GROSS_PROFIT, KpisEnum.GROSS_MARGIN, KpisEnum.CONTRIBUTION_PROFIT];
        const isGraphIsBar = !!props?.kpiNumber && barKpis?.includes(props?.kpiNumber);

        return (
            <bigWideviewCardStyles.GraphWrapper>
                {/*{isGraphIsBar ? <Line id="bigWideviewCardGraph" options={options} data={data}/> :*/}
                <Line id="bigWideviewCardGraph" options={options} data={data}/>
                {/*}*/}
            </bigWideviewCardStyles.GraphWrapper>
        );
    };


    const renderCardHeader = () => {
        const title = props?.title ?? '';
        return (
            <bigWideviewCardStyles.CardHeader>
                <bigWideviewCardStyles.Title>
                    <InformationWrapper text={title}></InformationWrapper>
                </bigWideviewCardStyles.Title>
                <bigWideviewCardStyles.ContentTools>
                    {renderAvatar()}
                    <Box w={5}/>
                    <Box position={"absolute"} right={"-5px"}>
                        {renderMenuButton(10)}
                    </Box>
                </bigWideviewCardStyles.ContentTools>
            </bigWideviewCardStyles.CardHeader>
        )
    }

    const renderSkeleton = () => {
        const startColor = '#d7cdf5';
        const endColor = '#d4c9f144';
        const fadeDuration = 0.4;
        const speed = 0.8;
        const options = {startColor, endColor, fadeDuration, speed}
        return (
            <Flex flexDirection={"column"} m={3}>
                <Flex flexDirection={"row"} justifyContent={"space-between"}>
                    <Skeleton
                        {...options}
                        height='16px' width='50px' mt={'14px'}><div>skeleton</div></Skeleton>
                    <Flex flexDirection={"row"}>
                        <SkeletonCircle size='10' mr={2} mt={1} {...options}/>
                        <Flex flexDirection={"column"}>
                            <SkeletonCircle size='3' mt={1} {...options}/>
                            <SkeletonCircle size='3' mt={1} {...options}/>
                            <SkeletonCircle size='3' mt={1} {...options}/>
                        </Flex>
                    </Flex>
                </Flex>
                <SkeletonText mt='4' noOfLines={4} spacing='4' {...options}/>
            </Flex>
        )
    }

    const renderCardContent = () => {
        const displayCurrency = isDisplayCurrency();
        const targetColor = getColorByPercentageNew(props?.targetPercentage ?? 0, theme, props?.kpiNumber);
        return (
            <bigWideviewCardStyles.CardData>
                <Flex flexDirection={"column"} alignItems={"center"} minWidth={100}>
                    <bigWideviewCardStyles.Value>{displayCurrency ? currentCurrency : ''}{props?.value}</bigWideviewCardStyles.Value>
                    {props.dataMode === DataMode.Broken && renderDataModeIcon(props.dataMode as DataMode)}
                    <Box h={2}/>

                    {props.dataMode === DataMode.Broken ? <></> :
                        props?.hideTarget ? <></> :
                            <bigWideviewCardStyles.TargetText className={'shifted-text'} fontSize={15}
                                                              color={targetColor}>

                                <InformationWrapper
                                    shortText={`Compared to previous period: ${lastPeriodDateRange?.startDate} to ${lastPeriodDateRange?.endDate}`}
                                    text={`${(!!props?.targetPercentage && props?.targetPercentage > 0) ? '+' : ''} ${(props?.targetPercentage ?? 0)?.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}%`}
                                    provided={true}
                                    small={true}
                                    width={200}
                                    dontDisplayHeader={true}/>
                            </bigWideviewCardStyles.TargetText>

                    }

                </Flex>
                <Box w={1}/>
                <Box overflow={'hidden'}>
                    {props.dataMode !== DataMode.Broken && renderGraph()}
                </Box>
            </bigWideviewCardStyles.CardData>
        )
    }

    const renderContent = () => {
        return (
            <bigWideviewCardStyles.Content>
                {renderCardHeader()}
                {renderCardContent()}
                {/* <bigWideviewCardStyles.ContentFirstColumn>
                    <bigWideviewCardStyles.Title>{props?.title}</bigWideviewCardStyles.Title>
                    <bigWideviewCardStyles.Value>{props?.value}</bigWideviewCardStyles.Value>
                    {props.dataMode === DataMode.Broken && renderDataModeIcon(props.dataMode as DataMode)}

                    {props.dataMode === DataMode.Broken ? <></> :
                        <TargetBadge percentage={props?.targetPercentage ?? 0}/>}
                </bigWideviewCardStyles.ContentFirstColumn>*/}
                {/* <bigWideviewCardStyles.ContentSecondColumn>
                    <bigWideviewCardStyles.ContentTools>
                        {renderAvatar()}
                        <Box position={"absolute"} right={"-10px"}>
                        {renderMenuButton(32)}
                        </Box>
                        <Box w={7} h={7}/>
                    </bigWideviewCardStyles.ContentTools>
                    <div>
                        {renderGraph()}
                    </div>
                </bigWideviewCardStyles.ContentSecondColumn>*/}
            </bigWideviewCardStyles.Content>
        );
    };

    // footer
    const renderProgress = (value?: number, colorByPercentage?: boolean) => {
        const color = colorByPercentage ? getColorByPercentage((!!value ? value : 0), theme) : theme.primaryColor;
        return (
            <bigWideviewCardStyles.ProgressWrapper>
                <bigWideviewCardStyles.ProgressText>
                    <InformationWrapper
                        shortText={'Share of Total'}
                        text={`${!!value && !isNaN(value) ? Math.trunc(value) : 0}%`}
                        provided={true}
                        small={true}
                        width={100}
                        dontDisplayHeader={true}/>
                </bigWideviewCardStyles.ProgressText>
                <ProgressBar color={color} percentage={(!!value ? value : 0)} height={'3px'}/>
            </bigWideviewCardStyles.ProgressWrapper>
        );
    };


    const renderDataCollapseRow = (title?: string, value?: number, shareOfTotalProgres?: number) => {
        return (
            !expand && <bigWideviewCardStyles.ExploreButton onClick={() => {
                setExpandCard(true)
            }}>
                <BsChevronCompactDown color={'#7031EB'}/>
            </bigWideviewCardStyles.ExploreButton>);

    };


    const renderDataCollapseContent = () => {
        return (
            <bigWideviewCardStyles.FooterCollapseDataContent>
                {renderDataTable()}
                {renderWideExploreButton()}
            </bigWideviewCardStyles.FooterCollapseDataContent>
        );
    };

    const renderDataTableRow = (title?: string, value?: number, shareOfTotalProgress?: number) => {
        const displayCurrency = isDisplayCurrency();
        return (
            <bigWideviewCardStyles.DataTableRow>
                <bigWideviewCardStyles.DataTableRowTitle>
                    {!!title ? getChannelNameById(title, channels.channels) ?? title : null}
                </bigWideviewCardStyles.DataTableRowTitle>
                <bigWideviewCardStyles.DataTableRowTitle>
                    {displayCurrency ? currentCurrency : ''}{value?.toLocaleString('en-us', {maximumFractionDigits: 2})}
                </bigWideviewCardStyles.DataTableRowTitle>
                <bigWideviewCardStyles.DataTableRowDivider/>
                {renderProgress(shareOfTotalProgress)}
            </bigWideviewCardStyles.DataTableRow>
        );
    };

    // const renderDataTableHeader = () => {
    //     return (
    //         <bigWideviewCardStyles.DataTableHeader>
    //             <bigWideviewCardStyles.DataTableHeaderSpacer>
    //             </bigWideviewCardStyles.DataTableHeaderSpacer>
    //             <bigWideviewCardStyles.DataTableHeaderTitle>
    //                 {'Value'}
    //             </bigWideviewCardStyles.DataTableHeaderTitle>
    //             <bigWideviewCardStyles.DataTableHeaderDivider/>
    //             <bigWideviewCardStyles.DataTableHeaderTitle>
    //                 {'Share of Total'}
    //             </bigWideviewCardStyles.DataTableHeaderTitle>
    //         </bigWideviewCardStyles.DataTableHeader>
    //     );
    // };

    const renderDataTable = () => {
        const replacedShare = props?.expandRows?.map((item) => {
            if (!!item?.shareOfTotal) {
                return item
            } else {
                return {...item, shareOfTotal: 0}
            }
        })
        const sortedRows = replacedShare?.sort((a, b) => b.shareOfTotal! - a.shareOfTotal!);
        return (
            <bigWideviewCardStyles.DataTable>
                {/*{renderDataTableHeader()}*/}
                {!!sortedRows ? sortedRows?.map((item) => {
                    return (renderDataTableRow(item?.title, item?.value, item?.shareOfTotal));
                }) : <></>}
            </bigWideviewCardStyles.DataTable>
        );
    };

    const renderExplore = {
        [KpisEnum.GROSS_REVENUE]: ExploresEnum.GROSS_REVENUE,
        [KpisEnum.NET_REVENUE]: ExploresEnum.NET_REVENUE,
        [KpisEnum.CONVERSION_RATE]: ExploresEnum.CONVERSION_RATE,
        [KpisEnum.SESSIONS]: ExploresEnum.SESSIONS,
        [KpisEnum.MARKETING_SPEND]: ExploresEnum.MARKETING_SPEND,
        [KpisEnum.BLENDED_ROAS]: ExploresEnum.BLENDED_ROAS,
        [KpisEnum.ORDERS]: ExploresEnum.ORDERS, // gross revenue
        [KpisEnum.COST_PER_ORDER]: ExploresEnum.COST_PER_ORDER, // gross revenue
        [KpisEnum.AVERAGE_ORDERS_VALUE]: ExploresEnum.AVERAGE_ORDERS_VALUE, // new explore
        [KpisEnum.GROSS_PROFIT]: ExploresEnum.GROSS_PROFIT, // net margin gross margin
        [KpisEnum.CONTRIBUTION_PROFIT]: ExploresEnum.CONTRIBUTION_PROFIT, // net margin cont margin

    };

    const renderWideExploreButton = () => {
        return (
            <bigWideviewCardStyles.WideExploreButtonWrapper>
                <bigWideviewCardStyles.WideExploreButton onClick={() => {
                    if(props?.kpiNumber === KpisEnum.MARKETING_SPEND){
                        dispatch(openDefaultExploreView({
                            isOpen: true,
                            ExploresEnum: renderExplore[KpisEnum.MARKETING_SPEND],
                        }));
                        return
                    }
                    if(props?.kpiNumber === KpisEnum.BLENDED_ROAS){
                        dispatch(openDefaultExploreView({
                            isOpen: true,
                            ExploresEnum: renderExplore[KpisEnum.BLENDED_ROAS],
                        }));
                        return
                    }
                    if(props?.kpiNumber === KpisEnum.COST_PER_ORDER){
                        dispatch(openDefaultExploreView({
                            isOpen: true,
                            ExploresEnum: renderExplore[KpisEnum.COST_PER_ORDER],
                        }));
                        return
                    }
                    dispatch(openDefaultExploreView({
                        isOpen: true,
                        ExploresEnum: ExploresEnum.METRIC_EXPLORE,
                        stateToTransfer: props?.stateToTransferToExplore
                    }));
                }}>
                    {'Explore'}
                </bigWideviewCardStyles.WideExploreButton>
            </bigWideviewCardStyles.WideExploreButtonWrapper>
        );
    };


    const renderFooter = () => {
        const replacedShare = props?.expandRows?.map((item) => {
            if (!!item?.shareOfTotal) {
                return item
            } else {
                return {...item, shareOfTotal: 0}
            }
        })
        return (
            <bigWideviewCardStyles.Footer index={props?.index} isOpen={expand} onMouseLeave={() => {
                setExpandCard(false)
            }}>
                {props.dataMode === DataMode.Broken ? <></> :
                    <>
                        <bigWideviewCardStyles.FooterCollapseData in={expand}
                                                                  transition={{
                                                                      exit: {duration: 0.7},
                                                                      enter: {duration: 0.7}
                                                                  }}
                        >
                            {renderDataCollapseContent()}
                        </bigWideviewCardStyles.FooterCollapseData>
                        <bigWideviewCardStyles.SneakPickButton onMouseEnter={() => {
                            setExpandCard(true)
                        }}>
                            <BsChevronCompactDown color={'#7031EB'}/>
                        </bigWideviewCardStyles.SneakPickButton>
                    </>}

            </bigWideviewCardStyles.Footer>
        );
    };


    return (
        <>
            <CreateAlert kpi={props.title} section={getSectionFromTitle()} isOpen={isAlertsOpen} onClose={onAlertsClose}
                         onOpen={onAlertsOpen}/>
            <ShareKpi exploreEnum={!!props?.kpiNumber ? renderExplore[props?.kpiNumber] : 0} isOpen={isShareOpen}
                      onClose={onShareClose} onOpen={onShareOpen}/>

            <bigWideviewCardStyles.Wrapper>
                <bigWideviewCardStyles.Card noData={props.dataMode === DataMode.Broken}>
                    {!isLoading ? ( <>
                            {renderContent()}
                            {renderFooter()}
                        </>) : renderSkeleton()}
                </bigWideviewCardStyles.Card>
            </bigWideviewCardStyles.Wrapper>
        </>
    );
};


import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { Column, Row, useRowSelect, useSortBy, useTable } from "react-table";
import { TableData } from "store/website";
import { styles } from "./styles";
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";


interface Data {
    col_0: string;  // browser 
    col_1: number;  // revenue
    col_2: number;  // % revenue out of revenues
    col_3: number;  // Users
    col_4: number;  // % user out of users
}

interface filters {
    browser: string[],
    age: string[],
    gender: string[],
    country: string[],
}

interface Props {
    rows: TableData[];
    displayMockData?:boolean;
    selectedTab: number;
    setSelectedTab: (selectedTab: number) => void,
    setFilteredData: (propsData: TableData[]) => void;
}

export const UserAnalysisTable = (props: Props) => {
    //hooks
    const [filters, setFilters] = useState<filters>({ browser: [], age: [], gender: [], country: [] });
    const [headers, setHeaders] = useState<string[]>(["Browser", "Revenue", "%", "Users", "%"]);
    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const [selectedRowTab1, setSelectedRowTab1] = useState([] as Row<Data>[]);
    const [selectedRowTab2, setSelectedRowTab2] = useState([] as Row<Data>[]);
    const [selectedRowTab3, setSelectedRowTab3] = useState([] as Row<Data>[]);
    const [tabBrowserFooter, setTabBrowserFooter] = useState({ revenue: 0, revenuePerc: 0, users: 0, usersPerc: 0 });
    const [propsData, setPropsData] = useState<TableData[]>(props.rows);
    const [highestNumber, setHighestNumber] = useState(0);
    const currency = useCurrencySign();
    useEffect(() => {
        const tempData: number[] = [];
        let temp = 0;
        propsData.forEach((item) => {
            tempData.push(item.revenue);
        });
        tempData.forEach((element) => {
            if (temp < element) {
                temp = element;
            }
        });
        setHighestNumber(temp);
    }, [propsData]);
    //helpers
    const decimalFix = (number: number) => {
        return (Math.round(number * 100) / 100).toFixed(2);
    };


    const percentageFormat = (amount: number) => {
        return amount.toString() + '%';
    };

    const totalRevenue = props.rows.reduce((sum, row) => row.revenue + sum, 0);
    const totalUsers = props.rows.reduce((sum, row) => row.users + sum, 0);

    const dataToDataTable = (data: TableData[]): Data[] => {
        if (props.selectedTab === 1) {
            return data.map((row, i) => {
                return ({
                    col_0: row.ages,
                    col_1: row.revenue,
                    col_2: row.revenue / totalRevenue * 100,
                    col_3: row.users,
                    col_4: row.users / totalUsers * 100,
                });
            });
        }
        if (props.selectedTab === 2) {
            return data.map((row, i) => {
                return ({
                    col_0: row.gender,
                    col_1: row.revenue,
                    col_2: row.revenue / totalRevenue * 100,
                    col_3: row.users,
                    col_4: row.users / totalUsers * 100,
                });
            });
        }
        if (props.selectedTab === 3) {
            return data.map((row, i) => {
                return ({
                    col_0: row.country,
                    col_1: row.revenue,
                    col_2: row.revenue / totalRevenue * 100,
                    col_3: row.users,
                    col_4: row.users / totalUsers * 100,
                });
            });
        }
        return data.map((row, i) => {
            return ({
                col_0: row.browser,
                col_1: row.revenue,
                col_2: row.revenue / totalRevenue * 100,
                col_3: row.users,
                col_4: row.users / totalUsers * 100,
            });
        });
    };

    const keysToSum = ['revenue', 'users'];
    const summed: TableData[] = Object.values(props.rows.reduce((obj, record) => {
        if (!obj[record.browser]) {
            obj[record.browser] = { ...record };
        } else {
            keysToSum.forEach(key => {
                if (key === "ctr") {
                    return;
                }
                obj[record.browser][key] += record[key];
            });
        }
        return obj;
    }, {}));
    useEffect(() => {
        setPropsData(summed);
    }, [props.rows]);


    useEffect(() => {
        if(props?.displayMockData){
            switch (props.selectedTab) {
                case 0:
                    setHeaders(["Browser", "Revenue", "%", "Users", "%"]);
                    break;
                case 1:
                    setHeaders(["Ages", "Revenue", "%", "Users", "%"]);
                    break;
                case 2:
                    setHeaders(["Gender", "Revenue", "%", "Users", "%"]);
                    break;
                case 3:
                    setHeaders(["Geography", "Revenue", "%", "Users", "%"]);
                    break;
            }
        }else {
            switch (props.selectedTab) {
                case 0:
                    setHeaders(["Device", "Revenue", "%", "Users", "%"]);
                    break;
                case 1:
                    setHeaders(["Brand", "Revenue", "%", "Users", "%"]);
                    break;
                case 2:
                    setHeaders(["Model", "Revenue", "%", "Users", "%"]);
                    break;
                case 3:
                    setHeaders(["Geography", "Revenue", "%", "Users", "%"]);
                    break;
            }
        }
    }, [props.selectedTab, props?.displayMockData]);




    const headersToColumns = (headers: string[],): Column<Data>[] => {
        return headers.map((header, index) => {


            if (header === "id") {
                return {
                    Header: header, accessor: 'col_' + index.toString(), sortType: 'basic', show: false,
                } as Column<Data>;
            }

            return {
                Header: header, accessor: 'col_' + index.toString(), sortType: 'basic',

                Footer: info => {
                    const totalRevenuePerc = useMemo(() => {
                        return (info.rows.reduce((sum, row) => row.values.col_2 + sum, 0));
                    }, [info.rows]);
                    const totalUsersPerc = useMemo(() => {
                        return (info.rows.reduce((sum, row) => row.values.col_4 + sum, 0));
                    }, [info.rows]);
                    if (info.column.id === "col_2") {
                        return <>{decimalFix(totalRevenuePerc) + "%"}</>;
                    }
                    if (info.column.id === "col_4") {
                        return <>{decimalFix(totalUsersPerc) + "%"}</>;
                    }
                    return <></>;
                }

            } as Column<Data>;
        });
    };

    const data = useMemo<Data[]>(() => dataToDataTable(propsData!), [propsData]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(headers), [headers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
        allColumns
    } = useTable({
        columns, data
    }, useSortBy, useRowSelect);

    switch (props.selectedTab) {
        case 0: // browser tab
            filters.browser = selectedRowTab0.map((item) => (item.cells[0].value));
            break;
        case 1: // ages tab
            filters.age = selectedRowTab1.map((item) => (item.cells[0].value));
            break;
        case 2: // gender tab
            filters.gender = selectedRowTab2.map((item) => (item.cells[0].value));
            break;
        case 3: // geography tab
            filters.country = selectedRowTab3.map((item) => (item.cells[0].value));
            break;
    }

    const filterByRow = (row: Row<Data>) => {
        if (props.selectedTab === 0) {
            const isRowSelected = selectedRowTab0.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab0([...(selectedRowTab0.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab0];
                newSelected.push(row);
                setSelectedRowTab0(newSelected);
            }

        }
        if (props.selectedTab === 1) {
            const isRowSelected = selectedRowTab1.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab1([...(selectedRowTab1.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab1];
                newSelected.push(row);
                setSelectedRowTab1(newSelected);
            }
        }
        if (props.selectedTab === 2) {
            const isRowSelected = selectedRowTab2.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab2([...(selectedRowTab2.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab2];
                newSelected.push(row);
                setSelectedRowTab2(newSelected);
            }
        }
        if (props.selectedTab === 3) {
            const isRowSelected = selectedRowTab3.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab3([...(selectedRowTab3.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab3];
                newSelected.push(row);
                setSelectedRowTab3(newSelected);
            }
        }
    };



    const browserTabHelper = () => {
        let newData = props.rows;
        if (filters.age.length !== 0) {
            newData = newData.filter((item) => filters.age.includes(item.ages));
        }
        if (filters.gender.length !== 0) {
            newData = newData.filter((item) => filters.gender.includes(item.gender));
        }
        if (filters.country.length !== 0) {
            newData = newData.filter((item) => filters.country.includes(item.country));
        }

        const summed: TableData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.browser]) {
                obj[record.browser] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.browser][key] += record[key];
                });
            }
            return obj;
        }, {}));
        props.setFilteredData(summed);

        setPropsData(summed);
    };
    const agesTabHelper = () => {
        let newData = props.rows;
        if (filters.browser.length !== 0) {
            newData = newData.filter((item) => filters.browser.includes(item.browser));
        }
        if (filters.gender.length !== 0) {
            newData = newData.filter((item) => filters.gender.includes(item.gender));
        }
        if (filters.country.length !== 0) {
            newData = newData.filter((item) => filters.country.includes(item.country));
        }
        const summed: TableData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.ages]) {
                obj[record.ages] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.ages][key] += record[key];
                });
            }
            return obj;
        }, {}));
        props.setFilteredData(summed);

        setPropsData(summed);
    };
    const genderTabHelper = () => {
        let newData = props.rows;
        if (filters.browser.length !== 0) {
            newData = newData.filter((item) => filters.browser.includes(item.browser));
        }
        if (filters.age.length !== 0) {
            newData = newData.filter((item) => filters.age.includes(item.ages));
        }
        if (filters.country.length !== 0) {
            newData = newData.filter((item) => filters.country.includes(item.country));
        }
        const summed: TableData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.gender]) {
                obj[record.gender] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.gender][key] += record[key];
                });
            }
            return obj;
        }, {}));
        props.setFilteredData(summed);

        setPropsData(summed);
    };
    const geographyTabHelper = () => {
        let newData = props.rows;
        if (filters.browser.length !== 0) {
            newData = newData.filter((item) => filters.browser.includes(item.browser));
        }
        if (filters.age.length !== 0) {
            newData = newData.filter((item) => filters.age.includes(item.ages));
        }
        if (filters.gender.length !== 0) {
            newData = newData.filter((item) => filters.gender.includes(item.gender));
        }
        const summed: TableData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.country]) {
                obj[record.country] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.country][key] += record[key];
                });
            }
            return obj;
        }, {}));
        props.setFilteredData(summed);

        setPropsData(summed);
    };


    //renderers
    const tabButtonsContainer = () => {
        return (
            <styles.TabButtonsContainer>
                <styles.TabButton {...props.selectedTab === 0 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(0);
                    browserTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {props?.displayMockData ? t('BROWSER', { ns: 'translation' }) : t('Device', { ns: 'translation' }) }
                        </styles.StyledDiv>
                        {filters?.browser.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 0 ? { selected: true } : { selected: false }}>
                                {(filters?.browser.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...props.selectedTab === 1 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(1);
                    agesTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {props?.displayMockData ? t('AGE', { ns: 'translation' }) : t('Brand', { ns: 'translation' }) }
                        </styles.StyledDiv>
                        {filters?.age.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 1 ? { selected: true } : { selected: false }}>
                                {(filters?.age.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...props.selectedTab === 2 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(2);
                    genderTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {props?.displayMockData ? t('GENDER', { ns: 'translation' }) : t('Model', { ns: 'translation' }) }
                        </styles.StyledDiv>
                        {filters.gender.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 2 ? { selected: true } : { selected: false }}>
                                {(filters.gender.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...props.selectedTab === 3 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(3);
                    geographyTabHelper();

                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('GEOGRAPHY', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.country.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 3 ? { selected: true } : { selected: false }}>
                                {(filters.country.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>

            </styles.TabButtonsContainer >
        );
    };

    const renderTable = () => {
        return (<table  {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <styles.TrCustom
                        {...{ ...headerGroup.getHeaderGroupProps() }}>
                        {
                            headerGroup.headers.map(column => {
                                if (column.id === 'col_0') {
                                    return <styles.styledThCol1 style={{ display: "flex" }} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <styles.StyledHeaderWrapper>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <GoTriangleUp />
                                                    : <GoTriangleDown />
                                                : null}
                                        </styles.StyledHeaderWrapper>
                                    </styles.styledThCol1>;
                                }
                                return (
                                    <styles.styledTh style={{ display: "flex" }} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <styles.StyledHeaderWrapper>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <GoTriangleUp />
                                                    : <GoTriangleDown />
                                                : null}
                                        </styles.StyledHeaderWrapper>
                                    </styles.styledTh>
                                );
                            }
                            )}
                    </styles.TrCustom>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    switch (props.selectedTab) {
                        case 0:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab0.some((element) => element.id === row.id) || selectedRowTab0.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_1 / highestNumber})` }} {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? currency+cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_2)}%
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_4)}%
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                        case 1:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab1.some((element) => element.id === row.id) || selectedRowTab1.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_1 / highestNumber})` }} {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? currency+cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_2)}%
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_4)}%
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                        case 2:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab2.some((element) => element.id === row.id) || selectedRowTab2.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_1 / highestNumber})` }} {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? currency+cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_2)}%
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_4)}%
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                        case 3:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab3.some((element) => element.id === row.id) || selectedRowTab3.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_1 / highestNumber})` }}{...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? currency+cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_2)}%
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {typeof cell?.value === "number" ? cell?.value?.toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }) : cell?.value}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_4)}%
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                    }
                    return;
                })}
            </tbody>
            <tfoot>
                {footerGroups.map(group => (
                    <styles.footerTr style={{ position: "sticky", bottom: 0 }} {...group.getFooterGroupProps()}>
                        {group.headers.map(column => {
                            if (column.id === "col_0") {
                                return (<styles.StyledTotal  {...column.getFooterProps()}>
                                    {t('GRAND_TOTAL', { ns: 'translation' })}</styles.StyledTotal>);
                            }
                            if (column.Header === 'Revenue') {
                                return (< styles.FooterTd  {...column.getFooterProps()}>{currency + (propsData.reduce((sum, row) => row.revenue + sum, 0)).toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</styles.FooterTd>);
                            }
                            if (column.id === 'col_2') {
                                return (< styles.FooterTd  {...column.getFooterProps()}>{column.render('Footer')}</styles.FooterTd>);
                            }
                            if (column.Header === 'Users') {
                                return (< styles.FooterTd  {...column.getFooterProps()}>{propsData.reduce((sum, row) => row.users + sum, 0).toLocaleString()}</styles.FooterTd>);
                            }
                            if (column.id === 'col_4') {
                                return (< styles.FooterTd  {...column.getFooterProps()}>{column.render('Footer')}</styles.FooterTd>);
                            }
                            return;
                        })}
                    </styles.footerTr>
                ))}
            </tfoot>

        </table >);
    };
    return (<>
        {tabButtonsContainer()}
        <styles.Styles>
            <styles.TableScroll>
                {renderTable()}
            </styles.TableScroll>
        </styles.Styles>
    </>);
};

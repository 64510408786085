import React, { useEffect, useState } from "react";
import { Column, Row, useExpanded, useTable, useSortBy } from "react-table";
import { styles } from "./styles";
import {BsThreeDotsVertical} from "react-icons/bs";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import {CarrierEnum, DepartmentsEnum, operationService} from "../../../../services/operations/operation.service";
import {
  getCarrierNameByEnum,
  getCarrierSvgByEnum,
} from "../../../../utils/carrier/helpers";
import {
  fetchOperationData,
  InventoryData,
  operationsStateSelector,
  TransportationStatusEnum
} from "../../../../store/operation";
import { groupDataByKey } from "../../../../utils/group-by/group-by";
import { GoTriangleDown } from "react-icons/go";
import { SearchInput } from "../../../../components/search-input/SearchInput";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import { DatepickerSmall } from "../../../../components/datepicker-small/DatepickerSmall";
import { useDispatch, useSelector } from "react-redux";
import { organizationSelector } from "@store";
import { dateRangeSelector } from "../../../../store/ui";
import { MoreInfoButton } from "../../../../components/more-info-button/MoreInfoButton";
import {DownloadButton, Field} from "../../../../components/download-button/DownloadButton";
import { removeTimezone } from "../../../../utils/remove-timezone/removeTimezone";
import {formatIsoDate} from "../../../../utils/date-format/dateFormat";
import {CheckboxMenuSmall} from "../../../../components/checkbox-menu-small/CheckboxMenuSmall";
import {dateSort} from "../../../../utils/sorting/sorting";
import axios from "axios";

interface Props {
  tableData?: InventoryData[];
  noData?: boolean;
  setInventoryQuery?: any;
  setDate?: any;
  chosenDate?: Date;
  countries?: string[];
  setCountries: (countries: string[]) => void;

  selectedProducts?: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>
}

/*
 Footer: (units: { rows: any[] }) => {
      const total = React.useMemo(
        () =>
          units.rows.reduce((sum: number, row) => {
            if (typeof row.values.quantity === "number") {
              return row.values.quantity + sum;
            } else {
              return 0 + sum;
            }
          }, 0),
        [units.rows]
      );

      return <>{total}</>;
    },
*/

const TableHeaders = [
  {
    Header: "Product SKU",
    accessor: "sku",
    Footer: <styles.TotalValueCell>Total</styles.TotalValueCell>,
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Warehouse",
    accessor: "warehouse",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    Footer: (units: { rows: any[] }) => {
      const total = React.useMemo(
        () =>
          units.rows.reduce((sum: number, row) => {
            if (typeof row.values.quantity === "number" && row.depth === 0) {
              return row.values.quantity + sum;
            } else {
              return 0 + sum;
            }
          }, 0),
        [units.rows]
      );

      return <>{total.toLocaleString("en-US")}</>;
    },
  },
  { Header: "Days of inventory left", accessor: "daysLeft" },
  { Header: "Avg. Units Sold per Day", accessor: "soldPerDay",  Footer: (units: { rows: any[] }) => {
      const total = React.useMemo(
          () =>
              units.rows.reduce((sum: number, row) => {
                if (typeof row.values.soldPerDay === "number" && row.depth === 0) {
                  return row.values.soldPerDay + sum;
                } else {
                  return 0 + sum;
                }
              }, 0),
          [units.rows]
      );

      return <>{total?.toLocaleString()}</>;
    },},
  {
    Header: "Expected Stockout Date",
    accessor: "stockoutDate",
    sortType: (rowA?: any,rowB?:any, columnId?: any ) => dateSort(rowA, rowB, columnId)
  },
  {
    Header: "Expected Restocking Date",
    accessor: "restockDate",
    sortType: (rowA?: any,rowB?:any, columnId?: any ) => dateSort(rowA, rowB, columnId)

  },
  { Header: "Expected Restocking Amount", accessor: "restockAmount" },
  /*{ Header: "", accessor: "options" },*/
];

interface TableDataInterface {
  sku?: string | number;
  warehouse?: string | number;
  quantity?: string | number;
  daysLeft?: string | number;
  soldPerDay?: string | number;
  stockoutDate?: string | number ;
  restockDate?: string | number;
  restockAmount?: string | number;
  /*options: any;*/
  subRows?: any[];
}

interface TableInputData {
  [key: string]: InventoryData[];
}

const InventoryOverviewTable = (props: Props) => {
  const organization = useSelector(organizationSelector);
  const dates = useSelector(dateRangeSelector);
  const operations = useSelector(operationsStateSelector);

  //country filter
  const [countriesOption, setCountriesOption] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);


  function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  const dataToDataTable = (
    data?: InventoryData[], downloadFormat?: boolean
  ): TableDataInterface[] | undefined => {
    if (props.noData) {
      const noData = {
        sku: "-",
        warehouse: "-",
        quantity: "",
        daysLeft: "-",
        soldPerDay: "-",
        stockoutDate: "-",
        restockDate: "-",
        restockAmount: "-",
        country: "-",
        description: "-",
      };

      return [noData, noData, noData, noData];
    }

    /* if (!!data) { // TODO do not delete code block
                           const newData = Object.keys(data).map((key) => {
                             const element = data[key];
                             let allUniqueWarehouses: string[] = [];
                             let totalQty = 0;
                             element.forEach((item) => {
                               if (!!item?.qty) {
                                 totalQty = totalQty + item?.qty;
                               }
                               if (
                                 !!item?.warehouseName &&
                                 !allUniqueWarehouses.includes(item?.warehouseName)
                               ) {
                                 allUniqueWarehouses.push(item?.warehouseName);
                               }
                             });

                             return {
                               sku: key,
                               warehouse: allUniqueWarehouses.join(" "),
                               quantity: totalQty,
                               daysLeft: "-",
                               soldPerDay: "-",
                               stockoutDate: "-",
                               restockDate: "-",
                               restockAmount: "-",
                               subRows: element.map((item) => {
                                 return {
                                   sku: item.sku,
                                   warehouse: item.warehouseName,
                                   quantity: item?.qty,
                                   daysLeft:
                                     item?.qty !== undefined && !!item?.meanPurchasedPerDay
                                       ? item?.qty / item?.meanPurchasedPerDay
                                       : "-",
                                   soldPerDay: !!item?.meanPurchasedPerDay
                                     ? item?.meanPurchasedPerDay
                                     : "-",
                                   stockoutDate:
                                     item?.qty !== undefined && !!item?.meanPurchasedPerDay
                                       ? addDays(new Date(), item?.qty / item?.meanPurchasedPerDay)
                                           .toISOString()
                                           .slice(0, 10)
                                       : "-",
                                   restockDate:
                                     item?.eta !== undefined && item.eta !== null ? item?.eta : "-",
                                   restockAmount:
                                     item?.totalAsnQuantity !== undefined &&
                                     item.totalAsnQuantity !== null
                                       ? item?.totalAsnQuantity
                                       : "-",
                                 };
                               }),
                             };
                           });
                           return newData;
                         } else {
                           return [{}];
                         }*/
    if (!!data) {
      const filteredData = data.filter((item) => {
          return (item?.qty !== undefined && !!item?.warehouseCountry && item?.qty > 0) &&
                selectedCountries.includes(item?.warehouseCountry)});

      const newData = filteredData.map((item) => {
        return {
          sku: item.sku,
          warehouse: item?.displayWarehouseName,
          quantity: item?.qty,
          description: !!item?.description ? item?.description : downloadFormat ? "" : "-",
          country: !!item?.warehouseCountry ? item?.warehouseCountry : downloadFormat ? "" : "-",
          daysLeft:
            item?.qty !== undefined && !!item?.meanPurchasedPerDay30
              ? Math.floor(item?.qty / item?.meanPurchasedPerDay30)
              : downloadFormat ? "" : "-",
          soldPerDay: !!item?.meanPurchasedPerDay30
            ? Math.floor(item?.meanPurchasedPerDay30)
            : downloadFormat ? "" : "-",
          stockoutDate:
            item?.qty !== undefined && !!item?.meanPurchasedPerDay30
              ? formatIsoDate(addDays(new Date(), item?.qty / item?.meanPurchasedPerDay30)
                    .toISOString()
                    .slice(0, 10))
              : downloadFormat ? "" : "-",
          restockDate:
            item?.restockingEta !== undefined && item?.restockingEta !== null
              ? formatIsoDate(item?.restockingEta.slice(0, 10))
              :downloadFormat ? "" : "-",
          restockAmount:
            item?.restockingAmount !== undefined &&
              item?.restockingAmount !== "-"
              ? item?.restockingAmount
              : downloadFormat ? "" : "-",
          subRows: [],
        };
      });
      return newData;
    } else {
      return [];
    }
  };

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          isAllRowsExpanded: any;
          getToggleAllRowsExpandedProps: any;
        }) => (
          <span /*{...getToggleAllRowsExpandedProps()}*/>
            {/*{isAllRowsExpanded ? "👇" : "👉"}*/}
          </span>
        ),
        Cell: ({ row }: { row: Row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? "👇" : "👉"}
            </span>
          ) : null,
      },
      ...TableHeaders,
    ],
    []
  );

  const  uniqByKeepFirst = (a:any, key: any) => {
    let seen = new Set();
    return a.filter((item: any) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  // @ts-ignore
  useEffect( async () => {
    /*const countriesArray : string[] = [];*/

    if (!!props?.tableData) {
      if (!!organization?.id) {

        const response = await operationService.fetchCountries(organization?.id).then((res) => {
          return res?.data
        })
        setCountriesOption(response);
        setSelectedCountries(response);

      }
      /*  props?.tableData?.forEach((row) => {
          if(!!row?.warehouseCountry){
            if(countriesArray?.includes(row?.warehouseCountry)){
              return
            }else {
              countriesArray?.push(row?.warehouseCountry)
            }
          }
        })*/

    }


  }, [props.tableData, organization?.id]);

  const data = React.useMemo(
    () => dataToDataTable(/*groupDataByKey("sku", */ props.tableData /*)*/),
    [props.tableData, selectedCountries]
  );

  const dateFormat = (date: Date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (removeTimezone(date).getDate()) + " " + monthNames[removeTimezone(date).getMonth()];
  };

  const handleSearchInventoryTable = (e: string) => { //TODO
    !!props?.setInventoryQuery && props?.setInventoryQuery(e)
  }
  const handleSearchByDate = (e: Date) => { // TODO
    !!props?.setDate && props.setDate(removeTimezone(e))
  }




const fields: Field[] = [
  { value: "sku", label: "Product SKU" },
  { value: "description", label: "Description" },
  { value: "warehouse", label: "Warehouse" },
  { value: "country", label: "Country" },
  { value: "quantity", label: "Quantity" },
  { value: "daysLeft", label: "Days of inventory left" },
  { value: "soldPerDay", label: "Avg. Units Sold per Day" },
  { value: "stockoutDate", label: "Expected Stockout Date" },
  { value: "restockDate", label: "Expected Restocking Date", isoFormat: true  },
  { value: "restockAmount", label: "Expected Restocking Amount" },
]

  const MenuListRender = () => {
    return (
      <MenuList>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </MenuList>
    );
  };

  const header = () => {
    return (
      <styles.HeaderWrapper>
        <Flex>
          <styles.Title>Inventory Overview
            <div style={{ alignSelf: 'start' }}><MoreInfoButton onClick={() => { return }} /></div>
          </styles.Title>
          <Box w={6} />
          <SearchInput
            placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"}
            onChange={(e) => { handleSearchInventoryTable(e) }}
            onSearch={() => { }}
          />
          <Box w={6}/>
          <CheckboxMenuSmall title={'Country'}
                        options={countriesOption}
                        setSelectedItems={setSelectedCountries}
                        defaultCheckItems={selectedCountries}/>
          <Box w={6}/>
          <CheckboxMenuSmall title={'Description'}
                             options={operations?.filters}
                             setSelectedItems={props?.setSelectedProducts}
                             defaultCheckItems={props?.selectedProducts}/>
        </Flex>
        <Flex>
          {<DownloadButton fileName={"inventory_overview"} fields={fields} data={dataToDataTable(props?.tableData, true)} onClick={() => { }} />}
          <Box w={3} />
          <styles.DatepickerSmallWrapper>
            <DatepickerSmall onApply={(e) => { handleSearchByDate(e) }} onChange={(e) => { handleSearchByDate(e) }} />
            <styles.DatepickerTitle>
              {!!props?.chosenDate ? dateFormat(props?.chosenDate) : ''}
            </styles.DatepickerTitle>
          </styles.DatepickerSmallWrapper>
          <Box w={25} />
        </Flex>

      </styles.HeaderWrapper>
    );
  };

  function Table({ columns: userColumns, data }: { columns: any; data: any }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      state: { expanded },
    } = useTable(
      {
        columns: userColumns,
        data,
        initialState: {
          sortBy: [
            {
              id: 'sku',
              desc: true
            }
          ]
        },
      },
      // Use the useExpanded plugin hook
      useSortBy,
      useExpanded
    );
    return (
      <>
        <styles.TableWrapper>

          <styles.StyledTable {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    if (column.id === "expander") {
                      return <></>;
                    }
                    return (
                      <styles.HeaderTh
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined })
                        )}
                      >
                        <Flex
                          textAlign={"center"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {column.render("Header")}
                          <Box w={1} />
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <IoIosArrowDown />
                              ) : (
                                <IoIosArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </Flex>
                      </styles.HeaderTh>
                    );
                  })}
                </styles.HeaderTr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <styles.TableTr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      if (cell.column.id === "expander") {
                        return <></>;
                      }
                      if (cell.column.id === "sku") {
                        return (
                          <styles.TableTd
                            {...cell.getCellProps()}
                            {...row.getToggleRowExpandedProps()}
                            title={""}
                          >
                            <styles.FlexTdCell>
                              {cell.render("Cell")}{" "}
                              {row.canExpand ? (
                                row.isExpanded ? (
                                  <IoIosArrowDown />
                                ) : (
                                  <IoIosArrowForward />
                                )
                              ) : (
                                <></>
                              )}
                            </styles.FlexTdCell>
                          </styles.TableTd>
                        );
                      }
                      if (cell.column.id === "warehouse" || cell?.column?.id === "description") {
                        return (
                          <styles.TableTd
                            {...cell.getCellProps()}
                            {...row.getToggleRowExpandedProps()}
                            title={""}
                          >
                            <Tooltip label={!!cell?.value ? cell?.value : ""}>
                              {!!cell?.value
                                ? cell?.value?.length > 45
                                  ? cell?.value.slice(0, 10) + "..."
                                  : cell?.value
                                : ""}
                            </Tooltip>
                          </styles.TableTd>
                        );
                      }
                      if (cell.column.id === "options") {
                        return (
                          <td {...cell.getCellProps()}>
                            <Menu placement="start-start">
                              {({ isOpen }) => (
                                <>
                                  <MenuButton padding={0}>
                                    <styles.ThreeDotHolder>
                                      <BsThreeDotsVertical />
                                    </styles.ThreeDotHolder>
                                  </MenuButton>
                                  <MenuListRender />
                                </>
                              )}
                            </Menu>
                          </td>
                        );
                      }
                      if (cell.column.id === "carrier") {
                        return (
                          <styles.FlexCell {...cell.getCellProps()}>
                            <styles.FlexDiv>
                              {getCarrierSvgByEnum(cell.value)}
                              {getCarrierNameByEnum(cell.value)}
                            </styles.FlexDiv>
                          </styles.FlexCell>
                        );
                      }
                      if (cell.column.id === "quantity") {
                        return (
                          <td
                            {...cell.getCellProps()}
                            {...row.getToggleRowExpandedProps()}
                            title={""}
                          >
                            {cell?.value.toLocaleString("en-US")}
                          </td>
                        );
                      }
                      if(cell?.column?.id ==="restockDate"){
                        const restockDateFormat = new Date(cell?.value);
                        const expectedRestock = new Date(row?.original?.stockoutDate);
                        const currentDate = new Date();
                        const isRestockDateAfterToday = restockDateFormat < currentDate || restockDateFormat < expectedRestock;
                        return (
                            <td
                                {...cell.getCellProps()}
                                {...row.getToggleRowExpandedProps()}
                                title={""}
                                style={{color: isRestockDateAfterToday ? "red" : ""}}
                            >
                              {cell.render("Cell")}
                            </td>
                        )
                      }
                      if(cell?.column?.Header ==="Expected Stockout Date"){
                        const expectedStockout = new Date(cell?.value);
                        const expectedRestock = new Date(row?.original?.restockDate);
                        const isRestockDateAfterToday = expectedRestock > expectedStockout;
                        return (
                            <td
                                {...cell.getCellProps()}
                                {...row.getToggleRowExpandedProps()}
                                title={""}
                                style={{color: isRestockDateAfterToday ? "red" : ""}}
                            >
                              {cell.render("Cell")}
                            </td>
                        )
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          {...row.getToggleRowExpandedProps()}
                          title={""}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </styles.TableTr>
                );
              })}
            </tbody>
          </styles.StyledTable>
        </styles.TableWrapper>

        <styles.TableFooterWrapper>

          {footerGroups.map((group: any, index: number) => {
            return (
              <styles.Footer key={index} {...group.getFooterGroupProps()}>
                <div style={{ width: '20px!important' }} />
                {group.headers.map((column: any) => {
                  if (column.id === "expander") {
                    return <></>;
                  } if (column.id === "options") {
                    return <Box width={"45%"} h={"100%"}>

                    </Box>
                  }
                  return (
                    <styles.StyledTotalFooter {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </styles.StyledTotalFooter>
                  );
                })}
                <div style={{ width: '20px!important' }} />

              </styles.Footer>

            );
          })}
        </styles.TableFooterWrapper>
      </>
    );
  }

  const renderFooter = () => {
    return (
      <styles.FooterWrapper>
        <div>{props?.tableData?.length} rows in total</div>
      </styles.FooterWrapper>
    );
  };

  return (
    <styles.PaddingAndTableWrapper>
      {header()}
      <styles.TablePadding />
      <styles.Wrapper>
        {!!data ? <Table columns={columns} data={data} /> : <Spinner />}
      </styles.Wrapper>
      {renderFooter()}
    </styles.PaddingAndTableWrapper>
  );
};

export default InventoryOverviewTable;

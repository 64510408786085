import { MenuItem } from "@chakra-ui/menu";
import {
  Button,
  Input,
  ModalContent,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const closeButton = styled.span`
    &:hover {
      cursor: pointer;
    }
  `;
  export const StyledModelContent = styled(ModalContent)`
    overflow-y: hidden;
  `;
  export const alertSubTitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    margin: 12px 0 12px 0;
    &:first-of-type {
      margin: 0 0 14px 0;
    }
  `;
  export const StyledMenuItem = styled(MenuItem)`
    &:hover {
      background-color: unset;
    }
  `;
  export const Spacer = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e2e5eb;
  `;
  export const SmallRow = styled.div`
    display: flex;
    width: 65%;
    justify-content: space-between;
  `;
  export const InputHeader = styled.div`
    color: #7a86a1;
    font-size: 18px;
    margin: 13px 0 8px 0;
    &:focus {
      // remove blue border from chakra
      box-shadow: ${(props) => props.theme.primaryColor} !important;
    }
  `;
  export const ButtonPopover = styled.div`
    border: 1px solid #e2e8f0;
    background-color: unset !important;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    min-width: 90px;
    white-space: nowrap;
    padding: 0 16px;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
  `;
  export const StyledPopoverContent = styled(PopoverContent)`
    width: max-content;
    overflow-y: auto;
    max-height: 200px;
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;
  export const option = styled.div`
    white-space: nowrap;
    width: 100%;
    padding: 5px 10px;
    text-overflow: ellipsis;
    border-bottom: 1px solid gray;
    &:first-of-type {
      padding: 5.5px 10px 5px;
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding: 5px 10px 5.5px;
      border-radius: 0 0 0.375rem 0.375rem;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 100px #e8e8e8 inset;
    }
  `;

  export const SelectRow = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const InputTitle = styled.div`
    font-size: 18px;
    color: #000000;
    margin: 15px 0 0 0;
  `;
  export const StyledSubmitButton = styled(Button)<{ blocked: boolean }>`
    margin-right: 3px;
    background-color: ${(props) =>
      props.blocked ? "#E8E8E8" : props.theme.primaryColor} !important;
    color: ${(props) => (props.blocked ? "black" : "white")} !important;
    &:hover {
      cursor: ${(props) => (props.blocked ? "auto" : "pointer")} !important;
    }
  `;
  export const NameInput = styled(Input)`
    &:focus {
      // remove blue border from chakra
      border-color: ${(props) => props.theme.primaryColor} !important;
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;
  export const StyledNumberInput = styled(NumberInput)`
    width: 86px;
  `;
  export const StyledNumberInputField = styled(NumberInputField)`
    &:focus {
      // remove blue border from chakra
      border-color: ${(props) => props.theme.primaryColor} !important;
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;

  export const StyledRadio = styled(Radio)`
    &[aria-checked=true], &[data-checked] {
      background: #7031EB !important;
      border-color: #7031EB !important;
    }

    &:focus {
    box-shadow: red !important;
}
    
  `; 
}

export default styles;

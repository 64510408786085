import styled from "styled-components";

export namespace styles {
    export const Wrapper = styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: start;
      align-self: center;
      flex-direction: column;
      padding: 20px;
      background-color: #e9e7fa;
      border-radius: 0 0 5px 5px;
    `;
    export const TopBarWrapper = styled.div`
      width: 90%;
      display: flex;
      justify-content: start;
      align-items: center;
    `;

    export const Title = styled.div`
      display: flex;
      font-size: 21px;
      font-weight: bold;
      border-color: ${(props) => props.theme.backgroundColor};
      font-family: ${(props) => props.theme.fonts.header};
      border-color: ${(props) => props.theme.backgroundColor};
    `

    export const Description = styled.div`
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 5px;
      border-color: ${props => props.theme.backgroundColor};
      display: flex;
    `

    export const ButtonsWrapper = styled.div`
      display: flex;
      flex-direction: row;
      margin-top: 35px;

    `
}

export default styles;

import {createSlice} from "@reduxjs/toolkit";
import {initialPnlState, Pnl, PnlState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {clearPnlState, resetMoveLoader} from "./actions";
import {
    applyDraftPnls,
    createPnl,
    deletePnl, fetchDraftPnlDataLanguage,
    fetchFields,
    fetchPnlDataLanguage,
    fetchPnlDataLanguageNames,
    fetchPnlsDisplayName, moveMetric
} from "./thunks";
import {Metric} from "../../models/pnl-language";

function buildCleanPnlStateReducer(
  builder: ActionReducerMapBuilder<PnlState>
) {
  builder.addCase(clearPnlState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildResetPnlMoveLoaderStateReducer(
  builder: ActionReducerMapBuilder<PnlState>
) {
  builder.addCase(resetMoveLoader, (state, action) => {
    state.isFinishedMoveLoad = false;
    return state;
  });
}

function buildFetchPnlDataReducer(
  builder: ActionReducerMapBuilder<PnlState>
) {
  builder
    .addCase(fetchPnlDataLanguage.pending, (state, action) => {
      state.data = undefined;
      return state;
    })
    .addCase(fetchPnlDataLanguage.fulfilled, (state, action) => {
      state.data = action.payload;
      return state;
    })
    .addCase(fetchPnlDataLanguage.rejected, (state, action) => {
      state.data = undefined;
      return state;
    });
}

function buildFetchDraftPnlDataReducer(
    builder: ActionReducerMapBuilder<PnlState>
) {
    builder
        .addCase(fetchDraftPnlDataLanguage.pending, (state, action) => {
            state.draftData = undefined;
            return state;
        })
        .addCase(fetchDraftPnlDataLanguage.fulfilled, (state, action) => {
            state.draftData = action.payload;
            return state;
        })
        .addCase(fetchDraftPnlDataLanguage.rejected, (state, action) => {
            state.draftData = undefined;
            return state;
        });
}

function buildFetchPnlNamesReducer(
    builder: ActionReducerMapBuilder<PnlState>
) {
  builder
      .addCase(fetchPnlDataLanguageNames.pending, (state, action) => {
        state.names = undefined;
        return state;
      })
      .addCase(fetchPnlDataLanguageNames.fulfilled, (state, action) => {
        state.names = action.payload;
        return state;
      })
      .addCase(fetchPnlDataLanguageNames.rejected, (state, action) => {
        state.names = undefined;
        return state;
      });
}

function buildFetchFields(
    builder: ActionReducerMapBuilder<PnlState>
) {
    builder
        .addCase(fetchFields.pending, (state, action) => {
            state.fields = undefined;
            return state;
        })
        .addCase(fetchFields.fulfilled, (state, action) => {
            state.fields = action.payload;
            return state;
        })
        .addCase(fetchFields.rejected, (state, action) => {
            state.fields = undefined;
            return state;
        });
}

function buildFetchPnlDisplayNamesReducer(
    builder: ActionReducerMapBuilder<PnlState>
) {
    builder
        .addCase(fetchPnlsDisplayName.pending, (state, action) => {
            state.displayNames = undefined;
            return state;
        })
        .addCase(fetchPnlsDisplayName.fulfilled, (state, action) => {
            state.displayNames = action.payload;
            return state;
        })
        .addCase(fetchPnlsDisplayName.rejected, (state, action) => {
            state.displayNames = undefined;
            return state;
        });
}





function buildCreateNewPnl(
    builder: ActionReducerMapBuilder<PnlState>
) {
  builder
      .addCase(createPnl.pending, (state, action) => {
        return state;
      })
      .addCase(createPnl.fulfilled, (state, action) => {
        if (!!state?.draftData?.data) {
          const deepCopy = JSON.parse(JSON.stringify(state.draftData.data));
          const newData = [...deepCopy, action?.payload];
          return {
            ...state,
            draftData: {
              ...state.draftData,
              data: newData
            }
          };
        }
        return state;
      })
      .addCase(createPnl.rejected, (state, action) => {
        return state;
      });
}

function buildDeletePnl(
    builder: ActionReducerMapBuilder<PnlState>
) {
  builder
      .addCase(deletePnl.pending, (state, action) => {
        return state;
      })
      .addCase(deletePnl.fulfilled, (state, action) => {
        if (!!state?.draftData?.data) {
          const idToRemove = action?.payload?._id;
          const deepCopy = JSON.parse(JSON.stringify(state.draftData.data));
          const newData = deepCopy?.filter((pnl: Pnl) => pnl?.id !== idToRemove);
          const fieldNameToRemove = action?.payload?.fieldName;

          const filteredData = newData?.map((metric: Metric) => metric?.expression?.map((expression) => {

          }))

          return {
            ...state,
            draftData: {
              ...state.draftData,
              data: newData
            }
          };
        }
        return state;
      })
      .addCase(deletePnl.rejected, (state, action) => {
        return state;
      });
}

function buildMovePnl(
    builder: ActionReducerMapBuilder<PnlState>
) {
    builder
        .addCase(moveMetric.pending, (state, action) => {
            return state;
        })
        .addCase(moveMetric.fulfilled, (state, action) => {
            if(!!state?.draftData?.data){
                const deepCopy = JSON.parse(JSON.stringify(state.draftData.data));
                const formattedData = deepCopy?.map((metric : Metric) => {
                    if(action?.payload?.some((subMetric: Metric) => metric?.fieldName === subMetric?.fieldName)){
                        const foundMetric = action?.payload?.find((subMetric: Metric) => metric?.fieldName === subMetric?.fieldName);
                        return foundMetric
                    }
                    return metric
                });
                state.draftData.data = formattedData
            }

            return state;
        })
        .addCase(moveMetric.rejected, (state, action) => {
            return state;
        })

}

function buildApplyPnl(
    builder: ActionReducerMapBuilder<PnlState>
) {
    builder
        .addCase(applyDraftPnls.pending, (state, action) => {
            state.isFinishedMoveLoad = false;
            return state;
        })
        .addCase(applyDraftPnls.fulfilled, (state, action) => {
            state.isFinishedMoveLoad = true;
            return state;
        })
        .addCase(applyDraftPnls.rejected, (state, action) => {
            state.isFinishedMoveLoad = true;
            return state;
        })

}




export const pnlSlice = createSlice({
  name: "pnl",
  initialState: initialPnlState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanPnlStateReducer(builder);
    // async

    buildCreateNewPnl(builder);

    buildFetchPnlDataReducer(builder);

    buildFetchPnlNamesReducer(builder);

    buildDeletePnl(builder)

    buildFetchPnlDisplayNamesReducer(builder);

    buildFetchFields(builder);

    buildMovePnl(builder);

    buildResetPnlMoveLoaderStateReducer(builder)

    buildFetchDraftPnlDataReducer(builder);

    buildApplyPnl(builder);

  },
});

// reducer
export const pnlReducer = pnlSlice.reducer;

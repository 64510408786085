import styled from "styled-components";
import {PopoverBody} from "@chakra-ui/react";

export namespace styles {
    export const Wrapper = styled.div`
    width: 100%;
      height: 55px;
      overflow: hidden;
      display: flex;
    justify-content: flex-end;
    margin: 0 auto;
      padding: 20px 0;
  `;
    export const LeftButton = styled.button`
      width: 67px;
      /*background-color: #d3dcf3;*/
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: ${props => props?.theme?.fonts?.roboto?.regular};
      color: #56627C;
      padding: 10px 0;
      align-self: center;
      border-radius: 5px;
    `;
    export const CompWrapper = styled.div`
    width: 100%;
      display: flex;
      flex-direction: column;
      
    `
    export const GanttWrapper = styled.div`
    width: 100%;
    background-color: #CBC7E4;
      display: flex;
      align-items: center;
      border-radius: 20px;
      overflow: visible;
      height: 100%;
    `
    export const StyledPopoverBody = styled(PopoverBody)`

  `
    export const PopoverWrapper = styled.div`
  display: flex;
    flex-direction: column;
    width: 100%;
  `
    export const MonthGantt = styled.div`
    width: 100%;
    background-color: #CBC7E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    overflow: visible;
  `

    export const GanttItem = styled.div<{width: number}>`
    /*flex: 1 1 0;*/
    background-color: #1A0989;
    color: white;

    border-radius: 50%;
    min-height: 100%;
    width: ${props => `${props.width}px`};
    max-width: 30px;
    max-height: 30px;
    /*border-radius: 50%;*/
    transform: scale(1, 1);
/*transform: scale(1.1);*/
    height: ${props => `${props.width}px`};
    /*min-height: 100%;*/
    aspect-ratio: 1;
    position: relative;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.roboto.regular};
    /*border-radius: 50%;*/
    text-align: center;
    display: flex;
    justify-self: center;
    align-items: center;
  `
    export const GanttLabelsWrapper = styled.div`
    width: 100%;
    height: 10%;
      /*overflow: hidden;*/
    background-color: #CBC7E4;
    /*display: flex;
    align-items: center;
    border-radius: 20px;
    overflow: visible;
    height: 100%;
    justify-content: space-between;*/
  `;
    export const GanttSubWrapper = styled.div<{width: number}>`
      width: ${props => props?.width+"px"};
      /*background-color: #CBC7E4;*/
      display: flex;
      align-items: center;
      border-radius: 20px;
      height: 100%;
      justify-content: space-between;
    `;

    export const GanttItemWrapper = styled.div`
      width: 20px;
      height: 20px;
      border-radius: 15px;
      /*background-color: #1A0989;*/
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75vw;
      position: relative;
    `
  export const AbsElement = styled.div<{width: number}>`
    width: ${props => props?.width+"px"};
    height: 20px;
    border-radius: 15px;
    background-color: #1A0989;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75vw;
    position: absolute;
    left: 0;
  `
  export const TextWrapper = styled.div`
  position: relative;
    width: 100%;
    height: 100%;

  `
  export const AbsText = styled.div`
  position: absolute;
    left: 24px;
    top: 2px;
    color: #1A0989;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
    z-index: 3;
  ;
  `
  export const AbsBackground = styled.div`
  position: absolute;
   background-color: white;
    width: 90px;
    height: 100%;
    left: -5px;
    border-radius: 10px;
  ;
  `
    export const GanttItemWrapperPlaceholder = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /*background-color: red;*/
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75vw;
      position: relative;
  `;
    export const AbsGanttElementPlaceholder = styled.div<{width: number;}>`
      position: absolute;
      height: 20%;
      /*background-color: #1A0989;
      width: ${props => `${props.width}px`};*/

    `

    export const EmptyGanttItem = styled.div<{width: number}>`
    color: white;

    border-radius: 50%;

    width: ${props => `${props.width}px`};
    max-width: 30px;
    max-height: 30px;
    /*border-radius: 50%;*/
    transform: scale(1, 1);
    /*transform: scale(1.1);*/
    height: ${props => `${props.width}px`};
    min-height: 100%;
    aspect-ratio: 1;
    position: relative;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.roboto.regular};
    /*border-radius: 50%;*/
    text-align: center;
    display: flex;
    justify-self: center;
    align-items: center;
  `
    export const GanttText = styled.div`
  width: 100%;
  `
    export const GanttItemPlaceholder = styled.div<{width: number}>`
    /*
    flex: 1 1 0;
    */
    width: ${props => `${props.width}px`};
    border-radius: 50%;
  `

}

export default styles;

import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {styles} from './styles';
import {GroupBy} from "../../../features/workspace/Workspace";
import {getDatesInRange} from "../../../utils/colors";
import {Tooltip} from "@chakra-ui/react";

interface Props {
    data: any;
    displayLabels: string[];
    allLabels: string[];
    groupByStatus: GroupBy;
}


const SubGantt = (props: Props) => {
    const ganttRef = useRef<any>(null);
    const [width, setWidth] = useState(0)
    const [uniqueData, setUniqueData] = useState<any[]>([]);



    useEffect(() => {
        if (!!props?.data) {
            const uniqueIds: any[] = [];
            const unique = props?.data.filter((element: any) => {
                const isDuplicate = uniqueIds?.includes(element?.id);

                if (!isDuplicate) {
                    uniqueIds.push(element?.id);
                    return true;
                }
                return false;
            });
            setUniqueData(unique?.filter((item: any) => !!item));
        }
    }, [props?.data])

    useLayoutEffect(() => {
        setWidth(ganttRef?.current?.offsetWidth);
    }, []);


    const renderDot = (label: string, i: number, lastIndex: number) => {
        let renderDotFlag = false;
        let widthOfHoliday = 20;
        let foundElementToRender;
        let widthOfPlaceholder = 20;
        const widthOfDay = width / props?.allLabels?.length;
        if (props?.groupByStatus === GroupBy.WEEK) {
            if (i === props?.displayLabels?.length - 1) {  // last label
                const newLabel = label?.slice(0, 5) + "-" + "20" + label?.slice(12);
                const formatedLabel = newLabel.replace(/\./g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
                const lastDay = props?.allLabels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(formatedLabel), new Date(lastDay));
                    if (i !== 0 && i !== lastIndex) {
                        rangeOfDatesBetweenPoints?.shift()
                    }
                    const foundElement = uniqueData?.find((item: any) => rangeOfDatesBetweenPoints?.includes(item?.firstDate?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1")));
                    foundElementToRender = foundElement;
                    if (!!foundElement) {
                        renderDotFlag = true
                    }
                }

            } else {
                let year = "20" + label?.slice(12);
                const newLabel = label?.slice(0, 5) + "-" + year;
                const formatedLabel = newLabel.replace(/\./g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
                const nextDate = props?.displayLabels[i + 1].slice(0, 5).replace(/\./g, '-') + "-" + "20" + props?.displayLabels[i + 1]?.slice(12);
                const nextDateFormat = nextDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
                if(new Date(formatedLabel) > new Date(nextDate)){
                    year = (parseInt(year) -1).toString();
                }
                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(year + formatedLabel?.slice(4)), new Date(nextDateFormat));
                rangeOfDatesBetweenPoints?.pop();

                const foundElement = uniqueData?.find((item: any) => rangeOfDatesBetweenPoints?.includes(item?.firstDate?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1")));
                foundElementToRender = foundElement;
                if (!!foundElement) {
                    renderDotFlag = true;
                }
            }
        }
        if (props?.groupByStatus === GroupBy.DAY) {

            if (i === props?.displayLabels?.length - 1) {  // last label
                const lastDay = props?.allLabels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(lastDay));
                    if (i !== lastIndex) {
                        rangeOfDatesBetweenPoints?.shift()
                    }

                    const foundElement = uniqueData?.find((element: any) => rangeOfDatesBetweenPoints?.includes(element?.startDate));
                    foundElementToRender = foundElement;
                    if (!!foundElement) {
                        renderDotFlag = true
                    }
                }
            } else {
                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(props?.displayLabels[i + 1]));
                if (i !== lastIndex && i !== 0) {
                    /*rangeOfDatesBetweenPoints?.shift()*/
                }
                rangeOfDatesBetweenPoints?.pop();
                const foundElement = uniqueData?.find((element: any) => rangeOfDatesBetweenPoints?.includes(element?.startDate));
                foundElementToRender = foundElement;
                if (!!foundElement) {
                    renderDotFlag = true
                }
            }
        }

        if (props?.groupByStatus === GroupBy.MONTH) {
            if (i === props?.displayLabels?.length - 1) {  // last label
                const lastDay = props?.allLabels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(lastDay));
                    const foundElement = uniqueData?.filter((item: any) => !!item).find((item: any) => rangeOfDatesBetweenPoints?.includes(item?.startDate));
                    foundElementToRender = foundElement;
                    if (!!foundElement) {
                        renderDotFlag = true
                    }
                }
            } else {

                const nextDate = props?.displayLabels[i + 1];
                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(nextDate));
                if (i === 0) {
                    rangeOfDatesBetweenPoints.pop();
                }

                const foundElement = uniqueData?.find((item: any) => rangeOfDatesBetweenPoints?.includes(item?.startDate));
                foundElementToRender = foundElement;
                if (!!foundElement) {
                    renderDotFlag = true
                }
            }
        }

        return (<>
                {renderDotFlag ? <styles.GanttItemWrapper key={i}>
                        <styles.AbsBackground/>

                        <styles.AbsElement width={widthOfHoliday}>

                            <styles.TextWrapper>
                                <styles.AbsText>

                                    <Tooltip label={foundElementToRender?.holiday}>
                                        {foundElementToRender?.holiday}
                                    </Tooltip>

                                </styles.AbsText>

                            </styles.TextWrapper>
                        </styles.AbsElement>
                    </styles.GanttItemWrapper> :
                    <styles.GanttItemWrapperPlaceholder key={i}>
                        <styles.AbsGanttElementPlaceholder width={widthOfPlaceholder}/>
                    </styles.GanttItemWrapperPlaceholder>}
            </>
        )
    }


    const renderLabels = () => {
        const lastPoint = props?.displayLabels?.at(-1);
        let missingDaysWidth = 0;
        if (!!lastPoint && props?.allLabels?.indexOf(lastPoint) > -1) {
            const index = props?.allLabels?.indexOf(lastPoint);
            const missingDays = props?.allLabels?.length - index - 1
            missingDaysWidth = ((width) / props?.allLabels?.length) * missingDays;
        }
        return (
            <styles.GanttLabelsWrapper ref={ganttRef}>
                <styles.GanttSubWrapper width={width - missingDaysWidth}>
                    {props?.displayLabels?.map((item, i) => renderDot(item, i, props?.displayLabels?.length - 1))}
                </styles.GanttSubWrapper>
            </styles.GanttLabelsWrapper>
        )
    }


    return <styles.CompWrapper>
        <styles.Wrapper>
            <styles.LeftButton/>
            {renderLabels()}
        </styles.Wrapper>
    </styles.CompWrapper>;
};

export default SubGantt;

// state

interface User {
  firstName: string;
  lastName: string;
  id: string;
  workspaces: {
    id?: string;
    organization?: string;
    assignedKpis?: number[];
  }[];
  image?: string
}

export interface UsersState {
  data?: User[];
}

// initial state
export const initalUserState: UsersState = {
  data: undefined,
};

import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import {CogsMetaData, Product} from "models/products";
import React from "react";

export interface CogsData {
  name: string;
  isPercentage: boolean;
  fromFob: boolean;
  value: number;
}

export interface UpdateCellType {
  productCogsId?: string;
  value?: number;
  productId?: string;
}

export interface CreateProductCogsType {
  value: number;
  cogsId: string;
  productId: string;
}

export interface UpdateProductCogsType {
  value: number;
  productCogsId: string;
}

const fetchProductsData = async ({
  organizationId,
  page,
  size,
  query,
  sort,
    channels
}: {
  organizationId: string;
  page: number;
  size: number;
  query?: string;
  sort?: string[],
    channels?: string[]
}): Promise<AxiosResponse<Product[]>> => {
    let sortQuery =''
    if(!!sort){
        sortQuery = `&sort=${sort}`
    }
    let channelsQuery = '';
    if (Array.isArray(channels)) {
        channelsQuery = channels.map(channel => `&channels=${encodeURIComponent(channel)}`).join('');
    }
  return axios.get<Product[]>(
    `${apiBaseUrl}/organization/${organizationId}/product?page=${page}&size=${size}${query ? `&query=${query}` : ""}${sortQuery}${channelsQuery}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const uploadProductCsvFile = (
    organizationId: string,
    data: File[],
    setProgress?: React.Dispatch<React.SetStateAction<number>>
) => {
    const formData = new FormData();
    formData.append('file', data[0]); // assumes only one file is selected

    return axios.post(
        `${apiBaseUrl}/organization/${organizationId}/product/upload`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            onUploadProgress: (progressEvent) => {
                if (setProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progress);
                }
            }
        }
    );
};


const fetchProductsFields = async ({organizationId,}: { organizationId: string; }): Promise<AxiosResponse<any>> => {
  return axios.get<Product[]>(
      `${apiBaseUrl}/organization/${organizationId}/product/names`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const fetchCogsMetaData = async ({organizationId}: { organizationId: string; }): Promise<AxiosResponse<CogsMetaData[]>> => {
  return axios.get<CogsMetaData[]>(
      `${apiBaseUrl}/organization/${organizationId}/product/meta-data`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};

const updateProductsData = async (
  organizationId: string,
  products: any[]
): Promise<AxiosResponse<any[]>> => {
  return axios.put<any[]>(
    `${apiBaseUrl}/organization/${organizationId}/product`,
    products,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const createProductCogs = async (
    organizationId: string,
    products: CreateProductCogsType[]
): Promise<AxiosResponse<CreateProductCogsType[]>> => {
  return axios.post<CreateProductCogsType[]>(
      `${apiBaseUrl}/organization/${organizationId}/product/product-cogs`,
      products,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};
const updateProductCogs = async (
    organizationId: string,
    products: UpdateProductCogsType[]
): Promise<AxiosResponse<UpdateProductCogsType[]>> => {
  return axios.put<UpdateProductCogsType[]>(
      `${apiBaseUrl}/organization/${organizationId}/product/product-cogs`,
      products,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};
const updateCellProduct = async (
    organizationId: string,
    products: UpdateCellType[]
): Promise<AxiosResponse<Product[]>> => {
  return axios.put<Product[]>(
      `${apiBaseUrl}/organization/${organizationId}/product`,
      products,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  );
};
const addCogs = async (
  organizationId: string,
  cogs: CogsData
): Promise<AxiosResponse<Product[]>> => {
  return axios.post<Product[]>(
    `${apiBaseUrl}/organization/${organizationId}/product/cogs`,
    cogs,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deleteCogs = async (
  organizationId: string,
  cogsName: string
): Promise<AxiosResponse<any>> => {
  return axios.delete<any>(
    `${apiBaseUrl}/organization/${organizationId}/product/cogs/${cogsName}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const editCogs = async (
  organizationId: string,
  cogsName: string,
  cogs: CogsData
): Promise<AxiosResponse<any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/product/cogs/${cogsName}`,
    cogs,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const productsService = {
  fetchProductsData,
  updateProductsData,
  addCogs,
  deleteCogs,
  editCogs,
  fetchCogsMetaData,
  updateCellProduct,
  createProductCogs,
  updateProductCogs,
  fetchProductsFields,
    uploadProductCsvFile
};

// state


import {IntegrationStatus} from "@models";

export enum IntegrationType {
  STORES,
  MARKETING,
  WEBSITE,
  CRM,
  TEAM_MANAGEMENT,
  BENCHMARKETING,
}
export enum IntegrationPlatform {
  AMAZON_ADS,
  AMAZON_SELLER,
  FACEBOOK_ADS,
  GOOGLE_ADS,
  GOOGLE_ANALYTICS,
  HUBSPOT,
  JUNGLE_SCOUT,
  KLAVIYO,
  MAGENTO,
  MONDAY,
  NOTION,
  SHOPIFY,
  SIMILARWEB,
  TIKTOK,
  WOOCOMMERCE,
}

export interface AccountIntegrations {
  platform: IntegrationPlatform;
  name: string;
  type: IntegrationType;
  status: IntegrationStatus;
  countryCode?: string;
  currencyCode?: string;
}

export interface AccountLanguageData {
  generalData: {
    timezone: string;
    currency: string;
  };
}

export interface AccountState {
  data?: AccountIntegrations[];
  languageData: AccountLanguageData | undefined;
  loading: boolean;
}

// initial state
export const initalAccountState: AccountState = {
  data: undefined,
  languageData: undefined,
  loading: false,
};

import { Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import styles from './styles';
import { MarketingKpi } from '@models';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

export const noMediaImagePlaceholder = `${process.env.PUBLIC_URL}/assets/icons/no_media_no_image.svg`;

interface Props {
    ads: MarketingKpi[];
    loading?: boolean;
}


export const Ads = (props: Props) => {
    //state
    const [adsGroup, setAdsGroup] = useState<MarketingKpi[]>([]);
    const { t } = useTranslation(['translation', 'translation']);

    useEffect(() => {
        setAdsGroup(props.ads)
    }, [props.ads]);


    if (props.loading) {
        return <h2>Loading ...</h2>
    }

    //helper
    const isPropertyName = (key: string): boolean => {
        return key === "roas"
            || key === "clicks"
            || key === "impressions"
            || key === "revenue"
            || key === "purchases"
            || key === "cpc"
            || key === "spend"
            || key === "cpm";
    }

    const toFixNumber = (calculate: number) => {
        if(!!calculate){
            return calculate.toFixed(2)
        }
        else {
            return 0
        }
    };

    const filterCalculate = (itemAd: MarketingKpi): string[] => {
        const filterNumberArray = Object.keys(itemAd).filter(isPropertyName);

        if (!!itemAd?.spend && !!itemAd?.clicks) {
            itemAd['cpc'] = (itemAd?.spend / +itemAd?.clicks);
        }

        if (!!itemAd?.revenue && !!itemAd?.spend) {
            itemAd["roas"] = (itemAd.revenue / itemAd.spend);
        }

        if (!!itemAd?.clicks && !!itemAd?.impressions) {
            itemAd["cpm"] = (+itemAd?.clicks / +itemAd?.impressions) * 1000;
        }

        return filterNumberArray;
    }

    const mediaVideoQueryWords = ["dailymotion", "youtube", "youtu", "vimeo", "soundcloud", "mixcloud", "wistia", "vidyard", "twitch", "mp4"]

    const isIncludeMediaVideo = (urlMedia: string): boolean => {
        return mediaVideoQueryWords?.some((mediaChannel) => {
            //console.log("mediaChannel", mediaChannel);
            return urlMedia.match(new RegExp(mediaChannel, "i"));
        });
    }

    //render
    const renderCard = (itemAd: MarketingKpi, keyIndex: number) => {
        return (
            <styles.Card key={keyIndex}>
                {/*{

                    !!itemAd?.imageUrl ? isIncludeMediaVideo(itemAd?.imageUrl) ?
                        <styles.Video>
                            <ReactPlayer

                                url={itemAd?.imageUrl}
                                width={'100%'} height={'100%'}
                                controls
                                preload={'auto'}
                                autoplay
                                playIcon={
                                    <div
                                        className="play-icon"
                                        role="button"
                                        tabIndex={0}
                                        style={{ outline: "none" }}>
                                        {" "}
                                        <styles.PlayCircle />
                                    </div>
                                }
                            />
                        </styles.Video> :
                            <styles.NoMediaPlaceholderContainer>
                                <styles.NoMediaPlaceholder
                                    src={itemAd?.imageUrl}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src=noMediaImagePlaceholder
                                    }}
                                />
                            </styles.NoMediaPlaceholderContainer>
                        :
                        <styles.NoMediaPlaceholderContainer>
                            <styles.NoMediaPlaceholder
                                src={noMediaImagePlaceholder}
                            />
                        </styles.NoMediaPlaceholderContainer>

                }*/}
                <styles.Description>
                    <Tooltip placement="bottom"
                        label={itemAd.campaignName}
                        aria-label='A tooltip wide views'>
                        <styles.Text> {itemAd.campaignName}</styles.Text>
                    </Tooltip>
                </styles.Description>
                <styles.HashtagAd>{`${t('ADS', { ns: 'translation' })} #`}</styles.HashtagAd>
                <styles.ObjectAdProperty>
                    {
                        filterCalculate(itemAd)
                            .map((item, i) => (
                                <styles.AdPropertyItem key={i}>
                                    <styles.SpanAdPropertyItem>{item}</styles.SpanAdPropertyItem>
                                    <styles.SpanAdPropertyItem>{toFixNumber(+itemAd[item])}</styles.SpanAdPropertyItem>
                                </styles.AdPropertyItem>
                            ))
                    }
                </styles.ObjectAdProperty>
            </styles.Card>)
    }


    return (<styles.Container>{adsGroup.map((item, i) => renderCard(item, i))}</styles.Container>);
}

import accountMenuStyles from "./styles";
import { Divider, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  organizationSelector,
  switchOrganization,
  userSelector,
} from "@store";
import { BiCheck, BiDotsHorizontalRounded } from "react-icons/bi";
import { ExploresEnum, openDefaultExploreView } from "store/ui";
import {useEffect, useState} from "react";

export interface WorkspaceSession {
  email?: string;
  organizationLogo?: string;
  organizationName?: string;
  organizationId?: string;
}

interface Props {
}

export const AccountMenu = (props: Props) => {
  const currentUser = useSelector(userSelector);
  const currentOrganization = useSelector(organizationSelector);
  const dispatch = useDispatch();
  const [sessions, setSessions] = useState<WorkspaceSession[]>([])

  // hooks

  useEffect(() => {
    if(!!currentUser?.id && !!currentUser?.workspaces){
      const currentSessions = currentUser?.workspaces?.map((item: any, i: number) => {
        console.log(item)
        return {
          organizationName: item?.name,
          email: currentUser?.email,
          organizationId: item?.organization
        } as WorkspaceSession;
      });
      setSessions(currentSessions)

    }
  }, [currentUser?.id, currentUser?.workspaces])



  // helpers
  const addAnotherAccount = () => {};

  const profileSettings = () => {
    dispatch(
      openDefaultExploreView({
        isOpen: true,
        ExploresEnum: ExploresEnum.PROFILE,
      })
    );
  };

  const logoutClicked = () => {
    dispatch(logout({}));
  };

  const handleSwitchOrganization = (organizationId: any) => {
    dispatch(switchOrganization({ organizationId: organizationId }));
  };

  // renderers
  const renderUserSessions = () => {
    return (
      <accountMenuStyles.UserSessionsWrapper>
        <accountMenuStyles.UserSessionsScroll>
          {sessions?.map((userSession, index) => {
            return renderUserSession(userSession, index);
          })}
        </accountMenuStyles.UserSessionsScroll>
      </accountMenuStyles.UserSessionsWrapper>
    );
  };

  const renderLogo = (userSession: WorkspaceSession) => {
    if (!!userSession?.organizationLogo) {
      return (
        <accountMenuStyles.UserSessionLogo>
          userSession?.organizationLogo
        </accountMenuStyles.UserSessionLogo>
      );
    } else {
      return (
        <accountMenuStyles.UserSessionLogoLetter>
          {userSession?.organizationName &&
          userSession?.organizationName.length > 0
            ? userSession?.organizationName[0].toLocaleUpperCase()
            : "W"}
        </accountMenuStyles.UserSessionLogoLetter>
      );
    }
  };

  const renderUserSession = (userSession: WorkspaceSession, index: number) => {
    return (
      <accountMenuStyles.UserSessionWrapper
        onClick={() => {
          handleSwitchOrganization(userSession?.organizationId);
        }}
        key={index}
      >
        <accountMenuStyles.UserSessionFirstRow>
          <accountMenuStyles.UserSessionEmail>
            {userSession?.email}
          </accountMenuStyles.UserSessionEmail>
          <accountMenuStyles.UserSessionDotsButton
            as={BiDotsHorizontalRounded}
          />
        </accountMenuStyles.UserSessionFirstRow>

        <Flex direction={"row"} justifyContent={"space-between"}>
          <Flex direction={"row"}>
            <accountMenuStyles.UserSessionLogoWrapper>
              {renderLogo(userSession)}
            </accountMenuStyles.UserSessionLogoWrapper>
            <accountMenuStyles.UserSessionName>
              {/* {userSession?.organizationId} */}
              {userSession?.organizationName}
            </accountMenuStyles.UserSessionName>
          </Flex>
          {renderUserCheckmarkIcon(userSession?.organizationId)}
        </Flex>

        {/*{renderOrganization(userSession?.workspaces![0]?.organization!)}*/}

        <Divider />
      </accountMenuStyles.UserSessionWrapper>
    );
  };

  // const renderOrganization = (organization: Organization) => {
  //     return (
  //         <accountMenuStyles.OrganizationWrapper>
  //             <accountMenuStyles.OrganizationAvatar name={organization?.name} size={'sm'}/>
  //             <accountMenuStyles.OrganizationName>{organization?.name}</accountMenuStyles.OrganizationName>
  //         </accountMenuStyles.OrganizationWrapper>
  //
  //     )
  // }

  const renderUserCheckmarkIcon = (id: any) => {
    if (id === currentOrganization?.id) {
      return <accountMenuStyles.UserSessionMenuButton as={BiCheck} />;
    } else {
      return false;
    }
  };

  const renderButton = (title: string, callback: () => void) => {
    return (
      <accountMenuStyles.BottomButton onClick={() => callback()}>
        {title}
      </accountMenuStyles.BottomButton>
    );
  };

  const renderAddAnotherAccount = () => {
    return renderButton("+ Add another account", addAnotherAccount);
  };

  const renderProfileSettings = () => {
    return renderButton("Profile Settings", profileSettings);
  };

  const renderLogout = () => {
    return renderButton("Log out", logoutClicked);
  };

  return (
    <accountMenuStyles.Wrappers>
      {renderUserSessions()}
      {/* {renderAddAnotherAccount()} //TODO .V2 */}
      <Divider />
      {renderProfileSettings()}
      <Divider />
      {renderLogout()}
    </accountMenuStyles.Wrappers>
  );
};

import styled from "styled-components";

export namespace styles {
  export const WideviewToastWrapper = styled.div<{ success?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 420px;
    border-radius: 12px;
    height: 63px;
  `;
  export const WideviewToast = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `;

  export const ToastIconAndText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
  export const ToastIcon = styled.div`
    margin: 0 10px;
    //border-radius: 50%;
    //background-color: #1AD598;
    //padding: 2px;
  `;
  export const ToastText = styled.div`
    font-size: 18px;
    margin: 0 10px;
  `;
  export const CloseButton = styled.div`
    display: flex;
    justify-self: end;
    margin: 0 10px;
    &:hover{
      cursor: pointer;
    }
  `;

  export const ProgressBarWrapper = styled.div`
    //background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    //border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 1px;
    display: flex;
    height: 10px;
    width: 100%;
    bottom: 0;
  `;
  export const ProgressBar = styled.div<{ color?: string, duration: number }>`
    animation: ${props => `load ${props?.duration}s normal forwards`};
    background: ${props => props.color};
    height: 10px;
    border-radius: 0px 0px 0px 32px;
    width: 0;
    @keyframes load {
      0% { width: 0; border-radius:  0px 0px 0px 32px}
      80% { border-radius:  0px 0px 0px 32px}
      100% { width: 100%; border-radius:  0px 0px 32px 32px}
    }
  `;


}
export default styles;

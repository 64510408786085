import {Box, Flex, Spinner, useTheme} from '@chakra-ui/react';
import { organizationSelector, organizationDataLanguageSelector } from '@store';
import { DownloadButton } from 'components/download-button/DownloadButton';
import { t } from 'i18next';
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { Cell, Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';
import { getColorByPercentage } from 'utils/colors';
import { currencyOptions } from 'utils/currency/currencyOptions';
import { sumData } from 'utils/get-months/getLastYear';
import { IconClearFiltersArrow } from 'utils/icons/explore';
import { currencyFormat } from 'utils/kpi-render/kpiCalculation';
import { styles } from './styles';
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";

interface Props {
    data: any[];
    headers: string[];
    icon: any;
    title: string;
    setSelectedRows: any;
    isLoading?: boolean;

}

interface Data {
    col_0: string;
    col_1: number;
    col_2: number;
    col_3: number;
    allChannels?: string[]
}

enum SelectedTab {
    PLATFORM,
    ACCOUNT,
    CAMPAIGN,
    AD_SET,
    ADS
}

interface TableData {
    spend: number;
    key: string;
    target?: number;
    allWvChannels?: string[];
}




export const SourcesTable = (props: Props) => {
    const [currentPropsData, setCurrentPropsData] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.PLATFORM);
    const [tableData, setTableData] = useState<any>([]);
    const [totalSpend, setTotalSpend] = useState<number>(0);

    const [initalTab, setInitalTab] = useState<SelectedTab>();



    const [currentCurrency, setCurrentCurrency] = useState<string>("$");
    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }

    }, [dataLanguage]);


    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const [selectedRowTab1, setSelectedRowTab1] = useState([] as Row<Data>[]);
    const [selectedRowTab2, setSelectedRowTab2] = useState([] as Row<Data>[]);
    const [selectedRowTab3, setSelectedRowTab3] = useState([] as Row<Data>[]);
    const [selectedRowTab4, setSelectedRowTab4] = useState([] as Row<Data>[]);

    const currentRow = {
        [SelectedTab.PLATFORM]: selectedRowTab0,
        [SelectedTab.ACCOUNT]: selectedRowTab1,
        [SelectedTab.CAMPAIGN]: selectedRowTab2,
        [SelectedTab.AD_SET]: selectedRowTab3,
        [SelectedTab.ADS]: selectedRowTab4,
    };
    const setRow = {
        [SelectedTab.PLATFORM]: setSelectedRowTab0,
        [SelectedTab.ACCOUNT]: setSelectedRowTab1,
        [SelectedTab.CAMPAIGN]: setSelectedRowTab2,
        [SelectedTab.AD_SET]: setSelectedRowTab3,
        [SelectedTab.ADS]: setSelectedRowTab4,
    };

    useEffect(() => {
        const allSelectedRowsWvId: string[] = [];
        const filters: {
            platforms: string[],
            campaigns: string[],
            accounts: string[],
            adSets: string[],
            ads: string[],
        } = {
            platforms: [],
            campaigns: [],
            accounts: [],
            adSets: [],
            ads: []
        };

        const pushRowChannels = (row: Row<Data>) => {
            row?.original?.allChannels?.forEach((item) => {
                if(!allSelectedRowsWvId?.includes(item)){
                    allSelectedRowsWvId?.push(item)
                }
            })
        };

        selectedRowTab0.forEach((row) => {
            pushRowChannels(row)
            filters.platforms.push(row.original.col_0);
        });
        selectedRowTab1.forEach((row) => {
            pushRowChannels(row)
            filters.accounts.push(row.original.col_0);
        });
        selectedRowTab2.forEach((row) => {
            pushRowChannels(row)
            filters.campaigns.push(row.original.col_0);
        });
        selectedRowTab3.forEach((row) => {
            pushRowChannels(row)
            filters.adSets.push(row.original.col_0);
        });
        selectedRowTab4.forEach((row) => {
            pushRowChannels(row)
            filters.ads.push(row.original.col_0);
        });
        /*props?.setSelectedRows(allSelectedRowsWvId);*/ // TODO DO NOT DELETE
        let newData = JSON.parse(JSON.stringify(props?.data));

        if (filters.platforms.length !== 0) {

            if (selectedTab === SelectedTab.PLATFORM) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.platforms.includes(item.platform);
            });
        }
        if (filters.accounts.length !== 0) {
            if (selectedTab === SelectedTab.ACCOUNT) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.accounts.includes(item.account);
            });

        }
        if (filters.campaigns.length !== 0) {
            if (selectedTab === SelectedTab.CAMPAIGN) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.campaigns.includes(item.campaign);
            });

        }
        if (filters.adSets.length !== 0) {
            if (selectedTab === SelectedTab.AD_SET) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.adSets.includes(item.adSet);
            });

        }
        if (filters.ads.length !== 0) {
            if (selectedTab === SelectedTab.ADS) {
                setCurrentPropsData(newData);
                return;
            }else {
                newData = newData.filter((item: any) => {
                    return filters.ads.includes(item.adId);
                });
                setCurrentPropsData(newData)
            }

        }

        else {
            setCurrentPropsData(newData);
        }

    }, [selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4, props.data, initalTab, selectedTab, props?.setSelectedRows]);

    useEffect(() => {
        if (!!selectedRowTab0 && !!selectedRowTab1 && !!selectedRowTab2 && !!selectedRowTab3 && !!selectedRowTab4) {
            if ([...selectedRowTab0, ...selectedRowTab1, ...selectedRowTab2, ...selectedRowTab3, ...selectedRowTab4].length === 1) {
                const currentRow = [...selectedRowTab0, ...selectedRowTab1, ...selectedRowTab2, ...selectedRowTab3, ...selectedRowTab4][0];
                const cell = currentRow.cells.filter((item) => item.column.Header === "Tab")[0];
                setInitalTab(cell?.value);
            }
        }
    }, [selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4]);

    useEffect(() => {
        if (!!props?.data) {
            setCurrentPropsData(props?.data);
        }
    }, [props?.data]);



    useEffect(() => {
        const dataToRenderByTab = {
            [SelectedTab.PLATFORM]: 'platform',
            [SelectedTab.CAMPAIGN]: 'campaign',
            [SelectedTab.ACCOUNT]: 'account',
            [SelectedTab.AD_SET]: 'adSet',
            [SelectedTab.ADS]: 'adId',
        };
        sumDataByKey(dataToRenderByTab[selectedTab]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, props?.data, currentPropsData]);

    const getAllWvChannelsId = (array: any[]) => {
        const wvChannelIds: string[] = [];
        array?.forEach((item) => {
            if(wvChannelIds?.includes(item?.wvChannelId)){
                return
            }else {
                wvChannelIds?.push(item?.wvChannelId)
            }
        })
        return wvChannelIds;
    }


    const sumDataByKey = (key: string) => {
        const dataByKeys = {};
        let totalSpend = 0;
        const keysToIgnore = ["platform", "account", "campaign", "adSet", "adId", "wvChannelId"];
        currentPropsData.forEach((item: any) => {
            if (!!dataByKeys[item[key]]) {
                dataByKeys[item[key]].push(item);
            }
            else {
                dataByKeys[item[key]] = [item];
            }
        });
        const data = Object.keys(dataByKeys).map((key) => {
            const allChannelIdsForThisKey = getAllWvChannelsId(dataByKeys[key]);
            return { spend: sumData(dataByKeys[key], keysToIgnore)["spend"], key: key, allWvChannels : allChannelIdsForThisKey};
        });

        Object.keys(data).forEach((key) => {
            totalSpend = totalSpend + data[key]["spend"];
        });
        setTotalSpend(totalSpend);
        setTableData(data);
    };

    const dataToDataTable = (data: TableData[]): Data[] => {
        return data.map((row, index: number) => {
            return {
                col_0: row?.key,
                col_1: row?.spend,
                col_2: ((row?.spend / totalSpend) * 100),
                col_3: row?.spend,
                col_4: selectedTab,
                allChannels: row?.allWvChannels,
            };
        });
    };

    const headersToColumns = (headers: string[],): Column<Data>[] => {
        return headers.map((header, index) => {
            return {
                Header: header, accessor: 'col_' + index.toString(), sortType: 'basic',
                Footer: info => {
                    const totalSpend = useMemo(
                        () => {
                            const allSelectedRows = [...selectedRowTab0, ...selectedRowTab1, ...selectedRowTab2, ...selectedRowTab3, ...selectedRowTab4];
                            let rows = info.rows;
                            if (allSelectedRows?.length > 0) {
                                if (selectedTab === SelectedTab.PLATFORM && selectedRowTab0?.length > 0) {
                                    rows = selectedRowTab0;
                                } else if (selectedTab === SelectedTab.ACCOUNT && selectedRowTab1?.length > 0) {
                                    rows = selectedRowTab1;
                                } else if (selectedTab === SelectedTab.CAMPAIGN && selectedRowTab2?.length > 0) {
                                    rows = selectedRowTab2;
                                } else if (selectedTab === SelectedTab.AD_SET && selectedRowTab3?.length > 0) {
                                    rows = selectedRowTab3;
                                } else if (selectedTab === SelectedTab.ADS && selectedRowTab4?.length > 0) {
                                    rows = selectedRowTab4;
                                }
                            }


                            return (rows.reduce((sum, row) => parseFloat(row.values.col_1) + sum, 0));

                        },
                        [info.rows, selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4]
                    );

                    switch (header) {
                        case "Platform":
                            return <>Grand Total</>;
                        case "Account":
                            return <>Grand Total</>;
                        case "Campaign":
                            return <>Grand Total</>;
                        case "Ad Set":
                            return <>Grand Total</>;
                        case "Ads":
                            return <>Grand Total</>;
                        case "Spend":
                            return <>{currencyFormat(totalSpend, 2, currentCurrency)}</>;
                    }
                    return <>-</>;
                }
            } as Column<Data>;
        });
    };
    const [ newHeaders, setNewHeaders] = useState<string[]>([]);
    useEffect(() => {
        if(!!props?.headers){
            if(selectedTab === SelectedTab.PLATFORM){
                setNewHeaders(props?.headers)
            }
            if(selectedTab === SelectedTab.ACCOUNT){
                const copyOfHeaders = [...props?.headers];
                copyOfHeaders[0] = "Account";
                setNewHeaders(copyOfHeaders);
            }
            if(selectedTab === SelectedTab.CAMPAIGN){
                const copyOfHeaders = [...props?.headers];
                copyOfHeaders[0] = "Campaign";
                setNewHeaders(copyOfHeaders);
            }
            if(selectedTab === SelectedTab.AD_SET){
                const copyOfHeaders = [...props?.headers];
                copyOfHeaders[0] = "Ad Set";
                setNewHeaders(copyOfHeaders);
            }
            if(selectedTab === SelectedTab.ADS){
                const copyOfHeaders = [...props?.headers];
                copyOfHeaders[0] = "Ads";
                setNewHeaders(copyOfHeaders);
            }
        }
    }, [props?.headers, selectedTab])
    const data = useMemo<Data[]>(() => dataToDataTable(tableData), [tableData]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(newHeaders), [newHeaders, selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4]);



    const rowSelect = (row: any) => {

        const selectedRows = currentRow[selectedTab];

        const isRowSelected = selectedRows.filter(selectedRow => selectedRow.id === row.id);
        if (!!isRowSelected && isRowSelected.length > 0) {
            setRow[selectedTab]([...(selectedRows.filter(selectedRow => selectedRow.id !== row.id))]);
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);
            setRow[selectedTab](newSelected);
        }
    };

    const TableRow = ({ row, i }: { row: any, i: number; }) => {
        const renderNames = {
            [SelectedTab.PLATFORM]: "platform",
            [SelectedTab.ACCOUNT]: "account",
            [SelectedTab.CAMPAIGN]: "campaign",
            [SelectedTab.AD_SET]: "adSet",
            [SelectedTab.ADS]: "adId",
        };
        const getSelectedRow = () => {
            if (currentRow[selectedTab]?.length === 0) {
                return true;
            }
            else return currentRow[selectedTab]?.some((selectedRows) => selectedRows?.original.col_0 === row.original.col_0);
        };

        return (
            <styles.TrCustom selected={getSelectedRow()} onClick={() => {
                rowSelect(row);
            }} {...row.getRowProps()}>
                {row.cells.map((cell: Cell, i: number) => {
                    if (cell.column.id === "col_4") {
                        return <td style={{ display: "none" }} key={i}></td>;
                    }
                    if (cell.column.id === "col_0") {
                        const currentCell = props?.data?.find((item) => item[renderNames[selectedTab]] === cell?.value);
                        return (<styles.StyledTdName {...cell.getCellProps()}>
                            {currentCell?.displayName[renderNames[selectedTab]]}
                        </styles.StyledTdName>);
                    }
                    if (cell.column.id === "col_1") {
                        return (
                            <styles.StyledTd {...cell.getCellProps()}>
                                {currencyFormat(cell.value, 2, currentCurrency)}
                                {/* <TargetBar actual={cell.value} target={cell.value} /> // TODO .V2 */}
                            </styles.StyledTd>
                        );
                    }
                    if (cell.column.id === "col_2") {
                        return (
                            <styles.StyledShareTd {...cell.getCellProps()}>
                                {cell?.value?.toFixed(2)+"%"}
                            </styles.StyledShareTd>
                        );
                    }
                    if (cell.column.id === "col_3") {
                        return (
                            <styles.StyledShareTd {...cell.getCellProps()}>
                                {/* <TargetBar actual={cell.value} target={cell.value} small /> // TODO .V2 */}
                            </styles.StyledShareTd>
                        );
                    }

                    return <styles.StyledTd {...cell.getCellProps()}>

                        {cell.render('Cell')}
                    </styles.StyledTd>;
                })}
            </styles.TrCustom>
        );
    };

    function Table({ columns, data }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            footerGroups,
            prepareRow,
        } = useTable({
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id:"col_2",
                        desc: true
                    }
                ]
            }
        }, useSortBy);

        return (
            <styles.StyledTable {...getTableProps()}>
                <thead >
                    {headerGroups.map(headerGroup => {
                        return (
                            <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => {
                                    if (column.id === "col_4") {
                                        return <th style={{ display: "none" }} key={i}></th>;
                                    }
                                    return (
                                      <styles.StyledTh
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                      >
                                        <Flex alignItems={"center"}>
                                          {column.render("Header")}{" "}
                                          <span>
                                            {column.isSorted ? (
                                              !column.isSortedDesc ? (
                                                <GoTriangleUp />
                                              ) : (
                                                <GoTriangleDown />
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </Flex>
                                      </styles.StyledTh>
                                    );
                                })}
                            </styles.HeaderTr>
                        );
                    })}
                </thead>
                { props?.isLoading && <Flex position={'absolute'} w={'100%'} h={'70%'} justifyContent={'center'} alignItems={'center'}><Spinner /> </Flex>}
                {!props?.isLoading && <styles.TbodyCustom {...getTableBodyProps()}>
                    {
                        rows.map((row, i) => {
                            prepareRow(row);
                            return <TableRow row={row} key={i} i={i}/>;
                        })}
                </styles.TbodyCustom>}
                <tfoot>
                {footerGroups.map((group, i) => (
                    <styles.footerTr {...group.getFooterGroupProps()}>
                        {group.headers.map((column, i) => {
                            if (column.Header === 'Platform' || column.Header === 'Account' || column.Header === 'Campaign' || column.Header === 'Ad Set' || column.Header === 'Ads') {
                                return (<td style={{display: "flex", justifyContent:"flex-start"}}><styles.StyledTotal  {...column.getFooterProps()}>{column.render('Footer')}</styles.StyledTotal></td> );
                            }
                            else if (column.Header === "adSet" || column.Header === "id" || column.Header === "ads" || column.Header === "platform" || column.Header === "accountId" || column.Header === "campaign" || column.Header === "Tab") {
                                return;
                            }

                            return (< styles.FooterTd  {...column.getFooterProps()}>{column.render('Footer')}</styles.FooterTd>);
                        })}
                    </styles.footerTr>
                ))}
                </tfoot>
            </styles.StyledTable>
        );
    }

    const tabsArray = [
        { title: t('PLATFORM', { ns: 'translation' }), selectedTab: SelectedTab.PLATFORM, selectedRow: selectedRowTab0 },
        { title: t('ACCOUNT', { ns: 'translation' }), selectedTab: SelectedTab.ACCOUNT, selectedRow: selectedRowTab1 },
        { title: t('CAMPAIGN', { ns: 'translation' }), selectedTab: SelectedTab.CAMPAIGN, selectedRow: selectedRowTab2 },
        { title: t('ADS_SET', { ns: 'translation' }), selectedTab: SelectedTab.AD_SET, selectedRow: selectedRowTab3 },
        { title: t('ADS', { ns: 'translation' }), selectedTab: SelectedTab.ADS, selectedRow: selectedRowTab4 },
    ];

    const Tabs = () => {
        return (
            <styles.TabButtonsContainer>
                {tabsArray.map((item, i) => {
                    return (
                        <styles.TabButton key={i} {...item.selectedTab === selectedTab ? { selected: true } : { selected: false }} onClick={(() => {
                            setSelectedTab(item.selectedTab);
                        })}>
                            <styles.TabButtonText>
                                <styles.StyledDiv>
                                    {item.title}
                                </styles.StyledDiv>
                                {!!item?.selectedRow && item?.selectedRow?.length > 0 ?
                                    <styles.StyledDivSelected {...item.selectedTab === selectedTab ? { selected: true } : { selected: false }}>
                                        {(item?.selectedRow?.length)}
                                    </styles.StyledDivSelected> : null
                                }
                            </styles.TabButtonText>
                        </styles.TabButton>
                    );
                })}

            </styles.TabButtonsContainer>
        );
    };

    const renderClearFiltersButton = () => {
        return <styles.ClearFiltersButtonWrapper onClick={() => {
            setSelectedRowTab0([]);
            setSelectedRowTab1([]);
            setSelectedRowTab2([]);
            setSelectedRowTab3([]);
            setSelectedRowTab4([]);
            /* clearFilters(); */
        }}>
            <IconClearFiltersArrow />
        </styles.ClearFiltersButtonWrapper>;
    };



    return (
        <styles.Wrapper>
            <styles.Card>
                <styles.Header>
                    <styles.Title>
                        <span style={{ marginRight: '5px' }}>
                            {props.icon}
                        </span>
                        {props.title}
                    </styles.Title>
                    {selectedRowTab0?.length > 0 || selectedRowTab1?.length > 0 || selectedRowTab2?.length > 0 || selectedRowTab3?.length > 0 || selectedRowTab4?.length > 0 ? renderClearFiltersButton() : <></>}
                    {/*<DownloadButton onClick={() => console.log('download')} />*/}
                </styles.Header>
                <Box h={17}/>
                <Tabs />
                <Box height={275} overflowY="auto">
                    <Table columns={columns} data={data} />
                </Box>
            </styles.Card>
        </styles.Wrapper>
    );
};
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PnLWaterfallChart } from "./pnl-waterfall-chart/PnLWaterfallChart";
import { PnlTable } from "./pnl-table/PnlTable";
import { PnlFinancialTable } from "../../models/pnl-financial-table";
import React, { useEffect, useState } from "react";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import { PanelMenu } from "components/panel-menu/PanelMenu";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { Toggle, ToggleOption } from "../../components/toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanGraphData,
  fetchGraphData,
  fetchKpiData, fetchMarketingKpi,
  kpiDataSelector,
  kpiStateSelector,
} from "../../store/kpis";
import {
  fetchHolidays,
  organizationDataLanguageSelector,
  organizationLoadingSelector,
  organizationSelector,
} from "@store";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import {
  dateRangeSelector,
  ExploresEnum,
  openDefaultExploreView,
  selectedChannelsSelector,
} from "store/ui";
import PnlBreakdownTableCogs from "./pnl-breakdown-table-cogs/PnlBreakdownTableCogs";
import PnlBreakdownTableDeductions from "./pnl-breakdown-table-deductions/PnlBreakdownTableDeductions";
import { groupDatesByWeek, sumData } from "utils/get-months/getLastYear";
import { channelsStateSelector } from "store/channels";
import {
  getChannelNameById,
  getProductNameById,
} from "utils/get-channel-name/getChannelNameById";
import MoreInformation from "components/more-information/MoreInformation";
import { formatIsoDate } from "../../utils/date-format/dateFormat";
import { useCurrencySign } from "../../utils/custom-hooks/useCurrencySign";
import { MenuButton } from "../../components/menu-generic/MenuGenericButton";
import StoreChannelFilter from "../../components/store-channel-filter/StoreChannelFilter";
import { DownloadButton } from "../../components/download-button/DownloadButton";
import {productsDataSelector, productsFieldsSelector} from "../../store/products";
import {normalizeCogsNameToFieldName} from "../../utils/field-names-from-db-format/FieldNamesFormat";

export enum Unit {
  MARGIN,
  VALUE,
}

enum TableType {
  COGS,
  DEDUCTION,
  MARKETING,
}

enum GroupBy {
  DAY,
  WEEK,
  MONTH,
  NONE
}

export enum ShowBy {
  DATE = "DATE",
  CHANNEL = "CHANNEL",
  PRODUCT = "PRODUCT",
}

export const WideViewsFinancial = () => {
  // hooks helpers
  const { t } = useTranslation(["translation", "translation"]);
  const [isMoreInfoDialogOpen, setMoreInfoDialogOpen] = useState(false);
  const [unitEconomy, setUnitEconomy] = useState<string>("TOTALS");
  const [allDeductions, setAllDeductions] = useState<string[]>([]);
  const [tableShowBy, setTableShowBy] = useState<string>(ShowBy.DATE);
  const [unit, setUnit] = useState<Unit>(Unit.VALUE);
  const [tableType, setTableType] = useState<TableType>(TableType.DEDUCTION);
  const [financialTableData, setFinanicalTableData] =
    useState<PnlFinancialTable[]>();
  const [kpiDataToRender, setKpiDataToRender] = useState<any[]>([]);
  const selectedChannels = useSelector(selectedChannelsSelector);

  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const currentCurrency = useCurrencySign();

  const [cogsTableData, setCogsTableData] = useState<any[]>([]);
  const [deductionTableData, setDeductionTableData] = useState<any[]>([]);
  const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);

  const organization = useSelector(organizationSelector);
  const HEADERS = [
    `${
      tableShowBy === ShowBy.DATE
        ? `${
            groupByStatus === GroupBy.DAY
              ? "Day"
              : groupByStatus === GroupBy.WEEK
              ? "Week"
              : "Month"
          }`
        : tableShowBy === ShowBy.PRODUCT
        ? "Product"
        : "Channel"
    }`,
    "Gross Revenue",
    "(Deductions)",
    `Net Revenue ${currentCurrency}`,
    "Net Revenue %",
    "(COGS)",
    "Gross Profit",
    "Gross Margin %",
    "(Marketing & Others)",
    `Contribution Profit ${currentCurrency}`,
    "Contribution Margin %",
  ];

  // hooks redux
  const dispatch = useDispatch();
  /* const financialData = useSelector(financialDataSelector); */
  const organizationId = useSelector(organizationSelector);
  const kpiData = useSelector(kpiDataSelector);
  const organizationLoader = useSelector(organizationLoadingSelector);
  const kpis = useSelector(kpiStateSelector);
  const datesSelector = useSelector(dateRangeSelector);
  const channels = useSelector(channelsStateSelector);
  const [
    isFinancialTableMoreInfoDialogOpen,
    setIsFinancialTableMoreInfoDialogOpen,
  ] = useState(false);
  const [
    isExpensesBreakdownMoreInfoDialogOpen,
    setIsExpensesBreakdowneMoreInfoDialogOpen,
  ] = useState(false);
  const [marketingTableData, setMarktingTableData] = useState<any[]>([]);
  const [allMarketingDeductionLabels, setAllMarketingDeductionLabels] =
    useState<string[]>([]);
  /*const allProducts = useSelector(productsDataSelector);*/
  const allProductsData = useSelector(productsFieldsSelector);

  useEffect(() => {
    if(!!organization?.id && !!datesSelector?.startDate && !!datesSelector?.endDate && !!selectedChannels){
      dispatch(fetchGraphData({organizationId: organization?.id, channels: selectedChannels, fromDate: datesSelector?.startDate, toDate: datesSelector?.endDate}));
    }
    return () => {
      dispatch(cleanGraphData());
    }

  }, [selectedChannels, organization?.id, datesSelector?.startDate, datesSelector?.endDate])


  const defaultDict = {
    REFUNDS: "refundCosts",
    TRANSACTION_FEE: "transactionFees",
    DISCOUNT: "discount",
    TAXES: "totalTax",
    MARKETING_SPEND: "marketingCost",
    SHIPPING: "totalShipping",
    FULFILLMENT_FEES: "fulfillmentFees",
    MARKETING_PLACE_FEES : "marketplaceFees",
  };

  const getNamesArray = (items: any[], defaultDict: any) => {
    const namesArray: string[] = [];
    items.forEach((item) => {
      if (!!item?.name) {
        if (item?.name in defaultDict) {
          namesArray.push(defaultDict[item?.name]);
        } else {
          namesArray.push(item.name);
        }
      }
    });
    return namesArray;
  };

  useEffect(() => {
    if (!!dataLanguage?.pnlSettings?.grossSales) {
      setAllDeductions(
        getNamesArray(dataLanguage?.pnlSettings?.grossSales, defaultDict)
      );
    }
  }, [dataLanguage?.pnlSettings?.grossSales]);

  useEffect(() => {
    if (!!dataLanguage?.pnlSettings?.grossProfit) {
      const namesArray = getNamesArray(
        dataLanguage?.pnlSettings?.grossProfit,
        defaultDict
      );
      namesArray.unshift("Marketing & Others");
      setAllMarketingDeductionLabels(namesArray);
    }
  }, [dataLanguage?.pnlSettings?.grossProfit]);
  const bottomIndexSortingString = "zzzzzzz";

  const keysToIgnore = [
    "purchasePoint",
    "date",
    "country",
    "createdAt",
    "updatedAt",
    "id",
    "activityDate",
    "market",
  ];

  const sumNestedObject = (array: any[], nestedKey: string) => {
    let objToReturn: any = {};

    array?.forEach((item, i) => {
      if (nestedKey && item && nestedKey in item) {
        if (i === 0) {
          objToReturn = { ...item[nestedKey] };
        } else {
          const nestedObj = item[nestedKey];
          for (const key in nestedObj) {
            if (objToReturn && key && key in objToReturn) {
              objToReturn[key] += nestedObj[key];
            }
          }
        }
      }
    });

    return objToReturn;
  };

  const sumDataFinance = (array: any[]) => {
    return array.reduce((accum, current) => {
      Object.entries(current).forEach(([key, value]) => {
        if (key === "wvChannelId" || key === "wvProductId") {
          accum[key] = value;
          return;
        }
        if (!keysToIgnore.includes(key)) {
          accum[key] = accum[key] + value || value;
        }
      });
      return {
        ...accum,
        marketplaceFeesBrake: sumNestedObject(array, 'marketplaceFeesBrake'),
        fulfillmentFeesBrake: sumNestedObject(array, 'fulfillmentFeesBrake'),
        refundCostsBrake: sumNestedObject(array, 'refundCostsBrake'),
      };
    }, {});
  };

  useEffect(() => {

    if (!!kpiData?.data?.data) {
      if (!!datesSelector?.startDate && !!datesSelector?.endDate) {

        const kpiDataFilteredByChannel = kpiData?.data?.data.filter((item) => {
          return selectedChannels?.includes(item.wvChannelId);
        });

        let finalObj: Record<string, any> = {};

        kpiDataFilteredByChannel.forEach((day) => {
          const date = day.activityDate.slice(0, 10);
          finalObj[date] = finalObj[date] ? [...finalObj[date], day] : [day];
        });

        Object.keys(finalObj).forEach((key) => {
          finalObj[key] = { ...sumDataFinance(finalObj[key]), activityDate: key };
        });

        const finalData = Object.values(finalObj);
        const tableDates = getDatesInRange(
            new Date(datesSelector?.startDate),
            new Date(datesSelector?.endDate)
        );

        const tableDataByDates: any[] = [...finalData];

        const missingDays = tableDates.filter((day) => {
          return !tableDataByDates.some((item) => item.activityDate === day);
        });

        const missingDaysData = missingDays.map((item) => ({
          activityDate: item,
        }));

        let dataToRender: any;


        const sortedByDates = [...missingDaysData, ...tableDataByDates].sort(
            (a, b) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        if(tableShowBy === ShowBy.CHANNEL || tableShowBy === ShowBy.PRODUCT){
          dataToRender = kpiDataFilteredByChannel;
        }
        if (groupByStatus === GroupBy.DAY) {
          dataToRender = sortedByDates;
        }
        if (groupByStatus === GroupBy.WEEK) {
          const groupedDates = groupDatesByWeek(sortedByDates);
          const flatWeekData: any[] = [];

          Object.keys(groupedDates).forEach((key) => {
            Object.keys(groupedDates[key]).forEach((dateRange) => {
              groupedDates[key][dateRange] = {
                ...sumDataFinance(groupedDates[key][dateRange]),
                activityDate: dateRange,
              };
              flatWeekData.push(groupedDates[key][dateRange]);
            });
          });

          dataToRender = flatWeekData;
        }
        if (groupByStatus === GroupBy.MONTH) {
          const month = {};
          const monthData: any[] = [];
          sortedByDates.forEach((day) => {
            const currentMonth = day.activityDate.slice(0, 7);
            if (month[currentMonth]) {
              month[currentMonth] = [...month[currentMonth], day];
            } else {
              month[currentMonth] = [day];
            }
          });
          Object.keys(month).forEach((key) => {
            month[key] = { ...sumDataFinance(month[key]), activityDate: key };

            monthData.push(month[key]);
          });
          dataToRender = monthData;
        }
        setKpiDataToRender(dataToRender);

      }
    }
  }, [
    allDeductions,
    datesSelector?.endDate,
    datesSelector?.startDate,
    groupByStatus,
    kpiData?.data?.data,
    selectedChannels,
    tableShowBy,
  ]);

  useEffect(() => {
    if (!!kpiDataToRender) {
      if (tableShowBy === ShowBy.DATE) {
        const tableData = () => {
          const dataReducer = kpiDataToRender
              .reverse()
              .map((day: any, i: number) => {
                const data = organization?.customCogs?.reduce(
                    (accumulator, value) => {
                      let newValue = "cogs_" + normalizeCogsNameToFieldName(value);
                      if (
                          value === "transactionFees" ||
                          value === "totalTax" ||
                          value === "discount" ||
                          value === "totalShipping" ||
                          value === 'fulfillmentFees' ||
                          value === "refundCosts" ||
                          value === 'marketplaceFees'
                      ) {
                        newValue = value;
                      }
                      if (day[newValue] === undefined) {
                        return {
                          ...accumulator,
                          [value + " " + currentCurrency]: 0,
                          [value + " %"]: 0,
                        };
                      }
                      return {
                        ...accumulator,
                        [value + " " + currentCurrency]:
                        currentCurrency +
                        day[newValue]?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                        [value + " %"]:
                        parseFloat(
                            ((day[newValue] / day.totalCogs) * 100).toFixed(2)
                        ) + "%",
                      };
                    },
                    {}
                );
                const monthDateArray = day?.activityDate?.split("-");
                const monthDateFormat =
                    monthDateArray[1] + "-" + monthDateArray[0];

                const defaults = {
                  "Year Month":
                      tableShowBy === ShowBy.DATE
                          ? groupByStatus === GroupBy.MONTH
                              ? monthDateFormat
                              : formatIsoDate(
                                  day?.activityDate?.slice(0, 10),
                                  groupByStatus !== GroupBy.DAY
                              )
                          : day?.wvChannelId,
                  ["COGS " + currentCurrency]: !!day.totalCogs
                      ? currentCurrency + day.totalCogs?.toFixed(2)
                      : bottomIndexSortingString,
                  "COGS %":
                      !!day.totalCogs && !!day.grossRevenue
                          ? parseFloat(
                          ((day.totalCogs / day.grossRevenue) * 100).toFixed(
                              2
                          )
                      ) + "%"
                          : bottomIndexSortingString,
                  ["FOB " + currentCurrency]: !!day.freeOnBoard
                      ? currentCurrency + day.freeOnBoard?.toFixed(2)
                      : bottomIndexSortingString,
                  "FOB %":
                      !!day.freeOnBoard && !!day.totalCogs
                          ? parseFloat(
                          ((day.freeOnBoard / day.totalCogs) * 100).toFixed(2)
                      ) + "%"
                          : bottomIndexSortingString,
                  totalGrossRevenue: day.grossRevenue,
                };
                return { ...data, ...defaults };
              });
          return dataReducer;
        };
        const tableDataDeductions = () => {
          const dataReducer = kpiDataToRender.map((day: any) => {
            const data = allDeductions.reduce((accumulator, value) => {
              let newValue = "pnl_" + normalizeCogsNameToFieldName(value);
              if (
                  value === "transactionFees" ||
                  value === "totalTax" ||
                  value === "discount" ||
                  value === "totalShipping" ||
                  value === 'fulfillmentFees' ||
                  value === "refundCosts" ||
                  value === 'marketplaceFees'
              ) {
                newValue = value;
              }
              if (day[newValue] === undefined) {
                return {
                  ...accumulator,
                  [value + " " + currentCurrency]: bottomIndexSortingString,
                  [value + " %"]: bottomIndexSortingString,
                };
              }

              return {
                ...accumulator,
                [value + " " + currentCurrency]:
                    day[newValue] !== undefined
                        ?
                        day[newValue]
                        : bottomIndexSortingString,
                [value + " %"]: !isNaN(
                    day[newValue] / (day.grossRevenue - day?.netRevenue)
                )
                    ? isFinite((day[newValue] /
                        (day.grossRevenue - day?.netRevenue))) ? parseFloat(
                        (
                            (day[newValue] /
                                (day.grossRevenue - day?.netRevenue)) *
                            100
                        ).toFixed(2)
                    )  : bottomIndexSortingString
                    : bottomIndexSortingString,
              };
            }, {});
            const monthDateArray = day?.activityDate?.split("-");
            const monthDateFormat =
                monthDateArray[1] + "-" + monthDateArray[0];
            const defaults = {
              "Year Month":
                  tableShowBy === ShowBy.DATE
                      ? groupByStatus === GroupBy.MONTH
                          ? monthDateFormat
                          : formatIsoDate(
                              day?.activityDate?.slice(0, 10),
                              groupByStatus !== GroupBy.DAY
                          )
                      : day?.wvChannelId,
              ["deduction " + currentCurrency]:
                  day.grossRevenue - day?.netRevenue !== undefined &&
                  !isNaN(day.grossRevenue - day?.netRevenue)
                      ?
                      (day.grossRevenue - day?.netRevenue)
                      : bottomIndexSortingString,
              "deduction %":
                  !!(day.grossRevenue - day?.netRevenue) &&
                  day.grossRevenue !== undefined
                      ? parseFloat(
                          (
                              ((day.grossRevenue - day?.netRevenue) /
                                  day.grossRevenue) *
                              100
                          ).toFixed(2)
                      )
                      : bottomIndexSortingString,
              totalGrossRevenue: day.grossRevenue,
            };

            return { ...data, ...defaults };
          });
          return dataReducer;
        };
        const marketingTableData = () => {
          const dataReducer = kpiDataToRender.map((day: any) => {
            const data = allMarketingDeductionLabels.reduce(
                (accumulator, value) => {

                  let newValue = "pnl_" + normalizeCogsNameToFieldName(value);
                  if (
                      value === "transactionFees" ||
                      value === "totalTax" ||
                      value === "discount" ||
                      value === "totalShipping" ||
                      value === 'fulfillmentFees' ||
                      value === "refundCosts" ||
                      value === 'marketplaceFees' ||
                      value === 'marketingCost'
                  ) {
                    newValue = value;
                  }

                  if (day[newValue] === undefined) {

                    return {
                      ...accumulator,
                      [value + " " + currentCurrency]: bottomIndexSortingString,
                      [value + " %"]: bottomIndexSortingString,
                    };
                  }
                  return {
                    ...accumulator,
                    [value + " " + currentCurrency]:
                        day[newValue] !== undefined
                            ?
                            day[newValue]?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : bottomIndexSortingString,
                    [value + " %"]:
                        !isNaN(
                            day[newValue] /
                            (day.grossProfit - day?.contributionProfit)
                        ) && !!(day.grossProfit - day?.contributionProfit)
                            ? parseFloat(
                                (
                                    (day[newValue] /
                                        (day.grossProfit - day?.contributionProfit)) *
                                    100
                                ).toFixed(2)
                            )
                            : bottomIndexSortingString,
                  };
                },
                {}
            );
            const monthDateArray = day?.activityDate?.split("-");
            const monthDateFormat =
                monthDateArray[1] + "-" + monthDateArray[0];
            const defaults = {
              "Year Month":
                  tableShowBy === ShowBy.DATE
                      ? groupByStatus === GroupBy.MONTH
                          ? monthDateFormat
                          : formatIsoDate(
                              day?.activityDate?.slice(0, 10),
                              groupByStatus !== GroupBy.DAY
                          )
                      : day?.wvChannelId,
              ["Marketing & Others " + currentCurrency]:
                  day.grossProfit - day?.contributionProfit !== undefined &&
                  !isNaN(day.grossProfit - day?.contributionProfit)
                      ?
                      (
                          day.grossProfit - day?.contributionProfit
                      )?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : bottomIndexSortingString,
              "Marketing & Others %":
                  !!(day.grossProfit - day?.contributionProfit) &&
                  !!day.grossRevenue
                      ? parseFloat(
                          (
                              ((day.grossProfit - day?.contributionProfit) /
                                  day.grossRevenue) *
                              100
                          ).toFixed(2)
                      )
                      : bottomIndexSortingString,
              totalGrossRevenue: day.grossRevenue,
            };

            return { ...data, ...defaults };
          });
          return dataReducer;
        };

        if(TableType.MARKETING === tableType){
          setMarktingTableData(marketingTableData());
        }
        if(TableType.DEDUCTION === tableType){
          setDeductionTableData(tableDataDeductions());
        }
        if(TableType.COGS === tableType){
          setCogsTableData(tableData());
        }


      }
      if (tableShowBy === ShowBy.CHANNEL) {
        const channelsObj: Record<string, any[]> = {};

        kpiDataToRender.forEach((item: any) => {
          const channelId = item?.wvChannelId;
          if (channelId) {
            channelsObj[channelId] = channelsObj[channelId] ? [...channelsObj[channelId], item] : [item];
          }
        });

        const dataArray: any[] = Object.keys(channelsObj).map((key) => ({
          ...sumDataFinance(channelsObj[key]),
          channelName: getChannelNameById(key, channels?.channels),
        }));

        if(TableType.MARKETING === tableType){
          setMarktingTableData(transformMarketingTable(dataArray));

        }
        if(TableType.DEDUCTION === tableType){
          setDeductionTableData(transformDeductionTable(dataArray));

        }
        if(TableType.COGS === tableType){
          setCogsTableData(transformCogsTable(dataArray));
        }

      }
      if (tableShowBy === ShowBy.PRODUCT) {
        const productsObj = {};
        kpiDataToRender.forEach((item: any) => {
          const currentProductId = item?.wvProductId;
          if (currentProductId !== undefined || currentProductId!== null) {
            if (!productsObj[currentProductId]) {
              productsObj[currentProductId] = [item];
            } else {
              productsObj[currentProductId].push(item);
            }
          }
        });
        const dataArray: any[] = [];
        Object.keys(productsObj).forEach((key) => {
          dataArray.push({
            ...sumDataFinance(productsObj[key]),
            wvProductId: key,
            channelName: getProductNameById(key, allProductsData),
          });
        });

        if(TableType.MARKETING === tableType){
          setMarktingTableData(transformMarketingTable(dataArray));
        }
        if(TableType.DEDUCTION === tableType){
          setDeductionTableData(transformDeductionTable(dataArray));
        }
        if(TableType.COGS === tableType){
          setCogsTableData(transformCogsTable(dataArray));
        }

      }
    }
  }, [kpiData, tableType, channels?.channels, allProductsData, groupByStatus])

  function transformMarketingTable(dataArray: any[]) {
    const dataReducer = dataArray.map((day: any) => {
      const data = allMarketingDeductionLabels.reduce((accumulator, value) => {
        if (day[value] === undefined) {
          return {
            ...accumulator,
            [value + " " + currentCurrency]: bottomIndexSortingString,
            [value + " %"]: bottomIndexSortingString,
          };
        }
        return {
          ...accumulator,
          [value + " " + currentCurrency]: day[value],
          [value + " %"]: !!(day.grossProfit - day?.contributionProfit)
            ? parseFloat(
                (
                  (day[value] / (day.grossProfit - day?.contributionProfit)) *
                  100
                ).toFixed(1)
              )
            : bottomIndexSortingString,
        };
      }, {});
      const defaults = {
        "Year Month": day.channelName ?? '-',
        ["Marketing & Others " + currentCurrency]:
          day.grossProfit - day?.contributionProfit !== undefined &&
          !isNaN(day.grossProfit - day?.contributionProfit)
            ?
              (day.grossProfit - day?.contributionProfit)
            : bottomIndexSortingString,
        totalGrossRevenue: day.grossRevenue,
        "Marketing & Others %":
          !!(day.grossProfit - day?.contributionProfit) && !!day.grossRevenue
            ? parseFloat(
                (
                  ((day.grossProfit - day?.contributionProfit) /
                    day.grossRevenue) *
                  100
                ).toFixed(2)
              )
            : bottomIndexSortingString,
      };

      return { ...data, ...defaults };
    });
    return dataReducer;
  }

  function transformCogsTable(dataArray: any[]) {
    const dataReducer = dataArray.reverse().map((day: any, i: number) => {
      const data = organization?.customCogs?.reduce((accumulator, value) => {
        if (!day[value]) {
          return {
            ...accumulator,
            [value + " " + currentCurrency]: bottomIndexSortingString,
            [value + " %"]: bottomIndexSortingString,
          };
        }
        return {
          ...accumulator,
          [value + " " + currentCurrency]:
            currentCurrency + day[value]?.toFixed(2),
          [value + " %"]:
            parseFloat(((day[value] / day.totalCogs) * 100).toFixed(2)) + "%",
        };
      }, {});

      const defaults = {
        "Year Month": day.channelName,
        ["COGS " + currentCurrency]: !!day.totalCogs
          ? currentCurrency + day.totalCogs?.toFixed(2)
          : bottomIndexSortingString,
        "COGS %":
          !!day.totalCogs && !!day.grossRevenue
            ? parseFloat(
                ((day.totalCogs / day.grossRevenue) * 100).toFixed(2)
              ) + "%"
            : bottomIndexSortingString,
        ["FOB " + currentCurrency]: !!day.freeOnBoard
          ? currentCurrency + day.freeOnBoard?.toFixed(2)
          : bottomIndexSortingString,
        "FOB %":
          !!day.freeOnBoard && !!day.totalCogs
            ? parseFloat(((day.freeOnBoard / day.totalCogs) * 100).toFixed(2)) +
              "%"
            : bottomIndexSortingString,
        totalGrossRevenue: day.grossRevenue,
      };
      return { ...data, ...defaults };
    });
    return dataReducer;
  }

  function transformDeductionTable(dataArray: any[]) {
    const dataReducer = dataArray.map((day: any) => {
      const data = allDeductions.reduce((accumulator, value) => {
        if (day[value] === undefined) {
          return {
            ...accumulator,
            [value + " " + currentCurrency]: bottomIndexSortingString,
            [value + " %"]:bottomIndexSortingString,
          };
        }
        return {
          ...accumulator,
          [value + " " + currentCurrency]: day[value],
          [value + " %"]: !!(day.grossRevenue - day?.netRevenue)
              ?  ((day[value] / (day.grossRevenue - day?.netRevenue)) * 100)
            : bottomIndexSortingString,
        };
      }, {});
      const defaults = {
        "Year Month": day.channelName,
        ["deduction " + currentCurrency]: !!(day.grossRevenue - day?.netRevenue)
          ?
            (day.grossRevenue - day?.netRevenue)
          : bottomIndexSortingString,
        "deduction %":
          !!(day.grossRevenue - day?.netRevenue) && !!day.grossRevenue
            ?  ((day.grossRevenue - day?.netRevenue) / day.grossRevenue) * 100
            : bottomIndexSortingString,
        totalGrossRevenue: day.grossRevenue,
      };

      return { ...data, ...defaults };
    });
    return dataReducer;
  }

  useEffect(() => {
    const financeFields: string[] = ['grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit', 'freeOnBoard'
    , 'refunds', 'totalCogs', 'totalDeduction', 'totalFba', 'totalShipping', 'transactionFees', 'totalTax', 'marketplaceFees', 'refundCosts', "marketplaceFees",
      'fulfillmentFees', 'discount', 'marketingCost'];
    if (
      !!organizationId?.id &&
      !!datesSelector?.endDate &&
      !!datesSelector?.startDate &&
        !!channels?.channels
    ) {
      dispatch(
        fetchKpiData({
          organizationId: organizationId?.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 0,
          channels: channels?.channels,
          cogsFields: true,
          pnlFields: true,
          fields: financeFields


        })
      );
      dispatch(
        fetchKpiData({
          organizationId: organizationId?.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 0,
          type: 2,
          channels: channels?.channels,
          cogsFields: true,
          pnlFields: true,
          fields: financeFields

        })
      );
    }
  }, [
    datesSelector?.endDate,
    datesSelector?.startDate,
    dispatch,
    organizationId?.id,
    organizationId?.dataLanguage,
    channels?.channels
  ]);

  useEffect(() => {
    if (
        kpiDataToRender &&
        datesSelector?.startDate &&
        datesSelector?.endDate &&
        channels
    ) {
      const transformAndSetData = () => {
        if (tableShowBy === ShowBy.DATE) {
          const newData = kpiDataToRender.map((item): PnlFinancialTable => {
            const monthDateArray = item.activityDate.split("-");
            const monthDateFormat = `${monthDateArray[1]}-${monthDateArray[0]}`;
            return {
              ...item,
              gross: item.grossRevenue,
              date:
                  groupByStatus === GroupBy.MONTH
                      ? monthDateFormat
                      : formatIsoDate(item.activityDate.slice(0, 10), groupByStatus !== GroupBy.DAY),
              clearing: item.grossRevenue - item.netRevenue,
              netRevenueFiat: item.netRevenue,
              netRevenuePercent:
                  item.netRevenue && item.grossRevenue
                      ? parseFloat(((item.netRevenue / item.grossRevenue) * 100).toFixed(1))
                      : 0,
              cogs: item.netRevenue - item.grossProfit,
              grossProfit: item.grossProfit,
              grossMargin:
                  item.grossProfit && item.grossRevenue
                      ? parseFloat(((item.grossProfit / item.grossRevenue) * 100).toFixed(1))
                      : 0,
              contributionProfit: item.contributionProfit,
              contributionMargin:
                  item.contributionProfit && item.grossRevenue
                      ? parseFloat(((item.contributionProfit / item.grossRevenue) * 100).toFixed(1))
                      : 0,
              marketingSpend: item.grossProfit - item.contributionProfit,
            };
          });
          setFinanicalTableData(newData.reverse());
        } else if (tableShowBy === ShowBy.CHANNEL) {
          setFinanicalTableData(transformData("wvChannelId").reverse());
        } else if (tableShowBy === ShowBy.PRODUCT) {
          setFinanicalTableData(transformData("wvProductId").reverse());
        }
      };

      transformAndSetData();
    }
  }, [
    kpiDataToRender,
    datesSelector?.endDate,
    datesSelector?.startDate,
    tableShowBy,
    channels,
    selectedChannels,
  ]);

  function transformData(groupByKey: string) {
    const groupedObj: { [key: string]: PnlFinancialTable[] } = {};

    kpiDataToRender.forEach((item) => {
      if (groupByKey in item) {
        const currentGroupValue = item[groupByKey];
        if (currentGroupValue) {
          if (!groupedObj[currentGroupValue]) {
            groupedObj[currentGroupValue] = [item];
          } else {
            groupedObj[currentGroupValue].push(item);
          }
        }
      }
    });

    // THIS SUM DATA FROM UTILS

    const dataArray: PnlFinancialTable[] = Object.keys(groupedObj).map((key) => {
      const displayName =
          groupByKey === "wvChannelId"
              ? getChannelNameById(key, channels?.channels)
              : getProductNameById(key, allProductsData);

      return {
        ...sumData(groupedObj[key], ["wvChannelId", "wvProductId"]),
        displayName,
      };
    });

    const newData = dataArray.map((item: any): any => {
      return {
        ...item,
        gross: item?.grossRevenue,
        date: item?.displayName,
        clearing: item?.grossRevenue - item?.netRevenue,
        netRevenueFiat: item?.netRevenue,
        netRevenuePercent:
            item.netRevenue && item.grossRevenue
                ? parseFloat(((item.netRevenue / item.grossRevenue) * 100).toFixed(2))
                : 0,
        cogs: item.netRevenue - item.grossProfit,
        grossProfit: item.grossProfit,
        grossMargin:
            item.grossProfit && item.grossRevenue
                ? parseFloat(((item.grossProfit / item.grossRevenue) * 100).toFixed(2))
                : 0,
        contributionProfit: item.contributionProfit,
        contributionMargin:
            item.contributionProfit && item?.grossRevenue
                ? parseFloat(((item.contributionProfit / item?.grossRevenue) * 100).toFixed(2))
                : 0,
        marketingSpend: item.grossProfit - item.contributionProfit,
      };
    });

    return newData;
  }


  // helpers
  function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date).toISOString().slice(0, 10));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const pnlOverviewMoreInfoDialogProps = [
    {
      title: "P&L - Overview",
      subtitle:
        "Start with this overview to understand which sections drive profitability / loss",
      description:
        "Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.",
      tip: "TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.",
      videoUrl: "https://www.youtube.com/watch?v=Rjm129AoRno",
      videoTitle: "Best Practices",
      videoSubtitle: "How to use this view?",
      videoDescription: "5 min explanatory video from our COO",
      videoLinkTitle: "Click to watch",
    },
    {
      title: "P&L - Financial Table",
      //subtitle: 'Start with this overview to understand which sections drive profitability / loss',
      description:
        "Use this overview to understand which sections in your financial funnel drive profitability/loss.\nIn this section, you can drill down to compare P&L performance over time and store channels. ",
      tip: "Tip: P&L structure can be edited on the `P&L` / `Account health` page.",
    },
    {
      title: "Expenses Breakdown",
      //subtitle: 'Start with this overview to understand which sections drive profitability / loss',
      description:
        "Use this overview to understand and explore how expenses are divided in your business.",
      tip: "Tip: You can adjust your COGS expenses on the `COGS` / `Account Health` page.",
    },
  ] as MoreInfoDialogProps[];
  const openPnlOverviewModeInfoDialog = (isOpen: boolean) =>
    setMoreInfoDialogOpen(isOpen);

  const setUnitHelper = (name: string) => {
    switch (name) {
      case "MARGIN":
        setUnit(Unit.MARGIN);
        break;
      case "VALUE":
        setUnit(Unit.VALUE);
        break;
      default:
        setUnit(Unit.MARGIN);
    }
  };

  const menuButtonsDataValue: MenuButton[] = [
    {
      title: "Margin",
      onClick: () => setUnitHelper("MARGIN"),
    },
    {
      title: "Value",
      onClick: () => setUnitHelper("VALUE"),
    },
  ];

  const menuButtonsDataShowBy: MenuButton[] = [
    {
      title: "Date",
      onClick: () => {
        setGroupByStatus(GroupBy.DAY);
        setTableShowBy(ShowBy.DATE);
      },
    },
    {
      title: "Channel",
      onClick: () => {
        setGroupByStatus(GroupBy.NONE);
        setTableShowBy(ShowBy.CHANNEL);
      },
    },
    {
      title: "Product",
      onClick: () => {
        setGroupByStatus(GroupBy.NONE);
        setTableShowBy(ShowBy.PRODUCT);
      },
    },
  ];


  // renderers

  const renderSplitter = (height: number) => (
    <div
      style={{
        minHeight: height + "px",
        width: "90%",
        marginBottom: "20px",
        backgroundColor: "#dedede",
      }}
    />
  );

  const renderPnLWaterfallSection = () => {
    return (
      <>
        <styles.SectionHeader>
          <styles.Title>
            {t("PNL_OVERVIEW", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={pnlOverviewMoreInfoDialogProps[0]}
              isMoreInfoDialogOpen={isMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={setMoreInfoDialogOpen}
            />
          </styles.Title>
          <styles.ButtonsWrapper>
            {/*  <PanelMenu title={"UNIT_ECONOMY"}
                            icon={(color: string) => <SiHackthebox color={color} />}
                            options={["TOTALS", "PER_CUSTOMER", "PER_ORDER", "PER_UNIT"]}
                            onSelect={(unit) => setUnitEconomy(unit)} /> */}
            <div style={{ width: "5px" }} />
            <Box>
              <styles.ShowByText>
                {t("SHOW_BY", { ns: "translation" })}
              </styles.ShowByText>
            </Box>
            <div style={{ width: "5px" }} />
            <PanelMenu
              data={menuButtonsDataValue}
              title={unit === Unit.MARGIN ? "Margin" : "Value"}
            />
            <div style={{ width: "5px" }} />
            <StoreChannelFilter />
            <div style={{ width: "5px" }} />
            <DatepickerRange
              backgroundColor="white"
              defaultsDate={datesSelector.preset}
              onApply={(range) => {}}
            />
          </styles.ButtonsWrapper>
        </styles.SectionHeader>
        <Box h={"10px"} />
        {!!kpiData?.graphData?.data && !!kpiData?.graphData?.targets ? (
          <PnLWaterfallChart actual={kpiData?.graphData?.data} targets={kpiData?.graphData?.targets} unit={unit} />
        ) : (
          <Flex h={"550px"} justifyContent="center" alignItems={"center"}>
            <Spinner />
          </Flex>
        )}
      </>
    );
  };

  const handlePnlTableDownload = () => {
    const dateFix = 'date'

    const refundsPrefix = 'Refunds.'
    const marketplacePrefix = 'Marketplace.'
    const fbaPrefix = 'Fulfillment Fees.'

    const fixedNames = {
      'gross': 'Gross Revenue',
      'date': dateFix,
      'netRevenueFiat': `Net Revenue ${currentCurrency}`,
      'cogs': '(COGS)',
      'netRevenuePercent': 'Net Revenue %',
      'grossMargin': 'Gross Margin %',
      'grossProfit': 'Gross Profit',
      'contributionMargin': 'Contribution Margin %',
      'marketingSpend': '(Marketing & Others)',
      'fulfillmentFees': 'Fulfillment Fees',
      'refundCosts': 'Refunds',
      'marketplaceFees': 'Marketplace Fees',
      'contributionProfit': `Contribution Profit ${currentCurrency}`,
      'discountRefund': refundsPrefix + 'Discount Refund',
      'itemPriceRefund': refundsPrefix + 'Item Price Refund',
      'referralFeeRefund': refundsPrefix + 'Referral Fee Refund',
      'refundCommission': refundsPrefix + 'Refund Commission',
      'shippingChargebackRefund': refundsPrefix + 'Shipping Charge Back Refund',
      'taxRefund': refundsPrefix + 'Tax Refund',
      'couponRedemptionFee': marketplacePrefix + 'Coupon Redemption Fee',
      'giftWrap': marketplacePrefix + 'Gift Wrap',
      'giftWrapTax': marketplacePrefix + 'Gift Wrap Tax',
      'giftwrapChargeback': marketplacePrefix + 'Gift Wrap Chargeback',
      'referralFee': marketplacePrefix + 'Referral Fee',
      'reserveCredit': marketplacePrefix + 'Reserve Credit',
      'reserveDebit': marketplacePrefix + 'Reserve Debit',
      'reversalReimbursement': marketplacePrefix + 'Reversal Reimbursement',
      'runLightningDealFee': marketplacePrefix + 'Run Lightning Deal Fee',
      'salesTaxCollectionFee': marketplacePrefix + 'Sales Tax Collection Fee',
      'shippingChargeback': marketplacePrefix + 'Shipping Chargeback',
      'subscription': marketplacePrefix + 'Subscription',
      'variableClosingFee': marketplacePrefix + 'Variable Closing Fee',
      'warehouseDamage': marketplacePrefix + 'Warehouse Damage Charge',
      'fBADisposalFee': fbaPrefix + 'Disposal Fee',
      'fBAInboundTransportationFee': fbaPrefix + 'Inbound Transportation Fee',
      'fBALongTermStorageFee': fbaPrefix + 'Long-Term Storage Fee',
      'fBAPerOrderFulfillmentFee': fbaPrefix + 'Per-Order Fulfillment Fee',
      'fBAPerUnitFulfillmentFee': fbaPrefix + 'Per-Unit Fulfillment Fee',
      'fBAStorageFee': fbaPrefix + 'Storage Fee',
      'fBAWeightBasedFee': fbaPrefix + 'Weight-Based Fee'
    }

    const grossRevenueDeductions = dataLanguage?.pnlSettings?.grossSales;
    const grossProfitDeductions = dataLanguage?.pnlSettings?.grossProfit;

    const refundsKeys = [fixedNames['refundCosts'], 'discountRefund', 'itemPriceRefund', 'referralFeeRefund', 'refundCommission', 'shippingChargebackRefund', 'taxRefund'];
    const marketKeys = [fixedNames['marketplaceFees'], 'couponRedemptionFee', 'giftWrap', 'giftWrapTax', 'giftwrapChargeback',
      'referralFee', 'reserveCredit', 'reserveDebit', 'reversalReimbursement', 'runLightningDealFee', 'salesTaxCollectionFee', 'shippingChargeback', 'subscription', 'variableClosingFee', 'warehouseDamage'];
    const fbaKeys = [fixedNames['fulfillmentFees'], 'fBADisposalFee', 'fBAInboundTransportationFee', 'fBALongTermStorageFee', 'fBAPerOrderFulfillmentFee', 'fBAPerUnitFulfillmentFee', 'fBAStorageFee', 'fBAWeightBasedFee'];



    const afterGrossRevenue : string[]= [];
    const afterGrossProfit: string[] = [];

    grossRevenueDeductions?.forEach((item) => {
      if(item?.name === 'REFUNDS'){
        afterGrossRevenue?.push(...refundsKeys)
      }
      if(item?.name === 'MARKETING_PLACE_FEES'){
        afterGrossRevenue?.push(...marketKeys)
      }
      if(item?.name === 'FULFILLMENT_FEES'){
        afterGrossRevenue?.push(...fbaKeys)
      }
    })

    grossProfitDeductions?.forEach((item) => {
      if(item?.name === 'REFUNDS'){
        afterGrossProfit?.push(...refundsKeys)
      }
      if(item?.name === 'MARKETING_PLACE_FEES'){
        afterGrossProfit?.push(...marketKeys)
      }
      if(item?.name === 'FULFILLMENT_FEES'){
        afterGrossProfit?.push(...fbaKeys)
      }
    })


    const objectNames = ['fulfillmentFeesBrake', 'marketplaceFeesBrake', 'refundCostsBrake'];

    const renamedTableData = financialTableData?.map(row => {
      const renamedRow = {};
      Object.keys(row).forEach(key => {
        if (!(key in fixedNames) && !objectNames?.includes(key)) {
          // skip this key
        }
        else if(objectNames?.includes(key)){
          Object.keys(row[key])?.forEach((subKey) => {
            renamedRow[subKey] = row[key][subKey]
          })
        }
        else if (key in fixedNames) {
          renamedRow[fixedNames[key]] = isNaN(row[key]) && typeof row[key] === 'number' ? '' : row[key];
        } else {
          renamedRow[key] = row[key];
        }
      });
      return renamedRow;
    });

    const orderArray = [dateFix, 'Gross Revenue', ...afterGrossRevenue, `Net Revenue ${currentCurrency}`, '(COGS)', 'Net Revenue %', 'Gross Margin %', 'Gross Profit', ...afterGrossProfit, 'Contribution Margin %', `Contribution Profit ${currentCurrency}`];

    const reorderObjects = (array?: any[], orderArray?: string[]) => {
      const orderedKeys = orderArray?.filter((key) =>
          array?.some((obj) => obj.hasOwnProperty(key))
      );
      return array?.map((obj) => {
        const orderedObj: { [key: string]: any } = {};
        orderedKeys?.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            orderedObj[key] = obj[key];
          }
        });
        return orderedObj;
      });
    };

    const renameKeys = (array?: any[], fixedNames?: { [key: string]: string }) => {
      const newArray = array?.map((obj) => {
        const newObj: { [key: string]: any } = {};
        Object.keys(obj).forEach((key) => {
          if (fixedNames?.hasOwnProperty(key)) {
            newObj[fixedNames[key]] = obj[key];
          } else {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      });
      return newArray;
    };



    return renameKeys(reorderObjects(renamedTableData, orderArray), fixedNames)
  }




  const renderPnLTableSection = () => {
    return (
      <>
        <styles.SectionHeader>
          <styles.Title>
            {t("PNL_FINANCIAL_TABLE", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={pnlOverviewMoreInfoDialogProps[1]}
              isMoreInfoDialogOpen={isFinancialTableMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={setIsFinancialTableMoreInfoDialogOpen}
            />
          </styles.Title>
          <styles.ButtonsWrapper>
            {tableShowBy === ShowBy.DATE ? (
              <>
                <Flex alignItems={"center"}>
                  <styles.ShowByText>
                    {t("GROUP_BY", { ns: "translation" })}
                  </styles.ShowByText>
                  <Box w={5} />
                  <Toggle
                    options={[
                      {
                        index: "day",
                        type: GroupBy.DAY,
                        title: t("DAY", { ns: "translation" }),
                      },
                      {
                        index: "week",
                        type: GroupBy.WEEK,
                        title: t("WEEK", { ns: "translation" }),
                      },
                      {
                        index: "month",
                        type: GroupBy.MONTH,
                        title: t("MONTH", { ns: "translation" }),
                      },
                    ]}
                    onChange={(option: ToggleOption) =>
                      setGroupByStatus(option.type)
                    }
                  />
                </Flex>
              </>
            ) : (
              <></>
            )}

            <Box w={5} />
            <Flex alignItems={"center"}>
              <styles.ShowByText>
                {t("SHOW_BY", { ns: "translation" })}
              </styles.ShowByText>
              <Box w={3} />
              <PanelMenu data={menuButtonsDataShowBy} title={tableShowBy} />
              <Box w={3} />
              <DownloadButton
                onClick={() => {}}
                data={handlePnlTableDownload()}
                fileName={`P&L - Financial Table - ${new Date()
                  .toISOString()
                  .slice(0, 10)}`}
              />
            </Flex>
          </styles.ButtonsWrapper>
        </styles.SectionHeader>
        <styles.SectionHeader>
          {/* <styles.ButtonsWrapper>
                        <DatepickerRange defaultsDate={DEFAULTS_DATES.LAST_30_DAYS} onApply={(date) => { handleChangePnlTableDates(date); }} />
                        <div style={{ width: "10px" }} />
                        <PanelMenu icon={(color: string) => <GiFunnel color={color} />} title={"CHANNEL"} options={["AMAZON_CA_US", "AMAZON_CA", "SHOPIFY_US", "SHOPIFY_UK"]} onSelect={() => { }} />
                        <div style={{ width: "10px" }} />
                        <PanelMenu icon={(color: string) => <BsFillFilterSquareFill color={color} />} title={"PRODUCT"} options={["PRODUCT_A", "PRODUCT_B", "PRODUCT_C", "PRODUCT_D"]} onSelect={() => { }} />
                    </styles.ButtonsWrapper> */}
        </styles.SectionHeader>
        {!!financialTableData && !!tableShowBy  ? (
            <PnlTable headers={HEADERS} data={financialTableData} showBy={tableShowBy}/>
        ) : (
          <Flex justifyContent={"center"} alignItems="center" height={"600px"}>
            <Spinner />
          </Flex>
        )}
      </>
    );
  };

  const getDataToDownload = (
    tableType: TableType
  ): { name?: string; data?: any[] } => {
    switch (tableType) {
      case TableType.DEDUCTION:
        return { name: "Deductions Breakdown", data: deductionTableData };
      case TableType.MARKETING:
        return { name: "Marketing Breakdown", data: marketingTableData };
      case TableType.COGS:
        return { name: "COGS Breakdown", data: cogsTableData };
      default:
        return { name: "Deductions Breakdown", data: deductionTableData };
    }
  };

  const renderPnLBreakDownSection = () => {
    const fileData = getDataToDownload(tableType);
    return (
      <>
        <styles.SectionHeader>
          <styles.Title>
            {t("PNL_EXPENSES_BREAKDOWN", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={pnlOverviewMoreInfoDialogProps[2]}
              isMoreInfoDialogOpen={isExpensesBreakdownMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={
                setIsExpensesBreakdowneMoreInfoDialogOpen
              }
            />
          </styles.Title>
          <styles.ButtonsWrapper>
            <Toggle
              options={[
                {
                  index: "deduction",
                  type: TableType.DEDUCTION,
                  title: t("Deduction", { ns: "translation" }),
                },
                {
                  index: "cogs",
                  type: TableType.COGS,
                  title: t("COGS", { ns: "translation" }),
                },
                {
                  index: "marketing",
                  type: TableType.MARKETING,
                  title: t("Marketing", { ns: "translation" }),
                },
              ]}
              onChange={(option: ToggleOption) => setTableType(option.type)}
            />
            <Box w={1} />
            <DownloadButton data={handlePnlTableDownload()} fileName={`P&L - Financial Table - ${new Date()
                .toISOString()
                .slice(0, 10)}`} />
          </styles.ButtonsWrapper>
        </styles.SectionHeader>
        <Box h={"30px"} />
        {tableType === TableType.COGS ? (
          !!cogsTableData &&
          !!organization?.customCogs && (
              <>
            <PnlBreakdownTableCogs
              customCogs={organization?.customCogs}
              data={cogsTableData}
              tableShowBy={tableShowBy}
              groupByStatus={groupByStatus}
              from={"COGS"}
            />
                {renderNumberOfRows(cogsTableData?.length)}

              </>
          )
        ) : tableType === TableType.MARKETING && !!marketingTableData ? (
            <>
          <PnlBreakdownTableDeductions
              tableShowBy={tableShowBy}
            data={marketingTableData}
            labels={allMarketingDeductionLabels}
            groupByStatus={groupByStatus}
            from={"Marketing & Others"}
          />
              {renderNumberOfRows(marketingTableData?.length)}

            </>
        ) : (
          !!deductionTableData && (
              <>
            <PnlBreakdownTableDeductions
              tableShowBy={tableShowBy}
              data={deductionTableData}
              labels={allDeductions}
              groupByStatus={groupByStatus}
              showDeductionColumn={true}
              from={"Deductions"}
            />
                {renderNumberOfRows(deductionTableData?.length)}
              </>
          )
        )}
        <Box width={"100%"} height={65} />
      </>
    );
  };


  const renderNumberOfRows = (numberOfRows?: number) => {
    return (
        <Flex w={"100%"} justifyContent={"flex-start"}>
          {numberOfRows ?? 0} total rows
        </Flex>
    )
  }

  return (
    <styles.Wrapper>
      <styles.SmallerWrapperBackground>
        {renderPnLWaterfallSection()}
        {renderSplitter(1)}
        <Box h={"55px"} />
        {renderPnLTableSection()}
        {renderSplitter(1)}
        {renderNumberOfRows(financialTableData?.length)}
        <Box h={"55px"} />

        {renderPnLBreakDownSection()}
      </styles.SmallerWrapperBackground>
    </styles.Wrapper>
  );
};

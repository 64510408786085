import styled from "styled-components";

export namespace styles {
    export const Wrapper = styled.div`
      height: 100%;
      width: 100%;
      display: flex;
    `;
}

export default styles;

// local
interface Todo {
  code?: number;
  value?: string;
  type?: string;
  id?: string;
}

// state
export interface TodosState {
  data?: Todo[];
  loading: boolean;
  error?: string;
}

// initial state
export const initalTodos: TodosState = {
  data: undefined,
  loading: false,
  error: undefined,
};

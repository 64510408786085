import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    font-size: 12px;

    height: 400px;
    overflow-y: auto;
  `;
  export const HeaderTr = styled.tr`
    border-bottom: 1px solid #e3e6eb;
    height: 64px;
    position: sticky;
    top: 0;
  `;
  export const ModalTitle = styled.div`
    color: ${(props) => props?.theme?.primaryColor};
    font-family: ${(props) => props?.theme?.fonts?.roboto?.regular};
  `;
  export const ModalSubTitle = styled.div`
    color: #1a0989;
    font-size: 16px;
    font-weight: lighter;
    font-family: ${(props) => props?.theme?.fonts?.roboto?.regular};
  `;
  export const ModalBodyWrapper = styled.div`
    display: flex;
    width: 100%;
    font-family: ${(props) => props?.theme?.fonts?.roboto?.regular};
    margin: 0 auto;
    justify-content: space-between;
    flex-direction: column;
  `;
  export const BoldText = styled.div`
    font-weight: bolder;
  `;
  export const CenterIconHolder = styled.div<{
    first?: boolean;
    last?: boolean;
  }>`
    display: flex;
    justify-self: center;
    position: relative;
    width: 2px;
    background: ${(props) =>
      props?.first
        ? `linear-gradient(to bottom, white, white 50%, ${props?.theme?.primaryColor} 50%, ${props?.theme?.primaryColor})`
        : props?.last
        ? `linear-gradient(to top, white, white 50%, ${props?.theme?.primaryColor} 50%, ${props?.theme?.primaryColor})`
        : props?.theme?.primaryColor};

    /*background-color: ${(props) => props?.theme?.primaryColor};*/
    z-index: 5;
  `;
  export const ModalBodyPartContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    padding: 0 0 0 15px;
    height: 100%;
    z-index: 3;
    position: relative;
  `;
  export const PaddingAndTableWrapper = styled.div`
    box-shadow: 2px 13px 54px #695f9714;
    width: 100%;
    padding: 0 0 12px 0;
  `;
  export const FlexTdCell = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
  `;
  export const TablePadding = styled.div`
    height: 8px;
    border-radius: 20px 20px 0 0;
    background-color: #ffffff;
  `;
  export const ThreeDotHolder = styled.div`
    &:hover {
      cursor: pointer;
    }
  `;
  export const StatusCell = styled.td`
    max-width: 150px;
    &:hover {
      cursor: pointer;
    }
  `;
  export const StatusBadge = styled.div<{ color?: string }>`
    background-color: ${(props) => props?.color};
    min-width: max-content;
    width: 75px;
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50px;
  `;
  export const NumberBadge = styled.div<{
    color: string;
    active: boolean;
    backgroundColor: string;
  }>`
    color: ${(props) => (props.active ? props.color : "black")};
    background-color: ${(props) =>
      props.active ? props.backgroundColor : "#DDDDDD"};
    width: 45px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  `;
  export const statusCell = styled.div<{ color: string }>`
    color: ${(props) => props.color};
  `;
  export const FlexCell = styled.td``;
  export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  `;
  export const HeaderTh = styled.th`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 14px;
    max-width: 75px;
    font-weight: lighter;
    background-color: #f4f7fc;
    border: 1px solid #e2e4e8;
  `;
  export const Tab = styled.div<{ selected?: boolean; color: string }>`
    width: 50%;
    height: 57px;
    background-color: ${(props) => (props.selected ? "#FFFFFF" : "#EDEDED")};
    border: 2px solid #d9d9d9;
    display: flex;
    justify-content: flex-start;
    gap: 28px;
    align-items: center;
    color: ${(props) => (props.selected ? "#000000" : "#7A86A1")};
    border-right: unset;
    border-bottom: ${(props) =>
      props?.selected ? `2px solid ${props?.color}` : "2px solid #d9d9d9"};
    border-top: ${(props) => (props?.selected ? "2px solid #d9d9d9" : "unset")};

    &: last-of-type {
      border-right: 2px solid #d9d9d9;
    }

    &: first-of-type {
      //border-left: unset;
    }

    &: hover {
      cursor: pointer;
    }
  `;
  export const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const TableTd = styled.td`
    max-width: 75px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  export const Title = styled.title`
    display: flex;
    //width: 100%;
    color: black;
    font-size: 21px;
    font-weight: bold;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
    overflow: hidden;
    white-space: nowrap;
  `;
  export const HeaderWrapper = styled.div`
    display: flex;
  `;
  export const TableTr = styled.tr`
    height: 50px;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #e7eaee;
    z-index: 2;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const StyledTable = styled.table`
    width: 100%;
    table-layout: fixed;
  `;
  export const StyledTotalFooter = styled.td`
    text-align: center;
  `;
  export const OrderLink = styled.a`
    text-decoration: underline;
    color: #71b1d9;
  `;
  export const Tag = styled.div`
    /* width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #95bd95;
    font-size: 10px;
    padding: 5px;
    border-radius: 19px;*/
  `;
  export const TableCell = styled.td`
    border: 1px solid #e2e4e8;
  `;
  export const FooterTr = styled.tr`
    border-top: 1px solid #e7eaee;
    position: sticky;
    bottom: 0;
    background-color: white;
  `;
  export const TotalValueCell = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    border-radius: 5px;
    box-shadow: 3px 17px 54px #695f9721;
  `;

  export const Circle = styled.div`
    position: absolute;
    width: 28px;
    height: 28px;
    left: 50%;
    display: flex;
    justify-self: center;
    align-items: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: ${(props) => props.theme.primaryColor};
  `;
  export const FooterRow = styled.tr``;
}

export default styles;

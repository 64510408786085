// state

import { DEFAULTS_DATES } from "components/datepicker-range/DatepickerRange";
import { addDays } from "date-fns";

import { KpisEnum } from "models/kpi-settings";
import {Toast} from "../../components/toast/Toast";

export enum ExploresEnum {
  PLACEHOLDER,
  PROFILE,
  KPI_FRAMEWORK,
  GENERAL,
  PLANNING,
  NET_REVENUE,
  PNL_SETTINGS,
  GROSS_REVENUE,
  CONVERSION_RATE,
  SESSIONS,
  COGS,
  MARKETING_MAPPING,
  SALES_CHANNELS,
  MARKETING_SPEND,
  BLENDED_ROAS,
  ORDERS,
  COST_PER_ORDER,
  AVERAGE_ORDERS_VALUE,
  GROSS_PROFIT,
  CONTRIBUTION_PROFIT,
  PENDING_DEVICES,
  HOLDING_DEVICES,
  APPROVED_DEVICES,
  NUMBER_OF_DEVICES,
  INCOMPLETE_ORDERS,
  REMAINING_COST,
  CAC,
  TRANSACTION_FEES,
  METRIC_EXPLORE
}

interface Account {
  createdAt?: string;
  updatedAt?: string;
  accountId?: string;
  status?: number;
  accountName: string;
  name?: string;
  countryCode?: string;
  integrationId?: string;
  displayName?: string;
  id?: string;
}

export interface UIState {
  defaultExplore: {
    isDefaultExploreOpen: boolean;
    exploreEnum?: ExploresEnum;
    stateToTransfer?: any;
  };
  secondaryExplore: {
    isSecondaryExploreOpen: boolean;
    exploreEnum?: ExploresEnum;
  };
  dateRange: {
    startDate?: string;
    endDate?: string;
    preset?: DEFAULTS_DATES;
    isDefaultValue: boolean;
  };
  selectedChannels?: string[];
  cardSelected: {
    isSelected?: boolean;
    //kpiNumber?: KpisEnum;
    workspaceKpiNumber?: KpisEnum;
    marketingKpiNumber?: KpisEnum;
  };
  isApplyBarOpen: boolean;
  isSecondaryApplyBarOpen: boolean;
  isApplyModalOpen: boolean;
  isSecondaryApplyModalOpen: boolean;
  accounts: { data: Account[]; error: boolean };
  loading: boolean;
  error?: string;
  globalLoading?: boolean;
  navigationTab?: string;
  appLoad: boolean;
  toasts: Toast[]
}

// initial state
export const initialUIState: UIState = {
  defaultExplore: {
    isDefaultExploreOpen: false,
    exploreEnum: undefined,
  },
  secondaryExplore: {
    isSecondaryExploreOpen: false,
    exploreEnum: undefined,
  },
  selectedChannels: [],
  cardSelected: {
    isSelected: false,
    workspaceKpiNumber: undefined,
    marketingKpiNumber: undefined,
  },
  isApplyBarOpen: false,
  isSecondaryApplyModalOpen: false,
  isApplyModalOpen: false,
  isSecondaryApplyBarOpen: false,
  dateRange: {
    startDate: addDays(new Date(), -30)?.toISOString().slice(0, 10),
    endDate: new Date()?.toISOString().slice(0, 10),
    preset: DEFAULTS_DATES.LAST_30_DAYS,
    isDefaultValue: true,
  },
  accounts: { data: [], error: false },
  loading: false,
  error: undefined,
  globalLoading: false,
  navigationTab: undefined,
  appLoad: false,
  toasts: []
};

import {AdDataTable, MarketingData, MarketingDataTable} from "@models";

// state
export interface MarketingState {
  data?: {
    performaceData: MarketingData;
    platformDataColumns: {
      Header?: string;
      columns?: { Header?: string; accessor?: string }[];
    };
    platformDataArray?: MarketingDataTable[];
    adDataTable: AdDataTable[];
  };
  loading: boolean;
  selectedRowsBreakdownTable: {
    selectedPlatforms: string[],
    selectedAccounts: string[],
    selectedCampaigns: string[],
    selectedAdSets: string[],
    selectedAds: string[],
  };
}

// initial state
export const initialMarketingState: MarketingState = {
  data: undefined,
  loading: false,
  selectedRowsBreakdownTable: {
    selectedPlatforms: [],
    selectedAccounts: [],
    selectedCampaigns: [],
    selectedAdSets: [],
    selectedAds: [],
  }
};

export const ToastSuccessIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_11850" data-name="Group 11850" transform="translate(-373 -3832)">
                <circle id="Ellipse_4" data-name="Ellipse 4" cx="12" cy="12" r="12" transform="translate(373 3832)" fill="#1ad598"/>
                <path id="check" d="M389.832,3839a1.1,1.1,0,0,0-.782.37c-1.8,1.943-3.442,3.849-5.17,5.75l-2.031-1.755a1.083,1.083,0,0,0-1.6.191,1.292,1.292,0,0,0,.177,1.723h0l2.843,2.451a1.078,1.078,0,0,0,1.516-.1c2.045-2.208,3.877-4.377,5.875-6.536a1.294,1.294,0,0,0,.015-1.733A1.1,1.1,0,0,0,389.832,3839Z" fill="#fff"/>
            </g>
        </svg>

    );
};

export const ToastWarningIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_11855" data-name="Group 11855" transform="translate(-373 -4072)">
                <circle id="Ellipse_4" data-name="Ellipse 4" cx="12" cy="12" r="12" transform="translate(373 4072)" fill="#f0bb09"/>
                <g id="Layer_2" data-name="Layer 2" transform="translate(377.371 4076.371)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_1089" data-name="Rectangle 1089" width="16" height="16" transform="translate(-0.371 -0.371)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(0.954 0.636)">
                        <g id="Group_11853" data-name="Group 11853">
                            <path id="Path_12743" data-name="Path 12743" d="M15.507,11.057a1.43,1.43,0,0,1-.381-.922V8.768A4.552,4.552,0,0,0,10.358,4,4.519,4.519,0,0,0,5.589,8.768v1.589a.827.827,0,0,1-.223.54L4.191,12.074A.636.636,0,0,0,4,12.519v2.289a.636.636,0,0,0,.636.636H8.164a2.225,2.225,0,0,0,4.387,0H16.08a.636.636,0,0,0,.636-.636V12.519a.636.636,0,0,0-.191-.445Zm-.064,3.115H5.272V12.805l.985-1.017a2.034,2.034,0,0,0,.6-1.43V8.768a3.255,3.255,0,0,1,3.5-3.5,3.285,3.285,0,0,1,3.5,3.5v1.367a2.734,2.734,0,0,0,.731,1.812l.858.858Z" transform="translate(-3.682 -3.364)" fill="#fff"/>
                            <path id="Path_12744" data-name="Path 12744" d="M4.271,7.447a5.436,5.436,0,0,1,2.066-4.26A.624.624,0,1,0,5.575,2.2,6.548,6.548,0,0,0,3,7.447a.636.636,0,0,0,1.272,0Z" transform="translate(-3 -2.042)" fill="#fff"/>
                            <path id="Path_12745" data-name="Path 12745" d="M34.717,2.127a.636.636,0,1,0-.763,1.017A5.372,5.372,0,0,1,36.02,7.4a.636.636,0,0,0,1.272,0,6.644,6.644,0,0,0-2.575-5.277Z" transform="translate(-23.941 -2)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
export const ToastErrorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_11851" data-name="Group 11851" transform="translate(-373 -3912)">
                <circle id="Ellipse_4" data-name="Ellipse 4" cx="12" cy="12" r="12" transform="translate(373 3912)" fill="#ea3a3d"/>
                <path id="Abgerundetes_Rechteck_1_Kopie" data-name="Abgerundetes Rechteck 1 Kopie" d="M387.122,3927.535,385,3925.414l-2.121,2.122a1,1,0,0,1-1.415-1.415l2.122-2.121-2.122-2.122a1,1,0,1,1,1.415-1.414l2.121,2.121,2.122-2.121a1,1,0,0,1,1.414,1.414L386.414,3924l2.121,2.121a1,1,0,1,1-1.414,1.415Z" fill="#fff"/>
            </g>
        </svg>
    );
};
export const ToastInfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_11852" data-name="Group 11852" transform="translate(-373 -4072)">
                <circle id="Ellipse_4" data-name="Ellipse 4" cx="12" cy="12" r="12" transform="translate(373 4072)" fill="#7031eb"/>
                <path id="info" d="M384,4089v-6a1,1,0,0,1,2,0v6a1,1,0,0,1-2,0Zm-.26-10.13a1.13,1.13,0,1,1,1.13,1.13A1.13,1.13,0,0,1,383.74,4078.87Z" fill="#fff"/>
            </g>
        </svg>
    );
};

import styles from "./styles";
import { useTranslation } from "react-i18next";

export interface GraphNodeCardProps {
    title: string;
    value: string;
    vsTargetPercentage: number;
    onClick?: () => void;
    isCollapsed?: boolean;
    isVisible?: boolean;
    isRoot?: boolean;
    isOnPath?: boolean;
    level?: number;
    index?: number;
    children?: GraphNodeCardProps[];
}

export const GraphNodeCard = (props: GraphNodeCardProps) => {

    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    // helpers
    const getBadgeColor = () => {
        if (props.vsTargetPercentage < 100) {
            return '#F7686A';
        }
        return '#61C322';
    };
    // renderers

    const renderVsTargetBadge = () => {
        const target: any = props?.vsTargetPercentage;
        return <styles.NodeCardBadge color={getBadgeColor()} isRoot={props.isRoot}>

            {
                `${parseFloat(target)?.toLocaleString("en", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}% 
                ${t('VS', { ns: 'translation' })} 
                ${t('TGT', { ns: 'translation' })} `
            }
        </styles.NodeCardBadge>;
    };


    return <styles.NodeWrapper isRoot={props.isRoot ?? false}
        isOnPath={props.isOnPath}
        onClick={() => props.onClick ? props.onClick() : console.log('')}>
        <styles.NodeCardTitle isRoot={props.isRoot ?? false}>
            {props.title}
        </styles.NodeCardTitle>
        <styles.NodeCardValueWrapper>
            <styles.NodeCardValue isRoot={props.isRoot ?? false}>
                {props.value}
            </styles.NodeCardValue>
            <div style={{width: 80, height:20}}>
                {renderVsTargetBadge()}
            </div>
        </styles.NodeCardValueWrapper>
    </styles.NodeWrapper>;

};

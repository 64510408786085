import styles from "./styles";
import { Box, Button, Flex, Menu, MenuItem, MenuList, Tag, Td, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import {MenuButton, MenuGenericProps} from "../menu-generic/MenuGenericButton";
import { Effort } from "../../models/effort";
import React, { useEffect, useMemo, useState } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { deleteEffort, effortsSelector, setNewEfforts } from "store/efforts";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import {usersData, usersDataSelector} from "store/users";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "@chakra-ui/react";
import {organizationSelector, userSelector} from "@store";
import {ThreeDotMenuButton} from "../3dot-menu-button/ThreeDotMenuButton";
import MenuGenericButton from "../menu-generic/MenuGenericButton";
import {getColor} from "../../utils/colors";
import {formatIsoDate} from "../../utils/date-format/dateFormat";
import Table, {ColumnDefinitionType} from "../wideview-table/WideviewTable";


interface Props {
    title?: string;
    headers: Array<ColumnDefinitionType<Effort, keyof Effort>>;
    data: Effort[];
    icon?: any;
    cameFromExplore?: boolean;
    onOpen?: any;

}

interface MenuProps {
    id: string;
    row?: any;
}

interface Data {
    col_0?: string;
    col_1?: string;
    col_2?: string;
    col_3?: string;
    col_4?: string;
    col_5?: string;
    col_6?: string;
    col_7?: string;
}

interface DataToShow {
    kpi?: string;
    title?: string;
    description?: string;
    assignee?: string;
    startDate?: string;
    endDate?: string;
    id?: string;
    createdAt?: string;
}

export enum Status {
    SOON,
    IN_PROGRESS,
    EXPIRING_SOON,
    EXPIRED,
}


export const EffortsTable = (props: Props) => {

    const {isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose} = useDisclosure();  //
    const dispatch = useDispatch();
    const [items, setItems] = useState<any>([]);
    const [effortsData, setEffortsData] = useState<Effort[]>([]);
    const [empty, setEmpty] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const organization = useSelector(organizationSelector);
    const allEfforts = useSelector(effortsSelector);
    const allUsers = useSelector(usersDataSelector);

    useEffect(() => {
        setItems([...props.data].reverse().slice(0, 5));
        setEmpty(false);
        //console.log(props.data); //TODO check kpi 
    }, [props.data]);

    useEffect(() => {
        const formatDateString = (date?:string) => {
           return formatIsoDate(date?.slice(0,10))
        }

        const findUser = (userId?: string) => {
            const foundUser = allUsers?.find((user) => user?.id === userId);
            return foundUser?.firstName +" " + foundUser?.lastName
        }


        if(!!props?.data && !!allUsers){
            const newEfforts = props?.data?.map((item, i) => {
                    return {...item,
                        createdAt: formatDateString(item?.createdAt),
                        startDate: formatDateString(item?.startDate),
                        endDate: formatDateString(item?.endDate),
                        assignee: findUser(item?.assignee),
                        status: !!item?.startDate && !!item?.endDate ?
                            renderStatusBadge(item?.startDate, item?.endDate, i) : ""

                    }
            })
            setEffortsData(newEfforts);
        }
    }, [props?.data, allUsers]);

    const getBadgeEnum = (startDate: string, endDate: string) => {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);
        const today = new Date();
        const difference = newEndDate.getTime() - newStartDate.getTime(); // total difference in milliseconds
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24)); // get total diffrence in days format

        if (newEndDate < today) {
            return Status.EXPIRED;
        }
        if (newStartDate > today) {
            return Status.SOON;
        }
        if (totalDays <= 2) {   // if end date is expiring in the next 2 days
            return Status.EXPIRING_SOON;
        } else {
            return Status.IN_PROGRESS;
        }
    };


    const users = useSelector(usersData);
    const [dataToEdit, setDataToEdit] = useState<DataToShow>({
        kpi: "",
        title: "",
        description: "",
        assignee: "",
        startDate: "",
        endDate: "",
        id: "",
        createdAt: ""
    });


    const dataToDataTable = (data: Effort[]): Data[] => {
        return data.map((row, index) => {
            return {
                col_0: row?.createdAt?.slice(0, 10),
                col_1: row?.kpiMetric,
                col_2: row?.description,
                col_3: row?.assignee,
                col_4: row?.status,
                col_5: row?.startDate?.slice(0, 10),
                col_6: row?.endDate?.slice(0, 10),
                id: row?.id
            };
        });
    };

    const findValue = (key: string, array: any[]) => {
        const currentColumn = array?.find((item) => {
            return item?.column?.Header === key;
        });
        return currentColumn?.value;
    };


    const headersToColumns = (headers: Array<ColumnDefinitionType<Effort, keyof Effort>>): Column<Data>[] => {
        return headers.map((header, index) => {
            return {Header: header, accessor: 'col_' + index.toString(), sortType: 'basic'} as Column<Data>;
        });
    };

    const handleDeleteEffort = (effortId: string) => {
        const newData = props?.data.filter((item) => {
            return item?.id !== effortId;
        });
        dispatch(setNewEfforts({data: newData}));
        !!organization?.id && dispatch(deleteEffort({id: effortId, organizationId: organization?.id}));
    };

    // hooks
    const data = useMemo<Data[]>(() => dataToDataTable(items), [items]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props.headers), [props.headers]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        /* page, */
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        /* state: { pageIndex, pageSize }, */
        state: {sortBy}
    } = useTable({columns, data /* initialState: { pageIndex: 0, pageSize: 5 } */}, useSortBy, /* usePagination */);

    // helpers

    // renderers



    const getBadgeText = (badgeEnum: Status) => {
        switch (badgeEnum) {
            case (Status.EXPIRED):
                return "Expired";
            case (Status.IN_PROGRESS):
                return "In Progress";
            case (Status.EXPIRING_SOON):
                return "Expiring Soon";
            case (Status.SOON):
                return "Waiting";
        }
    };

    const renderStatusBadge = (startDate: string, endDate: string, i: number) => {
        const badgeEnum: Status = getBadgeEnum(startDate, endDate);
        return (
            <styles.BadgeWrapper key={i} backgroundColor={getColor(badgeEnum).backgroundColor}>
                <styles.BadgeText color={getColor(badgeEnum).textColor}>
                    {getBadgeText(badgeEnum)}
                </styles.BadgeText>
            </styles.BadgeWrapper>
        );
    };

    const getUserNameById = (id?: string) => {
        //console.log("this user ", users.data); //TODO to clean the console
        if (!!users.data && !!id) {
            const currentUser = users.data?.filter((item) => item.id === id);
            if (!!currentUser[0]?.firstName && !!currentUser[0]?.lastName) {
                return currentUser[0]?.firstName + " " + currentUser[0]?.lastName;
            } else if (!!currentUser[0]?.firstName) {
                return currentUser[0]?.firstName;
            } else
                return "";
        }
        return "";
    };

    const itemMenu = () => {
        const menuButtonsData: MenuButton[] = [
            {
                title: "Edit",
                onClick: (id?:string) => {

                    const effortToEdit = allEfforts?.data?.find((effort) => effort?.id === id);

                    const title = effortToEdit?.kpiMetric;
                    const description = effortToEdit?.description;
                    const startDate = effortToEdit?.startDate?.slice(0,10);
                    const endDate = effortToEdit?.endDate?.slice(0,10);
                    const createdAt = effortToEdit?.createdAt;
                    const owner = effortToEdit?.assignee;

                    onEffortsOpen();
                    setDataToEdit({
                        kpi: title?.split("/")[0],
                        title: title?.split("/")[1],
                        description,
                        startDate,
                        endDate,
                        id: id,
                        createdAt,
                        assignee: owner
                    })
                }
            },
            {
                title: "Delete",
                onClick: (id?: string) => !!id && handleDeleteEffort(id)
            }
        ]
        return menuButtonsData
    };

    const CustomCard = React.forwardRef(({children, ...rest}: any, ref: any) => (
        <Box whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"} ref={ref} {...rest}>
            {children}
        </Box>
    ));

    const CustomToolTip = (props: { text: any; }) => {
        return (<Tooltip right={0} borderRadius={8} color="black" border={"1px solid #E2E8F0"} backgroundColor="white"
                         label={props.text.props.cell.value} placement="top-start">
            <CustomCard>{props.text}</CustomCard>
        </Tooltip>);
    };


    const fetchMoreData = () => {
        setLoader(true);
        setTimeout(() => {
            setItems(items.concat([...props.data].reverse().slice(items.length, items.length + 5)));
            setLoader(false);
        }, 1500);
        if ([...props.data].reverse().slice(items.length, items.length + 5).length === 0) {
            setEmpty(true);
        }
    };

    return (
        <>
            <EffortsModal cameFromEdit={true} isOpen={isEffortsOpen} onOpen={onEffortsOpen} onClose={onEffortsClose}
                          dataToShow={dataToEdit}/>

            <Table data={effortsData} columns={props.headers} menuButton={itemMenu()} />
            {props?.cameFromExplore ? <styles.StyledCreateEffortButton onClick={props?.onOpen}>Create New
                    Effort</styles.StyledCreateEffortButton>
                : <></>}
        </>
    )


    // const renderTable = () => {
    //     return <>
    //         <InfiniteScroll
    //             dataLength={items.length}
    //             next={fetchMoreData}
    //             hasMore={!empty}
    //             scrollableTarget="scrollableDiv"
    //             loader={<></>}
    //         >
    //             <styles.TableCustom variant='simple'  {...getTableProps()}>
    //                 <thead>
    //                     {headerGroups.map(headerGroup => {
    //                         return (<styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
    //                             {
    //                                 headerGroup.headers.map((column, i) => {
    //                                     if (column.Header === "id") {
    //                                         return (
    //                                             <styles.ThCustom {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} key={i}>
    //                                                 <span>
    //                                                     {column.isSorted
    //                                                         ? column.isSortedDesc
    //                                                             ? <VscTriangleDown />
    //                                                             : <VscTriangleUp />
    //                                                         : ''}
    //                                                 </span>
    //                                             </styles.ThCustom>
    //                                         );
    //                                     }
    //                                     return (<styles.ThCustom {...column.getHeaderProps(column.getSortByToggleProps())}>
    //                                         {/*<styles.ThCustomContent marginLeft={column.Header === "Status"}>*/}
    //                                             {column.render('Header')}
    //                                             <span>
    //
    //                                                 {column.isSorted
    //                                                     ? column.isSortedDesc
    //                                                         ? <VscTriangleDown />
    //                                                         : <VscTriangleUp />
    //                                                     : ''}
    //                                             </span>
    //                                         {/*</styles.ThCustomContent>*/}
    //                                     </styles.ThCustom>);
    //                                 })
    //                             }
    //                         </styles.HeaderTr>);
    //                     })}
    //                 </thead>
    //
    //
    //                 <styles.TbodyCustom id="scrollableDiv" {...getTableBodyProps()}>
    //                     {
    //                         rows.map((row, i) => {
    //
    //                             prepareRow(row);
    //                             return <styles.TrCustom {...row.getRowProps()}>
    //                                 {
    //                                     row.cells.map((cell, i) => {
    //                                         if (cell.column.Header === "id") {
    //                                             return <styles.TdCustom {...cell.getCellProps()}>
    //                                                 <Box key={i} padding="0"
    //                                                     width={"100%"} height={21} >
    //                                                     <MenuListRender id={cell.value} row={row} />
    //                                                 </Box>
    //                                             </styles.TdCustom>;
    //                                         }
    //                                         if (cell.column.Header === "Status") {
    //                                             return <styles.TdCustom   {...cell.getCellProps()}>
    //                                                 {renderStatusBadge(findValue('Start Date', row?.cells,), findValue('End Date', row?.cells), i)}
    //                                             </styles.TdCustom>;
    //                                         }
    //                                         if (cell.column.Header === "Description" || cell.column.Header === "Title") {
    //                                             return <styles.TdCustom  {...cell.getCellProps()}>
    //                                                 <CustomToolTip text={cell.render('Cell')} />
    //                                             </styles.TdCustom>;
    //                                         }
    //                                         if (cell.column.Header === "Owner") {
    //                                             return <styles.TdCustom {...cell.getCellProps()}>
    //                                                 {getUserNameById(cell.value)}
    //                                             </styles.TdCustom>;
    //                                         }
    //                                         if (cell.column.Header === "Created" || cell.column.Header === "Start Date" || cell.column.Header === "End Date") {
    //                                             return <styles.TdCustom {...cell.getCellProps()}>
    //                                                 {formatIsoDate(cell?.value)}
    //                                             </styles.TdCustom>;
    //                                         }
    //                                         return <styles.TdCustom {...cell.getCellProps()}>
    //                                             {cell.render('Cell')}
    //                                         </styles.TdCustom>;
    //                                     })
    //                                 }
    //                             </styles.TrCustom>;
    //                         })
    //                     }
    //                     {loader ? <styles.TrCustom role={"row"} style={{ width: "100%" }}>
    //                         <td >
    //                             <div id="my_box">
    //                                 <Spinner />
    //                             </div>
    //                         </td>
    //                     </styles.TrCustom> : <></>}
    //                 </styles.TbodyCustom>
    //             </styles.TableCustom>
    //         </InfiniteScroll >
    //     </>;
    // };
    //
    //
    // return <styles.Wrapper>
    //     <EffortsModal cameFromEdit={true} isOpen={isEffortsOpen} onOpen={onEffortsOpen} onClose={onEffortsClose} dataToShow={dataToEdit} />
    //     {renderTable()}
    //     {props?.cameFromExplore ? <styles.StyledCreateEffortButton onClick={props?.onOpen}>Create New Effort</styles.StyledCreateEffortButton>
    //         : <></>}
    // </styles.Wrapper>;


};

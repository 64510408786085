import styles from './styles';
import {Box, Select} from '@chakra-ui/react';
import {PageLink} from "../../features/marketing/creative-performance/pagination/page-link/PageLink";

interface Props {
    setNextPage?: any;
    setPreviousPage?: any;
    currentPage: number;
    setPage?: any;
    setSize?: any;
    maxPage: number;

}

export const PaginationMenu = (props: Props) => {
    //state

    //helper



    //render

    const renderSelectSize = () => {
        return (
            <Select onChange={(e) => {
                if(!!props?.setSize){
                    if(!e?.target?.value){
                        props?.setSize(20);
                    }else{
                        props?.setSize(parseInt(e?.target?.value));
                    }
                }
            }} width={'max-content'} placeholder='20'>
                <option value='50'>50</option>
                <option value='100'>100</option>
            </Select>
        )
    }

    return (
        <styles.PaginationNav aria-label='pagination'>
            <styles.AdsContext>
                <styles.DisplayingAdsPagination>
                    {`(page ${props.currentPage + 1} out of ${props?.maxPage + 1})`}
                </styles.DisplayingAdsPagination>
            </styles.AdsContext>

            <styles.PaginationList>
                <PageLink
                    //key={1}
                    href="#"
                    disabled={props.currentPage === 0}
                    onClick={() => {
                        if(!!props?.setPreviousPage){
                            props?.setPreviousPage((prev: number) => (prev - 1))
                        }
                    }}
                    isPrevOrNext={true}>
                    <styles.PreviousArrow />
                </PageLink>

                {
                    <styles.InputPaginationNumber
                        min={0}
                        max={props?.maxPage}
                        pattern={`[1-${props?.maxPage}]*`}
                        value={props?.currentPage + 1}
                        type={"number"}
                        onChange={(e: { target: { valueAsNumber: number; }; }) => {
                            props?.setPage(e?.target?.valueAsNumber)
                        }}

                        onBlur={(e: { target: { valueAsNumber: number; }; }) => {
                            props?.setPage(e?.target?.valueAsNumber)
                        }}
                    >
                    </styles.InputPaginationNumber>
                }
                <PageLink
                    href="#"
                    disabled={props.currentPage === props?.maxPage}
                    onClick={() => {
                        if(!!props?.setPreviousPage){
                            props?.setPreviousPage((prev: number) => (prev + 1))
                        }
                    }}
                    isPrevOrNext={true}>
                    <styles.NextArrow />
                </PageLink>
            </styles.PaginationList>
            {renderSelectSize()}

        </styles.PaginationNav>
    )
}
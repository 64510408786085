import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import { Effort, EffortsState } from "store/efforts";

export enum NotificationState {
  UNREAD,
  READ,
  CLICKED,
  IGNORE,
}

interface NotifcationPost {
  title: string;
  text: string;
  image: string;
  user: string;
  organization: string;
}

interface Notifcation {
  title: string;
  text: string;
  image: string;
  state: NotificationState;
  user: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}

interface Notifications {
  data: Notifcation[];
}

interface NotificationChangeState {
  notifications: string[];
  state: NotificationState;
}

const fetchNotifications = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<Notifications>(
    `${apiBaseUrl}/organization/${organizationId}/notification/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const createNotification = async ({
  data,
  organizationId,
}: {
  data: NotifcationPost;
  organizationId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/notification/`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deleteNotification = async ({
  id,
  organizationId,
}: {
  organizationId: string;
  id: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.delete<any>(
    `${apiBaseUrl}/organization/${organizationId}/notification/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const changeStateNotification = async ({
  data,
  organizationId,
}: {
  data: NotificationChangeState;
  organizationId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/notification/change-state`,
    {
      data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const notificationsService = {
  createNotification,
  changeStateNotification,
  fetchNotifications,
  deleteNotification,
};

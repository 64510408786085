// integrations images
import {collectorsBaseUrl} from "./general";
import {IntegrationPlatform} from "@models";

export const amazonAdsRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/amazon-ads-round-logo.svg`;
export const amazonSellerRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/amazon-seller-round-logo.svg`;
export const facebookAdsRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/facebook-ads-round-logo.svg`;
export const googleAdsRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/google-ads.svg`;
export const googleAnalyticsRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/google-analytics-round-logo.svg`;
export const hubspotRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/hubspot-round-logo.svg`;
export const jungleScoutRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/jungle-scout-round-logo.png`;
export const klaviyoRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/klaviyo-round-logo.png`;
export const magentoRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/magento-round-logo.svg`;
export const mondayRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/monday-round-logo.svg`;
export const notionRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/notion-round-logo.png`;
export const shopifyRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/shopify-round-logo.svg`;
export const similarWebRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/similarweb-round-logo.svg`;
export const tiktokRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/tiktok-round-logo.svg`;
export const woocommerceRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/woocommerce-round-logo.svg`;
export const skubanaRoundLogo = `https://wp.static-cdn-shsp.com/wp-content/uploads/2018/08/skubana-logo.jpg`;
export const shipbobRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/shipbob-round-logo.svg`;
export const walmartRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/walmart-round-logo.svg`;
export const floshipRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/floship-round-logo.png`;
export const fedexRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/fedex-round-logo.svg`;
export const shipstationRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/shipstation-round-logo.jpg`;
export const taboolaRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/taboola-round-logo.jpg`;
export const outbrainRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/outbrain-round-logo.jpg`;
export const quickbooksRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/quickbooks-round-logo.png`;
export const wixRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/wix-round-logo.png`;
export const ebayRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/ebay-round-logo.png`;
export const clickupRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/clickup-round-logo.png`;
export const slackRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/slack-round-logo.png`;
export const zohokCrmRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/zohocrm-round-logo.png`;
export const zohokBooksRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/zohobooks-round-logo.png`;
export const zohokInventoryRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/zohoinventory-round-logo.png`;
export const xeroRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/xero-round-logo.svg`;
export const globalAddressMeRoundLogo = `${process.env.PUBLIC_URL}/assets/integrations/round/gam-round-logo.svg`;

// integration status
export const integrationStatusConnected = `${process.env.PUBLIC_URL}/assets/integration-status/connected.svg`;
export const integrationStatusError = `${process.env.PUBLIC_URL}/assets/integration-status/error.svg`;
export const integrationStatusWarning = `${process.env.PUBLIC_URL}/assets/integration-status/warning.svg`;

export const premiumLogo = `${process.env.PUBLIC_URL}/assets/icons/premium.svg`;


//scopes
const slackScopes = ['app_mentions:read', 'channels:join', 'channels:read', 'chat:write', 'chat:write.public', 'files:write', 'im:write', 'users:read']
const ebayScopes = 'https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/buy.order.readonly https://api.ebay.com/oauth/api_scope/buy.guest.order https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.marketplace.insights.readonly https://api.ebay.com/oauth/api_scope/commerce.catalog.readonly https://api.ebay.com/oauth/api_scope/buy.shopping.cart https://api.ebay.com/oauth/api_scope/buy.offer.auction https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.email.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.phone.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.address.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.name.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.status.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.item.draft https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/sell.item https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly'
const zohoScopes = ['zohopulse.feedList.CREATE', 'zohopulse.feedList.READ', 'zohopulse.feedList.UPDATE','zohopulse.feedList.DELETE']
const xeroScopes = 'accounting.reports.read accounting.contacts.read assets.read payroll.payruns.read files.read assets.read projects.read '
// integrations auth urls
// export const mondayUrl = `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${collectorsBaseUrl}/monday/oauth/callback`
// export const notionUrl = `https://api.notion.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_NOTION_CLIENT_ID}&response_type=code&owner=user&redirect_uri=${collectorsBaseUrl}/notion/oauth/callback`
// export const tiktokAuthUrl =`https://ads.tiktok.com/marketing_api/auth?app_id=${process.env.REACT_APP_TIKTOK_APP_ID}&state=your_custom_params&redirect_uri=${collectorsBaseUrl}/tiktok/oauth/callback&rid=lzq23rrsugm`
// export const ebayUrl = `https://auth.sandbox.ebay.com/oauth2/authorize?client_id=${process.env.REACT_APP_EBAY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_EBAY_REDIRECT_URI}&scope=${ebayScopes}`
// export const clickupUrl = `https://app.clickup.com/api?client_id=${process.env.REACT_APP_CLICKUP_CLIENT_ID}&redirect_uri=${collectorsBaseUrl}/clickup/oauth/callback`
// export const slackUrl = `https://slack.com/oauth/v2/authorize?scope=${String(slackScopes)}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${collectorsBaseUrl}/slack/oauth/callback`
// // export const zohoUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=${String(zohoScopes)}&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&state=zzz&response_type=code&redirect_uri=${collectorsBaseUrl}/zoho/oauth/callback&access_type=offline`
// export const xeroUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${collectorsBaseUrl}/xero/oauth/callback&scope=${xeroScopes}`

export const mondayUrl = `https://auth.monday.com/oauth2/authorize?client_id=cb58c850672a99646791349feac2fb54&redirect_uri=${collectorsBaseUrl}/monday/oauth/callback`
export const notionUrl = `https://api.notion.com/v1/oauth/authorize?client_id=24671d70a13d&response_type=code&owner=user&redirect_uri=${collectorsBaseUrl}/notion/oauth/callback`
export const tiktokAuthUrl =`https://ads.tiktok.com/marketing_api/auth?app_id=7110073755916845057&state=your_custom_params&redirect_uri=${collectorsBaseUrl}/tiktok/oauth/callback&rid=lzq23rrsugm`
export const ebayUrl = `https://auth.sandbox.ebay.com/oauth2/authorize?client_id=omerwide-wideview-SBX-7a28fb95c-e36c12db}&response_type=code&redirect_uri=omer_wideview-omerwide-widevi-ebvuwtrv&scope=${ebayScopes}`
export const clickupUrl = `https://app.clickup.com/api?client_id=LX58J6ACO1V1V1Z7KZDM7ILPHBX612M0&redirect_uri=${collectorsBaseUrl}/clickup/oauth/callback`
export const slackUrl = `https://slack.com/oauth/v2/authorize?scope=${String(slackScopes)}&client_id=4392513933062.4446773153092&redirect_uri=${collectorsBaseUrl}/slack/oauth/callback`
// export const zohoUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=${String(zohoScopes)}&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&state=zzz&response_type=code&redirect_uri=${collectorsBaseUrl}/zoho/oauth/callback&access_type=offline`
export const xeroUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=8E09E95A52854BD28755B3E44329D5F2&redirect_uri=${collectorsBaseUrl}/xero/oauth/callback&scope=${xeroScopes}`



export const createZohoUrl = (platform: IntegrationPlatform ): string => {
    const zohoCrmScopes = ['zohopulse.feedList.CREATE', 'zohopulse.feedList.READ', 'zohopulse.feedList.UPDATE','zohopulse.feedList.DELETE']
    const zohoBooksScopes = ['ZohoBooks.contacts.READ', 'ZohoBooks.settings.READ', 'ZohoBooks.estimates.READ','ZohoBooks.invoices.READ','ZohoBooks.customerpayments.READ','ZohoBooks.creditnotes.READ', 'ZohoBooks.projects.READ', 'ZohoBooks.expenses.READ','ZohoBooks.salesorders.READ','ZohoBooks.purchaseorders.READ','ZohoBooks.bills.READ', 'ZohoBooks.debitnotes.READ', 'ZohoBooks.vendorpayments.READ','ZohoBooks.banking.READ','ZohoBooks.accountants.READ']
    const zohoInventoryScopes = ['ZohoInventory.contacts.READ', 'ZohoInventory.items.READ', 'ZohoInventory.compositeitems.READ', 'ZohoInventory.inventoryadjustments.READ', 'ZohoInventory.transferorders.READ', 'ZohoInventory.settings.READ', 'ZohoInventory.salesorders.READ', 'ZohoInventory.packages.READ', 'ZohoInventory.shipmentorders.READ', 'ZohoInventory.invoices.READ', 'ZohoInventory.customerpayments.READ', 'ZohoInventory.salesreturns.READ', 'ZohoInventory.creditnotes.READ', 'ZohoInventory.purchaseorders.READ', 'ZohoInventory.purchasereceives.READ', 'ZohoInventory.bills.READ', 'ZohoInventory.warehouses.READ']

    switch (platform) {
        case IntegrationPlatform.ZOHO_CRM:
            return `https://accounts.zoho.com/oauth/v2/auth?scope=${String(zohoCrmScopes)}&client_id=1000.308HAXL3LQINYXZ35MSNJZLHZRIDJN&state=${IntegrationPlatform.ZOHO_CRM}&response_type=code&redirect_uri=${collectorsBaseUrl}/zoho/oauth/callback&access_type=offline`;
        case IntegrationPlatform.ZOHO_BOOKS:
            return `https://accounts.zoho.com/oauth/v2/auth?scope=${String(zohoBooksScopes)}&client_id=1000.308HAXL3LQINYXZ35MSNJZLHZRIDJN&state=${IntegrationPlatform.ZOHO_BOOKS}&response_type=code&redirect_uri=${collectorsBaseUrl}/zoho/oauth/callback&access_type=offline`;
        case IntegrationPlatform.ZOHO_INVENTORY:
            return `https://accounts.zoho.com/oauth/v2/auth?scope=${String(zohoInventoryScopes)}&client_id=1000.308HAXL3LQINYXZ35MSNJZLHZRIDJN&state=${IntegrationPlatform.ZOHO_INVENTORY}&response_type=code&redirect_uri=${collectorsBaseUrl}/zoho/oauth/callback&access_type=offline`;
        default:
            return '';
    }

}
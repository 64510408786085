export const verifiyEmailBackground = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="369" height="182" viewBox="0 0 369 182">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1028" data-name="Rectangle 1028" width="369" height="182" transform="translate(11478 6429)" fill="#a4e574" />
                </clipPath>
                <linearGradient id="linear-gradient" x1="0.5" y1="4.863" x2="-0.47" y2="-0.521" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#5e00ff" />
                    <stop offset="0.711" stopColor="#7417ff" />
                    <stop offset="0.808" stopColor="#ad50ff" />
                    <stop offset="1" stopColor="#c569ff" />
                </linearGradient>
            </defs>
            <g id="Mask_Group_9" data-name="Mask Group 9" transform="translate(-11478 -6429)" clipPath="url(#clip-path)">
                <g id="Layer_2" data-name="Layer 2" transform="translate(11512.646 6388.408)" opacity="0.201">
                    <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
                        <path id="Path_1782" data-name="Path 1782" d="M260.687,0a39.658,39.658,0,0,1,34.828,58.642L230.751,188.385a39.72,39.72,0,0,1-72.21,0L150.265,171.8l-8.276,16.522a39.689,39.689,0,0,1-72.179,0L4.83,58.672A39.658,39.658,0,0,1,0,39.658H0A39.628,39.628,0,0,1,39.689,0Z" transform="translate(0 0)" fill="url(#linear-gradient)" />
                        <path id="Path_1783" data-name="Path 1783" d="M194.09,58.642,129.326,188.385a39.72,39.72,0,0,1-72.21,0L48.84,171.8,124.1,21.352A39.6,39.6,0,0,1,159.231,0h0A39.658,39.658,0,0,1,194.09,58.642Z" transform="translate(101.425 0)" fill="#00005b" />
                        <path id="Path_1784" data-name="Path 1784" d="M106.666,171.863,20.95,0H216.6L189.983,10.092Z" transform="translate(43.506 0)" fill="#00005b" opacity="0.2" />
                    </g>
                </g>
            </g>
        </svg>

    );
};
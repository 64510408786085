import { Kpi } from "../../models/kpi";
import { groupDatesByWeek, sumData } from "../get-months/getLastYear";
import { getDatesInRange } from "../colors";
import { KpisEnum } from "../../models/kpi-settings";
import { GroupBy } from "../../features/workspace/Workspace";
import { BlendedRoasReportShowBy } from "../../services/kpi/kpi.service";

export const sumMarketingNumbers = (data: any[], key: string) => {
  if (data.length > 0) {
    let dataFiltered;
    dataFiltered = data?.filter((item) => {
      return typeof item[key] === "number" || typeof item[key] === "string";
    });
    const result = dataFiltered.reduce((acc, obj) => {
      if (typeof obj[key] === "string") {
        if (Number.isNaN(parseInt(obj[key]))) {
          return;
        }
        return acc + parseInt(obj[key]);
      }
      return acc + obj[key]!;
    }, 0);
    return result;
  } else {
    return 0;
  }
};

export const dataForLastWeekChartFormat = (
  data: any[],
  title: string,
  week: any[],
  filteredByDay: { [key: string]: any[] }
) => {
  if (!!data) {
    const lastWeek = data.filter((item) => {
      if (week.includes(item.activityDate.slice(0, 10))) {
        return week.includes(item.activityDate.slice(0, 10));
      }
      return [];
    });
    week.forEach((day) => (filteredByDay[day.slice(0, 10)] = []));
    lastWeek.forEach((item) => {
      const day = item.activityDate.slice(0, 10);
      if (Array.isArray(filteredByDay[day])) {
        filteredByDay[day].push(item);
      }
    });
    const chartData = Object.entries(filteredByDay).map((entry) => {
      const [key, value] = entry;
      return sumMarketingNumbers(value, title);
    });
    if (chartData.length === 0) {
      return new Array(7).fill(0);
    }
    return chartData.reverse();
  }
  return [];
};

export const currencyFormat = (
  amount: number | string,
  decimals: number,
  currentCurrency: string
) => {
  if (typeof amount === "number") {
    if (currentCurrency === "") {
      return amount.toString();
    }
    return (
      currentCurrency +
      amount.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  } else {
    if (isNaN(parseInt(amount))) {
      return currentCurrency + 0;
    }
    return (
      currentCurrency +
      parseInt(amount)
        .toFixed(!!amount ? decimals : 0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
};

// data calculations
export const getDateFromTimestamp = (timestamp: string) => {
  return timestamp?.slice(0, 10)
}

export const arrangeDataByDate = (datesArray: string[], kpiDataByChannels: Kpi[]) => {
  const dataArray: any[] = [];
  kpiDataByChannels?.forEach((item: Kpi) => {
    if (datesArray.includes(getDateFromTimestamp(item?.activityDate))) {
      dataArray.push(item);
    }
  });
  return dataArray
}

export const sumAllDays = (dataByDate: any[]) => {
  const result = {}
  dataByDate.forEach((day) => {
    if (day?.activityDate?.slice(0, 10) in result) {
      result[day?.activityDate?.slice(0, 10)].push(day);
    } else {
      result[day?.activityDate?.slice(0, 10)] = [day];
    }
  });
  const newSummedDataArray: any[] = [];
  Object.keys(result).forEach((key) => {
    newSummedDataArray.push({
      ...sumData(result[key], []),
      activityDate: key,
    });
  });
  return newSummedDataArray
}

export const filterMissingDates = (datesArray: any[], newSummedDataArray: any[]) => {
  return datesArray.filter((day) => {
    return !newSummedDataArray.some((item) => item.activityDate === day);
  });
}

export const calculateData = (data: Kpi[], startDate: string, endDate: string, groupByStatus: GroupBy, selectedCard?: any): {labels: string[], data: any[]} => {

  const getKeyByEnum = {
    [KpisEnum.GROSS_REVENUE]: "grossRevenue",
    [KpisEnum.NET_REVENUE]: "netRevenue",
    [KpisEnum.GROSS_PROFIT]: "grossProfit",
    [KpisEnum.CONTRIBUTION_PROFIT]: "contributionProfit",
    [KpisEnum.AVERAGE_ORDERS_VALUE]: "aov",
    [KpisEnum.ORDERS]: "orders",
    [KpisEnum.CONVERSION_RATE]: "cr",
    [KpisEnum.MARKETING_SPEND]: "marketingCost",
    [KpisEnum.BLENDED_ROAS]: "grossRevenue",
    [KpisEnum.COST_PER_ORDER]: "grossRevenue",
    [KpisEnum.SESSIONS]: "sessions",
    [KpisEnum.COST_PER_ORDER]: "cpo",
    [KpisEnum.CUSTOMER_ACQUISITION_COST]: "cac",
  };
  const finalData: any[] = [];
  const result = {
    labels: [],
    data: []
  } as {labels: string[], data: any[]};
  const keysToIgnore = [
    "wvChannelId",
    "purchasePoint",
    "date",
    "country",
    "createdAt",
    "updatedAt",
    "id",
    "activityDate",
    "market",
  ];
  const datesArray = getDatesInRange(
      new Date(startDate),
      new Date(endDate)
  );
  const weeks: string[] = [];
  const flatWeekData: any[] = [];
  const dataByDate: any[] = arrangeDataByDate(datesArray, data);
  const newSummedDataArray = sumAllDays(dataByDate);

  const missingDays = filterMissingDates(datesArray, newSummedDataArray)

  const missingDaysData = missingDays.map((item) => {
    return {
      activityDate: item,
    };
  });

  const sortedByDates = [...missingDaysData, ...newSummedDataArray]
      .sort(
          (
              a: { activityDate: string | number | Date },
              b: { activityDate: string | number | Date }
          ) =>
              new Date(b.activityDate).getTime() -
              new Date(a.activityDate).getTime()
      )
      .reverse();

  let dataByYearAndWeeks: any;

  if (groupByStatus === GroupBy.YEAR) {
    const yearData = {};
    sortedByDates.forEach((item) => {
      if (yearData[item?.activityDate.slice(0, 4)]) {
        yearData[item?.activityDate.slice(0, 4)].push(item);
      } else {
        yearData[item?.activityDate.slice(0, 4)] = [item];
      }
    });
    const years: string[] = [];
    const flatData: any[] = [];
    const finalData: any[] = [];
    Object.keys(yearData).forEach((key) => {
      years.push(key);
      const currentDay = {
        ...sumData(yearData[key], keysToIgnore),
        activityDate: key,
      };
      flatData.push(currentDay);
    });
    flatData.forEach((item: any) => {
      if (selectedCard?.workspaceKpiNumber === KpisEnum.BLENDED_ROAS) {
        if (
            item?.grossRevenue !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.marketingCost);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.COST_PER_ORDER) {
        if (
            item?.orders !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          selectedCard?.workspaceKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE
      ) {
        if (
            item?.orders !== undefined &&
            item?.grossRevenue !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          selectedCard?.workspaceKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST || selectedCard?.marketingKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST
      ) {
        if (
            item?.customers !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.customers);
        } else {
          finalData.push(0);
        }
        return;
      }

      if (
          !!selectedCard?.workspaceKpiNumber &&
          !!item[getKeyByEnum[selectedCard.workspaceKpiNumber]]
      ) {
        finalData.push(item[getKeyByEnum[selectedCard.workspaceKpiNumber]]);
      } else {
        finalData.push(0);
      }
    });
    result.labels = years;
    result.data = finalData;
  }

  if (groupByStatus === GroupBy.MONTH) {
    const monthsData = {};
    sortedByDates.forEach((item) => {
      if (monthsData[item?.activityDate.slice(0, 7)]) {
        monthsData[item?.activityDate.slice(0, 7)].push(item);
      } else {
        monthsData[item?.activityDate.slice(0, 7)] = [item];
      }
    });
    const months: string[] = [];
    const flatData: any[] = [];

    Object.keys(monthsData).forEach((key) => {
      months.push(key);
      const currentDay = {
        ...sumData(monthsData[key], keysToIgnore),
        activityDate: key,
      };
      flatData.push(currentDay);
    });
    flatData.forEach((item: any) => {
      if (selectedCard?.workspaceKpiNumber === KpisEnum.BLENDED_ROAS || selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS) {
        if (
            item?.grossRevenue !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.marketingCost);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.COST_PER_ORDER || selectedCard?.marketingKpiNumber === KpisEnum.COST_PER_ORDER) {
        if (
            item?.orders !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          selectedCard?.workspaceKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE || selectedCard?.marketingKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE
      ) {
        if (
            item?.orders !== undefined &&
            item?.grossRevenue !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          selectedCard?.workspaceKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST || selectedCard?.marketingKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST
      ) {
        if (
            item?.customers !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.customers);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          !!selectedCard?.marketingKpiNumber &&
          !!item[getKeyByEnum[selectedCard.marketingKpiNumber]]
      ) {
        finalData.push(item[getKeyByEnum[selectedCard.marketingKpiNumber]]);
      }

      if (
          !!selectedCard?.workspaceKpiNumber &&
          !!item[getKeyByEnum[selectedCard.workspaceKpiNumber]]
      ) {
        finalData.push(item[getKeyByEnum[selectedCard.workspaceKpiNumber]]);
      } else {
        finalData.push(0);
      }
    });
    result.labels = months;
    result.data = finalData;
  }

  if (groupByStatus === GroupBy.WEEK) {
    dataByYearAndWeeks = groupDatesByWeek(sortedByDates);

    Object.keys(dataByYearAndWeeks).forEach((key) => {
      Object.keys(dataByYearAndWeeks[key]).forEach((dateRange) => {
        weeks.push(dateRange + "-" + key.slice(2));
        dataByYearAndWeeks[key][dateRange] = {
          ...sumData(dataByYearAndWeeks[key][dateRange], keysToIgnore),
          activityDate: dateRange,
        };
        flatWeekData.push(dataByYearAndWeeks[key][dateRange]);
      });
    });

    flatWeekData.forEach((item: any) => {
      if (selectedCard?.workspaceKpiNumber === KpisEnum.BLENDED_ROAS || selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS) {
        if (
            item?.grossRevenue !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.marketingCost);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.COST_PER_ORDER || selectedCard?.marketingKpiNumber === KpisEnum.COST_PER_ORDER) {
        if (
            item?.orders !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST || selectedCard?.marketingKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST) {
        if (
            item?.customers !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.customers);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          selectedCard?.workspaceKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE || selectedCard?.marketingKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE
      ) {
        if (
            item?.orders !== undefined &&
            item?.grossRevenue !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (
          !!selectedCard?.marketingKpiNumber &&
          !!item[getKeyByEnum[selectedCard.marketingKpiNumber]]
      ) {
        finalData.push(item[getKeyByEnum[selectedCard.marketingKpiNumber]]);
      } else {
        finalData.push(0);
      }

      if (
          !!selectedCard?.workspaceKpiNumber &&
          !!item[getKeyByEnum[selectedCard.workspaceKpiNumber]]
      ) {
        finalData.push(item[getKeyByEnum[selectedCard.workspaceKpiNumber]]);
      } else {
        finalData.push(0);
      }
    });

    result.labels = weeks;
    result.data = finalData;
  }

  if (groupByStatus === GroupBy.DAY) {
    const days: string[] = [];
    sortedByDates.forEach((item: any) => {
      days.push(item.activityDate.slice(0, 10));

      if (selectedCard?.workspaceKpiNumber === KpisEnum.BLENDED_ROAS || selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS) {
        if (
            item?.grossRevenue !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.marketingCost);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST || selectedCard?.marketingKpiNumber === KpisEnum.CUSTOMER_ACQUISITION_COST) {
        if (
            item?.customers !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.customers);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.COST_PER_ORDER || selectedCard?.marketingKpiNumber === KpisEnum.COST_PER_ORDER) {
        if (
            item?.orders !== undefined &&
            item?.marketingCost !== undefined
        ) {
          finalData.push(item?.marketingCost / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if (selectedCard?.workspaceKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE || selectedCard?.marketingKpiNumber === KpisEnum.AVERAGE_ORDERS_VALUE) {
        if (
            item?.orders !== undefined &&
            item?.grossRevenue !== undefined
        ) {
          finalData.push(item?.grossRevenue / item?.orders);
        } else {
          finalData.push(0);
        }
        return;
      }
      if(!!selectedCard?.marketingKpiNumber){
        if(!!item[getKeyByEnum[selectedCard.marketingKpiNumber]]){
        finalData.push(item[getKeyByEnum[selectedCard.marketingKpiNumber]]);
        }else {
          finalData.push(0);
        }
      }
      if (!!selectedCard?.workspaceKpiNumber) {
        if(!!item[getKeyByEnum[selectedCard.workspaceKpiNumber]]){
                finalData.push(item[getKeyByEnum[selectedCard.workspaceKpiNumber]]);
        }else {
          finalData.push(0);

        }
      }

    });
    // setLabels(days);
    // setActualDataChart(finalData);
    // setTargetDataChart(finalData);

    result.labels = days;
    result.data = finalData;

  }


  return result;
}


export const getOvertimeGraphDataForExplore = <T extends { activityDate?: string }>(data: T[],startDate:string,
                                                                                    endDate:string, groupByStatus: GroupBy, key: string,
                                                                                    percentageFormat?: {display: boolean; firstKey: string, secondKey: string},
                                                                                    blendedRoasShowBy?: BlendedRoasReportShowBy) => {
  const objToReturn:{data: any[], labels: string[]} = {data: [], labels: []};
  const dailyKpiData = JSON.parse(JSON.stringify(data));
  const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
  const datesArray = getDatesInRange(new Date(startDate), new Date(endDate));
  const weeks: string[] = [];
  const flatWeekData: any[] = [];
  const dataArray: any[] = [];
  dailyKpiData?.forEach((item: any) => {
      dataArray.push(item);
  });
  const sumAllDays = {};
  dataArray.forEach((day) => {
    if (day?.activityDate?.slice(0, 10) in sumAllDays) {
      sumAllDays[day?.activityDate?.slice(0, 10)]?.push(day);
    }
    else {
      sumAllDays[day?.activityDate?.slice(0, 10)] = [day];
    }
  });

  const newSummedDataArray: any[] = [];
  Object.keys(sumAllDays).forEach((key) => {
    newSummedDataArray.push({ ...sumData(sumAllDays[key], []), activityDate: key });

  });

  const missingDays = datesArray.filter((day) => {
    return !newSummedDataArray.some((item) => item?.activityDate === day);
  });
  const missingDaysData = missingDays.map((item) => {
    return {
      activityDate: item,
    };
  });

  const sortedByDates = [...missingDaysData, ...newSummedDataArray].sort((a: { activityDate: string | number | Date; }, b: { activityDate: string | number | Date; }) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()).reverse();
  console.log(sortedByDates)
  let dataByYearAndWeeks: any;

  if (groupByStatus === GroupBy.YEAR) {
    const yearData = {};
    sortedByDates.forEach((item) => {
      if (yearData[item?.activityDate?.slice(0, 4)]) {
        yearData[item?.activityDate?.slice(0, 4)].push(item);
      }
      else {
        yearData[item?.activityDate?.slice(0, 4)] = [item];
      }
    });
    const years: string[] = [];
    const flatData: any[] = [];
    const finalData: any[] = [];
    Object.keys(yearData).forEach(key => {
      years.push(key);
      const currentDay = { ...sumData(yearData[key], keysToIgnore), activityDate: key };
      flatData.push(currentDay);
    });
    flatData.forEach((item: any) => {
          if (key in item && !!item[key]) {
            finalData.push(item[key]);
          }
          else {
            finalData.push(0);
          }
        }
    );
    objToReturn.labels = years;
    objToReturn.data = finalData;

  }

  if (groupByStatus === GroupBy.MONTH) {
    const monthsData = {};
    sortedByDates.forEach((item) => {
      if (monthsData[item?.activityDate?.slice(0, 7)]) {
        monthsData[item?.activityDate?.slice(0, 7)].push(item);
      }
      else {
        monthsData[item?.activityDate?.slice(0, 7)] = [item];
      }
    });
    const months: string[] = [];
    const flatData: any[] = [];
    const finalData: any[] = [];

    Object.keys(monthsData).forEach(key => {
      months.push(key);
      const currentDay = { ...sumData(monthsData[key], keysToIgnore), activityDate: key };
      flatData.push(currentDay);
    });
    if(percentageFormat?.display){
      flatData.forEach((item: any) => {
            if (percentageFormat?.firstKey in item && !!item[percentageFormat.firstKey] && percentageFormat.secondKey && !!item[percentageFormat.secondKey]) {
              finalData.push(item[percentageFormat.firstKey] / item[percentageFormat.secondKey] * 100);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }else if(key === 'aov'){
      flatData.forEach((item: any) => {
            if ('grossRevenue' in item && !!item?.grossRevenue && 'orders' && !!item['orders']) {
              finalData.push(item?.grossRevenue / item?.orders);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }
    else if(key === 'cac'){
      flatData.forEach((item: any) => {
            if ('customers' in item && !!item?.customers && 'marketingCost' && !!item['marketingCost']) {
              finalData.push(item?.marketingCost / item?.customers);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }
    else if(key === 'cpo'){
      flatData.forEach((item: any) => {
            if ('marketingCost' in item && !!item?.marketingCost && 'orders' && !!item['orders']) {
              finalData.push(item?.marketingCost / item?.orders);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }
    else if(key === 'blendedRoas'){
      let revenueKey = 'grossRevenue';
      if(blendedRoasShowBy === BlendedRoasReportShowBy.NET_REVENUE){
        revenueKey = 'netRevenue'
      }
      if(blendedRoasShowBy === BlendedRoasReportShowBy.GROSS_PROFIT){
        revenueKey = 'grossProfit'
      }
      flatData.forEach((item: any) => {
            if (revenueKey in item && !!item?.grossRevenue && 'marketingCost' && !!item['marketingCost']) {
              finalData.push(item?.grossRevenue / item?.marketingCost);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }
    else {
      flatData.forEach((item: any) => {
            if (key in item && !!item[key]) {
              finalData.push(item[key]);
            }
            else {
              finalData.push(0);
            }
          }
      );
    }


    objToReturn.labels = months;
    objToReturn.data = finalData;

  }

  if (groupByStatus === GroupBy.WEEK) {
    dataByYearAndWeeks = groupDatesByWeek(sortedByDates);

    const getWeekData = (keyToSum: string) => {
      const data: number[] = [];
      Object.keys(dataByYearAndWeeks).forEach(key => {
        Object.keys(dataByYearAndWeeks[key]).forEach((dateRange) => {
          weeks.push(dateRange + "-" + key?.slice(2));
          dataByYearAndWeeks[key][dateRange] = { ...sumData(dataByYearAndWeeks[key][dateRange], keysToIgnore), activityDate: dateRange };
          flatWeekData.push(dataByYearAndWeeks[key][dateRange]);
        });
      });
      flatWeekData.forEach((item: any) => {
            if (keyToSum in item && !!item[keyToSum]) {
              data.push(item[keyToSum]);
            }
            else {
              data.push(0);
            }
          }
      );
      return data
    }
    const getWeekDataByKeys = (firstKey: string, secondKey: string, noPercentage?: boolean) => {
      const data: number[] = [];
      Object.keys(dataByYearAndWeeks).forEach(key => {
        Object.keys(dataByYearAndWeeks[key]).forEach((dateRange) => {
          weeks.push(dateRange + "-" + key?.slice(2));
          dataByYearAndWeeks[key][dateRange] = { ...sumData(dataByYearAndWeeks[key][dateRange], keysToIgnore), activityDate: dateRange };
          flatWeekData.push(dataByYearAndWeeks[key][dateRange]);
        });
      });
      flatWeekData.forEach((item: any) => {
            if (firstKey in item && !!item[firstKey] && !!secondKey && !!item[secondKey]) {
              data.push(item[firstKey] / item[secondKey] * (noPercentage ? 1 : 100));
            }
            else {
              data.push(0);
            }
          }
      );
      return data
    }

    if(percentageFormat?.display){
      objToReturn.data = getWeekDataByKeys(percentageFormat.firstKey, percentageFormat.secondKey);
    }
    else if(key === 'aov'){

      objToReturn.data = getWeekDataByKeys('grossRevenue', 'orders', true)
    }
    else if(key === 'cac'){

      objToReturn.data = getWeekDataByKeys('marketingCost', 'customers', true)
    }
    else if(key === 'cpo'){

      objToReturn.data = getWeekDataByKeys('marketingCost', 'orders', true)
    }
    else if(key === 'blendedRoas'){
      let revenueKey = 'grossRevenue';
      if(blendedRoasShowBy === BlendedRoasReportShowBy.NET_REVENUE){
        revenueKey = 'netRevenue'
      }
      if(blendedRoasShowBy === BlendedRoasReportShowBy.GROSS_PROFIT){
        revenueKey = 'grossProfit'
      }
      objToReturn.data = getWeekDataByKeys(revenueKey, 'marketingCost', true)
    }
    else {
      objToReturn.data = getWeekData(key);
    }


    objToReturn.labels = weeks;


  }
  if (groupByStatus === GroupBy.DAY) {
    const finalData: any[] = [];
    const days: string[] = [];
    sortedByDates.forEach((item: any) => {
          days.push(item?.activityDate?.slice(0, 10));

          if(!!percentageFormat?.display){
            if(percentageFormat?.firstKey in item && percentageFormat.secondKey in item && !!item[percentageFormat.firstKey] && !!item[percentageFormat.secondKey]){
              const firstValue = item[percentageFormat.firstKey];
              const secondValue = item[percentageFormat.secondKey];
              finalData.push((firstValue / secondValue) * 100)
            }
            else {
              finalData.push(0);
            }
            return
          }

          if(key === 'blendedRoas'){
            let revenueKey = 'grossRevenue';
            if(blendedRoasShowBy === BlendedRoasReportShowBy.NET_REVENUE){
              revenueKey = 'netRevenue'
            }
            if(blendedRoasShowBy === BlendedRoasReportShowBy.GROSS_PROFIT){
              revenueKey = 'grossProfit'
            }
            if(revenueKey in item && 'marketingCost' in item){
              const grossRevenue = item[revenueKey];
              const marketingCost = item?.marketingCost;
              if(!!marketingCost && !!grossRevenue){
                finalData.push(grossRevenue / marketingCost);
              }else{
                finalData.push(0);
              }
            }else {
              finalData.push(0);

            }

          return
          }
      if(key === 'cpo'){
        let revenueKey = 'orders';
        if(revenueKey in item && 'marketingCost' in item){
          const orders = item[revenueKey];
          const marketingCost = item?.marketingCost;
          if(!!marketingCost && !!orders){
            finalData.push(marketingCost/ orders);
          }else{
            finalData.push(0);
          }
        }else {
          finalData.push(0);

        }

        return
      }
      if(key === 'cac'){
        let revenueKey = 'customers';
        if(revenueKey in item && 'marketingCost' in item){
          const customers = item[revenueKey];
          const marketingCost = item?.marketingCost;
          if(!!marketingCost && !!customers){
            finalData.push(marketingCost/ customers);
          }else{
            finalData.push(0);
          }
        }else {
          finalData.push(0);

        }

        return
      }
          if(key === 'aov'){
            if('aov' in item) {
              finalData.push(item?.aov);

            }
            else {
              if('grossRevenue' in item && 'orders' in item){
                const grossRevenue = item?.grossRevenue;
                const orders = item?.orders;
                if(!!orders && !!grossRevenue){
                  finalData.push(grossRevenue / orders);
                }else{
                  finalData.push(0);
                }
              }else {
                finalData.push(0);

              }
            }
          }

          if (key in item && !!item[key]) {
            finalData.push(item[key]);
          }
          else {
            if(key !== 'aov'){
              finalData.push(0);
            }
          }
        }
    );
    objToReturn.labels = days;
    objToReturn.data = finalData;
  }
  return objToReturn
}

export const newGetOvertimeGraphData = (kpiData?: any[], metricKey?: string, groupByStatus?: GroupBy, startDate?:string,
                                        endDate?:string,) => {

  const graphDataToReturn : {[key: string]: any} = {labels: [], data: []}

  const allDatesBetweenDates = getDatesBetween(startDate, endDate);
  const groupedDataByDays = groupDailyStatsByActivityDate(kpiData);
  const groupedDataWithMissingDays = addAllMissingDaysInDataAsEmpty(allDatesBetweenDates, groupedDataByDays)
  const summedDataAsArray = sumDataForDay(groupedDataWithMissingDays);
  if(groupByStatus === GroupBy.DAY){
    const dailyGraphData = calculateGraphDataForDaily(summedDataAsArray, metricKey);
    graphDataToReturn['labels'] = allDatesBetweenDates
    graphDataToReturn['data'] = dailyGraphData
  }
  if(groupByStatus === GroupBy.WEEK){
    const dataGroupedToWeeksAndYears = groupDatesByWeek(summedDataAsArray);
    graphDataToReturn['labels'] = getLabelsForGroupByWeek(dataGroupedToWeeksAndYears)
    graphDataToReturn['data']  = getDataForGroupByWeek(dataGroupedToWeeksAndYears, metricKey)
  }
  return graphDataToReturn
}

const groupDailyStatsByActivityDate = (kpiData?: any[]) : {[key: string] : []} => {
  const groupedDataToReturn = {};

  kpiData?.forEach((dailyKpi) => {
    const currentDailyKpiActivityDate = dailyKpi?.activityDate?.slice(0,10);
    if(currentDailyKpiActivityDate in groupedDataToReturn){
      groupedDataToReturn[currentDailyKpiActivityDate]?.push(dailyKpi)
    }
    else {
      groupedDataToReturn[currentDailyKpiActivityDate] = [dailyKpi]
    }
  })

  return groupedDataToReturn
}

const getDatesBetween = (startDateStr?: string, endDateStr?: string): string[] => {
  if(!!startDateStr && !!endDateStr){
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const dates: string[] = [];

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      dates.push(
          `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
      );
    }
    return dates;
  }
  return []

}

const addAllMissingDaysInDataAsEmpty = (allDays?: string[], groupedData?:  {[key: string] : any[]}) => {
  if(!!groupedData){
    allDays?.forEach((day) => {
      if(day in groupedData){
        return
      }
      else {
        groupedData[day] = [{activityDate: day+'T00:00:00.000Z'}]
      }
    })
    return groupedData
  }
  return groupedData
}

const sumArrayOfObjects = (objects: { [key: string]: any }[]): { [key: string]: any } => {
  const summedObject: { [key: string]: any } = {};


  objects.forEach((currentObject, index) => {
    for (const property in currentObject) {
      if (typeof currentObject[property] === 'number') {
        summedObject[property] = (summedObject[property] || 0) + currentObject[property];
      } else if (index === 0) {
        summedObject[property] = currentObject[property];
      }
    }
  });
  summedObject['objectsForDay'] = objects?.length
  return summedObject;
}

const sumDataForDay = (groupedDailyData?: { [key: string]: any[] }) => {
    const dataToReturn :any[] = [];
      if(!!groupedDailyData){
        Object.keys(groupedDailyData).forEach((key) => {
          const summedDataForDay =  sumArrayOfObjects(groupedDailyData[key]);
          dataToReturn?.push(summedDataForDay)
        })
      }

    return dataToReturn.sort((a, b) => {
      if (!a.activityDate && !b.activityDate) return 0;
      if (!a.activityDate) return 1;
      if (!b.activityDate) return -1;
      return new Date(a.activityDate).getTime() - new Date(b.activityDate).getTime();
    });
}

const calculateGraphDataForDaily = (summedDailyStatsPerDay?: any[], fieldKey?: string) => {
  const dataArrayToReturn : number[] = [];

  summedDailyStatsPerDay?.forEach((dailyKpi) => {
    dataArrayToReturn?.push(calculatePointForObject(dailyKpi, fieldKey))
  })
  return dataArrayToReturn;
}

const getLabelsForGroupByWeek = (groupedDataByYearAndWeek: {[year: string]: {[week: string]: any[]}}) => {
  const labels : string[] = [];
  Object.keys(groupedDataByYearAndWeek).forEach(key => {
    Object.keys(groupedDataByYearAndWeek[key]).forEach((dateRange) => {
      labels.push(dateRange + "-" + key?.slice(2));
    });
  });

  return labels
}

const getDataForGroupByWeek = (groupedDataByYearAndWeek: {[year: string]: {[week: string]: { [key: string]: any }[]}}, metricKey?: string) => {
  const dataToReturn : any[] = [];
  Object.keys(groupedDataByYearAndWeek).forEach(key => {
    Object.keys(groupedDataByYearAndWeek[key]).forEach((dateRange) => {
      const currentGroupedDataForWeek = groupedDataByYearAndWeek[key][dateRange];

      const summedGroupData = sumArrayOfObjects(currentGroupedDataForWeek);

      dataToReturn?.push(calculatePointForObject(summedGroupData))
    });
  });

  return dataToReturn
}

const calculatePointForObject = (kpiObject?: any, metricToFind?: string) => {
  const listOfMetricsToCalculateAverage = ['aov', 'cpo', 'blendedRoas'];

  if(!!metricToFind){
    if(metricToFind in kpiObject){
      if(listOfMetricsToCalculateAverage?.includes(metricToFind) && 'objectsForDay' in kpiObject){
        return kpiObject[metricToFind] / kpiObject['objectsForDay']
      }
      else {
        return kpiObject[metricToFind]
      }
    }
    else  {
      return 0
    }
  }
  else  {
    return 0
  }

}

import styled from "styled-components";

export namespace Styles {
    export const Wrapper = styled.div`
      width: 100%;
      margin-bottom: 20px;
    `

    export const Title = styled.div`
      font-size: 18px;
      font-weight: bold;
      border-bottom: solid 1px;
      border-color: ${props => props.theme.backgroundColor};
    `
}

export default Styles;

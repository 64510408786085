/* eslint-disable jsx-a11y/anchor-is-valid */
import sidebarStyles from "./styles";
import {NavItem} from "./nav-item/NavItem";
import {Spacer} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {BiIntersect, BiSitemap, BiTachometer, BiWindows,} from "react-icons/bi";
import {useState} from "react";
import {WideviewLogoWIcon} from "../../../utils/icons/wideivew-logo-w";
import {useSelector} from "react-redux";
import {authSelector, organizationSelector} from "@store";
import {FeaturesEnum} from "@models";

export interface Props {}

export const Sidebar = (props: Props) => {
  // hooks
  const { t } = useTranslation(["translation", "translation"]);
  const [activeItem, setActiveItem] = useState<string>("");
  const organization = useSelector(organizationSelector);
  const currentUser = useSelector(authSelector);
  // helpers

    const checkFeature = (featureEnum: FeaturesEnum) => {
        if(!organization?.plan?.features){
            return false
        }
        return !organization?.plan?.features?.includes(featureEnum)
    }

  // renderers
  const renderNavItems = () => {
        // '647ee2bebf0a33d79e985159' , '63e23d3b69b6e70a481186db'
        const allowedUsers : string[] = []

        const renderWideviewSubItems = () => {
            const itemsToReturn = [
                {
                    title: t("ORGANIZATION", { ns: "translation" }),
                    to: "/admin/panel/organization",
                },
                {
                    title: t("FINANCIAL", { ns: "translation" }),
                    to: "/admin/panel/financial",
                },

            ]

            if(!!currentUser?.user?.id && allowedUsers?.includes(currentUser?.user?.id)){
                itemsToReturn?.push( {
                    title: t("P&L Report", { ns: "translation" }),
                    to: "/admin/panel/pnl-report",
                })
            }

            return itemsToReturn
        }


    return (
      <>
        <NavItem
          item={{
            title: t("WORKSPACE", { ns: "translation" }),
            to: "/admin/panel/workspace",
            icon: BiWindows,
            setActiveItem: setActiveItem,
            activeItem: activeItem,
          }}
        />
        <NavItem
          item={{
            title: t("WIDE_VIEWS", { ns: "translation" }),
            to: "/admin/panel/organization",
            subItems: renderWideviewSubItems(),
            icon: BiIntersect,
            setActiveItem: setActiveItem,
            activeItem: activeItem,
          }}
        />
          <NavItem
              item={{
                  title: t("DEPARTMENTS", { ns: "translation" }),
                  to: "/admin/panel/marketing",
                  subItems: [
                      {
                          title: t("MARKETING", { ns: "translation" }),
                          to: "/admin/panel/marketing",
                          disabled: checkFeature(FeaturesEnum.MARKETING)
                      },
                      {
                          title: t("OPERATION", { ns: "translation" }),
                          to: "/admin/panel/operation",
                          disabled: checkFeature(FeaturesEnum.OPERATION)

                      },
                      { title: t('WEBSITE', { ns: 'translation' }), to: '/admin/panel/website', disabled: checkFeature(FeaturesEnum.WEBSITE) },
                  ],
                  icon: BiSitemap,
                  setActiveItem: setActiveItem,
                  activeItem: activeItem,
              }}
          />
        <NavItem
          item={{
            title: t("ACCOUNT_HEALTH", { ns: "translation" }),
            to: "/admin/panel/portal",
            subItems: [
              {
                title: t("PORTAL", { ns: "translation" }),
                to: "/admin/panel/portal",
              },
              {
                title: t("INTEGRATIONS", { ns: "translation" }),
                to: "/admin/panel/integrations",
              },
              {
                title: t("LANGUAGE", { ns: "translation" }),
                to: "/admin/panel/data-language",
              },
              {
                title: t("STRATEGY", { ns: "translation" }),
                to: "/admin/panel/strategy",
              },
              {
                title: t("OPERATIONS", { ns: "translation" }),
                to: "/admin/panel/operations",
              },
            ],
            icon: BiTachometer,
            setActiveItem: setActiveItem,
            activeItem: activeItem,
          }}
        />
      </>
    );
  };

  return (
    <sidebarStyles.Wrapper>
      <sidebarStyles.AppBarLogo>
        <WideviewLogoWIcon width={40} height={40} />
      </sidebarStyles.AppBarLogo>
      {renderNavItems()}
      <Spacer />
      {/*  <sidebarStyles.NavItemButtonWrapper > */} {/* // TODO V.2 */}
      {/*<sidebarStyles.NavItemButton>*/}
      {/*    {t('ACCOUNT_SETTINGS', { ns: 'translation' }) }*/}
      {/*</sidebarStyles.NavItemButton>*/}
      {/* <NavItem
                noMargin
                    item={{
                        margin: '0px',
                        title: t('ACCOUNT_SETTINGS', { ns: 'translation' }),
                        to: '/admin/panel/account-settings',
                        icon: BiCog,
                        setActiveItem: setActiveItem,
                        activeItem: activeItem
                    }} /> */}
      {/* </sidebarStyles.NavItemButtonWrapper> */}
    </sidebarStyles.Wrapper>
  );
};

import styled from "styled-components";
import {
  MenuButton,
  PopoverBody,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Button } from "react-day-picker";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    border-radius: 30px 30px 0 0;
    font-size: 12px !important;
    max-height: 400px;
    overflow-y: auto;
    ::-webkit-scrollbar-track {
      margin-top: 65px !important;
    }
  `;
  export const StyledCreateEffortButton = styled.button`
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    align-self: flex-end;
    margin: 0 10px 10px;
  `;
  export const HeaderTr= styled.tr`
    border-bottom: 1px solid #e3e6eb;
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 5;
  `;
  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px;
    height: auto;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;

  export const Header = styled.div`
    border-bottom: 1px solid #e3e6eb;
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 5;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  `;

  export const TableCustom = styled(Table)`
    max-height: 300px;
  `;

  export const TheadCustom = styled(Thead)`
    display: flex;
    gap: 5px;
    padding: 0;
  `;

  export const TbodyCustom = styled(Tbody)`
    //display: block;
    height: 300px;
    overflow-y: auto;
    padding: 0;
    /* ::-webkit-scrollbar {
      display: none;
    } */
  `;

  export const HideMenu = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
  `;
  export const BadgeWrapper = styled.div<{ backgroundColor: string }>`
    width: 110px;
    height: 30px;
    border-radius: 50px;
    /* opacity: 0.26; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    margin-inline: auto;

    padding: 5px 16px;
  `;

  export const BadgeText = styled.div<{ color: string }>`
    font-family: ${(props) => props.theme.fonts.menu};
    color: ${(props) => props.color};
    white-space: nowrap;
    
  `;

  export const ThCustom = styled.th`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 14px;
    background-color: #ffffff;
    max-width: 75px;
    padding: 0 10px;
    font-weight: lighter;
  `;
  export const StyledToolTip = styled(PopoverBody)`
    font-size: 10px;
    font-weight: bold;
    color: #1a0989;
    display: flex;
    align-items: center;
  `;
  export const ToolTipText = styled.div`
    margin: 0 0 0 6px;
  `;
  export const ToolTipWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `;
  export const ThCustomSmall = styled(Th)`
    width: 30px;
    text-transform: capitalize !important;
    font-size: 14px;
    padding: 0;
    border-bottom: 1px solid #7a86a1 !important;
  `;
  export const ThCustomContent = styled.span<{ marginLeft?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 !important;
    margin-left: ${(props) => (props.marginLeft ? "2rem" : 0)};
    color: #7a86a1;
  `;

  export const TrCustom = styled.tr`
    height: 84px;
    text-align: center;
    background-color: #fdfdff;
    border-bottom: 1px solid #e7eaee;
    z-index: 2;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const TdCustom = styled.td`
    max-width: 65px;
    font-size: 12px;
    text-align: center !important;
  `;
  export const CreatedTd = styled(Td)`
    border: none !important;
    font-size: 14px;
    text-align: center !important;
    padding: 0 15px;
    padding-left: 12px !important;
    width: 115px !important;
  `;
  export const TdCustomSmall = styled.td`
    width: 30px;
    border: none !important;
    font-size: 14px;
    overflow: hidden;
    padding: 0;
    text-align: center !important;
    /* display: flex;
    width: 100%; */
  `;
  export const TdCustomWithHover = styled.td`
    border: none !important;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* text-align: center !important; */
  `;
  export const ProfileImage = styled.img`
    border-radius: 50%;
    width: 34px;
    height: 34px;
    margin: 0 5px 0 15px;
  `;
  export const Dot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #c0bdcc;
  `;
  export const DotWrapper = styled.div`
    &:hover {
      cursor: pointer;
    }
  `;
  export const StyledMenuButton = styled(MenuButton)`
    padding: 0;
    background-color: white;
    &:active {
      /*  background-color: none;
      outline: none;
      border: none; */
    }
  `;
  // export const StyledButton = styled(Button)`
  //   background-color: none;
  // `;
}

export default styles;

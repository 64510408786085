import {createAsyncThunk} from "@reduxjs/toolkit";

// local
import {amazonSellerService} from "@services";
import {isHttpCodeSuccess} from "../../utils/network/helpers";
import {addIntegration, RootState} from "@store";
import {IntegrationPlatform, IntegrationType} from "@models";
import {amazonAdsService} from "../../services/amazon-ads/amazon-ads.service";
import {AmazonAdsRegions} from "./state";

// init amazon-seller-seller sdk
export const initAmazonSdk = createAsyncThunk<any, any>(
    'amazon-seller/initAmazonSdk',
    async (action, thunkAPI) => {
        try {
            amazonSellerService.initAmazonSdk();
            return;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

// amazon-seller-seller seller
export const getAmazonSellerRefreshToken = createAsyncThunk<any, {
    code: string | null;
    state: string | null;
    sellingPartnerId: string | null;
}>(
    'amazon-seller/getAmazonSellerRefreshToken',
    async ({code, state, sellingPartnerId}, thunkAPI) => {
        try {
            const response = await amazonSellerService.getAmazonSellerRefreshToken(code, state, sellingPartnerId);
            if (isHttpCodeSuccess(response.status) && !!response?.data) {
                const rootState = thunkAPI.getState() as RootState;
                if (!!rootState.organization?.organization?.id) {
                    thunkAPI.dispatch(addIntegration({
                        integration: {
                            name: '',
                            type: IntegrationType.STORES,
                            platform: IntegrationPlatform.AMAZON_SELLER,
                            object: response?.data,
                        },
                        organizationId: rootState.organization?.organization?.id
                    }));
                }
                return;
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const getAmazonAdsRefreshToken = createAsyncThunk<any, any>(
    'amazon-ads/getAmazonAdsRefreshToken',
    async (action, thunkAPI) => {
        try {
            const codeResponse = await amazonAdsService.getAmazonAdsCode();
            if (!!codeResponse.code) {
                const tokenResponse = await amazonAdsService.getAmazonAdsRefreshToken(codeResponse?.code, action?.regionCode);
                if (isHttpCodeSuccess(tokenResponse.status) && !!tokenResponse?.data) {
                    const rootState = thunkAPI.getState() as RootState;
                    if (!!rootState.organization?.organization?.id) {
                        thunkAPI.dispatch(addIntegration({
                            integration: {
                                name: '',
                                type: IntegrationType.MARKETING,
                                platform: IntegrationPlatform.AMAZON_ADS,
                                object: tokenResponse?.data,
                            },
                            organizationId: rootState.organization?.organization?.id
                        }));
                    }
                    return;
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

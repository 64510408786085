export const CloseAlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0 -0.5)">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_211" data-name="Rectangle 211" width="24" height="23" transform="translate(0 0.5)" fill="none" />
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(0.979 0.979)">
                    <path id="Path_165" data-name="Path 165" d="M12.771,3.958a8.812,8.812,0,1,1-8.812,8.813,8.813,8.813,0,0,1,8.812-8.813m0-1.958A10.722,10.722,0,1,0,20.4,5.14,10.771,10.771,0,0,0,12.771,2Z" transform="translate(-2 -2)" fill="#1a0989" />
                    <path id="Path_166" data-name="Path 166" d="M20.778,19.385l2.742-2.693a1.028,1.028,0,0,0,.1-1.322.93.93,0,0,0-1.469-.1l-2.742,2.742-2.742-2.742a.93.93,0,0,0-1.469.1,1.028,1.028,0,0,0,.1,1.322l2.742,2.693-2.742,2.693a1.028,1.028,0,0,0-.1,1.322.93.93,0,0,0,1.469.1l2.742-2.742L22.148,23.5a.93.93,0,0,0,1.469-.1,1.028,1.028,0,0,0-.1-1.322Z" transform="translate(-8.636 -8.615)" fill="#1a0989" />
                </g>
            </g>
        </svg>

    );
};
import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationService } from "@services";

export const fetchUsersData = createAsyncThunk<any, any>(
  "users/fetchUsers",
  async (payload, thunkAPI) => {
      if(!!payload){
          const response = await organizationService.fetchUsers(payload);
          return response.data;
      }
  }
);

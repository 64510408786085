// state
export interface ShopifyState {
    code?: string | null;
    loading: boolean;
    oAuthUrl: string | null;
    error?: string | null;
}

// initial state
export const initialAmazonState: ShopifyState = {
    code: null,
    loading: false,
    oAuthUrl: '',
    error: '',
}

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 0 20px;
  `;
  export const TextCard = styled.div`
    width: 100%;
    background-color: white;
    margin: 2px 0 0 0;
    padding: 12px 0;
  `;
  export const FooterHolder = styled.div`
    display: flex;
    border-radius: 5px;
    width: 100%;
  `;
  export const FooterCellWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
  `;
  export const FooterCell = styled.div`
    width: 20%;
    display: flex;
    border-right: 1px solid black;
    &:last-of-type {
      border: 0;
    }
    background-color: white;
    flex-direction: column;
    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  `;
  export const FooterText = styled.div`
    color: #1a0989;
    font-size: 16px;
    font-weight: bold;
  `;
  export const FooterNumbers = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const FooterNumberText = styled.div`
    color: #070036;
    font-weight: bold;
    font-size: 22px;
  `;
  export const FooterNumber = styled.div`
    color: #7031eb;
    font-size: 22px;
  `;
  export const Text = styled.div`
    width: 95%;
    margin: 0 auto;
    font-size: 14px;
  `;
  export const DataCardsHolder = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
  `;
  export const DataCardTextHolder = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    align-self: center;
    flex-direction: column;
  `;
  export const DataCardTitle = styled.div`
    color: #1a0989;
    font-weight: bold;
    font-size: 16px;
  `;
  export const DataCardNumber = styled.div`
    font-size: 24px;
    font-weight: bold;
  `;
  export const DataNumberHolder = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const DataNumberBlue = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #7031eb;
  `;
  export const DataCard = styled.div`
    display: flex;
    width: 20%;
    border-right: 1px solid black;
    &:last-of-type {
      border: 0;
    }
    background-color: #f8f5fc;
  `;
  export const Card = styled.div`
    width: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px 20px 0 0;
  `;
  export const BarHolders = styled.div`
    display: flex;
    height: 300px;
    width: 100%;
  `;
  export const BarHolder = styled.div`
    display: flex;
    width: 20%;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(235, 230, 245, 1) 0%,
      rgba(231, 224, 241, 1) 100%
    );
    border-right: 1px solid black;
    &:last-of-type {
      border: 0;
    }
  `;
  export const Spacer = styled.div`
    height: 65px;
    display: flex;
  `;

  export const SpacerBottom = styled.div`
    height: 90px;
    display: flex;
  `;

  export const ArrowHolder = styled.div<{ height?: number }>`
    position: absolute;
    bottom: ${(props) => props.height}px;
    right: 35px;
  `;
  export const ArrowHolderFlipped = styled.div<{ height?: number }>`
    position: absolute;
    bottom: ${(props) => props.height}px;
    top: 0;
    transform: rotate(90deg);
  `;

  export const Arrow = styled.div`
    position: relative; /*it important to set this to relative to be able to use :before in absolute*/
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #1a0989;
    background: linear-gradient(
      270deg,
      rgba(232, 217, 200, 0.9307073171065301) 0%,
      rgba(230, 221, 230, 0.8438725832129726) 74%
    );

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(
        270deg,
        rgba(232, 217, 200, 0.9307073171065301) 0%,
        rgba(230, 221, 230, 0.33126754119616597) 100%
      );

      left: 100%;
      top: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid rgba(232, 217, 200, 1);
    }
  `;

  export const SpacerCell = styled.div`
    width: 20%;
    background: linear-gradient(
      180deg,
      rgba(235, 230, 245, 1) 0%,
      rgba(231, 224, 241, 1) 100%
    );
    border-right: 1px solid black;
    &:last-of-type {
      border: 0;
    }
  `;
  export const SpacerCellBottom = styled.div`
    width: 20%;
    position: relative;
    background-color: #f3eefb;
    border-right: 1px solid black;
    &:last-of-type {
      border: 0;
    }
  `;
  export const Bar = styled.div<{ height: number }>`
    width: 65px;
    height: ${(props) => props.height}%;
    background: linear-gradient(
      180deg,
      rgba(111, 48, 233, 1) 0%,
      rgba(68, 28, 184, 1) 100%
    );
    border-radius: 5px;
    bottom: 0;
    left: 20px;
    position: absolute;
  `;
  export const CardHeader = styled.div`
    display: flex;
    width: 95%;
    margin: 0 auto;
  `;
  export const CardItem = styled.div`
    margin: 0 0 0 58px;
    display: flex;
    &:first-of-type {
      margin: 0;
    }
  `;

  export const PopoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const PopoverData = styled.div`
    display: flex;
  `;
  export const PopoverDate = styled.div`
    font-size: 11px;
    color: #7a86a1;
  `;
  export const PopoverCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
  `;
  export const PopoverNumber = styled.div`
    color: #7031eb;
    font-size: 17px;
    font-weight: bold;
  `;
  export const PopoverText = styled.div`
    color: #7031eb;
    font-size: 13px;
  `;
  export const PopoverNumberRight = styled.div`
    color: #1a0989;
    font-size: 17px;
    font-weight: bold;
  `;
  export const PopoverTextRight = styled.div`
    color: #1a0989;
    font-size: 13px;
  `;

  export const CardTextHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 5px;
  `;
  export const CardTitle = styled.div`
    font-size: 19px;
  `;
  export const CardNumber = styled.div`
    font-weight: bold;
    font-size: 31px;
  `;
  export const CardSvg = styled.div`
    width: 37px;
    height: 37px;
  `;
}

export default styles;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, Content, PrimaryText, SecondaryText, Text, VerifyButton, Wrapper, LottieHodler, BackgroundHolder, SignOut } from "./styles";
import { useTranslation } from "react-i18next";
import { WideviewLogo } from "@components";
import { LightTheme } from "@styles";
import { useDispatch, useSelector } from "react-redux";
import { authenticate, logout, userSelector } from "@store";
import { Navigate, useNavigate } from "react-router";
import animationData from '../../utils/lottie-animations/success.json';
import { verifiyEmailBackground } from "utils/icons/verifiy-email-background";
import { push } from "redux-first-history";
import { Flex } from "@chakra-ui/react";


export const VerifyAccountEmail = () => {

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    const init = () => {
        if (!user) {
            dispatch(authenticate({ token: null }));
            dispatch(push("/"));

        } else {

        }
    };

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    init();

    // selectors

    // effects

    // helpers
    const next = () => {
        navigate('/onboarding/setup');
    };
    const handleSignOut = () => {
        dispatch(logout({}));
    };

    // renders
    const renderCard = () => {
        return (
            <Card>
                {renderTitle()}
                <Text>
                    {/* <PrimaryText >
                        {`${t('YOUR_ACCOUNT_HAS_BEEN_CREATED', { ns: 'translation' })}`}
                        <br />
                        {`${t('SUCCESSFULLY', { ns: 'translation' })}`}
                    </PrimaryText>
                    <SecondaryText>
                        {`${t('WEVE_SENT_A_VERIFICATION_LINK_TO_YOUR_EMAIL_ADDRESS', { ns: 'translation' })}`}
                    </SecondaryText> //TODO .V2 RETURN VERIFY EMAIL  */}
                    <Flex flexDirection={"column"} height="60%" alignItems={"center"} justifyContent="space-between">
                        <PrimaryText >
                            {`${t('THANK_YOU', { ns: 'translation' })}`}
                            <br />
                            {`${t('WE_WILL_BE_IN_TOUCH_SOON', { ns: 'translation' })}`}
                        </PrimaryText>
                        {/* <SecondaryText>
                        {`${t('WEVE_SENT_A_VERIFICATION_LINK_TO_YOUR_EMAIL_ADDRESS', { ns: 'translation' })}`}
                    </SecondaryText> */}
                        <SignOut onClick={handleSignOut}>
                            Log Out
                        </SignOut>
                    </Flex>
                </Text>

                {/*  <VerifyButton>
                    <a onClick={() => next()} href={void (0)}>
                        {`${t('NEXT', { ns: 'translation' })?.toUpperCase()}`}
                    </a>
                </VerifyButton> */}

            </Card>

        );
    };

    const renderTitle = () => {
        const width = 210;  // change this number to change size of lottie
        const rightMargin = (450 - width) / 2;
        return (
            < LottieHodler >
                <BackgroundHolder>
                    {verifiyEmailBackground()}
                </BackgroundHolder>
                <div style={{ position: "absolute", right: rightMargin, top: 0 }}>
                    {/* < Lottie
                        options={defaultOptions}
                        height={width}
                        width={width}
                    /> */}
                </div>
            </LottieHodler >
        );
    };



    return (
        <Wrapper img={process.env.PUBLIC_URL + '/assets/onboarding-background.png'}>
            <Content>
                {renderCard()}
            </Content>
        </Wrapper>
    );
};

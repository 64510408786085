import {useTranslation} from "react-i18next";

import styles from "./styles";
import {ProgressDashboard} from "../../../components/progress-dashboard/ProgressDashboard";
import {useTheme} from "@styles";
import {v4 as uuidv4} from "uuid";
import {KpiRender} from "utils/kpi-render/kpiRender";
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {PopoverPlacement} from "components/small-wideview-card/SmallWideviewCard";
import {organizationDataLanguageSelector} from "@store";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {kpiStateSelector} from "store/kpis";
import {KpisEnum} from "models/kpi-settings";
import MoreInformation from "components/more-information/MoreInformation";
import {MoreInfoDialogProps} from "dialogs/more-info-dialog/MoreInfoDialog";
import groovyWalkAnimation from "./plane.json";
import {selectedChannelsSelector} from "../../../store/ui";
import InformationWrapper from "../../../components/InformationWrapper/InformationWrapper";
import BigWideviewCardsRender from "../../../utils/big-kpi-card-render/BigWideviewCardsRender";
import {SummarizeResponseDto} from "../../../services/kpi/kpi.service";
import StoreChannelFilter from "../../../components/store-channel-filter/StoreChannelFilter";
import {DatepickerRange, DEFAULTS_DATES} from "../../../components/datepicker-range/DatepickerRange";


interface TopKpis {
    website: number[];
    marketing: number[];
    finance: number[];
}

interface Props {
    topKpis?: TopKpis;
    doNotDisplayCard?: boolean;
    marketingTarget: number;
    websiteTarget: number;
    financeTarget: number;
}

enum Section {
    MARKETING,
    FIANCNE,
    WEBSITE,
    CUSTOMERS,
    OPERATIONS
}

export const DepartmentsTopKpis = (props: Props) => {

    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const kpis = useSelector(kpiStateSelector);


    //helpers

    // renderers
    const renderTitle = (title: string) => {
        return (
            <styles.Title>
                <InformationWrapper text={`${title} Top KPIs`} displayLongText/>
            </styles.Title>
        );
    };

    const renderDepartmentRow = (title: string, kpis?: SummarizeResponseDto[], displayFilters?: boolean) => {
        return (
            <styles.RelativeWrapper>

                <Flex flexDirection={'row'} justifyContent={'space-between'}>
                    {renderTitle(title)}

                    {displayFilters ? (<Flex flexDirection={'row'}><StoreChannelFilter/>
                        <div style={{width: "20px"}}/>
                        <DatepickerRange backgroundColor="white"
                                         defaultsDate={DEFAULTS_DATES.LAST_30_DAYS}
                                         onApply={range => {
                                         }}/></Flex>) : <></>}
                </Flex>

                <styles.DepartmentRowWrapper>
                    <BigWideviewCardsRender cardsData={kpis}/>
                </styles.DepartmentRowWrapper>
            </styles.RelativeWrapper>
        );
    };

    const companyCards = ['grossRevenue', 'blendedRoas', 'marketingCost', 'contributionProfit'];
    const salesCards = ['orders', 'quantity', 'aov'];
    const marketingCards = ['cpo', 'marketingCost', 'blendedRoas']
    const financeCards = ['grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit']
    return (
        <styles.Wrapper>
            <div style={{height: '25px'}}/>
            {renderDepartmentRow("Company",
                kpis?.bigWideviewCardsData?.filter((card) => !!card?.name && companyCards?.includes(card?.name)),
                true)}
            <div style={{height: '25px'}}/>
            {renderDepartmentRow("Sales", kpis?.bigWideviewCardsData?.filter((card) => !!card?.name && salesCards?.includes(card?.name)))}
            <div style={{height: '25px'}}/>
            {renderDepartmentRow("Marketing", kpis?.bigWideviewCardsData?.filter((card) => !!card?.name && marketingCards?.includes(card?.name)))}
            <div style={{height: '25px'}}/>
            {renderDepartmentRow("Finance", kpis?.bigWideviewCardsData?.filter((card) => !!card?.name && financeCards?.includes(card?.name)))}
            <div style={{height: '25px'}}/>
        </styles.Wrapper>
    );
};

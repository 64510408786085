// imports
import {createSlice} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearAmazonSellerState} from "./actions";
import {AmazonSellerState, initialAmazonSellerState} from "./state";

// reducers
function buildCleanAmazonStateReducer(builder: ActionReducerMapBuilder<AmazonSellerState>) {
    builder.addCase(clearAmazonSellerState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.code = undefined;
        return state;
    });
}

// slice
export const amazonSlice = createSlice({
    name: 'amazon',
    initialState: initialAmazonSellerState,
    reducers: {},
    extraReducers: (builder => {
        // non async
        buildCleanAmazonStateReducer(builder);

        // async
    }),
});

// reducer
export const amazonReducer = amazonSlice.reducer;

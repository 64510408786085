import { Line } from "react-chartjs-2";
import styles from "./styles";
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  PointStyle,
  Title,
  Tooltip,
} from "chart.js";

import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { IconClearFiltersArrow, IconClock } from "../../utils/icons/explore";
import { useSelector } from "react-redux";
import { organizationDataLanguageSelector, organizationSelector } from "@store";
import { currencyOptions } from "utils/currency/currencyOptions";
import { Box } from "@chakra-ui/react";
import { KpisEnum } from "models/kpi-settings";
import { Toggle, ToggleOption } from "components/toggle/Toggle";
import MoreInformation from "components/more-information/MoreInformation";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import Gantt from "../gantt/Gantt";
import { dateRangeSelector } from "../../store/ui";
import { formatIsoDate } from "../../utils/date-format/dateFormat";
import { useCurrencySign } from "../../utils/custom-hooks/useCurrencySign";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

enum PrefixType {
  CURRENCY,
  PERCENTAGE,
  NONE,
}

enum GroupBy {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export interface extraDataset {
  label: string,
  color?: string,
  dataset: number[]}

interface Props {
  actual?: number[];
  target?: number[];
  lastPeriod?: number[];
  labels: string[];
  prefix?: PrefixType;
  setStartDate?: any;
  setEndDate?: any;
  kpiEnum?: KpisEnum;
  groupByStatus: GroupBy;
  setGroupBy?: (e: any) => void;
  clearFilters?: any;
  setGanttLabels?: any;
  displayGantt?: true;
  exploreGraph?: true
  displayByPercentage?: boolean;
  extraDatasets?: extraDataset[]
}

const overTimeProp = {
  title: "Over Time",
  //subtitle: 'How to use this view?',
  description:
    "In this section, you will see your KPI`s actual value vs. the target.\nGreen parts will mark periods of time which indicate when the KPI was above target, and red parts will mark periods of time when the KPI was below target. ",
  //tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
  /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5-min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export const OverTimeGraph = (props: Props) => {
  const [canvasClickPosition, setCanvasClickPosition] = useState<any>(0);
  const [canvasClickEndPosition, setCanvasEndPosition] = useState<any>(0);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [counter, setCounter] = useState(0);
  const [chartHeight, setChartHeight] = useState<number>(10);
  const targetRef = useRef<any>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isOverTimeMoreInfoDialogOpen, setIsOverTimeMoreInfoDialogOpen] =
    useState(false);
  const [invisibleLineForBackground, setInvisibleLineForBackground] = useState<
    number[]
  >([]);
  const [ganttLabels, setGanttLabels] = useState<any>([]);
  const datesSelector = useSelector(dateRangeSelector);
  const currency = useCurrencySign();
  useEffect(() => {
    if (!!props?.setGanttLabels) {
      const chart = Chart.getChart(
        props?.exploreGraph ? "exploreOvertime" : "overtime"
      );
      const newGanttLabels = chart?.scales?.x?.ticks
        ?.map((item) => {
          return props?.labels[item?.value];
        })
        ?.filter((item) => !!item);
      setGanttLabels(newGanttLabels);
      /*props?.setGanttLabels(newGanttLabels)*/
    }
  }, [props?.groupByStatus, props?.actual, props?.exploreGraph]);

  useEffect(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setCanvasClickPosition(0);
    setCanvasEndPosition(0);
  }, [datesSelector?.startDate, datesSelector?.endDate]);

  useEffect(() => {
    const invisibleLineData: number[] = [];
    props?.actual?.forEach((actualData, i) => {
      if (!!props?.target) {
        if (actualData <= props?.target[i]) {
          invisibleLineData.push(actualData);
        } else {
          invisibleLineData.push(props?.target[i]);
        }
      }
    });
    setInvisibleLineForBackground(invisibleLineData);
  }, [props?.actual, props?.target]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current?.offsetWidth,
        height: targetRef.current?.offsetHeight,
      });
    }
  }, [targetRef.current?.offsetWidth]);

  const legendsLabels = (chart: any) => {
    return [
      {
        text: "Actual",
        datasetIndex: 1,
        boxWidth: 40,
        boxHeight: 40,
        usePointStyle: true,
        fillStyle: "#7031EB",
        border: "none",
        pointStyle: "rectRounded" as PointStyle,
        color: "rgb(218,32,32)",
      },
      /*  {
                 text: 'Target', datasetIndex: 2, boxWidth: 40,
                 boxHeight: 40,
                 usePointStyle: true,
                 fillStyle: '#C0BDCC',
                 pointStyle: 'rectRounded' as PointStyle,
                 color: 'rgb(0, 0, 0)',
 
             }, */
      {
        text: "Over Target",
        datasetIndex: 3,
        boxWidth: 40,
        boxHeight: 40,
        usePointStyle: true,
        fillStyle: "#DDF2D0",
        pointStyle: "rectRounded" as PointStyle,
        color: "rgb(0, 0, 0)",
      },
      {
        text: "Under Target",
        datasetIndex: 4,
        boxWidth: 40,
        boxHeight: 40,
        usePointStyle: true,
        fillStyle: "#FDDBDB",
        pointStyle: "rectRounded" as PointStyle,
        color: "rgb(0, 0, 0)",
      },
    ];
  };


  useEffect(() => {
    setCounter(0);
  }, []);

  useEffect(() => {
    function isAfter(date1: Date, date2: Date) {
      return date1 > date2;
    }
    if (
      !!props?.setEndDate &&
      !!props?.setStartDate &&
      !!startDate &&
      !!endDate &&
      counter % 2 === 0 &&
      props.groupByStatus === GroupBy.YEAR
    ) {
      const initalStartDate = new Date(startDate[0]);
      const initalEndDate = new Date(endDate[0]);
      if (isAfter(initalEndDate, initalStartDate)) {
        props.setStartDate(initalStartDate);
        props.setEndDate(initalEndDate);
      } else {
        props.setStartDate(initalEndDate);
        props.setEndDate(initalStartDate);
      }
    }
    if (
      !!props?.setEndDate &&
      !!props?.setStartDate &&
      !!startDate &&
      !!endDate &&
      counter % 2 === 0 &&
      props.groupByStatus === GroupBy.DAY
    ) {
      const initalStartDate = new Date(startDate[0]);
      const initalEndDate = new Date(endDate[0]);
      if (isAfter(initalEndDate, initalStartDate)) {
        props.setStartDate(initalStartDate);
        props.setEndDate(initalEndDate);
      } else {
        props.setStartDate(initalEndDate);
        props.setEndDate(initalStartDate);
      }
    }
    if (
      !!props?.setEndDate &&
      !!props?.setStartDate &&
      !!startDate &&
      !!endDate &&
      counter % 2 === 0 &&
      props.groupByStatus === GroupBy.WEEK
    ) {
      const startYear = startDate[0].slice(12, 14);
      const endYear = endDate[0].slice(12, 14);
      const initalStartDate = (
        startDate[0]?.slice(0, 2) +
        "/" +
        startDate[0].slice(3, 5) +
        "/20" +
        startYear
      ).split("/");
      const initalEndDate = (
        endDate[0].split("-")[1]?.slice(0, 2) +
        "/" +
        endDate[0].split("-")[1]?.slice(3, 5) +
        "/20" +
        endYear
      ).split("/");

      const startDateFormat = new Date(
        initalStartDate[2] + "/" + initalStartDate[1] + "/" + initalStartDate[0]
      );
      const endDateFormat = new Date(
        initalEndDate[2] + "/" + initalEndDate[1] + "/" + initalEndDate[0]
      );

      if (isAfter(endDateFormat, startDateFormat)) {
        props.setStartDate(startDateFormat);
        props.setEndDate(endDateFormat);
      } else {
        props.setStartDate(endDateFormat);
        props.setEndDate(startDateFormat);
      }

      return;
    } else if (props.groupByStatus === GroupBy.MONTH) {
      if (
        !!props?.setEndDate &&
        !!props?.setStartDate &&
        !!startDate &&
        !!endDate &&
        counter % 2 === 0
      ) {
        const initalStartDate = new Date(startDate[0].split(",").join(" "));
        const initalEndDate = new Date(endDate[0].split(",").join(" "));
        if (isAfter(initalEndDate, initalStartDate)) {
          props.setStartDate(initalStartDate);
          props.setEndDate(initalEndDate);
        } else {
          props.setStartDate(initalEndDate);
          props.setEndDate(initalStartDate);
        }
      }
    }
  }, [
    canvasClickEndPosition,
    canvasClickPosition,
    counter,
    endDate,
    startDate,
    props.groupByStatus,
  ]);

  // hooks
  const { t } = useTranslation(["translation", "translation"]);



  const plugin = {
    id: "corsair",
    afterInit: (chart: {
      corsair: { x: number; y: number; chartArea?: any };
    }) => {
      chart.corsair = {
        x: 0,
        y: 0,
      };
    },
    afterEvent: (
      chart: { corsair?: any; draw?: any; chartArea?: any },
      evt: { event: { x: any; y: any } }
    ) => {
      const {
        chartArea: { top, bottom, left, right },
      } = chart;

      const { x, y } = evt?.event;
      if (x < left || x > right || y < top || y > bottom) {
        chart.corsair = {
          x,
          y,
          draw: false,
        };
        chart.draw();
        return;
      }

      chart.corsair = {
        x,
        y,
        draw: true,
      };

      chart.draw();
    },
    afterDatasetsDraw: (
      chart: { corsair?: any; ctx?: any; chartArea?: any },
      _: any,
      opts: {
        width: number;
        dash: any;
        color: string;
        vertical: any;
        horizontal: any;
      }
    ) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart;
      if (!chart?.corsair?.draw) {
        return;
      }

      ctx.lineWidth = opts.width || 0;
      ctx.setLineDash(opts.dash || []);
      ctx.strokeStyle = opts.color || "black";

      ctx.save();
      ctx.beginPath();

      if (opts.vertical) {
        ctx.moveTo(chart?.corsair?.x, bottom);
        ctx.lineTo(chart?.corsair?.x, top);
      }
      if (opts.horizontal) {
        ctx.moveTo(left, chart?.corsair?.y);
        ctx.lineTo(right, chart?.corsair?.y);
      }
      ctx.stroke();
      ctx.restore();
    },
  };

  Chart.register(plugin);
  const getOrCreateTooltip = (chart: {
    canvas: {
      parentNode: {
        querySelector: (arg0: string) => any;
        appendChild: (arg0: any) => void;
      };
    };
  }) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(255, 255, 255, 1)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.maxWidth = "max-content";
      tooltipEl.style.minWidth = "150px";
      tooltipEl.style.boxShadow = "6px 18px 32px #695F9721";
      tooltipEl.style.zIndex = 30;

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context: { chart: any; tooltip: any }) => {
    // Tooltip Element
    const { chart, tooltip } = context;

    const tooltipEl = getOrCreateTooltip(chart);
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b: { lines: any }) => b.lines);
      const wrapper = document.createElement("div");
      wrapper.style.display = "flex";

      titleLines.forEach((title: string) => {
        let newTitle: any = title;
        if (props?.groupByStatus === GroupBy.DAY) {
          newTitle = formatIsoDate(title);
        }
        if (props?.groupByStatus === GroupBy.MONTH) {
          const monthDateArray = title?.split("-");
          const monthDateFormat = monthDateArray[1] + "-" + monthDateArray[0];
          newTitle = monthDateFormat;
        }
        const tr = document.createElement("tr");
        tr.style.borderWidth = "";

        const th = document.createElement("th");
        th.style.borderWidth = "";

        const text = document.createTextNode(newTitle.replace(",", " "));
        th.appendChild(text);
        tr.appendChild(th);
        wrapper.appendChild(tr);
      });

      const bodyWrapper = document.createElement("div");
      bodyWrapper.style.display = "flex";

      bodyLines.forEach((body: string, i: string | number) => {
        if (body[0]?.toLowerCase()?.includes("actualinvisible")) {
          return;
        }
        const colors = tooltip.labelColors[i];
        const colorHolder = document.createElement("div");
        const span = document.createElement("span");
        span.style.background = colors.borderColor;
        span.style.borderColor = "black";
        span.style.borderWidth = "1px";

        span.style.borderRadius = "10px";
        span.style.marginRight = "2px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";

        /* const column = document.createElement('div');
                column.style.backgroundColor = 'inherit';
                column.style.borderWidth = ""; */

        const cell = document.createElement("div");
        cell.style.borderWidth = "";
        cell.style.display = "flex";
        cell.style.flexDirection = "column";
        cell.style.padding = "5px";
        let valueString = body?.toString()?.split(":")[1];
        const noCurrencyEnums = [KpisEnum.ORDERS, KpisEnum.BLENDED_ROAS, KpisEnum.HOLD_DEVICES, KpisEnum.PENDING_DEVICES];
        if (!!props?.kpiEnum && !noCurrencyEnums?.includes(props?.kpiEnum) && !props?.displayByPercentage) {
          valueString = currency + valueString;
        }
        if(props?.displayByPercentage){
          valueString =  valueString+"%";

        }
        const text = document.createTextNode(valueString);
        const textHolder = document.createTextNode(
            body?.toString()?.split(":")[0]
        );
        colorHolder.appendChild(span);
        colorHolder.appendChild(textHolder);
        cell.appendChild(text);
        cell.appendChild(colorHolder);
        /* tr.appendChild(td); */
        bodyWrapper.appendChild(cell);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(wrapper);
      tableRoot.appendChild(bodyWrapper);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font

    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;

    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.minWidth = "80px";

    /* tooltipEl.style.color = "white"; */

    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
    tooltipEl.style.opacity = 1;
  };



  const COLORS = {
    MAIN_COLOR: "#7031EB",
    TARGET_COLOR: "#bca6ee",
  };
  // @ts-ignore
  // @ts-ignore


  // rgba(125,232,137,0.29)

  const renderDatasets = () :any => {
    return props!.extraDatasets?.map((dataset) => {
      return {
        label: dataset?.label,
        data: dataset?.dataset,
        borderColor: dataset?.color ?? COLORS.MAIN_COLOR,
        borderWidth: 1.5,
        showLine: false,
        width: "20px",
        height: "20px",
        tension: tension,

      }
    })

  }




  const getBelowGradient = (): CanvasGradient | undefined => {
    const ctx = (
      document.getElementById(
        props?.exploreGraph ? "exploreOvertime" : "overtime"
      ) as HTMLCanvasElement
    )?.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient?.addColorStop(0, "white");
    gradient?.addColorStop(0.5, "rgba(188,166,238,.2) ");
    gradient?.addColorStop(0.8, "rgba(188,166,238,.05) ");
    return gradient;
  };

  const getGreenGradient = (): CanvasGradient | undefined => {
    const ctx = (
      document.getElementById(
        props?.exploreGraph ? "exploreOvertime" : "overtime"
      ) as HTMLCanvasElement
    )?.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    /*gradient?.addColorStop(0, 'white');
        gradient?.addColorStop(1, 'rgba(100,255,100,0.5) ');*/
    gradient?.addColorStop(0, "rgba(207,242,185,1)");
    gradient?.addColorStop(0.3, "rgba(207,242,185,0.9) ");
    gradient?.addColorStop(0.8, "rgba(207,242,185,0.1) ");
    return gradient;
  };

  const getRedGradient = (): CanvasGradient | undefined => {
    const ctx = (
      document.getElementById(
        props?.exploreGraph ? "exploreOvertime" : "overtime"
      ) as HTMLCanvasElement
    )?.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient?.addColorStop(0, "rgba(255,200,200,1) ");
    gradient?.addColorStop(0.3, "rgba(255,200,200,.9) ");
    gradient?.addColorStop(0.8, "rgba(255,200,200,.1) ");

    return gradient;
  };
  const tension = 0.35;
  const data = {
    labels: props.labels.map((item) => item.split(" ")),
    datasets: [
      {
        label: "Actual",
        // color: 'black',
        data:
          !!props.actual &&
          props.actual.map((item) => {
            if (isNaN(item)) {
              return 0;
            }
            if (!isFinite(item)) {
              return 0;
            } else return item;
          }),
        borderColor: COLORS.MAIN_COLOR,
        borderWidth: 1.5,
        showLine: true,
       /* fill: {
          target: "1",
          above:
            props?.kpiEnum === KpisEnum.MARKETING_SPEND ||
            props?.kpiEnum === KpisEnum.COST_PER_ORDER
              ? getRedGradient()
              : getGreenGradient(),
          below:
            props?.kpiEnum === KpisEnum.MARKETING_SPEND ||
            props?.kpiEnum === KpisEnum.COST_PER_ORDER
              ? getGreenGradient()
              : getRedGradient(),
        },*/
        width: "20px",
        height: "20px",
        tension: tension,
      },
      {
        label: "Target",
        borderWidth: 1.5,
        data:
          !!props.target &&
          props.target.map((item) => {
            if (isNaN(item)) {
              return 0;
            }
            if (!isFinite(item)) {
              return 0;
            } else return item;
          }),
        borderColor: COLORS.TARGET_COLOR,
        // fill: {
        //     target: '-1',
        //     above: getRedGradient() //'rgba(253, 219, 219, 0.5)',
        // },
        tension: tension,
        borderDash: [8, 8],
      },
      {
        label: "Last Period",
        data:
            !!props.lastPeriod &&
            props.lastPeriod.map((item) => {
              if (isNaN(item)) {
                return 0;
              }
              if (!isFinite(item)) {
                return 0;
              } else return item;
            }),
        borderColor: COLORS.TARGET_COLOR,
        borderDash: [5, 5],
        backgroundColor: "#fffffa",
        borderWidth: 1.5,
        showLine: true,
        width: "20px",
        height: "20px",
        tension: tension,
      },
      // {
      //     label: 'Invisible',
      //     borderWidth: 0,
      //     borderColor: 'rgba(255, 255, 255, 0.0)',
      //     // borderWidth: 1.5,
      //     // borderColor: 'red',
      //     data: invisibleLineForBackground,
      //     fill: true,
      //     backgroundColor: getBelowGradient(),
      //     tension: 0.35,
      //     // borderDash: [8, 8],
      // },
      {
        label: "ActualInvisible",
        // color: 'black',
        data:
          !!props.actual &&
          props.actual.map((item) => {
            if (isNaN(item)) {
              return 0;
            }
            if (!isFinite(item)) {
              return 0;
            } else return item;
          }),
        borderColor: COLORS.MAIN_COLOR,
        borderWidth: 1.5,
        showLine: true,
        fill: {
          target: { value: 0 },
          above: getBelowGradient(),
        },

        width: "20px",
        height: "20px",
        tension: tension,
      },

      {
        label: "Over Target",
        data: [],
        borderColor: "#DDF2D0",
        backgroundColor: "#DDF2D0",
      },
      {
        label: "Under Target",
        data: [],
        borderColor: "#FDDBDB",
        backgroundColor: "#FDDBDB",
      },
    ],
  };
  if(!!props?.extraDatasets){
    const newDataSets = renderDatasets();
    newDataSets?.forEach((dataset: any) => {
      data?.datasets?.push(dataset)
    })
  }

  const visibleDatasets = data.datasets.filter((dataset) => dataset.showLine !== false);

  let minVisibleValue = Number.POSITIVE_INFINITY;
  let maxVisibleValue = Number.NEGATIVE_INFINITY;

  visibleDatasets.forEach((dataset) => {
    if (Array.isArray(dataset?.data)) {
      dataset.data?.forEach((value) => {
        const formattedValue = parseFloat(value.toFixed(2));

        if (formattedValue < minVisibleValue) {
          minVisibleValue = formattedValue;
        }
        if (formattedValue > maxVisibleValue) {
          maxVisibleValue = formattedValue;
        }
      });
    }
  });




  const options: any = {
    onHover: (event: any, chartElement: any) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = "pointer";
    },
    onClick: (e: any, active: any) => {
      setCounter((prev) => prev + 1);
      if (canvasClickPosition === 0) {
        // inital click
        /* setCanvasClickPosition(e?.chart?.corsair?.x); */
        setCanvasClickPosition(e?.chart?.tooltip?.caretX);
        setStartDate(e?.chart?.tooltip.title);
      }
      if (canvasClickPosition > 0) {
        /* setCanvasEndPostion(e?.chart?.corsair?.x); */
        setCanvasEndPosition(e?.chart?.tooltip?.caretX);
        setEndDate(e?.chart?.tooltip.title);
      }
      if (canvasClickPosition > 0 && canvasClickEndPosition > 0) {
        setCanvasEndPosition(0);
        setEndDate(undefined);

        /* setCanvasClickPosition(e?.chart?.corsair?.x); */
        setCanvasClickPosition(e?.chart?.tooltip?.caretX);
        setStartDate(e?.chart?.tooltip.title);
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 20,
          maxTicksLimit: 9,
          maxRotation: 0,
          // @ts-ignore

          callback: function (val: number, index: any) {
            // Hide the label of every 2nd dataset
            // @ts-ignore
            const label = this.getLabelForValue(val)[0];
            if (props?.groupByStatus === GroupBy.DAY) {
              const objDate: string = new Date(label).toLocaleString("en-us", {
                month: "short",
              });
              const day: string = label?.slice(8, 10);
              const month: string = label?.slice(5, 7);
              return day + "." + month ?? "";
            }
            if (props?.groupByStatus === GroupBy.WEEK) {
              return label?.slice(0, 5);
            }
            if (props?.groupByStatus === GroupBy.MONTH) {
              const objDate: string = new Date(label).toLocaleString("en-us", {
                month: "short",
              });
              return objDate;
            }
            return label;
          },
          /* align: "start" as const, */
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          min: 0,
          max: 100,
          // padding: 20,
          // stepSize: 4000,
          // autoSkip: false,
          callback: function (label: any, index: any, labels: any) {
            if(props?.displayByPercentage){
              return label + "%"
            }
            if (props.prefix === PrefixType.CURRENCY) {
              if (label === 0) {
                return 0;
              }
              if (label < 1000) {
                return currency + label;
              }
              return currency + label / 1000 + "k";
            } else if (props.prefix === PrefixType.PERCENTAGE) {
              return label + "%";
            } else {
              if (label === 0) {
                return 0;
              }
              if (label < 1000) {
                return label;
              }
              return label / 1000 + "k";
            }
          },
        },
        scaleLabel: {
          display: true,
          labelString: props?.displayByPercentage ? "Percentage" :  "1k = 1000",
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      corsair: {
        horizontal: false,
        vertical: true,
        color: "red" as const,
        dash: [11, 11],
        width: 2,
      },
      legend: {
        position: "top" as const,
        align: "end" as const,
        onClick: (e: any) => {
          console.log();
        },
        labels: {
          usePointStyle: true,
          generateLabels: legendsLabels,
        },
        display: false,
      },
      tooltip: {
        intersect: false,
        enabled: false,
        mode: "index" as const,
        /* callbacks: {
                    footer: footer,
                } */
        external: externalTooltipHandler,
        backgroundColor: "white" as const,
        position: "nearest" as const,
        borderWidth: 1,
        /* borderColor: 'rgba(0, 0, 0, 1)' as const, */
        bodyColor: "#000000" as const,
        boxHeight: 10,
      },
    },
  };

  if(!!props?.extraDatasets){
    options['scales']['y']['min'] = minVisibleValue > 0 ? 0 : minVisibleValue
    options['scales']['y']['max'] = !!maxVisibleValue ? maxVisibleValue : 10
  }


  // helpers
  const leftMargin = 44;
  const startX = 0;
  const totalGraphWidth = dimensions.width - 90;
  const valueHelper = () => {
    return Math.abs(canvasClickEndPosition - canvasClickPosition);
  };
  const startPoint = () => {
    if (
      canvasClickEndPosition === 0 &&
      canvasClickPosition > canvasClickEndPosition
    ) {
      return canvasClickPosition; /* - 10; */
    }
    if (canvasClickPosition > canvasClickEndPosition) {
      return canvasClickEndPosition /* - 10 */;
    } else {
      return canvasClickPosition; /* - 10 */
    }
  };
  const StyledLegends = () => {

    return (
      <>
        <styles.Dot backgroundColor={COLORS.MAIN_COLOR} />
        <Box w={1} />

        <styles.LegendText>Actual</styles.LegendText>
        <Box w={31} />
        {!!props?.lastPeriod && <><styles.Dot backgroundColor={COLORS.TARGET_COLOR}/>
          <Box w={1} />
          <styles.LegendText>{'Last Period'}</styles.LegendText>
        </>}

      </>
    );
  };

  const Skeleton = () => (
    <styles.SkeletonBox>
      <Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1122.591"
          height="215.29"
          viewBox="0 0 1122.591 215.29"
        >
          <g
            id="shape_chart"
            data-name="shape chart"
            transform="translate(0 -2.644)"
          >
            <path
              id="Union_21"
              data-name="Union 21"
              d="M13268.572,8144.092v-72.346s43.511-37.764,78.335-45.479c22.27-4.93,26.632,2.618,79.8-12.67s47.834-33.08,80.49-33.08a413.807,413.807,0,0,1,50.016,3.016c15.8,2.015,28.911,5.143,33.228,9.307,13.944,13.468,45.106,23.129,79.923,23.129s60.389,11.821,70.248,11.821c16.415,0,43.868-9.138,58.856-15.144,12.681-5.082,29.58.69,35.99-6.125,13.028-13.847,25.337-39.526,49.452-39.526,12.3,0,18.012,1.867,25.665,4.638,1.352.49,2.652,1.013,3.913,1.563,19.154,8.365,28.893,23.012,65.238,23.039h.108c2.449,0,5.013-.067,7.714-.206l.254-.013.72-.04c46.389-2.662,52.232-44.453,79.9-52.164a125.644,125.644,0,0,1,31.371-4.218c24.949,0,26.694-10.022,49.52-10.777l.144,0c.24-.006.485-.01.738-.01h.008c.623,0,1.286.02,1.984.057.213.012.428.025.647.04q1.233.084,2.6.236,1.352.15,2.825.361h.009c12.226,1.751,30.554,6.547,49.067,11.342,19.273,4.994,38.749,9.986,51.753,11.546q.768.092,1.5.168l.457.046q.765.074,1.5.13h.006q1.906.144,3.554.153h.143q.706,0,1.359-.028l.309-.015c2.024-.11,3.987-.193,5.9-.25,3.131-.094,6.095-.118,8.915-.078,52,.661,53.16,22.963,71.778,29.235h-.006a29.108,29.108,0,0,0,3.646.964,29.052,29.052,0,0,1-3.641-.964l36.641-3.037v165.38Zm1089.821-161.334-.027-.005Zm-.115-.022-.09-.018Z"
              transform="translate(-13268.572 -7926.158)"
              fill="#d3d3d3"
            />
          </g>
        </svg>
      </Box>
    </styles.SkeletonBox>
  );

  const CANVAS_LINE_HEIGHT = 190;
  const TOP_MARGIN = 90;
  // renderers
  // @ts-ignore
  return (
    <styles.Wrapper>
      <styles.Card
        onClick={() => {
          setCanvasClickPosition(0);
          setCanvasEndPosition(0);
          setStartDate(undefined);
          setEndDate(undefined);
          setCounter(0);
          if (!!props?.clearFilters) {
            props.clearFilters();
          }
        }}
      >
        {!!canvasClickPosition ? (
          <Box
            pointerEvents={"none"}
            width={valueHelper()}
            opacity={0.5}
            height={CANVAS_LINE_HEIGHT}
            zIndex={4}
            top={TOP_MARGIN}
            left={startPoint() + leftMargin}
            borderLeft={"2px solid #7031EB"}
            borderRight={!!canvasClickEndPosition ? "2px solid #7031EB" : ""}
            position={"absolute"}
          />
        ) : null}
        {/*// 106*/}
        {!!canvasClickEndPosition && !!canvasClickPosition ? (
          <>
            <Box
              pointerEvents={"none"}
              width={startPoint() - leftMargin + 18}
              opacity={0.7}
              height={CANVAS_LINE_HEIGHT}
              float="right"
              zIndex={3}
              top={TOP_MARGIN}
              left={70}
              backgroundColor={"white"}
              position={"absolute"}
            />
            <Box
              pointerEvents={"none"}
              width={totalGraphWidth - valueHelper() - startPoint() + 44}
              opacity={0.7}
              height={CANVAS_LINE_HEIGHT}
              float="right"
              zIndex={3}
              top={TOP_MARGIN}
              left={startPoint() + startX + valueHelper() + leftMargin + "px"}
              backgroundColor={"white"}
              position={"absolute"}
            />
          </>
        ) : null}
        <styles.Header>
          <styles.Title>
            <styles.IconWrapper>
              <IconClock />
            </styles.IconWrapper>
            {t("OVER_TIME", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={overTimeProp}
              isMoreInfoDialogOpen={isOverTimeMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={setIsOverTimeMoreInfoDialogOpen}
            />
            <Box w={50} />
            <StyledLegends />
          </styles.Title>
          {counter % 2 === 0 && counter !== 0 ? (
            <styles.HeaderButton onClick={props.clearFilters}>
              <IconClearFiltersArrow />
            </styles.HeaderButton>
          ) : (
            <></>
          )}

          <Box w={15} />
          <styles.ButtonsWrapper>
            <styles.ShowByText>
              {t("GROUP_BY", { ns: "translation" })}
            </styles.ShowByText>
            <Box w={5} />
            <Toggle
              options={[
                {
                  index: "day",
                  type: GroupBy.DAY,
                  title: t("DAY", { ns: "translation" }),
                },
                {
                  index: "week",
                  type: GroupBy.WEEK,
                  title: t("WEEK", { ns: "translation" }),
                },
                {
                  index: "month",
                  type: GroupBy.MONTH,
                  title: t("MONTH", { ns: "translation" }),
                },
              ]}
              onChange={(option: ToggleOption) => {
                if (!!props.setGroupBy) {
                  console.log(option.type);
                  props?.setGroupBy(option.type);
                }
              }}
            />
            <Box w={5} />
            {/* <DownloadButton onClick={() => console.log('download')} /> // TODO .V2  */}
          </styles.ButtonsWrapper>
        </styles.Header>
        {!!props?.labels ? (
          <styles.Graph
            ref={targetRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Line
              id={props?.exploreGraph ? "exploreOvertime" : "overtime"}
              options={options}
              data={data}
            />
          </styles.Graph>
        ) : (
          <Skeleton />
        )}
      </styles.Card>

      {props?.displayGantt ? (
        <>
          <Gantt groupByStatus={props?.groupByStatus} labels={ganttLabels} />
        </>
      ) : (
        <></>
      )}
    </styles.Wrapper>
  );
};

import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`



  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  body {
    height: 100vh;
    /* height: 100%; */
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;

  }

  input {
    &:focus {
      box-shadow: 0 0 0 1px #7031EB !important;
      border-color: #7031EB !important;
    }
  }
  
  .no-border-input{
    &:focus {
      box-shadow: unset !important;
    }
  }

  *[data-focus] {
    box-shadow: none !important;
  }  // removes all chakra data focus shadow
  
  #root {
    height: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }


  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00005B;

    border-radius: 10px;
  }

  input:-webkit-autofill, // remove purple background from google auto complete
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    transition-delay: 50000s

  }

  /* ::-webkit-scrollbar-thumb {
    background: transparent linear-gradient(344deg, #1A0989 0%, #7031EB 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
  } */

    .swiper-wrapper{
      align-items: center;
    }


  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7a86a1!important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7a86a1!important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #7a86a1!important;
  }
  
  
`;

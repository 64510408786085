import {Button, Icon, Popover, PopoverTrigger, useDisclosure} from "@chakra-ui/react";
import {styles} from "./styles";
import React, {useEffect} from "react";
import {premiumLogo} from "../../constants/integration";
import PremiumIcon from "../premium-icon/PremiumIcon";

export interface MenuButton {
    title: string,
    disabled?: boolean,
    onClick: (e?: any) => void
}

export interface MenuGenericProps {
    data: MenuButton[]
    size?: number
    trigger?: any
    isOpen?: boolean
    onClose?: () => void
    onOpen?: () => void
    callbackId?: string

}

const MenuGenericButton = (props: MenuGenericProps) => {

    const onClick = (item: MenuButton) => {
        console.log(props?.callbackId)
        if (!item?.disabled){
            item?.onClick(props?.callbackId);
            !!props?.onClose && props?.onClose()
        }
    }

    return (
        <>
            <Popover
                isOpen={props.isOpen}
                onOpen={props.onOpen}
                onClose={props.onClose}
                placement='bottom'
                closeOnBlur={true}
            >
                <PopoverTrigger>
                    <div>
                        {props?.trigger}
                    </div>
                </PopoverTrigger>
                <styles.StyledPopOverContent minW={150} maxW={170}>
                    {
                        props?.data?.map((item, i) => {
                            return (
                               <styles.ButtonRow key={i}
                                                 onClick={() => onClick(item)}
                                                 disabled={item?.disabled ?? false}>
                                    {item.title}
                                   {item?.disabled ? <PremiumIcon tooltipText={'Coming soon'} width={'20px'} height={'20px'}/> : <></>}
                                </styles.ButtonRow>
                            )
                        })
                    }
                </styles.StyledPopOverContent>
            </Popover>

        </>
    );
};


export default MenuGenericButton;

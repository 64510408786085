import { organizationDataLanguageSelector, organizationSelector } from '@store';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';
import { ComparisonTable } from 'store/website';
import { currencyOptions } from 'utils/currency/currencyOptions';
import { currencyFormat } from 'utils/kpi-render/kpiCalculation';
import { styles } from './styles';

interface Props {
    data: ComparisonTable[];
    selectedTab: number;
    setSelectedTab: (selectedTab: number) => void,
    setFilteredData: (propsData: ComparisonTable[]) => void;

}

interface Data {
    col_0: string;  // type / url / source
    col_1: number;  // sessions
    col_2: number;  // users
    col_3: number;  // transactions
    col_4: number;  // revenue
    col_5: number; // cr
    col_6: number; // aov
}

interface filters {
    website: string[],
    productPage: string[],
    sourceMedium: string[],
}

export const PerformanceComparisonTable = (props: Props) => {
    //hooks

    const [filters, setFilters] = useState<filters>({ website: [], productPage: [], sourceMedium: [] });
    const [headers, setHeaders] = useState<string[]>(["Type", "Sessions", "Users", "Transactions", "Revenue", "CR", "AOV"]);
    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const [selectedRowTab1, setSelectedRowTab1] = useState([] as Row<Data>[]);
    const [selectedRowTab2, setSelectedRowTab2] = useState([] as Row<Data>[]);
    const [propsData, setPropsData] = useState<ComparisonTable[]>([]);
    const [highestNumber, setHighestNumber] = useState(0);
    const [currentCurrency, setCurrentCurrency] = useState<string>("$");

    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }

    }, [dataLanguage]);


    //helpers
    const decimalFix = (number: number) => {
        return (Math.round(number * 100) / 100).toFixed(2);
    };


    const percentageFormat = (amount: number) => {
        return amount.toString() + '%';
    };

    const dataToDataTable = (data: ComparisonTable[]): Data[] => {
        switch (props.selectedTab) {
            case (1):
                return data.map((row, i) => {
                    return ({
                        col_0: row.productPage,
                        col_1: row.sessions,
                        col_2: row.users,
                        col_3: row.transactions,
                        col_4: parseInt(row.revenue.toFixed(0)),
                        col_5: row.cr,
                        col_6: row.aov,
                    });
                });
            case (2):
                return data.map((row, i) => {
                    return ({
                        col_0: row.sourceMedium,
                        col_1: row.sessions,
                        col_2: row.users,
                        col_3: row.transactions,
                        col_4: parseInt(row.revenue.toFixed(0)),
                        col_5: row.cr,
                        col_6: row.aov,
                    });
                });
        }
        return data.map((row, i) => {
            return ({
                col_0: row.type,
                col_1: row.sessions,
                col_2: row.users,
                col_3: row.transactions,
                col_4: parseInt(row.revenue.toFixed(0)),
                col_5: row.cr,
                col_6: row.aov,
            });
        });
    };

    const keysToSum = ['sessions', 'users', "transactions", "revenue", "cr", "aov"];
    const summed: ComparisonTable[] = Object.values(props.data.reduce((obj, record) => {
        if (!obj[record.type]) {
            obj[record.type] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.type][key] += record[key];
            });
        }
        return obj;
    }, {}));
    useEffect(() => {
        setPropsData(summed);
    }, [props.data]);
    useEffect(() => {
        const tempData: number[] = [];
        let temp = 0;
        propsData.forEach((item) => {
            tempData.push(item.cr);
        });
        tempData.forEach((element) => {
            if (temp < element) {
                temp = element;
            }
        });
        setHighestNumber(temp);
    }, [propsData]);
    useEffect(() => {
        switch (props.selectedTab) {
            case 0:
                setHeaders(["Type", "Sessions", "Users", "Transactions", "Revenue", "CR", "AOV"]);
                break;
            case 1:
                setHeaders(["Product Page", "Sessions", "Users", "Transactions", "Revenue", "CR", "AOV"]);
                break;
            case 2:
                setHeaders(["Source / Medium", "Sessions", "Users", "Transactions", "Revenue", "CR", "AOV"]);
                break;
        }

    }, [props.selectedTab]);

    const headersToColumns = (headers: string[],): Column<Data>[] => {
        return headers.map((header, index) => {


            if (header === "id") {
                return {
                    Header: header, accessor: 'col_' + index.toString(), sortType: 'basic', show: false,
                } as Column<Data>;
            }

            return {
                Header: header, accessor: 'col_' + index.toString(), sortType: 'basic',

                Footer: info => {
                    const totalSessions = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_1 + sum, 0));
                        },
                        [info.rows]
                    );
                    const totalUsers = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_2 + sum, 0));
                        },
                        [info.rows]
                    );
                    const totalTransactions = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_3 + sum, 0));
                        },
                        [info.rows]
                    );
                    const totalRevenue = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_4 + sum, 0));
                        },
                        [info.rows]
                    );
                    const totalCR = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_5 + sum, 0));
                        },
                        [info.rows]
                    );
                    const totalAOV = useMemo(
                        () => {
                            return (info.rows.reduce((sum, row) => row.values.col_6 + sum, 0));
                        },
                        [info.rows]
                    );
                    if (info.column.Header === "Sessions") {
                        return <>{typeof totalSessions === "number" ? totalSessions?.toLocaleString() : totalSessions}</>;
                    }
                    if (info.column.Header === "Users") {
                        return <>{typeof totalUsers === "number" && !!totalUsers ? totalUsers?.toLocaleString() : totalUsers}</>;
                    }
                    if (info.column.Header === "Transactions") {
                        return <>{typeof totalTransactions === "number" && !!totalTransactions ? totalTransactions?.toLocaleString() : totalTransactions}</>;
                    }
                    if (info.column.Header === "Revenue") {
                        return <>{currencyFormat(totalRevenue, 0, currentCurrency)}</>;
                    }
                    if (info.column.Header === "CR") {
                        return <>{decimalFix(totalCR) + "%"}</>;
                    }
                    if (info.column.Header === "AOV") {
                        return <>{currencyFormat(totalAOV, 2, currentCurrency)}</>;
                    }
                    return <></>;
                }
            } as Column<Data>;
        });
    };

    const data = useMemo<Data[]>(() => dataToDataTable(propsData!), [propsData]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(headers), [headers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
        allColumns
    } = useTable({
        columns, data
    }, useSortBy, useRowSelect);

    switch (props.selectedTab) {
        case 0: // website tab
            filters.website = selectedRowTab0.map((item) => (item.cells[0].value));
            break;
        case 1: // product page tab
            filters.productPage = selectedRowTab1.map((item) => (item.cells[0].value));
            break;
        case 2: // source tab
            filters.sourceMedium = selectedRowTab2.map((item) => (item.cells[0].value));
            break;
    }

    const filterByRow = (row: Row<Data>) => {
        if (props.selectedTab === 0) {
            const isRowSelected = selectedRowTab0.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab0([...(selectedRowTab0.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab0];
                newSelected.push(row);
                setSelectedRowTab0(newSelected);
            }

        }
        if (props.selectedTab === 1) {
            const isRowSelected = selectedRowTab1.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab1([...(selectedRowTab1.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab1];
                newSelected.push(row);
                setSelectedRowTab1(newSelected);
            }
        }
        if (props.selectedTab === 2) {
            const isRowSelected = selectedRowTab2.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab2([...(selectedRowTab2.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab2];
                newSelected.push(row);
                setSelectedRowTab2(newSelected);
            }
        }
    };

    const websiteTabHelper = () => {
        let newData = props.data;
        if (filters.productPage.length !== 0) {
            newData = newData.filter((item) => filters.productPage.includes(item.productPage));
        }
        if (filters.sourceMedium.length !== 0) {
            newData = newData.filter((item) => filters.sourceMedium.includes(item.sourceMedium));
        }

        const summed: ComparisonTable[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.type]) {
                obj[record.type] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.type][key] += record[key];
                });
            }
            return obj;
        }, {}));
        setPropsData(summed);
        props.setFilteredData(summed);

    };

    const productPageTabHelper = () => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = newData.filter((item) => filters.website.includes(item.type));
        }
        if (filters.sourceMedium.length !== 0) {
            newData = newData.filter((item) => filters.sourceMedium.includes(item.sourceMedium));
        }
        newData = newData.filter((item) => {
            return (
                item.productPage !== "");
        });
        const summed: ComparisonTable[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.productPage]) {
                obj[record.productPage] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.productPage][key] += record[key];
                });
            }
            return obj;
        }, {}));
        setPropsData(summed);
        props.setFilteredData(summed);

    };

    const sourceMediumTabHelper = () => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = newData.filter((item) => filters.website.includes(item.type));
        }
        if (filters.productPage.length !== 0) {
            newData = newData.filter((item) => filters.productPage.includes(item.productPage));
        }


        const summed: ComparisonTable[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.sourceMedium]) {
                obj[record.sourceMedium] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.sourceMedium][key] += record[key];
                });
            }
            return obj;
        }, {}));
        setPropsData(summed);
        props.setFilteredData(summed);

    };

    const tabButtonsContainer = () => {
        return (
            <styles.TabButtonsContainer>
                <styles.TabButton {...props.selectedTab === 0 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(0);
                    websiteTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('WEBSITES', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters?.website.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 0 ? { selected: true } : { selected: false }}>
                                {(filters?.website.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...props.selectedTab === 1 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(1);
                    productPageTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('PRODUCT_PAGE', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters?.productPage.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 1 ? { selected: true } : { selected: false }}>
                                {(filters?.productPage.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...props.selectedTab === 2 ? { selected: true } : { selected: false }} onClick={(() => {
                    props.setSelectedTab(2);
                    sourceMediumTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('SOURCE_MEDIUM', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.sourceMedium.length > 0 ?
                            <styles.StyledDivSelected {...props.selectedTab === 2 ? { selected: true } : { selected: false }}>
                                {(filters.sourceMedium.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
            </styles.TabButtonsContainer >
        );
    };

    const renderTable = () => {
        return (<table  {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <styles.TrCustom
                        {...{ ...headerGroup.getHeaderGroupProps() }}>
                        {
                            headerGroup.headers.map(column => {
                                return (
                                    <styles.styledTh style={{ display: "flex", }} {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                                        <styles.StyledHeaderWrapper>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <GoTriangleUp />
                                                    : <GoTriangleDown />
                                                : null}
                                        </styles.StyledHeaderWrapper>
                                    </styles.styledTh>
                                );
                            }
                            )}
                    </styles.TrCustom>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    switch (props.selectedTab) {
                        case 0:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab0.some((element) => element.id === row.id) || selectedRowTab0.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                { (row.original.col_1)?.toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2" || cell?.column?.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {cell?.value.toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_4, 0, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_5") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_5 / highestNumber})` }} {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_5)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_6") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_6, 2, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                        case 1:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab1.some((element) => element.id === row.id) || selectedRowTab1.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {(row.original.col_1).toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2" || cell?.column?.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {cell?.value.toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_4, 0, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_5") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_5 / highestNumber})` }} {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_5)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_6") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_6, 2, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                        case 2:
                            return (
                                <styles.TrCustom {...{ selected: selectedRowTab2.some((element) => element.id === row.id) || selectedRowTab2.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === "col_1") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {(row.original.col_1).toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_2" || cell?.column?.id === "col_3") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {cell?.value.toLocaleString()}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_4") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_4, 0, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_5") {
                                            return <styles.styledTd style={{ background: `rgba(186,156,245, ${row.original.col_5 / highestNumber})` }} {...cell.getCellProps()}>
                                                {decimalFix(row.original.col_5)}
                                            </styles.styledTd>;
                                        }
                                        if (cell.column.id === "col_6") {
                                            return <styles.styledTd {...cell.getCellProps()}>
                                                {currencyFormat(row.original.col_6, 2, currentCurrency)}
                                            </styles.styledTd>;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </styles.styledTd>;
                                    })}
                                </styles.TrCustom>
                            );
                    }
                    return;
                })}
            </tbody>
            <tfoot>
                {footerGroups.map(group => (
                    <styles.footerTr style={{ position: "sticky", bottom: 0 }} {...group.getFooterGroupProps()}>
                        {group.headers.map(column => {
                            if (column.id === "col_0") {
                                return (<styles.StyledTotal  {...column.getFooterProps()}>
                                    {t('GRAND_TOTAL', { ns: 'translation' })}</styles.StyledTotal>);
                            }

                            return (< styles.FooterTd  {...column.getFooterProps()}>{column.render('Footer')}</styles.FooterTd>);
                        })}
                    </styles.footerTr>
                ))}
            </tfoot>

        </table >);
    };

    return (<>
        {tabButtonsContainer()}
        <styles.Styles>
            <styles.TableScroll>
                {renderTable()}
            </styles.TableScroll>
        </styles.Styles>
    </>);
};


export const createEffortSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 260.004 192.823">
            <g id="Group_726" data-name="Group 726" transform="translate(-52.226)">
                <path id="Path_380" data-name="Path 380" d="M964.431,685.179c.034-.1,3.308-10.233-2.487-19.274l-.592.379c5.61,8.752,2.445,18.574,2.412,18.672Z" transform="translate(-657.678 -493.62)" fill="#e4e4e4" />
                <path id="Path_381" data-name="Path 381" d="M950.508,650.972a4.608,4.608,0,1,0,9.018-1.9c-.525-2.49-4.978-8.688-7.468-8.163S949.983,648.481,950.508,650.972Z" transform="translate(-650.387 -477.386)" fill="#e4e4e4" />
                <path id="Path_382" data-name="Path 382" d="M950.175,691.008c2.222,2.4,6.2,2.337,6.2,2.337s.373-3.959-1.85-6.361-6.2-2.337-6.2-2.337S947.953,688.607,950.175,691.008Z" transform="translate(-649.214 -505.776)" fill="#e4e4e4" />
                <path id="Path_386" data-name="Path 386" d="M558.283,666.2l-5.128-2.159-3.518-25.707H502.791l-3.813,25.6-4.588,2.294a1.09,1.09,0,0,0,.487,2.064h62.983a1.089,1.089,0,0,0,.423-2.094Z" transform="translate(-354.426 -475.736)" fill="#e6e6e6" />
                <path id="Path_387" data-name="Path 387" d="M481.1,579.76H250.982a4.559,4.559,0,0,1-4.549-4.559v-32.45H485.651V575.2a4.559,4.559,0,0,1-4.549,4.559Z" transform="translate(-193.997 -413.745)" fill="#ccc" />
                <path id="Path_388" data-name="Path 388" d="M485.5,320H245.835V181.148a5.5,5.5,0,0,1,5.5-5.5H480.008a5.5,5.5,0,0,1,5.5,5.5Z" transform="translate(-193.609 -175.653)" fill="#3f3d56" />
                <path id="Path_389" data-name="Path 389" d="M489.793,204.328H278.751a4.24,4.24,0,0,0-4.235,4.238V324.279a4.24,4.24,0,0,0,4.235,4.238H489.793a4.242,4.242,0,0,0,4.235-4.238V208.566A4.242,4.242,0,0,0,489.793,204.328Z" transform="translate(-212.211 -194.251)" fill="#fff" />
                <path id="Path_403" data-name="Path 403" d="M598.112,245.661a1.282,1.282,0,0,0-1.28,1.28v21.436a1.282,1.282,0,0,0,1.28,1.28h86.415a1.282,1.282,0,0,0,1.28-1.28V246.941a1.282,1.282,0,0,0-1.28-1.28Z" transform="translate(-421.258 -221.059)" fill="#fff" />
                <path id="Path_404" data-name="Path 404" d="M598.112,245.661a1.282,1.282,0,0,0-1.28,1.28v21.436a1.282,1.282,0,0,0,1.28,1.28h86.415a1.282,1.282,0,0,0,1.28-1.28V246.941a1.282,1.282,0,0,0-1.28-1.28Z" transform="translate(-421.258 -221.059)" fill="#e6e6e6" />
                <path id="Path_405" data-name="Path 405" d="M600.93,270.875h86.1V249.759h-86.1Z" transform="translate(-423.916 -223.717)" fill="#fff" />
                <path id="Path_406" data-name="Path 406" d="M626.717,267.053a1.12,1.12,0,0,0,0,2.24h66.515a1.12,1.12,0,0,0,0-2.24Z" transform="translate(-439.915 -234.933)" fill="#e4e4e4" />
                <path id="Path_407" data-name="Path 407" d="M626.717,286.178a1.12,1.12,0,0,0,0,2.24h66.515a1.12,1.12,0,1,0,0-2.24Z" transform="translate(-439.915 -247.337)" fill="#e4e4e4" />
                <path id="Path_413" data-name="Path 413" d="M723.726,493.3a2.648,2.648,0,0,0,2.251-3.38l7.659-5.47-4.564-1.757-6.573,5.464a2.663,2.663,0,0,0,1.228,5.144Z" transform="translate(-501.632 -374.789)" fill="#ffb7b7" />
                <path id="Path_414" data-name="Path 414" d="M736.621,447.911l-2.541-4.339.054-.062,10.251-11.67,7.959-13.573,6.911.881.1,2.089-.021.031-10.981,16.506-.012.01Z" transform="translate(-510.275 -333.007)" fill="#7a86a1" />
                <path id="Path_415" data-name="Path 415" d="M729.822,508.09h-3.908l-1.86-15.076h5.769Z" transform="translate(-469.607 -319.759)" fill="#ffb7b7" />
                <path id="Path_416" data-name="Path 416" d="M817.263,713h-12.6v-.159a4.905,4.905,0,0,1,4.9-4.905h7.7Z" transform="translate(-556.052 -520.879)" fill="#2f2e41" />
                <path id="Path_417" data-name="Path 417" d="M637.732,506.015l-3.8-.908,1.692-15.1,5.611,1.34Z" transform="translate(-411.155 -317.812)" fill="#ffb7b7" />
                <path id="Path_418" data-name="Path 418" d="M719.6,709.707l-12.258-2.927.037-.155a4.906,4.906,0,0,1,5.91-3.632h0l7.487,1.788Z" transform="translate(-492.932 -517.587)" fill="#2f2e41" />
                <path id="Path_419" data-name="Path 419" d="M785.191,370.242v-6.579a8.816,8.816,0,1,1,17.632,0v6.579a1.185,1.185,0,0,1-1.184,1.184H786.376A1.185,1.185,0,0,1,785.191,370.242Z" transform="translate(-543.424 -291.874)" fill="#2f2e41" />
                <circle id="Ellipse_83" data-name="Ellipse 83" cx="6.463" cy="6.463" r="6.463" transform="translate(241.944 66.525)" fill="#ffb7b7" />
                <path id="Path_420" data-name="Path 420" d="M779.257,368.56a6.982,6.982,0,0,1,6.974-6.974h1.316a6.982,6.982,0,0,1,6.974,6.974v.132h-2.781l-.948-2.656-.19,2.656h-1.437l-.479-1.34-.1,1.34h-9.333Z" transform="translate(-539.575 -298.001)" fill="#2f2e41" />
                <path id="Path_421" data-name="Path 421" d="M781.746,433.89l-16.457-.626-.92-10.766a6.269,6.269,0,0,1-.034-5.029,5.034,5.034,0,0,1,2.779-2.4l3.6-4.349a4.9,4.9,0,0,1,3.806-1.774l9.356.064c.226-.136,1.594-.831,4.291.339,2.926,1.269,2.247,5.675,2.24,5.719l-.007.041-.032.027-6.224,5.241Z" transform="translate(-529.568 -326.788)" fill="#7a86a1" />
                <path id="Path_422" data-name="Path 422" d="M645.88,299.694,638.1,313.886l-5.9,64.569,6.668,2.036,11.8-50.51,13.7,49.077,7.993.2-5.442-56.635L662.158,300.7Z" transform="translate(-410.029 -194.375)" fill="#2f2e41" />
                <path id="Path_423" data-name="Path 423" d="M609.7,445.3a1.282,1.282,0,0,0-1.613.823l-6.61,20.391a1.282,1.282,0,0,0,.823,1.613l82.2,26.648a1.282,1.282,0,0,0,1.613-.823l6.61-20.391a1.282,1.282,0,0,0-.823-1.613Z" transform="translate(-424.233 -350.497)" fill="#fff" />
                <path id="Path_424" data-name="Path 424" d="M609.7,445.3a1.282,1.282,0,0,0-1.613.823l-6.61,20.391a1.282,1.282,0,0,0,.823,1.613l82.2,26.648a1.282,1.282,0,0,0,1.613-.823l6.61-20.391a1.282,1.282,0,0,0-.823-1.613Z" transform="translate(-424.233 -350.497)" fill="#e6e6e6" />
                <path id="Path_425" data-name="Path 425" d="M605.635,469.536l81.9,26.549L694.046,476l-81.9-26.549Z" transform="translate(-426.968 -353.232)" fill="#fff" />
                <path id="Path_426" data-name="Path 426" d="M642.623,474.388a1.12,1.12,0,1,0-.691,2.131l63.273,20.511a1.12,1.12,0,0,0,.692-2.131h0Z" transform="translate(-450.007 -369.371)" fill="#7031eb" />
                <path id="Path_427" data-name="Path 427" d="M636.725,492.582a1.12,1.12,0,0,0-.691,2.131l63.273,20.511a1.12,1.12,0,1,0,.7-2.13l0,0Z" transform="translate(-446.182 -381.171)" fill="#7031eb" />
                <path id="Path_428" data-name="Path 428" d="M819.318,516.8a2.648,2.648,0,0,0-.139-4.059l3.039-8.907-4.733,1.23-2.16,8.27a2.663,2.663,0,0,0,3.994,3.466Z" transform="translate(-562.669 -388.503)" fill="#ffb7b7" />
                <path id="Path_429" data-name="Path 429" d="M822.925,457.865l-4.593-2.047,1.543-15.538-1.436-15.668,6.131-3.31,1.3,1.639v.037l.689,19.813,0,.015Z" transform="translate(-564.919 -334.975)" fill="#7a86a1" />
                <path id="Path_430" data-name="Path 430" d="M615.9,722.367H416.681a.476.476,0,0,1-.449-.4.626.626,0,0,1,.078-.465.424.424,0,0,1,.353-.21h199.2a.491.491,0,0,1,.471.419.622.622,0,0,1-.087.452A.422.422,0,0,1,615.9,722.367Z" transform="translate(-304.115 -529.544)" fill="#ccc" />
                <g id="Group_820" data-name="Group 820" transform="translate(60)">
                    <path id="Path_391" data-name="Path 391" d="M279.956,382.713a2.954,2.954,0,0,0-3.607,2.741l-10.069,2.969,4.168,3.519,8.923-3.359a2.97,2.97,0,0,0,.584-5.87Z" transform="translate(-206.87 -309.899)" fill="#ffb6b6" />
                    <path id="Path_392" data-name="Path 392" d="M181.681,407.914a4.2,4.2,0,0,1-1.338-8.18l20.4-6.888,15.917-5.8,1.953,6.837-14.051,9.259-22.007,4.678A4.224,4.224,0,0,1,181.681,407.914Z" transform="translate(-149.279 -312.759)" />
                    <path id="Path_393" data-name="Path 393" d="M128.54,518.4H131.4l1.363-11.05h-4.229Z" transform="translate(-83.369 -329.054)" fill="#ffb6b6" />
                    <path id="Path_394" data-name="Path 394" d="M223.68,711.783h5.642a3.6,3.6,0,0,1,3.6,3.6v.117H223.68Z" transform="translate(-179.24 -523.375)" fill="#2f2e41" />
                    <path id="Path_395" data-name="Path 395" d="M66.032,518.4H68.9l1.363-11.05H66.032Z" transform="translate(-42.828 -329.054)" fill="#ffb6b6" />
                    <path id="Path_396" data-name="Path 396" d="M161.172,711.783h5.642a3.6,3.6,0,0,1,3.6,3.6v.117h-9.238Z" transform="translate(-138.698 -523.375)" fill="#2f2e41" />
                    <path id="Path_397" data-name="Path 397" d="M160.152,525.829a15.387,15.387,0,0,0-.818,9.681l-.818,54.816h6.292l3.523-30.146,5.842-19.863,6.31,15.423v32.145l6.076-.363L189.83,553.4l-3.739-24.3Z" transform="translate(-136.976 -402.77)" fill="#7a86a1" />
                    <path id="Path_398" data-name="Path 398" d="M184.68,396.276l-5.375-1.636-1.4-2.8H171l-1.743,3.038s-7.011,2.571-7.244,14.956-1.168,32.249-1.168,32.249l27.643,3.941Z" transform="translate(-138.485 -315.864)" fill="#464a53" />
                    <path id="Path_399" data-name="Path 399" d="M238.242,512.83a2.954,2.954,0,0,0-1.931-4.1l-.81-10.466-4.309,3.346,1.43,9.426a2.97,2.97,0,0,0,5.62,1.792Z" transform="translate(-184.112 -384.893)" fill="#ffb6b6" />
                    <path id="Path_400" data-name="Path 400" d="M219.012,446.164,212.876,430.5V408a4.2,4.2,0,0,1,8.371-.487l2.5,21.391,2.362,16.774Z" transform="translate(-172.233 -323.621)" fill="#464a53" />
                    <circle id="Ellipse_81" data-name="Ellipse 81" cx="6.899" cy="6.899" r="6.899" transform="translate(30.391 58.536)" fill="#ffb9b9" />
                    <path id="Path_401" data-name="Path 401" d="M184.635,341.025c-.323.414-.765.788-.836,1.309a6.651,6.651,0,0,0,.132,1.156,1.542,1.542,0,0,1-1.442,1.544,1.711,1.711,0,0,0-1.1-.63,1.016,1.016,0,0,0-1.046.6c-.2.579.269,1.167.346,1.776a3.673,3.673,0,0,1-.215,1.324,11.146,11.146,0,0,1-.528,2.09,1.674,1.674,0,0,1-1.695,1.069,1.7,1.7,0,0,1-1.161-1.279c-.166-.583-.159-1.2-.279-1.794-.39-1.93-2.051-3.359-2.7-5.217-.835-2.38.168-5.1,1.9-6.94a5.649,5.649,0,0,1,2.632-1.7,23.334,23.334,0,0,1,3.322-.194,23.206,23.206,0,0,0,3.442-.911,4.766,4.766,0,0,1,3.477.148,21.946,21.946,0,0,1,1.935,1.556,4.058,4.058,0,0,0,2.666.714l-1.128.493a3.624,3.624,0,0,0,1.52.832,2.629,2.629,0,0,1-1.62.569,1.85,1.85,0,0,0,1.165.9,5.35,5.35,0,0,1-2.708.723c-.461-.044-.9-.208-1.358-.293a7.986,7.986,0,0,0-2.94.193c-.51.1-1.135.192-1.392.682C184.8,340.146,184.965,340.6,184.635,341.025Z" transform="translate(-146.893 -277.687)" fill="#2f2e41" />
                    <circle id="Ellipse_82" data-name="Ellipse 82" cx="11.007" cy="11.007" r="11.007" transform="translate(72.12 61.165)" fill="#7031eb" />
                    <path id="Path_402" data-name="Path 402" d="M230.963,194.4H225.02v-5.944H223.7V194.4h-5.944v1.321H223.7v5.944h1.321v-5.944h5.943Z" transform="translate(-141.232 -122.229)" fill="#fff" />
                    <path id="Path_431" data-name="Path 431" d="M178.057,722.367H97.776a.551.551,0,0,1-.541-.4.537.537,0,0,1,.519-.674h80.259a.568.568,0,0,1,.568.419.537.537,0,0,1-.524.653Z" transform="translate(-97.219 -529.544)" fill="#ccc" />
                </g>
            </g>
        </svg>

    );
};
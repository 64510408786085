import styled from "styled-components";
import { Button, Input, Spinner } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  `;

  export const MessageWrapper = styled.div<{ success: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.success
        ? props.theme.successBackgroundColor
        : props.theme.errorBackgroundColor};
    padding: 20px;
    border-radius: 12px;
  `;

  export const Message = styled.span<{ success: boolean }>`
    font-size: 14px;
    color: ${(props) =>
      props.success ? props.theme.successColor : props.theme.errorColor};
  `;
  export const StyledTitle = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #92929d;
    font-size: 14px;
    margin-left: 7px;
  `;

  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;
  export const hrefText = styled.a`
    color: ${(props) => props.theme.primaryColor};
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `;
  export const SignUpWrapper = styled.div<{ img: string }>`
    background: url(${(props) => props.img})
      ${(props) => props.theme.primaryColor} round;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;
  export const BottomCard = styled.div`
    height: 48px;
    width: 100%;
    display: flex;
    border-radius: 0 0 10px 10px;
    background-color: #fbf7ff;
  `;
  export const BottomText = styled.div``;
  export const SignUpContent = styled.div`
    display: flex;
    justify-content: center;
    /* height: 330px; */
  `;

  export const SignUpCard = styled.div`
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 360px;
    /* overflow: hidden; */
    //@media(max-width: 768px) {
    //  width: 90vw;
    //}
  `;
  export const SignUpForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px 10px;
    //@media(max-width: 768px) {
    //  width: calc(100% - 20px);
    //}
  `;
  export const SignUpShowPasswordButton = styled.div`
    height: 20px;
    width: 20px;
    background-color: #ffffff00;
    border-width: 0px;
  `;
  export const LastBarHolder = styled.div<{ color: string }>`
    position: absolute;
    font-size: 13px;
    top: -9px;
    left: 50px;
    width: 38px;
    white-space: nowrap;
    text-align: center;
    color: ${(props) => props.color};
  `;
  export const PasswordBar = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    width: 50px;
    height: 5px;
    margin: 0 10px 0 0;
    position: relative;
    &:last-of-type {
      margin: 0;
    }
  `;
  export const SignInFormSubmitLoader = styled(Spinner)`
    height: 32px;
    width: 32px;
    margin: 5px 0;
    color: ${(props) => props.theme.primaryColor};
  `;
  export const SignUpFormSubmitButton = styled(Button)<{ color?: string }>`
    position: absolute !important;
    background-color: ${(props) => props.theme.primaryColor} !important;
    color: white !important;
    height: min-content;
    padding: 10px !important;
    border-radius: 20px !important;
    margin: 5px 0 !important;
    width: 100% !important;
    max-width: 300px !important;
    text-align: center !important;
    position: relative !important;
  `;
  export const ButtonDisable = styled.div`
    position: absolute;
    background-color: #e8e8e8;
    color: #888888;
    height: min-content;
    padding: 10px;
    border-radius: 20px;
    margin: 5px 0;
    width: 100%;
    max-width: 300px;
    text-align: center;
    position: relative;
  `;

  export const BarHolders = styled.div`
    display: flex;
    width: 200px;
    justify-content: space-between;
    align-self: flex-start;
    margin: 0 0 10px 38px;
  `;

  export const SignUpFormTitle = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const SignInText = styled.div`
    font-family: 10px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    width: 100%;
    text-align: center;
    color: #92929d;
    margin-bottom: 33px;
  `;
  export const SignUpFormOrText = styled.span`
    font-size: 10px;
    color: ${(props) => props.theme.descriptionTextColor};
    text-align: center;
    margin: 10px 0;
    text-transform: uppercase;
  `;

  export const FormInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `;

  export const SignUpFormInput = styled(Input)`
    padding: 10px !important;
    border-radius: 20px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    &:focus {
      // remove blue border from chakra
      outline: none !important;

      box-shadow: 1px ${(props) => props.theme.primaryColor} !important;
    }
  `;
}

export default styles;

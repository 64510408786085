import styles from "./styles";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../../dialogs/more-info-dialog/MoreInfoDialog";
import { DatepickerRange } from "../../datepicker-range/DatepickerRange";
import {
  IconAcrossChannels,
  IconAcrossProducts,
  IconClearFiltersArrow,
  IconDiamond,
  IconShare,
} from "../../../utils/icons/explore";
import { Kpi } from "../../../models/kpi";
import { useDispatch, useSelector } from "react-redux";
import { kpiStateSelector } from "../../../store/kpis";
import { organizationDataLanguageSelector, organizationSelector } from "@store";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import { MoreInfoButton } from "../../more-info-button/MoreInfoButton";
import { Effort, effortsSelector, EffortsState } from "store/efforts";
import {
  dateRangeSelector,
  ExploresEnum,
  isDefaultExploreOpenSelector,
  openDefaultExploreView,
} from "../../../store/ui";
import { Box, Spinner, useDisclosure } from "@chakra-ui/react";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { KpisEnum } from "models/kpi-settings";
import {
  convertCamelCaseToString,
  getKpiNameFromEnum,
} from "utils/kpi-names/kpiName";
import { channelsStateSelector } from "store/channels";
import { sumData } from "utils/get-months/getLastYear";
import { getChannelNameById } from "utils/get-channel-name/getChannelNameById";
import { getDatesInRange } from "utils/colors";
import { GroupBy } from "features/workspace/Workspace";
import { OverTimeGraph } from "components/over-time-graph/OverTimeGraph";
import { ExpenseTable } from "components/expense-table/ExpenseTable";
import { currencyOptions } from "utils/currency/currencyOptions";
import { getOvertimeGraphDataForExplore } from "../../../utils/kpi-render/kpiCalculation";
import { columns } from "../../../utils/effortTableHeaders/headers";
import { PanelMenu } from "../../panel-menu/PanelMenu";
import { Unit } from "../../../features/wideviews-financial/WideViewsFinancial";
import { MenuButton } from "../../menu-generic/MenuGenericButton";
import {
  ExploreTableDataEnum,
  ExploreTableFooterTypeEnum,
  GenericExploreTable,
} from "../../generic-explore-table/GenericExploreTable";
import { productsFieldsSelector } from "../../../store/products";
import { linkProductData } from "../../../utils/link-data/linkProductData";
import { Channel } from "../../../models/channel";
import { Row } from "react-table";
import {
  pnlDataLanguageDisplayNamesSelector,
  pnlDataLanguageSelector,
} from "../../../store/pnl";
import InformationWrapper from "../../InformationWrapper/InformationWrapper";

const GrossProfitExploreMoreInfoDialogProps = {
  title: "Gross Profit Explore",
  subtitle:
    "On this page, you can identify the primary driver of any KPI.\nExploring your KPI will help you understand the main factors you should focus on in order to improve your results.",
  description:
    "\nThe exploration structure:\n\nWhen - The timeframe you want to focus on.\n\nWhere - The primary sources with the most impact on your performance.\n Why - Identify sub metrics to understand precise KPI elements.\n What - Actions you can focus on in order to improve overall.",
  //tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
  /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

interface GraphData {
    xLabels: string[];
    grossRevenue: number[];
    netRevenue: number[];
    netMarginActual: number[];
}

export const GrossProfitExplore = () => {

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const [expensesTableDataBreakdown, setExpensesTableDataBreakdown] = useState<any>([]);

    const [isNetRevenueMoreInfoDialogOpen, setIsNetRevenueMoreInfoDialogOpen] = useState(false);
    const openNetRevenueLanguageMoreInfoDialog = (isOpen: boolean) => setIsNetRevenueMoreInfoDialogOpen(isOpen);
    const kpis = useSelector(kpiStateSelector);
    const [currentCurrency, setCurrentCurrency] = useState<string>("$");
    const { isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose } = useDisclosure();  //
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const datesSelector = useSelector(dateRangeSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organizationId = useSelector(organizationSelector);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const dispatch = useDispatch();
    const efforts: EffortsState = useSelector(effortsSelector);
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [filteredKpiData, setFilteredKpiData] = useState<any[]>([]);
    const [channelsFilters, setChannelsFilters] = useState<{original: {callbackId: string;}}[]>([]);
    const [productsFilters, setProductsFilters] = useState<any[]>([]);
    const channelsData = useSelector(channelsStateSelector);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any[]>([]);
    const [acrossProductsData, setAcrossProductsData] = useState<{productName?: string; grossProfit?: number; grossMargin?: number; shareOfTotal?: number; callbackId?: string}[]>([]);
    const [actualData, setActualData] = useState<any>([]);
    const [targetData, setTargetData] = useState<any>([]);
    const [labels, setLabels] = useState<any>([]);
    const [kpiData, setKpiData] = useState<Kpi[]>([]);
    const [totalNetMargin, setTotalNetMargin] = useState(0);
    const [totalGrossProfit, setTotalGrossProfit] = useState(0);
    const [channelsGraph, setChannelsGraph] = useState<GraphData>();
    const [productsData, setProductsData] = useState<any[]>([]);
    const [productsFilteredByDates, setProductsFilteredByDates] = useState<any[]>([]);
    const [productsGraph, setProductsGraph] = useState<GraphData>();
    const [ganttLabels, setGanttLabels] = useState<string[]>([])
    const allProductsData = useSelector(productsFieldsSelector);
    const [expanesTableData, setExpansesTableData] = useState<any>([]);
    const [selectedExpenses, setSelectedExpenses] = useState<any>([]);
    const [unit, setUnit] = useState<Unit>(Unit.VALUE);
    const [totalTableGrossMargin, setTotalTableGrossMargin] = useState(0);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [expenesGraphData, setExpensesGraphData] = useState<{ labels: string[], values: number[]; }>();
    const pnlsDisplayNames = useSelector(pnlDataLanguageDisplayNamesSelector)?.data;
    const defaultExploreData = useSelector(isDefaultExploreOpenSelector);
    const pnlData = useSelector(pnlDataLanguageSelector)?.data;

    const pnls = useSelector(pnlDataLanguageSelector)?.data;
    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }

    }, [dataLanguage]);

    useEffect(() => {
        setUnit(Unit.VALUE);
        if (defaultExploreData?.stateToTransfer === 0) {
            setUnit(Unit.MARGIN);
        }
    }, [
        defaultExploreData?.stateToTransfer,
        defaultExploreData?.isDefaultExploreOpen,
    ]);

    useEffect(() => {
        if (!!kpis.data?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.data?.data.filter((item) => {
                    if (!!item?.activityDate) {
                        return datesRange.includes(item?.activityDate.slice(0, 10));
                    }
                    return;
                });
                setKpiData(filteredData);

            }
            else {
                setKpiData(kpis?.data?.data);

            }
        }
    }, [kpis?.data?.data, startDate, endDate]);



    useEffect(() => {
        if (!!kpis.financeKpi?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.financeKpi?.data.filter((item) => {
                    return datesRange.includes(item?.orderDate?.slice(0, 10));
                });
                setProductsFilteredByDates(filteredData);
            }
            else {
                setProductsFilteredByDates(kpis.financeKpi?.data);
            }
        }
    }, [kpis?.financeKpi?.data, kpiData, startDate, endDate]);

    useEffect(() => { // PRODCTS TABLE DATA
        if (!!productsFilteredByDates && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const datesRange = getDatesInRange(new Date(datesSelector?.startDate), new Date(datesSelector?.endDate));
            const filteredData = productsFilteredByDates.filter((item) => {
                return datesRange.includes(item?.orderDate?.slice(0, 10));
            });
            const selectedRowsChannels: string[] = channelsFilters.map((row) => row?.original?.callbackId);
            let filtered: any = [];
            if (selectedRowsChannels.length > 0) {
                filtered = filteredData?.filter((order) => selectedRowsChannels.includes(order?.wvChannelId));
            } else {
                filtered = filteredData;
            }
            const sumOrders: any = {};
            filtered.forEach((order: any) => {
                if (!sumOrders[order?.sku]) {
                    sumOrders[order?.sku] = [order];
                }
                else {
                    sumOrders[order?.sku].push(order);
                }
            });

            const summedData: any[] = [];
            Object.keys(sumOrders).forEach((key) => {
                const summedKey = { ...sumData(sumOrders[key], ['wvChannelId', 'orderId', 'city', 'createdAt', 'fulfillmentChannel', 'id', 'orderDate', 'orderTime', 'productName', 'purchasePoint', 'sku', 'wvChannelId', 'updatedAt']), itemName: key };
                summedData.push(summedKey);
            });

            let totalRevenue = 0;
            summedData.forEach((item) => {
                totalRevenue = totalRevenue + item?.totalPaid;
            });
            const tableData = summedData.map((order): any => {
                return { channelName: order?.itemName, grossRevenue: order?.totalPaid, shareOfTotal: ((order?.totalPaid / totalRevenue) * 100) };
            });
            /* const orders = filteredData.map((order): AcrossTableData => {
                return {channelName: order?.productName, grossRevenue: order?.totalPaid,  }
            }); */
            setProductsData(tableData);

        }

    }, [channelsFilters, datesSelector?.endDate, datesSelector?.startDate, productsFilteredByDates]);

    useEffect(() => {

        if (!!kpis?.data?.data && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            let data = kpis?.data?.data;
            if(channelsFilters?.length > 0){
                const filters = channelsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvChannelId))
            }
            if(productsFilters?.length > 0){
                const filters = productsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvProductId))
            }
            const graphData = getOvertimeGraphDataForExplore(data,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "grossProfit", {display:unit === Unit.MARGIN, firstKey:"grossProfit", secondKey:"grossRevenue" });
            setActualData(graphData.data);
            setLabels(graphData.labels);
        }
    }, [kpis?.data?.data, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, channelsFilters, productsFilters, unit]);

    useEffect(() => {
        const wvChannelsArray = channelsFilters?.map(
            (item) => item?.original?.callbackId
        );

        if (!!allProductsData) {
            const allProductsIds: string[] = [];
            kpiData?.forEach((item) => {
                if (wvChannelsArray?.includes(item?.wvChannelId)) {
                    if (
                        !!item?.wvProductId &&
                        !allProductsIds?.includes(item?.wvProductId)
                    ) {
                        allProductsIds?.push(item?.wvProductId);
                    }
                }
            });

            const linkedData = linkProductData(
                allProductsData,
                kpiData,
                wvChannelsArray,
                "grossProfit",
                showAllProducts,
                allProductsIds
            );

            let totalNetRevenue = 0;
            let totalGrossRevenue = 0;
            linkedData?.forEach((item) => {
                if (!!item?.grossProfit) totalNetRevenue += item?.grossProfit;
                if (!!item?.grossRevenue) totalGrossRevenue += item?.grossRevenue;
            });


            const productDataTable = linkedData?.map((item: any) => {
                return {
                    productName:
                        item?.name === "NaN" || !item?.name ? item?.sku + "*" : item?.name,
                    grossProfit: item?.grossProfit,
                    grossMargin:
                        !!item?.grossRevenue && !!item?.netRevenue
                            ? (item?.grossProfit / item?.grossRevenue) * 100
                            : 0,
                    shareOfTotal: item?.share,
                    callbackId: item?.id,
                    grossRevenue: item?.grossRevenue
                };
            });
            setAcrossProductsData(productDataTable);
        }
    }, [
        datesSelector?.endDate,
        datesSelector?.startDate,
        allProductsData,
        kpiData,
        channelsFilters,
        showAllProducts,
    ]);


    useEffect(() => {

        if (!!kpis?.targetData?.data && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            let data = kpis?.targetData?.data;
            if(channelsFilters?.length > 0){
                const filters = channelsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvChannelId))
            }
            if(productsFilters?.length > 0){
                const filters = productsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvProductId))
            }
            const graphData = getOvertimeGraphDataForExplore(data,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "grossProfit", {display:unit === Unit.MARGIN, firstKey:"grossProfit", secondKey:"grossRevenue" });
            setTargetData(graphData.data);
            setLabels(graphData.labels);
        }
    }, [kpis?.targetData?.data, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, channelsFilters, productsFilters, unit]);

    useEffect(() => {
        if (!!kpiData) {
            let data = kpiData;
            if(channelsFilters?.length > 0){
                const filters = channelsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvChannelId))
            }
            if(productsFilters?.length > 0){
                const filters = productsFilters?.map((channel) => channel?.original?.callbackId);
                data = data?.filter((daily) => filters?.includes(daily?.wvProductId))
            }
            let totalRevenue = 0;
            let totalNet = 0;
            data.forEach((item) => {
                if (item?.grossProfit !== undefined) {
                    totalRevenue = totalRevenue + item?.grossRevenue;
                    totalNet = totalNet + item?.grossProfit;
                }
            });
            setTotalTableGrossMargin(
                !!totalRevenue && !!totalNet
                    ? (totalNet / totalRevenue) * 100
                    : 0
            );
            if(!!totalNet){
                setTotalGrossProfit(totalNet)
            }
            if (isNaN(totalNet / totalRevenue)) {
                setTotalNetMargin(0);
            } else {
                setTotalNetMargin((totalNet / totalRevenue) * 100);
            }
        }

    }, [kpiData, channelsFilters, productsFilters]);

    useEffect(() => {
        if (!!kpiData) {
            const kpiByChannel = {};

            if (channelsFilters.length > 0) {
                const selectedRowsChannels: any[] = channelsFilters.map((row) => row?.original?.callbackId);
                const filteredData = kpiData.filter((item) => {
                    return selectedRowsChannels.includes(item?.wvChannelId);
                });
                filteredData.forEach((item) => {
                    if (!kpiByChannel[item?.wvChannelId]) {
                        kpiByChannel[item?.wvChannelId] = [item];
                    }
                    else {
                        kpiByChannel[item?.wvChannelId].push(item);
                    }
                });
            }
            else {
                kpiData.forEach((item) => {
                    if (!kpiByChannel[item?.wvChannelId]) {
                        kpiByChannel[item?.wvChannelId] = [item];
                    }
                    else {
                        kpiByChannel[item?.wvChannelId].push(item);
                    }
                });
            }


            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const summedData: any[] = [];
            Object.keys(kpiByChannel).forEach((key) => {
                summedData.push({ ...sumData(kpiByChannel[key], keysToIgnore), channelName: getChannelNameById(key, channelsData.channels) });

            });
            const data: GraphData = {
                xLabels: summedData.map((item) => item.channelName),
                grossRevenue: summedData.map((item) => item.grossRevenue),
                netRevenue: summedData.map((item) => item.grossProfit),
                netMarginActual: summedData.map((item) => (item.grossProfit / item.grossRevenue) * 100),
            };
            setChannelsGraph(data);
        }
    }, [channelsData.channels, channelsFilters, kpiData]);
    useEffect(() => { // products graph data
        if (!!productsFilteredByDates) {
            const kpiByProduct = {};

            if (productsFilters.length > 0) {
                const selectedRowsChannels: any[] = productsFilters.map((row) => row?.original?.col_5);
                const filteredData = productsFilteredByDates.filter((item) => {
                    return selectedRowsChannels.includes(item?.sku);
                });
                filteredData.forEach((item) => {
                    if (!kpiByProduct[item.sku]) {
                        kpiByProduct[item.sku] = [item];
                    }
                    else {
                        kpiByProduct[item.sku].push(item);
                    }
                });
            }
            else {
                productsFilteredByDates.forEach((item) => {
                    if (!kpiByProduct[item.sku]) {
                        kpiByProduct[item.sku] = [item];
                    }
                    else {
                        kpiByProduct[item.sku].push(item);
                    }
                });
            }

            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market", "city", "orderDate", "orderId", "orderTime", "productName", "fulfillmentChannel"];
            const summedData: any[] = [];
            Object.keys(kpiByProduct).forEach((key) => {
                summedData.push({ ...sumData(kpiByProduct[key], keysToIgnore), sku: key });

            });
            const data: GraphData = {
                xLabels: summedData.map((item) => item?.sku),
                grossRevenue: summedData.map((item) => item?.grossRevenue),
                netRevenue: summedData.map((item) => item?.grossProfit),
                netMarginActual: summedData.map((item) => (item?.grossProfit / item?.grossRevenue) * 100),
            };
            setProductsGraph(data);

        }
    }, [channelsData.channels, channelsFilters, kpiData, productsFilteredByDates, productsFilters]);

    useEffect(() => {
        //filter efforts by kpi
        if (!!efforts?.data) {
            const filteredEfforts = efforts?.data.filter((item) => {
                return item?.kpi === KpisEnum.GROSS_MARGIN;
            });
            setEffortsTable(filteredEfforts);
        }
    }, [efforts?.data]);

    useEffect(() => {
        if (!!kpis?.data?.data && !!channelsFilters) {
            if (channelsFilters.length > 0) {
                const selectedRowsChannels: any[] = channelsFilters.map((row) => row?.original?.callbackId);
                const filteredData = kpis?.data?.data.filter((item) => {
                    return selectedRowsChannels.includes(item?.wvChannelId);
                });
                setFilteredKpiData(filteredData);
            }
            else {
                setFilteredKpiData(kpis?.data?.data);
            }
        }
    }, [channelsFilters, kpis?.data?.data]);

    useEffect(() => {
        if (!!kpiData && !!channelsData) {

            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const rowData: { channel?: string, grossProfit: number, shareOfTotal?: number,  callbackId?: string; grossMargin?: number }[] = [];
            const channels = {};
            kpiData?.forEach((item) => {
                if (!!channels[item?.wvChannelId]) {
                    channels[item?.wvChannelId]?.push(item);
                }
                else {
                    channels[item?.wvChannelId] = [item];
                }
            });

            Object.keys(channels).forEach((key) => {
                const summedChannel = sumData(channels[key], keysToIgnore);
                rowData.push({ grossProfit: summedChannel?.grossProfit, channel: getChannelNameById(key, channelsData?.channels), callbackId: key, grossMargin: !!summedChannel?.grossRevenue && !!summedChannel?.grossProfit ? (summedChannel?.grossProfit / summedChannel?.grossRevenue) * 100 : 0});
            });

            let totalGrossProfit = 0;
            rowData?.forEach((item) => {
                totalGrossProfit = totalGrossProfit + item?.grossProfit;
            });

            const newRowData = rowData?.map((item) => {
                return {
                    ...item,
                    shareOfTotal: Math.abs(Math.round((item?.grossProfit / totalGrossProfit) * 100 * 100) / 100),
                };
            });
            setAcrossChannelsData(newRowData);
        }
    }, [channelsData, kpiData]);

    useEffect(() => { // change on every explore
        if (!!kpiData) {
            const defaults = {
                TRANSACTION_FEE: "transactionFees",
                DISCOUNT: "discount",
                REFUNDS: "refunds",
                TAXES: "totalTax",
                SHIPPING: "totalShipping",
            };
            const selectedRowsChannels: string[] = channelsFilters.map((row) => row?.original?.callbackId);
            const selectedRowProducts: string[] = productsFilters.map((row) => row?.original?.callbackId);
            let data = kpiData;
            if (channelsFilters.length > 0) {
                data = kpiData?.filter((order) => selectedRowsChannels.includes(order?.wvChannelId));
            }
            if (productsFilters.length > 0) {
                data = kpiData?.filter((order) => !!order?.wvProductId && selectedRowProducts.includes(order?.wvProductId));
            }
            const dataByKey: any = {};
            const labels = dataLanguage?.pnlSettings?.netSales.map((item) => {
                if (!!item?.name && item.name in defaults) {
                    return defaults[item?.name];
                }
                return "pnl_" + item?.name;
            });
            labels?.forEach((label) => {
              if (!!label) {
                dataByKey[label] = 0;
              }
            });
            let totalGrossRevenue = 0;
            let totalNetRevenue = 0;
            let totalGrossProfit = 0;


            data.forEach((item: any) => {
                if(!!item?.grossRevenue){
                    totalGrossRevenue += item?.grossRevenue;
                }
                if(!!item?.netRevenue){
                    totalNetRevenue += item?.netRevenue;
                }
                if(!!item?.grossProfit){
                    totalGrossProfit += item?.grossProfit;
                }
            });

            let totalDeductionsGrossRevenue =  totalGrossRevenue - totalNetRevenue;
            let totalDeductionsNetRevenueRevenue =  totalNetRevenue - totalGrossProfit;
            let totalDeductions =  totalDeductionsNetRevenueRevenue + totalDeductionsGrossRevenue;

            const sumByKey = (objects: Kpi[], key?: string): number => {
                let sum = 0;

                for (const obj of objects) {
                    if(!!key){
                        const value = obj[key];

                        if (typeof value === 'number' && !isNaN(value)) {
                            sum += value;
                        }
                    }
                }

                return sum;
            }
            const fieldsToIgnore = ['grossRevenue', 'netRevenue'];
            const allPnls = pnls?.filter((pnl) => (pnl?.fieldName === 'grossProfit') || (pnl?.fieldName === 'netRevenue'))?.map((pnl) => pnl?.expression)?.flat()
            const allNetRevenuePnls = allPnls?.filter((pnl) => !pnl?.isOperator)?.map((pnl) => {
                if(!!pnl?.operand){
                    if(fieldsToIgnore.includes(pnl?.operand)){
                        return
                    }
                    return pnl?.operand
                }
                return
            })?.filter((pnl) => !!pnl);

            const tableData: any[] = [];

            allNetRevenuePnls?.forEach((key) => {
                const foundDisplayNameFromMetricForRow = pnlData?.find((metric) => metric?.fieldName === key)?.displayName;

                const value = Math.abs(sumByKey(data, key))
                const rowObject = {
                    name: foundDisplayNameFromMetricForRow ?? convertCamelCaseToString(key, pnlsDisplayNames),
                    value: value,
                    margin: !!totalGrossRevenue && !!value ? value / totalGrossRevenue * 100 : 0,
                    shareOfTotal: !!totalDeductions && !!value ? value / totalDeductions * 100 : 0,
                    totalGrossRevenue: totalGrossRevenue
                };
                tableData?.push(rowObject)

            })
            setExpansesTableData(tableData);
        }

    }, [channelsFilters, dataLanguage, kpiData, productsFilters, pnlsDisplayNames, pnlData]);
    function replaceUnderscoresWithDots(text?: string) {
        if(text?.includes('cogs')){
            return text
        }
        return text?.replace(/_/g, '.') ?? text;
    }
    function replaceDotsWithUnderscores(text?: string) {
        if(text?.includes('cogs')){
            return text
        }
        return text?.replace(/\./g, '_') ?? text;
    }
    useEffect(() => {

        if (!!kpiData) {

            const selectedRowsChannels: string[] = channelsFilters.map(
                (row) => row?.original?.callbackId
            );
            const selectedRowsProducts: string[] = productsFilters.map(
                (row) => row?.original?.callbackId
            );
            let filtered = kpiData;

            if (selectedRowsProducts.length > 0) {
                filtered = filtered?.filter((order) =>
                    !!order?.wvProductId && selectedRowsProducts.includes(order?.wvProductId)
                );
            }
            if (selectedRowsChannels.length > 0) {
                filtered = filtered?.filter((order) =>
                    selectedRowsChannels.includes(order?.wvChannelId)
                );
            }
            let totalGrossRevenue = 0;
            let totalNetRevenue = 0;


            filtered.forEach((item: any) => {
                if(!!item?.grossRevenue){
                    totalGrossRevenue += item?.grossRevenue;
                }
                if(!!item?.netRevenue){
                    totalNetRevenue += item?.netRevenue;
                }
            });


            const sumByKey = (objects: Kpi[], key?: string): number => {
                let sum = 0;

                for (const obj of objects) {
                    if(!!key){
                        const value = obj[key];

                        if (typeof value === 'number' && !isNaN(value)) {
                            sum += value;
                        }
                    }
                }

                return sum;
            }

            const listOfBreakdowns = () :string[] => {
                if(selectedExpenses?.length === 0){
                    return []
                }
                else {
                    const displayNamesOfBreakdowns = ['Cost of Goods', 'Marketing Cost', 'Amazon Fees', 'Refunds Cost'];
                    const allSelectedRowsByName = selectedExpenses?.map((item: any) => item?.original?.col_0)
                    const isSelectedNamesInBreakdown = displayNamesOfBreakdowns?.some((knownName) => allSelectedRowsByName?.includes(knownName));
                    if(isSelectedNamesInBreakdown){
                        const breakdownsToReturn:string[] = []
                        allSelectedRowsByName?.forEach((name: string | undefined) => {
                            if(name === 'Cost of Goods'){
                                breakdownsToReturn?.push('totalCogs');
                            }
                            if(name === 'Marketing Cost'){
                                breakdownsToReturn?.push('digitalMarketingSpend');
                            }
                            if(name === 'Amazon Fees'){
                                breakdownsToReturn?.push('amazonFees');
                            }
                            if(name === 'Refunds Cost'){
                                breakdownsToReturn?.push('refundsCost');
                            }
                        })
                        return breakdownsToReturn
                    }
                    else {
                        return []
                    }
                }
            }

            const pnlsToDisplay = pnls?.filter((item) => listOfBreakdowns()?.includes(item?.fieldName))?.map((item) => item?.expression)?.flat()

            const allNetRevenuePnls = pnlsToDisplay?.filter((pnl) => !pnl?.isOperator)?.map((pnl) => {
                if(!!pnl?.operand){
                    return pnl?.operand
                }
                return
            })?.filter((pnl) => !!pnl);

            let totalDeductions = 0;

            allNetRevenuePnls?.forEach((key) => {
                const value = (sumByKey(filtered, key)) * -1
                totalDeductions += value;
            })

            const tableData: any[] = [];

            allNetRevenuePnls?.forEach((key) => {
                const foundDisplayNameFromMetricForRow = pnlData?.find((metric) => metric?.fieldName === key)?.displayName;
                const rowName = pnlsDisplayNames?.find((item: any) => item?.fieldName === replaceUnderscoresWithDots(key))?.displayName ?? foundDisplayNameFromMetricForRow ?? key;
                const value = (sumByKey(filtered, replaceDotsWithUnderscores(key))) * -1
                const rowObject = {
                    name: rowName,
                    value: value,
                    margin: !!totalGrossRevenue && !!value ? value / totalGrossRevenue * 100 : 0,
                    shareOfTotal: !!totalDeductions && !!value ? value / totalDeductions * 100 : 0,
                    totalGrossRevenue: totalGrossRevenue
                };
                tableData?.push(rowObject)

            })

            setExpensesTableDataBreakdown(tableData);
        }
    }, [channelsFilters, pnls, kpiData, productsFilters, selectedExpenses, pnlsDisplayNames, pnlData]);

    useEffect(() => { // expensed graph data
        if (!!expanesTableData) {
            const selectedRowsExpenses: string[] = selectedExpenses.map((row: any) => row?.original?.col_0);
            let filtered: any = [];
            if (selectedRowsExpenses.length > 0) {
                filtered = expanesTableData?.filter((item: any) => selectedRowsExpenses.includes(item?.name));
            } else {
                filtered = expanesTableData;
            }
            const sortedByNumber = filtered.sort((x: any, y: any) => y?.value - x?.value);
            let totalOthers = 0;
            const graphValues = sortedByNumber.map((item: any, i: number) => {
                if (i <= 2) {
                    return item?.value;
                } else {
                    totalOthers = totalOthers + item?.value;
                    return;
                }
            });

            const labels = sortedByNumber.map((item: any, i: number) => {
                if (i <= 2) {
                    return item?.name;
                } else return;
            });

            const labelsData: string[] = [
                ...labels.filter((item: any) => item !== undefined),
            ];
            const defaults = {
                transactionFees: "Transaction Fees",
                discount: "Discount",
                refunds: "Refunds",
                totalTax: "Total Tax",
                totalShipping: "Shipping",
            };
            const transformedArray = labelsData?.map(str => str.replace(/^pnl_/, ''));

            const fixedLabels = transformedArray?.map((item) => {
                if (!!item && item in defaults) {
                    return defaults[item];
                }
                return item;
            });

            if (sortedByNumber.length > 3) {
                const values: number[] = [...graphValues.filter((item: any) => item !== undefined), totalOthers];
                const labelsData: string[] = [...fixedLabels, "All Others"];
                setExpensesGraphData({
                    labels: labelsData,
                    values
                });
            }
            else {
                const values: number[] = [...graphValues.filter((item: any) => item !== undefined)];
                setExpensesGraphData({
                    labels: fixedLabels,
                    values
                });
            }


        }
    }, [channelsFilters, expanesTableData, kpiData, selectedExpenses]);

    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(new Date(datesSelector.startDate));
            setEndDate(new Date(datesSelector.endDate));
        }
    };

    const setUnitHelper = (name: string) => {
        switch (name) {
            case "MARGIN":
                setUnit(Unit.MARGIN);
                break;
            case "VALUE":
                setUnit(Unit.VALUE);
                break;
            default:
                setUnit(Unit.MARGIN);
        }
    };


    const menuButtonsDataValue: MenuButton[] = [
        {
            title: "Margin",
            onClick: () => setUnitHelper("MARGIN"),
        },
        {
            title: "Value",
            onClick: () => setUnitHelper("VALUE"),
        },
    ];


    const renderDatePicker = () => {
        return (
            <>
                {
                    !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => { }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)} />
                        : <DatepickerRange onApply={(e) => { }} defaultsDate={datesSelector.preset} />
                }
            </>
        )

            ;
    };

    const renderHeader = () => {
        return <styles.Header>
            <styles.TitleWrapper>
                <styles.TitleDynamic>
                    <InformationWrapper
                    text={
                        unit === Unit.MARGIN
                            ? t('GROSS_MARGIN', { ns: 'translation' })
                            : "Gross Profit"
                    }/>
                </styles.TitleDynamic>
                <styles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </styles.TitleStatic>
                <MoreInfoButton onClick={() => openNetRevenueLanguageMoreInfoDialog(true)} />
                <MoreInfoDialog
                    props={GrossProfitExploreMoreInfoDialogProps}
                    isDialogOpen={isNetRevenueMoreInfoDialogOpen}
                    onClose={() => openNetRevenueLanguageMoreInfoDialog(false)}
                />
                <styles.HeaderButtonsWrapper>
                    <styles.HeaderButton>
                        <IconClearFiltersArrow />
                        <div style={{ width: '10px' }} />
                        {t('RESET_FILTERS', { ns: 'translation' })}
                    </styles.HeaderButton>
                    <Box>
                        <styles.ShowByText>
                            {t("SHOW_BY", { ns: "translation" })}
                        </styles.ShowByText>
                    </Box>
                    <div style={{ width: "5px" }} />
                    <PanelMenu
                        data={menuButtonsDataValue}
                        title={unit === Unit.MARGIN ? "Margin" : "Value"}
                    />
                    <div style={{ width: "10px" }} />
                    {renderDatePicker()}
                    <styles.HeaderButton onClick={onShareOpen}>
                        <IconShare />
                        <div style={{ width: '10px' }} />
                        {t('SHARE', { ns: 'translation' })}
                    </styles.HeaderButton>
                        <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                            <IconCloseOverlay />
                        </button>
                </styles.HeaderButtonsWrapper>
            </styles.TitleWrapper>
            <styles.ValueWrapper>
                <styles.Value>
                    {unit === Unit.VALUE
                        ? currentCurrency +
                        totalGrossProfit?.toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                        : totalNetMargin.toFixed(2) + "%"}
                </styles.Value>
                {/* <TargetBadge percentage={101} /> //TODO .V2 */}
            </styles.ValueWrapper>
            <styles.SubTitle>
                {t('DRILL_DOWN_TO_THE_MAIN_DRIVERS_OF_THIS_KPI_(OVER_TIME,_CHANNELS,_SUPPORTING_KPIS)_AND_ASSIGN_AN_OWNER_TO_THIS_TASK_ACCORDINGLY.', { ns: 'translation' })}
            </styles.SubTitle>
        </styles.Header>;
    };


    return (
        <styles.Wrapper>
            <ShareKpi exploreEnum={ExploresEnum.GROSS_PROFIT} isOpen={isShareOpen} onClose={onShareClose} onOpen={onShareOpen} />
            <EffortsModal dataToShow={{ kpi: "Gross Profit" }} isOpen={isEffortsOpen} onClose={onEffortsClose} onOpen={onEffortsOpen} />
            {renderHeader()}
            <styles.SectionWrapper>
                <OverTimeGraph displayByPercentage={unit === Unit.MARGIN}
                               exploreGraph={true} displayGantt={true}
                               setGanttLabels={setGanttLabels}
                               clearFilters={resetFilters}
                               groupByStatus={groupByStatus}
                               setGroupBy={setGroupByStatus}
                               kpiEnum={KpisEnum.GROSS_PROFIT}
                               setStartDate={setStartDate}
                               target={targetData}
                               setEndDate={setEndDate}
                               actual={actualData}
                               labels={labels} />
            </styles.SectionWrapper>
            <styles.SectionWrapper>
                <styles.Card>
                    <GenericExploreTable
                        icon={<IconAcrossChannels />}
                        setSelectedRows={setChannelsFilters}
                        data={acrossChannelsData}
                        defaultSortByKey={"shareOfTotal"}
                        channelsTable={true}
                        headers={[
                            {
                                header: "Channel",
                                accessor: "channel",
                                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                            },
                            {
                                header: "Gross Profit",
                                accessor: "grossProfit",
                                cellType: ExploreTableDataEnum.CURRENCY,
                                footerType: ExploreTableFooterTypeEnum.SUM,
                            },
                            {
                                header: "Gross Margin",
                                accessor: "grossMargin",
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                                footer: () => {
                                    return totalTableGrossMargin?.toFixed(2);
                                },
                            },
                            {
                                header: "Share of total",
                                accessor: "shareOfTotal",
                                footerType: ExploreTableFooterTypeEnum.SUM,
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                            },
                        ]}
                        height={500}
                        tableHeight={380}
                        title={t("ACROSS_CHANNELS", { ns: "translation" })}
                    />
                </styles.Card>
                <div style={{ width: '20px' }} />
                <styles.Card>
                    <GenericExploreTable
                        icon={<IconAcrossProducts />}
                        setSelectedRows={setProductsFilters}
                        data={acrossProductsData}
                        defaultSortByKey={"shareOfTotal"}
                        setShowAllProducts={setShowAllProducts}
                        isInfiniteScroll={true}
                        headers={[
                            {
                                header: "Product Name",
                                accessor: "productName",
                                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                                numberOfRowsToDisplay: 2
                            },
                            {
                                header: "Gross Profit",
                                accessor: "grossProfit",
                                cellType: ExploreTableDataEnum.CURRENCY,
                                footerType: ExploreTableFooterTypeEnum.SUM,
                            },
                            {
                                header: "Gross Margin",
                                accessor: "grossMargin",
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                                footer: (value, rows) => {
                                    let totalGrossProfit = 0;
                                    let totalGrossRevenue = 0;
                                    rows?.forEach((row: Row<{grossProfit?: number, grossRevenue?: number}> ) => {
                                        if (!row.original.grossProfit) {
                                            row.original.grossProfit = 0;
                                        }
                                        if (!row.original.grossRevenue) {
                                            row.original.grossRevenue = 0;
                                        }
                                        totalGrossProfit += row.original.grossProfit;
                                        totalGrossRevenue += row.original.grossRevenue;
                                    })
                                    return !!totalGrossProfit && !!totalGrossRevenue ? (totalGrossProfit / totalGrossRevenue * 100) : 0
                                },
                            },
                            {
                                header: "Share of total",
                                accessor: "shareOfTotal",
                                footerType: ExploreTableFooterTypeEnum.SUM,
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                            },
                        ]}
                        height={500}
                        tableHeight={380}
                        title={t("ACROSS_PRODUCTS", { ns: "translation" })}
                    />
                </styles.Card>
            </styles.SectionWrapper>
            <styles.SectionWrapper>
                <styles.Card>
                    <ExpenseTable
                        icon={<IconAcrossProducts />}
                        headers={[
                            t('EXPENSE_TYPE', { ns: 'translation' }),
                            t('VALUE', { ns: 'translation' }),
                            t("% of Gross Revenue", { ns: "translation" }),
                            t('SHARE_OF_TOTAL', { ns: 'translation' }),
                        ]}
                        data={expanesTableData}
                        setSelectedRows={setSelectedExpenses}
                        title={t('Expenses', { ns: 'translation' })} />
                </styles.Card>
                <div style={{ width: '20px' }} />
                <styles.Card>
                    <ExpenseTable
                        icon={<IconAcrossProducts />}
                        headers={[
                            t("EXPENSE_TYPE", { ns: "translation" }),
                            t("VALUE", { ns: "translation" }),
                            t("% of Gross Revenue", { ns: "translation" }),
                            t("SHARE_OF_TOTAL", { ns: "translation" }),
                        ]}
                        data={expensesTableDataBreakdown}
                        title={t("EXPENSES_BREAKDOWN", { ns: "translation" })}
                    />
                </styles.Card>
              {/*  <styles.Card>
                    {expenesGraphData?.values.length === 0 ? <DoughnutGraph colors={["#959597", "#7A4CBF", "#b998e9", "#E5D5FA",]}
                        data={[100]}
                        labels={["No Data"]}
                        sign={currentCurrency}
                        noData
                    /> : <DoughnutGraph colors={["#7A35F5", "#7A4CBF", "#b998e9", "#E5D5FA",]}
                        data={expenesGraphData?.values}
                        labels={expenesGraphData?.labels}
                        sign={currentCurrency}
                    />}
                </styles.Card>*/}
            </styles.SectionWrapper>
            <styles.SectionWrapper>
                <styles.Card>

                    <styles.TitleWrapper padding={"20px"}>
                        <styles.Title>
                            <span style={{ marginRight: '5px' }}>
                                {<IconDiamond />}
                            </span>
                            {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        </styles.Title>
                        {/* <DownloadButton onClick={() => console.log('download')} /> //TODO .V2*/}
                    </styles.TitleWrapper>
                    {!!effortsTable ? <EffortsTable
                        title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        data={effortsTable}
                        cameFromExplore
                        onOpen={onEffortsOpen}
                        headers={columns}
                    /> : <Spinner colorScheme={"purple"} />}
                </styles.Card>
            </styles.SectionWrapper>
        </styles.Wrapper>
    );
};

// @ts-ignore
import {calculateTotalForExplore} from "./calculateTotalForExplore";
import {Channel} from "../../store/channels";
import {getChannelNameById} from "../get-channel-name/getChannelNameById";
import {getDatesInRange} from "../colors";

interface TableRow {
    [key: string]: any;
}

export const mergeLastPeriodAndDailyStatsData = (dailyStatsTableData?: TableRow[], lastPeriodTableData?: TableRow[], fieldName?: string) => {
    const mergedTableData: TableRow[] = [];
    dailyStatsTableData?.forEach((tableRow) => {
        const foundLastPeriodDataForThisRow = lastPeriodTableData?.find((lastPeriodTableRow) => lastPeriodTableRow?.callbackId === tableRow?.callbackId);
        if(!!foundLastPeriodDataForThisRow && !!fieldName){
            const newKeyToAddToRow = 'lastPeriod' + fieldName
            if(fieldName in foundLastPeriodDataForThisRow){
                tableRow[newKeyToAddToRow] = foundLastPeriodDataForThisRow[fieldName]
            }
        }
        else{
            const newKeyToAddToRow = 'lastPeriod' + fieldName
            tableRow[newKeyToAddToRow] = 0
        }
        mergedTableData?.push(tableRow)
    })

    return mergedTableData


}
import {createSlice} from "@reduxjs/toolkit";
import {FinancialState, initalFinancialState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {clearFinancialState} from "./actions";
import {fetchFinancialData} from "./thunks";

function buildCleanFinancialStateReducer(
  builder: ActionReducerMapBuilder<FinancialState>
) {
  builder.addCase(clearFinancialState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildFetchFinancialDataReducer(
  builder: ActionReducerMapBuilder<FinancialState>
) {
  builder
    .addCase(fetchFinancialData.pending, (state, action) => {
      state.data = undefined;
      return state;
    })
    .addCase(fetchFinancialData.fulfilled, (state, action) => {
      state.data = action.payload;
      return state;
    })
    .addCase(fetchFinancialData.rejected, (state, action) => {
      state.data = undefined;
      return state;
    });
}

export const financielSlice = createSlice({
  name: "financial",
  initialState: initalFinancialState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanFinancialStateReducer(builder);

    // async
    buildFetchFinancialDataReducer(builder);
  },
});

// reducer
export const financialReducer = financielSlice.reducer;

// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { fetchTodos } from "store/organization";

// locals
import { clearTodosState } from "./actions";
import { initalTodos, TodosState } from "./state";

// reducers
// clean organization state
function buildCleanTodosStateReducer(
  builder: ActionReducerMapBuilder<TodosState>
) {
  builder.addCase(clearTodosState, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.data = undefined;
    return state;
  });
}

// fetch organization
function buildFetchTodosReducer(builder: ActionReducerMapBuilder<TodosState>) {
  builder
    .addCase(fetchTodos.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(fetchTodos.fulfilled, (state, action) => {
      if (
        !!action?.payload?.response?.status &&
        action.payload.response.status === 403
      ) {
        return state;
      }
      state.error = undefined;
      state.loading = false;
      state.data = action?.payload;
      return state;
    })
    .addCase(fetchTodos.rejected, (state, action) => {
      state.error = undefined;
      state.loading = false;
      return state;
    });
}

// slice

export const todosSlice = createSlice({
  name: "todos",
  initialState: initalTodos,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanTodosStateReducer(builder);

    // async
    // fetch organization
    buildFetchTodosReducer(builder);
  },
});

// reducer
export const todosReducer = todosSlice.reducer;

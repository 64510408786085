import { CurrencyCode } from "@store";
import { SVGProps } from "react";
import {
  aeFlag,
  bgFlag,
  brFlag,
  caFlag,
  chFlag,
  czFlag,
  dkFlag,
} from "utils/flags";
import { auFlag } from "utils/flags/flags-svg/au";
import { euFlag } from "utils/flags/flags-svg/eu";
import { gbFlag } from "utils/flags/flags-svg/gb";
import { hkFlag } from "utils/flags/flags-svg/hk";
import { hrFlag } from "utils/flags/flags-svg/hr";
import { huFlag } from "utils/flags/flags-svg/hu";
import { idFlag } from "utils/flags/flags-svg/id";
import { ilFlag } from "utils/flags/flags-svg/il";
import { inFlag } from "utils/flags/flags-svg/in";
import { jpFlag } from "utils/flags/flags-svg/jp";
import { myFlag } from "utils/flags/flags-svg/my";
import { noFlag } from "utils/flags/flags-svg/no";
import { nzFlag } from "utils/flags/flags-svg/nz";
import { plFlag } from "utils/flags/flags-svg/pl";
import { roFlag } from "utils/flags/flags-svg/ro";
import { seFlag } from "utils/flags/flags-svg/se";
import { sgFlag } from "utils/flags/flags-svg/sg";
import { trFlag } from "utils/flags/flags-svg/tr";
import { uaFlag } from "utils/flags/flags-svg/ua";
import { usFlag } from "utils/flags/flags-svg/us";

export interface CurrencyData {
  value: {
    sign: string;
    name: string;
    code: string;
    flag: SVGProps<SVGElement>;
    enum: CurrencyCode;
  };
}

export const currencyOptions: CurrencyData[] = [
  {
    value: {
      sign: "€",
      name: "Euro",
      code: "EUR",
      flag: euFlag(),
      enum: CurrencyCode["EUR"],
    },
  },
  {
    value: {
      sign: "£",
      name: "Pound Sterling",
      code: "GBP",
      flag: gbFlag(),
      enum: CurrencyCode["GBP"],
    },
  },
  {
    value: {
      sign: "$",
      name: "US Dollar",
      code: "USD",
      flag: usFlag(),
      enum: CurrencyCode["USD"],
    },
  },
  {
    value: {
      sign: "₪",
      name: "New Israeli Shekel",
      code: "ILS",
      flag: ilFlag(),
      enum: CurrencyCode["ILS"],
    },
  },
  {
    value: {
      sign: "د.إ",
      name: "UAE Dirham",
      code: "AED",
      flag: aeFlag(),
      enum: CurrencyCode["AED"],
    },
  },
  {
    value: {
      sign: "$",
      name: "Australian Dollar",
      code: "AUD",
      flag: auFlag(),
      enum: CurrencyCode["AUD"],
    },
  },
  {
    value: {
      sign: "лв",
      name: "Bulgarian Lev",
      code: "BGN",
      flag: bgFlag(),
      enum: CurrencyCode["BGN"],
    },
  },
  {
    value: {
      sign: "R$",
      name: "Brazilian Real",
      code: "BRL",
      flag: brFlag(),
      enum: CurrencyCode["BRL"],
    },
  },
  {
    value: {
      sign: "$",
      name: "Canadian Dollar",
      code: "CAD",
      flag: caFlag(),
      enum: CurrencyCode["CAD"],
    },
  },
  {
    value: {
      sign: "₣",
      name: "Swiss Franc",
      code: "CHF",
      flag: chFlag(),
      enum: CurrencyCode["CHF"],
    },
  },
  {
    value: {
      sign: "Kč",
      name: "Czech Koruna",
      code: "CZK",
      flag: czFlag(),
      enum: CurrencyCode["CZK"],
    },
  },

  {
    value: {
      sign: "kr",
      name: "Danish Krone",
      code: "DKK",
      flag: dkFlag(),
      enum: CurrencyCode["DKK"],
    },
  },
  {
    value: {
      sign: "$",
      name: "Hong Kong Dollar",
      code: "HKD",
      flag: hkFlag(),
      enum: CurrencyCode["HKD"],
    },
  },
  {
    value: {
      sign: "Kn",
      name: "Croatian Kuna",
      code: "HRK",
      flag: hrFlag(),
      enum: CurrencyCode["HRK"],
    },
  },
  {
    value: {
      sign: "Ft",
      name: "Forint",
      code: "HUF",
      flag: huFlag(),
      enum: CurrencyCode["HUF"],
    },
  },
  {
    value: {
      sign: "Rp",
      name: "Rupiah",
      code: "IDR",
      flag: idFlag(),
      enum: CurrencyCode["IDR"],
    },
  },
  {
    value: {
      sign: "₹",
      name: "Indian Rupee",
      code: "INR",
      flag: inFlag(),
      enum: CurrencyCode["INR"],
    },
  },
  {
    value: {
      sign: "¥",
      name: "Yen",
      code: "JPY",
      flag: jpFlag(),
      enum: CurrencyCode["JPY"],
    },
  },
  {
    value: {
      sign: "RM",
      name: "Malaysian Ringgit",
      code: "MYR",
      flag: myFlag(),
      enum: CurrencyCode["MYR"],
    },
  },
  {
    value: {
      sign: "kr",
      name: "Norwegian Krone",
      code: "NOK",
      flag: noFlag(),
      enum: CurrencyCode["NOK"],
    },
  },
  {
    value: {
      sign: "$",
      name: "New Zealand Dollar",
      code: "NZD",
      flag: nzFlag(),
      enum: CurrencyCode["NZD"],
    },
  },
  {
    value: {
      sign: "zł",
      name: "PZloty",
      code: "PLN",
      flag: plFlag(),
      enum: CurrencyCode["PLN"],
    },
  },
  {
    value: {
      sign: "L",
      name: "Leu",
      code: "RON",
      flag: roFlag(),
      enum: CurrencyCode["RON"],
    },
  },
  {
    value: {
      sign: "kr",
      name: "Swedish Krona",
      code: "SEK",
      flag: seFlag(),
      enum: CurrencyCode["SEK"],
    },
  },
  {
    value: {
      sign: "$",
      name: "Singapore Dollar",
      code: "SGD",
      flag: sgFlag(),
      enum: CurrencyCode["SGD"],
    },
  },
  {
    value: {
      sign: "₤",
      name: "Turkish Lira",
      code: "TRY",
      flag: trFlag(),
      enum: CurrencyCode["TRY"],
    },
  },
  {
    value: {
      sign: "₴",
      name: "Hryvnia",
      code: "UAH",
      flag: uaFlag(),
      enum: CurrencyCode["UAH"],
    },
  },
];

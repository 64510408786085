import { Box } from '@chakra-ui/react';
import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { ExploresEnum, openDefaultExploreView } from 'store/ui';
import { NoAssignedKpiIcon } from 'utils/icons/wideview-logo';
import { styles } from './styles';

export default function NoAssignedKpis() {

    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.KPI_FRAMEWORK }));
    };

    return (
        <styles.Wrapper>
            <styles.ComponentBackground>
                <styles.DataWrapper>
                    <NoAssignedKpiIcon />
                    <Box w={4} h={67.5} />
                    <styles.TextWrapper>
                        <styles.Text>
                            There isn’t any KPIs assigned to you.
                        </styles.Text>
                        <styles.Text>
                            Try can change that on <styles.ClickSpan onClick={handleClick}>KPI Framework</styles.ClickSpan>
                        </styles.Text>
                    </styles.TextWrapper>

                </styles.DataWrapper>
            </styles.ComponentBackground>
        </styles.Wrapper>
    );
}

import {Kpi} from "../../models/kpi";

export const linkProductData = (products: { productTitle?: string; id?: string, sku?:string }[],
                                data: Kpi[], wvChannelsArray: string[], shareKey: string,
                                showAllData: boolean, allProductsToDisplay: string[]) => {


    const totalGrossRevenue = data.reduce((acc, item) => acc + item[shareKey], 0);
    const totalData = products.map((product) => {
        if( wvChannelsArray?.length > 0 && !!product?.id && !allProductsToDisplay?.includes(product?.id)){
            return
        }
        let matchingData = data.filter((item) => item?.wvProductId === product.id);


        if (wvChannelsArray.length > 0) {
            matchingData = matchingData.filter((item) => wvChannelsArray.includes(item.wvChannelId));
        }

        const productData: any = matchingData.reduce((acc, item) => {
            for (const key in item) {
                if (typeof item[key] === 'number') {
                    acc[key] = (acc[key] || 0) + (item[key] || 0);
                }
            }
            return acc;
        }, {});
        const share = shareKey in productData && totalGrossRevenue > 0 ? productData[shareKey] / totalGrossRevenue : 0;

        if(!showAllData){
            if(shareKey in productData && !productData[shareKey] || !(shareKey in productData)){
                return
            }
        }
        return {
            id: product?.id,
            name: product.productTitle,
            sku: product?.sku,
            ...productData,
            share: isNaN(share) ? 0 : share * 100
        };
    });
    return totalData?.filter((item) => !!item);
}

import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    //padding: 0px 18px;
  `;

  export const SmallerWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px 21px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const RowsNumberInTotal = styled.div`
    font-size: initial;
    justify-content: start;
    display: flex;
  `;


  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 21px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Card = styled.div`
    /* background-color: white; */
    width: 100%;
    height: auto;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;

  export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 10px;
  `;

  export const Footer = styled.div`
    display: flex;
    width: 100%;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    //justify-content: end;
  `;

  export const CreateEffortButton = styled.div`
    //width: 180px;
    max-width: 100%;
    padding: 0 20px;
    min-height: 40px;
    border-radius: 10px;
    margin: 10px 0 0 0;
    font-size: 0.875em;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  `;

  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    margin: 25px 0 0 0;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;

  export const IconShareWrapper = styled.div`
    margin: 0 5px;
  `;

  export const LoaderWrapper = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
  `;
  export const TabTitle = styled.div`
    display: flex;
    //width: 100%;
    color: black;
    font-size: 1.23em;
    font-weight: bold;
    display: flex;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
    overflow: hidden;
    white-space: nowrap;
    width: 300px;

    &:nth-child(2) {
      font-size: 1.2em;
    }
  `;

  export const Title = styled.div`
    display: flex;
    //width: 100%;
    color: black;
    font-size: 21px;
    font-weight: bold;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
    overflow: hidden;
    white-space: nowrap;
  `;

  export const AssignedKpisWrapper = styled.div`
    width: 100%;
  `;

  export const AssignedKpisWrapperHeader = styled.div`
    /*width: calc(100% - 27px);*/
    //max-width: 100%;
    // max-width: 1500px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 80px;
    align-items: center;

    @media screen and (max-width: 1440px) {
      width: calc(100% - 12px);
    }
  `;

  export const AssignedKpisToggle = styled.div`
    width: 450px;
    margin: 0 auto;
  `;

  export const PrivatePublicToggle = styled.div`
    width: 150px;
  `;

  export const DatePickerButton = styled.div`
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 10px;
  `;

  export const StyledHolder = styled.div`
    width: 90%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background: linear-gradient(
      0deg,
      rgba(243, 238, 251, 1) 0%,
      rgba(253, 253, 254, 1) 100%
    );
  `;

  export const ShadowScroll = styled.div``;

  export const AssignedKpisContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    justify-content: start;
    padding: 30px;
    margin: 15px 0;
    gap: 8px;
    overflow-x: hidden;
    /*box-shadow: inset -13px 0 9px -9px rgb(0 0 0 / 10%);*/

    :hover {
      overflow-x: scroll;
      padding: 30px 30px 24px 30px;
    }
  `;

  export const AssignedKpiCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 5px;
  `;
  export const AddAssignedKpiButtonWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  `;

  export const AddAssignedKpiButtonCard = styled.div`
    min-width: 240px;
    height: 130px;
    display: block;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgb(17 12 46 / 15%) 5px 6px 12px 2px;
    background-color: ${(props) => props.theme.primaryColor + "30"};

    &:hover {
      cursor: pointer;
      opacity: 0.4;
    }
  `;

  export const Shadow = styled.div``;

  // content
  export const AddAssignedKpiButtonContent = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  export const AddAssignedKpiButtonText = styled.div`
    font-size: 16px;
    color: black;
    opacity: 1;
  `;
}

export default styles;

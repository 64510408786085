import marketingIntegrationsStyles from "./styles";
import {useTranslation} from "react-i18next";
import {Flex, Text} from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform,
    IntegrationType
} from "@models";
import {googleAdsConnectUrl} from "@constants";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import {
    addIntegration,
    getFacebookAdsAccounts,
    getGoogleAdsAccounts,
    getGoogleAdsAccountsV2,
    organizationSelector
} from "@store";
import {useDispatch, useSelector} from "react-redux";
import {facebookAdsAuthenticate} from "../../../store/facebook";
import {IntegrationButton} from "../../../components/integration-button";
import {integrationSelector} from "store/integrations";
import {getAmazonAdsRefreshToken} from "../../../store/amazon-ads";
import {AmazonAdsDialog} from "../../../dialogs/amazon-seller-dialog/AmazonAdsDialog";
import {useState} from "react";

export const MarketingIntegrations = () => {
    const [isAmazonRegionDialogOpen, setIsAmazonRegionDialogOpen] = useState(false);

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const integrations = useSelector(integrationSelector);


    // helpers

    const sendMarketingOAuthFromSearchParams = (params: URLSearchParams, platform: IntegrationPlatform) => {
        // @ts-ignore
        const googleOAuthData = params.get('result');
        try {
            if (!!googleOAuthData && organization?.id) {
                dispatch(getGoogleAdsAccountsV2({
                    object: JSON.parse(googleOAuthData),
                    organizationId: organization?.id
                }))
                /*  dispatch(addIntegration({
                      integration: {
                          name: '',
                          type: IntegrationType.MARKETING,
                          platform: platform,
                          object: JSON.parse(mondayOAuthData),
                      },
                      organizationId: organization?.id
                  }));
              }*/
            }
        } catch (err) {
            console.log(err);
        }
    };


    const getTaboolaParamas = (paramas: URLSearchParams) => {

    };
    const getOutbrainParamas = (paramas: URLSearchParams) => {

    };

    const facebookAdsOAuthAuthentication = () => {
        dispatch(facebookAdsAuthenticate({}));
    };

    const amazonAdsOAuthAuthentication = () => {
        dispatch(getAmazonAdsRefreshToken({ }));
    };
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    // renderers
    const renderStoreIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('MARKETING', { ns: 'translation' })}
                </Text>
                <Flex wrap={"wrap"} direction={"row"}>
                    {renderFacebookConnector()}
                    {renderGoogleAdsConnector()}
                    {renderAmazonAdsConnector()}
                    {renderTikTokAdsConnector()}
                    {renderTaboolaConnector()}
                    {renderOutbrainConnector()}
                </Flex>
            </Flex>
        );
    };

    const renderFacebookConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.FACEBOOK_ADS);
        return (
            <marketingIntegrationsStyles.ButtonWrapper onClick={() => facebookAdsOAuthAuthentication()}>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    badgeText={badgeText}>
                </IntegrationButton>
            </marketingIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderGoogleAdsConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.GOOGLE_ADS);
        const googleAdsOAuthUrl = `${googleAdsConnectUrl}`;
        return (
            <OAuthPopup
                title={'Shopify'}
                height={window.screenY}
                width={window.screenX}
                url={googleAdsOAuthUrl ?? ''}
                onResponse={(params) => {
                    sendMarketingOAuthFromSearchParams(params!, IntegrationPlatform.GOOGLE_ADS);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            </OAuthPopup>
        );
    };
    const renderTaboolaConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.TABOOLA);
        return (
            /*<OAuthPopup
                title={'Taboola'}
                height={window.screenY}
                width={window.screenX}
                url={'www.google.com'}
                onResponse={(params) => {
                    getTaboolaParamas(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };
    const renderOutbrainConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.OUTBRAIN);
        return (
            /*<OAuthPopup
                title={'Outbrain'}
                height={window.screenY}
                width={window.screenX}
                url={'www.google.com'}
                onResponse={(params) => {
                    getOutbrainParamas(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderAmazonAdsDialog = () => {
        return (
            <AmazonAdsDialog
                isDialogOpen={isAmazonRegionDialogOpen}
                onClose={() => setIsAmazonRegionDialogOpen(false)}
                onAuthorization={(params) => {
                    amazonAdsOAuthAuthentication()
                }}
            />
        );
    };

    const renderAmazonAdsConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.AMAZON_ADS);
        return (
            <marketingIntegrationsStyles.ButtonWrapper onClick={() => setIsAmazonRegionDialogOpen(true)}>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    badgeText={badgeText}>
                </IntegrationButton>
            </marketingIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderTikTokAdsConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.TIKTOK);
        return (
           /* <OAuthPopup
                title={'TikTok'}
                height={window.screenY}
                width={window.screenX}
                url={tiktokAuthUrl ?? ''}
                onResponse={(params) => {
                    sendMarketingOAuthFromSearchParams(params!, IntegrationPlatform.TIKTOK);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderStoreIntegrations()}
            {renderAmazonAdsDialog()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

import React from "react";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger} from "@chakra-ui/react";
import {premiumLogo} from "../../constants/integration";
import {styles} from './styles'

interface Props {
    fixedTopRight?: boolean;
    tooltipText?: string;
    width?: string;
    height?: string;
}

const PremiumIcon = (props: Props) => {

    const renderPopOverPremium = (trigger: ReactJSXElement) => {
        return (
            <Popover placement={"top"} trigger={"hover"}>
                <PopoverTrigger>{trigger}</PopoverTrigger>
                <PopoverContent maxW={"150px"}>
                    <PopoverArrow backgroundColor={"black"} />
                    <PopoverBody
                        h={"75%"}
                        borderRadius={"10px"}
                        backgroundColor={"black"}
                        color={"white"}
                    >
                        <Flex w={"100%"} justifyContent={"center"} alignItems={"center"}>
                            {!!props?.tooltipText ? props.tooltipText : 'Premium Plan'}
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };

    return (
        <>
            {renderPopOverPremium(<styles.PremiumWrapper fixedTopRight={props?.fixedTopRight} width={props?.width} height={props?.height}>
                <img src={premiumLogo}/>
            </styles.PremiumWrapper>)}
        </>

    )
};

export default PremiumIcon;

import { apiBaseUrl } from "@constants";
import { Workspace } from "@models";
import axios, { AxiosResponse } from "axios";
import { KpiWorkspace } from "services/kpi/kpi.service";

const pinKpi = async (
  workspace: Workspace,
  userId: string,
  workspaceId: string
): Promise<AxiosResponse<any>> => {
  return axios.put<KpiWorkspace>(
    `${apiBaseUrl}/workspaces/${userId}/workspace/${workspaceId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),

      },
      ...workspace,
    }
  );
};
const assignKpi = async (
  workspace: Workspace,
  userId: string,
  workspaceId: string,
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.put<KpiWorkspace>(
    `${apiBaseUrl}/workspaces/${userId}/workspace/${workspaceId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),

      },
      ...workspace,
    }
  );
};

export const workspaceService = {
  pinKpi,
  assignKpi,
};

import React, { useState } from "react";

export const IconClock = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g id="invisible_box" data-name="invisible box">
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="26"
          height="26"
          fill="none"
        />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.077 1.222)">
        <path
          id="Path_112"
          data-name="Path 112"
          d="M13.85,4.155a9.7,9.7,0,1,1-9.7,9.7,9.7,9.7,0,0,1,9.7-9.7M13.85,2a11.8,11.8,0,1,0,8.4,3.455A11.85,11.85,0,0,0,13.85,2Z"
          transform="translate(-2 -2)"
          fill="#7031eb"
        />
        <path
          id="Path_113"
          data-name="Path 113"
          d="M28.464,22.773a.916.916,0,0,1-.539-.162L22,19.056V13.077a1.077,1.077,0,1,1,2.155,0v4.794L29,20.78a1.074,1.074,0,0,1-.539,1.993Z"
          transform="translate(-11.227 -6.613)"
          fill="#7031eb"
        />
      </g>
    </svg>
  );
};

export const IconDiamond = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(0.001)">
        <g id="invisible_box" data-name="invisible box">
          <rect
            id="Rectangle_264"
            data-name="Rectangle 264"
            width="27"
            height="27"
            transform="translate(-0.001)"
            fill="none"
          />
        </g>
        <g
          id="Q3_icons"
          data-name="Q3 icons"
          transform="translate(1.774 3.517)"
        >
          <path
            id="Path_348"
            data-name="Path 348"
            d="M20.82,6H6.637L1.4,12.6,13.728,26.729,26.056,12.6ZM8,13.637l2.837,6.437L5.218,13.637Zm2.4,0h6.655l-3.328,7.637Zm9.055,0h2.782l-5.619,6.437Zm.273-5.455,2.618,3.273H19.183L17.492,8.182Zm-4.691,0,1.691,3.273h-6l1.691-3.273Zm-7.31,0H9.964L8.273,11.455H5.109Z"
            transform="translate(-1.4 -6)"
            fill="#7031eb"
          />
        </g>
      </g>
    </svg>
  );
};

export const IconTreeArrowUp = (size: { width?: number; height?: number }) => {
  return (
    <svg
      style={{
        width: size.width ?? 45,
        height: size.height ?? 45,
        marginBottom: 100,
        position: "absolute",
      }}
      id="Group_878"
      data-name="Group 878"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111.287 127.132"
      preserveAspectRatio="none"
    >
      {/*<svg id="Group_878" data-name="Group 878" xmlns="http://www.w3.org/2000/svg"  width="111.287" height="127.132" viewBox="0 0 111.287 127.132">*/}
      <path
        id="Path"
        d="M0,0V33.91c0,9.364,8.76,16.955,19.566,16.955H97.83c10.806,0,19.566,7.591,19.566,16.955v33.91"
        transform="translate(5.5 122.632) rotate(-90)"
        fill="none"
        stroke="#9cb0c0"
        stroke-linecap="round"
        strokeWidth="4"
        stroke-dasharray="1 8"
      />
      <g id="B" transform="translate(102.231)">
        <path
          id="Triangle"
          d="M0,1.766A1,1,0,0,1,1.514.909L8.571,5.143a1,1,0,0,1,0,1.715L1.514,11.091A1,1,0,0,1,0,10.234Z"
          transform="translate(0 -0.765)"
          fill="#9cb0c0"
        />
      </g>
      <g id="A" transform="translate(11 127.132) rotate(180)">
        <path
          id="Circle"
          d="M5.5,11A5.5,5.5,0,1,1,11,5.5,5.5,5.5,0,0,1,5.5,11Z"
          fill="#9cb0c0"
        />
      </g>
    </svg>
  );
};

export const IconTreeArrowDown = (size: { width: number; height: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: size.width ?? 45,
        height: size.height ?? 45,
        marginTop: 100,
      }}
      viewBox="0 0 120.697 132.184"
    >
      <g
        id="Group_3252"
        data-name="Group 3252"
        transform="translate(-413.297 -1847.316)"
      >
        <path
          id="Union_20"
          data-name="Union 20"
          d="M23761.449,25989.039v-8.473a1,1,0,0,1,1.521-.855l7.051,4.234a1,1,0,0,1,0,1.715l-2.621,1.572a2.009,2.009,0,0,1-.783.473l-3.646,2.189a1.011,1.011,0,0,1-.52.145A1,1,0,0,1,23761.449,25989.039Zm-5.445-1.238a2,2,0,0,1,0-4h1a2,2,0,0,1,0,4Zm-8.994,0a2,2,0,1,1,0-4h1a2,2,0,0,1,0,4Zm-9,0a2,2,0,1,1,0-4h1a2,2,0,1,1,0,4Zm-8.344-.256c-.371-.064-.742-.15-1.115-.24a2.006,2.006,0,0,1-1.506-1.937,2.1,2.1,0,0,1,.059-.49,2,2,0,0,1,2.434-1.447c.273.064.561.131.84.182a2,2,0,0,1-.354,3.967A2.046,2.046,0,0,1,23729.664,25987.545Zm-8.9-4.584c-.268-.248-.535-.516-.789-.783a1.989,1.989,0,0,1-.555-1.383,2,2,0,0,1,3.451-1.377c.2.215.418.424.627.627a2,2,0,1,1-2.734,2.916Zm-5.434-8.168c-.129-.338-.26-.678-.371-1.023a1.869,1.869,0,0,1-.111-.646,2,2,0,0,1,3.895-.645c.1.287.2.58.313.861a2,2,0,0,1-1.135,2.588,1.962,1.962,0,0,1-.729.141A2,2,0,0,1,23715.334,25974.793Zm-1.748-9.529v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,0,1,4,0v1a2,2,0,0,1-4,0Zm0-9v-1.006a2,2,0,0,1,4,0v1.006a2,2,0,0,1-4,0Zm0-9v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,1,1,4,0v1a2,2,0,1,1-4,0Zm0-9v-1a2,2,0,0,1,4,0v1a2,2,0,0,1-4,0Zm0-9v-1.006a2,2,0,0,1,4-.025v1.031a2,2,0,0,1-4,0Zm-1.291-8.154c-.1-.287-.215-.574-.332-.854a2.1,2.1,0,0,1-.15-.764,2,2,0,0,1,3.848-.764c.137.332.268.672.393,1.012a2,2,0,0,1-3.758,1.369Zm-4.271-6.836c-.209-.209-.424-.418-.646-.613a1.979,1.979,0,0,1-.666-1.494,1.992,1.992,0,0,1,3.328-1.486c.273.242.547.5.809.764a2,2,0,0,1-2.824,2.83Zm-58.209-5.922a5.5,5.5,0,1,1,5.5,5.5A5.5,5.5,0,0,1,23649.814,25863.354Zm51.451,2.107c-.275-.066-.561-.119-.842-.158a2,2,0,0,1-1.709-1.977,2.109,2.109,0,0,1,.02-.293,2,2,0,0,1,2.27-1.689c.379.059.758.125,1.123.209a2,2,0,0,1-.432,3.953A2.034,2.034,0,0,1,23701.266,25865.461Zm-9.543-.287a2,2,0,1,1,0-4h1a2,2,0,1,1,0,4Zm-9,0a2,2,0,0,1,0-4h1a2,2,0,0,1,0,4Zm-9,0a2,2,0,0,1,0-4h1.006a2,2,0,0,1,0,4Zm-8.994,0a2,2,0,1,1,0-4h1a2,2,0,1,1,0,4Z"
          transform="translate(-23236.518 -24010.539)"
          fill="#9cb0c0"
        />
      </g>
    </svg>
  );
};

export const IconAcrossChannels = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="invisible_box" data-name="invisible box">
        <rect
          id="Rectangle_170"
          data-name="Rectangle 170"
          width="27"
          height="27"
          fill="none"
        />
        <rect
          id="Rectangle_171"
          data-name="Rectangle 171"
          width="27"
          height="27"
          fill="none"
        />
        <rect
          id="Rectangle_172"
          data-name="Rectangle 172"
          width="27"
          height="27"
          fill="none"
        />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.284 1.38)">
        <path
          id="Path_128"
          data-name="Path 128"
          d="M24.134,10.927a3.466,3.466,0,0,0-3.187,2.232H16.2a5.518,5.518,0,0,0-.839-1.841L17.314,9.03a4.368,4.368,0,0,0,1.23.223A3.348,3.348,0,1,0,15.19,5.905a3.4,3.4,0,0,0,.447,1.674L13.624,9.867a4.759,4.759,0,0,0-2.348-.614l-.95.112L9.655,7.8A3.357,3.357,0,1,0,7.363,8.7h.224l.727,1.562a4.96,4.96,0,0,0,0,8.034l-.727,1.562H7.363a3.348,3.348,0,1,0,2.292.893l.671-1.562.95.112a4.759,4.759,0,0,0,2.348-.614l2.013,2.288a3.4,3.4,0,0,0-.447,1.674A3.354,3.354,0,1,0,18.544,19.3a4.368,4.368,0,0,0-1.23.223l-1.957-2.288A5.518,5.518,0,0,0,16.2,15.39h4.752a3.466,3.466,0,0,0,3.187,2.232,3.348,3.348,0,1,0,0-6.7ZM6.245,5.347A1.118,1.118,0,1,1,7.363,6.463,1.117,1.117,0,0,1,6.245,5.347Zm1.118,18.97A1.116,1.116,0,1,1,8.481,23.2,1.117,1.117,0,0,1,7.363,24.317ZM18.544,4.789a1.116,1.116,0,1,1-1.118,1.116A1.117,1.117,0,0,1,18.544,4.789ZM8.481,14.274a2.8,2.8,0,1,1,2.8,2.79,2.792,2.792,0,0,1-2.8-2.79Zm11.181,8.369a1.118,1.118,0,1,1-1.118-1.116A1.117,1.117,0,0,1,19.662,22.644Zm4.472-7.253a1.116,1.116,0,1,1,1.118-1.116A1.117,1.117,0,0,1,24.134,15.39Z"
          transform="translate(-4.02 -1.999)"
          fill="#7031eb"
        />
      </g>
    </svg>
  );
};

export const IconAcrossProducts = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-0.438 -0.438)">
        <g
          id="invisible_box"
          data-name="invisible box"
          transform="translate(0.438 0.438)"
        >
          <rect
            id="Rectangle_261"
            data-name="Rectangle 261"
            width="27"
            height="27"
            fill="none"
          />
          <rect
            id="Rectangle_262"
            data-name="Rectangle 262"
            width="27"
            height="27"
            fill="none"
          />
          <rect
            id="Rectangle_263"
            data-name="Rectangle 263"
            width="27"
            height="27"
            fill="none"
          />
        </g>
        <g
          id="Q3_icons"
          data-name="Q3 icons"
          transform="translate(2.406 1.203)"
        >
          <g id="Group_658" data-name="Group 658">
            <path
              id="Path_345"
              data-name="Path 345"
              d="M25.909,18H5.153A1.179,1.179,0,0,0,4,19.2V32.438a1.179,1.179,0,0,0,1.153,1.2H25.909a1.179,1.179,0,0,0,1.153-1.2V19.2A1.179,1.179,0,0,0,25.909,18ZM24.756,31.234H6.306V20.406h5.766a3.462,3.462,0,1,0,6.919,0h5.766Z"
              transform="translate(-4 -9.375)"
              fill="#7031eb"
            />
            <path
              id="Path_346"
              data-name="Path 346"
              d="M8.144,12.406H25.309a1.2,1.2,0,0,0,0-2.406H8.144a1.2,1.2,0,0,0,0,2.406Z"
              transform="translate(-5.195 -5.398)"
              fill="#7031eb"
            />
            <path
              id="Path_347"
              data-name="Path 347"
              d="M11.132,4.406h13.58a1.169,1.169,0,0,0,1.132-1.2A1.169,1.169,0,0,0,24.712,2H11.132A1.169,1.169,0,0,0,10,3.2,1.169,1.169,0,0,0,11.132,4.406Z"
              transform="translate(-6.391 -2)"
              fill="#7031eb"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconEcommerceFunnel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-0.447 0.377)">
        <g
          id="invisible_box"
          data-name="invisible box"
          transform="translate(0.447 -0.376)"
        >
          <rect
            id="Rectangle_169"
            data-name="Rectangle 169"
            width="27"
            height="27"
            transform="translate(0 0)"
            fill="none"
          />
        </g>
        <g
          id="Q3_icons"
          data-name="Q3 icons"
          transform="translate(2.113 0.455)"
        >
          <path
            id="Path_127"
            data-name="Path 127"
            d="M16.38,17.757h9a1.125,1.125,0,0,0,1.125-1.125v-4.5A1.125,1.125,0,0,0,25.384,11h-9a1.125,1.125,0,0,0-1.125,1.125v1.125h-4.5v-4.5h3.376a1.125,1.125,0,0,0,1.125-1.125v-4.5A1.125,1.125,0,0,0,14.129,2h-9A1.125,1.125,0,0,0,4,3.125v4.5A1.125,1.125,0,0,0,5.125,8.753H8.5V23.384A1.125,1.125,0,0,0,9.627,24.51h5.627v1.125a1.125,1.125,0,0,0,1.125,1.125h9a1.125,1.125,0,0,0,1.125-1.125v-4.5a1.125,1.125,0,0,0-1.125-1.125h-9a1.125,1.125,0,0,0-1.125,1.125v1.125h-4.5V15.506h4.5v1.125A1.125,1.125,0,0,0,16.38,17.757Zm1.125-4.5h6.753v2.251H17.506Zm-11.255-9H13V6.5H6.251ZM17.506,22.259h6.753V24.51H17.506Z"
            transform="translate(-4 -2)"
            fill="#7031eb"
          />
        </g>
      </g>
    </svg>
  );
};

export const IconClearFiltersArrow = (size?: { width?: number; height?: number }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18.413"
      style={{
        width: size?.width ?? 24,
        height: size?.height ?? 18.413,
      }}
      viewBox="0 0 24 18.413"
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <path
          id="np_arrow_2094740_000000"
          d="M9.334,13.239C18.668,14.465,22.666,20.6,24,26.74c-3.334-4.3-8-6.262-14.666-6.262v5.033L0,16.919,9.334,8.327Z"
          transform="translate(0 -8.327)"
          fill="#7031eb"
        />
      ) : (
        <path
          id="np_arrow_2094740_000000"
          d="M9.334,13.239C18.668,14.465,22.666,20.6,24,26.74c-3.334-4.3-8-6.262-14.666-6.262v5.033L0,16.919,9.334,8.327Z"
          transform="translate(0 -8.327)"
          fill="#464a53"
        />
      )}
    </svg>
  );
};

export const IconCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.995"
      height="17.031"
      viewBox="0 0 14.995 17.031"
    >
      <path
        id="icon_calendar"
        data-name="icon calendar"
        d="M426.29,793.008h-.009a4.237,4.237,0,0,1-3.116-1.176,4.432,4.432,0,0,1-1.157-3.183L422,781.559a4.393,4.393,0,0,1,1.015-2.98,4.076,4.076,0,0,1,2.738-1.3v-.639a.666.666,0,0,1,.183-.471.628.628,0,0,1,.9.013.66.66,0,0,1,.18.457v.6l4.885-.007v-.6a.663.663,0,0,1,.181-.47.628.628,0,0,1,.9.013.66.66,0,0,1,.181.455v.639a4.1,4.1,0,0,1,2.766,1.266,4.308,4.308,0,0,1,1.049,2.967l.009,7.174A3.987,3.987,0,0,1,432.72,793Zm6.572-4.945a.7.7,0,1,0,.008,0Zm-6.768,0h0a.718.718,0,0,0-.673.732.693.693,0,0,0,.675.681h.033a.668.668,0,0,0,.471-.226.709.709,0,0,0,.179-.511.685.685,0,0,0-.682-.681Zm3.388,0h0a.721.721,0,0,0-.673.733.693.693,0,0,0,.678.681h.03a.7.7,0,0,0,.649-.733.684.684,0,0,0-.682-.681ZM426.091,785h0a.718.718,0,0,0-.673.732.691.691,0,0,0,.676.681h.033a.666.666,0,0,0,.469-.226.71.71,0,0,0,.178-.511.684.684,0,0,0-.681-.681Zm3.389-.03h0a.718.718,0,0,0-.674.732.693.693,0,0,0,.679.681h.03a.7.7,0,0,0,.648-.733.684.684,0,0,0-.681-.681Zm3.39.005h0a.7.7,0,0,0-.673.714v.01a.685.685,0,0,0,.684.681h.016a.7.7,0,0,0-.023-1.4Zm-7.116-6.391c-1.653.17-2.491,1.171-2.489,2.974v.265l12.45-.017v-.3c-.033-1.8-.89-2.786-2.544-2.933v.656a.644.644,0,0,1-.634.653.629.629,0,0,1-.449-.187.667.667,0,0,1-.181-.464v-.689l-4.885.007v.688a.644.644,0,0,1-.637.651.631.631,0,0,1-.446-.186.667.667,0,0,1-.181-.465v-.656Z"
        transform="translate(-421.996 -775.981)"
        fill="#7031eb"
      />
    </svg>
  );
};

export const IconShare = (props: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 17 17"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(0 0.315)">
        <g
          id="invisible_box"
          data-name="invisible box"
          transform="translate(0.011 -0.305)"
        >
          <rect
            id="Rectangle_266"
            data-name="Rectangle 266"
            width="17"
            height="17"
            transform="translate(-0.011 -0.011)"
            fill="none"
          />
        </g>
        <g id="Layer_6" data-name="Layer 6" transform="translate(0.705 1.907)">
          <g id="Group_702" data-name="Group 702" fill={props?.color}>
            <path
              id="Path_353"
              data-name="Path 353"
              d="M13.256,6h-.211L2.491,9.1A.7.7,0,0,0,2,9.8v3.166a.774.774,0,0,0,.493.7l1.653.457.563,3.623a1.02,1.02,0,0,0,1.02.915H7.311a1.126,1.126,0,0,0,.774-.352A1.161,1.161,0,0,0,8.3,17.4l-.387-2.146,5.136,1.513h.211a.7.7,0,0,0,.7-.7V6.7A.7.7,0,0,0,13.256,6Zm-6.4,11.257H6.044l-.422-2.674.739.211Zm5.7-2.111L3.406,12.438V10.327l9.147-2.674Z"
              transform="translate(-1.998 -6)"
              fill={props?.color ?? "#7031eb"}
            />
            <path
              id="Path_354"
              data-name="Path 354"
              d="M38.693,11.4a.528.528,0,0,0,.317-.07l1.407-.7a.708.708,0,0,0-.633-1.266l-1.407.7a.7.7,0,0,0-.317.95.739.739,0,0,0,.633.387Z"
              transform="translate(-25.324 -8.131)"
              fill={props?.color ?? "#7031eb"}
            />
            <path
              id="Path_355"
              data-name="Path 355"
              d="M40.417,28.17l-1.407-.7a.739.739,0,0,0-.95.281.7.7,0,0,0,.317.95l1.407.7a.528.528,0,0,0,.317.07.739.739,0,0,0,.633-.387.668.668,0,0,0-.317-.915Z"
              transform="translate(-25.324 -19.867)"
              fill={props?.color ?? "#7031eb"}
            />
            <path
              id="Path_356"
              data-name="Path 356"
              d="M38.7,20.707h1.407a.7.7,0,0,0,0-1.407H38.7a.7.7,0,1,0,0,1.407Z"
              transform="translate(-25.335 -14.621)"
              fill={props?.color ?? "#7031eb"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconMarketingStrategy = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7031eb" />
          <stop offset="1" stopColor="#4a55fb" />
        </linearGradient>
      </defs>
      <g id="invisible_box" data-name="invisible box">
        <rect
          id="Rectangle_382"
          data-name="Rectangle 382"
          width="48"
          height="48"
          fill="none"
        />
      </g>
      <g id="Health_Icons" data-name="Health Icons">
        <g id="Group_1135" data-name="Group 1135">
          <path
            id="Path_532"
            data-name="Path 532"
            d="M45.8,16.4v-.3l-5-10.9A2,2,0,0,0,39,4H9A2,2,0,0,0,7.2,5.2L2.3,16.1v.3a6,6,0,0,0,1,5.2,6.9,6.9,0,0,0,2.8,2V41a2.9,2.9,0,0,0,3,3H39a2.9,2.9,0,0,0,3-3V23.6a6.9,6.9,0,0,0,2.8-2,6,6,0,0,0,1-5.2ZM6,17.6,10.3,8H37.7L42,17.6a1.9,1.9,0,0,1-.4,1.5,2.1,2.1,0,0,1-1.8.9h-.9a2.2,2.2,0,0,1-2.3-2.1,2,2,0,1,0-4,0A2.1,2.1,0,0,1,30.4,20H28.2A2.1,2.1,0,0,1,26,17.9a2,2,0,1,0-4,0A2.1,2.1,0,0,1,19.8,20H17.6a2.1,2.1,0,0,1-2.2-2.1,2,2,0,1,0-4,0A2.2,2.2,0,0,1,9.1,20H8.2a2.1,2.1,0,0,1-1.8-.9A1.9,1.9,0,0,1,6,17.6ZM35,40V27H28V40H10V23.9a5.9,5.9,0,0,0,3.4-1.5A6.3,6.3,0,0,0,17.6,24h2.2A6.3,6.3,0,0,0,24,22.4,6.3,6.3,0,0,0,28.2,24h2.2a6.3,6.3,0,0,0,4.2-1.6A5.9,5.9,0,0,0,38,23.9V40Z"
            fill="url(#linear-gradient)"
          />
          <rect
            id="Rectangle_383"
            data-name="Rectangle 383"
            width="11"
            height="10"
            transform="translate(13 27)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
    </svg>
  );
};

export const IconMarketing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={"100%"}
      height={"100%"}
      viewBox="0 0 64 64"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7031eb" />
          <stop offset="1" stopColor="#4a55fb" />
        </linearGradient>
      </defs>
      <g id="Group_3471" data-name="Group 3471" transform="translate(-5 -375)">
        <path
          id="Path_1806"
          data-name="Path 1806"
          d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z"
          transform="translate(5 375)"
          fill="url(#linear-gradient)"
        />
        <g
          id="Layer_2"
          data-name="Layer 2"
          transform="translate(14.656 391.835) rotate(-11)"
        >
          <g id="invisible_box" data-name="invisible box">
            <rect
              id="Rectangle_386"
              data-name="Rectangle 386"
              width="38.352"
              height="38.352"
              fill="none"
            />
          </g>
          <g
            id="Layer_6"
            data-name="Layer 6"
            transform="translate(1.596 4.794)"
          >
            <g id="Group_1136" data-name="Group 1136">
              <path
                id="Path_535"
                data-name="Path 535"
                d="M27.567,6h-.479L3.118,13.031A1.6,1.6,0,0,0,2,14.629V21.82a1.758,1.758,0,0,0,1.119,1.6l3.755,1.039,1.278,8.23a2.317,2.317,0,0,0,2.317,2.077h3.6a2.557,2.557,0,0,0,1.758-.8,2.637,2.637,0,0,0,.479-2.077l-.879-4.874,11.665,3.436h.479a1.6,1.6,0,0,0,1.6-1.6V7.6A1.6,1.6,0,0,0,27.567,6ZM13.026,31.568H11.188L10.229,25.5l1.678.479Zm12.944-4.794L5.2,20.622V15.828L25.969,9.755Z"
                transform="translate(-1.998 -6)"
                fill="#fff"
              />
              <path
                id="Path_536"
                data-name="Path 536"
                d="M39.595,14.091a1.2,1.2,0,0,0,.719-.16l3.2-1.6a1.608,1.608,0,1,0-1.438-2.876l-3.2,1.6a1.6,1.6,0,0,0-.719,2.157,1.678,1.678,0,0,0,1.438.879Z"
                transform="translate(-9.231 -6.661)"
                fill="#fff"
              />
              <path
                id="Path_537"
                data-name="Path 537"
                d="M43.51,29.156l-3.2-1.6a1.678,1.678,0,0,0-2.157.639,1.6,1.6,0,0,0,.719,2.157l3.2,1.6a1.2,1.2,0,0,0,.719.16,1.678,1.678,0,0,0,1.438-.879,1.518,1.518,0,0,0-.719-2.077Z"
                transform="translate(-9.231 -10.3)"
                fill="#fff"
              />
              <path
                id="Path_538"
                data-name="Path 538"
                d="M39.6,22.5h3.2a1.6,1.6,0,1,0,0-3.2H39.6a1.6,1.6,0,1,0,0,3.2Z"
                transform="translate(-9.234 -8.673)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconGeneral = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={"100%"}
      height={"100%"}
      viewBox="0 0 64 64"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7031eb" />
          <stop offset="1" stopColor="#4a55fb" />
        </linearGradient>
      </defs>
      <g id="Group_3471" data-name="Group 3471" transform="translate(-5 -375)">
        <path
          id="Path_1806"
          data-name="Path 1806"
          d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z"
          transform="translate(5 375)"
          fill="url(#linear-gradient)"
        />
        <g
          id="Layer_2"
          data-name="Layer 2"
          transform="translate(18.816 386.522)"
        >
          <g
            id="invisible_box"
            data-name="invisible box"
            transform="translate(0 0.294)"
          >
            <rect
              id="Rectangle_379"
              data-name="Rectangle 379"
              width="37"
              height="37"
              transform="translate(0.184 0.184)"
              fill="none"
            />
          </g>
          <g
            id="icons_Q2"
            data-name="icons Q2"
            transform="translate(3.136 3.162)"
          >
            <path
              id="Path_527"
              data-name="Path 527"
              d="M7.136,5.725v15.76A4.861,4.861,0,0,0,4,25.954a4.7,4.7,0,0,0,3.136,4.391v3.293A1.647,1.647,0,0,0,8.7,35.363a1.647,1.647,0,0,0,1.568-1.725V30.345a4.7,4.7,0,0,0,3.136-4.391,4.861,4.861,0,0,0-3.136-4.469V5.725A1.647,1.647,0,0,0,8.7,4,1.647,1.647,0,0,0,7.136,5.725Zm3.136,20.229A1.568,1.568,0,1,1,8.7,24.386,1.568,1.568,0,0,1,10.273,25.954Z"
              transform="translate(-4 -4)"
              fill="#fff"
            />
            <path
              id="Path_528"
              data-name="Path 528"
              d="M21.136,5.768V8.983A4.861,4.861,0,0,0,18,13.452a4.7,4.7,0,0,0,3.136,4.391V33.681a1.568,1.568,0,0,0,3.136,0V17.843a4.7,4.7,0,0,0,3.136-4.391,4.861,4.861,0,0,0-3.136-4.469V5.768a1.568,1.568,0,1,0-3.136,0Zm3.136,7.684A1.568,1.568,0,1,1,22.7,11.884,1.568,1.568,0,0,1,24.273,13.452Z"
              transform="translate(-7.023 -4.043)"
              fill="#fff"
            />
            <path
              id="Path_529"
              data-name="Path 529"
              d="M35.136,5.768V19.176A4.861,4.861,0,0,0,32,23.645a4.7,4.7,0,0,0,3.136,4.391v5.645a1.568,1.568,0,0,0,3.136,0V28.036a4.7,4.7,0,0,0,3.136-4.391,4.861,4.861,0,0,0-3.136-4.469V5.768a1.568,1.568,0,0,0-3.136,0Zm3.136,17.877A1.568,1.568,0,1,1,36.7,22.077,1.568,1.568,0,0,1,38.273,23.645Z"
              transform="translate(-10.046 -4.043)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const EyeIconShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g
        id="Group_1129"
        data-name="Group 1129"
        transform="translate(-1093.533 -477.377)"
      >
        <circle
          id="Ellipse_100"
          data-name="Ellipse 100"
          cx="22"
          cy="22"
          r="22"
          transform="translate(1093.533 477.377)"
          fill="#e5d5fa"
        />
        <g
          id="Layer_2"
          data-name="Layer 2"
          transform="translate(1098.248 482.715)"
        >
          <g
            id="invisible_box"
            data-name="invisible box"
            transform="translate(0 0)"
          >
            <rect
              id="Rectangle_372"
              data-name="Rectangle 372"
              width="34"
              height="34"
              transform="translate(0.285 -0.338)"
              fill="none"
            />
          </g>
          <g
            id="icons_Q2"
            data-name="icons Q2"
            transform="translate(2.428 7.808)"
          >
            <path
              id="Path_523"
              data-name="Path 523"
              d="M32.6,18.842h0C31.113,17,25.6,11,17.549,11S3.984,17,2.5,18.842a2.119,2.119,0,0,0,0,2.685c1.484,1.837,6.994,7.842,15.048,7.842s13.564-6,15.048-7.842A2.119,2.119,0,0,0,32.6,18.842Zm-15.048,7.7c-6.217,0-10.809-4.38-12.5-6.358,1.7-1.978,6.288-6.358,12.5-6.358s10.809,4.38,12.5,6.358C28.358,22.162,23.765,26.542,17.549,26.542Z"
              transform="translate(-2.694 -11.146)"
              fill="#7031eb"
            />
            <circle
              id="Ellipse_101"
              data-name="Ellipse 101"
              cx="3"
              cy="3"
              r="3"
              transform="translate(11.855 5.854)"
              fill="#7031eb"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TreeArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      height="100%"
      viewBox="0 0 107.788 128.866"
    >
      <g
        id="Group_902"
        data-name="Group 902"
        transform="translate(-426.204 -1850.633)"
      >
        <path
          id="Path"
          d="M0,101.731V67.821c0-9.364,9.151-16.955,20.438-16.955h81.754c11.288,0,20.438-7.591,20.438-16.955V0"
          transform="translate(529.936 1852.633) rotate(90)"
          fill="none"
          stroke="#9cb0c0"
          stroke-linecap="round"
          stroke-width="4"
          stroke-dasharray="1 8"
        />
        <g id="B" transform="translate(524.936 1969.028)">
          <path
            id="Triangle"
            d="M0,1.766A1,1,0,0,1,1.514.909L8.571,5.143a1,1,0,0,1,0,1.715L1.514,11.091A1,1,0,0,1,0,10.234Z"
            transform="translate(0 -0.765)"
            fill="#9cb0c0"
          />
        </g>
      </g>
    </svg>
  );
};

export const TreeArrowUp = () => {
  return (
    <svg
      id="Group_878"
      data-name="Group 878"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 111.287 127.132"
    >
      <path
        id="Path"
        d="M0,0V33.91c0,9.364,8.76,16.955,19.566,16.955H97.83c10.806,0,19.566,7.591,19.566,16.955v33.91"
        transform="translate(5.5 122.632) rotate(-90)"
        fill="none"
        stroke="#9cb0c0"
        stroke-linecap="round"
        stroke-width="4"
        stroke-dasharray="1 8"
      />
      <g id="B" transform="translate(102.231)">
        <path
          id="Triangle"
          d="M0,1.766A1,1,0,0,1,1.514.909L8.571,5.143a1,1,0,0,1,0,1.715L1.514,11.091A1,1,0,0,1,0,10.234Z"
          transform="translate(0 -0.765)"
          fill="#9cb0c0"
        />
      </g>
      {/* <g id="A" transform="translate(11 127.132) rotate(180)">
        <path
          id="Circle"
          d="M5.5,11A5.5,5.5,0,1,1,11,5.5,5.5,5.5,0,0,1,5.5,11Z"
          fill="#9cb0c0"
        />
      </g>*/}
    </svg>
  );
};

export const TreeArrowDownFullWidth = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      viewBox="0 0 107.788 128.866"
    >
      <g
        id="Group_902"
        data-name="Group 902"
        transform="translate(-426.204 -1850.633)"
      >
        <path
          id="Path"
          d="M0,101.731V67.821c0-9.364,9.151-16.955,20.438-16.955h81.754c11.288,0,20.438-7.591,20.438-16.955V0"
          transform="translate(529.936 1852.633) rotate(90)"
          fill="none"
          stroke="#9cb0c0"
          stroke-linecap="round"
          stroke-width="4"
          stroke-dasharray="1 8"
        />
        <g id="B" transform="translate(524.936 1969.028)">
          <path
            id="Triangle"
            d="M0,1.766A1,1,0,0,1,1.514.909L8.571,5.143a1,1,0,0,1,0,1.715L1.514,11.091A1,1,0,0,1,0,10.234Z"
            transform="translate(0 -0.765)"
            fill="#9cb0c0"
          />
        </g>
      </g>
    </svg>
  );
};

export const TreeArrowUpFullWidth = () => {
  return (
    <svg
      id="Group_878"
      data-name="Group 878"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111.287 127.132"
    >
      <path
        id="Path"
        d="M0,0V33.91c0,9.364,8.76,16.955,19.566,16.955H97.83c10.806,0,19.566,7.591,19.566,16.955v33.91"
        transform="translate(5.5 122.632) rotate(-90)"
        fill="none"
        stroke="#9cb0c0"
        stroke-linecap="round"
        stroke-width="4"
        stroke-dasharray="1 8"
      />
      <g id="B" transform="translate(102.231)">
        <path
          id="Triangle"
          d="M0,1.766A1,1,0,0,1,1.514.909L8.571,5.143a1,1,0,0,1,0,1.715L1.514,11.091A1,1,0,0,1,0,10.234Z"
          transform="translate(0 -0.765)"
          fill="#9cb0c0"
        />
      </g>
      <g id="A" transform="translate(11 127.132) rotate(180)">
        <path
          id="Circle"
          d="M5.5,11A5.5,5.5,0,1,1,11,5.5,5.5,5.5,0,0,1,5.5,11Z"
          fill="#9cb0c0"
        />
      </g>
    </svg>
  );
};

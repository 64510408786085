import styled from 'styled-components';
import {Spinner} from "@chakra-ui/react";

export namespace styles {

    export const Wrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: ${props => props.theme.backgroundColor ?? '#f9f9ff'};
    `;

    export const Loader = styled(Spinner)`
      height: 48px;
      width: 48px;
      color: ${props => props.theme.primaryColor ?? '#7331EC'};
    `;

}

export default styles;

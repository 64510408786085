import React, {useEffect, useState} from "react";
import {SummarizeResponseDto} from "../../services/kpi/kpi.service";
import {
    BigWideviewCard,
    DataMode,
} from "../../components/big-wideview-card/BigWideviewCard";
import {Flex} from "@chakra-ui/react";
import {KpisEnum} from "../../models/kpi-settings";
import {DictionaryAssinedKpiKeyUsersValue, User, UserAvatar} from "@models";
import {useSelector} from "react-redux";
import {organizationSelector} from "@store";
import {usersDataSelector} from "../../store/users";

interface Props {
    cardsData?: SummarizeResponseDto[];
}

const BigWideviewCardsRender = (props: Props) => {
    const organizationId = useSelector(organizationSelector);
    const users = useSelector(usersDataSelector);
    const [dictionary, setDictionary] =
        useState<DictionaryAssinedKpiKeyUsersValue>({});
    const [usersForKpiRender, setUsersForKpiRender] = useState<any[]>([]);

    const getArrayRelevantKpiAssignedNumberValue = () =>
        Object.values(KpisEnum).slice(Object.values(KpisEnum).length / 2 + 1);

    const returnCurrentWorkspaceObject = (user: User, currentWorkspace: any) => {
        return {
            userName: user?.firstName,
            userLastName: user?.lastName,
            userId: user?.id,
            workspaceId: currentWorkspace!.id!,
            assignedKpis: currentWorkspace?.assignedKpis,
        } as UserAvatar;
    };

    useEffect(() => {
        const usersData = users?.map((user) => {
            const currentWorkspaceIndex = user?.workspaces.findIndex(
                (workspace) => workspace?.organization === organizationId?.id
            );

            const currentWorkspace = user?.workspaces[currentWorkspaceIndex];

            if (!!currentWorkspace) {
                return returnCurrentWorkspaceObject(user, currentWorkspace);
            }
            return undefined;
        });

        if (!!usersData) {
            const filterUndefinedUser = usersData.filter(
                (item) => !!item && item?.assignedKpis?.length
            );
            const keyArray = getArrayRelevantKpiAssignedNumberValue();
            if (!!filterUndefinedUser && filterUndefinedUser.length && !!keyArray) {
                setUsersForKpiRender(usersData);
                let temp: DictionaryAssinedKpiKeyUsersValue = {};
                for (let i = 0; i < keyArray.length; i++) {
                    temp[keyArray[i]] = [];
                    filterUndefinedUser?.forEach((user, index) => {
                        const keyAssignedKpiValueNumber = user?.assignedKpis?.find(
                            (kpiItem) => keyArray[i] === kpiItem
                        );
                        if (!!keyAssignedKpiValueNumber) {
                            temp[keyArray[i]].push(user);
                        }
                    });
                }
                setDictionary(temp);
            }
        }
    }, [organizationId?.id, users]);

    const getCardDataMode = (card: SummarizeResponseDto): DataMode | undefined => {
        if (!card?.value && card?.value !== 0) {
            return DataMode.Broken
        }
        return
    }

    const getAvatarArray = (indexKpiNumber?: number | string | KpisEnum): [] => {
        if (!!dictionary && !!indexKpiNumber) {
            if (!!dictionary[indexKpiNumber]) {
                return dictionary[indexKpiNumber];
            }
        }
        //else
        return [];
    };

    const CardsToDisplay = () => {
        // if(props?.cardsData?.length === 0){
        //     return <>
        //         <BigWideviewCard dataMode={DataMode.Broken} key={1} index={1} value={''} title={'Gross Revenue'} />
        //         <BigWideviewCard dataMode={DataMode.Broken} key={2} index={2} value={''} title={'Marketing Spend'} />
        //         <BigWideviewCard dataMode={DataMode.Broken} key={3} index={3} value={''} title={'Net Revenue'} />
        //     </>
        // }
        // else {
        return (
            <>
                {props?.cardsData?.map((card, i) => {
                    let cardValue = card?.value?.toLocaleString("en-us", {
                        maximumFractionDigits: 2,
                    });
                    const assignedKpisAvatar = getAvatarArray(card?.kpiNumber)
                    let targetPercentage = 0;
                    if (!!card?.value && !!card?.lastPeriod) {
                        targetPercentage = (((card?.value) - (card?.lastPeriod)) / card?.lastPeriod) * 100;
                    }
                    return (
                        <BigWideviewCard
                            key={i}
                            index={i}
                            kpiNumber={card?.kpiNumber}
                            value={cardValue}
                            title={card?.displayName}
                            chartData={card?.points}
                            stateToTransferToExplore={{name: card?.name, displayName: card?.displayName, kpiEnum: card?.kpiNumber}}
                            lastPeriodChartData={card?.pointsLastPeriod}
                            targetPercentage={targetPercentage}
                            deductionTarget={targetPercentage < 0}
                            dataMode={getCardDataMode(card)}
                            assignedKpisAvatar={assignedKpisAvatar}
                            expandRows={card?.byChannels?.map((expandRow) => {
                                return {
                                    title: expandRow?.name,
                                    shareOfTotal: expandRow?.shareOfTotal,
                                    value: expandRow?.value
                                }
                            })}
                        />
                    );
                })}
            </>
        );
        // }

    }


    return <Flex flexWrap={'wrap'}>
        <CardsToDisplay/>
    </Flex>
};

export default BigWideviewCardsRender;

const statusKeys = {
  1027: "Email already exists, please try with another one.",
};

export const getErrorText = (status: number) => {
  if (!!statusKeys[status]) {
    return statusKeys[status];
  }
  return "Not Found";
};

import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { organizationDataLanguageSelector } from "@store";
import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import MoreInformation from "components/more-information/MoreInformation";
import { MoreInfoDialog, MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccountIntegrations } from "store/account/state";
import { openDefaultExploreView, ExploresEnum } from "store/ui";
import { IconGear } from "utils/icons/account-health";
import { styles } from './styles';

interface Props {
    data: AccountIntegrations[];
}

const strategyMoreInfoDialogProps = {
    title: 'Strategy',
    //subtitle: 'How to use this view?',
    description: 'In this section, you`ll set your organization strategy by choosing top KPIs & their targets.',
    tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

enum Buttons {
    KpiFramework,
    Planning,
    Market
}

export const PortalStrategy = (props: Props) => {
    const { t } = useTranslation(['translation', 'translation']);
    const [activeIntegrations, setActiveIntegrations] = useState<number[]>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const dispatch = useDispatch();
    //hooks
    const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] = useState(false);

    //helpers
    const handleClickNavigate = (exploreToOpen: Buttons) => {
        if (exploreToOpen === Buttons.KpiFramework) {
            dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.KPI_FRAMEWORK }));
            return;
        }
        if (exploreToOpen === Buttons.Planning) {
            dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.PLANNING }));
            return;
        }
    };
    const openStrategyMoreInfoDialog = (isOpen: boolean) => {
        setIsStrategyMoreInfoDialogOpen(isOpen);
    };

    //renderers

    const renderTitle = (number: Buttons) => {
        switch (number) {
            case (Buttons.KpiFramework):
                return (`${t('KPI_FRAMEWORK', { ns: 'translation' })}`);
            case (Buttons.Planning):
                return (`${t('PLANNING', { ns: 'translation' })}`);
            case (Buttons.Market):
                return (`${t('MARKET', { ns: 'translation' })}`);
        }
        return;
    };

    const customToolTip = (title: Buttons, flag: boolean, lastUpdated?: string) => {
        if (flag === false) {
            return (
                <PopoverContent w={205} borderRadius={30}>
                    <styles.StyledToolTip>
                        {IconGear()}
                        <styles.ToolTipWrapper>
                            <styles.ToolTipText>
                                {t('DEFAULT_METRICS', { ns: 'translation' })}
                            </styles.ToolTipText>
                            <styles.ToolTipLink onClick={() => {
                                handleClickNavigate(title);
                            }}
                            >
                                {t('CUSTOMIZE', { ns: 'translation' })}
                            </styles.ToolTipLink>
                        </styles.ToolTipWrapper>
                    </styles.StyledToolTip>
                </PopoverContent>
            );
        } else {
            return (
                <PopoverContent w={235} borderRadius={30}>
                    <styles.StyledToolTip>
                        {IconGear()}
                        <styles.ToolTipWrapper>
                            <styles.ToolTipText>
                                {t('LAST_UPDATED', { ns: 'translation' })}
                            </styles.ToolTipText>
                            <styles.ToolTipText>
                                {lastUpdated}
                            </styles.ToolTipText>
                            <styles.ToolTipLink onClick={() => {
                                handleClickNavigate(title);
                            }}
                            >
                                {t('UPDATE', { ns: 'translation' })}
                            </styles.ToolTipLink>
                        </styles.ToolTipWrapper>
                    </styles.StyledToolTip>
                </PopoverContent>
            );
        }

    };



    const renderButton = (title: Buttons, lastUpdated?: string) => {
        let flag: boolean = false;
        if (title === Buttons.KpiFramework && dataLanguage?.topKpis?.isDefault !== undefined) {
            flag = !dataLanguage?.topKpis?.isDefault;
        }

        return (
            <styles.ButtonWrapper onClick={() => {
                handleClickNavigate(title);
            }}>
                <Popover trigger="hover" placement='top'>
                    <PopoverTrigger>

                        <styles.Button {...{ selected: flag }} onClick={() => {
                            if (flag) {
                                handleClickNavigate(title);
                            }
                        }}>
                            <styles.ButtonText >
                                {renderTitle(title)}
                            </styles.ButtonText>
                            <styles.Spacer></styles.Spacer>
                        </styles.Button >
                    </PopoverTrigger>
                    {customToolTip(title, flag, lastUpdated)}
                </Popover>

            </styles.ButtonWrapper>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsContainer>
                {renderButton(Buttons.KpiFramework, dataLanguage?.topKpis?.updatedAt?.slice(0, 10))}
                 {renderButton(Buttons.Planning, "")}
                {/* {renderButton(2)} */}
            </styles.ButtonsContainer>
        );
    };

    return (
        <styles.Wrapper>
            <styles.Title>
                {t('STRATEGY', { ns: 'translation' })}
                <MoreInformation
                    moreInfoDialogProps={strategyMoreInfoDialogProps}
                    isMoreInfoDialogOpen={isStrategyMoreInfoDialogOpen}
                    setIsMoreInfoDialogOpen={setIsStrategyMoreInfoDialogOpen}
                />
            </styles.Title>
            {renderButtons()}
        </styles.Wrapper>
    );
};

import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {MarketingState} from "./state";


export const marketingStateSelector = (state: RootState): MarketingState => state.marketing;


export const marketingDataSelector = createSelector(marketingStateSelector, (state) => state.data);


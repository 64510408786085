import styles from "./styles";
import { useTranslation } from "react-i18next";
import { MoreInfoButton } from "../../components/more-info-button/MoreInfoButton";
import { MoreInfoDialog, MoreInfoDialogProps } from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { useState } from "react";
import { AccountHealthButton } from "../../components/account-health-button";
import { ExploresEnum, openDefaultExploreView } from "../../store/ui";
import { useDispatch } from "react-redux";
import { IconKpiFramework, IconPlanning } from "../../utils/icons/account-health";
import { useTheme } from "@styles";
import { IconMarketing, IconMarketingStrategy } from "utils/icons/explore";
import {Box} from "@chakra-ui/react";
import {KpiFrameworkIcon} from "../../utils/icons/account-health-icons/kpi-framework";

export const Strategy = () => {
    // hooks
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation(['translation', 'translation']);
    const strategyMoreInfoDialogProps = {
        title: 'Strategy',
        //subtitle: 'How to use this view?',
        description: 'In this section, you`ll set your organization strategy by choosing top KPIs & their targets.',
        tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
        /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
        videoTitle: 'Best Practices',
        videoSubtitle: 'How to use this view?',
        videoDescription: '5 min explanatory video from our COO',
        videoLinkTitle: 'Click to watch'*/
    } as MoreInfoDialogProps;
    const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] = useState(false);

    const buttons = [
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><KpiFrameworkIcon /></span>,
            title: 'KPI Framework',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.KPI_FRAMEWORK }));
            }
        },
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><IconPlanning /></span>,
            title: 'Planning',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.PLANNING }));
            },
            /* comingSoon: true */

        },
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><IconMarketingStrategy /></span>,
            title: 'Market',
            callback: () => {
                /* dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.KPI_FRAMEWORK })); */
            },
            comingSoon: true

        }
    ];

    // helpers
    const openStrategyMoreInfoDialog = (isOpen: boolean) => {
        setIsStrategyMoreInfoDialogOpen(isOpen);
    };


    // renderers

    const renderButtons = () => {
        return <styles.ButtonsWrapper>
            {
                buttons.map((button, index) => {
                    return <AccountHealthButton
                        key={index}
                        title={button.title} icon={button.icon}
                        callback={button.callback}
                        comingSoon={button?.comingSoon}
                    />;
                })
            }
        </styles.ButtonsWrapper>;
    };

    return (
        <styles.Wrapper>
            <Box ml={5}>
            <styles.Title>
                {t('STRATEGY', { ns: 'translation' })}
                <MoreInfoButton onClick={() => openStrategyMoreInfoDialog(true)} />
                <MoreInfoDialog
                    props={strategyMoreInfoDialogProps}
                    isDialogOpen={isStrategyMoreInfoDialogOpen}
                    onClose={() => setIsStrategyMoreInfoDialogOpen(false)}
                />
            </styles.Title>
            <styles.Description>
                {t(
                    'SET_TOP_METRICS_FOR_THE_WHOLE_ORGANIZATION_IN_LINE_WITH_YOUR_BUSINESS_STRATEGY'
                    , { ns: 'translation' })}
            </styles.Description>

            {renderButtons()}
            </Box>
        </styles.Wrapper>
    );
};

import styles from "./styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateRangeSelector } from "../../store/ui";
import { PnlReportGroupBy } from "../../services/kpi/kpi.service";
import { kpiStateSelector, setPnlPageFilters } from "../../store/kpis";

export interface ToggleOption {
    title: string;
    index: number | string;
    type?: any;
    isDisabled?: boolean;
}

interface Props {
    options: ToggleOption[];
    onChange: (option: ToggleOption) => void;
    active?: boolean;
    isNoBorderRadius?: boolean;
    fromPnlPage?: boolean;

}

export const Toggle = (props: Props) => {

    const maxDailyDays = 90;
    const maxWeekDays = 182;
    const maxMonthDays = 365 * 2; // 2 years
    const minWeekDays = 7;
    const minMonthDays = 30;

    // hooks
    const [selected, setSelected] = useState({} as ToggleOption);
    const dateSelector = useSelector(dateRangeSelector);
    const pnlGroupBy = useSelector(kpiStateSelector)?.pnlPageFilters?.groupBy
    const dispatch = useDispatch();
    useEffect(() => {
        handleChangingGroupBySelectedDates()
    }, [dateSelector?.startDate, dateSelector?.endDate, pnlGroupBy])

    const handleChangingGroupBySelectedDates = () => {
        const startDateString: string | undefined = dateSelector?.startDate;
        const endDateString: string | undefined = dateSelector?.endDate;

        if (startDateString && endDateString) {
            const startDate: Date = new Date(startDateString);
            const endDate: Date = new Date(endDateString);
            const timeDiff: number = Math.abs(endDate.getTime() - startDate.getTime());
            const daysBetween: number = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            if(pnlGroupBy === PnlReportGroupBy.WEEK){
                if(daysBetween < minWeekDays){
                    setSelected(props.options[0]);
                    dispatch(setPnlPageFilters({groupBy: PnlReportGroupBy.DAY}))
                }
            }
            if(pnlGroupBy === PnlReportGroupBy.MONTH){
                if(daysBetween < minWeekDays){
                    setSelected(props.options[0]);
                    dispatch(setPnlPageFilters({groupBy: PnlReportGroupBy.DAY}))
                    return
                }
                if(daysBetween < minMonthDays){
                    setSelected(props.options[1]);
                    dispatch(setPnlPageFilters({groupBy: PnlReportGroupBy.WEEK}))

                }
            }
        }
    }

    useEffect(() => {
        setSelected(props.options[0]);
    }, []);

    // helpers
    const setOption = (option: ToggleOption) => {
        setSelected(option);
        if (props.onChange) {
            props.onChange(option);
        }
    };

    // renderers


    return <styles.Wrapper isNoBorderRadius={!!props?.isNoBorderRadius ? true : false}>
        {
            props.options.map(option => {

                return <styles.Option
                    key={option.index}
                    isFirst={option.index === 0}
                    disabled={option?.isDisabled}
                    isLast={option.index === props.options.length - 1}
                    isSelected={option.index === selected.index} onClick={() => {
                        if(!option?.isDisabled) {
                            setOption(option);
                        }
                    }}
                    isNoBorderRadius={!!props?.isNoBorderRadius ? true : false}>
                    {option?.title}
                </styles.Option>;
            })
        }
    </styles.Wrapper>;
};

import { Accordion, AccordionButton, AccordionItem, Box } from '@chakra-ui/react';
import {useCallback, useEffect, useState} from 'react';
import { AiOutlineCheck, AiOutlineStar } from "react-icons/ai";

import { styles } from './styles';

enum Status {
    NOT_DONE,
    DONE
}

interface todo {
    title?: string, subtitle?: string, status?: boolean;
}

interface Props {
    todos: todo[];
}

export const ToDoChecklist = (props: Props) => {
    const [totalToDos, setTotalToDos] = useState(0);
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        setTotalToDos(props.todos.length);
        let numberCounter = 0;

        props.todos.forEach((item) => {
            if (item.status) {
                numberCounter++;
            }
        });
        setCounter(numberCounter)
    }, [props.todos]);




    const toDoItem = (text: string, department: string, status: boolean, i: number) => {
        return (
            <styles.StyledToDoItem key={i}>
                <styles.ToDoRow>
                    <styles.Circle status={status ? true : false} >
                        {status ? <AiOutlineCheck color='white' /> : <></>}
                    </styles.Circle>
                    <styles.ToDoText status={status ? true : false}>
                        {text}
                    </styles.ToDoText>
                </styles.ToDoRow>
                <styles.ToDoButton>
                    {department}
                </styles.ToDoButton>
            </styles.StyledToDoItem>
        );
    };

    const AccordionWrapper = () => {
        return (
            <Accordion  borderRadius={"0 0 10px 10px"} allowToggle>
                <AccordionItem outline={"none"} boxShadow={"none !important"} borderRadius={"0 0 10px 10px"} >
                    {({ isExpanded }) => (
                        <>
                            <styles.StyledButton>
                                <styles.Title >
                                    TO-DO Check list
                                </styles.Title>
                                <styles.Spacer />
                                {!isExpanded ?
                                    <AccordionButton justifyContent={"center"}>
                                        <Box borderRadius={"0 0 10px 10px"} padding={"0"} fontSize={11} mb={0} alignSelf={"center"} color={"#9AA2B8"} >
                                            Show Tasks
                                        </Box>
                                    </AccordionButton>
                                    : <></>}
                            </styles.StyledButton>
                            <styles.Panel >
                                {props.todos.map((item, i) => {
                                    if (!!item.title && !!item.subtitle && item.status !== undefined) {
                                        return (toDoItem(item.title, item.subtitle, item.status, i));
                                    }
                                    return;
                                }
                                )}
                            </styles.Panel>
                            {isExpanded ?
                                <>
                                    <styles.TotalBottom>
                                        <styles.Star>
                                            <AiOutlineStar size={"22px"} color='#7031EB' />
                                        </styles.Star>
                                        <styles.BottomText>
                                            <styles.BottomTitle>
                                                {counter}/{totalToDos} Completed
                                            </styles.BottomTitle>
                                            <styles.BottomSubtitle>
                                                Complete all tasks to unlock cool hidden features
                                            </styles.BottomSubtitle>
                                        </styles.BottomText>
                                    </styles.TotalBottom>
                                    <styles.SpacerBottom />
                                    <AccordionButton justifyContent={"center"}>
                                        <Box borderRadius={"0 0 10px 10px"} fontSize={11} mb={0} textAlign={"center"} color={"#9AA2B8"} >
                                            Hide Tasks
                                        </Box>
                                    </AccordionButton>
                                </> : <></>}
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        );
    };


    return (
        <>
            <styles.BlueTop />
            <styles.Wrapper>
                <AccordionWrapper />
            </styles.Wrapper>
        </>
    );
};

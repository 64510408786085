import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DepartmentsEnum} from "@services";
import {fetchExploreData, fetchKpiData, setExploreLoading} from "../../store/kpis";
import {organizationSelector} from "@store";
import {dateRangeSelector} from "../../store/ui";
import {Pnl, pnlDataLanguageSelector} from "../../store/pnl";
import {Metric} from "../../models/pnl-language";
import {getListOfMetrics} from "../get-metric-list/getMetricList";

function useFetchDailyStats(metricToFetch?: string) {
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const datesSelector = useSelector(dateRangeSelector);
    const pnls = useSelector(pnlDataLanguageSelector)?.data;


    useEffect(() => {
        dispatch(setExploreLoading({isLoading: true}));
        setTimeout(() => {
            if (organization?.id && datesSelector?.startDate && datesSelector?.endDate && !!metricToFetch && !!pnls) {
                dispatch(
                    fetchExploreData({
                        organizationId: organization?.id,
                        fromDate: datesSelector.startDate,
                        toDate: datesSelector.endDate,
                        kpisType: DepartmentsEnum.COMPANY,
                        fields: getListOfMetrics(metricToFetch, pnls),
                        type: 0
                    })
                );
            }
        }, 1000);

    }, [organization?.id, datesSelector?.startDate, datesSelector?.endDate, dispatch, metricToFetch, pnls]);

}


export default useFetchDailyStats;

import styles from "./styles";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { IconCloseOverlay, IconCOGS, IconCSV } from "../../utils/icons/account-health";
import { MoreInfoDialog, MoreInfoDialogProps } from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { MoreInfoButton } from "../more-info-button/MoreInfoButton";
import { SearchInput } from "../search-input/SearchInput";
import {Box, Button, Checkbox, Flex, useDisclosure} from '@chakra-ui/react';
import { DownloadButton } from "../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import {fetchProducts, productsDataSelector, productsStateSelector, uploadCsvProductFile} from "../../store/products";
import CogsTable from "./cogs-table/CogsTable";
import MissingCOGS from "./MissingCogs";
import { Product } from "../../models/products";
import {
    ExploresEnum,
    isApplyBarOpenSelector,
    isDefaultExploreOpenSelector, isSecondaryExploreOpenSelector,
    openApplyModal,
    openDefaultExploreView,
    openSecondaryExplore, selectedChannelsSelector
} from "store/ui";
import { ExploreType } from "features/admin-panel/AdminPanel";
import { calculateProductTotalCost } from "./cogs-table/cogs-cells/CogsCells";
import {fetchCustomCogsData, organizationSelector} from "@store";
import {debounce} from "../../utils/debounce/debounce";
import {ApplyChangesModal} from "../apply-changes-modal/ApplyChangesModal";
import {CogsRoundIcon} from "../../utils/icons/account-health-icons/cogs-round";
import StoreChannelFilter from "../store-channel-filter/StoreChannelFilter";
import {organizationService, productsService} from "@services";
import {getCsv, getProductCogsCsv} from "../../store/ui/thunks";
import {DepartmentsEnum} from "../../services/operations/operation.service";
import {BsUpload} from "react-icons/bs";
import FileUploadDialog from "../../dialogs/file-upload-dialog/FileUploadDialog";
import {uploadFilesThunk} from "../../store/operation";
import {Pagination} from "../../features/marketing/creative-performance/pagination/Pagination";
import {PaginationMenu} from "../pagination-menu/PaginationMenu";



interface Props {
    ExploresType: ExploreType;
}

export const CogsSettings = (props: Props) => {

    // hooks
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const productsData = useSelector(productsDataSelector);
    const organization = useSelector(organizationSelector);
    const isExplroeOpen = useSelector(isDefaultExploreOpenSelector);
    const isSecondExploreOpen = useSelector(isSecondaryExploreOpenSelector);
    const [productsCogs, setProductCogs] = useState<Product[] | undefined>([]);
    const [query, setQuery] = useState<string>("");
    const [counter, setCounter] = useState(0);
    const [size, setSize] = useState(20);
    const [tableLength, setTableLength] = useState(0);
    const [newCogs, setNewCogs] = useState([]);
    const [applyFlag, setApplyFlag] = useState(0);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const [totalProducts, setTotalProducts] = useState<number>();
    const productsCsv = useSelector(productsStateSelector);
    const [selectedSort, setSelectedSort] = useState<string>('');
    const cogsSettingMoreInfoDialogProps = {
        title: 'CogsSettings',
        subtitle: 'How to use this view?',
        description: 'Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.',
        tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
        videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
        videoTitle: 'Best Practices',
        videoSubtitle: 'How to use this view?',
        videoDescription: '5 min explanatory video from our COO',
        videoLinkTitle: 'Click to watch'
    } as MoreInfoDialogProps;
    const [isCogsMoreInfoDialogOpen, setIsCogsMoreInfoDialogOpen] = useState(false);
    const [missingCogs, setMissingCogs] = useState(false);
    const [storedChanges, setStoredChanges] = useState<Product[]>([])
    // helpers
    const openCOGSMoreInfoDialog = (isOpen: boolean) => setIsCogsMoreInfoDialogOpen(isOpen);
    const secondaryExplore = useSelector(isSecondaryExploreOpenSelector);
    const selectedChannels = useSelector(selectedChannelsSelector);
    const [selectedSortArrow, setSelectedSortArrow] = useState<{key?: string, stage: number}>({stage: 0});


    useEffect(() => {
        // clean up function
        setStoredChanges([]);
    },[isExplroeOpen?.isDefaultExploreOpen, isSecondExploreOpen?.isSecondaryExploreOpen]);

    useEffect(() => {
        if (productsData) {
            const newProductsData = JSON.parse(JSON.stringify(productsData));
            if (missingCogs) {
                const missingCogs: Product[] = [];
                newProductsData?.forEach((product: Product) => {
                    if (calculateProductTotalCost(product?.itemPrice, product?.fob, product?.newCogs) === 0) {
                        missingCogs.push(product);
                    }
                });
                setProductCogs(missingCogs);
            }
            else {

                const newChanges = JSON.parse(JSON.stringify(storedChanges));
                newChanges?.forEach((item:any, i:number) => {
                    const currentIndex = newProductsData?.findIndex((element:any) => element?.id === item?.id);
                    if(currentIndex > -1){
                        newProductsData.splice(currentIndex, 1, item);
                    }
                })
                setProductCogs(newProductsData);
            }
        }
    }, [missingCogs, productsData, storedChanges]);


    useEffect(() => {
        /*if(!!query || !!selectedSort){*/
            /*setCounter(0);*/
        if(isSecondExploreOpen?.isSecondaryExploreOpen || isExplroeOpen?.isDefaultExploreOpen) {
            !!organization?.id && dispatch(fetchProducts({
                organizationId: organization?.id,
                page: counter,
                size: size,
                query: query,
                sort: [selectedSort],
                channels: selectedChannels
            }));
        }
       /* }*/
    }, [query, selectedSort, selectedChannels, counter, isSecondExploreOpen?.isSecondaryExploreOpen, isExplroeOpen?.isDefaultExploreOpen, selectedChannels, size]);

   /* useEffect(() => {
        if(isSecondExploreOpen?.isSecondaryExploreOpen || isExplroeOpen?.isDefaultExploreOpen){
            setCounter(0);
            !!organization?.id && dispatch(fetchProducts({ organizationId: organization?.id, page: 0, size: 5, channels: selectedChannels}));
        }
    }, [isSecondExploreOpen?.isSecondaryExploreOpen, isExplroeOpen?.isDefaultExploreOpen, selectedChannels])*/

    const updateDebounce = debounce((text: string) => {
        setQuery(text);
    })

    useEffect(() => {
        !!organization?.id && dispatch(fetchCustomCogsData({ organizationId: organization?.id }));
        const fetchCount = async () => {
            if(!!organization?.id){
                const response = await organizationService.fetchProductsCount(organization?.id);
                setTotalProducts(response?.data);

            }
        }
        fetchCount()
    }, [organization?.id])

    const renderOptionsBar = () => {
        return (
            <Flex justifyContent={'space-between'}>
            <styles.OptionsBar>
                <styles.OptionsSection>
                    <SearchInput placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"} onChange={(e) => {
                            setCounter(0);
                            updateDebounce(e);
                            if(e?.length === 0){
                                !!organization?.id && dispatch(fetchProducts({ organizationId: organization?.id, page: 0, size: size, query: e, channels: selectedChannels }));
                            }
                    }}
                        onSearch={(e) => {
                        }} />
                    <div style={{ width: "30px" }} />

                    {/*<Checkbox onChange={(e) => {
                        setMissingCogs(prev => !prev);
                    }} colorScheme={"purple"} size='lg' css={`> span:first-of-type {
                      box-shadow: unset;
                    }`}>
                        <div style={{ fontSize: '16px' }}>
                            {t("ONLY_MISSING_COGS", { ns: "translation" })}
                        </div>
                    </Checkbox>*/}
                    {/*  <styles.FiltersMenu>
                        {t("MORE_FILTERS", { ns: "translation" })}
                    </styles.FiltersMenu> */}
                    <StoreChannelFilter />
                </styles.OptionsSection>
            </styles.OptionsBar>

                <Flex gap={'15px'}>
                    <Flex>
                        <styles.UploadIcon onClick={onOpen}>
                            <BsUpload />
                        </styles.UploadIcon>
                    </Flex>
                    <Flex>
                        <DownloadButton
                            serviceCallback={() => {
                                !!organization?.id &&
                                dispatch(getProductCogsCsv({organizationId: organization?.id}
                                ))
                            }}
                        />
                        <Box w={'20px'}/>
                    </Flex>
                </Flex>


            </Flex>

        );
    };

    const renderHeader = () => {
        return <styles.Header>
            <styles.Info>
                <styles.HeaderIcon>
                    <span style={{ width: '50px', height: '50px', overflow: 'hidden' }}>
                        <CogsRoundIcon />
                    </span>
                </styles.HeaderIcon>
                <styles.TitleWrapper>
                    <styles.Title>
                        {t('COGS_SETTINGS', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => openCOGSMoreInfoDialog(true)} />
                        <MoreInfoDialog
                            props={cogsSettingMoreInfoDialogProps}
                            isDialogOpen={isCogsMoreInfoDialogOpen}
                            onClose={() => setIsCogsMoreInfoDialogOpen(false)}
                        />
                    </styles.Title>
                    <styles.SubTitle>
                        <styles.SubTitleBold>
                            {t('WHAT_ARE_YOUR_PRODUCTION_COSTS', { ns: 'translation' })}
                        </styles.SubTitleBold>
                        <div style={{ width: "5px" }} />
                        {t('FILL_IN_EMPTY_FIELDS_IN_THE_COLUMNS_BELOW', { ns: 'translation' })}
                    </styles.SubTitle>
                </styles.TitleWrapper>
            </styles.Info>
            <styles.HeaderIcon>
                <span style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
                    <button onClick={() => {
                        if (isApplyBarOpen) {
                            dispatch(openApplyModal({ isApplyModalOpen: true }));
                            return;
                        }
                        if(secondaryExplore?.isSecondaryExploreOpen && secondaryExplore.exploreEnum === ExploresEnum?.COGS){
                            dispatch(openSecondaryExplore({ isOpen: false }));
                        }else {
                            dispatch(openDefaultExploreView({ isOpen: false }));

                        }
                    }}>
                        <IconCloseOverlay />
                    </button>
                </span>
            </styles.HeaderIcon>
        </styles.Header >;
    };



    return (

        <styles.Wrapper>
            <FileUploadDialog
                messageDisplay={productsCsv?.messageDisplay}
                isLoading={productsCsv?.csvLoading}
                actionCallback={async (form) => {
                    !!organization?.id && await dispatch(uploadCsvProductFile({
                        organizationId: organization?.id,
                        data: form
                    }))
                }}
                maxSize={5} filesAccept={{
                'text/csv': ['.csv'],
            }} subTitle={'CSV only, up to 5mb'} maxFiles={1} title={"Upload CSV"} onOpen={onOpen} onClose={onClose} isOpen={isOpen} />

            {renderHeader()}
            <styles.Content>
                {<MissingCOGS applyFlag={applyFlag} />}
                {renderOptionsBar()}
                {<CogsTable selectedSort={selectedSort} setTableLength={setTableLength} setStoredChanges={setStoredChanges} setSelectedSort={setSelectedSort}
                            query={query} setCounter={setCounter} counter={counter} setApplyFlag={setApplyFlag}
                            products={productsCogs} selectedSortArrow={selectedSortArrow} setSelectedSortArrow={setSelectedSortArrow} />}
                <Box width={"100%"} height={"100%"} position={"relative"}>
                <styles.FooterWrapper>
                    {/*<Box ml={7}>
                    {tableLength?.toLocaleString()} products out of {totalProducts?.toLocaleString()} products in total
                    </Box>*/}

                    <PaginationMenu setSize={setSize} setPreviousPage={setCounter} setNextPage={setCounter} setPage={setCounter} currentPage={counter} maxPage={!!productsCsv?.data?.maxPage ? Math.floor(productsCsv?.data?.maxPage) : 0}/>


                </styles.FooterWrapper>
                </Box>
            </styles.Content>
        </styles.Wrapper>
    );
};

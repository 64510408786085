import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountStateSelector, fetchAccountData } from "store/account";
import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import { PortalIntegrations } from "components/portal-integrations/PortalIntegrations";
import { organizationDataLanguageSelector, organizationLoadingSelector, organizationSelector } from "@store";
import { PortalLanguage } from "components/portal-language/PortalLanguage";
import { PortalStrategy } from "components/portal-strategy/PortalStrategy";
import { PortalOperations } from "components/portal-operations/PortalOperations";
import { ToDoChecklist } from "components/to-do/ToDoChecklist";
import { todosDataSelector } from "store/todos";
import { kpiStateSelector } from "store/kpis";
import { openDefaultExploreView, ExploresEnum } from "store/ui";
import { Box } from "@chakra-ui/react";
import { MoreInfoDialog, MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";

enum Status {
    NOT_DONE,
    DONE
}

interface todo {
    title: string, subtitle: string, status: Status;
}

enum ActiveIntegrations {
    STORES,
    MARKETING,
    WEBSITE
}
enum DataLanguage {
    GENERAL,
    PnL,
    MARKETING
}

interface RenderedTodos {
    title?: string;
    type?: string;
    status?: boolean;
}


const myPortalMoreInfoDialogProps = {
    title: 'My Portal',
    //subtitle: 'How to use this view?',
    description: 'This page is the brain of the system - here you can see all the parts of the system.\n For example; integrations to external platforms, customizing your data by your business needs, etc. \nOn the side of the page, you will see a checklist that will guide you with automated to-do lists, each completed task will promote the efficiency of the system and provide more targeted insights.\nCompleted tasks will turn the portal green, which is a sign of strong efficiency. ',
    //tip: 'Tip: Updating your strategy & targets every few months will ensure healthy & smart growth.',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export const Portal = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const [todosDataToShow, setTodosDataToShow] = useState<RenderedTodos[]>([]);
    const accountData = useSelector(accountStateSelector);
    const generalLanguagedata = useSelector(organizationDataLanguageSelector);
    const todosData = useSelector(todosDataSelector);
    const [isMyPortalMoreInfoDialogOpen, setIsMyPortalMoreInfoDialogOpen] = useState(false);

    useEffect(() => {
        if (organization !== undefined) {
            dispatch(fetchAccountData(organization!.id));
        }
    }, [dispatch, organization]);
    const organizationLoader = useSelector(organizationLoadingSelector);
    const kpisLoad = useSelector(kpiStateSelector);


    useEffect(() => {
        const doneTodos: (number | undefined)[] = [];
        if (!!generalLanguagedata?.todos) {
            generalLanguagedata?.todos.forEach((item) => {
                if (item?.isDone) {
                    doneTodos.push(item?.code);
                }
            });
        }
        if (!!todosData) {
            const dataToRender = todosData?.data?.map((item) => {
                if (doneTodos?.includes(item.code)) {
                    return { title: item.value, subtitle: item.type, status: true };
                }
                return { title: item.value, subtitle: item.type, status: false };
            });
            if (!!dataToRender) {
                setTodosDataToShow(dataToRender);
            }

        }
    }, [generalLanguagedata?.todos, todosData]);


    // helpers
    const openMyPortalMoreInfoDialog = (isOpen: boolean) => setIsMyPortalMoreInfoDialogOpen(isOpen);

    // renderers

    return (
        <>
            <styles.Wrapper>
                <styles.TodoPostion>
                    <styles.ChecklistContainer >
                        <ToDoChecklist todos={todosDataToShow} />
                    </styles.ChecklistContainer>
                </styles.TodoPostion>
                <styles.LeftSideWrapper>
                    {/*<styles.Title>{t('MY_PORTAL', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => openMyPortalMoreInfoDialog(true)} />
                        <MoreInfoDialog
                            props={myPortalMoreInfoDialogProps}
                            isDialogOpen={isMyPortalMoreInfoDialogOpen}
                            onClose={() => openMyPortalMoreInfoDialog(false)}
                        />
                    </styles.Title>*/}
                    {accountData.data !== undefined ?
                        <Box mt={5} ml={5}>
                        <PortalIntegrations data={accountData?.data} />
                        <PortalLanguage data={accountData?.data} />
                        <PortalStrategy data={accountData.data} />
                        <PortalOperations data={accountData.data} />
                        <Box h={20} />
                    </Box>
                        : null}

                </styles.LeftSideWrapper>

            </styles.Wrapper>
        </>
    );
};

import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

// ui state
export const uiStateSelector = (state: RootState) => state.ui;

// is defaultExplore open
export const isDefaultExploreOpenSelector = createSelector(
  uiStateSelector,
  (state) => state.defaultExplore
);

export const isGlobalLoadingSelector = createSelector(
    uiStateSelector,
    state => state.globalLoading
)

export const isAppLoadingSelector = createSelector(
    uiStateSelector,
    state => state.appLoad
)

export const navigationTabSelector = createSelector(
    uiStateSelector,
    state => state.navigationTab
)
// is defaultExplore open
export const isSecondaryExploreOpenSelector = createSelector(
  uiStateSelector,
  (state) => state.secondaryExplore
);

export const isApplyBarOpenSelector = createSelector(
  uiStateSelector,
  (state) => state.isApplyBarOpen
);

export const isSecondaryApplyModalOpenSelector = createSelector(
    uiStateSelector,
    (state) => state.isSecondaryApplyModalOpen
);

export const isSecondaryApplyBarOpenSelector = createSelector(
    uiStateSelector,
    (state) => state.isSecondaryApplyBarOpen
);
export const isApplyModalOpenSelector = createSelector(
  uiStateSelector,
  (state) => state.isApplyModalOpen
);

export const dateRangeSelector = createSelector(
  uiStateSelector,
  (state) => state.dateRange
);

// ui error
export const uiErrorSelector = createSelector(
  uiStateSelector,
  (state) => state.error
);

// ui loading
export const uiLoadingSelector = createSelector(
  uiStateSelector,
  (state) => state.loading
);
// accounts selector
export const accountStateSelector = createSelector(
  uiStateSelector,
  (state) => state.accounts
);

export const selectedChannelsSelector = createSelector(
    uiStateSelector,
    state => state.selectedChannels
)

// ui card selected
export const selectedCardSelector = createSelector(
  uiStateSelector,
  (state) => state.cardSelected
);

export const toastsSelector = createSelector(
    uiStateSelector,
    (state) => state.toasts
);

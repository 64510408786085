export const KpiFrameworkIcon = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#7031eb"/>
                    <stop offset="1" stop-color="#4a55fb"/>
                </linearGradient>
            </defs>
            <g id="invisible_box" data-name="invisible box">
                <rect id="Rectangle_372" data-name="Rectangle 372" width="48" height="48" fill="none"/>
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(2 2.595)">
                <g id="Group_1132" data-name="Group 1132">
                    <circle id="Ellipse_99" data-name="Ellipse 99" cx="6" cy="6" r="6" transform="translate(0 28.404)" fill="url(#linear-gradient)"/>
                    <path id="Path_523" data-name="Path 523" d="M24,31a6,6,0,1,0,6,6A6,6,0,0,0,24,31Zm0,8a2,2,0,1,1,2-2A2,2,0,0,1,24,39Z" transform="translate(-2.09 -2.595)" fill="url(#linear-gradient)"/>
                    <circle id="Ellipse_100" data-name="Ellipse 100" cx="6" cy="6" r="6" transform="translate(32 28.404)" fill="url(#linear-gradient)"/>
                    <path id="Path_524" data-name="Path 524" d="M37.4,19.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l4,3.9a1.9,1.9,0,0,0,2.8,0l4-3.9a2.3,2.3,0,0,0,.3-2.7,2,2,0,0,0-3.1-.2l-.6.6A18,18,0,1,0,6,21v2a2,2,0,0,0,4,0V21a14.007,14.007,0,0,1,28-.9Z" transform="translate(-2.09 -2.595)" fill="url(#linear-gradient)"/>
                </g>
            </g>
        </svg>

    )
}
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin: 0 auto;
    padding: 0 0 20px;
    //box-shadow: 2px 13px 54px #695f9714;
    box-shadow: 3px 3px 10px #c6c6c6;
  `;
  export const ShowByText = styled.div`
    color: #7a86a1;
    font-size: 14px;
    white-space: nowrap;
  `;
  export const Dot = styled.div<{ backgroundColor: string }>`
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor};
  `;
  export const HeaderButton = styled.div`
    padding: 5px;
    margin: 0 10px;
    border-radius: 20px;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border: solid 1px #ebebeb;
    &:hover {
      cursor: pointer;
    }
  `;
  export const SkeletonBox = styled.div`
    display: inline-block;
    height: 10em;
    position: relative;
    overflow: hidden;
    background-color: white;

    
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      /*background-color: white;*/
      opacity: .6;

      background-image: linear-gradient(to right, white 0%, white 20%, white 40%, white 100%);

      -webkit-animation-duration: 1.7s;
      -webkit-animation-fill-mode: forwards;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: shimmer;
      -webkit-animation-timing-function: linear;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  `
  export const LegendText = styled.div`
    color: #7a86a1;
    font-size: 14px;
    font-weight: normal;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px 20px 0;
    height: auto;
    position: relative;
    border-radius: 20px;
  `;
  export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `;

  export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 21px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.header};
  `;

  export const IconWrapper = styled.div`
    margin-right: 5px;
  `;

  export const Graph = styled.div`
    background-color: transparent;
    width: 100%;
    position: relative;
    padding: 20px;
    z-index: 2;
    height: 300px;
  `;
}

export default styles;

import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import { channelsService } from "services/channels/channels.service";

export const fetchChannels = createAsyncThunk<any, any>(
  "orgaization/fetchChannels",
  async ({ organizationId }: { organizationId: string }, thunkAPI) => {
    try {
      const response = await channelsService.fetchChannels({
        organizationId,
      });
      if (isHttpCodeSuccess(response.status)) {
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error fetch channels");
    }
  }
);
export const fetchChannelById = createAsyncThunk<any, any>(
  "orgaization/fetchChannelById",
  async (
    {
      organizationId,
      channelId,
    }: { organizationId: string; channelId: string },
    thunkAPI
  ) => {
    try {
      const response = await channelsService.fetchChannelById({
        organizationId,
        channelId,
      });
      if (isHttpCodeSuccess(response.status)) {
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error fetch channel by id");
    }
  }
);
export const changeChannelName = createAsyncThunk<
  any,
  {
    organizationId: string;
    body: { id?: string; displayName: string }[];
    channelId?: string;
  }
>(
  "orgaization/changeChannelName",
  async (
    {
      organizationId,
      body,
    }: {
      organizationId: string;
      body: { id?: string; displayName: string }[];
    },
    thunkAPI
  ) => {
    try {
      const response = await channelsService.changeChannelName({
        organizationId,
        body,
      });
      if (isHttpCodeSuccess(response.status)) {
        thunkAPI.dispatch(fetchChannels({ organizationId: organizationId }));
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error fetch channel by id");
    }
  }
);

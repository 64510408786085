import styled from "styled-components";
//import { media } from '../../theme/media'
import { BsFillPlayCircleFill } from 'react-icons/bs'

export namespace styles {
  export const Container = styled.div`
    // background-color: #faf7ff;
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    gap: 12px;
    margin-left: 10px;
    align-self: center;
    padding-top: 20px;
    @media only screen and (max-width: 1600px) {
      padding-left: calc(5% - 4.5em);
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1400px) {
      grid-template-columns: repeat(2, 0fr);
    }

  `;

  export const Card = styled.div`
    background: #faf7ff 0% 0%;
    box-shadow: 2px 13px 54px #695f9714;
    padding: 0 19px;
    width: 298px;
    max-width: 90%;
    padding: 10px 10px;
    margin-bottom: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #695f9726;
  `;

  export const Description = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    margin: 0 0 1em 0;
    overflow: hidden;
    padding-top: 8px;
    position: relative;
  `;

  export const Text = styled.p`
    margin: 0;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 193%;
  `;

  export const Image = styled.img`
    margin: 0;
    height: 163px;
    width: 100%;
    object-fit: fill;
    background-color: #fff;

    &[alt] {
      background: var(--chakra-colors-blackAlpha-50);
      color: grey;
      text-align: center;
    }
  `;

  export const NoMediaPlaceholderContainer =  styled.div`
    height: 163px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
  `;

  export const NoMediaPlaceholder =  styled.img`
    height: 100px;
    width: 100px;
  `;

  export const Video = styled.div`
    width: 100%;
    height: 163px;
    //border: solid 3px ${(props) => props.theme.primaryColor};
    margin-right: 15px;
  `;


  export const PlayCircle = styled(BsFillPlayCircleFill)`
  background-color: pink;
  `

  export const HashtagAd = styled.div`
    margin: 0;
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.27);
  `;

  export const ObjectAdProperty = styled.ul`
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `;

  export const AdPropertyItem = styled.li`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  `;

  export const SpanAdPropertyItem = styled.span`
    background-color: #faf7ff;
    font-size: 12px;
  `;
}

export default styles;

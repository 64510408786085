import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

// sign-up state
export const notificationStateSelector = (state: RootState) =>
  state.notification;

export const notificationSelector = createSelector(
  notificationStateSelector,
  (state) => state.data
);

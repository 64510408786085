import marketingIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform, IntegrationType,
} from "@models";
import {addIntegration, organizationSelector, userSelector} from "@store";
import {useDispatch, useSelector} from "react-redux";
import { IntegrationButton } from "../../../components/integration-button";
import { integrationSelector } from "store/integrations";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import {clickupUrl, mondayUrl, notionUrl, slackUrl} from "../../../constants/integration";

export const TaskManagementIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const organization = useSelector(organizationSelector);
    const user = useSelector(userSelector);
    const integrations = useSelector(integrationSelector);
    const dispatch = useDispatch();

    // helpers
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    const sendTaskManagementOAuthFromSearchParams = (params: URLSearchParams, platform: IntegrationPlatform) => {
        // @ts-ignore
        const OAuthData = params.get('result');
        try {
            if (!!OAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.TEAM_MANAGEMENT,
                        platform: platform,
                        object: JSON.parse(OAuthData),
                        userId: user?.id
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    // renderers
    const renderTaskManagementIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('TASK_MANAGEMENT', { ns: 'translation' })}
                </Text>
                <Flex wrap={"wrap"} direction={"row"}>
                    {renderMondayConnector()}
                    {renderNotionConnector()}
                    {renderClickupConnector()}
                    {renderSlackConnector()}
                </Flex>
            </Flex>
        );
    };

    const renderMondayConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.MONDAY);
        return (
            /*<OAuthPopup
                title={'Monday'}
                height={window.screenY}
                width={window.screenX}
                url={mondayUrl ?? ''}
                onResponse={(params) => {
                    sendTaskManagementOAuthFromSearchParams(params!, IntegrationPlatform.MONDAY);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderNotionConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.NOTION);
        return (
            /*<OAuthPopup
                title={'Notion'}
                height={window.screenY}
                width={window.screenX}
                url={notionUrl ?? ''}
                onResponse={(params) => {
                    sendTaskManagementOAuthFromSearchParams(params!, IntegrationPlatform.NOTION);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderClickupConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.CLICKUP);
        return (
            /*<OAuthPopup
                title={'ClickUp'}
                height={window.screenY}
                width={window.screenX}
                url={clickupUrl ?? ''}
                onResponse={(params) => {
                    sendTaskManagementOAuthFromSearchParams(params!, IntegrationPlatform.CLICKUP);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderSlackConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.SLACK);
        return (
            /*<OAuthPopup
                title={'Slack'}
                height={window.screenY}
                width={window.screenX}
                url={slackUrl ?? ''}
                onResponse={(params) => {
                    sendTaskManagementOAuthFromSearchParams(params!, IntegrationPlatform.SLACK);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderTaskManagementIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

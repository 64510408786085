import { aeFlag, afFlag, alFlag, amFlag, aoFlag, arFlag, awFlag, azFlag, baFlag, bbFlag, bdFlag, bgFlag, bhFlag, biFlag, bmFlag, boFlag, brFlag, bsFlag, bwFlag, byFlag, caFlag, chFlag, clFlag, cnFlag, coFlag, crFlag, czFlag, dkFlag, doFlag } from "utils/flags";
import { auFlag } from "utils/flags/flags-svg/au";
import { cuFlag } from "utils/flags/flags-svg/cu";
import { euFlag } from "utils/flags/flags-svg/eu";
import { gbFlag } from "utils/flags/flags-svg/gb";
import { ilFlag } from "utils/flags/flags-svg/il";
import { usFlag } from "utils/flags/flags-svg/us";

// XCD XAF
export const renderFlag = (code: string) => {
    switch (code) {
        case ("ILS"):
            return (<>{ilFlag()}</>);
        case ("USD"):
            return (<>{usFlag()}</>);
        case ("EUR"):
            return (<>{euFlag()}</>);
        case ("GBP"):
            return (<>{gbFlag()}</>);
        case ("AED"):
            return (<>{aeFlag()}</>);
        case ("AFN"):
            return (<>{afFlag()}</>);
        case ("ALL"):
            return (<>{alFlag()}</>);
        case ("AMD"):
            return (<>{amFlag()}</>);
        case ("AOA"):
            return (<>{aoFlag()}</>);
        case ("ARS"):
            return (<>{arFlag()}</>);
        case ("AUD"):
            return (<>{auFlag()}</>);
        case ("AWG"):
            return (<>{awFlag()}</>);
        case ("AZN"):
            return (<>{azFlag()}</>);
        case ("BAM"):
            return (<>{baFlag()}</>);
        case ("BBD"):
            return (<>{bbFlag()}</>);
        case ("BDT"):
            return (<>{bdFlag()}</>);
        case ("BGN"):
            return (<>{bgFlag()}</>);
        case ("BHD"):
            return (<>{bhFlag()}</>);
        case ("BIF"):
            return (<>{biFlag()}</>);
        case ("BMD"):
            return (<>{bmFlag()}</>);
        case ("BOB"):
            return (<>{boFlag()}</>);
        case ("BRL"):
            return (<>{brFlag()}</>);
        case ("BSD"):
            return (<>{bsFlag()}</>);
        case ("BWP"):
            return (<>{bwFlag()}</>);
        case ("BYN"):
            return (<>{byFlag()}</>);
        case ("CAD"):
            return (<>{caFlag()}</>);
        case ("CHF"):
            return (<>{chFlag()}</>);
        case ("CLP"):
            return (<>{clFlag()}</>);
        case ("CNY"):
            return (<>{cnFlag()}</>);
        case ("COP"):
            return (<>{coFlag()}</>);
        case ("CRC"):
            return (<>{crFlag()}</>);
        case ("CUP"):
            return (<>{cuFlag()}</>);
        case ("CZK"):
            return (<>{czFlag()}</>);
        case ("DKK"):
            return (<>{dkFlag()}</>);
        case ("DOP"):
            return (<>{doFlag()}</>);

        default:
            return <></>;
    }
};
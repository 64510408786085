import {createAsyncThunk} from "@reduxjs/toolkit";
import {accountService} from "services/account/account.service";
import {AccountIntegrations} from "./state";

export const fetchAccountData = createAsyncThunk<any, any>(
  "account/fetchAccountData",
  async (payload, thunkAPI) => {
    const response = await accountService.getAccountData(payload);
    return response.data;
  }
);


import {createAsyncThunk} from "@reduxjs/toolkit";
import {getWebsiteData} from "services/website/website.service";

export const fetchWebsiteData = createAsyncThunk<any, any>(
  "website/fetchWebsiteData",
  async (payload, thunkAPI) => {
    const response = await getWebsiteData();
    return response;
  }
);


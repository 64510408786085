import React, { useEffect, useState } from "react";
import styles from "./styles";
import {
  Channel,
  Expression,
  Metric,
  Operator,
  PercentageFrom,
  PnlSections,
} from "../../../models/pnl-language";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import {useDispatch, useSelector} from "react-redux";
import {
  customCogsDataSelector,
  organizationDataLanguageSelector,
} from "@store";
import { ItemTypes } from "../itemTypes";
import {pnlDataLanguageDraftSelector, pnlDataLanguageSelector} from "../../../store/pnl";
import { CheckboxMenuSmall } from "../../checkbox-menu-small/CheckboxMenuSmall";
import { channelsStateSelector } from "../../../store/channels";
import {isApplyBarOpenSelector, openApplyBar} from "../../../store/ui";

interface Props {
  onApply: (newCog: Metric) => void;
  onClose: () => void;
  metricIdToEdit?: string;
  channels: Channel[];
  onEditApply: (metric: Metric) => void;
}

export enum PnlElementFrequencyEnum {
    EVERY_DAY,
    EVERY_WEEK,
    EVERY_MONTH,
    EVERY_YEAR
}


export const AddCustomExpense = (props: Props) => {

    const { onApply, onClose } = props;
    //const CHANNELS = [{ name: 'Ebay' }, { name: "AmazonUS" }, { name: "Shopify" }];

    const [name, setName] = useState('');
    const [allPnlNames, setAllPnlNames] = useState<string[] | undefined>([])
    const [value, setValue] = useState<number>(0);
    const [isPercentage, setIsPercentage] = useState(true);
    const [percentageFrom, setPercentageFrom] = useState<PercentageFrom | undefined>();
    const [channelItem, setChannelItem] = useState<Channel>({});//('ALL_CHANNELS');
    const [frequency, setFrequency] = useState<string | number>(0);
    const [group, setGroup] = useState(PnlSections.ExpensesBank);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const [validForm, setValidForm] = useState({isValid: false, error: ""});
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [endDateInfinity, setEndDateInfinity] = useState<boolean>(false);
    const [startDateInfinity, setStartDateInfinity] = useState<boolean>(false);
    const pnlElements = useSelector(pnlDataLanguageDraftSelector)?.data;
    const allCogs = useSelector(customCogsDataSelector);
    const [options,setOptions] = useState<string[]>([]);
    const [selectedOptions,setSelectedOptions] = useState<string[]>([]);
    const channels = useSelector(channelsStateSelector);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        const allUniqueChannelsNames:any[] = [];
        const allUniqueChannelsIds:any[] = [];
        channels?.channels?.forEach((channel) => {
            if(allUniqueChannelsNames?.includes(channel?.displayName)){
                return
            }else {
                allUniqueChannelsNames?.push(channel?.displayName);
                allUniqueChannelsIds?.push((channel?.id));
            }
        })
        setOptions(allUniqueChannelsIds);
    }, [channels?.channels]);

    const getPercentageFromByExpression = (from: string) : PercentageFrom => {
        switch (from){
            case ('grossRevenue'):
                return PercentageFrom.GrossSales;
            case ('netRevenue'):
                return PercentageFrom.NetSales;
            case ('grossProfit'):
                return PercentageFrom.GrossProfit
            default:
                return PercentageFrom.GrossSales;
                
        }
    }

    useEffect(() => {
        console.log(startDate);
    }, [startDate])


    useEffect(() => {
        if(!props?.metricIdToEdit){
            setStartDate('')
            setEndDate('')
            setStartDateInfinity(false)
            setEndDateInfinity(false)
        }
    },[frequency, props?.metricIdToEdit])

    useEffect(() => {
            if(!!props?.metricIdToEdit){
                const foundMetric = pnlElements?.find((metric) => metric?.id === props?.metricIdToEdit);
                !!foundMetric?.displayName && setName(foundMetric?.displayName);
                // @ts-ignore
                const foundValueInExpressions = foundMetric?.expression?.find((expression) => expression?.isNumber)?.operand;
                // @ts-ignore
                setSelectedOptions(foundMetric?.channels)


                if(!!foundValueInExpressions){
                    let value = parseFloat(foundValueInExpressions);
                    if(foundMetric?.isPercentage){
                        setValue(value * 100);
                    }
                }
                else {
                    if(!!foundMetric?.fixedAmount){
                        setValue(foundMetric?.fixedAmount);
                    }
                    if(foundMetric?.frequency !== undefined){
                        const frequencies = ['EVERY_DAY', 'EVERY_WEEK', 'EVERY_MONTH', 'EVERY_YEAR'];
                        setFrequency(frequencies[foundMetric?.frequency]);
                        if(!!foundMetric?.startDate){
                            if(foundMetric?.frequency === PnlElementFrequencyEnum.EVERY_MONTH){
                                setStartDate(foundMetric?.startDate?.slice(0, 7))
                            }
                            else {
                                setStartDate(foundMetric?.startDate?.slice(0, 10));
                            }
                        }else {
                            setStartDateInfinity(true)
                        }

                        if(!!foundMetric?.endDate){
                            if(foundMetric?.frequency === PnlElementFrequencyEnum.EVERY_MONTH){
                                setEndDate(foundMetric?.endDate?.slice(0, 7))
                            }
                            else {
                                setEndDate(foundMetric?.endDate?.slice(0, 10));
                            }
                        }
                        else {
                            setEndDateInfinity(true)
                        }
                    }

                }


                if(!!foundMetric?.isPercentage){
                    const foundPercentageFrom = foundMetric?.expression[0]?.operand;
                    if(!!foundPercentageFrom){
                        setPercentageFrom(getPercentageFromByExpression(foundPercentageFrom))
                    }
                }
                else {
                    setIsPercentage(false)
                }
            }
    }, [props?.metricIdToEdit])



    function processString(inputString: string): string {
        const processedString = inputString.toLowerCase().replace(/ /g, '_');
        return processedString;
    }

    function isInputValid(input: string): boolean {
        const trimmedInput = input.trim();

        if (trimmedInput === "") {
            return false;
        }

        if (/^\s+$/.test(input)) {
            return false;
        }

        if (input !== trimmedInput) {
            return false;
        }

        if (/[^\x20-\x7E]/.test(input)) {
            return false;
        }

        return true;
    }




    useEffect(() => {
        const allPnlNames: string[] |undefined = pnlElements?.map((pnl) => pnl?.fieldName);


        const formattedNames = allPnlNames?.map((item) => (item?.toLowerCase()));
        if(formattedNames?.includes(name?.toLowerCase())){
            setValidForm({isValid: false, error: "Expenses name already exists"});
        }
        if(formattedNames?.includes(processString(name))){
            setValidForm({isValid: false, error: "Expenses name already exists"});
        }

        if(isPercentage){
            setValidForm({isValid: name?.length > 0 && !!percentageFrom && selectedOptions?.length > 0 && value > 0, error: ""});
        }
        if(!isPercentage){
            const checkIfDateRangeValid = startDate?.length > 0 && endDate?.length > 0 || startDate.length > 0 && endDateInfinity || endDate.length > 0 && startDateInfinity;
            setValidForm({isValid: name?.length > 0 && frequency !== undefined && selectedOptions?.length > 0 && value > 0 && checkIfDateRangeValid, error: ""});
        }

        setAllPnlNames(allPnlNames);

    }, [pnlElements, name, name.length, percentageFrom, selectedOptions, value, frequency, startDate, endDate, endDateInfinity, startDateInfinity]);



    const { t } = useTranslation(['translation', 'translation']);

    const getType = (isPercentage: boolean, from?: PercentageFrom) => {
        if (!isPercentage) {
            return ItemTypes.CARD;
        }
        if (!!from) {
            switch (from) {
                case (PercentageFrom.GrossSales):
                    return ItemTypes.GROSS_REVENUE;
                case (PercentageFrom.NetSales):
                    return ItemTypes.NET_REVENUE;
                case (PercentageFrom.GrossProfit):
                    return ItemTypes.GROSS_PROFIT;

            }
        }
        return ItemTypes.CARD;

    };


const getEnumByFrequencyName = {
    "EVERY_DAY": PnlElementFrequencyEnum.EVERY_DAY,
    "EVERY_WEEK": PnlElementFrequencyEnum.EVERY_WEEK,
    "EVERY_MONTH": PnlElementFrequencyEnum.EVERY_MONTH,
    "EVERY_YEAR": PnlElementFrequencyEnum.EVERY_YEAR,
}

    const createNewPnlDataLanguageItem = () => {
        if(!props?.metricIdToEdit){
            if (name.length === 0){
                return;
            }
            const formattedNames = allPnlNames?.map((item) => t(item, { ns: 'translation' })?.toLowerCase());
            if(formattedNames?.includes(name?.toLowerCase())){
                return
            }
        }

        let fixedStartDate = '';
        let fixedEndDate = '';



        if( frequency === PnlElementFrequencyEnum?.EVERY_DAY || frequency === 'EVERY_DAY'){
            fixedStartDate = startDate + 'T00:00:00.000Z';
            fixedEndDate = endDate + 'T23:59:59.999Z'
        }


        function getNumberOfDaysInMonth(input: string): number {
            const [year, month] = input.split('-').map(Number);
            return new Date(year, month, 0).getDate();
        }

        if(frequency === 'EVERY_MONTH'){
            const lastDayOfMonth = getNumberOfDaysInMonth(endDate);
            fixedStartDate = startDate + '-01' + 'T00:00:00.000Z';
            fixedEndDate = endDate + '-' + lastDayOfMonth?.toString() + 'T23:59:59.999Z'

        }

        const getFieldNameByFrom = () : string => {
            switch (percentageFrom){
                case 'GROSS_REVENUE':
                    return 'grossRevenue'
                case 'NET_REVENUE':
                    return 'netRevenue'
                case 'GROSS_PROFIT':
                    return 'grossProfit'
                case 'MARKETING_SPEND':
                    return 'marketingCost'
                default:
                    return 'grossRevenue'
            }
        }
        const calculateExpressions = () :Expression[] => {
            if(isPercentage){
                return [
                    {
                        isOperator: false,
                        order: 0,
                        operand: getFieldNameByFrom()
                    },
                    {
                        isOperator: false,
                        order: 1,
                        operand: (value / 100)?.toString(),
                        isNumber: true
                    },
                    {
                        isOperator: true,
                        order: 2,
                        operand: Operator.MULTIPLY
                    },
                ]
            }
            else {
                return [
                    {
                        isOperator: false,
                        order: 0,
                        operand: processString(name)
                    },
                    {
                        isOperator: true,
                        order: 1,
                        operand: Operator.PLUS
                    },
                ]
            }
        }


        const foundMetric = pnlElements?.find((metric) => metric?.id === props?.metricIdToEdit);



        let newPnlDataLanguageItem: Metric = {
            expression: calculateExpressions(),
            isDefault: false,
            isDisplayable: true,
            id: foundMetric?.id ?? uuidv4(),
            isPercentage: isPercentage,
            fieldName: processString(name),
            displayName: name,
            channels: selectedOptions,
            frequency: getEnumByFrequencyName[frequency],
            startDate: !isPercentage ? !startDateInfinity ? fixedStartDate : '' : '',
            endDate: !isPercentage ? !endDateInfinity ? fixedEndDate : '' : '',
            fixedAmount: !isPercentage ? value : undefined,
            
        };
        if(props?.metricIdToEdit){
            props?.onEditApply(newPnlDataLanguageItem)
        }else {
            onApply(newPnlDataLanguageItem);
        }
        if(!isApplyBarOpen){
            dispatch(openApplyBar({isApplyBarOpen: true}))
        }
    };


    interface MenuProps {
        title: string,
        items: Channel[] | any[],
        item: any,
        setItem: React.Dispatch<React.SetStateAction<any>>;
    }


    const AddExpenseMenu = ({ title, items, item, setItem }: MenuProps) => {
        const disabledItems = ['EVERY_WEEK', 'EVERY_YEAR'];

        return (
          <>
            <styles.Title>{t(title, { ns: "translation" })}:</styles.Title>
            <Menu matchWidth={true}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    mb={3}
                    color={"#555"}
                    w={"100%"}
                    isActive={isOpen}
                    as={Button}
                    rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  >
                    <Box
                      overflow="hidden"
                      textOverflow={"ellipsis"}
                      whiteSpace="nowrap"
                      textAlign={"center"}
                      paddingLeft={"2em"}
                    >
                      {isOpen
                        ? t(title, { ns: "translation" })
                        : item
                        ? t(
                            !!item?.displayName
                              ? item?.displayName
                              : typeof item === "object"
                              ? items[0].displayName
                              : item,
                            { ns: "translation" }
                          )
                        : t("SELECT", { ns: "translation" })}
                    </Box>
                  </MenuButton>
                  <MenuList minW={"100%"} maxH={"150px"} overflowY={"scroll"}>
                    {items.map((item, index) => (
                      <styles.StyledMenuItem
                          block={disabledItems?.includes(item)}
                        key={uuidv4()}
                        onClick={(e: any) => {
                            if(!disabledItems?.includes(item)){
                                setItem(item);
                            }else{
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                      >
                        {t(!!item?.name ? item?.displayName : item, {
                          ns: "translation",
                        })}
                      </styles.StyledMenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </>
        );
    };

    const renderStartDateInput = () => {
        const typeOfDate = frequency === PnlElementFrequencyEnum?.EVERY_DAY || frequency === 'EVERY_DAY' ? 'date' : 'month'
        return (
            <div>
                <fieldset>
                   <label>
                       <Flex alignItems={'center'} gap={'10px'}>
                           <div>
                            Start Date:
                           </div>
                           <Checkbox  isChecked={startDateInfinity} onChange={(e) => {
                               if(e?.target?.checked && endDateInfinity){
                                   setEndDateInfinity(false);
                                   setEndDate('');
                               }
                               setStartDateInfinity(e?.target?.checked)
                           }} colorScheme={'purple'}>
                               Infinity
                           </Checkbox>
                       </Flex>
                   </label>
                    <Input max={endDate} disabled={startDateInfinity} value={startDateInfinity ? '' : startDate} onChange={(e) => setStartDate(e?.target?.value)} type={typeOfDate}/>

                </fieldset>
            </div>
        )
    }

    const renderEndDateInput = () => {
        const typeOfDate = frequency === PnlElementFrequencyEnum?.EVERY_DAY || frequency === 'EVERY_DAY' ? 'date' : 'month'
        return (
          <div>
            <fieldset>
              <label>
                <Flex alignItems={"center"} gap={"10px"}>
                  <div>End Date:</div>
                  <Checkbox
                      isChecked={endDateInfinity}
                    onChange={(e) => {
                        if(e?.target?.checked && startDateInfinity){
                            setStartDateInfinity(false);
                            setStartDate('');
                        }
                      setEndDateInfinity(e?.target?.checked);
                    }}
                    colorScheme={"purple"}
                  >
                    Infinity
                  </Checkbox>
                </Flex>
              </label>
              <Input
                  min={startDate}
                  disabled={endDateInfinity}
                value={endDateInfinity ? "" : endDate}
                onChange={(e) => {
                  setEndDate(e?.target?.value);
                }}
                type={typeOfDate}
              />
            </fieldset>
          </div>
        );
    }
    const defaults =  ['fob', 'updatesAt', 'product', 'channel', 'sellingprice'];
    const allCogsNames= allCogs?.map((item) => item?.name?.toLowerCase()).concat(defaults);
    const isSameCogsName: boolean = !!allCogsNames && allCogsNames?.includes(name?.toLowerCase());

    const checkIfMetricNameIsValid = () => {
        const isSamePnlName: boolean = !(allPnlNames?.map((item) => t(item, { ns: 'translation' })?.toLowerCase())?.includes(name?.toLowerCase()));
        if(!isSamePnlName && !!props?.metricIdToEdit){
            const foundMetric = pnlElements?.find((metric) => metric?.id === props?.metricIdToEdit);
            return foundMetric?.displayName === name
        }
        return isSamePnlName
    }


    const isApplyValid = validForm?.isValid && checkIfMetricNameIsValid() && !isSameCogsName;


    return (

        <styles.Wrapper>
            <styles.Header>
                <styles.TypeButton isFocus={!isPercentage} onClick={() => setIsPercentage(false)}>
                    {t('BY_VALUE', { ns: 'translation' })}
                    <div>{"$"}</div>
                </styles.TypeButton>
                <styles.TypeButton isFocus={isPercentage} onClick={() => setIsPercentage(true)}>
                    {t('BY_PERCENTAGE', { ns: 'translation' })}
                    <div>{"%"}</div>
                </styles.TypeButton>
            </styles.Header>
            <styles.Content>
                <styles.Title>
                    {t('NAME', { ns: 'translation' })}:
                </styles.Title>
                <styles.Input disabled={!!props?.metricIdToEdit} value={name} placeholder={'Name'} onChange={e => setName(e.target.value)} />
                <styles.Title>
                    {t(isPercentage ? 'PERCENTAGE' : 'AMOUNT', { ns: 'translation' })}:
                </styles.Title>
                <styles.Input type={'number'} value={value} placeholder={'10'}
                    onChange={e => setValue(e.target.valueAsNumber)} />
                {
                    isPercentage &&
                    <AddExpenseMenu title={"PERCENTAGE_FROM"} items={Object?.values(PercentageFrom)}
                        item={percentageFrom} setItem={setPercentageFrom} />
                }
                {/*<AddExpenseMenu
                    title={"CHANNEL"}
                    items={[{ displayName: 'ALL_CHANNELS', name: 'ALL_CHANNELS', id: "123" }, ...props.channels]}
                    item={channelItem}
                    setItem={setChannelItem} />*/}
                <CheckboxMenuSmall
                    title={"Channels"}
                    backgroundColor={'#EDF2F7'}
                    borderRadius={10}
                    boldText={true}
                    displayButtons={true}
                    defaultCheckItems={selectedOptions}
                    /*icon={(color: string) => <IoStorefrontSharp color={color} />}*/
                    displayChannelName={true}
                    options={options}
                    createMetricModal={true}
                    onApply={(checked) => {
                        if(!!checked){
                            setSelectedOptions(checked)
                        }
                    }}
                />
                {
                    !isPercentage &&
                    <AddExpenseMenu title={t("Frequency", { ns: "translation" })}
                        items={['EVERY_DAY', 'EVERY_WEEK', 'EVERY_MONTH', 'EVERY_YEAR']} item={frequency}
                        setItem={setFrequency} />
                }
                <Flex flexDirection={'column'} gap={'10px'}>
                    {!isPercentage &&
                        renderStartDateInput()}
                    {!isPercentage &&
                        renderEndDateInput()}
                </Flex>

            </styles.Content>
            <styles.Footer>
                <styles.FooterButton onClick={onClose}>
                    {t('CANCEL', { ns: 'translation' })}
                </styles.FooterButton>
                <styles.FooterButton disabled={!validForm?.isValid} isApply={isApplyValid} onClick={createNewPnlDataLanguageItem}>
                    {t(props.metricIdToEdit ? 'EDIT' : 'APPLY', { ns: 'translation' })}
                </styles.FooterButton>
            </styles.Footer>
        </styles.Wrapper>

    );
};


export default AddCustomExpense;
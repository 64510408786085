import { Flex, Spinner } from "@chakra-ui/react";
import {
  fetchMarketingMappingElementsView,
  organizationSelector,
} from "@store";
import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exploreLoadingSelector,
  fetchKpiData,
  fetchMarketingMappingByCampaign,
  marketingStatsLoaderSelector,
} from "store/kpis";
import {
  ExploresEnum,
  isDefaultExploreOpenSelector,
  isSecondaryExploreOpenSelector,
  openDefaultExploreView, openSecondaryExplore
} from "store/ui";
import { IconCloseOverlay } from "utils/icons/account-health";
import { IconMarketing } from "utils/icons/explore";
import { styles } from "./styles";
import MarketingTable from "./marketing-table/MarketingTable";
import useFetchDailyStats from "../../utils/custom-hooks/useFetchDailyStats";

export const MarketingView = () => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState<{ today: string; lastYear: string }>();
  const organization = useSelector(organizationSelector);
  const marketingLoader = useSelector(marketingStatsLoaderSelector);
  const currentExploreOpen = useSelector(isDefaultExploreOpenSelector);
  const currentSecondaryExplore = useSelector(isSecondaryExploreOpenSelector);

  useEffect(() => {
    let date = new Date();
    const today = date?.toISOString();
    const lastYear = new Date();
    const pastYear = lastYear?.getFullYear() - 1;
    lastYear.setFullYear(pastYear);

    setDates({ today: today, lastYear: lastYear.toISOString() });
  }, []);

  useEffect(() => {
    if (
      !!organization?.id &&
      !!dates?.lastYear &&
      !!dates?.today &&
      currentExploreOpen?.isDefaultExploreOpen
    ) {
      // TODO remove from date
      dispatch(
        fetchMarketingMappingByCampaign({ organizationId: organization?.id })
      );
    }
  }, [
    dates?.lastYear,
    dates?.today,
    dispatch,
    organization?.id,
    currentExploreOpen?.isDefaultExploreOpen,
  ]);
  useEffect(() => {
    if (!!organization?.id && currentExploreOpen?.isDefaultExploreOpen) {
      dispatch(
        fetchMarketingMappingElementsView({ organizationId: organization?.id })
      );
    }
  }, [organization?.id, currentExploreOpen?.isDefaultExploreOpen, dispatch]);

  const renderHeader = () => {
    return (
      <styles.Header>
        <styles.HeaderHolder>
          <styles.HeaderIcon>
            <span style={{ width: "50px", height: "50px" }}>
              <IconMarketing />
            </span>
          </styles.HeaderIcon>
          <styles.TitleWrapper>
            <styles.Title>
              {t("MARKETING_MAPPING", { ns: "translation" })}
              <MoreInfoButton onClick={() => {}} />
              {/* <DataDialog
                    /> */}
            </styles.Title>
            <styles.SubTitle>
              {t(
                "PLEASE_MAP_AND_ALLOCATE_SPECIFIC SPENDS_TO_CHANNELS_AND_PRODUCTS",
                { ns: "translation" }
              )}

            </styles.SubTitle>
            <styles.SubTitle>
              Drill down between Integration, Account and Campaign
            </styles.SubTitle>
          </styles.TitleWrapper>
        </styles.HeaderHolder>

        <styles.HeaderIcon>
          <span style={{ width: "100px", height: "100px", overflow: "hidden" }}>
            <button
              onClick={() => {
                if(currentSecondaryExplore?.isSecondaryExploreOpen && currentSecondaryExplore?.exploreEnum === ExploresEnum.MARKETING_MAPPING){
                  dispatch(openSecondaryExplore({isOpen: false}))
                  return
                }
                dispatch(openDefaultExploreView({ isOpen: false }));
              }}
            >
              <IconCloseOverlay />
            </button>
          </span>
        </styles.HeaderIcon>
      </styles.Header>
    );
  };

  return (
    <styles.Wrapper>
      {renderHeader()}
      {marketingLoader ? (
        <Flex w={"100%"} justifyContent="center" alignItems={"center"}>
          <Spinner />
        </Flex>
      ) : (
        <MarketingTable />
      )}
    </styles.Wrapper>
  );
};

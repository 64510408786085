import styled from "styled-components";
import {Table, Tbody, Td, Th, Tr} from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
  `;

  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px;
    height: auto;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;

  export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  `;

  export const TableCustom = styled(Table)`
    max-height: 300px;
  `;

  export const TbodyCustom = styled(Tbody)`
    display: block;
    height: 300px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  export const ThCustom = styled(Th)`
    text-transform: capitalize !important;
    color: gray;
    font-size: 14px;
    border-bottom: 1px solid #7a86a1 !important;
    table-layout: fixed;
    user-select: none;
  `;

  export const TrCustom = styled(Tr)<{ selected?: boolean }>`
    border: none !important;
    display: table;
    width: 100%;
    table-layout: fixed;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    &:hover {
      cursor: pointer;
    }
  `;

  export const TdCustom = styled(Td)`
    border: none !important;
    font-size: 14px;
  `;

  export const ClearFiltersButtonWrapper = styled.div`
    width: 45px;
    height: 45px;
    border: 1px solid #ebebeb;
    border-radius: 19px;
    background: white;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;
    align-items: center;
    margin: 0 10px 0 10px;

    &:hover {
      cursor: pointer;
    }
  `;
}

export default styles;

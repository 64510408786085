import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {AmazonSellerState} from "./state";

// sign-up state
export const amazonSellerStateSelector = (state: RootState): AmazonSellerState => state.amazon;

// code
export const codeSelector = createSelector(amazonSellerStateSelector, (state) => state.code);

// sign-up error
export const amazonSellerErrorSelector = createSelector(amazonSellerStateSelector, (state) => state.error);

// loading
export const amazonSellerLoadingSelector = createSelector(amazonSellerStateSelector, (state) => state.loading);

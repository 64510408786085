import marketingIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform, IntegrationType,
} from "@models";
import {addIntegration, organizationSelector} from "@store";
import {useDispatch, useSelector} from "react-redux";
import { IntegrationButton } from "../../../components/integration-button";
import { integrationSelector } from "store/integrations";
import OAuthPopup from "components/o-auth-popup/OAuthPopup";
import {quickbooksService} from "../../../services/quickbooks/quickbooks";
import axios from "axios";
import {useEffect, useState} from "react";
import {collectorsBaseUrl} from "@constants";
import {createZohoUrl, xeroUrl, zohokCrmRoundLogo} from "../../../constants/integration";
import storeIntegrationsStyles from "../stores-integrations/styles";

export const FinanceIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const integrations = useSelector(integrationSelector);
    const [quickbooksUrl, setQuickbooksUrl] = useState('')


    useEffect(() => {
        const getAxios = async () => {
           const response =  await axios({
                method: 'get',
                url: `${collectorsBaseUrl}/quickbooks/oauth`

            });
            setQuickbooksUrl(response?.data)
        }
        getAxios()

    }, [])


    // helpers

    const sendFinanceOAuthFromSearchParams = (params: URLSearchParams, platform: IntegrationPlatform) => {
        // @ts-ignore
        const OAuthData = params.get('result');
        try {
            if (!!OAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.FINANCE,
                        platform: platform,
                        object: JSON.parse(OAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };


    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    const getQuickbooksParams = (params: URLSearchParams) => {
        console.log(params)
    };



    // renderers
    const renderFinanceIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('FINANCE', { ns: 'translation' })}
                </Text>
                <Flex direction={"row"}>
                    {renderQuickbooks()}
                    {renderZohoBooksIntegrations()}
                    {renderXeroIntegrations()}
                </Flex>
            </Flex>
        );
    };

    const renderQuickbooks = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.QUICKBOOKS);

        return (
           /* <OAuthPopup
                title={'Quickbooks'}
                height={800}
                width={1000}
                url={quickbooksUrl}
                onResponse={(params) => {
                    sendFinanceOAuthFromSearchParams(params!, IntegrationPlatform.QUICKBOOKS);
                }}
                onClose={() => {

                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
           /* </OAuthPopup>*/
        );
    };

    const renderZohoBooksIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.ZOHO_BOOKS);
        return (
            /*<OAuthPopup
                title={'Zoho books'}
                height={800}
                width={1000}
                url={createZohoUrl(IntegrationPlatform.ZOHO_BOOKS) ?? ''}
                onResponse={(params) => {
                    sendFinanceOAuthFromSearchParams(params!, IntegrationPlatform.ZOHO_BOOKS);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderXeroIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.XERO);

        return (
            /*<OAuthPopup
                title={'Xero'}
                height={800}
                width={1000}
                url={xeroUrl ?? ''}
                onResponse={(params) => {
                    sendFinanceOAuthFromSearchParams(params!, IntegrationPlatform.XERO);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        premium={true}
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderFinanceIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
  `;

  export const Title = styled.div`
    /* border-bottom: solid 1px; */
    /*margin-bottom: 20px;*/
    align-items: center;

    display: flex;
    justify-content: space-between;
  `;
  export const StyledTitle = styled.div`
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    font-size: 21px;
    font-weight: bold;
    display: flex;
  `;

  export const TopKpiWrapper = styled.div`
    /* height: 480px; */
    display: flex;
    flex-wrap: nowrap;
    min-height: 240px;
    width: 100%;
    //overflow-y: hidden!important;

    //overflow-x: hidden;

    /* &::-webkit-scrollbar {
      display: none;
    } */
  `;
  export const WideviewCardWrapper = styled.div`
    //width:200px;
    //height: 120px;
    //padding: 20px;
  `;
}

export default styles;

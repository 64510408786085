export const PlanningRoundIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 64 64">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#7031eb"/>
                    <stop offset="1" stop-color="#4a55fb"/>
                </linearGradient>
            </defs>
            <g id="Group_11785" data-name="Group 11785" transform="translate(-5 -375)">
                <path id="Path_1806" data-name="Path 1806" d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z" transform="translate(5 375)" fill="url(#linear-gradient)"/>
                <g id="Layer_2" data-name="Layer 2" transform="translate(24.288 392.143)">
                    <g id="invisible_box" data-name="invisible box" transform="translate(0.001 0.146)">
                        <rect id="Rectangle_393" data-name="Rectangle 393" width="29" height="29" transform="translate(-0.289 -0.289)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(3.569 1.205)">
                        <g id="Group_1138" data-name="Group 1138">
                            <path id="Path_550" data-name="Path 550" d="M17.9,4.379V3.19A1.19,1.19,0,0,0,16.707,2H6V26.984a1.19,1.19,0,0,0,2.379,0V16.277h7.138v1.19a1.19,1.19,0,0,0,1.19,1.19H27.415V4.379Zm7.138,7.138H22.656V13.9h2.379v2.379H22.656V13.9H20.277v2.379H17.9V13.9H15.518V11.518H13.138V13.9H10.759V11.518H8.379V9.138h2.379V6.759H8.379V4.379h2.379V6.759h2.379V4.379h2.379V6.759H17.9V9.138h2.379V6.759h2.379V9.138h2.379Z" transform="translate(-6 -2)" fill="#fff"/>
                            <rect id="Rectangle_394" data-name="Rectangle 394" width="2" height="3" transform="translate(5.143 6.652)" fill="#fff"/>
                            <rect id="Rectangle_395" data-name="Rectangle 395" width="2" height="2" transform="translate(7.143 4.652)" fill="#fff"/>
                            <rect id="Rectangle_396" data-name="Rectangle 396" width="3" height="3" transform="translate(9.143 6.652)" fill="#fff"/>
                            <rect id="Rectangle_397" data-name="Rectangle 397" width="2" height="2" transform="translate(12.143 9.652)" fill="#fff"/>
                            <rect id="Rectangle_398" data-name="Rectangle 398" width="3" height="3" transform="translate(14.143 6.652)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;

export interface Tooltip {
  [key: string]: {
    shortText?: string;
    longText?: string;
  }
}

// state
export interface TooltipState {
  data?: Tooltip;
  loading: boolean;
  error?: string;
}

// initial state
export const initialTooltips: TooltipState = {
  data: undefined,
  loading: false,
  error: undefined,
};

import styled from "styled-components";

import { PopoverContent } from "@chakra-ui/react";
import {MdDragIndicator, MdOpenInNew} from "react-icons/md";
import {RiPencilLine} from "react-icons/ri";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
  `;

  export const CogsTicket = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    background-color: #775bad36;
    color: #6c4ea5;
    margin-left: 10px;
    max-height: 30px;
  
  `;
  export const CogsTicketText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  `



  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  export const Info = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const HeaderIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `;

  export const Title = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    display: flex;
  `;
  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    padding: 50px;
    height: 100%;
    width: 100%;
  `;

  export const ActiveList = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: transparent;
  `;
  export const BankList = styled.div`
    border-top: 7px solid red;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(90deg, #7031eb 0%, #4a55fb 100%) 1 stretch;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: min-content;
    margin-right: 50px;
    background-color: #ffffff;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    box-shadow: ${(props) => props.theme.fullShadow};
  `;

  export const Bucket = styled.div<{bankBucket?: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px;
    position: relative;
    z-index: 1;
    max-height: 450px;
    overflow-y: auto;
    min-height: ${props => props?.bankBucket ? '150px': '50px'};
  `;



  export const TiltedText = styled.div`
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    color: #7a86a1;
    font-size: 15px;
    margin-left: 20px;
    padding: 2px 0;
  `
  export const StyledPopoverContent= styled(PopoverContent)`
    min-width: 0;
    max-width: 110px;
    &:focus{
      box-shadow: 5px 31px 54px #695F9736 !important;
      border: unset;
    }
  `



  export const DropAreaPlaceHolderWrapper = styled.div`
    height: 70px;
    width: 100%;
    align-self: flex-end;
    padding: 5px 0;
    
    
  `;

  export const DropAreaPlaceHolder = styled.div`
    border: 3px #bababa52 dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #bababa52;
    text-align: center;
    height: 100%;
    width: 100%;
  `;

  export const ExpensesSectionTitle = styled.div`
    border-left: 5px ${(props) => props.theme.primaryColor} solid;
    width: 100%;
    background-color: #4a55fb19;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
  `;

  export const EditItemButton = styled.div<{ addBorder?: boolean }>`
    width: 100%;
    text-align: start;
    padding: 5px;
    z-index: 10;

    border-bottom: ${(props) => (props.addBorder ? "1px" : "0px")};
    &:hover {
      background-color: #bababa52;
      cursor: pointer;
    }
  `;

  export const EditItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 15 !important;
    overflow: hidden;
    align-items: center;
  `;

  export const CardTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;

  export const MenuWrapper = styled.div`
    /*position: absolute;
    right: 0;*/
    display: flex;
    /*flex-direction: column;*/
    /*bottom: -2px;*/
    justify-self: flex-end;
    font-size: smaller;
    /*z-index: 7;*/
    background-color: white;
    border-radius: 6px;
    //border: 1px solid #E2E8F0;
  `
  export const MenuItem = styled.div`
    border-bottom: 1px solid #E2E8F0;
    &:last-of-type{
      border-bottom: none;
    }
    padding: 3px 5px;
    &:hover{
      background-color: rgba(186, 186, 186, 0.32);
      cursor: pointer;
    }
  `

  export const IconHolder = styled.div`
   
    padding: 3px 5px;
    &:hover{
      cursor: pointer;
    }
  `

  export const ExpensesCardWrapper = styled.div<{ isDragging?: boolean }>`
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: #ffffff;
    opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
    border-radius: 3px;
    padding: 10px 0;
    width: 100%;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    &:hover {
      box-shadow: ${(props) => props.theme.fullShadow};
    }
  `;

  export const DraggedElement = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
    max-width: 600px;
    z-index: 10000;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
`;
  export const CardTitle = styled.div`
    font-size: 16px;
  `;

  export const BankTitle = styled.div`
    padding: 5px 20px;
    width: 100%;
    text-align: start;
    font-size: 26px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #bababa52;
  `;

  export const AddCustom = styled.button`
    display: flex;
    flex-direction: row;
    background-color: rgb(251, 247, 255);
    justify-content: start;
    padding: 10px 20px;
    font-weight: bold;
  `;

  export const IconMdDragIndicator = styled(MdDragIndicator)`
    color: #bababa52;
    font-size: 18px;
    min-width: 36px;
    min-height: 36px;
    &:hover {
      color: #777;
      cursor: all-scroll;
    }
  `;
  export const IconMdDragIndicatorPlaceholder = styled.span`
    color: #bababa52;
    width: 36px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const IconMdOpenInNew = styled(MdOpenInNew)`
    font-size: 20px;
    margin-right: 6px;
    color: #bababa52;
    &:hover {
      color: #777;
      cursor: pointer;
      
    }
  `;
  export const IconRiPencilLine = styled(RiPencilLine)`
    color: #bababa52;
    &:hover {
      color: #777;
    }
  `;

  export const CogsHolder = styled.div`
  display: flex;
    ::-webkit-scrollbar{
      display: none;
    }
    
    gap: 5px;
    max-width: 70%;
    overflow: auto;
  `

}

export default styles;

import {useEffect, useState} from 'react';
import {AccountIntegrations, IntegrationType} from 'store/account';
import {styles} from './styles';
import {useNavigate} from "react-router-dom";
import {MoreInfoButton} from "components/more-info-button/MoreInfoButton";
import {useTranslation} from 'react-i18next';
import {
    AmazonAds,
    AmazonSeller,
    IconFacebook,
    IconGoogleAds,
    IconGoogleAnalytics, IconShipstation,
    IconShopify, IconWooCommerce
} from 'utils/icons/platforms';
import {Popover, PopoverContent, PopoverTrigger, Tooltip} from '@chakra-ui/react';
import {IconWarning} from 'utils/icons/account-health';
import {MoreInfoDialog, MoreInfoDialogProps} from 'dialogs/more-info-dialog/MoreInfoDialog';
import {IntegrationPlatform} from "@models";


interface Props {
  data: AccountIntegrations[];
}

const portalIntegrationsMoreInfoDialogProps = {
  title: "Active Integrations",
  //subtitle: 'How to use this view?',
  description:
    "In this section, you will see all your platform integrations divided by type.",
  tip: "Tip: More integrations = more data = more insights.",
  /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export const PortalIntegrations = (props: Props) => {
    const {t} = useTranslation(['translation', 'translation']);
    const [activeIntegrations, setActiveIntegrations] = useState<number[]>([]);
    const [allMarketing, setAllMarketing] = useState<AccountIntegrations[]>([]);
    const [allStores, setAllStores] = useState<AccountIntegrations[]>([]);
    const [allWebsites, setAllWebsites] = useState<AccountIntegrations[]>([]);
    const [allCRM, setAllCRM] = useState<AccountIntegrations[]>([]);
    const [counter, setCounter] = useState(0);
    const [isPortalIntegrationsMoreInfoDialogOpen, setIsPortalIntegrationsMoreInfoDialogOpen] = useState(false);

  useEffect(() => {
    const activeData: number[] = [];
    const marketingData: AccountIntegrations[] = [];
    const storesData: AccountIntegrations[] = [];
    const websiteData: AccountIntegrations[] = [];
    const crmData: AccountIntegrations[] = [];
    props.data.forEach((item) => {
      activeData.push(item.type);
    });
    setActiveIntegrations(activeData);
    props.data.forEach((item) => {
      if (item.type === IntegrationType.MARKETING) {
        marketingData.push(item);
      }
      if (item.type === IntegrationType.STORES) {
        storesData.push(item);
      }
      if (item.type === IntegrationType.WEBSITE) {
        websiteData.push(item);
      }
      if (item.type === IntegrationType.CRM) {
        crmData.push(item);
      }
    });
    setAllMarketing(marketingData);
    setAllStores(storesData);
    setAllWebsites(websiteData);
    setAllCRM(crmData);
  }, [props.data]);
  useEffect(() => {
    const array = [
      allMarketing.length,
      allStores.length,
      allWebsites.length,
      allCRM.length,
    ];
    setCounter(Math.max(...array));
  }, [
    allCRM.length,
    allMarketing.length,
    allStores.length,
    allWebsites.length,
  ]);
  let navigate = useNavigate();
  //hooks
  const handleClickNavigate = () => {
    navigate("/admin/panel/integrations");
  };
  //helpers

  const openPortalIntegrationsMoreInfoDialog = (isOpen: boolean) =>
    setIsPortalIntegrationsMoreInfoDialogOpen(isOpen);

    const renderSvg = (dataName: number, i: number) => {
        switch (dataName) {
            case (IntegrationPlatform.AMAZON_ADS):
                return <styles.Icon key={i}>{AmazonAds()}</styles.Icon>;
            case (IntegrationPlatform.AMAZON_SELLER):
                return <styles.Icon key={i}>{AmazonSeller()}</styles.Icon>;
            case (IntegrationPlatform.FACEBOOK_ADS):
                return <styles.Icon key={i}>{IconFacebook()}</styles.Icon>;
            case (IntegrationPlatform.GOOGLE_ADS):
                return <styles.Icon key={i}>{IconGoogleAds()}</styles.Icon>;
            case (IntegrationPlatform.GOOGLE_ANALYTICS):
                return <styles.Icon key={i}>{IconGoogleAnalytics()}</styles.Icon>;
            case (IntegrationPlatform.SHOPIFY):
                return <styles.Icon key={i}>{IconShopify()}</styles.Icon>;
            case(IntegrationPlatform.WOOCOMMERCE):
                return <styles.Icon key={i}>{IconWooCommerce()}</styles.Icon>;
            case(IntegrationPlatform.SHIPSTATION):
                return <styles.Icon key={i}>{IconShipstation()}</styles.Icon>;


            default:
                return <></>;
        }
    };

  const renderTitle = (number: number) => {
    switch (number) {
      case 0:
        return `${t("STORES", { ns: "translation" })}`;
      case 1:
        return `${t("MARKETING", { ns: "translation" })}`;
      case 2:
        return `${t("WEBSITE", { ns: "translation" })}`;
      case 3:
        return `${t("CRM", { ns: "translation" })}`;
    }
    return;
  };

  //renderes

  const customToolTip = (title: number) => {
    return (
      <PopoverContent borderRadius={30} w={"max-content"}>
        <styles.StyledToolTip>
          {IconWarning()}
          <div style={{ width: 3 }} />
          {t("NO_ACTIVE", { ns: "translation" })} {renderTitle(title)}{" "}
          {t("INTEGRATIONS", { ns: "translation" })}
        </styles.StyledToolTip>
      </PopoverContent>
    );
  };

  const renderButton = (title: number, array?: AccountIntegrations[]) => {
    const flag = activeIntegrations?.includes(title);
    return (
      <styles.ButtonWrapper onClick={() => {
          handleClickNavigate();
      }}>
        <Popover trigger="hover" placement="top">
          <PopoverTrigger>
            <styles.Button
              {...{ selected: flag }}
              onClick={() => {
                if (flag) {
                  handleClickNavigate();
                }
              }}
            >
              <styles.ButtonText>{renderTitle(title)}</styles.ButtonText>
              <styles.Spacer />
            </styles.Button>
          </PopoverTrigger>
          {!flag ? customToolTip(title) : null}
        </Popover>
        {flag ? (
          <styles.iconHolder>
            <styles.IconRow>
              {array?.map((item, i) => {
                return (
                  <Tooltip key={i} placement="top" label={item.name}>
                    {renderSvg(item.platform, i)}
                  </Tooltip>
                );
              })}
            </styles.IconRow>
          </styles.iconHolder>
        ) : null}
      </styles.ButtonWrapper>
    );
  };

    const renderButtonPlaceholder = () => {
        return (
            <styles.buttonWrapperPlaceholder>
                <Popover trigger="hover" placement="top">
                    <PopoverTrigger>
                        <styles.Button>
                        </styles.Button>
                    </PopoverTrigger>
                </Popover>
            </styles.buttonWrapperPlaceholder>
        );
    };

  const renderButtons = () => {
    return (
      <styles.ButtonsContainer>
        {renderButton(0, allStores)}
        {renderButton(1, allMarketing)}
        {/*{renderButton(2, allWebsites)}
        {renderButton(3, allCRM)}*/}
          {renderButtonPlaceholder()}
          {renderButtonPlaceholder()}
      </styles.ButtonsContainer>
    );
  };

  return (
    <styles.Wrapper icons={((Math.ceil(counter / 4) * 50) < 135) ? (Math.ceil(counter / 4) * 50) : 135 }>
      <styles.Title>
        {t("ACTIVE_INTEGRATIONS", { ns: "translation" })}
        <MoreInfoButton
          onClick={() => openPortalIntegrationsMoreInfoDialog(true)}
        />
        <MoreInfoDialog
          props={portalIntegrationsMoreInfoDialogProps}
          isDialogOpen={isPortalIntegrationsMoreInfoDialogOpen}
          onClose={() => openPortalIntegrationsMoreInfoDialog(false)}
        />
      </styles.Title>
      {renderButtons()}
    </styles.Wrapper>
  );
};

import styles from "./styles";
import React, { useEffect, useState } from "react";
import {
  Channel,
  DictionaryKeyChannelValuePayment,
  Metric,
  PnlDataLanguageSettingSettings,
} from "../../models/pnl-language";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { IconCloseOverlay, IconCOGS } from "../../utils/icons/account-health";
import { MoreInfoButton } from "../more-info-button/MoreInfoButton";
import { useTheme } from "@styles";
import { Flex, useDisclosure } from "@chakra-ui/react";

import ApplyChangesBar from "../apply-changes-bar/ApplyChangesBar";
import {
  ExploresEnum,
  isApplyBarOpenSelector,
  isApplyModalOpenSelector,
  isDefaultExploreOpenSelector,
  isSecondaryExploreOpenSelector,
  openApplyBar,
  openApplyModal,
  openDefaultExploreView,
  openSecondaryExplore,
} from "store/ui";
import {
  cleanUpPnlLoaders,
  organizationDataLanguageSelector,
  organizationSelector,
  pnlPostLoadingSelector,
  pnlPutLoadingSelector,
} from "@store";
import { ApplyChangesModal } from "components/apply-changes-modal/ApplyChangesModal";
import { fetchPayments, paymentsSelector } from "store/payments";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Container from "./Container";
import { ItemTypes } from "./itemTypes";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddCustomExpense from "../pnl-overlay-new/add-custom-expens/AddCustomExpense";
import {
  applyDraftPnls,
  createPnl,
  discardDraftPnl,
  editPnl,
  fetchDraftPnlDataLanguage,
  isFinishedMovingLoading,
  pnlDataLanguageDisplayNamesSelector,
  pnlDataLanguageDraftSelector,
  pnlDataLanguageNamesSelector,
  resetMoveLoader,
} from "../../store/pnl";
import { IntegrationType } from "@models";
import { channelSelector } from "../../store/channels";
import {CardProps} from "./Card";


const pnlSettingMoreInfoDialogProps = {
    title: 'P&L Overview',
    //subtitle: 'How to use this view?',
    description: 'Use this overview to understand which sections in your financial funnel drive profitability/loss. Lines represent your set targets, and colors and scoreboards below indicate performance level. In the section below, you can drill down to compare P&L performance over time and store channels. ',
    tip: 'Tip: P&L structure can be edited on the `P&L` / `Account health` page.',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export enum OperatorNode {
    PLUS,
    MINUS

}

export interface ExpressionDto {
    fieldName?: string,
    order: number,
    isOperator: boolean,
    action?: OperatorNode
}

export interface PnlNodeDto {
    fieldName: string,
    equation: ExpressionDto[],
}

export const PnlSettingOverlayNew = () => {

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const organization = useSelector(organizationSelector);
    const theme = useTheme();
    const [pnlDataLanguage, setPnlDataLanguage] = useState<PnlDataLanguageSettingSettings>({ pnlItems: [], transactionFees: [] });
    const [changesCounter, setChangesCounter] = useState(0);
    const [isPnlMoreInfoDialogOpen, setIsPnlMoreInfoDialogOpen] = useState(false);
    const isDefaultExploreOpen = useSelector(isDefaultExploreOpenSelector);
    const isSecondaryExploreOpen = useSelector(isSecondaryExploreOpenSelector);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const isApplyModalOpen = useSelector(isApplyModalOpenSelector);
    const paymentsData = useSelector(paymentsSelector);
    const [channelForTransactionFee, setChannelForTransactionFee] = useState<Channel[]>([]);
    const [dictionary, setDictionary] = useState<DictionaryKeyChannelValuePayment>({});
    const postLoader = useSelector(pnlPostLoadingSelector);
    const putLoader = useSelector(pnlPutLoadingSelector);
    const [isAddExpenseOpen, setAddExpenseOpen] = useState<boolean>(false);
    const pnlElements = useSelector(pnlDataLanguageDraftSelector);
    const [cards, setCards] = useState<any[]>([]);
    const [initCards, setInitCards] = useState<any[]>([]);
    const allPnlDisplayNames = useSelector(pnlDataLanguageDisplayNamesSelector)?.data;
    const channelsData = useSelector(channelSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const allPnlNames = useSelector(pnlDataLanguageNamesSelector);
    const isFinishedLoading = useSelector(isFinishedMovingLoading);
    const [metricIdToEdit, setMetricIdToEdit] = useState<string>('');

    useEffect(() => {
        if(!isApplyBarOpen && !!pnlElements?.data && isDefaultExploreOpen?.isDefaultExploreOpen){
            const isSomeMetricChanged = pnlElements?.data?.some((draftMetric) => draftMetric?.isChanged);
            if(isSomeMetricChanged){
                dispatch(openApplyBar({isApplyBarOpen: true}));
                return
            }

            const draftDataNames = pnlElements?.data?.map((draftMetric) => draftMetric?.fieldName);
            const realMetricNames = allPnlNames?.data;
            if(draftDataNames?.length !== realMetricNames?.length){
                dispatch(openApplyBar({isApplyBarOpen: true}));
            }
            else {
                const areArraysEqual = draftDataNames.length === realMetricNames.length && draftDataNames.every((value, index) => value === realMetricNames[index]);
                if(!areArraysEqual){
                    dispatch(openApplyBar({isApplyBarOpen: true}));
                    return
                }
            }
        }

    }, [isDefaultExploreOpen?.isDefaultExploreOpen, allPnlNames, pnlElements])

    useEffect(() => {
        if(!isApplyBarOpen){
            dispatch(resetMoveLoader())

        }
    }, [isApplyBarOpen])


    const editMetric = (metricToAdd: Metric): void => {
        const metricId = metricToAdd?.id;
        if(!!organization?.id && !!metricId){
            dispatch(editPnl({organizationId: organization?.id, id: metricId, dto: metricToAdd}))
        }
        setAddExpenseOpen(false)
    }

    useEffect(() => {
        if(!isAddExpenseOpen){
            setMetricIdToEdit('');
        }
    }, [isAddExpenseOpen]);

    useEffect(() => {
        if(!!metricIdToEdit){
            setAddExpenseOpen(true)
        }
    }, [metricIdToEdit]);

    const setPnlData = () => {
        if (dataLanguage?.pnlSettings) {
            const pnlSettings = dataLanguage?.pnlSettings;
            const transformTransactionFees = pnlSettings?.transactionFees?.map((item) => {
                if(item?.channel === "ALL"){
                    return {...item, channel: "ALL_OTHER_CHANNELS", paymentMethod: "ALL_OTHER_PAYMENT_METHOD"}
                }else {
                    return item
                }
            })
            let transformed: PnlDataLanguageSettingSettings = {
                pnlItems: [],
                transactionFees: transformTransactionFees
            };
                setPnlDataLanguage(transformed);
        }
    }

    useEffect(() => {
        setPnlData();
    }, [ isDefaultExploreOpen.isDefaultExploreOpen, isSecondaryExploreOpen.isSecondaryExploreOpen, dataLanguage?.pnlSettings]);

    const {
        isOpen: isTransactionFeeManagerOpen,
        onOpen: onTransactionFeeManagerOpen,//onTFMOpen,
        onClose: onTransactionFeeManagerClose
    } = useDisclosure();
    useEffect(() => {
        if (!!channelsData && !!paymentsData) {
            let temp: DictionaryKeyChannelValuePayment = {};
            const channelsFilterByIntegrationType =
                channelsData?.filter((item) => item.type === IntegrationType.STORES);

            setChannelForTransactionFee(channelsFilterByIntegrationType);

            channelsFilterByIntegrationType.forEach((channel) => {
                if (channel?.id) {

                    const getPayments = paymentsData.filter(item => item.wvChannelId === channel.id);

                    if (getPayments.length) {

                        temp[channel.id] = [...getPayments];

                        const indexByChannel = pnlDataLanguage.transactionFees.findIndex(item => item.channel === channel.id);

                        if (indexByChannel !== -1) {
                            temp[channel.id].forEach(paymentIndex => {
                                const indexByPayment = pnlDataLanguage.transactionFees.findIndex(tfIndex =>
                                    tfIndex.paymentMethod === paymentIndex.id && tfIndex.channel === paymentIndex.wvChannelId
                                );

                                if (indexByPayment !== -1 && !!channel?.id) {
                                    const newObj = Object.assign({ isSelected: true },
                                        temp[channel.id][indexByPayment]);
                                    temp[channel.id][indexByPayment] = newObj;
                                }
                            })

                        }

                    }

                    else { //TODO to ask what to do with there isn't payment method
                        temp[channel.id] = [];
                    }
                }

            });
            setDictionary(temp);
        }

        else {

        }
    }, [channelsData, paymentsData]);

    useEffect(() => {
        handleDispatchData()
    }, [isDefaultExploreOpen?.isDefaultExploreOpen, organization?.id]);

    useEffect(() => {
        if(!!pnlElements?.data){
            const cardsData = handleData(pnlElements?.data);
            const sortedCards =  cardsData?.sort((card) => {
                // @ts-ignore
                if(card?.isDefault){
                    return -1
                }
                else {
                    return 1
                }
            })
            setCards(sortedCards)
            setInitCards(sortedCards)
        }

    }, [pnlElements?.data])

    const handleDispatchData = () => {
        if(!!organization?.id){
            dispatch(fetchDraftPnlDataLanguage({organizationId: organization?.id}))
        }
    }

    const  convertCamelCaseToString = (input?: string): string => {
        const foundDisplayName = allPnlDisplayNames?.find((name: any) => name?.fieldName === input)?.displayName;
        if(!!foundDisplayName){
            return foundDisplayName
        }
        if(!!input){
            const words: string[] = [];
            let currentWord = '';

            for (let i = 0; i < input?.length; i++) {
                const char = input?.charAt(i);

                if (char === char.toUpperCase()) {
                    // Start of a new word
                    if (currentWord.length > 0) {
                        words.push(currentWord);
                    }
                    currentWord = char;
                } else {
                    // Append lowercase character to current word
                    currentWord += char;
                }
            }

            if (currentWord.length > 0) {
                words.push(currentWord);
            }

            // Capitalize the first letter of each word
            const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

            return capitalizedWords.join(' ');
        }
        return ''
    }

    const handleData = (data: Metric[]) => {

        const allUnusedPnlElements: Metric[] = [];

        if(!!pnlElements?.data){
            data?.forEach((element) => {
                const currentElementName = element?.fieldName;
                // @ts-ignore
                for (let i = 0; i < data?.length; i++) {
                    // @ts-ignore
                    const pnlElement = pnlElements?.data[i];
                    if (pnlElement?.expression?.some((expression) => expression?.operand === currentElementName
                    && currentElementName !== pnlElement?.fieldName || currentElementName === 'contributionProfit')) {
                        // If the condition is true, skip the current iteration of the inner loop
                        return
                    }
                }
                //if(!element?.isDefault){

                allUnusedPnlElements?.push(element)
                //}
            });
        }

    const handleOpenTransactionFees = () => {
            dispatch(openSecondaryExplore({isOpen: true, ExploresEnum: ExploresEnum.TRANSACTION_FEES}))
    }

        let newData = data?.map((item, i) => {
            if(item?.fieldName === 'grossRevenue'){
                return
            }
            if((allUnusedPnlElements?.some((unusedPnl) => unusedPnl?.fieldName === item?.fieldName))){
                const isTransactionFees = item?.fieldName === 'transactionFees';
                const isCogs = item?.fieldName === 'totalCogs';
                return {
                    fieldName: 'bank',
                    text: item?.displayName ?? convertCamelCaseToString(item?.fieldName),
                    type: ItemTypes.CARD,
                    moveCard: () => {},
                    isDefault: item?.isDefault,
                    id: item?.fieldName,
                    cardId: item?.id,
                    callback: () => isTransactionFees ? handleOpenTransactionFees() : isCogs ? dispatch(openSecondaryExplore({ExploresEnum: ExploresEnum.COGS, isOpen: true})) : {}
                }
            }
            return item?.expression?.map((subItem, subItemIndex) => {
                const foundPnlItem = pnlElements?.data?.find((pnlElement) => subItem?.operand === pnlElement?.fieldName);
                    if(foundPnlItem?.isDisplayable !== undefined && !foundPnlItem?.isDisplayable){
                        return
                    }
                    if(!subItem?.isOperator){
                        const isTransactionFees = subItem?.operand === 'transactionFees';
                        const isCogs = subItem?.operand === 'totalCogs';
                        console.log(foundPnlItem?.displayName, subItem?.operand)
                        const isMarketing = subItem?.operand === 'digitalMarketingSpend';
                       /* const isTransactionFees = subItem?.operand === 'transactionFees';*/
                        return {
                            // add index at the end
                            fieldName: item?.fieldName,
                            text: foundPnlItem?.displayName ?? convertCamelCaseToString(subItem?.operand),
                            type: ItemTypes.CARD,
                            moveCard: () => {},
                            id: subItem?.operand,
                            isDefault: foundPnlItem?.isDefault,
                            cardId: foundPnlItem?.id,
                            callback: () => isTransactionFees ? handleOpenTransactionFees() : isCogs ?
                                dispatch(openSecondaryExplore({ExploresEnum: ExploresEnum.COGS, isOpen: true})) :
                                {}

                        }
                    }
                    else{
                        return
                    }
                }
            ).filter((subItem) => !!subItem)
        }).flat()

        const foundDigitalMarketingMetric = pnlElements?.data?.find((metric) => metric?.fieldName === 'digitalMarketingSpend');
        const digitalMarketingMetricDataFixed = {
            fieldName: 'contributionProfit',
            text: foundDigitalMarketingMetric?.displayName,
            type: ItemTypes.CARD,
            isDefault: true,
            moveCard: () => {},
            callback: () => {
                dispatch(openSecondaryExplore({ExploresEnum: ExploresEnum.MARKETING_MAPPING, isOpen: true}))
            },
            id: 'contributionProfit',
            cardId: foundDigitalMarketingMetric?.id
        }
        // @ts-ignore
        newData?.push(digitalMarketingMetricDataFixed)

        const fixedData = newData.map((item, i) => {
            return {...item, index: i}
        })

        return fixedData
    }


    // TFM - Transaction Fee Manager

    // Get the payments-method
    useEffect(() => {
        if (organization?.id) {
            dispatch(fetchPayments({ organizationId: organization?.id }));
        }
    }, [organization?.id]);

    const handleOnApply = () => {
        if(!!organization?.id){
            dispatch(applyDraftPnls({organizationId: organization?.id}))
        }
    }

    const handleOnDiscard = () => {
        dispatch(openApplyBar({isApplyBarOpen: false}));
        dispatch(openApplyModal({isApplyModalOpen: false}));
        if(!!organization?.id){
            dispatch(discardDraftPnl({organizationId: organization?.id}))
        }
        dispatch(openDefaultExploreView({isOpen: false}))
    }

    const handleFindAllChangesInBucket = (bucketName: string, initCards: CardProps[], cards: CardProps[]) : CardProps[] => {
        const allCardsCurrentlyInBucket = cards?.filter((card) => card?.fieldName === bucketName);
        const allCardsInitiallyInBucket = initCards?.filter((card) => card?.fieldName === bucketName);
        // from current cards list find if for this bucket theres a change
        const allNewCards = allCardsCurrentlyInBucket?.filter((card) => !(allCardsInitiallyInBucket?.some((initCard) => initCard.text === card.text)));
        return allNewCards
    }


    // helpers
    const openPnlLanguageMoreInfoDialog = (isOpen: boolean) => setIsPnlMoreInfoDialogOpen(isOpen);

    // renders
    const renderHeader = () => {
        return <styles.Header>
            <styles.Info>
                <styles.HeaderIcon>
                    <span style={{ width: '50px', height: '50px', overflow: 'hidden' }}>
                        <IconCOGS />
                    </span>
                </styles.HeaderIcon>
                <styles.TitleWrapper>
                    <styles.Title>
                        {t('PNL_SETTINGS', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => openPnlLanguageMoreInfoDialog(true)} />
                    </styles.Title>
                    <styles.SubTitle>
                        {t('DRAG_METRICS_YOU_WANT_TO_INCLUDE_IN_THE_PNL_ETC', { ns: 'translation' })}
                    </styles.SubTitle>
                </styles.TitleWrapper>
            </styles.Info>
            <styles.HeaderIcon>
                <span style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
                    <button onClick={() => {
                        if (isApplyBarOpen) {
                            dispatch(openApplyModal({ isApplyModalOpen: true }));
                        }
                        else {
                            dispatch(openDefaultExploreView({ isOpen: false }));
                        }
                    }}>
                        <IconCloseOverlay />
                    </button>
                </span>
            </styles.HeaderIcon>
        </styles.Header>;
    };


    const moveCard = (dragIndex: number, hoverIndex: number) => {
        console.log(`Moving card from index ${dragIndex} to ${hoverIndex}`);
    };


    const handleCreatePnlElement = (pnlElement: any) => {

        const pnlDtoToCreate: Metric = {
            fieldName: pnlElement?.fieldName,
            isDefault: false,
            displayName: pnlElement?.displayName,
            isPercentage: pnlElement?.isPercentage,
            expression: pnlElement?.expression,
            channels: pnlElement?.channels,
            frequency: pnlElement?.frequency,
            isDisplayable: true,
            fixedAmount: pnlElement?.fixedAmount,
            startDate: pnlElement?.startDate,
            endDate: pnlElement?.endDate,
        };

        if(!!organization?.id){
            dispatch(createPnl({organizationId: organization?.id, dto: pnlDtoToCreate}))
        }
    }


    const renderActiveContainers = () => {
        return (
            <Flex flexDirection={'column'}>
                <Container setMetricIdToEdit={setMetricIdToEdit} id={'netRevenue'} name={'Gross Revenue'} subTitle={'(Deductions)'} accepts={[ItemTypes.CARD, ItemTypes.GROSS_REVENUE]} cards={cards?.filter((card) => card?.fieldName === 'netRevenue')} moveCard={moveCard} setCards={setCards} moreInfoOnClick={() => {openPnlLanguageMoreInfoDialog(true)}} />
                <Container setMetricIdToEdit={setMetricIdToEdit} id={'grossProfit'} name={'Net Revenue'} subTitle={'(COGS)'}  accepts={[ItemTypes.CARD, ItemTypes.GROSS_REVENUE, ItemTypes.NET_REVENUE]} cards={cards} moveCard={moveCard} setCards={setCards} moreInfoOnClick={() => {openPnlLanguageMoreInfoDialog(true)}} />
                <Container setMetricIdToEdit={setMetricIdToEdit} id={"contributionProfit"} name={'Gross Profit'} subTitle={'(Marketing&Others)'} accepts={[ItemTypes.CARD, ItemTypes.GROSS_REVENUE, ItemTypes.NET_REVENUE, ItemTypes.GROSS_PROFIT]} cards={cards} moveCard={moveCard} setCards={setCards} moreInfoOnClick={() => {openPnlLanguageMoreInfoDialog(true)}} />
                <Container setMetricIdToEdit={setMetricIdToEdit} id={'null'} name={'Contribution Profit'}
                           accepts={[]}
                           cards={[]}
                           moveCard={moveCard}
                           setCards={setCards}
                           hiddenContainer={true}
                           moreInfoOnClick={() => {openPnlLanguageMoreInfoDialog(true)}} />
            </Flex>
        )
    }
    const renderBankContainer = () => {

        const container = () => {
            return (
                <>
            <Container setMetricIdToEdit={setMetricIdToEdit} bankContainer={true} id={'bank'} accepts={[ItemTypes.CARD, ItemTypes.GROSS_REVENUE, ItemTypes.NET_REVENUE, ItemTypes.GROSS_PROFIT]}
                       cards={cards?.filter((card) => card?.fieldName === 'bank')} moveCard={moveCard} setCards={setCards} moreInfoOnClick={() => {openPnlLanguageMoreInfoDialog(true)}} />
            <styles.AddCustom onClick={() => setAddExpenseOpen(true)}>
                <IoMdAddCircleOutline size={"24px"} color={theme.primaryColor} />
                <div style={{ width: "20px" }} />
                {t("ADD_CUSTOM", { ns: 'translation' })}
            </styles.AddCustom>
                </>
            )
        }

        return (
            <Flex flexDirection={'column'}>
                <styles.BankTitle>
                    Add Expenses
                </styles.BankTitle>
                {isAddExpenseOpen  ?
                    <AddCustomExpense
                        onEditApply={editMetric}
                        metricIdToEdit={metricIdToEdit}
                        onApply={(newCog) => {
                            handleCreatePnlElement(newCog)
                            setAddExpenseOpen(false)}
                    }
                        onClose={() => {
                            setAddExpenseOpen(false);
                        }}
                        channels={channelForTransactionFee}/> : null}
                {!isAddExpenseOpen && container()}
            </Flex>
        )
    }

    const renderContainers = () => {
        return (
            <DndProvider backend={HTML5Backend}>
                <styles.Content>
                    <styles.ActiveList>
                        {renderActiveContainers()}
                    </styles.ActiveList>
                    <styles.BankList>
                        {renderBankContainer()}
                    </styles.BankList>
                </styles.Content>
            </DndProvider>
        )
    }

    return (
        <styles.Wrapper>
            <ApplyChangesModal
                onApply={() => {handleOnApply()}}
                isOpen={isApplyModalOpen}
                onOpen={onOpen}
                onClose={() => {handleOnDiscard()}} />
            {renderHeader()}
            {isApplyBarOpen ? <ApplyChangesBar onClose={() => dispatch(cleanUpPnlLoaders())}
                 finishedLoading={isFinishedLoading} onApplyChanges={() => {
                handleOnApply()}}
                title={"PNL_SETTING_UPDATED"} /> : <></>}
            <MoreInfoDialog
                props={pnlSettingMoreInfoDialogProps}
                isDialogOpen={isPnlMoreInfoDialogOpen}
                onClose={() => setIsPnlMoreInfoDialogOpen(false)}
            />

            {renderContainers()}
        </styles.Wrapper>
    );
};

export default PnlSettingOverlayNew;
import { Integration } from "@models";

// state
export interface IntegrationsState {
  integrations?: Integration[];
  loading: boolean;
  integrationsLoader: boolean;
}

// initial state
export const initalIntegrationsState: IntegrationsState = {
  integrations: undefined,
  loading: false,
  integrationsLoader: false,
};

import styles from "./styles";
import {Box, Checkbox, Flex, Spinner, Thead, Tooltip} from "@chakra-ui/react";
import {
    CellProps,
    Column,
    FooterProps,
    HeaderGroup,
    Row,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {IconClearFiltersArrow} from "../../utils/icons/explore";
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {DataDialog, DataDialogProps} from "../../dialogs/data-dialog/DataDialog";
import {useDispatch, useSelector} from "react-redux";
import {kpiDataSelector} from "../../store/kpis";
import {Kpi} from "../../models/kpi";
import {selectedChannelsSelector, setChannels} from "../../store/ui";
import {localStorageService} from "@services";
import {channelSelector} from "../../store/channels";
import {IntegrationType} from "@models";

export enum ExploreTableDataEnum {
    PERCENTAGE,
    CURRENCY,
    NUMBER
}

export enum ExploreTableFooterTypeEnum {
    GRAND_TOTAL,
    SUM,
    MEAN,
}

export interface TableColumn {
    header: string;
    accessor: string;
    // adding custom cell will remove any cell type
    cell?: (value?: any, row?: Row) => any;
    // adding custom footer will remove any footer type
    footer?: (value?: any, rows?: Row[]) => any;
    cellType?: ExploreTableDataEnum;
    footerType?: ExploreTableFooterTypeEnum;
    numberOfRowsToDisplay?: number;
}

interface Props {
    title?: string;
    data: any[];
    headers: TableColumn[];
    icon: any;
    setSelectedRows?: any;
    height?: number;
    tableHeight?: number;
    setShowAllProducts?: (e?: any) => void;
    defaultSortByKey?: string;
    comingSoon?: true;
    channelsTable?: true;
    totalUpdateState?: any;
    isInfiniteScroll?: boolean;

}

export const GenericExploreTable = (props: Props) => {
    // hooks
    const currentCurrency = useCurrencySign();
    const [selectedRows, setSelectedRows] = useState([] as Row<{ callbackId?: string }>[]);
    const [page, setPage] = useState(1);
    const data = useMemo(() => {
        return props.data;
    }, [props.data]);
    const [isDataDialogOpen, setIsDataDialogOpen] = useState(false);
    const dailyStatsSelector = useSelector(kpiDataSelector);
    const [dailyStatsData, setDailyStatsData] = useState<Kpi[]>([]);
    const [shadow, setShadow] = useState<boolean>(false);
    const allSelectedChannels = useSelector(selectedChannelsSelector);
    const allChannels = useSelector(channelSelector);
    const loader = useRef(null);
    const displayPerPage = 50;
    const tableRef = useRef<any>(null);

    useEffect(() => {

        const handleScroll = () => {
            const scrollTop = tableRef.current.scrollTop;

            if (scrollTop > 0) {
                setShadow(true);
            } else {
                setShadow(false);
            }
        };

        if (tableRef.current) {
            tableRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableRef.current) {
                tableRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }

        return () => {
            observer.disconnect();
        }
    }, [loader]);

    const handleObserver = (entities: any[]) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1)
        }
    };

    const dispatch = useDispatch();
    const dataDialogProps = {
        title: 'Daily Stats',
        subtitle: '',
        // description: 'Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.',
        // tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
        data: dailyStatsData
    } as DataDialogProps;

    useEffect(() => {
        if (!!props?.channelsTable && !!props?.setSelectedRows) {
            const fixedData = allSelectedChannels?.map((item) => {
                return {original: {callbackId: item}}
            });
            props?.setSelectedRows(fixedData);
        }
    }, [props?.channelsTable, allSelectedChannels, props?.setSelectedRows]);

    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (!!props?.data && !!allChannels && props?.data?.length > 0 && !!props?.channelsTable && !!allSelectedChannels && !flag && allSelectedChannels?.length > 0) {
            const fixedData = props?.data?.map((item, i) => {
                if (allSelectedChannels?.includes(item?.callbackId)) {
                    return {
                        id: i?.toString(),
                        index: i,
                        original: {callbackId: item?.callbackId, ...item},
                        isSelected: false,
                    };
                } else {
                    return
                }
            }).filter((item) => !!item);
            const allSalesChannelsForOrganization = allChannels?.filter((channel) => {
                return channel?.type === IntegrationType.STORES
            })
            if (allSelectedChannels?.length !== allSalesChannelsForOrganization?.length) {
                setSelectedRows(fixedData as unknown as Row[]);
            }

            setFlag(true)

        }
    }, [props?.data, props?.channelsTable, allSelectedChannels, flag, allChannels]);

    useEffect(() => {
        setDailyStatsData(!!dailyStatsSelector.data ? dailyStatsSelector.data.data : [])
    }, [dailyStatsSelector.data])
    const openLearnMorePopup = () => {
        setIsDataDialogOpen(true)
    }

    const renderCurrencyCell = (value: number | string | ReactJSXElement) => {
        if (typeof value === "string") {
            if (value?.length === 0) {
                return "";
            }
            return currentCurrency + value;
        }
        if (typeof value === "number") {
            return (
                currentCurrency +
                value?.toLocaleString("en", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                })
            );
        } else {
            return value;
        }
    };

    const renderPercentageCell = (value: number | string | ReactJSXElement) => {
        if (typeof value === "string") {
            if (value?.length === 0) {
                return "";
            }
            return value + "%";
        }
        if (typeof value === "number") {
            return (
                value?.toLocaleString("en", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                }) + "%"
            );
        } else {
            return value;
        }
    };

    const renderSumOfColumn = (
        cell: React.PropsWithChildren<FooterProps<{}>>
    ) => {
        let totalColumnValue = 0;

        const currentColumnId = cell?.column?.id;
        let data = cell?.data;
        if (selectedRows?.length > 0) {
            const allSelectedChannels = selectedRows?.map((row) => row?.original?.callbackId);
            data = data?.filter((item: any) => allSelectedChannels?.includes(item?.callbackId))
        } else {
            data = cell?.data
        }
        data.forEach((item: any) => {
            if (
                !!currentColumnId &&
                currentColumnId in item &&
                typeof item[currentColumnId] === "number"
            ) {
                totalColumnValue += item[currentColumnId];
            }
        });

        if (currentColumnId === 'shareOfTotal') {
            return Math.round(totalColumnValue)?.toLocaleString("en", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
            });
        }


        return totalColumnValue?.toLocaleString("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        });
    };

    const renderMeanOfColumn = (
        cell: React.PropsWithChildren<FooterProps<{}>>
    ) => {
        let totalColumnValue = 0;
        const currentColumnId = cell?.column?.id;
        let data = cell?.data;
        if (selectedRows?.length > 0) {
            data = selectedRows?.map((row) => row?.original)
        } else {
            data = cell?.data
        }
        let lengthOfRows = data.length;

        data.forEach((item: any) => {
            if (
                !!currentColumnId &&
                currentColumnId in item &&
                typeof item[currentColumnId] === "number" &&
                !(isNaN(item[currentColumnId]))
            ) {
                totalColumnValue += item[currentColumnId];
            }
        });
        if (!lengthOfRows) {
            return 0;
        }
        return (totalColumnValue / lengthOfRows)?.toLocaleString("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        });
    };

    const renderCell = (
        cell: React.PropsWithChildren<CellProps<{}, any>>,
        columnProps?: TableColumn
    ) => {
        let returnedValue: string | ReactJSXElement | number = cell?.value ?? "";

        if (!!columnProps?.cell) {
            returnedValue = columnProps?.cell(cell?.value, cell?.row);
        }
        if (columnProps?.cellType === ExploreTableDataEnum.CURRENCY) {
            returnedValue = renderCurrencyCell(returnedValue);
        }
        if (columnProps?.cellType === ExploreTableDataEnum.PERCENTAGE) {
            returnedValue = renderPercentageCell(returnedValue);
        }
        if (columnProps?.numberOfRowsToDisplay) {
            return (
                <Tooltip label={returnedValue}>
                    <styles.TdDiv numberOfLinesToShow={columnProps?.numberOfRowsToDisplay}>
                        {returnedValue}
                    </styles.TdDiv>
                </Tooltip>
            )
        }
        return returnedValue;
    };

    const renderFooterCell = (
        cell: React.PropsWithChildren<FooterProps<{}>>,
        columnProps?: TableColumn
    ) => {
        let returnedValue: string | ReactJSXElement | number = cell?.value ?? "";

        if (columnProps?.footerType === ExploreTableFooterTypeEnum.GRAND_TOTAL) {
            returnedValue = <styles.GrandTotalDiv>
                <styles.GrandTotalContent> Grand Total</styles.GrandTotalContent>
            </styles.GrandTotalDiv>;
        }
        if (columnProps?.footerType === ExploreTableFooterTypeEnum.SUM) {
            returnedValue = renderSumOfColumn(cell);
        }
        if (columnProps?.footerType === ExploreTableFooterTypeEnum.MEAN) {
            returnedValue = renderMeanOfColumn(cell);
        }
        if (columnProps?.cellType === ExploreTableDataEnum.CURRENCY) {
            returnedValue = renderCurrencyCell(returnedValue);
        }
        if (columnProps?.cellType === ExploreTableDataEnum.PERCENTAGE) {
            returnedValue = renderPercentageCell(returnedValue);
        }
        if (columnProps?.footer) {
            let rowsToReturn = cell?.rows;
            if (selectedRows?.length > 0) {
                rowsToReturn = selectedRows;
            } else {
                rowsToReturn = cell?.rows;
            }
            returnedValue = columnProps?.footer(cell?.value, rowsToReturn);
        }

        return returnedValue;
    };

    const headersToColumns = (headers: TableColumn[]): Column[] => {
        const columns: Column[] = headers?.map((column) => {
            return {
                Header: column?.header,
                accessor: column?.accessor,
                /*sortType: (rowA: any, rowB:any) => {
                  if(!!props?.defaultSortByKey){
                    if (rowA.original[props?.defaultSortByKey] > rowB.original[props?.defaultSortByKey]) return 1;
                    if (rowB.original[props?.defaultSortByKey] > rowA.original[props?.defaultSortByKey]) return -1;
                  }
                  if (rowA.original?.shareOfTotal > rowB.original?.shareOfTotal) return 1;
                  if (rowB.original?.shareOfTotal > rowA.original?.shareOfTotal) return -1;
                  else return 0
                },*/
                Cell: (cell) => renderCell(cell, column),
                Footer: (cell) => renderFooterCell(cell, column),
            };
        });
        return columns;
    };

    const columns = useMemo<Column[]>(
        () => headersToColumns(props?.headers),
        [props?.headers, props?.totalUpdateState, selectedRows, props?.data]
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns, data, initialState: {
            sortBy: [
                {
                    id: props?.defaultSortByKey ?? "",
                    desc: true
                }
            ]
        }
    }, useSortBy, useRowSelect);

    // helpers

    const selectedRow = (row: Row<{ callbackId?: string }>, allRows: Row<{ callbackId?: string }>[]) => {
        row.toggleRowSelected();
        let isRowSelected = selectedRows.some(
            (selectedRow) => selectedRow.id === row.id
        );

        if (!!isRowSelected) {
            setSelectedRows([
                ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
            ]);
            if (!!props?.setSelectedRows) {
                props?.setSelectedRows([
                    ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
                ]);
            }
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);

            setSelectedRows(newSelected);
            if (!!props?.setSelectedRows) {
                props?.setSelectedRows(newSelected);
            }
            /* if(!!props?.defaultSelectedRows){
               dispatch(setChannels({channels: newSelected?.map((item: Row<{callbackId?: string}>) => item?.original?.callbackId)}))
             }*/
        }
    };

    // renderers

    const clearFilters = () => {
        rows?.forEach((row) => {
            if (row.isSelected) {
                row.toggleRowSelected();
            }
            if (!!props?.setSelectedRows) {
                props?.setSelectedRows([]);
            }
        });
    };

    const renderClearFiltersButton = (placeholder?: boolean) => {
        return (
            <Tooltip label={'Reset'}>
                <styles.ClearFiltersButtonWrapper
                    style={{opacity: placeholder ? 0 : 1}}
                    onClick={() => {
                        setSelectedRows([] as Row[]);
                        clearFilters();
                    }}
                >
                    <IconClearFiltersArrow/>
                </styles.ClearFiltersButtonWrapper>
            </Tooltip>
        );
    };

    const showAllProductsButton = () => {
        return (
            !!props?.setShowAllProducts && (
                <Flex fontSize={"12px"} alignItems={"center"}>
                    <Box w={"max-content"}>Show all</Box>
                    <Box w={2}/>
                    <Checkbox
                        colorScheme={"purple"}
                        onChange={(e) => {
                            !!props?.setShowAllProducts &&
                            props?.setShowAllProducts(e?.target?.checked);
                        }}
                    />
                </Flex>
            )
        );
    };

    const renderLearnMoreButton = () => {
        return (
            <Flex fontSize={"12px"} alignItems={"center"}>
                {/*<styles.LearnMoreButton onClick={openLearnMorePopup}>Learn More</styles.LearnMoreButton>*/}
                <DataDialog
                    props={dataDialogProps}
                    isDialogOpen={isDataDialogOpen}
                    onClose={() => setIsDataDialogOpen(false)}
                />
            </Flex>
        )
    }

    const cardHeader = () => {
        return (
            <styles.Header>
                <styles.Title>
                    <span onClick={openLearnMorePopup} style={{marginRight: "5px"}}>{props.icon}</span>
                    {props.title}
                </styles.Title>
                {renderLearnMoreButton()}

                {selectedRows?.length > 0
                    ? renderClearFiltersButton()
                    : renderClearFiltersButton(true)}
                {showAllProductsButton()}
            </styles.Header>
        );
    };

    const tableHeader = (headerGroups: HeaderGroup[]) => {

        return (
            <Thead>
                {headerGroups.map((headerGroup) => (
                    <styles.HeaderTr
                        {...{selected: true, ...headerGroup.getHeaderGroupProps()}}
                    >
                        {headerGroup.headers.map((column, i) => {
                            if (column?.id === "callbackId") {
                                return <></>;
                            }
                            return (
                                <styles.StyledTh
                                    shadow={shadow}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    <Box onClick={() => {
                                        if (props?.isInfiniteScroll) {
                                            if (!!tableRef) {
                                                tableRef.current.scrollTop = 0;
                                            }
                                            setPage(1);
                                            setSelectedRows([] as Row[]);
                                            clearFilters();
                                        }
                                    }} display={"flex"} alignItems={"center"}>
                                        {column.render("Header")}
                                        <span>
                      {column.isSorted ? (
                          !column.isSortedDesc ? (
                              <GoTriangleUp/>
                          ) : (
                              <GoTriangleDown/>
                          )
                      ) : (
                          ""
                      )}
                    </span>
                                    </Box>
                                </styles.StyledTh>
                            );
                        })}
                    </styles.HeaderTr>
                ))}
            </Thead>
        );
    };

    const tableBody = (rows: Row[]) => {

        return (
            <styles.TbodyCustom {...getTableBodyProps()}>
                {rows.map((row: Row<{ callbackId?: string }>, i: number) => {
                    if (i > page * displayPerPage) {
                        return
                    }
                    prepareRow(row);
                    return (
                        <styles.TrCustom
                            {...{
                                selected:
                                    selectedRows?.some(
                                        (selectedRow) => selectedRow?.original?.callbackId === row?.original?.callbackId
                                    ) || selectedRows.length === 0,
                                ...row.getRowProps(),
                            }}
                            highlightRow={selectedRows?.length > 0}
                            onClick={() => selectedRow(row, rows)}
                        >
                            {row.cells.map((cell) => {
                                if (cell?.column?.id === "callbackId") {
                                    return <></>;
                                }
                                return (
                                    <styles.TdCustom {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </styles.TdCustom>
                                );
                            })}
                        </styles.TrCustom>
                    );
                })}
            </styles.TbodyCustom>
        );
    };

    const tableFooter = (footerGroups: HeaderGroup[]) => {
        return (
            <tfoot>
            {footerGroups.map((group) => (
                <styles.FooterTr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => {
                        return (
                            <styles.FooterTd {...column.getFooterProps()}>
                                {column.render("Footer")}
                            </styles.FooterTd>
                        );
                    })}
                </styles.FooterTr>
            ))}
            </tfoot>
        );
    };

    return (
        <styles.Wrapper>
            <styles.Card height={props?.height}>

                {cardHeader()}
                <styles.TableWrapper ref={tableRef} tableHeight={props?.tableHeight}>
                    {!!props?.comingSoon &&
                        <Flex justifyContent={"center"} alignItems={'center'} borderRadius={'5px'} position={'absolute'}
                              width={'95%'} height={'85%'} zIndex={10}>
                            {/*Coming Soon*/}
                        </Flex>}
                    <styles.TableCustom comingSoon={props?.comingSoon} variant="simple" {...getTableProps()}>
                        {tableHeader(headerGroups)}
                        {tableBody(rows)}
                        {!!props?.isInfiniteScroll && <div ref={loader}>
                            <Flex justifyContent={'center'}>
                                {page * displayPerPage >= rows?.length ? <></> : <Spinner/>}
                            </Flex>
                        </div>}
                        {tableFooter(footerGroups)}
                    </styles.TableCustom>

                </styles.TableWrapper>
                <Box opacity={props?.comingSoon ? 0.2 : 1} marginTop={2} marginLeft={2}>
                    {rows?.length + " rows in total"}
                </Box>
            </styles.Card>
        </styles.Wrapper>
    );
};

import { User } from "./user";
import {FacebookAdsAccount, GoogleAdsAccount, Integration} from "./integration";
import {CurrencyCode, DataLanguage, Holidays, PendingInvitations} from "@store";
import {CogsType} from "@services";


export enum FeaturesEnum {
  OPERATION,
  WEBSITE,
  MARKETING
}

export interface Plan {
  features?: FeaturesEnum[];
  name?: string;
  users?: number;
  price?: number;
  isDefaultPlan?: boolean;
}


export interface Organization {
  id: string;
  name?: string;
  phone?: string;
  image?: any;
  organizationId?: number;
  users?: User[];
  customCogs?: string[];
  customCogsColumns?: CogsType[];
  dataLanguage?: string;
  pendingInvitations?: PendingInvitations[]; //list of emails
  loading?: boolean;
  holidays?: Holidays[];
  numberOfEmployees?: string;
  industry?: string;
  annualRevenue?: string;
  integrations?: Integration[];
  currency?: CurrencyCode;
  timezone?: number;
  plan?: Plan
  isOpenAccountsModal?: boolean;
  isLoadingAccountModal?: boolean;

  googleAdsAccounts?: {
    integration?: Integration;
    accounts?: GoogleAdsAccount[];
    object?: any;
  };
  facebookAdsAccounts?: {
    integration?: Integration;
    accounts?: FacebookAdsAccount[];
    object?: any;
  };
}

export const cpoConfig = [
    {
        "_id" : "64bfbb393c5e4d57c73a633f",
        "tableName" : "cpo",
        "rows" : [
            {
                "fieldName" : "orders",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Orders"
                    }
                ]
            },
            {
                "fieldName" : "CPO",
                "attribute" : [
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "digitalMarketingSpend",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Digital Marketing Spend"
                    },
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "GoogleAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "FacebookAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "AmazonAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "shareOfTotalRevenue",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Share of total"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "shareOfTotalDigitalSpend",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Share of total"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FacebookAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Facebook Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "GoogleAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Google Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "AmazonAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Amazon Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            }
        ],
        "columns" : [

        ],
    }

]
import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    //padding: 0px 18px;
  `;

  export const RowsNumberInTotal = styled.div`
    font-size: initial;
    justify-content: start;
    display: flex;
  `;

  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 10px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;
  export const Card = styled.div`
    /* background-color: white; */
    width: 100%;
    height: auto;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;

  export const AssignedKpisToggle = styled.div`
    width: 400px;
  `;

  export const buttonWrapper = styled.div`
    display: flex;
    /* font-size: 10px !important; */
  `;
  export const toggleHolder = styled.div`
    margin: 0 0 0 45px;
  `;

  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;
  export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `;

  export const TopBarWrapper = styled.div`
    width: 100%;
    display: flex;
  `;

  export const AssignedKpisContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: start;
    padding: 30px;
    margin: 15px;
    overflow-x: auto;
  `;

  export const DepartmentRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const Spacer = styled.div`
    height: 50px;
  `;
  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    margin: 50px 0 0 0;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;

  export const LoaderWrapper = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
  `;
}

export default styles;

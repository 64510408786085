import {styles} from './styles';
import {SessionIcon} from 'utils/icons/sessions-icon';
import {ConversionRate} from 'utils/icons/conversion-rate';
import {Conversion} from 'utils/icons/conversion';
import {FunnelGraph} from 'store/website';
import {useEffect, useState} from 'react';
import {Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

interface Props {
    data: FunnelGraph;
}

export const WebsiteEcommerceFunnel = (props: Props) => {
    //hooks

    const { t } = useTranslation(['translation', 'translation']);
    const [data, setData] = useState<FunnelGraph>(props.data);
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    //helpers
    const decimalFix = (number: number) => {
        return (Math.round(number * 100) / 100).toFixed(2);
    };
    const productHeight = (data.product / data.sessions * 100) < 10 ? (data.product / data.sessions * 100) +15  : (data.product / data.sessions * 100);
    const cartHeight = data.cart / data.sessions * 100 < 10 ? (data.cart / data.sessions * 100 ) + 15: data.cart / data.sessions * 100 ;
    const checkoutHeight = data.checkout / data.sessions * 100 < 10 ? (data.checkout / data.sessions * 100) +15 : data.checkout / data.sessions * 100;
    const transactionHeight = (data.transactions / data.sessions * 100) < 10 ? (data.transactions / data.sessions * 100) +15 : data.transactions / data.sessions * 100;
    //renderers

    const cardHeader = () => {
        return (
            <styles.CardHeader>
                <styles.CardItem>
                    {SessionIcon()}
                    <styles.CardTextHolder>
                        <styles.CardTitle>{t('SESSIONS', { ns: 'translation' })}</styles.CardTitle>
                        <styles.CardNumber>{data.sessions?.toLocaleString()}</styles.CardNumber>
                    </styles.CardTextHolder>
                </styles.CardItem>
                <styles.CardItem>
                    <styles.CardItem>
                        {ConversionRate()}
                        <styles.CardTextHolder>
                            <styles.CardTitle>{t('CONVERSION_RATE', { ns: 'translation' })}</styles.CardTitle>
                            <styles.CardNumber>{decimalFix(data.transactions / data.sessions * 100)}%</styles.CardNumber>
                        </styles.CardTextHolder>
                    </styles.CardItem>
                </styles.CardItem>
                <styles.CardItem>
                    <styles.CardItem>
                        {Conversion()}
                        <styles.CardTextHolder>
                            <styles.CardTitle>{t('CONVERSION', { ns: 'translation' })}</styles.CardTitle>
                            <styles.CardNumber>{data.transactions?.toLocaleString()}</styles.CardNumber>
                        </styles.CardTextHolder>
                    </styles.CardItem>
                </styles.CardItem>
            </styles.CardHeader>
        );
    };

    const cardData = () => {
        return (
            <styles.DataCardsHolder>
                <styles.DataCard>
                    <styles.DataCardTextHolder>
                        <styles.DataCardTitle>
                            {t('ALL_SESSIONS', { ns: 'translation' })}
                        </styles.DataCardTitle>
                        <styles.DataCardNumber>
                            {data.sessions?.toLocaleString()}
                        </styles.DataCardNumber>
                    </styles.DataCardTextHolder>
                </styles.DataCard>
                <styles.DataCard>
                    <styles.DataCardTextHolder>
                        <styles.DataCardTitle>
                            {t('PRODUCT', { ns: 'translation' })}
                        </styles.DataCardTitle>
                        <styles.DataNumberHolder>
                            <styles.DataNumberBlue>
                                {decimalFix(data.product / data.sessions * 100)}%
                            </styles.DataNumberBlue>
                            <styles.DataCardNumber>
                                {data.product?.toLocaleString()}
                            </styles.DataCardNumber>
                        </styles.DataNumberHolder>
                    </styles.DataCardTextHolder>
                </styles.DataCard>
                <styles.DataCard>
                    <styles.DataCardTextHolder>
                        <styles.DataCardTitle>
                            {t('ADD_TO_CART', { ns: 'translation' })}
                        </styles.DataCardTitle>
                        <styles.DataNumberHolder>
                            <styles.DataNumberBlue>
                                {decimalFix(data.cart / data.sessions * 100)}%
                            </styles.DataNumberBlue>
                            <styles.DataCardNumber>
                                {data.cart?.toLocaleString()}
                            </styles.DataCardNumber>
                        </styles.DataNumberHolder>
                    </styles.DataCardTextHolder>
                </styles.DataCard>
                <styles.DataCard>
                    <styles.DataCardTextHolder>
                        <styles.DataCardTitle>
                            {t('CHECKOUT', { ns: 'translation' })}
                        </styles.DataCardTitle>
                        <styles.DataNumberHolder>
                            <styles.DataNumberBlue>
                                {decimalFix(data.checkout / data.sessions * 100)}%
                            </styles.DataNumberBlue>
                            <styles.DataCardNumber>
                                {data.checkout?.toLocaleString()}
                            </styles.DataCardNumber>
                        </styles.DataNumberHolder>
                    </styles.DataCardTextHolder>
                </styles.DataCard>
                <styles.DataCard>
                    <styles.DataCardTextHolder>
                        <styles.DataCardTitle>
                            {t('TRANSCTIONS', { ns: 'translation' })}
                        </styles.DataCardTitle>
                        <styles.DataNumberHolder>
                            <styles.DataNumberBlue>
                                {decimalFix(data.transactions / data.sessions * 100)}%
                            </styles.DataNumberBlue>
                            <styles.DataCardNumber>
                                {data.transactions?.toLocaleString()}
                            </styles.DataCardNumber>
                        </styles.DataNumberHolder>
                    </styles.DataCardTextHolder>
                </styles.DataCard>
            </styles.DataCardsHolder>
        );
    };

    const bars = () => {
        return (
            <styles.BarHolders>
                <styles.BarHolder>
                    <Popover placement='right-start' trigger='hover'>
                        <PopoverTrigger>
                            <styles.Bar {...{ height: 100 }} />
                        </PopoverTrigger>
                        <PopoverContent w={160} maxH={75}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <styles.PopoverWrapper>
                                    <styles.PopoverDate>
                                        11 Feb, 2022
                                    </styles.PopoverDate>
                                    <styles.PopoverData>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumber>1.5%</styles.PopoverNumber>
                                            <styles.PopoverText>Target</styles.PopoverText>
                                        </styles.PopoverCell>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumberRight>1.3%</styles.PopoverNumberRight>
                                            <styles.PopoverTextRight>Benchmark</styles.PopoverTextRight>
                                        </styles.PopoverCell>
                                    </styles.PopoverData>

                                </styles.PopoverWrapper>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <styles.ArrowHolder {...{ height: 3 * (productHeight) }}>

                        <styles.Arrow >{decimalFix((data.product / data.sessions * 100))}%</styles.Arrow>
                    </styles.ArrowHolder>
                </styles.BarHolder>
                <styles.BarHolder>
                    <Popover placement='right-start' trigger='hover'>
                        <PopoverTrigger>
                            <styles.Bar {...{ height: productHeight }} />
                        </PopoverTrigger>
                        <PopoverContent w={160} maxH={75}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <styles.PopoverWrapper>
                                    <styles.PopoverDate>
                                        11 Feb, 2022
                                    </styles.PopoverDate>
                                    <styles.PopoverData>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumber>1.5%</styles.PopoverNumber>
                                            <styles.PopoverText>Target</styles.PopoverText>
                                        </styles.PopoverCell>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumberRight>1.3%</styles.PopoverNumberRight>
                                            <styles.PopoverTextRight>Benchmark</styles.PopoverTextRight>
                                        </styles.PopoverCell>
                                    </styles.PopoverData>

                                </styles.PopoverWrapper>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <styles.ArrowHolder {...{ height: 3 * (cartHeight) }}>
                        <styles.Arrow >{decimalFix((data.cart / data.product * 100))}%</styles.Arrow>
                    </styles.ArrowHolder>
                </styles.BarHolder>
                <styles.BarHolder>
                    <Popover placement='right-start' trigger='hover'>
                        <PopoverTrigger>
                            <styles.Bar {...{ height: cartHeight }} />
                        </PopoverTrigger>
                        <PopoverContent w={160} maxH={75}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <styles.PopoverWrapper>
                                    <styles.PopoverDate>
                                        11 Feb, 2022
                                    </styles.PopoverDate>
                                    <styles.PopoverData>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumber>1.5%</styles.PopoverNumber>
                                            <styles.PopoverText>Target</styles.PopoverText>
                                        </styles.PopoverCell>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumberRight>1.3%</styles.PopoverNumberRight>
                                            <styles.PopoverTextRight>Benchmark</styles.PopoverTextRight>
                                        </styles.PopoverCell>
                                    </styles.PopoverData>

                                </styles.PopoverWrapper>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <styles.ArrowHolder {...{ height: 3 * (checkoutHeight) }}>
                        <styles.Arrow >{decimalFix((data.checkout / data.cart * 100))}%</styles.Arrow>
                    </styles.ArrowHolder>
                </styles.BarHolder>
                <styles.BarHolder>
                    <Popover placement='right-start' trigger='hover'>
                        <PopoverTrigger>
                            <styles.Bar {...{ height: checkoutHeight }} />
                        </PopoverTrigger>
                        <PopoverContent w={160} maxH={75}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <styles.PopoverWrapper>
                                    <styles.PopoverDate>
                                        11 Feb, 2022
                                    </styles.PopoverDate>
                                    <styles.PopoverData>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumber>1.5%</styles.PopoverNumber>
                                            <styles.PopoverText>Target</styles.PopoverText>
                                        </styles.PopoverCell>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumberRight>1.3%</styles.PopoverNumberRight>
                                            <styles.PopoverTextRight>Benchmark</styles.PopoverTextRight>
                                        </styles.PopoverCell>
                                    </styles.PopoverData>

                                </styles.PopoverWrapper>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <styles.ArrowHolder {...{ height: 3 * (transactionHeight) }}>
                        <styles.Arrow >{decimalFix((data.transactions / data.checkout * 100))}%</styles.Arrow>
                    </styles.ArrowHolder>
                </styles.BarHolder>
                <styles.BarHolder>
                    <Popover placement='right-start' trigger='hover'>
                        <PopoverTrigger>
                            <styles.Bar {...{ height: transactionHeight }} />
                        </PopoverTrigger>
                        <PopoverContent w={160} maxH={75}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <styles.PopoverWrapper>
                                    <styles.PopoverDate>
                                        11 Feb, 2022
                                    </styles.PopoverDate>
                                    <styles.PopoverData>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumber>1.5%</styles.PopoverNumber>
                                            <styles.PopoverText>Target</styles.PopoverText>
                                        </styles.PopoverCell>
                                        <styles.PopoverCell>
                                            <styles.PopoverNumberRight>1.3%</styles.PopoverNumberRight>
                                            <styles.PopoverTextRight>Benchmark</styles.PopoverTextRight>
                                        </styles.PopoverCell>
                                    </styles.PopoverData>

                                </styles.PopoverWrapper>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </styles.BarHolder>
            </styles.BarHolders>
        );
    };

    return (<styles.Wrapper>
        <styles.Card>
            {cardHeader()}
        </styles.Card>
        <styles.TextCard>
            <styles.Text>
                {t('DRILL_DOWN_TO_THE_MAIN_DRIVERS_OF_THIS_FUNNEL_EXPLORE_THE_CONVERSION_RATE_VS_SESSIONS_BY_APPLICATIONS_AND_MILESTONES_FROM_WEBSITE_TO_CHECKOUT', { ns: 'translation' })}
            </styles.Text>
        </styles.TextCard>
        {cardData()}
        <styles.Spacer>
            <styles.SpacerCell />
            <styles.SpacerCell />
            <styles.SpacerCell />
            <styles.SpacerCell />
            <styles.SpacerCell />
        </styles.Spacer>
        {bars()}
        <styles.SpacerBottom>
            <styles.SpacerCellBottom >
                <styles.ArrowHolderFlipped {...{ height: 3 * (transactionHeight) }}>
                    <styles.Arrow >{decimalFix(100 - (data.product / data.sessions * 100))}%</styles.Arrow>
                </styles.ArrowHolderFlipped>
            </styles.SpacerCellBottom>
            <styles.SpacerCellBottom >
                <styles.ArrowHolderFlipped {...{ height: 3 * (transactionHeight) }}>
                    <styles.Arrow >{decimalFix(100 - (data.cart / data.product * 100))}%</styles.Arrow>
                </styles.ArrowHolderFlipped>
            </styles.SpacerCellBottom>
            <styles.SpacerCellBottom ><styles.ArrowHolderFlipped {...{ height: 3 * (transactionHeight) }}>
                <styles.Arrow >{decimalFix(100 - (data.checkout / data.cart * 100))}%</styles.Arrow>
            </styles.ArrowHolderFlipped></styles.SpacerCellBottom>
            <styles.SpacerCellBottom ><styles.ArrowHolderFlipped {...{ height: 3 * (transactionHeight) }}>
                <styles.Arrow >{decimalFix(100 - (data.transactions / data.checkout * 100))}%</styles.Arrow>
            </styles.ArrowHolderFlipped></styles.SpacerCellBottom>
            <styles.SpacerCellBottom ><styles.ArrowHolderFlipped {...{ height: 3 * (transactionHeight) }}>
                {/*<styles.Arrow >{decimalFix((data.transactions / data.checkout * 100))}%</styles.Arrow>*/}
            </styles.ArrowHolderFlipped></styles.SpacerCellBottom>
        </styles.SpacerBottom>
        <styles.FooterHolder>
            <styles.FooterCell>
                <styles.FooterCellWrapper>

                    <styles.FooterText>{t('DROP_OFF', { ns: 'translation' })}
                    </styles.FooterText>
                    <styles.FooterNumbers>
                        <styles.FooterNumber>{decimalFix(100 - (data.product / data.sessions * 100))}%</styles.FooterNumber>
                        <styles.FooterNumberText>{(data.sessions - data.product)?.toLocaleString()}</styles.FooterNumberText>
                    </styles.FooterNumbers>
                </styles.FooterCellWrapper>

            </styles.FooterCell>
            <styles.FooterCell>
                <styles.FooterCellWrapper>

                    <styles.FooterText>{t('DROP_OFF', { ns: 'translation' })}</styles.FooterText>
                    <styles.FooterNumbers>
                        <styles.FooterNumber>{decimalFix(100 - (data.cart / data.product * 100))}%</styles.FooterNumber>
                        <styles.FooterNumberText>{(data.product - data.cart)?.toLocaleString()}</styles.FooterNumberText>
                    </styles.FooterNumbers>
                </styles.FooterCellWrapper>
            </styles.FooterCell>
            <styles.FooterCell>
                <styles.FooterCellWrapper>

                    <styles.FooterText>{t('DROP_OFF', { ns: 'translation' })}</styles.FooterText>
                    <styles.FooterNumbers>
                        <styles.FooterNumber>{decimalFix(100 - (data.checkout / data.cart * 100))}%</styles.FooterNumber>
                        <styles.FooterNumberText>{(data.cart - data.checkout)?.toLocaleString()}</styles.FooterNumberText>
                    </styles.FooterNumbers>
                </styles.FooterCellWrapper>
            </styles.FooterCell>
            <styles.FooterCell>
                <styles.FooterCellWrapper>

                    <styles.FooterText>{t('DROP_OFF', { ns: 'translation' })}</styles.FooterText>
                    <styles.FooterNumbers>
                        <styles.FooterNumber>{decimalFix(100 - (data.transactions / data.checkout * 100))}%</styles.FooterNumber>
                        <styles.FooterNumberText>{(data.cart - data.transactions)?.toLocaleString()}</styles.FooterNumberText>
                    </styles.FooterNumbers>
                </styles.FooterCellWrapper>
            </styles.FooterCell>
            <styles.FooterCell>
                    {/*<styles.FooterCellWrapper>
                    <styles.FooterText>{t('DROP_OFF', { ns: 'translation' })}</styles.FooterText>
                    <styles.FooterNumbers>
                        <styles.FooterNumber>{decimalFix(100 - (data.product / data.sessions * 100))}%</styles.FooterNumber>
                        <styles.FooterNumberText>{data.sessions - data.product}</styles.FooterNumberText>
                    </styles.FooterNumbers>
                </styles.FooterCellWrapper>*/}
            </styles.FooterCell>
        </styles.FooterHolder>

    </styles.Wrapper>);
}


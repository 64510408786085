// state

import {
  CarrierEnum,
  PurchaseOrder,
} from "../../services/operations/operation.service";

export enum TransportationStatusEnum {
  APPROVED,
  CANCELED,
  DRAFT,
  IN_REVIEW,
  ON_HOLD,
  PENDING_ARRIVAL,
}

export interface LastMile {
  id?: string;
  deliveryStatus?: string;
  trackingNumber?: string;
  activityDate?: string;
  orderId?: string;
  courier?: string;
  displayWarehouseName?: string;
  totalAmount?: number;
  shipCountry?: string;
  tagList?: string[];
  shipCity?: string;
  billingTotalAmount?: number;
  BillingTotalAmount?: number;
  shippingFee?: number;
  extraChargeAmount1?: number;
  extraChargeDescription1?: string;
  extraChargeAmount2?: number;
  extraChargeDescription2?: string;
  estimatedDeliveryDate?: string;
  pickUpTime?: string;
  destinationCountryCode?: string;
  destinationCity?: string;
  arrivalTime?: string;
  totalTimeOfDelivery?: TotalTimeOfDelivery;
  notTheSameStatus?: boolean;
  totalTimeOrderToDelivery?: TotalTimeOfDelivery;
  isDapButPaidTax?: boolean;
  urlTracking?: string;
  urlOrder?: string;

  totalTimeOfDelievry?: {
    days?: number;
    hours?: number;
    minutes?: number;
  };

  dutiesFee: number;
  otherFee: number;
  taxesFee: number;
}

export interface TotalTimeOfDelivery {
  days?: number;
  hours?: number;
  minutes?: number;
}

export interface OperationData {
  id?: string;
  status?: TransportationStatusEnum | string;
  reference?: string;
  senderCountry?: string;
  approveDate?: string;
  quantity?: number;
  activityDate?: string;
  sku?: string;
  warehouseName?: string;
  batchNumber?: string;
  asnId?: string;
  relatedPo?: string;
  carrier?: CarrierEnum;
  statusCode?: TransportationStatusEnum ;
  eta?: string;
  createDate?: string;
  updateDate?: string;
  description?: string;
  customerReference?: string;
  totalAsnQuantity?: number;
  shippingCost?: number;
  warehouseReference?: string;
}

export interface InventoryData {
  id?: string;
  sku?: string;
  description?: string;
  upc?: string;
  productId?: number;
  qty?: number;
  qtyOnHand?: number;
  quarantineQty?: number;
  warehouseId?: number;
  warehouseName?: string;
  displayWarehouseName?: string;
  customsVal?: number;
  itemId?: string;
  totalAsnQuantity?: number;
  eta?: string;
  meanPurchasedPerDay30?: number;
  restockingAmount?: string;
  restockingEta?: string;
  warehouseCountry?: string;
  activityDate?: string;
}

export enum StatusPurchases {
  CANCELLED = "Cancelled",
  FULFILLED = "Fulfilled",
  INCOMPLETE = "Incomplete",
  PENDING_FULFILLMENT = "Pending Fulfillment",
  PENDING_APPROVAL = "Pending Approval",
}
export interface PurchasesStats {
  id?: string;
  orderId?: string;
  activityDate?: string;
  originalTransactionDate?: string;
  status?: string;
  incoterms?: string;
  source?: string;
  customerReference?: string;
  reference?: string;
  createDate?: string;
  updateDate?: string;
  displayWarehouseName?: string;
  shipmentDate?: string;
  courierId?: string | number;
  courier?: string;
  trackingNumber?: string;
  trackingStatus?: string;
  customerShippingOption?: string;
  orderType?: string;
  cancellationFee?: string;
  fulfilmentDate?: string;
  itemId?: string | number;
  upc?: string;
  sku?: string;
  productQuantity?: number;
  quantity?: number;
  productId?: number;
  description?: string;
  packagingType?: string;
  itemType?: string;
  customsVa?: number;
  customsCurrency?: string;
  shipCountry?: string;
  shipCity?: string;
  warehouseName?: string;
  warehouseId?: number | string;
  displayStatus?: string;
  statusCode?: StatusPurchases;
  url?: string;
}

export interface PurchaseStatsStatusesMetadata {
  incomplete: number;
  pendingApproval: number;
  pendingFulfillment: number;
  fulfilled: number;
  cancelled: number;
  returns: number;
}
export interface LastMileStatsStatusesMetadata {
  delivered: number;
  inTransit: number;
}

export interface TrackingEvent {
  date: string;
  time: string;
  typeCode: string;
  description: string;
  serviceArea: {
    code: string;
    description: string;
  }[];
}

export interface OperationState {
  data: {
    lastMileData?: LastMile[];
    transportationData?: OperationData[];
    inventoryData?: InventoryData[];
    purchaseStats?: PurchasesStats[];
    manufacturingData?: PurchaseOrder[];
    lastMileMetaData?: string[];
  };
  metadata: {
    purchaseStats?: PurchaseStatsStatusesMetadata;
    lastMileStats?: LastMileStatsStatusesMetadata;
  };
  filters?: string[];
  error?: any;
  loading?: boolean;
  success?: boolean;
  createPoLoader?: any;
  asnEditLoader?: boolean;

  transportationFiltersFlag?: boolean;
  isFilesUploading: boolean;

  filesUploadError: boolean;
  purchaseStatsLoading: boolean;
  lastMileLoading: boolean;
  modalTrackingEvents?: TrackingEvent[];
  modalLoader: boolean;
}

// initial state
export const initialOperationState: OperationState = {
  error: undefined,
  loading: false,
  data: {
    lastMileData: undefined,
    transportationData: undefined,
    inventoryData: undefined,
    purchaseStats: undefined,
    manufacturingData: undefined,
    lastMileMetaData: [],
  },
  metadata: {
    purchaseStats: {
      incomplete: 0,
      pendingApproval: 0,
      pendingFulfillment: 0,
      fulfilled: 0,
      cancelled: 0,
      returns: 0,
    },
    lastMileStats: {
      delivered: 0,
      inTransit: 0,
    },
  },
  purchaseStatsLoading: false,
  lastMileLoading: false,
  filters: undefined,
  success: false,
  createPoLoader: undefined,
  asnEditLoader: false,
  transportationFiltersFlag: false,
  isFilesUploading: false,
  filesUploadError: false,
  modalLoader: false,
};

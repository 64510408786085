/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Bottom,
    Card,
    Content,
    Divider,
    EmailInput,
    EmailInputWrapper,
    Form,
    FormFieldCheckbox,
    FormFieldContainer,
    FormFieldSelectDepartment,
    FormFieldSelectOption,
    FormFieldSelectPermissions,
    NextButton,
    PendingEmailText,
    PendingInvitationsItem,
    PendingInvitationsList,
    PendingText,
    PrimaryTitle,
    SkipButton,
    Text,
    Wrapper
} from "./styles";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser, organizationSelector } from "@store";
import { DepartmentsEnum, RolesEnum } from "@services";


const schema = yup.object().shape({
    email: yup.string().required().email(),
    permission: yup.string(),
    department: yup.string(),
    grantEditorPermission: yup.string()
});

export const FirstInvitation = () => {

    const permissionOptions = [
        "Editor",
        "Viewer"
    ];

    const departmentOptions = [
        "Company",
        "Marketing",
        "Finance",
        "Website"
    ];

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);

    // selectors

    // effects

    // helpers
    const skip = () => {
        navigate('/admin');
    };


    const getRoleQuery = (permission: string) => {
        switch (permission) {
            case ("Editor"):
                return RolesEnum.EDITOR;
            case ("Viewer"):
                return RolesEnum.VIEWER;
            default:
                return RolesEnum.VIEWER;

        }
    };
    const getDepartmentQuery = (department: string) => {
        switch (department) {
            case ("Company"):
                return DepartmentsEnum.COMPANY;
            case ("Marketing"):
                return DepartmentsEnum.MARKETING;
            case ("Finance"):
                return DepartmentsEnum.FINANCE;
            case ("Website"):
                return DepartmentsEnum.WEBSITE;
            default:
                return DepartmentsEnum.COMPANY;

        }
    };

    const submit = (data: any) => {
        dispatch(inviteUser({
            email: data?.email,
            organizationId: organization?.id!,
            role: getRoleQuery(data.permission),
            department: getDepartmentQuery(data.department)
        }));
    };

    // renders
    const renderInviteButton = () => {
        return <NextButton onClick={handleSubmit(submit)}>
            {t('INVITE', { ns: 'translation' })}
        </NextButton>;
    };

    const renderBottom = () => {
        return (
            <Bottom>
                <SkipButton onClick={() => {
                    skip();
                }}>
                    {t('SKIP', { ns: 'translation' })}
                </SkipButton>
                {renderInviteButton()}
            </Bottom>
        );
    };

    const renderForm = () => {
        return <Form onSubmit={handleSubmit(submit)}>
            <EmailInputWrapper>
                <EmailInput>
                    <input {...register('email')}
                        placeholder={t('ENTER_TEAM_USERNAME_EMAIL_ADDRESS', { ns: 'translation' })} />
                </EmailInput>
                <FormFieldSelectPermissions
                    {...register('permission')} placeholder={''}>
                    {
                        permissionOptions?.map((option, index) => {
                            return <FormFieldSelectOption key={index} style={{ width: "30%" }}>
                                {option}
                            </FormFieldSelectOption>;
                        })
                    }
                </FormFieldSelectPermissions>
            </EmailInputWrapper>
            Department (can be edited in profile settings):
            <FormFieldSelectDepartment
                {...register('department')} placeholder={''}>
                {
                    departmentOptions?.map((option, index) => {
                        return <FormFieldSelectOption key={index}>
                            {option}
                        </FormFieldSelectOption>;
                    })
                }
            </FormFieldSelectDepartment>
            <FormFieldContainer>

                {/*  <FormFieldCheckbox size={'lg'} colorScheme={'brand'}
                    {...register('grantEditorPermission')} placeholder={''}>
                    {`${t('GRANT_EDITOR_PERMISSIONS', { ns: 'translation' })}`}
                </FormFieldCheckbox> */}
            </FormFieldContainer>
        </Form>;
    };

    const renderPendingInvitations = () => {
        return <PendingInvitationsList>
            {organization?.pendingInvitations?.map((invitation, index) => {
                return <PendingInvitationsItem key={index}>
                    <PendingEmailText>{invitation.email}</PendingEmailText>
                    <PendingText>{'Pending Invitation'}</PendingText>
                </PendingInvitationsItem>;
            })}
            <Divider />
        </PendingInvitationsList>;
    };

    const renderHeader = () => {
        return <Text>
            <PrimaryTitle>
                {`${t('INVITE_YOUR_FIRST_TEAM_MEMBER', { ns: 'translation' })}`}
            </PrimaryTitle>
        </Text>;
    };

    const renderCard = () => {
        return (
            <Card>
                {organization?.pendingInvitations && organization?.pendingInvitations.length > 0 ? renderPendingInvitations() : renderHeader()}
                {renderForm()}
                {renderBottom()}
            </Card>
        );
    };

    return (
        <Wrapper img={process.env.PUBLIC_URL + '/assets/onboarding-background.png'}>
            <Content>
                {renderCard()}
            </Content>
        </Wrapper>
    );
};

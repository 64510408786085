/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    SignInCard,
    SignInContent,
    SignInForm,
    SignInFormButton,
    SignInFormInput,
    SignInFormOrText,
    SignInFormSubmitButton,
    SignInFormSubmitLoader,
    SignInFormTitle,
    SignInHeader,
    SignInShowPasswordButton,
    SignInSignInLink,
    SignInTitle,
    SignInWrapper, StyledButton,
    RecoverLink
} from "./styles";
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useNavigate} from "react-router";
import {authErrorSelector, authLoadingSelector, signIn, tokenSelector} from "@store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {AiOutlineEye} from "react-icons/ai";
import {WideViewIcon} from "utils/icons/wideview-icons";

const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(6),
});

export const SignIn = () => {

    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const errorMessage = useSelector(authErrorSelector);
    const {register, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordShow, setPasswordShow] = useState(false);

    // selectors
    const authLoading = useSelector(authLoadingSelector);


    // effects
    useEffect(() => {
        setValue('email', '');
        setValue('password', '');
    }, [setValue]);


    // helpers
    const submitForm = (data: any) => {
        dispatch(signIn({
            email: data?.email,
            password: data?.password
        }));
    };

    const navigateToSignUp = () => {
        navigate('/auth/sign-up');
    };
    const navigateToRecoverPassword = () => {
        navigate('/auth/recover-password');
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            console.log('d');
            handleSubmit(submitForm);


        }
    };

    // renders
    const renderSignInHeader = () => {
        return (
            <SignInHeader>
                <SignInTitle>
                    <WideViewIcon/>
                </SignInTitle>
            </SignInHeader>
        );
    };

    const renderSignInContent = () => {
        return (
            <SignInContent>
                <SignInCard>
                    {renderSignInForm()}
                    {/*{renderSignInSideImage()}*/}
                    {renderSignInSignInLink()}
                </SignInCard>
            </SignInContent>
        );
    };

    // const renderSignInSideImage = () => {
    //     return (
    //         <SignInImage>
    //         </SignInImage>
    //     );
    // }

    const renderSignInForm = () => {
        return (
            <SignInForm onSubmit={
                handleSubmit(submitForm)}>
                {renderSignInFormTitle()}
                <div style={{height: '20px'}}/>
                {renderSignInFormEmailInput()}
                {renderSignInFormPasswordInput()}
                {renderSignInFormSubmitButton()}
                {renderRecoverLink()}
                {errorMessage?.message}

                {/*  <SignInFormOrText>
                    {t('or', { ns: 'translation' })}
                </SignInFormOrText> */}
                {/* {renderSignInGoogleButton() //TODO .V2}
                {renderSignInSSOButton()} */}
                <div style={{height: '20px'}}/>
            </SignInForm>
        );
    };

    const renderSignInFormTitle = () => {
        return (
            <SignInFormTitle>
                {t('LOG_IN_TO_YOUR_ACCOUNT', {ns: 'translation'})}
            </SignInFormTitle>
        );
    };

    const renderSignInFormEmailInput = () => {
        return (
            <SignInFormInput>
                <input style={{backgroundColor: "unset", height: "25px"}} className={'no-border-input'} {...register('email')} placeholder={'Email'}/>
                <div style={{width: '20px', height: '20px'}}/>
            </SignInFormInput>
        );
    };

    const renderSignInFormPasswordInput = () => {
        return (
            <SignInFormInput>
                <input style={{backgroundColor: "unset", height: "25px"}}
                       className={'no-border-input'}
                       onKeyPress={(e) => {
                    handleKeyPress(e);
                }} {...register('password')} type={passwordShow ? "text" : "password"} placeholder={'Password'}/>
                <SignInShowPasswordButton onClick={() => setPasswordShow(prev => !prev)}>
                    <AiOutlineEye size='max-content' color="gray"/>
                </SignInShowPasswordButton>
            </SignInFormInput>
        );
    };

    const renderSignInFormSubmitButton = () => {
        return (
            !!authLoading
                ? <SignInFormSubmitLoader/>
                : <SignInFormSubmitButton>
                    <StyledButton type={"submit"} id={'submit'}>
                        {t('CONTINUTE', {ns: 'translation'})}
                    </StyledButton>
                </SignInFormSubmitButton>
        );
    };

    const renderSignInGoogleButton = () => {
        return (
            <SignInFormButton>
                <a onClick={() => console.log('Google!')} href={void (0)}>
                    <img src={'https://freesvg.org/img/1534129544.png'} alt={'Google'}/>
                    <div style={{width: '10px'}}/>
                    {t('CONTINUTE_WITH_GOOGLE', {ns: 'translation'})}
                </a>
            </SignInFormButton>
        );
    };

    const renderSignInSSOButton = () => {
        return (
            <SignInFormButton>
                <a onClick={() => console.log('SSO!')} href={void (0)}>
                    <img src={'https://www.pngall.com/wp-content/uploads/10/Lock-Silhouette-PNG-File.png'} alt={'SSO'}/>
                    <div style={{width: '10px'}}/>
                    {t('SSO_BUTTON', {ns: 'translation'})}
                </a>
            </SignInFormButton>
        );
    };

    const renderRecoverLink = () => {
        return (
            <RecoverLink onClick={() => {
                navigateToRecoverPassword();
            }}>
                {t('Forgot password?', {ns: 'translation'})}
            </RecoverLink>
        );
    };

    const renderSignInSignInLink = () => {
        return (
            <SignInSignInLink onClick={() => {
                navigateToSignUp();
            }}>
                {t('SIGN_UP_LINK', {ns: 'translation'})}
            </SignInSignInLink>
        );
    };

    return (
        <SignInWrapper img={process.env.PUBLIC_URL + '/assets/auth-background.png'}>
            {renderSignInHeader()}
            {renderSignInContent()}
        </SignInWrapper>
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {CogsData, CreateProductCogsType, productsService, UpdateCellType, UpdateProductCogsType} from "@services";
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import { Product } from "models/products";
import {disableApplyButton, fetchCustomCogsData} from "@store";
import {setGlobalLoading} from "../ui";
import {operationService} from "../../services/operations/operation.service";

export const fetchProducts = createAsyncThunk<
  any,
  {
    organizationId: string;
    page: number;
    size: number;
    query?: string;
    discardData?: boolean;
    infiniteScroll?: boolean;
    sort?: string[];
    channels?: string[]
  }
>(
  "products/fetchProducts",
  async ({ organizationId, page, size, query, discardData, sort, channels }, thunkAPI) => {
    if(!!organizationId){
      const response = await productsService.fetchProductsData({
        organizationId,
        page,
        size,
        query,
        sort,
        channels
      });
      return response.data;
    }
    return
  }
);


export const fetchProductsFieldsData = createAsyncThunk<
    any,
    { organizationId: string; }
    >(
    "products/fetchProductsFields",
    async ({ organizationId }, thunkAPI) => {
      if(!!organizationId){
        const response = await productsService.fetchProductsFields({organizationId,});
        return response.data;
      }
    }
);

export const uploadCsvProductFile = createAsyncThunk<
    any,
    { organizationId: string; data: File[] }
    >(
    "product/uploadFile",
    async (
        { organizationId, data }: { organizationId: string; data: File[] },
        thunkAPI
    ) => {
      const response = await productsService.uploadProductCsvFile(
          organizationId,
          data
      );
      return response.data;
    }
);

export const fetchProductsMetaData = createAsyncThunk<
  any,
  {
    organizationId: string;
  }
>(
  "products/fetchCogsMetaData",
  async ({ organizationId }, thunkAPI) => {
    const response = await productsService.fetchCogsMetaData({
      organizationId,
    });
    return response.data;
  }
);

export const updateCellProdut = createAsyncThunk<
    Product[],
    { organizationId: string; products: UpdateCellType[] }
    >("products/updateProducts", async ({ organizationId, products }, thunkAPI) => {
  try {
    const response = await productsService.updateCellProduct(
        organizationId,
        products
    );
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    console.log("Error", err);
    return thunkAPI.rejectWithValue("Error update products");
  }
});


export const updateProducts = createAsyncThunk<
  any[],
  { organizationId: string; products: any[], dataLanguageId: string }
>("products/updateProducts", async ({ organizationId, products, dataLanguageId }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading({isLoading: true}))

    const response = await productsService.updateProductsData(
      organizationId,
      products
    );
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      thunkAPI.dispatch(setGlobalLoading({isLoading: false}))
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    thunkAPI.dispatch(setGlobalLoading({isLoading: false}))

    console.log("Error", err);
    return thunkAPI.rejectWithValue("Error update products");
  }
});

export const createProductCogsCell = createAsyncThunk<
    any[],
    { organizationId: string; products: CreateProductCogsType[], dataLanguageId: string }
    >("products/createProducts", async ({ organizationId, products, dataLanguageId }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading({isLoading: true}))
    const response = await productsService.createProductCogs(
        organizationId,
        products
    );
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      thunkAPI.dispatch(fetchProducts({ organizationId, size: 20, page: 0, discardData: true }))
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId }))
      thunkAPI.dispatch(setGlobalLoading({isLoading: false}))
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    thunkAPI.dispatch(setGlobalLoading({isLoading: false}))

    console.log("Error", err);
    return thunkAPI.rejectWithValue("Error update products");
  }
});

export const updateProductCogsCell = createAsyncThunk<
    any[],
    { organizationId: string; products: UpdateProductCogsType[], dataLanguageId: string }
    >("products/updateProducts", async ({ organizationId, products, dataLanguageId }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading({isLoading: true}))

    const response = await productsService.updateProductCogs(
        organizationId,
        products
    );
    if (isHttpCodeSuccess(response.status) && !!response?.data) {

      thunkAPI.dispatch(fetchProducts({ organizationId, size: 20, page: 0, discardData: true }))
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId }))
      thunkAPI.dispatch(setGlobalLoading({isLoading: false}))
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}))

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    console.log("Error", err);
    thunkAPI.dispatch(setGlobalLoading({isLoading: false}))

    return thunkAPI.rejectWithValue("Error update products");
  }
});

export const addCogs = createAsyncThunk<
  Product[],
  { organizationId: string; cogs: CogsData, dataLanguageId: string }
>("products/addNewCogs", async ({ organizationId, cogs, dataLanguageId }, thunkAPI) => {
  try {
    const response = await productsService.addCogs(organizationId, cogs);
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      thunkAPI.dispatch(fetchProducts({ organizationId, size: 20, page: 0, discardData: true }));
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId }));
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}));

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    console.log("Error", err);
    return thunkAPI.rejectWithValue("Error update products");
  }
});
export const deleteCogs = createAsyncThunk<
  any,
  { organizationId: string; cogsName: string, dataLanguageId:string }
>("products/deleteCogs", async ({ organizationId, cogsName , dataLanguageId}, thunkAPI) => {
  try {
    const response = await productsService.deleteCogs(organizationId, cogsName);
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      thunkAPI.dispatch(fetchProducts({ organizationId, size: 20, page: 0 }));
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId }));
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}));

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return thunkAPI.rejectWithValue("Error update products");
  }
});
export const editCogs = createAsyncThunk<
  any,
  { organizationId: string; cogsName: string; cogs: CogsData, dataLanguageId:string }
>("products/editCogs", async ({ organizationId, cogsName, cogs, dataLanguageId }, thunkAPI) => {
  try {
    const response = await productsService.editCogs(
      organizationId,
      cogsName,
      cogs
    );
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      thunkAPI.dispatch(fetchProducts({ organizationId, size: 20, page: 0 }));
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId }));
      thunkAPI.dispatch(disableApplyButton({organizationId, dataLanguageId, display: true}));

      return response?.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    console.log("Error", err);
    return thunkAPI.rejectWithValue("Error update products");
  }
});

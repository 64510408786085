// const facebookAppId = '2972797199717142';
const facebookAppId = '764433687858105';

export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v13.0'
            });
            // auto authenticate with the api if already logged in with facebook
            window.FB.getLoginStatus(({authResponse}) => {
                // console.log(authResponse);
                resolve(null);
            });
        };

        // load facebook sdk script
        (function () {
            let fjs = document.getElementsByTagName('script')[0];
            if (document.getElementById('facebook-jssdk')) {
                return;
            }
            let js = document.createElement('script');
            js.id = 'facebook-jssdk';
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs?.parentNode?.insertBefore(js, fjs);
        }());
    });
}

/*export async function facebookAdsAuthenticate() {
    return new Promise<{
        authResponse?: {
            accessToken?: string;
            userID?: string;
            expiresIn?: number;
            signedRequest?: string;
            graphDomain?: string;
            data_access_expiration_time?: number;
        };
        status?: string;
    }>(resolve => {
        window.FB.getLoginStatus((responseStatus) => {
            console.log(responseStatus);
            window.FB.login((response => {
                resolve(response);
            }), {
                // scope: ''
                // scope: 'ads_management,pages_manage_ads,ads_read,business_management,read_insights'
                // scope: 'ads_read,read_insights,ads_management,business_management',
                scope: 'ads_read,business_management',
                auth_type: 'reauthenticate'
            })
        }, true);

    });*/

export async function facebookAdsAuthenticate() {
    return new Promise<{
        authResponse?: {
            accessToken?: string;
            userID?: string;
            expiresIn?: number;
            signedRequest?: string;
            graphDomain?: string;
            data_access_expiration_time?: number;
        };
        status?: string;
    }>(resolve => {
        window.FB.getLoginStatus((responseStatus) => {
            console.log(responseStatus);
            if (responseStatus.status === 'connected') {
                window.FB.logout(() => {
                    initiateLogin(resolve);
                });
            } else {
                initiateLogin(resolve);
            }
        }, true);
    });
}

function initiateLogin(resolve: (value?: any) => void) {
    window.FB.login((response => {
        resolve(response);
    }), {
        scope: 'ads_read,business_management',
        auth_type: 'reauthorize',
    });
}



export const facebookService = {
    initFacebookSdk,
    facebookAdsAuthenticate,
};



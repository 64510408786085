import styled from "styled-components";
import {PopoverBody} from "@chakra-ui/react";

export namespace styles {
    export const Wrapper = styled.div`
    width: 100%;
      height: 55px;

      display: flex;
    justify-content: flex-end;
      padding: 25px 0;
      border-radius: 0 0 20px 20px;
      background-color: white;
      overflow: visible;
  `;
    export const ToolTipRow = styled.div`
    display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    `
  export const EventName = styled.div`
  font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  `
  export const Date = styled.div`
  color: #000000;
    opacity: .3;
  `
    export const LeftButton = styled.button`
      width: 67px;
      background-color: #d3dcf3;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      font-family: ${props => props?.theme?.fonts?.roboto?.regular};
      color: #56627C;
      padding: 10px 5px;
      align-self: center;
      border-radius: 5px;
    `;
    export const CompWrapper = styled.div`
    width: 97%;
      display: flex;
      align-self: flex-start;
      flex-direction: column;
      
    `

  export const SubGanttWrapper = styled.div`
  max-height: 200px;
    overflow-y: auto;
  `
    export const GanttWrapper = styled.div`
    width: 100%;
    background-color: #CBC7E4;
      display: flex;
      align-items: center;
      border-radius: 20px;
      overflow: visible;
      height: 100%;
    `
  export const StyledPopoverBody = styled(PopoverBody)`
    display: flex;
    justify-content: center;
    align-items: center;
  `
  export const PopoverWrapper = styled.div`
  display: flex;
    flex-direction: column;
    width: 100%;
    
  `
  export const MonthGantt = styled.div`
    width: 100%;
    background-color: #CBC7E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    overflow: visible;
  `

  export const GanttItem = styled.div<{width: number}>`
    /*flex: 1 1 0;*/
    background-color: #1A0989;
    color: white;

    border-radius: 50%;
    min-height: 100%;
    width: ${props => `${props.width}px`};
    max-width: 30px;
    max-height: 30px;
    /*border-radius: 50%;*/
    transform: scale(1, 1);
/*transform: scale(1.1);*/
    height: ${props => `${props.width}px`};
    /*min-height: 100%;*/
    aspect-ratio: 1;
    position: relative;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.roboto.regular};
    /*border-radius: 50%;*/
    text-align: center;
    display: flex;
    justify-self: center;
    align-items: center;
  `
  export const GanttLabelsWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #CBC7E4;
    border-radius: 20px;
    /*display: flex;
    align-items: center;
    border-radius: 20px;
    overflow: visible;
    height: 100%;
    justify-content: space-between;*/
  `;
    export const GanttSubWrapper = styled.div<{width: number}>`
      width: ${props => props?.width+"px"};
      background-color: #CBC7E4;
      display: flex;
      border-radius: 20px;

      align-items: center;
      border-radius: 20px;
      overflow: visible;
      height: 100%;
      justify-content: space-between;
    `;

    export const GanttItemWrapper = styled.div`
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #1A0989;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75vw;
    `
  export const GanttItemWrapperPlaceholder = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /*background-color: #1A0989;*/
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75vw;
  `;

  export const EmptyGanttItem = styled.div<{width: number}>`
    color: white;

    border-radius: 50%;

    width: ${props => `${props.width}px`};
    max-width: 30px;
    max-height: 30px;
    /*border-radius: 50%;*/
    transform: scale(1, 1);
    /*transform: scale(1.1);*/
    height: ${props => `${props.width}px`};
    min-height: 100%;
    aspect-ratio: 1;
    position: relative;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.roboto.regular};
    /*border-radius: 50%;*/
    text-align: center;
    display: flex;
    justify-self: center;
    align-items: center;
  `
  export const GanttText = styled.div`
  width: 100%;
  `
  export const GanttItemPlaceholder = styled.div<{width: number}>`
    /*
    flex: 1 1 0;
    */
    width: ${props => `${props.width}px`};
    border-radius: 50%;
  `

}

export default styles;

import {createSlice} from "@reduxjs/toolkit";
import {AccountState, initalAccountState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {clearAccountState} from "./actions";
import {fetchAccountData} from "./thunks";

function buildCleanAccountStateReducer(
  builder: ActionReducerMapBuilder<AccountState>
) {
  builder.addCase(clearAccountState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildFetchAccountDataReducer(
  builder: ActionReducerMapBuilder<AccountState>
) {
  builder
    .addCase(fetchAccountData.pending, (state, action) => {
      state.data = undefined;
      return state;
    })
    .addCase(fetchAccountData.fulfilled, (state, action) => {
      state.data = action.payload;
      return state;
    })
    .addCase(fetchAccountData.rejected, (state, action) => {
      state.data = undefined;
      return state;
    });
}

export const accountSlice = createSlice({
  name: "account",
  initialState: initalAccountState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanAccountStateReducer(builder);

    // async
    buildFetchAccountDataReducer(builder);
  },
});

// reducer
export const accountReducer = accountSlice.reducer;

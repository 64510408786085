import {
    Badge,
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay, PopoverTrigger,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import {IntegrationPlatform, MarketingKpi} from "@models";
import {
    MarketingMappingSource,
    organizationDataLanguageMappingSelector,
    organizationDataLanguageSelector, organizationIntegrationsSelector,
    organizationSelector,
    Product,
} from "@store";
import React, {Fragment, useEffect, useState} from "react";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {Cell, Row, useExpanded, useTable} from "react-table";
import {Channel, channelsStateSelector} from "store/channels";
import {
    kpiMarketingMappingSelector,
    kpiMarketingSelector,
    MarketingMappingData,
} from "store/kpis";
import {productsDataSelector} from "store/products";
import {accountStateSelector, isDefaultExploreOpenSelector} from "store/ui";
import {
    IconAmazon,
    IconFacebook,
    IconGoogleAds,
    IconTiktok,
} from "utils/icons/platforms";
import {MarketingModal} from "./marketing-modal/MarketingModal";
import {styles} from "./styles";
import {linksIcon} from "../../../utils/icons/links";
import PremiumIcon from "../../premium-icon/PremiumIcon";
import PlatformChip from "../../platform-chip/platformChip";
import {getChannelNameById} from "../../../utils/get-channel-name/getChannelNameById";
import InformationWrapper from "../../InformationWrapper/InformationWrapper";

interface TableData {
    title?: string;
    salesChannel?: string[];
    products?: string[];
    url?: string[];
    type?: string;
    subRows?: TableData[];
    subRowsDictionary?: { [key: string]: TableData };
    id?: string;
    displayName?: string;
    integrationPlatform?: IntegrationPlatform
    accountId?: string;
    salesChannelsSet?: Set<string>;
    campaignId?: string;
}

const MarketingTable = () => {
    const [row, setRow] = useState<any>();
    const [products, setProducts] = useState<{ id: number; name: string[] }[]>(
        []
    );
    const [channels, setChannels] = useState<{ id: number; name: string[] }>({
        id: 0,
        name: [""],
    });
    const [fetchedProducts, setFetchedProducts] = useState([] as Product[]);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {
        isOpen: isUrlModalOpen,
        onOpen: onUrlModalOpen,
        onClose: onUrlModalClose,
    } = useDisclosure();
    const [flag, setFlag] = useState(false);
    const [data, setData] = useState<MarketingMappingData[]>([]);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const [dates, setDates] = useState<{ today: string; lastYear: string }>();
    const [urlModalData, setUrlModalData] = useState<{
        depth?: number;
        key?: string;
    }>({});
    const integrationsFromStore = useSelector(organizationIntegrationsSelector);

    const [channelsToAds, setChannelsToAds] = useState<any>();
    const accounts = useSelector(accountStateSelector);
    const marketingKpiSelector = useSelector(kpiMarketingMappingSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const channelsStore = useSelector(channelsStateSelector);
    const [allExpendedRows, setAllExpendedRows] = useState<{
        [key: string]: boolean;
    }>({});
    const isExploreOpen = useSelector(isDefaultExploreOpenSelector);

    // helpers
    const addSalesChannelsToTableData = (tableData: TableData, salesChannels: string[], override = false) => {
        //add to integration
        if (!tableData.salesChannelsSet || override) {
            tableData.salesChannelsSet = new Set<string>();
        }

        salesChannels?.forEach((salesChannel: any) => {
            tableData.salesChannelsSet?.add(salesChannel);
        })
    }

    const createSalesChannelsByIntegration = (nestedData: TableData[]) => {
        marketingMappingElements?.forEach((marketingMappingElement: any) => {
            const salesChannels = marketingMappingElement?.salesChannels;
            const accountId = marketingMappingElement?.accountId;
            const campaignId = marketingMappingElement?.campaignId;

            if (salesChannels?.length > 0) {
                nestedData?.forEach((integration: TableData) => {

                    if (!!accountId && accountId in integration?.subRowsDictionary!) {
                        const accountFromNestedData = integration?.subRowsDictionary![accountId];

                        if (!!campaignId && campaignId in accountFromNestedData?.subRowsDictionary!) {
                            const campaignFromNestedData = accountFromNestedData?.subRowsDictionary![campaignId];
                            addSalesChannelsToTableData(campaignFromNestedData, salesChannels, true);
                        }
                        addSalesChannelsToTableData(integration, salesChannels);
                        addSalesChannelsToTableData(accountFromNestedData, salesChannels);


                        // fill all the campaign under the account which does not have sales channels yet
                        // TODO - need to check it - because if there is more than 1 sales channel for
                        //  the account we need to find out which one is it
                        Object.keys(accountFromNestedData?.subRowsDictionary!).forEach((campaignKey: any) => {
                            const campaignFromNestedData = accountFromNestedData?.subRowsDictionary![campaignKey];
                            if (!campaignFromNestedData?.salesChannelsSet && (!campaignFromNestedData?.salesChannel || campaignFromNestedData?.salesChannel?.length === 0)) {
                                addSalesChannelsToTableData(campaignFromNestedData, salesChannels, true);
                            }
                        })

                    }

                })
            }
        })
        // setNestedData(nestedData)
        console.log(nestedData)
    }


    // useEffect(() => {
    //     createSalesChannelsByIntegration()
    // }, [nestedData]);

    useEffect(() => {
        setAllExpendedRows({});
    }, [isExploreOpen?.isDefaultExploreOpen]);

    useEffect(() => {
        if (!!marketingKpiSelector) {
            setData(marketingKpiSelector);
        }
    }, [marketingKpiSelector]);


    const organizationId = useSelector(organizationSelector);
    const productsData = useSelector(productsDataSelector);

    useEffect(() => {
        if (!!dataLanguage?.marketingMapping?.elements) {
            setChannelsToAds(dataLanguage?.marketingMapping?.elements);
        }
    }, [dataLanguage?.marketingMapping?.elements]);

    useEffect(() => {
        if (productsData !== undefined) {
            setFetchedProducts(productsData);
        }
    }, [productsData, dataLanguage?.marketingMapping?.elements]);

    // selectors

    const getPlatformToString = (number: string) => {
        switch (number) {
            case "FacebookAds":
                return "Facebook Ads";
            case "AmazonAds":
                return "Amazon Ads";
            case "GoogleAds":
                return "Google Ads";
        }
        return "Error";
    };

    const getIntegrationById = (integrationId: string) => {
        return integrationsFromStore?.find(
            (integration) => integration?.id === integrationId
        );
    };

    const createNestedData = (data: any[]) => {
        let fixedData: TableData[] = [];
        let integrationsDictionary = {};

        data?.forEach((adDataTable, i) => {

            //platform
            let foundIntegration = integrationsDictionary[adDataTable?.wvIntegrationId];
            if (!foundIntegration) {
                const integration = getIntegrationById(adDataTable?.wvIntegrationId);
                foundIntegration = {
                    title: integration?.name,
                    salesChannel: [],
                    products: [],
                    url: [],
                    type: "",
                    subRows: [],
                    subRowsDictionary: {},
                    data: [adDataTable.platform, adDataTable.wvAccountId],
                    platform: true,
                    id: adDataTable.wvIntegrationId,
                    integrationPlatform: integration?.platform
                } as TableData;
                integrationsDictionary[adDataTable?.wvIntegrationId] = foundIntegration;
            }

            //account
            let foundAccount: TableData = foundIntegration.subRowsDictionary[adDataTable.wvAccountId]
            if (!foundAccount) {
                foundAccount = {} as TableData;
                foundAccount = {
                    title: adDataTable?.wvAccountId,
                    salesChannel: [],
                    products: [],
                    url: [],
                    type: "",
                    subRows: [],
                    subRowsDictionary: {},
                    account: true,
                    data: [
                        getPlatformToString(adDataTable.platform),
                        adDataTable.wvAccountId,
                    ],
                    accountId: adDataTable.wvAccountId,
                    id: adDataTable.id,
                } as TableData;

                foundIntegration.subRowsDictionary[adDataTable.wvAccountId] = foundAccount;
            }

            //campaign
            let foundCampaign: TableData = foundAccount.subRowsDictionary![adDataTable.campaignId]

            if (!foundCampaign) {
                foundCampaign = {
                    title: adDataTable?.campaignId,
                    salesChannel: [],
                    products: [],
                    url: [],
                    type: "",
                    data: [
                        getPlatformToString(adDataTable.platform),
                        adDataTable.wvAccountId,
                        adDataTable.campaignId,
                    ],
                    subRows: [],
                    subRowsDictionary: {},
                    id: adDataTable.id,
                    displayName: adDataTable?.campaignName,
                    campaignId: adDataTable.campaignId,
                } as TableData;

                foundAccount.subRowsDictionary![adDataTable.campaignId] = foundCampaign;
            }
        });

        // convert the integrationsDictionary to an array
        Object.keys(integrationsDictionary).forEach((key) => {

            Object.keys(integrationsDictionary[key].subRowsDictionary).forEach((accountKey) => {
                Object.keys(integrationsDictionary[key].subRowsDictionary[accountKey].subRowsDictionary).forEach((campaignKey) => {
                    integrationsDictionary[key].subRowsDictionary[accountKey].subRows.push(integrationsDictionary[key].subRowsDictionary[accountKey].subRowsDictionary[campaignKey])
                })
                integrationsDictionary[key].subRows.push(integrationsDictionary[key].subRowsDictionary[accountKey])

            })
            fixedData.push(integrationsDictionary[key]);
        })

        createSalesChannelsByIntegration(fixedData)

        //sort the accounts and campaigns by name
        fixedData.forEach((integration: TableData) => {
            integration?.subRows?.sort((a, b) => {
                if(!a || !b) {
                    return 0;
                }
                if (a.title! < b?.title!) {
                    return -1;
                }
                if (a.title! > b.title!) {
                    return 1;
                }
                return 0;
            });
            integration?.subRows?.forEach((account: TableData) => {
                account?.subRows?.sort((a, b) => {
                    if(!a || !b) {
                        return 0;
                    }
                    if (a.title! < b?.title!) {
                        return -1;
                    }
                    if (a.title! > b.title!) {
                        return 1;
                    }
                    return 0;
                });
            });
        });
        // sort the integrations
        fixedData?.sort((a, b) => {
            if(!a || !b) {
                return 0;
            }
            if (a.title! < b?.title!) {
                return -1;
            }
            if (a.title! > b.title!) {
                return 1;
            }
            return 0;
        });

        console.log(fixedData)
        return fixedData;
    };

    const getSvg = (integrationPlatform: IntegrationPlatform) => {
        switch (integrationPlatform) {
            case IntegrationPlatform.FACEBOOK_ADS:
                return (
                    <styles.ImageWraper>
                        <IconFacebook/>
                    </styles.ImageWraper>
                );
            case IntegrationPlatform.GOOGLE_ADS:
                return (
                    <styles.ImageWraper>
                        <IconGoogleAds/>
                    </styles.ImageWraper>
                );
            case IntegrationPlatform.TIKTOK:
                return (
                    <styles.ImageWraper>
                        <IconTiktok/>
                    </styles.ImageWraper>
                );
            case IntegrationPlatform.AMAZON_ADS:
                return (
                    <styles.ImageWraper>
                        <IconAmazon/>
                    </styles.ImageWraper>
                );
        }
        return;
    };

    const getSalesChannelChips = (salesChannel: Channel) => {
        return <Flex m={'5px'}>
            <PlatformChip text={salesChannel?.displayName} platform={salesChannel?.platformType}/>
        </Flex>
    }

    const getThreeDotsChip = () => {
        return <Flex m={'5px'}>
            <styles.ThreeDotsWrapper>
                <styles.ThreeDots>
                    ...
                </styles.ThreeDots>
            </styles.ThreeDotsWrapper>
        </Flex>
    }

    const getSalesChannelsNameById = (id?: string) => {
        const currentChannel = channelsStore?.channels?.filter(
            (item) => item?.id === id
        );
        return currentChannel?.map((item: any) => item?.displayName);
    };

    const getSalesChannelsById = (id?: string) => {
        const currentChannel = channelsStore?.channels?.filter(
            (item) => item?.id === id
        );
        return currentChannel;
    };

    const marketingMappingElements = useSelector(
        organizationDataLanguageMappingSelector
    );

    const checkRow = (row: Row<TableData>) => {
        if (row?.original?.salesChannelsSet?.size && row?.original?.salesChannelsSet.size > 0){
            const chips = Array.from(row?.original?.salesChannelsSet).map((salesChannel: any) => {
                return getSalesChannelChips(getSalesChannelsById(salesChannel)[0])
            })

            // if chips length is larger than 3, we need to show only 3 and add a badge with three dots,
            // and we will show the reset in a tolltip
            if (chips.length > 3){
                const chipsToShow = chips.slice(0, 3)
                const dots = getThreeDotsChip()
                const tooltipText = Array.from(row?.original?.salesChannelsSet).map(salesChannel => {
                    return getSalesChannelsNameById(salesChannel)
                }).join(',');
                return <styles.SalesChannelsWrapper>
                    {chipsToShow}
                    <InformationWrapper
                        shortText={tooltipText}
                        text={tooltipText}
                        provided={true}
                        small={true}
                        width={300}
                        dontDisplayHeader={true}>
                        <PopoverTrigger>
                            {dots}
                        </PopoverTrigger>
                    </InformationWrapper></styles.SalesChannelsWrapper>
            } else{
                return <styles.SalesChannelsWrapper> {chips} </styles.SalesChannelsWrapper>
            }

        } else {
            return <></>
        }


    };

    const UrlModal = () => {
        let urls: any[] = [];
        if (urlModalData?.depth === 0) {
            const platformUrls = data?.filter(
                (item) => item?.platform === urlModalData?.key
            );
            const allUrls = platformUrls?.map((item) => {
                return item?.urls;
            });
            urls = allUrls;
        }
        if (urlModalData?.depth === 1) {
            const platformUrls = data?.filter(
                (item) => item?.wvAccountId === urlModalData?.key
            );
            const allUrls = platformUrls?.map((item) => {
                return item?.urls;
            });
            urls = allUrls;
        }
        if (urlModalData?.depth === 2) {
            const platformUrls = data?.filter(
                (item) => item?.campaignId === urlModalData?.key
            );
            const allUrls = platformUrls?.map((item) => {
                return item?.urls;
            });
        }
        let uniqueUrls = urls
            ?.filter((c, index) => {
                return urls?.indexOf(c) === index;
            })
            ?.flat()
            ?.filter((item) => !!item);

        const UrlRow = ({url}: { url?: string }) => {
            return (
                <styles.UrlRow>
                    <styles.Url rel="noreferrer noopener" target="_blank" href={url}>
                        {url}
                    </styles.Url>
                </styles.UrlRow>
            );
        };

        return (
            <>
                <Modal isOpen={isUrlModalOpen} onClose={onUrlModalClose}>
                    <ModalOverlay/>
                    <ModalContent backgroundColor={"#FBF7FF"} borderRadius={10}>
                        <ModalHeader padding={"0 !important"}>
                            <styles.ModalHeaderFlex>
                                {linksIcon()}
                                <styles.ModalUrlTitle>Links</styles.ModalUrlTitle>
                            </styles.ModalHeaderFlex>
                        </ModalHeader>

                        <ModalBody
                            padding={"0 !important"}
                            w={"100%"}
                            backgroundColor={"white"}
                            borderRadius={"10px"}
                        >
                            {uniqueUrls?.length === 0 ? (
                                <styles.UrlRow>
                                    <Box padding={"10px"}>No Links</Box>
                                </styles.UrlRow>
                            ) : (
                                <Flex
                                    w={"100%"}
                                    flexDirection={"column"}
                                    maxH={240}
                                    overflowY={"auto"}
                                >
                                    {uniqueUrls.map(
                                        (item, i) => (
                                            <UrlRow key={i} url={item}/>
                                        )
                                        /*<styles.Url rel="noreferrer noopener" target="_blank" href={item}  key={i}>{item}</styles.Url>*/
                                    )}
                                </Flex>
                            )}
                            <Box h={2} w={"100%"}/>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    const RenderMappingModal = () => {
        return <MarketingModal isOpen={isOpen} onClose={onClose} row={row}/>;
    };

    const onRowClick = (row: Row) => {
        if (row?.canExpand) {
            if (row?.id in allExpendedRows) {
                setAllExpendedRows((prev) => {
                    return {...prev, [row.id]: !prev[row?.id]};
                });
            } else {
                setAllExpendedRows((prev) => {
                    return {...prev, [row.id]: true};
                });
            }
        }
    };

    // render table
    const renderToggleCell = (cell: Cell) => {
        return;
    }

    const renderSalesChannelCell = (row: Row<TableData>, cell: Cell) => {
        return (
            <styles.StyledTd
                width={20}
                {...row.getToggleRowExpandedProps({ title: undefined } as any)}
                {...cell.getCellProps}
            >
                {checkRow(row)}
            </styles.StyledTd>
        );
    }

    const renderUrlCell = (row: Row<TableData>, cell: Cell) => {
        return (
            <styles.StyledTd
                width={15}
                {...row.getToggleRowExpandedProps({ title: undefined } as any)}
                {...cell.getCellProps()}
            >
                <styles.URLbutton disabled={true}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      let key = "";
                                      // if (row?.depth === 0) {
                                      //     key = row?.original?.data[0];
                                      // }
                                      // if (row?.depth === 1) {
                                      //     key = row?.original?.data[1];
                                      // }
                                      // if (row?.depth === 2) {
                                      //     key = row?.original?.data[2];
                                      // }
                                      onUrlModalOpen();
                                      setUrlModalData({depth: row?.depth, key: key});
                                  }}
                >
                    Watch URL
                </styles.URLbutton>
            </styles.StyledTd>
        );
    }

    const renderEditCell = (row: Row, cell: Cell) => {
        if (row.depth === 0) {
            return (
                <styles.StyledTd
                    {...cell.getCellProps()}
                ></styles.StyledTd>
            );
        }
        return (
            <styles.StyledTd
                width={10}
                onClick={(e) => {
                }}
                {...cell.getCellProps()}
            >
                <Flex
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <styles.Edit
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onOpen();
                            setRow(row);
                        }}
                    >
                        Edit
                    </styles.Edit>
                </Flex>
            </styles.StyledTd>
        );
    }

    const renderTitleCellIntegration = (row: Row<TableData>, cell: Cell) => {
        return (
                <styles.StyledTdTitleIntegration
                    width={100}
                    {...row.getToggleRowExpandedProps({ title: undefined } as any)}
                    {...cell.getCellProps()}
                >
                    <Flex alignItems={"center"}>
                        {cell?.column?.id === "title" && (!!row?.original?.integrationPlatform || row?.original?.integrationPlatform === 0)
                            ? getSvg(row.original.integrationPlatform)
                            : null}
                        {cell.render("Cell")}
                        {renderTitleCellExpandButton(row, cell)}
                    </Flex>
                </styles.StyledTdTitleIntegration>
        );

    }

    const renderTitleCellAccount = (row: Row<TableData>, cell: Cell) => {
        const account = accounts?.data?.find((item) => item.id === cell.value);
        console.log(account)
        return (
                <styles.StyledTdTitleAccount
                    width={100}
                    {...row.getToggleRowExpandedProps({ title: undefined } as any)}
                    {...cell.getCellProps()}
                >
                    <Flex alignItems={"center"}>
                        <InformationWrapper
                            shortText={`${account?.displayName} (${account?.accountId})`}
                            text={account?.displayName}
                            provided={true}
                            small={true}
                            width={200}
                            dontDisplayHeader={true}/>
                        {renderTitleCellExpandButton(row, cell)}
                    </Flex>
                </styles.StyledTdTitleAccount>
        );
    }

    const renderTitleCellCampaign = (row: Row<TableData>, cell: Cell) => {
        console.log(row?.original)
        console.log(cell)
        return (
                <styles.StyledTdTitleCampaign width={100}
                                              {...row.getToggleRowExpandedProps({ title: undefined } as any)}
                                              {...cell.getCellProps()}>
                    <Flex alignItems={"center"}>
                        <InformationWrapper
                            shortText={`${row?.original?.campaignId}`}
                            text={row?.original?.displayName}
                            provided={true}
                            small={true}
                            width={200}
                            dontDisplayHeader={true}/>
                    </Flex>
                </styles.StyledTdTitleCampaign>
        );
    }

    const renderTitleCellExpandButton = (row: Row<TableData>, cell: Cell) => {
        if (cell.column.id === "title" &&
            row.depth !== 2) {

            if (row.isExpanded) {
                return (
                    <Flex flexDirection={"column"}>
                        <Box h={"1px"} w={1}/>
                        <IoIosArrowDown/>
                    </Flex>
                )
            } else {
                return (
                    <Flex flexDirection={"column"}>
                        <Box h={"1px"} w={1}/>
                        <IoIosArrowForward/>
                    </Flex>
                )
            }
        } else {
            return <></>
        }

    }

    const renderTitleCellByDepth = (row: Row<TableData>, cell: Cell) => {
        switch (row.depth) {
            case 0:
                return renderTitleCellIntegration(row, cell);
            case 1:
                return renderTitleCellAccount(row, cell);
            case 2:
                return renderTitleCellCampaign(row, cell);
            default:
                return <></>
        }
    }

    const renderRow = (row: Row<TableData>) => {
        return row.cells.map((cell: Cell, i: number) => {
            if (cell.column.id === "expander") {
                return renderToggleCell(cell);
            }
            if (cell.column.id === "salesChannel") {
                return renderSalesChannelCell(row, cell);
            }
            if (cell.column.id === "url") {
                return renderUrlCell(row, cell);
            }
            if (cell?.column?.id === "edit") {
                return renderEditCell(row, cell);
            } else { // title
                return renderTitleCellByDepth(row, cell);
            }
        })
    }

    const Table = ({
                       columns: userColumns,
                       data,
                       onRowClick,
                   }: {
        columns: any;
        data: any;
        onRowClick: any;
    }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            state: {expanded},
        } = useTable<TableData>(
            {
                columns: userColumns,
                data,
                initialState: {expanded: allExpendedRows},
            },
            useExpanded // Use the useExpanded plugin hook
        );

        const tableRows = React.useMemo(
            () =>
                rows?.map((row: Row<TableData>, i) => {
                    prepareRow(row);
                    return (
                        <styles.StyledTr
                            depth={row.depth > 0}
                            onClick={() => {
                                onRowClick(row);
                            }}
                            {...row.getRowProps()}
                        >
                            {renderRow(row)}
                        </styles.StyledTr>
                    );
                }),
            [rows]
        );

        return (
            <styles.StyledTable {...getTableProps()}>
                <styles.StyledThead>
                    {headerGroups.map((headerGroup, i) => (
                        <styles.StyledHeaderTr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, i) => {
                                if (column.id === "expander") {
                                    // eslint-disable-next-line array-callback-return
                                    return;
                                }
                                if (column.id === "products") {
                                    // eslint-disable-next-line array-callback-return
                                    return;
                                }
                                if (column.id === "url") {
                                    return (
                                        <styles.StyledTh {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </styles.StyledTh>
                                    );
                                }

                                if (column.id === "title") {
                                    return (
                                        <styles.StyledTh
                                            center={true}
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </styles.StyledTh>
                                    );
                                }
                                return (
                                    <styles.StyledTh
                                        style={column.id === "title" ? {textAlign: "left"} : {}}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </styles.StyledTh>
                                );
                            })}
                        </styles.StyledHeaderTr>
                    ))}
                </styles.StyledThead>
                <tbody {...getTableBodyProps()}>{tableRows}</tbody>
            </styles.StyledTable>
        );
    }

    const getHeaders = () => {
        return [
            {
                id: "expander", // Make sure it has an ID
                Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}: any) => (
                    <span {...getToggleAllRowsExpandedProps()}></span>
                ),
                Cell: ({row}: any) =>
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                                title: undefined,
                            })}
                        ></span>
                    ) : null,
            },
            {
                Header: "Marketing sources",
                accessor: "title",
            },
            {
                Header: "Sales Channel",
                accessor: "salesChannel",
            },
            {
                Header: () => {
                    return <Flex flexDirection={row}
                                 justifyContent={"center"}
                                 alignItems={"center"}> <Flex mr={2}>URL</Flex> <PremiumIcon tooltipText={'Coming soon'}
                                                                                             width={'20px'}
                                                                                             height={'20px'}/></Flex>
                },
                accessor: "url",
            },
            /*{
                          Header: 'Type',
                          accessor: 'type',
                      },*/
            {
                Header: "",
                accessor: "edit",
            },
        ];
    };

    const columns = React.useMemo(() => getHeaders(), []);

    const tableData = React.useMemo(() => createNestedData(data), [data, accounts]);

    return (
        <styles.TableHolder style={{width: "95%"}}>
            <RenderMappingModal/>
            <UrlModal/>
            {<Table columns={columns} data={tableData} onRowClick={onRowClick}/>}
        </styles.TableHolder>
    );
};

export default React.memo(MarketingTable);

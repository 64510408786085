import React, {useEffect, useState} from "react";
import {IoStorefrontSharp} from "react-icons/io5";
import {IntegrationType} from "@models";
import {useDispatch, useSelector} from "react-redux";
import {channelsStateSelector} from "../../store/channels";
import {selectedChannelsSelector, setChannels} from "../../store/ui";
import {localStorageService} from "@services";
import {CheckboxMenuSmall} from "../checkbox-menu-small/CheckboxMenuSmall";

interface Props {
}

const StoreChannelFilter = (props: Props) => {
    const channels = useSelector(channelsStateSelector);
    const selectedChannelsFromStore = useSelector(selectedChannelsSelector);
    const dispatch = useDispatch();
    const [defaultChannels, setDefaultChannels] = useState<any[]>([]);
    const [options,setOptions] = useState<string[]>([])

    useEffect(() => {
        const allUniqueChannelsNames:any[] = [];
        const allUniqueChannelsIds:any[] = [];
        channels?.channels?.forEach((channel) => {
            if(allUniqueChannelsNames?.includes(channel?.displayName)){
                return
            }else {
                allUniqueChannelsNames?.push(channel?.displayName);
                allUniqueChannelsIds?.push((channel?.id));
            }
        })
        setOptions(allUniqueChannelsIds);
    }, [channels?.channels])

    useEffect(() => {
        let channelsFromStorage =  localStorageService.getItem("channels");
        if (!!channels?.channels && !channelsFromStorage) {
            const optionsList = channels?.channels?.filter((item) => !!item?.displayName)
                .map((item) => item?.id)?.filter((item) => !!item);
            dispatch(setChannels({channels: optionsList}))
            setDefaultChannels(optionsList);
        }
        if (channelsFromStorage) {
            const selectedChannels = JSON.parse(channelsFromStorage);
            const isSameArray = arraysHaveSameContent(selectedChannels?.channels, selectedChannelsFromStore);
            if(!isSameArray){
                dispatch(setChannels({channels: selectedChannels?.channels}))
            }

            setDefaultChannels(selectedChannels?.channels);
        }
    }, [channels?.channels]);

    function arraysHaveSameContent(arr1: string[], arr2?: string[]): boolean {
        if (arr1.length !== arr2?.length) {
            return false;
        }

        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2?.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }




    const onApply = (checked?: string[]) => {
        dispatch(setChannels({channels: checked}))
        !!checked && setDefaultChannels(checked);
        localStorageService.setItem(
            "channels",
            JSON.stringify({channels: checked})
        );
    }

    return (
        <CheckboxMenuSmall
            title={"Sales Channels"}
            displayButtons={true}
            defaultCheckItems={defaultChannels}
            icon={(color: string) => <IoStorefrontSharp color={color} />}
            displayChannelName={true}
            options={options}
            onApply={(checked) => {
                onApply(checked)}}
        />
    )
};

export default StoreChannelFilter;

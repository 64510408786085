import styles from './styles';
import { PageLink } from './page-link/PageLink';
import { getPaginationItemsToPresent } from './get-pagination-items-to-present';
import { Box } from '@chakra-ui/react';

interface Props {
    adsPerPage: number;
    totalAds: number;
    currentPage: number;
    changePage: (numberPage: any) => void;
    maxLength?: number;
}

export const Pagination = (props: Props) => {
    //state

    //helper
    const pageCount = Math.ceil(props.totalAds / props.adsPerPage);
    const pageNumbers = getPaginationItemsToPresent(props.currentPage, !!props?.maxLength ? props?.maxLength : 0, pageCount);

    //render
    return (
        <styles.PaginationNav aria-label='pagination'>
            <styles.AdsContext>
                <styles.DisplayingAdsPagination>
                    {`(page ${props.currentPage} out of ${pageCount})`}
                </styles.DisplayingAdsPagination>
            </styles.AdsContext>

            <styles.PaginationList>
                <PageLink
                    //key={1}
                    href="#"
                    disabled={props.currentPage === 1}
                    onClick={() => props.changePage(props.currentPage - 1)}
                    isPrevOrNext={true}>
                    <styles.PreviousArrow />
                </PageLink>

                {
                    <styles.InputPaginationNumber
                        min={1}
                        max={pageCount}
                        pattern={`[1-${pageCount}]*`}
                        value={props?.currentPage}
                        type={"number"}
                        onChange={(e: { target: { valueAsNumber: number; }; }) => {
                            console.log(isNaN(e?.target?.valueAsNumber))
                            if(isNaN(e?.target?.valueAsNumber)){
                                return
                            }
                            if(e?.target?.valueAsNumber > pageCount){
                                props.changePage(pageCount)
                            }else{
                                props.changePage(e?.target?.valueAsNumber)
                            }
                        }}

                        onBlur={(e: { target: { valueAsNumber: number; }; }) => {
                            if(isNaN(e?.target?.valueAsNumber)){
                                return
                            }
                            if(e?.target?.valueAsNumber > pageCount){
                                props.changePage(pageCount)
                            }else{
                                props.changePage(e?.target?.valueAsNumber)
                            }
                        }}
                    >
                    </styles.InputPaginationNumber>
                }
                <PageLink
                    href="#"
                    disabled={props.currentPage === pageCount}
                    onClick={() => props.changePage(props.currentPage + 1)}
                    isPrevOrNext={true}>
                    <styles.NextArrow />
                </PageLink>
            </styles.PaginationList>
            <Box w={114} />
        </styles.PaginationNav>
    )
}
import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    //padding: 0px 18px;
  `;

  export const SmallerWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 10px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Holder = styled.div`
    width: 100%;
    margin: 0 auto;
  `;
  export const StyledHolder = styled.div`
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(
      0deg,
      rgba(243, 238, 251, 1) 0%,
      rgba(253, 253, 254, 1) 100%
    );
    margin: 50px 0 0 0;
  `;
  export const toggleHolder = styled.div`
    display: flex;
    margin: 0 0 0 160px;
    align-self: center
  `;

  export const AssignedKpisToggle = styled.div`
    width: 400px;
  `;

  export const buttonWrapper = styled.div`
    display: flex;
    /* font-size: 10px !important; */
  `;

  export const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
  `;

  export const TopBarWrapper = styled.div`
    width: 100%;
    display: flex;
  `;

  export const Title = styled.div`
    //width: 100%;
    color: black;
    font-size: 21px;
    font-weight: bold;
    display: flex;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
  `;

  export const DepartmentRowWrapper = styled.div`
    display: flex;
    margin: 50px auto 0;
    width: 80%;
    justify-content: space-around;
  `;

  export const Spacer = styled.div`
    height: 90px;
  `;
  export const WrapperTable = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    margin: 136px 0 0 0;
  `;
  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px;
    height: auto;
    display: flex;
    justify-content: space-around;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;
  export const Table = styled.div``;
  export const Chart = styled.div`
    display: flex;
  `;
  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    margin: 50px 0 0 0;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;

  export const LoaderWrapper = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
  `;
}

export default styles;

export const sgFlag = () => {
    return (

        <svg width={"100%"} height={"100%"} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 4320 2880">
            <rect fill="#ed2939" width="4320" height="1440" />
            <rect fill="#fff" y="1440" width="4320" height="1440" />
            <path fill="#fff" d="M 1481.678,720 A 541.5,541.5 0 1 1 398.67798,720 A 541.5,541.5 0 1 1 1481.678,720 z" />
            <path fill="#ed2939" d="M 1651.835,720 A 511.73499,511.73499 0 1 1 628.36499,720 A 511.73499,511.73499 0 1 1 1651.835,720 z" />
            <path fill="#fff" id="star" d="M 1007.1951,733.06439 L 933.63466,676.63425 L 860.09279,733.09117 L 888.40587,642.09687 L 814.61147,586.0053 L 905.6704,586.1981 L 933.6049,495.07467 L 961.56934,586.18798 L 1052.6282,585.96246 L 978.8522,642.0808 L 1007.1951,733.06439 z" />
            <use xlinkHref="#star" transform="translate(577.18853,0)" />
            <use xlinkHref="#star" transform="translate(288.88853,-214.21117)" />
            <use xlinkHref="#star" transform="translate(107.99953,342.74883)" />
            <use xlinkHref="#star" transform="translate(469.18853,342.74883)" />
        </svg>
    );
};

const data = {
  data: [
    {
      id: 1,
      link: "https://www.google.com",
      pageViews: 9220,
      sessions: 5225,
      avgTimeOnPage: 185,
      entrances: 5221,
      transactions: 123,
      ecommCr: 3,
      bounceRate: 87.39,
      revenue: 268,
      users: 56,
      exit: 85.32,
      pageValue: 8242,
      exits: 6420,
    },
    {
      id: 2,
      link: "https://www.google.com",
      sessions: 5225,
      pageViews: 9220,
      avgTimeOnPage: 185,
      entrances: 5221,
      transactions: 123,
      bounceRate: 87.39,
      ecommCr: 3,
      users: 56,
      exit: 85.32,
      pageValue: 8242,
      revenue: 668,
      exits: 6420,
    },
    {
      id: 3,
      link: "https://www.google.com",
      pageViews: 1434,
      sessions: 4144,
      avgTimeOnPage: 453,
      entrances: 5221,
      transactions: 123,
      ecommCr: 3,
      users: 56,
      bounceRate: 87.39,
      exit: 85.32,
      exits: 6420,
      revenue: 868,
      pageValue: 8242,
    },
    {
      id: 4,
      link: "https://www.google.com",
      pageViews: 1434,
      sessions: 4144,
      avgTimeOnPage: 453,
      entrances: 5221,
      transactions: 123,
      ecommCr: 3,
      users: 56,
      bounceRate: 87.39,
      exits: 6420,
      exit: 85.32,
      revenue: 12,
      pageValue: 8242,
    },
    {
      id: 5,
      link: "https://www.google.com",
      pageViews: 1434,
      sessions: 4144,
      avgTimeOnPage: 453,
      transactions: 123,
      entrances: 5221,
      exits: 6420,
      bounceRate: 87.39,
      users: 56,
      ecommCr: 3,
      exit: 85.32,
      pageValue: 8242,
      revenue: 68,
    },
  ],
  userAnalysis: {
    rows: [
      {
        browser: "Android Webview",
        country: "Canada",
        ages: "18-24",
        gender: "male",
        users: 23,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "Canada",
        ages: "25-34",
        gender: "male",
        users: 40,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "Canada",
        ages: "25-34",
        gender: "female",
        users: 20,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "Canada",
        ages: "35-44",
        gender: "male",
        users: 21,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "Israel",
        ages: "35-44",
        gender: "male",
        users: 21,
        revenue: 45130.0,
      },
      {
        browser: "Android Webview",
        country: "Canada",
        ages: "45-54",
        gender: "male",
        users: 15,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "United Kingdom",
        ages: "18-24",
        gender: "male",
        users: 51,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "United Kingdom",
        ages: "25-34",
        gender: "male",
        users: 17,
        revenue: 3772,
      },
      {
        browser: "Android Webview",
        country: "United Kingdom",
        ages: "35-44",
        gender: "male",
        users: 16,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "United Kingdom",
        ages: "45-54",
        gender: "male",
        users: 61,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "United Kingdom",
        ages: "55-64",
        gender: "male",
        users: 17,
        revenue: 0.0,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 41,
        revenue: 1927,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "25-34",
        gender: "female",
        users: 10,
        revenue: 121.7,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "35-44",
        gender: "male",
        users: 12,
        revenue: 249.76,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "45-54",
        gender: "male",
        users: 84,
        revenue: 124.83,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "55-64",
        gender: "male",
        users: 41,
        revenue: 682,
      },
      {
        browser: "Android Webview",
        country: "United States",
        ages: "65+",
        gender: "female",
        users: 14,
        revenue: 1108,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "18-24",
        gender: "male",
        users: 11,
        revenue: 1905,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "25-34",
        gender: "male",
        users: 14,
        revenue: 1468,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "35-44",
        gender: "male",
        users: 10,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "45-54",
        gender: "male",
        users: 83,
        revenue: 4492,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "55-64",
        gender: "female",
        users: 53,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "Canada",
        ages: "65+",

        gender: "male",
        users: 44,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "China",
        ages: "25-34",
        gender: "male",
        users: 11,
        revenue: 5134.0,
      },
      {
        browser: "Chrome",
        country: "India",
        ages: "25-34",
        gender: "male",
        users: 13,
        revenue: 119,
      },
      {
        browser: "Chrome",
        country: "Israel",
        ages: "18-24",
        gender: "male",
        users: 11,
        revenue: 974.2,
      },
      {
        browser: "Chrome",
        country: "Israel",
        ages: "25-34",
        gender: "male",
        users: 39,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "Israel",
        ages: "35-44",
        gender: "male",
        users: 47,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "Israel",
        ages: "45-54",
        gender: "male",
        users: 22,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "Israel",
        ages: "65+",

        gender: "male",
        users: 15,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "United Kingdom",
        ages: "18-24",
        gender: "male",
        users: 43,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "United Kingdom",
        ages: "25-34",
        gender: "male",
        users: 74,
        revenue: 1635,
      },
      {
        browser: "Chrome",
        country: "United Kingdom",
        ages: "35-44",
        gender: "male",
        users: 37,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "United Kingdom",
        ages: "45-54",
        gender: "male",
        users: 19,
        revenue: 0.0,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 11,
        revenue: 952.73,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "25-34",
        gender: "male",
        users: 17,
        revenue: 915.54,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "35-44",
        gender: "male",
        users: 14,
        revenue: 855.75,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "45-54",
        gender: "male",
        users: 97,
        revenue: 843.72,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "55-64",
        gender: "male",
        users: 61,
        revenue: 319.48,
      },
      {
        browser: "Chrome",
        country: "United States",
        ages: "65+",

        gender: "male",
        users: 36,
        revenue: 133.6,
      },
      {
        browser: "Edge",
        country: "Canada",
        ages: "25-34",
        gender: "male",
        users: 16,
        revenue: 5213,
      },
      {
        browser: "Edge",
        country: "Canada",
        ages: "35-44",
        gender: "male",
        users: 13,
        revenue: 424.0,
      },
      {
        browser: "Edge",
        country: "Canada",
        ages: "45-54",
        gender: "male",
        users: 13,
        revenue: 4524.0,
      },
      {
        browser: "Edge",
        country: "Egypt",
        ages: "45-54",
        gender: "male",
        users: 13,
        revenue: 4524.0,
      },
      {
        browser: "Edge",
        country: "Greece",
        ages: "45-54",
        gender: "male",
        users: 13,
        revenue: 4524.0,
      },
      {
        browser: "Edge",
        country: "Greece",
        ages: "45-54",
        gender: "male",
        users: 13,
        revenue: 4524.0,
      },
      {
        browser: "Firefox",
        country: "Canada",
        ages: "45-54",
        gender: "male",
        users: 13,
        revenue: 40524.0,
      },
      {
        browser: "Edge",
        country: "Canada",
        ages: "55-64",
        gender: "male",
        users: 13,
        revenue: 121.0,
      },
      {
        browser: "Edge",
        country: "Canada",
        ages: "65+",

        gender: "male",
        users: 12,
        revenue: 0.0,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 97,
        revenue: 4472,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "25-34",
        gender: "male",
        users: 84,
        revenue: 8593,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "35-44",
        gender: "male",
        users: 98,
        revenue: 106.14,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "45-54",
        gender: "male",
        users: 11,
        revenue: 1750,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "55-64",
        gender: "male",
        users: 12,
        revenue: 3484,
      },
      {
        browser: "Edge",
        country: "United States",
        ages: "65+",
        gender: "male",
        users: 11,
        revenue: 3392,
      },
      {
        browser: "Opera",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 17,
        revenue: 2583,
      },
      {
        browser: "Opera",
        country: "United States",
        ages: "25-34",
        gender: "male",
        users: 919,
        revenue: 1596,
      },
      {
        browser: "Safari",
        country: "United States",
        ages: "45-54",
        gender: "male",
        users: 11,
        revenue: 55.0,
      },
      {
        browser: "Safari (in-app)",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 14,
        revenue: 1995,
      },
      {
        browser: "Safari (in-app)",
        country: "United States",
        ages: "25-34",
        gender: "male",
        users: 11,
        revenue: 0.0,
      },
      {
        browser: "Safari (in-app)",
        country: "United States",
        ages: "35-44",
        gender: "male",
        users: 13,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "Canada",
        ages: "18-24",
        gender: "male",
        users: 11,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "Canada",
        ages: "25-34",
        gender: "male",
        users: 19,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "Canada",
        ages: "35-44",
        gender: "male",
        users: 22,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "Canada",
        ages: "45-54",
        gender: "male",
        users: 12,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "18-24",
        gender: "male",
        users: 54,
        revenue: 1508,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "25-34",
        gender: "male",
        users: 13,
        revenue: 286.44,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "35-44",
        gender: "male",
        users: 11,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "45-54",
        gender: "male",
        users: 60,
        revenue: 0.0,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "55-64",
        gender: "male",
        users: 55,
        revenue: 776,
      },
      {
        browser: "Samsung Internet",
        country: "United States",
        ages: "65+",
        gender: "male",
        users: 33,
        revenue: 0.0,
      },
    ],
  },
  performanceData: {
    data: [
      {
        type: "Shopify US",
        productPage: "www.USproduct1.com",
        sourceMedium: "(direct) / (none)",
        sessions: 1079,
        users: 234,
        transactions: 5225,
        revenue: 3510,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify US",
        productPage: "",
        sourceMedium: "(direct) / (none)",
        sessions: 1079,
        users: 5000,
        transactions: 5225,
        revenue: 0,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify US",
        productPage: "www.USproduct1.com",
        sourceMedium: "google / organic",
        sessions: 1501,
        users: 10,
        transactions: 5225,
        revenue: 1.53,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify US",
        productPage: "www.USproduct1.com",
        sourceMedium: "instagram",
        sessions: 1079,
        users: 500,
        transactions: 5225,
        revenue: 1564,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify US",
        productPage: "www.USproduct4.com",
        sourceMedium: "google / organic",
        sessions: 1079,
        users: 1666,
        transactions: 5225,
        revenue: 1564,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify EU",
        productPage: "www.EUproduct1.com",
        sourceMedium: "instagram / referral",
        sessions: 1079,
        users: 1456,
        transactions: 5225,
        revenue: 1564,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify EU",
        productPage: "www.EUproduct2.com",
        sourceMedium: "instagram / referral",
        sessions: 1079,
        users: 2000,
        transactions: 5225,
        revenue: 1564,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify EU",
        productPage: "",
        sourceMedium: "instagram / referral",
        sessions: 1079,
        users: 2000,
        transactions: 5225,
        revenue: 0,
        cr: 2.26,
        aov: 18.34,
      },
      {
        type: "Shopify EU",
        productPage: "www.EUproduct1.com",
        sourceMedium: "instagram / referral",
        sessions: 1079,
        users: 1500,
        transactions: 5225,
        revenue: 1564,
        cr: 2.26,
        aov: 18.34,
      },
    ],
  },
  funnelData: {
    data: [
      {
        sessions: 52725,
        product: 39000,
        cart: 12101,
        checkout: 6451,
        transactions: 1253,
      },
    ],
  },
};

export const getWebsiteData = () => {
  return Promise.resolve(data);
};

import {Popover, PopoverTrigger, PopoverContent, Button} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {MdArrowDropDown} from "react-icons/md";
import {useSelector} from "react-redux";
import {accountStateSelector} from "store/ui";
import {styles} from './checkboxStyles';

interface Checkbox {
    name: string;
    selected: boolean;
    id?: string;
}

interface CheckboxData {
    platforms?: Checkbox[];
}

interface Props {
    data: any;
    title: string;
    setData: any;
    disabled?: boolean;
    border?: boolean;
    platformError?: boolean;
    setPlatformError?: any;
    flag?: boolean;
    platformDisable?: boolean;
    setAccountError?: any;
    accountError?: any;
    closePopover?: any;
}


export const CheckboxList = (props: Props) => {
    const [platformCheckbox, setPlatformCheckBox] = React.useState<CheckboxData>({});
    const accounts = useSelector(accountStateSelector);


    useEffect(() => {
        if (!!props?.data) {
            setPlatformCheckBox(props?.data);
        }
    }, [props?.data]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, type: string | number) => {
        if (!!e?.target?.name && !!e?.target?.checked) {
            const {name, checked} = e.target;
            const newPlatformCheckBox = [...platformCheckbox[type]];
            if (!!newPlatformCheckBox) {
                const index = newPlatformCheckBox?.findIndex(h => h.name === name);
                if (index > -1) {
                    newPlatformCheckBox[index] = {name, selected: checked};
                }
                setPlatformCheckBox((h: any) => ({...h, [type]: newPlatformCheckBox}));
                props.setData((h: any) => ({...h, [type]: newPlatformCheckBox}));
            }

        }
        ;
    }

    const handleOnSelectAll = (e: React.ChangeEvent<HTMLInputElement>, type: string | number) => {
        if (!!e?.target?.checked) {
            const {checked} = e.target;
            let newPlatformCheckBox = [...platformCheckbox[type]];
            if (!checked) {
                newPlatformCheckBox = newPlatformCheckBox?.map(opt => ({...opt, selected: false}));
            } else {
                newPlatformCheckBox = newPlatformCheckBox?.map(opt => ({...opt, selected: true}));
            }
            setPlatformCheckBox((h: any) => ({...h, [type]: newPlatformCheckBox}));
            props.setData((h: any) => ({...h, [type]: newPlatformCheckBox}));
        }
    };
    const renderCheckboxList = (options: any[], type: any) => {
        if (!!options)
            return options.map((opt, i) => (
                <div key={i} style={{paddingLeft: 10, paddingRight: 10}}>
                    <label>
                        <input
                            disabled={props?.disabled}
                            type="checkbox"
                            name={opt.name}
                            onChange={e => handleOnChange(e, type)}
                            checked={opt.selected}
                        />
                        {props?.title === "Account" ? !accounts.error && accounts?.data.find((item) => item?.id === opt.name)?.displayName : opt.name}
                    </label>
                </div>
            ));
        return;
    };


    const renderSelectAllCheckbox = (type: string | number, state: any, callback: any) => (
        <div>
            <label style={{display: "flex", alignItems: "center"}}>
                <input
                    disabled={props.disabled}
                    type="checkbox"
                    onChange={e => callback(e, type)}
                    checked={state[type].every((opt: { selected: any; }) => opt.selected)}
                />
                <div>
                    {"All " + props.title}
                </div>
            </label>
        </div>
    );

    const platforms = () => {
        return (
            <Popover>
                <styles.StyledPopoverTrigger>
                    <styles.styledButton error={props?.platformDisable ? props?.platformError : false}
                                         onClick={(e: any) => {
                                             if (props?.setPlatformError) {
                                                 if (props.disabled) {
                                                     props.setPlatformError(true);
                                                 }
                                             }
                                             if (props?.setAccountError) {
                                                 if (props.disabled) {
                                                     props.setAccountError(true);

                                                 }
                                             }
                                         }}>{props.title}
                        <MdArrowDropDown/>
                    </styles.styledButton>

                </styles.StyledPopoverTrigger>
                {props.closePopover ? <></> :
                    <styles.StyledPopoverContent w={"max-content"} paddingTop={0.5} paddingBottom={0.5}>
                        {!!platformCheckbox?.platforms ? renderSelectAllCheckbox("platforms", platformCheckbox, handleOnSelectAll) : <></>}
                        {!!platformCheckbox?.platforms ? renderCheckboxList(platformCheckbox?.platforms, "platforms") : <></>}
                    </styles.StyledPopoverContent>}

            </Popover>
        );
    };


    return (
        <>{platforms()}</>
    );
};



import { Button } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {


    export const ThreeDotsMenuButton = styled.div<{size?: number}>`
      &:hover{
        cursor: pointer;
      }
      width: ${props=>  props?.size +"px"};
      /*margin-top: 4px;*/
    `;
}
export default styles;
import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AccountIntegrations } from "store/account/state";
import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { IconGear, IconWarning } from "utils/icons/account-health";
import { styles } from './styles';
import { useDispatch, useSelector } from "react-redux";
import { productsDataSelector } from "store/products";
import { calculateProductTotalCost } from "components/cogs-settings/cogs-table/cogs-cells/CogsCells";
import { ExploresEnum, openDefaultExploreView } from "store/ui";
import MoreInformation from "components/more-information/MoreInformation";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";


interface Props {
    data: AccountIntegrations[];
}

const portalOperationsMoreInfoDialogProps = {
    title: 'Operations',
    //subtitle: 'How to use this view?',
    description: 'In this section, you`ll define the production costs and ensure efficient production tracking for your business.',
    //tip: 'TIP: Updating your strategy & targets every few months will ensure healthy & smart growth. ',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export const PortalOperations = (props: Props) => {
    const { t } = useTranslation(['translation', 'translation']);
    const [activeIntegrations, setActiveIntegrations] = useState<number[]>([]);
    const products = useSelector(productsDataSelector);
    let navigate = useNavigate();
    const [lastUpdated, setLastUpdated] = useState<string>("");
    const [totalMissingCogs, setTotalMissingCogs] = useState(0);
    const [numberOfTotalCogs, setNumberOfTotalCogs] = useState<number>(0);
    const [isPortalOperationsMoreInfoDialogOpen, setIsPortalOperationsMoreInfoDialogOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!!products) {
            const deepCopyProducts = JSON.parse(JSON.stringify(products));
            setNumberOfTotalCogs(deepCopyProducts.length);
            const missingCogs = [];
            deepCopyProducts?.forEach((product: any) => {
                if (calculateProductTotalCost(product?.sellingPrice, product?.fob, product?.cogs) === 0) {
                    missingCogs.push("");
                }
            });
            setTotalMissingCogs(missingCogs.length);
        }
    }, [products]);

    useEffect(() => {
        products?.forEach((item) => {
            setLastUpdated(item?.updatedAt?.slice(0, 10));
        });
    }, [products]);

    //hooks

    //helpers

    const handleClickNavigate = () => {
        dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.COGS }));
    };


    //renderers

    const customToolTip = (title: number) => {

        if (totalMissingCogs === 0) {
            return (
                <PopoverContent w={235} borderRadius={30}>
                    <styles.StyledToolTipGreen>
                        {IconGear()}
                        <styles.ToolTipWrapperGreen>
                            <styles.ToolTipTextGreen>
                                {t('LAST_UPDATED', { ns: 'translation' })}
                            </styles.ToolTipTextGreen>
                            <styles.ToolTipTextGreen>
                                {lastUpdated}
                            </styles.ToolTipTextGreen>
                            <styles.ToolTipLinkGreen onClick={() => {
                                handleClickNavigate();
                            }}
                            >
                                {t('UPDATE', { ns: 'translation' })}
                            </styles.ToolTipLinkGreen>
                        </styles.ToolTipWrapperGreen>
                    </styles.StyledToolTipGreen>
                </PopoverContent>
            );
        }

        return (
            <PopoverContent zIndex={999} w={"max-content"} borderRadius={30}>
                <styles.StyledToolTip>
                    {IconWarning()}
                    <styles.ToolTipWrapper>
                        <styles.ToolTipText>
                            {t('MISSING COGS', { ns: 'translation' })}
                        </styles.ToolTipText>
                        <styles.ToolTipLink onClick={() => handleClickNavigate()}>
                            {totalMissingCogs} items missing cogs
                        </styles.ToolTipLink>
                    </styles.ToolTipWrapper>
                    <styles.ToolTipUpdate onClick={() => {
                        handleClickNavigate();
                    }}>
                        Update
                    </styles.ToolTipUpdate>
                </styles.StyledToolTip>
            </PopoverContent>
        );
    };

    const renderTitle = (number: number) => {
        switch (number) {
            case (0):
                return (`${t('COGS', { ns: 'translation' })}`);

        }
        return;
    };

    const renderButton = (title: number) => {
        const flag = activeIntegrations.includes(title);
        return (
            <styles.ButtonWrapper onClick={() => {
                handleClickNavigate();
            }}>
                {/*{totalMissingCogs !== 0 ? <styles.NumberOfItems>{totalMissingCogs}</styles.NumberOfItems> : <></>}*/}
                <Popover trigger="hover" placement='top'>
                    <PopoverTrigger>
                        <styles.Button {...{ selected: flag }} onClick={() => {
                            if (flag) {
                                handleClickNavigate();
                            }
                        }}>
                            <styles.ButtonBackground width={!flag ? 100 : 0}/>
                            {/*<styles.ButtonBackground
                                width={totalMissingCogs !== 0 ? totalMissingCogs / numberOfTotalCogs === 1 ? 0 : 100 - (totalMissingCogs / numberOfTotalCogs * 100) : 100} />*/}
                            <styles.ButtonText selected={!flag}>
                                {renderTitle(title)}
                            </styles.ButtonText>
                        </styles.Button >
                    </PopoverTrigger>
                    {/*{!flag ? customToolTip(title) : null}*/}

                </Popover>
            </styles.ButtonWrapper>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsContainer>
                {renderButton(0)}
            </styles.ButtonsContainer>
        );
    };

    return (
        <styles.Wrapper>
            <styles.Title>
                {t('OPERATIONS', { ns: 'translation' })}
                <MoreInformation
                    moreInfoDialogProps={portalOperationsMoreInfoDialogProps}
                    isMoreInfoDialogOpen={isPortalOperationsMoreInfoDialogOpen}
                    setIsMoreInfoDialogOpen={setIsPortalOperationsMoreInfoDialogOpen}/>
            </styles.Title>
            {renderButtons()}
        </styles.Wrapper>
    );
};

import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {FinancialState} from "./state";

export const financialStateSelector = (state: RootState): FinancialState =>
  state.financial;

// export const financialOverviewSelector = createSelector(
//     financialStateSelector,
//     state => state.data?.overview
// );
// export const financialTableSelector = createSelector(
//     financialStateSelector,
//     state => state.data?.financialTable
// );
// export const financialBreakdownSelector = createSelector(
//     financialStateSelector,
//     state => state.data?.breakdownTable
// );

export const financialDataSelector = createSelector(
  financialStateSelector,
  (state) => state.data
);

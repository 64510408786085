import { Channel } from "store/channels";
import { Product } from "../../models/products";

export const getChannelNameById = (
  id?: string,
  channels?: Channel[]
): string => {
  if (!id) {
    return "";
  }
  const channel = channels?.find((channel) => channel?.id === id);
  if(!channel){
    return id
  }
  if (!channel?.displayName && typeof channel?.displayName !== "string") {
    return "";
  } else {
    return channel?.displayName;
  }
};

export const getProductNameById = (
  id?: string,
  products?: any[]
): string => {
  if (id === undefined || id === null) {
    return "";
  }

  const product = products?.find((product) => product?.id === id);
  if (!product?.productTitle && typeof product?.productTitle !== "string") {
    return product?.sku +"*";
  } else {
    return product?.productTitle;
  }
};

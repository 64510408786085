import styled from "styled-components";
import tinycolor from "tinycolor2";

export namespace styles {
  export const Wrapper = styled.div`
    background: transparent linear-gradient(344deg, #1a0989 0%, #7031eb 100%) 0%
      0% no-repeat padding-box;
    height: 100%;
    width: 220px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 12px;
  `;

  export const AppBarLogo = styled.div`
    margin: 4px 0 30px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-height: 60px;
  `;

  export const Divider = styled.div`
    border: 0 none;
    background-color: ${(props) =>
      tinycolor(props.theme.lightTextColor).setAlpha(0.5).toHex8String()};
    height: 0.5px;
    margin: 10px 0;
  `;

  export const NavItemButtonWrapper = styled.div`
    border: 1px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0px 9px 13px #00000021;
    margin: 0 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  `;

  export const NavItemButton = styled.div``;
}

export default styles;

import React from "react";
import { styles } from "./styles";
import { t } from "i18next";
import {Flex} from "@chakra-ui/react";

interface Props {
  cards: { title: string; icon: JSX.Element; option: any, disable?: boolean }[];
  setOption: (e: any) => void;
  currentSelectedItem: any;

}

const OperationsChain = (props: Props) => {
  const Item = ({
    svg,
    title,
    option,
    key,
    disable
  }: {
    svg: JSX.Element;
    title: string;
    option: any;
    key: number;
    disable?: boolean
  }) => {
    return (
          <styles.Item
            key={key}
            selected={props.currentSelectedItem === option}
            disable={disable}
            onClick={() => {
              if(!disable){
              props.setOption(option);
              }
            }}
          >
              <styles.ItemSuffixRed selected={props.currentSelectedItem === option} />
              <styles.ItemSuffixBottom selected={props.currentSelectedItem === option} />
            <styles.LeftBorderDiv  selected={props.currentSelectedItem === option} />

              <styles.ItemContentHolder>
              <styles.SvgHolder>{svg}</styles.SvgHolder>
              <styles.ItemTitle selected={props.currentSelectedItem === option}>
                {t(title, { ns: "translation" })}
              </styles.ItemTitle>
            </styles.ItemContentHolder>
           {/* <styles.DotWrapper selected={props.currentSelectedItem === option}>
              <styles.Dot selected={props.currentSelectedItem === option} />
            </styles.DotWrapper>*/}
          <styles.ItemSuffix selected={props.currentSelectedItem === option} />
          </styles.Item>
    );
  };

  const ItemsWrapper = () => {
    return (
      <styles.ItemWrapper>
        {props.cards.map((item, i) => (
          <Item
            key={i}
            title={item.title}
            svg={item.icon}
            option={item.option}
            disable={item?.disable}
          />
        ))}
      </styles.ItemWrapper>
    );
  };
  return <ItemsWrapper />;
};

export default OperationsChain;

import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import {
  AsnOrderEdit,
  DepartmentsEnum,
  operationService,
  PurchaseOrder,
} from "../../services/operations/operation.service";

export const fetchPoData = createAsyncThunk(
  "organization/fetchOperationsPoData",
  async (
    {
      organizationId,
      query,
      fromDate,
      toDate,
    }: {
      organizationId: string;
      query?: string;
      fromDate?: string;
      toDate?: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await operationService.fetchManufacturingData(
        organizationId,
        query,
        fromDate,
        toDate
      );

      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const fetchModalTrackingDataThunks = createAsyncThunk(
  "organization/fetchTransportationData",
  async (
    {
      organizationId,
      orderId,
      trackingNumber,
    }: { organizationId: string; orderId: string; trackingNumber: string },
    thunkAPI
  ) => {
    try {
      const response = await operationService.fetchModalTrackingData(
        organizationId,
        orderId,
        trackingNumber
      );

      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const fetchOperationData = createAsyncThunk<
  any,
  {
    organizationId: string;
    fromDate: string;
    toDate: string;
    type?: number;
    kpisType?:
      | DepartmentsEnum.PURCHASE
      | DepartmentsEnum.INVENTORY
      | DepartmentsEnum.LAST_MILE;
    query?: string;
    statusCode?: string;
    deliveryStatus?: string;
    page?: number;
    size?: number;
    tags?: string[];
    sort?: string[];
    description?: string[];
  }
>(
  "kpi/fetchKpiData",
  async (
    {
      organizationId,
      fromDate,
      toDate,
      type,
      kpisType,
      query,
      statusCode,
      deliveryStatus,
      page,
      size,
      tags,
      sort,
      description,
    },
    thunkAPI
  ) => {
    const response = await operationService.fetchOperationData(
      organizationId,
      fromDate,
      toDate,
      type,
      kpisType,
      query,
      statusCode,
      page,
      size,
      deliveryStatus,
      tags,
      sort,
      description
    );
    return response.data;
  }
);

export const fetchTransportationData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        query?: string;
        page?: number;
        size?: number;
        tags?: string[];
        sort?: string[];
        description?: string[];
    }
    >(
    "kpi/fetchTransportationData",
    async (
        {
            organizationId,
            fromDate,
            toDate,
            query,
            page,
            size,
            sort,
            description,
        },
        thunkAPI
    ) => {
        const response = await operationService.fetchTransportationData({organizationId, fromDate, toDate,page, description,size,sort, query});
        return response.data;
    }
);

export const fetchLastMileData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        query?: string;
        page?: number;
        size?: number;
        tags?: string[];
        sort?: string[];
        deliveryStatus?: string;
    }
    >(
    "kpi/fetchLastMileData",
    async (
        {
            organizationId,
            fromDate,
            toDate,
            query,
            page,
            size,
            sort,
            deliveryStatus,
            tags
        },
        thunkAPI
    ) => {
        const response = await operationService.fetchLastMileData({organizationId, fromDate, toDate,page, deliveryStatus,size,sort, query, tags});
        return response.data;
    }
);

export const fetchHandlingStats = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        query?: string;
        page?: number;
        size?: number;
        tags?: string[];
        sort?: string[];
        statusCode?: string;
    }
    >(
    "kpi/fetchHandlingStatus",
    async (
        {
            organizationId,
            fromDate,
            toDate,
            query,
            page,
            size,
            sort,
            statusCode,
        },
        thunkAPI
    ) => {
        const response = await operationService.fetchHandlingStatus({organizationId, fromDate, toDate,page, statusCode,size,sort, query});
        return response.data;
    }
);

export const fetchInventoryData = createAsyncThunk<
    any,
    {
        organizationId: string;
        fromDate: string;
        toDate: string;
        query?: string;
        tags?: string[];
        sort?: string[];
        description?: string[];
    }
    >(
    "kpi/fetchInventoryData",
    async (
        {
            organizationId,
            fromDate,
            toDate,
            query,
            sort,
            description,
        },
        thunkAPI
    ) => {
        const response = await operationService.fetchInventoryData({organizationId, fromDate, toDate, description,sort, query});
        return response.data;
    }
);


export const fetchLastMileMetaData = createAsyncThunk<
  any,
  { organizationId: string }
>(
  "operation/fetchLastMileData",
  async ({ organizationId }: { organizationId: string }, thunkAPI) => {
    const response = await operationService.fetchLastMileMetaData(
      organizationId
    );
    return response.data;
  }
);

export const uploadFilesThunk = createAsyncThunk<
  any,
  { organizationId: string; data: FormData }
>(
  "operation/uploadFilesThunk",
  async (
    { organizationId, data }: { organizationId: string; data: FormData },
    thunkAPI
  ) => {
    const response = await operationService.uploadMultipleFiles(
      organizationId,
      data
    );
    return response.data;
  }
);

export const createPurchaseOrder = createAsyncThunk<any, any>(
  "operation/createPurchaseOrder",
  async (
    {
      organizationId,
      data,
    }: {
      organizationId: string;
      data: PurchaseOrder[];
    },
    thunkAPI
  ) => {
    const response = await operationService.createPo({ organizationId, data });
    thunkAPI.dispatch(fetchPoData({ organizationId: organizationId }));
    return response.data;
  }
);
export const deletePurchaseOrder = createAsyncThunk<any, any>(
  "operation/deletePurchaseOrder",
  async (
    {
      organizationId,
      elementId,
    }: {
      organizationId: string;
      elementId: string;
    },
    thunkAPI
  ) => {
    const response = await operationService.deletePurchaseOrder({
      organizationId,
      elementId,
    });
    thunkAPI.dispatch(fetchPoData({ organizationId: organizationId }));

    return response.data;
  }
);
export const editPurchaseOrder = createAsyncThunk<
  any,
  { organizationId: string; data: PurchaseOrder }
>(
  "operation/editPurchaseOrder",
  async (
    {
      organizationId,
      data,
    }: {
      organizationId: string;
      data: PurchaseOrder;
    },
    thunkAPI
  ) => {
    const response = await operationService.editPurchaseOrder({
      organizationId,
      data,
    });
    thunkAPI.dispatch(fetchPoData({ organizationId: organizationId }));
    return response.data;
  }
);
export const editAsnOrder = createAsyncThunk<
  any,
  { organizationId: string; data: AsnOrderEdit }
>(
  "operation/editPurchaseOrder",
  async (
    {
      organizationId,
      data,
    }: {
      organizationId: string;
      data: AsnOrderEdit;
    },
    thunkAPI
  ) => {
    const response = await operationService.editAsnOrder({
      organizationId,
      data,
    });

    /*thunkAPI.dispatch(
              fetchOperationData({
                organizationId,
                kpisType: DepartmentsEnum.TRANSPORTATION,
                fromDate: "",
                toDate: "",
              })
            );*/
    return response.data;
  }
);

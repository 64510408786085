import styles from "./styles";
import { useTranslation } from "react-i18next";
import { MoreInfoButton } from "../../components/more-info-button/MoreInfoButton";
import { MoreInfoDialog, MoreInfoDialogProps } from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { useState } from "react";
import { AccountHealthButton } from "../../components/account-health-button";
import {
    ExploresEnum,
    openDefaultExploreView,
} from "../../store/ui";
import { useDispatch, useSelector } from "react-redux";
import { IconKpiFramework, IconMarketing } from "../../utils/icons/account-health";
import { useTheme } from "@styles";
import {IconGeneral, IconMarketingStrategy} from "utils/icons/explore";
import {Box} from "@chakra-ui/react";
import {GeneralSettingsIcon} from "../../utils/icons/account-health-icons/general-settings";
import {PnlIcon} from "../../utils/icons/account-health-icons/pnl";

export const DataLanguagePage = () => {
    // hooks
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation(['translation', 'translation']);
    const strategyMoreInfoDialogProps = {
        title: 'Data Language',
        //subtitle: 'How to use this view?',
        description: 'Set up top metrics for the whole organization in line with your business strategy.',
        /*tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
        videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
        videoTitle: 'Best Practices',
        videoSubtitle: 'How to use this view?',
        videoDescription: '5 min explanatory video from our COO',
        videoLinkTitle: 'Click to watch'*/
    } as MoreInfoDialogProps;
    const [isDataLanguageMoreInfoDialogOpen, setIsDataLanguageMoreInfoDialogOpen] = useState(false);

    /* const closePnlOverlay = () => dispatch(openPnlOverlayView({ isOpen: !isPnlOpen })); */


    /*  const renderPnlSettingOverlay = () => {
         return (
             <styles.OverlayWrapper direction='right' in={isPnlOpen} onClick={() => {
                 closePnlOverlay();
             }}>
                 <styles.OverlayContent
                     onClick={(event) => event.stopPropagation()}>
                     <PnlSettingOverlay onClose={closePnlOverlay} />
                 </styles.OverlayContent>
             </styles.OverlayWrapper>
         );
     }; */

    const buttons = [
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><GeneralSettingsIcon /></span>,
            title: 'General',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.GENERAL }));
            }
        },
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><PnlIcon /></span>,
            title: 'P & L',
            callback: () => dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.PNL_SETTINGS }))
        },
        {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><IconMarketing /></span>,
            title: 'Marketing',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.MARKETING_MAPPING }));
            }
        },
        /* {
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><IconKpiFramework /></span>,
            title: 'Customers',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.KPI_FRAMEWORK }));

            }
        } */
        /*{
            icon: <span style={{ color: theme?.primaryColor, width: '100%', height: '100%' }}><IconMarketingStrategy /></span>,
            title: 'Sales Channels',
            callback: () => {
                dispatch(openDefaultExploreView({ isOpen: true, ExploresEnum: ExploresEnum.SALES_CHANNELS }));
            }
        },*/
    ];

    // helpers
    const openDataLanguageMoreInfoDialog = (isOpen: boolean) => setIsDataLanguageMoreInfoDialogOpen(isOpen);


    // renderers

    const renderButtons = () => {
        return (
            <styles.ButtonsWrapper>
                {
                    buttons.map((button, index) => {
                        return <AccountHealthButton
                            key={index}
                            title={button.title} icon={button.icon}
                            callback={button.callback} />;
                    })
                }
            </styles.ButtonsWrapper>
        );
    };


    return (
        <styles.Wrapper>
            <Box ml={5}>
            <styles.Title>
                {t('DATA_LANGUAGE', { ns: 'translation' })}
                <MoreInfoButton onClick={() => openDataLanguageMoreInfoDialog(true)} />
                <MoreInfoDialog
                    props={strategyMoreInfoDialogProps}
                    isDialogOpen={isDataLanguageMoreInfoDialogOpen}
                    onClose={() => openDataLanguageMoreInfoDialog(false)}
                />
            </styles.Title>
            <styles.Description>
                {t(
                    'SET_TOP_METRICS_FOR_THE_WHOLE_ORGANIZATION_IN_LINE_WITH_YOUR_BUSINESS_STRATEGY'
                    , { ns: 'translation' })}
            </styles.Description>

            {renderButtons()}
            {/* {renderPnlSettingOverlay()} */}
            </Box>
        </styles.Wrapper>
    );
};


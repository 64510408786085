import styled from "styled-components";
import { ModalContent } from "@chakra-ui/react";
import { IoIosCloseCircleOutline } from "react-icons/io";

export namespace styles {
  export const CustomModalContent = styled(ModalContent)`
    width: 600px !important;
    max-width: none !important;
    max-height: none !important;
    border-radius: 10px !important;
    padding: 0 !important;
  `;

  export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
  `;

  export const CloseButton = styled(IoIosCloseCircleOutline)`
    margin-left: auto;
    margin-right: 0;
    width: 25px !important;
    height: 25px !important;
    &:hover {
      cursor: pointer;
    }
  `;
  export const CloseIconHolder = styled.span`
  &:hover{
    cursor: pointer;
  }
  `

  export const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
  `;
  export const Title = styled.div`
    color: ${(props) => props.theme.primaryColor};
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    font-size: 20px;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: space-between;
  `;
  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: 600;
  `;
  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    max-height: 500px;
    overflow: auto;
  `;

  export const Description = styled.div`
    color: gray;
    font-size: 14px;
    margin: 20px 0;
  `;

  export const TipSection = styled.div`
    color: gray;
    font-size: 14px;
    width: 100%;
    height: 50px;
    display: flex;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};

    align-items: center;
    padding: 0 25px;
    border-radius: 0 0 10px 10px;
    background-color: #f0e5fd;
    margin: 10px 0 0 0;
  `;

  export const VideoSection = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    margin-bottom: 5px;
  `;

  export const Video = styled.div`
    width: 200px;
    height: 150px;
    border: solid 3px ${(props) => props.theme.primaryColor};
    margin-right: 15px;
  `;
  export const VideoDetails = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const VideoTitle = styled.div`
    color: ${(props) => props.theme.primaryColor};
    font-size: 16px;
    font-weight: 600;
  `;
  export const VideoSubTitle = styled.div`
    color: black;
    font-size: 14px;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    font-weight: 600;
  `;
  export const VideoDescription = styled.div`
    color: gray;
    font-size: 14px;
  `;
  export const VideoLink = styled.a`
    font-size: 14px;
  `;

  export const LearnMoreButton = styled.div`
    width: 100px;
    min-height: 40px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-self: end;
    align-self: end;

    &:hover {
      cursor: pointer;
    }
  `;
}

export default styles;

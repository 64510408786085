import { createSlice } from "@reduxjs/toolkit";
import { initalIntegrationsState, IntegrationsState } from "./state";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { clearIntegrationsState, finishLoadingIntegrations } from "./actions";
import { fetchIntegrations } from "./thunks";

function buildFinishIntegrationsLoader(
  builder: ActionReducerMapBuilder<IntegrationsState>
) {
  builder.addCase(finishLoadingIntegrations, (state, action) => {
    state.integrationsLoader = action.payload.integrationsLoader;
    return state;
  });
}

function buildFetchkpiDataReducer(
  builder: ActionReducerMapBuilder<IntegrationsState>
) {
  builder
    .addCase(fetchIntegrations.pending, (state, action) => {
      state.loading = false;
      return state;
    })
    .addCase(fetchIntegrations.fulfilled, (state, action) => {
      state.integrations = action.payload;
      state.loading = true;
      return state;
    })
    .addCase(fetchIntegrations.rejected, (state, action) => {
      state.loading = false;
      return state;
    });
}

export const integrationSlice = createSlice({
  name: "integrations",
  initialState: initalIntegrationsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildFinishIntegrationsLoader(builder);

    /* buildCleanKpiStateReducer(builder); */
    // async
    buildFetchkpiDataReducer(builder);
  },
});

// reducer
export const integrationsReducer = integrationSlice.reducer;

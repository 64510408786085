import styled from "styled-components";
import {Button, Menu, MenuButton, MenuList, ModalContent} from "@chakra-ui/react";
import Lottie from "lottie-react";

export namespace styles {
  export const LottieStyle = styled(Lottie)`
    //height: 40vh;
    position: absolute;
    width: 45%;
  `;
  export const Wrapper = styled(ModalContent)`
    min-width: 500px !important;
    min-height: 400px !important;
    //max-width: none !important;
    //max-height: none !important;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;
  export const StyledInput = styled.input`

    &:focus {
      box-shadow: unset !important;
      border-color: unset !important;
    }
  `

  export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    flex: 1;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
  `;

  export const Title = styled.span`
    font-size: 18px;
    font-weight: bold;
  `;

  export const ConnectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
  `;

  export const ShopifyIconWrapper = styled.div`
    height: 64px;
    width: 64px;
    margin: 5px;
    border-radius: 60px;
    color: white;
    box-shadow: 0px 14px 36px #060E8331;
  `;

  export const ShopifyIcon = styled.img`
    width: 100%;
    height: auto;
  `;

  export const AvatarWrapper = styled.div`
    height: 64px;
    width: 64px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: ${(props) => props.theme.primaryColor};
    box-shadow: 0px 14px 36px #060E8331;  
  `;
  export const AvatarLetters = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: white;
  `;

  export const ConnectionLine = styled.div`
    width: 120px;
    height: 2px;
    /*background-image: linear-gradient(
      to right,
      gray 60%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 16px 2px;
    background-repeat: repeat-x;*/
  `;

  export const InputLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 5px 0;
    width: 100%;
  `;

  export const InputLabel = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor};
  `;

  export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 30px;
  `;

  export const InputWrapper = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    width: 100%;
  `;

  export const InputPrefixWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: solid 1px #bfbfbf;
    border-right: unset;
    border-radius: 10px 0 0 10px;
    height: 40px;
    width: 70%;

    > input {
      width: calc(100% - 20px);
      height: calc(100% - 4px);
      font-size: 14px;
      outline: none;
      border: none;
      background-color: transparent;

      ::-webkit-input-placeholder {
        color: ${(props) => props.theme.inputColor};
      }

      :-ms-input-placeholder {
        color: ${(props) => props.theme.inputColor};
      }

      ::placeholder {
        color: ${(props) => props.theme.inputColor};
      }
    }
  `;

  export const InputSuffixWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    border: solid 1px #bfbfbf;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    height: 40px;
    width: 30%;
  `;
  export const FlagHolder = styled.div`
  min-width: 40px;
    min-height: 21px;
    max-height: 22px;
    max-width: 41px;
    margin-right: 8px;
  `
  export const StyledMenu = styled(Menu)`
  display: flex;
    width: 100%;
    justify-content: space-between;
  `;
  export const StyledMenuButton = styled(MenuButton)`
   padding: 10px;
    border: 1px solid #707070;
    border-radius: 13px;
    width: 100%;
  `
  export const StyledMenuList = styled(MenuList)<{width: number}>`
  width: 400px;
    max-height: 200px;
    overflow: auto;
  `
  export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `

  export const MenuHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `

  export const InputSuffix = styled.span`
    color: #7d7d84;
    font-size: 14px;
    font-weight: bold;
  `;

  export const InputDividerWrapper = styled.div`
    height: 40px;
    width: 1px;
    background-image: linear-gradient(
      to bottom,
      #bfbfbf 100%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: left;
    background-size: 1px 10px;
    background-repeat: repeat-y;
  `;

  export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;

  export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `;

  export const BackButton = styled(Button)`
    height: 38px !important;
    padding: 0 20px;
    margin: 5px 10px;
    border-radius: 40px !important;
    color: #7031EB;
    width: 80px !important;
    background-color: transparent !important;
    border-radius: 35px;
    border: 2px solid #7031EB;
  `;

  export const AuthorizeButton = styled(Button)`
    height: 38px !important;
    padding: 0 20px;
    margin: 5px 10px;
    border-radius: 40px !important;
    width: 80px !important;
    background-color: transparent !important;
    border-radius: 35px;
    width: 120px !important;
    border: 2px solid #7031EB;
    color: #7031EB;
  `;
}

export default styles;

/* eslint-disable jsx-a11y/anchor-is-valid */
import adminPanelStyles from "./styles";
import {Sidebar} from "./sidebar/Sidebar";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Integrations} from "../integrations/Integrations";
import {Loading} from "../../components/loading/Loading";
import React, {Suspense, useEffect, useRef} from "react";
import {Departments} from "../departments/Workspace";
import {Marketing} from "../marketing/Marketing";
import {Portal} from "../portal/Portal";
import {Strategy} from "../strategy/Strategy";
import {Operations} from "../operations/Operations";
import {NotFound} from "../../components/not-found/NotFound";
import {AccountSettings} from "../account-settings/AccountSettings";
import {Header} from "components/header/Header";
import {Workspaces} from "../workspace/Workspace";
import {useDispatch, useSelector} from "react-redux";
import {
    isApplyBarOpenSelector,
    isDefaultExploreOpenSelector, isSecondaryApplyBarOpenSelector,
    isSecondaryExploreOpenSelector,
    openApplyModal,
    openDefaultExploreView, openSecondaryApplyModal,
    openSecondaryExplore,
} from "../../store/ui";
import {WideViewsOrganization} from "../wideviews-organization/WideViewsOrganization";
import {WideViewsFinancial} from "../wideviews-financial/WideViewsFinancial";
import {DataLanguagePage} from "../data-language/DataLanguage";
import {TopBar} from "../../components/top-bar/TopBar";
import {RenderExplores} from "./render-explores/RenderExplores";
import {Box} from "@chakra-ui/react";
import {
    fetchDataLanguage,
    organizationDataLanguageSelector,
    organizationSelector,
    organizationStateSelector
} from "@store";
import {Website} from "../website/Website";
import {FeaturesEnum} from "@models";
import {Operation} from "../operation/Operations";
import {WideViewsFinancialNew} from "../wideviews-financial/WideViewsFinancialNew";
import {useExploreView} from "../../utils/custom-hooks/useExploreView";


export enum ExploreType {
  DEFAULT,
  SECONDARY,
}

export const AdminPanel = () => {
  // hooks
  const exploreView = useExploreView();
  const isDefaultExploreOpen = useSelector(isDefaultExploreOpenSelector);
  const isSecondaryExploreOpen = useSelector(isSecondaryExploreOpenSelector);
  const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
  const isSecondaryApplyBarOpen = useSelector(isSecondaryApplyBarOpenSelector);
  const dispatch = useDispatch();
  const organization = useSelector(organizationStateSelector);
  const contentRef = useRef<null | HTMLDivElement>(null);
  const location = useLocation();
  const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organizationState = useSelector(organizationSelector);

    useEffect(() => { // reset scroll on page change
                      contentRef.current?.scrollTo(0, 0);
                      const currency = dataLanguage?.generalSettings?.currency;
                      const chosenCurrency = dataLanguage?.generalSettings?.chosenCurrency;
                      if(chosenCurrency !== currency){
                        !!organization?.organization?.id && !!dataLanguage?.id && dispatch(fetchDataLanguage({organizationId: organization?.organization?.id, dataLanguageId: dataLanguage?.id}))
                      }
                      /*dispatch(setCardSelected({ isSelected: false, kpisEnumMarketing: undefined, kpisEnumWorkspace: undefined }));*/
                  }, [location?.pathname]);


  // helpers

  const closeDefaultExplore = () => {
    dispatch(
      openDefaultExploreView({
        isOpen: !isDefaultExploreOpen.isDefaultExploreOpen,
      })
    );
  };

  const closeSeconderyExplore = () => {
    dispatch(
      openSecondaryExplore({
        isOpen: !isSecondaryExploreOpen.isSecondaryExploreOpen,
      })
    );
  };

  // explore overlay
  const renderDefaultExplore = () => {
    return (
      <Box position={"absolute"} zIndex={70}>
        <adminPanelStyles.ExploreOverlayWrapper
          direction="right"
          in={isDefaultExploreOpen.isDefaultExploreOpen}
          unmountOnExit={true}
          onClick={() => {
            if (isApplyBarOpen) {
              dispatch(openApplyModal({ isApplyModalOpen: true }));
            } else {
              closeDefaultExplore();
            }
          }}
        >
          <adminPanelStyles.ExploreOverlayContent
            onClick={(event) => event.stopPropagation()}
          >
            <RenderExplores
              exploreType={ExploreType.DEFAULT}
              inputEnum={isDefaultExploreOpen?.exploreEnum}
            />
          </adminPanelStyles.ExploreOverlayContent>
        </adminPanelStyles.ExploreOverlayWrapper>
      </Box>
    );
  };
  const renderSecondaryExplore = () => {
    return (
      <Box position={"absolute"} zIndex={70}>
        <adminPanelStyles.ExploreOverlayWrapper
          direction="right"
          unmountOnExit={true}
          in={isSecondaryExploreOpen.isSecondaryExploreOpen}
          onClick={() => {
            if (isApplyBarOpen) {
              dispatch(openApplyModal({ isApplyModalOpen: true }));
            }
            else if(isSecondaryApplyBarOpen){
                dispatch(openSecondaryApplyModal({ isApplyModalOpen: true }));
            }
            else {
              closeSeconderyExplore();
            }
          }}
        >
          <adminPanelStyles.ExploreOverlayContent
            onClick={(event) => event.stopPropagation()}
          >
            <RenderExplores
              exploreType={ExploreType.SECONDARY}
              inputEnum={isSecondaryExploreOpen?.exploreEnum}
            />
          </adminPanelStyles.ExploreOverlayContent>
        </adminPanelStyles.ExploreOverlayWrapper>
      </Box>
    );
  };
  // explore overlay

    const checkFeature = (featureEnum: FeaturesEnum) :boolean => {
        if(!organizationState?.plan?.features){
            return true
        }
        return organizationState?.plan?.features?.includes(featureEnum)
    }

  return (
    <adminPanelStyles.Wrapper>
      {/*<Toast />*/}
      <Sidebar />
      <adminPanelStyles.Page>
        <adminPanelStyles.Header>
          {/*<adminPanelStyles.TopHeader />*/}
          <Header />
        </adminPanelStyles.Header>
        <adminPanelStyles.BelowHeader>
          <adminPanelStyles.Content ref={contentRef}>
            <TopBar
              title={`Your  ${location?.pathname
                ?.split("/")
                .pop()
                ?.replace(
                  /^(\w)(.+)/,
                  (match, firstLetter, resetLetters) =>
                    firstLetter.toUpperCase() + resetLetters.toLowerCase()
                )}`}
            />
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  path=""
                  element={<Navigate replace to={"workspace"} />}
                />
                <Route path="workspace/*" element={<Workspaces />} />
                <Route
                  path="organization/*"
                  element={<WideViewsOrganization />}
                />
                <Route path="financial/*" element={<WideViewsFinancialNew />} />
                {/*<Route path="pnl-report/*" element={<WideViewsFinancialNew />} />*/}
                <Route path="departments/*" element={<Departments />} />
                {checkFeature(FeaturesEnum.MARKETING) && (<Route path="marketing/*" element={<Marketing />} />)}
                {checkFeature(FeaturesEnum.OPERATION) &&  (<Route path="operation/*" element={<Operation />} />)}
                {checkFeature(FeaturesEnum.WEBSITE) && (<Route path="website/*" element={<Website />} />)}
                <Route path="portal/*" element={<Portal />} />
                <Route path="integrations" element={<Integrations />} />
                <Route path="strategy" element={<Strategy />} />

                <Route path="operations" element={<Operations />} />

                <Route path="account-settings" element={<AccountSettings />} />
                <Route path="data-language" element={<DataLanguagePage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
            <adminPanelStyles.BelowContent />
          </adminPanelStyles.Content>
        </adminPanelStyles.BelowHeader>
      </adminPanelStyles.Page>
      {renderDefaultExplore()}
      {renderSecondaryExplore()}
    </adminPanelStyles.Wrapper>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { initialProductState, ProductsState } from "./state";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import {cleanUpLoader, clearProductsState, editProducts} from "./actions";
import {
  addCogs,
  createProductCogsCell,
  fetchProducts,
  fetchProductsFieldsData,
  fetchProductsMetaData,
  updateProducts, uploadCsvProductFile
} from "./thunks";
import {Product} from "../../models/products";

function buildCleanProductsStateReducer(
  builder: ActionReducerMapBuilder<ProductsState>
) {
  builder.addCase(clearProductsState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildCleanUpLoadersStateReducer(
    builder: ActionReducerMapBuilder<ProductsState>
) {
  builder.addCase(cleanUpLoader, (state, action) => {
    state.isPutLoading = undefined;
    state.isPostLoading = undefined;
    return state;
  });
}


// set new products
function buildSetNewProducts(builder: ActionReducerMapBuilder<any>) {
  builder.addCase(editProducts, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
    return state;
  });
}

function buildFetchProductsDataReducer(
  builder: ActionReducerMapBuilder<ProductsState>
) {
  builder
    .addCase(fetchProducts.pending, (state, action) => {
      if (state.data?.page !== undefined && state.data?.page !== null) {
        state.data.page = state.data.page++;
      }
      return state;
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
      const filteredData = action?.payload?.data?.map((item: Product) => {
        if(!("newCogs" in item)){
          return {...item, newCogs: []}
        }else {
          return item
        }
      });

      state.data = {
        count: action.payload?.count,
        data: filteredData,
        maxPage: action?.payload?.maxPage
      };
      return state;
    })
    .addCase(fetchProducts.rejected, (state, action) => {
      /* state.data = undefined; */
      return state;
    });
}
function buildAddCogsReducer(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
    .addCase(addCogs.pending, (state, action) => {
      /* state.data = undefined; */
      return state;
    })
    .addCase(addCogs.fulfilled, (state, action) => {
      /*   action.payload.forEach((element) => {
        console.log(element);
      }); */
      /* const newData = state.data. */
      /* state.data = action.payload; */

      return state;
    })
    .addCase(addCogs.rejected, (state, action) => {
      /* state.data = undefined; */
      return state;
    });
}

function buildFetchProductsFields(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
      .addCase(fetchProductsFieldsData.pending, (state, action) => {
        /* state.data = undefined; */
        return state;
      })
      .addCase(fetchProductsFieldsData.fulfilled, (state, action) => {
          state.productsReducedData.data = action.payload

        return state;
      })
      .addCase(fetchProductsFieldsData.rejected, (state, action) => {
        /* state.data = undefined; */
        return state;
      });
}

function buildUploadProductsCsvFields(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
      .addCase(uploadCsvProductFile.pending, (state, action) => {
        /* state.data = undefined; */
        state.messageDisplay = '';
        state.csvLoading = true;
        return state;
      })
      .addCase(uploadCsvProductFile.fulfilled, (state, action) => {

        state.csvLoading = false;
        state.messageDisplay = action?.payload?.message;
        return state;
      })
      .addCase(uploadCsvProductFile.rejected, (state, action) => {
        state.messageDisplay = '';
        state.csvLoading = false;
        return state;
      });
}

function buildGetCogsMetaData(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
      .addCase(fetchProductsMetaData.pending, (state, action) => {
         state.metaData = undefined;
        return state;
      })
      .addCase(fetchProductsMetaData.fulfilled, (state, action) => {
        state.metaData = action?.payload;
        return state;
      })
      .addCase(fetchProductsMetaData.rejected, (state, action) => {
        /* state.data = undefined; */
        return state;
      });
}
function buildUpdateProduct(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
      .addCase(updateProducts.pending, (state, action) => {
        if(!!state){
        state.isPutLoading = {
          isPutRequestLoading: true,
        }
        }
        return state;
      })
      .addCase(updateProducts.fulfilled, (state, action) => {
        state.isPutLoading = {
          isPutRequestLoading: false,
          isPutRequestSuccess: true,
        }
        return state;
      })
      .addCase(updateProducts.rejected, (state, action) => {
        state.isPutLoading = {
          isPutRequestLoading: false,
          isPutRequestSuccess: false,
        }
        return state;
      });
}

function buildCreateProduct(builder: ActionReducerMapBuilder<ProductsState>) {
  builder
      .addCase(createProductCogsCell.pending, (state, action) => {
        if(!!state){
          state.isPostLoading = {
            isPostRequestLoading: true,
          }
        }
        return state;
      })
      .addCase(createProductCogsCell.fulfilled, (state, action) => {
        state.isPostLoading = {
          isPostRequestLoading: false,
          isPostRequestSuccess: true,
        }
        return state;
      })
      .addCase(createProductCogsCell.rejected, (state, action) => {
        state.isPostLoading = {
          isPostRequestLoading: false,
          isPostRequestSuccess: false,
        }
        return state;
      });
}


// update products
/* function buildUpdateProductReducer(
  builder: ActionReducerMapBuilder<ProductsState>
) {
  builder
    .addCase(updateProducts.pending, (state, action) => {
      return state;
    })
    .addCase(updateProducts.fulfilled, (state, action) => {
      state.data = action?.payload;
      return state;
    })
    .addCase(updateProducts.rejected, (state, action) => {
      return state;
    });
} */

export const productsSlice = createSlice({
  name: "products",
  initialState: initialProductState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanProductsStateReducer(builder);

    buildCleanUpLoadersStateReducer(builder);

    buildSetNewProducts(builder);

    // async
    buildFetchProductsDataReducer(builder);

    buildGetCogsMetaData(builder);

    buildAddCogsReducer(builder);

    buildUpdateProduct(builder);

    buildCreateProduct(builder);

    buildFetchProductsFields(builder);

    buildUploadProductsCsvFields(builder);

    /* buildUpdateProductReducer(builder) */
  },
});

// reducer
export const productsReducer = productsSlice.reducer;

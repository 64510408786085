export const dateSort = (rowA:any, rowB:any, columnId:any) => {
    function compareBasic(a: any, b: any) {
        return a === b ? 0 : a > b ? 1 : -1
    }

    function getValue(columnId: string, row:any) {

        const foundCell = row?.cells?.find((cell:any) => {
            return cell?.column?.id === columnId
        });

        if(!!foundCell && foundCell?.value !== "-"){
            const dateParts = foundCell?.value?.split(".");
            if(!!dateParts && !!dateParts[2] && dateParts[1] - 1 && dateParts[0]){
                const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                return date
            }
            // @ts-ignore
            return new Date(null)

        }else {
            // @ts-ignore
            return new Date(null)
        }

    }

    return compareBasic(
        (getValue(columnId, rowA) as Date)?.getTime(),
        (getValue(columnId, rowB) as Date)?.getTime()
    )
}
import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";

// sign-up state
export const effortStateSelector = (state: RootState) => state.efforts;

export const effortsSelector = createSelector(
  effortStateSelector,
  (state) => state
);

import styled from "styled-components";
import { Button } from "@chakra-ui/react";
import { Props } from "../../../components/auth-button";
import { Input } from "@components";

export const Wrapper = styled.div<{ img: string }>`
  background: url(${(props) => props.img})
    ${(props) => props.theme.primaryColor};
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// content
export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 500px;
  min-height: 300px;
  overflow: hidden;
`;

export const PrimaryTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: black;
  font-family: ${(props) => props.theme.fonts.menu};
`;

export const SecondaryTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.menu};
  color: ${(props) => props.theme.primaryColor};
`;

export const LoadMorePlatformsButton = styled(Button)<Props>`
  border: ${(props) => {
    return `1px solid ${props.theme.borderColor}`;
  }};

  > a {
    color: white !important;

    > img {
      height: 18px;
      width: auto;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

//bottom
export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 30px;
  justify-content: end;
  width: 100%;
`;

export const SkipButton = styled.button`
  border-radius: 20px;
  margin: 0 10px;
  width: 100px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.primaryColor};
`;

export const NextButton = styled.button`
  border-radius: 20px !important;
  margin: 0 10px !important;
  width: 120px !important;
  height: 40px !important;
  background-color: ${(props) => props.theme.primaryColor} !important;
  color: white;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Item = styled.div<{ selected: boolean }>`
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 100px;
  width: 100px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  border: solid 2px
    ${(props) => (props.selected ? props.theme.primaryColor : "white")};
`;
export const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ItemIcon = styled.img`
  margin: 10px;
  width: 75px;
  height: auto;
`;

//shopify extra page

export const ImageContainer = styled.div``;

export const ImageCircle = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 3px 36px #060e835d;
  padding: 5px;
`;

export const NameInitials = styled.div`
  color: white;
  font-size: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: 0px 3px 36px #060e835d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dashes = styled.div`
  width: 100px;
  height: 1px;
  border: dashed 4px #e0e0e7;
  margin: 0 10px 0 10px;
`;

export const Line = styled.hr`
  width: 100px;
  border: solid #e0e0e7;
`;

export const ShopifyForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

export const ShopifyStoreNameFormInput = styled.div`
  height: 50px;
  border-radius: 16px;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 36px #060e835d;
`;

export const ShopifyStoreNameFormInputPrefix = styled(Input)`
  height: 100%;
  border-radius: 13px 0 0 13px;
  width: 60%;
  max-width: 300px;
`;

export const ShopifyStoreNameFormInputSuffix = styled(Input)`
  height: 100%;
  border-radius: 0 13px 13px 0;
  width: 40%;
  max-width: 300px;
  background-color: #dddddd;
`;

import headerStyles from "./styles";
import {Avatar, Box, Icon, MenuButton, MenuList, useDisclosure, useToast} from "@chakra-ui/react";
import {MdOutlineKeyboardArrowDown} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {AccountMenu, WorkspaceSession} from "./account-menu/AccountMenu";
import {InviteMenu} from "./invite-menu";
import {useDispatch, useSelector} from "react-redux";
import {
    authSelector,
    fetchWorkspaces,
    organizationDataLanguageSelector,
    organizationSelector,
    userSelector
} from "@store";
import {inviteIcon} from "utils/icons/invite";
import {NotificationMenu} from "./notification-menu/Notification";
import React, {useEffect, useState} from "react";
import {notificationSelector, NotificationState} from "store/notifcations";
import {NotificationsBell} from "utils/icons/notifications-bell";
import {
    addToasts,
    isApplyModalOpenSelector,
    isDefaultExploreOpenSelector,
    isSecondaryExploreOpenSelector
} from "store/ui";
import {getUserColorById, gradientDictionary} from "../../utils/colors";
import {SystemUpdatesIcon} from "utils/icons/SystemUpdatesIcon";
import {SystemEventsMenu} from "./system-events-menu/SystemEventsMenu";
import {fetchSystemEvent} from "../../store/system-events";
import {Toast, ToastStatus} from "../toast/Toast";


interface Props {
    message?: string;
}

export const Header = (props: Props) => {
    const toast = useToast();
    const notifcations = useSelector(notificationSelector);
    const currentUser = useSelector(userSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [unreadNotifcations, setUnreadNotifcations] = useState<number>(0);
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const defaultExplore = useSelector(isDefaultExploreOpenSelector);
    const secondaryExplore = useSelector(isSecondaryExploreOpenSelector);
    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const isApplyModalOpen = useSelector(isApplyModalOpenSelector);

    const {isOpen: isUpdateDataOpen, onOpen: onUpdateDataOpen, onClose: onUpdateDataClose } = useDisclosure(); //

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    useEffect(() => {
        if (!!notifcations) {
            notifcations?.forEach((item) => {
                if (item.state === NotificationState.UNREAD) {
                    setUnreadNotifcations(prev => prev + 1);
                }
            });
        }
    }, [notifcations]);

    useEffect(() => {
        if (!!currentUser?.id) {
            dispatch(fetchWorkspaces({ userId: currentUser?.id }));
        }
    }, [currentUser?.id, dispatch, currentUser?.workspaces]);

    // helpers

    // renderers
    const renderProfileImage = () => {

        return (
            <headerStyles.PopupMenu>
                <MenuButton>
                    <headerStyles.ProfileImageWrapper>
                        {!!currentUser?.firstName && !!currentUser?.lastName ?

                            <Avatar margin={[0, 3]} size={"sm"} color={"white"} src={auth?.user?.image}
                                    background={!!currentUser?.id ? getUserColorById(currentUser?.id) : gradientDictionary[0]}
                                    name={currentUser?.firstName + " " + currentUser?.lastName} /> :
                            !!currentUser?.firstName ?
                                <Avatar color={"white"} src={auth?.user?.image} background={!!currentUser?.id ? getUserColorById(currentUser?.id) : gradientDictionary[0]} margin={[0, 3]} size={"sm"} name={currentUser?.firstName} /> :
                                <Avatar color={"white"} src={auth?.user?.image} background={!!currentUser?.id ? getUserColorById(currentUser?.id) : gradientDictionary[0]} margin={[0, 3]} size={"sm"} name={""} />}
                        <headerStyles.Name>
                            {currentUser?.firstName}
                        </headerStyles.Name>
                        <Icon fontSize={25} as={MdOutlineKeyboardArrowDown} />
                        <div style={{ width: 7 }} />
                    </headerStyles.ProfileImageWrapper>
                </MenuButton>
                <headerStyles.AccountMenuWrapper>
                    <AccountMenu />
                </headerStyles.AccountMenuWrapper>
            </headerStyles.PopupMenu>
        );
    };

    const renderInviteButton = () => {
        return (
            <headerStyles.PopupMenu>
                {/*<MenuButton>*/}
                <headerStyles.InviteButton onClick={onOpen}>
                    <headerStyles.InviteFlex>

                        <div style={{ marginRight: 11 }}>
                            {inviteIcon()}
                        </div>
                        <div style={{ fontSize: 16 }}>
                            {t('INVITE', { ns: 'translation' })}
                        </div>
                    </headerStyles.InviteFlex>
                </headerStyles.InviteButton>
                <InviteMenu isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            </headerStyles.PopupMenu>
        );
    };


    const renderNotificationButton = (() => {
        return (
            <headerStyles.NotificationsWrapper>
                <headerStyles.PopupMenu placement={"bottom-end"}>
                    {/*<MenuButton onClick={changeStateNotification}>*/}
                    <MenuButton onClick={() =>
                        dispatch(addToasts({toasts: [
                                {
                                    title: 'test title',
                                    description: 'test description',
                                    isClosable: true,
                                    status: ToastStatus.INFO,
                                    duration: 2500 //in milliseconds
                                } as Toast
                            ]}))

                    }>
                        {
                            unreadNotifcations > 0 ? NotificationsBell() : <headerStyles.BiBellWideview />
                        }
                    </MenuButton>
                    <MenuList paddingTop={"0px !important"} paddingBottom={"0px !important"} mr={"-10px"} mt={"20px"} w={450}>
                        <NotificationMenu />
                    </MenuList>
                </headerStyles.PopupMenu>
            </headerStyles.NotificationsWrapper>
        );
    });

    const renderSystemEventsButton = () => {
        return (
            <headerStyles.NotificationsWrapper >
                <headerStyles.PopupMenu closeOnBlur={!isUpdateDataOpen} direction={"ltr"} placement={"bottom-end"}>
                    {
                        ({isOpen, onClose}) => (
                            <>
                        <MenuButton onClick={() => {
                            if(!!organization?.id){
                               !isOpen && dispatch(fetchSystemEvent({organizationId: organization?.id}))
                            }
                        }}>
                            <SystemUpdatesIcon />
                        </MenuButton>
                        <MenuList borderRadius={"6px !important"} paddingTop={"0px !important"} paddingBottom={"0px !important"} mr={"-10px"} mt={"19px"} w={"max-content"}>
                        <SystemEventsMenu closeMenu={onClose} isOpen={isUpdateDataOpen} onClose={onUpdateDataClose} onOpen={onUpdateDataOpen}/>
                        </MenuList>
                            </>
                        )
                    }
                </headerStyles.PopupMenu>
            </headerStyles.NotificationsWrapper>
        )
    }



    return (
        <headerStyles.Wrapper exploreIsOpen={defaultExplore.isDefaultExploreOpen || secondaryExplore.isSecondaryExploreOpen}>
            <headerStyles.Container>
                <div style={{ width: 20 }} />
                {renderInviteButton()}
                {/*<div style={{ width: 20 }} />*/}
                {/*<Box zIndex={25}>*/}
                {/*    {renderNotificationButton()}*/}
                {/*</Box>*/}
                <div style={{ width: 20 }} />
                <Box zIndex={25}>
                    {renderSystemEventsButton()}
                </Box>
                <div style={{ width: 20 }} />
                <Box zIndex={25}>
                    {renderProfileImage()}
                </Box>
            </headerStyles.Container>
        </headerStyles.Wrapper>
    );
};

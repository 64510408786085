import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  `;

  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
  `;

  export const TitleStatic = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
  `;

  export const TitleDynamic = styled.span`
    color: ${(props) => props.theme.primaryColor};
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
  `;

  export const ValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
  `;

  export const Value = styled.div`
    font-size: 20px;
    margin-right: 10px;
    font-weight: 600;
  `;

  export const Description = styled.div``;

  export const BadgeWrapper = styled.div``;

  export const HeaderButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
  `;

  export const HeaderButton = styled.div`
    padding: 5px 12px;
    margin: 0 10px;
    height: 44px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border: solid 1px #ebebeb;
    &:hover {
      cursor: pointer;
    }
  `;

  export const HeaderButtonText = styled.div`
    font-size: 14px;
    margin: 0 10px;
  `;

  export const Card = styled.div``;

  export const SectionOne = styled.div`
    width: 100%;
  `;

  export const SectionTwo = styled.div`
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-direction: row;
  `;
  export const SectionThree = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  `;

  export const SectionFour = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
  `;
}

export default styles;

import {apiBaseUrl} from "@constants";
import axios, {AxiosResponse} from "axios";
import {AccountIntegrations} from "store/account";

/* const data = {
  accountIntegrations: [
    {
      name: "shopify",
      isActive: true,
      type: "stores",
      status: IntegrationStatus.CONNECTED,
    },
    {
      name: "facebook ads",
      isActive: true,
      type: "marketing",
      status: IntegrationStatus.CONNECTED,
    },
    {
      name: "google ads",
      isActive: true,
      type: "marketing",
      status: IntegrationStatus.CONNECTED,
    },
    {
      name: "google analytics",
      isActive: true,
      type: "website",
      status: IntegrationStatus.WARNING,
    },
  ],
}; */

export const getAccountData = async (
  organization: string
): Promise<AxiosResponse<AccountIntegrations>> => {
  return axios.get<AccountIntegrations>(
    `${apiBaseUrl}/organization/${organization}/integrations`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};



export const accountService = {
  getAccountData,
};

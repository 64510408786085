import React, { AnchorHTMLAttributes } from "react";
import styles from "./styles";

export type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
    active?: boolean;
    disabled?: boolean;
    isPrevOrNext?: boolean;
};

export const PageLink = ({
    className,
    active,
    disabled,
    isPrevOrNext,
    children,
    ...otherProps
}: Props) => {

    //render
    return (
        <styles.PageLink
            disabled={!!disabled && disabled}
            active={active}
            isPrevOrNext={isPrevOrNext}
            {...otherProps}
            aria-current={active ? "page" : undefined}
        >
            {children}
        </styles.PageLink>
    );
};
import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { SearchInput } from "../../../components/search-input/SearchInput";
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@chakra-ui/react";
import ShippingTable, {
  TransportationData,
} from "./shipping-table/ShippingTable";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOperation,
  fetchTransportationData,
  operationsStateSelector,
  TransportationStatusEnum,
} from "../../../store/operation";
import { organizationSelector } from "@store";
import { DepartmentsEnum } from "../../../services/operations/operation.service";
import { dateRangeSelector } from "../../../store/ui";
import { MoreInfoButton } from "../../../components/more-info-button/MoreInfoButton";
import {
  DownloadButton,
  Field,
} from "../../../components/download-button/DownloadButton";
import ToggleColumnButton from "../../../components/toggle-column-button/ToggleColumnButton";
import { CheckboxMenuSmall } from "../../../components/checkbox-menu-small/CheckboxMenuSmall";
import OperationKpis from "../operation-kpis/OperationKpis";
import { KpisEnum } from "../../../models/kpi-settings";

const TransportationSection = () => {
  const [statusOptions, setStatusOptions] = useState<string[]>([
    "Approved",
    "Pending Arrival",
    "Draft",
    "In Review",
    "On Hold",
    "Cancelled",
  ]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<string[]>([
    "Approved",
    "Pending Arrival",
    "Draft",
    "In Review",
    "On Hold",
    "Cancelled",
  ]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tableDataToRender, setTableDataToRender] = useState<
    TransportationData[]
  >([]);
  const operations = useSelector(operationsStateSelector);
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const dates = useSelector(dateRangeSelector);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  useEffect(() => {
    if (!!operations?.filters) {
      setSelectedProducts(operations?.filters);
    }
  }, [operations?.filters]);

  useEffect(() => {
    dispatch(clearOperation());
    !!organization?.id &&
      !!dates?.startDate &&
      !!dates?.endDate &&
      !!selectedProducts &&
      dispatch(
        fetchTransportationData({
          organizationId: organization?.id,
          fromDate: new Date(dates.startDate).toISOString(),
          toDate: new Date(dates.endDate).toISOString(),
          query: !!searchQuery ? searchQuery : undefined,
          description: selectedProducts,
        })
      );
  }, [
    dates.endDate,
    dates.startDate,
    dispatch,
    organization?.id,
    searchQuery,
    selectedProducts,
  ]);

  const [rowLoader, setRowLoader] = useState<{
    rowId?: string;
    loading?: boolean;
  }>();

  useEffect(() => {
    setRowLoader(undefined);
  }, []);

  useEffect(() => {
    const MAX_OPTIONS = 6;
    if (!!operations?.data?.transportationData) {
      let data = operations?.data?.transportationData;
      if (selectedStatusOptions?.length !== MAX_OPTIONS) {
        data = operations?.data?.transportationData?.filter((item) => {
          return selectedStatusOptions?.includes(
            getStatusName(item?.statusCode)
          );
        });
      }
      const newData = data?.map((item): TransportationData => {
        return {
          poId: item?.relatedPo,
          asnId: item?.warehouseReference,
          status: item?.statusCode,
          shippingCost: item?.shippingCost,
          units: item?.totalAsnQuantity,
          carrier: item?.carrier,
          to: item?.warehouseName,
          sku: item?.sku,
          from: item?.senderCountry,
          inboundDate: item?.approveDate,
          batchNumber: item?.batchNumber,
          eta: item?.eta,
          issueDate: item?.createDate,
          description: item?.description,
          id: item?.id,
        };
      });
      setTableDataToRender(newData);
    }
  }, [operations?.data, selectedStatusOptions]);

  const addColumnButton = () => {
    return (
      <Popover placement={"top"}>
        <PopoverTrigger>
          <span>
            <ToggleColumnButton />
          </span>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>{}</PopoverBody>{" "}
          {/*// TODO add column hiding "Column Hiding" in react table v7*/}
        </PopoverContent>
      </Popover>
    );
  };

  const getStatusName = (status: any): string => {
    switch (parseInt(status)) {
      case TransportationStatusEnum.APPROVED:
        return "Approved";
      case TransportationStatusEnum.PENDING_ARRIVAL:
        return "Pending Arrival";
      case TransportationStatusEnum.DRAFT:
        return "Draft";
      case TransportationStatusEnum.IN_REVIEW:
        return "In Review";
      case TransportationStatusEnum.ON_HOLD:
        return "On Hold";
      case TransportationStatusEnum.CANCELED:
        return "Cancelled";
      default:
        return "";
    }
  };

  const fields: Field[] = [
    { label: "ASN ID", value: "asnId" },
    { label: "Related PO (ID)", value: "poId" },
    { label: "Batch Number", value: "batchNumber" },
    { label: "Sku", value: "sku" },
    { label: "Description", value: "description" },
    { label: "Issue Date", value: "issueDate", isoFormat: true },
    { label: "Ships From", value: "from" },
    { label: "Ships To", value: "to" },
    { label: "Number of Units", value: "units" },
    { label: "ETA", value: "eta", isoFormat: true },
    { label: "Actual Inbound Date", value: "inboundDate", isoFormat: true },
    { label: "Status", value: "status", customCallback: getStatusName },
  ];

  const header = () => {
    return (
      <styles.HeaderWrapper>
        <Flex>
          <styles.Title>
            Shipping to warehouse
            <div style={{ alignSelf: "start" }}>
              <MoreInfoButton
                onClick={() => {
                  return;
                }}
              />
            </div>
          </styles.Title>
          <Box w={6} />
          <SearchInput
            placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"}
            onChange={(e) => {
              setSearchQuery(e);
            }}
            onSearch={(e) => {}}
          />
          <Box w={6} />

          <CheckboxMenuSmall
            title={"Status"}
            options={statusOptions}
            setSelectedItems={setSelectedStatusOptions}
            defaultCheckItems={selectedStatusOptions}
          />
          <Box w={6} />
          <CheckboxMenuSmall
            title={"Description"}
            options={operations?.filters}
            setSelectedItems={setSelectedProducts}
            defaultCheckItems={selectedProducts}
          />
        </Flex>
        {/*{addColumnButton()}*/}
        {
          <DownloadButton
            fileName={"shipping_to_warehouse"}
            fields={fields}
            data={tableDataToRender}
            onClick={() => {}}
          />
        }
      </styles.HeaderWrapper>
    );
  };

  const renderFooter = () => {
    return (
      <styles.FooterWrapper>
        <div>{tableDataToRender.length} orders in total</div>
      </styles.FooterWrapper>
    );
  };

  const table = () => {
    return (
      <>
        {!!tableDataToRender && (
          <Box position={"relative"}>
            <ShippingTable
              tableData={tableDataToRender}
              rowLoader={rowLoader}
              setRowLoader={setRowLoader}
            />
            {renderFooter()}
          </Box>
        )}
      </>
    );
  };
  return (
    <styles.Wrapper>
     {/* <OperationKpis
        kpisToDisplay={[
          KpisEnum.PENDING_DEVICES,
          KpisEnum.HOLD_DEVICES,
        ]}
      />*/}
      {header()}
      {!!operations?.data?.transportationData ? (
        <>
          {table()}
        </>
      ) : (
        <Flex
          h={150}
          w={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner />
        </Flex>
      )}
    </styles.Wrapper>
  );
};
export default TransportationSection;

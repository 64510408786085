import React from "react";
import {
    Button,
    FormControl,
    FormLabel, Modal,
    ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onOpen: () => void;
    onDiscard: () => void;
    onConfirm?: () => void;

}


const ConfirmModal = (props: Props) => {
    const initialRef = React.useRef(null);

    const handleClose = () => {
        props?.onDiscard();
    }

    return  <Modal
        initialFocusRef={initialRef}
        isOpen={props?.isOpen}
        onClose={props?.onDiscard}
    >
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Are you sure you want to delete?</ModalHeader>
            <ModalCloseButton onClick={handleClose}/>
            <ModalBody pb={6}>

            </ModalBody>

            <ModalFooter>
                <Button onClick={handleClose} mr={3}>Cancel</Button>
                <Button onClick={props?.onConfirm} ref={initialRef} colorScheme='purple' >
                    Delete
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
};

export default ConfirmModal;

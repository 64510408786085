import { DayPicker } from "react-day-picker";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    min-height: max-content;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 60;
  `;

  export const DatePickerButton = styled.button<{ backgroundColor?: string }>`
    background-color: ${(props) => (props?.backgroundColor ? "white" : "")};
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ebebeb;
    border-radius: 19px;
  `;

  export const ClearButton = styled.button`
    text-decoration: underline;
    color: ${props => props?.theme?.primaryColor};
    margin-left: 10px;
    &:hover{
      opacity: .5;
    }
  `

  export const PopoverBody = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 15;
  `;

  export const PresetsRangeMenu = styled.div`
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  `;

  export const PresetTitle = styled.div`
    color: #7a86a1;
    padding: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;

  export const PresetButton = styled.div<{isDisabled?: boolean}>`
    color: #7a86a1;
    width: 100%;
    padding: 2px;
    text-align: start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    opacity: ${props => props?.isDisabled ? 0.3 : 1};
    &:hover {
      background-color: ${props => props?.isDisabled ? '' : 'rgba(122, 134, 161, 0.3)'};
      cursor: ${props => props?.isDisabled ? '' : 'pointer'};
    }
  `;
  export const SelectedPresetButton = styled.button`
    color: #fff;
    padding: 2px;
    width: 100%;
    background-color: #7031eb;
    text-align: start;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0 10px 10px 0;
    font-size: 14px;
  `;

  export const PresetSeparator = styled.div`
    width: 100%;
    border-width: 1px;
    margin: 5px 0;
  `;

  export const DatePickerFooter = styled.div`
    width: 100%;
    background-color: rgba(229, 213, 250, 0.3);
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 15;
  `;

  export const SelectedDates = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  `;

  export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  `;

  export const CancelButton = styled.button`
    font-size: 14px;
    padding: 5px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 19px;
  `;
  export const ApplyButton = styled.button<{ clickable: boolean }>`
    background: ${(props) =>
      props.clickable ? "#7031EB" : "rgba(112,49,235,0.5)"};
    font-size: 14px;
    color: white;
    padding: 5px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid
      ${(props) => (props.clickable ? "#7031EB" : "rgba(112,49,235,0.5)")};
    border-radius: 19px;
    &:hover {
      cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    }
  `;

  export const DayPickerWideview = styled(DayPicker)`
    :root {
      --rdp-cell-size: 40px;
      --rdp-accent-color: rgba(112, 49, 235, 0.4);
      --rdp-background-color: #e7edff;
      /* Switch to dark colors for dark themes */
      --rdp-accent-color-dark: #3003e1;
      --rdp-background-color-dark: #180270;
      /* Outline border for focused elements */
      /*--rdp-outline: 2px solid #fff;*/
      /* Outline border for focused and selected elements */
      /*--rdp-outline-selected: 2px solid rgba(0, 0, 0, 4);*/
    }

    .rdp {
      margin: 1em;
    }

    /* Hide elements for devices that are not screen readers */
    .rdp-vhidden {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      background: transparent;
      border: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      position: absolute !important;
      top: 0;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      overflow: hidden !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      border: 0 !important;
    }

    /* Buttons */
    .rdp-button_reset {
      appearance: none;
      position: relative;
      margin: 0;
      padding: 0;
      cursor: default;
      color: inherit;
      outline: none;
      background: none;
      font: inherit;

      -moz-appearance: none;
      -webkit-appearance: none;
    }

    .rdp-button {
      border: 2px solid transparent;
    }

    .rdp-button[disabled] {
      opacity: 0.25;
    }

    .rdp-button:not([disabled]) {
      cursor: pointer;
    }

    .rdp-months {
      display: flex;
    }

    .rdp-month {
      margin: 0 1em;
    }

    .rdp-month:first-child {
      margin-left: 0;
    }

    .rdp-month:last-child {
      margin-right: 0;
    }

    .rdp-table {
      margin: 0;
      max-width: calc(var(--rdp-cell-size) * 7);
      border-collapse: collapse;
      /*background-color: #000;*/
    }

    .rdp-with_weeknumber .rdp-table {
      max-width: calc(var(--rdp-cell-size) * 8);
      border-collapse: collapse;
    }

    .rdp-caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      text-align: left;
    }

    .rdp-multiple_months .rdp-caption {
      position: relative;
      display: block;
      text-align: center;
    }

    .rdp-caption_dropdowns {
      position: relative;
      display: inline-flex;
    }

    .rdp-caption_label {
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0 0.25em;
      white-space: nowrap;
      color: currentColor;
      border: 0;
      border: 2px solid transparent;
      font-family: inherit;
      font-size: 100%;
      font-weight: bold;
    }

    .rdp-nav {
      white-space: nowrap;
    }

    .rdp-multiple_months .rdp-caption_start .rdp-nav {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .rdp-multiple_months .rdp-caption_end .rdp-nav {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .rdp-nav_button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--rdp-cell-size);
      height: var(--rdp-cell-size);
      padding: 0.25em;
      margin: 0.25em;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 1px 8px 29px #695f972e;
      border-radius: 15px;
      opacity: 1;
    }

    /* ---------- */
    /* Dropdowns  */
    /* ---------- */

    .rdp-dropdown_year,
    .rdp-dropdown_month {
      position: relative;
      display: inline-flex;
      align-items: center;
    }

    .rdp-dropdown {
      appearance: none;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      cursor: inherit;
      opacity: 0;
      border: none;
      background-color: transparent;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    .rdp-dropdown[disabled] {
      opacity: unset;
      color: unset;
    }

    .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
    .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
      border: var(--rdp-outline);
      border-radius: 6px;
      background-color: var(--rdp-background-color);
    }

    .rdp-dropdown_icon {
      margin: 0 0 0 5px;
    }

    .rdp-head {
      border: 0;
    }

    .rdp-head_row,
    .rdp-row {
      height: 100%;
    }

    .rdp-head_cell {
      vertical-align: middle;
      text-transform: uppercase;
      font-size: 0.75em;
      font-weight: 700;
      text-align: center;
      height: 100%;
      height: var(--rdp-cell-size);
      padding: 0;
    }

    .rdp-tbody {
      border: 0;
    }

    .rdp-tfoot {
      margin: 0.5em;
    }

    .rdp-cell {
      width: var(--rdp-cell-size);
      height: 100%;
      height: var(--rdp-cell-size);
      padding: 0;
      text-align: center;
    }

    .rdp-weeknumber {
      font-size: 0.75em;
    }

    .rdp-weeknumber,
    .rdp-day {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: var(--rdp-cell-size);
      max-width: var(--rdp-cell-size);
      height: var(--rdp-cell-size);
      margin: 0;
      border: 2px solid transparent;
      border-radius: 25%;
    }
    .rdp-day:hover {
      background-color: rgba(112, 49, 235, 0.2);
    }

    .rdp-day_today:not(.rdp-day_outside) {
      font-weight: bold;
    }

    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]) {
      color: black;
      background-color: var(--rdp-accent-color);
    }

    .rdp-day_selected:hover:not([disabled]) {
      color: black;
      background-color: rgba(112, 49, 235, 0.2);
    }

    .rdp-day_selected:focus:not([disabled]) {
      background-color: #6149cd;
      border: 1px solid white;
      color: white;
    }

    .rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
      background-color: #6149cd;
      border: 1px solid transparent;
      color: white;
    }

    .rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
      background-color: #1a0989;
      border: 1px solid transparent;
      color: white;
    }

    .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
      background-color: #6149cd;
      border: 1px solid transparent;
      color: white;
    }

    .rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
      background-color: #1a0989;
      border: 1px solid transparent;
      color: white;
    }

    .rdp-day_range_end.rdp-day_range_start {
      background-color: #1a0989;
      border: 1px solid white;
      color: white;
    }

    .rdp-day_range_middle {
      border-radius: 0;
      border-width: 0.3em;
      border-left: 0em;
      border-right: 0em;
      border-color: white;
    }
  `;
}

export default styles;

import styles from "./styles";

export interface Props {
    color?: string;
    percentage: number;
}

export const ImpactOnTarget = (props: Props) => {

    // hooks

    // helpers

    // renderers

    return (
        <styles.ProgressBarWrapper color={props.color} percentage={props.percentage <= 100 ? props.percentage : 100}>
           {/* <styles.ProgressBar color={props.color} percentage={props.percentage <= 100 ? props.percentage : 100}>
            </styles.ProgressBar>*/}
            <styles.negativeBarHolder color={props.color}>
                <styles.negativeBar color={props.color} percentage={props.percentage} />
            </styles.negativeBarHolder>
            <styles.positiveBarHolder  color={props.color}>
                <styles.positiveBar  color={props.color} percentage={props.percentage}/>
            </styles.positiveBarHolder>
        </styles.ProgressBarWrapper>
    );

};

// imports
import {createSlice} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearGoogleState} from "./actions";
import {GoogleState, initialGoogleState} from "./state";

// reducers
function buildCleanGoogleStateReducer(builder: ActionReducerMapBuilder<GoogleState>) {
    builder.addCase(clearGoogleState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.code = undefined;
        return state;
    });
}


// slice
export const googleSlice = createSlice({
    name: 'google',
    initialState: initialGoogleState,
    reducers: {},
    extraReducers: (builder => {
        // non async
        buildCleanGoogleStateReducer(builder);

        // async
    }),
});

// reducer
export const googleReducer = googleSlice.reducer;

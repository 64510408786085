import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

// sign-up state
export const operationsStateSelector = (state: RootState) => state.operations;

// user
export const operationsSelector = createSelector(
  operationsStateSelector,
  (state) => state.data.transportationData
);

export const lastmileMetaDataSelector = createSelector(
    operationsStateSelector,
    (state) => state.data.lastMileMetaData
);
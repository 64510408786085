import { Button, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const styledButton = styled(Button)<{ error?: boolean }>`
    &:focus {
      // remove blue border from chakra
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
    @-webkit-keyframes bounce {
      0%,
      50%,
      100% {
        -webkit-transform: translateX(0);
      }
      20% {
        transform: translateX(-10px);
      }
      40% {
        -webkit-transform: translateX(10px);
      }
      60% {
        -webkit-transform: translateX(-5px);
      }
      80% {
        -webkit-transform: translateX(5px);
      }
    }

    @keyframes bounce {
      0%,
      50%,
      100% {
        -webkit-transform: translateX(0);
      }
      20% {
        transform: translateX(-10px);
      }
      40% {
        -webkit-transform: translateX(10px);
      }
      60% {
        -webkit-transform: translateX(-5px);
      }
      80% {
        -webkit-transform: translateX(5px);
      }
    }

    -webkit-animation-name: ${(props) => (props.error ? "bounce" : "")};
    animation-name: ${(props) => (props.error ? "bounce" : "")};
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.75s;

    font-weight: normal !important;

    border: ${(props) =>
      props.error ? "1px solid red" : " 1px solid #e2e8f0"};
    background-color: unset !important;
    border-radius: 0.375rem;
  `;

  export const StyledPopoverContent = styled(PopoverContent)`
    width: max-content;
    padding: 0.5px 2px;

    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;
  export const StyledPopoverTrigger = styled(PopoverTrigger)`
    &:focus {
      // remove blue border from chakra
      box-shadow: ${(props) =>
        `0 0 0 1px ${props.theme.primaryColor}`} !important;
    }
  `;
}

export default styles;

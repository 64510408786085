import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {styles} from './styles';
import {useSelector} from "react-redux";
import {dateRangeSelector} from "../../store/ui";
import {getDatesInRange} from "../../utils/colors";
import {Popover, PopoverArrow, PopoverContent, PopoverTrigger} from "@chakra-ui/react";
import {GroupBy} from "../../features/workspace/Workspace";
import {groupDatesByWeek} from "../../utils/get-months/getLastYear";
import {groupDataByKey} from "../../utils/group-by/group-by";
import {AiOutlineDown, AiOutlineRight} from "react-icons/ai";
import SubGantt from "./sub-gantt/SubGantt";
import {useTranslation} from "react-i18next";
import {holidaysSelector} from "@store";
import {formatIsoDate} from "../../utils/date-format/dateFormat";

interface Props {
    groupByStatus: GroupBy
    labels: string[]
}

const Gantt = (props: Props) => {
    const dates = useSelector(dateRangeSelector);
    const [labels, setLabels] = useState<string[]>([]);
    const [groupedEvents, setGroupedEvent] = useState<{ [key: string]: any[] }>({});
    const [longestArr, setLongestArr] = useState<any[]>([]);
    const [width, setWidth] = useState(0)
    const [events, setEvents] = useState<any[]>([])
    const ganttRef = useRef<any>(null)
    const [expand, setExpand] = useState<boolean>(false);
    const {t} = useTranslation(['translation', 'translation']);
    const [subGanttDataRender, setSubGanttDataRender] = useState<any>({})
    const holidaysData = useSelector(holidaysSelector);

    useEffect(() => {
        setSubGanttDataRender({})
        setGroupedEvent({})
        setLongestArr([])
    }, [props?.groupByStatus])
    /*useMemo(() => {setSubGanttDataRender({})}, [props?.groupByStatus])*/

    useLayoutEffect(() => {
        setWidth(ganttRef?.current?.offsetWidth);
    }, [dates?.startDate, dates?.endDate, ganttRef?.current?.offsetWidth]);

    useEffect(() => {
        if (!!dates?.startDate && !!dates?.endDate && !!holidaysData && Array.isArray(holidaysData)) {
            const newData = holidaysData?.map((item) => {
                let endDate = item?.date;
                if (!!item?.date) {
                    const date = new Date(item?.date)
                    date.setDate(date.getDate() + 1);
                    endDate = date?.toISOString().slice(0, 10)
                }
                return {holiday: item?.name, startDate: item?.date?.slice(0, 10), endDate: endDate, id: item?.id}
            });
            const datesInRange = getDatesInRange(new Date(dates?.startDate), new Date(dates?.endDate));
            const newEvents = newData?.filter((event) => {
                if (!!event?.startDate && !!event?.endDate) {
                    return (datesInRange?.includes(event?.startDate) || labels?.includes(event?.endDate))
                }
                return
            })
            const filteredEvents = newEvents?.filter((item) => !!item);
            setEvents(filteredEvents);
        }
    }, [dates?.startDate, dates?.endDate, holidaysData])

    useEffect(() => {
        if (!!events) {
            if (props?.groupByStatus === GroupBy.DAY) {
                const newData: { [key: string]: any[] } = {};
                const sortedData = events.sort((a, b) => new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime());
                sortedData.forEach((event, i) => {
                    if (i === 0) {
                        newData[event?.startDate] = [event];
                        return
                    }
                    const keys = Object.keys(newData);
                    if (keys.some((item) => new Date(event?.startDate).getTime() <= new Date(item).getTime())) {
                        const key = keys.find((item) => new Date(event?.startDate).getTime() <= new Date(item).getTime());
                        if (!!key && key in newData) {
                            newData[key].push(event);
                        }
                    } else {
                        newData[event.startDate] = [event];
                    }
                })
                setGroupedEvent(newData)
                let length = 0;
                Object.keys(newData).forEach(key => {
                    if (newData[key].length > length) {
                        length = newData[key].length
                    }
                })
            }
            if (props?.groupByStatus === GroupBy.MONTH) {
                const monthsData = {};
                events?.forEach((event) => {
                    if (!!event?.startDate) {
                        if (event?.startDate?.slice(0, 7) in monthsData) {
                            monthsData[event?.startDate?.slice(0, 7)].push({
                                ...event,
                                startPoint: event?.startDate?.slice(0, 7) + "-01"
                            })
                        } else {
                            monthsData[event?.startDate?.slice(0, 7)] = [{
                                ...event,
                                startPoint: event?.startDate?.slice(0, 7) + "-01"
                            }]
                        }
                    }
                })
                setGroupedEvent(monthsData)
                let length = 0;
                Object.keys(monthsData).forEach(key => {
                    if (monthsData[key].length > length) {
                        length = monthsData[key].length
                    }
                })
                /*setLongestArr(new Array(length).fill(0));*/
            }
            if (props?.groupByStatus === GroupBy.WEEK) {
                const formatData = events?.map((item) => {
                    return {...item, activityDate: item.startDate}
                })
                const dataByYearAndWeeks = groupDatesByWeek(formatData);
                const objectData = Object.keys(dataByYearAndWeeks).map((key) => {
                    return Object.keys(dataByYearAndWeeks[key]).map((subKey) => {
                        return dataByYearAndWeeks[key][subKey].map((item: any) => {
                            return {
                                ...item, startPoint: subKey,
                                firstDate: subKey.slice(0, 5).replace(/\./g, '-') + "-" + key
                            }
                        })
                    })
                });
                const groupedData = groupDataByKey("startPoint", objectData?.flat().flat())
                setGroupedEvent(groupedData)
                let length = 0;
                Object.keys(groupedData).forEach(key => {
                    if (groupedData[key].length > length) {
                        length = groupedData[key].length
                    }
                })
                /*setLongestArr(new Array(length).fill(0));*/
            }
        }
    }, [events, props?.groupByStatus])

    useEffect(() => {
        if (!!dates?.startDate && !!dates?.endDate) {
            const datesInRange = getDatesInRange(new Date(dates?.startDate), new Date(dates?.endDate));
            if (props?.groupByStatus === GroupBy.DAY) {
                setLabels(datesInRange);
            }
            if (props?.groupByStatus === GroupBy.MONTH) {
                setLabels(datesInRange);
            }
            if (props?.groupByStatus === GroupBy.WEEK) {
                setLabels(datesInRange);
            }
        }
    }, [dates?.startDate, dates?.endDate, props?.groupByStatus])

    const handleOnClick = (label: string) => {
        const index = props?.labels?.indexOf(label);
    };

    const row = (name: string, date: string, key: number) => {
        return <styles.ToolTipRow key={key}>
            <styles.EventName>
                {name}
            </styles.EventName>
            <styles.Date>
                {formatIsoDate(date)}
            </styles.Date>
        </styles.ToolTipRow>
    }

    const renderTooltip = (events: { holiday: string, startDate: string, key: number }[]) => {
        return (
            <PopoverContent>
                <PopoverArrow/>
                <styles.StyledPopoverBody>
                    <styles.PopoverWrapper>
                        {events.map((item, i) => row(item?.holiday, item?.startDate, i))}
                    </styles.PopoverWrapper>
                </styles.StyledPopoverBody>
            </PopoverContent>
        )
    }

    const returnEventsInRange = (rangeOfDatesBetweenPoints: string[], i: number, lastIndex: number) => {
        if (i !== 0 /*&& props?.groupByStatus !== GroupBy.MONTH*/ && i !== lastIndex) {
            /*rangeOfDatesBetweenPoints?.shift()*/
        }
        if (props?.groupByStatus === GroupBy.WEEK) {
            if (rangeOfDatesBetweenPoints?.length % 7 === 0) {
                rangeOfDatesBetweenPoints.pop();
            }
            const events = Object.keys(groupedEvents)?.map((key) => {
                const elementArray = groupedEvents[key];
                return elementArray?.map((item) => {
                    const itemDate = item?.firstDate?.split("-").reverse().join("-");

                    if (rangeOfDatesBetweenPoints?.includes(itemDate)) {
                        return item
                    } else {
                        return
                    }

                });
            })?.flat()?.filter((item) => !!item)
            return events
        }
        if (props?.groupByStatus === GroupBy.MONTH) {
            const events = Object.keys(groupedEvents)?.map((key) => {
                const element = groupedEvents[key];
                return element?.map((item: any) => {
                    if (rangeOfDatesBetweenPoints?.includes(item?.startPoint)) {
                        return item;
                    } else {
                        return
                    }
                })
            })?.flat()?.filter((item) => !!item)
            return events
        }
        if (props?.groupByStatus === GroupBy.DAY) {
            const eventsInRange = Object.keys(groupedEvents)?.map((key) => {
                if (rangeOfDatesBetweenPoints?.includes(key)) {
                    return groupedEvents[key]
                } else {
                    return
                }
            })?.filter((item) => !!item).flat();
            return eventsInRange
        }
        return []
    }

    const renderDot = (label: string, i: number, lastIndex: number) => {

        let numberToDisplay;
        let allEvents: any[] = [];

        if (props?.groupByStatus === GroupBy.DAY) {
            if (i === props?.labels?.length - 1) {  // last label
                const lastDay = labels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(lastDay));
                    const eventsToDisplay = returnEventsInRange(rangeOfDatesBetweenPoints, i, lastIndex);
                    if (eventsToDisplay?.length > 0) {
                        const data = {[label]: eventsToDisplay};
                        if (!(label in subGanttDataRender)) {
                            setSubGanttDataRender((prev: any) => {
                                return {...prev, ...data}
                            })
                        }
                    }
                    numberToDisplay = eventsToDisplay?.length;
                    allEvents = eventsToDisplay
                }
            } else {

                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(props?.labels[i + 1]));


                rangeOfDatesBetweenPoints.pop();


                const eventsToDisplay = returnEventsInRange(rangeOfDatesBetweenPoints, i, lastIndex);

                if (eventsToDisplay?.length > 0) {
                    const data = {[label]: eventsToDisplay};
                    if (!(label in subGanttDataRender)) {
                        setSubGanttDataRender((prev: any) => {
                            return {...prev, ...data}
                        })
                    }
                }
                numberToDisplay = eventsToDisplay?.length;
                allEvents = eventsToDisplay

            }
        }
        if (props?.groupByStatus === GroupBy.WEEK) {
            if (i === props?.labels?.length - 1) {  // last label
                const formatedLabel = (label?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1"));

                const lastDay = labels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(formatedLabel), new Date(lastDay));
                    const eventsToDisplay = returnEventsInRange(rangeOfDatesBetweenPoints, i, lastIndex);
                    if (eventsToDisplay?.length > 0) {
                        const data = {[label]: eventsToDisplay};
                        if (!(label in subGanttDataRender)) {
                            setSubGanttDataRender((prev: any) => {
                                return {...prev, ...data}
                            })
                        }
                    }
                    numberToDisplay = eventsToDisplay?.length;
                    allEvents = eventsToDisplay
                }
            } else {
                const formatedLabel = (label?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1"));

                let nextDateLastDayYear = "20" + props?.labels[i + 1]?.slice(12);
                const nextDateLastDay = props?.labels[i + 1]?.slice(6, 11) + "." + nextDateLastDayYear;
                const nextDateFirstDay = props?.labels[i + 1]?.slice(0, 5) + "." + nextDateLastDayYear;
                const firstDate = nextDateFirstDay.replace(/\./g, '/');
                const secondDate = nextDateLastDay.replace(/\./g, '/');
                const dateParts = firstDate.split("/");
                const secondDateParts = secondDate.split("/");
                // @ts-ignore
                const firstDateFormat = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                // @ts-ignore
                const secondDateFormat = new Date(+secondDateParts[2], secondDateParts[1] - 1, +secondDateParts[0]);
                if (firstDateFormat > secondDateFormat) {
                    nextDateLastDayYear = (parseInt(nextDateLastDayYear) - 1).toString();
                }
                const nextDate = props?.labels[i + 1].slice(0, 5).replace(/\./g, '-') + "-" + "20" + props?.labels[i + 1]?.slice(12);
                const nextDateFormat = nextDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(nextDateLastDayYear + formatedLabel?.slice(4)), new Date(nextDateLastDayYear + nextDateFormat?.slice(4)));
                rangeOfDatesBetweenPoints.pop();
                const eventsToDisplay = returnEventsInRange(rangeOfDatesBetweenPoints, i, lastIndex);
                if (eventsToDisplay?.length > 0) {
                    const data = {[label]: eventsToDisplay};
                    if (!(label in subGanttDataRender)) {
                        setSubGanttDataRender((prev: any) => {
                            return {...prev, ...data}
                        })
                    }
                }
                numberToDisplay = eventsToDisplay?.length;
                allEvents = eventsToDisplay
            }
        }
        if (props?.groupByStatus === GroupBy.MONTH) {
            if (i === props?.labels?.length - 1) {  // last label
                const lastDay = labels?.at(-1);
                if (!!lastDay) {
                    const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(lastDay));
                    const eventsInRange = Object.keys(groupedEvents)?.map((key) => {
                        const arrayElement = groupedEvents[key];
                        return arrayElement?.map((item) => {
                            if (rangeOfDatesBetweenPoints?.includes(item?.startDate)) {
                                return item
                            } else {
                                return
                            }
                        })
                    })?.flat().filter((item) => !!item);

                    if (eventsInRange?.length > 0) {
                        const data = {[label]: eventsInRange};
                        if (!(label in subGanttDataRender)) {
                            setSubGanttDataRender((prev: any) => {
                                return {...prev, ...data}
                            })
                        }
                    }

                    allEvents = eventsInRange
                    numberToDisplay = eventsInRange?.length;
                }
            } else {
                const nextDate = props?.labels[i + 1];
                const rangeOfDatesBetweenPoints = getDatesInRange(new Date(label), new Date(nextDate));
                if (i === 0) {
                    rangeOfDatesBetweenPoints.pop();
                }

                const eventsInRange = Object.keys(groupedEvents)?.map((key) => {
                    const arrayElement = groupedEvents[key];
                    return arrayElement?.map((item) => {
                        if (rangeOfDatesBetweenPoints?.includes(item?.startDate)) {
                            return item
                        } else {
                            return
                        }
                    })
                })?.flat().filter((item) => !!item);

                if (eventsInRange?.length > 0) {
                    const data = {[label]: eventsInRange};
                    if (!(label in subGanttDataRender)) {
                        setSubGanttDataRender((prev: any) => {
                            return {...prev, ...data}
                        })
                    }
                }

                allEvents = eventsInRange
                numberToDisplay = eventsInRange?.length;
            }
        }

        return (
            <Popover trigger={"hover"} placement='top'>
                <PopoverTrigger>
                    {allEvents?.length > 0 ?
                        <styles.GanttItemWrapper onClick={() => {
                            if (allEvents?.length > 0) {
                                handleOnClick(label)
                            }
                        }
                        } key={i}>
                            {!!numberToDisplay ? numberToDisplay : ""}
                        </styles.GanttItemWrapper>
                        :
                        <styles.GanttItemWrapperPlaceholder/>
                    }
                </PopoverTrigger>
                {allEvents?.length > 0 && renderTooltip(allEvents)}
            </Popover>
        )
    }
    useEffect(() => {
        if (!!subGanttDataRender) {
            let length = 0;
            Object.keys(subGanttDataRender).forEach(key => {
                if (subGanttDataRender[key].length > length) {
                    length = subGanttDataRender[key].length
                }
            })
            setLongestArr(new Array(length).fill(0));
        }
    }, [subGanttDataRender, props?.labels, props?.groupByStatus])

    const newLabels = props?.labels?.map((label) => {
        return label?.slice(0, 5).replace(/\./g, '-') + "-" + "20" + label?.slice(12)
    });
    const renderLabels = () => {
        const lastPoint = props?.labels?.at(-1);
        let missingDaysWidth = 0;
        if (!!lastPoint && labels?.indexOf(lastPoint) > -1) {
            const index = labels?.indexOf(lastPoint);
            const missingDays = labels?.length - index - 1
            missingDaysWidth = ((width) / labels?.length) * missingDays;
        }
        return (
            <styles.GanttLabelsWrapper ref={ganttRef}>
                <styles.GanttSubWrapper width={width - missingDaysWidth}>
                    {
                        props?.groupByStatus === GroupBy.DAY ?
                            props?.labels?.map((item, i) => renderDot(item, i, props?.labels?.length - 1)) : <></>
                    }
                    {props?.groupByStatus === GroupBy.WEEK ?
                        newLabels?.map((item, i) => renderDot(item, i, props?.labels?.length - 1))
                        : <></>}
                    {props?.groupByStatus === GroupBy.MONTH ?
                        props?.labels?.map((item, i) => renderDot(item + "-01", i, props?.labels?.length - 1)) : <></>}
                </styles.GanttSubWrapper>
            </styles.GanttLabelsWrapper>
        )
    }

    const subGanttData = Object.keys(groupedEvents)?.map((key) => {
        return groupedEvents[key]?.map((item: any) => item)
    })?.flat();
    return (
        <styles.CompWrapper>
            <styles.Wrapper>
                <styles.LeftButton onClick={() => {
                    setExpand(prev => !prev)
                }}>
                    {t('EVENTS', {ns: 'translation'})}
                    {expand ? <AiOutlineDown/> : <AiOutlineRight/>}
                    {/*{events?.length}*/}
                </styles.LeftButton>
                {renderLabels()}
            </styles.Wrapper>
            <styles.SubGanttWrapper>
                {expand ?
                    longestArr?.map((item, i) => {
                        if (props?.groupByStatus === GroupBy.DAY) {
                            const fixedData = Object.keys(subGanttDataRender)?.map((key) => {
                                if (key.length > 8) {
                                    const element = subGanttDataRender[key];
                                    const uniqueIds: any[] = [];
                                    const unique = element?.filter((element: any) => {
                                        const isDuplicate = uniqueIds?.includes(element?.id);

                                        if (!isDuplicate) {
                                            uniqueIds.push(element?.id);
                                            return true;
                                        }
                                        return false;
                                    });

                                    return unique[i]
                                } else {
                                    return
                                }
                            })

                            return <SubGantt key={i} data={fixedData} displayLabels={props?.labels} allLabels={labels}
                                             groupByStatus={props?.groupByStatus}/>

                        }
                        const rowToRender = Object.keys(subGanttDataRender)?.map((key) => {
                            const element = subGanttDataRender[key];
                            const uniqueIds: any[] = [];
                            const unique = element?.filter((element: any) => {
                                const isDuplicate = uniqueIds?.includes(element?.id);

                                if (!isDuplicate) {
                                    uniqueIds.push(element?.id);
                                    return true;
                                }
                                return false;
                            });

                            return unique[i]
                        })
                        return <SubGantt key={i} data={rowToRender} displayLabels={props?.labels} allLabels={labels}
                                         groupByStatus={props?.groupByStatus}/>
                    })
                    : <></>}
            </styles.SubGanttWrapper>
        </styles.CompWrapper>
    );
};

export default Gantt;

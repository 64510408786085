import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
  `;

  export const UploadIcon = styled.div`
    background-color: white;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
  &:hover{
    cursor: pointer;
  }
  `

  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;
  export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `;
  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 10px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;
  export const ButtonsHolder = styled.div`
    margin: 25px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
}

export default styles;

import { CarrierEnum } from "../../services/operations/operation.service";
import { dhlIcon, upsIcon } from "./carrier-svgs";

export const getCarrierNameByEnum = (carrierEnum: CarrierEnum): string => {
  switch (carrierEnum) {
    case CarrierEnum.DHL:
      return "DHL";
    case CarrierEnum.FEDEX:
      return "Fedex";
    case CarrierEnum.UPS:
      return "UPS";
    default:
      return "-";
  }
};
export const getCarrierSvgByEnum = (carrierEnum: CarrierEnum) => {
  switch (carrierEnum) {
    case CarrierEnum.DHL:
      return dhlIcon();
    case CarrierEnum.FEDEX:
      return upsIcon();
    case CarrierEnum.UPS:
      return upsIcon();
    default:
      return;
  }
};

// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

// locals

import { ChannelsState, initalChannelsState } from "./state";
import {changeChannelName, fetchChannelById, fetchChannels} from "./thunks";

// reducers
// clean sign-up state
/* function buildCleanAuthStateReducer(
  builder: ActionReducerMapBuilder<ChannelsState>
) {
  builder.addCase(clearAuthState, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.user = undefined;
    return state;
  });
} */
/* function buildCleanAuthPasswordStateReducer(
  builder: ActionReducerMapBuilder<ChannelsState>
) {
  builder.addCase(resetPasswordStatusAction, (state, action) => {
    console.log("d");
    state.resetPasswordSuccess = action.payload.resetPasswordSuccess;
    return state;
  });
} */

// fetch channels
function buildFetchChannels(builder: ActionReducerMapBuilder<ChannelsState>) {
  builder.addCase(fetchChannels.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(fetchChannels.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.channels = action?.payload;
    return state;
  });
  builder.addCase(fetchChannels.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    return state;
  });
}

// change channel name
function buildChangeChannelName(builder: ActionReducerMapBuilder<ChannelsState>) {
  builder.addCase(changeChannelName.pending, (state, action) => {
    return state;
  });
  builder.addCase(changeChannelName.fulfilled, (state, action) => {
    return state;
  });
  builder.addCase(changeChannelName.rejected, (state, action) => {
    return state;
  });
}

// fetch channel by id
function buildFetchChannelById(
  builder: ActionReducerMapBuilder<ChannelsState>
) {
  builder.addCase(fetchChannelById.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(fetchChannelById.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.channels = action?.payload;
    return state;
  });
  builder.addCase(fetchChannelById.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    return state;
  });
}

// slice
export const channelsSlice = createSlice({
  name: "channels",
  initialState: initalChannelsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildFetchChannels(builder);

    buildChangeChannelName(builder);

    // async
    buildFetchChannelById(builder);
  },
});

// reducer
export const channelsReducer = channelsSlice.reducer;

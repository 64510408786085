import {ExploresEnum} from "../../store/ui";

export const getExploreByEnum = (exploreEnum : ExploresEnum) :string => {

    switch (exploreEnum) {
        case ExploresEnum.PLACEHOLDER:
            return 'PLACEHOLDER';
        case ExploresEnum.PROFILE:
            return 'PROFILE';
        case ExploresEnum.KPI_FRAMEWORK:
            return 'KPI_FRAMEWORK';
        case ExploresEnum.GENERAL:
            return 'GENERAL';
        case ExploresEnum.PLANNING:
            return 'PLANNING';
        case ExploresEnum.NET_REVENUE:
            return 'NET_REVENUE';
        case ExploresEnum.PNL_SETTINGS:
            return 'PNL_SETTINGS';
        case ExploresEnum.GROSS_REVENUE:
            return 'GROSS_REVENUE';
        case ExploresEnum.CONVERSION_RATE:
            return 'CONVERSION_RATE';
        case ExploresEnum.SESSIONS:
            return 'SESSIONS';
        case ExploresEnum.COGS:
            return 'COGS';
        case ExploresEnum.MARKETING_MAPPING:
            return 'MARKETING_MAPPING';
        case ExploresEnum.SALES_CHANNELS:
            return 'SALES_CHANNELS';
        case ExploresEnum.MARKETING_SPEND:
            return 'MARKETING_SPEND';
        case ExploresEnum.BLENDED_ROAS:
            return 'BLENDED_ROAS';
        case ExploresEnum.ORDERS:
            return 'ORDERS';
        case ExploresEnum.COST_PER_ORDER:
            return 'COST_PER_ORDER';
        case ExploresEnum.AVERAGE_ORDERS_VALUE:
            return 'AVERAGE_ORDERS_VALUE';
        case ExploresEnum.GROSS_PROFIT:
            return 'GROSS_PROFIT';
        case ExploresEnum.CONTRIBUTION_PROFIT:
            return 'CONTRIBUTION_PROFIT';
        case ExploresEnum.PENDING_DEVICES:
            return 'PENDING_DEVICES';
        case ExploresEnum.HOLDING_DEVICES:
            return 'HOLDING_DEVICES';
        case ExploresEnum.APPROVED_DEVICES:
            return 'APPROVED_DEVICES';
        case ExploresEnum.NUMBER_OF_DEVICES:
            return 'NUMBER_OF_DEVICES';
        case ExploresEnum.INCOMPLETE_ORDERS:
            return 'INCOMPLETE_ORDERS';
        case ExploresEnum.REMAINING_COST:
            return 'REMAINING_COST';
        case ExploresEnum.CAC:
            return 'CAC';
        case ExploresEnum.TRANSACTION_FEES:
            return 'TRANSACTION_FEES';
        default:
            return '';
    }
}
import wideViewsStyles from "./styles";
import { OverTimeGraph } from "../../over-time-graph/OverTimeGraph";
import {AcrossTable, getProgressBarColor} from "components/across-table/AcrossTable";
import { useTranslation } from "react-i18next";
import { Graph } from "../../graph-node-card/Graph";
import { fakeData2 } from "components/graph-node-card/FakeDataTree";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import { Effort } from "../../../models/effort";
import {
    IconAcrossChannels,
    IconAcrossProducts,
    IconCalendar,
    IconClearFiltersArrow,
    IconDiamond,
    IconShare
} from "../../../utils/icons/explore";

import React, { useEffect, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import {  kpiStateSelector } from "store/kpis";
import { Kpi } from "models/kpi";
import { CurrencyCode, organizationDataLanguageSelector, organizationSelector } from "@store";
import { IntegrationPlatform } from "@models";
import { getLastYear, groupDatesByWeek, sumData } from "utils/get-months/getLastYear";
import { currencyOptions } from "utils/currency/currencyOptions";
import {currencyFormat, getOvertimeGraphDataForExplore} from "utils/kpi-render/kpiCalculation";
import {dateRangeSelector, ExploresEnum, openDefaultExploreView} from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { GroupBy } from "features/workspace/Workspace";
import { KpisEnum } from "models/kpi-settings";
import { getDatesInRange } from "utils/colors";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";
import {Box, Spinner, useDisclosure} from "@chakra-ui/react";
import { getChannelNameById } from "utils/get-channel-name/getChannelNameById";
import { Channel, channelSelector } from "store/channels";
import { effortsSelector } from "store/efforts";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";
import {columns} from "../../../utils/effortTableHeaders/headers"
import {
    ExploreTableDataEnum,
    ExploreTableFooterTypeEnum,
    GenericExploreTable
} from "../../generic-explore-table/GenericExploreTable";
import {ProgressBar} from "../../progress-bar/ProgressBar";
import {Row} from "react-table";

export interface ExploreProps {
    title?: string;
}

interface AcrossTableData {
    channelName?: string;
    vsTarget?: number;
    grossRevenue?: number | string;
    shareOfTotal?: number;
    impactOnTarget?: number;

}
export const AovExplore = (props: ExploreProps) => {
    // hooks
    const { isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose } = useDisclosure();  //

    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const { t } = useTranslation(['translation', 'translation']);
    const [kpiData, setKpiData] = useState<Kpi[]>([])
    const [targetKpiData, setTargetKpiData] = useState<Kpi[]>([]);

    const [filteredByDatesData, setFilteredByDatesData] = useState<Kpi[]>([]);
    const kpis = useSelector(kpiStateSelector);
    const [aov, setAov] = useState(0);
    const [filteredByGroupData, setFilteredByGroupData] = useState<{}>();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<{
        channelName: string;
        grossRevenue: number | string;
        shareOfTotal: number;
        vsTarget: number;
        impactOnTarget: number;
    }[]>([]);
    const organizationId = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const datesSelector = useSelector(dateRangeSelector);
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const [graphData, setGraphData] = useState<any>();
    const channelsData = useSelector(channelSelector);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any>([]);
    const effortsData = useSelector(effortsSelector);
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const [productsData, setProductsData] = useState<any[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [productsFilteredByDates, setProductsFilteredByDates] = useState<any[]>([]);
    const currentCurrency = useCurrencySign();

    useEffect(() => {
        //filter efforts by kpi
        if (!!effortsData) {
            const filteredEfforts = effortsData?.data.filter((item) => {
                return item?.kpi === KpisEnum.AVERAGE_ORDERS_VALUE;
            });
            setEffortsTable(filteredEfforts);
        }

    }, [effortsData]);

    useEffect(() => {
        if (!!kpiData && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            let data = kpiData;
            if(selectedChannels?.length > 0){
                const allChannels = selectedChannels.map((item) => item?.original?.callbackId);
                data = data?.filter((item) => allChannels?.includes(item?.wvChannelId))
            }
            else {
                data = kpiData
            }
            const graphData = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                "aov"
            );
            setActualDataChart(graphData.data);
            setLabels(graphData.labels);
        }
    }, [
        kpiData,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        selectedChannels
    ]);

    useEffect(() => {
        if (
            !!targetKpiData &&
            !!datesSelector?.startDate &&
            !!datesSelector?.endDate
        ) {
            let data = targetKpiData;
            if(selectedChannels?.length > 0){
                const allChannels = selectedChannels.map((item) => item?.original?.callbackId);
                data = data?.filter((item) => allChannels?.includes(item?.wvChannelId))
            }else {
                data = targetKpiData
            }

            const graphData = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                "aov"
            );
            setTargetDataChart(graphData.data);
            setLabels(graphData.labels);
        }
    }, [
        targetKpiData,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        selectedChannels
    ]);

    useEffect(() => {
        if (!!filteredByDatesData) {
            const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'platform', 'impressions', 'imageName', 'market', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];
            const summedData = sumData(filteredByDatesData, keysToIgnore);
            if (summedData?.orders !== undefined && summedData?.grossRevenue !== undefined) {
                const aov = summedData?.grossRevenue / summedData?.orders;
                const graphDataToRender = {
                    title: "AOV",
                    value: currencyFormat(aov, 0, ""),
                    vsTargetPercentage: summedData?.grossRevenue / summedData?.grossRevenue,
                    isRoot: true,
                    children: [
                        {
                            title: "New Order AOV",
                            value: undefined,
                            vsTargetPercentage: summedData['newCustomers'] / summedData['orders'],
                            isRoot: false,
                            children: []
                        },
                        {
                            title: "Returning Order AOV",
                            value: undefined,
                            vsTargetPercentage: 95,
                            isRoot: false,
                            children: [
                                {
                                    title: "Avg Items per Order",
                                    value: undefined,
                                    vsTargetPercentage: summedData['newCustomers'] / summedData['orders'],
                                    isRoot: false,
                                    children: []
                                },
                                {
                                    title: "Avg Item Price",
                                    value: undefined,
                                    vsTargetPercentage: summedData['newCustomers'] / summedData['orders'],
                                    isRoot: false,
                                    children: []
                                },
                            ]
                        }
                    ]
                };
                setGraphData(graphDataToRender);
            }
        }

    }, [currentCurrency, filteredByDatesData, startDate, endDate]);

    useEffect(() => {
        if (!!kpis.data?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.data?.data.filter((item) => {
                    return datesRange.includes(item.activityDate?.slice(0, 10));
                });
                setFilteredByDatesData(filteredData);
                setKpiData(kpis.data?.data);
            }
            else {
                setFilteredByDatesData(kpis.data?.data);
                setKpiData(kpis.data?.data);
            }
        }
    }, [kpis.data?.data, kpiData, startDate, endDate]);
    useEffect(() => {
        if (!!kpis.financeKpi?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.financeKpi?.data.filter((item) => {
                    return datesRange.includes(item?.orderDate?.slice(0, 10));
                });
                setProductsFilteredByDates(filteredData);
            }
            else {
                setProductsFilteredByDates(kpis.financeKpi?.data);
            }
        }
    }, [kpis?.financeKpi?.data, kpiData, startDate, endDate]);

    useEffect(() => {
        if (!!filteredByDatesData && !!channelsData) {
            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const rowData: { aov?: number, channelName?: string, orders: number, shareOfTotal?: number, vsTarget?: number, impactOnTarget?: number, callbackId?: string; grossRevenue?: number }[] = [];
            const channels = {};
            filteredByDatesData?.forEach((item) => {
                if (!!channels[item?.wvChannelId]) {
                    channels[item?.wvChannelId]?.push(item);
                }
                else {
                    channels[item?.wvChannelId] = [item];
                }
            });

            Object.keys(channels).forEach((key) => {
                const summedChannel = sumData(channels[key], keysToIgnore);
                rowData.push({ orders: summedChannel["orders"], aov: !!summedChannel["orders"] && !!summedChannel["grossRevenue"]  ? summedChannel["grossRevenue"] / summedChannel["orders"] : 0, channelName: getChannelNameById(key, channelsData), callbackId: key, grossRevenue: summedChannel['grossRevenue'] });
            });
            let totalGrossRevenue = 0;
            rowData?.forEach((item) => {
                totalGrossRevenue = totalGrossRevenue + item?.orders;
            });
            const newRowData = rowData?.map((item) => {
                return {
                    ...item,
                    shareOfTotal: Math.round((item?.orders / totalGrossRevenue) * 100 * 100) / 100,
                };
            });
            setAcrossChannelsData(newRowData);
        }
    }, [channelsData, filteredByDatesData]);

    useEffect(() => {
        if (!!filteredByDatesData) {
            let data = filteredByDatesData;
            if(selectedChannels?.length > 0){
                const allChannels = selectedChannels.map((item) => item?.original?.callbackId);
                data = data?.filter((item) => allChannels?.includes(item?.wvChannelId))
            }else {
                data = filteredByDatesData
            }
            const summedData = sumData(data, []);
            if (isNaN(summedData['grossRevenue'] / summedData['orders'])) {
                setAov(0);
            } else {
                setAov(summedData['grossRevenue'] / summedData['orders']);
            }
        }
    }, [filteredByDatesData, selectedChannels]);


    useEffect(() => {
        let counter = 0;
        if (filteredByGroupData) {
            Object.keys(filteredByGroupData).forEach(key => {
                counter = filteredByGroupData[key] + counter;
            });
            const tableData = Object.keys(filteredByGroupData).map(key => {
                return {
                    channelName: IntegrationPlatform[key],
                    grossRevenue: (filteredByGroupData[key]),
                    shareOfTotal: filteredByGroupData[key] / counter * 100,
                    vsTarget: 105,
                    impactOnTarget: 1.5
                };
            });
            setTableData(tableData);
        }
    }, [filteredByGroupData]);

    useEffect(() => {
        if (!!productsFilteredByDates && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const datesRange = getDatesInRange(new Date(datesSelector?.startDate), new Date(datesSelector?.endDate));
            const filteredData = productsFilteredByDates.filter((item) => {
                return datesRange.includes(item?.orderDate?.slice(0, 10));
            });
            const selectedRowsChannels: string[] = selectedChannels.map((row) => row?.original?.callbackId);
            let filtered: any = [];
            if (selectedRowsChannels.length > 0) {
                filtered = filteredData?.filter((order) => selectedRowsChannels.includes(order?.wvChannelId));
            } else {
                filtered = filteredData;
            }
            const sumOrders: any = {};
            filtered.forEach((order: any) => {
                if (!sumOrders[order?.sku]) {
                    sumOrders[order?.sku] = [order];
                }
                else {
                    sumOrders[order?.sku].push(order);
                }
            });

            const summedData: any[] = [];
            Object.keys(sumOrders).forEach((key) => {
                const summedKey = { ...sumData(sumOrders[key], ['wvChannelId', 'orderId', 'city', 'createdAt', 'fulfillmentChannel', 'id', 'orderDate', 'orderTime', 'productName', 'purchasePoint', 'sku', 'wvChannelId', 'updatedAt']), itemName: key };
                summedData.push(summedKey);
            });

            let totalRevenue = 0;
            summedData.forEach((item) => {
                totalRevenue = totalRevenue + item?.totalPaid;
            });
            const tableData = summedData.map((order): AcrossTableData => {
                return { channelName: order?.itemName, grossRevenue: order?.qty, shareOfTotal: ((order?.totalPaid / totalRevenue) * 100) };
            });
            /* const orders = filteredData.map((order): AcrossTableData => {
                return {channelName: order?.productName, grossRevenue: order?.totalPaid,  }
            }); */
            setProductsData(tableData);

        }

    }, [datesSelector?.endDate, datesSelector?.startDate, productsFilteredByDates, selectedChannels]);

    // helpers


    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(new Date(datesSelector.startDate));
            setEndDate(new Date(datesSelector.endDate));
        }
    };


    const onApplyDates = () => {

    };


    const openShareModal = () => {
        onShareOpen();
    };

    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton onClick={() => openShareModal()}>
            <IconShare />
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderDatePicker = () => {
        return (
            <>
                {
                    !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => { onApplyDates(); }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)} />
                        : <DatepickerRange onApply={(e) => { onApplyDates(); }} defaultsDate={datesSelector.preset} />
                }
            </>
        )

            ;
    };

    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow />
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };


    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                    <IconCloseOverlay />
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    {/*{props.title}*/}
                    {t('AOV', { ns: 'translation' })}
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderResetFilters()}
                    {renderDatePicker()}
                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currencyFormat(aov, 0, currentCurrency)}
                </wideViewsStyles.Value>
                {/* <wideViewsStyles.BadgeWrapper> // TODO .V2
                    <TargetBadge color={'#57a11e'} percentage={100} />
                </wideViewsStyles.BadgeWrapper> */}
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };
    const [ganttLabels, setGanttLabels] = useState<string[]>([])

    return (
        <wideViewsStyles.Wrapper>
            <ShareKpi exploreEnum={ExploresEnum.AVERAGE_ORDERS_VALUE} isOpen={isShareOpen} onClose={onShareClose} onOpen={onShareOpen} />
            <EffortsModal dataToShow={{ kpi: "AOV" }} isOpen={isEffortsOpen} onClose={onEffortsClose} onOpen={onEffortsOpen} />

            {renderHeader()}
            <wideViewsStyles.SectionOne>
                <OverTimeGraph  exploreGraph={true} displayGantt={true} setGanttLabels={setGanttLabels}  clearFilters={resetFilters} groupByStatus={groupByStatus}
                                setGroupBy={setGroupByStatus} kpiEnum={KpisEnum.AVERAGE_ORDERS_VALUE} setStartDate={setStartDate} setEndDate={setEndDate} actual={actualDataChart} target={targetDataChart} labels={labels} />
            </wideViewsStyles.SectionOne>
            {tableData !== undefined ? <wideViewsStyles.SectionTwo>
                <GenericExploreTable
                    icon={<IconAcrossChannels />}
                    setSelectedRows={setSelectedChannels}
                    data={acrossChannelsData}
                    defaultSortByKey={"shareOfTotal"}
                    channelsTable={true}

                    headers={[
                        {
                            header: "Channel",
                            accessor: "channelName",
                            footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                        },
                        {
                            header: "AOV",
                            accessor: "aov",
                            cellType: ExploreTableDataEnum.CURRENCY,
                            footer: (cell, allRows) => {
                                let totalGrossRevenue = 0;
                                let totalOrders = 0;
                                allRows?.forEach((row:  Row<{grossRevenue?: number, orders?: number}>) => {
                                    if(!!row?.original?.grossRevenue){
                                        totalGrossRevenue += row?.original?.grossRevenue
                                    }
                                    if(!!row?.original?.orders){
                                        totalOrders += row?.original?.orders
                                    }
                                })
                                return !!totalGrossRevenue && !!totalOrders ? (totalGrossRevenue / totalOrders) : 0
                            },
                        },
                        {
                            header: "Orders",
                            accessor: "orders",
                            cellType: ExploreTableDataEnum.NUMBER,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        {
                            header: "Gross Revenue",
                            accessor: "grossRevenue",
                            cellType: ExploreTableDataEnum.CURRENCY,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        {
                            header: "Share Of Total",
                            accessor: "shareOfTotal",
                            cellType: ExploreTableDataEnum.PERCENTAGE,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                    ]}
                    height={400}
                    tableHeight={320}
                    title={t("ACROSS_CHANNELS", { ns: "translation" })}
                />
               {/* <AcrossTable
                    icon={<IconAcrossProducts />}
                    headers={[
                        t('CARTS', { ns: 'translation' }),
                        t('VS_TARGET', { ns: 'translation' }),
                        t('ORDERS', { ns: 'translation' }),
                        t('SHARE_OF_TOTAL', { ns: 'translation' }),
                        t('IMPACT_ON_TARGET', { ns: 'translation' })
                    ]}
                    data={[]}
                    noCurrencyFormat
                    title={t('ACROSS_CARTS', { ns: 'translation' })} />*/}
            </wideViewsStyles.SectionTwo> : null}

            {/*  <wideViewsStyles.SectionThree>
                {!!graphData && <Graph graphData={graphData} title={t('SUPPORTING_KPIS', { ns: 'translation' })} />}
            </wideViewsStyles.SectionThree> */}
            <wideViewsStyles.SectionFour>
                <styles.Card>
                    <styles.Header>
                        <styles.Title>
                            <span style={{ marginRight: '5px' }}>
                                {<IconDiamond />}
                            </span>
                            {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        </styles.Title>
                        <DownloadButton onClick={() => console.log('download')} />
                    </styles.Header>
                    {!!effortsTable ? <EffortsTable
                        title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        data={effortsTable}
                        cameFromExplore
                        onOpen={onEffortsOpen}

                        headers={columns}
                    /> : <Spinner colorScheme={"purple"} />}

                </styles.Card>
            </wideViewsStyles.SectionFour>
        </wideViewsStyles.Wrapper>
    );
};

// imports
import {createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {initialUIState, UIState} from "./state";

// locals
import {
    clearApplyBarState,
    clearApplyModalState,
    clearUIState,
    openApplyBar,
    openApplyModal,
    openDefaultExploreView,
    openSecondaryExplore,
    setDateRangeState,
    setCardSelected,
    setGlobalLoading,
    setChannels,
    setNavigationTab,
    setAppLoading,
    addToasts, removeToasts, openSecondaryApplyBar, openSecondaryApplyModal
} from "./actions";
import {fetchAccounts, getCsv} from "./thunks";
import {KpiState, setTargetStats} from "../kpis";
import {v4 as uuidv4} from "uuid";

// reducers
// clean ui state
function buildCleanUIStateReducer(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(clearUIState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.defaultExplore.isDefaultExploreOpen = false;
        state.defaultExplore.exploreEnum = undefined;
        return state;
    });
}

function buildSetGlobalStateReducer(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setGlobalLoading, (state, action) => {
        state.globalLoading = action?.payload.isLoading;
    });
}

function buildSetAppLoader(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setAppLoading, (state, action) => {
        state.appLoad = action?.payload.isLoading;
    });
}

function buildNavigationTabReuder(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setNavigationTab, (state, action) => {
        state.navigationTab = action?.payload?.tab;
    });
}

function buildSetChannels(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setChannels, (state, action) => {
        state.selectedChannels = action?.payload?.channels;
    });
}

function buildCleanApplyBarStateReducer(
    builder: ActionReducerMapBuilder<UIState>
) {
    builder.addCase(clearApplyBarState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.isApplyBarOpen = false;
        return state;
    });
}

function buildCleanApplyModalStateReducer(
    builder: ActionReducerMapBuilder<UIState>
) {
    builder.addCase(clearApplyModalState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.isApplyModalOpen = false;
        return state;
    });
}

// open default explore view
function buildOpenDefaultExploreViewReducer(
    builder: ActionReducerMapBuilder<UIState>
) {
    builder.addCase(openDefaultExploreView, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.defaultExplore.isDefaultExploreOpen = action.payload.isOpen;
        if (!!action.payload.ExploresEnum) {
            state.defaultExplore.exploreEnum = action.payload.ExploresEnum;
        }
        if (action?.payload?.stateToTransfer !== undefined) {
            state.defaultExplore.stateToTransfer = action.payload.stateToTransfer;
        } else {
            state.defaultExplore.stateToTransfer = null;
        }
        return state;
    });
}

function buildSetDatesReducer(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setDateRangeState, (state, action) => {
        state.dateRange.startDate = action?.payload?.startDate;

        state.dateRange.endDate = action?.payload?.endDate;

        state.dateRange.preset = action?.payload?.preset;

        state.dateRange.isDefaultValue = false;

        return state;
    });
}

// open default explore view
function buildOpenSecondaryExploreView(
    builder: ActionReducerMapBuilder<UIState>
) {
    builder.addCase(openSecondaryExplore, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.secondaryExplore.isSecondaryExploreOpen = action.payload.isOpen;
        if (!!action.payload.ExploresEnum) {
            state.secondaryExplore.exploreEnum = action.payload.ExploresEnum;
        }
        return state;
    });
}

// open apply bar explore view
function buildOpenApplyBar(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(openApplyBar, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.isApplyBarOpen = action.payload.isApplyBarOpen;
        return state;
    });
}

// open apply bar explore view
function buildOpenApplyModal(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(openApplyModal, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.isApplyModalOpen = action.payload.isApplyModalOpen;
        return state;
    });
}


// open apply bar explore view
function buildOpenSecondaryApplyBar(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(openSecondaryApplyBar, (state, action) => {

        state.isSecondaryApplyBarOpen = action.payload.isApplyBarOpen;
        return state;
    });
}

// open apply bar explore view
function buildOpenSecondaryApplyModal(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(openSecondaryApplyModal, (state, action) => {

        state.isSecondaryApplyModalOpen = action.payload.isApplyModalOpen;
        return state;
    });
}
//selected card
function buildSelectedReducer(builder: ActionReducerMapBuilder<UIState>) {
    builder.addCase(setCardSelected, (state, action) => {
        state.cardSelected.isSelected = action.payload.isSelected;
        state.cardSelected.marketingKpiNumber = action.payload.kpisEnumMarketing;
        state.cardSelected.workspaceKpiNumber = action.payload.kpisEnumWorkspace;
        return state;
    });
}

// fetch accounts
function buildFetchAccounts(builder: ActionReducerMapBuilder<UIState>) {
    builder
        .addCase(fetchAccounts.pending, (state, action) => {
            return state;
        })
        .addCase(fetchAccounts.fulfilled, (state, action) => {
            state.accounts.data = action.payload;
            return state;
        })
        .addCase(fetchAccounts.rejected, (state, action) => {
            state.accounts.error = true;
            return state;
        });
}

function buildGetCsv(builder: ActionReducerMapBuilder<UIState>) {
    builder
        .addCase(getCsv.pending, (state, action) => {
            state.appLoad = true;
            return state;
        })
        .addCase(getCsv.fulfilled, (state, action) => {
            state.appLoad = false;
            return state;
        })
        .addCase(getCsv.rejected, (state, action) => {
            state.appLoad = false;
            return state;
        });
}

function buildAddToasts(builder: ActionReducerMapBuilder<UIState>) {
    builder
        .addCase(addToasts, (state, action) => {
            if (!!state.toasts) {
                // add unique id
                action?.payload?.toasts?.map(toast => {
                    toast.id = uuidv4();
                    return toast
                })
                state.toasts = state.toasts.concat(action?.payload?.toasts ?? [])
            }
            return state;
        }).addCase(removeToasts, (state, action) => {
        if (!!state.toasts) {
            if (!!action.payload?.toasts) {
                const idsToRemove = action?.payload?.toasts?.map(toast => toast.id);
                state.toasts = state.toasts.filter(toastFromState => {
                    return !idsToRemove.includes(toastFromState.id)
                })
            }
        }
        return state;
    })
}

// slice
export const uiSlice = createSlice({
    name: "ui",
    initialState: initialUIState,
    reducers: {},
    extraReducers: (builder) => {
        // non async
        // clean ui state
        buildCleanUIStateReducer(builder);

        buildSetAppLoader(builder);

        buildNavigationTabReuder(builder);

        buildOpenSecondaryApplyModal(builder);

        buildOpenSecondaryApplyBar(builder);

        buildSetChannels(builder);

        buildCleanApplyModalStateReducer(builder);

        buildCleanApplyBarStateReducer(builder);

        buildOpenSecondaryExploreView(builder);
        // Default Explore
        buildOpenDefaultExploreViewReducer(builder);

        buildGetCsv(builder);

        buildOpenApplyBar(builder);

        buildOpenApplyModal(builder);

        buildSetDatesReducer(builder);

        buildSelectedReducer(builder);

        buildFetchAccounts(builder);

        buildSetGlobalStateReducer(builder);

        buildAddToasts(builder)
    },
});

// reducer
export const uiReducer = uiSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { clearEffortsState, setNewEfforts } from "./actions";
import { EffortsState, initalEffortsState } from "./state";
import {
  createEfforts,
  deleteEffort,
  editEffort,
  fetchEfforts,
} from "./thunks";

function buildCleanEffortsStateReducer(
  builder: ActionReducerMapBuilder<EffortsState>
) {
  builder.addCase(clearEffortsState, (state, action) => {
    state.data = [];
    return state;
  });
}

// set new efforts
function buildSetNewEfforts(builder: ActionReducerMapBuilder<EffortsState>) {
  builder.addCase(setNewEfforts, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
    return state;
  });
}

function buildFetchEffortsDataReducer(
  builder: ActionReducerMapBuilder<EffortsState>
) {
  builder
    .addCase(fetchEfforts.pending, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    })
    .addCase(fetchEfforts.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = true;
      return state;
    })
    .addCase(fetchEfforts.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    });
}
function buildCreateNewEffort(builder: ActionReducerMapBuilder<EffortsState>) {
  builder
    .addCase(createEfforts.pending, (state, action) => {
      /* state.data = []; */
      state.loading = true;
      return state;
    })
    .addCase(createEfforts.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.loading = false;
      return state;
    })
    .addCase(createEfforts.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    });
}
function buildEditEffort(builder: ActionReducerMapBuilder<EffortsState>) {
  builder
    .addCase(editEffort.pending, (state, action) => {
      /* state.data = []; */
      state.loading = true;
      return state;
    })
    .addCase(editEffort.fulfilled, (state, action) => {
      /*  const findIndex = state?.data?.findIndex(
        (item: any) => item?.id === action?.payload?.id
      ); */
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      console.log(index);
      /* const newData = state?.data?.splice(findIndex, 1, action.payload); */
      /* state.data = [] */
      state.loading = false;
      return state;
    })
    .addCase(editEffort.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    });
}
function buildDeleteEffort(builder: ActionReducerMapBuilder<EffortsState>) {
  builder
    .addCase(deleteEffort.pending, (state, action) => {
      state.loading = false;
      return state;
    })
    .addCase(deleteEffort.fulfilled, (state, action) => {
      state.itemToRemove = action.payload.id;
      state.loading = true;
      return state;
    })
    .addCase(deleteEffort.rejected, (state, action) => {
      state.loading = false;
      return state;
    });
}

export const effortsSlice = createSlice({
  name: "efforts",
  initialState: initalEffortsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanEffortsStateReducer(builder);

    buildSetNewEfforts(builder);

    // async

    buildCreateNewEffort(builder);
    buildFetchEffortsDataReducer(builder);
    buildEditEffort(builder);

    /* buildDeleteEffort(builder); */
  },
});

// reducer
export const effortsReducer = effortsSlice.reducer;

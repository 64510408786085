export  const PnlIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="51" height="44.001" viewBox="0 0 36 44.001">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#7031eb"/>
                    <stop offset="1" stop-color="#4a55fb"/>
                </linearGradient>
            </defs>
            <g id="Q3_icons" data-name="Q3 icons" transform="translate(-6 -1.999)">
                <g id="Group_1137" data-name="Group 1137">
                    <path id="Path_548" data-name="Path 548" d="M31.4,9.9l1.5-5.2A2,2,0,0,0,31,2.1h-.4L24,5.6,16.1,2h-.5a1.9,1.9,0,0,0-1.9,2.4l2.2,6C10,14.8,6,23.2,6,29.6,6,38.7,14.1,46,24,46s18-7.3,18-16.4C42,23,37.6,14.1,31.4,9.9ZM24,42c-7.7,0-14-5.6-14-12.4a20.36,20.36,0,0,1,8.3-16l2.4-1.8L19.6,9l-.4-1.2,3.2,1.4,1.7.8,1.8-.9,1.8-.9-.2.5-.8,2.9,2.4,1.6C34.4,16.8,38,24.6,38,29.6,38,36.4,31.7,42,24,42Z" fill="url(#linear-gradient)"/>
                    <path id="Path_549" data-name="Path 549" d="M24.7,25c-3.5-.7-3.5-1.3-3.5-1.8s.2-.6.5-.9a3.4,3.4,0,0,1,1.8-.4,6.3,6.3,0,0,1,3.3.9,1.8,1.8,0,0,0,2.7-.5,1.9,1.9,0,0,0-.4-2.8A9.1,9.1,0,0,0,26,18.3V16a2,2,0,0,0-4,0v2.2c-3,.5-5,2.5-5,5.2s3.3,4.9,6.5,5.5,3.3,1.3,3.3,1.8-1.1,1.4-2.5,1.4h0a6.7,6.7,0,0,1-4.1-1.3,2,2,0,0,0-2.8.6,1.8,1.8,0,0,0,.3,2.6A10.9,10.9,0,0,0,22,35.8V38a2,2,0,0,0,4,0V35.8a6.3,6.3,0,0,0,3-1.3,4.9,4.9,0,0,0,2-4h0C31,26.8,27.6,25.6,24.7,25Z" fill="url(#linear-gradient)"/>
                </g>
            </g>
        </svg>

    )
}
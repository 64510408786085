import styled from 'styled-components';
import {Props} from './IntegrationButton';

export namespace integrationButton {

    export const Wrapper = styled.a<{premium?: true}>`
      background-color: white;
      border: 1px solid #eae9e9;
      border-radius: 10px;
      height: 70px;
      width: 250px;
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 5px;
      position: relative;
      &:hover{
        cursor: ${props => props?.premium ? 'unset' : 'pointer'};
      }
    `;

    export const ImageWrapper = styled.div`
        position: relative;
        display: flex;
    `;

    export const LockedOverlay = styled.div<{ premium?: true }>`
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      height: 100%;
      width: 100%;
      position: absolute;
      display: ${props => (props.premium ? "none" : "flex")};
      justify-content: center;
      align-items: center;
    `;

    export const LockedIcon = styled.div`
      font-size: 24px;
      color: ${props => props.theme.primaryColor};
    `;



    export const ImageBadge = styled.div`
      background-color: ${props => props.theme.primaryColor};
      border-radius: 7.5px;
      height: 15px;
      width: 15px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
    `;

    export const ImageBadgeText = styled.span`
      color: ${props => props.theme.lightTextColor};
      font-size: 12px;
      font-weight: 500;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `;

    export const Image = styled.img<{premium?: true}>`
      height: 50px;
      width: 50px;
      mix-blend-mode: ${props => props?.premium ? 'luminosity' : 'normal'};
      opacity: ${props => props?.premium ? 0.5 : 1};
    `;

    export const Info = styled.div`
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
    `;

    export const Title = styled.div<{premium?: true}>`
      font-size: 14px;
      font-weight: bold;
      color: ${props => props?.premium ? 'gray' : ''};
    `;

    export const Description = styled.div`
      font-size: 10px;
    `;

    export const PremiumWrapper = styled.div`
    /*background-color: #DDDAED;*/
      color: ${props => props?.theme?.primaryColor};
      max-width: 25px;
      max-height: 25px;
      border-radius: 50%;
      right: .5rem;
      top: .5rem;
      position: absolute;
    `

    export const More = styled.div`
      font-size: 20px;
      display: flex;
      margin-left: auto;
    `;
}



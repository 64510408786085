// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearAuthState, clearSuccessImageUpload, resetPasswordStatusAction} from "./actions";
import { AuthState, initialAuthState } from "./state";
import {
  assignKpi,
  authenticate,
  fetchWorkspaces, forgotPassword,
  logout,
  pinKpi,
  resetPassword,
  signIn,
  signUp,
  switchOrganization,
  updateUser, uploadAvatarImage,
} from "./thunks";
import {KpisEnum} from "../../models/kpi-settings";
import {kpiOrder, orderArray} from "../../utils/constants/constants";

// reducers
// clean sign-up state
function buildCleanAuthStateReducer(
  builder: ActionReducerMapBuilder<AuthState>
) {
  builder.addCase(clearAuthState, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.user = undefined;
    return state;
  });
}

function buildCleanSuccess(
    builder: ActionReducerMapBuilder<AuthState>
) {
  builder.addCase(clearSuccessImageUpload, (state, action) => {
   state.successUpdateAvatar = undefined;
    return state;
  });
}


function buildCleanAuthPasswordStateReducer(
  builder: ActionReducerMapBuilder<AuthState>
) {
  builder.addCase(resetPasswordStatusAction, (state, action) => {
    state.resetPasswordSuccess = action.payload.resetPasswordSuccess;
    return state;
  });
}

// sign up
function buildSignUpReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(signUp.pending, (state, action) => {
    state.error = undefined;
    state.loading = false;
    return state;
  });
  builder.addCase(signUp.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.user = action?.payload?.user;
    state.token = action?.payload?.token;
    return state;
  });
  builder.addCase(signUp.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    return state;
  });
}
// sign up
function buildFetchWorkspaces(builder: ActionReducerMapBuilder<any>) {
  builder.addCase(fetchWorkspaces.pending, (state, action) => {});
  builder.addCase(fetchWorkspaces.fulfilled, (state, action) => {
    state.workspaces = action?.payload;
    return state;
  });
  builder.addCase(fetchWorkspaces.rejected, (state, action) => {});
}

// sign in
function buildSignInReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(signIn.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(signIn.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = false;
      state.token = action?.payload?.token;
      state.user = action?.payload?.user;
      if(action?.payload?.user?.image?.buffer && !!state?.user){
        const data = action?.payload?.user?.image?.buffer?.data;
        const base64 = Buffer.from(data).toString('base64');

        state.user.image = `data:image/jpeg;base64,${base64}`
      }
      return state;
    })
    .addCase(signIn.rejected, (state, action) => {
      console.log(action);
      state.error = action?.payload;
      state.loading = false;
      return state;
    });
}

// authenticate
function buildAuthenticateReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(authenticate.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(authenticate.fulfilled, (state, action) => {

    const deepCopy = JSON.parse(JSON.stringify(action?.payload?.user));
    if("workspaces" in deepCopy && Array?.isArray(deepCopy["workspaces"])){

      const newWorkspaces = deepCopy["workspaces"]?.map((workspace) => {
        return {...workspace,  // change assigned kpis for each workspace
          assignedKpis: orderArray(workspace?.assignedKpis),
          pinnedKpis: orderArray(workspace?.pinnedKpis),
        }
      })
      deepCopy["workspaces"] = newWorkspaces
    }

    state.error = undefined;
    state.loading = false;
    state.token = action?.payload?.token;
    state.user = deepCopy;
    if(action?.payload?.user?.image?.buffer && !!state?.user){
      const data = action?.payload?.user?.image?.buffer?.data;
      const base64 = Buffer.from(data).toString('base64');

      state.user.image = `data:image/jpeg;base64,${base64}`
    }
    return state;
  });
  builder.addCase(authenticate.rejected, (state, action) => {
    state.error = undefined;
    state.loading = false;
    return state;
  });
}
// authenticate
function buildSwitchOrganization(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(switchOrganization.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(switchOrganization.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.token = action?.payload?.accessToken;
    state.user = action?.payload?.user;
    return state;
  });
  builder.addCase(switchOrganization.rejected, (state, action) => {
    state.error = undefined;
    state.loading = false;
    return state;
  });
}

// authenticate
function buildLogoutReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(logout.pending, (state, action) => {
      state.token = undefined;
      state.user = undefined;
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(logout.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = false;
      return state;
    })
    .addCase(logout.rejected, (state, action) => {
      state.error = undefined;
      state.loading = false;
      return state;
    });
}

// authenticate
function buildUpdateAvatarImage(builder: ActionReducerMapBuilder<AuthState>) {
  builder
      .addCase(uploadAvatarImage.pending, (state, action) => {
        state.loadingUpdateAvatar = true;
        return state;
      })
      .addCase(uploadAvatarImage.fulfilled, (state, action) => {
        state.loadingUpdateAvatar = false;
        state.successUpdateAvatar = true;
        state.user = action.payload;
        if(action?.payload?.image?.buffer && !!state?.user){
          const data = action?.payload?.image?.buffer?.data;
          const base64 = Buffer.from(data).toString('base64');

          state.user.image = `data:image/jpeg;base64,${base64}`
        }
        return state;
      })
      .addCase(uploadAvatarImage.rejected, (state, action) => {

        state.loadingUpdateAvatar = false;
        state.successUpdateAvatar = false;

        return state;
      });
}


function buildResetPassword(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(resetPassword.pending, (state, action) => {
      state.resetPasswordLoading = true;
      return state;
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.error = undefined;
      state.resetPasswordLoading = false;
      state.resetPasswordSuccess = action.payload;

      return state;
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordLoading = false;
      return state;
    });
}
function buildForgotPassword(builder: ActionReducerMapBuilder<AuthState>) {
  builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.forgotPasswordLoading = true;
        return state;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = action.payload;

        return state;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        return state;
      });
}


// workspace pin kpi

function buildPinKpi(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(pinKpi.pending, (state, action) => {
      return state;
    })
    .addCase(pinKpi.fulfilled, (state, action) => {
      const workspaceId = action.meta.arg.workspaceId;

      const currentWorkspace = state?.user?.workspaces?.find(
        (workspace) => workspace.id === workspaceId
      );
      const actionData = action.payload.workspaces.find(
        (workspace: { id: string }) => workspace.id === workspaceId
      );
      if (!!currentWorkspace?.pinnedKpis && !!actionData?.pinnedKpis) {
        currentWorkspace.pinnedKpis = actionData?.pinnedKpis;
      }
      return state;
    })
    .addCase(pinKpi.rejected, (state, action) => {
      return state;
    });
}
function buildAssignKpi(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(assignKpi.pending, (state, action) => {
      return state;
    })
    .addCase(assignKpi.fulfilled, (state, action) => {
      const workspaceId = action.meta.arg.workspaceId;

      const currentWorkspace = state?.user?.workspaces?.find(
        (workspace) => workspace.id === workspaceId
      );
      const actionData = action?.payload?.workspaces?.find(
        (workspace: { id: string }) => workspace.id === workspaceId
      );
      if (!!currentWorkspace?.assignedKpis && !!actionData?.assignedKpis) {
        currentWorkspace.assignedKpis = actionData?.assignedKpis;
      }
      return state;
    })
    .addCase(assignKpi.rejected, (state, action) => {
      return state;
    });
}
function buildUpdateUser(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(updateUser.pending, (state, action) => {
      return state;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      if (!!state?.user?.firstName)
        state.user.firstName = action?.payload?.firstName;
      if (!!state?.user?.lastName)
        state.user.lastName = action?.payload?.lastName;
      if (!!state?.user?.workspaces) {
        state.user.workspaces = action?.payload?.workspaces;
      }

      return state;
    })
    .addCase(updateUser.rejected, (state, action) => {
      return state;
    });
}

// slice
export const userSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanAuthStateReducer(builder);

    buildCleanSuccess(builder);

    // async
    // sign up
    buildSignUpReducer(builder);

    // sign in
    buildSignInReducer(builder);

    // authenticate
    buildAuthenticateReducer(builder);

    // logout
    buildLogoutReducer(builder);

    // pin Kpi
    buildPinKpi(builder);

    buildAssignKpi(builder);

    buildSwitchOrganization(builder);

    buildUpdateUser(builder);

    buildResetPassword(builder);

    buildCleanAuthPasswordStateReducer(builder);

    buildFetchWorkspaces(builder);

    buildForgotPassword(builder);

    buildUpdateAvatarImage(builder);
  },
});

// reducer
export const authReducer = userSlice.reducer;

import styled from "styled-components";
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  Select,
  Spinner,
} from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div<{ img: string }>`
    background: url(${(props) => props.img})
      ${(props) => props.theme.primaryColor} round;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  export const Content = styled.div`
    display: flex;
    justify-content: center;
  `;

  export const Card = styled.div`
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 600px;
    min-height: 300px;
    /* overflow: hidden; */

    //@media (max-width: 768px) {
    //  width: 90vw;
    //}
  `;

  // header
  export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `;

  export const Step = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.menu};
    color: ${(props) => props.theme.primaryColor};
  `;

  export const HelloUser = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.menu};
  `;

  export const Welcome = styled.div`
    font-weight: bold;
    font-size: 30px;
    font-family: ${(props) => props.theme.fonts.header};
    color: ${(props) => props?.theme?.primaryColor};
  `;

  export const Description = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.menu};
  `;

  //bottom
  export const Bottom = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    margin-bottom: 0;
    justify-content: end;
    width: 100%;
  `;

  export const SkipButton = styled.button`
    border-radius: 20px;
    font-family: ${(props) => props.theme.fonts.menu};
    margin: 0 10px;
    width: 100px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;

  export const NextButton = styled(Button)<{ notactive?: boolean }>`
    border-radius: 20px !important;
    font-family: ${(props) => props.theme.fonts.menu};
    margin: 0 10px !important;
    width: 120px !important;
    height: 40px !important;
    background-color: ${(props) =>
      props.notactive ? "#E8E8E8" : props.theme.primaryColor} !important;
    color: ${(props) => (props.notactive ? "#888888" : "white")};
    &:hover {
      cursor: ${(props) => (props.notactive ? "default" : "pointer")};
    }
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;

  // content
  export const FormWrapper = styled.form`
    width: 100%;
  `;

  export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 20px 0;
    width: 100%;
  `;

  export const FormFieldContainer = styled(FormControl)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 20px 0;
    width: 100%;
  `;

  export const FormFieldTitle = styled.div`
    margin-bottom: 5px;
    padding-left: 10px;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.menu};
    color: ${(props) => props.theme.primaryColor};
  `;

  export const FormFieldInput = styled(Input)`
    min-height: 50px;
    border: 1px solid gray !important;
    font-family: ${(props) => props.theme.fonts.menu};
    width: 100%;
    border-radius: 15px !important;
    &:focus {
      // remove blue border from chakra
      box-shadow: 0 0 0 1px ${(props) => props.theme.primaryColor} !important;
    }
    font-weight: bold;

    font-family: ${(props) => props.theme.fonts.menu};
  `;

  export const FormFieldSelect = styled(Select)`
    height: 50px;
    border: 1px solid gray !important;
    width: 100%;
    border-radius: 15px !important;
  `;

  export const FormFieldSelectOption = styled.option`
    background-color: white;
    color: black;
  `;

  export const FormFieldCheckbox = styled(Checkbox)`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  export const FormFieldCheckboxLabel = styled(Checkbox)`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const Loader = styled(Spinner)`
    height: 48px;
    width: 48px;
    color: ${(props) => props.theme.primaryColor ?? "#7331EC"};
  `;
}

export default styles;

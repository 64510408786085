import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
  `;

  export const Header = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
  `;
  export const FlexBox = styled.div`
  display: flex;
    width: 100%;
    justify-content: center;
  `

  export const TypeButton = styled.button<{ isFocus: boolean }>`
    flex: 1;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: ${(props) => (props.isFocus ? "#000" : "#7A86A1")};
    border-bottom: ${(props) => (props.isFocus ? "3px" : "0")}
      ${(props) => props.theme.primaryColor} solid;
    background-color: ${(props) =>
      props.isFocus ? "#fff" : "rgb(236, 237, 241)"};
    box-shadow: ${(props) => (props.isFocus ? props.theme.fullShadow : null)};
  `;

  export const Content = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    padding: 20px;
  `;
  export const Title = styled.h2`
    font-size: 19px;
    font-weight: normal;
    margin-bottom: 10px;
  `;

  export const Input = styled.input`
    background: #ffffff;
    border: 2px solid #b0b0b0;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
  `;
  export const RadioButtonsWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  `;

  export const Footer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
  `;

  export const FooterButton = styled.button<{ isApply?: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.isApply ? props.theme.primaryColor : "transparent"};
    border: 2px
      ${(props) => (props.isApply ? props.theme.primaryColor : "#7A86A1")} solid;
    color: ${(props) => (props.isApply ? "#fff" : "#7A86A1")};
    font-weight: bold;
    border-radius: 30px;
    margin: 0px 15px;
    padding: 10px;
  `;
}

export default styles;

import {PnlFinancialTable} from "../../models/pnl-financial-table";
import {PnlBreakdown} from "../../models/pnl-breakdown";
import {apiBaseUrl} from "@constants";
import axios from "axios";
import {saveAs} from "file-saver";
import {DepartmentsEnum} from "../operations/operation.service";
import {PnlReportGroupBy, PnlReportShowBy} from "../kpi/kpi.service";

const generatePnLWaterfallData = () => {
    let TGTs = [100, 95, 65, 35];
    let losses = [5, 50, 40];
    return {
        TGTs: TGTs,
        losses: losses,
    };
};

const generatePnLFinancialData = () => {
    let finalData: PnlFinancialTable[] = [];
    for (let i = 2; i < 15; i++) {
        finalData.push({
            date: new Date(i + " Apr 2021").toISOString(),
            gross: 217016,
            clearing: 24662,
            netRevenueFiat: 192353,
            netRevenuePercent: 88.6,
            cogs: 168387,
            grossProfit: 23992,
            grossMargin: 11,
            marketingSpend: 79884,
            contributionProfit: -55878,
            contributionMargin: -15.5,
        } as PnlFinancialTable);
    }
    return finalData;
};

const generatePnLBreakdown = () => {
    let breakdownData: PnlBreakdown[] = [];
    for (let i = 2; i < 15; i++) {
        breakdownData.push({
            date: new Date(i + " Apr 2021").toISOString(),
            cogsFiat: 217016,
            cogsPercent: 77.6,
            fob: 192353,
            shipping: 88.6,
            lastMail: 168387,
            duties: 23992,
            ramp: 11.0,
            fba: 79884,
        } as PnlBreakdown);
    }
    return breakdownData;
};

export const getFinancialData = () => {
    return Promise.resolve({
        overview: generatePnLWaterfallData(),
        financialTable: generatePnLFinancialData(),
        breakdownTable: generatePnLBreakdown(),
    });
};


const createCsvFilename = (fromDate: string, toDate: string, groupBy?: PnlReportGroupBy, showBy?: PnlReportShowBy) => {
    let groupByStr = '';
    let showByStr = '';

    if (!!groupBy || groupBy === 0) {
        switch (groupBy) {
            case PnlReportGroupBy.DAY:
                groupByStr = 'daily';
                break;
            case PnlReportGroupBy.WEEK:
                groupByStr = 'weekly';
                break;
            case PnlReportGroupBy.MONTH:
                groupByStr = 'monthly';
                break;
        }
    }

    if (!!showBy || showBy === 0) {
        switch (showBy) {
            case PnlReportShowBy.DATE:
                showByStr = 'date';
                break;
            case PnlReportShowBy.CHANNEL:
                showByStr = 'channel';
                break;
            case PnlReportShowBy.PRODUCT:
                showByStr = 'product';
                break;
        }
    }
    const dateStr = fromDate.toString() + ' - ' + toDate.toString();
    const fileName = `pnl-report-${dateStr}-${showByStr}-${groupByStr}`
    return fileName;
}


const downloadPnlCsv = ({
                            organizationId,
                            fromDate,
                            toDate,
                            channels,
                            fields,
                            groupBy,
                            showBy,
                            fileName,
                            currencySign
                        }: {
    organizationId: string,
    fromDate: string,
    toDate: string,
    channels?: string[],
    fields?: string[],
    groupBy?: PnlReportGroupBy,
    showBy?: PnlReportShowBy,
    fileName?: string,
    currencySign?: string
}): any => {

    const generatedFileName = createCsvFilename(fromDate, toDate, groupBy, showBy);
    const params = {
        fromDate: fromDate?.slice(0, 10) + "T00:00:00.000Z",
        toDate: toDate?.slice(0, 10) + "T23:59:59.999Z",
        channels: channels,
        fields: fields,
        groupBy: groupBy,
        showBy: showBy,
        fileName: generatedFileName,
        currencySign: currencySign
    };

    let urlToFetchData = `${apiBaseUrl}/organization/${organizationId}/reports/pnl-csv`;

    return axios
        .get<any>(urlToFetchData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/octet-stream;",
                "Content-Disposition": "attachment",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: params,
        })
        .then((response) => {
            const csv =
                "data:text/csv;charset=utf-8,%EF%BB%BF" +
                encodeURIComponent(response.data);
            saveAs(csv, `${generatedFileName}.csv`);
        });
};


export const financeService = {
    downloadPnlCsv
}

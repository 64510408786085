import { createAction } from "@reduxjs/toolkit";
import {PnlReportGroupBy} from "../../services/kpi/kpi.service";
import {downloadPnlCsv} from "./thunks";

export const clearKpiState = createAction("kpi/clearState");

export const finishLoadingMarketing = createAction<{
  marketingLoader: boolean;
}>("kpi/finishLoadingMarketing");

export const setDailyStats = createAction<{
  dailyStats: any;
}>("kpi/setDailyStats");
export const setMarketingStats = createAction<{
  marketingStats: any;
}>("kpi/setMarketingStats");
export const setTargetStats = createAction<{
  targetStats: any;
}>("kpi/setTargetsStats");
export const cleanUpCustomTargetsLoaders = createAction('kpiData/CleanUpLoaders');

export const setPnlPageFilters = createAction<{
  groupBy: PnlReportGroupBy;
}>("kpi/setPnlPageFilters");

export const resetPnlPageFilers = createAction("kpi/resetPnlPageFilters");


export const cleanGraphData = createAction('kpiData/cleanGraphData');

export const setExploreLoading = createAction<{ isLoading: boolean }>(
    "kpi/setExploreLoading"
);

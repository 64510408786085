import styled from "styled-components";
import { Table, Td, Th, Tr } from "@chakra-ui/react";

export namespace Styles {
  export const Wrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    //padding: 0px 18px;
  `;

  export const SmallerWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    padding: 20px 10px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Title = styled.div`
    //margin: 5px;
    //size: 18px;
    //font-weight: 200;
    display: flex;
    font-size: 21px;
    font-weight: bold;
    padding-top: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;

  export const MyIntegrations = styled.div`
    width: 90%;
  `;
  export const MyIntegrationsTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 1px;
    border-color: ${(props) => props.theme.backgroundColor};
  `;

  export const AddIntegrations = styled.div`
    width: 90%;
  `;
  export const AddIntegrationsTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 1px;
    border-color: ${(props) => props.theme.backgroundColor};
  `;
  export const ButtonWrapper = styled.div`
    margin-right: 10px;
    margin-top: 10px;
  `;

  export const IntegrationsTable = styled(Table)`
    font-size: 12px;
    font-weight: normal;
    max-height: 200px;
    margin: 25px 0 25px 0;
  `;
  export const TableTd = styled(Td)<{ width: number }>`
    border: solid 1px gray;
    width: ${(props) => props.width};
  `;

  export const TableTh = styled(Th)<{ width: number }>`
    border: solid 1px gray;
    width: ${(props) => props.width};
    text-transform: none !important;
  `;

  export const TableTr = styled(Tr)<{ index: number }>`
    border: solid 1px gray;
    background-color: ${(props) =>
      props.index % 2 === 0 ? "white" : "#fcfcfe"};
  `;
}

export default Styles;

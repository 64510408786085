import { Input, Switch } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    //background-color: ${(props) => props.theme.backgroundColor};
    background-color: white;
    max-width: 423px;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    border-radius: 12px 0 0 0;
    padding: 15px;
  `;

  export const InviteForm = styled.form<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
  `;
  export const CloseIcon = styled.div`
    position: absolute;
    right: -5px;
    &:hover{
      cursor: pointer;
    }
  `;

  export const ImageContainer = styled.div`
    width: 39px;
  `;

  export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 31px;
    width: 100%;
    position: relative;
  `;
  export const InviteFlex = styled.div`
    display: flex;
  `;

  export const InviteEmailInput = styled.input`
    height: 31px;
    margin: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #a781ef;
    width: 100%;
    /* max-width: 300px; */
    &:focus {
      outline: none !important;
      box-shadow: unset !important;
    
    }
  `;

  export const UsersContainer = styled.div`
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px 0 0 0;
  `;
  export const UsersContainerActive = styled.div`
    max-height: 240px;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    margin: 15px 0 0 0;
  `;
  export const UsersActive = styled.div`
    padding: 5px;
    width: 100%;
    margin: 15px 0 0 0;
  `;
  export const PermissionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 5px;
    align-items: center;
  `;

  export const PermissionsSwitch = styled(Switch)`
    margin: 0 10px;
  `;

  export const UsersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
  `;
  export const UsersWrapperActive = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    position: relative;
  `;

  export const UserContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
  `;
  export const UserContainerActive = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    max-height: 200px;
    overflow-y: auto;
  `;
  export const CustomSpacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
    height: 100%;
  `;
  export const Pending = styled.div`
    color: #7a86a1;
    opacity: 0.96;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const StyledTitle = styled.div`
    font-family: ${(props) => props.theme.fonts.header};
    font-size: 17px;
    width: 100%;
    font-weight: bold;
    border-color: ${(props) => props.theme.backgroundColor};
    align-self: flex-start;
  `;

  export const UserTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /* width: 50%; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const UserName = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 16px;
  `;
  export const UserEmail = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 15px;
    color: #171725;
    opacity: 0.37;
  `;

  export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    width: 50%;
  `;
  export const PendingMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;
  export const UserRole = styled.div``;
  export const UserPermissions = styled.div`
    font-weight: bold;
  `;

  export const SendButton = styled.button<{ notActive: boolean }>`
    border-radius: 10px;
    width: 90px;
    height: 35px;
    margin: 10px 0 0 0;
    background-color: ${(props) =>
      props.notActive ? "#dddddd" : props.theme.headerButtonBackgroundColor};
    color: ${(props) => (props.notActive ? "black" : props.theme.buttonColor)};
    opacity: ${(props) => (props.notActive ? 0.5 : 1)};
    align-self: flex-end;
  `;
  export const Spacer = styled.div`
    height: 1px;
    margin: 10px 0;
    background-color: #dccdfa;
    width: 100%;
  `;
  export const DropDownWrapper = styled.div<{ w?: number }>`
    width: 360px;
    margin: 0 10px;
    top: 32px;
    height: 35px;
    left: -9px;
    z-index: 9999;
    display: flex;
    align-items: center;
    position: absolute;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    border-left: 1px solid white;
    background-color: white;
    border-radius: 0 0 3px 3px;
    &:hover {
      cursor: pointer;
    }
    box-shadow: 0px 2px 5px #babbbb;
  `;
  export const DropDownText = styled.div`
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
}

export default styles;

import { Toggle } from "components/toggle/Toggle";
import styled from "styled-components";

export namespace styles {

  export const Section = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 40px calc(100% - 80px);
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
    
    @media only screen and (max-width: 1400px) {
      margin: 0;
      padding: 10px;
    }
  `;

  export const WrapperInside = styled.div`
    width: 95%;
    padding: 25px 0;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const Header = styled.div`
    padding-left: 40px;
    font-weight: bold;
    align-self: flex-start;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
  `;

  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: #f9f9ff;
    margin-bottom: 20px;
  `;

  export const WrapperToggle = styled.div`
    width: 400px;
  `;

  export const ToggleStyle = styled(Toggle)`
    border-radius: 0;
  `;
}

export default styles;
export const FilterIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g id="Layer_2" data-name="Layer 2" transform="translate(0.369 0.37)">
            <g id="invisible_box" data-name="invisible box" transform="translate(-0.012 -0.012)">
                <rect id="Rectangle_887" data-name="Rectangle 887" width="22" height="22" transform="translate(-0.357 -0.357)" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.933 1.374)">
                <g id="Group_10721" data-name="Group 10721">
                    <path id="Path_12409" data-name="Path 12409" d="M9.763,17.882a.882.882,0,0,0-1.763,0v4.408H9.763Z" transform="translate(-5.355 -10.829)" fill="#464a53" />
                    <path id="Path_12410" data-name="Path 12410" d="M28.171,23.645H26.408V21.882a.882.882,0,1,0-1.763,0v1.763H22.882a.882.882,0,0,0,0,1.763h1.763v1.763a.882.882,0,1,0,1.763,0V25.408h1.763a.882.882,0,0,0,0-1.763Z" transform="translate(-13.184 -13.066)" fill="#464a53" />
                    <path id="Path_12411" data-name="Path 12411" d="M16.987,7.937V3.882A.926.926,0,0,0,16.105,3a.882.882,0,0,0-.882.882v3.57H13.46V5.2a.926.926,0,0,0-.882-.882A.882.882,0,0,0,11.7,5.2V7.937A7.67,7.67,0,0,0,9.934,8.951V7.408a.926.926,0,0,0-.882-.882.882.882,0,0,0-.882.882v3.659a6.832,6.832,0,0,0-.882,3.394,7.714,7.714,0,0,0,.22,1.763H2.882a.882.882,0,1,0,0,1.763H8.259a6.964,6.964,0,0,0,6.083,3.526,7.053,7.053,0,0,0,7.053-7.053A6.964,6.964,0,0,0,16.987,7.937ZM14.342,19.75a5.289,5.289,0,1,1,5.289-5.289A5.289,5.289,0,0,1,14.342,19.75Z" transform="translate(-2 -3)" fill="#464a53" />
                </g>
            </g>
        </g>
    </svg>);

};
import { ModalHeader } from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const StyledModalHeader = styled(ModalHeader)``;
  export const StyledRow = styled.div``;
  export const StyledTitle = styled.div`
    display: flex;
  `;
  export const StyledTr = styled.tr<{ depth?: boolean }>`
    background-color: ${(props) => (props.depth ? "#F9FAFB" : "#F2EFFB")};
    display: flex;
    margin: 5px 0 0 0;
  `;
  export const StyledTd = styled.td`
    display: flex;
    align-items: center;
  `;

  export const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
  `;
  export const ChannelItem = styled.div`
    display: flex;
    height: 35px;
    font-size: 18px;
    gap: 8px;
    width: 100%;
    align-items: center;
    &:hover {
      background-color: #f2effb;
      cursor: pointer;
    }
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;

  export const styledButton = styled(Button)<{ save?: boolean }>`
    text-align: center;
    letter-spacing: 0px;
    color: ${(props) => (props?.save ? "white" : "#93979f")};
    background-color: ${(props) =>
      props?.save ? props.theme.primaryColor : "white"} !important;
    border-radius: 30px !important;
    width: 100px !important;
    height: 35px !important;
    border: ${(props) => (props?.save ? "" : "1px solid #93979f")};
    margin: 0 15px 0 0;
    &:last-of-type {
      margin: 0;
    }
&:hover{
  opacity: .8;
}
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;

  export const StyledTdCheckbox = styled.td`
    width: 15px;
    margin-right: 10px;
  `;
  export const CancelButton = styled.button`
    margin-right: 36px;
    border: 2px solid #7a86a1;
    font-size: 15px;
    color: #7a86a1;
    font-weight: bold;
    width: 107px;
    padding: 4px 0;
    border-radius: 17px;
  `;
  export const SaveButton = styled.button`
    background: #7031eb 0% 0% no-repeat padding-box;
    font-size: 15px;
    color: white;
    padding: 4px 0;

    width: 107px;
    font-weight: bold;
    border-radius: 17px;
  `;
  export const Spacer = styled.div`
    width: 8;
  `;
  export const StyledDivHolder = styled.div``;
  export const Edit = styled.button``;
  export const TableHolder = styled.div``;
  export const StyledTh = styled.th`
    color: #070036;
    font-size: 17px;
    text-align: left;
    font-weight: bold;
    background-color: #f9fafb;
  `;
  export const StyledHeaderTr = styled.tr``;
  export const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0 7px;
    width: 100%;
  `;
  export const TableWrapper = styled.div`
    width: 95%;
  `;
}

export default styles;

import styles from "./styles";

export interface Props {
    color?: string;
    percentage: number;
    height?: string;
}

export const ProgressBar = (props: Props) => {

    // hooks

    // helpers

    // renderers

    return (
        <styles.ProgressBarWrapper color={props.color} percentage={props.percentage <= 100 ? props.percentage : 100} height={props?.height}>
            <styles.ProgressBar color={props.color} percentage={props.percentage <= 100 ? props.percentage : 100} height={props?.height}>
            </styles.ProgressBar>
        </styles.ProgressBarWrapper>
    );

};

import styles from "./styles";
import React, {useEffect} from "react";
import {Box, Popover, PopoverContent, PopoverTrigger, useDisclosure} from '@chakra-ui/react'
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {useTheme} from "@styles";
import MenuGenericButton, {MenuButton, MenuGenericProps} from "../menu-generic/MenuGenericButton";

export interface Props {
    data : MenuButton[]
    size ?: number
    trigger ?: any
    icon?:  (color: string) => JSX.Element;
    title: string;
/*    options: string[];
    onSelect: (selected: string) => void;
    title : string*/
}

export const PanelMenu = (props: Props) => {



    //hooks
    const {t} = useTranslation(['translation', 'translation']);
    const theme = useTheme();
    const {onOpen, onClose, isOpen} = useDisclosure();

    //helpers


    //renders
/*    const renderOpenMenuButton = (isOpen: boolean) => {
        return (
            <styles.OpenMenuButton isOpen={isOpen}>
                {props.icon && props.icon(isOpen ? "white" : theme.primaryColor)}
                {props.icon && <div style={{width: "5px"}}/>}
                {t(props.data[0].title, {ns: 'translation'})}
                <div style={{width: "5px"}}/>
                {
                    isOpen ?
                        <IoIosArrowUp/>
                        :
                        <IoIosArrowDown/>
                }
            </styles.OpenMenuButton>
        )
    }*/

        return <Box zIndex={61} position={"relative"}>
                <MenuGenericButton data={props?.data} size={props?.size} onOpen={onOpen} onClose={onClose} isOpen={isOpen} trigger={
                    (<Box>
                        <styles.OpenMenuButton isOpen={true}>
                            {props.icon && props.icon(isOpen  ? "white" : theme.primaryColor)}
                            {props.icon && <div style={{width: "5px"}}/>}
                            {t(props.title, {ns: 'translation'})}
                            <div style={{width: "5px"}}/>
                            {
                                isOpen ?
                                    <IoIosArrowUp/>
                                    :
                                    <IoIosArrowDown/>
                            }
                        </styles.OpenMenuButton>
                    </Box>
                )} />
        </Box>
}


/*     return (

        <Popover placement={'bottom'}>
            {({isOpen, onClose}) => (
                <>
                    <PopoverTrigger>
                        {renderOpenMenuButton(isOpen)}
                    </PopoverTrigger>
                    <PopoverContent
                        _focus={{boxShadow: "5px 31px 54px #695F9736"}}
                        width={176}
                        borderWidth={0}
                        borderRadius={19}
                        padding={0}
                        overflow={'hidden'}
                        boxShadow={'5px 31px 54px #695F9736'}>
                        {
                            props.options.map((option, index) => {
                                return(
                                    <styles.MenuItem key={index} onClick={() => {
                                        props.onSelect(option)
                                        onClose()
                                    }}>
                                        {t(option, {ns: 'translation'})}
                                    </styles.MenuItem>
                                )
                            })
                        }
                    </PopoverContent>
                </>

                )}
        </Popover>
     );*/


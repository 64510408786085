import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    inset: 0px 0px -180px auto;
    overflow-y: auto;
    max-height: 75vh;
  `;

  export const SystemUpdate = styled.div`
    display: flex;
    padding: 25px 0 25px 0;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  `;
  export const TextTitleUpdate = styled.h3`
    color: #03073e;
    font-weight: 700;
  `;
  export const LastUpdate = styled.div`
    color: #909090;
    text-decoration: underline;
    font-size: 15px;
  `;

  export const TextSystemSubtitle = styled.h4`
    font-weight: 700;
  `;

  export const LinkSystem = styled.a`
    color: #4a55fb;
    text-decoration: underline;
    font: 15px;
  `;

  export const ContentWrapper = styled.div`
    display: flex;
    padding: 25px 0 0 0;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  `;
  export const ContentWrapperSystem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background-color: #f7f3ff;
  `;
  export const SystemTitle = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const SystemSubtitle = styled.div`
    display: flex;
  `;
  export const Spacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
  `;
  export const SystemTitleWrapper = styled.div`
    display: flex;
  `;

  export const DotRow = styled.div`
    display: flex;
    //justify-content: space-between;
    flex-direction: row;
    //gap:10px
  `;

  export const Circle = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    align-self: center;
  `;
  export const RowText = styled.div<{ system: boolean }>`
    color: ${(props) => (props.system ? "#7A86A1" : "#000000")};
    font-weight: ${(props) => (props.system ? "normal" : "bold")};
    font-size: ${(props) => (props.system ? "13px" : "15px")};
  `;
  export const RowTextNotifcation = styled.div<{ system: boolean }>`
    color: ${(props) => (props.system ? "#7A86A1" : "#000000")};
    font-weight: ${(props) => (props.system ? "normal" : "bold")};
    font-size: ${(props) => (props.system ? "13px" : "15px")};
    max-width: 248px;
  `;
  export const TextNotifcation = styled.div`
    font-size: 15px;
    color: black;
    max-width: 248px;
    //padding-top: 20px;
  `;

  export const Notifcations = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const NotifcationsTitle = styled.div`
    color: #03073e;
    font-weight: bold;
    font-size: 19px;
    padding-bottom: 13px;
  `;
  export const NotifcationsHolder = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const Notifcation = styled.div`
    width: 371px;
    min-height: 148px;
    border: 1px solid #70707038;
    border-radius: 10px;
    position: relative;
    margin: 0 0 9px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 14px;
    padding-bottom: 20px;
    /*  &:last-of-type {
      margin: 0;
    } */
  `;
  export const MessageWrapper = styled.div`
    width: 95%;
    height: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;
  export const MessageWrapperBottom = styled.div`
    width: 95%;
    height: 95%;

    margin: 0 auto;
    text-decoration: underline;
    color: #464a53;
    font-size: 15px;
    padding-left: 14px;
    display: flex;
    gap: 31px;
    &:hover {
      cursor: pointer;
    }
  `;

  export const MessageItem = styled.a`
    &:nth-child(2) {
      flex-grow: 1;
    }

    &:nth-child(odd) {
      color: #4A55FB;
    }
  `;

  export const TimeDiv = styled.div`
    font-size: 13px;
    color: #909090;
    position: absolute;
    right: 10px;
    top: 2px;
    padding-top: 20px;
  `;
}

export default styles;

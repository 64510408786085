import {createSlice} from "@reduxjs/toolkit";
import {initialMarketingState, MarketingState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {BreakdownBuckets, clearMarketingState, setMarketingTableRows} from "./actions";
import {fetchMarketingData} from "./thunks";

function buildCleanMarketingStateReducer(
  builder: ActionReducerMapBuilder<MarketingState>
) {
  builder.addCase(clearMarketingState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildSetMarketingTableBreakdownRows(
    builder: ActionReducerMapBuilder<MarketingState>
) {
  builder.addCase(setMarketingTableRows, (state, action) => {
    switch (action?.payload?.bucket){
      case BreakdownBuckets.PLATFORM:
        state.selectedRowsBreakdownTable.selectedPlatforms = action?.payload.rows;
        break
      case BreakdownBuckets.ACCOUNT:
        state.selectedRowsBreakdownTable.selectedAccounts = action?.payload.rows;
        break
      case BreakdownBuckets.CAMPAIGN:
        state.selectedRowsBreakdownTable.selectedCampaigns = action?.payload.rows;
        break
      case BreakdownBuckets.AD_SET:
        state.selectedRowsBreakdownTable.selectedAdSets = action?.payload.rows;
        break
      case BreakdownBuckets.AD:
        state.selectedRowsBreakdownTable.selectedAds = action?.payload.rows;
        break
    }

    return state;
  });
}

function buildFetchMarketingDataReducer(
  builder: ActionReducerMapBuilder<MarketingState>
) {
  builder
    .addCase(fetchMarketingData.pending, (state, action) => {
      state.data = undefined;
      return state;
    })
    .addCase(fetchMarketingData.fulfilled, (state, action) => {
      state.data = action.payload;
      return state;
    })
    .addCase(fetchMarketingData.rejected, (state, action) => {
      state.data = undefined;
      return state;
    });
}

export const marketingSlice = createSlice({
  name: "marketing",
  initialState: initialMarketingState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildSetMarketingTableBreakdownRows(builder);

    buildCleanMarketingStateReducer(builder);

    // async
    buildFetchMarketingDataReducer(builder);
  },
});

// reducer
export const marketingReducer = marketingSlice.reducer;

import styled from "styled-components";
import {Box, Table, Tbody, Th, Tr} from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
  `;

  export const Card = styled.div<{ height?: number }>`
    background-color: white;
    width: 100%;
    padding: 20px;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
    height: ${(props) => (props?.height ? props?.height + "px" : "100%")};
    max-height: ${(props) => (props?.height ? props?.height + "px" : "359px")};
    overflow: hidden;
  `;

  export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props?.theme?.fonts?.header};
    font-size: 21px;
    margin-bottom: 7px;
    font-weight: bold;
  `;

  export const TableCustom = styled(Table)`
    width: 100%;
    overflow-y: scroll;
    table-layout: fixed;
    border-collapse: separate !important;
    border-spacing: 0;
    height: 100%;
  `;

  export const TbodyCustom = styled(Tbody)`
    /*display: block;
    height: 300px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }*/
  `;

  export const ThCustom = styled(Th)`
    text-transform: capitalize !important;
    color: gray;
    font-size: 14px;
    border-bottom: 1px solid #7a86a1 !important;
    table-layout: fixed;
    user-select: none;
  `;

  export const StyledTh = styled.th`
    text-align: left;
    color: #7a86a1;
    padding: 15px 0 10px;
    font-size: 14px;
    border-bottom: 1px solid #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const HeaderTr = styled.tr`
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 8;
  `;
  export const TableWrapper = styled.div<{ tableHeight?: number }>`
    height: ${(props) =>
      props?.tableHeight ? props?.tableHeight + "px" : "220px"};
    overflow-y: auto;
    ::-webkit-scrollbar-track {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
  `;

  export const GrandTotalTd = styled.td`
    padding: 8px 0px 8px 10px;
    border-top: 1px #e9e6e6 solid!important;
  `;
  export const GrandTotalDiv = styled.div`
    background-color: #7031eb;
    color: white;
    border-radius: 10px;
    background-size: 100% 100%;
    /*transform: scale(0.9, 0.9);*/
    height: 115%;
    padding: 10px 0;
    transform-origin: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 8;
    width: 60%;
  `;
  export const FooterTd = styled.td`
    background-color: white;
    border-top: 1px #e9e6e6 solid!important;
    z-index: 8;
  `;
  export const FooterTr = styled.tr`
    position: sticky;
    bottom: 0;
    z-index: 8;
    background-color: white !important;
  `;

  export const TrCustom = styled(Tr)<{ selected?: boolean }>`
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  `;

  export const TdCustom = styled.td`
    padding: 16px 0;
  `;

  export const ClearFiltersButtonWrapper = styled.div`
    width: 38px;
    height: 38px;
    border: 1px solid #ebebeb;
    border-radius: 19px;
    background: white;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;
    align-items: center;
    margin: 0 10px 0 10px;

    &:hover {
      cursor: pointer;
    }
  `;

  export const TotalRowsFooter = styled(Box)`
    margin-top: 25px;
    margin-bottom: 10px;
  `

  export const FooterEmptyCell = styled.div`
    width: 100%;
    border-top: 1px #e9e6e6 solid!important;
    background-color: white !important;
  `

  export const LearnMoreButton = styled.div`
    width: 80px;
    color: ${(props) => props.theme.primaryColor};
    &:hover{
      cursor: pointer;
    }
  `
}

export default styles;

import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
   /* width: 100%;
    position: relative;
    margin: 10px;
    box-shadow: rgb(17 12 46 / 15%) 5px 6px 12px 2px;
    border-radius: 30px;
    max-height: 600px;
    overflow: auto;
    ::-webkit-scrollbar-track {    
      margin-top: 75px !important;
      margin-bottom: 15px !important;
    }*/
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  `;
  export const StyledTbody = styled.tbody`
    overflow: hidden;
    max-height: 0px;
    overflow-x: hidden;
  `

  export const CogsTable = styled.table`
    height: 100%;
    background-color: #ffffff;
    border: none;
    width: 100%;

    /*::-webkit-scrollbar-track {
      margin-bottom: 15px !important;
    }*/
  `;
export const ScrollDiv = styled.div`
   width: 100%;
    position: relative;
    margin: 10px;
    box-shadow: rgb(17 12 46 / 15%) 5px 6px 12px 2px;
    /*border-radius: 30px;*/
    max-height: 600px;
    overflow-x: auto;
    overflow-y: auto;
  ::-webkit-scrollbar:vertical {
    width: 7px !important;
  }
    ::-webkit-scrollbar-track {    
      margin-top: 70px !important;
      margin-bottom: 0px !important;
    }
`

  export const CogsTh = styled.th`
    position: sticky;
    top: 0;
    background-color: #ffffff;
    padding: 0;
    z-index: 20;
    &:hover{
      cursor: pointer;
    }
  `;

export const StyledTr = styled.tr`
`

  export const ApplyChangesBar = styled.div`
    position: absolute;
    right: 0;
    top: 70vh;
    width: 33vw;
    height: 70px;
    padding: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 18px 0 0 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #71d18b;
    z-index: 4;
  `;
  export const ApplyChangesButton = styled.button`
    border-radius: 40px;
    border-width: 0px;
    font-size: 18px;
    font-weight: bold;
    box-shadow: ${(props) => props.theme.fullShadow};
    background-color: #ffffff;
    padding: 10px 20px;
    color: #71d18b;
  `;

  export const CogsTd = styled.td`
    min-width: max-content;
    min-height: max-content;
    padding: 0;
    white-space: nowrap;
    
  `;
}
export default styles;

import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { ProductsState } from "./state";

export const productsStateSelector = (state: RootState): ProductsState =>
  state.products;

export const productsDataSelector = createSelector(
  productsStateSelector,
  (state) => state.data?.data
);
export const productsCountSelector = createSelector(
  productsStateSelector,
  (state) => state.data?.count
);

export const productsMetaDataSelector = createSelector(
    productsStateSelector,
    state => state?.metaData
)

export const productsLoadingSelector = createSelector(
    productsStateSelector,
    state => state?.isPutLoading
)
export const postLoadingSelector = createSelector(
    productsStateSelector,
    state => state?.isPostLoading
)

export const productsFieldsSelector = createSelector(
    productsStateSelector,
    state => state.productsReducedData.data
)
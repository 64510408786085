import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  export const ComponentBackground = styled.div`
    width: 381px;
    background-color: #e8e2f9;
    height: 113px;
    border-radius: 6px;
    display: flex;
    align-items: center;
  `;
  export const DataWrapper = styled.div`
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
  `;
  export const IconPlaceholder = styled.div`
    min-width: 50px;
    height: 50px;
    background-color: blue;
    border-radius: 50px;
    margin: auto 0;
  `;
  export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const Text = styled.p`
    color: #7a86a1;
    font-size: 15px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const ClickSpan = styled.span`
    text-decoration: underline;
    color: #4a55fb;
    &:hover {
      cursor: pointer;
    }
  `;
}

export default styles;

export const WideviewLogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="216.161" height="37.874" viewBox="0 0 216.161 37.874">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" y1="1.28" x2="0.033" y2="-0.021" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#5e00ff" />
                    <stop offset="0.256" stopColor="#7416ff" />
                    <stop offset="0.79" stopColor="#ac50ff" />
                    <stop offset="1" stopColor="#c569ff" />
                </linearGradient>
            </defs>
            <g id="Group_11422" data-name="Group 11422" transform="translate(-1496 1438)">
                <g id="Group_1022" data-name="Group 1022" transform="translate(1496 -1438)">
                    <g id="type" transform="translate(59.506 3.94)">
                        <path id="type_CompoundPathItem_" data-name="type &lt;CompoundPathItem&gt;" d="M238.55,59h-5.9l-4.252-10.925L224.215,59h-5.9L212.264,40.82l5.316-.221,3.812,13.162,4.583-12.905,4.876-.257,4.582,13.235,3.849-13.014,5.279-.221Z" transform="translate(-212.264 -33.157)" fill="#fff" />
                        <path id="type_CompoundPathItem_2" data-name="type &lt;CompoundPathItem&gt;" d="M333.341,20.322a3.135,3.135,0,1,1,3.153-3.154A3.122,3.122,0,0,1,333.341,20.322Zm2.53,19.576h-4.949V21.716l4.949-.221Z" transform="translate(-297.156 -14.053)" fill="#fff" />
                        <path id="type_CompoundPathItem_3" data-name="type &lt;CompoundPathItem&gt;" d="M374.885,39.813a15.93,15.93,0,0,1-5.756,1.174c-6.306,0-10.265-3.776-10.265-9.422s3.886-9.421,10.155-9.421a16.444,16.444,0,0,1,5.426,1.1V15.361l4.986.257V40.84h-4.4Zm-.44-4.472V27.9a15.124,15.124,0,0,0-4.986-1.027c-5.352,0-5.572,3.373-5.572,4.693s.22,4.692,5.572,4.692A14.244,14.244,0,0,0,374.445,35.341Z" transform="translate(-317.766 -14.994)" fill="#fff" />
                        <path id="type_CompoundPathItem_4" data-name="type &lt;CompoundPathItem&gt;" d="M459.976,51.213l-13.748-.074c.66,1.283,2.126,2.53,5.389,2.53a18.053,18.053,0,0,0,5.939-1.026l1.393,4.4a18.685,18.685,0,0,1-7.405,1.356c-6.306,0-10.815-3.776-10.815-9.422s4.179-9.421,10.485-9.421c4.692,0,8.908,3.043,8.908,8.762C460.122,49.746,459.976,51.213,459.976,51.213Zm-13.931-4.106,9.055-.073c0-1.8-1.54-2.75-3.813-2.75C447.914,44.283,446.558,45.751,446.045,47.107Z" transform="translate(-376.681 -32.406)" fill="#fff" />
                        <path id="type_CompoundPathItem_5" data-name="type &lt;CompoundPathItem&gt;" d="M519.174,59,512.135,40.82l2.493-.111,6.159,16.167,6.232-16.167,2.456-.11L522.4,59Z" transform="translate(-428.069 -33.157)" fill="#fff" />
                        <path id="type_CompoundPathItem_6" data-name="type &lt;CompoundPathItem&gt;" d="M579.586,20.831a1.558,1.558,0,1,1,1.54-1.576A1.557,1.557,0,0,1,579.586,20.831Zm-1.21,3.446,2.383-.11V42.534h-2.383Z" transform="translate(-475.503 -16.688)" fill="#fff" />
                        <path id="type_CompoundPathItem_7" data-name="type &lt;CompoundPathItem&gt;" d="M615.6,50.443l-16.717-.074c.623,3.593,3.666,5.866,8.1,5.866a18.472,18.472,0,0,0,7.149-1.283l.587,2.126a21.027,21.027,0,0,1-7.809,1.32c-6.305,0-10.558-3.776-10.558-9.422s4.033-9.421,10.338-9.421c4.692,0,9.055,3.043,9.055,8.762C615.746,49.087,615.6,50.443,615.6,50.443ZM598.773,48.39l14.518-.22c-.037-4.546-3.226-6.452-6.489-6.452C601.889,41.718,599.029,44.283,598.773,48.39Z" transform="translate(-488.678 -32.406)" fill="#fff" />
                        <path id="type_CompoundPathItem_8" data-name="type &lt;CompoundPathItem&gt;" d="M665.8,40.727l2.493-.111,5.059,16.131,5.682-16.02,3.116-.147,5.573,16.167,5.132-16.167,2.456-.11-5.792,18.44H686.18l-5.536-15.543L674.925,58.91h-3.336Z" transform="translate(-538.654 -33.064)" fill="#fff" />
                    </g>
                    <g id="logoMark">
                        <path id="logoMark_PathItem_" data-name="logoMark &lt;PathItem&gt;" d="M46.657,0A7.1,7.1,0,0,1,52.9,10.5L41.282,33.721a7.1,7.1,0,0,1-12.92.007l-1.484-2.966L25.4,33.721a7.1,7.1,0,0,1-12.919.007L.864,10.5A7.075,7.075,0,0,1,0,7.1H0A7.1,7.1,0,0,1,7.1,0H46.657Z" fill="url(#linear-gradient)" />
                        <path id="logoMark_PathItem_2" data-name="logoMark &lt;PathItem&gt;" d="M121.9,10.5,110.283,33.721a7.1,7.1,0,0,1-12.92.007l-1.484-2.966,13.484-26.94A7.1,7.1,0,0,1,115.656,0h0A7.1,7.1,0,0,1,121.9,10.5Z" transform="translate(-69)" fill="#00005b" />
                        <path id="logoMark_PathItem_3" data-name="logoMark &lt;PathItem&gt;" d="M56.5,30.762,41.163,0H76.179L71.413,1.809Z" transform="translate(-29.624)" fill="#00005b" opacity="0.183" />
                    </g>
                </g>
            </g>
        </svg>


    );
};

export const NoPinnedKpiIcon = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="54.592" height="54.592" viewBox="0 0 54.592 54.592">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Rectangle_322" data-name="Rectangle 322" width="54.592" height="54.592" fill="none" />
                <rect id="Rectangle_323" data-name="Rectangle 323" width="54.592" height="54.592" fill="none" />
                <rect id="Rectangle_324" data-name="Rectangle 324" width="54.592" height="54.592" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(2.292 1.953)">
                <g id="Group_946" data-name="Group 946" transform="translate(0 0)">
                    <path id="Path_493" data-name="Path 493" d="M27.021,2A24.908,24.908,0,1,0,44.748,9.3,25.021,25.021,0,0,0,27.021,2Zm0,45.494A20.472,20.472,0,1,1,47.494,27.021,20.472,20.472,0,0,1,27.021,47.494Z" transform="translate(-2 -2)" fill="#7031eb" />
                    <g id="Layer_2-2" data-name="Layer 2" transform="translate(2.52 14.789) rotate(-20)">
                        <g id="invisible_box-2" data-name="invisible box">
                            <rect id="Rectangle_1042" data-name="Rectangle 1042" width="34" height="34" transform="translate(0.449 0.449)" fill="none" />
                        </g>
                        <g id="icons_Q2-2" data-name="icons Q2" transform="translate(5.816 2.908)">
                            <path id="Path_12565" data-name="Path 12565" d="M29.811,22.176h-.218L26.176,16V6.908H26.9A1.454,1.454,0,0,0,26.9,4H12.362a1.454,1.454,0,1,0,0,2.908h.727V16l-3.417,6.18H9.454a1.454,1.454,0,1,0,0,2.908h8.724v8a1.454,1.454,0,0,0,2.908,0v-8h8.724a1.454,1.454,0,1,0,0-2.908ZM23.268,6.908v6.543H16V6.908ZM16,16.36h7.27l3.054,5.816H12.944Z" transform="translate(-8 -4)" fill="#7031eb" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

export const NoAssignedKpiIcon = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="54.592" height="54.592" viewBox="0 0 54.592 54.592">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Rectangle_322" data-name="Rectangle 322" width="54.592" height="54.592" fill="none" />
                <rect id="Rectangle_323" data-name="Rectangle 323" width="54.592" height="54.592" fill="none" />
                <rect id="Rectangle_324" data-name="Rectangle 324" width="54.592" height="54.592" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(2.292 1.953)">
                <g id="Group_946" data-name="Group 946" transform="translate(0 0)">
                    <path id="Path_493" data-name="Path 493" d="M27.021,2A24.908,24.908,0,1,0,44.748,9.3,25.021,25.021,0,0,0,27.021,2Zm0,45.494A20.472,20.472,0,1,1,47.494,27.021,20.472,20.472,0,0,1,27.021,47.494Z" transform="translate(-2 -2)" fill="#7031eb" />
                    <g id="np_user_4406629_000000" transform="translate(13.648 9.75)">
                        <path id="Path_61" data-name="Path 61" d="M45.418,18.022A5.522,5.522,0,1,1,39.9,12.5a5.522,5.522,0,0,1,5.522,5.522" transform="translate(-28.854 -12.5)" fill="#7031eb" />
                        <path id="Path_62" data-name="Path 62" d="M39.843,56.34l-3.423-3.2a2.487,2.487,0,0,0-2.982-.331,7.744,7.744,0,0,1-7.289,0,2.689,2.689,0,0,0-2.982.331L19.744,56.23a3.426,3.426,0,0,0-.994,2.43v3.092a2.959,2.959,0,0,0,2.761,3.092H38.076a2.89,2.89,0,0,0,2.761-3.092V58.659A3.008,3.008,0,0,0,39.843,56.34Z" transform="translate(-18.751 -38.34)" fill="#7031eb" />
                    </g>
                </g>
            </g>
        </svg>

    );
};
// imports
import {createSlice} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearAmazonState} from "./actions";
import {AmazonState, initialAmazonState} from "./state";

// reducers
function buildCleanAmazonStateReducer(builder: ActionReducerMapBuilder<AmazonState>) {
    builder.addCase(clearAmazonState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.code = undefined;
        return state;
    });
}

// slice
export const amazonSlice = createSlice({
    name: 'amazon',
    initialState: initialAmazonState,
    reducers: {},
    extraReducers: (builder => {
        // non async
        buildCleanAmazonStateReducer(builder);

        // async
    }),
});

// reducer
export const amazonReducer = amazonSlice.reducer;

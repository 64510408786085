import successMessageStyles from "./styles";
import { useCallback, useEffect, useState } from "react";
import { authService } from "@services";
import { useNavigate } from "react-router";
import { Box, Flex, Input, InputGroup, InputRightElement, Spinner } from "@chakra-ui/react";
import styles from './styles';
import { t } from "i18next";
import StrengthMeter from "components/password-strength/StrengthMeter";
import { AiOutlineEye } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { changePassword } from "@store";


interface Props {
    message?: string;
}
enum PasswordLevel {
    NOT_ALLOWED,
    OKAY,
    STRONG,
    VERY_STRONG
}

export const ResetPassword = (props: Props) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordInputConfirm, setPasswordInputConfirm] = useState("");
    const [poorPassword, setPoorPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [passwordError, setPasswordErr] = useState("");
    const [passwordLevel, setPasswordLevel] = useState<number>();
    const [formValidity, setFormValidity] = useState(false);

    useEffect(() => {
        passwordStrength();
    }, [passwordInput, passwordInputConfirm]);

    const dispatch = useDispatch();
    const navigate = useNavigate();



    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const token = params.get('token');
    const passwordColors = { weak: "#F7686A", okay: "#F0BB09", strong: "#61C322", veryStrong: "#61C322", gray: "#E2E2EA" };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatchNewPassword();
        //local storage , user id , url token, new password
    };


    const passwordStrength = () => {
        const passwordValue = passwordInput;
        const passwordLength = passwordInput.length;

        const passwordValueConfirm = passwordInputConfirm;
        const passwordLengthConfirm = passwordInputConfirm.length;


        const onlyAllowedCharacter = /[^a-z0-9#?!@$%^&*-]/i;
        const poorRegExp = /[a-z]/;
        const weakRegExp = /(?=.*?[0-9A-Z])/;
        const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
        const whitespaceRegExp = /^$|\s+/;
        const notAllowedPassword = onlyAllowedCharacter.test(passwordValue);
        const poorPassword = poorRegExp.test(passwordValue);
        const weakPassword = weakRegExp.test(passwordValue);
        const strongPassword = strongRegExp.test(passwordValue);

        if (passwordValue === '') {
            setPasswordErr("");
            setPasswordLevel(PasswordLevel.NOT_ALLOWED);
            setFormValidity(false);
        } else {

            // to check whitespace
            /*   if (whiteSpace) {
                  setPasswordErr("Whitespaces are not allowed");
              } */
            // to check poor password

            if (passwordLength > 0 && !notAllowedPassword) {
                setPasswordErr("");
            }
            if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
                setPoorPassword(true);
                /* setPasswordErr("Password is Okay"); */
                setPasswordLevel(PasswordLevel.OKAY);
                setFormValidity(true);
            }
            // to check weak password
            if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
                setWeakPassword(true);
                /* setPasswordErr("Password is Strong"); */
                setPasswordLevel(PasswordLevel.STRONG);
                setFormValidity(true);

            } else {
                setWeakPassword(false);
            }
            // to check strong Password
            if (passwordLength >= 8 && (poorPassword && weakPassword) && strongPassword) {
                setStrongPassword(true);
                /* setPasswordErr("Password is Very Strong"); */
                setFormValidity(true);
                setPasswordLevel(PasswordLevel.VERY_STRONG);
            }

            if (passwordLength < 8) {
                setPasswordErr(`Minimum password length is 8 characters`);
                setPasswordLevel(PasswordLevel.NOT_ALLOWED);
                setFormValidity(false);

            }
            if (passwordLength > 0 && notAllowedPassword) {
                setPasswordErr("Non english characters are not allowed");
                setPasswordLevel(PasswordLevel.NOT_ALLOWED);
                setFormValidity(false);
            }
            if (passwordInputConfirm.length > 0 && passwordInput !== passwordInputConfirm) {
                setPasswordErr("Passwords dont match");
                setFormValidity(false);
            }
            if (passwordInputConfirm.length === 0) {
                setFormValidity(false);

            }


        }

    };

    const handlePasswordChange = (evnt: { target: { value: any; }; }, setState: (e: any) => void) => {
        setState(evnt.target.value);
        if (evnt.target.value.indexOf(' ') >= 0) {
            setPasswordErr("Whitespaces are not allowed");
        }
    };

    const dispatchNewPassword = () => {
        dispatch(changePassword({ token: token, password: passwordInput }));
    };

    const renderLevel = () => {
        switch (passwordLevel) {
            case (PasswordLevel.NOT_ALLOWED):
                return "Weak";
            case (PasswordLevel.OKAY):
                return "Okay";
            case (PasswordLevel.STRONG):
                return "Strong";
            case (PasswordLevel.VERY_STRONG):
                return "Very Strong";
            default:
                return "";
        }
    };

    const renderBar = (color: string, numberOfColoredBars: number) => {
        const colored = [...Array(numberOfColoredBars)].map((item, i) => {
            if (i + 1 === [...Array(numberOfColoredBars)].length) {
                return (
                    <styles.PasswordBar key={uuidv4()} color={color} >
                        <styles.LastBarHolder color={color}>
                        </styles.LastBarHolder>
                        {numberOfColoredBars === 4 ? < styles.LastBarHolder color={color} key={uuidv4()}>{renderLevel()}</styles.LastBarHolder> : <></>}
                    </styles.PasswordBar>


                )
                    ;
            }
            return (<styles.PasswordBar color={color} key={uuidv4()} />)
                ;
        });
        const notColored = [...Array(4 - numberOfColoredBars)].map((item, i) => {

            return <styles.PasswordBar color={passwordColors.gray} key={uuidv4()} >
                {i === 3 - numberOfColoredBars ? < styles.LastBarHolder color={color} key={uuidv4()}>{renderLevel()}</styles.LastBarHolder> : <></>}

            </styles.PasswordBar >
                ;
        });

        return [...colored, ...notColored];
    };

    const renderBars = () => {
        switch (passwordLevel) {
            case (PasswordLevel.NOT_ALLOWED):
                return renderBar(passwordColors.weak, PasswordLevel.NOT_ALLOWED + 1);
            case (PasswordLevel.OKAY):
                return renderBar(passwordColors.okay, PasswordLevel.OKAY + 1);
            case (PasswordLevel.STRONG):
                return renderBar(passwordColors.strong, PasswordLevel.STRONG + 1);
            case (PasswordLevel.VERY_STRONG):
                return renderBar(passwordColors.veryStrong, PasswordLevel.VERY_STRONG + 1);

        }
        return;
    };

    const renderSignUpFormPasswordInput = (passwordShow: any, setPasswordShow: (e: any) => void, passwordValue: string, setState: (e: any) => void, handleInput: boolean) => {
        const handleClick = () => setPasswordShow(!passwordShow);
        return (
            <>
                <InputGroup size="md">
                    <styles.SignUpFormInput type={passwordShow ? "text" : "password"} required minLength={8} value={passwordValue} onChange={(e: any) => handlePasswordChange(e, setState)}
                        placeholder={t('CREATE_PASSWORD', { ns: 'translation' })} />
                    <InputRightElement>
                        <styles.SignUpShowPasswordButton onClick={handleClick}>
                            <AiOutlineEye size='max-content' color="gray" />
                        </styles.SignUpShowPasswordButton>
                    </InputRightElement>
                </InputGroup>
            </>
        );
    };


    const renderSignUpFormTitle = () => {
        return (
            <styles.SignUpFormTitle>
                {t('CHANGE_YOUR_PASSWORD', { ns: 'translation' })}
            </styles.SignUpFormTitle>
        );
    };
    const renderSignUpFormSubmitButton = () => {
        return (

            formValidity ?
                <styles.SignUpFormSubmitButton type="submit">
                    {t('CHANGE_MY_PASSWORD', { ns: 'translation' })}
                </styles.SignUpFormSubmitButton>
                :
                (<styles.ButtonDisable >
                    {t('CHANGE_MY_PASSWORD', { ns: 'translation' })}
                </styles.ButtonDisable>)
        );
    };

    const renderSignUpForm = () => {
        return (
            <styles.SignUpForm onSubmit={handleSubmit}>
                {renderSignUpFormTitle()}
                <div style={{ height: '20px' }} />
                <styles.FormInputs >
                    <styles.StyledTitle>
                        {t('TYPE_NEW_PASSWORD', { ns: 'translation' })}
                    </styles.StyledTitle>
                    {/* <Box h={2} /> */}

                    {renderSignUpFormPasswordInput(passwordShow, setPasswordShow, passwordInput, setPasswordInput, true)}
                    <Box h={5} />
                    <styles.StyledTitle>

                        {t('CONFIRM_NEW_PASSWORD', { ns: 'translation' })}
                    </styles.StyledTitle>
                    {/* <Box h={2} /> */}

                    {renderSignUpFormPasswordInput(passwordShowConfirm, setPasswordShowConfirm, passwordInputConfirm, setPasswordInputConfirm, false)}
                </styles.FormInputs>
                {!!passwordError ? <StrengthMeter poorPassword={poorPassword} weakPassword={weakPassword} strongPassword={strongPassword} passwordError={passwordError} /> : <div style={{ height: '24px' }} />}
                <br />
                {passwordInput.length > 0 ?
                    <styles.BarHolders>
                        {renderBars()}
                    </styles.BarHolders> : <div style={{ height: '5px' }} />}
                <styles.SignInText>
                    By signing up, you confirm that you’ve read and accepted our {" "}
                    <styles.hrefText target={"_blank"} rel="noreferrer" href={"https://www.wideview.io/privacy-policy"}>
                        User Notice {" "}
                    </styles.hrefText >
                    and {" "}
                    <styles.hrefText target={"_blank"} rel="noreferrer" href={"https://www.wideview.io/privacy-policy"}>
                        Privacy Policy.
                    </styles.hrefText>
                </styles.SignInText>
                {renderSignUpFormSubmitButton()}


            </styles.SignUpForm>
        );
    };


    const renderSignUpContent = () => {
        return (
            <styles.SignUpContent>
                <styles.SignUpCard>
                    {renderSignUpForm()}
                    {/* <styles.BottomCard ></styles.BottomCard> */}
                </styles.SignUpCard>

            </styles.SignUpContent>
        );
    };

    return (
        <styles.SignUpWrapper img={process.env.PUBLIC_URL + '/assets/auth-background.png'}>
            {/* {renderSignUpHeader()} */}
            {renderSignUpContent()}
        </styles.SignUpWrapper>
    );
};

export const SalesChannelsRound = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 64 64">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#7031eb"/>
                    <stop offset="1" stop-color="#4a55fb"/>
                </linearGradient>
            </defs>
            <g id="Group_11783" data-name="Group 11783" transform="translate(-5 -375)">
                <path id="Path_1806" data-name="Path 1806" d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z" transform="translate(5 375)" fill="url(#linear-gradient)"/>
                <g id="Layer_2" data-name="Layer 2" transform="translate(19 388.769)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_382" data-name="Rectangle 382" width="36" height="36" transform="translate(0 0.231)" fill="none"/>
                    </g>
                    <g id="Health_Icons" data-name="Health Icons" transform="translate(1.576 3.019)">
                        <g id="Group_1135" data-name="Group 1135">
                            <path id="Path_532" data-name="Path 532" d="M35.083,13.36v-.226L31.308,4.906A1.51,1.51,0,0,0,29.95,4H7.305a1.51,1.51,0,0,0-1.359.906l-3.7,8.228v.226A4.529,4.529,0,0,0,3,17.285a5.208,5.208,0,0,0,2.114,1.51V31.928a2.189,2.189,0,0,0,2.264,2.264H29.95a2.189,2.189,0,0,0,2.264-2.264V18.795a5.208,5.208,0,0,0,2.113-1.51,4.529,4.529,0,0,0,.755-3.925Zm-30.042.906L8.286,7.019H28.969l3.246,7.246a1.434,1.434,0,0,1-.3,1.132,1.585,1.585,0,0,1-1.359.679h-.679a1.661,1.661,0,0,1-1.736-1.585,1.51,1.51,0,1,0-3.019,0,1.585,1.585,0,0,1-1.661,1.585H21.8a1.585,1.585,0,0,1-1.661-1.585,1.51,1.51,0,1,0-3.019,0,1.585,1.585,0,0,1-1.661,1.585H13.8a1.585,1.585,0,0,1-1.661-1.585,1.51,1.51,0,1,0-3.019,0,1.661,1.661,0,0,1-1.736,1.585H6.7A1.585,1.585,0,0,1,5.343,15.4,1.434,1.434,0,0,1,5.041,14.266Zm21.89,16.908V21.361H21.647v9.813H8.06V19.021a4.453,4.453,0,0,0,2.566-1.132A4.755,4.755,0,0,0,13.8,19.1h1.661a4.755,4.755,0,0,0,3.17-1.208A4.755,4.755,0,0,0,21.8,19.1h1.661a4.755,4.755,0,0,0,3.17-1.208,4.453,4.453,0,0,0,2.566,1.132V31.174Z" transform="translate(-2.087 -4)" fill="#fff"/>
                            <rect id="Rectangle_383" data-name="Rectangle 383" width="8" height="8" transform="translate(8.424 17.212)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
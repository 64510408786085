export const blendedRoasConfig = [
    {
        "_id" : "64bfbb2e3c5e4d57c73a633e",
        "tableName" : "blendedRoas",
        "rows" : [
            {
                "fieldName" : "grossRevenue",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Gross Revenue"
                    },
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "netRevenue",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Net Revenue"
                    },
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "Blended ROAS",
                "attribute" : [

                ]
            },
            {
                "fieldName" : "grossProfit",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Gross Profit"
                    },
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "digitalMarketingSpend",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Digital Marketing Spend"
                    },
                    {
                        "key" : "displayCurrency",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "GoogleAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "FacebookAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "AmazonAdsCost",
                "attribute" : [
                    {
                        "key" : "display",
                        "value" : "false"
                    }
                ]
            },
            {
                "fieldName" : "shareOfTotalRevenue",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Share of total"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "shareOfTotalDigitalSpend",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Share of total"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    }
                ]
            },
            {
                "fieldName" : "FacebookAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Facebook Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    },
                    {
                        "key" : "backgroundColor",
                        "value" : "#f2efef"
                    }
                ]
            },
            {
                "fieldName" : "GoogleAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Google Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    },
                    {
                        "key" : "backgroundColor",
                        "value" : "#f2efef"
                    }
                ]
            },
            {
                "fieldName" : "AmazonAdsCostShare",
                "attribute" : [
                    {
                        "key" : "displayName",
                        "value" : "Amazon Ads %"
                    },
                    {
                        "key" : "displayPercentage",
                        "value" : "true"
                    },
                    {
                        "key" : "backgroundColor",
                        "value" : "#f2efef"
                    }
                ]
            }
        ],
        "columns" : [

        ],

    }

]
// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

// locals
import { clearTooltipsState} from "./actions";
import {initialTooltips, TooltipState} from "./state";
import {fetchTooltips} from "./thunks";

// reducers
  function buildCleanTooltipsStateReducer(
    builder: ActionReducerMapBuilder<TooltipState>
  ) {
    builder.addCase(clearTooltipsState, (state) => {
      state.error = undefined;
      state.loading = false;
      state.data = undefined;
      return state;
    });
  }

// fetch organization
function buildFetchTooltipsReducer(builder: ActionReducerMapBuilder<TooltipState>) {
  builder
    .addCase(fetchTooltips.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(fetchTooltips.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = false;
      state.data = action?.payload?.general;
      return state;
    })
    .addCase(fetchTooltips.rejected, (state, action) => {
      state.error = undefined;
      state.loading = false;
      return state;
    });
}

// slice

export const tooltipsSlice = createSlice({
  name: "tooltips",
  initialState: initialTooltips,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanTooltipsStateReducer(builder);

    // async
    buildFetchTooltipsReducer(builder);
  },
});

// reducer
export const toolstipsReducer = tooltipsSlice.reducer;

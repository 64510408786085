import { PopoverBody } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    border-top: 2px solid #e1e3e5;
    width: 700px;
    margin: 35px 0 0 0;
  `;

  export const Button = styled.div<{ selected?: boolean }>`
    height: 58px;
    background-color: ${(props) => (props.selected ? "#DFE9E0" : "#D8DFED")};
    color: ${(props) => (props.selected ? "#61C322" : "#7A86A1")};
    display: flex;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 31px 54px #695f970d;
    border-radius: 10px;
    z-index: 10;
    position: relative;

    &:hover {
      cursor: ${(props) => (props.selected ? "pointer" : null)};
    }
  `;
  export const ToolTipWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `;
  export const ToolTipText = styled.div`
    margin: 0 0 0 6px;
  `;
  export const ToolTipLink = styled.div`
    color: #4a55fb;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  `;
  export const DivWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
  `;
  export const ButtonText = styled.div``;
  export const ButtonsContainer = styled.div`
    overflow: hidden;
    display: flex;
    margin: 23px 0 0 0;
  `;
  export const iconHolder = styled.div`
    top: 48px;
    display: flex;
    width: 100%;
    border-radius: 0 0 10px 10px;
    background-color: #f5f4fc;
    z-index: 0;
    display: flex;
    position: absolute;
  `;
  export const Spacer = styled.div`
    position: absolute;
  `;
  export const IconRow = styled.div`
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 0 10px;
  `;
  export const ButtonWrapper = styled.div`
    width: 154px;
    margin: 0 0 0 28px;
    &:hover{
      cursor: pointer;
    }
    &:first-of-type {
      margin: 0;
    }
  `;
  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    padding-top: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;
  export const Icon = styled.div`
    max-width: 30px;
    max-height: 30px;
    margin: 18px 0 0 3px;
  `;
  export const StyledToolTip = styled(PopoverBody)`
    font-size: 10px;
    font-weight: bold;
    color: #1a0989;
    display: flex;
    align-items: center;
  `;
}

export default styles;

import {RootState} from "@store";
import {createSelector} from "@reduxjs/toolkit";
import {ShopifyState} from "./state";

// sign-up state
export const shopifyStateSelector = (state: RootState): ShopifyState => state.shopify;

// code
export const codeSelector = createSelector(shopifyStateSelector, (state) => state.code);

// oAuthUrl
export const shopifyOAuthUrlSelector = createSelector(shopifyStateSelector, (state) => state.oAuthUrl);

// sign-up error
export const shopifyErrorSelector = createSelector(shopifyStateSelector, (state) => state.error);

// loading
export const shopifyLoadingSelector = createSelector(shopifyStateSelector, (state) => state.loading);

import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import {
  clearAlertsState,
  deleteLocalAlert,
  updateLocalAlerts,
} from "./actions";
import { AlertsState, initalAlertsState } from "./state";
import {editAlerts, fetchAlerts} from "./thunks";

function buildCleanAlertsStateReducer(
  builder: ActionReducerMapBuilder<AlertsState>
) {
  builder.addCase(clearAlertsState, (state, action) => {
    state.data = [];
    return state;
  });
}

function buildUpdateLocalAlertsStateReducer(
  builder: ActionReducerMapBuilder<AlertsState>
) {
  builder.addCase(updateLocalAlerts, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
    return state;
  });
}
function buildDeleteLocalAlertsStateReducer(
  builder: ActionReducerMapBuilder<AlertsState>
) {
  builder.addCase(deleteLocalAlert, (state, action) => {
    state.loading = false;
    state.data = state.data.filter((item) => item.id !== action.payload.id);
    return state;
  });
}

function buildFetchAlertsDataReducer(
  builder: ActionReducerMapBuilder<AlertsState>
) {
  builder
    .addCase(fetchAlerts.pending, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    })
    .addCase(fetchAlerts.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = true;
      return state;
    })
    .addCase(fetchAlerts.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    });
}

function buildEditAlert(
    builder: ActionReducerMapBuilder<AlertsState>
) {
  builder
      .addCase(editAlerts.pending, (state, action) => {

        return state;
      })
      .addCase(editAlerts.fulfilled, (state, action) => {
        const foundIndex = state?.data?.findIndex((item) => item?.id === action?.payload?.id);
        if(foundIndex > -1){
          state.data[foundIndex] = action?.payload;
        }
        state.loading = true;
        return state;
      })
      .addCase(editAlerts.rejected, (state, action) => {

        return state;
      });
}

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: initalAlertsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanAlertsStateReducer(builder);

    buildDeleteLocalAlertsStateReducer(builder);

    buildUpdateLocalAlertsStateReducer(builder);

    buildEditAlert(builder)
    // async
    buildFetchAlertsDataReducer(builder);
  },
});

// reducer
export const alertsReducer = alertsSlice.reducer;

import styled from "styled-components";
import tinycolor from "tinycolor2";
import {NavLink} from "react-router-dom";

export namespace styles {
  export const Wrapper = styled.div<{ showMargin?: boolean }>`
    display: flex;
    flex-direction: column;
  `;

  // button
  export const LinkWrapper = styled.div<{ active: boolean , isSubItem: boolean}>`
    a {
      display: flex;
      flex-direction: row;

      cursor: pointer;
      border-radius: 5px;
      // background-color: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.lightTextColor};
      //margin: 10px 0px;
      max-height: 32px;
      align-items: center;
      background-color: ${(props) =>
        props.active ? props.theme.primaryColor : ""};
      :hover {
        background-color: ${(props) => props.isSubItem ? 'unset' : props.theme.primaryColor};
      }
      font-family: ${(props) => props.theme.fonts.menu};
    }
  `;

  export const BadgeWrapper = styled.div`
    width: 70px;
    height: max-content;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #c9a598;
    color: white;
  `

  // icon
  export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    margin-inline-start: 30px;
  `;


  export const IconWrapperSubItemTop = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid #FFFFFF;
      opacity: 0.4;
      height: 100%;
      width: 20px;
      border-bottom: 1px solid #FFFFFF;
`;

  export const IconWrapperSubItemBottom = styled.div<{isLast: boolean}>`
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: ${props => props.isLast ? 'unset' : '1px solid #FFFFFF'};
      opacity: 0.4;
      height: 100%;
      width: 20px;
`;

  // title
  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 120px;
  `;

  export const Title = styled.span<{ active: boolean }>`
    font-size: 15px;
    font-weight: ${(props) => (!!props.active ? "bold" : "icons")};
    color: ${(props) =>
      props.active
        ? props.theme.lightTextColor
        : tinycolor(props.theme.lightTextColor).setAlpha(0.5).toHex8String()};
    margin: 5px;
    width: 100%;
  `;

  // arrow
  export const ArrowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    //transform: rotate(270deg);
  `;

  // sub items
  export const SubItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-start: 30px;
  `;

  export const SubItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  export const CustomNavLink = styled(NavLink)<{hoverColor: string}>`
    &:hover {
      background-color: ${(props) => props.hoverColor};
    }
    
  `
}

export default styles;

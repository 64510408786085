import topBarStyles from "./styles";
import React, { useState } from "react";
import { SwiperSlide } from "swiper/react/swiper-react.js";
import { Avatar, Icon, useDisclosure } from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { organizationSelector, userSelector } from "@store";
import AvatarUploadDialog, {
  UploadModalType,
} from "../../dialogs/avatar-upload-dialog/AvatarUploadDialog";

interface Props {
    title?: string;
    message?: string;
}


export const TopBar = (props: Props) => {

    // hooks
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const user = useSelector(userSelector);
    const organization = useSelector(organizationSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // helpers

    function getGreetingByHour(hour: number): string {
        if (hour >= 5 && hour < 12) {
            return "Good Morning";
        } else if (hour >= 12 && hour < 15) {
            return "Good Afternoon";
        } else if (hour >= 15 && hour < 18) {
            return "Good Afternoon";
        } else if (hour >= 18 && hour < 22) {
            return "Good Evening";
        } else {
            return "Good Night";
        }
    }

    const now = new Date();
    const hour = now.getHours();
    const greeting = getGreetingByHour(hour) + `, ${user?.firstName}`;

    // renderers
    const renderTitle = () => {
        return (
            <topBarStyles.Title>{greeting}</topBarStyles.Title>
        );
    };

    const renderLogo = () => {
        return (
            <topBarStyles.Logo src={'https://i.pinimg.com/originals/63/fb/ce/63fbcebe6858a27559e21670cbeccd7e.jpg'} />
        );
    };

    const renderMessage = () => {
        return (
            <topBarStyles.MessageWrapper>
                <topBarStyles.Message>
                    Watch the tutorial
                </topBarStyles.Message>
                <topBarStyles.MessageSliderIconWrapper>
                    <Icon as={MdChevronRight} mt={1} fontSize={20} onClick={() => swiperRef?.slideNext()} />
                </topBarStyles.MessageSliderIconWrapper>
            </topBarStyles.MessageWrapper>
        );
    };

    const renderMessageSlider = () => {
        return (
            <topBarStyles.MessageSliderWrapper>
                {/*<topBarStyles.MessageSliderIconWrapper>*/}
                {/*    <Icon mt={1} as={MdChevronLeft} fontSize={20} onClick={() => swiperRef?.slidePrev()} />*/}
                {/*</topBarStyles.MessageSliderIconWrapper>*/}
                <topBarStyles.MessageSlider
                    onSwiper={setSwiperRef}
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                >
                    {Array(4).fill(0).map((i, index) => (
                        <SwiperSlide style={{ width: 150 }} key={index}>{renderMessage()}
                        </SwiperSlide>))}
                </topBarStyles.MessageSlider>

            </topBarStyles.MessageSliderWrapper>
        );
    };

    const renderContent = () => {
        const lengthOfDefaultString = 24
        return (
            <topBarStyles.Wrapper>
                <AvatarUploadDialog  modalType={UploadModalType.ORGANIZATION} onClose={onClose} onOpen={onOpen} isOpen={isOpen}/>

                <topBarStyles.Card>
                    <topBarStyles.Header>
                        <topBarStyles.HeaderContent>
                            <Avatar onClick={onOpen} color={"white"} src={organization?.image}
                                    background={organization?.image?.length < lengthOfDefaultString || !organization?.image ? '#7031EB' : 'white'}
                                    border={'1px solid #7031EB'}
                                    _hover={{
                                        cursor: "pointer",
                                        opacity: 0.7

                                    }}
                                    margin={[0, 3]} size={'md'} name={
                                organization?.image?.length < lengthOfDefaultString || !organization?.image ?
                                    !!organization?.name ? organization?.name[0] : '' : ''} />
                            {renderTitle()}
                            {/*{renderMessageSlider()}*/}
                        </topBarStyles.HeaderContent>
                        {/*<Image src={process.env.PUBLIC_URL + '/assets/images/dots.png'}>*/}
                        {/**/}
                        {/*</Image>*/}
                    </topBarStyles.Header>
                    <topBarStyles.ContentSeparator />
                </topBarStyles.Card>
            </topBarStyles.Wrapper>
        );
    };

    return renderContent();
};

import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { clearNotifcationsState, setNewNotifications } from "./actions";

import { initialNotificationsState, Notifications, Notification } from "./state";
import { deleteNotification, fetchNotifications } from "./thunks";

function buildCleanNotifiactionsStateReducer(
  builder: ActionReducerMapBuilder<Notifications>
) {
  builder.addCase(clearNotifcationsState, (state, action) => {
    state.data = [];
    return state;
  });
}

// set new Notifcations
function buildSetNewNotifcations(
  builder: ActionReducerMapBuilder<Notifications>
) {
  builder.addCase(setNewNotifications, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
    return state;
  });
}

function buildFetchNotifcationsDataReducer(
  builder: ActionReducerMapBuilder<Notifications>
) {
  builder
    .addCase(fetchNotifications.pending, (state, action) => {
      state.data = [];
      state.loading = true;
      return state;
    })
    .addCase(fetchNotifications.fulfilled, (state, action) => {
      state.data = action.payload.data?.reverse();
      state.loading = false;
      return state;
    })
    .addCase(fetchNotifications.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      return state;
    });
}
function buildDeleteNotifcation(
  builder: ActionReducerMapBuilder<Notifications>
) {
  builder
    .addCase(deleteNotification.pending, (state, action) => {
      state.loading = false;
      return state;
    })
    .addCase(deleteNotification.fulfilled, (state, action) => {
      state.loading = true;
      return state;
    })
    .addCase(deleteNotification.rejected, (state, action) => {
      state.loading = false;
      return state;
    });
}

export const notifcationsSlice = createSlice({
  name: "notifcations",
  initialState: initialNotificationsState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanNotifiactionsStateReducer(builder);

    buildSetNewNotifcations(builder);

    // async

    buildFetchNotifcationsDataReducer(builder);

    /* buildDeleteEffort(builder); */
  },
});

// reducer
export const notifcationsReducer = notifcationsSlice.reducer;

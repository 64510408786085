import styled from 'styled-components';
import {Props} from "./ImpactOnTarget";
import tinycolor from "tinycolor2";

export namespace styles {

    export const ProgressBarWrapper = styled.div<Props>`
      /*background-color: ${props => tinycolor(props.color).setAlpha(0.3).toHex8String()};*/
      border-radius: 6px;
      height: 6px;
      width: 100%;
      display: flex;
    `;

    export const ProgressBar = styled.div<Props>`
      width: 100%;
      /*background-color: ${props => props.color};*/
      border-radius: 6px;
      height: 6px;
    `;
    export const negativeBar = styled.div<Props>`
      width: ${props => props?.percentage < 0 ? Math.abs(props?.percentage) + '%' : 0};
      border-radius: 0 6px  6px 0;
      height: 6px;
      max-width: 100%;
      background-color: ${props => props.color};
    `
    export const negativeBarHolder = styled.div<{color?: string}>`
      width: 50%;
      border-radius: 6px 0 0 6px;
      height: 6px;
      background-color: ${props => tinycolor('#F7686A').setAlpha(0.3).toHex8String()};
      opacity: .5;
      display: flex;
      justify-content: flex-end;
    `
    export const positiveBarHolder = styled.div<{color?: string}>`
      width: 50%;
      border-radius: 0 6px  6px 0;
      height: 6px;
      background-color: ${props => tinycolor('#A3DB81').setAlpha(0.3).toHex8String()};
    `;
    export const positiveBar = styled.div<Props>`
      width: ${props => props?.percentage > 0 ? props?.percentage + '%' : 0};
      max-width: 100%;
      border-radius: 0 6px  6px 0;
      height: 6px;
      background-color: ${props => props.color};
    `;

}

export default styles;

import styled from "styled-components";
import { Table, Td, Th, Tr } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    padding: 0;
  `;

  export const Title = styled.div`
      display: flex;
      font-size: 21px;
      font-weight: bold;
      padding: 5px;
      border-color: ${(props) => props.theme.backgroundColor};
      font-family: ${(props) => props.theme.fonts.header};
      border-color: ${(props) => props.theme.backgroundColor};
  `;

  export const IntegrationsTableWrapper = styled.div`
    overflow: hidden;
    overflow-y: scroll;
    height: 395px;
    margin: 25px 0 25px 0;
  `;

  export const IntegrationsTable = styled(Table)`
    font-size: 12px;
    font-weight: normal;
    border-collapse: separate !important; /* Don't collapse */
    border-spacing: 0;
  `;

  export const TableTd = styled(Td)<{ width: number, pointer?: true }>`
    position: relative;
    padding: 5px !important;
    width: ${(props) => props.width +"px"};
    border-bottom: solid 1px ${(props) => props.theme.tableBorderColor};
    border-right: solid 1px ${(props) => props.theme.tableBorderColor};

    :first-child {
      border-left: solid 1px ${(props) => props.theme.tableBorderColor};
    }
    &:hover{
      cursor: ${props => props?.pointer ? "pointer": ""};
    }
    
  `;

  export const StoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `;

  export const ArrowHolder = styled.div`
    //position: absolute;
    //right: 7px;
    //top: 50%;
    font-size: 18px;
    //transform: translateY(-50%);
  `

  export const TableTh = styled(Th)<{ width?: number }>`
    width: ${(props) => props?.width +"%"} !important;
    background-color: white !important;
    text-transform: none !important;
    position: sticky;
    z-index: 1;
    top: 0;
    border-top: solid 1px ${(props) => props.theme.tableBorderColor};
    border-bottom: solid 1px ${(props) => props.theme.tableBorderColor};
    border-right: solid 1px ${(props) => props.theme.tableBorderColor};

    :first-child {
      border-left: solid 1px ${(props) => props.theme.tableBorderColor};
    }
  `;

  export const TableTr = styled(Tr)<{ index: number }>`
    //border: solid 1px gray;
    z-index: -1;
    background-color: ${(props) =>
      props.index % 2 === 0
        ? props.theme.tableEvenCellBackgroundColor
        : props.theme.tableOddCellBackgroundColor};
  `;

  export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `;

  export const StatusIcon = styled.img`
    margin: 5px;
    height: 35px;
    width: auto;
  `;

  export const StatusInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  export const StatusText = styled.span`
    color: ${(props) => props.theme.textColor};
    font-size: 14px;
  `;

  export const StatusLastUpdatedText = styled.span`
    color: ${(props) => props.theme.descriptionTextColor};
    font-size: 8px;
  `;

  export const More = styled.div`
    margin: 5px;
    font-size: 20px;
    display: flex;
  `;

  export const NameInput = styled.input`
   /* position: absolute;
    left: 0;
    right: 0;
    color: gray;
    top: 0;
    bottom: 0;*/
    width: 90%;
    padding: 0 0 0 10px;
    /*margin: 0 auto;*/
    /*max-width: 300px;
    min-width: 100px;*/
    margin: 0 0 0 12px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    background-color: unset;
    &:hover {
      outline: 1px solid ${props => props?.theme?.primaryColor} !important;
      border-color: unset;
      box-shadow: none;
      color: black;
    }
    &:focus {
      outline: none !important;
      border-color: unset;
      box-shadow: none;
      color: black;
    }
  `;

  export const NameInputDisabled = styled.input`
   /* position: absolute;
    left: 0;
    right: 0;
    color: gray;
    top: 0;
    bottom: 0;*/
    width: 90%;
    padding: 0 0 0 10px;
    /*margin: 0 auto;*/
    /*max-width: 300px;
    min-width: 100px;*/
    margin: 0 0 0 12px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    background-color: unset;
   
  `;

  export const Saved = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    color: green;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 10px;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  `;  export const SavedHidden = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    color: green;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  `;
}

export default styles;

import styled from 'styled-components';

export const InputWrapper = styled.div`
  height: 24px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.inputBackgroundColor};
  border: 1px solid ${props => props.theme.inputBorderColor};
  > input {
    width: calc(100% - 20px);
    height: calc(100% - 4px);
    outline: none;
    border: none;
    background-color: transparent;
    ::-webkit-input-placeholder {
      color: ${props => props.theme.inputColor};
    }

    :-ms-input-placeholder {
      color: ${props => props.theme.inputColor};
    }

    ::placeholder {
      color: ${props => props.theme.inputColor};
    }
  }
`;

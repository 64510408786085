
import styles from "./styles";
import {useEffect, useState} from "react";
import {getIntegrationPlatformRoundLogo, IntegrationPlatform} from "@models";


interface Props {
    color: string;
    width: string;
    text?: string;
}


export const StatusChip = (props: Props) => {
    const [backgroundColor, setBackgroundColor] = useState('');

    //hooks
    useEffect(() => { // get currency sign
        const rgba = hexToRgb(props.color, 0.3);
        setBackgroundColor(`rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, ${rgba?.a})`);
        console.log(backgroundColor)
    }, []);

    //helpers
    const hexToRgb = (hex: string, alpha: number) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: alpha
        } : null;
    }

    return <>
        <styles.ChipWrapper width={props.width} color={backgroundColor}>
            <styles.Chip color={props.color}>
                {props.text}
            </styles.Chip>
        </styles.ChipWrapper>
    </>
}

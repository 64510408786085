import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationsService } from "services/notifcations/notificationsService";
import { NotificationChangeState } from "./state";

export const fetchNotifications = createAsyncThunk<
  any,
  { organizationId: string }

>(
  "notifications/fetchNotificationsData",
  async ({ organizationId }: { organizationId: string }, hunkAPI) => {
    const response = await notificationsService.fetchNotifications({
      organizationId,
    });
    return response.data;
  }
);

export const deleteNotification = createAsyncThunk<any, any>(
  "notifications/deleteNotification",
  async (
    { id, organizationId }: { id: string; organizationId: string },
    thunkAPI
  ) => {
    const response = await notificationsService.deleteNotification({
      id,
      organizationId,
    });
    return response.data;
  }
);

export const changeStateNotification = createAsyncThunk<any, any>(
  "efforts/editEffortsData",
  async (
    {
      data,
      organizationId,
    }: { data: NotificationChangeState; organizationId: string },
    thunkAPI
  ) => {
    const response = await notificationsService.changeStateNotification({
      data,
      organizationId,
    });
    return response.data;
  }
);

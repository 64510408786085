import { DownloadButtonWrapper } from "./styles";
// @ts-ignore
import { CSVLink } from "react-csv";
import { useRef, useState } from "react";
// @ts-ignore
import { Parser } from "json2csv";
import { useGenerateCSV } from "../../utils/custom-hooks/useGenerateCSV";
import { Box } from "@chakra-ui/react";

export interface Field {
  label: string;
  value: string;
  isoFormat?: true;
  customCallback?: (e: string) => string;
}

interface Props {
  onClick?: () => void;
  fileName?: string;
  data?: any;
  serviceCallback?: () => void;
  fields?: Field[];
}

export const DownloadButton = (props: Props) => {
  // hooks
  const [csv] = useGenerateCSV({ fields: props?.fields, data: props?.data });
  const csvLink = useRef<any>();

  // helpers

  const getData = async () => {
    if (!!props?.serviceCallback) {
      props?.serviceCallback();
    } else {
      csvLink?.current?.link?.click();
    }
  };

  // renderers

  const DownloadIcon = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Box margin={[0, "auto"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="23.98"
          viewBox="0 0 24 23.98"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? (
            <path
              id="icon_dowload"
              data-name="icon dowload"
              d="M1311.069,666.052v-5.06a.95.95,0,0,1,.907-.988.941.941,0,0,1,.933.861l.008.126v5.06h5.736a5.573,5.573,0,0,1,5.34,5.526v6.632a5.609,5.609,0,0,1-5.078,5.774h-13.594a5.554,5.554,0,0,1-5.328-5.526v-6.62a5.612,5.612,0,0,1,5.066-5.775l.25-.006h5.76v8.077l-1.92-2.087a.874.874,0,0,0-1.281-.028.331.331,0,0,0-.027.028,1.1,1.1,0,0,0-.1,1.3l.1.126,3.492,3.8a.864.864,0,0,0,1.218.09l.09-.09,3.492-3.8a1.069,1.069,0,0,0,0-1.421.874.874,0,0,0-1.2-.1l-.1.1-1.908,2.085v-8.077Z"
              transform="translate(-1299.993 -660.005)"
              fill="#7031eb"
            />
          ) : (
            <path
              id="icon_dowload"
              data-name="icon dowload"
              d="M1311.069,666.052v-5.06a.95.95,0,0,1,.907-.988.941.941,0,0,1,.933.861l.008.126v5.06h5.736a5.573,5.573,0,0,1,5.34,5.526v6.632a5.609,5.609,0,0,1-5.078,5.774h-13.594a5.554,5.554,0,0,1-5.328-5.526v-6.62a5.612,5.612,0,0,1,5.066-5.775l.25-.006h5.76v8.077l-1.92-2.087a.874.874,0,0,0-1.281-.028.331.331,0,0,0-.027.028,1.1,1.1,0,0,0-.1,1.3l.1.126,3.492,3.8a.864.864,0,0,0,1.218.09l.09-.09,3.492-3.8a1.069,1.069,0,0,0,0-1.421.874.874,0,0,0-1.2-.1l-.1.1-1.908,2.085v-8.077Z"
              transform="translate(-1299.993 -660.005)"
              fill="#464a53"
            />
          )}
        </svg>
      </Box>
    );
  };

  return (
    <DownloadButtonWrapper
      onClick={() => {
        getData();
        /*!!props?.onClick && props?.onClick();*/
      }}
    >
      <CSVLink
        data={csv}
        filename={props?.fileName ?? ""}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      <DownloadIcon />
    </DownloadButtonWrapper>
  );
};

import styled from "styled-components";
import { Swiper } from "swiper/react/swiper-react.js";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    display: flex;
  `;

  export const Card = styled.div`
    width: 100%;
    padding: 10px;
    background-color: #e9e7fa;
    border-radius: 5px 5px 0 0;
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px 10px 5px 25px;
    align-items: center;
  `;

  export const ContentSeparator = styled.hr`
    width: 96%;
    background: #b4abab50;
    height: 1px;
    margin: auto;
  `;

  export const Title = styled.div`
    font-size: 21px;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.header};
  `;

  export const Logo = styled.img`
    width: 64px;
    height: 64px;
    margin: 5px;
    border-radius: 10px;
  `;

  export const MessageSliderWrapper = styled.div`
    //width: 250px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `;

  export const MessageSliderIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;

  export const MessageSlider = styled(Swiper)`
    width: 200px;
  `;

  export const MessageWrapper = styled.div`
    margin: 5px;
    display: flex;
    align-items: center;
  `;

  export const Message = styled.span`
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
    text-decoration: underline;
    color: ${(props) => props.theme.primaryColor};
  `;
}

export default styles;

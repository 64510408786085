import selectMenuStyles from "./styles";
import { Icon } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { renderFlag } from "utils/icons/flags/flags";


export interface SelectMenuOption<T = string> {
    value: T;
    label?: string;
}

interface Props<T> {
    options: any[];
    setValueCallback: (value: T) => void;
    bold?: boolean;
    renderFlags?: boolean;
}

export const SelectMenu = <T,>(props: Props<T>) => {

    // hooks
    const [selectedOption, setSelectedOption] = useState<any>(props.options[0]);
    // helpers

    const setValue = (option: SelectMenuOption<T>) => {
        setSelectedOption(option);
        props.setValueCallback(option.value);
    };

    const renderTitle = () => {
        if (props.renderFlags) {
            const flag = (renderFlag(selectedOption.value.code));
            return (<>
                <selectMenuStyles.FlagHolder>{flag}</selectMenuStyles.FlagHolder>
                &nbsp;
                {selectedOption.value.sign}{selectedOption.value.code}
            </>);
        }
        else {
            return selectedOption.label;
        }
    };
    // renderers

    return (
        <selectMenuStyles.StyledMenu >
            <selectMenuStyles.SelectButton bold={props.bold ? "true" : "false"}>
                {selectedOption.label ? renderTitle() : selectedOption.value}
                <Icon as={IoIosArrowDown} marginLeft={"auto"} fontSize={20} />
            </selectMenuStyles.SelectButton>
            <selectMenuStyles.SelectMenuList >

                {
                    props.options.map((option, index) => {
                        if (props.renderFlags) {
                            const flag = (renderFlag(option.value.code));
                            return (<selectMenuStyles.MenuItemStyled bold={props.bold ? "true" : "false"} key={index} onClick={() => setValue(option)}>
                                <selectMenuStyles.FlagHolder>{flag}</selectMenuStyles.FlagHolder>
                                &nbsp;
                                {option.value.sign}{option.value.code} {option.value.name}
                            </selectMenuStyles.MenuItemStyled>);
                        }
                        return (<>
                            <selectMenuStyles.MenuItemStyled bold={props.bold ? "true" : "false"} key={index} onClick={() => setValue(option)}>{option.label ? option.label : option.value}</selectMenuStyles.MenuItemStyled>
                        </>);
                    })
                }
            </selectMenuStyles.SelectMenuList>
        </selectMenuStyles.StyledMenu>
    );
};

import styles from "./styles";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../../dialogs/more-info-dialog/MoreInfoDialog";
import { DatepickerRange } from "../../datepicker-range/DatepickerRange";
import {
  IconAcrossChannels,
  IconClearFiltersArrow,
  IconShare,
} from "../../../utils/icons/explore";
import { useDispatch, useSelector } from "react-redux";
import { MoreInfoButton } from "../../more-info-button/MoreInfoButton";
import { effortsSelector, EffortsState } from "store/efforts";
import {
  dateRangeSelector,
  ExploresEnum,
  isDefaultExploreOpenSelector,
  openDefaultExploreView,
} from "../../../store/ui";
import { Box, useDisclosure } from "@chakra-ui/react";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { KpisEnum } from "models/kpi-settings";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";

import { GroupBy } from "features/workspace/Workspace";
import { OverTimeGraph } from "components/over-time-graph/OverTimeGraph";
import {
  InventoryData,
  OperationData,
  operationsStateSelector,
  PurchasesStats,
  StatusPurchases,
  TransportationStatusEnum,
} from "../../../store/operation";
import {
  addHandlingDays,
  getSummedDataByKey,
  groupArrayByKey,
  groupArrayByKeyAndValue,
  sumGroupedDataByKey,
} from "../../../utils/kpi-data-format/kpiDataFormat";
import { getOvertimeGraphDataForExplore } from "../../../utils/kpi-render/kpiCalculation";
import {
  ExploreTableFooterTypeEnum,
  GenericExploreTable,
  TableColumn,
} from "../../generic-explore-table/GenericExploreTable";
import { useCurrencySign } from "../../../utils/custom-hooks/useCurrencySign";
import { PurchaseOrder } from "../../../services/operations/operation.service";


const GrossProfitExploreMoreInfoDialogProps = {
    title: 'GROSS_PROFIT_EXPLORE',
    subtitle: 'How to use this view?',
    description: 'Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.',
    tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
    videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'
} as MoreInfoDialogProps;

interface GraphData {
    xLabels: string[];
    grossRevenue: number[];
    netRevenue: number[];
    netMarginActual: number[];
}
type AllData = OperationData[] | InventoryData[];

enum ValueType {
    NONE,
    CURRENCY,
    PERCENTAGE
}

type AnyObject = { [key: string]: any };

interface TableRow<T extends AnyObject> {
    original: T;
}

export const OperationKpiExplore = () => {

    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    const [isNetRevenueMoreInfoDialogOpen, setIsNetRevenueMoreInfoDialogOpen] = useState(false);
    const openNetRevenueLanguageMoreInfoDialog = (isOpen: boolean) => setIsNetRevenueMoreInfoDialogOpen(isOpen);
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const datesSelector = useSelector(dateRangeSelector);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const dispatch = useDispatch();
    const efforts: EffortsState = useSelector(effortsSelector);
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [ganttLabels, setGanttLabels] = useState<string[]>([])
    const [totalValue, setTotalValue] = useState<number>(0);
    const currentExplore = useSelector(isDefaultExploreOpenSelector)?.exploreEnum;
    const isOpen = useSelector(isDefaultExploreOpenSelector)?.isDefaultExploreOpen;
    const operationData = useSelector(operationsStateSelector);
    const [allData, setAllData] = useState<AllData>([]);
    const [exploreTitle, setExploreTitle] = useState<string>('');
    const [overTimeData, setOverTimeData] = useState<{data: number[], labels: string[]}>({data: [], labels: []});

     const currency = useCurrencySign();
     const [totalValueType, setTotalValueType] = useState<ValueType>(ValueType.NONE);

     const [hideOvertime, setHideOvertime] = useState<boolean>(false);

    const [selectedTopLeftTableRows, setSelectedTopLeftTableRows] = useState<TableRow<AnyObject>[]>([]);
    const [topLeftTableDefaultSortKey, setTopLeftTableDefaultSortKey] = useState<string>('');
    const [topLeftTableColumns, setTopLeftTableColumns] = useState<TableColumn[]>([]);
    const [topLeftTableData, setTopLeftTableData] = useState<{ [key: string]: number | string;  }[]>([]);

    const [selectedTopRightTableRows, setSelectedTopRightTableRows] = useState<TableRow<AnyObject>[]>([]);
    const [topRightTableDefaultSortKey, setTopRightTableDefaultSortKey] = useState<string>('');
    const [topRightTableColumns, setTopRightTableColumns] = useState<TableColumn[]>([]);
    const [topRightTableData, setTopRightTableData] = useState<{ [key: string]: number | string;  }[]>([]);

    useEffect(() => {
        if(isOpen){
            setAllData(setExploreData());
        }
    }, [currentExplore, operationData?.data, isOpen, selectedTopRightTableRows, selectedTopLeftTableRows]);

    useEffect(() => {
        setTotalValue(getTotalValueForExplore());
    } ,[currentExplore, allData, datesSelector, isOpen, selectedTopRightTableRows, selectedTopLeftTableRows]);

    useEffect(() => {
        if(!isOpen){
            cleanStates();
        }
    }, [isOpen, currentExplore]);

    const cleanStates = () => {
        setTopLeftTableData([]);
        setTopLeftTableColumns([]);
        setTopLeftTableDefaultSortKey('');
        setSelectedTopLeftTableRows([]);
        setTopRightTableData([]);
        setTopRightTableColumns([]);
        setTopRightTableDefaultSortKey('');
        setSelectedTopRightTableRows([]);
        setOverTimeData({data: [], labels: []});
        setAllData([]);
        setTotalValueType(ValueType.NONE);
        setHideOvertime(false);
    }



    const inventoryNumberOfDevicesTable : TableColumn[] = [
        {accessor: 'displayName', header:'Description', footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL},
        {accessor: 'qty', header:'Quantity', footerType: ExploreTableFooterTypeEnum.SUM, cell: (value) => value?.toLocaleString('en-US', {maximumFractionDigits: 0})},
    ]

    const incompleteOrdersByCourierTable : TableColumn[] = [
        {accessor: 'courier', header:'Courier', footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL},
        {accessor: 'numberOfIncomplete', header:'Amount of Incomplete Orders', footerType: ExploreTableFooterTypeEnum.SUM, cell: (value) => value?.toLocaleString('en-US', {maximumFractionDigits: 0})},
    ]

    const incompleteOrdersByDaysTable : TableColumn[] = [
        {accessor: 'handlingDays', header:'Handling Days', footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL},
        {accessor: 'numberOfIncomplete', header:'Amount of Incomplete Orders', footerType: ExploreTableFooterTypeEnum.SUM, cell: (value) => value?.toLocaleString('en-US', {maximumFractionDigits: 0})},
    ];

    const handleFilterDataBySelectedRows = <T extends AnyObject>(
        array: T[],
        key: keyof T,
        selectedRows: TableRow<AnyObject>[],
    ) => {
        const allSelectedFields = selectedRows?.map((item) => item?.original[key as keyof typeof item.original]);
        const data = array?.filter((item) => allSelectedFields?.includes(item[key]));
        return data;
    };

    const handleIncompleteOrdersExplore = () => {
        setHideOvertime(true);
        if(!!operationData?.data?.purchaseStats){
            const groupedData = groupArrayByKeyAndValue(operationData?.data?.purchaseStats, 'statusCode', StatusPurchases.INCOMPLETE);
            let data = groupedData[StatusPurchases.INCOMPLETE];
            const incompleteOrdersGroupedDataByCourier = groupArrayByKey(groupedData[StatusPurchases.INCOMPLETE] as PurchasesStats[], 'courier');

            if(selectedTopLeftTableRows?.length > 0){
                data = handleFilterDataBySelectedRows(data, 'courier', selectedTopLeftTableRows);
            }

            const transformedArray = Object.entries(incompleteOrdersGroupedDataByCourier).map(([key, value]) => ({
                courier: key,
                numberOfIncomplete: value?.length
            }));

            const dataWithHandlingDays = addHandlingDays(data as any);
            const dataGroupedByHandlingDays = groupArrayByKey(dataWithHandlingDays, 'handlingDays');


            const transformedHandlingDaysArray = Object.entries(dataGroupedByHandlingDays).map(([key, value]) => ({
                handlingDays: key,
                numberOfIncomplete: value?.length
            }));
            setTopRightTableColumns(incompleteOrdersByDaysTable);
            setTopRightTableData(transformedHandlingDaysArray);
            setTopRightTableDefaultSortKey('numberOfIncomplete');

            setTopLeftTableData(transformedArray);
            setTopLeftTableColumns(incompleteOrdersByCourierTable);
            setTopLeftTableDefaultSortKey('numberOfIncomplete');

            return data;
        }
        return []
    }

    const handleNumberOfDevicesExplore = () => {
        if(!!operationData?.data?.inventoryData){
            const inventoryDataGroupedByType = groupArrayByKey(operationData?.data?.inventoryData, 'description');
            const inventoryTopLeftTableData = sumGroupedDataByKey(inventoryDataGroupedByType, []);
            setTopLeftTableData(inventoryTopLeftTableData);
            setTopLeftTableColumns(inventoryNumberOfDevicesTable);
            setTopLeftTableDefaultSortKey('qty');
            return operationData?.data?.inventoryData
        }
        return []
    }

    const setExploreData = () => {
        let groupedData:any= {};

        switch (currentExplore){
            case (ExploresEnum.PENDING_DEVICES):
                if(!!operationData?.data?.transportationData){
                    groupedData = groupArrayByKeyAndValue(operationData?.data?.transportationData, 'statusCode', TransportationStatusEnum.PENDING_ARRIVAL);
                    const fixedData = groupedData[TransportationStatusEnum.PENDING_ARRIVAL]?.map((item:OperationData) => {return {...item, activityDate: item?.createDate}});
                    return fixedData
                }
                return []
            case (ExploresEnum.HOLDING_DEVICES):
                if(!!operationData?.data?.transportationData){
                    groupedData = groupArrayByKeyAndValue(operationData?.data?.transportationData, 'statusCode', TransportationStatusEnum.ON_HOLD);
                    const fixedData = groupedData[TransportationStatusEnum.ON_HOLD]?.map((item:OperationData) => {return {...item, activityDate: item?.createDate}});
                    return fixedData
                }
                return []
            case (ExploresEnum.APPROVED_DEVICES):
                if(!!operationData?.data?.transportationData){
                    groupedData = groupArrayByKeyAndValue(operationData?.data?.transportationData, 'statusCode', TransportationStatusEnum.APPROVED);
                    const fixedData = groupedData[TransportationStatusEnum.APPROVED]?.map((item:OperationData) => {return {...item, activityDate: item?.createDate}});
                    return fixedData
                }
                return []
            case (ExploresEnum.NUMBER_OF_DEVICES):
                return handleNumberOfDevicesExplore();
            case (ExploresEnum.INCOMPLETE_ORDERS):
                return handleIncompleteOrdersExplore();
            case (ExploresEnum.REMAINING_COST):
                if(!!operationData?.data?.manufacturingData){
                    setTotalValueType(ValueType.CURRENCY);
                    const fixedData = (operationData?.data?.manufacturingData as (PurchaseOrder & { remainingCost: number })[])?.map((item: PurchaseOrder & { remainingCost: number }) => {return {...item, remainingCost: !!item?.totalCost && !!item?.paid ? (item?.totalCost - item?.paid) : 0, activityDate: item?.createdAt}});
                    return fixedData;
                }
                return []
            default:
                return []
        }
    }



    const getTotalValueForExplore = () : number => {
        let totalValue = 0;

        switch (currentExplore){
                case (ExploresEnum.PENDING_DEVICES):
                    if(!!allData){
                        setExploreTitle('Pending Inbound Devices');
                        setOvertimeGraphData('quantity');
                        totalValue = getSummedDataByKey(allData as OperationData[], 'quantity');
                    }
                    break
            case (ExploresEnum.HOLDING_DEVICES):
                if(!!allData){
                    setExploreTitle('On Hold Inbound Devices');
                    setOvertimeGraphData('quantity');
                    totalValue = getSummedDataByKey(allData as OperationData[], 'quantity');
                }
                break
            case (ExploresEnum.APPROVED_DEVICES):
                if(!!allData){
                    setExploreTitle('Approved Devices');
                    setOvertimeGraphData('quantity');
                    totalValue = getSummedDataByKey(allData as OperationData[], 'quantity');
                }
                break
            case (ExploresEnum.NUMBER_OF_DEVICES):
                if(!!allData){
                    setExploreTitle('Number of Devices');
                    setOvertimeGraphData('qty');
                    totalValue = getSummedDataByKey(allData as InventoryData[], 'qty');
                }
                break
            case (ExploresEnum.INCOMPLETE_ORDERS):
                if(!!allData){
                    setExploreTitle('Incomplete Orders');
                    console.log(allData);
                    totalValue = allData?.length
                }
                break
            case (ExploresEnum.REMAINING_COST):
                if(!!allData){
                    setExploreTitle('Remaining Cost');
                    const summedTotalCost = getSummedDataByKey(operationData?.data?.manufacturingData, 'totalCost');
                    const summedTotalPaid = getSummedDataByKey(operationData?.data?.manufacturingData, 'paid');
                    setOvertimeGraphData('remainingCost');

                    totalValue = (summedTotalCost - summedTotalPaid);
                }
                break
            }
            return totalValue
    }

    const setOvertimeGraphData = (key: string) => {
        !!datesSelector?.startDate && !! datesSelector?.endDate && setOverTimeData(getOvertimeGraphDataForExplore(allData, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, key))
    }

    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(new Date(datesSelector.startDate));
            setEndDate(new Date(datesSelector.endDate));
        }
    };

    const renderDatePicker = () => {
        return (
            <>
                {
                    !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => { }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)} />
                        : <DatepickerRange onApply={(e) => { }} defaultsDate={datesSelector.preset} />
                }
            </>
        );
    };


    const renderHeader = () => {
        return <styles.Header>
            <styles.TitleWrapper>
                <styles.TitleDynamic>
                    {exploreTitle}
                </styles.TitleDynamic>
                <styles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </styles.TitleStatic>
                <MoreInfoButton onClick={() => openNetRevenueLanguageMoreInfoDialog(true)} />
                <MoreInfoDialog
                    props={GrossProfitExploreMoreInfoDialogProps}
                    isDialogOpen={isNetRevenueMoreInfoDialogOpen}
                    onClose={() => openNetRevenueLanguageMoreInfoDialog(false)}
                />
                <styles.HeaderButtonsWrapper>
                    <styles.HeaderButton>
                        <IconClearFiltersArrow />
                        <div style={{ width: '10px' }} />
                        {t('RESET_FILTERS', { ns: 'translation' })}
                    </styles.HeaderButton>
                    {renderDatePicker()}
                    <styles.HeaderButton onClick={onShareOpen}>
                        <IconShare />
                        <div style={{ width: '10px' }} />
                        {t('SHARE', { ns: 'translation' })}
                    </styles.HeaderButton>
                        <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                            <IconCloseOverlay />
                        </button>
                </styles.HeaderButtonsWrapper>
            </styles.TitleWrapper>
            <styles.ValueWrapper>
                <styles.Value>
                    {totalValueType === ValueType.CURRENCY ? currency : ''}{totalValue?.toLocaleString('en-us', {maximumFractionDigits: 0})}
                </styles.Value>
            </styles.ValueWrapper>
            <styles.SubTitle>
                {t('DRILL_DOWN_TO_THE_MAIN_DRIVERS_OF_THIS_KPI_(OVER_TIME,_CHANNELS,_SUPPORTING_KPIS)_AND_ASSIGN_AN_OWNER_TO_THIS_TASK_ACCORDINGLY.', { ns: 'translation' })}
            </styles.SubTitle>
        </styles.Header>;
    };

    const renderOvertimeGraph = () => {
        return (
          <styles.SectionWrapper>
            {
              !overTimeData?.data && <Box
                w={"100%"}
                h={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                filter="grayscale(100%)"
                zIndex={5}
                position={"absolute"}
              >
                No Data
              </Box>
            }
            <OverTimeGraph
              exploreGraph={true}
              displayGantt={true}
              setGanttLabels={setGanttLabels}
              clearFilters={resetFilters}
              groupByStatus={groupByStatus}
              setGroupBy={setGroupByStatus}
              kpiEnum={KpisEnum.PENDING_DEVICES}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              actual={overTimeData?.data}
              labels={overTimeData?.labels}
            />
          </styles.SectionWrapper>
        );
    }

    const renderTopLeftTable = () => {
        return (
            <GenericExploreTable
                icon={<IconAcrossChannels />}
                setSelectedRows={setSelectedTopLeftTableRows}
                data={topLeftTableData}
                defaultSortByKey={topLeftTableDefaultSortKey}
                headers={topLeftTableColumns}
                height={500}
                tableHeight={380}
                title={t("Across Products", { ns: "translation" })}
            />
        )
    }

    const renderTopRightTable = () => {
        return (
            <GenericExploreTable
                icon={<IconAcrossChannels />}
                setSelectedRows={setSelectedTopRightTableRows}
                data={topRightTableData}
                defaultSortByKey={topRightTableDefaultSortKey}
                headers={topRightTableColumns}
                height={500}
                tableHeight={380}
                title={t("Across Products", { ns: "translation" })}
            />
        )
    }

    const renderSectionOne = () => {
        return (
            <styles.SectionWrapper>
                <styles.Card>
                    {topLeftTableColumns?.length > 0 && renderTopLeftTable()}
                </styles.Card>
                <styles.Card>
                    {topRightTableColumns?.length > 0 && renderTopRightTable()}
                </styles.Card>
            </styles.SectionWrapper>
        )
    }

    return (
        <styles.Wrapper>
            <ShareKpi exploreEnum={ExploresEnum.REMAINING_COST} isOpen={isShareOpen} onClose={onShareClose} onOpen={onShareOpen} />
            {renderHeader()}
            {!hideOvertime && renderOvertimeGraph()}
            {topLeftTableColumns?.length > 0 && renderSectionOne()}
        </styles.Wrapper>
    );
};

import styled from "styled-components";

export namespace styles {
  export const PageLink = styled.a<{
    active?: boolean;
    disabled?: boolean;
    isPrevOrNext?: boolean;
  }>`
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #7031EB;
    text-align: center;
    color: ${(props) => (props.active ? "#7031EB" : "#7A86A1")};
    background-color: ${(props) => (props.active ? "#fbf7ff" : "fff")};
    padding:  ${(props) => (props.isPrevOrNext ? "4px 11px 8px" : "4px 4px 4px 10px")}; //calc(4% - 11px) 2px calc(4% - 11px)
    font-size: ${(props) => (props.isPrevOrNext ? "16px" : "12px")};//12px;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    cursor: pointer;
    z-index: ${(props) => (!!props?.active ? 2 : 3)};

    &:last-child,  &:first-child {
      background: #fff;
      //border: transparent;
      color: #7031EB;
     width: 37px;
     height: 37px;
     padding: 4px 9px 0;
    }

    &:hover,
    &:focus {
      color: #7031EB;
      background-color: #e9ecef;
    }
    &:focus {
      z-index: 4;
    }
    &[disabled] {
      color: #6c757d;
      pointer-events: none;
      border: 1px solid #6c757d;
    }
  `;
}

export default styles;
import {AuthButtonWrapper} from "./styles";
import {ReactNode} from "react";

export interface Props {
    className?: string;
    children?: ReactNode;
}

export const AuthButton = ({className, children}: Props) => {
    return (
        <AuthButtonWrapper className={className}>
            {children}
        </AuthButtonWrapper>
    );
};

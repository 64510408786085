import { apiBaseUrl } from "@constants";
import axios, { AxiosResponse } from "axios";
import { Effort, EffortsState } from "store/efforts";

interface effort {
  kpiMetric: string;
  description: string;
  assignee: string;
  startDate: string;
  endDate: string;
}
interface EffortUpdate {
  kpiMetric: string;
  description: string;
  assignee: string;
  startDate: string;
  endDate: string;
  id: string;
}

const fetchEfforts = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get<EffortsState>(
    `${apiBaseUrl}/organization/${organizationId}/effort/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const createEffort = async ({
  data,
  organizationId,
}: {
  data: effort;
  organizationId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.post<any>(
    `${apiBaseUrl}/organization/${organizationId}/effort/`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const deleteEffort = async ({
  id,
  organizationId,
}: {
  id: string;
  organizationId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.delete<any>(
    `${apiBaseUrl}/organization/${organizationId}/effort/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const editEffort = async ({
  data,
  array,
  organizationId,
}: {
  data: EffortUpdate;
  array: any[];
  organizationId: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/organization/${organizationId}/effort/${data.id}`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const effortsService = {
  fetchEfforts,
  createEffort,
  deleteEffort,
  editEffort,
};

import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const kpiStateSelector = (state: RootState) => state.kpi;

export const kpiDataSelector = createSelector(
  kpiStateSelector,
  (state) => state
);
export const kpiMarketingSelector = createSelector(
  kpiStateSelector,
  (state) => state.marketing?.data
);
export const kpiPnlSelector = createSelector(
    kpiStateSelector,
    (state) => state.pnlReports
);
export const kpiMarketingMappingSelector = createSelector(
    kpiStateSelector,
    (state) => state.marketingMappingData
);
export const kpiFinanceDaily = createSelector(
  kpiStateSelector,
  (state) => state.financeDaily
);
export const summedKpiDataSelector = createSelector(
  kpiStateSelector,
  (state) => state.summedData
);
export const marketingStatsLoaderSelector = createSelector(
  kpiStateSelector,
  (state) => state.marketingLoader
);

export const exploreLoadingSelector = createSelector(
    kpiStateSelector,
    (state) => state.exploreLoading
);

export const summedTargetStats = createSelector(
    kpiStateSelector,
    (state) => state?.summedData?.targetStats
);


export const customTargetsLoader = createSelector(
    kpiStateSelector,
    (state) => state?.customTargetsTable?.isLoading
);
export const customTargetsSuccess = createSelector(
    kpiStateSelector,
    (state) => state?.customTargetsTable?.isSuccess
);

export const kpisLoader = createSelector(
    kpiStateSelector,
    (state) => state.loading
);

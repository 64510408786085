import styled from "styled-components";
import {Button, Input} from "@components";
import {AuthButton} from "../../../components/auth-button";
import {Spinner} from "@chakra-ui/react";

export const SignInWrapper = styled.div<{ img: string }>`
  background: url(${(props) => props.img}) ${(props) => props.theme.primaryColor} round;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// header
export const SignInHeader = styled.div`
  display: flex;
  justify-content: center;
`;

// title
export const SignInTitle = styled.span`
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
`;

// content
export const SignInContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const SignInCard = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 360px;
  overflow: hidden;
  //@media(max-width: 768px) {
  //  width: 90vw;
  //}
`;

export const SignInImage = styled.div`
  background-image: url("https://wallpaperaccess.com/full/2593043.jpg");
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SignInForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 10px 10px;
  //@media(max-width: 768px) {
  //  width: calc(100% - 20px);
  //}
`;

export const SignInFormTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

export const SignInFormInput = styled(Input)`
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
`;

export const SignInShowPasswordButton = styled.div`
  height: 20px;
  width: 20px;
  background-color: #ffffff00;
  border-width: 0px;
`;

export const SignInFormSubmitLoader = styled(Spinner)`
  height: 32px;
  width: 32px;
  margin: 5px 0;
  color: ${(props) => props.theme.primaryColor};
`;

export const SignInFormSubmitButton = styled(Button)`
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;
  color: white;
`;
export const StyledButton = styled.button`
  height: min-content;
  border-radius: 20px;
  width: 100%;
  max-width: 300px;
  color: white;
  font-family: ${props => props.theme.fonts.roboto.regular};
`

export const SignInFormOrText = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.descriptionTextColor};
  text-align: center;
  margin: 10px 0;
  text-transform: uppercase;
`;

export const SignInFormButton = styled(AuthButton)`
  height: min-content;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0;
  width: 100%;
  max-width: 300px;

  > a {
    font-size: 12px;

    > img {
      height: 14px;
      width: auto;
    }
  }
`;

export const SignInSignInLink = styled.span`
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  background: #fbf7ff 0% 0% no-repeat padding-box;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RecoverLink = styled.span`
  cursor: pointer;
  font-size: 10px;
  margin-top: 5px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";
import { authenticate, authLoadingSelector, organizationSelector, tokenSelector, userSelector } from "@store";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/loading/Loading";

export const OnboardingGuard = (props: any) => {
    const [loading, setLoading] = useState(true);
    const authLoading = useSelector(authLoadingSelector);
    const user = useSelector(userSelector);
    const token = useSelector(tokenSelector);
    const organization = useSelector(organizationSelector);

    // hooks
    const dispatch = useDispatch();
    // console.log(user);
    // console.log('=-=-=-=-=-');
    // console.log(organization);
    // effects
    useEffect(() => {
        if (!user?.id || !token) {
            dispatch(authenticate({ token: null, organizationIdFromStore: organization?.id }));
        }
        setLoading(false);
    }, [dispatch, organization?.id, token, user]);

    if (loading || !!authLoading) {
        return (<Loading />);
    }
    else if (!user?.id) {
        // console.log(user);s
        return (<Navigate to="/auth" replace />);
    }
    /*else if (!user.isVerified) {
        return (<Navigate to="/verify-account" replace />);
    }*/
    else if (!!user?.id && !!token) {
        return (<Outlet />);
    } else {
        return (<Navigate to="/auth" replace />);
    }
};

import { Product } from "../../models/products";
import styles from "./styles";
import { IconAlert } from "../../utils/icons/account-health";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchProductsMetaData, productsDataSelector, productsMetaDataSelector} from "store/products";
import { calculateProductTotalCost } from "./cogs-table/cogs-cells/CogsCells";
import {organizationSelector} from "@store";

interface Props {
    missingCogsProducts?: Product[];
    applyFlag: number;
}

export const MissingCOGS = (props: Props) => {
    const products = useSelector(productsDataSelector);
    const { t } = useTranslation(['translation', 'translation']);
    const [totalMissingCogs, setTotalMissingCogs] = useState(0);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const metaDataSelector = useSelector(productsMetaDataSelector);
    useEffect(() => {
        if(!!organization?.id){
        /*dispatch(fetchProductsMetaData({organizationId: organization?.id}))*/
        }

       /* if (!!products) {
            const deepCopyProducts = JSON.parse(JSON.stringify(products));
            const missingCogs = [];
            deepCopyProducts?.forEach((product: any) => {
                if (calculateProductTotalCost(product?.sellingPrice, product?.fob, product?.cogs) === 0) {
                    missingCogs.push("");
                }
            });
            setTotalMissingCogs(missingCogs.length);
        }*/
    }, [organization?.id /*metaDataSelector?.count*/]);

    useEffect(() => {
        if(metaDataSelector?.count !== undefined){
            setTotalMissingCogs(metaDataSelector?.count)
        }
    }, [metaDataSelector?.count])

    if (totalMissingCogs === 0) {
        return (<></>);
    }
    return (
        <styles.MissingCogsBar>
            <styles.MissingCogsHeadline>
                <IconAlert />
                <styles.MissingCogsText>
                    {t("MISSING_COGS_YOU_HAVE", { ns: "translation" })}
                    {" " + totalMissingCogs + " "}
                    {t("PRODUCTS_THAT_ARE_MISSING_PRODUCTION_COSTS", { ns: "translation" })}
                </styles.MissingCogsText>
            </styles.MissingCogsHeadline>
            {/*  <div>
                <styles.MissingCogsText>
                    {t("LEARN_MORE", { ns: "translation" })}
                    {" "}
                    <HiOutlineChevronDoubleRight size={"16px"} color={"#F7686A"} />
                </styles.MissingCogsText>
            </div> */}
        </styles.MissingCogsBar>
    );
};

export default MissingCOGS;
import React, {useEffect, useState} from "react";
import {styles} from "./styles";
import {KpiRender} from "../../../utils/kpi-render/kpiRender";
import {KpisEnum} from "../../../models/kpi-settings";
import {PopoverPlacement} from "../../../components/small-wideview-card/SmallWideviewCard";
import {
    clearOperation, fetchLastMileData,
    fetchLastMileMetaData,
    fetchOperationData, lastmileMetaDataSelector,
    operationsStateSelector,
} from "../../../store/operation";
import {useDispatch, useSelector} from "react-redux";
import LastMileTable from "./last-mile-shipping-table/LastMileTable";
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {organizationSelector} from "@store";
import {DepartmentsEnum} from "../../../services/operations/operation.service";
import {SearchInput} from "../../../components/search-input/SearchInput";
import {dateRangeSelector} from "../../../store/ui";
import {debounce} from "../../../utils/debounce/debounce";
import {MoreInfoButton} from "../../../components/more-info-button/MoreInfoButton";
import {DownloadButton} from "../../../components/download-button/DownloadButton";
import {CheckboxMenuSmall} from "../../../components/checkbox-menu-small/CheckboxMenuSmall";
import {getCsv} from "../../../store/ui/thunks";

interface Props {
}

const defaultPageSize = 50

const LastMileSection = (props: Props) => {
    const operationData = useSelector(operationsStateSelector);

    const [handlingQuery, setHandlingQuery] = useState<string>("");
    const [handlingStatusCode, setHandlingStatusCode] = useState<string>("Delivered");
    const [handlingSize, setHandlingSize] = useState<number>(defaultPageSize);
    const [handlingPage, setHandlingPage] = useState<number>(0);
    const [handlingHasMore, setHandlingHasMore] = useState<any>(true);
    const [sortedHeader, setSortedHeader] = useState<{headerName?: any, ascending?: boolean, key?: string}>({headerName: "Order Date", ascending: false, key:'activityDate'});

    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const dates = useSelector(dateRangeSelector);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const metaData = useSelector(lastmileMetaDataSelector);
    useEffect(() => {
        dispatch(clearOperation());
    }, [])

    useEffect(() => {
        if(!!metaData){
            setSelectedTags(metaData)
        }
    }, [metaData])

    useEffect(() => {
        if(!!organization?.id){
            dispatch(fetchLastMileMetaData({organizationId: organization?.id}))
        }
    }, [organization?.id])


    useEffect(() => {
        if (!!operationData.metadata?.lastMileStats && !!operationData?.data?.lastMileData) {
            if (handlingStatusCode === "Delivered") {
                setHandlingHasMore(operationData.metadata.lastMileStats.delivered > operationData.data.lastMileData.length);
            }
            if (handlingStatusCode === "In Transit") {
                setHandlingHasMore(operationData.metadata.lastMileStats.inTransit > operationData.data.lastMileData.length);
            }
        }
    }, [operationData])



    useEffect(() => {
        const fixInTransitName = handlingStatusCode === 'In Transit' ? 'In-Transit' : handlingStatusCode;
        !!organization?.id && !!dates?.startDate && !!dates?.endDate &&
            dispatch(
                fetchLastMileData({
                    organizationId: organization?.id,
                    fromDate: new Date(dates.startDate).toISOString(),
                    toDate: new Date(dates.endDate).toISOString(),
                    query: !!handlingQuery ? handlingQuery : undefined,
                    deliveryStatus:fixInTransitName , //the first tab
                    size: handlingSize,
                    page: handlingPage,
                    tags: selectedTags?.length === metaData?.length ? [] : selectedTags,
                    sort: [`${[sortedHeader?.key]}:${sortedHeader?.ascending ?1 :-1}`]
                })
            );
    }, [organization?.id, dates?.startDate, dates?.endDate, handlingQuery, handlingPage, selectedTags, sortedHeader]);

    const fetchMorePurchasesData = () => {
        if (!operationData?.lastMileLoading) {
            setHandlingPage(handlingPage + 1);
        }
    };

    const onTabChanged = (statusCode: string) => {
        setHandlingStatusCode(statusCode);
        setHandlingPage(0);
        setHandlingSize(defaultPageSize);
        if (!!organization?.id && !!dates?.startDate && !!dates?.endDate) {
            const fixInTransitName = statusCode === 'In Transit' ? 'In-Transit' : statusCode;
            dispatch(
                fetchLastMileData({
                    organizationId: organization?.id,
                    fromDate: new Date(dates.startDate).toISOString(),
                    toDate: new Date(dates.endDate).toISOString(),
                    query: !!handlingQuery ? handlingQuery : undefined,
                    deliveryStatus: fixInTransitName,
                    size: handlingSize,
                    page: handlingPage,
                    tags: selectedTags?.length === metaData?.length ? [] : selectedTags
                })
            );
        }
    }


    const updateDebounce = debounce((text: string) => {
        setHandlingQuery(text);
    })


    const header = () => {
        return (
            <styles.HeaderWrapper>
                <Flex>

                    <styles.Title>Last Mile Shipping
                        <div style={{ alignSelf: 'start' }}><MoreInfoButton onClick={() => { return }} /></div>
                    </styles.Title>
                    <Box w={6} />
                    <SearchInput
                        placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"}
                        onChange={(e) => {
                            setHandlingPage(0);
                            updateDebounce(e)
                        }}
                        onSearch={(e) => {
                        }}
                    />
                    <Box w={4} />
                    <CheckboxMenuSmall title={'Tags'}
                                        options={!!metaData ? metaData : []}
                                        setSelectedItems={setSelectedTags}
                                        defaultCheckItems={selectedTags}/>
                </Flex>

                <Flex>
                    {<DownloadButton fileName={"inventory_overview"} serviceCallback={() => {
                        const fixInTransitName = handlingStatusCode === 'In Transit' ? 'In-Transit' : handlingStatusCode;

                        !!organization?.id && !!dates?.startDate && !!dates?.endDate &&
                            dispatch(getCsv({
                                    organizationId: organization?.id,
                                    fromDate: new Date(dates?.startDate).toISOString(),
                                    toDate: new Date(dates?.endDate).toISOString(),
                                    type: 0,
                                    kpisType: DepartmentsEnum.LAST_MILE,
                                    query: handlingQuery,
                                    deliveryStatus: fixInTransitName,
                                    fileName: `last_mile_${fixInTransitName}`,
                                }
                            ))

                    }} onClick={() => { }} />}
                    <Box w={3} />

                   {/* <DatepickerRange onApply={(range) => {
                    }} />*/}
                    <Box w={25} />
                </Flex>

            </styles.HeaderWrapper>
        );
    };

    const kpiSection = () => {
        return (
            <styles.KpisHolder>
                <KpiRender
                    popoverPlacement={PopoverPlacement.TOP}
                    kpiArray={[
                        KpisEnum.AVERAGE_UNITS_SOLD,
                        KpisEnum.COGS_AVERAGE,
                        KpisEnum.INVENTORY_TURNOVER,
                    ]}
                />
            </styles.KpisHolder>
        );
    };

    const Loader = () => {
        return (
            <Flex h={150} w={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Spinner />
            </Flex>
        );
    };
    return (
        <styles.Wrapper>
            {header()}
            {!!operationData?.data?.lastMileData ? (
                <LastMileTable
                    onTabChanged={onTabChanged}
                    tableData={operationData?.data?.lastMileData}
                    fetchMoreData={fetchMorePurchasesData}
                    handlingHasMore={handlingHasMore}
                    setSortedHeader={setSortedHeader}
                    sortedHeader={sortedHeader}
                    setPage={setHandlingPage}
                    setSize={setHandlingSize}
                />
            ) : (
                <Loader />
            )}
        </styles.Wrapper>
    );
};

export default LastMileSection;

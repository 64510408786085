import {createAsyncThunk} from "@reduxjs/toolkit";
import {facebookService} from "@services";
import {addIntegration, getFacebookAdsAccountsV2, RootState, updateFacebookAds} from "@store";
import {IntegrationPlatform, IntegrationType} from "@models";

export const facebookAdsAuthenticate = createAsyncThunk<any, any>(
    'facebook/facebookAdsAuthenticate',
    async ({code, state, sellingPartnerId, isUpdate, integrationId}, thunkAPI) => {
        try {
            const response = await facebookService.facebookAdsAuthenticate();
            if (!!response && !!response?.authResponse) {
                const rootState = thunkAPI.getState() as RootState;
                if (!!rootState.organization?.organization?.id) {
                    if(isUpdate){
                        thunkAPI.dispatch(updateFacebookAds({
                            integrationId: integrationId,
                            object: response
                        }))
                    }
                    else {
                        thunkAPI.dispatch(getFacebookAdsAccountsV2({
                            organizationId: rootState.organization?.organization?.id,
                            object: response
                        }))
                    }

                   /* thunkAPI.dispatch(addIntegration({
                        integration: {
                            name: '',
                            type: IntegrationType.MARKETING,
                            platform: IntegrationPlatform.FACEBOOK_ADS,
                            object: response,
                        },
                        organizationId: rootState.organization?.organization?.id
                    }));*/
                }
                return;
            } else {
                throw new Error();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import { paymentsService } from "services/payments/payments.service";
import {TransactionFee} from "./state";

export const fetchPayments = createAsyncThunk<any, any>(
  "orgaization/fetchPayments",
  async ({ organizationId }: { organizationId: string }, thunkAPI) => {
    try {
      const response = await paymentsService.fetchPayments({
        organizationId,
      });
      if (isHttpCodeSuccess(response.status)) {
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error fetch payments methods");
    }
  }
);
export const fetchTransactionFees = createAsyncThunk<any, any>(
    "organization/fetchTransactionFees",
    async ({ organizationId }: { organizationId: string }, thunkAPI) => {
        try {
            const response = await paymentsService.fetchTransactionFees({
                organizationId,
            });
            if (isHttpCodeSuccess(response.status)) {
                return response.data;
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error fetch payments methods");
        }
    }
);

export const createTransactionFees = createAsyncThunk<any, { organizationId: string, transactionFees: TransactionFee[] }>(
    "organization/createTransactionFees",
    async ({ organizationId, transactionFees }: { organizationId: string, transactionFees: TransactionFee[] }, thunkAPI) => {
        try {
            const response = await paymentsService.createTransactionFees({
                organizationId,
                transactionFees
            });
            if (isHttpCodeSuccess(response.status)) {
                return response.data;
            }
        } catch (err) {
            return thunkAPI.rejectWithValue("Error fetch payments methods");
        }
    }
);
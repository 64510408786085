import {ButtonWrapper} from "./styles";
import {ReactNode} from "react";

export interface Props {
    className?: string;
    children?: ReactNode;
    buttonStyle?: 'raised' | 'outlined';
}

export const Button = ({className, children, buttonStyle = 'raised'}: Props) => {
    return (
        <ButtonWrapper className={className} buttonStyle={buttonStyle || 'raised'}>
            {children}
        </ButtonWrapper>
    );
};

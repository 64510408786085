import marketingIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform, IntegrationType,
} from "@models";
import {addIntegration, organizationSelector} from "@store";
import { useDispatch, useSelector } from "react-redux";
import { IntegrationButton } from "../../../components/integration-button";
// import {getAmazonSellerRefreshToken} from "../../../store/amazon-seller-seller";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import { skubanaUri } from "@constants";
import storeIntegrationsStyles from "../stores-integrations/styles";
import { integrationSelector } from "store/integrations";
import {createZohoUrl, zohokCrmRoundLogo} from "../../../constants/integration";

export const OperationIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const organization = useSelector(organizationSelector);
    const integrations = useSelector(integrationSelector);

    const dispatch = useDispatch();


    // helpers
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    //TODO - activate Skubana
    // const getSkubanaParams = (params: URLSearchParams) => {
    //     const spApiOAuthCode = params.get('spapi_oauth_code');
    //     const state = params.get('state');
    //     const sellingPartnerId = params.get('selling_partner_id');
    //     if (!!spApiOAuthCode && !!state && !!sellingPartnerId) {
    //         dispatch(getAmazonSellerRefreshToken({
    //             code: spApiOAuthCode,
    //             state: state,
    //             sellingPartnerId: sellingPartnerId
    //         }));
    //     }
    // }

    const getSkubanaParams = (params: URLSearchParams) => {
    };

    const sendOperationsOAuthFromSearchParams = (params: URLSearchParams, platform: IntegrationPlatform) => {
        // @ts-ignore
        const OAuthData = params.get('result');
        try {
            if (!!OAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.OPERATION,
                        platform: platform,
                        object: JSON.parse(OAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    // renderers
    const renderCRMIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('OPERATION', { ns: 'translation' })}
                </Text>
                <Flex wrap={"wrap"} direction={"row"}>
                    {renderSkubanaIntegration()}
                    {renderZohoInventoryIntegrations()}

                </Flex>
            </Flex>
        );
    };

    const renderSkubanaIntegration = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.SKUBANA);
        return (
            /*<OAuthPopup
                title={'Skubana'}
                height={800}
                width={1000}
                url={skubanaUri}
                onResponse={(params) => {
                    getSkubanaParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderZohoInventoryIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.ZOHO_INVENTORY);
        return (
            /*<OAuthPopup
                title={'Zoho Inventory'}
                height={800}
                width={1000}
                url={createZohoUrl(IntegrationPlatform.ZOHO_INVENTORY) ?? ''}
                onResponse={(params) => {
                    sendOperationsOAuthFromSearchParams(params!, IntegrationPlatform.ZOHO_INVENTORY);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderCRMIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

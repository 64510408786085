import { ModalBody, ModalCloseButton } from "@chakra-ui/modal";
import styled from "styled-components";

export namespace styles {
  export const Title = styled.div`
    font-size: 17px;
    font-weight: 300;
    font-family: ${(props) => props.theme.fonts.header} !important;
  `;
  export const TitleBold = styled.span`
    font-size: 17px;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.header} !important;
  `;
  export const StyledModalClose = styled(ModalCloseButton)`
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;
  export const InviteEmailInput = styled.input`
    height: 31px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #a781ef;
    width: 100%;
    &:focus {
      outline: none;
    }
  `;
  export const SendButton = styled.button<{ notActive: boolean }>`
    border-radius: 10px;
    width: 90px;
    height: 35px;
    margin: 10px 0 0 0;
    background-color: ${(props) =>
      props.notActive ? "#dddddd" : props.theme.headerButtonBackgroundColor};
    color: ${(props) => (props.notActive ? "black" : props.theme.buttonColor)};
    opacity: ${(props) => (props.notActive ? 0.5 : 1)};
    align-self: flex-end;
  `;
  export const DropDownWrapper = styled.div`
    width: 400px;
    margin: 0 10px;
    top: 98px;
    height: 35px;
    left: 14px;
    z-index: 9999;
    display: flex;
    align-items: center;
    position: absolute;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    border-left: 1px solid white;
    background-color: white;
    border-radius: 0 0 3px 3px;
    &:hover {
      cursor: pointer;
    }
    box-shadow: 0px 2px 5px #babbbb;
  `;
  export const DropDownText = styled.div`
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;

  export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 31px;
  `;
  export const UserEmail = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 15px;
    color: #171725;
    opacity: 0.37;
  `;
  export const InviteForm = styled.form<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 5px;
  `;
  export const UsersContainer = styled.div`
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px 0 0 0;
  `;

  export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    width: 50%;
  `;
  export const UserRole = styled.div``;
  export const UserName = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 16px;
  `;
  export const UserTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const UserContainer = styled.div`
    width: 375px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px 0;
    &:hover {
      cursor: pointer;
    }
  `;
  export const FooterText = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 16px;
    font-weight: bold;
    color: #171725;
  `;
  export const Link = styled.div`
    color: ${(props) => props.theme.primaryColor};

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  `;
  export const FooterSubtext = styled.div`
    font-size: 11px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #171725;
  `;
  export const ModalBodyWrapper = styled(ModalBody)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;
  export const UsersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
  `;
  export const UsersWrapperActive = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
  `;

  export const UsersContainerActive = styled.div`
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px 0 0 0;
  `;
  export const Spacer = styled.div`
    height: 1px;
    margin: 10px 0;
    background-color: #dccdfa;
    width: 100%;
  `;
  export const StyledTitle = styled.div`
    font-family: ${(props) => props.theme.fonts.header};
    font-size: 17px;
    width: 100%;
    font-weight: bold;
    border-color: ${(props) => props.theme.backgroundColor};
    align-self: flex-start;
  `;

  export const UserContainerActive = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    max-height: 200px;
    overflow-y: auto;
  `;
  export const CustomSpacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
    height: 100%;
  `;
}

export default styles;

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: white;
  `;

  export const CloseButton = styled.button`
    box-shadow: ${(props) => props.theme.fullShadow};
  `;
  export const ShowByText = styled.div`
    color: #7a86a1;
    font-size: 14px;
    white-space: nowrap;
  `;

  export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  `;
  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 21px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.header};
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  `;

  export const TitleWrapper = styled.div<{ padding?: string }>`
    display: flex;
    padding: ${(props) => (props.padding ? props.padding : "0px")};
    flex-direction: row;
    margin-bottom: 5px;
    height: min-content;
    align-items: center;
  `;

  export const TitleStatic = styled.div`
    color: black;
    font-size: 24px;
    font-weight: 600;
    margin-right: 5px;
    height: min-content;
  `;

  export const TitleDynamic = styled.span`
    color: ${(props) => props.theme.primaryColor};
    font-size: 24px;
    font-weight: 600;
    margin-right: 10px;
    height: min-content;
  `;

  export const IconWrapper = styled.div`
    margin-right: 5px;
  `;

  export const ValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
  `;

  export const Value = styled.div`
    font-size: 32px;
    margin-right: 10px;
    font-weight: 600;
  `;

  export const HeaderButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
  `;

  export const HeaderButton = styled.div`
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: min-content;
    justify-content: start;
    align-items: center;
    border: solid 1px #ebebeb;
    &:hover {
      cursor: pointer;
    }
  `;

  export const SubTitle = styled.div`
    color: black;
    font-size: 16px;
    font-weight: normal;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    padding: 50px;
    height: 100%;
    width: 100%;
  `;
  export const Card = styled.div`
    flex: 1;
    height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    //height: 100%;
    border-radius: 15px;
    overflow: hidden;
    //box-shadow: 2px 13px 54px #695f9714;
    box-shadow: 3px 3px 10px #c6c6c6;
  `;
}

export default styles;

import styles from "./styles";
import { EffortsTable } from "../../components/efforts-table/EffortsTable";
import { useTranslation } from "react-i18next";
import { AlertsTable } from "../../components/alerts-table/AlertsTable";
import { Toggle, ToggleOption } from "../../components/toggle/Toggle";
import  { useEffect, useState } from "react";
import { IconShare } from "../../utils/icons/explore";
import { OverTimeGraph } from "../../components/over-time-graph/OverTimeGraph";
import { useDispatch, useSelector } from "react-redux";
import {Kpi} from "models/kpi";
import {
  fetchKpiData, fetchMarketingKpi,
  finishLoadingMarketing, KpiGroupBy,
  KpiState,
  kpiStateSelector,
} from "store/kpis";
import {
  fetchHolidays,
  organizationDataLanguageSelector,
  organizationLoadingSelector,
  organizationSelector,
  userSelector,
} from "@store";
import { Box, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { CreateAlertDialog } from "../../dialogs/create-alert-dialog/CreateAlertDialog";
import { KpiRender } from "utils/kpi-render/kpiRender";
import {
  arrangeDataByDate,
  calculateData,
  filterMissingDates,
  sumAllDays,
  sumMarketingNumbers
} from "utils/kpi-render/kpiCalculation";
import {
  Effort,
  effortsSelector,
  EffortsState,
  fetchEfforts,
} from "store/efforts";
import { alertsStateSelector, fetchAlerts } from "store/alerts";
import { Loading } from "components/loading/Loading";
import { CreateAlert } from "components/create-alert/CreateAlert";
import { EffortsModal } from "components/efforts-modal/EffortsModal";

import {
  getLastYear,
  groupDatesByWeek,
} from "utils/get-months/getLastYear";
import NoPinnedKpis from "./no-pinned-kpis/NoPinnedKpis";
import NoAssignedKpis from "./no-assigned-kpis/NoAssignedKpis";
import {
  DictionaryAssinedKpiKeyUsersValue,
  User,
  UserAvatar,
  Workspace,
  IntegrationType, MarketingData,
} from "@models";
import {
  dateRangeSelector,
  ExploresEnum, isDefaultExploreOpenSelector,
  openDefaultExploreView,
  selectedCardSelector, selectedChannelsSelector,
  setCardSelected,
} from "store/ui";
import { Alert, AlertStatus, AlertType } from "../../models/alerts";
import { getDatesInRange } from "utils/colors";
import { KpisEnum } from "models/kpi-settings";
import { addDays } from "date-fns";
import {
  DatepickerRange,
  DEFAULTS_DATES,
} from "components/datepicker-range/DatepickerRange";
import { DateRange } from "react-day-picker";
import { PopoverPlacement } from "components/small-wideview-card/SmallWideviewCard";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import MoreInformation from "components/more-information/MoreInformation";
import { channelsStateSelector } from "store/channels";
import { usersDataSelector, fetchUsersData } from "store/users";
import Gantt from "../../components/gantt/Gantt";
import StoreChannelFilter from "../../components/store-channel-filter/StoreChannelFilter";
import Table, {ColumnDefinitionType} from "../../components/wideview-table/WideviewTable";
import InformationWrapper from "../../components/InformationWrapper/InformationWrapper";


export enum DepartmentsEnum {
  COMPANY,
  MARKETING,
  FINANCE,
  WEBSITE,
}

enum PrivacyMode {
  PRIVATE,
  PUBLIC,
}

export enum GroupBy {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

const workspaceMoreInfoDialogProps = [
  {
    title: "Your Assigned KPIs",
    //subtitle: 'How to use this view?',
    description:
      "In this view, you will see all of the KPIs owned by you.\nClick here to learn more about what KPIs are.",
    tip: "Tip: KPI ownerships can be edited on the `KPI Framework` / `Account health` page.",
  },
  {
    title: "Efforts",
    //subtitle: 'How to use this view?',
    description:
      "In this section, you will see all your efforts.\n Efforts = processes & tasks that will be set in order to improve your organization`s KPI.\n`My Efforts` / `Department` / `All`:\n`My Efforts` will show efforts that you`re tagged in.\n `Department` will show efforts that you or your department members are tagged in.\n `All` will show all the efforts in your organization.",
  },
  {
    title: "Alerts",
    //subtitle: 'How to use this view?',
    description:
      "In this section, you will see all your alerts.\n `Public` / `Private` toggle:\n`Public` will show alerts that belong to your department or the entire organization.\n`Private` will show alerts that belong only to you.\n`Triggered Alerts` / `Alerts Bank` toggle:\n`Triggered Alerts` `will show only the alerts that were triggered by a rule.\n`Alerts Bank` will show a list of all existing alerts.",
  },
  {
    title: "Your Pinned KPIs",
    //subtitle: 'How to use this view?',
    description:
      "In this view, you will see all the KPIs you pinned to your workspace.\n You can find the `pin to workspace` option by clicking on the 3 dots in the upper-right corner of each of the metrics.\n Click here to learn more about what KPIs are",
  },
  {
    title: "Your Department KPIs",
    //subtitle: 'How to use this view?',
    description:
      "In this section, you will see your KPI`s actual value vs. the target.\nGreen parts will mark periods of time which indicate when the KPI was above target, and red parts will mark periods of time when the KPI was below target. ",
    tip: "Tip: KPI ownerships can be edited on the `KPI Framework` / `Account health` page.",
  },
] as MoreInfoDialogProps[];

export const Workspaces = () => {
  // hooks
  const {
    isOpen: isAlertsOpen,
    onOpen: onAlertsOpen,
    onClose: onAlertsClose,
  } = useDisclosure(); //
  const {
    isOpen: isEffortsOpen,
    onOpen: onEffortsOpen,
    onClose: onEffortsClose,
  } = useDisclosure(); //

  const [kpiTab, setKpiTab] = useState(0);
  const [alertMode, setAlertMode] = useState<PrivacyMode>(PrivacyMode.PRIVATE);
  const [effortsMode, setEffortsMode] = useState<PrivacyMode>(PrivacyMode.PRIVATE);
  const [topKpis, setTopKpis] = useState<any>();
  const [kpiData, setKpiData] = useState<any>();
  const dispatch = useDispatch();
  const { t } = useTranslation(["translation", "translation"]);
  const organizationId = useSelector(organizationSelector);
  const user = useSelector(userSelector);

  const [createAlertModalOpen, setCreateAlertModalOpen] = useState(false);
  const [effortsModalOpen, setEffortsModalOpen] = useState(false);
  const [actualDataChart, setActualDataChart] = useState<number[]>([]);
  const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
  const [graphLoader, setGraphLoader] = useState(false);
  const [noPinnedKpis, setNoPinnedKpis] = useState(true);
  const [noAssignedKpis, setNoAssignedKpis] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);

  const kpis: KpiState = useSelector(kpiStateSelector);
  const efforts: EffortsState = useSelector(effortsSelector);
  const alerts = useSelector(alertsStateSelector);
  const organizationLoader = useSelector(organizationLoadingSelector);
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const [graphKpiEnum, setGraphKpiEnum] = useState(KpisEnum.GROSS_REVENUE);
  const [labels, setLabels] = useState<string[]>([]);
  const selectedCard = useSelector(selectedCardSelector);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
  const [effortsDataToRender, setEffortsDataToRender] = useState<Effort[]>([]);
  const [alertsDataToRender, setAlertsDataToRender] = useState<Alert[]>([]);

  const datesSelector = useSelector(dateRangeSelector);
  const [kpiDataByChannels, setKpiDataByChannels] = useState<Kpi[]>([]);
  const [kpiTargetDataByChannels, setKpiTargetDataByChannels] = useState<Kpi[]>([]);
  const channels = useSelector(channelsStateSelector);
  const [ganttLabels, setGanttLabels] = useState<string[]>([])

  const selectedChannels = useSelector(selectedChannelsSelector);
  const isExploreOpen = useSelector(isDefaultExploreOpenSelector).isDefaultExploreOpen;




  const [isEffortMoreInfoDialogOpen, setIsEffortMoreInfoDialogOpen] =
    useState(false);
  const [isAlertsMoreInfoDialogOpen, setIsAlertsMoreInfoDialogOpen] =
    useState(false);
  const [
    isYourAssignedKPIsMoreInfoDialogOpen,
    setIsYourAssignedKPIsMoreInfoDialogOpen,
  ] = useState(false);

  useEffect(() => {
    if (effortsMode === PrivacyMode.PRIVATE) {
      const privateEfforts = efforts.data.filter(
        (item) => item.assignee === user?.id
      )
      setEffortsDataToRender(privateEfforts);
    } else {
      setEffortsDataToRender(efforts.data);
    }
  }, [efforts.data, effortsMode, user?.id]);

  useEffect(() => {
    setStartDate(undefined);
    setEndDate(undefined);
  }, [datesSelector?.startDate, datesSelector?.endDate])

  useEffect(() => {
    if (alertMode === PrivacyMode.PRIVATE) {
      const privateAlerts = alerts.data.filter((item) => {
        return item?.isPrivate;
      });
      setAlertsDataToRender(privateAlerts);
    } else {
      const publicAlerts = alerts.data.filter((item) => {
        return !item?.isPrivate;
      });
      setAlertsDataToRender(publicAlerts);
    }
  }, [alerts.data, alertMode, user?.id]);


  useEffect(() => {
    setNoPinnedKpis(true);
    let currentWorkspace: any;
    if (!!user?.workspaces && !!organizationId?.id) {
      currentWorkspace = user?.workspaces?.find(
        (workspace) => workspace.organization === organizationId?.id
      );
    }
    if (currentWorkspace?.pinnedKpis) {
      if (currentWorkspace?.pinnedKpis.length !== 0) {
        setNoPinnedKpis(false);
      }
    }
  }, [organizationId?.id, user?.workspaces]);

  useEffect(() => {
    setNoAssignedKpis(true);
    let currentWorkspace: any;
    if (!!user?.workspaces && !!organizationId?.id) {
      currentWorkspace = user?.workspaces?.find(
        (workspace) => workspace.organization === organizationId?.id
      );
    }
    if (currentWorkspace?.assignedKpis) {
      if (currentWorkspace?.assignedKpis.length !== 0) {
        setNoAssignedKpis(false);
      }
    }
  }, [organizationId?.id, user?.workspaces]);

  useEffect(() => {
    dispatch(finishLoadingMarketing({ marketingLoader: false }));
    if (
      !!organizationId?.id &&
      !!datesSelector?.startDate &&
      !!datesSelector.endDate &&
      channels?.channels?.length > 0
    ) {
      if(!!organizationId?.dataLanguage && !datesSelector?.isDefaultValue){
      dispatch(
          fetchHolidays({
            dataLanguageId: organizationId?.dataLanguage,
            organizationId: organizationId?.id,
            fromDate: new Date(datesSelector?.startDate)?.toISOString(),
            toDate: new Date(datesSelector?.endDate)?.toISOString(),
          })
      )
      }
      // targets
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: DepartmentsEnum.COMPANY,
          channels: channels?.channels
        })
      );
      // targets
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 0,
          type: 2,
          channels: channels?.channels
        })
      );
    /*  dispatch(
          fetchKpiData({
            organizationId: organizationId!.id,
            fromDate: datesSelector?.startDate,
            toDate: datesSelector?.endDate,
            kpisType: 0,
            type: 1,
            channels: channels?.channels
          })
      );*/
      /*dispatch(
          fetchMarketingKpi({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          channels: channels?.channels
        })
      );*/
      /*dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 2,
          channels: channels?.channels
        })
      );*/
     /* dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 3,
          channels: channels?.channels
        })
      );*/
    }
  }, [
    datesSelector?.endDate,
    datesSelector?.startDate,
    organizationId?.id,
    channels?.channels
  ]);

  useEffect(() => {
    setKpiData(kpis);
  }, [kpis]);

  useEffect(() => {
    if (dataLanguage?.topKpis) {
      setTopKpis(dataLanguage?.topKpis);
    }
  }, [
    organizationId,
    dataLanguage?.topKpis?.marketing,
    dataLanguage?.topKpis?.website,
    dataLanguage?.topKpis?.finance,
  ]);

  useEffect(() => {
    if (organizationId?.id) {
      dispatch(fetchEfforts({ organizationId: organizationId?.id }));
     !!organizationId?.id && dispatch(fetchAlerts({organizationId: organizationId?.id}));
    }
  }, [dispatch, organizationId?.id]);

  // helpers
  const openCreateNewAlertModal = () => {
    setCreateAlertModalOpen(true);
    onAlertsOpen();
  };

  const closeCreateNewAlertModal = () => {
    setCreateAlertModalOpen(false);
  };

  const clearFilters = () => {
    if (!!datesSelector?.startDate && !!datesSelector?.endDate) {
      setStartDate(new Date(datesSelector.startDate));
      setEndDate(new Date(datesSelector.endDate));
    }
  };

  const getKeyByEnum = {
    [KpisEnum.GROSS_REVENUE]: "grossRevenue",
    [KpisEnum.NET_REVENUE]: "netRevenue",
    [KpisEnum.GROSS_PROFIT]: "grossProfit",
    [KpisEnum.CONTRIBUTION_PROFIT]: "contributionProfit",
    [KpisEnum.AVERAGE_ORDERS_VALUE]: "aov",
    [KpisEnum.ORDERS]: "orders",
    [KpisEnum.CONVERSION_RATE]: "cr",
    [KpisEnum.MARKETING_SPEND]: "marketingCost",
    [KpisEnum.BLENDED_ROAS]: "grossRevenue",
    [KpisEnum.COST_PER_ORDER]: "grossRevenue",
    [KpisEnum.SESSIONS]: "sessions",
  };

  useEffect(() => {
    const channelsByName = selectedChannels?.map((item) => {
      const foundChannel = channels?.channels?.find((channel) => channel?.id === item);
      return foundChannel?.displayName
    })
    if (!!channels && !!kpis?.data?.data) {

      const newData = kpis?.data?.data?.filter((item) =>
      {
        if(kpis?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME){
          return channelsByName?.includes(item?.channelDisplayName);
        }else {
          return selectedChannels?.includes(item?.wvChannelId)
        }
      }
      );
      setKpiDataByChannels(newData);
    }

    if (!!channels && !!kpis?.targetData?.data) {
      const newTargetData = kpis?.targetData?.data?.filter((item) =>
          {
            if(kpis?.groupBy === KpiGroupBy.CHANNEL_DISPLAY_NAME){
              return channelsByName?.includes(item?.channelDisplayName);
            }else {
              return selectedChannels?.includes(item?.wvChannelId)
            }
          }
      );
      setKpiTargetDataByChannels(newTargetData);
    }
  }, [channels, channels.channels.length, kpis?.data?.data, kpis?.targetData?.data, selectedChannels, kpis?.groupBy]);


  // data calculations

  useEffect(() => {
    if (
        !!kpis.data?.data &&
        !!datesSelector?.startDate &&
        !!datesSelector?.endDate &&
        !!kpiDataByChannels
    ) {
      const result = calculateData(kpiDataByChannels,datesSelector?.startDate, datesSelector?.endDate, groupByStatus, selectedCard);
      setLabels(result.labels);

      setActualDataChart(result.data);
    }

    if (
        !!kpis.targetData?.data &&
        !!datesSelector?.startDate &&
        !!datesSelector?.endDate &&
        !!kpiDataByChannels
    ) {
      const result = calculateData(kpiTargetDataByChannels,datesSelector?.startDate, datesSelector?.endDate, groupByStatus, selectedCard);
      setLabels(result.labels);
      setTargetDataChart(result.data);
    }
  }, [
    kpiDataByChannels,
    graphKpiEnum,
    selectedCard?.workspaceKpiNumber,
    kpis?.website?.data,
    datesSelector?.startDate,
    datesSelector?.endDate,
    groupByStatus,
  ]);

  useEffect(() => {
    if (user?.workspaces) {
      const currentWorkspace = user?.workspaces?.filter(
        (item) => item?.organization === organizationId?.id
      )[0];
      if (currentWorkspace?.assignedKpis?.includes(KpisEnum.GROSS_REVENUE)) {
        dispatch(
          setCardSelected({
            isSelected: true,
            kpisEnumMarketing: undefined,
            kpisEnumWorkspace: KpisEnum.GROSS_REVENUE,
          })
        );
      } else {
        if (currentWorkspace?.assignedKpis) {
          dispatch(
            setCardSelected({
              isSelected: true,
              kpisEnumMarketing: undefined,
              kpisEnumWorkspace: currentWorkspace?.assignedKpis[0],
            })
          );
        }
      }
    }
  }, [dispatch, organizationId?.id, user?.workspaces, datesSelector]);

  // renderers
  const renderCreateNewAlertModal = () => {
    return (
      <CreateAlertDialog
        props={{}}
        isDialogOpen={createAlertModalOpen}
        onClose={() => closeCreateNewAlertModal()}
      />
    );
  };

  const effortsColumns: ColumnDefinitionType<Effort, keyof Effort>[] = [
    {
      key: "createdAt",
      header: t("CREATED", { ns: "translation" })
    },
    {
      key: "kpiMetric",
      header:  t("TITLE", { ns: "translation" })
    },
    {
      key: "description",
      header: t("DESCRIPTION", { ns: "translation" })
    },
    {
      key: "assignee",
      header: t("OWNER", { ns: "translation" })
    },
    {
      key: "status",
      header: t("STATUS", { ns: "translation" })
    },
    {
      key: "startDate",
      header: t("START_DATE", { ns: "translation" })
    },
    {
      key: "endDate",
      header: t("END_DATE", { ns: "translation" })
    },
    {
      key: "id"
    }
  ]

  const alertsColumns : ColumnDefinitionType<any, any>[] = [
    {
      key: "name",
      header: t("NAME", { ns: "translation" })
    },
    {
      key: "description",
      header: t("DESCRIPTION", { ns: "translation" })
    },
    {
      key: "recipients",
      header: t("RECIPIENTS", { ns: "translation" })
    },
    {
      key: "triggered",
      header: t("DATES_TRIGGERED", { ns: "translation" })
    },
    {
      key: "id",
    }
  ]


  const renderEfforts = () => {
    return (
      <>
        <styles.Header>
          <styles.Title>
            {t("EFFORTS", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={workspaceMoreInfoDialogProps[1]}
              isMoreInfoDialogOpen={isEffortMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={setIsEffortMoreInfoDialogOpen}
            />
          </styles.Title>
          <styles.PrivatePublicToggle>
            <Toggle
              options={[
                { index: 0, type: PrivacyMode.PRIVATE, title: "Private" },
                { index: 1, type: PrivacyMode.PUBLIC, title: "Public" },
              ]}
              onChange={(option: ToggleOption) => setEffortsMode(option.type)}
            />
          </styles.PrivatePublicToggle>
        </styles.Header>
        <styles.Card>
          {effortsDataToRender ? (
              <EffortsTable
                  title={t("EFFORTS", { ns: "translation" })}
                  data={effortsDataToRender}
                  headers={effortsColumns}
                  />) : (
              <></>
            // <EffortsTable
            //   title={t("EFFORTS", { ns: "translation" })}
            //   data={effortsDataToRender}
            //   headers={[
            //     t("CREATED", { ns: "translation" }),
            //     t("TITLE", { ns: "translation" }),
            //     t("DESCRIPTION", { ns: "translation" }),
            //     t("OWNER", { ns: "translation" }),
            //     t("STATUS", { ns: "translation" }),
            //     t("START_DATE", { ns: "translation" }),
            //     t("END_DATE", { ns: "translation" }),
            //     "id",
            //   ]}
            // />

          )}

          <styles.Footer>
            <styles.RowsNumberInTotal>{effortsDataToRender?.length} rows in total</styles.RowsNumberInTotal>
            <styles.CreateEffortButton onClick={onEffortsOpen}>
              <styles.IconShareWrapper>
                <IconShare color={"white"} />
              </styles.IconShareWrapper>
              {t("CREATE_NEW_EFFORT", { ns: "translation" })}
            </styles.CreateEffortButton>
          </styles.Footer>
        </styles.Card>
      </>
    );
  };

  const renderAlerts = () => {
    renderCreateNewAlertModal();
    return (
      <>
        <styles.Header>
          <styles.Title>
            <span style={{ marginRight: "5px" }}>{/* {props.icon} */}</span>
            {t("ALERTS", { ns: "translation" })}
            <MoreInformation
              moreInfoDialogProps={workspaceMoreInfoDialogProps[2]}
              isMoreInfoDialogOpen={isAlertsMoreInfoDialogOpen}
              setIsMoreInfoDialogOpen={setIsAlertsMoreInfoDialogOpen}
            />
          </styles.Title>
          <styles.PrivatePublicToggle>
            <Toggle
              options={[
                { index: 0, title: "Private" },
                { index: 1, title: "Public" },
              ]}
              onChange={(option: ToggleOption) => {
                switch (option.index) {
                  case 0:
                    setAlertMode(PrivacyMode.PRIVATE);
                    break;
                  case 1:
                    setAlertMode(PrivacyMode.PUBLIC);
                    break;
                }
              }}
            />
          </styles.PrivatePublicToggle>
        </styles.Header>
        <styles.Card>
          {alerts?.data !== undefined ? (
            <AlertsTable
              data={alertsDataToRender}
              headers={alertsColumns}
            />
          ) : (
            <></>
          )}

          <styles.Footer>
            <styles.RowsNumberInTotal>{alertsDataToRender?.length} rows in total</styles.RowsNumberInTotal>
            <styles.CreateEffortButton
              onClick={() => openCreateNewAlertModal()}
            >
              <styles.IconShareWrapper>
                <IconShare color={"white"} />
              </styles.IconShareWrapper>
              {t("CREATE_NEW_ALERT", { ns: "translation" })}
            </styles.CreateEffortButton>
          </styles.Footer>
        </styles.Card>
      </>
    );
  };

  const renderDatePickerButton = () => {
    return (
      <DatepickerRange
        backgroundColor="white"
        defaultsDate={DEFAULTS_DATES.LAST_30_DAYS}
        onApply={(range) => {}}
      />
    );
  };

  const kpisLoader = () => {
    return (
      <styles.LoaderWrapper>
        <styles.Loader />
      </styles.LoaderWrapper>
    );
  };

  const renderDepartment = (department: DepartmentsEnum, i: number) => {
    switch (department) {
      case DepartmentsEnum.COMPANY:
        return (
          <KpiRender
            storesChannels={selectedChannels}
            popoverPlacement={PopoverPlacement.TOP}
            onClick={(e) => setGraphKpiEnum(e)}
            startDate={startDate}
            endDate={endDate}
            kpiArray={topKpis?.company}
          />
        );

      case DepartmentsEnum.FINANCE:
        return (
          <KpiRender
            storesChannels={selectedChannels}
            popoverPlacement={PopoverPlacement.TOP}
            onClick={(e) => setGraphKpiEnum(e)}
            startDate={startDate}
            endDate={endDate}
            kpiArray={topKpis?.finance}
          />
        );

      case DepartmentsEnum.MARKETING:
        return (
          <KpiRender
            storesChannels={selectedChannels}
            popoverPlacement={PopoverPlacement.TOP}
            onClick={(e) => setGraphKpiEnum(e)}
            startDate={startDate}
            endDate={endDate}
            kpiArray={topKpis?.marketing}
          />
        );

      case DepartmentsEnum.WEBSITE:
        return (
          <KpiRender
            storesChannels={selectedChannels}
            popoverPlacement={PopoverPlacement.TOP}
            onClick={(e) => setGraphKpiEnum(e)}
            startDate={startDate}
            endDate={endDate}
            kpiArray={topKpis?.website}
          />
        );

      default:
        return <>No Department</>;
    }
  };

  const renderKpis = (tab: number) => {
    let currentWorkspace: any;
    if (user?.workspaces !== undefined && organizationId?.id !== undefined) {
      currentWorkspace = user?.workspaces?.find(
        (workspace) => workspace?.organization === organizationId?.id
      );
    }

    //console.log("832", dictionary)
    return (
      <styles.AssignedKpisContent>
        {kpis.loading ? (
          kpisLoader()
        ) : (
          <>
            {currentWorkspace?.assignedKpis !== undefined && tab === 0 ? (
              !noAssignedKpis ? (
                <KpiRender
                  storesChannels={selectedChannels}
                  popoverPlacement={PopoverPlacement.TOP}
                  onClick={(e) => setGraphKpiEnum(e)}
                  startDate={startDate}
                  endDate={endDate}
                  initialLoad={initialLoad}
                  setInitialLoad={setInitialLoad}
                  kpiArray={currentWorkspace?.assignedKpis}
                />
              ) : (
                <NoAssignedKpis />
              )
            ) : null}
            {currentWorkspace?.departments !== undefined && tab === 1
              ? Object.keys(currentWorkspace?.departments).map((key, i) => {
                  const value = currentWorkspace.departments[key];
                  return renderDepartment(value, i);
                })
              : null}
            {!!currentWorkspace?.pinnedKpis && tab === 2 ? (
              !noPinnedKpis ? (
                <KpiRender
                  storesChannels={selectedChannels}
                  popoverPlacement={PopoverPlacement.TOP}
                  onClick={(e) => setGraphKpiEnum(e)}
                  startDate={startDate}
                  endDate={endDate}
                  fromPinned
                  kpiArray={currentWorkspace.pinnedKpis}
                />
              ) : (
                <NoPinnedKpis />
              )
            ) : null}
          </>
        )}
        <styles.Shadow />
      </styles.AssignedKpisContent>
    );
  };

  const renderTitle = () => {
    const titleName = [
      t("YOUR_ASSIGNED_KPIS", { ns: "translation" }),
      t("YOUR_DEPARTMENT_KPIS", { ns: "translation" }),
      t("YOUR_PINNED_KPIS", { ns: "translation" }),
    ];

    if (kpiTab === 0) {
      return (
        <>
          <InformationWrapper text={titleName[0]} displayLongText/>
        </>
      );
    } else if (kpiTab === 1) {
      return (
        <>
          <InformationWrapper text={titleName[1]} displayLongText/>
        </>
      );
    } else if (kpiTab === 2) {
      return (
        <styles.Title>
          <InformationWrapper text={titleName[2]} displayLongText/>
        </styles.Title>
      );
    }

    return <></>;
  };

  return (
    <styles.Wrapper>
      <CreateAlert
        isOpen={isAlertsOpen}
        onClose={onAlertsClose}
        onOpen={onAlertsOpen}
      />
      <EffortsModal
        isOpen={isEffortsOpen}
        onClose={onEffortsClose}
        onOpen={onEffortsOpen}
      />
      <styles.SmallerWrapperBackground>
        <styles.AssignedKpisWrapper>
          <styles.AssignedKpisWrapperHeader>
            <styles.TabTitle>

              {renderTitle()}
            </styles.TabTitle>
            <styles.AssignedKpisToggle>
              <Toggle
                options={[
                  { index: 0, title: "Assigned KPIs" },
                  { index: 1, title: "Department" },
                  { index: 2, title: "Pinned KPIs" },
                ]}
                /* active={pinnedKpiActive} */
                onChange={(option: ToggleOption) => {
                  switch (option.index) {
                    case 0:
                      setKpiTab(0);
                      break;
                    case 1:
                      setKpiTab(1);
                      break;
                    case 2:
                      setKpiTab(2);
                      break;
                  }
                }}
              />
            </styles.AssignedKpisToggle>
            <Flex>
              <StoreChannelFilter />
              <Box w={"19px"} />

              {renderDatePickerButton()}
            </Flex>
          </styles.AssignedKpisWrapperHeader>

          {kpiTab === 0 ? renderKpis(0) : null}
          {kpiTab === 1 ? renderKpis(1) : null}
          {kpiTab === 2 ? renderKpis(2) : null}
        </styles.AssignedKpisWrapper>
        {!actualDataChart && !targetDataChart ? (
          <Spinner />
        ) : (
          <OverTimeGraph
            clearFilters={clearFilters}
            groupByStatus={groupByStatus}
            setGroupBy={setGroupByStatus}
            kpiEnum={graphKpiEnum}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            actual={actualDataChart}
            target={targetDataChart}
            labels={labels}
            setGanttLabels={setGanttLabels}
            displayGantt={true}
          />
        )}
        <div style={{ minHeight: "25px" }} />
      </styles.SmallerWrapperBackground>

      <styles.SmallerWrapper>{renderEfforts()}</styles.SmallerWrapper>
      <div style={{ minHeight: "50px" }} />
      <styles.SmallerWrapper>{renderAlerts()}</styles.SmallerWrapper>
    </styles.Wrapper>
  );
};

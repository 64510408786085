import integrationsStyles from "./styles";
import integrationsTableStyles from "../integrtions-table/styles";
import { useTranslation } from "react-i18next";
import { StoresIntegrations } from "../stores-integrations/StoresIntegrations";
import { MarketingIntegrations } from "../marketing-integrations/MarketingIntegrations";
import { WebsiteIntegrations } from "../website-integrations/WebsiteIntegrations";
import { CRMIntegrations } from "../crm-integrations/CRMIntegrations";
import { TaskManagementIntegrations } from "../task-management-integrations/TaskManagementIntegrations";
import { BenchmarketingIntegrations } from "../benchmarketing-integrations/BenchmarketingIntegrations";
import { OperationIntegrations } from "../operation-integrations/OperationIntegrations";
import { FinanceIntegrations } from "../shipping-integrations/FinanceIntegrations";
import {ShippingIntegrations} from "../finance-integrations/ShippingIntegrations";

export const AddIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    // helpers

    // renderers
    const renderAddIntegrationsTitle = () => {

        return (
            <integrationsTableStyles.Title>
                {t('ADD_INTEGRATIONS', { ns: 'translation' })}
            </integrationsTableStyles.Title>
        );
    };

    return (
        <integrationsStyles.Wrapper>
            {renderAddIntegrationsTitle()}
            {<StoresIntegrations />}
            {<MarketingIntegrations />}
            {<WebsiteIntegrations />}
            {<CRMIntegrations />}
            {<OperationIntegrations />}
            {<TaskManagementIntegrations />}
            {<ShippingIntegrations />}
            {<FinanceIntegrations />}
        </integrationsStyles.Wrapper>
    );
};

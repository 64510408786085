import { Icon } from '@chakra-ui/react';
import { SVGProps, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { renderFlag } from 'utils/icons/flags/flags';
import { styles } from './styles';




interface Props {
    data: {
        value: {
            sign: string;
            name: string;
            code: string;
            flag: SVGProps<SVGElement>;
        };
    }[];
    setItem: any;
}

export const SelectMenuFlag = (props: Props) => {

    // hooks
    const [selectedOption, setSelectedOption] = useState<any>(props.data[0].value);
    const [newData, setData] = useState(props.data);
    const [searchValue, setSearchValue] = useState<string>("");
    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    // helpers
    /* const handleOnChange = (e: any) => {
        setSearchValue(e.target.value);
        const filteredData = props.data.filter((item) =>
            (item.value.code.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
        );
        if (e.target.value.length === 0) {
            setData(props.data);
        }
        setData(filteredData);
    }; */

    // renderers

    return (
        <styles.StyledMenu >
            <styles.SelectButton>
                <styles.FlagHolder>
                    {selectedOption.flag}
                </styles.FlagHolder>
                {selectedOption.code}
                <styles.CustomSpacer width={7} />
                {selectedOption.name}
                <Icon as={IoIosArrowDown} marginLeft={"auto"} fontSize={20} />
            </styles.SelectButton>
            {/*  <input value={searchValue} type={"text"} onChange={e => handleOnChange(e)} placeholder={"Type to search..."} /> */}
            <styles.SelectMenuList >
                <styles.menuTitle>Popular currencies</styles.menuTitle>
                {
                    newData.map((option, index) => {
                        if (index <= 3) {
                            return (
                                <styles.MenuItemStyled key={index} onClick={() => {
                                    setSelectedOption(option.value);
                                    props.setItem(option.value);
                                }}>
                                    <styles.FlagHolder>{option.value.flag ? option.value.flag : null}</styles.FlagHolder>
                                    {option.value.code} {option.value.name}
                                </styles.MenuItemStyled>
                            );
                        } return <></>;
                    })
                }
                <styles.menuTitle>All currencies</styles.menuTitle>
                {
                    newData.map((option, index) => {
                        if (index > 3) {
                            return (
                                <styles.MenuItemStyled key={index} onClick={() => {
                                    setSelectedOption(option.value);
                                    props.setItem(option.value);
                                }}>
                                    <styles.FlagHolder>{option.value.flag ? option.value.flag : null}</styles.FlagHolder>
                                    {option.value.code} {option.value.name}
                                </styles.MenuItemStyled>
                            );
                        } return <></>;
                    })
                }
            </styles.SelectMenuList>
        </styles.StyledMenu>
    );
};

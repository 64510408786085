import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    max-width: 97%;
    max-height: 350px;
    display: flex;
    height: 100%;
    position: relative;
    align-self: center;
  `;
  export const Title = styled.div`
    display: flex;
    position: absolute;
    top: 95px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 1px;
    margin-bottom: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    display: flex;
  `;
}

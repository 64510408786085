import successMessageStyles from "./styles";
import { useLocation, useNavigate } from "react-router";
import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  addIntegration,
  organizationErrorSelector,
  organizationStateSelector,
} from "@store";
import { IntegrationPlatform, IntegrationType } from "@models";
import { useDispatch, useSelector } from "react-redux";
import { localStorageService } from "@services";
import { localStorageTokenKey } from "@constants";

interface Props {
  message?: string;
}

export const ShopifyIntegrationMessage = (props: Props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const addIntegrationError = useSelector(organizationErrorSelector);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const token = localStorageService.getItem(localStorageTokenKey);
    if (!token) {
      setError(true);
      setErrorMessage(
        "You need to login into your Wideview account before you can add your shopify store."
      );
      return;
    }
    const queryParams = new URLSearchParams(location?.search);
    const shopifyOAuthData = queryParams.get("result");
    const shopifyOAuthStatus = queryParams.get("status");
    if (!!shopifyOAuthData && shopifyOAuthStatus === "1") {
      setError(true);
      setErrorMessage("We could not connect your store. Please login");
      return;
    }
    let shopifyParsedOAuthData = null;
    try {
      if (!!shopifyOAuthData) {
        shopifyParsedOAuthData = JSON.parse(shopifyOAuthData);
      }

      if (!!shopifyParsedOAuthData && !!token) {
        dispatch(
          addIntegration({
            integration: {
              name: !!shopifyParsedOAuthData?.shop ? 'Shopify' : 'Shopify',
              type: IntegrationType.STORES,
              platform: IntegrationPlatform.SHOPIFY,
              object: shopifyParsedOAuthData,
            },
          })
        );
      }
    } catch (err) {
      setErrorMessage("Error add integration");
      setError(true);
    }
  }, [location]);

  const goToLoginScreen = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <successMessageStyles.Wrapper>
      <successMessageStyles.MessageWrapper
        success={
          (!!addIntegrationError && addIntegrationError?.length <= 0) || !error
        }
      >
        <successMessageStyles.Message
          success={
            (!!addIntegrationError && addIntegrationError?.length <= 0) ||
            !error
          }
        >
          {!!addIntegrationError || error
            ? addIntegrationError ?? errorMessage
            : props?.message ?? "Success!"}
        </successMessageStyles.Message>
      </successMessageStyles.MessageWrapper>
      <Button onClick={() => goToLoginScreen()}>
        Click here to go to home screen!
      </Button>
    </successMessageStyles.Wrapper>
  );
};

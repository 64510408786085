import axios from "axios";
import {getAmazonAdsRefreshTokenUrl} from "@constants";
import {AmazonAdsRegions} from "../../store/amazon-ads";

// amazon ads
const getAmazonAdsCode = () => {
    return new Promise<{
        code: string;
    }>((resolve, reject) => {
        try {
            amazon.Login.authorize({
                scope: 'advertising::campaign_management',
                response_type: 'code',
            } as any, function (response) {
                // @ts-ignore
                resolve({code: response?.code});
            });
        } catch (err) {
            reject(err);
        }
    });
}

const getAmazonAdsRefreshToken = (code: string | null, region: AmazonAdsRegions) => {
    return axios.get<{
        access_token: string;
        refresh_token: string;
        token_type: string;
        expires_in: number;
    }>(`${getAmazonAdsRefreshTokenUrl}?code=${code}&regionCode=${region}`,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        });
}

export const amazonAdsService = {
    getAmazonAdsCode,
    getAmazonAdsRefreshToken,
};

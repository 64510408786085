import wideViewsStyles, { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { OverTimeGraph } from "@components";
import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import { fetchWebsiteData, websiteStateSelector } from "store/website";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { WebsiteLandingTable } from "components/website-landing-table/WebsiteLandingTable";
import { UserAnalysis } from "components/user-analysis/UserAnalysis";
import { PerformanceComparisonWebsite } from "components/performance-comparsion-website/PerformanceComparsionWebsite";
import { WebsiteEcommerceFunnel } from "components/website-ecommerce-funnel/WebsiteEcommerceFunnel";
import { organizationDataLanguageSelector, organizationLoadingSelector, organizationSelector } from "@store";
import { KpiRender, KpiSection } from "utils/kpi-render/kpiRender";
import { fetchKpiData, kpiDataSelector, kpiStateSelector } from "store/kpis";
import { sumMarketingNumbers } from "utils/kpi-render/kpiCalculation";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { IoStorefrontSharp } from "react-icons/io5";
import { Toggle, ToggleOption } from "components/toggle/Toggle";
import { DateRange } from "react-day-picker";
import { getLastYear } from "utils/get-months/getLastYear";
import { openDefaultExploreView, ExploresEnum } from "store/ui";
import {PopoverPlacement, SmallWideviewCard} from "components/small-wideview-card/SmallWideviewCard";
import websiteJsonData from './website-data.json';
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";

export const Website = () => {
    // hooks
    const dispatch = useDispatch();
    const yearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substring(0, 4);
    const firstDayLastYear = new Date(parseInt(yearAgo), 0, 1).toISOString();
    const lastDayLastYear = new Date(parseInt(yearAgo), 11, 31).toISOString();
    const { t } = useTranslation(['translation', 'translation']);
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [storeChannels, setStoreChannels] = useState<string[]>([]);
    const [displayMockData, setDisplayMockData] = useState(true);
    const [dateRange, setDateRange] = useState<DateRange | undefined>();
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    const organizationLoader = useSelector(organizationLoadingSelector);
    const kpisLoad = useSelector(kpiStateSelector);
    const [funnelData, setFunnelData] = useState<any>({})
    const currency = useCurrencySign();

    useEffect(() => {
        dispatch(fetchWebsiteData({}));
    }, [dispatch]);

    const websiteData = useSelector(websiteStateSelector);
    const organization = useSelector(organizationSelector);
    const kpis = useSelector(kpiDataSelector);
    // helpers

    useEffect(() => {

        if (!!organization?.id) {
            dispatch(fetchKpiData({ organizationId: organization.id, fromDate: firstDayLastYear, toDate: lastDayLastYear, kpisType: 3 }));
        }
    }, [dispatch, firstDayLastYear, lastDayLastYear, organization?.id]);

    const keys = { '01': [], '02': [], '03': [], '04': [], '05': [], '06': [], '07': [], '08': [], '09': [], '10': [], '11': [], '12': [] };
    const dataToMonths = (data: any[]) => {
        let newArr: number[] = [];
        data.forEach((item) => {
            const itemMonth = item.activityDate.slice(5, 7);
            keys[itemMonth].push(item);
        });

        const monthData = Object.keys(keys).map(function (key, index) {
            return ({ data: sumMarketingNumbers(keys[key], "sessions"), key }); // TODO Change hardcoded sessions
        });
        monthData.forEach((item) => {
            newArr.splice(parseInt(item.key, 10) - 1, 0, item.data);
        });
        return newArr;
    };

    useEffect(() => {
        let totalSessions = 0;
        let totalProductView = 0;
        let totalAddedToCart = 0;
        let totalCheckout = 0;
        let totalTransactions = 0;

            // @ts-ignore
        websiteJsonData?.forEach((item) => {
                if(item?.event_name === "page_view"){
                    totalSessions++
                }
                if(item?.event_name === "view_item"){
                    totalProductView++
                }
                if(item?.event_name === "add_to_cart"){
                    totalAddedToCart++
                }
                if(item?.event_name === "begin_checkout"){
                    totalCheckout++
                }
                if(item?.event_name === "purchase"){
                    totalTransactions++
                }

            })

        const funnelData = {
            cart: totalAddedToCart,
            checkout: totalCheckout,
            product: totalProductView,
            sessions: totalSessions,
            transactions: totalTransactions,
        }
        setFunnelData(funnelData)

    }, []);



    useEffect(() => {
            if(!!websiteData?.data?.performanceData?.data){
                const data = websiteData?.data?.performanceData?.data.map((item, i) => {
                    return {
                        type: item.type,
                        productPage: item.productPage,
                        sourceMedium: item?.sourceMedium,
                        sessions: item?.sessions,
                        users: item.users,
                        transactions: item.transactions,
                        revenue: item?.revenue,
                        cr: 0,
                        aov: 0
                    };
                });
                setTableData(data);

            }
    }, [websiteData?.data?.performanceData?.data]);


    const renderToggle = () => {
        return (
            <styles.AssignedKpisToggle>
                <Toggle options={
                    [
                    { index: 0, title: t('WideView Store', { ns: 'translation' }) },
                    { index: 1, title: t('LittleData Store', { ns: 'translation' }) }
                    ]
                }
                    onChange={(option: ToggleOption) => {
                        switch (option.index) {
                            case 0:
                                setDisplayMockData(true);
                                break;
                            case 1:
                                setDisplayMockData(false);
                                break;
                        }
                    }}
                />
            </styles.AssignedKpisToggle>
        );
    };


    // renderers

    const renderHeader = () => {
        return (
            <>

                <styles.HeaderWrapper>
                    <styles.Title>{t('WEBSITE_DEPARTMENT', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => { }} />
                    </styles.Title>
                    <styles.toggleHolder >
                        {renderToggle()}
                    </styles.toggleHolder>
                </styles.HeaderWrapper>

                {/* <styles.buttonWrapper>
                        <CheckboxMenu title={"WEBSITES_CHANNELS"}
                            icon={(color: string) => <IoStorefrontSharp color={color} />}
                            options={['FACEBOOK', 'AMAZAON.US', 'EBAY']}
                            onSelect={(checked) => setStoreChannels(checked)} />
                        <div style={{ width: 8 }} />
                        <DatepickerRange onApply={range => setDateRange(range)} />
                    </styles.buttonWrapper>*/}
            </>
        );
    };

    const renderDepartmentRow = () => {
        // @ts-ignore
        let totalRevenue = websiteJsonData?.reduce((acc, obj) => {
            let revenue = parseInt(obj.revenue);
            if (!isNaN(revenue)) {
                acc += revenue;
            }
            return acc;
        }, 0);
        let data: any = {};
        // @ts-ignore
        if(!!websiteData && !!!!websiteData?.data?.funnelData?.data && !!websiteData.data.funnelData.data[0]){
            data = websiteData.data.funnelData.data[0]
        }
        return (
            <styles.DepartmentRowWrapper>
                <SmallWideviewCard targetPercentage={displayMockData ? 115 : 80} value={!displayMockData ? funnelData?.sessions?.toLocaleString() : data?.sessions?.toLocaleString()} hideBorder title={"Sessions"} popoverPlacemnt={PopoverPlacement.TOP} />
                <SmallWideviewCard targetPercentage={displayMockData ? 80 : 75} value={!displayMockData ? ((funnelData?.transactions / funnelData?.sessions * 100).toFixed(2))+"%" : ((data?.transactions / data?.sessions * 100).toFixed(2))+"%"}  hideBorder title={"CR"} popoverPlacemnt={PopoverPlacement.TOP} />
                <SmallWideviewCard targetPercentage={displayMockData ? 82 : 72} value={!displayMockData ? funnelData?.transactions?.toLocaleString() : data?.transactions?.toLocaleString()} hideBorder title={"Orders"} popoverPlacemnt={PopoverPlacement.TOP} />
                <SmallWideviewCard targetPercentage={displayMockData ? 75 : 95} value={!displayMockData ?  currency + totalRevenue?.toLocaleString() : currency + (11301).toLocaleString() } hideBorder title={"Gross Revenue"} popoverPlacemnt={PopoverPlacement.TOP} />
            </styles.DepartmentRowWrapper>
        );
    };

    /*
    {category: string;
    mobile_brand_name: string;
    mobile_model_name: number;
    users: total user_pseudo_id;
    revenue: total revenue}
     */


    function groupBy(array: any[], key1: string, key2: string, key3: string) {
        return array.reduce((objectsByKeyValue, obj) => {
            const value1 = obj[key1];
            const value2 = obj[key2];
            const value3 = obj[key3];
            objectsByKeyValue[value1] = objectsByKeyValue[value1] || {};
            objectsByKeyValue[value1][value2] = objectsByKeyValue[value1][value2] || {};
            objectsByKeyValue[value1][value2][value3] = objectsByKeyValue[value1][value2][value3] || { users: new Set(), revenue: 0 };
            objectsByKeyValue[value1][value2][value3].users.add(obj.user_pseudo_id);  // add user_pseudo_id to Set
            objectsByKeyValue[value1][value2][value3].revenue += parseInt(obj.revenue, 10);  // add revenue
            return objectsByKeyValue;
        }, {});
    }

    function groupByCountry(data: any[]): { [key: string]: { revenue: number, uniqueUsers: any } } {
        return data.reduce((acc, item) => {
            const country = item.country;
            if (!acc[country]) {
                acc[country] = { revenue: 0, uniqueUsers: new Set() };
            }
            acc[country].revenue += Number(item.revenue);
            acc[country].uniqueUsers.add(item.user_pseudo_id);
            return acc;
        }, {});
    }



    function sumData(data:any, keyToAdd?: string) {

        let sum = { users: 0, revenue: 0, device: (keyToAdd === "undefined" ? "Unknown" : keyToAdd)};
        for (const key in data) {
            if (typeof data[key] === "object") {
                if (data[key] instanceof Set) {
                    sum.users += data[key].size;  // add size of Set
                }
                sum.users += sumData(data[key]).users;  // sum users recursively
                sum.revenue += sumData(data[key]).revenue;  // sum revenue recursively
            } else {
                sum[key] += data[key];  // add value
            }
        }
        return sum;
    }

    const [userData, setUserData] = useState<any>()

    function groupByFourKeys(array: any[], key1: string, key2: string, key3: string, key4: string) {
        return array.reduce((objectsByKeyValue, obj) => {
            const value1 = obj[key1];
            const value2 = obj[key2];
            const value3 = obj[key3];
            const value4 = obj[key4];
            objectsByKeyValue[value1] = objectsByKeyValue[value1] || {};
            objectsByKeyValue[value1][value2] = objectsByKeyValue[value1][value2] || {};
            objectsByKeyValue[value1][value2][value3] = objectsByKeyValue[value1][value2][value3] || {};
            objectsByKeyValue[value1][value2][value3][value4] = objectsByKeyValue[value1][value2][value3][value4] || { users: new Set(), revenue: 0 };
            objectsByKeyValue[value1][value2][value3][value4].users.add(obj.user_pseudo_id);  // add user_pseudo_id to Set
            objectsByKeyValue[value1][value2][value3][value4].revenue += parseInt(obj.revenue, 10);  // add revenue
            return objectsByKeyValue;
        }, {});
    }

    const [groupedData, setGroupedData] = useState<any[]>([])
    useEffect(() => {
        // @ts-ignore
        const formattedData = groupBy(websiteJsonData, "category", "mobile_brand_name", "mobile_model_name");
        // @ts-ignore
        const newFormattedData = groupByFourKeys(websiteJsonData, "category", "mobile_brand_name", "mobile_model_name", "country");
        const deviceData:{users?: number, revenue?:number, device?:string}[] = [];
        const brandName:{users?: number, revenue?:number, device?:string}[] = [];
        const modelName:{users?: number, revenue?:number, device?:string}[] = [];
        const countryData:{users?: number, revenue?:number, device?:string}[] = [];

        const groupedData:any[] =  [];
        Object.keys(newFormattedData).forEach((browserKey) => {
            Object.keys(newFormattedData[browserKey]).forEach((ageKey) => {
                Object.keys(newFormattedData[browserKey][ageKey]).forEach((genderKey) => {
                    Object.keys(newFormattedData[browserKey][ageKey][genderKey]).forEach((countryKey) => {
                        const totalSum = sumData(newFormattedData[browserKey][ageKey][genderKey][countryKey]);
                        groupedData?.push({...totalSum, browser: browserKey=== "undefined" ? "Unknown" : browserKey, country: countryKey === "undefined" ? "Unknown" : countryKey, gender: genderKey=== "undefined" ? "Unknown" : genderKey, ages: ageKey=== "undefined" ? "Unknown" : ageKey  })
                    })
                })
            })
        })
        if(displayMockData && !!websiteData?.data?.userAnalysis?.rows){
            setGroupedData(websiteData?.data?.userAnalysis?.rows)
        }else {
            setGroupedData(groupedData);
        }

        Object.keys(formattedData).map((key) => {
            const totalSum = sumData(formattedData[key], key);
            deviceData?.push(totalSum);
            Object.keys(formattedData[key]).map(subKey => {
                const totalSum = sumData(formattedData[key][subKey], subKey);
                brandName?.push(totalSum);
                    Object.keys(formattedData[key][subKey]).map(thirdKey => {
                        const totalSum = sumData(formattedData[key][subKey][thirdKey], thirdKey);
                            modelName?.push(totalSum);
                    })
            })
        });

        // @ts-ignore
        const groupedByCountry = groupByCountry(websiteJsonData);
        Object.keys(groupedByCountry).map((key) => {
            const currentItem = groupedByCountry[key];
            countryData?.push({revenue: currentItem?.revenue, users: currentItem?.uniqueUsers?.size ?? 0, device: (key === "undefined" ? "Unknown" : key)})
        })
        setUserData({
            deviceData,
            brandName,
            modelName,
            countryData
        })

    }, [displayMockData, websiteData?.data?.userAnalysis?.rows])

    return (
        <wideViewsStyles.Wrapper>
            <wideViewsStyles.SmallerWrapperBackground>
                <styles.Holder>
                    {renderHeader()}
                    {kpis.loading === true ? renderDepartmentRow() :
                        <styles.LoaderWrapper>
                            <styles.Loader />
                        </styles.LoaderWrapper>}
                    {!!tableData ? <PerformanceComparisonWebsite displayMockData={displayMockData} data={tableData} />
                        : null}
                </styles.Holder>
                <styles.StyledHolder>
                    <styles.Holder>
                        {displayMockData ? !!websiteData?.data?.funnelData?.data ? <WebsiteEcommerceFunnel data={websiteData.data.funnelData.data[0]} /> : null :
                        !!funnelData ? <WebsiteEcommerceFunnel data={funnelData} /> : null}
                    </styles.Holder>
                </styles.StyledHolder>
                <styles.Holder>
                  {/*  {websiteData.data.data !== undefined ? <WebsiteLandingTable headers={[
                        t('PAGE_TITLE', { ns: 'translation' }),
                        t('USERS', { ns: 'translation' }),
                        t('SESSIONS', { ns: 'translation' }),
                        t('AVG_TIME_ON_PAGE', { ns: 'translation' }),
                        t('TRANSACTIONS', { ns: 'translation' }),
                        t('ECOMM_CR', { ns: 'translation' }),
                        t('REVENUE', { ns: 'translation' }),
                        t('EXIT', { ns: 'translation' }),
                        t('PAGE_VIEWS', { ns: 'translation' }),
                        t('PAGE_SESSIONS', { ns: 'translation' }),
                        t('ENTRANCES', { ns: 'translation' }),
                        t('EXITS', { ns: 'translation' }),
                        "id"
                    ]} data={websiteData?.data.data} /> : <></>}*/}
                    {!!groupedData ? <UserAnalysis rows={groupedData} displayMockData={displayMockData} /> : null}
                </styles.Holder>
            </wideViewsStyles.SmallerWrapperBackground>
        </wideViewsStyles.Wrapper>
    );
};

import successMessageStyles from "./styles";
import {useNavigate} from "react-router";
import {Button} from "@chakra-ui/react";

interface Props {
    message?: string;
}

export const SuccessMessages = (props: Props) => {

    let navigate = useNavigate();
    const goToLoginScreen = () =>{
        let path = `/`;
        navigate(path);
    }

    return (
        <successMessageStyles.Wrapper>
            <successMessageStyles.MessageWrapper>
                <successMessageStyles.Message>
                    {props?.message ?? 'Success!'}
                </successMessageStyles.Message>
            </successMessageStyles.MessageWrapper>
            <Button onClick={() => goToLoginScreen()}>
                Click here to go to home screen!
            </Button>
        </successMessageStyles.Wrapper>
    );
}

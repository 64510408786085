// state
export interface FacebookState {
    code?: string | null;
    loading: boolean;
    error?: string | null;
}

// initial state
export const initialFacebookState: FacebookState = {
    code: null,
    loading: false,
    error: '',
}

import styled from "styled-components";

export namespace styles {

    export const ChipWrapper = styled.div<{width: string, color: string}>`
      width: ${(prop => prop.width)};
      background-color: ${(prop => prop.color)};
      filter: alpha(opacity=60);
      height: 30px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    `

    export const Chip = styled.div<{color: string}>`
      color: ${(prop => prop.color)};
    `
}

export default styles;

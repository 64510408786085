import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
  `;

  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px;
    height: 426px;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `;
  export const RevenueCell = styled.td<{ rgba?: any }>`
    margin: 0;
    padding: 0.5rem;
    height: 50px;
    border-bottom: 0.5px solid #695f9726;
    border-right: 0.5px solid #695f9726;
    margin: 0 auto;
    white-space: nowrap;
    font-size: 14px;
    width: max-content;
    overflow: hidden;
  `;

  export const InputFilter = styled.input`
    accent-color: #7031eb;
    height: 20px;
    border-radius: 10px;
    width: 20px;
  `;

  export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
  `;

  export const FilterText = styled.div`
    margin: 0 0 0 7px;
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  `;

  export const TrCustom = styled.tr<{ selected?: boolean }>`
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    &:hover {
      cursor: pointer;
    }
  `;

  export const TabButtonsContainer = styled.div`
    width: 98%;
    height: 56px;
    display: flex;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
  `;

  export const StyledFilterButton = styled.button`
    width: 45px;
    height: 45px;
    border-radius: 19px;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const TabButton = styled.div<{ selected?: boolean }>`
    width: 20%;
    width: 20%;
    border: 0.5px solid #695f9726;
    border-bottom: 4px solid #e5d5fa;
    border-radius: 5px 30px 0px 0px;
    display: flex;
    align-items: center;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    &:hover {
      cursor: pointer;
    }
  `;

  export const TabButtonText = styled.div`
    margin: 0 0 0 23px;
    display: flex;
    width: 80%;
    justify-content: space-between;
  `;

  export const StyledDiv = styled.div``;
  export const StyledDivSelected = styled.div<{ selected?: boolean }>`
    color: ${(props) => (props.selected ? "#7031eb" : "black")};
    width: 80px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.selected ? "#dac9fa" : "#CBCBCB")};
    font-size: 12px;
  `;

  export const TbodyCustom = styled.tbody``;

  export const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 0.5px solid #695f9726;
      display: table;
      overflow: scroll;
      table-layout: fixed;
      max-width: 1400px;
      tbody {
        max-height: 250px;
      }
      tr {
        &:last-of-type {
          td {
          }
        }
      }
      th {
        text-align: left;
        color: #7a86a1;
        position: relative;
        font-size: 1rem;
        font-weight: normal;
      }

      th,
      td {
        margin: 0;
        text-align: center;
        padding: 0.5rem;
        border-bottom: 0.5px solid #695f9726;
      }
    }
  `;

  export const StyledTypeHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
  `;
  export const StyledSpendHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
  `;

  export const StyledSpendBar = styled.div`
    width: 50px;
    background-color: #695f9714;
    height: 6px;
    border-radius: 12px;
    overflow: hidden;
  `;

  export const StyledSpendBarInside = styled.div<{ number: number }>`
    width: ${(props) => props.number + "%"};
    background-color: #7031eb;
    height: 6px;
    border-radius: 12px;
  `;

  export const styledTh = styled.th`
    margin: 0;
    text-align: center;
    padding: 0.5rem;
    border-bottom: 0.5px solid #695f9726;
    border-right: 0.5px solid #695f9726;
    width: 150px;
  `;

  export const StyledTotal = styled.td`
    background-color: #7031eb;
    color: white;
    border-radius: 10px;
    background-size: 100% 100%;
    transform: scale(1.12, 1.12);
    transform-origin: center;
  `;

  export const StyledText = styled.div`
    font-size: 14px;
    background-color: #7031eb;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;
  `;

  export const styledTd = styled.td`
    margin: 0;
    padding: 0.5rem;
    height: 50px;
    border-bottom: 0.5px solid #695f9726;
    border-right: 0.5px solid #695f9726;
    margin: 0 auto;
    white-space: nowrap;
    font-size: 14px;
    width: max-content;
    overflow: hidden;
  `;

  export const StyledTdSpend = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
  `;

  export const FooterTd = styled.td`
    border-bottom: 1px solid #695f9726;
    font-weight: bold;
  `;

  export const footerTr = styled.tr``;

  export const StyledHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;
}

export default styles;

import { useDisclosure } from "@chakra-ui/react";
import { ApplyChangesModal } from "components/apply-changes-modal/ApplyChangesModal";
import { CogsSettings } from "components/cogs-settings/CogsSettings";
import { BlendedRoasExplore } from "components/explore/blended-roas-explore/BlendedRoasExplore";
import { CrExplore } from "components/explore/CrExplore/CrExplore";
import { Explore } from "components/explore/Explore";
import { MarketingSpendExplore } from "components/explore/marketing-spend-explore/MarketingSpendExplore";
import { ProfileExplore } from "components/explore/profileExplore/ProfileExplore";
import { SessionsExplore } from "components/explore/SessionsExplore/SessionsExplore";
import { GeneralOverlay } from "components/general-overlay/GeneralOverlay";
import { KpiFramework } from "components/kpis-framework/KpiFramework";
import { MarketingView } from "components/marketing-view/MarketingView";
import { NetRevenueOverlay } from "components/explore/net-revenue-overlay/NetRevenueOverlay";
import { Planning } from "components/Planning/Planning";
import PnlSettingOverlay from "components/pnl-overlay/PnlSettingOverlay";
import { SalesChannelsOverlay } from "components/sales-channels-overlay/SalesChannelsOverlay";
import { useDispatch, useSelector } from "react-redux";
import { ExploresEnum, isDefaultExploreOpenSelector, openDefaultExploreView } from "store/ui";
import { ExploreType } from "../AdminPanel";
import { CpoExplore } from "components/explore/cpo-explore/CpoExplore";
import { OrdersExplore } from "components/explore/orders-explore/OrdersExplore";
import { AovExplore } from "components/explore/aov-explore/AovExplore";
import { GrossProfitExplore } from "components/explore/grossProfitExplore/GrossProfitExplore";
import { ContProfitExplore } from "components/explore/contProfitExplore/ContProfitExplore";
import {OperationKpiExplore} from "../../../components/explore/operation-kpi-explore/OperationKpiExplore";
import {PnlSettingOverlayNew} from "../../../components/pnl-overlay-new/PnlSettingOverlay";
import {CacExplore} from "../../../components/explore/cac-explore/CacExplore";
import {TransactionFeesExplore} from "../../../components/explore/transaction-fees-explore/TransactionFeesExplore";
import {MetricExplore} from "../../../components/explore/metric-explore/MetricExplore";

interface Props {
    inputEnum?: ExploresEnum;
    exploreType: ExploreType;
}

export const RenderExplores = (props: Props) => {


    const dispatch = useDispatch();


    const Explores = {
        [ExploresEnum.PROFILE]: <ProfileExplore />,
        [ExploresEnum.GENERAL]: <GeneralOverlay />,
        [ExploresEnum.KPI_FRAMEWORK]: <KpiFramework />,
        [ExploresEnum.PLANNING]: <Planning />,
        [ExploresEnum.PNL_SETTINGS]: <PnlSettingOverlayNew />,
        [ExploresEnum.GROSS_REVENUE]: <Explore />, //1
        [ExploresEnum.COST_PER_ORDER]: <CpoExplore />, // 2
        [ExploresEnum.CONVERSION_RATE]: <CrExplore />,
        [ExploresEnum.SESSIONS]: <SessionsExplore />,
        [ExploresEnum.NET_REVENUE]: <NetRevenueOverlay />, //3
        [ExploresEnum.COGS]: <CogsSettings ExploresType={props.exploreType} />,
        [ExploresEnum.MARKETING_MAPPING]: <MarketingView />,
        [ExploresEnum.SALES_CHANNELS]: <SalesChannelsOverlay />,
        [ExploresEnum.MARKETING_SPEND]: <MarketingSpendExplore />, // 4
        [ExploresEnum.BLENDED_ROAS]: <BlendedRoasExplore />, // 5
        [ExploresEnum.CAC]: <CacExplore />, // 5
        [ExploresEnum.ORDERS]: <OrdersExplore />, // 6
        [ExploresEnum.AVERAGE_ORDERS_VALUE]: <AovExplore />, // 7
        [ExploresEnum.GROSS_PROFIT]: <GrossProfitExplore />, // 8
        [ExploresEnum.CONTRIBUTION_PROFIT]: <ContProfitExplore />, // 9
        [ExploresEnum.PENDING_DEVICES]: <OperationKpiExplore />,
        [ExploresEnum.HOLDING_DEVICES]: <OperationKpiExplore />,
        [ExploresEnum.APPROVED_DEVICES]: <OperationKpiExplore />,
        [ExploresEnum.NUMBER_OF_DEVICES]: <OperationKpiExplore />,
        [ExploresEnum.INCOMPLETE_ORDERS]: <OperationKpiExplore />,
        [ExploresEnum.REMAINING_COST]: <OperationKpiExplore />,
        [ExploresEnum.TRANSACTION_FEES]: <TransactionFeesExplore />,
        [ExploresEnum.METRIC_EXPLORE]: <MetricExplore />,
    };

    if (props.inputEnum !== undefined) {
        return <>
            {Explores[props.inputEnum]}
        </>;
    }
    return <></>;

};
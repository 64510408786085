// @ts-ignore
import {calculateTotalForExplore} from "./calculateTotalForExplore";
import {Channel} from "../../store/channels";
import {getChannelNameById} from "../get-channel-name/getChannelNameById";
import {getDatesInRange} from "../colors";

interface DataType {
    [key: string]: any;
}

// Group daily stats by 'wvChannelId'. Sum up the data using 'fieldName'.
// For KPIs like AOV and CPO, we do some functions.
// Then, turn each channel's data into a table row.

export const getAcrossChannelsTableData = (data?: DataType[],
                                           fieldName?: string,
                                           channels?: Channel[],
                                           selectedProducts?: {original?: {callbackId?: string}}[],
                                           chartSelectedDate?: {startDate?: Date, endDate?: Date}) => {
    const grouped: { [key: string]: DataType[] } = {};

    if(!!selectedProducts && selectedProducts?.length > 0){
        const mappedSelectedProducts = selectedProducts?.map((item) => item?.original?.callbackId);
        data = data?.filter((kpiItem) => {
            return mappedSelectedProducts?.includes(kpiItem?.wvProductId)
        })
    }

    if(!!chartSelectedDate?.startDate && !!chartSelectedDate?.endDate){
        const allDatesInRangeOfSelect = getDatesInRange(chartSelectedDate?.startDate, chartSelectedDate?.endDate);
        data = data?.filter((kpiItem) => {
            return allDatesInRangeOfSelect?.includes(kpiItem?.activityDate?.slice(0,10))
        })
    }

    if(!!data){
        for (const item of data) {
            if ('wvChannelId' in item) {
                const key = item.wvChannelId;
                if (!grouped[key]) {
                    grouped[key] = [];
                }
                grouped[key].push(item);
            }
        }

    }

    const flattenedSums: any[] = [];



    let totalMetricForExplore = 0;

    for (const key in grouped) {
        totalMetricForExplore += calculateTotalForExplore(grouped[key], fieldName);
    }

    for (const key in grouped) {
        const groupSum = calculateTotalForExplore(grouped[key], fieldName);

        if(!!fieldName){
            const rowToPush = { [fieldName]: groupSum, callbackId: key, channelName: getChannelNameById(key, channels),
                shareOfTotal: totalMetricForExplore ? (groupSum / totalMetricForExplore) * 100 : 0, }
            if(fieldName === 'aov'){
                const rowGrossRevenue = calculateTotalForExplore(grouped[key], 'grossRevenue');
                const rowOrders = calculateTotalForExplore(grouped[key], 'orders');
                rowToPush['grossRevenue'] = rowGrossRevenue;
                rowToPush['orders'] = rowOrders;
            }
            const financeFields = ['netRevenue', 'grossProfit', 'contributionProfit'];

            if(financeFields?.includes(fieldName)){
                const rowGrossRevenue = calculateTotalForExplore(grouped[key], 'grossRevenue');
                rowToPush['grossRevenue'] = rowGrossRevenue;
                rowToPush['valueByMargin'] = !!rowGrossRevenue && !!groupSum ? (groupSum / rowGrossRevenue) * 100 : 0
            }
            flattenedSums.push(rowToPush);
        }

    }

    return flattenedSums;

}
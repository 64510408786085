export const ConversionRate = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.095)">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_341" data-name="Rectangle 341" width="36" height="36" transform="translate(0.095)" fill="none" />
                </g>
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(3.841 3.834)">
                    <g id="Q3_icons-2" data-name="Q3 icons">
                        <path id="Path_503" data-name="Path 503" d="M30.917,4H5.417A1.417,1.417,0,0,0,4,5.417v25.5a1.417,1.417,0,0,0,1.417,1.417h25.5a1.417,1.417,0,0,0,1.417-1.417V5.417A1.417,1.417,0,0,0,30.917,4ZM29.5,29.5H6.833V25.462l6.729-6.729,3.188,3.188,10.2-10.2a1.417,1.417,0,0,0-.283-1.983,1.487,1.487,0,0,0-1.7,0L16.75,17.954l-3.187-3.188L6.833,21.5V6.833H29.5Z" transform="translate(-4 -4)" fill="#7031eb" />
                    </g>
                </g>
            </g>
        </svg>

    );
};
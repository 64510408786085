import React, { useEffect } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { newPoIcon } from "../../../../utils/icons/opeartion-icons";
import { styles } from "./styles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { organizationSelector, signIn } from "@store";
import { useDispatch, useSelector } from "react-redux";
import {
  createPurchaseOrder, editPurchaseOrder,
  operationsSelector,
  operationsStateSelector,
} from "../../../../store/operation";
import {EditData} from "../ManufacturingSection";

interface Props {
  isOpen: boolean;
  onClose: any;
  data?: EditData
  setEditData?: any
}

interface CreatePo {
  poId: string;
  issueDate: Date;
  supplier: string;
  amount: number;
  totalCost: number;
}

const AddRowModal = (props: Props) => {
  let schema = yup.object().shape({
    purchaseOrderId: yup.string().required(),
    projectNumber: yup.string().required(),
    issueDate: yup.date().required(),
    supplier: yup.string().required(),
    unitsAmount: yup.number().required(),
    totalCost: yup.number().required(),
  });
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const operation = useSelector(operationsStateSelector);
  useEffect(() => {
    if(!!props?.data?.id){
      setValue("purchaseOrderId", props?.data?.poId);
      setValue("issueDate",  props?.data?.issueDate.toLocaleDateString('en-CA'));
      setValue("projectNumber",  props?.data?.projectNumber);
      setValue("supplier", props?.data?.supplier);
      setValue("unitsAmount", props?.data?.units);
      setValue("totalCost", props?.data?.totalCost);
    }else {
    setValue("purchaseOrderId", "");
    setValue("issueDate", undefined);
    setValue("projectNumber", undefined);
    setValue("supplier", "");
    setValue("unitsAmount", undefined);
    setValue("totalCost", undefined);
    }
  }, [setValue, props.isOpen]);

  const submitForm = (data: any) => {
    if(!!props?.data?.id){
      const copyOfData = {...data};
      copyOfData["id"] = props?.data?.id;
      copyOfData.issueDate = copyOfData?.issueDate?.toISOString();
      !!organization?.id &&
      dispatch(
          editPurchaseOrder({ organizationId: organization?.id, data: copyOfData })
      );
    }else {
    !!organization?.id &&
      dispatch(
        createPurchaseOrder({ organizationId: organization?.id, data: [data] })
      );
    }
    !!props?.setEditData && props?.setEditData(undefined)
    !operation?.createPoLoader && props?.onClose();
  };

  const handleClose = () => {
    !!props?.setEditData && props?.setEditData(undefined);
    props.onClose()
  }

  const content = () => {
    return (
      <styles.InputWrapper>
        <styles.LabelWrapper>
          PO's ID:
          <styles.StyledInput
            {...register("purchaseOrderId")}
            placeholder={"Type Your PO’s ID/Description"}
          />
        </styles.LabelWrapper>  <styles.LabelWrapper>
          Project Number:
          <styles.StyledInput
            {...register("projectNumber")}
            placeholder={"Type project number"}
          />
        </styles.LabelWrapper>
        <styles.LabelWrapper>
          Issue Date:
          <styles.StyledInput  small type={"date"} defaultValue={!!props?.data?.issueDate ? props?.data?.issueDate.toLocaleDateString('en-CA') : ""} {...register("issueDate")} />
        </styles.LabelWrapper>{" "}
        <styles.LabelWrapper>
          Supplier:
          <styles.StyledInput
            {...register("supplier")}
            placeholder={"Type the supplier name"}
          />

        </styles.LabelWrapper>
        <styles.LabelWrapper>
          Amount of units:
          <styles.StyledInput
            {...register("unitsAmount")}
            placeholder={"Amount of units…"}
          />
        </styles.LabelWrapper>
        <styles.LabelWrapper>
          Total Cost:
          <styles.StyledInput
            {...register("totalCost")}
            placeholder={"General cost…"}
          />
        </styles.LabelWrapper>
      </styles.InputWrapper>
    );
  };


  return (
    <Modal isOpen={props.isOpen} onClose={() => handleClose()}>
      <ModalOverlay />
      <ModalContent borderRadius={[10]}>
        <styles.StyledHeader>
          <styles.Title>
            {newPoIcon()}
            <Box w={"8px"} />
            {!!props?.data?.id  ? "Edit New P.O" :  "Create New P.O"}
          </styles.Title>
        </styles.StyledHeader>
        {/*<ModalCloseButton />*/}
        <ModalBody>{content()}</ModalBody>

        <ModalFooter>
          <styles.StyledCancelButton onClick={props.onClose}>
            Cancel
          </styles.StyledCancelButton>
          <Box w={"20px"} />
          <styles.StyledSubmitButton
            type={"submit"}
            onClick={handleSubmit(submitForm)}
          >
            {operation?.createPoLoader ? <Spinner /> : !!props?.data?.id ? "Edit" :  "Create"}
          </styles.StyledSubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRowModal;

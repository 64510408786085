import {Kpi, MarketingKpi} from "../../models/kpi";
import {Channel} from "../../store/channels";

export const getFormattedKpi: (data:Kpi[], channels: Channel[]) => Kpi[] = (data: Kpi[], channels: Channel[]) : Kpi[] => {

    function groupByDisplayName(arr: Channel[]) {
        if (!arr) return;
        let groups = {};
        for (let obj of arr) {
            if (!!obj.displayName && !groups[obj.displayName]) {
                groups[obj.displayName] = [obj];
            } else {
                if(!!obj.displayName){
                    groups[obj.displayName].push(obj);
                }
            }
        }
        for (let key in groups) {
            if (groups[key].length <= 1) {
                delete groups[key];
            }
        }
        return groups;
    }

    const allDuplicateData = groupByDisplayName(channels);
    const dataWithDisplayName = data?.map((kpiItem) => {
        const itemToReturn = {...kpiItem};
        const foundChannel = channels?.find((channelItem) => channelItem?.id === kpiItem?.wvChannelId);
        if(!!foundChannel?.displayName && !!allDuplicateData && foundChannel?.displayName in allDuplicateData){
            itemToReturn["wvChannelId"] = allDuplicateData[foundChannel?.displayName][0]?.id
        }
        return itemToReturn;
    })
    return dataWithDisplayName
};

export const linkMarketingMappingToKpiData = (data: MarketingKpi[], selectedChannels: string[], marketingMapping: any) => {
    if (!!data && selectedChannels && !!marketingMapping) {
        const newDataArr:MarketingKpi[] = [];
        const allSelectedChannels = selectedChannels;
        data.forEach((item) => {
            if(marketingMapping?.some((marketingMappingItem:any) => {
                return marketingMappingItem?.campaignId === item?.campaignId
            })){
                const foundMarketingElement = marketingMapping?.find((marketingElement:any) => item?.campaignId === marketingElement?.campaignId);
                if(!!foundMarketingElement?.salesChannels && allSelectedChannels.includes(foundMarketingElement?.salesChannels[0])){
                    newDataArr?.push(item);
                }
                return
            }
            if(marketingMapping?.some((marketingMappingItem:any) => {
                return marketingMappingItem?.accountId === item?.wvAccountId
            })){
                const foundMarketingElement = marketingMapping?.find((marketingElement:any) => item?.wvAccountId === marketingElement?.accountId);
                if(!!foundMarketingElement?.salesChannels && allSelectedChannels.includes(foundMarketingElement?.salesChannels[0])){
                    newDataArr?.push(item);
                }
                return
            }
        });
        return newDataArr
    }else{
        return []
    }
}

export const groupArrayByKeyAndValue = <T extends { [key: string]: any }, K extends keyof T>(array?: T[], key?: K, value?: T[K]) => {
    if (!array || !key || value === undefined) {
        return {};
    }

    const result: { [key: string]: T[] } = {[value]: []};
    for (const item of array) {
        if (item[key] === value) {
            if (value in result) {
                result[value].push(item);
            } else {
                result[value] = [item];
            }
        }
    }
    return result;
};


export const getSummedDataByKey = <T extends { [key: string]: any }>(array: T[] | undefined, key: keyof T) => {
    let sum = 0;
    if(Array.isArray(array)){
        for (const item of array){
            if (key in item) {
                sum += item[key];
            }
        }
        return sum;
    }
    return  sum
};

export const groupArrayByKey = <T>(array: T[], key: keyof T): Record<string | number, T[]> => {
    const groups: Record<string | number, T[]> = {};

    for (const item of array) {
        if (key in item) {
            const value = item[key] as unknown;
            if (typeof value === 'string' || typeof value === 'number') {
                if (!groups[value]) {
                    groups[value] = [];
                }
                groups[value].push(item);
            }
        }
    }

    return groups;
};


type ObjectWithDisplayName = { [key: string]: number; displayName: any };

export const sumGroupedDataByKey = <T>(groups: Record<string, T[]>, ignoreKeys: string[] = []): ObjectWithDisplayName[] => {
    return Object.entries(groups).map(([key, group]) => {
        const sum = group.reduce((acc, item) => {
            Object.keys(item).forEach((itemKey) => {
                if (typeof item[itemKey] === 'number' && !ignoreKeys.includes(itemKey)) {
                    acc[itemKey] = (acc[itemKey] ?? 0) + item[itemKey];
                }
            });
            return acc;
        }, {} as ObjectWithDisplayName);

        sum.displayName = key;
        return sum;
    });
};

interface Activity {
    activityDate: string;
}

interface ActivityWithHandlingDays extends Activity {
    handlingDays: number;
}

export const addHandlingDays = <T extends Activity>(activities: T[]): ActivityWithHandlingDays[] => {
    const today = new Date();
    return activities.map(activity => {
        const activityDate = new Date(activity.activityDate);
        const diffInTime = today.getTime() - activityDate.getTime();
        const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
        return {
            ...activity,
            handlingDays: diffInDays
        };
    });
}


import styles from "./styles";
import { useTranslation } from "react-i18next";
import { OverTimeGraph } from "../../components/over-time-graph/OverTimeGraph";
import { PlatformTable } from "components/platform-table/PlatformTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchMarketingData, marketingDataSelector, marketingStateSelector} from "store/marketing";
import {
  getIntegrationPlatformName,
  IntegrationPlatform,
  IntegrationType,
} from "@models";
import { PerformanceReporting } from "components/performance-reporting/PerformanceReporting";
import {
  fetchHolidays,
  MarketingMapping,
  MarketingMappingElement,
  organizationDataLanguageMappingSelector,
  organizationDataLanguageSelector,
  organizationLoadingSelector,
  organizationSelector,
  userSelector,
} from "@store";
import {
  fetchKpiData, fetchMarketingKpi,
  kpiMarketingMappingSelector,
  kpiMarketingSelector,
  kpiStateSelector,
} from "store/kpis";
import { KpiRender } from "utils/kpi-render/kpiRender";
import { Kpi, MarketingKpi } from "models/kpi";
import {
  calculateData,
  sumMarketingNumbers,
} from "utils/kpi-render/kpiCalculation";
import { Toggle, ToggleOption } from "components/toggle/Toggle";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { DateRange } from "react-day-picker";
import { Loading } from "components/loading/Loading";
import {
  getLastYear,
  groupDatesByWeek,
  sumData,
} from "utils/get-months/getLastYear";
import {
  openDefaultExploreView,
  ExploresEnum,
  dateRangeSelector,
  selectedCardSelector,
  setCardSelected,
  selectedChannelsSelector,
  accountStateSelector,
} from "store/ui";
import { PopoverPlacement } from "components/small-wideview-card/SmallWideviewCard";
import { Box, Spinner } from "@chakra-ui/react";
import { GroupBy } from "features/workspace/Workspace";
import { KpisEnum } from "models/kpi-settings";
import { getDatesInRange } from "utils/colors";
import { channelsStateSelector } from "store/channels";
import { BreakdownByPlatform } from "./BreakdownByPlatformTable/SourcesTable/BreakdownByPlatformTable";
import { getIntegrationNameById } from "utils/get-integration-name/getIntegrationNameById";
import { integrationSelector } from "store/integrations";
import MoreInformation from "components/more-information/MoreInformation";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import { CreativePerformance } from "./creative-performance/CreativePerformance";
import { Chart } from "chart.js";
import StoreChannelFilter from "../../components/store-channel-filter/StoreChannelFilter";
import { channel } from "diagnostics_channel";
import marketingTable from "../../components/marketing-view/marketing-table/MarketingTable";
import {linkMarketingMappingToKpiData} from "../../utils/kpi-data-format/kpiDataFormat";
import InformationWrapper from "../../components/InformationWrapper/InformationWrapper";

const marketingMoreInfoDialogProps = {
  title: "Marketing Department ",
  description:
    "Use this page to understand and explore your marketing performance.\nWith this data, you can make more informed decisions when building your campaigns and ultimately improve your marketing results.",
} as MoreInfoDialogProps;

export const Marketing = () => {
  // hooks
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const organizationId = useSelector(organizationSelector);
  const user = useSelector(userSelector);
  const datesSelector = useSelector(dateRangeSelector);
  const marketingMapping = useSelector(organizationDataLanguageMappingSelector);
  const [actualDataChart, setActualDataChart] = useState<number[]>([]);
  const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
  const [graphKpiEnum, setGraphKpiEnum] = useState(KpisEnum.MARKETING_SPEND);
  const [ganttLabels, setGanttLabels] = useState<string[]>([]);

  const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
  const [labels, setLabels] = useState<string[]>([]);
  const selectedCard = useSelector(selectedCardSelector);
  const channels = useSelector(channelsStateSelector);
  const { t } = useTranslation(["translation", "translation"]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const organizationLoader = useSelector(organizationLoadingSelector);
  const marketingMappingData = useSelector(kpiMarketingMappingSelector);
  const uiAccountsSelector = useSelector(accountStateSelector);
  const marketingWVStatsData = useSelector(kpiMarketingSelector);
  const kpis = useSelector(kpiStateSelector);
  const [kpiData, setKpiData] = useState<Kpi[]>([]);
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const [breakdownPlatformData, setBreakdownPlatformData] = useState<any[]>([]);
  const [filteredMarketingDataByDates, setFilteredMarketingDataByDates] =
    useState<any[]>([]);
  const [filteredMarketingDataByChannels, SetFilteredMarketingDataByChannels] =
    useState<any[]>([]);
  const integrations = useSelector(integrationSelector);
  const [platformData, setPlatformData] = useState<
    {
      revenue: number;
      platformName: string;
      clicks: number;
      impressions: number;
      purchases: number;
      spend: number;
    }[]
  >([]);
  const [
    isMarketingDepartmentMoreInfoDialogOpen,
    setIsMarketingDepartmentMoreInfoDialogOpen,
  ] = useState(false);
  const [adsKpiData, setAdsKpiData] = useState<any[]>([]);
  const selectedChannels = useSelector(selectedChannelsSelector);
  const [kpiDataByChannels, setKpiDataByChannels] = useState<Kpi[]>([]);
  const [kpiTargetDataByChannels, setKpiTargetDataByChannels] = useState<Kpi[]>(
    []
  );
  const selectedBreakdownRows = useSelector(marketingStateSelector)?.selectedRowsBreakdownTable;

  useEffect(() => {
    if (!!channels && !!kpis?.data?.data) {
      const newData = kpis?.data?.data?.filter((item) =>
        selectedChannels?.includes(item?.wvChannelId)
      );
      setKpiDataByChannels(newData);
    }
    if (!!channels && !!kpis?.targetData?.data) {
      const newTargetData = kpis?.targetData?.data?.filter((item) =>
        selectedChannels?.includes(item?.wvChannelId)
      );
      setKpiTargetDataByChannels(newTargetData);
    }
  }, [
    channels,
    channels.channels.length,
    kpis?.data?.data,
    kpis?.targetData?.data,
    selectedChannels,
  ]);

  useEffect(() => {
    if (!!kpis?.marketing?.data && !!channels) {
      const marketingChannels: any[] = [];
      channels?.channels?.forEach((channel) => {
        if (channel?.type === IntegrationType.MARKETING) {
          marketingChannels.push(channel);
        }
      });
      const dataByPlatform = {};
      const marketingDataByPlatform = {}; // { [platformType]: [...marketingStats] }
      marketingChannels.forEach((item) => {
        if (item?.integrationId in dataByPlatform) {
          dataByPlatform[item?.integrationId].push(item?.id);
        } else {
          dataByPlatform[item?.integrationId] = [item?.id];
          marketingDataByPlatform[item?.integrationId] = [];
        }
      });
      Object.keys(dataByPlatform).forEach((key) => {
        kpis?.marketing?.data.forEach((item) => {
          if (dataByPlatform[key].includes(item?.wvChannelId)) {
            marketingDataByPlatform[key].push(item);
          }
        });
      });
      const tableData: any[] = [];
      Object.keys(marketingDataByPlatform).forEach((key) => {
        const newData = marketingDataByPlatform[key].map((item: any) => {
          return {
            ...item,
            clicks: parseFloat(item?.clicks),
            impressions: parseFloat(item?.impressions),
          };
        });
        const currentPlatformData = sumData(newData, [
          "platform",
          "imageName",
          "createdAt",
          "id",
          "account",
          "activityDate",
          "ad",
          "adId",
          "adSetId",
          "adsGroup",
          "campaignId",
          "campaignName",
          "image",
          "updatedAt",
          "type",
          "purchasePoint",
        ]);
        tableData.push({
          ...currentPlatformData,
          platformType: key,
          platformName: key,
        });
      });
      setPlatformData(tableData);
    }
  }, [kpis?.marketing?.data, channels]);

  useEffect(() => {
    if (!!kpis?.marketing?.data) {
      let data = kpis?.marketing?.data;
      if(!!startDate && !!endDate){
        const dates = getDatesInRange((startDate),(endDate));

        data = kpis?.marketing?.data?.filter((item) => {
          if(!!item?.activityDate){
            return dates?.includes(item?.activityDate?.slice(0,10))
          }
          return
        })?.filter((item) => !!item)
      }

      setFilteredMarketingDataByDates(data);
    }
  }, [endDate, kpis?.marketing?.data, startDate]);

  useEffect(() => {
    if (!!filteredMarketingDataByDates) {
      const newData = filteredMarketingDataByDates?.map((item: any) => {
        if (
          !!item?.platform &&
          !!item?.wvAccountId &&
          !!item?.adId &&
          !!item?.campaignName &&
          !!item?.adSetId
        ) {
          return {
            platform: item?.platform,
            account: item?.platform + item?.wvAccountId,
            campaign: item?.platform + item?.wvAccountId + item?.campaignName,
            adSet:
              item?.platform +
              item?.wvAccountId +
              item?.campaignName +
              item?.adSetId,
            adId:
              item?.platform +
              item?.wvAccountId +
              item?.campaignName +
              item?.adSetId +
              item?.adId,
            spend: item?.spend,
            revenue: item?.revenue,
            clicks: item?.clicks,
            conversions: item?.purchases,
            impressions: item?.impressions,
            orders: item?.purchases,
            displayName: {
              platform: item?.platform,
              account: item?.accountName,
              campaign: item?.campaignName,
              adSet: item?.adsSetName,
              adId: item?.adName ?? item?.adId,
            },
          };
        } else return;
      });

      setBreakdownPlatformData(newData);
    }
  }, [filteredMarketingDataByDates]);

  useEffect(() => {


  }, [breakdownPlatformData])

  useEffect(() => {
    if (!!kpis?.data?.data && !!selectedChannels) {
      const dataArr: Kpi[] = [];
      kpis?.data?.data?.forEach((item) => {
        if (selectedChannels.includes(item?.wvChannelId)) {
          dataArr.push(item);
        }
      });
      setKpiData(dataArr);
    }
  }, [kpis?.data?.data, selectedChannels]);



  useEffect(() => {
    if (!!kpis?.marketing?.data && selectedChannels && !!marketingMapping) {
      SetFilteredMarketingDataByChannels(linkMarketingMappingToKpiData(kpis.marketing.data, selectedChannels, marketingMapping));
    }
  }, [kpis?.marketing?.data, selectedChannels, marketingMapping]);

  useEffect(() => {
    if (!!filteredMarketingDataByChannels) {
      const newData = filteredMarketingDataByChannels?.map((item: any) => {
        if (
          !!item &&
          !!item?.platform &&
          !!item?.wvAccountId &&
          !!item?.adId &&
          !!item?.campaignName &&
          !!item?.adSetId
        ) {
          return {
            platform: item?.platform,
            account: item?.platform + item?.wvAccountId,
            campaign: item?.platform + item?.wvAccountId + item?.campaignName,
            adSet:
              item?.platform +
              item?.wvAccountId +
              item?.campaignName +
              item?.adSetId,
            adId:
              item?.platform +
              item?.wvAccountId +
              item?.campaignName +
              item?.adSetId +
              item?.adId,
            spend: item?.spend,
            revenue: item?.revenue,
            clicks: item?.clicks,
            conversions: item?.purchases,
            impressions: item?.impressions,
            orders: item?.purchases,
            displayName: {
              platform: item?.platform,
              account: item?.accountName,
              campaign: item?.campaignName,
              adSet: item?.adsSetName,
              adId: item?.adName ?? item?.adId,
            },
          };
        } else {
          return;
        }
      });

      setBreakdownPlatformData(newData?.filter((item: any) => !!item));
    }
  }, [filteredMarketingDataByChannels, selectedChannels]);


  useEffect(() => {
    if (!!kpis?.marketing?.data) {
      const dataArrayAds = kpis?.marketing?.data.map((item, index) => {
        return {
          campaignName: item?.campaignName,
          campaignId: item?.campaignId,
          adsGroup: item?.adsGroup,
          ad: item.ad,
          adId: item.adId,
          image: item?.image,
          imageName: item.imageName,
          impressions: item?.impressions, //
          clicks: item?.clicks,
          spend: item?.spend,
          purchases: item?.purchases,
          revenue: item?.revenue,
          wvAccountId: item?.wvAccountId,
          accountId: item?.accountId,
          id: item.id,
          cpm: 0, // spend/impressions * 1000
          cpc: 0, // spend/clicks
          roas: 0, // revenue/spend
          activityDate: item.activityDate,
          imageUrl: item.imageUrl,
        } as MarketingKpi;
      });
      setAdsKpiData(dataArrayAds);
    }
  }, [kpis?.marketing?.data]);


  useEffect(() => {
    if(!!kpis?.marketing?.data && !!selectedBreakdownRows && !!datesSelector?.startDate &&
        !!datesSelector?.endDate){
      const allRows = [...selectedBreakdownRows?.selectedPlatforms, ...selectedBreakdownRows?.selectedAccounts, ...selectedBreakdownRows?.selectedCampaigns, ...selectedBreakdownRows?.selectedAdSets,...selectedBreakdownRows?.selectedAds];
      if(selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS && allRows?.length > 0){
        const fixedMarketingData: any[] = kpis?.marketing?.data?.map((item) => {return {...item, grossRevenue: item?.revenue, marketingCost: item?.spend}});
        let displayData: any[] = fixedMarketingData;
        if(selectedBreakdownRows?.selectedPlatforms?.length > 0){
          displayData = fixedMarketingData?.filter((item) => !!item?.platform && selectedBreakdownRows?.selectedPlatforms?.includes(item?.platform))
        }
        if(selectedBreakdownRows?.selectedAccounts?.length > 0){
          displayData = fixedMarketingData?.filter((item) => !!item?.accountName && selectedBreakdownRows?.selectedAccounts?.includes(item?.accountName))
        }
        if(selectedBreakdownRows?.selectedCampaigns?.length > 0){
          displayData = fixedMarketingData?.filter((item) => !!item?.campaignName && selectedBreakdownRows?.selectedCampaigns?.includes(item?.campaignName))
        }

        const result = calculateData(
            displayData,
            datesSelector?.startDate,
            datesSelector?.endDate,
            groupByStatus,
            selectedCard
        );

        setLabels(result.labels);
        setActualDataChart(result.data);
        setTargetDataChart([])
      }

    }
  }, [kpis?.marketing?.data, selectedCard, selectedBreakdownRows, datesSelector])

  useEffect(() => {
    if (
      !!kpis.data?.data &&
      !!datesSelector?.startDate &&
      !!datesSelector?.endDate &&
      !!kpiDataByChannels
    ) {

      const allRows = [...selectedBreakdownRows?.selectedPlatforms, ...selectedBreakdownRows?.selectedAccounts, ...selectedBreakdownRows?.selectedCampaigns, ...selectedBreakdownRows?.selectedAdSets,...selectedBreakdownRows?.selectedAds];
      if(selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS && allRows?.length > 0){
        return
      }

      const result = calculateData(
        kpiDataByChannels,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        selectedCard
      );

      setLabels(result.labels);
      setActualDataChart(result.data);
    }

    if (
      !!kpis.targetData?.data &&
      !!datesSelector?.startDate &&
      !!datesSelector?.endDate &&
      !!kpiDataByChannels
    ) {
      const allRows = [...selectedBreakdownRows?.selectedPlatforms, ...selectedBreakdownRows?.selectedAccounts, ...selectedBreakdownRows?.selectedCampaigns, ...selectedBreakdownRows?.selectedAdSets,...selectedBreakdownRows?.selectedAds];

      if(selectedCard?.marketingKpiNumber === KpisEnum.BLENDED_ROAS && allRows?.length > 0){
        return
      }
      const result = calculateData(
        kpiTargetDataByChannels,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        selectedCard
      );
      setLabels(result.labels);
      setTargetDataChart(result.data);
    }
  }, [
    kpiDataByChannels,
    kpiTargetDataByChannels,
    graphKpiEnum,
    selectedCard?.marketingKpiNumber,
    datesSelector?.startDate,
    datesSelector?.endDate,
    groupByStatus,
    selectedBreakdownRows
  ]);

  const getKeyByEnum = {
    [KpisEnum.GROSS_REVENUE]: "grossRevenue",
    [KpisEnum.NET_REVENUE]: "netRevenue",
    [KpisEnum.GROSS_PROFIT]: "grossProfit",
    [KpisEnum.CONTRIBUTION_PROFIT]: "contProfit",
    [KpisEnum.AVERAGE_ORDERS_VALUE]: "aov",
    [KpisEnum.ORDERS]: "orders",
    [KpisEnum.CONVERSION_RATE]: "cr",
    [KpisEnum.MARKETING_SPEND]: "marketingCost",
    [KpisEnum.BLENDED_ROAS]: "grossRevenue",
    [KpisEnum.COST_PER_ORDER]: "grossRevenue",
    [KpisEnum.SESSIONS]: "sessions",
  };

  useEffect(() => {
    if (!!dataLanguage?.topKpis?.marketing) {
      const topKpis = dataLanguage?.topKpis?.marketing;
      if (topKpis?.includes(KpisEnum.BLENDED_ROAS)) {
        dispatch(
          setCardSelected({
            isSelected: true,
            kpisEnumMarketing: KpisEnum.BLENDED_ROAS,
            kpisEnumWorkspace: undefined,
          })
        );
      } else {
        if (!!topKpis) {
          dispatch(
            setCardSelected({
              isSelected: true,
              kpisEnumMarketing: topKpis[0],
              kpisEnumWorkspace: undefined,
            })
          );
        }
      }
    }
  }, [
    dispatch,
    organizationId?.id,
    datesSelector,
    dataLanguage?.topKpis?.marketing,
  ]);

  // selectors

  useEffect(() => {
    if (
      !!organizationId?.id &&
      !!datesSelector?.startDate &&
      !!datesSelector?.endDate &&
        !!channels?.channels
    ) {
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 0,
          channels: channels?.channels,
          allDepartments: true

        })
      );
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          kpisType: 0,
          type: 2,
          channels: channels?.channels,
          allDepartments: true

        })
      );
      dispatch(
          fetchMarketingKpi({
          organizationId: organizationId!.id,
          fromDate: datesSelector?.startDate,
          toDate: datesSelector?.endDate,
          channels: channels?.channels
        })
      );
      if (!!organizationId?.dataLanguage && !datesSelector?.isDefaultValue) {
        dispatch(
          fetchHolidays({
            dataLanguageId: organizationId?.dataLanguage,
            organizationId: organizationId?.id,
            fromDate: new Date(datesSelector?.startDate)?.toISOString(),
            toDate: new Date(datesSelector?.endDate)?.toISOString(),
          })
        );
      }
    }
  }, [
    dispatch,
    organizationId?.id,
    datesSelector?.endDate,
    datesSelector?.startDate,
    channels?.channels
  ]);

  // helpers
  const getPlatform = (name: string) => {
    switch (name) {
      case "FacebookAds":
        return IntegrationPlatform.FACEBOOK_ADS;
      case "GoogleAds":
        return IntegrationPlatform.GOOGLE_ADS;
      default:
        return 3;
    }
  };

  const clearFilters = () => {
    if (!!datesSelector?.startDate && !!datesSelector?.endDate) {
      setStartDate(new Date(datesSelector.startDate));
      setEndDate(new Date(datesSelector.endDate));
    }
  };

  // renderers

  const kpisLoader = () => {
    return (
      <styles.LoaderWrapper>
        <styles.Loader />
      </styles.LoaderWrapper>
    );
  };

  const renderDepartmentRow = () => {
    let currentWorkspace: any;
    if (user?.workspaces !== undefined && organizationId?.id !== undefined) {
      currentWorkspace = user?.workspaces?.find(
        (workspace) => workspace.organization === organizationId?.id
      );
    }
    return (
      <styles.AssignedKpisContent>
        {kpis.loading ? (
          kpisLoader()
        ) : !!dataLanguage?.topKpis?.marketing ? (
          <KpiRender
            storesChannels={selectedChannels}
            startDate={startDate}
            endDate={endDate}
            popoverPlacement={PopoverPlacement.TOP}
            kpiArray={dataLanguage?.topKpis?.marketing}
          />
        ) : (
          <></>
        )}
      </styles.AssignedKpisContent>
    );
  };

  const renderToggle = () => {
    return (
      <styles.AssignedKpisToggle>
        <Toggle
          options={[
            { index: 0, title: t("MARKETING_TOP_KPIS", { ns: "translation" }) },
            { index: 1, title: t("DEFAULT_VIEW", { ns: "translation" }) },
            /* { index: 2, title: t('CUSTOM_VIEW', { ns: 'translation' }) }  // TODO .V3*/
          ]}
          onChange={(option: ToggleOption) => {
            switch (option.index) {
              case 0:
                break;
              case 1:
                break;
              case 2:
                break;
            }
          }}
        />
      </styles.AssignedKpisToggle>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <styles.HeaderWrapper>
          <styles.Title>
            <InformationWrapper text={t('MARKETING_DEPARTMENT', {ns: 'translation'})} displayLongText/>
          </styles.Title>
          <styles.toggleHolder>{renderToggle()}</styles.toggleHolder>
          <styles.buttonWrapper>
            <StoreChannelFilter />
            <div style={{ width: "5px" }} />
            <div style={{ width: 8 }} />
            <DatepickerRange
              backgroundColor="white"
              onApply={(range) => setDateRange(range)}
            />
          </styles.buttonWrapper>
        </styles.HeaderWrapper>
      </>
    );
  };

  return (
    <styles.Wrapper>
      <styles.SmallerWrapperBackground>
        {renderHeader()}
        {renderDepartmentRow()}
        <styles.Spacer />
        {!actualDataChart && !targetDataChart ? (
          <Spinner />
        ) : (
          <OverTimeGraph
            clearFilters={clearFilters}
            groupByStatus={groupByStatus}
            setGroupBy={setGroupByStatus}
            kpiEnum={graphKpiEnum}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            actual={actualDataChart}
            target={targetDataChart}
            labels={labels}
            setGanttLabels={setGanttLabels}
            displayGantt={true}
          />
        )}

        <Box w={"100%"} h={75} />
        <BreakdownByPlatform
          data={breakdownPlatformData}
          placeholder={breakdownPlatformData?.length === 0}
          headers={[
            "Type",
            "Spend",
            "Impressions",
            "Clicks",
            "CPC",
            "CTR",
            "CPM",
            "CPO",
            "Conversions",
            "Revenue",
            "ROAS",
            "Tab",
          ]}
          title={"Breakdown By Platform"}
        />

        {/*{<PerformanceReporting data={platformData}/>}*/}

        {<CreativePerformance kpiMarketing={adsKpiData} />}
      </styles.SmallerWrapperBackground>
    </styles.Wrapper>
  );
};

import wideViewsStyles from "./styles";
import {
  extraDataset,
  OverTimeGraph,
} from "../../over-time-graph/OverTimeGraph";
import { useTranslation } from "react-i18next";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import {
  IconAcrossChannels,
  IconClearFiltersArrow,
  IconDiamond,
  IconShare,
} from "../../../utils/icons/explore";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCacTableConfig,
  fetchCustomerReports,
  kpiStateSelector,
} from "store/kpis";
import { Kpi } from "models/kpi";
import {
  organizationDataLanguageMappingSelector,
  organizationDataLanguageSelector,
  organizationSelector,
} from "@store";
import { MarketingKpi } from "@models";
import { Effort, effortsSelector, EffortsState } from "store/efforts";
import { sumData } from "utils/get-months/getLastYear";
import {
  addToasts,
  dateRangeSelector,
  ExploresEnum,
  isDefaultExploreOpenSelector,
  openDefaultExploreView,
} from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import {
  currencyFormat,
  getOvertimeGraphDataForExplore,
} from "utils/kpi-render/kpiCalculation";
import { Flex, Spinner, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { KpisEnum } from "models/kpi-settings";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";
import { GroupBy } from "features/workspace/Workspace";
import { getDatesInRange } from "utils/colors";
import { getChannelNameById } from "utils/get-channel-name/getChannelNameById";
import { channelsStateSelector } from "store/channels";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import { useCurrencySign } from "../../../utils/custom-hooks/useCurrencySign";
import { columns } from "../../../utils/effortTableHeaders/headers";
import { BlendedRoasReportShowBy } from "../../../services/kpi/kpi.service";
import { Toast, ToastStatus } from "../../toast/Toast";
import { BiCopyAlt } from "react-icons/bi";
import CacTable from "../../cac-table/CacTable";

export interface ExploreProps {
  title?: string;
}

interface ColumnData {
    website: string;
    sessions: number;
    sharesOfTotal: number;
    vsLastYear: number;
}

interface Data {
    col_0: string;
    col_1: number;
    col_2: number | string;
    col_3: number;
}


export const CacExplore = (props: ExploreProps) => {
    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const kpis = useSelector(kpiStateSelector);
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);

    const {isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose} = useDisclosure();  //

    const {isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose} = useDisclosure();  //
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [extraDatasets, setExtraDatasets] = useState<extraDataset[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any>([]);

    const [sourceTableData, setSourceTableData] = useState<any>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organization = useSelector(organizationSelector);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const dispatch = useDispatch();
    const datesSelector = useSelector(dateRangeSelector);

    const [currentBlendedRoas, setCurrentBlendedRoas] = useState<number>(5);
    const marketingMapping = useSelector(organizationDataLanguageMappingSelector);
    const [allExpendedRows, setAllExpendedRows] = useState<{ [key: string]: boolean; }>({});

    const efforts: EffortsState = useSelector(effortsSelector);
    const organizationId = useSelector(organizationSelector);
    const [filteredData, setFilteredData] = useState<MarketingKpi[]>([]);
    const [filteredSummedData, setFilteredSummedData] = useState<any>();
    const [graphData, setGraphData] = useState<any>();
    const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
    const [filteredByDatesDataDaily, setFilteredByDatesDataDaily] = useState<Kpi[]>([]);
    const [summedData, setSummedData] = useState<any[]>([]);
    const channelsData = useSelector(channelsStateSelector);
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const currentCurrency = useCurrencySign();
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [selectedSources, setSelectedSources] = useState<any[]>([]);

    const tableRef = useRef<any>(null);
    const customersReportsData = useSelector(kpiStateSelector)?.customersReport?.data;
    useEffect(() => {
        if(!!organization?.id && isExploreOpen?.isDefaultExploreOpen){
            dispatch(fetchCacTableConfig({organizationId: organization?.id}))
        }
    }, [organization?.id, isExploreOpen?.isDefaultExploreOpen]);

    useEffect(() => {
        if(isExploreOpen?.isDefaultExploreOpen){
         setStartDate(undefined);
         setEndDate(undefined);
        }
    }, [datesSelector?.startDate, datesSelector?.endDate, isExploreOpen?.isDefaultExploreOpen])

    useEffect(() => {
        if(!!organization?.id && isExploreOpen?.isDefaultExploreOpen){

            const timeFilterDates = {
                startDate: datesSelector?.startDate+"T00:00:00.000Z",
                endDate: datesSelector?.endDate+"T23:59:59.999Z"
            };


            let fetchStartDate = timeFilterDates?.startDate;
            let fetchEndDate = timeFilterDates?.endDate;

            const overtimeDatesFilter = {
                startDate: !!startDate ? startDate?.toISOString()?.slice(0,10)+"T00:00:00.000Z" : undefined,
                endDate: !!endDate ? endDate?.toISOString()?.slice(0,10)+"T23:59:59.999Z" : undefined
            }

            if(!!overtimeDatesFilter?.startDate && !!overtimeDatesFilter?.endDate){
                fetchStartDate = overtimeDatesFilter?.startDate;
                fetchEndDate = overtimeDatesFilter?.endDate;
            }
            if(!!fetchEndDate && !!fetchStartDate){
                dispatch(fetchCustomerReports({
                    organizationId: organization?.id,
                    searchQueryDto: {
                        fromDate: fetchStartDate,
                        toDate:fetchEndDate,
                        showBy: 3,
                        showByReport: BlendedRoasReportShowBy.GROSS_REVENUE
                    }
                }))
            }
        }

    }, [startDate, endDate, organization?.id, datesSelector?.startDate, datesSelector?.endDate, isExploreOpen?.isDefaultExploreOpen])

    useEffect(() => {
        //filter efforts by kpi
        if (!!efforts) {
            const filteredEfforts = efforts?.data.filter((item) => {
                return item?.kpi === KpisEnum.BLENDED_ROAS;
            });
            setEffortsTable(filteredEfforts);
        }

    }, [efforts]);
    useEffect(() => {
        if (!!kpis?.marketing?.data) {
            const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'market', 'platform', 'impressions', 'imageName', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];

            const summedData = sumData(kpis?.marketing?.data, keysToIgnore);
            setFilteredData(kpis?.marketing?.data);
            setFilteredSummedData(summedData);
        }
    }, [kpis?.marketing?.data]);

    useEffect(() => {
        if (!!filteredByDatesDataDaily) {
            let data = filteredByDatesDataDaily;
            if(selectedChannels?.length > 0){
                const channels = selectedChannels?.map((item) => item?.original?.channelId);
                data = data?.filter((item) => channels?.includes(item?.wvChannelId));
            }

            const summedData = sumData(data, ["activityDate"]);
            setSummedData(summedData);
            setCurrentBlendedRoas(summedData?.marketingCost / summedData?.customers)


            if (summedData?.customers !== undefined) {
                const graphDataToRender = {
                    title: "Customer Acquisition Cost",
                    value: (summedData?.marketingCost / summedData?.customers)?.toFixed(2),
                    vsTargetPercentage: summedData?.grossRevenue / summedData?.marketingCost,
                    isRoot: true,
                    children: [
                        {
                            title: "Customers",
                            value: summedData?.customers,
                            vsTargetPercentage: summedData?.customers / summedData?.customers,
                            isRoot: false,
                            children: []
                        },
                        {
                            title: "Spend",
                            value: currencyFormat(summedData?.marketingCost, 2, currentCurrency),
                            vsTargetPercentage: summedData?.marketingCost / summedData?.marketingCost,
                            isRoot: false,
                            children: []
                        }
                    ]
                };
                setGraphData(graphDataToRender);
            }

        }
    }, [filteredByDatesDataDaily, selectedChannels, selectedSources]);

    useEffect(() => {
        if (!!filteredByDatesDataDaily && !!channelsData?.channels) {
            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const rowData: any[] = [];
            const channels = {};
            let data = filteredByDatesDataDaily;
            if(selectedSources?.length > 0){
                const allSelectedSourcesChannels = selectedSources?.map((item) => item?.original?.allChannelsLinkedToMarketingChannel).flat();
                data = data?.filter((item) => allSelectedSourcesChannels?.includes(item?.wvChannelId))
            }
            data?.forEach((item) => {
                if (!!channels[item?.wvChannelId]) {
                    channels[item?.wvChannelId]?.push(item);
                } else {
                    channels[item?.wvChannelId] = [item];
                }
            });

            Object.keys(channels).forEach((key) => {
                const summedChannel = sumData(channels[key], keysToIgnore);
                rowData.push({
                    grossRevenue: summedChannel["grossRevenue"],
                    channelName: getChannelNameById(key, channelsData?.channels),
                    channelId: key,
                    blendedRoas:  !!summedChannel["marketingCost"] ? (summedChannel["grossRevenue"] / summedChannel["marketingCost"]) : 0,
                    marketingSpend: summedChannel["marketingCost"]
                });
            });
            let totalGrossRevenue = 0;
            rowData?.forEach((item) => {
                totalGrossRevenue = totalGrossRevenue + item?.grossRevenue;
            });
            const newRowData = rowData?.map((item) => {
                return {
                    ...item,
                    shareOfTotal: Math.round((item?.grossRevenue / totalGrossRevenue) * 100 * 100) / 100,
                    vsTarget: item?.grossRevenue / item?.grossRevenue * 100,
                    impactOnTarget: 5,
                    channelId: item?.channelId
                };
            });
            setAcrossChannelsData(newRowData);
        }
    }, [channelsData, filteredByDatesDataDaily, selectedSources]);

    useEffect(() => {
        if (!!kpis.data?.data) {
            const fixedChannels = channelsData?.channels?.map((channel) => channel?.id);
            const fixedKpisByOrgChannel = kpis?.data?.data.filter((kpi) => fixedChannels?.includes(kpi?.wvChannelId))
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = fixedKpisByOrgChannel.filter((item) => {
                    return datesRange.includes(item.activityDate.slice(0, 10));
                });
                setFilteredByDatesDataDaily(filteredData);
            } else {
                setFilteredByDatesDataDaily(fixedKpisByOrgChannel);
            }
        }
    }, [kpis.data?.data, startDate, endDate, channelsData]);


    useEffect(() => {
        if (!!kpis?.data?.data && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const fixedChannels = channelsData?.channels?.map((channel) => channel?.id);
            let data = kpis?.data?.data.filter((kpi) => fixedChannels?.includes(kpi?.wvChannelId))
            if(selectedChannels?.length > 0){
                const allChannels = selectedChannels.map((item) => item?.original?.channelId);
                data = data?.filter((item) => allChannels?.includes(item?.wvChannelId))
            }

            const graphData = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                "cac",
            );
            setActualDataChart(graphData.data);
            setLabels(graphData.labels);

            const graphDataMarketing = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                "marketingCost"
            );

            const graphDataGrossRevenue = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                'customers'
            );

            const datasets = [
                {label: 'Customers', dataset: graphDataGrossRevenue?.data, color: '#90ee90' },
                {label: 'Marketing Cost', dataset: graphDataMarketing?.data, color: "#ee90a1" },
            ]

            setExtraDatasets(datasets)


        }
    }, [
        kpis?.data?.data,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        selectedChannels,

    ]);


    // selectors


    // helpers
    const handleCopyTable = () => {
        const table = tableRef.current;
        if (!table) return;

        // Create a range to select the table contents
        const range = document.createRange();
        range.selectNode(table);

        // Clear any existing selection and select the table
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }

        // Copy the selected table contents to the clipboard
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Copied to clipboard!',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                console.log('Table copied to clipboard!');
            } else {
                console.log('Table copy failed.');
            }
        } catch (error) {
            console.log('Table copy failed.', error);
        }

        // Clear the selection
        if (selection) {
            selection.removeAllRanges();
        }
    }

    const onApplyDates = () => {

    };

    const clearFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    };


    const openShareModal = () => {
        onShareOpen();
    };
    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton onClick={() => openShareModal()}>
            <IconShare/>
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', {ns: 'translation'})}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };



    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow/>
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', {ns: 'translation'})}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({isOpen: false}))}>
                    <IconCloseOverlay/>
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    {t('CAC', {ns: 'translation'})}
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', {ns: 'translation'})}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderResetFilters()}
                    {

                        !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => {
                                onApplyDates();
                            }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)}/>
                            : <DatepickerRange onApply={(e) => {
                                onApplyDates();
                            }} defaultsDate={datesSelector.preset}/>
                    }

                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currentBlendedRoas.toFixed(2)}
                </wideViewsStyles.Value>
                <wideViewsStyles.BadgeWrapper>
                    {/*<TargetBadge color={'#57a11e'} percentage={100}/>*/}
                </wideViewsStyles.BadgeWrapper>
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };

    const renderMarketingTable = () => {
       return (
           <styles.Card>
               <Flex alignItems={'center'} gap={'4px'} padding={'12px 0'} width={'98.5%'} margin={'0 auto'}>
                   <styles.Title>
                                <span style={{marginRight: '5px'}}>
                                    {<IconAcrossChannels/>}
                                </span>
                       {t('CAC', {ns: 'translation'})}
                   </styles.Title>
                   <Tooltip label={'Copy CAC Table'}>
                       <wideViewsStyles.StyledHeaderButton onClick={handleCopyTable}><BiCopyAlt fontSize={'14px'} color={'white'}/></wideViewsStyles.StyledHeaderButton>
                   </Tooltip>
               </Flex>

               <CacTable setSelectedChannels={setSelectedChannels} allExpendedRows={allExpendedRows} setAllExpendedRows={setAllExpendedRows}
                                          data={customersReportsData} tableRef={tableRef}/>
           </styles.Card>
       )
    }

    return (
        <>
            <ShareKpi exploreEnum={ExploresEnum.CAC} isOpen={isShareOpen} onClose={onShareClose}
                      onOpen={onShareOpen}/>
            <EffortsModal dataToShow={{kpi: "Blended ROAS"}} isOpen={isEffortsOpen} onClose={onEffortsClose}
                          onOpen={onEffortsOpen}/>

            <wideViewsStyles.Wrapper>
                {renderHeader()}
                <wideViewsStyles.SectionOne>
                    <OverTimeGraph clearFilters={clearFilters} groupByStatus={groupByStatus}
                                   setGroupBy={setGroupByStatus} kpiEnum={KpisEnum.BLENDED_ROAS}
                                   setStartDate={setStartDate} setEndDate={setEndDate} actual={actualDataChart}
                                   target={targetDataChart} labels={labels} extraDatasets={extraDatasets}/>
                </wideViewsStyles.SectionOne>
                <wideViewsStyles.SectionTwo>
                    <styles.Card>
                        {renderMarketingTable()}
                    </styles.Card>

                  {/*  <GenericExploreTable

                        icon={<IconAcrossChannels />}
                        data={acrossChannelsData}
                        channelsTable={true}
                        setSelectedRows={setSelectedChannels}
                        defaultSortByKey={"shareOfTotal"}
                        headers={[
                            {
                                header: "Channel",
                                accessor: "channelName",
                                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                            },
                            {
                                header: "Blended ROAS",
                                accessor: "blendedRoas",
                                cellType: ExploreTableDataEnum.NUMBER,
                                cell: (value) => {
                                    return value?.toFixed(2)
                                },
                                footer: (cell, allRows) => {
                                    let totalGrossRevenue = 0;
                                    let totalMarketingSpend = 0;
                                    allRows?.forEach((row:  Row<{grossRevenue?: number, marketingSpend?: number}>) => {
                                        if(!!row?.original?.grossRevenue){
                                            totalGrossRevenue += row?.original?.grossRevenue
                                        }
                                        if(!!row?.original?.marketingSpend){
                                            totalMarketingSpend += row?.original?.marketingSpend
                                        }
                                    })
                                    return !!totalGrossRevenue && !!totalMarketingSpend ? (totalGrossRevenue / totalMarketingSpend)?.toFixed(2) : 0
                                },
                            },
                            {
                                header: "Gross Revenue",
                                accessor: "grossRevenue",
                                cellType: ExploreTableDataEnum.CURRENCY,
                                footerType: ExploreTableFooterTypeEnum.SUM,
                            },
                            {
                                header: "Share Of Total",
                                accessor: "shareOfTotal",
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                                footerType: ExploreTableFooterTypeEnum.SUM,
                            },
                        ]}
                        height={400}
                        tableHeight={320}
                        title={t("ACROSS_CHANNELS", { ns: "translation" })}
                    />
                    <GenericExploreTable
                        icon={<IconAcrossChannels />}
                        data={sourceTableData}
                        setSelectedRows={setSelectedSources}
                        defaultSortByKey={"shareOfTotal"}
                        headers={[
                            {
                                header: "Platform",
                                accessor: "platform",
                                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                            },
                            {
                                header: "Spend",
                                accessor: "marketingSpend",
                                cellType: ExploreTableDataEnum.CURRENCY,
                                footerType: ExploreTableFooterTypeEnum.SUM
                            },
                            {
                                header: "Share Of Total",
                                accessor: "shareOfTotal",
                                cellType: ExploreTableDataEnum.PERCENTAGE,
                                footerType: ExploreTableFooterTypeEnum.SUM,
                            },
                        ]}
                        height={400}
                        tableHeight={320}
                        title={t("ACROSS_SOURCES", { ns: "translation" })}
                    />
               
                    <div style={{width: '20px'}}/>*/}

                </wideViewsStyles.SectionTwo>
               {/* <wideViewsStyles.SectionThree>
                    {!!graphData ?
                        <Graph graphData={graphData} title={t('SUPPORTING_KPIS', {ns: 'translation'})}/> : <></>}
                </wideViewsStyles.SectionThree>*/}
                <wideViewsStyles.SectionFour>
                    <styles.Card>
                        <styles.Header>
                            <styles.Title>
                                <span style={{marginRight: '5px'}}>
                                    {<IconDiamond/>}
                                </span>
                                {t('EFFORTS_IN_PROGRESS', {ns: 'translation'})}
                            </styles.Title>
                            <DownloadButton onClick={() => console.log('download')}/>
                        </styles.Header>
                        {!!effortsTable ? <EffortsTable
                            title={t('EFFORTS_IN_PROGRESS', {ns: 'translation'})}
                            data={effortsTable}
                            cameFromExplore
                            onOpen={onEffortsOpen}
                            headers={columns}
                        /> : <Spinner colorScheme={"purple"}/>}
                    </styles.Card>
                    
                </wideViewsStyles.SectionFour>
            </wideViewsStyles.Wrapper>
        </>
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BarHolders,
  ButtonDisable,
  ErrorMessage,
  FormInputs,
  LastBarHolder,
  PasswordBar,
  SignUpCard,
  SignUpContent,
  SignUpForm,
  SignUpFormAgreement,
  SignUpFormAgreementLink,
  SignUpFormAgreementText,
  SignUpFormButton,
  SignUpFormInput,
  SignUpFormSubmitButton,
  SignUpFormTitle,
  SignUpHeader,
  SignUpShowPasswordButton,
  SignUpSignInLink,
  SignUpTitle,
  SignUpWrapper,
  StyledInput,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { SignInFormSubmitLoader } from "../sign-in/styles";
import { useDispatch, useSelector } from "react-redux";
import { authErrorSelector, authLoadingSelector, signUp } from "@store";
import { AiOutlineEye } from "react-icons/ai";
import { WideViewIcon } from "utils/icons/wideview-icons";
import StrengthMeter from "components/password-strength/StrengthMeter";
import { v4 as uuidv4 } from "uuid";
import { getErrorText } from "utils/errors/getErrorText";
import { Box } from "@chakra-ui/react";

enum PasswordLevel {
  NOT_ALLOWED,
  OKAY,
  STRONG,
  VERY_STRONG,
}

interface Props {
  token?: string;
  email?: string;
}

export const SignUp = () => {
  // hooks
  const [passwordLevel, setPasswordLevel] = useState<number>();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [poorPassword, setPoorPassword] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [formValidity, setFormValidity] = useState(false);

  const { t } = useTranslation(["translation", "translation"]);

  const [nameValue, setNameValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const passwordColors = {
    weak: "#F7686A",
    okay: "#F0BB09",
    strong: "#61C322",
    veryStrong: "#61C322",
    gray: "#E2E2EA",
  };
  const error = useSelector(authErrorSelector);
  const [blockEmailInput, setBlockEmailInput] = useState(false);

  useEffect(() => {
    if (!!location.state) {
      const state: any = location.state;
      setEmailValue(state?.email);
      setBlockEmailInput(true);
      setEmailValid(true);
    }
  }, [location]);

  // const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);

  // effects

  // helpers

  const handlePasswordChange = (evnt: { target: { value: any } }) => {
    setPasswordInput(evnt.target.value);
    if (evnt.target.value.indexOf(" ") >= 0) {
      setPasswordErr("Whitespaces are not allowed");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let firstName = "";
    let lastName = "";
    if (!!nameValue) {
      const splitted = nameValue.split(" ");
      if (splitted?.length > 1) {
        firstName = splitted[0];
        splitted.shift();
        lastName = splitted.join(" ");
      }else {
        firstName = nameValue;
      }
    }

    if (!!location.state) {
      const state: any = location.state;
      /* setEmailValue(location?.state?) */
      dispatch(
        signUp({
          firstName: firstName,
          lastName: lastName,
          email: emailValue,
          password: passwordInput,
          token: state.token,
        })
      );
    } else {
      dispatch(
        signUp({
          firstName: firstName,
          lastName: lastName,
          email: emailValue,
          password: passwordInput,
        })
      );
    }

    return;
  };

  const passwordStrength = (evnt: any) => {
    const passwordValue = evnt.target.value;
    const passwordLength = passwordValue.length;
    const onlyAllowedCharacter = /[^a-z0-9#?!@$%^&*-]/i;
    const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9A-Z])/;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const whitespaceRegExp = /^$|\s+/;
    const notAllowedPassword = onlyAllowedCharacter.test(passwordValue);
    const poorPassword = poorRegExp.test(passwordValue);
    const weakPassword = weakRegExp.test(passwordValue);
    const strongPassword = strongRegExp.test(passwordValue);

    if (passwordValue === "") {
      setPasswordErr("");
      setPasswordLevel(PasswordLevel.NOT_ALLOWED);
      setFormValidity(false);
    } else {
      // to check whitespace
      /*   if (whiteSpace) {
                  setPasswordErr("Whitespaces are not allowed");
              } */
      // to check poor password

      if (passwordLength > 0 && !notAllowedPassword) {
        setPasswordErr("");
      }
      if (
        passwordLength >= 8 &&
        (poorPassword || weakPassword || strongPassword)
      ) {
        setPoorPassword(true);
        /* setPasswordErr("Password is Okay"); */
        setPasswordLevel(PasswordLevel.OKAY);
        setFormValidity(true);
      }
      // to check weak password
      if (
        passwordLength >= 8 &&
        poorPassword &&
        (weakPassword || strongPassword)
      ) {
        setWeakPassword(true);
        /* setPasswordErr("Password is Strong"); */
        setPasswordLevel(PasswordLevel.STRONG);
        setFormValidity(true);
      } else {
        setWeakPassword(false);
      }
      // to check strong Password
      if (
        passwordLength >= 8 &&
        poorPassword &&
        weakPassword &&
        strongPassword
      ) {
        setStrongPassword(true);
        /* setPasswordErr("Password is Very Strong"); */
        setFormValidity(true);
        setPasswordLevel(PasswordLevel.VERY_STRONG);
      }
      if (passwordLength < 8) {
        setPasswordErr(`Minimum password length is 8 characters`);
        setPasswordLevel(PasswordLevel.NOT_ALLOWED);
        setFormValidity(false);
      }
      if (passwordLength > 0 && notAllowedPassword) {
        setPasswordErr("Non english characters are not allowed");
        setPasswordLevel(PasswordLevel.NOT_ALLOWED);
        setFormValidity(false);
      }
    }
  };

  const navigateToSignIn = () => {
    navigate("/auth/sign-in");
  };

  // selectors
  const authLoading = useSelector(authLoadingSelector);

  const renderLevel = () => {
    switch (passwordLevel) {
      case PasswordLevel.NOT_ALLOWED:
        return "Weak";
      case PasswordLevel.OKAY:
        return "Okay";
      case PasswordLevel.STRONG:
        return "Strong";
      case PasswordLevel.VERY_STRONG:
        return "Very Strong";
      default:
        return "";
    }
  };

  const renderSignUpHeader = () => {
    return (
      <SignUpHeader>
        <SignUpTitle>
          <WideViewIcon />
        </SignUpTitle>
      </SignUpHeader>
    );
  };

  const renderBar = (color: string, numberOfColoredBars: number) => {
    const colored = [...Array(numberOfColoredBars)].map((item, i) => {
      if (i + 1 === [...Array(numberOfColoredBars)].length) {
        return (
          <PasswordBar key={uuidv4()} color={color}>
            <LastBarHolder color={color}></LastBarHolder>
            {numberOfColoredBars === 4 ? (
              <LastBarHolder color={color} key={uuidv4()}>
                {renderLevel()}
              </LastBarHolder>
            ) : (
              <></>
            )}
          </PasswordBar>
        );
      }
      return <PasswordBar color={color} key={uuidv4()} />;
    });
    const notColored = [...Array(4 - numberOfColoredBars)].map((item, i) => {
      return (
        <PasswordBar color={passwordColors.gray} key={uuidv4()}>
          {i === 3 - numberOfColoredBars ? (
            <LastBarHolder color={color} key={uuidv4()}>
              {renderLevel()}
            </LastBarHolder>
          ) : (
            <></>
          )}
        </PasswordBar>
      );
    });

    return [...colored, ...notColored];
  };

  const renderBars = () => {
    switch (passwordLevel) {
      case PasswordLevel.NOT_ALLOWED:
        return renderBar(passwordColors.weak, PasswordLevel.NOT_ALLOWED + 1);
      case PasswordLevel.OKAY:
        return renderBar(passwordColors.okay, PasswordLevel.OKAY + 1);
      case PasswordLevel.STRONG:
        return renderBar(passwordColors.strong, PasswordLevel.STRONG + 1);
      case PasswordLevel.VERY_STRONG:
        return renderBar(
          passwordColors.veryStrong,
          PasswordLevel.VERY_STRONG + 1
        );
    }
    return;
  };

  const renderSignUpContent = () => {
    return (
      <SignUpContent>
        <SignUpCard>
          {renderSignUpForm()}
          {/*{renderSignUpSideImage()}*/}
          {renderSignUpSignInLink()}
        </SignUpCard>
      </SignUpContent>
    );
  };

  // const renderSignUpSideImage = () => {
  //     return (
  //         <SignUpImage>
  //         </SignUpImage>
  //     );
  // }

  const renderSignUpForm = () => {
    return (
      <SignUpForm onSubmit={handleSubmit}>
        {renderSignUpFormTitle()}
        <div style={{ height: "20px" }} />
        <FormInputs>
          {renderSignUpFormNameInput()}
          {renderSignUpFormEmailInput()}
          {renderSignUpFormPasswordInput()}
        </FormInputs>
        {renderSignUpFormAgreement()}
        {renderSignUpFormSubmitButton()}
        {error?.status ? (
          <ErrorMessage>{getErrorText(error.status)}</ErrorMessage>
        ) : null}
        {/* <SignUpFormOrText>
                    {t('OR', { ns: 'translation' })}
                </SignUpFormOrText>
                {renderSignUpGoogleButton()}
                {renderSignUpFacebookButton()}
                <div style={{ height: '20px' }} /> */}
      </SignUpForm>
    );
  };

  const renderSignUpFormTitle = () => {
    return (
      <SignUpFormTitle>
        {t("SIGN_UP_TO_YOUR_ACCOUNT", { ns: "translation" })}
      </SignUpFormTitle>
    );
  };

  const renderSignUpFormNameInput = () => {
    return (
      <SignUpFormInput>
        <input
          style={{ height: "max-height" }}
          className={"no-border-input"}
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          placeholder={t("YOUR_NAME", { ns: "translation" })}
        />
        <div style={{ width: "20px", height: "20px" }} />
      </SignUpFormInput>
    );
  };

  const renderSignUpFormEmailInput = () => {
    return (
      <SignUpFormInput border={!!error?.message}>
        <StyledInput
          disabled={blockEmailInput}
          className={"no-border-input"}
          type={"email"}
          value={emailValue}
          onChange={(e) => {
            setEmailValue(e.target.value);
            setEmailValid(e.target.validity.valid);
          }}
          placeholder={t("YOUR_EMAIL", { ns: "translation" })}
        />
        <div style={{ width: "20px", height: "20px" }} />
      </SignUpFormInput>
    );
  };

  const renderSignUpFormPasswordInput = () => {
    return (
      <>
        <SignUpFormInput>
          <input
            type={passwordShow ? "text" : "password"}
            className={"no-border-input"}
            required
            minLength={8}
            value={passwordInput}
            onChange={handlePasswordChange}
            onInput={(e) => passwordStrength(e)}
            placeholder={t("CREATE_PASSWORD", { ns: "translation" })}
          />
          <SignUpShowPasswordButton
            onClick={() => setPasswordShow((prev: any) => !prev)}
          >
            <AiOutlineEye size="max-content" color="gray" />
          </SignUpShowPasswordButton>
        </SignUpFormInput>
        <StrengthMeter
          poorPassword={poorPassword}
          weakPassword={weakPassword}
          strongPassword={strongPassword}
          passwordError={passwordError}
        />
        <br />
        <BarHolders>
          <Box w={14} />
          {renderBars()}
        </BarHolders>
      </>
    );
  };

  const renderSignUpFormSubmitButton = () => {
    return !!authLoading ? (
      <SignInFormSubmitLoader />
    ) : formValidity && emailValid && nameValue.length > 0 ? (
      <>
        <SignUpFormSubmitButton>
          <button style={{ color: "white" }} id={"submit"} type="submit">
            {t("REGISTER", { ns: "translation" })}
          </button>
        </SignUpFormSubmitButton>
      </>
    ) : (
      <>
        <ButtonDisable>
          <a>{t("REGISTER", { ns: "translation" })}</a>
        </ButtonDisable>
      </>
    );
  };

  const renderSignUpFormAgreement = () => {
    return (
      <SignUpFormAgreement>
        <SignUpFormAgreementText>
          {t("By signing up, you confirm that you’ve read and accepted our ", {
            ns: "translation",
          })}
        </SignUpFormAgreementText>
        <SignUpFormAgreementLink>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={"https://www.wideview.io/privacy-policy"}
          >
            {t("User Notice ", { ns: "translation" })}
          </a>
        </SignUpFormAgreementLink>
        <SignUpFormAgreementText>
          {t("and ", { ns: "translation" })}
        </SignUpFormAgreementText>
        <SignUpFormAgreementLink>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={"https://www.wideview.io/privacy-policy"}
          >
            {t("Privacy Policy.", { ns: "translation" })}
          </a>
        </SignUpFormAgreementLink>
      </SignUpFormAgreement>
    );
  };

  const renderSignUpGoogleButton = () => {
    return (
      <SignUpFormButton>
        <a onClick={() => console.log("Google!")} href={void 0}>
          <img src={"https://freesvg.org/img/1534129544.png"} alt={"Google"} />
          <div style={{ width: "10px" }} />
          {t("CONTINUTE_WITH_GOOGLE", { ns: "translation" })}
        </a>
      </SignUpFormButton>
    );
  };

  const renderSignUpFacebookButton = () => {
    return (
      <SignUpFormButton>
        <a onClick={() => console.log("Google!")} href={void 0}>
          <img
            src={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/768px-Facebook_icon_2013.svg.png"
            }
            alt={"Facebook"}
          />
          <div style={{ width: "10px" }} />
          {t("facebookButton", { ns: "translation" })}
        </a>
      </SignUpFormButton>
    );
  };

  const renderSignUpSignInLink = () => {
    return (
      <SignUpSignInLink
        onClick={() => {
          navigateToSignIn();
        }}
      >
        {t("ALREADY_HAVE_AN_WIDEVIEW_ACCOUNT_?_LOG_IN", { ns: "translation" })}
      </SignUpSignInLink>
    );
  };

  return (
    <>
      {/*{renderOnboardingPopup()}*/}
      <SignUpWrapper
        img={process.env.PUBLIC_URL + "/assets/auth-background.png"}
      >
        {renderSignUpHeader()}
        {renderSignUpContent()}
      </SignUpWrapper>
    </>
  );
};

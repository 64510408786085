import {createAsyncThunk} from "@reduxjs/toolkit";
import {getMarketingData} from "services/marketing/marketing.service";

export const fetchMarketingData = createAsyncThunk<any, any>(
    'marketing/fetchMarketingData',
    async (payload, thunkAPI) => {
        const response = await getMarketingData();
        return response;
    }
)


import {TableData} from 'store/website';
import {Chart, registerables} from 'chart.js';
import React, {useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import {styles} from './styles';
import {t} from 'i18next';

interface Props {
    rows: TableData[];
    selectedTab: number;
}
Chart.register(...registerables);


export const UserAnalysisChart = (props: Props) => {
    //hooks
    const [labels, setLabels] = useState<(string | string[])[]>([]);
    const [chartData, setChartData] = useState<number[]>([]);
    const [chartColor, setChartColor] = useState<string>("#7031EB");
    const [chartTitle, setChartTitle] = useState<string>("");
    //helpers
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 120,
                    minRotation: 0,
                },
            },
            y: {
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    callback: function (label: any, index: any, labels: any) {
                        if (label === 0) {
                            return 0;
                        }
                        return label / 1000 + 'k';
                    }
                },
                scaleLabel: {
                    display: true,
                    labelString: '1k = 1000'
                },
                stacked: true,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                align: 'end' as const

            },
            title: {
                display: false,

            },
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: `${t('REVENUE', { ns: 'translation' })}`,
                data: chartData,
                backgroundColor: chartColor,
            }
        ],
    };

    const keysToSum = ['revenue'];
    const summedCountry: TableData[] = Object.values(props.rows.reduce((obj, record) => {
        if (!obj[record.country]) {
            obj[record.country] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.country][key] += record[key];
            });
        }
        return obj;
    }, {}));
    const summedBrowsers: TableData[] = Object.values(props.rows.reduce((obj, record) => {
        if (!obj[record.browser]) {
            obj[record.browser] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.browser][key] += record[key];
            });
        }
        return obj;
    }, {}));
    const summedAge: TableData[] = Object.values(props.rows.reduce((obj, record) => {
        if (!obj[record.ages]) {
            obj[record.ages] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.ages][key] += record[key];
            });
        }
        return obj;
    }, {}));
    const summedGender: TableData[] = Object.values(props.rows.reduce((obj, record) => {
        if (!obj[record.gender]) {
            obj[record.gender] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.gender][key] += record[key];
            });
        }
        return obj;
    }, {}));

    useEffect(() => {
        const newData = summedBrowsers.map((item) =>
            item.browser
        );
        const dataSets = summedBrowsers.map((item) =>
            item.revenue
        );
        setChartData(dataSets);
        setLabels(newData);
        setChartTitle(`${t('SALES_BY_BROWSER', { ns: 'translation' })}`);
    }, [props?.rows]);

    useEffect(() => {
        switch (props.selectedTab) {
            case 0:
                const newDataBrowser = summedBrowsers.map((item) => {
                    if (item.browser.indexOf(' ') >= 0) {
                        return (item.browser.split(' '));
                    }
                    return item.browser;
                }
                );
                const dataSetsBrowser = summedBrowsers.map((item) =>
                    item.revenue
                );
                setChartData(dataSetsBrowser);
                setLabels(newDataBrowser);
                setChartColor("#7031EB");
                setChartTitle(`${t('SALES_BY_BROWSER', { ns: 'translation' })}`);
                break;
            case 1:
                const newDataAge = summedAge.map((item) =>
                    item.ages
                );
                const dataSetsAge = summedAge.map((item) =>
                    item.revenue
                );
                setChartData(dataSetsAge);
                setLabels(newDataAge);
                setChartColor("#7031EB");
                setChartTitle(`${t('SALES_BY_AGE', { ns: 'translation' })}`);
                break;
            case 2:
                const newDataGender = summedGender.map((item) =>
                    item.gender
                );
                const dataSetsGender = summedGender.map((item) =>
                    item.revenue
                );
                setLabels(newDataGender);
                setChartData(dataSetsGender);
                setChartColor("#7031EB");
                setChartTitle(`${t('SALES_BY_GENDER', { ns: 'translation' })}`);
                break;
            case 3:
                const newDataGeography = summedCountry.map((item) => {
                    if (item.country.indexOf(' ') >= 0) {
                        return (item.country.split(' '));
                    }
                    return item.country;
                }
                );
                const dataSetsGeography = summedCountry.map((item) =>
                    item.revenue
                );
                setLabels(newDataGeography);
                setChartData(dataSetsGeography);
                setChartColor("#7031EB");
                setChartTitle(`${t('SALES_BY_GEOGRAPHY', { ns: 'translation' })}`);
                break;
        }
    }, [props.selectedTab, props?.rows]);

    //renderer

    return (<styles.Wrapper>
        <styles.Title>{chartTitle}</styles.Title>
        <Bar style={{ marginTop: 100 }} options={options} data={data} >
        </Bar>
    </styles.Wrapper>);
};

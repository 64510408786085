import wideViewsStyles, { styles } from "./styles";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  IconCloseOverlay,
  IconKpiFramework,
} from "../../utils/icons/account-health";
import {
  MoreInfoDialog,
  MoreInfoDialogProps,
} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { MoreInfoButton } from "../more-info-button/MoreInfoButton";
import { IoIosArrowForward } from "react-icons/io";
import { KpiSettingsDialog } from "../../dialogs/kpi-settings-dialog/KpiSettingsDialog";
import {
  organizationDataLanguageSelector,
  organizationSelector,
  userSelector,
} from "@store";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersData } from "store/users";
import { openDefaultExploreView } from "store/ui";
import { Box, Tooltip } from "@chakra-ui/react";
import { KpisEnum } from "models/kpi-settings";
import PremiumIcon from "../premium-icon/PremiumIcon";

export interface ExploreProps {
  title?: string;
}

interface Row {
  title: string;
  description: string;
  buttonCallback: () => void;
  index: number;
  premium?: boolean;
}

export const KpiFramework = (props: ExploreProps) => {
  // hooks
  const user = useSelector(userSelector);
  const organization = useSelector(organizationSelector);
  const [titles, setTitles] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(["translation", "translation"]);

  useEffect(() => {
    if (!!organization?.id) {
      dispatch(fetchUsersData(organization.id));
    }
  }, [dispatch, organization?.id, organization?.users]);

  const strategyMoreInfoDialogProps = {
    title: "Company Top KPIs",
    subtitle: "How to use this view?",
    description:
      "Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.",
    tip: "TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.",
    videoUrl: "https://www.youtube.com/watch?v=Rjm129AoRno",
    videoTitle: "Best Practices",
    videoSubtitle: "How to use this view?",
    videoDescription: "5 min explanatory video from our COO",
    videoLinkTitle: "Click to watch",
  } as MoreInfoDialogProps;
  const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] =
    useState(false);
  const dataLanguage = useSelector(organizationDataLanguageSelector);

  const [isKpiDialogOpen, setIsKpiDialogOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const kpiEnumToString = (title: number) => {
    if (title === undefined) {
      return "";
    }
    switch (title) {
      case KpisEnum.MARKETING_SPEND:
        return "Marketing Spend";
      case KpisEnum.RETURN_ON_AD_SPEND:
        return "ROAS";
      case KpisEnum.MARKETING_EFFICIENCY_RATIO:
        return "MER";
      case KpisEnum.ADVERTISING_COST_OF_SALES:
        return "ACOS";
      case KpisEnum.COST_PER_CLICK:
        return "CPC";
      case KpisEnum.COST_PER_ORDER:
        return "CPO";
      case KpisEnum.COST_PER_MILE:
        return "CPM";
      case KpisEnum.CUSTOMER_ACQUISITION_COST:
        return "CAC";
      case KpisEnum.AIO:
        return "AIO";
      case KpisEnum.USERS:
        return "Users";
      case KpisEnum.NEW_USERS:
        return "New Users";
      case KpisEnum.CLICKS:
        return "Clicks";
      case KpisEnum.GROSS_REVENUE:
        return "Gross Revenue";
      case KpisEnum.NET_REVENUE:
        return "Net Revenue";
      case KpisEnum.GROSS_PROFIT:
        return "Gross Profit";
      case KpisEnum.CONTRIBUTION_PROFIT:
        return "Contribution Profit";
      case KpisEnum.GROSS_MARGIN:
        return "Gross Margin";
      case KpisEnum.ORDERS:
        return "Orders";
      case KpisEnum.AVERAGE_ORDERS_VALUE:
        return "AOV";
      case KpisEnum.UNITS_SOLD:
        return "Units Sold";
      case KpisEnum.CUSTOMERS:
        return "Customers";
      case KpisEnum.NEW_CUSTOMER_ORDER:
        return "New Customer Order";
      case KpisEnum.RETURNING_CUSTOMER_ORDERS:
        return "Returning Customer Orders";
      case KpisEnum.CUSTOMER_LIFETIME_VALUE:
        return "CLV";
      case KpisEnum.COST_OF_GOODS_SOLD:
        return "COGS";
      case KpisEnum.RETURNS:
        return "Returns";
      case KpisEnum.REPLACEMENTS:
        return "Replacements";
      case KpisEnum.INVENTORY_TURNOVER:
        return "Inventory Turnover";
      case KpisEnum.AVERAGE_INVENTORY:
        return "Average Inventory";
      case KpisEnum.DAYS_SALES_OF_INVENTORY:
        return "DSI";
      case KpisEnum.DAYS_SALE_OUTSTANDING:
        return "DSO";
      case KpisEnum.DAYS_PAYABLE_OUTSTANDING:
        return "DPO";
      case KpisEnum.CASH_CONVERSION_CYCLE:
        return "CCC";
      case KpisEnum.SESSIONS:
        return "Sessions";
      case KpisEnum.COST_PER_SESSION:
        return "CPS";
      case KpisEnum.CONVERSION_RATE:
        return "CR";
      case KpisEnum.BLENDED_ROAS:
        return "Blended ROAS";
      case KpisEnum.COGS_AVERAGE:
        return "COGS Average";
      case KpisEnum.AVERAGE_UNITS_SOLD:
        return "Average Units Solid p/ Day";
      default:
        return "Not Found";
    }
  };

  useEffect(() => {
    const array: React.SetStateAction<string[]> = [];
    user?.workspaces![0].pinnedKpis?.forEach((item) => {
      array.push(kpiEnumToString(item));
    });
    setTitles(array);
  }, [user, user?.workspaces, dispatch, organization?.id, organization?.users]);

  const rows : Row[] = [
    {
      title: "Company top KPIs",
      description:
        "Set top KPIs for the whole organization in line with your business strategy",
      index: 1,
      buttonCallback: () => {
        openCloseKpiSettingsDialog(1, true);
      },
    } as Row,
    {
      title: "Marketing top KPIs",
      description: "Set top KPIs for the marketing team",
      index: 2,
      buttonCallback: () => {
        openCloseKpiSettingsDialog(2, true);
      },
    } as Row,
    {
      title: "Finance top KPIs",
      description: "Set top KPIs for the sales team",
      buttonCallback: () => {
        openCloseKpiSettingsDialog(3, true);
      },
      index: 3,
    } as Row,
    {
      title: "Sales top KPIs",
      description: "Set top KPIs for the sales team",
      buttonCallback: () => {
        openCloseKpiSettingsDialog(4, true);
      },
      index: 4,
    } as Row,
    {
      title: "Website top KPIs",
      description: "Set top KPIs for the website team",
      buttonCallback: () => {
        openCloseKpiSettingsDialog(5, true);
      },
      index: 5,
      premium: true,
    } as Row,
    {
      title: "Operation top KPIs",
      description: "Set top KPIs for the operation team",
      buttonCallback: () => {},
      index: 6,
      premium: true,
    } as Row,
    {
      title: "Customer Success top KPIs",
      description: "Set top KPIs for the customer success team",
      buttonCallback: () => {},
      index: 7,
      premium: true,
    } as Row,
  ];

  // helpers
  const openStrategyMoreInfoDialog = (isOpen: boolean) => {
    setIsStrategyMoreInfoDialogOpen(isOpen);
  };

  const openCloseKpiSettingsDialog = (index: number, isOpen: boolean) => {
    const updatedIsKpiDialogOpen = [...isKpiDialogOpen];
    updatedIsKpiDialogOpen[index] = isOpen;
    setIsKpiDialogOpen(updatedIsKpiDialogOpen);
  };

  const renderTitles = (index: number): string[] => {
    if (dataLanguage !== undefined) {
      switch (index) {
        case 1:
          if (dataLanguage?.topKpis?.company !== undefined) {
            const titles: string[] = [];
            dataLanguage!.topKpis?.company?.forEach((item, i, array) => {
              if (!!kpiEnumToString(item)) {
                titles.push(kpiEnumToString(item)!);
              }
            });
            return titles;
          }
          break;
        case 2:
          if (dataLanguage?.topKpis?.marketing !== undefined) {
            const titles: string[] = [];
            dataLanguage!.topKpis?.marketing?.forEach((item, i, array) => {
              if (!!kpiEnumToString(item)) {
                titles.push(kpiEnumToString(item)!);
              }
            });
            return titles;
          }
          break;
        case 3:
          if (dataLanguage?.topKpis?.finance !== undefined) {
            const titles: string[] = [];
            dataLanguage!.topKpis?.finance?.forEach((item, i, array) => {
              if (!!kpiEnumToString(item)) {
                titles.push(kpiEnumToString(item)!);
              }
            });
            return titles;
          }
          break;
        case 4:
          if (dataLanguage?.topKpis?.website !== undefined) {
            const titles: string[] = [];
            dataLanguage!.topKpis?.website?.forEach((item, i, array) => {
              if (!!kpiEnumToString(item)) {
                titles.push(kpiEnumToString(item)!);
              }
            });
            return titles;
          }
          break;
      }
    }
    return [];
  };

  // renderers
  const renderRow = (row: Row, index: number) => {
    const length = 45;
    let moreKpis = 0;

    let array = renderTitles(index).join(", ").slice(0, length);
    if (array[length - 1] === " ") {
      array = renderTitles(index)
        .join(", ")
        .slice(0, length - 2);
    }
    if (array[length - 1] === ",") {
      array = renderTitles(index)
        .join(", ")
        .slice(0, length - 1);
    }
    if (renderTitles(index).join(", ").length + 4 > length) {
      array = array + "...";
    }
    if (renderTitles(index).length === 0) {
      array = "";
    }
    moreKpis =
      renderTitles(index).length - 1 - (array.match(/,/g) || []).length;

    return (
      <wideViewsStyles.Row premium={row?.premium} key={index}>

        <wideViewsStyles.RowTitleWrapper>
          <wideViewsStyles.RowTitle>
            <Box opacity={row?.premium ? 0.5 : 1}>
              {row.title}
            </Box>
              {!!row?.premium && <PremiumIcon/>}

          </wideViewsStyles.RowTitle>
          <wideViewsStyles.RowDescription>
            <Box opacity={row?.premium ? 0.5 : 1}>
            {row.description}
            </Box>
          </wideViewsStyles.RowDescription>
        </wideViewsStyles.RowTitleWrapper>
        <wideViewsStyles.RowButton premium={row?.premium} onClick={() => row.buttonCallback()}>
          <wideViewsStyles.RowButtonTextWrapper>
            <wideViewsStyles.RowButtonText>
              <Tooltip
                placement="top"
                label={renderTitles(index).join(", ")}
                aria-label="A tooltip"
              >
                {array}
              </Tooltip>
              {moreKpis > 0 ? "+" + moreKpis : null}
            </wideViewsStyles.RowButtonText>
          </wideViewsStyles.RowButtonTextWrapper>
          <wideViewsStyles.RowButtonIcon>
            <IoIosArrowForward />
          </wideViewsStyles.RowButtonIcon>

          <KpiSettingsDialog
            props={{
              title: row?.title,
              subtitle: row?.description,
            }}
            isDialogOpen={isKpiDialogOpen[index]}
            onClose={() => {
              openCloseKpiSettingsDialog(index, false);
            }}
            index={index}
          />
        </wideViewsStyles.RowButton>
      </wideViewsStyles.Row>
    );
  };

  const renderContent = () => {
    return (
      <wideViewsStyles.Content>
        {rows.map((row, index) => {
          return renderRow(row, row.index);
        })}
      </wideViewsStyles.Content>
    );
  };

  const renderHeader = () => {
    return (
      <wideViewsStyles.Header>
        <styles.HeaderHolder>
          <wideViewsStyles.HeaderIcon>
            <span style={{ width: "50px", height: "50px" }}>
              <IconKpiFramework />
            </span>
          </wideViewsStyles.HeaderIcon>
          <wideViewsStyles.TitleWrapper>
            <wideViewsStyles.Title>
              {t("KPI_FRAMEWORK", { ns: "translation" })}
              <MoreInfoButton
                onClick={() => openStrategyMoreInfoDialog(true)}
              />
              <MoreInfoDialog
                props={strategyMoreInfoDialogProps}
                isDialogOpen={isStrategyMoreInfoDialogOpen}
                onClose={() => setIsStrategyMoreInfoDialogOpen(false)}
              />
            </wideViewsStyles.Title>
            <wideViewsStyles.SubTitle>
              {t("CHANGE_KPI_SETTINGS_BELOW", { ns: "translation" })}
            </wideViewsStyles.SubTitle>
          </wideViewsStyles.TitleWrapper>
        </styles.HeaderHolder>

        <styles.HeaderIcon>
          <span style={{ width: "100px", height: "100px", overflow: "hidden" }}>
            <button
              onClick={() => {
                dispatch(openDefaultExploreView({ isOpen: false }));
              }}
            >
              <IconCloseOverlay />
            </button>
          </span>
        </styles.HeaderIcon>
      </wideViewsStyles.Header>
    );
  };

  return (
    <wideViewsStyles.Wrapper>
      {renderHeader()}
      {renderContent()}
    </wideViewsStyles.Wrapper>
  );
};

import {apiBaseUrl} from "./general";

// organization url
export const organizationUrl = `${apiBaseUrl}/organization`;

// endpoints
export const organizationFetchUrl = `${organizationUrl}`;

export const addIntegrationUrl = `${organizationUrl}`;

export const addUserUrl = `${organizationUrl}/adduser`;

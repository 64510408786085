import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { ComparisonTable } from "store/website/state";
import { useEffect, useState } from "react";
import { styles } from './styles';
import { t } from "i18next";


Chart.register(...registerables);


interface Props {
    data: ComparisonTable[];
    selectedTab: number;
}



export const PerformanceComparisonChart = (props: Props) => {
    const [labels, setLabels] = useState<(string | string[])[]>([]);
    const [chartData, setChartData] = useState<number[]>([]);
    const [noData, setNoData] = useState<boolean>(false);
    const [chartColor, setChartColor] = useState<string>("#7031EB");
    const [chartTitle, setChartTitle] = useState<string>("");
    const [arrayData2, setArrayData2] = useState<number[]>([]);

    useEffect(() => {
        if (chartData.every((item) => item === 0)) {
            setNoData(true);
        }
        else {
            setNoData(false);
        }

    }, [chartData]);



    const colors = [
        "#7A35F5",
        "#7A4CBF",
        "#b998e9",
        "#E5D5FA",
        "#bca8d3",
        "#a183c9",
    ];
    const colorsNoData = [
        "#E8E8E8",

    ];
    const footer = (tooltipItems: any[]) => {

        return 'No revenue';
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {

                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: function (label: any, index: any, labels: any) {
                        if (label === 0) {
                            return 0;
                        }
                        return label / 1000 + 'k';
                    }
                },
                scaleLabel: {
                    display: false,
                    labelString: '1k = 1000'
                },
                stacked: true,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
                align: 'end' as const

            },
            title: {
                display: false,
            },


        },
    };
    const optionsNoData = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {

                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: function (label: any, index: any, labels: any) {
                        if (label === 0) {
                            return 0;
                        }
                        return label / 1000 + 'k';
                    }
                },
                scaleLabel: {
                    display: false,
                    labelString: '1k = 1000'
                },
                stacked: true,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
                align: 'end' as const

            },
            title: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function () {
                        return "No Revenue";
                    },


                }


            }

        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: `${t('REVENUE', { ns: 'translation' })}`,
                data: chartData,
                backgroundColor: colors
            }
        ],
    };
    const noDataChart = {
        labels,
        datasets: [
            {
                label: `${t('REVENUE', { ns: 'translation' })}`,
                data: [1],
                backgroundColor: colorsNoData
            }
        ],
    };

    const keysToSum = ['revenue'];
    const summedType: ComparisonTable[] = Object.values(props.data.reduce((obj, record) => {
        if (!obj[record.type]) {
            obj[record.type] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.type][key] += record[key];
            });
        }
        return obj;
    }, {}));
    const summedProduct: ComparisonTable[] = Object.values(props.data.reduce((obj, record) => {
        if (!obj[record.productPage]) {
            obj[record.productPage] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.productPage][key] += record[key];
            });
        }
        return obj;
    }, {}));
    const summedSource: ComparisonTable[] = Object.values(props.data.reduce((obj, record) => {
        if (!obj[record.sourceMedium]) {
            obj[record.sourceMedium] = { ...record };
        } else {
            keysToSum.forEach(key => {
                obj[record.sourceMedium][key] += record[key];
            });
        }
        return obj;
    }, {}));

    useEffect(() => {

        const data: { type: string, revenue: number; }[] = [];
        const newData = summedType.map((item) =>
            item?.type
        );
        const dataSets = summedType.map((item) =>
            item?.revenue
        );
        newData.forEach((item, i) => {
            data.push({ type: item, revenue: dataSets[i] });
        });
        setChartData(dataSets);
        setLabels(newData);

    }, [props.data]/* [summedType] */);

    useEffect(() => {
        switch (props.selectedTab) {
            case 0:
                const data: { type: string, revenue: number; }[] = [];

                const newDataType = summedType.map((item) => {

                    return item.type;
                }
                );
                const dataSetsType = summedType.map((item) =>
                    item.revenue
                );
                newDataType.forEach((item, i) => {
                    data.push({ type: item, revenue: dataSetsType[i] });
                });
                if (newDataType.length >= 4) {

                    const sortedData = data.sort((a, b) => b.revenue - a.revenue);

                    let otherData = 0;
                    let x = sortedData.map((val, index) => {
                        if (index > 2)
                            otherData += val.revenue;
                        return;
                    });
                    const arrayData = [sortedData[0].revenue, sortedData[1].revenue, sortedData[2].revenue, otherData];
                    const arrayLabels = [sortedData[0].type, sortedData[1].type, sortedData[2].type, "Other"];
                    setChartData(arrayData);
                    setLabels(arrayLabels);
                    return;
                }
                else {
                    setChartData(dataSetsType);
                    setLabels(newDataType);
                }
                break;
            case 1:
                const data1: { type: string, revenue: number; }[] = [];

                const newDataProduct = summedProduct.map((item) =>
                    item.productPage
                );
                const dataSetsProduct = summedProduct.map((item) =>
                    item.revenue
                );

                newDataProduct.forEach((item, i) => {
                    data1.push({ type: item, revenue: dataSetsProduct[i] });
                });

                if (newDataProduct.length >= 4) {

                    const sortedData1 = data1.sort((a, b) => b.revenue - a.revenue);

                    let otherData = 0;
                    let x = sortedData1.map((val, index) => {
                        if (index > 2)
                            otherData += val.revenue;
                        return;
                    });
                    const arrayData1 = [sortedData1[0].revenue, sortedData1[1].revenue, sortedData1[2].revenue, otherData];
                    const arrayLabels1 = [sortedData1[0].type, sortedData1[1].type, sortedData1[2].type, "Other"];
                    setChartData(arrayData1);
                    setLabels(arrayLabels1);
                    return;
                }
                else {
                    setChartData(dataSetsProduct);
                    setLabels(newDataProduct);
                }

                break;
            case 2:
                const data2: { type: string, revenue: number; }[] = [];

                const newDataSource = summedSource.map((item) =>
                    item.sourceMedium
                );
                const dataSetsSource = summedSource.map((item) =>
                    item.revenue
                );
                newDataSource.forEach((item, i) => {
                    data2.push({ type: item, revenue: dataSetsSource[i] });
                });

                if (newDataSource.length >= 4) {
                    const sortedData2 = data2.sort((a, b) => b.revenue - a.revenue);

                    let otherData = 0;
                    let x = sortedData2.map((val, index) => {
                        if (index > 2)
                            otherData += val.revenue;
                        return;
                    });
                    const arrayData2 = [sortedData2[0].revenue, sortedData2[1].revenue, sortedData2[2].revenue, otherData];
                    const arrayLabels2 = [sortedData2[0].type, sortedData2[1].type, sortedData2[2].type, "Other"];
                    setChartData(arrayData2);
                    setLabels(arrayLabels2);
                    return;
                }
                else {
                    setChartData(dataSetsSource);
                    setLabels(newDataSource);
                }
                break;
        }
    }, [props.selectedTab]);

    return (<>
        <styles.Wrapper >
            {noData ? <Doughnut options={optionsNoData} data={noDataChart} >
            </Doughnut> : <Doughnut options={options} data={data} >
            </Doughnut>}
            {noData ? <></> : <styles.LabelsWrappers>{labels?.map((item, i) => (<styles.Label><styles.Span color={colors[i]} />{item}</styles.Label>))}</styles.LabelsWrappers>}
        </styles.Wrapper>
    </>);

};

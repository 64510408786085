import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

// sign-up state
export const channelsStateSelector = (state: RootState) => state.channels;

// user
export const channelSelector = createSelector(
  channelsStateSelector,
  (state) => state.channels
);

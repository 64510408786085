import {Metric} from "../../models/pnl-language";

const extractPnlsForMetric = (metricToFetch: string, pnls?: Metric[]) => {
    const listOfAllPnlToFetch: string[] = [];
    const fieldsToIgnore = ['grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit'];

    const allExpressionsForMetric = pnls?.find((pnl) => pnl?.fieldName === metricToFetch)?.expression
        ?.filter((expression) =>
            !expression?.isOperator &&
            !!expression?.operand &&
            !(fieldsToIgnore?.includes(expression?.operand))
        )?.map((expression) => expression?.operand);

    allExpressionsForMetric?.forEach((fieldName) => {
        if(!!fieldName){
            listOfAllPnlToFetch?.push(fieldName)
        }
        const foundMetricForExpression = pnls?.find((pnl) => pnl?.fieldName === fieldName);
        foundMetricForExpression?.expression?.forEach((expression) => {
            if(!expression?.isOperator && !!expression?.operand && !expression?.isNumber){
                listOfAllPnlToFetch.push(expression?.operand);
            }
        });
    });

    return listOfAllPnlToFetch;
}

function replaceDotsWithUnderscores(text?: string) {
    if(text?.includes('cogs')){
        return text
    }
    return text?.replace(/\./g, '_') ?? text;
}


export const getListOfMetrics = (metricToFetch: string, pnls?: Metric[]) => {
    const listOfAllPnlToFetch: any[] = [];

    if (!!metricToFetch && ['netRevenue', 'grossProfit', 'contributionProfit'].includes(metricToFetch)) {
        const netRevenueMetrics = extractPnlsForMetric('netRevenue', pnls);
        netRevenueMetrics?.forEach((metric) => {
            listOfAllPnlToFetch?.push(replaceDotsWithUnderscores(metric))
        })
    }
    if (!!metricToFetch && ['grossProfit', 'contributionProfit'].includes(metricToFetch)) {
        const grossProfitMetrics = extractPnlsForMetric('grossProfit', pnls);
        grossProfitMetrics?.forEach((metric) => {
            listOfAllPnlToFetch?.push(replaceDotsWithUnderscores(metric))
        })
    }
    if (!!metricToFetch && ['contributionProfit'].includes(metricToFetch)) {
        const contributionProfitMetrics = extractPnlsForMetric('contributionProfit', pnls);
        contributionProfitMetrics?.forEach((metric) => {
            listOfAllPnlToFetch?.push(replaceDotsWithUnderscores(metric))
        })
    }

    switch (metricToFetch) {
        case ('cpo'):
            return ['marketingCost', 'orders']
        case ('blendedRoas'):
            return ['marketingCost', 'grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit']
        case ('aov'):
            return ['orders', 'grossRevenue']
        case ('netRevenue'):
            return [metricToFetch, 'grossRevenue', ...listOfAllPnlToFetch]
        case ('grossProfit'):
            return [metricToFetch, 'grossRevenue', ...listOfAllPnlToFetch]
        case ('contributionProfit'):
            return [metricToFetch, 'grossRevenue', ...listOfAllPnlToFetch]
        default:
            return [metricToFetch ?? '']

    }

}
import styled from "styled-components";

export namespace styles {
    export const ButtonWrapper = styled.button`
  min-width: 45px;
  height: 45px;
  border: 1px solid #ebebeb;
  border-radius: 19px;
  background: white;
      display: flex;
      justify-content: center;
      align-items: center;
`;
}

export default styles;

import {createSlice} from "@reduxjs/toolkit";
import {initalUserState, UsersState} from "./state";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {clearUsersState} from "./actions";
import {fetchUsersData} from "./thunks";

function buildCleanProductsStateReducer(
  builder: ActionReducerMapBuilder<UsersState>
) {
  builder.addCase(clearUsersState, (state, action) => {
    state.data = undefined;
    return state;
  });
}

function buildFetchUsersDataReducer(
  builder: ActionReducerMapBuilder<UsersState>
) {
  builder
    .addCase(fetchUsersData.pending, (state, action) => {
      state.data = undefined;
      return state;
    })
    .addCase(fetchUsersData.fulfilled, (state, action) => {
      if(Array.isArray(action?.payload)){
        state.data = action.payload.map((user) => {
          const data = user?.image?.buffer?.data;
          let base64 = "";
          if(!!data){
             base64 = Buffer.from(data).toString('base64');
          }
          return {
            ...user,
             image: `data:image/jpeg;base64,${base64}`
          }
        })
      }
      return state;
    })
    .addCase(fetchUsersData.rejected, (state, action) => {
      state.data = undefined;
      return state;
    });
}

export const usersKpiSlice = createSlice({
  name: "users",
  initialState: initalUserState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanProductsStateReducer(builder);

    // async
    buildFetchUsersDataReducer(builder);
  },
});

// reducer
export const usersReducer = usersKpiSlice.reducer;

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};
    /* height: 100%; */
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    border-radius: 0px 0px 20px 20px;
  `;
  export const GraphWrapper = styled.div`
  width: 100%;
  `

  export const StyledHeaderButton = styled.button`
    background-color: ${(props) => props.theme.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 12px;
  `

  export const SmallerWrapperBackground = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundColor};

    /* height: 100%; */
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to top,
      #f7faff,
      #f9fbff,
      #fbfcff,
      #fdfdff,
      #fefeff,
      #fdfcff,
      #fbfbfe,
      #faf9fe,
      #f6f4fd,
      #f2f0fc,
      #edebfb,
      #e9e7fa
    );
    
    padding: 20px 10px 20px 40px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const TopBarWrapper = styled.div`
    width: 100%;
    display: flex;
  `;

  export const SectionHeader = styled.div`
    width: 100%;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
  export const Title = styled.div`
    font-size: 21px;
    min-width: max-content;
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
    align-self: flex-start;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
  `;

  export const ShowByText = styled.div`
    color: #7a86a1;
    font-size: 14px;
    white-space: nowrap;
  `;

  export const DownloadButton = styled.button`
    background: white;
    font-size: 14px;
    height: 45px;
    min-width: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #ebebeb;
    border-radius: 19px;
  `;

  export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  `;
}

export default styles;

// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { assignTopKpi } from "store/kpis";

// locals
import {
  cleanSavedIntegration,
  cleanUpGeneralSettingsLoader,
  cleanUpPnlLoaders,
  clearOrganizationState, closeAccountsModal,
  createMarketingMappingElementLocal,
  displayUpdateButton,
  newMarketingMapping,
  removePnlElementFromData,
  setDisplayCurrency,
} from "./actions";
import {
  DataLanguage,
  initialOrganizationState,
  OrganizationState,
  PnlElement,
} from "./state";
import {
  addIntegration,
  changeCurrencyAndTime,
  changeIntegrationName,
  createOrganization,
  createPnlElement,
  deleteIntegration,
  editPnlSettings,
  fetchCustomCogs,
  fetchCustomCogsData,
  fetchDataLanguage,
  fetchHolidays,
  fetchMarketingMappingElementsView,
  fetchOrganization, getFacebookAdsAccounts, getFacebookAdsAccountsV2, getGoogleAdsAccounts, getGoogleAdsAccountsV2,
  inviteUser, refreshIntegration,
  triggerAirflow,
} from "./thunks";
import { KpisEnum } from "../../models/kpi-settings";
import { kpiOrder } from "../../utils/constants/constants";
import {Integration, Organization} from "@models";

// reducers
// clean organization state
function buildCleanOrganizationStateReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(clearOrganizationState, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.organization = undefined;
    return state;
  });
}

function buildCloseAccountsModal(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(closeAccountsModal, (state, action) => {
    if(!!state?.organization){
      state.organization.isOpenAccountsModal = false;
    }
    return state;
  });
}

function buildDeletePnlElement(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(removePnlElementFromData, (state, action) => {
    const findByNameAndRemove = (
      array: PnlElement[],
      name: string
    ): PnlElement[] => {
      return array?.filter((item) => item?.name !== name);
    };
    if (!!state.dataLanguage?.pnlSettings) {
      const pnlSettingsDeepCopy = JSON.parse(
        JSON.stringify(state.dataLanguage?.pnlSettings)
      );
      state.dataLanguage.pnlSettings.elements = findByNameAndRemove(
        pnlSettingsDeepCopy?.elements,
        action?.payload?.name
      );
      state.dataLanguage.pnlSettings.grossSales = findByNameAndRemove(
        pnlSettingsDeepCopy?.grossSales,
        action?.payload?.name
      );
      state.dataLanguage.pnlSettings.grossProfit = findByNameAndRemove(
        pnlSettingsDeepCopy?.grossProfit,
        action?.payload?.name
      );
      state.dataLanguage.pnlSettings.netSales = findByNameAndRemove(
        pnlSettingsDeepCopy?.netSales,
        action?.payload?.name
      );
    }
    return state;
  });
}

function buildCleanPnlLoadersStateReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(cleanUpPnlLoaders, (state, action) => {
    if (!!state?.dataLanguage?.pnlSettings) {
      state.dataLanguage.pnlSettings.isPostLoading = undefined;
      state.dataLanguage.pnlSettings.isPutLoading = undefined;
    }
    return state;
  });
}

function buildCleanGeneralSettingsLoadersStateReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(cleanUpGeneralSettingsLoader, (state, action) => {
    if (!!state?.dataLanguage?.generalSettings) {
      state.dataLanguage.generalSettings.isLoading = undefined;
    }
    return state;
  });
}
function buildSetDisplayCurrencyReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(setDisplayCurrency, (state, action) => {
    if (!!state.dataLanguage?.generalSettings?.chosenCurrency) {
      state.dataLanguage.generalSettings.chosenCurrency =
        action?.payload?.chosenCurrency;
    }
    return state;
  });
}

function buildSetDisplayUpdateButtonReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(displayUpdateButton, (state, action) => {
    if (!!state.dataLanguage) {
      state.dataLanguage.displayUpdate = true;
    }
    return state;
  });
}

function buildCleanSavedIntegration(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(cleanSavedIntegration, (state, action) => {
    state.integrationNameChangeLoader = {
      loading: false,
      rowId: undefined,
    };
    return state;
  });
}
function buildCreateMarketingMappingLocal(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(createMarketingMappingElementLocal, (state, action) => {
    if (!!state.dataLanguage?.marketingMapping?.elements) {
      state.dataLanguage.marketingMapping.elements = [
        ...state.dataLanguage.marketingMapping.elements,
        action?.payload?.data,
      ];
    }

    return state;
  });
}
function buildNewMarketingMapping(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder.addCase(newMarketingMapping, (state, action) => {
    if (!!state.dataLanguage) {
      state.dataLanguage["marketingMapping"] = { elements: [] };
      state.dataLanguage["marketingMapping"]["elements"] =
        action?.payload?.data;
    }

    return state;
  });
}

// fetch organization
function buildFetchOrganizationReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(fetchOrganization.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(fetchOrganization.fulfilled, (state, action: any) => {
      console.log(action)
      state.error = undefined;
      state.loading = false;
      state.organization = action?.payload;
      if(action?.payload?.image?.buffer && !!state?.organization){
        const data = action?.payload?.image?.buffer?.data;
        const base64 = Buffer.from(data).toString('base64');

        state.organization.image = `data:image/jpeg;base64,${base64}`
      }
      if(!!state?.organization){
        state.organization.holidays = [];
      }
      return state;
    })
    .addCase(fetchOrganization.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    });
}
//fetch holidays
function buildFetchHolidays(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(fetchHolidays.pending, (state, action) => {
      if (!!state?.organization?.holidays) {
        state.organization.holidays = [];
      }
      return state;
    })
    .addCase(fetchHolidays.fulfilled, (state, action) => {
      if (!!state?.organization?.holidays) {
        state.organization.holidays = action?.payload;
      }
      return state;
    })
    .addCase(fetchHolidays.rejected, (state, action) => {
      if (!!state?.organization?.holidays) {
        state.organization.holidays = [];
      }
      return state;
    });
}
// get marketing elements
function buildFetchMarketingElements(builder: ActionReducerMapBuilder<any>) {
  builder
    .addCase(fetchMarketingMappingElementsView.pending, (state, action) => {
      return state;
    })
    .addCase(fetchMarketingMappingElementsView.fulfilled, (state, action) => {
      if (!!state.dataLanguage) {
        state.dataLanguage["marketingMapping"] = { elements: [] };
        state.dataLanguage["marketingMapping"]["elements"] = action?.payload;
      }
      return state;
    })
    .addCase(fetchMarketingMappingElementsView.rejected, (state, action) => {
      return state;
    });
}
//fetch custom cogs
function buildFetchCustomCogsReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(fetchCustomCogs.pending, (state, action) => {
      state.error = undefined;
      return state;
    })
    .addCase(fetchCustomCogs.fulfilled, (state, action) => {
      if (!!state?.organization) {
        state.organization.customCogs = action?.payload;
      }
      return state;
    })
    .addCase(fetchCustomCogs.rejected, (state, action) => {
      state.error = undefined;
      return state;
    });
}
//fetch custom data
function buildFetchCustomCogsDataReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(fetchCustomCogsData.pending, (state, action) => {
      state.error = undefined;
      return state;
    })
    .addCase(fetchCustomCogsData.fulfilled, (state, action) => {
      if (!!state?.organization) {
        state.organization.customCogsColumns = action?.payload;
      }
      return state;
    })
    .addCase(fetchCustomCogsData.rejected, (state, action) => {
      state.error = undefined;
      return state;
    });
}
function buildTriggerAirflowReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(triggerAirflow.pending, (state, action) => {
      state.error = undefined;
      return state;
    })
    .addCase(triggerAirflow.fulfilled, (state, action) => {
      if (!!state?.dataLanguage?.displayUpdate) {
        state.dataLanguage.displayUpdate = false;
      }
      return state;
    })
    .addCase(triggerAirflow.rejected, (state, action) => {
      state.error = undefined;
      return state;
    });
}
// fetch data language
function buildFetchDataLanguageReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(fetchDataLanguage.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(fetchDataLanguage.fulfilled, (state, action) => {
      const deepCopy = JSON.parse(JSON.stringify(action?.payload));
      const changeTopKpiOrder = (key: string) => {
        if ("topKpis" in deepCopy && key in deepCopy["topKpis"]) {
          const newOrder = kpiOrder
            ?.map((kpi) => {
              if (deepCopy.topKpis[key]?.includes(kpi)) {
                return kpi;
              } else {
                return;
              }
            })
            ?.filter((item) => !!item);
          deepCopy.topKpis[key] = newOrder;
        }
      };

      changeTopKpiOrder("marketing");
      changeTopKpiOrder("website");
      changeTopKpiOrder("finance");
      changeTopKpiOrder("company");

      state.error = undefined;
      state.loading = false;
      state.dataLanguage = deepCopy;
      return state;
    })
    .addCase(fetchDataLanguage.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    });
}

// create organization
function buildCreateOrganizationReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(createOrganization.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(createOrganization.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = false;
      state.organization = action?.payload;
      state.error = "";
      return state;
    })
    .addCase(createOrganization.rejected, (state, action: any) => {
      const errorMessage = action?.payload?.response?.data?.message;
      state.error = errorMessage;
      state.loading = false;
      return state;
    });
}

// add integration
function buildAddIntegrationReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(addIntegration.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(addIntegration.fulfilled, (state, action) => {
        if (!!state?.organization?.integrations) {
        state.organization.integrations = action.payload.data;
      }

      return state;
    })
    .addCase(addIntegration.rejected, (state, action) => {
      state.error = "Error add integration";
      state.loading = true;
      return state;
    });
}

// add integration
function buildRefreshIntegration(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
      .addCase(refreshIntegration.pending, (state, action) => {
        state.error = undefined;
        return state;
      })
      .addCase(refreshIntegration.fulfilled, (state, action) => {
        state.error = undefined;
        const indexOfIntegration = state?.organization?.integrations?.findIndex((integration) => integration?.id === action?.meta?.arg?.integrationId);
        if(indexOfIntegration !== undefined && indexOfIntegration > -1 && !!state?.organization?.integrations){
          state.organization.integrations[indexOfIntegration]['googleAdsAccounts'] = action?.meta?.arg?.integration['googleAdsAccounts'];
          state.organization.integrations[indexOfIntegration]['facebookAdsAccounts'] = action?.meta?.arg?.integration['facebookAdsAccounts'];
        }

        return state;
      })
      .addCase(refreshIntegration.rejected, (state, action) => {
        return state;
      });
}



// accounts
function buildGetGoogleAdsAccounts(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
      .addCase(getGoogleAdsAccounts.pending, (state, action) => {
        if(!!state?.organization){
          state.organization.googleAdsAccounts = {};
        }
        return state;
      })
      .addCase(getGoogleAdsAccounts.fulfilled, (state, action) => {

        if(!!state.organization){
          state.organization.googleAdsAccounts = {
            integration: action?.meta?.arg?.integration,
            accounts: action?.payload
          };

        }

        return state;
      })
      .addCase(getGoogleAdsAccounts.rejected, (state, action) => {
        return state;
      });
}

function buildGetGoogleAdsAccountsV2(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
      .addCase(getGoogleAdsAccountsV2.pending, (state, action) => {
        if(!!state?.organization){
          state.organization.googleAdsAccounts = {};
          state.organization.facebookAdsAccounts = {};
          state.organization.isLoadingAccountModal = true;
          state.organization.isOpenAccountsModal = true;
        }
        return state;
      })
      .addCase(getGoogleAdsAccountsV2.fulfilled, (state, action) => {

        if(!!state.organization){
          state.organization.googleAdsAccounts = {
            accounts: action?.payload?.accounts,
            object: action?.payload?.object
          };
          state.organization.isLoadingAccountModal = false;


        }

        return state;
      })
      .addCase(getGoogleAdsAccountsV2.rejected, (state, action) => {
        if(!!state.organization) {
          state.organization.isLoadingAccountModal = false;
        }
        return state;
      });
}

function buildGetFacebookAdsAccountsV2(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
      .addCase(getFacebookAdsAccountsV2.pending, (state, action) => {
        if(!!state?.organization){
          state.organization.googleAdsAccounts = {};
          state.organization.facebookAdsAccounts = {};
          state.organization.isOpenAccountsModal = true;
          state.organization.isLoadingAccountModal = true;
        }
        return state;
      })
      .addCase(getFacebookAdsAccountsV2.fulfilled, (state, action) => {

        if(!!state.organization){
          state.organization.facebookAdsAccounts = {
            accounts: action?.payload?.accounts,
            object: action?.payload?.object
          };
          state.organization.isLoadingAccountModal = false;


        }

        return state;
      })
      .addCase(getFacebookAdsAccountsV2.rejected, (state, action) => {
        if(!!state.organization) {
          state.organization.isLoadingAccountModal = false;
        }
        return state;
      });
}

function buildGetFacebookAdsAccounts(
    builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
      .addCase(getFacebookAdsAccounts.pending, (state, action) => {
        if(!!state?.organization){
          state.organization.facebookAdsAccounts = {}
        }
        return state;
      })
      .addCase(getFacebookAdsAccounts.fulfilled, (state, action) => {
        if(!!state.organization){
          state.organization.facebookAdsAccounts = {
            integration: action?.meta?.arg?.integration,
            accounts: action?.payload
          }

        }

        return state;
      })
      .addCase(getFacebookAdsAccounts.rejected, (state, action) => {
        return state;
      });
}


function buildCreatePnlElement(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(createPnlElement.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.isPostLoading = {
          isPostRequestLoading: true,
          isPostRequestSuccess: undefined,
        };
      }
      return state;
    })
    .addCase(createPnlElement.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.elements.push(action?.payload);
        state.dataLanguage.pnlSettings.isPostLoading = {
          isPostRequestLoading: false,
          isPostRequestSuccess: true,
        };
      }
      return state;
    })
    .addCase(createPnlElement.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.isPostLoading = {
          isPostRequestLoading: false,
          isPostRequestSuccess: false,
        };
      }
      return state;
    });
}
// change integration name
function buildChaneIntegrationNameReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(changeIntegrationName.pending, (state, action) => {
      const indexOfIntegration = state?.organization?.integrations?.findIndex((integration) => integration?.id === action.meta.arg?.integrationId);
      if(indexOfIntegration !== undefined && indexOfIntegration > -1 && !!state?.organization && !!state?.organization?.integrations){
        state.organization.integrations[indexOfIntegration]['name'] =  action.meta.arg?.name;
      }
      state.integrationNameChangeLoader = {
        rowId: action.meta.arg?.integrationId,
        loading: true,
      };
      return state;
    })
    .addCase(changeIntegrationName.fulfilled, (state, action) => {
      const indexOfIntegration = state?.organization?.integrations?.findIndex((integration) => integration?.id === action.meta.arg?.integrationId);
      if(indexOfIntegration !== undefined && indexOfIntegration > -1 && !!state?.organization && !!state?.organization?.integrations){
        state.organization.integrations[indexOfIntegration]['name'] =  action.meta.arg?.name;
      }
      state.integrationNameChangeLoader = {
        rowId: action.meta.arg?.integrationId,
        loading: false,
      };
      return state;
    })
    .addCase(changeIntegrationName.rejected, (state, action) => {
      state.integrationNameChangeLoader = {
        rowId: action.meta.arg?.integrationId,
        loading: false,
      };
      return state;
    });
}

// invite user
function buildInviteUser(builder: ActionReducerMapBuilder<OrganizationState>) {
  builder
    .addCase(inviteUser.pending, (state, action) => {
      state.error = undefined;
      state.pendingLoader = true;
      return state;
    })
    .addCase(inviteUser.fulfilled, (state, action) => {
      state.error = undefined;
      state.pendingLoader = false;
      state.organization = action?.payload;
      return state;
    })
    .addCase(inviteUser.rejected, (state, action) => {
      state.error = undefined;
      state.pendingLoader = false;
      return state;
    });
}
/* function buildDeleteInvitePending(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(deleteUserPending.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(deleteUserPending.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = true;
      state.organization = action?.payload;
      return state;
    })
    .addCase(deleteUserPending.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    });
} */

// change currency and time
function buildChangeCurrencyAndTime(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(changeCurrencyAndTime.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.generalSettings) {
        state.dataLanguage.generalSettings.isLoading = {
          isLoad: true,
          isSuccess: undefined,
        };
      }
      return state;
    })
    .addCase(changeCurrencyAndTime.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (
        !!state?.dataLanguage?.generalSettings &&
        !!action.payload.generalSettings
      ) {
        state.dataLanguage.generalSettings = action?.payload.generalSettings;
      }
      if (!!state?.dataLanguage?.generalSettings) {
        state.dataLanguage.generalSettings.isLoading = {
          isLoad: false,
          isSuccess: true,
        };
      }
      return state;
    })
    .addCase(changeCurrencyAndTime.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.generalSettings) {
        state.dataLanguage.generalSettings.isLoading = {
          isLoad: false,
          isSuccess: false,
        };
      }
      return state;
    });
}

function buildEditPnlSettings(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(editPnlSettings.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.isPutLoading = {
          isPutRequestLoading: true,
          isPutRequestSuccess: undefined,
        };
      }
      return state;
    })
    .addCase(editPnlSettings.fulfilled, (state, action) => {
      state.error = undefined;
      if (!!state?.dataLanguage) {
        state.dataLanguage = action.payload;
      }
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.isPutLoading = {
          isPutRequestLoading: false,
          isPutRequestSuccess: true,
        };
      }
      return state;
    })
    .addCase(editPnlSettings.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage?.pnlSettings) {
        state.dataLanguage.pnlSettings.isPutLoading = {
          isPutRequestLoading: false,
          isPutRequestSuccess: false,
        };
      }
      return state;
    });
}

// delete integration
function buildDeleteIntegrationReducer(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(deleteIntegration.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    })
    .addCase(deleteIntegration.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = true;
      state.organization = action?.payload;
      return state;
    })
    .addCase(deleteIntegration.rejected, (state, action) => {
      state.error = undefined;
      state.loading = true;
      return state;
    });
}

// change topkpi
function buildChangeTopKpi(
  builder: ActionReducerMapBuilder<OrganizationState>
) {
  builder
    .addCase(assignTopKpi.pending, (state, action) => {
      state.loading = true;
      return state;
    })
    .addCase(assignTopKpi.fulfilled, (state, action) => {
      state.error = undefined;
      state.loading = true;
      if (!!state?.dataLanguage) {
        state.dataLanguage.topKpis = action?.payload.topKpis;
      }

      return state;
    })
    .addCase(assignTopKpi.rejected, (state, action) => {
      state.loading = true;
      return state;
    });
}

// slice

export const organizationSlice = createSlice({
  name: "organization",
  initialState: initialOrganizationState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildCleanOrganizationStateReducer(builder);

    buildDeletePnlElement(builder);

    buildCreateMarketingMappingLocal(builder);

    buildCleanGeneralSettingsLoadersStateReducer(builder);

    buildNewMarketingMapping(builder);

    buildCleanPnlLoadersStateReducer(builder);

    buildCleanSavedIntegration(builder);

    buildSetDisplayCurrencyReducer(builder);

    buildSetDisplayUpdateButtonReducer(builder);

    buildCloseAccountsModal(builder);

    // async
    // fetch organization
    buildFetchOrganizationReducer(builder);

    // create organization
    buildCreateOrganizationReducer(builder);

    buildCreatePnlElement(builder);

    // add integration
    buildAddIntegrationReducer(builder);

    // loader for name change
    buildChaneIntegrationNameReducer(builder);

    // delete integration
    buildDeleteIntegrationReducer(builder);

    //change time zone and currency
    buildChangeCurrencyAndTime(builder);

    //holidays
    buildFetchHolidays(builder);

    buildChangeTopKpi(builder);

    buildInviteUser(builder);

    buildFetchCustomCogsReducer(builder);

    buildFetchCustomCogsDataReducer(builder);

    buildEditPnlSettings(builder);

    buildFetchDataLanguageReducer(builder);

    buildFetchMarketingElements(builder);

    buildTriggerAirflowReducer(builder);

    buildGetGoogleAdsAccounts(builder);

    buildGetFacebookAdsAccounts(builder);

    buildRefreshIntegration(builder);

    buildGetGoogleAdsAccountsV2(builder);

    buildGetFacebookAdsAccountsV2(builder);

    /* buildChaneIntegrationNameReducer(builder); */
  },
});

// reducer
export const organizationReducer = organizationSlice.reducer;

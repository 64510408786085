import styles from "./styles";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  MoreInfoDialog,
} from "../../../dialogs/more-info-dialog/MoreInfoDialog";
import { useDispatch, useSelector } from "react-redux";
import { MoreInfoButton } from "../../more-info-button/MoreInfoButton";
import {
    ExploresEnum,
    isDefaultExploreOpenSelector,
    isSecondaryApplyBarOpenSelector,
    isSecondaryApplyModalOpenSelector,
    isSecondaryExploreOpenSelector, openDefaultExploreView, openSecondaryApplyBar, openSecondaryApplyModal,
    openSecondaryExplore
} from "../../../store/ui";
import {Box, Flex, useDisclosure} from "@chakra-ui/react";
import TransactionFeesTable from "./TransactionFeesTable";
import {
    createTransactionFees, fetchPayments,
    fetchTransactionFees,
    Payment,
    paymentsSelector, paymentsStateSelector,
    TransactionFee,
    transactionFeesSelector
} from "../../../store/payments";
import {organizationSelector} from "@store";
import ApplyChangesBar from "../../apply-changes-bar/ApplyChangesBar";
import {cleanUpCustomTargetsLoaders} from "../../../store/kpis";
import {ApplyChangesModal} from "../../apply-changes-modal/ApplyChangesModal";


export interface PaymentMethodTableRow {
    wvChannelId?: string;
    subRows: {
      paymentMethodName?: string;
      paymentMethodId?: string;
      amount?: number;
      wvChannelId: string;
      percentage?: number;
    }[]
}

export const TransactionFeesExplore = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const allPaymentMethods = useSelector(paymentsSelector);
    const allTransactionFees = useSelector(transactionFeesSelector);
    const isSecondaryApplyModalOpen = useSelector(isSecondaryApplyModalOpenSelector);
    const isSecondaryApplyBarOpen = useSelector(isSecondaryApplyBarOpenSelector);
    const organization = useSelector(organizationSelector);
    const [paymentMethodsTableData, setPaymentMethodsTableData] = useState<PaymentMethodTableRow[]>([]);
    const defaultExplore = useSelector(isDefaultExploreOpenSelector);
    const isSecondaryExploreOpen = useSelector(isSecondaryExploreOpenSelector)?.isSecondaryExploreOpen
    const isFinishedLoading = useSelector(paymentsStateSelector)?.finishedSavingTransactionFees;

    useEffect(() => {
        if (organization?.id) {
            dispatch(fetchPayments({ organizationId: organization?.id }));
        }
    }, [organization?.id]);

    useEffect(() => {
        dispatch(openSecondaryApplyBar({isApplyBarOpen: false}))
        dispatch(openSecondaryApplyModal({isApplyModalOpen: false}))
        if(!!organization?.id){
            dispatch(fetchTransactionFees({organizationId: organization?.id}))
        }
    } ,[organization?.id, isSecondaryExploreOpen])

    useEffect(() => {
        if(!!allPaymentMethods && !!allTransactionFees){
            formatPaymentMethodsDataToTable(allPaymentMethods, allTransactionFees)
        }
    }, [allPaymentMethods, allTransactionFees, isSecondaryExploreOpen]);

    const groupPaymentMethodsByWvChannelId = (paymentMethods: Payment[]) => {
        const groupedData: { [key: string]: Payment[] } = {};

        for (const payment of paymentMethods) {
            const wvChannelId = payment.wvChannelId ?? "undefined";
            const { wvChannelId: _, ...rest } = payment;
            if (!groupedData[wvChannelId]) {
                groupedData[wvChannelId] = [];
            }
            groupedData[wvChannelId].push({ ...rest });
        }
        return groupedData
    }

    const convertToPaymentMethodTableRow = (groupedPaymentData: { [key: string]: any[] }, transactionFees: TransactionFee[] ):
        PaymentMethodTableRow[] => {
        const paymentMethodTableRows: PaymentMethodTableRow[] = [];
        for (const key of Object.keys(groupedPaymentData)) {
            const wvChannelId = key === "undefined" ? undefined : key;
            const subRows = groupedPaymentData[key].map((item: any) => {
                const foundTransactionFeeForPaymentMethod = transactionFees?.find((transactionFee) =>
                    transactionFee?.wvPaymentMethodId === item?.id && transactionFee?.wvChannelId === wvChannelId )
                return {
                    paymentMethodName: item.name,
                    paymentMethodId: item?.id,
                    amount: foundTransactionFeeForPaymentMethod?.amount ?? 0,
                    percentage: foundTransactionFeeForPaymentMethod?.percentage ?? 0,
                    wvChannelId: key,

                };
            });
            paymentMethodTableRows.push({ wvChannelId, subRows });
        }
        return paymentMethodTableRows;
    };

    const formatPaymentMethodsDataToTable = (paymentMethods: Payment[], transactionFees: TransactionFee[]) => {
        const groupedPaymentMethodsByChannel = groupPaymentMethodsByWvChannelId(paymentMethods);
        const paymentMethodDataAsTableRow = convertToPaymentMethodTableRow(groupedPaymentMethodsByChannel, transactionFees)
        setPaymentMethodsTableData(paymentMethodDataAsTableRow)
    };

    const handleUpdateCell = ({
                                  wvChannelId,
                                  paymentMethodId,
                                  value,
                                  isPercentageInput,
                              }: {
        wvChannelId: string;
        paymentMethodId: string;
        value: number;
        isPercentageInput: boolean;
    }) => {

        const updatedPaymentMethodTableRows = paymentMethodsTableData.map((row) => {
            if (row.wvChannelId === wvChannelId) {
                const updatedSubRows = row.subRows.map((subRow) => {
                    if (subRow.paymentMethodId === paymentMethodId) {
                        if(isPercentageInput){
                            return {
                                ...subRow,
                                percentage: value
                            }
                        } else {
                            return {
                                ...subRow,
                                amount: value,
                            };
                        }
                    }
                    return subRow;
                });

                return {
                    ...row,
                    subRows: updatedSubRows,
                };
            }
            return row;
        });
        setPaymentMethodsTableData(updatedPaymentMethodTableRows);
        dispatch(openSecondaryApplyBar({isApplyBarOpen: true}))
    };

    const handleUpdateParentCell = ({
                                  wvChannelId,
                                  value,
                                  isPercentageInput,
                              }: {
        wvChannelId: string;
        value: number;
        isPercentageInput: boolean;
    }) => {

        const updatedPaymentMethodTableRows = paymentMethodsTableData.map((row) => {
            if (row.wvChannelId === wvChannelId) {
                const updatedSubRows = row.subRows.map((subRow) => {
                    if(isPercentageInput){
                        return {
                            ...subRow,
                            percentage: value
                        }
                    }else {
                        return {
                            ...subRow,
                            amount: value
                        }
                    }
                });

                return {
                    ...row,
                    subRows: updatedSubRows,
                };
            }
            return row;
        });

        setPaymentMethodsTableData(updatedPaymentMethodTableRows);
    };

    const tableRowDataToTransactionFees = (tableRowData: PaymentMethodTableRow[]) : TransactionFee[] => {
        const paymentMethodsWithFees  = tableRowData?.map((row) => {
            const transactionFees: TransactionFee[] =  row?.subRows?.map((subRow) => {
                return {
                    wvChannelId: subRow?.wvChannelId,
                    wvPaymentMethodId: subRow?.paymentMethodId,
                    percentage: subRow?.percentage,
                    amount: subRow?.amount
                }
            })
            return transactionFees
        })?.flat()
        return paymentMethodsWithFees
    }

    const handleOnApply = () => {
        if(!!organization?.id){
            dispatch(createTransactionFees({organizationId: organization?.id, transactionFees: tableRowDataToTransactionFees(paymentMethodsTableData)}))
        }

    }


    const renderHeader = () => {
        return <styles.Header>
            <styles.TitleWrapper>
                <styles.TitleDynamic>
                    Transaction Fees
                </styles.TitleDynamic>
                <MoreInfoButton onClick={() => {}} />

                <styles.HeaderButtonsWrapper>
                        <button onClick={() => {
                            if(isSecondaryApplyBarOpen){
                                dispatch(openSecondaryApplyModal({isApplyModalOpen: true}))
                            }
                            else {
                                if(defaultExplore?.exploreEnum === ExploresEnum.TRANSACTION_FEES && defaultExplore?.isDefaultExploreOpen){
                                    dispatch(openDefaultExploreView({isOpen: false}))
                                }
                                dispatch(openSecondaryExplore({ isOpen: false }))
                            }
                        }}>
                            <IconCloseOverlay />
                        </button>
                </styles.HeaderButtonsWrapper>
            </styles.TitleWrapper>
            <styles.SubTitle>
                {t('DRILL_DOWN_TO_THE_MAIN_DRIVERS_OF_THIS_KPI_(OVER_TIME,_CHANNELS,_SUPPORTING_KPIS)_AND_ASSIGN_AN_OWNER_TO_THIS_TASK_ACCORDINGLY.', { ns: 'translation' })}
            </styles.SubTitle>
        </styles.Header>;
    };

    const renderTransactionFeesTable = () => {
        return ( <styles.Card>
                {/*<Box maxH={'63vh'} overflowY={'auto'}>*/}
                    <TransactionFeesTable updateParentCell={handleUpdateParentCell} updateCell={handleUpdateCell} paymentMethodsRows={paymentMethodsTableData}/>
                {/*</Box>*/}
                {/*<Flex justifyContent={'flex-end'} w={'96%'}>
                    <styles.ApplyButton onClick={handleOnApply}>
                        Apply
                    </styles.ApplyButton>
                </Flex>*/}

        </styles.Card>
        )
    }



    return (
        <styles.Wrapper>
            {isSecondaryApplyBarOpen && (
                <ApplyChangesBar
                    finishedLoading={isFinishedLoading}
                    onClose={() => {
                    }}
                    onApplyChanges={() => handleOnApply()}
                    title={"Update Transaction Fees"}
                />
            )}
            <ApplyChangesModal
                onApply={() => handleOnApply()}
                isOpen={isSecondaryApplyModalOpen}
                onOpen={onOpen}
                onClose={onClose}
            />
            {renderHeader()}
            <Box h={'35px'}/>
            {renderTransactionFeesTable()}
        </styles.Wrapper>
    );
};

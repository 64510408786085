import styled from "styled-components";
import { ModalContent, ModalHeader } from "@chakra-ui/react";

export namespace styles {
  export const Title = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-weight: bold;
    font-size: 21px;
    color: #000000;
    display: flex;
    align-items: center;
  `;
  export const StyledHeader = styled(ModalHeader)`
    background-color: #fbf7ff;
    border-radius: 10px 10px 0 0;
  `;
  export const StyledInput = styled.input<{ small?: boolean }>`
    border: 1px solid #b5b5b5;
    width: ${(props) => (props?.small ? "40%" : "100%")};
    height: 40px;
    border-radius: 13px;
    padding: 10px;

    &::placeholder {
    }

    &:focus {
      outline: none !important;
      border: 1px solid #7031eb !important;
    }

    &:active {
      outline: none !important;
      border: 1px solid #7031eb !important;
    }
  `;
  export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #7a86a1;
    width: 100%;
  `;
  export const LabelWrapper = styled.label`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  `;
  export const StyledSubmitButton = styled.button`
    background-color: ${(props) => props.theme.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 138px;
    color: white;
    height: 43px;
  `;
  export const StyledCancelButton = styled.button`
    color: ${(props) => props.theme.primaryColor};
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 103px;
    height: 43px;
  `;
}

export default styles;

import styles from "./styles";
import {TopKpis} from "./top-kpis/TopKpis";
import {DepartmentsOverview} from "./departments-overview/DepartmentsOverview";
import {DepartmentsTopKpis} from "./departments-top-kpis/DepartmentsTopKpis";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {
    fetchHolidays,
    organizationDataLanguageSelector,
    organizationLoadingSelector,
    organizationSelector
} from "@store";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchBigWideviewCardsData,
    kpiDataSelector,
    kpiMarketingSelector,
    kpiStateSelector
} from "store/kpis";
import {dateRangeSelector, ExploresEnum, openDefaultExploreView, selectedChannelsSelector} from "store/ui";
import {KpisEnum} from "models/kpi-settings";
import {sumData} from "utils/get-months/getLastYear";
import {channelsStateSelector} from "../../store/channels";

// import {useTranslation} from "react-i18next";

interface TopKpis {
    website: number[];
    marketing: number[];
    finance: number[];
    company: number[];
}

export const WideViewsOrganization = () => {
    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const [topKpis, setTopKpis] = useState<TopKpis>();

    const organizationId = useSelector(organizationSelector);
    const dispatch = useDispatch();
    const marketingSelector = useSelector(kpiMarketingSelector);
    const kpiDailySelector = useSelector(kpiDataSelector);
    const organizationLoader = useSelector(organizationLoadingSelector);
    const kpis = useSelector(kpiStateSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const dateSelector = useSelector(dateRangeSelector);
    const [marketingTargets, setMarketingTargets] = useState(0);
    const [financeTargets, setFinanceTargets] = useState(0);
    const [websiteTargets, setWebsiteTargets] = useState(0);
    const [filteredData, setFilteredData] = useState<{ targets: any[], daily: any[] }>();
    const selectedChannels = useSelector(selectedChannelsSelector);
    const channels = useSelector(channelsStateSelector);

    useEffect(() => {
        console.log('here4')
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const kpiEnum = params.get('explore');
        if (!!kpiEnum && !!!organizationLoader && !!kpis.loading) {
            setTimeout(() => {
                dispatch(openDefaultExploreView({isOpen: true, ExploresEnum: ExploresEnum[kpiEnum]}));
            }, 1500);
        }
    }, [dispatch, kpis.loading, organizationLoader]);

    useEffect(() => {
        console.log('here5')
        if (!!selectedChannels && !!kpis?.data?.data && !!kpis?.targetData?.data) {
            const dailyStats = kpis?.data?.data.filter((item) => selectedChannels.includes(item.wvChannelId));
            const targetStats = kpis?.targetData?.data.filter((item) => selectedChannels.includes(item.wvChannelId));
            setFilteredData({targets: targetStats, daily: dailyStats});
        }
    }, [kpis?.data?.data, selectedChannels, kpis?.targetData?.data])

    useEffect(() => {
        console.log('here6')
        if (!!organizationId?.id && !!dateSelector?.endDate && !!dateSelector?.startDate && !!selectedChannels && selectedChannels?.length > 0) {
            dispatch(
                fetchBigWideviewCardsData({
                    organizationId: organizationId?.id,
                    fromDate: dateSelector?.startDate,
                    toDate: dateSelector?.endDate,
                    channels: selectedChannels,
                })
            );
        }
    }, [dateSelector?.endDate, dateSelector?.startDate, organizationId?.id, organizationId?.dataLanguage, selectedChannels]);

    // useEffect(() => {
    //     if (!!dataLanguage?.topKpis) {
    //         setTopKpis(dataLanguage?.topKpis);
    //     }else{
    //         setTopKpis(dataLanguage?.topKpis);
    //     }
    // }, [organizationId, dataLanguage, dataLanguage?.topKpis?.company, dataLanguage?.topKpis?.marketing, dataLanguage?.topKpis?.website, dataLanguage?.topKpis?.finance]);



    // helpers


    // helpers


    // renderers

    return (
        <styles.Wrapper>
            <styles.SmallerWrapperBackground>


                <>
                    {/*<TopKpis topKpis={topKpis} />*/}
                    {/*<div style={{minHeight: '22px'}}/>*/}
                    {/*{<DepartmentsOverview marketingTarget={marketingTargets} websiteTarget={websiteTargets}
                                          financeTarget={financeTargets}/>}*/}
                    {/*<div style={{minHeight: '18px'}}/>*/}

                    <DepartmentsTopKpis marketingTarget={marketingTargets}
                                        websiteTarget={websiteTargets} financeTarget={financeTargets} doNotDisplayCard
                                        topKpis={topKpis}/>
                </>
                {/* <styles.LoaderWrapper>


                    <styles.Loader />
                </styles.LoaderWrapper> */}


            </styles.SmallerWrapperBackground>
        </styles.Wrapper>
    );
};

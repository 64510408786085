import storeIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform,
    IntegrationType
} from "@models";
import { IntegrationButton } from "../../../components/integration-button";
import {amazonSellerUri, collectorsBaseUrl} from "@constants";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import { addIntegration, organizationSelector } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { getAmazonSellerRefreshToken } from "../../../store/amazon-seller";
import {useEffect, useState} from "react";
import { ShopifyNameDialog } from "../../../dialogs/shopify-name-dialog/ShopifyNameDialog";
import { integrationSelector } from "store/integrations";
import {AmazonAdsDialog} from "../../../dialogs/amazon-seller-dialog/AmazonAdsDialog";
import {AmazonSellerDialog} from "../../../dialogs/amazon-ads-dialog/AmazonSellerDialog";
import axios from "axios";
import {ebayUrl} from "../../../constants/integration";

export const StoresIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const integrations = useSelector(integrationSelector);
    const [isShopifyNameDialogOpen, showShopifyNameDialog] = useState(false);
    const [isAmazonRegionDialogOpen, setIsAmazonRegionDialogOpen] = useState(false);
    const [wixUrl, setWixUrl] = useState('')


    useEffect(() => {
        const getAxios = async () => {
            const response =  await axios({
                method: 'get',
                url: `${collectorsBaseUrl}/wix/oauth`

            });
            setWixUrl(response?.data)
        }
        getAxios()

    }, [])

    // helpers
    const getAmazonSellerRefreshTokenFromSearchParams = (params: URLSearchParams) => {
        const spApiOAuthCode = params.get('spapi_oauth_code');
        const state = params.get('state');
        const sellingPartnerId = params.get('selling_partner_id');
        if (!!spApiOAuthCode && !!state && !!sellingPartnerId) {
            console.log('here')
            console.log(state)
            console.log(spApiOAuthCode)
            console.log(sellingPartnerId)
            dispatch(getAmazonSellerRefreshToken({
                code: spApiOAuthCode,
                state: state,
                sellingPartnerId: sellingPartnerId
            }));
        }
    };
    const getWalmartParams = (params: URLSearchParams) => {

    };

    const sendShopifyOAuthFromSearchParams = (params: URLSearchParams) => {
        const shopifyOAuthData = params.get('result');
        try {
            if (!!shopifyOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.STORES,
                        platform: IntegrationPlatform.SHOPIFY,
                        object: JSON.parse(shopifyOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sendWixOAuthFromSearchParams = (params: URLSearchParams) => {
        const wixOAuthData = params.get('result');
        try {
            if (!!wixOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.STORES,
                        platform: IntegrationPlatform.WIX,
                        object: JSON.parse(wixOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sendEbayOAuthFromSearchParams = (params: URLSearchParams) => {
        const ebayOAuthData = params.get('result');
        try {
            if (!!ebayOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.STORES,
                        platform: IntegrationPlatform.EBAY,
                        object: JSON.parse(ebayOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };
    //TO-DO replace before production
    // renderers
    const renderStoreIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('STORES', { ns: 'translation' })}
                </Text>
                <Flex wrap={"wrap"} direction={"row"}>
                    {renderShopifyIntegration()}
                    {renderWooCommerceIntegration()}
                    {renderAmazonSellerIntegration()}
                    {renderMagentoIntegration()}
                    {renderWalmartIntegrations()}
                    {renderWixIntegrations()}
                    {renderEbayIntegrations()}

                </Flex>
            </Flex>
        );
    };

    const renderShopifyIntegration = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.SHOPIFY);
        return (
            <storeIntegrationsStyles.ButtonWrapper onClick={() => showShopifyNameDialog(true)}>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    badgeText={badgeText}
                >

                </IntegrationButton>
            </storeIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderShopifyNameDialog = () => {
        return (
            <ShopifyNameDialog
                isDialogOpen={isShopifyNameDialogOpen}
                onClose={() => showShopifyNameDialog(false)}
                onAuthorization={(params) => sendShopifyOAuthFromSearchParams(params)}
            />
        );
    };
    const renderAmazonSellerDialog = () => {
        return (
            <AmazonSellerDialog
                isDialogOpen={isAmazonRegionDialogOpen}
                onClose={() => setIsAmazonRegionDialogOpen(false)}
                onAuthorization={(params) => getAmazonSellerRefreshTokenFromSearchParams(params!)}
            />
        );
    };

    const renderMagentoIntegration = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.MAGENTO);
        return (
            <storeIntegrationsStyles.ButtonWrapper>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    premium={true}
                    badgeText={badgeText}>
                </IntegrationButton>
            </storeIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderWooCommerceIntegration = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.WOOCOMMERCE);
        return (
            <storeIntegrationsStyles.ButtonWrapper>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    badgeText={badgeText}>
                </IntegrationButton>
            </storeIntegrationsStyles.ButtonWrapper>
        );
    };



    const renderAmazonSellerIntegration = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.AMAZON_SELLER);
        /*return (
            /!*<OAuthPopup
                title={'Amazon Seller'}
                height={800}
                width={1000}
                url={amazonSellerUri}
                onResponse={(params) => {
                    getAmazonSellerRefreshTokenFromSearchParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /!* description={t(incText, { ns: 'translation' })} *!/
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            </OAuthPopup>*!/
        );*/
        return (<storeIntegrationsStyles.ButtonWrapper onClick={() => setIsAmazonRegionDialogOpen(true)}>
            <IntegrationButton
                title={t(title, { ns: 'translation' })}
                /* description={t(incText, { ns: 'translation' })} */
                image={image}
                badgeText={badgeText}>
            </IntegrationButton>
        </storeIntegrationsStyles.ButtonWrapper>)
    };
    const renderWalmartIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.WALMART);
        return (
           /* <OAuthPopup
                title={'Walmart'}
                height={800}
                width={1000}
                url={amazonSellerUri}
                onResponse={(params) => {
                    getWalmartParams(params!);
                }}
                onClose={() => {
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderWixIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.WIX);
        return (
            /*<OAuthPopup
                title={'Wix'}
                height={800}
                width={1000}
                url={wixUrl}
                onResponse={(params) => {
                    sendWixOAuthFromSearchParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    const renderEbayIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.EBAY);
        return (
           /* <OAuthPopup
                title={'Ebay'}
                height={800}
                width={1000}
                url={ebayUrl ?? ''}
                onResponse={(params) => {
                    sendEbayOAuthFromSearchParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };



    return (
        <storeIntegrationsStyles.Wrapper>
            {renderStoreIntegrations()}
            {renderShopifyNameDialog()}
            {renderAmazonSellerDialog()}
        </storeIntegrationsStyles.Wrapper>
    );
};

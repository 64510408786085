import styled from "styled-components";
import {PopoverContent} from "@chakra-ui/react";

export  namespace styles {
    export const StyledPopOverContent = styled(PopoverContent)`
    background-color: white !important;
    &:focus {
      box-shadow: unset !important;
    }
    border-radius: 10px !important;
    width: 176px !important;
    border: 1px solid !important;
    border-color: inherit !important;
    box-shadow: 5px 31px 54px #695f9736 !important;
    z-index: inherit !important;
    font-size: 100% !important;
    font-family: inherit !important;
      overflow: hidden;
      
      
    /* z-index: 99999; */
  `;

    export const ButtonRow = styled.div<{disabled: boolean}>`
      &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '#f1f0f0' : 'rgba(230, 230, 238, 0.5)')}; 
      }
      font-family: ${props => props?.theme?.fonts?.roboto?.regular};
      color: black;
      width: 176px;
      padding: 10px 10px 10px 15px;
      font-size: 13px;
      border-bottom: 1px solid #EBEBEB;
      text-align: start;
      display: flex;
      justify-content: space-between;

      background-color: ${(props) => (props.disabled ? '#f1f0f0' : 'auto')};

    `

    export const DotsButton = styled.button`
    font-size: 16px;
    height: 25px;
    color: black;
    &:hover{
      cursor: pointer;
    }

  `;

    export const Divider = styled.div`
    width: 100% ;
    height: 1px;
    background-color: #EBEBEB;
  `;
}

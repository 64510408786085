import {MoreInfoButton} from 'components/more-info-button/MoreInfoButton';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {TableData} from 'store/website';
import {styles} from './styles';
import {UserAnalysisChart} from './user-analysis-chart/UserAnaylsisChart';
import {UserAnalysisTable} from './user-analysis-table/UserAnalysisTable';
import {UserAnalysisTableNew} from "./user-analysis-table-new/UserAnalysisTableNew";
import {UserAnalysisChartNew} from "./user-analysis-chart-new/UserAnaylsisChartNew";
import jsonData from '../../features/website/website-data.json';

interface Props {
    rows: TableData[];
    userData?:any;
    displayMockData?: boolean;
}


export const UserAnalysis = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [filteredData, setFilteredData] = useState<TableData[]>(props.rows);
    const [chartMockData, setChartMockData] = useState<{name: string, value: number}[]>([])
    useEffect(() => {
        setFilteredData(props.rows);
    }, [props.rows]);

    const tableData = {
        0: props?.userData?.deviceData,
        1: props?.userData?.brandName,
        2: props?.userData?.modelName,
        3: props?.userData?.countryData
    }

    useEffect(() => {
        if(!!props?.userData){
            const currentDisplayData = tableData[selectedTab];
            const mappedData = currentDisplayData?.map((item: any) => {
                if(!!item?.device){
                    if(!!item?.revenue){
                        return {name: item?.device , value: item?.revenue}
                    }else {
                        return {name:  item?.device , value: 0}
                    }
                }
                else {
                    return
                }
            })?.filter((item: any) => !!item);
            setChartMockData(mappedData);
        }
    }, [props?.userData, selectedTab, props?.displayMockData])

    return (
        <styles.WrapperTable>
            <styles.Card>
                <styles.Table>
                    <styles.Title>
                        {t('USER_ANALYSIS', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => { }} />
                    </styles.Title>
                    { props.rows !== undefined ?
                        <UserAnalysisTable
                            selectedTab={selectedTab}
                            setSelectedTab={(selectedTab: number) => setSelectedTab(selectedTab)}
                            rows={props.rows}
                            setFilteredData={setFilteredData}
                            displayMockData={props?.displayMockData}
                        /> : <></>}
                </styles.Table>
                <styles.Chart>
                    {!!filteredData && <UserAnalysisChart rows={filteredData!} selectedTab={selectedTab} /> }
                </styles.Chart>
            </styles.Card>

        </styles.WrapperTable>
    );
};

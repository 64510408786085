import styled from 'styled-components';
import tinycolor from "tinycolor2";

export namespace styles {

    export const TargetBadge = styled.div<{ color?: string, tiny?: boolean }>`
      background-color: ${props => tinycolor(props.color).setAlpha(0.21).toHex8String()};
      border-radius: 15px;
      height: max-content;
      padding: 5px 10px;
      min-width: ${props => props?.tiny ? "85px" : "105px"};
      max-width: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    export const TargetText = styled.span<{ color?: string, fontSize?: number }>`
      color: ${props => props.color};
      font-size:  ${props => props?.fontSize ? props?.fontSize + "px" : `min(calc(4px + 0.8vw), 12px)`};
      text-align: center;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: block;
      line-height: 1em;
      max-height: 1em;
    `;

}

export default styles;

import styled from "styled-components";
import { Button } from "@chakra-ui/react";
import { Props } from "../../components/auth-button";

export const Wrapper = styled.div<{ img: string }>`
  background: url(${(props) => props.img})
    ${(props) => props.theme.primaryColor};
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LottieHodler = styled.div`
  &:hover {
    cursor: default;
  }
`;
export const BackgroundHolder = styled.div`
  position: absolute;
  top: 0;
  right: 40.5px;
`;

// content
export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const SignOut = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.primaryColor};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
export const Card = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 450px;
  min-height: 300px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

export const PrimaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-weight: bold;
  font-size: larger;
  margin: 65px 0 0 0;
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fonts.header};
`;

export const SecondaryText = styled.span`
  font-size: 14px;
  font-weight: normal;

  color: gray;
`;

export const VerifyButton = styled(Button)<Props>`
  background-color: ${(props) => props.theme.primaryColor}!important;
  border-radius: 20px !important;
  border: ${(props) => {
    return `1px solid ${props.theme.borderColor}`;
  }};

  > a {
    color: white !important;
    > img {
      height: 18px;
      width: auto;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    padding: 2px 18px;
    background: linear-gradient(
      180deg,
      rgba(233, 231, 250, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    border-radius: 0 0 10px 10px;
  `;

  export const LeftSideWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  `;

  export const Title = styled.div`
    display: flex;
    font-size: 21px;
    font-weight: bold;
    padding-top: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fonts.header};
    border-color: ${(props) => props.theme.backgroundColor};
  `;
  export const ChecklistContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: end;
    border-radius: 10px 10px 0 0;
    border-top: solid 12px ${(props) => props.theme.primaryColor};
    margin-top: 20px;
  `;
  export const TodoPostion = styled.div`
    position: absolute;
    top: 90px;
    z-index: 5;
    right: 5.3vw;
  `;
}

export default styles;

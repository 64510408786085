import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {GoogleState} from "./state";

// sign-up state
export const googleStateSelector = (state: RootState): GoogleState => state.google;

// code
export const codeSelector = createSelector(googleStateSelector, (state) => state.code);

// sign-up error
export const googleErrorSelector = createSelector(googleStateSelector, (state) => state.error);

// loading
export const googleLoadingSelector = createSelector(googleStateSelector, (state) => state.loading);

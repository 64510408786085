import styled from "styled-components";
import {PopoverContent, Portal} from "@chakra-ui/react";

export namespace styles {

    export const Header = styled.div`
      font-weight: bold;
      border-bottom: unset!important;
      padding: 10px 10px;
    `;

    export const TooltipBody = styled.div<{small?: boolean}>`
      padding: 5px 10px;
      font-weight: 300;
      max-height: 200px;
      overflow: auto;
      font-size: ${props => !!props?.small ? '11px' : 'unset'};;
      display: flex;
      justify-content: ${props => !!props?.small ? `center` : 'unset'};
      /* overflow: auto; */
      /* white-space: pre-wrap; */
      /* word-wrap: break-word; */
      overflow-wrap: anywhere;
    `
    export const StyledPopoverContent = styled(PopoverContent)<{width?: number}>`
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
      z-index: 100 !important;
      font-size: smaller;
      width: ${props => !!props?.width ? `${props.width}px!important` : 'unset'};

      &:focus {
        outline: none!important;
        outline-offset: unset!important;
        box-shadow: none!important;
      }
    `

    export const StyledPortal = styled(Portal)<{id?: string}>`
      z-index: 100 !important;
      & {
        z-index: 100 !important;
      }
    `

}

export default styles;

import {Box, Flex, Tooltip, useTheme} from "@chakra-ui/react";
import {organizationDataLanguageSelector, organizationSelector} from "@store";
import {t} from "i18next";
import {useEffect, useMemo, useState} from "react";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {useDispatch, useSelector} from "react-redux";
import {Cell, Column, Row, useSortBy, useTable} from "react-table";
import {getColorByPercentage} from "utils/colors";
import {currencyOptions} from "utils/currency/currencyOptions";
import {sumData} from "utils/get-months/getLastYear";
import {IconClearFiltersArrow} from "utils/icons/explore";
import {currencyFormat} from "utils/kpi-render/kpiCalculation";
import {styles} from "./styles";
import {BreakdownBuckets, setMarketingTableRows,} from "../../../../store/marketing";
import PlatformChip from "../../../../components/platform-chip/platformChip";
import {IntegrationPlatform} from "../../../../store/account";

interface Props {
    data: any[];
    headers: string[];
    icon?: any;
    title?: string;
    placeholder?: boolean;
}

interface Data {
    col_0: string;
    col_1: number | string;
    col_2: number | string;
    col_3?: number | string;
    col_4: number | string;
    col_5: number | string;
    col_6: number | string;
    col_7: number | string;
    col_8: number | string;
    col_9: number | string;
    col_10: number | string;
    col_11: any;
}

enum SelectedTab {
    PLATFORM,
    ACCOUNT,
    CAMPAIGN,
    AD_SET,
    ADS
}

interface TableData {
    spend: number;
    key: string; // title
    impressions: number;
    clicks: number;
    conversions: number;
    revenue: number;
    cpc: number | string;
    ctr: number | string;
    cpm: number | string;
    cpa: number | string;
    roas: number;
}




export const BreakdownByPlatform = (props: Props) => {
    const [currentPropsData, setCurrentPropsData] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.PLATFORM);
    const [tableData, setTableData] = useState<any>([]);

    const [initalTab, setInitalTab] = useState<SelectedTab>();

    const dispatch = useDispatch();
    const [currentCurrency, setCurrentCurrency] = useState<string>("$");
    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }
    }, [dataLanguage]);


    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const [selectedRowTab1, setSelectedRowTab1] = useState([] as Row<Data>[]);
    const [selectedRowTab2, setSelectedRowTab2] = useState([] as Row<Data>[]);
    const [selectedRowTab3, setSelectedRowTab3] = useState([] as Row<Data>[]);
    const [selectedRowTab4, setSelectedRowTab4] = useState([] as Row<Data>[]);

    const currentRow = {
        [SelectedTab.PLATFORM]: selectedRowTab0,
        [SelectedTab.ACCOUNT]: selectedRowTab1,
        [SelectedTab.CAMPAIGN]: selectedRowTab2,
        [SelectedTab.AD_SET]: selectedRowTab3,
        [SelectedTab.ADS]: selectedRowTab4,
    };
    const setRow = {
        [SelectedTab.PLATFORM]: setSelectedRowTab0,
        [SelectedTab.ACCOUNT]: setSelectedRowTab1,
        [SelectedTab.CAMPAIGN]: setSelectedRowTab2,
        [SelectedTab.AD_SET]: setSelectedRowTab3,
        [SelectedTab.ADS]: setSelectedRowTab4,
    };
    const handleSetRowsInState = (selectedTab: SelectedTab, rows: any[]) => {
        const rowsToState = rows?.map((item) => item?.original?.col_0);
        const allDisplayNames = rowsToState?.map((row) => {
            return props?.data?.find((item) => item[renderNames[selectedTab]] === row)['displayName'][renderNames[selectedTab]];
        })
        switch(selectedTab){
            case SelectedTab.PLATFORM:
                dispatch(setMarketingTableRows({bucket: BreakdownBuckets.PLATFORM, rows: allDisplayNames}))
                break
            case SelectedTab.ACCOUNT:
                dispatch(setMarketingTableRows({bucket: BreakdownBuckets.ACCOUNT, rows: allDisplayNames}))
                break
            case SelectedTab.CAMPAIGN:
                dispatch(setMarketingTableRows({bucket: BreakdownBuckets.CAMPAIGN, rows: allDisplayNames}))
                break
            case SelectedTab.AD_SET:
                dispatch(setMarketingTableRows({bucket: BreakdownBuckets.AD_SET, rows: allDisplayNames}))
                break
            case SelectedTab.ADS:
                dispatch(setMarketingTableRows({bucket: BreakdownBuckets.AD, rows: allDisplayNames}))
                break
        }

    };

    useEffect(() => {
        const filters: {
            platforms: string[],
            campaigns: string[],
            accounts: string[],
            adSets: string[],
            ads: string[],
        } = {
            platforms: [],
            campaigns: [],
            accounts: [],
            adSets: [],
            ads: []
        };

        selectedRowTab0.forEach((row) => {
            filters.platforms.push(row.original.col_0);
        });
        selectedRowTab1.forEach((row) => {
            filters.accounts.push(row.original.col_0);
        });
        selectedRowTab2.forEach((row) => {
            filters.campaigns.push(row.original.col_0);
        });
        selectedRowTab3.forEach((row) => {
            filters.adSets.push(row.original.col_0);
        });
        selectedRowTab4.forEach((row) => {
            filters.ads.push(row.original.col_0);
        });
        let newData = props?.data;

        if (filters.platforms.length !== 0) {
            if (selectedTab === SelectedTab.PLATFORM) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.platforms.includes(item.platform);
            });
        }
        if (filters.accounts.length !== 0) {
            if (selectedTab === SelectedTab.ACCOUNT) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.accounts.includes(item.account);
            });

        }
        if (filters.campaigns.length !== 0) {
            if (selectedTab === SelectedTab.CAMPAIGN) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.campaigns.includes(item.campaign);
            });

        }
        if (filters.adSets.length !== 0) {
            if (selectedTab === SelectedTab.AD_SET) {
                setCurrentPropsData(newData);
                return;
            }
            newData = newData.filter((item: any) => {
                return filters.adSets.includes(item.adSet);
            });

        }
        if (filters.ads.length !== 0) {
            if (selectedTab === SelectedTab.ADS) {
                setCurrentPropsData(newData);
                return;
            }else {
            newData = newData.filter((item: any) => {
                return filters.ads.includes(item.adId);
            });
            setCurrentPropsData(newData)
            }

        }
        else {
            setCurrentPropsData(newData);
        }

    }, [selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4, props.data, initalTab, selectedTab]);

    useEffect(() => {
        if (!!selectedRowTab0 && !!selectedRowTab1 && !!selectedRowTab2 && !!selectedRowTab3 && !!selectedRowTab4) {
            if ([...selectedRowTab0, ...selectedRowTab1, ...selectedRowTab2, ...selectedRowTab3, ...selectedRowTab4].length === 1) {
                const currentRow = [...selectedRowTab0, ...selectedRowTab1, ...selectedRowTab2, ...selectedRowTab3, ...selectedRowTab4][0];
                const cell = currentRow.cells.filter((item) => item.column.Header === "Tab")[0];
                setInitalTab(cell?.value);
            }
        }
    }, [selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4]);

    useEffect(() => {
        if (!!props?.data) {
            setCurrentPropsData(props?.data);
        }
    }, [props?.data]);



    useEffect(() => {
        const dataToRenderByTab = {
            [SelectedTab.PLATFORM]: 'platform',
            [SelectedTab.CAMPAIGN]: 'campaign',
            [SelectedTab.ACCOUNT]: 'account',
            [SelectedTab.AD_SET]: 'adSet',
            [SelectedTab.ADS]: 'adId',
        };
        sumDataByKey(dataToRenderByTab[selectedTab]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, props?.data, currentPropsData]);



    const sumDataByKey = (key: string) => {
        const dataByKeys = {};
        const keysToIgnore = ["platform", "account", "campaign", "adSet", "adId"];
        currentPropsData.forEach((item: any) => {
            if (!!dataByKeys[item[key]]) {
                dataByKeys[item[key]].push(item);
            }
            else {
                dataByKeys[item[key]] = [item];
            }
        });
        let orders = 0;
        const data = Object.keys(dataByKeys).map((key) => {
            const newData = dataByKeys[key].map((item: any) => { return { ...item, clicks: parseFloat(item?.clicks), impressions: parseFloat(item?.impressions) }; });
            const summedDataByKey = sumData(newData, keysToIgnore);
            orders = orders + summedDataByKey?.orders;
            return {
                cpa: !!summedDataByKey?.orders ? ((summedDataByKey?.spend / summedDataByKey?.orders)) : "-",
                cpm: !!summedDataByKey?.impressions ? ((summedDataByKey?.spend / summedDataByKey?.impressions)) * 1000 : "-",
                ctr: !!summedDataByKey?.impressions ? ((summedDataByKey?.clicks / summedDataByKey?.impressions)) : "-",
                cpc: !!summedDataByKey?.clicks ? ((summedDataByKey?.spend / summedDataByKey?.clicks)) : "-",
                spend: (summedDataByKey?.spend),
                key: key,
                impressions: summedDataByKey?.impressions,
                clicks: summedDataByKey?.clicks,
                revenue: (summedDataByKey?.revenue),
                conversions: summedDataByKey?.orders,
                roas: !!summedDataByKey?.spend && !!summedDataByKey?.revenue ? (summedDataByKey?.revenue / summedDataByKey?.spend) : "-"
            };
        });
        setTableData(data);
    };

    const dataToDataTable = (data: TableData[]): Data[] => {
        if(props?.placeholder){
            return (
                [{
                col_0: "-",
                col_1: "-",
                col_2: "-",
                col_3: "-",
                col_4: "-",
                col_5:"-",
                col_6: "-",
                col_7: "-",
                col_8: "-",
                col_9: "-",
                col_10: "-",
                col_11: selectedTab
                }]
            )
        }
        const minimumItems = 7;
        const newData = data.map((row, index) => {
            return {
                col_0: row?.key,
                col_1: row?.spend,
                col_2: row?.impressions,
                col_3: row?.clicks,
                col_4: row?.cpc,
                col_5: row?.ctr,
                col_6: row?.cpm,
                col_7: row?.cpa,
                col_8: row?.conversions,
                col_9: row?.revenue,
                col_10: row?.roas,
                col_11: selectedTab,
            };
        });

        const remainingItems = Math.max(minimumItems - newData.length, 0);

        for (let i = 0; i < remainingItems; i++) {
            // @ts-ignore
            newData.push({placeholder: true});
        }

        return newData;
    };

    const headersToColumns = (headers: string[]): Column<Data>[] => {
      return headers.map((header, index) => {
        return {
          Header: header,
          accessor: "col_" + index.toString(),
          sortType: (rowA, rowB, id, desc) => {
            console.log(rowA);
            if (rowA.values[id] > rowB.values[id]) {
              return 1;
            }
            if (rowB.values[id] > rowA.values[id]) return -1;
            return 0;
          },
          Footer: (info) => {
            const allSelectedRows = [
              ...selectedRowTab0,
              ...selectedRowTab1,
              ...selectedRowTab2,
              ...selectedRowTab3,
              ...selectedRowTab4,
            ];
            let rows = info.rows;
            if (allSelectedRows?.length > 0) {
              if (
                selectedTab === SelectedTab.PLATFORM &&
                selectedRowTab0?.length > 0
              ) {
                rows = selectedRowTab0;
              } else if (
                selectedTab === SelectedTab.ACCOUNT &&
                selectedRowTab1?.length > 0
              ) {
                rows = selectedRowTab1;
              } else if (
                selectedTab === SelectedTab.CAMPAIGN &&
                selectedRowTab2?.length > 0
              ) {
                rows = selectedRowTab2;
              } else if (
                selectedTab === SelectedTab.AD_SET &&
                selectedRowTab3?.length > 0
              ) {
                rows = selectedRowTab3;
              } else if (
                selectedTab === SelectedTab.ADS &&
                selectedRowTab4?.length > 0
              ) {
                rows = selectedRowTab4;
              }
            }
            const totalSpend = useMemo(() => {
              return rows.reduce((sum, row) => {
                const colValue = parseFloat(row.values.col_1);
                if (!isNaN(colValue)) {
                  return sum + colValue;
                }
                return sum;
              }, 0);
            }, [
              info.rows,
              selectedRowTab0,
              selectedRowTab1,
              selectedRowTab2,
              selectedRowTab3,
              selectedRowTab4,
            ]);
            const totalImpressions = useMemo(() => {
              return rows.reduce((sum, row) => {
                const colValue = parseFloat(row.values.col_2);
                if (!isNaN(colValue)) {
                  return sum + colValue;
                }
                return sum;
              }, 0);
            }, [
              info.rows,
              selectedRowTab0,
              selectedRowTab1,
              selectedRowTab2,
              selectedRowTab3,
              selectedRowTab4,
            ]);
            const totalClicks = useMemo(() => {
              return rows.reduce((sum, row) => {
                const colValue = parseFloat(row.values.col_3);
                if (!isNaN(colValue)) {
                  return sum + colValue;
                }
                return sum;
              }, 0);
            }, [
              info.rows,
              selectedRowTab0,
              selectedRowTab1,
              selectedRowTab2,
              selectedRowTab3,
              selectedRowTab4,
            ]);
            const totalOrders = useMemo(() => {
              return rows.reduce((sum, row) => {
                const colValue = parseFloat(row.values.col_8);
                if (!isNaN(colValue)) {
                  return sum + colValue;
                }
                return sum;
              }, 0);
            }, [
              info.rows,
              selectedRowTab0,
              selectedRowTab1,
              selectedRowTab2,
              selectedRowTab3,
              selectedRowTab4,
            ]);
            const totalRevenue = useMemo(() => {
              return rows.reduce((sum, row) => {
                if (!!parseFloat(row.values.col_9)) {
                  return parseFloat(row.values.col_9) + sum;
                } else {
                  return sum;
                }
              }, 0);
            }, [
              info.rows,
              selectedRowTab0,
              selectedRowTab1,
              selectedRowTab2,
              selectedRowTab3,
              selectedRowTab4,
            ]);
            const totalCpc = !!totalClicks ? totalSpend / totalClicks : "-";
            const totalCtr = !!totalImpressions
              ? totalClicks / totalImpressions
              : "-";
            const totalCpm = !!totalImpressions
              ? (totalSpend / totalImpressions) * 1000
              : "-";
            const totalCpo = !!totalOrders ? totalSpend / totalOrders : "-";
            const totalRoas = !!totalSpend ? totalRevenue / totalSpend : "-";
            switch (header) {
              case "Type":
                return <>Grand Total</>;
              case "Spend":
                return <>{currencyFormat(totalSpend, 2, currentCurrency)}</>;
              case "Impressions":
                return <>{totalImpressions.toLocaleString("en-US")}</>;
              case "Clicks":
                return (
                  <>
                    {totalClicks?.toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })}
                  </>
                );
              case "CPC":
                return <>{currencyFormat(totalCpc, 2, currentCurrency)}</>;
              case "CTR":
                return <>{totalCtr?.toLocaleString("en-US") + "%"}</>;
              case "CPM":
                return <>{currencyFormat(totalCpm, 2, currentCurrency)}</>;
              case "CPO":
                return <>{currencyFormat(totalCpo, 2, currentCurrency)}</>;
              case "Conversions":
                return (
                  <>
                    {totalOrders?.toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })}
                  </>
                );
              case "Revenue":
                return <>{currencyFormat(totalRevenue, 2, currentCurrency)}</>;
              case "ROAS":
                return <>{totalRoas.toLocaleString("en-US")}</>;
              case "Tab":
                return <></>;
            }
            return <>-</>;
          },
        } as Column<Data>;
      });
    };

    const data = useMemo<Data[]>(() => dataToDataTable(tableData), [tableData, props?.placeholder]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props.headers), [props.headers, selectedRowTab0, selectedRowTab1, selectedRowTab2, selectedRowTab3, selectedRowTab4]);

    const rowSelect = (row: any) => {

        const selectedRows = currentRow[selectedTab];

        const isRowSelected = selectedRows.filter(selectedRow => selectedRow.id === row.id);
        if (!!isRowSelected && isRowSelected.length > 0) {
            const rows = [...(selectedRows.filter(selectedRow => selectedRow.id !== row.id))];
            setRow[selectedTab](rows);
            handleSetRowsInState(selectedTab, rows)
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);

            handleSetRowsInState(selectedTab, newSelected)
            setRow[selectedTab](newSelected);
        }
    };

    const TargetBar = ({ actual, target, small }: { actual: number, target: number, small?: boolean; }) => {
        const theme = useTheme();
        const percantge = (actual / target) * 100;
        const color = getColorByPercentage(percantge, theme);
        return (<styles.TargetWrapper small={small}>
            <styles.TargetPercantageWrapper width={percantge} color={color} />
        </styles.TargetWrapper>);
    };
    const renderNames = {
        [SelectedTab.PLATFORM]: "platform",
        [SelectedTab.ACCOUNT]: "account",
        [SelectedTab.CAMPAIGN]: "campaign",
        [SelectedTab.AD_SET]: "adSet",
        [SelectedTab.ADS]: "adId",
    };
    const TableRow = ({ row, i }: { row: any, i: number; }) => {

        const getSelectedRow = () => {
            if (currentRow[selectedTab]?.length === 0) {
                return true;
            }
            else return currentRow[selectedTab]?.some((selectedRows) => selectedRows?.original.col_0 === row.original.col_0);
        };

        return (
            <styles.TrCustom placeholder={row?.original?.placeholder} selected={getSelectedRow()} onClick={() => {
                if(!row?.original?.placeholder){
                    rowSelect(row);
                }
            }} {...row.getRowProps()}>
                {row.cells.map((cell: Cell, i: number) => {

                    if (cell.column.id === "col_11") {
                        return <th style={{ display: "none" }} key={i}></th>;
                    }
                    // @ts-ignore
                    if(cell?.row?.original?.placeholder){
                        return (
                            <styles.StyledTdPlaceholder {...cell.getCellProps()}>
                            </styles.StyledTdPlaceholder>
                        )
                    }
                    if (cell.column.id === "col_0") {
                        const currentCell = props?.data?.find((item) => item[renderNames[selectedTab]] === cell?.value);
                        let platformIntegration;
                        if(currentCell?.platform === 'FacebookAds'){
                            platformIntegration = IntegrationPlatform.FACEBOOK_ADS
                        }
                        if(currentCell?.platform === 'GoogleAds'){
                            platformIntegration = IntegrationPlatform.GOOGLE_ADS
                        }
                        if(currentCell?.platform === 'AmazonAds'){
                            platformIntegration = IntegrationPlatform.AMAZON_ADS
                        }
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            <Tooltip label={currentCell?.displayName[renderNames[selectedTab]]}>
                                <Flex maxW={'100%'} overflow={'hidden '} whiteSpace={'normal'} overflowWrap={'break-word'} alignItems={'center'}>
                                    <PlatformChip platform={platformIntegration}/>
                                    {currentCell?.displayName[renderNames[selectedTab]]}
                                </Flex>
                            </Tooltip>

                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_1") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {currencyFormat(cell?.value, 2, currentCurrency)}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_2") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value?.toLocaleString("en-US")}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_3") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value?.toLocaleString("en-US")}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_4") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {currencyFormat(cell?.value, 2, currentCurrency)}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_5") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value?.toLocaleString("en-US")+"%"}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_6") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {currencyFormat(cell?.value, 2, currentCurrency)}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_7") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value !== "-" ? currentCurrency + cell?.value?.toLocaleString("en-US") : "-"}
                        </styles.StyledTd>);
                    } if (cell.column.id === "col_8") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value?.toLocaleString("en-US")}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_9") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {currencyFormat(cell?.value, 2, currentCurrency)}
                        </styles.StyledTd>);
                    }
                    if (cell.column.id === "col_10") {
                        return (<styles.StyledTd {...cell.getCellProps()}>
                            {cell?.value?.toLocaleString("en-US")}
                        </styles.StyledTd>);
                    }
                    return <styles.StyledTd {...cell.getCellProps()}>

                        {cell.render('Cell')}
                    </styles.StyledTd>;
                })}
            </styles.TrCustom>
        );
    };

    function Table({ columns, data }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            footerGroups,

        } = useTable({
            columns,
            data,
        },
            useSortBy
        );

        return (
            <styles.StyledTable {...getTableProps()}>
                <thead >
                    {headerGroups.map(headerGroup => {
                        return (
                            <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => {
                                    if (column.id === "col_11") {
                                        return <th style={{ display: "none" }} key={i}></th>;
                                    }
                                    return (
                                        <styles.StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <GoTriangleDown />
                                                        : <GoTriangleUp />
                                                    : ''}
                                            </span>
                                        </styles.StyledTh>
                                    );
                                })}
                            </styles.HeaderTr>
                        );
                    })}
                </thead>
                <styles.TbodyCustom {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return <TableRow row={row} key={i} i={i} />;
                    })}
                </styles.TbodyCustom>
                <tfoot>
                    {footerGroups.map((group, i) => (
                        <styles.footerTr {...group.getFooterGroupProps()}>
                            {group.headers.map((column, i) => {
                                if (column.Header === 'Type') {
                                    return (<styles.StyledTotal  {...column.getFooterProps()}>{column.render('Footer')}</styles.StyledTotal>);
                                }
                                else if (column.Header === "adSet" || column.Header === "id" || column.Header === "ads" || column.Header === "platform" || column.Header === "accountId" || column.Header === "campaign" || column.Header === "Tab") {
                                    return;
                                }
                                return (< styles.FooterTd  {...column.getFooterProps()}>

                                    {typeof(column.render('Footer')) === 'number' ? column.render('Footer')?.toLocaleString() : column.render('Footer')}
                                </styles.FooterTd>);
                            })}
                        </styles.footerTr>
                    ))}
                </tfoot>
            </styles.StyledTable>
        );
    }

    const tabsArray = [
        { title: t('PLATFORM', { ns: 'translation' }), selectedTab: SelectedTab.PLATFORM, selectedRow: selectedRowTab0 },
        { title: t('ACCOUNT', { ns: 'translation' }), selectedTab: SelectedTab.ACCOUNT, selectedRow: selectedRowTab1 },
        { title: t('CAMPAIGN', { ns: 'translation' }), selectedTab: SelectedTab.CAMPAIGN, selectedRow: selectedRowTab2 },
        { title: t('ADS_SET', { ns: 'translation' }), selectedTab: SelectedTab.AD_SET, selectedRow: selectedRowTab3 },
        { title: t('ADS', { ns: 'translation' }), selectedTab: SelectedTab.ADS, selectedRow: selectedRowTab4 },
    ];

    const Tabs = () => {
        return (
            <styles.TabButtonsContainer>
                {tabsArray.map((item, i) => {
                    return (
                        <styles.TabButton key={i} {...item.selectedTab === selectedTab ? { selected: true } : { selected: false }} onClick={(() => {
                            setSelectedTab(item.selectedTab);
                        })}>
                            <styles.TabButtonText>
                                <styles.StyledDiv>
                                    {item.title}
                                </styles.StyledDiv>
                                {!!item?.selectedRow && item?.selectedRow?.length > 0 ?
                                    <styles.StyledDivSelected {...item.selectedTab === selectedTab ? { selected: true } : { selected: false }}>
                                        {(item?.selectedRow?.length)}
                                    </styles.StyledDivSelected> : null
                                }
                            </styles.TabButtonText>
                        </styles.TabButton>
                    );
                })}

            </styles.TabButtonsContainer>
        );
    };
    
    const handleClearState = () => {
        setSelectedRowTab0([]);
        setSelectedRowTab1([]);
        setSelectedRowTab2([]);
        setSelectedRowTab3([]);
        setSelectedRowTab4([]);
        
        dispatch(setMarketingTableRows({rows: [], bucket: BreakdownBuckets.PLATFORM}))
        dispatch(setMarketingTableRows({rows: [], bucket: BreakdownBuckets.ACCOUNT}))
        dispatch(setMarketingTableRows({rows: [], bucket: BreakdownBuckets.CAMPAIGN}))
        dispatch(setMarketingTableRows({rows: [], bucket: BreakdownBuckets.AD_SET}))
        dispatch(setMarketingTableRows({rows: [], bucket: BreakdownBuckets.AD}))
    }

    const renderClearFiltersButton = () => {
        return <styles.ClearFiltersButtonWrapper onClick={() => {
            handleClearState();
            
            /* clearFilters(); */
        }}>
            <IconClearFiltersArrow />
        </styles.ClearFiltersButtonWrapper>;
    };
    const rowsInTotal = data?.filter((row: any) => {
        return !row?.placeholder
    })?.length

    return (
        <styles.Wrapper>
            <styles.Card>
                <styles.Header>
                    <styles.Title>
                        <span style={{ marginRight: '5px' }}>
                            {props.icon}
                        </span>
                        {props.title}
                    </styles.Title>
                    {selectedRowTab0?.length > 0 || selectedRowTab1?.length > 0 || selectedRowTab2?.length > 0 || selectedRowTab3?.length > 0 || selectedRowTab4?.length > 0 ? renderClearFiltersButton() : <></>}
                    {/*<DownloadButton onClick={() => console.log('download')} />*/}
                </styles.Header>

                <Tabs />
                <Box maxH={'77%'} overflowY="auto">
                    <Table columns={columns} data={data} />
                </Box>

                <styles.RowsNumberInTotal>{rowsInTotal} rows in total</styles.RowsNumberInTotal>

            </styles.Card>
        </styles.Wrapper>
    );
};
import axios, {AxiosResponse} from "axios";
import {amazonAppClientId, getAmazonAdsRefreshTokenUrl, getAmazonSellerRefreshTokenUrl} from "@constants";

const initAmazonSdk = () => {
    window['onAmazonLoginReady'] = function () {
        amazon.Login.setClientId(amazonAppClientId);
    };
    (function () {
        var a = document.createElement('script');
        a.type = 'text/javascript';
        a.async = true;
        a.id = 'amazon-login-sdk';
        a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
        document.getElementById('amazon-root')?.appendChild(a);
    })();
}

// amazon-seller-seller seller
const getAmazonSellerRefreshToken = (code: string | null, state: string | null, sellingPartnerId: string | null): Promise<AxiosResponse<{
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
}>> => {
    return axios.get<{
        access_token: string;
        expires_in: number;
        refresh_token: string;
        token_type: string;
    }>(`${getAmazonSellerRefreshTokenUrl}?spApiOauthCode=${code}&state=${state}&sellingPartnerId=${sellingPartnerId}`,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        });
}


export const amazonSellerService = {
    initAmazonSdk,
    getAmazonSellerRefreshToken
};

export const formatIsoDate = (date?: string, noFormat?: boolean) => {
    if(!date){
        return date
    }
    if(noFormat){
        return date
    }
    const slicedDate = date?.slice(0,10);
    const [year, month, day] = slicedDate?.split('-');
    const result = [day, month, year].join('.');
    return  result;
}
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {currentWorkspaceSelector, organizationLoadingSelector, organizationStateSelector} from "@store";
import {ExploresEnum, openDefaultExploreView} from "../../store/ui";
import {Workspace} from "@models";

export const useExploreView = ( ) => {
    const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>();

    const dispatch = useDispatch();
    const organizationLoader = useSelector(organizationLoadingSelector);
    const usersWorkspaces = useSelector(currentWorkspaceSelector);
    const organizationId = useSelector(organizationStateSelector)?.organization?.id;

    useEffect(() => {
        if(!!usersWorkspaces && !!organizationId){
            const currentWorkspace = usersWorkspaces?.find((workspace) => workspace?.organization === organizationId);
            setCurrentWorkspace(currentWorkspace);
        }
    }, [usersWorkspaces, organizationId])


    useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const exploreEnumAsString = params.get("explore");
        if (!!exploreEnumAsString && !!!organizationLoader) {
            setTimeout(() => {
                dispatch(
                    openDefaultExploreView({
                        isOpen: true,
                        ExploresEnum: ExploresEnum[exploreEnumAsString],
                    })
                );
            }, 1500);
        }
    }, [dispatch, organizationLoader]);

};

import styles from "./styles";
import { getColorByPercentage } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@styles";

export interface Props {
    color?: string;
    percentage: number;

    deductionTarget?: boolean
}

export const TargetText = (props: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const color = props.color ?? getColorByPercentage(props.percentage, theme, props?.deductionTarget);

    // hooks

    // helpers

    // renderers

    return (
        <styles.TargetText color={color}>
            {`${props.percentage.toFixed(0)}%`}

        </styles.TargetText>
    );

};

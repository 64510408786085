import styles from './styles';
// import {useTranslation} from "react-i18next";

import {Menu, MenuButton, MenuItem, MenuList, Modal, ModalOverlay, Select} from "@chakra-ui/react";
import { getIntegrationPlatformRoundLogo, IntegrationPlatform } from "@models";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import OAuthPopup from "../../components/o-auth-popup/OAuthPopup";
import lottieAnimation from '../../utils/lottie-animations/line-lottie.json';

import {
    amazonAdsRegion,
    amazonAppState,
    amazonSellerMarketplaces,
    amazonSellerUri,
    fetchShopifyOAuthUrlUrl
} from "@constants";
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {SelectMenuFlag} from "../../components/select-menu-flags/SelectMenuFlags";
import {currencyOptions} from "../../utils/currency/currencyOptions";
import {ilFlag} from "../../utils/flags/flags-svg/il";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import groovyWalkAnimation from "../../features/wideviews-organization/departments-top-kpis/plane.json";
import {amazonAdsService} from "../../services/amazon-ads/amazon-ads.service";
import {useDispatch} from "react-redux";
import {AmazonAdsRegions, getAmazonAdsRefreshToken} from "../../store/amazon-ads";

export interface ShopifyNameDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onAuthorization: (params: URLSearchParams) => void;
}

/* const shopifyNameSchema = yup.object().shape({
    shopifyName: yup.string().required(),
}); */


export const AmazonAdsDialog = (props: ShopifyNameDialogProps) => {
    const [region, setRegion] = useState<{region: string, URL: string, code: AmazonAdsRegions}>(amazonAdsRegion[0]);
    const [menuWidth, setMenuWidth] = useState<number>(0);
    const dispatch = useDispatch();
    const menuRef = useRef<any>(null);

    useLayoutEffect(() => {
        setMenuWidth(menuRef?.current?.offsetWidth);
    }, []);

    // hooks
    // const {t} = useTranslation(['translation', 'translation']);
    /* const { register, getValues, formState } = useForm({
        resolver: yupResolver(shopifyNameSchema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    }); */

    // helpers

    // renderers
    const renderTitle = () => {
        return (
            <styles.TitleWrapper>
                <styles.Title>Connect to Amazon Ads</styles.Title>
            </styles.TitleWrapper>
        );
    };

    const renderConnection = () => {
        return (
            <styles.ConnectionWrapper>
                <styles.AvatarWrapper>
                    <styles.AvatarLetters>W</styles.AvatarLetters>
                </styles.AvatarWrapper>
                <styles.LottieStyle animationData={lottieAnimation} />
                <styles.ConnectionLine>
                </styles.ConnectionLine>
                <styles.ShopifyIconWrapper>
                    <styles.ShopifyIcon src={getIntegrationPlatformRoundLogo(IntegrationPlatform.AMAZON_ADS)} />
                </styles.ShopifyIconWrapper>
            </styles.ConnectionWrapper>
        );
    };

    const renderSelectInput = () => {

        const options = amazonAdsRegion?.map((item,i) => {
            return <MenuItem  onClick={() => {setRegion(item)}} key={i} value={item?.region}>
                {/*<styles.FlagHolder>
                {item?.flag}
                </styles.FlagHolder>*/}
                {item?.region}
            </MenuItem>
        })
        return(
        <styles.StyledMenu >
            {
                ({isOpen}) => (
                    <>
                        <styles.StyledMenuButton >
                            <styles.StyledWrapper ref={menuRef}>
                                <styles.MenuHolder>
                                   {/* <styles.FlagHolder>
                                        {region?.flag}
                                    </styles.FlagHolder>*/}
                                    {region?.region}
                                </styles.MenuHolder>
                                <div>
                                    {isOpen ? <AiOutlineUp/> : <AiOutlineDown />}
                                </div>
                            </styles.StyledWrapper>
                        </styles.StyledMenuButton>
                        <styles.StyledMenuList width={menuWidth}>
                            {options}
                        </styles.StyledMenuList>
                    </>
                )
            }
        </styles.StyledMenu>
        )
    }

    const renderInput = () => {
        return (
            <styles.InputContainer>
                <styles.InputLabelWrapper>
                    <styles.InputLabel>Your region</styles.InputLabel>
                </styles.InputLabelWrapper>
                {/*<styles.InputWrapper>*/}
                   {/* <styles.InputPrefixWrapper>*/}
                {renderSelectInput()}

                      {/*  <styles.StyledInput
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            type={'text'}
                        />*/}
                    {/*</styles.InputPrefixWrapper>*/}
                   {/* <styles.InputDividerWrapper />
                    <styles.InputSuffixWrapper>*/}
                        {/*<styles.InputSuffix>{'.myshopify.com'}</styles.InputSuffix>*/}
                    {/*</styles.InputSuffixWrapper>*/}
                {/*</styles.InputWrapper>*/}
            </styles.InputContainer>
        );
    };

    const renderAuthorizeButtons = () => {
        /*  if (!formState.isValid) {
             return (
                 <styles.AuthorizeButton>
                     <a href={''}>Authorized</a>
                 </styles.AuthorizeButton>
             );
         } */
        const amazonAdsUri = `https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.f583860b5045418198ea27180d59da96`;
        return (
            // <OAuthPopup
            //     title={'Amazon'}
            //     height={800}
            //     width={1000}
            //     url={amazonAdsUri ?? ''}
            //     onResponse={(params) => {
            //         props?.onAuthorization(params!);
            //     }}
            //     onClose={() => {
            //         // console.log('closing!');
            //     }}
            // >
                <styles.AuthorizeButton onClick={async () => {
                    dispatch(getAmazonAdsRefreshToken({regionCode: region?.code}))
                }}>
                    <a>Authorize</a>
                </styles.AuthorizeButton>
            // </OAuthPopup>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsWrapper>
                <styles.BackButton onClick={() => props?.onClose()} buttonStyle={'outlined'}>
                   Cancel
                </styles.BackButton>
                {renderAuthorizeButtons()}
            </styles.ButtonsWrapper>
        );
    };

    const renderDialogContent = () => {
        return (
            <styles.ContentWrapper>
                {renderTitle()}
                {renderConnection()}
                {renderInput()}
            </styles.ContentWrapper>
        );
    };

    const renderDialogFooter = () => {
        return (
            <styles.FooterWrapper>
                {renderButtons()}
            </styles.FooterWrapper>
        );
    };

    return (
        <Modal isOpen={props.isDialogOpen} onClose={() => props.onClose()}>
            <ModalOverlay onClick={() => props?.onClose()} />
            <styles.Wrapper>
                {renderDialogContent()}
                {renderDialogFooter()}
            </styles.Wrapper>
        </Modal>
    );
};

import { useEffect, useState } from 'react';
import { organizationDataLanguageSelector} from "@store";
import {currencyOptions} from "../currency/currencyOptions";
import {useSelector} from "react-redux";

export const useCurrencySign = () => {
    const [currentCurrency, setCurrentCurrency] = useState<string>("");
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    useEffect(() => {
        if (!!dataLanguage && dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }
    }, [dataLanguage?.generalSettings?.currency]);

    return currentCurrency;
}

import smallWideviewCardStyles from "./styles";
import {
  AvatarGroup,
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { TargetBadge } from "../target-badge/TargetBadge";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ExploresEnum,
  openDefaultExploreView,
  selectedCardSelector,
  setCardSelected,
} from "../../store/ui";
import { useTheme } from "@styles";
import { organizationSelector, pinKpi, userSelector } from "@store";
import { UserAvatar, Workspace } from "@models";
import { CreateAlert } from "components/create-alert/CreateAlert";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { t } from "i18next";
import { KpisEnum } from "models/kpi-settings";
import { useLocation } from "react-router";
import { kpiStateSelector } from "store/kpis";
import { getUserColorById, gradientDictionary } from "../../utils/colors";
import { MenuButton } from "../menu-generic/MenuGenericButton";
import { ThreeDotMenuButton } from "../3dot-menu-button/ThreeDotMenuButton";
import { usersDataSelector } from "../../store/users";
// @ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { VscDebugDisconnect } from "react-icons/vsc";
import { MdArrowForwardIos } from "react-icons/md";
import { BsClockHistory, BsFillLightningChargeFill } from "react-icons/bs";
import InformationWrapper from "../InformationWrapper/InformationWrapper";

enum Section {
  MARKETING,
  FINANCE,
  WEBSITE,
  CUSTOMERS_SUCCESS,
}

export enum Size {
  Tiny,
  Small,
}

export enum DataMode {
  NotUpToDate,
  NotSet,
  Broken,
}
export enum PopoverPlacement {
  TOP = "top",
  BOTTOM = "bottom",
}

export interface SmallCardProps {
  title?: string;
  value?: string;
  size?: Size;
  targetPercentage?: number;
  userFullName?: string;
  userAvatarUrl?: string;
  dataMode?: DataMode;
  enumType?: string;
  kpiNumber?: number;
  hovered?: boolean;
  onClick?: (e: any) => void;
  popoverPlacemnt: PopoverPlacement;
  doNotDisplayCard?: boolean;
  user?: any;
  assinedKpisAvatar?: UserAvatar[];
  reducedShadow?: boolean;
  hideBorder?: true;
  defaultStateToExplore?: any;
  deductionTarget?: boolean;
  hideTarget?: boolean;
}

export const SmallWideviewCard = (props: SmallCardProps) => {
  const {
    isOpen: isAlertsOpen,
    onOpen: onAlertsOpen,
    onClose: onAlertsClose,
  } = useDisclosure(); //
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure(); //

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isInsidePinnedKpi, setIsInsidePinnedKpi] = useState(false);
  // const isExploreOpen = useSelector(isExploreOpenSelector)
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(userSelector);
  const organization = useSelector(organizationSelector);
  const selectedKpiCard = useSelector(selectedCardSelector);
  //const isWorkspaceKpiPage = useSelector(isWorkspaceSelector);
  //const isMarktingKpiPage = useSelector(isMarketingSelector);
  //const [toClean, setToClean] = useState(false);
  const location = useLocation();
  const dailyStats = useSelector(kpiStateSelector);
  const allUsers = useSelector(usersDataSelector);

  const [image, takeScreenshot] = useScreenshot();
  const screenshotRef = createRef<any | null>();
  const getImage = () => takeScreenshot(screenshotRef.current);

  useEffect(() => {
    const currentWorkspace = user?.workspaces?.find(
      (workspace) => workspace.organization === organization?.id
    );
    if (!!currentWorkspace?.pinnedKpis && props.kpiNumber !== undefined) {
      const copyOfWorkspace: Workspace = JSON.parse(
        JSON.stringify(currentWorkspace)
      ); // deep copy of object
      if (copyOfWorkspace.pinnedKpis) {
        if (!currentWorkspace?.pinnedKpis.includes(props.kpiNumber)) {
          setIsInsidePinnedKpi(true);
        }
      }
    }
  }, [organization?.id, props.kpiNumber, user?.workspaces]);


  const returnKpiOnClick = () => {
    if (props?.onClick) {
      props?.onClick(props.kpiNumber);
    }
  };

  const pinKpiHelper = () => {
    if (user?.workspaces !== undefined && organization?.id !== undefined) {
      const currentWorkspace = user?.workspaces?.find(
        (workspace) => workspace.organization === organization?.id
      );
      const copyOfWorkspace: Workspace = JSON.parse(
        JSON.stringify(currentWorkspace)
      ); // deep copy of object
      if (!!copyOfWorkspace?.pinnedKpis && props.kpiNumber !== undefined) {
        const currentTypePinnedKpi: number[] = copyOfWorkspace.pinnedKpis;
        if (isInsidePinnedKpi) {
          currentTypePinnedKpi.push(props.kpiNumber);
        }
        if (!isInsidePinnedKpi) {
          const index = currentTypePinnedKpi.indexOf(props.kpiNumber);
          if (index > -1) {
            currentTypePinnedKpi.splice(index, 1);
          }
        }
      }
      if (
        !!user?.id &&
        !!currentWorkspace &&
        typeof currentWorkspace.id === "string"
      ) {
        dispatch(
          pinKpi({
            data: copyOfWorkspace,
            userId: user?.id,
            workspaceId: currentWorkspace.id,
          })
        );
        onClose();
      }
    }
  };

  const openCreateNewAlertModal = () => {
    onAlertsOpen();
  };
  const openShareModal = () => {
    getImage();
    onShareOpen();
  };

  const getSectionFromTitle = () => {
    switch (props?.enumType) {
      case "marketing":
        return Section.MARKETING;
      case "finance":
        return Section.FINANCE;
      case "website":
        return Section.WEBSITE;
      case "customers success":
        return Section.CUSTOMERS_SUCCESS;
      default:
        return Section.MARKETING;
    }
  };

  // content

  const renderUserNamesTooltip = () => {
    if (props?.assinedKpisAvatar) {
      return props?.assinedKpisAvatar?.map(
        (nameUser) => `${nameUser?.userName}`
      );
    }
    return [t("NO_USER_ASSIGN", { ns: "translation" })];
  };

  const renderAvatar = (tiny?: boolean) => {
    return (
      <AvatarGroup size="xs" max={1}>
        {!!props?.assinedKpisAvatar?.length &&
        props?.assinedKpisAvatar?.length > 0 ? (
          props?.assinedKpisAvatar.map((user, i) => {
            if (i < 2) {
              const foundUserImage = allUsers?.find((currentUser) => currentUser?.id === user?.userId);
              return (
                <>
                  <Tooltip
                    placement="bottom"
                    label={renderUserNamesTooltip().join(" ,")}
                    aria-label="A tooltip wide views"
                  >
                    <smallWideviewCardStyles.UserAvatar
                      key={i}
                      color={"white"}
                      src={foundUserImage?.image}
                      background={
                        user?.userId
                          ? getUserColorById(user?.userId)
                          : gradientDictionary[0]
                      }
                      name={
                        !!user?.userName && !!user?.userLastName
                          ? user?.userName + " " + user?.userLastName
                          : user?.userName
                          ? user?.userName
                          : ""
                      }
                      size="xs"
                    />
                  </Tooltip>
                </>
              );
            }
            //else TODO
            return (
              <>
                <Tooltip
                  placement="top"
                  label={renderUserNamesTooltip().toString()}
                  aria-label="A tooltip wide views"
                >
                  <smallWideviewCardStyles.UserAvatar
                    key={i}
                    name={""}
                    size="xs"
                    bg={"red"}
                  />
                </Tooltip>
              </>
            );
          })
        ) : (
          <>
            <smallWideviewCardStyles.UserAvatar
              size={"xs"} //{tiny ? "xs" : 'sm'}
              name={""}
              src={props?.userAvatarUrl}
            />
          </>
        )}
      </AvatarGroup>
    );
  };

  const menuButtonsData: MenuButton[] = [
    {
      title: t("SHARE", { ns: "translation" }),
      onClick: () => openShareModal(),
    },
    {
      title: !isInsidePinnedKpi
        ? t("UNPIN", { ns: "translation" })
        : t("PIN_TO_WORKSPACE", { ns: "translation" }),
      onClick: () => pinKpiHelper(),
    },
    {
      title: t("CREATE_ALERT", { ns: "translation" }),
      onClick: () => openCreateNewAlertModal(),
    },
  ];

  const renderMenuButton = (size: number) => {
    return (
      <>
        <ThreeDotMenuButton data={menuButtonsData} size={size} />
      </>

      /*            <>
                    <Popover
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    placement='right'
                    closeOnBlur={true}
                >
                    <PopoverTrigger>
                        <smallWideviewCardStyles.MenuButton>
                            {threeDots()}
                             <Icon as={threeDots} fontSize={size} color={'gray'} />
                        </smallWideviewCardStyles.MenuButton>
                    </PopoverTrigger>
                    <smallWideviewCardStyles.StyledPopoverContent>
                        <smallWideviewCardStyles.DotsButton onClick={() => openShareModal()}>

                            {'Share'}</smallWideviewCardStyles.DotsButton>
                        <smallWideviewCardStyles.Divider />
                        <smallWideviewCardStyles.DotsButton isInside={!isInsidePinnedKpi} onClick={pinKpiHelper}>
                            <Box w={14} />
                            {!isInsidePinnedKpi ? 'Unpin' : 'Pin to Workspace'}
                        </smallWideviewCardStyles.DotsButton>
                        <smallWideviewCardStyles.Divider />

                        <smallWideviewCardStyles.DotsButton onClick={() => openCreateNewAlertModal()}>
                            <Box w={14} />
                            {'Create Alert'}
                        </smallWideviewCardStyles.DotsButton>
                    </smallWideviewCardStyles.StyledPopoverContent>
                </Popover>
            </>*/
    );
  };

  const renderExplore = {
    [KpisEnum.GROSS_REVENUE]: ExploresEnum.GROSS_REVENUE,
    [KpisEnum.NET_REVENUE]: ExploresEnum.NET_REVENUE,
    [KpisEnum.CONVERSION_RATE]: ExploresEnum.CONVERSION_RATE,
    [KpisEnum.SESSIONS]: ExploresEnum.SESSIONS,
    [KpisEnum.MARKETING_SPEND]: ExploresEnum.MARKETING_SPEND,
    [KpisEnum.BLENDED_ROAS]: ExploresEnum.BLENDED_ROAS,
    [KpisEnum.ORDERS]: ExploresEnum.ORDERS, // gross revenue
    [KpisEnum.COST_PER_ORDER]: ExploresEnum.COST_PER_ORDER, // gross revenue
    [KpisEnum.AVERAGE_ORDERS_VALUE]: ExploresEnum.AVERAGE_ORDERS_VALUE, // new explore
    [KpisEnum.GROSS_PROFIT]: ExploresEnum.GROSS_PROFIT, // net margin gross margin
    [KpisEnum.CONTRIBUTION_PROFIT]: ExploresEnum.CONTRIBUTION_PROFIT, // net margin cont margin
    [KpisEnum.PENDING_DEVICES]: ExploresEnum.PENDING_DEVICES,
    [KpisEnum.HOLD_DEVICES]: ExploresEnum.HOLDING_DEVICES,
    [KpisEnum.APPROVED_DEVICES]: ExploresEnum.APPROVED_DEVICES,
    [KpisEnum.NUMBER_OF_DEVICES]: ExploresEnum.NUMBER_OF_DEVICES,
    [KpisEnum.INCOMPLETE_ORDERS]: ExploresEnum.INCOMPLETE_ORDERS,
    [KpisEnum.REMAINING_COST]: ExploresEnum.REMAINING_COST,
    [KpisEnum.CUSTOMER_ACQUISITION_COST]: ExploresEnum.CAC,
  };
  const renderExploreButton = () => {
    return (
      <smallWideviewCardStyles.ExploreButtonWrapper
        onClick={() => {
          if (props.kpiNumber) {
            dispatch(
              openDefaultExploreView({
                isOpen: true,
                ExploresEnum: renderExplore[props.kpiNumber],
                stateToTransfer: props?.defaultStateToExplore,
              })
            );
          }
        }}
      >
        <smallWideviewCardStyles.ExploreButton className={'shifted-text'}>
          {"Explore"}
        </smallWideviewCardStyles.ExploreButton>
        <smallWideviewCardStyles.ExploreButtonIcon as={MdArrowForwardIos} />
      </smallWideviewCardStyles.ExploreButtonWrapper>
    );
  };

  const renderDataModeIcon = (dataMode: DataMode) => {
    const tooltipTitles = [
      "DATA_ISNT_UP_TO_DATE",
      "CONNECTION_IS_MISSING",
      "OOPS_SOMETHING_IS_BROKEN",
    ];

    const DataModeIcon = () => {
      return dataMode === DataMode.NotSet ? (
        <smallWideviewCardStyles.DataModeIconWrapper color={"#F1EAFF"}>
          <VscDebugDisconnect
            fontWeight={"bold"}
            size={"16px"}
            color={theme.primaryColor}
          />
        </smallWideviewCardStyles.DataModeIconWrapper>
      ) : (
        <Flex alignItems={"center"}>
          <Box />
          <smallWideviewCardStyles.NoDataText>
            No Data
          </smallWideviewCardStyles.NoDataText>
          <Box w={2} />
          <smallWideviewCardStyles.DataModeIconWrapper color={"#7031EB"}>
            {dataMode === DataMode.Broken ? (
              <BsFillLightningChargeFill size={"10px"} color={"#fff"} />
            ) : (
              <BsClockHistory size={"10px"} color={"#fff"} />
            )}
          </smallWideviewCardStyles.DataModeIconWrapper>
        </Flex>
      );
    };

    return (
      <Popover trigger={"hover"} placement={props.popoverPlacemnt}>
        <PopoverTrigger>
          <div>
            <DataModeIcon />
          </div>
        </PopoverTrigger>
        <PopoverContent borderRadius={30} w={"max-content"}>
          <smallWideviewCardStyles.Tooltip>
            <smallWideviewCardStyles.TooltipHeader>
              {/* <DataModeIcon /> */}
              <div style={{ width: "10px" }} />
              <smallWideviewCardStyles.TooltipTitle>
                {t(tooltipTitles[dataMode], { ns: "translation" })}
              </smallWideviewCardStyles.TooltipTitle>
            </smallWideviewCardStyles.TooltipHeader>
            <smallWideviewCardStyles.TooltipSection>
              <smallWideviewCardStyles.TooltipLastUpdate>
                {/* Last Update: 11/06/22 on 10:46 AM */}
              </smallWideviewCardStyles.TooltipLastUpdate>
              <div style={{ width: "10px" }} />
              <smallWideviewCardStyles.TooltipMoreinfo>
                {t("LETS_FIX_IT", { ns: "translation" })}
              </smallWideviewCardStyles.TooltipMoreinfo>
            </smallWideviewCardStyles.TooltipSection>
          </smallWideviewCardStyles.Tooltip>
        </PopoverContent>
      </Popover>
    );
  };

  const checkingPage = () => {
    if (
      location.pathname === "/admin/panel/marketing" &&
      props.dataMode !== DataMode.Broken
    ) {
      dispatch(
        setCardSelected({
          isSelected: true,
          kpisEnumMarketing: props.kpiNumber,
          kpisEnumWorkspace: undefined,
        })
      );
    }
    if (
      location.pathname === "/admin/panel/workspace" &&
      props.dataMode !== DataMode.Broken
    ) {
      dispatch(
        setCardSelected({
          isSelected: true,
          kpisEnumMarketing: undefined,
          kpisEnumWorkspace: props.kpiNumber,
        })
      );
    }
  };

  const renderContent = () => {
    return (
      <smallWideviewCardStyles.Card

        reducedShadow={props?.reducedShadow}
        onClick={() => {
          if (props?.hideBorder) {
            return;
          }
          if (
            props.doNotDisplayCard === false ||
            props.doNotDisplayCard === undefined
          ) {
            checkingPage();
          }
          //The page is in the organization page
          else {
            dispatch(
              setCardSelected({
                isSelected: false,
                kpisEnumMarketing: undefined,
                kpisEnumWorkspace: undefined,
              })
            );
          }
        }}
        noData={
          props.dataMode === DataMode.NotSet ||
          props.dataMode === DataMode.Broken
        }
        hovered={props.dataMode !== DataMode.Broken}
        toClean={
          (location.pathname === "/admin/panel/marketing" &&
            !!selectedKpiCard?.workspaceKpiNumber) ||
          (location.pathname === "/admin/panel/workspace" &&
            !!selectedKpiCard?.marketingKpiNumber)
        }
        isSelected={
          !props?.hideBorder &&
          (selectedKpiCard.marketingKpiNumber === props.kpiNumber ||
            selectedKpiCard.workspaceKpiNumber === props.kpiNumber) &&
          props.dataMode !== DataMode.Broken &&
          (props.doNotDisplayCard === false ||
            props.doNotDisplayCard === undefined)
        }
      >
        <smallWideviewCardStyles.Header>
          <smallWideviewCardStyles.Title className={'shifted-text'}>
            <InformationWrapper text={props?.title}/>
          </smallWideviewCardStyles.Title>
          <smallWideviewCardStyles.TinyContentSecondColumn>
            {renderAvatar(true)}
            <Box w={1.2} />
          </smallWideviewCardStyles.TinyContentSecondColumn>
          {/*<smallWideviewCardStyles.Pin >*/}
          {renderMenuButton(4)}
          <Box w={"2px"} />
          {/*</smallWideviewCardStyles.Pin>*/}
        </smallWideviewCardStyles.Header>
        <smallWideviewCardStyles.Content>
          <smallWideviewCardStyles.Content
            noData={
              props.dataMode === DataMode.NotSet ||
              props.dataMode === DataMode.Broken
            }
          >
            <smallWideviewCardStyles.ContentFirstColumn>
              <smallWideviewCardStyles.IndicatorWrapper>
                <smallWideviewCardStyles.Value className={'shifted-text'}>
                  {props?.value ? props.value : ""}
                </smallWideviewCardStyles.Value>
                {props.dataMode &&
                  !DataMode.Broken &&
                  renderDataModeIcon(props.dataMode as DataMode)}
              </smallWideviewCardStyles.IndicatorWrapper>
              {props?.hideTarget ? <></> : props.dataMode === DataMode.Broken ? (
                <></>
              ) : (
                <TargetBadge
                  deductionTarget={props?.deductionTarget}
                  percentage={props?.targetPercentage ?? 0}
                />
              )}
            </smallWideviewCardStyles.ContentFirstColumn>
            <smallWideviewCardStyles.ContentSecondColumn >
              {props.dataMode === DataMode.Broken ? (
                <></>
              ) : (
                renderExploreButton()
              )}
            </smallWideviewCardStyles.ContentSecondColumn>
          </smallWideviewCardStyles.Content>
          {(props.dataMode === DataMode.NotSet ||
            props.dataMode === DataMode.Broken) && (
            <smallWideviewCardStyles.NoData>
              <smallWideviewCardStyles.IndicatorWrapper>
                <smallWideviewCardStyles.ValueGhost>
                  {props?.value ? props.value : ""}
                </smallWideviewCardStyles.ValueGhost>
                {renderDataModeIcon(props.dataMode)}
              </smallWideviewCardStyles.IndicatorWrapper>
            </smallWideviewCardStyles.NoData>
          )}
        </smallWideviewCardStyles.Content>
      </smallWideviewCardStyles.Card>
    );
  };

  const renderTinyContent = () => {
    return (
      <>
        <smallWideviewCardStyles.TinyCard hovered={props.hovered}>
          <smallWideviewCardStyles.TinyWrapper>
            <smallWideviewCardStyles.TinyHeader>
              <smallWideviewCardStyles.TinyTitle>
                <InformationWrapper text={props?.title}/>
              </smallWideviewCardStyles.TinyTitle>
              <div style={{ width: 7 }}>{renderMenuButton(4)}</div>
            </smallWideviewCardStyles.TinyHeader>

            <smallWideviewCardStyles.Content>
              <smallWideviewCardStyles.TinyContentFirstColumn>
                <div>
                  <smallWideviewCardStyles.TinyValue>
                    {props?.value}
                  </smallWideviewCardStyles.TinyValue>
                  <div style={{ marginTop: 5 }}>
                    {props?.hideTarget ? <></> : props?.targetPercentage ? (
                      isNaN(props?.targetPercentage) ? (
                        <TargetBadge
                          targetFontSize={10}
                          tiny={true}
                          percentage={0}
                        />
                      ) : (
                        <TargetBadge
                          targetFontSize={10}
                          tiny={true}
                          percentage={props?.targetPercentage ?? 0}
                        />
                      )
                    ) : (
                      <TargetBadge
                        targetFontSize={10}
                        tiny={true}
                        percentage={0}
                      />
                    )}
                  </div>
                </div>
                {renderExploreButton()}
              </smallWideviewCardStyles.TinyContentFirstColumn>
            </smallWideviewCardStyles.Content>
          </smallWideviewCardStyles.TinyWrapper>
        </smallWideviewCardStyles.TinyCard>
      </>
    );
  };

  return (
    <>
      <CreateAlert
        kpi={props.title}
        section={getSectionFromTitle()}
        isOpen={isAlertsOpen}
        onClose={onAlertsClose}
        onOpen={onAlertsOpen}
      />
      <ShareKpi
        exploreEnum={!!props.kpiNumber ? renderExplore[props.kpiNumber] : 0}
        isOpen={isShareOpen}
        onClose={onShareClose}
        imageToShare={image}
        onOpen={onShareOpen}
      />
      <smallWideviewCardStyles.Wrapper
          ref={screenshotRef}

          hover={props.dataMode !== DataMode.Broken}
        onClick={() => {
          if (props.dataMode !== DataMode.Broken) {
            returnKpiOnClick();
          }
        }}
      >
        {props.size === Size.Tiny ? (
          <>{renderTinyContent()}</>
        ) : (
          renderContent()
        )}
      </smallWideviewCardStyles.Wrapper>
    </>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {systemEventsService} from "../../services/system-events/systemEvents.service";
import {SystemEventsType} from "./state";

export const fetchSystemEvent = createAsyncThunk<
  any,
    {  organizationId: string; query?:string; type?:SystemEventsType; }
>(
  "notifications/fetchSystemEvents",
  async ({ organizationId,query, type, }: {  organizationId: string; query?:string; type?:SystemEventsType }, hunkAPI) => {
    const response = await systemEventsService.fetchSystemEvents({
      organizationId, query, type
    });
    return response.data;
  }
);

export const fetchSystemEventIntegrations = createAsyncThunk<
    any,
    {  organizationId: string, integrations?:string[] }
    >(
    "notifications/fetchSystemEventsIntegrations",
    async ({ organizationId, integrations }: {  organizationId: string, integrations?:string[] }, hunkAPI) => {

        const response = await systemEventsService.fetchSystemEventsIntegrations({
            organizationId,
            integrations
        });
        return response.data;
    }
);

import React, {useCallback, useEffect, useState} from "react";
import {FileRejection, useDropzone} from "react-dropzone";
import {styles} from './styles'

import {Box, Flex, Spinner} from "@chakra-ui/react";
import lottieAnimation from "../../../utils/lottie-animations/file-upload-lottie.json";
import {TiDeleteOutline} from "react-icons/ti";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {HiOutlineTrash} from "react-icons/hi";



function Dropzone({ setDroppedFiles, droppedFiles, progress, maxFiles, accept, onFileBrowserOpen, maxSize }:
                      {droppedFiles: File[], setDroppedFiles: (e: File[]) => void, progress?: number, maxFiles: number, maxSize?: number
    accept: {[key: string]: string[]}, onFileBrowserOpen?: (e?:any) => void; }) {
    const [errors, setErrors] = useState("");
    const MAX_MB = maxSize ?? 25
    const MAX_FILES_SIZE = MAX_MB * 1024 * 1024

    useEffect(() => {
        if (progress === 100) {
            setErrors("");
        }
    }, [progress]);

    const onDrop = useCallback((acceptedFiles, fileRejections: FileRejection[]) => {

        /*setDroppedFiles([]);*/
        setErrors("")
        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    setErrors(`Error: File is larger than ${MAX_MB} MB`);
                }
                if (err.code === "file-invalid-type") {
                    setErrors(`Error: ${err.message}`);
                }
                if (err.code === "too-many-files") {
                    setErrors(`Error: ${err.message}`);
                }

            });
        })
        const newFiles = [...droppedFiles, ...acceptedFiles];
        if(newFiles.length > maxFiles){
            setErrors(`Error: Too many files`)
        }else {

            let totalSize = 0;
            for (const file of newFiles) {
                totalSize += file.size;
            }
            if(!(totalSize > MAX_FILES_SIZE)){
                setDroppedFiles(newFiles)
            }
            else {
                setErrors(`Files size is larger than ${MAX_MB} MB`)
            }
        }
    }, [droppedFiles])

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            maxFiles: maxFiles,
            accept: accept,
            multiple: true,
            onDrop,
            disabled: progress === 100
        });

    const removeFiles = (file: File) => {
    const newFiles = [...droppedFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setDroppedFiles(newFiles)
}

    const files = droppedFiles.map((file, i) => {
        return (
            <styles.StyledText key={i}>
                {progress !== 100 ? <TiDeleteOutline /> : <AiOutlineCheckCircle color={"#61C322"}/>}

                    <styles.FileNameHolder>
                        {file?.name}{/*-{formatBytes(file.size)}*/}
                    </styles.FileNameHolder>
                {progress !== 100 ?  <styles.StyledIcon>
                    <HiOutlineTrash color={"#7A86A1"} onClick={() => {
                    removeFiles(file)
                }} />
                </styles.StyledIcon> : ""}
            </styles.StyledText>
         )})

    return (
        <>
        <styles.Wrapper   {...getRootProps({ className: "dropzone" })}>
            {progress !== 100 ? <input onClick={onFileBrowserOpen} className="input-zone" {...getInputProps()} /> : ""}
            <div>
                <Flex justifyContent={"center"} alignItems={"center"}>
                    {progress === 0 || progress === 100 ?
                        /*<BsArrowDownShort size={45}/> */
                        <styles.LottieStyle animationData={lottieAnimation} />
                        : progress === undefined ? <></> : <Spinner />}
                </Flex>
                {isDragActive ? (
                    <styles.DragText>
                        Release to drop the files here
                    </styles.DragText>
                ) : (
                    <styles.DragText>
                        <Flex flexDirection={"column"}>
                            <Box>
                                Drag and drop files here
                            </Box>
                            <styles.StyledButton>
                                {"or click to select files"}
                            </styles.StyledButton>
                        </Flex>
                    </styles.DragText>
                )}

            </div>
        </styles.Wrapper>
            <styles.ErrorText>
                {errors}
            </styles.ErrorText>
            <Box mt={"5px"} w={"100%"} overflow={"hidden"}>
                {files}
            </Box>
        </>
    );
}


export default Dropzone;
import styles from "./styles";
// import {useTranslation} from "react-i18next";
import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { KpiSettingsTable } from "../../components/kpi-settings-table/KpiSettingsTable";
import { IconAcrossChannels } from "../../utils/icons/explore";
import { useTranslation } from "react-i18next";
import { KpisEnum, KpiSettings } from "../../models/kpi-settings";
import { Workspace } from "@models";
import { useDispatch, useSelector } from "react-redux";
import {
  assignKpi,
  organizationDataLanguageSelector,
  organizationSelector,
  userSelector,
} from "@store";
import { assignTopKpi } from "store/kpis";
import { usersDataSelector } from "store/users";

export class MoreInfoDialogProps {
  title?: string;
  subtitle?: string;
  description?: string;
  tip?: string;
}

export const KpiSettingsDialog = (props: {
  props: MoreInfoDialogProps;
  isDialogOpen: boolean;
  index: number;
  onClose: () => void;
}) => {
  // hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(["translation", "translation"]);
  const [pinnedKpi, setPinnedKpi] = useState<number[]>();
  /* const [assignedOwners, setAssignedOwners] = useState<{ data: string[], id: number; }[]>([]); */
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const user = useSelector(userSelector);
  const users = useSelector(usersDataSelector);
  const organiztion = useSelector(organizationSelector);
  const dataLanguage = useSelector(organizationDataLanguageSelector);

  const [allUsersToAssign, setAllUsersToAssign] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentModal = getCurrentModalType();
    if (!!dataLanguage?.topKpis?.[currentModal] && !!currentModal) {
      if (Array.isArray(dataLanguage?.topKpis?.[currentModal]) && !!pinnedKpi) {
        const currentDataToPush = [
          ...pinnedKpi,
          ...dataLanguage?.topKpis?.[currentModal],
        ];
        const filteredArray = currentDataToPush.filter(function (v, _, a) {
          return a.indexOf(v) === a.lastIndexOf(v);
        });
        if (filteredArray.length === 0) {
          setDisableForm(true);
        } else {
          setDisableForm(false);
        }
      }
    }
  }, [dataLanguage?.topKpis, pinnedKpi]);

  // helpers
  const onCloseDialog = () => {
    props.onClose();
    onClose();
  };

  const getCurrentModalType = () => {
    switch (props.index) {
      case 1:
        return "company";
      case 2:
        return "marketing";
      case 3:
        return "finance";
      case 4:
        return "sales";
      case 5:
        return "website";
      default:
        return "company";
    }
  };

  /*    const assignKpisToUsers = (department: string) => {
               const usersData: { userId: string, kpis: number[], workspaceId?: string, data?: any; }[] = [];
       
       
               assignedOwners.forEach((item) => {
                   item.data.forEach((user) => {
                       if (!usersData.some((currentObj) => currentObj.userId === user)) {
                           usersData.push({ userId: user, kpis: [] });
                       }
                   });
               });
       
               usersData.forEach((item) => {
                   assignedOwners.forEach((data) => {
                       if (data.data.includes(item.userId)) {
                           item.kpis.push(data.id);
                       }
                   });
               });
               users?.forEach((user) => {
                   let data: Workspace;
       
                   if (!!user.workspaces[0]?.organization) {
                       const currentWorkspace = user?.workspaces?.find((workspace) => workspace);
                       const copyOfWorkspace: Workspace = JSON.parse(JSON.stringify(currentWorkspace));
                       data = copyOfWorkspace;
       
                   }
                   usersData.forEach((item) => {
                       if (!!user.workspaces[0]?.id && !!user.id && !!item.userId && user.id === item.userId) {
       
                           item.workspaceId = user.workspaces[0].id;   // TODO needs organization
                           item.data = data;
                       }
                   });
               });
       
               const objectToDispatch = (department: string, kpis: number[], assignedKpis: any) => {
                   const copyOfKpis: any = JSON.parse(JSON.stringify(assignedKpis));
                   copyOfKpis[department] = kpis;
                   return {
                       "assignedKpis": copyOfKpis
                   };
               };
       
               usersData.forEach((item) => {
                   let assignedKpis;
                   users?.forEach((user) => {
                       if (user.id === item.userId) {
                           const currentWorkspace = user.workspaces.find((org) => org.organization === organiztion?.id);
                           assignedKpis = currentWorkspace?.assignedKpis;
                       }
                   });
                   if (!!item.workspaceId && !!assignedKpis) {
                       dispatch(assignKpi({ data: objectToDispatch(department, item.kpis, assignedKpis), userId: item.userId, workspaceId: item.workspaceId }));
                   }
               });
           }; */

  const ownersList = () => {
    const data = users?.map((item) => item);
    return data;
  };

  const [ownersArray, setOwnersArray] = useState<any[]>([
    {
      userId: "",
      assignedKpis: [],
    },
  ]);

  useEffect(() => {
    if (!!organiztion?.users) {
      const newArr = organiztion?.users?.map((item) => {
        return {
          userId: item.id,
          marketing: [], // TODO
          finance: [], // TODO
          website: [], // TODO
          sales: [], // TODO
        };
      });
      setOwnersArray(newArr);
    }
  }, [organiztion?.users]);

  const kpiData = (): KpiSettings[] => {
    return [
      {
        kpi: KpisEnum.GROSS_REVENUE,
        target: 15,
      },
      {
        kpi: KpisEnum.NET_REVENUE,
        target: 600,
      },
      {
        kpi: KpisEnum.GROSS_PROFIT,
        target: 600,
      },
      {
        kpi: KpisEnum.CONTRIBUTION_PROFIT,
        target: 600,
      },
      {
        kpi: KpisEnum.AVERAGE_ORDERS_VALUE,
        target: 600,
      },
      {
        kpi: KpisEnum.ORDERS,
        target: 600,
      },
      {
        kpi: KpisEnum.MARKETING_SPEND, // 23
        target: 15,
      },
      {
        kpi: KpisEnum.BLENDED_ROAS,
        target: 15,
      },
      {
        kpi: KpisEnum.COST_PER_ORDER,
        target: 15,
      },
      {
        kpi: KpisEnum.CUSTOMER_ACQUISITION_COST,
        target: 15,
      },
      /*{
              kpi: KpisEnum.INVENTORY_TURNOVER,
              target: 15,
            },
            {
              kpi: KpisEnum.AVERAGE_UNITS_SOLD,
              target: 15,
            },
            {
              kpi: KpisEnum.COGS_AVERAGE,
              target: 15,
            },*/
    ];
  };

  // renderers

  return (
    <Modal isOpen={props.isDialogOpen} onClose={onCloseDialog}>
      <ModalOverlay />
      <styles.CustomModalContent>
        <styles.Wrapper>
          <styles.CloseButton onClick={onCloseDialog}></styles.CloseButton>
          <styles.Header>
            <styles.TitleWrapper>
              <styles.HorizontalBar />
              <styles.Title>{props.props.title}</styles.Title>
            </styles.TitleWrapper>
            <styles.SubTitle>{props.props.subtitle}</styles.SubTitle>
          </styles.Header>
          <styles.Content>
            <KpiSettingsTable
              icon={<IconAcrossChannels />}
              headers={[
                t("KPI", { ns: "translation" }),
                t("OWNER", { ns: "translation" }),
                /*t("TARGET", { ns: "translation" }),*/
              ]}
              pinnedKpi={pinnedKpi}
              setPinnedKpi={setPinnedKpi}
              data={kpiData()}
              ownersList={ownersList()}
              ownersArray={ownersArray}
              index={props.index}
              setAllUsersToAssign={setAllUsersToAssign}
              /* setAssignedOwners={setAssignedOwners} */
            />
          </styles.Content>
          <styles.Footer>
            <styles.BackButton onClick={() => onCloseDialog()}>
              {t("BACK", { ns: "translation" })}
            </styles.BackButton>
            {!disableForm ? (
              <styles.ApplyButton
                onClick={() => {
                  switch (props.index) {
                    case 1: //company
                      if (!!dataLanguage && !!organiztion?.id) {
                        let copiedDataLanguage = JSON.parse(
                          JSON.stringify(dataLanguage)
                        );
                        if (
                          Array.isArray(copiedDataLanguage?.topKpis?.company)
                        ) {
                          const arr2 = copiedDataLanguage?.topKpis?.company;
                          const totalCompany = [...pinnedKpi!, ...arr2];
                          const companyResult = totalCompany.filter(function (
                            v,
                            _,
                            a
                          ) {
                            return a.indexOf(v) === a.lastIndexOf(v);
                          });
                          if (copiedDataLanguage! !== undefined) {
                            copiedDataLanguage.topKpis.company = companyResult;
                          }
                        }
                        allUsersToAssign.forEach((user) => {
                          dispatch(
                            assignKpi({
                              data: { assignedKpis: user.assignedKpis },
                              userId: user.userId,
                              workspaceId: user.workspaceId,
                              organizationId: organiztion.id,
                            })
                          );
                        });
                        !!organiztion?.id &&
                          dispatch(
                            assignTopKpi({
                              data: {topKpis: copiedDataLanguage?.topKpis},
                              dataLanguageId: dataLanguage?.id,
                              organizationId: organiztion?.id,
                            })
                          );
                      }
                      break;
                    case 2: //marketing
                      if (!!dataLanguage && !!organiztion?.id) {
                        let copiedDataLanguage = JSON.parse(
                          JSON.stringify(dataLanguage)
                        );
                        if (
                          Array.isArray(copiedDataLanguage?.topKpis?.marketing)
                        ) {
                          const arr2 = copiedDataLanguage?.topKpis?.marketing;
                          const totalMarketing = [...pinnedKpi!, ...arr2];
                          const marketingResult = totalMarketing.filter(
                            function (v, _, a) {
                              return a.indexOf(v) === a.lastIndexOf(v);
                            }
                          );
                          if (copiedDataLanguage! !== undefined) {
                            copiedDataLanguage.topKpis.marketing! =
                              marketingResult!;
                          }
                        }
                        allUsersToAssign.forEach((user) => {
                          dispatch(
                            assignKpi({
                              data: { assignedKpis: user.assignedKpis },
                              userId: user.userId,
                              workspaceId: user.workspaceId,
                              organizationId: organiztion.id,
                            })
                          );
                        });
                        !!organiztion?.id &&
                          dispatch(
                            assignTopKpi({
                              data: {topKpis: copiedDataLanguage?.topKpis},
                              dataLanguageId: dataLanguage?.id,
                              organizationId: organiztion?.id,
                            })
                          );
                      }
                      break;
                    case 3: //Finance
                      if (!!dataLanguage && !!organiztion?.id) {
                        let copiedDataLanguageFinance = JSON.parse(
                          JSON.stringify(dataLanguage)
                        );
                        const financeFilter =
                          copiedDataLanguageFinance?.topKpis?.finance;
                        const totalFinance = [...pinnedKpi!, ...financeFilter];

                        const result = totalFinance.filter(function (v, _, a) {
                          return a.indexOf(v) === a.lastIndexOf(v);
                        });
                        if (copiedDataLanguageFinance! !== undefined) {
                          copiedDataLanguageFinance.topKpis.finance! = result!;
                        }
                        allUsersToAssign.forEach((user) => {
                          dispatch(
                            assignKpi({
                              data: { assignedKpis: user.assignedKpis },
                              userId: user.userId,
                              workspaceId: user.workspaceId,
                              organizationId: organiztion.id,
                            })
                          );
                        });
                        !!organiztion?.id &&
                          dispatch(
                            assignTopKpi({
                              data: {topKpis: copiedDataLanguageFinance?.topKpis},
                              dataLanguageId: dataLanguage?.id,
                              organizationId: organiztion?.id,
                            })
                          );
                      }
                      break;
                    case 4: //sales
                      if (!!dataLanguage && !!organiztion?.id) {
                        let copiedDataLanguageProduct = JSON.parse(
                          JSON.stringify(dataLanguage)
                        );
                        const productFilter =
                          copiedDataLanguageProduct?.topKpis?.sales;
                        const totalProduct = [...pinnedKpi!, ...productFilter];

                        const productResult = totalProduct.filter(function (
                          v,
                          _,
                          a
                        ) {
                          return a.indexOf(v) === a.lastIndexOf(v);
                        });
                        if (copiedDataLanguageProduct! !== undefined) {
                          copiedDataLanguageProduct.topKpis.sales! =
                            productResult!;
                        }
                        allUsersToAssign.forEach((user) => {
                          dispatch(
                            assignKpi({
                              data: { assignedKpis: user.assignedKpis },
                              userId: user.userId,
                              workspaceId: user.workspaceId,
                              organizationId: organiztion.id,
                            })
                          );
                        });
                        !!organiztion?.id &&
                          dispatch(
                            assignTopKpi({
                              data: {topKpis: copiedDataLanguageProduct?.topKpis},
                              dataLanguageId: dataLanguage?.id,
                              organizationId: organiztion?.id,
                            })
                          );
                      }
                      break;
                    case 5: //Website
                      if (!!dataLanguage && !!organiztion?.id) {
                        let copiedDataLanguageProduct = JSON.parse(
                            JSON.stringify(dataLanguage)
                        );
                        const productFilter =
                            copiedDataLanguageProduct?.topKpis?.website;
                        const totalProduct = [...pinnedKpi!, ...productFilter];

                        const productResult = totalProduct.filter(function (
                            v,
                            _,
                            a
                        ) {
                          return a.indexOf(v) === a.lastIndexOf(v);
                        });
                        if (copiedDataLanguageProduct! !== undefined) {
                          copiedDataLanguageProduct.topKpis.website! =
                              productResult!;
                        }
                        allUsersToAssign.forEach((user) => {
                          dispatch(
                              assignKpi({
                                data: { assignedKpis: user.assignedKpis },
                                userId: user.userId,
                                workspaceId: user.workspaceId,
                                organizationId: organiztion.id,
                              })
                          );
                        });
                        !!organiztion?.id &&
                        dispatch(
                            assignTopKpi({
                              data: {topKpis: copiedDataLanguageProduct?.topKpis},
                              dataLanguageId: dataLanguage?.id,
                              organizationId: organiztion?.id,
                            })
                        );
                      }
                      break;
                  }
                  onCloseDialog();
                }}
              >
                {t("Apply", { ns: "translation" })}
              </styles.ApplyButton>
            ) : (
              <styles.DisabledButton>
                {t("Apply", { ns: "translation" })}
              </styles.DisabledButton>
            )}
          </styles.Footer>
          {disableForm ? (
            <styles.ErrorMessageWrapper>
              <styles.ErrorMessage>Pick at least 1 Top Kpi</styles.ErrorMessage>
            </styles.ErrorMessageWrapper>
          ) : null}
        </styles.Wrapper>
      </styles.CustomModalContent>
    </Modal>
  );
};

import { Input, PopoverContent, Img } from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const CheckBoxWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 14px;
    background-color: rgb(242, 244, 246);
    border-top: 2px solid #eee;
    padding: 0 0 0 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
  `;

  export const CheckBoxInput = styled.input`
    flex: 1;
    accent-color: ${(props) => props.theme.primaryColor};
    min-width: 20px;
    min-height: 20px;
  `;

  export const TableInput = styled(Input)`
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: #ebebeb solid 2px;
    padding: 5px 0px;
    border-radius: 20px;
  `;
  export const StyledBox = styled.div<{ show?: boolean }>`
    justify-self: flex-end;
    display: ${(props) => (props?.show ? "block" : "none")};
    &:hover {
      cursor: pointer;
    }
  `;
  export const ToolTipSubtext = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => props.theme.primaryColor};
    font-size: 15px;
  `;
  export const ToolTip = styled.div`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: #7a86a1;
    font-weight: bold;
    font-size: 16px;
  `;

  export const EditBulkPopoverWrapper = styled(PopoverContent)`
    min-width: 0px;
    padding: 5px;
    width: min-content;
    max-width: min-content;
    box-shadow: unset;
    background-color: #fff;
    //border: 0 transparent solid;
    border-width: 0;
  `;

  export const EditBulkInput = styled.input`
    max-width: 130px;
    width: auto;
    text-align: center;
    font-size: 1rem;
    background-color: #fff;
    border: #ebebeb solid 2px;
    padding: 5px 5px;
    border-radius: 20px;
  `;

  export const EditBulkButton = styled.button`
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: transparent;
    padding: 8px 20px;
    margin-bottom: 20px;
    &:hover {
      background-color: ${(props) => props.theme.primaryColor};
      color: #ffffff;
    }
  `;

  export const EditBulkGhost = styled.div`
    opacity: 0;
    padding: 8px 20px;
    border: 1px solid #cccccc00;
    border-radius: 10px;
    background-color: transparent;
    margin-bottom: 20px;
    flex: 1;
  `;

  export const EditBulkApplyButton = styled.button`
    background-color: #ffffff;
    //border: 1px solid #CCCCCC;
    padding: 4px;
    border-radius: 10px;
  `;

  export const CogsTdDiv = styled.div<{
    bgc?: string;
    isNumber?: boolean;
    isLastUpdate?: boolean;
  }>`
    display: flex;
    min-width: 150px;
    min-height: 100px;
    height: 100%;
    width: 100%;
    font-size: 14px;
    text-align: center;
    background-color: ${(props) => (props.bgc ? props.bgc : "transparent")};
    border-top: 2px solid #eee;
    padding-left: ${(props) => (props.isLastUpdate ? "20px" : "0")};
    align-items: center;
    justify-content: ${(props) => (props.isLastUpdate ? "start" : "center")};
  `;

  export const CogsTh = styled.div<{ isDate?: boolean; isProduct?: boolean }>`
    font-weight: bold;
    font-size: 14px;
    height: 100%;
    color: rgb(122, 134, 161);
    background-color: ${(props) =>
      props.isDate ? "rgb(242, 244, 246)" : "#fff"};
    display: flex;
    flex-direction: column;
    align-items: ${(props) =>
      props.isProduct || props.isDate ? "start" : "center"};
    justify-content: center;
    padding: 20px;
    white-space: nowrap;
    border-bottom: 2px solid #eee;
  `;

  export const CogsThTotalWraper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `;
  export const CogsThTotal = styled.div`
    background-color: ${(props) => props.theme.primaryColor};
    width: 100%;
    height: 100%;
    color: white;
    flex: 1;
    white-space: nowrap;
    transform: scale(1, 1.1);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 0px;
    box-shadow: ${(props) => props.theme.fullShadow};
    font-weight: bold;
  `;
  export const CogsTdTotal = styled.div`
    height: 100%;
    width: 100%;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #eee;
    background-color: #fff;
    font-weight: bold;
    box-shadow: ${(props) => props.theme.fullShadow};
  `;

  export const AddColumnButton = styled.button`
    padding: 5px 20px;
    margin: 0 10px;
    font-weight: bold;
    background-color: #e5d5fa;
    color: ${(props) => props.theme.primaryColor};
    border-radius: 16px;
  `;

  export const ProductWrapper = styled.div`
    display: flex;
    border-top: 2px solid #eee;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    min-width: 250px;
    min-height: 100px;
    padding: 10px 0 10px 20px;
  `;

  export const ProductImage = styled(Img)`
    object-fit: cover;
    height: 60px;
    width: 60px;
    padding: 3px;
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 15px;
  `;

  export const ProductIndicator = styled.div<{ mode: string }>`
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: rgb(113, 209, 139);
    margin: 15px;
  `;

  export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  `;

  export const ProductName = styled.div`
    font-size: 16px;
    color: #000;
    font-weight: normal;
    white-space: nowrap;
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden; /
  `;

  export const ProductSKU = styled.div`
    font-size: 16px;
    color: #000;
    font-weight: bold;
    white-space: nowrap;
  `;
}
export default styles;

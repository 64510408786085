import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 0 25px 0;
  `;
  export const Title = styled.title`
    display: flex;
    //width: 100%;
    color: black;
    font-size: 21px;
    font-weight: bold;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
    overflow: hidden;
    white-space: nowrap;
  `;
  export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 40px 0;
  `;


}

export default styles;

import wideViewsStyles, { styles } from "./styles";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { MoreInfoDialog, MoreInfoDialogProps } from "../../dialogs/more-info-dialog/MoreInfoDialog";
import { MoreInfoButton } from "../more-info-button/MoreInfoButton";
import { IoIosArrowForward } from "react-icons/io";
import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, useDisclosure } from "@chakra-ui/react";
import { IconGeneral } from "utils/icons/explore";
import { useDispatch, useSelector } from "react-redux";
import {
    changeCurrencyAndTime,
    CurrencyCode,
    chosenCurrencyCurrencySelector,
    organizationDataLanguageSelector,
    organizationStateSelector, generalSettingsLoadingSelector, cleanUpGeneralSettingsLoader
} from "@store";
import { timezones } from "utils/timezones/timezones";
import { currencyOptions } from "utils/currency/currencyOptions";
import { SelectMenuFlag } from "components/select-menu-flags/SelectMenuFlags";
import { CurrencyMenu } from "./currencyMenu/CurrencyMenu";
import { IconCloseOverlay } from "utils/icons/account-health";
import { clearApplyBarState, isApplyBarOpenSelector, isApplyModalOpenSelector, isDefaultExploreOpenSelector, openApplyBar, openApplyModal, openDefaultExploreView } from "store/ui";
import ApplyChangesBar from "components/apply-changes-bar/ApplyChangesBar";
import { ApplyChangesModal } from "components/apply-changes-modal/ApplyChangesModal";

export interface ExploreProps {
    title?: string;
    timezone?: {
        code: number,
        text: string;
    },
    currency: CurrencyCode;
}

interface Row {
    title: string,
    description: string,
    buttonCallback: () => void,
    index: number;
}


export const currency = [
    { title: "USD", sign: "$" },
    { title: "CAD", sign: "CA$" },
    { title: "EUR", sign: "€" },
    { title: "AUD", sign: "AU$" },
    { title: "BGN", sign: "BGN" },
    { title: "BRL", sign: "R$" },
    { title: "CHF", sign: "CHF" },
    { title: "CNY", sign: "CN¥" },
    { title: "CZK", sign: "Kč" },
    { title: "DKK", sign: "Dkr" },
    { title: "GBP", sign: "£" },
    { title: "HKD", sign: "HK$" },
    { title: "HRK", sign: "kn" },
    { title: "HUF", sign: "Ft" },
    { title: "IDR", sign: "Rp" },
    { title: "ILS", sign: "₪" },
    { title: "INR", sign: "Rs" },
    { title: "ISK", sign: "Ikr" },
    { title: "JPY", sign: "¥" },
    { title: "KRW", sign: "₩" },
    { title: "MXN", sign: "MX$" },
    { title: "MYR", sign: "RM" },
    { title: "NOK", sign: "Nkr" },
    { title: "NZD", sign: "NZ$" },
    { title: "PHP", sign: "₱" },
    { title: "PLN", sign: "zł" },
    { title: "RON", sign: "RON" },
    { title: "RUB", sign: "RUB" },
    { title: "SEK", sign: "Skr" },
    { title: "SGD", sign: "S$" },
    { title: "THB", sign: "฿" },
    { title: "TRY", sign: "TL" },
    { title: "ZAR", sign: "R" }
];



export const GeneralOverlay = () => {
    // hooks
    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();
    const generalLanguagedata = useSelector(organizationDataLanguageSelector);

    const [selectedTimeZone, setSelectedTimeZone] = useState<{ timezone: string, id: string; }>({ timezone: generalLanguagedata?.generalSettings?.timezone.text ?? '', id: generalLanguagedata?.generalSettings?.timezone?.code?.toString() ?? '' });
    /* const [selectedCurrency, setSelectedCurrency] = useState<{ title: string, sign: string; }>({ title: CurrencyCode[props.currency], sign: currency.filter((item) => item.title === CurrencyCode[props.currency])[0].sign }); */
    const [currency, setCurrency] = useState(currencyOptions[0].value);
    const chosenCurrency = useSelector(chosenCurrencyCurrencySelector);
    const organization = useSelector(organizationStateSelector);
    const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const isApplyModalOpen = useSelector(isApplyModalOpenSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const isLoading = useSelector(generalSettingsLoadingSelector);
    useEffect(() => {
        dispatch(clearApplyBarState());
    }, [isExploreOpen.isDefaultExploreOpen]);

    useEffect(() => {
        setSelectedTimeZone({ timezone: generalLanguagedata?.generalSettings?.timezone?.text ?? '', id: generalLanguagedata?.generalSettings?.timezone?.code?.toString() ?? '' });
        if(chosenCurrency !== undefined){
            const currentCurrencyIndex = currencyOptions.findIndex((item) => item.value.enum === chosenCurrency);
            setCurrency(currencyOptions[currentCurrencyIndex].value);
        }else {
            if(generalLanguagedata?.generalSettings?.currency !== undefined){
                const currentCurrencyIndex = currencyOptions.findIndex((item) => item.value.enum === generalLanguagedata?.generalSettings?.currency);
                setCurrency(currencyOptions[currentCurrencyIndex].value);
            }
        }
    }, [chosenCurrency, generalLanguagedata?.generalSettings?.currency,  isExploreOpen?.isDefaultExploreOpen, generalLanguagedata?.generalSettings?.timezone?.code, generalLanguagedata?.generalSettings?.timezone?.text, isExploreOpen.isDefaultExploreOpen]);

    const { t } = useTranslation(['translation', 'translation']);
    const strategyMoreInfoDialogProps = {
        title: 'Company Top KPIs',
        subtitle: 'How to use this view?',
        description: 'Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.',
        tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
        videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
        videoTitle: 'Best Practices',
        videoSubtitle: 'How to use this view?',
        videoDescription: '5 min explanatory video from our COO',
        videoLinkTitle: 'Click to watch'
    } as MoreInfoDialogProps;
    const [isStrategyMoreInfoDialogOpen, setIsStrategyMoreInfoDialogOpen] = useState(false);

    useEffect(() => {
        setSelectedTimeZone({ timezone: generalLanguagedata?.generalSettings?.timezone?.text ?? '', id: generalLanguagedata?.generalSettings?.timezone?.code?.toString() ?? '' });
    }, [generalLanguagedata?.generalSettings?.timezone, isExploreOpen?.isDefaultExploreOpen,]);

    const rows = [
        {
            title: 'Timezone',
            description: 'Set a timezone for display across all channels',
            buttonCallback: () => {

            },
            index: 1
        } as Row,
        {
            title: 'Currency',
            description: 'Set a currency for display across all channels',
            buttonCallback: () => {

            },
            index: 2
        } as Row,
    ];

    // helpers
    const openStrategyMoreInfoDialog = (isOpen: boolean) => {
        setIsStrategyMoreInfoDialogOpen(isOpen);
    };

    const updateTimeAndCurrency = (currency: number, text: string, code: string) => {
        const copyOfGeneral = JSON.parse(JSON.stringify(generalLanguagedata)); // deep copy of object
        if (!!copyOfGeneral?.generalSettings?.timezone) {
            copyOfGeneral!.generalSettings!.timezone = {
                value: selectedTimeZone.timezone,
                abbreviation: "",
                offset: 0,
                isDst: true,
                text,
                utc: [
                    "",
                ],
                code: parseInt(code)
            };
            if (!!generalLanguagedata?.id) {
                !!organization?.organization?.id && !!dataLanguage?.id && dispatch(changeCurrencyAndTime({
                    generalSettings: { timezone: copyOfGeneral.generalSettings.timezone, currency: copyOfGeneral?.generalSettings?.currency, chosenCurrency: currency, id: generalLanguagedata!.id },
                    organizationId: organization?.organization?.id,
                    dataLanguageId: dataLanguage?.id
                }));
            }
        }
    };

    const render = (i: number) => {
        switch (i) {
            case (0): // index of rows map
                return (<Menu closeOnSelect={false}>
                    <MenuButton _focus={{boxShadow: "none !important"}} padding={"0 25px 0 25px"} w={"450px"} borderRadius={"30px"} border="solid 2px #e2e2e2" as={Button} backgroundColor="white">
                        <styles.RowButton>
                            <styles.RowButtonText>
                                {selectedTimeZone.timezone}
                            </styles.RowButtonText>
                            <wideViewsStyles.RowButtonIcon>
                                <IoIosArrowForward />
                            </wideViewsStyles.RowButtonIcon>
                        </styles.RowButton>
                    </MenuButton>
                    <MenuList w={"450px"} maxHeight={"250px"} overflow={"scroll"}>
                        <MenuOptionGroup defaultValue={chosenCurrency?.toString()} type="radio">
                            {timezones.map((item, i) => (<MenuItemOption fontWeight={"bold"} key={i} onClick={() => {
                                dispatch(openApplyBar({ isApplyBarOpen: true }));
                                setSelectedTimeZone({ timezone: item.text, id: item.code.toString() });
                            }} value={item.code.toString()}>{item.text}</MenuItemOption>))}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>);
            case (1):  // index of rows map
                return (
                    <CurrencyMenu item={currency} setItem={setCurrency} data={currencyOptions} />
                );
        }
        return;
    };


    // renderers
    const renderRow = (row: Row, index: number) => {
        return (
            <styles.Row key={index}>
                <wideViewsStyles.RowTitleWrapper>
                    <wideViewsStyles.RowTitle>
                        {row.title}
                    </wideViewsStyles.RowTitle>
                    <wideViewsStyles.RowDescription>
                        {row.description}
                    </wideViewsStyles.RowDescription>
                </wideViewsStyles.RowTitleWrapper>

                {render(index)}
            </styles.Row>
        );
    };

    const renderContent = () => {

        return (
            <wideViewsStyles.Content>
                {rows.map((row, index) => {
                    return renderRow(row, index);
                })}
            </wideViewsStyles.Content>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.HeaderHolder>
                <wideViewsStyles.HeaderIcon>
                    <span style={{ width: '50px', height: '50px' }}>
                        <IconGeneral />
                    </span>
                </wideViewsStyles.HeaderIcon>
                <wideViewsStyles.TitleWrapper>
                    <wideViewsStyles.Title>
                        {t('GENERAL_SETTINGS', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => openStrategyMoreInfoDialog(true)} />
                        <MoreInfoDialog
                            props={strategyMoreInfoDialogProps}
                            isDialogOpen={isStrategyMoreInfoDialogOpen}
                            onClose={() => setIsStrategyMoreInfoDialogOpen(false)}
                        />
                    </wideViewsStyles.Title>
                    <wideViewsStyles.SubTitle>
                        {t('CHANGE_GENERAL_SETTINGS_BELOW', { ns: 'translation' })}
                    </wideViewsStyles.SubTitle>
                </wideViewsStyles.TitleWrapper>
            </wideViewsStyles.HeaderHolder>
            <styles.HeaderIcon>
                <span style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
                    <button onClick={() => {
                        if (isApplyBarOpen) {
                            dispatch(openApplyModal({ isApplyModalOpen: true }));
                        } else {
                            dispatch(openDefaultExploreView({ isOpen: false }));
                        }
                    }}>
                        <IconCloseOverlay />
                    </button>
                </span>
            </styles.HeaderIcon>
        </wideViewsStyles.Header>;
    };

    const onApply = () => {
        updateTimeAndCurrency(currency.enum, selectedTimeZone.timezone, selectedTimeZone.id.toString())
    }

    return (
        <>
            <ApplyChangesModal onApply={() => onApply()} isOpen={isApplyModalOpen} onOpen={onOpen} onClose={onClose} />
            {isApplyBarOpen ? <ApplyChangesBar onClose={() => dispatch(cleanUpGeneralSettingsLoader())} finishedLoading={isLoading?.isSuccess && !isLoading?.isLoad}
                              title="GENERAL_SETTING_UPDATED" show={isApplyBarOpen} onApplyChanges={() => onApply()}/> : <></>}
            <wideViewsStyles.Wrapper>
                {renderHeader()}
                {renderContent()}

            </wideViewsStyles.Wrapper>
        </>
    );
};

import { useEffect, useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";

import { IconCalendar } from "../../utils/icons/explore";


//import "./pickerStyle.css";
import { PlacementWithLogical, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { addDays, addMonths } from "date-fns";


export interface Props {
  title?: string;
  onApply: (range: Date) => void;
  onChange: (range: Date) => void;
  popperPlacement?: PlacementWithLogical;
  date?: Date;
  min?: Date;
  max?: Date;

}

export const DatepickerSmall = (props: Props) => {
  const today = props?.date ? props?.date : new Date();



  // hooks
  const { t } = useTranslation(["translation", "translation"]);
  const [pickerDay, setPickerDay] = useState<Date | undefined>(today);
  const [daySelected, setDaySelected] = useState<Date | undefined>(today);
  const nextMonth = addMonths(today, 0);
  const [month, setMonth] = useState<Date>(nextMonth);


  const disabledDays = [/*{ from: !!props?.min ? new Date(props?.min) : undefined , 
  to:   !!props?.max ? new Date(props?.max) : new Date() }*/
    { before: !!props?.min ? new Date(props?.min) : addDays(today, -7) },
    { after: !!props?.max ? new Date(props?.max) : today },
  ];


  //console.log("here",props.min)


  useEffect(() => {
    if (!!props?.date) {
      setDaySelected(props.date);
      setPickerDay(props.date);
      setMonth(props.date);
      //console.log("this date min", props.min);
      //console.log("this date max", props.max);
    }
  }, [props.date]);

  // helpers
  const dateFormat = (date: Date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear()
    );
  };


  const selectDay = (date: Date | undefined) => {
    setPickerDay(date);
    setDaySelected(date);
  };


  const applyDay = (onClose: () => void) => {
    //console.log("apply day", pickerDay);
    if (!pickerDay) {
      return;
    }

    setDaySelected(pickerDay);
    props.onChange(pickerDay ? pickerDay : today);
    onClose();
  };

  const getDateFormat = () => pickerDay && dateFormat(pickerDay);


  // renderers
  const renderOpenPickerButton = (isOpen: boolean) => {
    return (
      <styles.DatePickerButton
        onClick={() => {
          setPickerDay(daySelected ? daySelected : pickerDay);
        }}
      >
        <IconCalendar />
      </styles.DatePickerButton>
    );
  };


  const renderPickerFooter = (onClose: () => void) => {
    return (
      <styles.DatePickerFooter>
        <styles.SelectedDates>
          <IconCalendar />
          <div style={{ minWidth: 10 }} />
          {getDateFormat()}
        </styles.SelectedDates>
        <styles.ButtonsWrapper>
          <div style={{ width: "10px" }} />
          <styles.ApplyButton
            clickable={true}
            onClick={() => {
              applyDay(onClose);
            }}
          >
            {t("APPLY", { ns: "translation" })}
          </styles.ApplyButton>
        </styles.ButtonsWrapper>
      </styles.DatePickerFooter>
    );
  };

  return (
    <styles.Wrapper>
      <Popover
        placement={
          props.popperPlacement ? props.popperPlacement : "bottom-start"
        }
      >
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>{renderOpenPickerButton(isOpen)}</PopoverTrigger>
            <PopoverContent
              _focus={{ boxShadow: "5px 31px 54px #695F9736" }}
              width="inherit"
              borderWidth={0}
              borderRadius={30}
              padding={0}
              overflow={"hidden"}
              boxShadow={"5px 31px 54px #695F9736"}
            >
              <styles.PopoverBody>
                <styles.DayPickerWideview
                  mode="single"
                  defaultMonth={daySelected}
                  month={month}
                  onMonthChange={setMonth}
                  pagedNavigation
                  selected={daySelected}
                  onSelect={selectDay}
                  fromDate={props?.min ? props?.min : undefined}
                  toDate={props?.max ? props?.max : undefined}
                />
              </styles.PopoverBody>
              {renderPickerFooter(onClose)}
            </PopoverContent>
          </>
        )}
      </Popover>
    </styles.Wrapper>
  );
};

export const SystemUpdatesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_350" data-name="Group 350" transform="translate(-307 -892)">
            <g id="Group_79" data-name="Group 79" transform="translate(-1129 841.934)">
                <g id="Layer_2" data-name="Layer 2" transform="translate(1435.644 50.067)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_981" data-name="Rectangle 981" width="32" height="32" transform="translate(0.356)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(2.649 1.481)">
                        <path id="Path_12499" data-name="Path 12499" d="M32.028,16.674A14.681,14.681,0,0,1,4.2,23.147a1.33,1.33,0,1,1,2.336-1.268A12.012,12.012,0,1,0,8.4,8.666h3.537a1.4,1.4,0,0,1,1.4,1.134,1.335,1.335,0,0,1-1.335,1.535H5.335A1.335,1.335,0,0,1,4,10V3.394a1.4,1.4,0,0,1,1.134-1.4A1.335,1.335,0,0,1,6.669,3.327V6.6A14.681,14.681,0,0,1,32.028,16.674Z" transform="translate(-4 -1.977)" fill="#454953"/>
                        <path id="Path_12500" data-name="Path 12500" d="M30.008,25.347a1.134,1.134,0,0,1-.667-.2L22,20.742V13.335a1.335,1.335,0,0,1,2.669,0v5.939l6.006,3.6a1.33,1.33,0,0,1-.667,2.469Z" transform="translate(-9.988 -5.312)" fill="#454953"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const SystemUpdatedNotification = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_350" data-name="Group 350" transform="translate(-307 -892)">
            <g id="Group_79" data-name="Group 79" transform="translate(-1129 841.934)">
                <g id="Layer_2" data-name="Layer 2" transform="translate(1435.644 50.067)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_981" data-name="Rectangle 981" width="32" height="32" transform="translate(0.356)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(2.649 1.481)">
                        <path id="Path_12499" data-name="Path 12499" d="M32.028,16.674A14.681,14.681,0,0,1,4.2,23.147a1.33,1.33,0,1,1,2.336-1.268A12.012,12.012,0,1,0,8.4,8.666h3.537a1.4,1.4,0,0,1,1.4,1.134,1.335,1.335,0,0,1-1.335,1.535H5.335A1.335,1.335,0,0,1,4,10V3.394a1.4,1.4,0,0,1,1.134-1.4A1.335,1.335,0,0,1,6.669,3.327V6.6A14.681,14.681,0,0,1,32.028,16.674Z" transform="translate(-4 -1.977)" fill="#454953"/>
                        <path id="Path_12500" data-name="Path 12500" d="M30.008,25.347a1.134,1.134,0,0,1-.667-.2L22,20.742V13.335a1.335,1.335,0,0,1,2.669,0v5.939l6.006,3.6a1.33,1.33,0,0,1-.667,2.469Z" transform="translate(-9.988 -5.312)" fill="#454953"/>
                    </g>
                </g>
                <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(1455 50.067)" fill="#7031eb"/>
            </g>
        </g>
    </svg>

)
import styled from "styled-components";

export namespace styles {
  export const StyledTr = styled.tr<{ depth?: boolean }>`
    text-align: left;
    height: 56px;
    background-color: ${(props) => (props.depth ? "white" : "#E5D5FA4D")};
  `;
  export const StyledTd = styled.td`
    /*width: 11%;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
  `;
  export const StyledTdURL = styled.td`
    width: 11%;
    text-align: center;
  `;
  export const NameInput = styled.input`
   /* position: absolute;
    left: 0;
    right: 0;
    color: gray;
    top: 0;
    bottom: 0;*/
    width: 85%;
    margin: 0 auto;
    /*max-width: 300px;
    min-width: 100px;*/
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    background-color: unset;
    &:hover {
      outline: 1px solid ${props => props?.theme?.primaryColor} !important;
      border-color: unset;
      box-shadow: none;
      color: black;
    }
    &:focus {
      outline: none !important;
      border-color: unset;
      box-shadow: none;
      color: black;
    }
  `;

  export const StyledThead = styled.thead``;
  export const StyledDiv = styled.div`
    overflow: hidden;
    width: 144px;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const StyledDivHolder = styled.div`
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const Edit = styled.div`
    text-align: center;
    letter-spacing: 0px;
    color: white;
    background-color: #7031eb;
    border-radius: 15px;
    width: 60px;
    &:hover {
      cursor: pointer;
    }
  `;
  export const TableHolder = styled.div`
    width: 95%;
    margin: 0 auto;
    padding: 15px 0 65px 0;
    border-radius: 30px;
    background-color: white;
    box-shadow: 2px 13px 54px #695f9714;
  `;
  export const StyledTh = styled.th`
    /*width: 11%;*/
    text-align: center;
    color: #7a86a1;
    font-size: 15px;
  `;
  export const StyledHeaderTr = styled.tr`
    border-bottom: 1px solid #d4d7df;
    height: 45px;
  `;
  export const URLbutton = styled.button`
    width: 75%;
    border-radius: 25px;
    border: 1px solid #1a0989;
    color: #1a0989;
    margin: 0 auto;
  `;
  export const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0 15px;
  `;
}

export default styles;

import wideViewsStyles from "./styles";
import {OverTimeGraph} from "../../over-time-graph/OverTimeGraph";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getOvertimeGraphDataForExplore} from "utils/kpi-render/kpiCalculation";
import {
    dateRangeSelector,
    isDefaultExploreOpenSelector,
    openDefaultExploreView,
} from "../../../store/ui";
import {IconCloseOverlay} from "../../../utils/icons/account-health";
import {DatepickerRange} from "components/datepicker-range/DatepickerRange";
import {GroupBy} from "../../../features/workspace/Workspace";
import {Kpi} from "../../../models/kpi";
import {exploreLoadingSelector, kpiStateSelector, marketingStatsLoaderSelector} from "../../../store/kpis";
import useFetchDailyStats from "../../../utils/custom-hooks/useFetchDailyStats";
import {calculateTotalForExplore} from "../../../utils/explore-helpers/calculateTotalForExplore";
import {
    ExploreTableDataEnum,
    ExploreTableFooterTypeEnum,
    GenericExploreTable,
    TableColumn,
} from "../../generic-explore-table/GenericExploreTable";
import {
    IconAcrossChannels,
    IconAcrossProducts,
} from "../../../utils/icons/explore";
import {getAcrossChannelsTableData} from "../../../utils/explore-helpers/getAcrossChannelsTableData";
import {channelsStateSelector} from "../../../store/channels";
import {Channel} from "../../../models/channel";
import {getAcrossProductsTableData} from "../../../utils/explore-helpers/getAcrossProductsTableData";
import {productsFieldsSelector} from "../../../store/products";
import {Box, Flex, theme} from "@chakra-ui/react";
import {getColorByPercentageNew, getDatesInRange} from "../../../utils/colors";
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";
import useFetchLastPeriod from "../../../utils/custom-hooks/useFetchLastPeriod";
import {mergeLastPeriodAndDailyStatsData} from "../../../utils/explore-helpers/mergeLastPeriodAndDailyStatsDataTable";
import {Row} from "react-table";
import {ExpenseTable} from "../../expense-table/ExpenseTable";
import styles from "../net-revenue-overlay/styles";
import {convertCamelCaseToString} from "../../../utils/kpi-names/kpiName";
import {
    pnlDataLanguageDisplayNamesSelector,
    pnlDataLanguageSelector,
} from "../../../store/pnl";
import InformationWrapper from "../../InformationWrapper/InformationWrapper";
import {Unit} from "../../../features/wideviews-financial/WideViewsFinancial";
import {PanelMenu} from "../../panel-menu/PanelMenu";
import {MenuButton} from "../../menu-generic/MenuGenericButton";
import {KpisEnum} from "../../../models/kpi-settings";
import {getIntegrationPlatformRoundLogo, getIntegrationPlatformThemeColor, IntegrationPlatform} from "@models";
import {Loading} from "../../loading/Loading";
import {useTheme} from "@styles";
import PlatformChip from "../../platform-chip/platformChip";


export interface ExploreProps {
    title?: string;
}

interface ExploreStateToTransfer {
    name?: string;
    displayName?: string;
    kpiEnum?: KpisEnum

}

export const MetricExplore = (props: ExploreProps) => {
    const exploreSelectorTransferState: ExploreStateToTransfer = useSelector(isDefaultExploreOpenSelector)?.stateToTransfer;
    const exploreLoading = useSelector(exploreLoadingSelector);
    const theme = useTheme();
    useFetchDailyStats(exploreSelectorTransferState?.name)
    useFetchLastPeriod(exploreSelectorTransferState?.name)
    const datesSelector = useSelector(dateRangeSelector);
    const dispatch = useDispatch();
    const {t} = useTranslation(["translation", "translation"]);
    const [unit, setUnit] = useState<Unit>(Unit.VALUE);
    const [lastPeriodDateRange, setLastPeriodDateRange] = useState({startDate: '', endDate: ''});


    // activity date period
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const pnls = useSelector(pnlDataLanguageSelector)?.data;
    const pnlsDisplayNames = useSelector(pnlDataLanguageDisplayNamesSelector)?.data;

    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [kpiData, setKpiData] = useState<Kpi[] | undefined>([])
    const [lastPeriodKpiData, setLastPeriodKpiData] = useState<Kpi[] | undefined>([])
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [lastPeriodDataChart, setLastPeriodDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const kpisFromStore = useSelector(kpiStateSelector);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any>([]);
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const channels = useSelector(channelsStateSelector)?.channels;
    const [productsData, setProductsData] = useState<Channel[]>([]);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const allProductsData = useSelector(productsFieldsSelector);
    const [ganttLabels, setGanttLabels] = useState<string[]>([]);
    const currentCurrency = useCurrencySign();

    const [expensesTableData, setExpensesTableData] = useState<any>([]);
    const [expensesTableDataBreakdown, setExpensesTableDataBreakdown] = useState<any>([]);
    const [selectedExpenses, setSelectedExpenses] = useState<any>([]);
    const currentDateRange = useSelector(dateRangeSelector);

    useEffect(() => {
        if (!!currentDateRange && !!currentDateRange.startDate && !!currentDateRange.endDate) {
            // Convert date strings to Date objects
            let fromDate = new Date(`${currentDateRange.startDate}T00:00:00.000Z`);
            let toDate = new Date(`${currentDateRange.endDate}T23:59:59.999Z`);

            if (!!startDate && !!endDate) {
                fromDate = startDate;
                toDate = endDate;
            }

            // Compute the difference in milliseconds
            const difference = toDate.getTime() - fromDate.getTime();

            // Subtract the difference to get the last period
            const lastPeriodStart = new Date(fromDate.getTime() - difference).toISOString().slice(0, 10);
            const lastPeriodEnd = new Date(toDate.getTime() - difference - 1).toISOString().slice(0, 10);


            setLastPeriodDateRange({
                startDate: (lastPeriodStart),
                endDate: (lastPeriodEnd)
            })
        } else {
            setLastPeriodDateRange({
                startDate: '',
                endDate: ''
            })
        }
    }, [currentDateRange, startDate, endDate]);

    // set kpi
    useEffect(() => {
        setKpiData(kpisFromStore?.exploreData?.data)
    }, [kpisFromStore?.exploreData?.data]);

    // set last period
    useEffect(() => {
        setLastPeriodKpiData(kpisFromStore?.lastPeriod?.data)
    }, [kpisFromStore?.lastPeriod?.data]);

    // set across channels
    useEffect(() => {
        if (!!kpiData && !!lastPeriodKpiData) {
            const datesForLastPeriod = getFixedDatesFromGraphForLastPeriod()
            const lastPeriodAcrossChannelsTableData = getAcrossChannelsTableData(lastPeriodKpiData, exploreSelectorTransferState?.name, channels, selectedProducts, {
                startDate: datesForLastPeriod?.startDate,
                endDate: datesForLastPeriod?.endDate
            });
            const acrossChannelsData = getAcrossChannelsTableData(kpiData, exploreSelectorTransferState?.name, channels, selectedProducts, {
                startDate,
                endDate
            });
            const mergedDataToDisplay = mergeLastPeriodAndDailyStatsData(acrossChannelsData, lastPeriodAcrossChannelsTableData, exploreSelectorTransferState?.name)
            setAcrossChannelsData(mergedDataToDisplay)
        }
    }, [kpiData, lastPeriodKpiData, exploreSelectorTransferState?.name, channels, selectedProducts, startDate, endDate])

    // set across products
    useEffect(() => {
        if (!!kpiData && !!lastPeriodKpiData) {
            const datesForLastPeriod = getFixedDatesFromGraphForLastPeriod()
            const lastPeriodAcrossProductsTableData = getAcrossProductsTableData(lastPeriodKpiData, exploreSelectorTransferState?.name, allProductsData, selectedChannels, {
                startDate: datesForLastPeriod?.startDate,
                endDate: datesForLastPeriod?.endDate
            });
            const acrossProductsTableData = getAcrossProductsTableData(kpiData, exploreSelectorTransferState?.name, allProductsData, selectedChannels, {
                startDate,
                endDate
            });
            const mergedDataToDisplay = mergeLastPeriodAndDailyStatsData(acrossProductsTableData, lastPeriodAcrossProductsTableData, exploreSelectorTransferState?.name)
            setProductsData(mergedDataToDisplay)
        }
    }, [kpiData, lastPeriodKpiData, exploreSelectorTransferState?.name, selectedChannels, startDate, endDate,])

    // set overtime graph
    useEffect(() => {
        if (!!kpisFromStore?.exploreData?.data && !!datesSelector?.startDate && !!datesSelector?.endDate && !!exploreSelectorTransferState?.name && !!kpisFromStore?.lastPeriod?.data) {

            const kpiDataToRender = filterKpiData(kpisFromStore?.exploreData?.data);
            const lastPeriodDataToRender = filterKpiData(kpisFromStore?.lastPeriod?.data);

            const graphData = getOvertimeGraphDataForExplore(
                kpiDataToRender,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                exploreSelectorTransferState?.name,
                {display: unit === Unit.MARGIN, firstKey: exploreSelectorTransferState?.name, secondKey: 'grossRevenue'}
            );

            const lastPeriodGraphData = getOvertimeGraphDataForExplore(
                lastPeriodDataToRender,
                lastPeriodDateRange?.startDate,
                lastPeriodDateRange?.endDate,
                groupByStatus,
                exploreSelectorTransferState?.name,
                {display: unit === Unit.MARGIN, firstKey: exploreSelectorTransferState?.name, secondKey: 'grossRevenue'}
            );


            setActualDataChart(graphData.data);
            setLabels(graphData.labels);
            setLastPeriodDataChart(lastPeriodGraphData?.data)
        }
    }, [
        kpisFromStore?.exploreData?.data,
        datesSelector?.startDate,
        datesSelector?.endDate,
        groupByStatus,
        exploreSelectorTransferState?.name,
        selectedChannels,
        selectedProducts,
        kpisFromStore?.lastPeriod?.data,
        lastPeriodDateRange,
        unit
    ]);

    useEffect(() => {

        if (!!kpiData && !!lastPeriodKpiData) {
            const datesForLastPeriod = getFixedDatesFromGraphForLastPeriod()
            const tableData = calculateExpensesTable(kpiData, startDate, endDate);
            const tableDataLastPeriod = calculateExpensesTable(lastPeriodKpiData, datesForLastPeriod?.startDate, datesForLastPeriod?.endDate);
            const newMergedData = mergeExpensesTableDataWithLastPeriodData(tableData, tableDataLastPeriod);
            setExpensesTableData(newMergedData);
        }
    }, [pnls, kpisFromStore?.exploreData?.data, kpiData, pnlsDisplayNames, pnls,
        exploreSelectorTransferState?.name, selectedChannels, selectedProducts, startDate, endDate, exploreLoading]);

    useEffect(() => {
        if (!!kpiData) {

            let filtered = filterKpiData(kpiData);
            if (!!startDate && !!endDate) {
                const allDatesInRangeOfSelect = getDatesInRange(startDate, endDate);
                filtered = filtered?.filter((kpiItem) => {
                    return allDatesInRangeOfSelect?.includes(kpiItem?.activityDate?.slice(0, 10))
                })
            }
            let totalGrossRevenue = 0;
            let totalMetric = 0;


            filtered.forEach((item: any) => {
                if (!!item?.grossRevenue) {
                    totalGrossRevenue += item?.grossRevenue;
                }
                if (!!exploreSelectorTransferState?.name && exploreSelectorTransferState?.name in item) {
                    totalMetric += item[exploreSelectorTransferState?.name]
                }
            });


            const sumByKey = (objects: Kpi[], key?: string): number => {
                let sum = 0;

                for (const obj of objects) {
                    if (!!key) {
                        const value = obj[key];

                        if (typeof value === 'number' && !isNaN(value)) {
                            sum += value;
                        }
                    }
                }

                return sum;
            }

            const listOfBreakdowns = (): string[] => {
                if (selectedExpenses?.length === 0) {
                    return []
                } else {
                    const displayNamesOfBreakdowns = ['Cost of Goods', 'Marketing Cost', 'Amazon Fees', 'Refunds Cost'];
                    const allSelectedRowsByName = selectedExpenses?.map((item: any) => item?.original?.col_0)
                    const isSelectedNamesInBreakdown = displayNamesOfBreakdowns?.some((knownName) => allSelectedRowsByName?.includes(knownName));
                    if (isSelectedNamesInBreakdown) {
                        const breakdownsToReturn: string[] = []
                        allSelectedRowsByName?.forEach((name: string | undefined) => {
                            if (name === 'Cost of Goods') {
                                breakdownsToReturn?.push('totalCogs');
                            }
                            if (name === 'Marketing Cost') {
                                breakdownsToReturn?.push('digitalMarketingSpend');
                            }
                            if (name === 'Amazon Fees') {
                                breakdownsToReturn?.push('amazonFees');
                            }
                            if (name === 'Refunds Cost') {
                                breakdownsToReturn?.push('refundsCost');
                            }
                        })
                        return breakdownsToReturn
                    } else {
                        return []
                    }
                }
            }

            const pnlsToDisplay = pnls?.filter((item) => listOfBreakdowns()?.includes(item?.fieldName))?.map((item) => item?.expression)?.flat()

            const allNetRevenuePnls = pnlsToDisplay?.filter((pnl) => !pnl?.isOperator)?.map((pnl) => {
                if (!!pnl?.operand) {
                    return pnl?.operand
                }
                return
            })?.filter((pnl) => !!pnl);

            let totalDeductions = 0;

            allNetRevenuePnls?.forEach((key) => {
                const value = (sumByKey(filtered, key)) * -1
                totalDeductions += value;
            })

            const tableData: any[] = [];

            allNetRevenuePnls?.forEach((key) => {
                const rowName = pnlsDisplayNames?.find((item: any) => item?.fieldName === (key))?.displayName ?? key;
                const foundDisplayNameFromMetricForRow = pnls?.find((metric) => metric?.fieldName === key)?.displayName;

                const value = (sumByKey(filtered, replaceDotsWithUnderscores(key))) * -1
                const rowObject = {
                    name: foundDisplayNameFromMetricForRow ?? rowName,
                    value: value,
                    margin: !!totalGrossRevenue && !!value ? value / totalGrossRevenue * 100 : 0,
                    shareOfTotal: !!totalDeductions && !!value ? value / totalDeductions * 100 : 0,
                    totalGrossRevenue: totalGrossRevenue
                };
                tableData?.push(rowObject)

            })

            setExpensesTableDataBreakdown(tableData);
        }
    }, [pnls, kpiData, pnlsDisplayNames, selectedExpenses, exploreSelectorTransferState?.name, selectedChannels, selectedProducts, startDate, endDate]);

    const getTotalValueForKeyInArray = (arr?: any[], key?: string) => {
        let total = 0;
        arr?.forEach((item) => {
            if (!!key && key in item && typeof item[key] === 'number' && !!item[key]) {
                total += item[key]
            }
        })
        return total
    }

    const calculateExpensesTable = (kpiData: any[], startDate?: Date, endDate?: Date) => {
        let filtered = filterKpiData(kpiData);

        if (!!startDate && !!endDate) {
            const allDatesInRangeOfSelect = getDatesInRange(startDate, endDate);
            filtered = filtered?.filter((kpiItem) => {
                return allDatesInRangeOfSelect?.includes(kpiItem?.activityDate?.slice(0, 10))
            })
        }

        let totalGrossRevenue = 0;
        let totalAboveMetric = 0;
        let totalMetric = 0;
        const aboveKey = getMetricNameToSum();

        filtered.forEach((item: any) => {
            if (!!item?.grossRevenue) {
                totalGrossRevenue += item?.grossRevenue;
            }
            if (!!aboveKey && aboveKey in item && !!item[aboveKey]) {
                totalAboveMetric += item[aboveKey];
            }
            if (!!exploreSelectorTransferState?.name && exploreSelectorTransferState?.name in item) {
                totalMetric += item[exploreSelectorTransferState?.name]
            }
        });

        let totalDeductions = totalAboveMetric - totalMetric;

        const sumByKey = (objects: Kpi[], key?: string): number => {
            let sum = 0;

            for (const obj of objects) {
                if (!!key) {
                    const value = obj[key];

                    if (typeof value === 'number' && !isNaN(value)) {
                        sum += value;
                    }
                }
            }

            return sum;
        }

        const fieldsToIgnore = ['grossRevenue', 'netRevenue', 'grossProfit'];
        const allPnlForMetrics: string[] = [];
        if (exploreSelectorTransferState?.name === 'netRevenue') {
            allPnlForMetrics?.push('netRevenue')
        }
        if (exploreSelectorTransferState?.name === 'grossProfit') {
            allPnlForMetrics?.push('netRevenue')
            allPnlForMetrics?.push('grossProfit')
        }
        if (exploreSelectorTransferState?.name === 'contributionProfit') {
            allPnlForMetrics?.push('netRevenue')
            allPnlForMetrics?.push('grossProfit')
            allPnlForMetrics?.push('contributionProfit')
        }

        const allMetricPnls = pnls?.filter((pnl) => !!exploreSelectorTransferState?.name && allPnlForMetrics?.includes(pnl?.fieldName));
        const flatMetricsPnl = allMetricPnls?.map((metric) => {
            return metric?.expression?.filter((pnl) => !pnl?.isOperator)?.map((pnl) => {
                if (!!pnl?.operand) {
                    if (fieldsToIgnore.includes(pnl?.operand)) {
                        return
                    }
                    return pnl?.operand
                }
                return
            })?.filter((pnl) => !!pnl);
        })?.flat();

        const tableData: any[] = [];

        flatMetricsPnl?.forEach((key) => {
            const foundDisplayNameFromMetricForRow = pnls?.find((metric) => metric?.fieldName === key)?.displayName;

            const value = Math.abs(sumByKey(filtered, key))
            const rowObject = {
                name: foundDisplayNameFromMetricForRow ?? convertCamelCaseToString(key, pnlsDisplayNames),
                value: value,
                margin: !!totalGrossRevenue && !!value ? value / totalGrossRevenue * 100 : 0,
                shareOfTotal: !!totalDeductions && !!value ? value / totalDeductions * 100 : 0,
                totalGrossRevenue: totalGrossRevenue
            };

            tableData?.push(rowObject)
        })
        return tableData
    }

    const getAcrossChannelsHeaders = (): TableColumn[] => {
        const tableColumns: TableColumn[] = [
            {
                header: "Channel",
                accessor: "channelName",
                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
            },
            {
                header: exploreSelectorTransferState?.displayName ?? '',
                accessor: exploreSelectorTransferState?.name ?? Math.random().toString(),
                cellType: !!exploreSelectorTransferState?.name && !(exploresToNotDisplayCurrency?.includes(exploreSelectorTransferState?.name)) ?
                    ExploreTableDataEnum.CURRENCY : ExploreTableDataEnum.NUMBER,
                footerType: !!exploreSelectorTransferState?.name && exploresToUseAvg?.includes(exploreSelectorTransferState?.name)
                    ? ExploreTableFooterTypeEnum.MEAN : ExploreTableFooterTypeEnum.SUM,
            },
            {
                header: "Share Of Total",
                accessor: "shareOfTotal",
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
            },
            {
                header: 'Vs Last Period',
                accessor: 'lastPeriod' + exploreSelectorTransferState?.name,
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
                cell: (value, row) => {
                    if (!!value) {
                        if (!!exploreSelectorTransferState?.name && !!row?.original && exploreSelectorTransferState?.name in row?.original) {
                            const rowDailyStatsValue = row?.original[exploreSelectorTransferState?.name];
                            return (rowDailyStatsValue / value) * 100
                        }
                    }
                    return 0
                },
                footer: (value, rows) => {
                    const dailyStatsTotal = calculateTablesTotalFromRows(rows, exploreSelectorTransferState?.name);
                    const lastPeriodTotal = calculateTablesTotalFromRows(rows, 'lastPeriod' + exploreSelectorTransferState?.name);
                    if (!!lastPeriodTotal && !!dailyStatsTotal) {
                        return ((dailyStatsTotal / lastPeriodTotal) * 100)?.toLocaleString('en-us', {
                            maximumFractionDigits: 2
                        }) + '%'
                    }
                    return 0
                }
            },
        ]

        if (exploreSelectorTransferState?.name === 'aov') {
            tableColumns[1] = {
                header: exploreSelectorTransferState?.displayName ?? '',
                accessor: exploreSelectorTransferState?.name ?? Math.random().toString(),
                cellType: !!exploreSelectorTransferState?.name && !(exploresToNotDisplayCurrency?.includes(exploreSelectorTransferState?.name)) ?
                    ExploreTableDataEnum.CURRENCY : ExploreTableDataEnum.NUMBER,
                footerType: !!exploreSelectorTransferState?.name && exploresToUseAvg?.includes(exploreSelectorTransferState?.name)
                    ? ExploreTableFooterTypeEnum.MEAN : ExploreTableFooterTypeEnum.SUM,
                footer: (value, rows) => {
                    const allSelectedRows = rows?.map((row) => row?.original);
                    const allSelectedRowsGrossRevenue = getTotalValueForKeyInArray(allSelectedRows, 'grossRevenue');
                    const allSelectedRowsOrders = getTotalValueForKeyInArray(allSelectedRows, 'orders');
                    if (!!allSelectedRowsGrossRevenue && !!allSelectedRowsOrders) {
                        return currentCurrency + (allSelectedRowsGrossRevenue / allSelectedRowsOrders)?.toLocaleString('en-us', {maximumFractionDigits: 2})
                    }
                    return 0
                }
            }
        }

        const metricsToDisplayMarginColumn = ['netRevenue', 'grossProfit', 'contributionProfit'];
        if (!!exploreSelectorTransferState?.name && (metricsToDisplayMarginColumn?.includes(exploreSelectorTransferState?.name))) {
            let header = '';
            switch (exploreSelectorTransferState?.name) {
                case 'netRevenue':
                    header = 'Net Margin'
                    break
                case 'grossProfit':
                    header = 'Gross Margin'
                    break
                case 'contributionProfit':
                    header = 'Contribution Margin'
                    break
            }
            tableColumns.splice(2, 0, {
                header: header,
                accessor: 'valueByMargin',
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
                footer: (value, rows) => {
                    const allSelectedRows = rows?.map((row) => row?.original);
                    const allSelectedRowsGrossRevenue = getTotalValueForKeyInArray(allSelectedRows, 'grossRevenue');
                    const allSelectedRowsExploreMetric = getTotalValueForKeyInArray(allSelectedRows, exploreSelectorTransferState?.name);
                    if (!!allSelectedRowsGrossRevenue && !!allSelectedRowsExploreMetric) {
                        return (allSelectedRowsExploreMetric / allSelectedRowsGrossRevenue * 100)?.toLocaleString('en-us', {maximumFractionDigits: 2}) + '%'
                    }
                    return 0
                }
            },);
        }

        return tableColumns
    }

    const setUnitHelper = (name: string) => {
        switch (name) {
            case "MARGIN":
                setUnit(Unit.MARGIN);
                break;
            case "VALUE":
                setUnit(Unit.VALUE);
                break;
            default:
                setUnit(Unit.MARGIN);
        }
    };

    const menuButtonsDataValue: MenuButton[] = [
        {
            title: "Margin",
            onClick: () => setUnitHelper("MARGIN"),
        },
        {
            title: "Value",
            onClick: () => setUnitHelper("VALUE"),
        },
    ];

    const getAcrossProductsHeaders = () => {
        const tableColumns: TableColumn[] = [
            {
                header: "Product",
                accessor: "productName",
                footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                numberOfRowsToDisplay: 2,
                cell: (value, row) => {
                    return renderAcrossProductsProductsNameCell(value, row)
                }
            },
            {
                header: exploreSelectorTransferState?.displayName ?? '',
                accessor: exploreSelectorTransferState?.name ?? Math.random().toString(),
                cellType: !!exploreSelectorTransferState?.name && !(exploresToNotDisplayCurrency?.includes(exploreSelectorTransferState?.name)) ?
                    ExploreTableDataEnum.CURRENCY : ExploreTableDataEnum.NUMBER,
                footerType: !!exploreSelectorTransferState?.name && exploresToUseAvg?.includes(exploreSelectorTransferState?.name)
                    ? ExploreTableFooterTypeEnum.MEAN : ExploreTableFooterTypeEnum.SUM,
            },
            {
                header: "Share Of Total",
                accessor: "shareOfTotal",
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
            },
            {
                header: 'Vs Last Period',
                accessor: 'lastPeriod' + exploreSelectorTransferState?.name,
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
                cell: (value, row) => {
                    if (!!value) {
                        if (!!exploreSelectorTransferState?.name && !!row?.original && exploreSelectorTransferState?.name in row?.original) {
                            const rowDailyStatsValue = row?.original[exploreSelectorTransferState?.name];
                            return (rowDailyStatsValue / value) * 100
                        }
                    }
                    return 0
                },
                footer: (value, rows) => {
                    const dailyStatsTotal = calculateTablesTotalFromRows(rows, exploreSelectorTransferState?.name);
                    const lastPeriodTotal = calculateTablesTotalFromRows(rows, 'lastPeriod' + exploreSelectorTransferState?.name);
                    if (!!lastPeriodTotal && !!dailyStatsTotal) {
                        return ((dailyStatsTotal / lastPeriodTotal) * 100)?.toLocaleString('en-us', {
                            maximumFractionDigits: 2
                        }) + '%'
                    }
                    return 0
                }
            },
        ]
        if (exploreSelectorTransferState?.name === 'aov') {
            tableColumns[1] = {
                header: exploreSelectorTransferState?.displayName ?? '',
                accessor: exploreSelectorTransferState?.name ?? Math.random().toString(),
                cellType: !!exploreSelectorTransferState?.name && !(exploresToNotDisplayCurrency?.includes(exploreSelectorTransferState?.name)) ?
                    ExploreTableDataEnum.CURRENCY : ExploreTableDataEnum.NUMBER,
                footerType: !!exploreSelectorTransferState?.name && exploresToUseAvg?.includes(exploreSelectorTransferState?.name)
                    ? ExploreTableFooterTypeEnum.MEAN : ExploreTableFooterTypeEnum.SUM,
                footer: (value, rows) => {
                    const allSelectedRows = rows?.map((row) => row?.original);
                    const allSelectedRowsGrossRevenue = getTotalValueForKeyInArray(allSelectedRows, 'grossRevenue');
                    const allSelectedRowsOrders = getTotalValueForKeyInArray(allSelectedRows, 'orders');
                    if (!!allSelectedRowsGrossRevenue && !!allSelectedRowsOrders) {
                        return currentCurrency + (allSelectedRowsGrossRevenue / allSelectedRowsOrders)?.toLocaleString('en-us', {maximumFractionDigits: 2})
                    }
                    return 0
                }
            }
        }

        const metricsToDisplayMarginColumn = ['netRevenue', 'grossProfit', 'contributionProfit'];
        if (!!exploreSelectorTransferState?.name && (metricsToDisplayMarginColumn?.includes(exploreSelectorTransferState?.name))) {
            let header = '';
            switch (exploreSelectorTransferState?.name) {
                case 'netRevenue':
                    header = 'Net Margin'
                    break
                case 'grossProfit':
                    header = 'Gross Margin'
                    break
                case 'contributionProfit':
                    header = 'Contribution Margin'
                    break
            }
            tableColumns.splice(2, 0, {
                header: header,
                accessor: 'valueByMargin',
                cellType: ExploreTableDataEnum.PERCENTAGE,
                footerType: ExploreTableFooterTypeEnum.SUM,
                footer: (value, rows) => {
                    const allSelectedRows = rows?.map((row) => row?.original);
                    const allSelectedRowsGrossRevenue = getTotalValueForKeyInArray(allSelectedRows, 'grossRevenue');
                    const allSelectedRowsExploreMetric = getTotalValueForKeyInArray(allSelectedRows, exploreSelectorTransferState?.name);
                    if (!!allSelectedRowsGrossRevenue && !!allSelectedRowsExploreMetric) {
                        return (allSelectedRowsExploreMetric / allSelectedRowsGrossRevenue * 100)?.toLocaleString('en-us', {maximumFractionDigits: 2}) + '%'
                    }
                    return 0
                }
            },);
        }

        return tableColumns
    }

    const mergeExpensesTableDataWithLastPeriodData = (expensesTableData: any[], lastPeriodExpensesTableData: any[]) => {
        const newDataToReturn: any[] = [];
        expensesTableData?.forEach((tableRow) => {
            const foundRowInLastPeriod = lastPeriodExpensesTableData?.find((lastPeriodRow) => lastPeriodRow?.name === tableRow?.name);
            if (!!foundRowInLastPeriod) {
                if (!!tableRow?.value && foundRowInLastPeriod?.value) {
                    tableRow['vsLastPeriod'] = (tableRow?.value / foundRowInLastPeriod?.value) * 100
                } else {
                    tableRow['vsLastPeriod'] = 0
                }
            } else {
                tableRow['vsLastPeriod'] = 0
            }
            newDataToReturn?.push(tableRow)
        })
        return newDataToReturn
    }

    function replaceUnderscoresWithDots(text?: string) {
        if (text?.includes('cogs')) {
            return text
        }
        return text?.replace(/_/g, '.') ?? text;
    }

    function replaceDotsWithUnderscores(text?: string) {
        if (text?.includes('cogs')) {
            return text
        }
        return text?.replace(/\./g, '_') ?? text;
    }

    // get metric name to sum in order for net revenue / gross profit / contribution profit
    const getMetricNameToSum = () => {
        switch (exploreSelectorTransferState?.name) {
            case 'netRevenue':
                return 'grossRevenue';
            case 'grossProfit':
                return 'grossRevenue'
            case 'contributionProfit':
                return 'grossRevenue'
            default:
                return ''
        }
    }

    // calculate dates for last period
    const getFixedDatesFromGraphForLastPeriod = () => {
        if (!!startDate && !!endDate && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const initialStartDate = new Date(datesSelector?.startDate)
            const initialEndDate = new Date(datesSelector?.endDate)

            const diffTime = Math.abs(initialStartDate.getTime() - initialEndDate.getTime());
            const lastPeriodFromDate = new Date(startDate.getTime() - diffTime);
            const lastPeriodToDate = new Date(endDate.getTime() - diffTime);
            return {startDate: lastPeriodFromDate, endDate: lastPeriodToDate}
        }
        return {startDate, endDate}
    }

    const filterKpiData = (kpiData: any[]) => {
        let kpiDataToRender = kpiData;

        if (selectedChannels?.length) {
            const mappedSelectedChannels = selectedChannels.map(item => item.original?.callbackId);
            kpiDataToRender = kpiDataToRender?.filter(kpiItem => mappedSelectedChannels?.includes(kpiItem?.wvChannelId));
        }

        if (selectedProducts?.length) {
            const mappedSelectedProducts = selectedProducts.map(item => item.original?.callbackId);
            kpiDataToRender = kpiDataToRender?.filter(kpiItem => mappedSelectedProducts?.includes(kpiItem?.wvProductId));
        }

        return kpiDataToRender;
    }

    const getTotalForExplore = (data?: any[], isTotalForLastPeriod?: boolean) => {
        let startDateFilter = startDate;
        let endDateFilter = endDate;

        if (isTotalForLastPeriod && !!lastPeriodDateRange?.startDate && !!lastPeriodDateRange?.endDate) {
            startDateFilter = new Date(lastPeriodDateRange?.startDate);
            endDateFilter = new Date(lastPeriodDateRange?.endDate);
        }

        const totalForMetric = calculateTotalForExplore(data, exploreSelectorTransferState?.name, selectedChannels,
            selectedProducts, {startDate: startDateFilter, endDate: endDateFilter});

        if (unit === Unit.MARGIN) {
            const totalGrossRevenue = calculateTotalForExplore(data, 'grossRevenue', selectedChannels,
                selectedProducts, {startDate: startDateFilter, endDate: endDateFilter});
            return (totalForMetric / totalGrossRevenue) * 100
        }

        return totalForMetric;
    }

    const getTotalPrefix = () => {
        if (unit === Unit.MARGIN) {
            return ''
        }
        if (!!exploreSelectorTransferState?.name && !(exploresToNotDisplayCurrency?.includes(exploreSelectorTransferState?.name))) {
            return currentCurrency
        }
        return ''
    }

    const getPercentageSymbol = () => {
        if (unit === Unit.MARGIN) {
            return '%'
        }
        return ''
    }

    const getExploreTitle = () => {
        let header = exploreSelectorTransferState?.displayName;
        if (unit === Unit.MARGIN) {
            switch (exploreSelectorTransferState?.name) {
                case 'netRevenue':
                    header = 'Net Margin'
                    break
                case 'grossProfit':
                    header = 'Gross Margin'
                    break
                case 'contributionProfit':
                    header = 'Contribution Margin'
                    break
                default:
                    header = exploreSelectorTransferState?.displayName;

            }
        }

        return header
    }

    const calculateTablesTotalFromRows = (tableRows?: Row<{}>[], fieldToCalculate?: string) => {
        let total = 0;
        tableRows?.forEach((tableRow) => {
            if (!!fieldToCalculate && fieldToCalculate in tableRow?.original) {
                total += tableRow?.original[fieldToCalculate]
            }
        })
        return total
    }

    /*const renderChip = (text?: string, color?: string, platform?: IntegrationPlatform) => {
        return <wideViewsStyles.ChipWrapper color={getIntegrationPlatformThemeColor(platform)}>
            <wideViewsStyles.Chip color={getIntegrationPlatformThemeColor(platform)}>
                <wideViewsStyles.LogoIconWrapper>
                    <wideViewsStyles.LogoIcon src={getIntegrationPlatformRoundLogo(platform)}/>
                </wideViewsStyles.LogoIconWrapper>
                <wideViewsStyles.ChipText>
                    {text}
                </wideViewsStyles.ChipText>
            </wideViewsStyles.Chip>
        </wideViewsStyles.ChipWrapper>
    }*/

    // render products name cell in products table for the added chip
    const renderAcrossProductsProductsNameCell = (value?: any, row?: Row<any>) => {
        const rowChannel = row?.original?.wvChannelId;
        const foundChannel = channels?.find((channel) => channel?.id === rowChannel);
        const foundChannelDisplayName = foundChannel?.displayName;
        const foundChannelPlatform = foundChannel?.platformType;
        return <>
            <PlatformChip text={foundChannelDisplayName} platform={foundChannelPlatform}/>
            {/*{renderChip(foundChannelDisplayName, '#68ab5e', foundChannelPlatform)}*/}
            {value}
        </>
    }

    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };

    const renderDatePicker = () => {
        return (
            <>
                {
                    !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => {
                        }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)}/>
                        : <DatepickerRange onApply={(e) => {
                        }} defaultsDate={datesSelector.preset}/>
                }
            </>
        );
    };

    const renderCloseButton = () => {
        return (
            <button onClick={() => dispatch(openDefaultExploreView({isOpen: false}))}>
                <IconCloseOverlay/>
            </button>
        );
    };

    const exploresToNotDisplayCurrency = ['orders', 'quantity']

    const renderShowByButton = () => {
        const metricsToDisplay = ['netRevenue', 'grossProfit', 'contributionProfit'];
        if (!!exploreSelectorTransferState?.name && (metricsToDisplay?.includes(exploreSelectorTransferState?.name))) {
            return (
                <Flex alignItems={'center'} gap={'8px'}>
                    <styles.ShowByText>
                        {t("SHOW_BY", {ns: "translation"})}
                    </styles.ShowByText>
                    <PanelMenu
                        data={menuButtonsDataValue}
                        title={unit === Unit.MARGIN ? "Margin" : "Value"}
                    />
                </Flex>
            )
        }
        return <></>
    }

    const renderHeader = () => {
        const exploreTotal = getTotalForExplore(kpiData);
        let exploreLastPeriodTotal = getTotalForExplore(lastPeriodKpiData, true);
        if (!!startDate && !!endDate && !!kpiData && !!lastPeriodKpiData) {
            exploreLastPeriodTotal = getTotalForExplore([...lastPeriodKpiData, ...kpiData], true);
        }
        let target = 0;
        if (!!exploreLastPeriodTotal && !!exploreTotal) {
            target = (((exploreTotal) - (exploreLastPeriodTotal)) / exploreLastPeriodTotal) * 100;
        }


        const targetColor = getColorByPercentageNew(target ?? 0, theme, exploreSelectorTransferState?.kpiEnum);

        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    <InformationWrapper text={getExploreTitle()}/>
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', {ns: 'translation'})}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderShowByButton()}
                    <Box width={'8px'} height={'100%'}/>
                    {renderDatePicker()}
                    <Box width={'8px'} height={'100%'}/>
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>
            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {getTotalPrefix()}{exploreTotal?.toLocaleString('en-us', {
                    maximumFractionDigits: 2
                })}{getPercentageSymbol()}
                </wideViewsStyles.Value>
                <wideViewsStyles.TargetText className={'shifted-text'} fontSize={15}
                                            color={targetColor}>

                    <InformationWrapper
                        shortText={`Compared to previous period: ${lastPeriodDateRange?.startDate} to ${lastPeriodDateRange?.endDate}`}
                        text={`${(!!target && target > 0) ? '+' : ''} ${(target ?? 0)?.toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}%`}
                        provided={true}
                        small={true}
                        width={200}
                        dontDisplayHeader={true}/>
                </wideViewsStyles.TargetText>
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };

    const renderOvertimeGraph = () => {
        return (
            <wideViewsStyles.SectionOne>
                <OverTimeGraph
                    clearFilters={resetFilters}
                    groupByStatus={groupByStatus}
                    setGroupBy={setGroupByStatus}
                    kpiEnum={exploreSelectorTransferState?.kpiEnum}
                    displayGantt={true}
                    setGanttLabels={setGanttLabels}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    actual={actualDataChart}
                    target={targetDataChart}
                    lastPeriod={lastPeriodDataChart}
                    displayByPercentage={unit === Unit.MARGIN}
                    labels={labels}
                />
            </wideViewsStyles.SectionOne>
        )
    }

    const exploresToUseAvg = ['aov', 'cpo', 'blendedRoas']

    const renderAcrossChannelsTable = () => {
        return (
            <wideViewsStyles.SectionOne>
                <GenericExploreTable
                    icon={<IconAcrossChannels/>}
                    setSelectedRows={setSelectedChannels}
                    data={acrossChannelsData}
                    channelsTable={true}
                    defaultSortByKey={"shareOfTotal"}
                    headers={getAcrossChannelsHeaders()}
                    height={500}
                    tableHeight={420}
                    title={t("ACROSS_CHANNELS", {ns: "translation"})}
                />
            </wideViewsStyles.SectionOne>
        )
    }

    const renderAcrossProductsTable = () => {
        return (
            <wideViewsStyles.SectionOne>
                <GenericExploreTable
                    isInfiniteScroll={true}
                    icon={<IconAcrossProducts/>}
                    setSelectedRows={setSelectedProducts}
                    data={productsData}
                    setShowAllProducts={setShowAllProducts}
                    defaultSortByKey={"shareOfTotal"}
                    headers={getAcrossProductsHeaders()}
                    height={500}
                    tableHeight={420}
                    title={t("ACROSS_PRODUCTS", {ns: "translation"})}
                />
            </wideViewsStyles.SectionOne>
        )
    }

    const renderExpenses = () => {
        return <styles.Card>
            <ExpenseTable
                displayVsLastPeriod={true}
                icon={<IconAcrossProducts/>}
                headers={[
                    t("EXPENSE_TYPE", {ns: "translation"}),
                    t("VALUE", {ns: "translation"}),
                    t("% of Gross Revenue", {ns: "translation"}),
                    t("SHARE_OF_TOTAL", {ns: "translation"}),
                    t("Vs Last Period", {ns: "translation"}),
                ]}
                data={expensesTableData}
                setSelectedRows={setSelectedExpenses}
                title={t("Expenses", {ns: "translation"})}
            />
        </styles.Card>
    }

    const renderExpensesBreakdown = () => {
        return <styles.Card>
            <ExpenseTable
                displayVsLastPeriod={true}
                icon={<IconAcrossProducts/>}
                headers={[
                    t("EXPENSE_TYPE", {ns: "translation"}),
                    t("VALUE", {ns: "translation"}),
                    t("% of Gross Revenue", {ns: "translation"}),
                    t("SHARE_OF_TOTAL", {ns: "translation"}),
                    t("Vs Last Period", {ns: "translation"}),
                ]}
                data={expensesTableDataBreakdown}
                title={t("EXPENSES_BREAKDOWN", {ns: "translation"})}
            />
        </styles.Card>
    }

    const renderExpensesTables = () => {
        const metricsToDisplay = ['netRevenue', 'grossProfit', 'contributionProfit'];
        if (!!exploreSelectorTransferState?.name && (metricsToDisplay?.includes(exploreSelectorTransferState?.name))) {
            return (<wideViewsStyles.SectionThree>
                {renderExpenses()}
                <Box h={'100%'} w={'40px'}/>
                {renderExpensesBreakdown()}
            </wideViewsStyles.SectionThree>)
        }
        return <></>

    }

    return (
        <>{exploreLoading ?
            <wideViewsStyles.LoaderWrapper><Loading/></wideViewsStyles.LoaderWrapper> :
            <wideViewsStyles.Wrapper>
                {renderHeader()}
                <Box h={'40px'} w={'100%'}/>
                {renderOvertimeGraph()}
                <Box h={'40px'} w={'100%'}/>
                {renderAcrossChannelsTable()}
                <Box h={'40px'} w={'100%'}/>
                {renderAcrossProductsTable()}
                <Box h={'40px'} w={'100%'}/>
                {renderExpensesTables()}
            </wideViewsStyles.Wrapper>}</>
    );
};

import styled from "styled-components";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { BiBell } from "react-icons/bi";

export namespace styles {
  export const Wrapper = styled.div<{ exploreIsOpen: boolean }>`
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    z-index: 99999;
    display: flex;
    justify-content: end;
    align-items: center;
    border-radius: 30px 0 0 0;
    padding: 18px;
    position: ${(props) => (props.exploreIsOpen ? "static" : "static")};
  `;
  export const Container = styled.div`
    display: flex;
    width: 100%;
    padding:0 22px;
    margin: 0 auto;
    align-items: center;
    position: relative;
    justify-content: end;
    /*transform: scale(0.95);*/
    z-index: 70;

    /*max-height: 35px;*/
  `;

  export const InviteButton = styled(MenuButton)`
    display: flex;
    color: #7a86a1;
    width: 134px;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    height: 50px;
  `;
  export const UpdateButton = styled.div`

  `;


  export const InviteFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

  `;



  export const ProfileImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    height: 50px;
    width: max-content;
    justify-content: center;
    align-items: center;
  `;
  export const Name = styled.div`
    color: #7a86a1;
    font-size: 16px;
    margin: 0 15px 0 0;
  `;
  export const NotificationsWrapper = styled.div`
    height: 50px;
    width: 50px;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const ProfileImage = styled.img`
    border-radius: 50%;
    width: 34px;
    height: 34px;
    margin: 0 12px 0 6px;
  `;

  export const AccountMenuWrapper = styled(MenuList)`
    //background: ${(props) => props.theme.backgroundColor}!important;
    background: white !important;
    z-index: 1000;
    /*max-height: 420px;
    overflow: scroll;*/
    box-shadow: 2px 13px 34px #695f9733;
    border: 1px solid #00000000;
    border-radius: 10px;
    overflow-x: unset;
    overflow-y: auto;
    //-webkit-transition: all 4s ease-in-out;
    /* &:hover {
      overflow-y: scroll;
    } */
  `;
  export const NotificationsList = styled(MenuList)`
    background: white !important;
    z-index: 1000;
    position: absolute;
    right: -220px;
    overflow-y: auto;
    top: 20px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 430px;
    z-index: 9;
  `;

  export const PopupMenu = styled(Menu)`
    background-color: white;
    z-index: 9;
    box-shadow: 0px 13px 40px #00000019;
    border: 1px solid #7070703b;
    width: max-content;
  `;

  export const UpdateButtonWrapper = styled.div`
    background-color: ${props => props?.theme?.primaryColor};
    padding: 10px 20px;
    color: white;
    border-radius: 15px;
   &:hover{
     cursor: pointer;
     opacity: .8;
   }
  `;

  export const BiBellWideview = styled(BiBell)`
    /*height: 1.8155em;
  width: 1.625em;*/
    height: 2em;
    width: 2em;
  `;
}

export default styles;

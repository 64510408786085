import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    inset: 0px 0px -180px auto;
    overflow-y: auto;
    max-height: 75vh;
    border-radius: 6px;
  `;

  export const SystemUpdate = styled.div`
    display: flex;
    padding: 15px 0;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};

  `;
  export const TextTitleUpdate = styled.h3`
    color: #03073e;
    font-weight: 600;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};

  `;
  export const LastUpdate = styled.div`
    color: #909090;
    text-decoration: underline;
    font-size: 15px;
    white-space: nowrap
  `;

  export const ContentWrapperSystem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background-color: white;
  `;
  export const SystemSubtitle = styled.div`
    display: flex;
  `;
  export const Spacer = styled.div<{ width: number }>`
    width: ${(props) => props.width + "px"};
  `;
  export const SystemTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #B3B3B3;
    min-width: 480px;
  `;

  export const NextUpdate = styled.div`
  margin-top: 8px;
    font-family: ${props => props?.theme?.fonts?.roboto?.regular};
    font-weight: 600;
  `

  export const DotRow = styled.div`
    display: flex;
    flex-direction: row;
  `;

  export const Circle = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    align-self: center;
  `;
  export const RowText = styled.div<{ system: boolean }>`
    color: ${(props) => (props.system ? "#7A86A1" : "#000000")};
    font-weight: ${(props) => (props.system ? "normal" : "bold")};
    font-size: ${(props) => (props.system ? "13px" : "15px")};
  `;

  export const ApplyChangesButton = styled.div<{inProgress?: boolean}>`
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 150px;
    /*color: #be5d5d;*/
    background-color: ${props => props?.inProgress ? "#fa5c5c" : props?.theme?.primaryColor};
    padding: 10px 15px;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

  `

  export const MessageWrapper = styled.div`
    width: 95%;
    height: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;
}

export default styles;

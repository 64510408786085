export function isHttpCodeSuccess(code: number | null) {
    return !!code && code >= 200 && code < 300;
}

export function isHttpCodeRedirect(code: number | null) {
    return !!code && code >= 300 && code < 400;
}

export function isHttpCodeClientErrors(code: number | null) {
    return !!code && code >= 300 && code < 400;
}

export function isHttpCodeServerError(code: number | null) {
    return !!code && code >= 400 && code < 500;
}

import React, {MutableRefObject, RefObject, useEffect, useRef, useState} from "react";
import {
    Box,
    Flex, Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Spinner, useDisclosure
} from "@chakra-ui/react";
import {styles} from './styles'
import {useDispatch, useSelector} from "react-redux";
import {
    authSelector,
    clearSuccessImageUpload,
    organizationSelector,
    uploadAvatarImage,
    uploadOrganizationAvatarImage,
    userSelector
} from "@store";
import {CloseModalIcon} from "../../utils/icons/save-modal";
import {uploadFilesThunk} from "../../store/operation";
import Dropzone from "../file-upload-dialog/dropzone/Dropzone";
import AvatarEditor from 'react-avatar-editor'
import {useTheme} from "@styles";

export enum UploadModalType {
    PROFILE,
    ORGANIZATION
}



interface Props {
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
    title?: string;
    modalType?: UploadModalType
}

const AvatarUploadDialog = (props: Props) => {
    const organization = useSelector(organizationSelector);
    const dispatch = useDispatch();
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = useSelector(userSelector);
    const auth = useSelector(authSelector);

    useEffect(() => {
        if(!isOpen){
            setDroppedFiles([]);
        }
    }, [isOpen])

    useEffect(() => {
        dispatch(clearSuccessImageUpload());
    }, [props?.isOpen])


    useEffect(() => {
        if(auth?.successUpdateAvatar){
            setDroppedFiles([]);
            onClose();
        }
    }, [auth?.successUpdateAvatar])


    const onFileBrowserOpen = () => {
        setDroppedFiles([]);
    }



    const handleSetFiles = (files: File[]) => {
        setDroppedFiles(files);
        if(files?.length > 0){
            onOpen();
        }
    }

    const EditAvatarModal = ({isOpen, onOpen, onClose}: { onOpen: () => void;
        onClose: () => void;
        isOpen: boolean;}) => {
        const [scale, setScale] = useState(1);
        const editorRef: any = useRef<any>(null);
        const auth = useSelector(authSelector);

        const uploadFiles = async () => {
            if(!!user?.id && !!editorRef){
                const base64String = (editorRef?.current?.getImageScaledToCanvas().toDataURL())
                const byteString = atob(base64String.split(',')[1]);
                const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const buffer = Buffer.from(ab);
                const blob = new Blob([buffer], { type: mimeType, });
                const fileName = 'output';
                const fileExtension = mimeType.split('/')[1];
                const file = new File([blob], `${fileName}.${fileExtension}`, { type: mimeType });
                const formData = new FormData();
                formData?.append('file', file);
                if(props?.modalType === UploadModalType.ORGANIZATION) {
                    !!organization?.id && await dispatch(uploadOrganizationAvatarImage({organizationId: organization?.id, data: formData }))
                }
                else {
                    !!organization?.id && await dispatch(uploadAvatarImage({userId: user?.id, data: formData, organizationId: organization?.id}))
                }

            }
        }

        return (
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex>
                            <styles.ModalTitleHolder>
                                {props?.title ?? "Upload File"}
                                <styles.ModalSubTitle>
                                    JPG or PNG only, up to 180Kb
                                </styles.ModalSubTitle>
                            </styles.ModalTitleHolder>
                            <Flex _hover={{
                                cursor: "pointer"
                            }} onClick={onClose}>
                                <CloseModalIcon />
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalBody>
                        <Flex justifyContent={"center"}>
                            <AvatarEditor
                                ref={editorRef}
                                width={250}
                                height={250}
                                scale={scale}
                                borderRadius={125}
                                backgroundColor={"white"}
                                image={droppedFiles[0]}/>
                        </Flex>

                        <Flex alignItems={"center"} mt={4}>
                            <fieldset>
                                <label >
                                    Scale
                                </label>
                                <styles.StyledInput step={"any"} id={"scaleInput"} min={0.5} max={5.5} type={"range"} value={scale} onChange={(e) => {
                                    setScale(parseFloat(e.target.value))
                                }}/>
                            </fieldset>
                        </Flex>


                    </ModalBody>
                    <ModalFooter>

                        <Flex w={"100%"} flexDirection={"column"}>
                            <Flex alignSelf={"center"} w={"max-content"}  flexDirection={"column"}>
                                <styles.UploadButton onClick={uploadFiles}>
                                    {auth?.loadingUpdateAvatar ? <Spinner /> : "Upload"}
                                </styles.UploadButton>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        )
    }



    return (
        <>
            <EditAvatarModal isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        <Modal isOpen={props?.isOpen} onClose={props?.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex>
                        <styles.ModalTitleHolder>
                            {props?.title ?? "Upload File"}
                            <styles.ModalSubTitle>
                                JPG or PNG only, up to 180Kb
                            </styles.ModalSubTitle>
                        </styles.ModalTitleHolder>
                        <Flex _hover={{
                          cursor: "pointer"
                        }} onClick={props?.onClose}>
                            <CloseModalIcon />
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Dropzone onFileBrowserOpen={onFileBrowserOpen} setDroppedFiles={handleSetFiles} droppedFiles={droppedFiles} maxFiles={1} accept={{
                        'image/jpeg': [''],
                        'image/png': [''],
                        'image/jpg': [''],
                    }} />
                    {auth?.successUpdateAvatar && "Image Uploaded"}
                </ModalBody>



            </ModalContent>
        </Modal>
            </>
    );
};

export default AvatarUploadDialog;

import styled from "styled-components";
import { ModalContent } from "@chakra-ui/react";
import { IoIosCloseCircleOutline } from "react-icons/io";

export namespace styles {
  export const CustomModalContent = styled(ModalContent)`
    width: 800px !important;
    max-width: none !important;
    max-height: none !important;
    border-radius: 10px !important;
  `;

  export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 25px;
  `;

  export const CloseButton = styled(IoIosCloseCircleOutline)`
    margin-left: auto;
    margin-right: 0;
    width: 25px !important;
    height: 25px !important;
    &:hover {
      cursor: pointer;
    }
  `;

  export const Header = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  export const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
  `;
  export const HorizontalBar = styled.div`
    width: 2px;
    height: 20px;
    margin-right: 10px;
    border: solid 2px ${(props) => props?.theme?.primaryColor};
    border-radius: 20px;
  `;

  export const SubTitle = styled.div`
    color: black;
    font-size: 14px;
    font-weight: normal;
    margin-left: 16px;
  `;
  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  `;

  export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin: 20px 0;
  `;

  export const ApplyButton = styled.div`
    width: 100px;
    min-height: 40px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-self: end;
    align-self: end;

    &:hover {
      cursor: pointer;
    }
  `;

  export const BackButton = styled.div`
    width: 100px;
    min-height: 40px;
    border-radius: 20px;
    border: solid 2px ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-self: end;
    align-self: end;

    &:hover {
      cursor: pointer;
    }
  `;
}

export default styles;

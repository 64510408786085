import wideViewsStyles from "./styles";
import { OverTimeGraph } from "../../over-time-graph/OverTimeGraph";
import { AcrossTable } from "components/across-table/AcrossTable";
import { useTranslation } from "react-i18next";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import {
    IconAcrossChannels,
    IconAcrossProducts,
    IconCalendar,
    IconClearFiltersArrow,
    IconDiamond,
    IconShare
} from "../../../utils/icons/explore";
import { TargetBadge } from "../../target-badge/TargetBadge";
import React, { useEffect, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import { kpiStateSelector } from "store/kpis";
import { Kpi } from "models/kpi";
import { currency } from "components/general-overlay/GeneralOverlay";
import { CurrencyCode, organizationDataLanguageSelector, organizationSelector } from "@store";
import { IntegrationPlatform } from "@models";
import { WebsiteEcommerceFunnel } from "components/website-ecommerce-funnel/WebsiteEcommerceFunnel";
import { fetchWebsiteData, websiteStateSelector } from "store/website";
import { effortsSelector, EffortsState } from "store/efforts";
import { getLastYear } from "utils/get-months/getLastYear";
import { currencyOptions } from "utils/currency/currencyOptions";
import { currencyFormat } from "utils/kpi-render/kpiCalculation";
import { openDefaultExploreView } from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import {useCurrencySign} from "../../../utils/custom-hooks/useCurrencySign";
import Table, {ColumnDefinitionType} from "../../wideview-table/WideviewTable";
import {Effort} from "store/efforts";

export interface ExploreProps {
    title?: string;
}


export const CrExplore = (props: ExploreProps) => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const [kpiData, setKpiData] = useState<Kpi[]>();
    const kpis = useSelector(kpiStateSelector);
    const [totalGrossRevenue, setTotalGrossRevenue] = useState(0);
    const [filteredByGroupData, setFilteredByGroupData] = useState<{}>();
    const [tableData, setTableData] = useState<{
        channelName: string;
        grossRevenue: number | string;
        shareOfTotal: number;
        vsTarget: number;
        impactOnTarget: number;
    }[]>([]);
    const organizationId = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);

    const dispatch = useDispatch();
    useEffect(() => {
        setKpiData(kpis.data?.data);
    }, [kpis.data?.data, kpiData]);

    useEffect(() => {
        dispatch(fetchWebsiteData({}));
    }, [dispatch]);

    const efforts: EffortsState = useSelector(effortsSelector);
    const websiteData = useSelector(websiteStateSelector);
    const currentCurrency = useCurrencySign();



    useEffect(() => {
        const holder = {};
        if (kpiData !== undefined && !!dataLanguage?.generalSettings?.currency) {
            let grossRevenueCounter = 0;
            kpiData!.forEach((item) => {
                if (item.grossRevenue !== undefined) {
                    grossRevenueCounter = grossRevenueCounter + item.grossRevenue;
                }
            });


            setTotalGrossRevenue(grossRevenueCounter);
            kpiData.forEach(function (d) {
                if (holder.hasOwnProperty(d?.wvChannelId)) {
                    holder[d.wvChannelId] = holder[d.wvChannelId] + d.grossRevenue;
                } else {
                    holder[d.wvChannelId] = d.grossRevenue;
                }
            });
            setFilteredByGroupData(holder);
        }
    }, [kpiData, organizationId]);

    // helpers


    useEffect(() => {
        let counter = 0;
        if (filteredByGroupData) {
            Object.keys(filteredByGroupData).forEach(key => {
                counter = filteredByGroupData[key] + counter;
            });
            const tableData = Object.keys(filteredByGroupData).map(key => {
                return {
                    channelName: IntegrationPlatform[key],
                    grossRevenue: (filteredByGroupData[key]),
                    shareOfTotal: filteredByGroupData[key] / counter * 100,
                    vsTarget: 105,
                    impactOnTarget: 1.5
                };
            });
            setTableData(tableData);
        }
    }, [filteredByGroupData]);




    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton>
            <IconShare />
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderDatePicker = () => {
        return <wideViewsStyles.HeaderButton>
            <IconCalendar />
            <wideViewsStyles.HeaderButtonText>
                {'This Month'}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow />
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                    <IconCloseOverlay />
                </button>
        );
    };

    // t('CREATED', { ns: 'translation' }),
    // t('KPI', { ns: 'translation' }) + '/' + t('METRIC', { ns: 'translation' }),
    // t('OWNER', { ns: 'translation' }),
    // t('DESCRIPTION', { ns: 'translation' }),
    // t('GOAL', { ns: 'translation' }),
    // t('START_DATE', { ns: 'translation' }),
    // t('END_DATE', { ns: 'translation' })
    const columns: ColumnDefinitionType<Effort, keyof Effort>[] = [
        {
            key: "createdAt",
            header: t('CREATED', { ns: 'translation' })
        },
        {
            key: "kpiMetric",
            header: t('KPI', { ns: 'translation' }) + '/' + t('METRIC', { ns: 'translation' })
        },
        {
            key: "description",
            header: t('DESCRIPTION', { ns: 'translation' })
        },
        {
            key: "assignee",
            header: t('OWNER', { ns: 'translation' })
        },
        {
            key: "status",
            header: t('GOAL', { ns: 'translation' })
        },
        {
            key: "startDate",
            header: t('START_DATE', { ns: 'translation' })
        },
        {
            key: "endDate",
            header: t('END_DATE', { ns: 'translation' })
        }
    ]

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    {/*{props.title}*/}
                    {t('Conversion Rate', { ns: 'translation' })}
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderResetFilters()}
                    {renderDatePicker()}
                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currencyFormat(totalGrossRevenue, 0, currentCurrency)}
                </wideViewsStyles.Value>
                <wideViewsStyles.BadgeWrapper>
                    <TargetBadge color={'#57a11e'} percentage={100} />
                </wideViewsStyles.BadgeWrapper>
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };

    return (
        <wideViewsStyles.Wrapper>
            {renderHeader()}
            <wideViewsStyles.SectionOne>
                {/*<OverTimeGraph labels={getLastYear()} />*/}
            </wideViewsStyles.SectionOne>
            {tableData !== undefined ? <wideViewsStyles.SectionTwo>
                <AcrossTable
                    icon={<IconAcrossChannels />}
                    headers={[
                        t('CHANNEL', { ns: 'translation' }),
                        t('VS_TARGET', { ns: 'translation' }),
                        t('GROSS_REVENUE', { ns: 'translation' }),
                        t('SHARE_OF_TOTAL', { ns: 'translation' }),
                        t('IMPACT_ON_TARGET', { ns: 'translation' })
                    ]}
                    data={tableData!}
                    title={t('ACROSS_CHANNELS', { ns: 'translation' })} />
                <div style={{ width: '20px' }} />
                <AcrossTable
                    icon={<IconAcrossProducts />}
                    headers={[
                        t('CHANNEL', { ns: 'translation' }),
                        t('VS_TARGET', { ns: 'translation' }),
                        t('GROSS_REVENUE', { ns: 'translation' }),
                        t('SHARE_OF_TOTAL', { ns: 'translation' }),
                        t('IMPACT_ON_TARGET', { ns: 'translation' })
                    ]}
                    data={tableData!}
                    title={t('ACROSS_PRODUCTS', { ns: 'translation' })} />
            </wideViewsStyles.SectionTwo> : null}
            <wideViewsStyles.StyledHolder>

                <wideViewsStyles.Holder>
                    <wideViewsStyles.Title>

                        {t('eCommerce Funnel', { ns: 'translation' })}
                    </wideViewsStyles.Title>

                    {
                        websiteData.data.funnelData.data !== undefined ? <WebsiteEcommerceFunnel data={websiteData.data.funnelData.data[0]} /> : null
                    }
                </wideViewsStyles.Holder>
            </wideViewsStyles.StyledHolder>

            <wideViewsStyles.SectionFour>
                <styles.Card>
                    <styles.Header>
                        <styles.Title>
                            <span style={{ marginRight: '5px' }}>
                                {<IconDiamond />}
                            </span>
                            {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        </styles.Title>
                        <DownloadButton onClick={() => console.log('download')} />
                    </styles.Header>
                    {efforts.data !== undefined ? <Table
                        title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        data={efforts.data}
                        columns={columns}
                    /> : <></>}
                </styles.Card>
            </wideViewsStyles.SectionFour>
        </wideViewsStyles.Wrapper>
    );
};

export const transportationIcon = (color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.322"
      height="44.322"
      viewBox="0 0 44.322 44.322"
    >
      <g id="warehouse" transform="translate(-0.5 -0.5)">
        <rect
          id="Rectangle_1031"
          data-name="Rectangle 1031"
          width="3.518"
          height="2.814"
          transform="translate(20.902 24.362)"
          fill={color}
        />
        <path
          id="Path_12552"
          data-name="Path 12552"
          d="M34.329,34.418H31.867v3.869a1.055,1.055,0,0,1-1.055,1.055H25.184a1.055,1.055,0,0,1-1.055-1.055V34.418H21.666A3.169,3.169,0,0,0,18.5,37.584v9.849A3.169,3.169,0,0,0,21.666,50.6H34.329A3.169,3.169,0,0,0,37.5,47.433V37.584A3.169,3.169,0,0,0,34.329,34.418ZM32.922,48.489H23.073a1.055,1.055,0,1,1,0-2.111h9.849a1.055,1.055,0,0,1,0,2.111Zm0-4.221H23.073a1.055,1.055,0,1,1,0-2.111h9.849a1.055,1.055,0,0,1,0,2.111Z"
          transform="translate(-5.337 -10.056)"
          fill={color}
        />
        <path
          id="Path_12553"
          data-name="Path 12553"
          d="M42.657,7.01l-19-6.348a3.17,3.17,0,0,0-2,0l-19,6.348A3.169,3.169,0,0,0,.5,10.021V41.648a3.174,3.174,0,0,0,3.167,3.174h37.99a3.173,3.173,0,0,0,3.166-3.174V10.021A3.169,3.169,0,0,0,42.657,7.01ZM8.239,18.019H37.083v2.116H8.239ZM37.083,15.9H8.239V13.786H37.083ZM8.239,22.251H37.083V42.706H8.239Zm34.473,19.4a1.058,1.058,0,0,1-1.056,1.058H39.194V12.728a1.057,1.057,0,0,0-1.055-1.058H7.184a1.057,1.057,0,0,0-1.055,1.058V42.706H3.666a1.058,1.058,0,0,1-1.055-1.058V10.021a1.057,1.057,0,0,1,.721-1l19-6.348a1.065,1.065,0,0,1,.668,0l19,6.348a1.057,1.057,0,0,1,.721,1Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const inventoryIcon = (color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.341"
      height="47.341"
      viewBox="0 0 47.341 47.341"
    >
      <g id="courier" transform="translate(-0.5 -0.5)" opacity="0.497">
        <path
          id="Path_12554"
          data-name="Path 12554"
          d="M40.5,18.269H51.772V8.5H40.5ZM43.881,10H48.39a1.127,1.127,0,1,1,0,2.254H43.881a1.127,1.127,0,1,1,0-2.254Zm0,4.509H48.39a1.127,1.127,0,0,1,0,2.254H43.881a1.127,1.127,0,0,1,0-2.254Z"
          transform="translate(-9.942 -1.988)"
          fill={color}
        />
        <path
          id="Path_12555"
          data-name="Path 12555"
          d="M52.413.5H35.881A3.385,3.385,0,0,0,32.5,3.881V18.91a3.385,3.385,0,0,0,3.381,3.381h1.127v3.381a1.128,1.128,0,0,0,1.8.9l5.711-4.283h7.89a3.385,3.385,0,0,0,3.381-3.381V3.881A3.385,3.385,0,0,0,52.413.5Zm-.376,16.907a1.127,1.127,0,0,1-1.127,1.127H37.384a1.127,1.127,0,0,1-1.127-1.127V5.384a1.127,1.127,0,0,1,1.127-1.127H50.91a1.127,1.127,0,0,1,1.127,1.127Z"
          transform="translate(-7.954)"
          fill={color}
        />
        <path
          id="Path_12556"
          data-name="Path 12556"
          d="M46.714,47.823H37.321V37.679A3.385,3.385,0,0,0,33.939,34.3H20.413a3.385,3.385,0,0,0-3.381,3.381v3.381H9.517V35.425a1.127,1.127,0,0,0-2.254,0v6.763A1.127,1.127,0,0,0,8.39,43.315h3.381v4.509H6.136a3.385,3.385,0,0,1-3.381-3.381V31.667A6.394,6.394,0,0,1,9.142,25.28h7.514a6.394,6.394,0,0,1,6.387,6.387v.751a1.127,1.127,0,0,0,2.254,0v-.751a8.651,8.651,0,0,0-7.055-8.489,7.839,7.839,0,0,0,1.344-9.955l2-.8a1.127,1.127,0,0,0-.837-2.093l-2.709,1.083A7.878,7.878,0,0,0,5.011,17.353v.037a7.862,7.862,0,0,0,2.547,5.788A8.651,8.651,0,0,0,.5,31.667V44.442a5.642,5.642,0,0,0,5.636,5.636H46.714a1.127,1.127,0,1,0,0-2.254ZM12.9,11.754a5.6,5.6,0,0,1,2.641.66L7.556,15.608A5.642,5.642,0,0,1,12.9,11.754ZM7.318,18.131l10.132-4.053A5.63,5.63,0,1,1,7.318,18.131ZM25.3,36.552h3.757v2.254H25.3Zm-6.012,1.127a1.128,1.128,0,0,1,1.127-1.127h2.63v3.381a1.127,1.127,0,0,0,1.127,1.127h6.011a1.127,1.127,0,0,0,1.127-1.127V36.552h2.63a1.128,1.128,0,0,1,1.127,1.127V47.823H19.286Zm-5.26,5.636h3.006v4.509H14.026Z"
          transform="translate(0 -2.237)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const manufcaturingIcon = (color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50.319"
      height="43.214"
      viewBox="0 0 50.319 43.214"
    >
      <g
        id="Group_11487"
        data-name="Group 11487"
        transform="translate(3342.66 5617.331)"
      >
        <path
          id="np_production_4760693_000000"
          d="M48.951,54.809a3.556,3.556,0,0,0-3.432,2.669H34.59a3.541,3.541,0,0,0-6.863,0H16.8a3.559,3.559,0,1,0,0,1.779H27.727a3.541,3.541,0,0,0,6.863,0H45.519a3.552,3.552,0,1,0,3.432-4.448ZM13.366,60.147a1.779,1.779,0,1,1,1.779-1.779A1.782,1.782,0,0,1,13.366,60.147Zm17.792,0a1.779,1.779,0,1,1,1.779-1.779A1.782,1.782,0,0,1,31.158,60.147Zm17.792,0a1.779,1.779,0,1,1,1.779-1.779A1.782,1.782,0,0,1,48.951,60.147Zm2.669-8.9H10.7A4.453,4.453,0,0,0,6.249,55.7v5.338A4.453,4.453,0,0,0,10.7,65.484H51.62a4.453,4.453,0,0,0,4.448-4.448V55.7A4.453,4.453,0,0,0,51.62,51.25Zm2.669,9.786a2.671,2.671,0,0,1-2.669,2.669H10.7a2.671,2.671,0,0,1-2.669-2.669V55.7A2.671,2.671,0,0,1,10.7,53.03H51.62A2.671,2.671,0,0,1,54.289,55.7Z"
          transform="translate(-3348.659 -5639.852)"
          fill={color}
          stroke="#1a0989"
          stroke-width="0.5"
        />
        <rect
          id="Rectangle_1032"
          data-name="Rectangle 1032"
          width="5.544"
          height="4.435"
          transform="translate(-3320.273 -5617.331)"
          fill={color}
        />
        <path
          id="Path_12564"
          data-name="Path 12564"
          d="M43.447,34.418H39.567v6.1A1.663,1.663,0,0,1,37.9,42.179h-8.87a1.663,1.663,0,0,1-1.663-1.663v-6.1H23.489A5,5,0,0,0,18.5,39.408V54.93a5,5,0,0,0,4.989,4.989H43.447a5,5,0,0,0,4.989-4.989V39.408A5,5,0,0,0,43.447,34.418ZM41.23,56.593H25.707a1.663,1.663,0,1,1,0-3.326H41.23a1.663,1.663,0,1,1,0,3.326Zm0-6.653H25.707a1.663,1.663,0,1,1,0-3.326H41.23a1.663,1.663,0,1,1,0,3.326Z"
          transform="translate(-3350.969 -5651.749)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const lastMileShippingIcon = (color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.995"
      height="45.233"
      viewBox="0 0 49.995 45.233"
    >
      <g
        id="Group_11529"
        data-name="Group 11529"
        transform="translate(-463.103 -258.999)"
        opacity="0.506"
      >
        <g
          id="delivery_truck"
          data-name="delivery truck"
          transform="translate(463.103 258.999)"
        >
          <path
            id="Path_12557"
            data-name="Path 12557"
            d="M25.942,9.5H10.071A3.575,3.575,0,0,0,6.5,13.071v11.11a3.575,3.575,0,0,0,3.571,3.571H25.942a3.575,3.575,0,0,0,3.571-3.571V13.071A3.575,3.575,0,0,0,25.942,9.5Zm-2.777,5.952a1.19,1.19,0,0,1-1.191,1.19H14.039a1.19,1.19,0,0,1-1.19-1.191V12.674a1.19,1.19,0,1,1,2.381,0v1.587h5.555V12.674a1.19,1.19,0,1,1,2.381,0Z"
            transform="translate(-1.739 -4.739)"
            fill={color}
          />
          <path
            id="Path_12558"
            data-name="Path 12558"
            d="M46.21,17.626A3.588,3.588,0,0,0,43.353,16.2H33.036V7.071A3.575,3.575,0,0,0,29.465,3.5H4.071A3.575,3.575,0,0,0,.5,7.071V39.607a3.575,3.575,0,0,0,3.571,3.571h1.3a6.738,6.738,0,0,0,13.267,0H30.767a6.738,6.738,0,0,0,13.267,0h2.889a3.575,3.575,0,0,0,3.571-3.571V24.53a3.592,3.592,0,0,0-.714-2.142Zm-1.9,1.428,3.571,4.762a1.2,1.2,0,0,1,.238.714v1.19H38.988a1.192,1.192,0,0,1-1.19-1.19V18.578h5.555a1.2,1.2,0,0,1,.952.476ZM4.071,5.881H29.465a1.192,1.192,0,0,1,1.19,1.19v23.41H2.881V7.071A1.192,1.192,0,0,1,4.071,5.881ZM2.881,39.607V32.862H30.656V40.8H18.64a6.738,6.738,0,0,0-13.267,0h-1.3a1.192,1.192,0,0,1-1.19-1.19Zm9.126,6.745a4.365,4.365,0,1,1,4.365-4.365A4.365,4.365,0,0,1,12.007,46.353Zm25.394,0a4.365,4.365,0,1,1,4.365-4.365A4.365,4.365,0,0,1,37.4,46.353ZM46.924,40.8H44.035a6.732,6.732,0,0,0-11-3.943V18.578h2.381V24.53A3.575,3.575,0,0,0,38.988,28.1h9.126V39.607a1.192,1.192,0,0,1-1.19,1.19Z"
            transform="translate(-0.5 -3.5)"
            fill={color}
          />
          <path
            id="Path_12559"
            data-name="Path 12559"
            d="M50.071,37.5H47.69a1.19,1.19,0,0,0,0,2.381h2.381a1.19,1.19,0,1,0,0-2.381Z"
            transform="translate(-9.996 -10.519)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
export const newPoIcon = (color?: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(0 -0.355)">
        <g id="invisible_box" data-name="invisible box">
          <rect
            id="Rectangle_1060"
            data-name="Rectangle 1060"
            width="24"
            height="24"
            transform="translate(0 0.355)"
            fill="none"
          />
        </g>
        <g
          id="icons_Q2"
          data-name="icons Q2"
          transform="translate(3.044 1.015)"
        >
          <path
            id="Path_12615"
            data-name="Path 12615"
            d="M23.252,24.311H7.015A1.015,1.015,0,0,1,6,23.3V4.015A1.015,1.015,0,0,1,7.015,3h5.074A1.015,1.015,0,0,1,13.1,4.015h0A1.015,1.015,0,0,1,12.089,5.03H8.03V22.281H22.237V17.816A1.015,1.015,0,0,1,23.252,16.8h0a1.015,1.015,0,0,1,1.015,1.015V23.3A1.015,1.015,0,0,1,23.252,24.311Z"
            transform="translate(-6 -2.493)"
            fill={color ? color : "#7031eb"}
          />
          <path
            id="Path_12616"
            data-name="Path 12616"
            d="M33.089,12.044a1.066,1.066,0,0,1-1.015,1.015H31.059v1.015a1.015,1.015,0,0,1-1.015,1.015,1.066,1.066,0,0,1-1.015-1.015V13.059H28.015A1.015,1.015,0,0,1,27,12.044a1.066,1.066,0,0,1,1.015-1.015H29.03V10.015A1.015,1.015,0,0,1,30.044,9a1.066,1.066,0,0,1,1.015,1.015V11.03h1.015A1.015,1.015,0,0,1,33.089,12.044Z"
            transform="translate(-16.344 -5.448)"
            fill={color ? color : "#7031eb"}
          />
          <path
            id="Path_12617"
            data-name="Path 12617"
            d="M26.6,4.03A4.567,4.567,0,1,1,22.03,8.6,4.567,4.567,0,0,1,26.6,4.03M26.6,2a6.6,6.6,0,1,0,6.6,6.6A6.6,6.6,0,0,0,26.6,2Z"
            transform="translate(-12.896 -2)"
            fill={color ? color : "#7031eb"}
          />
        </g>
      </g>
    </svg>
  );
};

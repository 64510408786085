import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  `;

  export const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 1px;
    margin-bottom: 20px;
    border-color: ${(props) => props.theme.backgroundColor};
    display: flex;
  `;

  export const DepartmentRowWrapper = styled.div`
    display: flex;
    margin: 80px auto 0;
    width: 80%;
    justify-content: space-around;
  `;

  export const Spacer = styled.div`
    height: 90px;
  `;
  export const WrapperTable = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    margin: 50px 0 0 0;
  `;
  export const Card = styled.div`
    background-color: white;
    width: 1250px;
    padding: 20px;
    max-height: 625px;
    display: flex;
    justify-content: space-around;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
    height: 450px;
    overflow: hidden;
  `;
  export const Table = styled.div`
    width: 60%;
  `;
  export const Chart = styled.div`
    display: flex;
  `;
}

export default styles;

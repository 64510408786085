

export const camelCaseToWords = (str: string) => {
    return str.replace(/[a-z][A-Z]/g, (match) => {
        return match[0] + " " + match[1];
    }).replace(/[A-Z]/g, (match) => {
        return " " + match;
    }).replace(/^\w/, (c) => c.toUpperCase());
}


export const checkISODateInString = (str: string) => {
    const ISO_DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
    return ISO_DATE_REGEX.test(str)
}

// @ts-ignore
import {calculateTotalForExplore} from "./calculateTotalForExplore";
import {Channel} from "../../store/channels";
import {getChannelNameById, getProductNameById} from "../get-channel-name/getChannelNameById";
import {getDatesInRange} from "../colors";

interface DataType {
    [key: string]: any;
}

export const getAcrossProductsTableData = (data?: DataType[], fieldName?: string,
                                           productsData?: { productTitle?: string; id?: string, sku?:string }[],
                                           selectedChannels?: {original?: {callbackId?: string}}[],
                                           chartSelectedDate?: {startDate?: Date, endDate?: Date}) => {
    const grouped: { [key: string]: DataType[] } = {};
    if(!!data){

        if(!!selectedChannels && selectedChannels?.length > 0){
            const mappedSelectedChannels = selectedChannels?.map((item) => item?.original?.callbackId);
            data = data?.filter((kpiItem) => {
                return mappedSelectedChannels?.includes(kpiItem?.wvChannelId)
            })
        }

        if(!!chartSelectedDate?.startDate && !!chartSelectedDate?.endDate){
            const allDatesInRangeOfSelect = getDatesInRange(chartSelectedDate?.startDate, chartSelectedDate?.endDate);
            data = data?.filter((kpiItem) => {
                return allDatesInRangeOfSelect?.includes(kpiItem?.activityDate?.slice(0,10))
            })
        }

        for (const item of data) {
            if ('wvProductId' in item) {
                const key = item.wvProductId;
                if (!grouped[key]) {
                    grouped[key] = [];
                }
                grouped[key].push(item);
            }
        }
    }




    const flattenedSums: any[] = [];

    let totalMetricForExplore = 0;

    for (const key in grouped) {
        totalMetricForExplore += calculateTotalForExplore(grouped[key], fieldName);
    }


    for (const key in grouped) {
        const groupSum = calculateTotalForExplore(grouped[key], fieldName);
        if(!!fieldName){
            const rowToPush = { [fieldName]: groupSum, callbackId: key, productName: getProductNameById(key, productsData),
                shareOfTotal: totalMetricForExplore ? (groupSum / totalMetricForExplore) * 100 : 0, wvChannelId: grouped[key][0]?.wvChannelId}
            if(fieldName === 'aov'){
                const rowGrossRevenue = calculateTotalForExplore(grouped[key], 'grossRevenue');
                const rowOrders = calculateTotalForExplore(grouped[key], 'orders');
                rowToPush['grossRevenue'] = rowGrossRevenue;
                rowToPush['orders'] = rowOrders;
            }
            const financeFields = ['netRevenue', 'grossProfit', 'contributionProfit'];

            if(financeFields?.includes(fieldName)){
                const rowGrossRevenue = calculateTotalForExplore(grouped[key], 'grossRevenue');
                rowToPush['grossRevenue'] = rowGrossRevenue;
                rowToPush['valueByMargin'] = !!rowGrossRevenue && !!groupSum ? (groupSum / rowGrossRevenue) * 100 : 0
            }
            flattenedSums.push(rowToPush);
        }
    }

    return flattenedSums;

}
import { Integration } from "@models";

export const getIntegrationNameById = (
  id: string,
  integrations: Integration[]
) => {
  const integration = integrations.find(
    (integrations) => integrations?.id === id
  );
  return integration?.name;
};

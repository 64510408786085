import styles from './styles';
// import {useTranslation} from "react-i18next";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import { getIntegrationPlatformRoundLogo, IntegrationPlatform } from "@models";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import OAuthPopup from "../../components/o-auth-popup/OAuthPopup";
import { fetchShopifyOAuthUrlUrl } from "@constants";
import { useState } from 'react';
import lottieAnimation from "../../utils/lottie-animations/line-lottie.json";

export interface ShopifyNameDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onAuthorization: (params: URLSearchParams) => void;
}

/* const shopifyNameSchema = yup.object().shape({
    shopifyName: yup.string().required(),
}); */

export const ShopifyNameDialog = (props: ShopifyNameDialogProps) => {
    const [shopName, setShopName] = useState<string>("");
    // hooks
    // const {t} = useTranslation(['translation', 'translation']);
    /* const { register, getValues, formState } = useForm({
        resolver: yupResolver(shopifyNameSchema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    }); */

    // helpers

    // renderers
    const renderTitle = () => {
        return (
            <styles.TitleWrapper>
                <styles.Title>Connect your store to Shopify</styles.Title>
            </styles.TitleWrapper>
        );
    };

    const renderConnection = () => {
        return (
            <styles.ConnectionWrapper>
                <styles.AvatarWrapper>
                    <styles.AvatarLetters>W</styles.AvatarLetters>
                </styles.AvatarWrapper>
                <styles.LottieStyle animationData={lottieAnimation} />
                <styles.ConnectionLine>
                </styles.ConnectionLine>
                <styles.ShopifyIconWrapper>
                    <styles.ShopifyIcon src={getIntegrationPlatformRoundLogo(IntegrationPlatform.SHOPIFY)} />
                </styles.ShopifyIconWrapper>
            </styles.ConnectionWrapper>
        );
    };

    const renderInput = () => {
        return (
            <styles.InputContainer>
                <styles.InputLabelWrapper>
                    <styles.InputLabel>Domain</styles.InputLabel>
                </styles.InputLabelWrapper>
                <styles.InputWrapper>
                    <styles.InputPrefixWrapper>
                        <styles.StyledInput
                            value={shopName}
                            onChange={(e) => setShopName(e.target.value)}
                            type={'text'}
                        />
                    </styles.InputPrefixWrapper>
                    <styles.InputDividerWrapper />
                    <styles.InputSuffixWrapper>
                        <styles.InputSuffix>{'.myshopify.com'}</styles.InputSuffix>
                    </styles.InputSuffixWrapper>
                </styles.InputWrapper>
            </styles.InputContainer>
        );
    };

    const renderAuthorizeButtons = () => {
        /*  if (!formState.isValid) {
             return (
                 <styles.AuthorizeButton>
                     <a href={''}>Authorized</a>
                 </styles.AuthorizeButton>
             );
         } */
        // const shopifyOAuthUrl = `${fetchShopifyOAuthUrlUrl}?shop=${shopName}.myshopify.com`;
        // const shopifyOAuthUrl = 'https://partners.shopify.com/2270315/apps/6277731/test'
        const shopifyOAuthUrl = 'https://apps.shopify.com/wideview'
        return (
            <OAuthPopup
                title={'Shopify'}
                height={800}
                width={1000}
                url={shopifyOAuthUrl ?? ''}
                onResponse={(params) => {
                    props?.onAuthorization(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >
                <styles.AuthorizeButton>
                    <a href={''}>Authorized</a>
                </styles.AuthorizeButton>
            </OAuthPopup>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsWrapper>
                <styles.BackButton onClick={() => props?.onClose()} buttonStyle={'outlined'}>
                    Back
                </styles.BackButton>
                {renderAuthorizeButtons()}
            </styles.ButtonsWrapper>
        );
    };

    const renderDialogContent = () => {
        return (
            <styles.ContentWrapper>
                {renderTitle()}
                {renderConnection()}
                <styles.InstructionWrapper>
                    <styles.Instruction><b>Please do the following to connect your Shopify store:</b></styles.Instruction>
                    {/*<styles.Instruction>1. Insert the name of your store (E.g: if your store name is 'my-store.myshopify.com', insert 'my-store').</styles.Instruction>*/}
                    <styles.Instruction>1. Click the 'Authorized' button below.</styles.Instruction>
                    <styles.Instruction>2. In the new window, select the store you want to connect from Shopify.</styles.Instruction>
                    <styles.Instruction>3. Complete the authorization process in Shopify.</styles.Instruction>
                    <styles.Instruction>4. When you done, we will connect you to Wideview (you will see your data after a few minutes)</styles.Instruction>
                </styles.InstructionWrapper>
                {/*{renderInput()}*/}
            </styles.ContentWrapper>
        );
    };

    const renderDialogFooter = () => {
        return (
            <styles.FooterWrapper>
                {renderButtons()}
            </styles.FooterWrapper>
        );
    };

    return (
        <Modal isOpen={props.isDialogOpen} onClose={() => props.onClose()}>
            <ModalOverlay onClick={() => props?.onClose()} />
            <styles.Wrapper>
                {renderDialogContent()}
                {renderDialogFooter()}
            </styles.Wrapper>
        </Modal>
    );
};

import styled from "styled-components";
import {keyframes, Table, Tbody, Th, Tr} from "@chakra-ui/react";

export namespace styles {
    export const Wrapper = styled.div`
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-radius: 20px;
    `;

    export const Card = styled.div<{ height?: number }>`
      background-color: white;
      width: 100%;
      padding: 20px;
      box-shadow: 3px 3px 10px #c6c6c6;
      
      border-radius: 20px;
      height: ${(props) => (props?.height ? props?.height + "px" : "100%")};
      max-height: ${(props) => (props?.height ? props?.height + "px" : "359px")};
      overflow: hidden;
      display: flex;
      flex-direction: column;
    `;

    export const Header = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `;

    export const Title = styled.div`
      width: 100%;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      font-family: ${(props) => props?.theme?.fonts?.header};
      font-size: 21px;
      margin-bottom: 7px;
      font-weight: bold;
    `;

    export const TableCustom = styled(Table)<{ comingSoon?: true }>`
      width: 100%;
      overflow-y: scroll;
      table-layout: fixed;
      border-collapse: separate !important;
      border-spacing: 0;
      height: 100%;
      opacity: ${props => props?.comingSoon ? 0.2 : 1};
    `;

    export const TbodyCustom = styled(Tbody)`
      /*display: block;
      height: 300px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }*/
    `;

    export const ThCustom = styled(Th)`
      text-transform: capitalize !important;
      color: gray;
      font-size: 14px;
      border-bottom: 1px solid #7a86a1 !important;
      table-layout: fixed;
      user-select: none;
    `;

    export const StyledTh = styled.th<{ shadow?: boolean }>`
      text-align: left;
      color: #7a86a1;
      padding: 15px 0 10px;
      font-size: 14px;
      border-bottom: 1px solid #dcdcdc63;
      font-family: ${(props) => props.theme.fonts.roboto.regular};

      box-shadow: ${(props) => props.shadow ? '0px 4px 6px #dcdcdc63' : 'none'};
      transition: box-shadow 0.3s ease-in-out;
    `;
    export const HeaderTr = styled.tr`
      position: sticky;
      background-color: white;
      top: 0;
      z-index: 8;
    `;

    const fadeIn = keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `;

    const fadeOut = keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `;

    export const TableWrapper = styled.div<{ tableHeight?: number }>`
      height: ${(props) =>
              props?.tableHeight ? props?.tableHeight + "px" : "220px"};
      overflow-y: auto;

      ::-webkit-scrollbar-track {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
      }


      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: transparent;
        opacity: 0;
        animation: ${fadeOut} 2s forwards;
      }

      &:hover {
        ::-webkit-scrollbar-track {
          background: #ebebeb;
        }

        ::-webkit-scrollbar-thumb {
          background: #00005B;
          opacity: 1;
          animation: ${fadeIn} 2s forwards;
        }
      }


    `;

    export const GrandTotalTd = styled.td``;
    export const GrandTotalDiv = styled.div`
      border: none !important;
      font-size: 12px;
      padding: 16px 6px !important;
    `;

    export const GrandTotalContent = styled.div`
      font-size: 12px;
      padding: 6px 6px !important;
      background-color: #7031eb;
      color: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%
      `

    export const FooterTd = styled.td`
      background-color: white;
      border-top: 1px solid #dcdcdc63;
      z-index: 8;
      font-weight: bold;
    `;
    export const FooterTr = styled.tr`
      position: sticky;
      bottom: 0;
      z-index: 8;


    `;

    export const TrCustom = styled(Tr)<{ selected?: boolean, highlightRow?: boolean }>`
      opacity: ${(props) => (props.selected ? 1 : 0.5)};
      background-color: ${(props) => props.highlightRow && props?.selected ? '#7031EB07' : 'unset'};
      font-size: 14px;
      font-family: ${(props) => props.theme.fonts.roboto.regular};
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    `;

    export const TdCustom = styled.td`
      padding: 16px 0;
    `;

    export const TdDiv = styled.div<{ numberOfLinesToShow?: number }>`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props => props?.numberOfLinesToShow ? props?.numberOfLinesToShow : 0};
    `

    export const ClearFiltersButtonWrapper = styled.div`
      width: 26px;
      height: 26px;
      border: 1px solid #ebebeb;
      border-radius: 19px;
      background: #7031EB07;
      display: flex;
      flex-direction: row;
      padding: 5px;
      justify-content: space-around;
      align-items: center;
      margin: 0 10px 0 10px;
      align-self: center;
      
      &:hover {
        cursor: pointer;
        background: #7031EB10;
      }
    `;

    export const LearnMoreButton = styled.div`
      width: 80px;
      color: ${(props) => props.theme.primaryColor};

      &:hover {
        cursor: pointer;
      }
    `
}

export default styles;

// import
import {useEffect, useState} from "react";
import {Collapse, Flex, Icon, keyframes, Spacer} from "@chakra-ui/react";

// local
import navItemStyles from "./styles";
import {NavItemData} from "../types";
import {IconType} from "react-icons/lib/cjs/iconBase";
import {NavLink} from "react-router-dom";
import tinycolor from "tinycolor2";
import {useTheme} from "@styles";
import {FaAngleDown} from "react-icons/fa";
import {navigationTabSelector, setNavigationTab} from "../../../../store/ui";
import {useDispatch, useSelector} from "react-redux";

// import * as Icons from "react-icons/fa";

interface Props {
    item?: NavItemData;
    icon?: IconType;
    noMargin?:boolean;
    isSubItem?: boolean;
    isLast?: boolean;
    disabled?: boolean
}
const openArrow = keyframes`
          0% { transform: rotate(0deg); }
          100% { transform: rotate(180deg); }
        `
const closeArrow = keyframes`
          0% { transform: rotate(180deg); }
          100% { transform: rotate(0deg); }
        `

export const NavItem = ({ item, icon, noMargin, isSubItem = false, isLast = false, disabled }: Props) => {
    // hooks
    const [active, setActive] = useState(false);
    const theme = useTheme();
    const navigationTab = useSelector(navigationTabSelector);
    const dispatch = useDispatch();

    // helpers

    // renderers

    const renderPremiumBadge = () => {
        return (
            <navItemStyles.BadgeWrapper>
                Premium
            </navItemStyles.BadgeWrapper>
        )
    }

    const renderNavItemButton = () => {
        return (
            <navItemStyles.LinkWrapper
                isSubItem={isSubItem}
                active={navigationTab === item?.title}
                onClick={() => {
                    if(item?.disabled){
                        return
                    }

                    if(!!item?.subItems){
                       if(navigationTab === item?.title ){
                          dispatch(setNavigationTab({tab: ""}));

                        }else {
                          dispatch(setNavigationTab({tab: item?.title}));
                         }
                    }else {
                        if(item?.title?.toLowerCase() === "workspace"){
                            dispatch(setNavigationTab({tab: item?.title}));
                        }
                    }

            }}
             >
                <NavLink
                    onClick={(e) => {item?.disabled && e?.preventDefault()}}
                    style={{height: '60px',  maxHeight: 'unset'}}
                    to={!item?.subItems ? item?.to ?? '#' : "#"} color={"white"} className={(props => {
                        if(item?.disabled){
                            return
                        }
                    setActive(!item?.subItems && !!item?.to && !!props?.isActive);

                    return ""
                })}>
                    {renderIcon()}
                    {renderTitle()}
                    {disabled && renderPremiumBadge()}
                    {renderArrow()}
                </NavLink>
            </navItemStyles.LinkWrapper>
        );
    };

    const renderIcon = () => {
        return (
           !isSubItem ?  <navItemStyles.IconWrapper style={{ marginInlineStart: item?.margin }}>
                {item?.icon && <Icon as={item?.icon} w={5} h={5}
                    color={navigationTab === item?.title ? theme.lightTextColor : tinycolor(theme.lightTextColor).setAlpha(0.5).toHex8String()} />}
            </navItemStyles.IconWrapper> :
               <Flex direction={"column"} style={{height: '100%', margin: '8px'}}>
                   <navItemStyles.IconWrapperSubItemTop>
                   </navItemStyles.IconWrapperSubItemTop>
                   <navItemStyles.IconWrapperSubItemBottom isLast={isLast}>
                   </navItemStyles.IconWrapperSubItemBottom>
               </Flex>

        );
    };

    const renderTitle = () => {
        return (
            <navItemStyles.TitleWrapper>
                <navItemStyles.Title active={navigationTab === item?.title || active}>{item?.title}</navItemStyles.Title>
            </navItemStyles.TitleWrapper>
        );
    };

    const renderArrow = () => {

        return (
            !!item?.subItems?.length &&
            <navItemStyles.ArrowWrapper>
                {<Icon as={FaAngleDown} w={5} h={5}
                       style={{ animationFillMode: 'forwards'}}
                       animation={navigationTab === item?.title ? `${openArrow} 0.5s 1 linear` : `${closeArrow} 0.5s 1 linear`}
                    color={navigationTab === item?.title ? theme.lightTextColor : tinycolor(theme.lightTextColor).setAlpha(0.5).toHex8String()} />
                }
            </navItemStyles.ArrowWrapper>
        );
    };

    const renderSubItems = () => {
        return (
            <navItemStyles.SubItemsWrapper>
                {item?.subItems?.map((subItem, i) => {
                    return (
                        <navItemStyles.SubItemWrapper onClick={() => {}} key={subItem?.title}>
                            <NavItem disabled={subItem?.disabled} item={subItem} isSubItem={true} isLast={item?.subItems?.length === i+1} />
                        </navItemStyles.SubItemWrapper>
                    );
                })}
            </navItemStyles.SubItemsWrapper>
        );
    };
    return (
        <navItemStyles.Wrapper
        showMargin={noMargin}
        >
            {renderNavItemButton()}
            {!noMargin && <Collapse in={navigationTab === item?.title} unmountOnExit={true}>
                {renderSubItems()}
            </Collapse>}
        </navItemStyles.Wrapper>
    );
};

import styles from "./styles";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Thead,
  Tooltip,
} from "@chakra-ui/react";
import { Column, Row, useRowSelect, useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { KpisEnum, KpiSettings } from "../../models/kpi-settings";
import { User } from "@models";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationDataLanguageSelector,
  organizationSelector,
  userSelector,
} from "@store";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { usersDataSelector } from "store/users";
import {getUserColorById, gradientDictionary} from "../../utils/colors";

interface Props {
  headers: string[];
  data: KpiSettings[];
  icon: any;
  pinnedKpi?: number[];
  setPinnedKpi: any;
  ownersList?: User[];
  index: number;
  ownersArray: any;
  setAllUsersToAssign: any;
  /* setAssignedOwners: any; */
}

interface Data {
  col_0: string;
  col_1: User | undefined;
  col_2: number;
  col_3: boolean;
  col_4: number;
}

interface OwnerProps {
  row: Row<Data>;
}

export const KpiSettingsTable = (props: Props) => {
  // hooks
  const [selectedRows, setSelectedRows] = useState([] as Row<Data>[]);
  const [selectedKpis, setSelectedKpis] = useState([] as Row<Data>[]);
  const [selectedOwners, setSelectedOwners] = useState<
    { data: string[]; id: number }[]
  >([]);
  const [flag, setFlag] = useState(false);
  const [allOwners, setAllOwners] = useState<any>([]);
  const [defaultCheckedOwners, setDefaultCheckedOwners] = useState<{
    [key: number]: string[];
  }>({});
  const [load, setLoad] = useState(true);
  const organization = useSelector(organizationSelector);
  const user = useSelector(userSelector);
  const users = useSelector(usersDataSelector);
  const dispatch = useDispatch();
  const allUsers = useSelector(usersDataSelector);
  const dataLanguage = useSelector(organizationDataLanguageSelector);

  const getKpiNameFromEnum = (kpiCode: KpisEnum) => {
    switch (kpiCode) {
      case KpisEnum.GROSS_REVENUE:
        return "Gross Revenue";
      case KpisEnum.GROSS_MARGIN:
        return "Gross Margin";
      case KpisEnum.CONVERSION_RATE:
        return "Conversion Rate (CR)";
      case KpisEnum.ORDERS:
        return "Orders";
      case KpisEnum.MARKETING_SPEND:
        return "Marketing Spend";
      case KpisEnum.RETURN_ON_AD_SPEND:
        return "Return on Ad Spend";
      case KpisEnum.MARKETING_EFFICIENCY_RATIO:
        return "Marketing Efficiency Ratio";
      case KpisEnum.ADVERTISING_COST_OF_SALES:
        return "Advertising Cost of Sales";
      case KpisEnum.COST_PER_CLICK:
        return "Cost per Click";
      case KpisEnum.COST_PER_ORDER:
        return "Cost per Order";
      case KpisEnum.COST_PER_MILE:
        return "Cost per Mile";
      case KpisEnum.CUSTOMER_ACQUISITION_COST:
        return "Customer Acquisition Cost";
      case KpisEnum.AIO:
        return "AIO";
      case KpisEnum.USERS:
        return "Users";
      case KpisEnum.NEW_USERS:
        return "New Users";
      case KpisEnum.CLICKS:
        return "Clicks";
      case KpisEnum.NET_REVENUE:
        return "Net Revenue";
      case KpisEnum.GROSS_PROFIT:
        return "Gross Profit";
      case KpisEnum.CONTRIBUTION_PROFIT:
        return "Contribution Profit";
      case KpisEnum.AVERAGE_ORDERS_VALUE:
        return "Average Orders Value";
      case KpisEnum.UNITS_SOLD:
        return "Units Sold";
      case KpisEnum.CUSTOMERS:
        return "Customers";
      case KpisEnum.NEW_CUSTOMER_ORDER:
        return "New Customer Order";
      case KpisEnum.RETURNING_CUSTOMER_ORDERS:
        return "Returning Customer Orders";
      case KpisEnum.CUSTOMER_LIFETIME_VALUE:
        return "Customer Lifetime Value";
      case KpisEnum.COST_OF_GOODS_SOLD:
        return "Cost of Good Sold";
      case KpisEnum.SHOPPING_CART_ABANDONMENT_RATE:
        return "Shopping Cart Abandonment Rate";
      case KpisEnum.RETURNS:
        return "Returns";
      case KpisEnum.REPLACEMENTS:
        return "Replacements";
      case KpisEnum.SESSIONS:
        return "Sessions";
      case KpisEnum.COST_PER_SESSION:
        return "Cost per Sessions";
      case KpisEnum.INVENTORY_TURNOVER:
        return "Inventory Turnover";
      case KpisEnum.AVERAGE_INVENTORY:
        return "Average Inventory";
      case KpisEnum.DAYS_SALES_OF_INVENTORY:
        return "Days Sales of Inventory";
      case KpisEnum.DAYS_SALE_OUTSTANDING:
        return "DSO";
      case KpisEnum.DAYS_PAYABLE_OUTSTANDING:
        return "DPO";
      case KpisEnum.CASH_CONVERSION_CYCLE:
        return "COC";
      case KpisEnum.BLENDED_ROAS:
        return "Blended ROAS";
      case KpisEnum.COGS_AVERAGE:
        return "COGS Average";
      case KpisEnum.AVERAGE_UNITS_SOLD:
        return "Average Units Solid p/ Day";
      default:
        return "Not Found";
    }
  };

  const getENUMByKpiName = (kpiCode: string) => {
    switch (kpiCode) {
      case "Marketing Spend":
        return KpisEnum.MARKETING_SPEND;
      case "Return on Ad Spend":
        return KpisEnum.RETURN_ON_AD_SPEND;
      case "Marketing Efficiency Ratio":
        return KpisEnum.MARKETING_EFFICIENCY_RATIO;
      case "Advertising Cost of Sales":
        return KpisEnum.ADVERTISING_COST_OF_SALES;
      case "Cost per Click":
        return KpisEnum.COST_PER_CLICK;
      case "Cost per Order":
        return KpisEnum.COST_PER_ORDER;
      case "Cost per Mile":
        return KpisEnum.COST_PER_MILE;
      case "Customer Acquisition Cost":
        return KpisEnum.CUSTOMER_ACQUISITION_COST;
      case "AIO":
        return KpisEnum.AIO;
      case "Users":
        return KpisEnum.USERS;
      case "New Users":
        return KpisEnum.NEW_USERS;
      case "Clicks":
        return KpisEnum.CLICKS;
      case "Gross Revenue":
        return KpisEnum.GROSS_REVENUE;
      case "Net Revenue":
        return KpisEnum.NET_REVENUE;
      case "Gross Profit":
        return KpisEnum.GROSS_PROFIT;
      case "Contribution Profit":
        return KpisEnum.CONTRIBUTION_PROFIT;
      case "Gross Margin":
        return KpisEnum.GROSS_MARGIN;
      case "Orders":
        return KpisEnum.ORDERS;
      case "Average Orders Value":
        return KpisEnum.AVERAGE_ORDERS_VALUE;
      case "Units Sold":
        return KpisEnum.UNITS_SOLD;
      case "Customers":
        return KpisEnum.CUSTOMERS;
      case "New Customer Order":
        return KpisEnum.NEW_CUSTOMER_ORDER;
      case "Returning Customer Orders":
        return KpisEnum.RETURNING_CUSTOMER_ORDERS;
      case "Customer Lifetime value":
        return KpisEnum.CUSTOMER_LIFETIME_VALUE;
      case "Cost of Goods Sold":
        return KpisEnum.COST_OF_GOODS_SOLD;
      case "Shoping Cart Abandoment Rate":
        return KpisEnum.SHOPPING_CART_ABANDONMENT_RATE;
      case "Returns":
        return KpisEnum.RETURNS;
      case "Replacements":
        return KpisEnum.REPLACEMENTS;
      case "Sessions":
        return KpisEnum.SESSIONS;
      case "Cost per Sessions":
        return KpisEnum.COST_PER_SESSION;
      case "Conversion Rate (CR)":
        return KpisEnum.CONVERSION_RATE;
      case "Inventory Turnover":
        return KpisEnum.INVENTORY_TURNOVER;
      case "Average Inventory":
        return KpisEnum.AVERAGE_INVENTORY;
      case "Days Sales of Inventory":
        return KpisEnum.DAYS_SALES_OF_INVENTORY;
      case "DSO":
        return KpisEnum.DAYS_SALE_OUTSTANDING;
      case "DPO":
        return KpisEnum.DAYS_PAYABLE_OUTSTANDING;
      case "COC":
        return KpisEnum.CASH_CONVERSION_CYCLE;
      case "Blended ROAS":
        return KpisEnum.BLENDED_ROAS;
      case "COGS Average":
        return KpisEnum.COGS_AVERAGE;
      case "Average Units Solid p/ Day":
        return KpisEnum.AVERAGE_UNITS_SOLD;
      default:
        return KpisEnum.GROSS_REVENUE;
    }
  };

  const dataToDataTable = (data: KpiSettings[]): Data[] => {
    return data.map((row, index) => {
      return {
        col_0: getKpiNameFromEnum(row.kpi),
        col_1: row.owner,
        col_2: row.target,
        col_3: false,
        col_4: getENUMByKpiName(getKpiNameFromEnum(row.kpi)),
      };
    });
  };

  const headersToColumns = (headers: string[]): Column<Data>[] => {
    const columns: Column<Data>[] = [];
    columns.push({
      Header: "",
      accessor: "col_3",
      sortType: "basic",
    } as Column<Data>);
    headers.forEach((header, index) => {
      columns.push({
        Header: header,
        accessor: "col_" + index.toString(),
        sortType: "basic",
      } as Column<Data>);
    });
    // columns.pop();

    return columns;
  };

  const data = useMemo<Data[]>(
    () => dataToDataTable(props.data),
    [props.data, defaultCheckedOwners]
  );
  const columns = useMemo<Column<Data>[]>(
    () => headersToColumns(props.headers),
    [props.headers]
  );

  useEffect(() => {
    /* props.setAssignedOwners([]); */
    const companyData = selectedKpis.map((item) =>
      getENUMByKpiName(item.original.col_0)
    );
    props.setPinnedKpi(companyData);
  }, [selectedKpis]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, useRowSelect);

  // helpers

  const selectedRow = (row: Row<Data>) => {
    row.toggleRowSelected();
    const isRowSelected = selectedRows.filter(
      (selectedRow) => selectedRow.id === row.id
    );
    if (!!isRowSelected && isRowSelected.length > 0) {
      setSelectedRows([
        ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
      ]);
    } else {
      const newSelected = [...selectedRows];
      newSelected.push(row);
      setSelectedRows(newSelected);
    }
  };

  const selectedKpi = (row: Row<Data>) => {
    const isRowSelected = selectedKpis.filter(
      (selectedRow) => selectedRow.id === row.id
    );
    if (!!isRowSelected && isRowSelected.length > 0) {
      setSelectedKpis([
        ...selectedKpis.filter((selectedRow) => selectedRow.id !== row.id),
      ]);
    } else {
      const newSelected = [...selectedKpis];
      newSelected.push(row);
      setSelectedKpis(newSelected);
    }
  };

  const checkboxCheck = (row: Row<Data>) => {
    switch (props.index) {
      case 1:
        if (!!dataLanguage?.topKpis?.company) {
          return dataLanguage?.topKpis.company.some(
            (item) => item === row.original.col_4
          );
        }
        break;
      case 2:
        if (!!dataLanguage?.topKpis?.marketing) {
          return dataLanguage?.topKpis.marketing.some(
            (item) => item === row.original.col_4
          );
        }
        break;
      case 3:
        if (!!dataLanguage?.topKpis?.finance) {
          return dataLanguage?.topKpis.finance.some(
            (item) => item === row.original.col_4
          );
        }
        break;
      case 4:
        if (!!dataLanguage?.topKpis?.website) {
          return dataLanguage?.topKpis.website.some(
            (item) => item === row.original.col_4
          );
        }
        break;
    }
    return false;
  };

  // renderers

  useEffect(() => {
    const newUsersData = users?.map((item) => {
      const currentWorkspaceIndex = item?.workspaces.findIndex(
        (workspace) => workspace?.organization === organization?.id
      );
      const currentWorkspace = item?.workspaces[currentWorkspaceIndex];

      if (!!currentWorkspace) {
        return {
          userName: item?.firstName,
          userLastName: item?.lastName,
          userId: item?.id,
          workspaceId: currentWorkspace!.id!,
          assignedKpis: currentWorkspace?.assignedKpis,
        }; // TODO
      }
      return undefined;
    });
    const filteredData = newUsersData?.filter(
      (item: any) => item !== undefined
    );
    if (!!filteredData) {
      setAllOwners(filteredData);
      props.setAllUsersToAssign(filteredData);
    }
  }, [organization?.id, users]);

  const assignKpiHandler = (owner: any, row: any) => {
    const newData = JSON.parse(JSON.stringify(allOwners));

    const indexOfOwners = allOwners.findIndex(
      (item: any) => item?.userId === owner?.userId
    );
    if (indexOfOwners > -1) {
      if (!newData[indexOfOwners].assignedKpis.includes(row.original?.col_4)) {
        newData[indexOfOwners].assignedKpis.push(row.original?.col_4);
      } else {
        const indexOfKpi = newData[indexOfOwners].assignedKpis.indexOf(
          row.original?.col_4
        );
        newData[indexOfOwners].assignedKpis.splice(indexOfKpi, 1);
      }
    }
    setAllOwners(newData);
    props.setAllUsersToAssign(newData);
  };

  const ownersList = (owner: any, row: any) => {
    assignKpiHandler(owner, row);
  };

  useEffect(() => {
    setLoad(true);
    const data: { [key: number]: string[] } = {};
    props.data.forEach((item) => {
      data[item.kpi] = [];
    });
    if (!!users && !flag) {
      users?.forEach((owner) => {
        const currentWorkspaceIndex = owner?.workspaces.findIndex(
          (workspace) => workspace?.organization === organization?.id
        );
        const currentWorkspace = owner?.workspaces[currentWorkspaceIndex];

        if (currentWorkspaceIndex > -1) {
          currentWorkspace?.assignedKpis?.forEach((kpi: number) => {
            data[kpi]?.push(owner?.id);
          });
        }
      });
      setDefaultCheckedOwners(data);
      setFlag(true);
    }
    setLoad(false);
  }, [props.data, allOwners, users, organization?.id]);

  const checkIfOwner = (row: Row<Data>) => {
    if (row?.original?.col_4 in defaultCheckedOwners) {
      return defaultCheckedOwners[row.original.col_4];
    } else return;
  };
  const getOwnersAvatar = (row: Row<Data>) => {
    if (row?.original?.col_4 in defaultCheckedOwners) {
      const usersArray: {name: string, id: string}[] = [];
      const test = defaultCheckedOwners[row.original.col_4].map((item) => {
        return users?.filter((user) => {
          return user.id === item;
        });
      });
      test.forEach((element) => {
        if (!!element) {
          usersArray.push({name : element[0].firstName + " " + element[0].lastName, id: element[0].id});
        }
      });
      return usersArray;
    } else return [];
  };

  const renderOwner = (row: Row<Data>) => {
    const copyOfOwners = JSON.parse(JSON.stringify(defaultCheckedOwners));
    const currentOwnerArray =
      copyOfOwners[getENUMByKpiName(row.original.col_0)];
    const handleClick = (id: string) => {
      const indexOfObject = currentOwnerArray.indexOf(id);
      if (indexOfObject > -1) {
        currentOwnerArray.splice(indexOfObject, 1);
      } else {
        currentOwnerArray.push(id);
      }
      setDefaultCheckedOwners(copyOfOwners);
    };

    return (
      <styles.OwnerCell>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton color={"#606AFB"}>
                {
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {getOwnersAvatar(row).length > 0 ? (
                      <AvatarGroup size="sm" max={3}>
                        {getOwnersAvatar(row).map((owner, i) => {

                          const foundUserImage = allUsers?.find((currentUser) => currentUser?.id === owner?.id);

                          return (<Avatar src={foundUserImage?.image} name={owner?.name} size="sm" color={"white"}
                                  background={!!owner?.id ? getUserColorById(owner?.id) : gradientDictionary[0]}/>
                        )
                        })}
                      </AvatarGroup>
                    ) : (
                      "Select"
                    )}
                    <div style={{ margin: "3px 0 0 2px" }}>
                      {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </div>
                  </div>
                }
              </MenuButton>
              <MenuList
                maxH={150}
                overflowY={"auto"}
                padding={0}
                minW={0}
                w={"130px"}
              >
                <MenuOptionGroup
                  defaultValue={checkIfOwner(row)}
                  padding={0}
                  type="checkbox"
                >
                  {allOwners.map((item: any, i: number) => (
                    <MenuItemOption
                      onClick={() => {
                        ownersList(item, row);
                        handleClick(item.userId);
                      }}
                      key={i}
                      style={{ color: "black" }}
                      value={item.userId}
                    >
                      {item.userName + " " + item.userLastName?.charAt(0)}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </>
          )}
        </Menu>
      </styles.OwnerCell>
    );
  };

  return (
    <styles.Wrapper>
      <styles.Card>
        <styles.TableCustom variant="simple" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <styles.TrCustom
                {...{ selected: true, ...headerGroup.getHeaderGroupProps() }}
              >
                {headerGroup.headers.map((column, i) => {
                  if (column.id === "col_3") {
                    return (
                      <styles.ThCustom w={"8%"} key={i}>
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </styles.ThCustom>
                    );
                  }
                  return (
                    <styles.ThCustom key={i}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </styles.ThCustom>
                  );
                })}
              </styles.TrCustom>
            ))}
          </Thead>
          <styles.TbodyCustom {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <styles.TrCustom
                  {...{
                    selected: row.isSelected || selectedRows.length === 0,
                    ...row.getRowProps(),
                  }}
                  onClick={() => selectedRow(row)}
                >
                  <styles.TdCustom w={"8%"}>
                    <styles.StyledCheckbox
                      colorScheme={"purple"}
                      defaultChecked={checkboxCheck(row)}
                      onChange={(e: any) => {
                        selectedKpi(row);
                      }}
                    />
                  </styles.TdCustom>
                  <styles.TdCustom>{row.cells[1]?.value}</styles.TdCustom>
                  <styles.TdCustom>
                    {load ? <></> : renderOwner(row)}
                  </styles.TdCustom>
                  {/*<styles.TdCustom>{row?.cells[3]?.value}</styles.TdCustom>*/}
                </styles.TrCustom>
              );
            })}
          </styles.TbodyCustom>
        </styles.TableCustom>
      </styles.Card>
    </styles.Wrapper>
  );
};

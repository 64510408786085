import {Box, Flex, Image, Input, Text} from "@chakra-ui/react";
import { getIntegrationPlatformName, getIntegrationPlatformRoundLogo, IntegrationPlatform, IntegrationType } from "@models";
import { organizationStateSelector } from "@store";
import { t } from "i18next";
import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Cell, Row, useTable} from "react-table";
import { changeChannelName, channelsStateSelector } from "store/channels";
import { integrationSelector } from "store/integrations";
import { getIntegrationNameById } from "utils/get-integration-name/getIntegrationNameById";
import { styles } from './styles';
import {BsPencilFill} from "react-icons/bs";
// channel name - display name
// origin name
// integration name
// platform type
// sales channels button

export const SalesChannelsTable = () => {
    const dispatch = useDispatch();
    const columnsData = [
        { displayName: "Display Name", accessor: "displayName" },
        { displayName: "Original Account Name", accessor: "originName" },
        { displayName: "Integration", accessor: "integration" },
        { displayName: "Platform", accessor: "platform" },
    ];

    const columns = React.useMemo(
        () =>
            columnsData.map((item) => {
                return {
                    Header: item.displayName,
                    accessor: item.accessor
                };
            }),

        []
    );

    const channels = useSelector(channelsStateSelector);
    const integrations = useSelector(integrationSelector);
    const organization = useSelector(organizationStateSelector);
    const dataToRender = () => {
        const data = channels?.channels?.map((item) => {
            if (!!item?.integrationId && !!integrations?.integrations && !!item?.platformType && item.type === IntegrationType.STORES) {
                return {
                    displayName: item?.displayName,
                    originName: item?.name,
                    integration: getIntegrationNameById(item?.integrationId, integrations?.integrations),
                    platform: item.platformType,
                    id: item?.id
                };
            }
            return;
        });
        return data.filter((item) => !!item);
    };

    const data = React.useMemo(() => dataToRender(), [channels?.channels, integrations]);

    const renderTypeInTable = (integrationPlatform?: number) => {
        let image = getIntegrationPlatformRoundLogo(integrationPlatform);
        let title = getIntegrationPlatformName(integrationPlatform);
        return <Flex direction={'row'} width={"100%"} justifyContent="flex-start" alignItems={'center'}>
            <Image src={image} width={'auto'} height={'20px'} marginRight={5} ml={8} />
            <Text>{t(title ?? '', { ns: "translation" })}</Text>
        </Flex>;
    };


    const InputTd = ({cell, channelId}: {cell:any, channelId: string}) => {
        const [focused, setFocused] = useState(false);
        const onFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
                const body = [{
                    displayName: e?.target?.value,
                    id: channelId
                }];
                !!organization?.organization?.id && dispatch(changeChannelName({ organizationId: organization?.organization?.id, body }));

        };

        return (<Flex alignItems={"center"} justifyContent={"space-around"}>
            <styles.NameInput onFocus={() => {
                setFocused(true)
            }} onBlur={(e) => {
                onFocusOut(e);
                setFocused(false);
            }} type={"text"} defaultValue={cell} />
            {focused ? <Box opacity={0}><BsPencilFill/></Box> :<BsPencilFill/>}
        </Flex>)

    }


    function Table({ columns, data }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        });

        return (
            <styles.StyledTable {...getTableProps()}>
                <styles.StyledThead>
                    {headerGroups.map(headerGroup => (
                        <styles.StyledHeaderTr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <styles.StyledTh {...column.getHeaderProps()}>{column.render('Header')}</styles.StyledTh>
                            ))}
                        </styles.StyledHeaderTr>
                    ))}
                </styles.StyledThead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, i) => {
                        prepareRow(row);
                        return (
                            <styles.StyledTr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    if (cell.column.id === "platform") {
                                        return <styles.StyledTd {...cell.getCellProps()}>
                                            {renderTypeInTable(cell.value)}
                                        </styles.StyledTd>;
                                    }
                                    if (cell.column.id === "displayName") {
                                        const channelId = row?.original?.id;
                                    return (
                                        <styles.StyledTd {...cell.getCellProps()} >
                                            {!!channelId && <InputTd cell={cell?.value} channelId={channelId}/>}
                                        </styles.StyledTd>
                                    )
                                    }
                                    return <styles.StyledTd {...cell.getCellProps()}>
                                        <Box ml={8}>
                                        {cell.render('Cell')}
                                        </Box>
                                    </styles.StyledTd>;
                                })}
                            </styles.StyledTr>
                        );
                    })}
                </tbody>
            </styles.StyledTable>
        );
    }


    return (
        <Table columns={columns} data={data} />
    );
};
import axios, { AxiosResponse } from "axios";
import {
  apiBaseUrl,
  authenticateUrl,
  signInUrl,
  signUpUrl,
  verifyUserUrl,
} from "@constants";
import { LoginResponse, User } from "@models";
import React from "react";

const signUp = async (user: User): Promise<AxiosResponse<LoginResponse>> => {
  return axios.post<LoginResponse>(
    signUpUrl,
    {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      password: user?.password,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

const changePassword = async ({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post<{ password: string; token: string }>(
    `${apiBaseUrl}/auth/password/reset`,
    {
      password,
      token,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const signIn = async (user: User): Promise<AxiosResponse<LoginResponse>> => {
  return axios.post<{
    user: User;
    accessToken: string;
    organizationId: string;
  }>(
    signInUrl,
    {
      email: user?.email,
      password: user?.password,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

const forgotPassword = async (
    email: string
): Promise<AxiosResponse> => {
  return axios.get<any>(`${apiBaseUrl}/auth/password/forgot/${email}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const authenticate = async (
  accessToken: string | null
): Promise<AxiosResponse<LoginResponse>> => {
  return axios.get<LoginResponse>(`${authenticateUrl}/${accessToken}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
const getWorkspacesByUserId = async ({
  userId,
}: {
  userId: string;
}): Promise<AxiosResponse<LoginResponse>> => {
  return axios.get<LoginResponse>(`${apiBaseUrl}/workspaces/${userId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const switchOrganization = async (
  organizationId: string
): Promise<AxiosResponse<any>> => {
  return axios.get<LoginResponse>(
    `${apiBaseUrl}/auth/switch/${organizationId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};
const resetPassword = async (email: string): Promise<AxiosResponse<any>> => {
  return axios.get<LoginResponse>(
    `${apiBaseUrl}/auth/password/forgot/${email}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const verifyUser = async (
  token: string
): Promise<AxiosResponse<{ verified: boolean }>> => {
  return axios.get<{ verified: boolean }>(`${verifyUserUrl}/${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const updateUser = async (
  userId: string,
  user: User
): Promise<AxiosResponse<any, any>> => {
  return axios.put<any>(
    `${apiBaseUrl}/users/${userId}`,
    {
      ...user,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

const uploadAvatarImage = (
    userId: string,
    data: FormData,
) => {
  return axios.put(
      `${apiBaseUrl}/users/${userId}/avatar/upload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
  );
};

export const authService = {
  signUp,
  signIn,
  authenticate,
  verifyUser,
  switchOrganization,
  updateUser,
  resetPassword,
  changePassword,
  getWorkspacesByUserId,
  forgotPassword,
  uploadAvatarImage
};

import {Input, InputLeftElement} from "@chakra-ui/react";
import styled from "styled-components";

export namespace styles {
  export const StyledInput = styled.input`
    border-radius: 35px;
    border: 0.5px solid #ebecf8;
    text-align: center;
    min-width: 50px;
    max-width: 70px;
  `;
  export const StyledTotal = styled.th`
    background-color: #7031eb;
    color: white;
    font-weight: normal;
    position: sticky;
    right: 0;
    border-radius: 4px;
    transform: scale(1.05, 1.05);
  `;
  export const Title = styled.div``;
  export const titleHolder = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const StyledTitle = styled.td<{
    isSubRow?: boolean;
    isDeduction?: boolean;
  }>`
    height: 55px;
    background-color: #f2f3ff;
    position: sticky;
    left: 0;
    z-index: 5;
    border-left: ${(props) =>
      props?.isSubRow
        ? props?.isDeduction
          ? "5px solid #F76D6F"
          : "5px solid #A2DBB6"
        : ""};

    white-space: nowrap;
    color: ${(props) => (props.isSubRow ? "#070036" : "#434343")};
    font-size: ${(props) => (props.isSubRow ? "15px" : "14px")};
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  `;
  export const ThPlaceHolder = styled.th`
    position: sticky;
    left: 0;
    z-index: 5;
    opacity: 1;
    overflow: hidden;
  `;
  export const StickyTh = styled.th`
    position: sticky;
    top: 0;
  `;
  export const TitleMargin = styled.div<{ isSubRow?: boolean }>`
    margin: ${(props) => (props.isSubRow ? "0" : "0 0 0 25px")};
  `;

  export const StyledTr = styled.tr<{ isSubRow: boolean }>`
    background-color: ${(props) => (props.isSubRow ? "#F2F3FF" : "white")};
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  `;
  export const StyledTotalFooter = styled.td`
    background-color: white;
    position: sticky;
    right: 0;
    box-shadow: 2px 2px 25px 1px rgba(0, 0, 0, 0.15);
  `;
  export const StyledTable = styled.table`
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const TableInput = styled(Input)`
    min-width: 99px !important;
    text-align: center !important;
    background-color: transparent;
    border: #ebebeb solid 2px;
    /* padding: 5px 0px; */
    font-weight: 500;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    //font-size: 14px !important;
    border-radius: 20px;
    padding-inline-start: 20px!important;
  }
  `;

  export const TableInputLeftElementWrapper = styled(InputLeftElement)`
    width: 20px!important;
  `

  export const Table = styled.div`
    padding: 1rem;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: #ebebeb;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #1a0989;
      border-radius: 10px;
    }

    table {
      border-collapse: separate;
      border-spacing: 0 5px;
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      th,
      td {
        margin: 0;
        padding: 0.25rem;
        border-right: 0.5px solid #ebecf8;
        min-width: 75px;
        :last-child {
          border-right: 0;
        }
        :first-child {
          border-right: 0;
        }
      }
    }
  `;
}
export default styles;

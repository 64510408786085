import {
  amazonAdsRoundLogo,
  amazonSellerRoundLogo, clickupRoundLogo, ebayRoundLogo,
  facebookAdsRoundLogo,
  fedexRoundLogo,
  floshipRoundLogo, globalAddressMeRoundLogo,
  googleAdsRoundLogo,
  googleAnalyticsRoundLogo,
  hubspotRoundLogo,
  integrationStatusConnected,
  integrationStatusError,
  integrationStatusWarning,
  jungleScoutRoundLogo,
  klaviyoRoundLogo,
  magentoRoundLogo,
  mondayRoundLogo,
  notionRoundLogo,
  outbrainRoundLogo, quickbooksRoundLogo,
  shipbobRoundLogo,
  shipstationRoundLogo,
  shopifyRoundLogo,
  similarWebRoundLogo,
  skubanaRoundLogo, slackRoundLogo,
  taboolaRoundLogo,
  tiktokRoundLogo,
  walmartRoundLogo, wixRoundLogo,
  woocommerceRoundLogo, xeroRoundLogo, zohokBooksRoundLogo, zohokCrmRoundLogo, zohokInventoryRoundLogo,
} from "../constants/integration";
import { Organization } from "./organization";

export interface GoogleAdsAccount {
  clientCustomer?: string,
  level?: number,
  manager?: boolean,
  descriptiveName?: string,
  currencyCode?: string,
  timeZone?: string,
  id?: number,
  resourceName?: string,
  isActive?: boolean
}

export interface FacebookAdsAccount {
  id?: string,
  accountId?: string,
  name?: string,
  age?: string,
  isActive?: boolean
  account_id?: string;

}

export interface AmazonAdsProfile {
  profileId?: string,
  countryCode?: string,
  currencyCode?: string,
  dailyBudget?: string,
  timezone?: string,
  accountInfo?: {
    marketplaceStringId?: string;
    id?: string;
    type?: string;
    name?: string;
    validPaymentMethod?: boolean
  }
}

export interface Integration {
  id?: string;
  name?: string;
  type?: IntegrationType;
  platform?: IntegrationPlatform;
  status?: IntegrationStatus;
  accountName?: string;
  object?: any;
  accountId?: string;
  updatedAt?: string;
  userId?: string;
  firstDayOfData?: string | null;
  googleAdsAccounts?: GoogleAdsAccount[];
  facebookAdsAccounts?: FacebookAdsAccount[];
  amazonAdsProfiles?: AmazonAdsProfile[];
}

export enum IntegrationType {
  STORES,
  MARKETING,
  WEBSITE,
  CRM,
  TEAM_MANAGEMENT,
  BENCHMARKETING,
  OPERATION,
  FINANCE,
}

export enum IntegrationPlatform {
  AMAZON_ADS,
  AMAZON_SELLER,
  FACEBOOK_ADS,
  GOOGLE_ADS,
  GOOGLE_ANALYTICS,
  HUBSPOT,
  JUNGLE_SCOUT,
  KLAVIYO,
  MAGENTO,
  MONDAY,
  NOTION,
  SHOPIFY,
  SIMILARWEB,
  TIKTOK,
  WOOCOMMERCE,
  FLOSHIP,
  FEDEX,
  EASYSHIP = 17,
  SHIPSTATION = 18,
  QUICKBOOKS,
  TABOOLA,
  SKUBANA,
  SHIPBOB,
  OUTBRAIN,
  WALMART,
  WIX,
  DHL,
  UFRIEGHT,
  EBAY,
  CLICKUP,
  SLACK,
  ZOHO_CRM,
  ZOHO_BOOKS,
  ZOHO_INVENTORY,
  XERO,
  GLOBAL_ADDRESS_ME
}

export enum IntegrationStatus {
  CONNECTED,
  ERROR,
  WARNING,
  NOT_SYNCED
}

// helpers
export const countIntegrationInOrganization = (
  integrationPlatform?: IntegrationPlatform,
  integrations?: Integration[]
) => {
  return (
    integrations?.filter(
      (integration) => integration?.platform === integrationPlatform
    )?.length ?? 0
  );
};

export const getIntegrationPlatformRoundLogo = (
  integrationPlatform?: IntegrationPlatform
) => {
  switch (integrationPlatform) {
    case IntegrationPlatform.AMAZON_ADS:
      return amazonAdsRoundLogo;
    case IntegrationPlatform.AMAZON_SELLER:
      return amazonSellerRoundLogo;
    case IntegrationPlatform.FACEBOOK_ADS:
      return facebookAdsRoundLogo;
    case IntegrationPlatform.GOOGLE_ADS:
      return googleAdsRoundLogo;
    case IntegrationPlatform.GOOGLE_ANALYTICS:
      return googleAnalyticsRoundLogo;
    case IntegrationPlatform.HUBSPOT:
      return hubspotRoundLogo;
    case IntegrationPlatform.JUNGLE_SCOUT:
      return jungleScoutRoundLogo;
    case IntegrationPlatform.KLAVIYO:
      return klaviyoRoundLogo;
    case IntegrationPlatform.MAGENTO:
      return magentoRoundLogo;
    case IntegrationPlatform.MONDAY:
      return mondayRoundLogo;
    case IntegrationPlatform.NOTION:
      return notionRoundLogo;
    case IntegrationPlatform.SHOPIFY:
      return shopifyRoundLogo;
    case IntegrationPlatform.SIMILARWEB:
      return similarWebRoundLogo;
    case IntegrationPlatform.TIKTOK:
      return tiktokRoundLogo;
    case IntegrationPlatform.WOOCOMMERCE:
      return woocommerceRoundLogo;
    case IntegrationPlatform.SKUBANA:
      return skubanaRoundLogo;
    case IntegrationPlatform.SHIPBOB:
      return shipbobRoundLogo;
    case IntegrationPlatform.WALMART:
      return walmartRoundLogo;
    case IntegrationPlatform.FLOSHIP:
      return floshipRoundLogo;
    case IntegrationPlatform.FEDEX:
      return fedexRoundLogo;
    case IntegrationPlatform.SHIPSTATION:
      return shipstationRoundLogo;
    case IntegrationPlatform.TABOOLA:
      return taboolaRoundLogo;
    case IntegrationPlatform.OUTBRAIN:
      return outbrainRoundLogo;
    case IntegrationPlatform.QUICKBOOKS:
      return quickbooksRoundLogo;
    case IntegrationPlatform.WIX:
      return wixRoundLogo;
    case IntegrationPlatform.EBAY:
      return ebayRoundLogo;
    case IntegrationPlatform.CLICKUP:
      return clickupRoundLogo;
    case IntegrationPlatform.SLACK:
      return slackRoundLogo;
    case IntegrationPlatform.ZOHO_CRM:
      return zohokCrmRoundLogo;
    case IntegrationPlatform.ZOHO_BOOKS:
      return zohokBooksRoundLogo;
    case IntegrationPlatform.ZOHO_INVENTORY:
      return zohokInventoryRoundLogo;
    case IntegrationPlatform.XERO:
      return xeroRoundLogo;
      case IntegrationPlatform.GLOBAL_ADDRESS_ME:
      return globalAddressMeRoundLogo;
    default:
      return undefined;
  }
};

export const getIntegrationPlatformName = (
  integrationPlatform?: IntegrationPlatform
) => {
  switch (integrationPlatform) {
    case IntegrationPlatform.AMAZON_ADS:
      return "AMAZON_ADS";
    case IntegrationPlatform.AMAZON_SELLER:
      return "AMAZON_SELLER";
    case IntegrationPlatform.FACEBOOK_ADS:
      return "FACEBOOK_ADS";
    case IntegrationPlatform.GOOGLE_ADS:
      return "GOOGLE_ADS";
    case IntegrationPlatform.GOOGLE_ANALYTICS:
      return "GOOGLE_ANALYTICS";
    case IntegrationPlatform.HUBSPOT:
      return "HUBSPOT";
    case IntegrationPlatform.JUNGLE_SCOUT:
      return "JUNGLE_SCOUT";
    case IntegrationPlatform.KLAVIYO:
      return "KLAVIYO";
    case IntegrationPlatform.MAGENTO:
      return "MAGENTO";
    case IntegrationPlatform.MONDAY:
      return "MONDAY";
    case IntegrationPlatform.NOTION:
      return "NOTION";
    case IntegrationPlatform.SHOPIFY:
      return "SHOPIFY";
    case IntegrationPlatform.SIMILARWEB:
      return "SIMILARWEB";
    case IntegrationPlatform.TIKTOK:
      return "TIKTOK";
    case IntegrationPlatform.WOOCOMMERCE:
      return "WOOCOMMERCE";
    case IntegrationPlatform.SKUBANA:
      return "SKUBANA";
    case IntegrationPlatform.SHIPBOB:
      return "SHIPBOB";
    case IntegrationPlatform.FLOSHIP:
      return "FLOSHIP";
    case IntegrationPlatform.FEDEX:
      return "FEDEX";
    case IntegrationPlatform.TABOOLA:
      return "TABOOLA";
    case IntegrationPlatform.OUTBRAIN:
      return "OUTBRAIN";
    case IntegrationPlatform.SHIPSTATION:
      return "SHIPSTATION";
    case IntegrationPlatform.WALMART:
      return "WALMART";
    case IntegrationPlatform.QUICKBOOKS:
      return "QUICKBOOKS";
    case IntegrationPlatform.WIX:
      return "WIX";
    case IntegrationPlatform.EBAY:
      return "EBAY";
    case IntegrationPlatform.CLICKUP:
      return "CLICKUP";
    case IntegrationPlatform.SLACK:
      return "SLACK";
    case IntegrationPlatform.ZOHO_CRM:
      return "ZOHO_CRM";
    case IntegrationPlatform.ZOHO_BOOKS:
      return "ZOHO_BOOKS";
    case IntegrationPlatform.ZOHO_INVENTORY:
      return "ZOHO_INVENTORY";
    case IntegrationPlatform.XERO:
      return "XERO";
    case IntegrationPlatform.GLOBAL_ADDRESS_ME:
      return "GLOBAL_ADDRESS_ME";
    default:
      return undefined;
  }
};

export const getIntegrationPlatformIncText = (
  integrationPlatform?: IntegrationPlatform
) => {
  switch (integrationPlatform) {
    case IntegrationPlatform.AMAZON_ADS:
      return "BY_AMAZON_INC";
    case IntegrationPlatform.AMAZON_SELLER:
      return "BY_AMAZON_INC";
    case IntegrationPlatform.FACEBOOK_ADS:
      return "BY_META_INC";
    case IntegrationPlatform.GOOGLE_ADS:
      return "BY_GOOGLE_INC";
    case IntegrationPlatform.GOOGLE_ANALYTICS:
      return "BY_GOOGLE_INC";
    case IntegrationPlatform.HUBSPOT:
      return "BY_HUBSPOT_INC";
    case IntegrationPlatform.JUNGLE_SCOUT:
      return "BY_JUNGLE_SCOUT_INC";
    case IntegrationPlatform.KLAVIYO:
      return "BY_KLAVIYO_INC";
    case IntegrationPlatform.MAGENTO:
      return "BY_MAGENTO_ASSOCIATION";
    case IntegrationPlatform.MONDAY:
      return "BY_MONDAY_INC";
    case IntegrationPlatform.NOTION:
      return "BY_NOTION_INC";
    case IntegrationPlatform.SHOPIFY:
      return "BY_SHOPIFY_INC";
    case IntegrationPlatform.SIMILARWEB:
      return "BY_SIMILARWEB_INC";
    case IntegrationPlatform.TIKTOK:
      return "BY_TIKTOK_INC";
    case IntegrationPlatform.WOOCOMMERCE:
      return "BY_WOOCOMMERCE";
    case IntegrationPlatform.SKUBANA:
      return "BY_SKUBANA";
    case IntegrationPlatform.SHIPBOB:
      return "BY_SHIPBOB";
    case IntegrationPlatform.FLOSHIP:
      return "BY_FLOSHIP";
    case IntegrationPlatform.FEDEX:
      return "BY_FEDEX";
    case IntegrationPlatform.QUICKBOOKS:
      return "BY_QUICKBOOKS";
    case IntegrationPlatform.WIX:
      return "BY_WIX";
    case IntegrationPlatform.EBAY:
      return "BY_EBAY";
    case IntegrationPlatform.CLICKUP:
      return "BY_CLICKUP";
    case IntegrationPlatform.SLACK:
      return "BY_SLACK";
    case IntegrationPlatform.ZOHO_CRM:
      return "BY_ZOHO";
    case IntegrationPlatform.XERO:
      return "BY_XERO";
    default:
      return undefined;
  }
};

export const getIntegrationStatusImage = (
  integrationStatus?: IntegrationStatus
) => {
  switch (integrationStatus) {
    case IntegrationStatus.CONNECTED:
      return integrationStatusConnected;
    case IntegrationStatus.NOT_SYNCED:
      return integrationStatusConnected;
    case IntegrationStatus.WARNING:
      return integrationStatusWarning;
    case IntegrationStatus.ERROR:
      return integrationStatusError;
    default:
      return undefined;
  }
};

export const getIntegrationStatusText = (
  integrationStatus?: IntegrationStatus
) => {
  switch (integrationStatus) {
    case IntegrationStatus.CONNECTED:
      return "CONNECTED";
    case IntegrationStatus.WARNING:
      return "WARNING";
    case IntegrationStatus.ERROR:
      return "ERROR";
    case IntegrationStatus.NOT_SYNCED:
      return "NOT_SYNCED_YET";
    default:
      return undefined;
  }
};

export const getIntegrationPlatformThemeColor = (
    integrationPlatform?: IntegrationPlatform
) => {
  switch (integrationPlatform) {
    case IntegrationPlatform.AMAZON_ADS:
      return '#E09634';
    case IntegrationPlatform.AMAZON_SELLER:
      return '#E09634';
    case IntegrationPlatform.SHOPIFY:
      return '#96C048';
    default:
      return '#ffffff';
  }
};

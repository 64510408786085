import styled from 'styled-components';
import {Input} from "@components";
import {Checkbox, FormControl, Select} from "@chakra-ui/react";

export const Wrapper = styled.div<{ img: string }>`
  background: url(${props => props.img}) ${props => props.theme.primaryColor};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


// content
export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 600px;
  min-height: 300px;
  overflow: hidden;
  //@media (max-width: 768px) {
  //  width: 90vw;
  //}
`;


export const PrimaryTitle = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
`;


export const SecondaryTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: black;
`;


export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  display: flex;
`


//bottom
export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
`

export const SkipButton = styled.button`
  border-radius: 20px;
  margin: 0 10px;
  width: 100px;
  height: 40px;
  border: 1px solid ${props => props.theme.primaryColor};
`

export const NextButton = styled.button`
  border-radius: 20px !important;
  margin: 0 10px !important;
  width: 120px !important;
  height: 40px !important;
  background-color: ${props => props.theme.primaryColor} !important;
  color: white;
`


export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 10px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

export const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 13px 0 0 13px;
  width: 100%;
  justify-content: space-between;
`;

export const EmailInput = styled(Input)`
  height: 100%;
  width: 170%;
  margin-right: 25px;
  max-width: 300px;
  background-color: white;
  border: none;
  border-bottom: solid 1px;
  border-radius: 0;
`;

export const FormFieldSelectPermissions = styled(Select)`
  height: 50px;
  border: 1px solid gray !important;
  //width: 30%!important;
  border-radius: 15px !important;
`;

export const FormFieldSelectDepartment = styled(Select)`
  height: 50px;
  border: 1px solid gray !important;
  //width: 100% !important;
  margin-bottom: 20px;
  border-radius: 15px !important;
`;

export const FormFieldSelectOption = styled.option`
`;


export const FormFieldCheckbox = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormFieldContainer = styled(FormControl)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
`

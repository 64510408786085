import React, { useState } from "react";
import {
  Cell,
  Column,
  Row,
  useExpanded,
  useSortBy,
  useTable,
} from "react-table";
import { styles } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import {
  getCarrierNameByEnum,
  getCarrierSvgByEnum,
} from "../../../../utils/carrier/helpers";
import {
  fetchOperationData,
  operationsStateSelector,
  PurchasesStats,
} from "../../../../store/operation";

import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../../../../components/loading/Loading";
import { formatIsoDate } from "../../../../utils/date-format/dateFormat";
import { DepartmentsEnum } from "../../../../services/operations/operation.service";
import { organizationSelector } from "@store";
import { dateRangeSelector } from "../../../../store/ui";

interface Props {
  tableData: PurchasesStats[];
  onTabChanged: (statusCode: string) => void;
  fetchMoreData: () => void;
  handlingHasMore: boolean;
  searchQuery: string;
  handlingStatusCode: any;
  setSortedHeader: (e?: any) => void;
  sortedHeader: any;

  setPage: any;
}

const TableHeaders = [
  {
    Header: "Order ID",
    accessor: "orderId",
    keyToFetch: "orderId",
  },
  {
    Header: "Order Date",
    accessor: "orderDate",
    keyToFetch: "activityDate",
  },
  {
    Header: "Courier",
    accessor: "courier",
    keyToFetch: "courier",
  },
  {
    Header: "Warehouse",
    accessor: "warehouse",
    keyToFetch: "displayWarehouseName",
  },
  {
    Header: "Tracking",
    accessor: "tracking",
    keyToFetch: "trackingNumber",
  },
  {
    Header: "Status",
    accessor: "status",
    keyToFetch: "displayStatus",
  },
];

interface TableDataInterface {
  status?: string;
  orderId?: string;
  orderDate?: string;
  tracking?: string;
  warehouse?: string;
  shippingCarrier?: any;
  productsUnit?: string | number;
  handlingCost?: string | number;
  subRows?: any[];
  id?: string;
}

interface TableInputData {
  [key: string]: PurchasesStats[];
}

enum Tabs {
  INCOMPLETE,
  PENDING_APPROVAL,
  PENDING_FULFILLMENT,
  FULFILLED,
  CANCELLED,
  RETURNS,
}

interface SelectedTab {
  name?: string;
  tab: Tabs;
  keyName?: string;
  color?: string;
  backgroundColor?: string;
}

const TabsData = [
  {
    name: "Incomplete",
    tab: Tabs.INCOMPLETE,
    keyName: "Exceptions",
    color: "#F7686A",
    backgroundColor: "#FDD9D9",
  },
  {
    name: "Pending Approval",
    tab: Tabs.PENDING_APPROVAL,
    keyName: "Pending Approval",
    color: "#F09122",
    backgroundColor: "#FFE781",
  },
  {
    name: "Pending Fulfillment",
    tab: Tabs.PENDING_FULFILLMENT,
    keyName: "Pending Fulfillment",
    color: "#F0BB22",
    backgroundColor: "#FFE281",
  },
  {
    name: "Fulfilled",
    tab: Tabs.FULFILLED,
    keyName: "Fulfilled",
    color: "#72CB3B",
    backgroundColor: "#C8F4AD",
  },
  { name: "Cancelled", tab: Tabs.CANCELLED, keyName: "Cancelled" },
  {
    name: "Returns",
    tab: Tabs.RETURNS,
    keyName: "Returns",
    color: "#72CB3B",
    backgroundColor: "#C8F4AD",
  },
];

const HandlingOverviewTable = (props: Props) => {
  const operationState = useSelector(operationsStateSelector);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(TabsData[0]);
  const organization = useSelector(organizationSelector);
  const dates = useSelector(dateRangeSelector);

  // const [tableData, setTableData] = useState();
  // const [items, setItems] = useState<any>([]);
  // const [empty, setEmpty] = useState<any>(false);
  // const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const dataToDataTable = (data?: PurchasesStats[]): TableDataInterface[] => {
    if (!!data) {
      const filteredData = data.filter((item) => {
        return item?.statusCode === getStatusNameByEnum(selectedTab?.tab);
      });

      const tableData = filteredData.map((item) => {
        return {
          status: checkValues(item?.displayStatus),
          orderId: checkValues(item?.orderId),
          courier: checkValues(item?.courier),
          warehouse: checkValues(item?.displayWarehouseName),
          orderDate: checkValues(
            formatIsoDate(item?.activityDate?.slice(0, 10))
          ),
          tracking: checkValues(item?.trackingNumber),
          id: item?.id,
          url: item?.url,
          subRows: [],
        };
      });
      return tableData;
    } else {
      return [];
    }
  };

  const checkValues = (value: any) => {
    if (!!value) {
      return value;
    } else {
      return "-";
    }
  };

  const getStatusNameByEnum = (key: Tabs): string => {
    switch (key) {
      case Tabs.INCOMPLETE:
        return "Incomplete";
      case Tabs.FULFILLED:
        return "Fulfilled";
      case Tabs.PENDING_APPROVAL:
        return "Pending Approval";
      case Tabs.PENDING_FULFILLMENT:
        return "Pending Fulfillment";
      case Tabs.CANCELLED:
        return "Cancelled";
      case Tabs.RETURNS:
        return "Returns";
      default:
        return "";
    }
  };

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          isAllRowsExpanded: any;
          getToggleAllRowsExpandedProps: any;
        }) => (
          <span /*{...getToggleAllRowsExpandedProps()}*/>
            {/*{isAllRowsExpanded ? "👇" : "👉"}*/}
          </span>
        ),
        Cell: ({ row }: { row: Row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
              title={""}
            >
              {row.isExpanded ? "👇" : "👉"}
            </span>
          ) : null,
      },
      ...TableHeaders,
    ],
    []
  );

  const data = React.useMemo(
    () => dataToDataTable(props.tableData),
    [props.tableData, selectedTab]
  );

  const getRowsCount = (tabName: string) => {
    if (tabName === "Incomplete") {
      return operationState.metadata?.purchaseStats?.incomplete ?? 0;
    }
    if (tabName === "Pending Approval") {
      return operationState.metadata?.purchaseStats?.pendingApproval ?? 0;
    }
    if (tabName === "Pending Fulfillment") {
      return operationState.metadata?.purchaseStats?.pendingFulfillment ?? 0;
    }
    if (tabName === "Fulfilled") {
      return operationState.metadata?.purchaseStats?.fulfilled ?? 0;
    }
    if (tabName === "Cancelled") {
      return operationState.metadata?.purchaseStats?.cancelled ?? 0;
    }
    if (tabName === "Returns") {
      return operationState.metadata?.purchaseStats?.returns ?? 0;
    }
    return 0;
  };

  const MenuListRender = () => {
    return (
      <MenuList>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </MenuList>
    );
  };

  const renderTabs = () => {
    return (
      <styles.TabWrapper>
        {TabsData.map((item) => {
          return (
            <styles.Tab
              color={item?.color ? item?.color : "#d9d9d9"}
              selected={selectedTab.tab === item.tab}
              onClick={() => {
                props.onTabChanged(item.name);
                setSelectedTab(item);
              }}
            >
              {item?.name}
              <styles.NumberBadge
                active={selectedTab.tab === item.tab}
                color={item?.color ? item?.color : "black"}
                backgroundColor={
                  item?.backgroundColor ? item?.backgroundColor : "#DDDDDD"
                }
              >
                {getRowsCount(item?.name)}
              </styles.NumberBadge>
            </styles.Tab>
          );
        })}
      </styles.TabWrapper>
    );
  };

  function Table({ columns: userColumns, data }: { columns: any; data: any }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      state: { expanded },
    } = useTable(
      {
        columns: userColumns,
        data,
      },
      useSortBy,
      useExpanded // Use the useExpanded plugin hook
    );

    type Column<T> = {
      Header: string;
      accessor: keyof T;
      keyToFetch?: string;
    };

    const handleHeaderClick = (column: Column<{ keyToFetch?: string }>) => {
      props?.setPage(0);
      if (column?.Header === props?.sortedHeader?.headerName) {
        props?.setSortedHeader((prev: any) => {
          return {
            headerName: column?.Header,
            ascending: !prev?.ascending,
            key: column?.keyToFetch,
          };
        });
      } else {
        props?.setSortedHeader({
          headerName: column?.Header,
          ascending: true,
          key: column?.keyToFetch,
        });
      }
      const params = {};
      if (props?.sortedHeader?.ascending) {
        params["ascending"] = true;
        params["descending"] = undefined;
      }
      if (!props?.sortedHeader?.ascending) {
        params["ascending"] = undefined;
        params["descending"] = true;
      }
    };

    return (
      <InfiniteScroll
        dataLength={props?.tableData?.length ?? 0}
        next={props?.fetchMoreData}
        hasMore={props?.handlingHasMore}
        scrollableTarget="scrollableDivHandlingInventory"
        loader={
          <Flex
            h={150}
            w={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Flex>
        }
      >
        <styles.StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  if (column.id === "expander") {
                    return <></>;
                  }
                  return (
                    <styles.HeaderTh
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                      onClick={() => {
                        handleHeaderClick(column);
                      }}
                    >
                      <Flex
                        textAlign={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {column.render("Header")}
                        <Box w={1} />
                        <span>
                          {props?.sortedHeader?.headerName ===
                          column?.Header ? (
                            !props?.sortedHeader?.ascending ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </styles.HeaderTh>
                  );
                })}
              </styles.HeaderTr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <styles.TableTr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => {
                    if (cell.column.id === "expander") {
                      return <></>;
                    }
                    if (cell.column.id === "warehouse") {
                      return (
                        <styles.TableTd
                          {...cell.getCellProps()}
                          {...row.getToggleRowExpandedProps()}
                          title={""}
                        >
                          <Tooltip label={!!cell?.value ? cell?.value : ""}>
                            {!!cell?.value
                              ? cell?.value?.length > 45
                                ? cell?.value.slice(0, 10) + "..."
                                : cell?.value
                              : ""}
                          </Tooltip>
                        </styles.TableTd>
                      );
                    }
                    if (cell.column.id === "orderId") {
                      return (
                        <styles.TableCell
                          {...cell.getCellProps()}
                          {...row.getToggleRowExpandedProps()}
                          title={""}
                        >
                          <styles.OrderLink
                            target={"_blank"}
                            href={row?.original?.url}
                          >
                            {cell.render("Cell")}
                          </styles.OrderLink>
                        </styles.TableCell>
                      );
                    }
                    if (cell.column.id === "options") {
                      return (
                        <styles.TableCell {...cell.getCellProps()}>
                          <Menu placement="start-start">
                            {({ isOpen }) => (
                              <>
                                <MenuButton padding={0}>
                                  <styles.ThreeDotHolder>
                                    <BsThreeDotsVertical />
                                  </styles.ThreeDotHolder>
                                </MenuButton>
                                <MenuListRender />
                              </>
                            )}
                          </Menu>
                        </styles.TableCell>
                      );
                    }
                    if (cell.column.id === "status") {
                      return (
                        <styles.StatusCell
                          {...cell.getCellProps()}
                          {...row.getToggleRowExpandedProps()}
                          title={""}
                        >
                          <styles.statusCell
                            color={
                              !!selectedTab?.color
                                ? selectedTab?.color
                                : "black"
                            }
                          >
                            {cell.render("Cell")}
                          </styles.statusCell>
                        </styles.StatusCell>
                      );
                    }
                    if (cell.column.id === "carrier") {
                      return (
                        <styles.FlexCell {...cell.getCellProps()}>
                          <styles.FlexDiv>
                            {getCarrierSvgByEnum(cell.value)}
                            {getCarrierNameByEnum(cell.value)}
                          </styles.FlexDiv>
                        </styles.FlexCell>
                      );
                    }
                    if (cell.column.id === "productsUnit") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          {...row.getToggleRowExpandedProps()}
                          title={""}
                        >
                          {cell?.value?.toLocaleString("en-US")}
                        </td>
                      );
                    }
                    return (
                      <styles.TableCell
                        {...cell.getCellProps()}
                        {...row.getToggleRowExpandedProps()}
                        title={""}
                      >
                        {cell.render("Cell")}
                      </styles.TableCell>
                    );
                  })}
                </styles.TableTr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group: any) => {
              return (
                <styles.FooterTr {...group.getFooterGroupProps()}>
                  {group.headers.map((column: any) => {
                    if (column.id === "expander") {
                      return <></>;
                    }
                    return (
                      <styles.StyledTotalFooter {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </styles.StyledTotalFooter>
                    );
                  })}
                </styles.FooterTr>
              );
            })}
          </tfoot>
        </styles.StyledTable>
      </InfiniteScroll>
    );
  }

  return (
    <styles.PaddingAndTableWrapper>
      <styles.TablePadding />
      {renderTabs()}
      <styles.Wrapper id={"scrollableDivHandlingInventory"}>
        <Table columns={columns} data={data} />
      </styles.Wrapper>
    </styles.PaddingAndTableWrapper>
  );
};

export default HandlingOverviewTable;

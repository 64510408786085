/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    BarHolders,
    ButtonDisable,
    FormInputs,
    LastBarHolder,
    PasswordBar,
    SignUpCard,
    SignUpContent,
    SignUpForm,
    SignUpFormAgreement,
    SignUpFormAgreementLink,
    SignUpFormAgreementText,
    SignUpFormButton,
    SignUpFormInput,
    SignUpFormOrText,
    SignUpFormSubmitButton,
    SignUpFormTitle,
    SignUpHeader,
    SignUpShowPasswordButton,
    SignUpSignInLink,
    SignUpTitle,
    SignUpWrapper,
    StyledInput,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { WideViewIcon } from "utils/icons/wideview-icons";
import {authStateSelector, forgotPassword, signIn} from "@store";
import {Spinner} from "@chakra-ui/react";


export const RecoverPassword = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(authStateSelector);

    const schema = yup.object().shape({
        email: yup.string().required().email(),
    });

    const {register, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    // effects

    // helpers

    const navigateToSignIn = () => {
        navigate('/auth/sign-in');
    };


    const submitForm = (data: any) => {
        dispatch(forgotPassword(data?.email))
    };

    // selectors

    const renderSignUpHeader = () => {
        return (
            <SignUpHeader>
                <SignUpTitle>
                    <WideViewIcon />
                </SignUpTitle>
            </SignUpHeader>
        );
    };



    const renderSignUpContent = () => {
        return (
            <SignUpContent>
                <SignUpCard>
                    {renderSignUpForm()}
                    {renderSignUpSignInLink()}
                </SignUpCard>
            </SignUpContent>
        );
    };



    const renderSignUpForm = () => {
        return (
            <SignUpForm onSubmit={handleSubmit(submitForm)}>
                {renderSignUpFormTitle()}
                <div style={{ height: '20px' }} />
                <FormInputs >
                    {renderSignUpFormEmailInput()}
                    {renderSignUpFormSubmitButton()}
                    {auth?.forgotPasswordSuccess ? "Email sent" : <></>}
                </FormInputs>
            </SignUpForm>
        );
    };


    const renderSignUpFormTitle = () => {
        return (
            <SignUpFormTitle>
                {t('RESET_YOUR_PASSWORD', { ns: 'translation' })}
            </SignUpFormTitle>
        );
    };

    const renderSignUpFormEmailInput = () => {
        return (
            <SignUpFormInput>
                <StyledInput className={'no-border-input'}
                             type={"email"} {...register('email')} placeholder={t('YOUR_EMAIL', { ns: 'translation' })} />
                <div style={{ width: '20px', height: '20px' }} />

            </SignUpFormInput>
        );
    };

    const renderSignUpSignInLink = () => {
        return (
            <SignUpSignInLink onClick={() => {
                navigateToSignIn();
            }}>
                {t('ALREADY_HAVE_AN_WIDEVIEW_ACCOUNT_?_LOG_IN', { ns: 'translation' })}
            </SignUpSignInLink>
        );
    };

    const renderSignUpFormSubmitButton = () => {
        return (
                            <SignUpFormSubmitButton>
                                <button style={{ color: "white" }} id={'submit'} type="submit" >
                                    {auth?.forgotPasswordLoading ? <Spinner /> : t('SEND_EMAIL', { ns: 'translation' }) }
                                </button>
                            </SignUpFormSubmitButton>
        );
    };

    return (
            <SignUpWrapper img={process.env.PUBLIC_URL + '/assets/auth-background.png'}>
                {renderSignUpHeader()}
                {renderSignUpContent()}
            </SignUpWrapper>
    );
};

import { MoreInfoButton } from "components/more-info-button/MoreInfoButton";
import { PlanningGraph } from "components/planning-graph/PlanningGraph";
import { Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpCustomTargetsLoaders,
  createCustomTargets,
  customTargetsLoader,
  customTargetsSuccess,
  fetchCustomTargets,
  fetchKpiData,
  kpiStateSelector,
  putCustomTargets,
} from "store/kpis";
import {
  customCogsDataSelector,
  organizationDataLanguageSelector,
  organizationSelector,
} from "store/organization/selectors";
import { IconCloseOverlay } from "utils/icons/account-health";
import wideViewsStyles, { styles } from "./styles";
import { IoIosArrowForward } from "react-icons/io";
import { PlanningTable } from "components/planning-graph/planning_table/PlanningTable";
import {
  isApplyBarOpenSelector,
  isApplyModalOpenSelector,
  isDefaultExploreOpenSelector,
  openApplyBar,
  openApplyModal,
  openDefaultExploreView,
  openSecondaryExplore,
} from "store/ui";
import { fetchCustomCogsData } from "@store";
import { sumData } from "utils/get-months/getLastYear";
import ApplyChangesBar from "components/apply-changes-bar/ApplyChangesBar";
import { ApplyChangesModal } from "components/apply-changes-modal/ApplyChangesModal";
import { currencyFormat } from "utils/kpi-render/kpiCalculation";
import { channelsStateSelector } from "store/channels";
import { IntegrationType } from "store/account";
import { PlanningRoundIcon } from "../../utils/icons/account-health-icons/planning-round";
import MenuGenericButton, {
  MenuButton,
} from "../menu-generic/MenuGenericButton";
/*import {MenuButton} from "../menu-generic/MenuGenericButton";*/
import { useCurrencySign } from "../../utils/custom-hooks/useCurrencySign";

enum TypeOfRow {
  REVENUE,
  DEDUCTION,
}

export interface PlanningItem {
  rowId?: string;
  title?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  total?: number;
  editableCell?: boolean;
  noCurrency?: boolean;
}

export interface PlanningData {
  title?: string;
  id?: string;
  rowId?: string;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  total?: number;
  noCurrency?: boolean;
  editableCell?: boolean;
  subRows?: PlanningItem[];
}

interface TableData {
  data: PlanningData[];
  channels: string[];
}

interface Card {
  value: number;
  title: string;
  lastYearValue: number;
  currency?: boolean;
}

const DEFAULTS = {
  grossRevenue: 100,
  aov: 10,
  orders: 10,
  deductions: 0,
  netRevenue: 100,
  cogs: 0,
  grossProfit: 100,
  marketingSpend: 100,
  cpo: 10,
  roas: 1,
  contProfit: 0,
};

export const Planning = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(organizationSelector);
  const [plannedDataYearly, setPlannedDataYearly] = useState<PlanningData[]>(
    []
  );

  const [lastYearData, setLastYearData] = useState<PlanningData[]>([]);
  const [chartPlannedData, setChartPlannedData] = useState<PlanningData[]>([]);
  const [graphData, setGraphData] = useState<PlanningData[]>([]);
  const [storeChannels, setStoreChannels] = useState<string[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState("2023");
  /*const customCogs = useSelector(customCogsSelector);*/
  const customDataCogs = useSelector(customCogsDataSelector);
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const kpis = useSelector(kpiStateSelector);
  const [chartTitle, setChartTitle] = useState<string>("Gross Revenue");
  const [cards, setCards] = useState<Card[]>([]);
  const [applyBarFlag, setApplyBarFlag] = useState<boolean>(false);
  const { t } = useTranslation(["translation", "translation"]);
  const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
  const isApplyBarOpen = useSelector(isApplyBarOpenSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isApplyModalOpen = useSelector(isApplyModalOpenSelector);
  const [summedHistoryData, setSummedHistoryData] = useState<any>();
  const channels = useSelector(channelsStateSelector);
  const isLoading = useSelector(customTargetsLoader);
  const isSuccess = useSelector(customTargetsSuccess);
  const currentCurrency = useCurrencySign();
  const currencyCode = useSelector(organizationDataLanguageSelector)?.generalSettings?.currency;
  useEffect(() => {
    dispatch(openApplyBar({ isApplyBarOpen: false }));
  }, [dispatch, isExploreOpen?.isDefaultExploreOpen]);
  useEffect(() => {
    !!organizationId?.id &&
      !!selectedYear &&
      dispatch(
        fetchCustomTargets({
          organizationId: organizationId?.id,
          year: selectedYear,
        })
      );
  }, [
    dispatch,
    organizationId?.id,
    selectedYear,
    isExploreOpen?.isDefaultExploreOpen,
  ]);

  useEffect(() => {
    if (!!graphData && !!kpis?.data?.data) {
      const summedData = sumData(kpis?.data?.data, []);
      setSummedHistoryData(summedData);
    }
  }, [graphData, kpis?.data?.data, selectedYear]);

  useEffect(() => {
    const year = 2023;
    handleDataFetch(year);
  }, [dispatch, organizationId?.id]);

  const getSummedDataByKey = (
    data: any,
    key: string,
    summedData: any,
    dailyStatsKey: string
  ) => {
    let total = 0;
    const cardsToReturn: Card[] = [];
    const currentData = data.find((item: any) => item.title === key);
    !!currentData &&
      Object.keys(currentData).forEach((key) => {
        if (typeof currentData[key] === "number" && key!=='currency') {
          total = total + currentData[key];
        }
      });
    if (!!currentData) {
      const card: Card = {
        title: currentData["title"],
        value: total,
        lastYearValue: summedData[dailyStatsKey],
      };
      cardsToReturn.push(card);
    }

    if (!currentData) {
      const currentSubRowData = data
        .map((item: any) =>
          item.subRows?.find((subRowItem: any) => subRowItem.title === key)
        )
        .filter((item: any) => !!item);
      if (!!currentSubRowData && currentSubRowData.length > 0) {
        let subRowTotal = 0;

        Object.keys(currentSubRowData[0]).forEach((key) => {
          if (typeof currentSubRowData[0][key] === "number" && key !== "currency") {
            subRowTotal = subRowTotal + currentSubRowData[0][key];
          }
        });
        const card: Card = {
          title: currentSubRowData[0]["title"],
          value: subRowTotal,
          lastYearValue: summedData[dailyStatsKey],
        };
        cardsToReturn.push(card);
      }
    }
    return cardsToReturn;
  };

  const handleDataFetch = (year: number) => {
    const firstDay = `${year}-01-01T00:00:00.000Z`;

    const lastDay = `${year}-12-31T23:59:59.999Z`;

    if (organizationId?.id !== undefined) {
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: firstDay,
          toDate: lastDay,
          kpisType: 0,
          type: 1, // forecast
        })
      );
    }
    if (organizationId?.id !== undefined) {
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: firstDay,
          toDate: lastDay,
          kpisType: 0,
          type: 2, // targets
        })
      );
    }
    if (organizationId?.id !== undefined) {
      const firstDay = `${year - 1}-01-01T00:00:00.000Z`;
      const lastDay = `${year - 1}-12-31T23:59:59.999Z`;
      dispatch(
        fetchKpiData({
          organizationId: organizationId!.id,
          fromDate: firstDay,
          toDate: lastDay,
          kpisType: 0, // actual
        })
      );
    }
    if (!!organizationId?.id) {
      dispatch(fetchCustomCogsData({ organizationId: organizationId?.id }));
    }
  };

  const formatData = (data: any) => {
    const keysToIgnore = [
      "activityDate",
      "createdAt",
      "id",
      "market",
      "purchasePoint",
      "updatedAt",
    ];
    const monthToString = {
      "01": "january",
      "02": "february",
      "03": "march",
      "04": "april",
      "05": "may",
      "06": "june",
      "07": "july",
      "08": "august",
      "09": "september",
      "10": "october",
      "11": "november",
      "12": "december",
    };
    const groupByMonths = {
      "01": [],
      "02": [],
      "03": [],
      "04": [],
      "05": [],
      "06": [],
      "07": [],
      "08": [],
      "09": [],
      "10": [],
      "11": [],
      "12": [],
    };
    data.forEach((item: any) => {
      if (groupByMonths[item?.activityDate?.slice(5, 7)]) {
        groupByMonths[item?.activityDate?.slice(5, 7)]?.push(item);
      } else {
        groupByMonths[item?.activityDate?.slice(5, 7)] = [item];
      }
    });
    const summedDataByMonths: any[] = [];
    Object.keys(groupByMonths).forEach((key) => {
      summedDataByMonths.push({
        ...sumData(groupByMonths[key], keysToIgnore),
        month: monthToString[key],
      });
    });

    const grossRevenueDeductions = dataLanguage?.pnlSettings?.grossSales?.map(
      (item: any) => item?.name
    );
    const netRevenueDeductions = dataLanguage?.pnlSettings?.netSales?.map(
      (item: any) => item?.name
    );
    const grossProfitDeductions = dataLanguage?.pnlSettings?.grossProfit?.map(
      (item: any) => item?.name
    );

    const grossRevenueDeductionsData: any =
      dataLanguage?.pnlSettings?.grossSales?.map((item: any) => {
        return {
          title: item?.name,
          editableCell: true,
          pnlElementId: item?.id,
          currency: currencyCode
        };
      }); // deduction sub rows

    let customCogsArray = [];
    if (!!customDataCogs) {
      customDataCogs?.map((item: any) => {
        return { title: item?.name, editableCell: true, cogsId: item?._id,
            currency: currencyCode };
      });
    }
    customCogsArray?.unshift({
      title: "freeOnBoard",
      editableCell: true,
      cogsId: "",
        currency: currencyCode
    });
    const cogsDeductionData: any = customCogsArray;
    // cogs sub rows
    const netRevenueDeductionsData: any =
      dataLanguage?.pnlSettings?.netSales?.map((item: any) => {
        return { title: item?.name, editableCell: true };
      }); // deduction net revenue sub rows
    const grossProfitDeductionsData: any =
      dataLanguage?.pnlSettings?.grossProfit
        ?.map((item: any) => {
          if (item?.name === "MARKETING_SPEND") {
            return;
          } else {
            return {
              title: item?.name,
              editableCell: true,
              pnlElementId: item?.id,
              currency: currencyCode

            };
          }
        })
        .filter((item) => !!item); // deduction net revenue sub rows
    const ordersData: any = {
      editableCell: true,
      title: "Orders",
      noCurrency: true,
      currency: currencyCode

    }; // orders row
    const aovData: any = { editableCell: true, title: "AOV", currency: currencyCode
    }; // aov row
    const cpoData: any = { title: "CPO", editableCell: true, currency: currencyCode
    }; // cpo row
    const roasData: any = {
      title: "ROAS",
      editableCell: true,
      noCurrency: true,
      currency: currencyCode
    }; // cpo row

    const grossRevenueData: any = {
      title: "Gross Revenue",
      subRows: [ordersData, aovData],
      type: TypeOfRow.REVENUE,
      currency: currencyCode
    }; // gross revenue row
    const deductionsData: any = {
      title: "Deductions",
      subRows: [...grossRevenueDeductionsData],
      type: TypeOfRow.DEDUCTION,
      currency: currencyCode
    }; // first deductions row
    const netRevenueData: any = {
      title: "Net Revenue",
      type: TypeOfRow.REVENUE,
      currency: currencyCode
    }; // net revenue row
    const cogsData: any = {
      title: "COGS",
      subRows: [...cogsDeductionData],
      type: TypeOfRow.DEDUCTION,
      currency: currencyCode
    }; // Cogs Deduction Data
    const firstCustomExpensesData: any = {
      rowId: "netRevenueExpenses",
      title: "Custom Expenses",
      currency: currencyCode,
      subRows: [...netRevenueDeductionsData],
      type: TypeOfRow.DEDUCTION,
    }; // custom Expenses Deduction Data
    const secondCustomExpensesData: any = {
      rowId: "grossProfitExpenses",
      title: "Custom Expenses",
      subRows: [...grossProfitDeductionsData],
      type: TypeOfRow.DEDUCTION,
      currency: currencyCode
    }; // custom Expenses Deduction Data
    const grossProfitData: any = {
      title: "Gross Profit",
      type: TypeOfRow.REVENUE,
      currency: currencyCode
    }; // gross profit row
    const contProfitData: any = {
      title: "Contribution Profit",
      type: TypeOfRow.REVENUE,
      currency: currencyCode
    }; // cont profit row
    const marketingSpendData: any = {
      editableCell: true,
      title: "Marketing Spend",
      subRows: [cpoData, roasData],
      currency: currencyCode,
      type: TypeOfRow.DEDUCTION,
    }; // marketing Spend row
    summedDataByMonths.forEach((item) => {
      let totalGrossRevenueDeductionsPerMonth = 0;
      let totalCogsDeductionPerMonth = 0;
      let totalNetRevenueDeductions = 0;
      let totalGrossProfitDeductions = 0;
      Object.keys(item).forEach((key) => {
        if (grossRevenueDeductions?.includes(key)) {
          totalGrossRevenueDeductionsPerMonth =
            totalGrossRevenueDeductionsPerMonth + item[key]; // deduction first row data
        }
        const indexOfDeduction = grossRevenueDeductionsData.findIndex(
          (item: any) => item.title === key
        ); // sub item data
        if (indexOfDeduction > -1) {
          //
          grossRevenueDeductionsData[indexOfDeduction][item.month] = !!item[key]
            ? item[key]
            : 0; //
        }
        if (customDataCogs?.map((item) => item?.name)?.includes(key)) {
          totalCogsDeductionPerMonth = totalCogsDeductionPerMonth + item[key]; // cogs first row data
        }
        if (key === "freeOnBoard") {
          totalCogsDeductionPerMonth += item[key];
        }
        const indexOfCogs = cogsDeductionData.findIndex(
          (item: any) => item.title === key
        ); // sub item data
        if (indexOfCogs > -1) {
          //
          cogsDeductionData[indexOfCogs][item?.month] = !!item[key]
            ? item[key]
            : 0; //
        }
        if (netRevenueDeductions?.includes(key)) {
          totalNetRevenueDeductions = totalNetRevenueDeductions + item[key]; // deduction second row data
        }
        if (grossProfitDeductions?.includes(key)) {
          totalGrossProfitDeductions = totalGrossProfitDeductions + item[key]; // deduction second row data
        }
        const indexOfCustomExpenses = netRevenueDeductionsData.findIndex(
          (item: any) => item.title === key
        ); // sub item data
        if (indexOfCustomExpenses > -1) {
          //
          netRevenueDeductionsData[indexOfCustomExpenses][item?.month] = !!item[
            key
          ]
            ? item[key]
            : 0; //
        }
        const indexOfSecondCustomExpenses = grossProfitDeductionsData.findIndex(
          (item: any) => item.title === key
        ); // sub item data
        if (indexOfSecondCustomExpenses > -1) {
          //
          grossProfitDeductionsData[indexOfSecondCustomExpenses][item?.month] =
            !!item[key] ? item[key] : 0; //
        }
      });
      const netRevenue =
        item?.grossRevenue - totalGrossRevenueDeductionsPerMonth;
      const grossProfit =
        netRevenue - totalCogsDeductionPerMonth - totalGrossProfitDeductions;
      const contProift =
        grossProfit - (!!item?.marketingCost ? item?.marketingCost : 0);
      cpoData[item.month] =
        !!item?.orders &&
        !isNaN(item?.marketingCost / item?.orders) &&
        isFinite(item?.marketingCost / item?.orders)
          ? !!(item?.marketingCost / item?.orders)
            ? parseFloat((item?.marketingCost / item?.orders).toFixed(2))
            : 10
          : DEFAULTS?.cpo; // TODO Planning defaults
      roasData[item.month] =
        isNaN(item?.grossRevenue / item?.marketingCost) &&
        !item?.marketingCost &&
        !(item?.grossRevenue / item?.marketingCost) &&
        !isFinite(item?.grossRevenue / item?.marketingCost)
          ? DEFAULTS?.roas // TODO roas default
          : !!item?.marketingCost
          ? (item?.grossRevenue / item?.marketingCost).toFixed(2)
          : 1;
      grossRevenueData[item.month] = !!item?.grossRevenue
        ? item?.grossRevenue
        : DEFAULTS?.grossRevenue;
      ordersData[item.month] = !!item?.orders ? item?.orders : DEFAULTS?.orders; // TODO orders default
      netRevenueData[item.month] = !!netRevenue
        ? netRevenue
        : DEFAULTS?.netRevenue;
      grossProfitData[item.month] = !!grossProfit
        ? grossProfit
        : DEFAULTS?.grossProfit;
      contProfitData[item.month] = !!contProift
        ? contProift
        : DEFAULTS?.contProfit;
      marketingSpendData[item.month] = !!item?.marketingCost
        ? item?.marketingCost
        : DEFAULTS?.marketingSpend; // TODO marketing spend default
      aovData[item.month] = !!item?.orders
        ? parseFloat((item?.grossRevenue / item?.orders).toFixed(2))
        : DEFAULTS?.aov; // TODO Planning defaults
      deductionsData[item.month] = totalGrossRevenueDeductionsPerMonth;
      firstCustomExpensesData[item.month] = !!totalNetRevenueDeductions
        ? totalNetRevenueDeductions
        : DEFAULTS?.deductions;
      secondCustomExpensesData[item.month] = !!totalGrossProfitDeductions
        ? totalGrossProfitDeductions
        : 0;
      cogsData[item.month] = !!totalCogsDeductionPerMonth
        ? totalCogsDeductionPerMonth
        : DEFAULTS?.cogs;
    });

    const tableData = [
      grossRevenueData,
      deductionsData,
      netRevenueData,
      cogsData,
      /*firstCustomExpensesData,*/ // TODO return when pnl is updated
      grossProfitData,
      marketingSpendData,
      secondCustomExpensesData,
      contProfitData,
    ];
    return tableData;
  };
  useEffect(() => {
    if (
      !!kpis?.forecast?.data &&
      !!dataLanguage?.pnlSettings?.grossSales &&
      !!customDataCogs
    ) {
      setPlannedDataYearly(formatData(kpis?.forecast?.data));
    }
  }, [
    dataLanguage?.pnlSettings?.grossSales,
    kpis?.forecast?.data,
    customDataCogs,
    kpis?.data?.data,
    selectedYear,
  ]);

  useEffect(() => {
    if (!!kpis?.data?.data) {
      setLastYearData(formatData(kpis?.data?.data));
    }
  }, [
    customDataCogs,
    kpis?.data?.data,
    selectedYear,
    kpis?.targetData?.data,
    kpis?.forecast?.data,
  ]);

  useEffect(() => {
    if (
      !!kpis?.forecast?.data &&
      !!dataLanguage?.pnlSettings?.grossSales &&
      !!customDataCogs
    ) {
      setChartPlannedData(formatData(kpis?.forecast?.data));
    }
  }, [
    customDataCogs,
    dataLanguage?.pnlSettings?.grossSales,
    kpis?.forecast?.data,
    selectedYear,
    kpis?.data?.data,
  ]);

  const handleTimeFilter = (year: number) => {
    if (organizationId?.id !== undefined) {
      switch (year) {
        case 2022:
          if (selectedYear === "2022") {
            break;
          }
          setSelectedYear("2022");
          handleDataFetch(year);
          break;
        case 2023:
          if (selectedYear === "2023") {
            break;
          }
          setSelectedYear("2023");
          handleDataFetch(year);
          break;
        case 2024:
          if (selectedYear === "2024") {
            break;
          }
          setSelectedYear("2024");
          handleDataFetch(year);
          break;
      }
    }
  };

  const menuButtonsData: MenuButton[] = [
    {
      title: "2022",
      onClick: () => handleTimeFilter(2022),
    },
    {
      title: "2023",
      onClick: () => handleTimeFilter(2023),
    },
    {
      title: "2024",
      onClick: () => handleTimeFilter(2024),
    },
  ];

  //renderer
  const renderHeader = () => {
    return (
      <wideViewsStyles.Header>
        <styles.HeaderHolder>
          <wideViewsStyles.HeaderIcon>
            <span style={{ width: "50px", height: "50px" }}>
              <PlanningRoundIcon />
            </span>
          </wideViewsStyles.HeaderIcon>
          <wideViewsStyles.TitleWrapper>
            <wideViewsStyles.Title>
              {t("PLANNING", { ns: "translation" })}
              <MoreInfoButton onClick={() => {}} />
            </wideViewsStyles.Title>
            <wideViewsStyles.SubTitle>
              {t(
                "CREATE_A_CUSTOM_BUSINESS_PLANNING_BASED_ON_YOUR_DATA_ASSUMPTIONS_AND_TARGET_GOALS",
                { ns: "translation" }
              )}
            </wideViewsStyles.SubTitle>
          </wideViewsStyles.TitleWrapper>
        </styles.HeaderHolder>

        <styles.HeaderIcon>
          <span style={{ width: "100px", height: "100px", overflow: "hidden" }}>
            <button
              onClick={() => {
                if (isApplyBarOpen) {
                  dispatch(openApplyModal({ isApplyModalOpen: true }));
                  return;
                } else {
                  dispatch(openSecondaryExplore({ isOpen: false }));
                }
                dispatch(openSecondaryExplore({ isOpen: false }));
                dispatch(openDefaultExploreView({ isOpen: false }));
              }}
            >
              <IconCloseOverlay />
            </button>
          </span>
        </styles.HeaderIcon>
      </wideViewsStyles.Header>
    );
  };

  const RenderButton = () => (
    <wideViewsStyles.RowButton>
      <wideViewsStyles.RowButtonText>
        {parseInt(selectedYear)}
      </wideViewsStyles.RowButtonText>

      <wideViewsStyles.RowButtonIcon>
        <IoIosArrowForward />
      </wideViewsStyles.RowButtonIcon>
    </wideViewsStyles.RowButton>
  );

  const renderButtons = () => {
    return (
      <wideViewsStyles.ButtonsWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <wideViewsStyles.ButtonsTitle>Plan for</wideViewsStyles.ButtonsTitle>
          <MenuGenericButton
            data={menuButtonsData}
            trigger={<RenderButton />}
          />
        </div>
        <wideViewsStyles.Toggle></wideViewsStyles.Toggle>
      </wideViewsStyles.ButtonsWrapper>
    );
  };

  const RenderCard = ({ value, title, lastYearValue, currency }: Card) => {
    return (
      <wideViewsStyles.CardWrapper>
        <wideViewsStyles.CardTitle>
          {currency
            ? currencyFormat(value, 0, currency ? currentCurrency : "")
            : Math.floor(value).toLocaleString("en-US")}
        </wideViewsStyles.CardTitle>
        <wideViewsStyles.CardSubtitle>{title}</wideViewsStyles.CardSubtitle>
        <wideViewsStyles.CardVsLastYear>
          {((value / lastYearValue) * 100).toFixed(0)}% vs Last Year
        </wideViewsStyles.CardVsLastYear>
      </wideViewsStyles.CardWrapper>
    );
  };
  const renderCards = () => {
    return (
      <wideViewsStyles.CardHolder>
        {!!summedHistoryData && !!graphData ? (
          <>
            {getSummedDataByKey(
              graphData,
              "Orders",
              summedHistoryData,
              "orders"
            ).map((item) => (
              <RenderCard
                currency={false}
                value={item.value}
                title={item.title}
                lastYearValue={item.lastYearValue}
              />
            ))}
            {getSummedDataByKey(
              graphData,
              "Gross Revenue",
              summedHistoryData,
              "grossRevenue"
            ).map((item) => (
              <RenderCard
                currency
                value={item.value}
                title={item.title}
                lastYearValue={item.lastYearValue}
              />
            ))}
            {getSummedDataByKey(
              graphData,
              "Gross Profit",
              summedHistoryData,
              "grossProfit"
            ).map((item) => (
              <RenderCard
                currency
                value={item.value}
                title={item.title}
                lastYearValue={item.lastYearValue}
              />
            ))}
          </>
        ) : (
          <Flex w={"100%"} justifyContent="center" alignItems={"center"}>
            <Spinner />
          </Flex>
        )}
      </wideViewsStyles.CardHolder>
    );
  };

  const handleApplyChanged = () => {
    const filteredChannels = channels?.channels
      ?.filter((item) => item?.type === IntegrationType.STORES)
      .map((item) => item.id);
    if (!kpis?.customTargetsTable?.data) {
      !!organizationId?.id &&
        !!filteredChannels &&
        !!dataLanguage?.id &&
          currencyCode !== undefined &&
        dispatch(
          createCustomTargets({
            organizationId: organizationId?.id,
            data: graphData,
            channels: filteredChannels,
            year: selectedYear,
            currency: currencyCode,
            dataLanguageId: dataLanguage?.id,
          })
        );
    } else {
      !!organizationId?.id &&
        !!filteredChannels &&
        !!dataLanguage?.id &&
      currencyCode !== undefined &&
        dispatch(
          putCustomTargets({
            organizationId: organizationId?.id,
            data: graphData,
            channels: filteredChannels,
            id: kpis?.customTargetsTable?.id,
            year: selectedYear,
            currency: currencyCode,
            dataLanguageId: dataLanguage?.id,
          })
        );
    }
  };

  return (
    <wideViewsStyles.Wrapper>
      {isApplyBarOpen ? (
        <ApplyChangesBar
          finishedLoading={!isLoading && isSuccess}
          onClose={() => dispatch(cleanUpCustomTargetsLoaders)}
          onApplyChanges={() => handleApplyChanged()}
          title={"PLANNING_UPDATED"}
        />
      ) : (
        <></>
      )}
      <ApplyChangesModal
        onApply={() => handleApplyChanged()}
        isOpen={isApplyModalOpen}
        onOpen={onOpen}
        onClose={onClose}
      />

      {renderHeader()}
      {renderButtons()}
      {renderCards()}
      {!!graphData ? (
        <PlanningGraph
          chartTitle={chartTitle}
          setChartTitle={setChartTitle}
          planningDataYearly={graphData}
          historyData={lastYearData}
          forecastData={chartPlannedData}
          setTotalOrders={setTotalOrders}
          setTotalRevenue={setTotalRevenue}
        />
      ) : (
        <></>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      {!!kpis?.customTargetsTable?.data ? (
        <PlanningTable
          setApplyBarFlag={setApplyBarFlag}
          setGraphData={setGraphData}
          setChartPlannedData={setChartPlannedData}
          data={kpis?.customTargetsTable?.data}
        />
      ) : (
        <PlanningTable
          setApplyBarFlag={setApplyBarFlag}
          setGraphData={setGraphData}
          setChartPlannedData={setChartPlannedData}
          data={plannedDataYearly}
        />
      )}
      <br />
      <br />
      <br />
    </wideViewsStyles.Wrapper>
  );
};

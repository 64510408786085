import styles from "./styles";
import { DownloadButton } from "../download-button/DownloadButton";
import { Box, Checkbox, Flex, Thead, Tooltip } from "@chakra-ui/react";
import { ProgressBar } from "../progress-bar/ProgressBar";
import { Channel } from "../../models/channel";
import { Column, Row, useRowSelect, useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { IconClearFiltersArrow } from "../../utils/icons/explore";
import {
  CurrencyCode,
  organizationDataLanguageSelector,
  organizationSelector,
} from "@store";
import { currency } from "components/general-overlay/GeneralOverlay";
import { useSelector } from "react-redux";
import { currencyOptions } from "utils/currency/currencyOptions";
import { currencyFormat } from "utils/kpi-render/kpiCalculation";
import { useCurrencySign } from "../../utils/custom-hooks/useCurrencySign";
import {MoreInfoDialog, MoreInfoDialogProps} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import {DataDialog, DataDialogProps} from "../../dialogs/data-dialog/DataDialog";
import {ColumnDefinitionType} from "../wideview-table/WideviewTable";
import {Effort} from "../../store/efforts";
import {useTranslation} from "react-i18next";
import {kpiDataSelector} from "../../store/kpis";
import {Kpi} from "../../models/kpi";



interface Props {
  title?: string;
  headers: string[];
  data: Channel[];
  icon: any;
  setSelectedRows?: any;
  noCurrencyFormat?: boolean;
  AovTable?: boolean;
  percantgeOnGrossColumn?: boolean;
  orderTable?: boolean;
  height?: number;
  tableHeight?: number;
  displayFooter?: true;
  setShowAllProducts?: (e?: any) => void;
  aov?: number;
  acos?: number;
  hideVsTarget?: true;
  displayImpactOnTarget?: boolean;
  netRevenueTable?: true;
  defaultSortByKey?: string
}

interface Data {
  col_0?: string;
  col_1?: number | string;
  col_2?: number | string;
  col_3?: number | string;
  col_4?: number | string;
  col_5?: number | string;
  col_6?: any;
}

export const getProgressBarColor = (percentage: number) => {
  if (!percentage) {
    return "#F7686A";
  }

  if(percentage <= 0){
    return "#F7686A";
  }
  if (percentage < 90) {
    return "#A3DB81";
  } else if (percentage < 100) {
    return "#A3DB81";
  }
  return "#A3DB81";
};

export const AcrossTable = (props: Props) => {
  const { t } = useTranslation(["translation", "translation"]);

  const dailyStatsSelector = useSelector(kpiDataSelector);
  const [dailyStatsData, setDailyStatsData] = useState<Kpi[]>([]);
  useEffect(() => {
    setDailyStatsData(!!dailyStatsSelector.data ? dailyStatsSelector.data.data : [])
  }, [dailyStatsSelector.data])

  // const dataDialogHeaders: ColumnDefinitionType<any, keyof any>[] = [
  //   {
  //     key: "createdAt",
  //     header: t("CREATED", { ns: "translation" })
  //   },
  //   {
  //     key: "id"
  //   }
  // ]
  const dataDialogProps = {
    title: 'Daily Stats',
    subtitle: '',
    // description: 'Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.',
    // tip: 'TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.',
    data: dailyStatsData
  } as DataDialogProps;
  const [isDataDialogOpen, setIsDataDialogOpen] = useState(false);


  const dataToDataTable = (data: Channel[]): Data[] => {
    const dataRender = (data: Channel[]) => {
      return data?.map((row, index) => {
        if (row?.aov !== undefined) {
          return {
            col_0: row.channelName,
            col_1: row.vsTarget,
            col_2: row.aov,
            col_3: row.grossRevenue,
            col_4: row.shareOfTotal,
            col_5: row.impactOnTarget,
            col_6: row?.channelId,
          };
        } else {
          return {
            col_0: row.channelName,
            col_1: row.vsTarget,
            col_2: !!row.grossRevenue ? row?.grossRevenue : 0,
            col_3: row.shareOfTotal,
            col_4: row.impactOnTarget,
            col_5: row?.channelId,
          };
        }
      });
    };

    return dataRender(data);
  };
  const headers = ["col_0", "col_1", "col_2", "col_3"];

  const headersToColumns = (headers: string[]): Column<Data>[] => {
    return headers?.map((header, index) => {
      return {
        Header: header,
        accessor: "col_" + index.toString(),
        sortType: "basic",
        Footer: (info) => {
          let totalColumnValue = 0;
          const currentColumnId = info?.column?.id;
          info?.data?.forEach((item: any) => {
            if (
              !!currentColumnId &&
              currentColumnId in item &&
              typeof item[currentColumnId] === "number"
            ) {
              totalColumnValue += item[currentColumnId];
            }
          });

          return (
            <>
              {typeof totalColumnValue === "number"
                ? totalColumnValue?.toLocaleString()
                : totalColumnValue}
            </>
          );
        },
      } as Column<Data>;
    });
  };

  // hooks
  const [selectedRows, setSelectedRows] = useState([] as Row<Data>[]);
  const data = useMemo<Data[]>(() => dataToDataTable(props.data), [props.data]);
  const columns = useMemo<Column<Data>[]>(
    () => headersToColumns(props.headers),
    [props.headers]
  );
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const currentCurrency = useCurrencySign();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, initialState: {
      sortBy: [
        {
          id: props?.defaultSortByKey ?? "col_3",
          desc: true
        }
      ]
    }}, useSortBy, useRowSelect);

  // helpers

  const percentageFormat = (amount: number) => {
    return amount?.toFixed(2).toString() + "%";
  };



  const selectedRow = (row: Row<Data>) => {
    row.toggleRowSelected();
    const isRowSelected = selectedRows.filter(
      (selectedRow) => selectedRow.id === row.id
    );
    if (!!isRowSelected && isRowSelected.length > 0) {
      setSelectedRows([
        ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
      ]);
      if (!!props?.setSelectedRows) {
        props?.setSelectedRows([
          ...selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
        ]);
      }
    } else {
      const newSelected = [...selectedRows];
      newSelected.push(row);
      setSelectedRows(newSelected);
      if (!!props?.setSelectedRows) {
        props?.setSelectedRows(newSelected);
      }
    }
  };

  const clearFilters = () => {
    rows?.forEach((row) => {
      if (row.isSelected) {
        row.toggleRowSelected();
      }
      if (!!props?.setSelectedRows) {
        props?.setSelectedRows([]);
      }
    });
  };

  const openLearnMorePopup = () => {
    setIsDataDialogOpen(true)
  }

  // renderers
  const renderClearFiltersButton = (placeholder?: boolean) => {
    return (
      <styles.ClearFiltersButtonWrapper
        style={{ opacity: placeholder ? 0 : 1 }}
        onClick={() => {
          setSelectedRows([] as Row<Data>[]);
          clearFilters();
        }}
      >
        <IconClearFiltersArrow />
      </styles.ClearFiltersButtonWrapper>
    );
  };

  const renderShowAllButton = () => {
    return !!props?.setShowAllProducts && (
        <Flex fontSize={"12px"} alignItems={"center"}>
          <Box w={"max-content"}>Show all</Box>
          <Box w={2} />
          <Checkbox
              colorScheme={"purple"}
              onChange={(e) => {
                !!props?.setShowAllProducts &&
                props?.setShowAllProducts(e?.target?.checked);
              }}
          />
        </Flex>
    )
  }

  const renderLearnMoreButton = () => {
    return (
        <Flex fontSize={"12px"} alignItems={"center"}>
          <styles.LearnMoreButton onClick={openLearnMorePopup}>Learn More</styles.LearnMoreButton>
          <DataDialog
              props={dataDialogProps}
              isDialogOpen={isDataDialogOpen}
              onClose={() => setIsDataDialogOpen(false)}
          />
        </Flex>
    )
  }

  return (
    <styles.Wrapper>
      <styles.Card height={props?.height}>
        <styles.Header>
          <styles.Title>
            <span style={{ marginRight: "5px" }}>{props.icon}</span>
            {props.title}
          </styles.Title>
          {renderLearnMoreButton()}
          {renderShowAllButton()}
          {selectedRows?.length > 0
            ? renderClearFiltersButton()
            : renderClearFiltersButton(true)}
          {/* <DownloadButton onClick={() => console.log('download')} /> */}
        </styles.Header>
        <styles.TableWrapper tableHeight={props?.tableHeight}>
          <styles.TableCustom variant="simple" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <styles.HeaderTr
                  {...{ selected: true, ...headerGroup.getHeaderGroupProps() }}
                >
                  {headerGroup.headers.map((column, i) => {
                    if (column.id === "col_1" && props?.hideVsTarget) {
                      return (
                        <styles.StyledTh
                          style={{ display: "none" }}
                          key={i}
                        ></styles.StyledTh>
                      );
                    }
                    if (
                      column.id === "col_4" &&
                      !props?.displayImpactOnTarget
                    ) {
                      return (
                        <styles.StyledTh
                          style={{ display: "none" }}
                          key={i}
                        ></styles.StyledTh>
                      );
                    }
                    if (column?.id === "col_0") {
                      return (
                        <styles.StyledTh
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          colSpan={2}
                        >
                          <Box display={"flex"}>
                            {column.render("Header")}

                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </Box>
                        </styles.StyledTh>
                      );
                    }
                    return (
                      <styles.StyledTh
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Box display={"flex"}>
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <GoTriangleUp />
                              ) : (
                                <GoTriangleDown />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </Box>
                      </styles.StyledTh>
                    );
                  })}
                </styles.HeaderTr>
              ))}
            </Thead>
            <styles.TbodyCustom {...getTableBodyProps()}>
              {rows.map((row) => {
                /*const tooltipString = !!row && !!row?.cells && !!row?.cells[0].value && row?.cells[0].value?.length > 75
                                ? row.cells[0]?.value : "";*/
                const tooltipString = "";
                prepareRow(row);
                return (
                  <styles.TrCustom
                    {...{
                      selected:
                        selectedRows?.some(
                          (selectedRow) => selectedRow?.index === row?.index
                        ) || selectedRows.length === 0,
                      ...row.getRowProps(),
                    }}
                    onClick={() => selectedRow(row)}
                  >
                    <styles.TdCustom colSpan={2}>
                      {/*<Tooltip label={""}>*/}
                      {row.cells[0].value?.length > 75
                        ? row.cells[0]?.value?.slice(0, 75) + "..."
                        : row.cells[0].value}
                      {/*</Tooltip>*/}
                    </styles.TdCustom>

                    {props?.netRevenueTable ? (
                      <styles.TdCustom>
                        {currencyFormat(row.cells[2].value, 2, currentCurrency)}
                      </styles.TdCustom>
                    ) : (
                      !props?.hideVsTarget && (
                        <styles.TdCustom>
                          {!!row.cells[1].value
                            ? percentageFormat(row.cells[1].value)
                            : 0 + "%"}
                          <Box w={"80%"}>
                            <ProgressBar
                              color={getProgressBarColor(row.cells[1].value)}
                              percentage={
                                !!row.cells[1].value ? row.cells[1].value : 0
                              }
                            />
                          </Box>
                        </styles.TdCustom>
                      )
                    )}
                    <styles.TdCustom>
                      {props?.netRevenueTable ? (
                        <styles.TdCustom>
                          {row?.original?.col_6?.toFixed(2) + "%"}
                        </styles.TdCustom>
                      ) : props?.noCurrencyFormat ? (
                        props?.percantgeOnGrossColumn ? (
                          row.cells[2].value?.toFixed(2) + "%"
                        ) : (
                          row.cells[2].value?.toFixed(props?.orderTable ? 0 : 2)
                        )
                      ) : (
                        currencyFormat(row.cells[2].value, 2, currentCurrency)
                      )}
                    </styles.TdCustom>

                    {props?.AovTable ? (
                      <styles.TdCustom>
                        {currencyFormat(row.cells[3].value, 0, "")}
                      </styles.TdCustom>
                    ) : (
                      <styles.TdCustom>
                        {percentageFormat(row.cells[3].value)}
                      </styles.TdCustom>
                    )}

                    {/*{props?.AovTable ?
                                    <styles.TdCustom>{percentageFormat(row.cells[4].value)}</styles.TdCustom> : <></>}*/}
                    {props?.displayImpactOnTarget ? (
                      <styles.TdCustom>
                        {(row.cells[4].value > 0 ? "+" : "") +
                          percentageFormat(row.cells[4].value)}
                        <div style={{ width: "40px" }}>
                          <ProgressBar
                            color={getProgressBarColor(row.cells[1].value)}
                            percentage={100}
                          />
                        </div>
                      </styles.TdCustom>
                    ) : (
                      <></>
                    )}
                  </styles.TrCustom>
                );
              })}
            </styles.TbodyCustom>
            <tfoot>
              {footerGroups.map((group) => (
                <styles.FooterTr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => {
                    if (headers?.includes(column?.id)) {
                      if (column?.id === "col_0") {
                        return (
                          <styles.GrandTotalTd colSpan={2}>
                            <styles.GrandTotalDiv>
                              Grand Total
                            </styles.GrandTotalDiv>
                          </styles.GrandTotalTd>
                        );
                      }
                      if (column?.id === "col_1") {
                        if (props?.hideVsTarget) {
                          return <styles.FooterEmptyCell></styles.FooterEmptyCell>;
                        }
                        return (
                          <styles.FooterTd>
                            <Box w={"100%"} />
                          </styles.FooterTd>
                        );
                      }

                      if (props?.AovTable && column?.Header === "AOV") {
                        return (
                          <styles.FooterTd {...column.getFooterProps()}>
                            {!!props?.aov
                              ? currencyFormat(props?.aov, 2, currentCurrency)
                              : 0}
                          </styles.FooterTd>
                        );
                      }
                      if (column?.Header === "ACOS") {
                        return (
                          <styles.FooterTd {...column.getFooterProps()}>
                            {props?.acos?.toFixed(2) + "%"}
                          </styles.FooterTd>
                        );
                      }
                      return (
                        <styles.FooterTd {...column.getFooterProps()}>
                          {column?.Header === "Gross Revenue" &&
                          !props?.noCurrencyFormat
                            ? currentCurrency
                            : ""}
                          {column.render("Footer")}
                          {column?.Header === "Share Of Total" ? "%" : ""}
                        </styles.FooterTd>
                      );
                    } else {
                      return <styles.FooterEmptyCell></styles.FooterEmptyCell>;
                    }
                  })}
                </styles.FooterTr>
              ))}
            </tfoot>
          </styles.TableCustom>
        </styles.TableWrapper>
        <styles.TotalRowsFooter>{props?.data?.length + " rows in total"}</styles.TotalRowsFooter>
      </styles.Card>
    </styles.Wrapper>
  );
};

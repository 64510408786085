// state

export enum SystemEventsType {
  INTEGRATIONS = 0,
  KPICALCULATION = 1
}

export interface SystemEvent{
  _id?: string;
  title?: string;
  message?: string;
  updatedAt?:string;
  type?: SystemEventsType;
  data?: any;
}

export interface SystemEventState {
  data?: SystemEvent[];
  loading?: boolean;
  integrationData?: SystemEvent[];
  count?: number;
}

// initial state
export const initialSystemEventsState: SystemEventState = {
  loading: false,
  data: undefined,
  integrationData: undefined,
  count: undefined,
};

import styles from "./styles";
import React from "react";
import {ColumnDefinitionType} from "./WideviewDataTable";


export interface WideviewTableHeaderProps<T, K extends keyof T> {
    columns: Array<ColumnDefinitionType<T, K>>;
}

const WideviewTableHeader = <T, K extends keyof T>({columns}: WideviewTableHeaderProps<T, K>) => {
    const headers = columns.map((column, index) => {
        return (
            <styles.ThCustom
                key={`headCell-${index}`}
                // style={styles.ThCustom}
            >
                {column.header}
            </styles.ThCustom>
        );
    });

    return (
        <thead>
        <styles.HeaderTr>
            {headers}
        </styles.HeaderTr>
        </thead>
    );
};

export default WideviewTableHeader;

import wideViewsStyles from "./styles";
import { OverTimeGraph } from "../../over-time-graph/OverTimeGraph";
import { getProgressBarColor } from "components/across-table/AcrossTable";
import { useTranslation } from "react-i18next";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import { Effort } from "../../../models/effort";
import { Channel } from "../../../models/channel";
import {
  IconAcrossChannels,
  IconAcrossProducts,
  IconClearFiltersArrow,
  IconDiamond,
  IconShare,
} from "../../../utils/icons/explore";

import React, { useEffect, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import { kpiStateSelector } from "store/kpis";
import { Kpi } from "models/kpi";
import { organizationDataLanguageSelector, organizationSelector } from "@store";
import { IntegrationPlatform } from "@models";
import { sumData } from "utils/get-months/getLastYear";
import { currencyOptions } from "utils/currency/currencyOptions";
import {
  currencyFormat,
  getOvertimeGraphDataForExplore,
} from "utils/kpi-render/kpiCalculation";
import {
  dateRangeSelector,
  ExploresEnum,
  openDefaultExploreView,
} from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { GroupBy } from "features/workspace/Workspace";
import { KpisEnum } from "models/kpi-settings";
import { getDatesInRange } from "utils/colors";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";
import { Box, Spinner, useDisclosure } from "@chakra-ui/react";
import { getChannelNameById } from "utils/get-channel-name/getChannelNameById";
import { channelSelector } from "store/channels";
import { effortsSelector } from "store/efforts";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import { TargetBadge } from "../../target-badge/TargetBadge";
import { productsFieldsSelector } from "../../../store/products";
import { linkProductData } from "../../../utils/link-data/linkProductData";
import { columns } from "../../../utils/effortTableHeaders/headers";
import {
  ExploreTableDataEnum,
  ExploreTableFooterTypeEnum,
  GenericExploreTable,
} from "../../generic-explore-table/GenericExploreTable";
import { ProgressBar } from "../../progress-bar/ProgressBar";
import { ImpactOnTarget } from "../../impact-on-target/ImpactOnTarget";

export interface ExploreProps {
    title?: string;
}

interface AcrossTableData {
    channelName?: string;
    vsTarget?: number;
    grossRevenue?: number | string;
    shareOfTotal?: number;
    impactOnTarget?: number;

}
export const OrdersExplore = (props: ExploreProps) => {
    // hooks
    const { isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose } = useDisclosure();  //

    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const { t } = useTranslation(['translation', 'translation']);
    const [kpiData, setKpiData] = useState<Kpi[]>([]);
    const [filteredByDatesData, setFilteredByDatesData] = useState<Kpi[]>([]);
    const [filteredByDatesDataTarget, setFilteredByDatesDataTarget] = useState<Kpi[]>([]);
    const [ganttLabels, setGanttLabels] = useState<string[]>([])

    const kpis = useSelector(kpiStateSelector);
    const [currentCurrency, setCurrentCurrency] = useState<string>("$");
    const [totalOrders, setTotalOrders] = useState(0);
    const [filteredByGroupData, setFilteredByGroupData] = useState<{}>();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<{
        channelName: string;
        grossRevenue: number | string;
        shareOfTotal: number;
        vsTarget: number;
        impactOnTarget: number;
    }[]>([]);
    const organizationId = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const datesSelector = useSelector(dateRangeSelector);
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const [graphData, setGraphData] = useState<any>();
    const channelsData = useSelector(channelSelector);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any>([]);
    const effortsData = useSelector(effortsSelector);
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const [productsData, setProductsData] = useState<any[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [productsFilteredByDates, setProductsFilteredByDates] = useState<any[]>([]);
    const channels = useSelector(channelSelector);
    const [targetKpiData, setTargetKpiData] = useState<Kpi[]>([]);
    const [vsTarget, setVsTarget] = useState(0);
    const allProductsData = useSelector(productsFieldsSelector);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'platform', 'impressions', 'imageName', 'market', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];
    const [showAllProducts, setShowAllProducts] = useState(false);


    useEffect(() => {
        const wvChannelsArray = selectedChannels?.map((item) => item?.original?.callbackId);
        if(!!allProductsData) {
            const allProductsIds:string[] = [];
            filteredByDatesData?.forEach((item) => {
                if(wvChannelsArray?.includes(item?.wvChannelId)){
                    if(!!item?.wvProductId && !allProductsIds?.includes((item?.wvProductId))){
                        allProductsIds?.push(item?.wvProductId)
                    }
                }
            })
            const linkedData = linkProductData(allProductsData, filteredByDatesData, wvChannelsArray, "orders", showAllProducts, allProductsIds)
            const productDataTable: Channel[] = linkedData?.map((item: any) => {
                return {
                    productName: item?.name === "NaN" || !item?.name ? item?.sku+"*" : item?.name ,
                    orders: item?.orders,
                    shareOfTotal: item?.share,
                    callbackId: item?.id
                }
            })
            setProductsData(productDataTable);
        }
    }, [datesSelector?.endDate, datesSelector?.startDate, allProductsData, filteredByDatesData, selectedChannels, showAllProducts]);

    useEffect(() => {

        let filteredData = filteredByDatesData;
        let targetData = filteredByDatesDataTarget;
        if(selectedChannels?.length > 0){
            const selectedChannelsIds= selectedChannels?.map((item) => item?.original?.callbackId);
            filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
            targetData = targetData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
        }
        if(selectedProducts?.length > 0){
            const selectedChannelsIds= selectedProducts?.map((item) => item?.original?.callbackId);
            filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvProductId));
            targetData = targetData?.filter((item) => selectedChannelsIds?.includes(item?.wvProductId));
        }

        const summedGrossRevenueByKpiData = sumData(filteredData, keysToIgnore);
        const summedGrossRevenueByTargetKpiData = sumData(targetData, keysToIgnore);
        const vsTarget = summedGrossRevenueByKpiData["orders"] / summedGrossRevenueByTargetKpiData["orders"] * 100;
        setVsTarget(vsTarget)
    }, [filteredByDatesData, filteredByDatesDataTarget, selectedChannels, selectedProducts])

    useEffect(() => {
        if (!!targetKpiData) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = targetKpiData?.filter((item) => {
                    return (datesRange.includes(item.activityDate.slice(0, 10)) && !!item?.wvChannelId);
                });
                setFilteredByDatesDataTarget(filteredData);
            }
            else {
                const filteredData =  targetKpiData?.filter((item) => !!item?.wvChannelId)
                setFilteredByDatesDataTarget(filteredData);
            }
        }
    }, [targetKpiData, startDate, endDate]);


    useEffect(() => {
        //filter efforts by kpi
        if (!!effortsData) {
            const filteredEfforts = effortsData?.data.filter((item) => {
                return item?.kpi === KpisEnum.ORDERS;
            });
            setEffortsTable(filteredEfforts);
        }

    }, [effortsData]);

    useEffect(() => {
        if (!!filteredByDatesData) {
            const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'platform', 'impressions', 'imageName', 'market', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];
            const summedData = sumData(filteredByDatesData, keysToIgnore);
            if (summedData?.orders !== undefined && summedData?.newCustomers !== undefined && summedData?.returningCustomers !== undefined) {
                const graphDataToRender = {
                    title: "Orders",
                    value: currencyFormat(summedData?.orders, 0, ""),
                    vsTargetPercentage: summedData?.grossRevenue / summedData?.grossRevenue,
                    isRoot: true,
                    children: [
                        {
                            title: "New Orders",
                            value: summedData?.newCustomers,
                            vsTargetPercentage: summedData['newCustomers'] / summedData['orders'],
                            isRoot: false,
                            children: []
                        },
                        {
                            title: "Returning Orders",
                            value: summedData?.returningCustomers,
                            vsTargetPercentage: 95,
                            isRoot: false,
                            children: []
                        }
                    ]
                };
                setGraphData(graphDataToRender);
            }
        }

    }, [currentCurrency, filteredByDatesData, startDate, endDate]);

    useEffect(() => {
        if(!!kpis?.targetData?.data){
            const allChannelsIds = channels?.map((item) => item?.id)
            let filteredData = kpis?.targetData?.data?.filter((item) => allChannelsIds?.includes(item?.wvChannelId));

            if(selectedChannels?.length > 0){
                const selectedChannelsIds= selectedChannels?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
            }
            if(selectedProducts?.length > 0){
                const selectedChannelsIds= selectedProducts?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvProductId));
            }
            setTargetKpiData(filteredData)
        }
    }, [kpis?.targetData?.data, channels, selectedChannels, selectedChannels]);

    useEffect(() => {
        if(!!kpis?.data?.data){
            const allChannelsIds = channels?.map((item) => item?.id)
            let filteredData = kpis?.data?.data?.filter((item) => allChannelsIds?.includes(item?.wvChannelId));

            if(selectedChannels?.length > 0){
                const selectedChannelsIds= selectedChannels?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
            }
            if(selectedProducts?.length > 0){
                const selectedChannelsIds= selectedProducts?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvProductId));
            }
            setKpiData(filteredData)
        }
    }, [kpis?.data?.data, channels, selectedChannels, selectedProducts]);

    useEffect(() => {
        if (!!kpis.data?.data) {
            const allChannelsIds = channels?.map((item) => item?.id)
            if (!!startDate && !!endDate) {

                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.data?.data.filter((item) => {
                    return (datesRange.includes(item.activityDate.slice(0, 10)) && allChannelsIds?.includes(item?.wvChannelId));
                });
                setFilteredByDatesData(filteredData);
                /*setKpiData(filteredData);*/
            }
            else {
                const filteredData = kpis?.data?.data.filter((item) => allChannelsIds?.includes(item?.wvChannelId));
                setFilteredByDatesData(filteredData);
                /*setKpiData(filteredData);*/
            }
        }
    }, [kpis.data?.data, startDate, endDate, channels]);

    useEffect(() => {
        if (!!kpis.financeKpi?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.financeKpi?.data.filter((item) => {
                    return datesRange.includes(item?.orderDate?.slice(0, 10));
                });
                setProductsFilteredByDates(filteredData);
            }
            else {
                setProductsFilteredByDates(kpis.financeKpi?.data);
            }
        }
    }, [kpis?.financeKpi?.data, startDate, endDate]);

    useEffect(() => {
        if (!!filteredByDatesData && !!channelsData) {
            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const targetDate: { channelName?: string, orders: number, shareOfTotal?: number, vsTarget?: number, impactOnTarget?: number, channelId?: string; }[] = [];
            const rowData: { channelName?: string, orders: number, shareOfTotal?: number, vsTarget?: number, impactOnTarget?: number, channelId?: string; }[] = [];
            const channels = {};
            filteredByDatesData?.forEach((item) => {
                if (!!channels[item?.wvChannelId]) {
                    channels[item?.wvChannelId]?.push(item);
                }
                else {
                    channels[item?.wvChannelId] = [item];
                }
            });
            const targetChannel = {};
            filteredByDatesDataTarget?.forEach((item) => {
                if (!!targetChannel[item?.wvChannelId]) {
                    targetChannel[item?.wvChannelId]?.push(item);
                }
                else {
                    targetChannel[item?.wvChannelId] = [item];
                }
            });

            Object.keys(channels).forEach((key) => {
                const summedChannel = sumData(channels[key], keysToIgnore);
                rowData.push({ orders: summedChannel["orders"], channelName: getChannelNameById(key, channelsData), channelId: key });
                if(!!targetChannel[key]){
                    const summedTargetChannel = sumData(targetChannel[key], keysToIgnore);
                    targetDate.push({ orders: summedTargetChannel["orders"], channelName: getChannelNameById(key, channelsData), channelId: key });
                }
            });
            let totalTargetGrossRevenue = 0;
            targetDate?.forEach((item) => {
                totalTargetGrossRevenue = totalTargetGrossRevenue + item?.orders;
            });
            let totalGrossRevenue = 0;
            rowData?.forEach((item) => {
                totalGrossRevenue = totalGrossRevenue + item?.orders;
            });
            const newRowData = rowData?.map((item) => {
                const currentFoundTarget = targetDate?.find((targetItem) => targetItem?.channelId === item?.channelId);
                return {
                    ...item,
                    shareOfTotal: Math.round((item?.orders / totalGrossRevenue) * 100 * 100) / 100,
                    vsTarget: !!currentFoundTarget && !!currentFoundTarget?.orders && !!item?.orders ? item?.orders / currentFoundTarget?.orders * 100 : 0,
                    impactOnTarget:!!currentFoundTarget?.orders ?  ((item?.orders - currentFoundTarget?.orders) / totalTargetGrossRevenue) * 100 : 0 ,
                    callbackId: item?.channelId
                };
            });
            setAcrossChannelsData(newRowData);
        }
    }, [channelsData, filteredByDatesData, filteredByDatesDataTarget]);

    useEffect(() => { // get currency sign
        if (dataLanguage?.generalSettings?.currency !== undefined && !!dataLanguage?.generalSettings) {
            const currencySign = currencyOptions.filter((item) => (item.value.enum === dataLanguage!.generalSettings!.currency));
            setCurrentCurrency(currencySign[0].value.sign);
        }

    }, [dataLanguage]);

    useEffect(() => {
        if (!!filteredByDatesData) {
            let filteredData = filteredByDatesData;
            if(selectedChannels?.length > 0){
                const selectedChannelsIds= selectedChannels?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvChannelId));
            }
            if(selectedProducts?.length > 0){
                const selectedChannelsIds= selectedProducts?.map((item) => item?.original?.callbackId);
                filteredData = filteredData?.filter((item) => selectedChannelsIds?.includes(item?.wvProductId));
            }
            const summedData = sumData(filteredData, []);
            setTotalOrders(summedData['orders']);
        }
    }, [filteredByDatesData, selectedChannels, selectedProducts, filteredByDatesDataTarget]);

    useEffect(() => {

        if (!!kpiData && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const graphData = getOvertimeGraphDataForExplore(kpiData,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "orders");
            setActualDataChart(graphData.data);
            setLabels(graphData.labels);
        }
    }, [kpiData, datesSelector?.startDate, datesSelector?.endDate, groupByStatus]);


    useEffect(() => {

        if (!!targetKpiData && !!datesSelector?.startDate && !!datesSelector?.endDate) {
            const graphData = getOvertimeGraphDataForExplore(targetKpiData,datesSelector?.startDate , datesSelector?.endDate, groupByStatus, "orders");
            setTargetDataChart(graphData.data);
            setLabels(graphData.labels);
        }
    }, [targetKpiData, datesSelector?.startDate, datesSelector?.endDate, groupByStatus]);

    useEffect(() => {
        let counter = 0;
        if (filteredByGroupData) {
            Object.keys(filteredByGroupData).forEach(key => {
                counter = filteredByGroupData[key] + counter;
            });
            const tableData = Object.keys(filteredByGroupData).map(key => {
                return {
                    channelName: IntegrationPlatform[key],
                    grossRevenue: (filteredByGroupData[key]),
                    shareOfTotal: filteredByGroupData[key] / counter * 100,
                    vsTarget: 105,
                    impactOnTarget: 1.5
                };
            });
            setTableData(tableData);
        }
    }, [filteredByGroupData]);


    // helpers


    const resetFilters = () => {
        if (!!datesSelector?.endDate && !!datesSelector?.startDate) {
            setStartDate(new Date(datesSelector.startDate));
            setEndDate(new Date(datesSelector.endDate));
        }
    };


    const onApplyDates = () => {

    };


    const openShareModal = () => {
        onShareOpen();
    };

    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton onClick={() => openShareModal()}>
            <IconShare />
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderDatePicker = () => {
        return (
            <>
                {
                    !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => { onApplyDates(); }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)} />
                        : <DatepickerRange onApply={(e) => { onApplyDates(); }} defaultsDate={datesSelector.preset} />
                }
            </>
        )

            ;
    };

    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow />
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };


    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                    <IconCloseOverlay />
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    {/*{props.title}*/}
                    {t('ORDERS', { ns: 'translation' })}
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderResetFilters()}
                    {renderDatePicker()}
                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currencyFormat(totalOrders, 0, "")}
                </wideViewsStyles.Value>
                 <wideViewsStyles.BadgeWrapper>
                    <TargetBadge  percentage={vsTarget} />
                </wideViewsStyles.BadgeWrapper>
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };

    return (
        <wideViewsStyles.Wrapper>
            <ShareKpi exploreEnum={ExploresEnum.ORDERS} isOpen={isShareOpen} onClose={onShareClose} onOpen={onShareOpen} />
            <EffortsModal dataToShow={{ kpi: "Orders" }} isOpen={isEffortsOpen} onClose={onEffortsClose} onOpen={onEffortsOpen} />

            {renderHeader()}
            <wideViewsStyles.SectionOne>
                <OverTimeGraph exploreGraph={true} displayGantt={true} setGanttLabels={setGanttLabels} clearFilters={resetFilters} groupByStatus={groupByStatus} setGroupBy={setGroupByStatus} kpiEnum={KpisEnum.ORDERS} setStartDate={setStartDate} setEndDate={setEndDate} actual={actualDataChart} target={targetDataChart} labels={labels} />
            </wideViewsStyles.SectionOne>
            {tableData !== undefined ? <wideViewsStyles.SectionTwo>
                <GenericExploreTable
                    icon={<IconAcrossChannels />}
                    setSelectedRows={setSelectedChannels}
                    data={acrossChannelsData}
                    channelsTable={true}
                    defaultSortByKey={"shareOfTotal"}
                    headers={[
                        {
                            header: "Channel",
                            accessor: "channelName",
                            footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                        },
                        {
                            header: "Orders",
                            accessor: "orders",
                            cellType: ExploreTableDataEnum.NUMBER,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        {
                            header: "Share Of Total",
                            accessor: "shareOfTotal",
                            cellType: ExploreTableDataEnum.PERCENTAGE,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        {
                            header: "Vs Target",
                            accessor: "vsTarget",
                            cell: (info, row: any) => {
                                return (
                                    <Box w={"100%"}>
                                        {!!info ? info?.toFixed(2)+"%" : 0+"%"}
                                        <div style={{ width: "85%" }}>
                                            <ProgressBar
                                                color={getProgressBarColor(info)}
                                                percentage={info}
                                            />
                                        </div>
                                    </Box>)
                            }
                        },
                        {
                            header: "Impact on Target",
                            accessor: "impactOnTarget",
                            cell: (info, row: any) => {
                                return (
                                    <Box w={"100%"}>
                                        {!!info ? info?.toFixed(2)+"%" : 0+"%"}
                                        <div style={{ width: "85%" }}>
                                            <ImpactOnTarget
                                                color={getProgressBarColor(info)}
                                                percentage={info}
                                            />
                                        </div>
                                    </Box>)
                            }
                        },
                    ]}
                    height={400}
                    tableHeight={320}
                    title={t("ACROSS_CHANNELS", { ns: "translation" })}
                />
                <div style={{ width: '20px' }} />
                <GenericExploreTable
                    icon={<IconAcrossProducts />}
                    setSelectedRows={setSelectedProducts}
                    data={productsData}
                    defaultSortByKey={"shareOfTotal"}
                    headers={[
                        {
                            header: "Product Name",
                            accessor: "productName",
                            footerType: ExploreTableFooterTypeEnum.GRAND_TOTAL,
                            numberOfRowsToDisplay: 2
                        },
                        {
                            header: "Orders",
                            accessor: "orders",
                            cellType: ExploreTableDataEnum.NUMBER,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        {
                            header: "Share Of Total",
                            accessor: "shareOfTotal",
                            cellType: ExploreTableDataEnum.PERCENTAGE,
                            footerType: ExploreTableFooterTypeEnum.SUM,
                        },
                        /*{
                            header: "Vs Target",
                            accessor: "vsTarget",
                            cell: (info, row: any) => {
                                return (
                                    <Box w={"100%"}>
                                        {!!info ? info?.toFixed(2)+"%" : 0+"%"}
                                        <div style={{ width: "85%" }}>
                                            <ProgressBar
                                                color={getProgressBarColor(info)}
                                                percentage={info}
                                            />
                                        </div>
                                    </Box>)
                            }
                        },*/
                    ]}
                    height={400}
                    tableHeight={320}
                    setShowAllProducts={setShowAllProducts}
                    title={t("ACROSS_PRODUCTS", { ns: "translation" })}
                />

            </wideViewsStyles.SectionTwo> : null}

           {/* <wideViewsStyles.SectionThree>
                {!!graphData && <Graph graphData={graphData} title={t('SUPPORTING_KPIS', { ns: 'translation' })} />}
            </wideViewsStyles.SectionThree>*/}
            <wideViewsStyles.SectionFour>
                <styles.Card>
                    <styles.Header>
                        <styles.Title>
                            <span style={{ marginRight: '5px' }}>
                                {<IconDiamond />}
                            </span>
                            {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        </styles.Title>
                        <DownloadButton onClick={() => console.log('download')} />
                    </styles.Header>
                    {!!effortsTable ? <EffortsTable
                        title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        data={effortsTable}
                        cameFromExplore
                        onOpen={onEffortsOpen}
                        headers={columns}
                    /> : <Spinner colorScheme={"purple"} />}

                </styles.Card>
            </wideViewsStyles.SectionFour>
        </wideViewsStyles.Wrapper>
    );
};

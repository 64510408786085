import React, { useEffect, useRef, useState } from 'react';
import { IntegrationPlatform } from "@models";

export type IWindowProps = {
    url: string;
    title: string;
    width: number;
    height: number;
};

export type IPopupProps = IWindowProps & {
    onClose: () => void;
    onResponse: (params?: URLSearchParams) => void;
    children: React.ReactNode;
    platform?: IntegrationPlatform;
    imageUrl?: string;
};

const createPopup = ({ url, title, height, width, }: IWindowProps) => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const win = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    return win;
};


const OAuthPopup: React.FC<IPopupProps> = (
    { title = '', width = 500, height = 500, url, children, onResponse, onClose }: IPopupProps
) => {
    const [externalWindow, setExternalWindow] = useState<Window | null>();
    const intervalRef = useRef<number>();

    const clearTimer = () => {
        window.clearInterval(intervalRef.current);
    };

    const onContainerClick = () => {
        setExternalWindow(createPopup({
            url, title, width, height,
        }));
    };


    useEffect(() => {
        let handleRunOnce = false;


        if (externalWindow) {
            intervalRef.current = window.setInterval(() => {
                try {
                    const currentUrl = externalWindow.location.href;
                    console.log(currentUrl);
                    const params = new URL(currentUrl).searchParams;
                    console.log(params)
                    const oAuth = params.get('oauthsuccess');
                    if (!oAuth) {
                        return;
                    }
                    if(!!onResponse && !handleRunOnce){
                        onResponse(params);
                        handleRunOnce = true;
                    }
                    clearTimer();
                    externalWindow.close();
                } catch (error) {
                    console.log(error)
                    // eslint-ignore-line
                } finally {
                    if (!externalWindow || externalWindow.closed) {
                        onClose();
                        clearTimer();
                    }
                }
            }, 700);
        }
        return () => {
            if (externalWindow) {
                externalWindow.close();
                console.log(externalWindow.location.href);
            }
            if (onClose) {
                onClose();
            }
        };
    }, [externalWindow, onClose, onResponse]);

    return (
        <div onClick={() => {
            onContainerClick();
        }}>
            {children}
        </div>
    );
};

export default OAuthPopup;

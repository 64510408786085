import wideViewsStyles from "./styles";
import {
  extraDataset,
  OverTimeGraph,
} from "../../over-time-graph/OverTimeGraph";
import { useTranslation } from "react-i18next";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import {
  IconAcrossChannels,
  IconClearFiltersArrow,
  IconDiamond,
  IconShare,
} from "../../../utils/icons/explore";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCpoReports,
  fetchCpoTableConfig,
  kpiStateSelector,
} from "store/kpis";
import { Kpi } from "models/kpi";
import {
  organizationDataLanguageMappingSelector,
  organizationDataLanguageSelector,
  organizationSelector,
} from "@store";
import { MarketingKpi } from "@models";
import { Effort, effortsSelector, EffortsState } from "store/efforts";
import { sumData } from "utils/get-months/getLastYear";
import {
  addToasts,
  dateRangeSelector,
  ExploresEnum,
  isDefaultExploreOpenSelector,
  openDefaultExploreView,
} from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import {
  currencyFormat,
  getOvertimeGraphDataForExplore,
} from "utils/kpi-render/kpiCalculation";
import { Flex, Spinner, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ShareKpi } from "components/share-kpi/ShareKpi";
import { KpisEnum } from "models/kpi-settings";
import { getKpiNameFromEnum } from "utils/kpi-names/kpiName";
import { GroupBy } from "features/workspace/Workspace";
import { getDatesInRange } from "utils/colors";
import { channelsStateSelector } from "store/channels";
import { EffortsModal } from "components/efforts-modal/EffortsModal";
import { columns } from "../../../utils/effortTableHeaders/headers";
import { useCurrencySign } from "../../../utils/custom-hooks/useCurrencySign";
import { BiCopyAlt } from "react-icons/bi";
import { Toast, ToastStatus } from "../../toast/Toast";
import { BlendedRoasReportShowBy } from "../../../services/kpi/kpi.service";
import CpoTable from "../../cpo-table/CpoTable";
import useFetchDailyStats from "../../../utils/custom-hooks/useFetchDailyStats";
import InformationWrapper from "../../InformationWrapper/InformationWrapper";

export interface ExploreProps {
    title?: string;
}

export const CpoExplore = (props: ExploreProps) => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const kpis = useSelector(kpiStateSelector);
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    useFetchDailyStats('cpo')

    const { isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose } = useDisclosure();  //

    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [acrossChannelsData, setAcrossChannelsData] = useState<any>([]);

    const [sourceTableData, setSourceTableData] = useState<any>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const organization = useSelector(organizationSelector);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const dispatch = useDispatch();
    const datesSelector = useSelector(dateRangeSelector);
    
    const [acrossSourcesTableFilter, setAcrossSourcesTableFilter] = useState<{
      original?: { callbackIds?: string[] };
    }[]>([]);
    const [acrossChannelsFilters, setAcrossChannelsFilters] = useState<{
        original?: { callbackId: string };
    }[]>([]);

    const [currentBlendedRoas, setCurrentBlendedRoas] = useState<number>(0);
    const [extraDatasets, setExtraDatasets] = useState<extraDataset[]>([]);

    const efforts: EffortsState = useSelector(effortsSelector);
    const organizationId = useSelector(organizationSelector);
    const [filteredData, setFilteredData] = useState<MarketingKpi[]>([]);
    const [filteredSummedData, setFilteredSummedData] = useState<any>();
    const [graphData, setGraphData] = useState<any>();
    const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
    const [marketingStats, setMarketingStats] = useState<MarketingKpi[]>([]);
    const [filteredByDatesDataDaily, setFilteredByDatesDataDaily] = useState<Kpi[]>([]);
    const [summedData, setSummedData] = useState<any[]>([]);
    const channelsData = useSelector(channelsStateSelector);
    const [effortsTable, setEffortsTable] = useState<Effort[]>([]);
    const marketingMapping = useSelector(organizationDataLanguageMappingSelector);
    const currency = useCurrencySign();
    const [allExpendedRows, setAllExpendedRows] = useState<{ [key: string]: boolean; }>({});
    const tableRef = useRef<any>(null);

    useEffect(() => {
        if(!!organization?.id && isExploreOpen?.isDefaultExploreOpen){

            const timeFilterDates = {
                startDate: datesSelector?.startDate+"T00:00:00.000Z",
                endDate: datesSelector?.endDate+"T23:59:59.999Z"
            };

            let fetchStartDate = timeFilterDates?.startDate;
            let fetchEndDate = timeFilterDates?.endDate;

            const overtimeDatesFilter = {
                startDate: !!startDate ? startDate?.toISOString()?.slice(0,10)+"T00:00:00.000Z" : undefined,
                endDate: !!endDate ? endDate?.toISOString()?.slice(0,10)+"T23:59:59.999Z" : undefined
            }

            if(!!overtimeDatesFilter?.startDate && !!overtimeDatesFilter?.endDate){
                fetchStartDate = overtimeDatesFilter?.startDate;
                fetchEndDate = overtimeDatesFilter?.endDate;
            }
            if(!!fetchEndDate && !!fetchStartDate){
                dispatch(fetchCpoReports({
                    organizationId: organization?.id,
                    searchQueryDto: {
                        fromDate: fetchStartDate,
                        toDate:fetchEndDate,
                        showBy: 3,
                        showByReport: BlendedRoasReportShowBy.GROSS_REVENUE
                    }
                }))
            }
        }

    }, [startDate, endDate, organization?.id, datesSelector?.startDate, datesSelector?.endDate, isExploreOpen?.isDefaultExploreOpen])

    useEffect(() => {
        if(!!kpis?.exploreData?.data && !!datesSelector?.startDate && datesSelector?.endDate){
            const allChannels = acrossChannelsFilters?.map((item) => item?.original?.callbackId);
            let data = kpis?.exploreData?.data;
            if(allChannels?.length > 0){
                data = data?.filter((item) => allChannels?.includes(item?.wvChannelId))
            }
            const result = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                'cpo'
            );

            const graphDataMarketing = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                "marketingCost"
            );
            const graphDataOrders = getOvertimeGraphDataForExplore(
                data,
                datesSelector?.startDate,
                datesSelector?.endDate,
                groupByStatus,
                'orders'
            );

            setActualDataChart(result?.data)
            setLabels(result?.labels)

            const datasets = [
                {label: 'Orders', dataset: graphDataOrders?.data, color: '#90ee90' },
                {label: 'Marketing Cost', dataset: graphDataMarketing?.data, color: "#ee90a1" },
            ]

            setExtraDatasets(datasets)
        }

    }, [kpis?.exploreData?.data, datesSelector?.startDate, datesSelector?.endDate, groupByStatus, acrossChannelsFilters])

    useEffect(() => {
        if(!!organization?.id && isExploreOpen?.isDefaultExploreOpen){
            dispatch(fetchCpoTableConfig({organizationId: organization?.id}))
        }
    }, [organization?.id, isExploreOpen?.isDefaultExploreOpen]);

    useEffect(() => {
        //filter efforts by kpi
        if (!!efforts) {
            const filteredEfforts = efforts?.data.filter((item) => {
                return item?.kpi === KpisEnum.COST_PER_ORDER;
            });
            setEffortsTable(filteredEfforts);
        }

    }, [efforts]);

    useEffect(() => {
        if (!!kpis?.marketing?.data) {
            const keysToIgnore = ['updatedAt', 'type', 'wvChannelId', 'purchasePoint', 'market', 'platform', 'impressions', 'imageName', 'image', 'id', 'createdAt', 'clicks', 'campaignName', 'campaignId', 'adsGroup', 'adSetId', 'adId', 'ad', 'activityDate', 'account'];

            const summedData = sumData(kpis?.marketing?.data, keysToIgnore);
            setFilteredData(kpis?.marketing?.data);
            setFilteredSummedData(summedData);
        }
    }, [kpis?.marketing?.data]);

    useEffect(() => {
        if (!!filteredByDatesDataDaily) {
            let data = filteredByDatesDataDaily;
            const marketingChannelFilters = acrossSourcesTableFilter?.map((row) => row?.original?.callbackIds?.flat())?.flat();
            const channelsFilters = acrossChannelsFilters?.map((row) => row?.original?.callbackId);
            if(marketingChannelFilters?.length > 0){
                data = data?.filter((item) => marketingChannelFilters?.includes(item?.wvChannelId));
            }
            if(channelsFilters?.length > 0){
                data = data?.filter((item) => channelsFilters?.includes(item?.wvChannelId));
            }

            const summedData = sumData(data, ["activityDate"]);
            setSummedData(summedData);
            if (summedData?.marketingCost !== undefined) {
                setCurrentBlendedRoas(summedData?.marketingCost / summedData?.orders);
                const graphDataToRender = {
                    title: "CPO",
                    value: (summedData?.marketingCost / summedData?.orders)?.toFixed(2),
                    vsTargetPercentage: 100,
                    isRoot: true,
                    children: [
                        {
                            title: "Spend",
                            value: currencyFormat(summedData?.marketingCost, 2, currency),
                            vsTargetPercentage: 100,
                            isRoot: false,
                            children: []
                        },
                        {
                            title: "Orders",
                            value: currencyFormat(summedData?.orders, 0, ""),
                            vsTargetPercentage: 100,
                            isRoot: false,
                            children: []
                        }
                    ]
                };
                setGraphData(graphDataToRender);
            }

        }
    }, [filteredByDatesDataDaily, acrossChannelsFilters, acrossSourcesTableFilter]);


    useEffect(() => {
        if (!!kpis.exploreData?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.exploreData?.data.filter((item) => {
                    return datesRange.includes(item.activityDate.slice(0, 10));
                });
                setFilteredByDatesDataDaily(filteredData);
            }
            else {
                setFilteredByDatesDataDaily(kpis.exploreData?.data);
            }
        }
    }, [kpis.exploreData?.data, startDate, endDate]);

    // selectors

    const handleCopyTable = () => {
        const table = tableRef.current;
        if (!table) return;

        // Create a range to select the table contents
        const range = document.createRange();
        range.selectNode(table);

        // Clear any existing selection and select the table
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }

        // Copy the selected table contents to the clipboard
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                dispatch(addToasts({
                    toasts: [
                        {
                            title: 'Copied to clipboard!',
                            isClosable: true,
                            status: ToastStatus.SUCCESS,
                            duration: 2500 //in milliseconds
                        } as Toast
                    ]
                }))
                console.log('Table copied to clipboard!');
            } else {
                console.log('Table copy failed.');
            }
        } catch (error) {
            console.log('Table copy failed.', error);
        }

        // Clear the selection
        if (selection) {
            selection.removeAllRanges();
        }
    }

    const renderCpoTable = () => {
        return (
            <styles.Card>
                <Flex alignItems={'center'} gap={'4px'} padding={'12px 0'} width={'98.5%'} margin={'0 auto'}>
                    <styles.Title>
                                <span style={{marginRight: '5px'}}>
                                    {<IconAcrossChannels/>}
                                </span>
                        {t('CPO', {ns: 'translation'})}
                    </styles.Title>
                    <Tooltip label={'Copy CPO Table'}>
                        <wideViewsStyles.StyledHeaderButton onClick={handleCopyTable}><BiCopyAlt fontSize={'14px'} color={'white'}/></wideViewsStyles.StyledHeaderButton>
                    </Tooltip>
                </Flex>

                <CpoTable setSelectedChannels={setAcrossChannelsFilters} allExpendedRows={allExpendedRows} setAllExpendedRows={setAllExpendedRows}
                                           data={kpis?.cpoReports?.data }  tableRef={tableRef}/>


            </styles.Card>
        )
    }



    // helpers



    const onApplyDates = () => {
    };

    const clearFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    };



    const openShareModal = () => {
        onShareOpen();
    };
    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton onClick={() => openShareModal()}>
            <IconShare />
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow />
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                    <IconCloseOverlay />
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    <InformationWrapper text={t('CPO', {ns: 'translation'})} />
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {/*{renderResetFilters()}*/}
                    {

                        !!datesSelector?.startDate && !!datesSelector?.endDate ? <DatepickerRange onApply={(e) => { onApplyDates(); }} from={new Date(datesSelector?.startDate)} to={new Date(datesSelector?.endDate)} />
                            : <DatepickerRange onApply={(e) => { onApplyDates(); }} defaultsDate={datesSelector.preset} />
                    }

                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {currencyFormat(currentBlendedRoas, 2, currency)}
                </wideViewsStyles.Value>
                {/* <wideViewsStyles.BadgeWrapper>
                    <TargetBadge color={'#57a11e'} percentage={100} />
                </wideViewsStyles.BadgeWrapper> //TODO .V2 */}
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };
    const [ganttLabels, setGanttLabels] = useState<string[]>([])

    return (
        <>
            <ShareKpi exploreEnum={ExploresEnum.COST_PER_ORDER} isOpen={isShareOpen} onClose={onShareClose} onOpen={onShareOpen} />
            <EffortsModal cameFromEdit dataToShow={{ kpi: "CPO" }} isOpen={isEffortsOpen} onClose={onEffortsClose} onOpen={onEffortsOpen} />
            <wideViewsStyles.Wrapper>
                {renderHeader()}
                <wideViewsStyles.SectionOne>
                    <OverTimeGraph exploreGraph={true} displayGantt={true} setGanttLabels={setGanttLabels}
                                   clearFilters={clearFilters} groupByStatus={groupByStatus} setGroupBy={setGroupByStatus}
                                   kpiEnum={KpisEnum.COST_PER_ORDER} setStartDate={setStartDate} setEndDate={setEndDate}
                                   actual={actualDataChart} target={targetDataChart} labels={labels}  extraDatasets={extraDatasets}/>
                </wideViewsStyles.SectionOne>
                <wideViewsStyles.SectionTwo>
                    {renderCpoTable()}

                    <div style={{ width: '20px' }} />

                </wideViewsStyles.SectionTwo>
               {/* <wideViewsStyles.SectionThree>
                    {!!graphData ? <Graph graphData={graphData} title={t('SUPPORTING_KPIS', { ns: 'translation' })} /> : <></>}
                </wideViewsStyles.SectionThree>*/}
              {/*  <wideViewsStyles.SectionFour>
                    <styles.Card>
                        <styles.Header>
                            <styles.Title>
                                <span style={{ marginRight: '5px' }}>
                                    {<IconDiamond />}
                                </span>
                                {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                            </styles.Title>
                            <DownloadButton onClick={() => console.log('download')} />
                        </styles.Header>
                        {!!effortsTable ? <EffortsTable
                            title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                            data={effortsTable}
                            cameFromExplore
                            onOpen={onEffortsOpen}

                            headers={columns}

                        /> : <Spinner colorScheme={"purple"} />}
                    </styles.Card>
                </wideViewsStyles.SectionFour>*/}
            </wideViewsStyles.Wrapper>
        </>
    );
};

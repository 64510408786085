import styled from "styled-components";

export namespace styles {

    export const TableHolder = styled.div`
        overflow-y: auto;
        width: 100%;


      ::-webkit-scrollbar-track{
        margin-left: 200px;
      }
    `

    export const StyledTable = styled.table`
      border-spacing: 0;
      //border: 1px solid black;
      background-color: white;
      display: table;
      min-height: 500px;
      width: 100%;
      border-collapse: collapse;
      font-family: ${props => props?.theme?.fonts?.roboto?.regular};
      font-weight: lighter;
      font-size: smaller;
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
    `;

    export const StyledTh = styled.th<{highlightCell?: boolean, minWidth?: number}>`
     
      margin: 0;
      padding: 0.5rem;
      height: 20px;
      text-align: left;
      min-width: ${props => props?.minWidth ? props?.minWidth + 'px' : '0'};
      max-width: 250px !important;
      border-bottom: ${props => props?.highlightCell ? `2px solid ${props?.theme?.primaryColor}` : ''};
      //border-right: 1px solid black;
      background-color: ${props => props?.highlightCell ? '#F5F5F5' : 'white'};
       /* z-index: 2;*/
      position: relative;
      &:hover{
        cursor: pointer;
      }

      :last-child {
        border-right: 0;
      }
  `;

    export const StyledThName = styled.th`
      margin: 0;
      padding: 0.5rem;
      //border-bottom: 1px solid black;
      //border-right: 1px solid black;
      white-space: nowrap;
      font-weight: lighter;
      width: 200px;
      //border-right: 1px solid black;
      position: sticky;
      background-color: white;
      left: 0;
      z-index: 2;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 2px;
        background-color: black;
        box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
      



  `;
    export const StyledTr = styled.tr<{rowIndex: number}>`
      margin: 0;
      display: table-row;
      border-spacing: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #0000000d;
      //border-right: 1px solid black;
      background-color: ${props => props?.rowIndex % 2 === 0 ? 'white' : 'white'};

      :last-child {
        border-right: 0;
      }

  `;

    export const StyledTd = styled.td<{highlightCell?: boolean, backgroundColor? : string, boldText?: boolean}>`
      text-align: left;
      padding: 7px;
      //white-space: nowrap;
      background-color: ${props => props?.highlightCell ? '#F5F5F5' : props?.backgroundColor ?? 'white'};
      font-weight: ${props => props?.boldText ? "bold" : "regular"};
      &:hover{
        cursor: pointer;
      }
    `

    export const StyledTdName = styled.td<{displayBoldTitle?: boolean, backgroundColor?: string}>`
      text-align: left;
      padding-left: 5px;
      min-width: 200px;
      max-width: 200px;
      //white-space: nowrap;

      //border-right: 1px solid black;
      position: sticky;
      font-weight: ${props => props?.displayBoldTitle ? 'bold' : 'regular'};
      left: 0;
      z-index: 0;
      background-color: ${props => props?.backgroundColor ?? 'white'};
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 2px;
        background-color: black;
        box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
      
    `



}

export default styles;

import React, { useEffect, useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import { IconCalendar } from "../../utils/icons/explore";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./pickerStyle.css";
import {
  Box,
  PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { dateRangeSelector, setDateRangeState } from "store/ui";
import { localStorageService } from "@services";
import { kpiStateSelector } from "../../store/kpis";
import { PnlReportGroupBy } from "../../services/kpi/kpi.service";

export enum DEFAULTS_DATES {
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_QUARTER = "THIS_QUARTER",
  THIS_YEAR = "THIS_YEAR",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_90_DAYS = "LAST_90_DAYS",
  LAST_365_DAYS = "LAST_365_DAYS",
}


export interface Props {
    title?: string;
    onApply: (range: DateRange) => void;
    onChange?: (range: DateRange) => void;
    popperPlacement?: PlacementWithLogical;
    backgroundColor?: string;
    defaultsDate?: DEFAULTS_DATES;
    min?: Date;
    max?: Date;
    from?: Date;
    to?: Date;
    fromExplore?: boolean;
    fromPnlPage?: boolean;
}


export const DatepickerRange = (props: Props) => {
    const dispatch = useDispatch();
    const today = new Date();
    const pnlGroupByStatus = useSelector(kpiStateSelector)?.pnlPageFilters?.groupBy;

    const defaultSelected: DateRange = {
        from: addDays(today, -7),
        to: today
    };

    const clearPickerRange : DateRange = {
        from: undefined,
        to: undefined
    }

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const [pickerRange, setPickerRange] = useState<DateRange | undefined>(defaultSelected);
    const [rangeSelected, setRangeSelected] = useState<DateRange | undefined>(defaultSelected);
    const [selectedPreset, setSelectedPreset] = useState<string | undefined>(DEFAULTS_DATES.LAST_30_DAYS);
    const [openButtonTitle, setOpenButtonTitle] = useState<string>(DEFAULTS_DATES.LAST_30_DAYS);

    const datesSelector = useSelector(dateRangeSelector);

    const maxDailyDays = 365;
    const maxWeekDays = 365;
    const maxMonthDays = 365 * 2; // 2 years

    useEffect(() => {
        if (!!datesSelector?.startDate && !!datesSelector?.endDate) {
            const propsRange: DateRange = {
                from: new Date(datesSelector.startDate),
                to: new Date(datesSelector.endDate)
            };
            setRangeSelected(propsRange);
            setPickerRange(propsRange);
            let rangeInfo = propsRange?.from && dateFormat(propsRange.from);
            if (propsRange?.from && propsRange.to)
                rangeInfo += " - ";
            if (propsRange?.to)
                rangeInfo += dateFormat(propsRange.to);
            if (!!rangeInfo) {
                setOpenButtonTitle(rangeInfo);
            }
        }
    }, [datesSelector.endDate, datesSelector.startDate]);

    useEffect(() => {
        if(datesSelector?.preset !== undefined){
            setSelectedPreset(datesSelector?.preset)
        }
    }, [datesSelector?.preset])



    /* useEffect(() => {
        if (!!props?.defaultsDate) {
            setOpenButtonTitle(props.defaultsDate);
            setSelectedPreset(props.defaultsDate);
        }
    }, [props.defaultsDate]);  */


    // helpers
    const dateFormat = (date: Date) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return (date.getDate()) + " " + monthNames[date.getMonth()];
    };

    const handleClear = () => {
        setPickerRange(clearPickerRange);
        setSelectedPreset(undefined);
    }

    const getStartOfWeek = (date: Date) => {
        date = new Date(date);
        let day = date.getDay(), diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(date.setDate(diff));
    };
    const getStartOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);
    const getStartOfQuarter = (date: Date) => new Date(date.getFullYear(), Math.floor((date.getMonth() + 1) / 3) * 3, 1);
    const getStartOfYear = (date: Date) => new Date(date.getFullYear(), 0, 1);

    const presetsThisRanges = {
        THIS_WEEK: { from: getStartOfWeek(today), to: today } as DateRange,
        THIS_MONTH: { from: getStartOfMonth(today), to: today } as DateRange,
        THIS_QUARTER: { from: getStartOfQuarter(today), to: today } as DateRange,
        THIS_YEAR: { from: getStartOfYear(today), to: today } as DateRange,
    };
    const presetsLastRanges = {
        LAST_7_DAYS: { from: addDays(today, -7), to: today } as DateRange,
        LAST_30_DAYS: { from: addDays(today, -30), to: today } as DateRange,
        LAST_90_DAYS: { from: addDays(today, -90), to: today } as DateRange,
        LAST_365_DAYS: { from: addDays(today, -365), to: today } as DateRange,
    };

    const disabledDays = [
        /*{ from: !!props?.min ? new Date(props?.min) : undefined , 
        to:   !!props?.max ? new Date(props?.max) : new Date() }*/

        { after: !!props?.max ? new Date(props?.max) : new Date() },
        { before: !!props?.min ? new Date(props?.min) : new Date() },
    ];

    const selectRange = (range: DateRange | undefined) => {
        setPickerRange(range);
        /* dispatch(setDateRangeState({ startDate: range?.from, endDate: range?.to })); */
        setSelectedPreset(undefined);
    };

    const selectPresetRange = (key: string, range: DateRange) => {

        setPickerRange(range);
        /* dispatch(setDateRangeState({ startDate: range?.from, endDate: range?.to, preset: stringToEnum[key] })); */
        setSelectedPreset(key);
    };

    const applyRange = (onClose: () => void) => {
        const stringToEnum = {
            "THIS_WEEK": DEFAULTS_DATES.THIS_WEEK,
            "THIS_QUARTER": DEFAULTS_DATES.THIS_QUARTER,
            "THIS_MONTH": DEFAULTS_DATES.THIS_MONTH,
            "THIS_YEAR": DEFAULTS_DATES.THIS_YEAR,
            "LAST_7_DAYS": DEFAULTS_DATES.LAST_7_DAYS,
            "LAST_30_DAYS": DEFAULTS_DATES.LAST_30_DAYS,
            "LAST_90_DAYS": DEFAULTS_DATES.LAST_90_DAYS,
            "LAST_365_DAYS": DEFAULTS_DATES.LAST_365_DAYS
        };
        if (!pickerRange) {
            return;
        }
        if (!props.fromExplore) {
            if(!!pickerRange?.from && !!pickerRange?.to){
                const offset = pickerRange?.from.getTimezoneOffset();
                const newFromDate = new Date(pickerRange?.from.getTime() - (offset*60*1000));
                const newToDate = new Date(pickerRange?.to.getTime() - (offset*60*1000));

                localStorageService.setItem(
                    "dateRange",
                    JSON.stringify({startDate: newFromDate.toISOString()?.slice(0,10),
                        endDate: newToDate.toISOString()?.slice(0,10), preset: undefined })
                );
            dispatch(setDateRangeState({ startDate: newFromDate.toISOString()?.slice(0,10), endDate: newToDate.toISOString()?.slice(0,10), preset: undefined }));
            }
        }
        setRangeSelected(pickerRange);
        if (selectedPreset) {
            setOpenButtonTitle(selectedPreset);
            if (!props.fromExplore) {
                if(!!pickerRange?.from && !!pickerRange?.to){
                const offset = pickerRange?.from.getTimezoneOffset();
                const newFromDate = new Date(pickerRange?.from.getTime() - (offset*60*1000));
                const newToDate = new Date(pickerRange?.to.getTime() - (offset*60*1000));

                    localStorageService.setItem(
                        "dateRange",
                        JSON.stringify({ startDate: newFromDate.toISOString()?.slice(0,10),
                            endDate: newToDate.toISOString()?.slice(0,10), preset: stringToEnum[selectedPreset] })
                    );
                dispatch(setDateRangeState({ startDate: newFromDate.toISOString()?.slice(0,10), endDate: newToDate.toISOString()?.slice(0,10), preset: stringToEnum[selectedPreset] }));
            }
            }
        }
        else {
            setOpenButtonTitle(getRangeInfoFormat() + "");
        }
        props.onApply(pickerRange ? pickerRange : defaultSelected);
        onClose();

    };

    const getRangeInfoFormat = () => {
        let rangeInfo = pickerRange?.from && dateFormat(pickerRange.from);
        if (pickerRange?.from && pickerRange.to)
            rangeInfo += " - ";
        if (pickerRange?.to)
            rangeInfo += dateFormat(pickerRange.to);
        return (rangeInfo);
    };


    // renderers

    const renderOpenPickerButton = (isOpen: boolean) => {
        return (
            <styles.DatePickerButton backgroundColor={props?.backgroundColor} onClick={() => { setPickerRange(rangeSelected); }}>
                <IconCalendar />
                <div style={{ minWidth: 10 }} />
                {t(openButtonTitle, { ns: 'translation' })}
                <div style={{ minWidth: 10 }} />
                {
                    isOpen ?
                        <IoIosArrowUp />
                        :
                        <IoIosArrowDown />
                }
            </styles.DatePickerButton>
        );
    };
    
    const checkIfPresetBlocked = (presetName: string):boolean => {
        if(props?.fromPnlPage && pnlGroupByStatus !== undefined){
            const currentDate: Date = new Date(); // Get the current date

            const startOfYear: Date = new Date(currentDate.getFullYear(), 0, 1); // Create a new date object for January 1st of the current year
            const timeDiff: number = currentDate.getTime() - startOfYear.getTime(); // Calculate the time difference in milliseconds
            const daysPassed: number = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

            if(pnlGroupByStatus === PnlReportGroupBy.DAY){
                const notAllowedPresets : string[] = [ ];
                if(daysPassed > maxDailyDays){
                    notAllowedPresets?.push('THIS_YEAR')
                }

                return notAllowedPresets?.includes(presetName)
            }
            if(pnlGroupByStatus === PnlReportGroupBy.WEEK){
                const notAllowedPresets : string[] = [];
                if(daysPassed > maxWeekDays){
                    notAllowedPresets?.push('THIS_YEAR')
                }
                return notAllowedPresets?.includes(presetName)
            }
            return false
        }
        return false
    }

    const renderPreset = (key: string, range: DateRange) => {
        const isPresetBlocked = checkIfPresetBlocked(key);
        if (key === selectedPreset) {
            return (
                <styles.SelectedPresetButton key={key}>
                    {t(key, { ns: 'translation' })}
                </styles.SelectedPresetButton>
            );
        } else {
            return (
                <styles.PresetButton isDisabled={isPresetBlocked} onClick={() => {
                    if(!isPresetBlocked){
                        selectPresetRange(key, range)}
                    }
                } key={key}>
                    {t(key, { ns: 'translation' })}
                </styles.PresetButton>
            );
        }
    };

    const renderPresetsMenu = () => {
        return (
            <styles.PresetsRangeMenu>
                <styles.PresetTitle>
                    {t("PRESETS", { ns: 'translation' })}
                </styles.PresetTitle>
                {Object.keys(presetsThisRanges).map((key) => renderPreset(key, presetsThisRanges[key]))}
                <styles.PresetSeparator />
                {Object.keys(presetsLastRanges).map((key) => renderPreset(key, presetsLastRanges[key]))}
            </styles.PresetsRangeMenu>
        );
    };

    const renderPickerFooter = (onClose: () => void) => {
        return (
            <styles.DatePickerFooter>
                <styles.SelectedDates>
                    <IconCalendar />
                    <div style={{ minWidth: 10 }} />
                    {getRangeInfoFormat()}
                    <styles.ClearButton onClick={handleClear}>Reset</styles.ClearButton>
                </styles.SelectedDates>
                <styles.ButtonsWrapper>
                    <styles.CancelButton onClick={onClose}>
                        {t('CANCEL', { ns: 'translation' })}
                    </styles.CancelButton>
                    <div style={{ width: "10px" }} />
                    <styles.ApplyButton clickable={pickerRange?.to !== undefined && pickerRange?.from !== undefined} onClick={() => {
                        if(pickerRange?.to !== undefined && pickerRange?.from !== undefined){
                            applyRange(onClose);
                        }
                    }}>
                        {t('APPLY', { ns: 'translation' })}
                    </styles.ApplyButton>
                </styles.ButtonsWrapper>
            </styles.DatePickerFooter>
        );
    };



    const handleMaxDate = (date: Date | undefined, daysToAdd: number) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + daysToAdd);
            return newDate
        }
        return undefined
    }

    const handleMinDate = (date: Date | undefined, dayToRemove: number) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() - dayToRemove);
            return newDate
        }
        return undefined
    }

    const getMaxDate = () => {
        if(props?.fromPnlPage && pnlGroupByStatus !== undefined){
            const fieldDate: Date | undefined = pickerRange?.from;

            if(pnlGroupByStatus === PnlReportGroupBy.DAY){
                return handleMaxDate(fieldDate, maxDailyDays)
            }
            if(pnlGroupByStatus === PnlReportGroupBy.WEEK){
                return handleMaxDate(fieldDate, maxWeekDays)
            }
            if(pnlGroupByStatus === PnlReportGroupBy.MONTH){
                return handleMaxDate(fieldDate, maxMonthDays)
            }

        }
        return !!props?.max ? props?.max : undefined
    }

    const getMinDate = () => {

        if(props?.fromPnlPage && pnlGroupByStatus !== undefined){
            const fieldDate: Date | undefined = pickerRange?.to ?? pickerRange?.from;
            if(pnlGroupByStatus === PnlReportGroupBy.DAY){
                return handleMinDate(fieldDate, maxDailyDays)
            }
            if(pnlGroupByStatus === PnlReportGroupBy.WEEK){
                return handleMinDate(fieldDate, maxWeekDays)
            }
            if(pnlGroupByStatus === PnlReportGroupBy.MONTH){
                return handleMinDate(fieldDate, maxMonthDays)
            }
        }

        return !!props?.min ? props?.min : undefined
    }


    return (
        <styles.Wrapper>
            <Popover  placement={props.popperPlacement ? props.popperPlacement : 'bottom-start'}>
                {({ isOpen, onClose }) => (
                    <>
                        <PopoverTrigger >
                            {renderOpenPickerButton(isOpen)}
                        </PopoverTrigger>
                        <Box>
                            <PopoverContent

                                _focus={{ boxShadow: "unset" }}
                                width='inherit' borderWidth={0}
                                borderRadius={30}
                                padding={0}
                                overflow={'hidden'}
                                boxShadow={'5px 31px 54px #695F9736'}>
                                <styles.PopoverBody>

                                    {renderPresetsMenu()}
                                    <DayPicker
                                        mode="range"
                                        defaultMonth={today}
                                        selected={pickerRange}
                                        onSelect={selectRange}
                                        numberOfMonths={2}
                                        //disabled={disabledDays}
                                        fromDate={getMinDate()}
                                        toDate={getMaxDate()}
                                    />
                                </styles.PopoverBody>
                                {renderPickerFooter(onClose)}
                            </PopoverContent>
                        </Box>
                    </>
                )}
            </Popover>
        </styles.Wrapper >
    );
};



import { createAsyncThunk } from "@reduxjs/toolkit";

// local
import { LoginResponse, User, Workspace } from "@models";
import { authService, localStorageService } from "@services";
import { localStorageTokenKey } from "@constants";
import { isHttpCodeSuccess } from "../../utils/network/helpers";
import { push } from "redux-first-history";
import {
    addUser, clearOrganizationState,
    fetchCustomCogs, fetchCustomCogsData,
    fetchDataLanguage,
    fetchOrganization,
    fetchTodos,
} from "../organization";
import { workspaceService } from "services/workspace/workspace.service";
import { AxiosError, AxiosResponse } from "axios";
import { fetchIntegrations } from "store/integrations";
import { fetchNotifications } from "store/notifcations";
import {fetchProducts, fetchProductsFieldsData} from "store/products";
import { fetchUsersData } from "store/users";
import { fetchChannels } from "store/channels";
import { fetchAccounts } from "store/ui/thunks";
import {clearKpiState} from "../kpis";
import {fetchSystemEvent} from "../system-events";
import {setChannels, setDateRangeState} from "../ui";
import {operationService} from "../../services/operations/operation.service";
import {fetchFields, fetchPnlDataLanguage, fetchPnlDataLanguageNames, fetchPnlsDisplayName} from "../pnl";
import {fetchTooltips} from "../tooltips";

// import {userSlice} from "./slice";

export const signUp = createAsyncThunk<any, User>(
  "auth/signUpUser",
  async (user, thunkAPI) => {
    try {
      const response = await authService.signUp(user);
      if (user.token) {
        thunkAPI.dispatch(
          addUser({
            token: user.token,
            user: {
              email: user?.email,
              password: user?.password,
            },
          })
        );
      }
      if (
        isHttpCodeSuccess(response.status) &&
        !!response?.data?.user &&
        !!response?.data?.accessToken
      ) {
        localStorageService.setItem(
          localStorageTokenKey,
          response?.data.accessToken
        );
        const payload = {
          user: {
            ...response?.data?.user,
            id: response?.data?.user?.id,
          },
          token: response?.data.accessToken,
        };

        // userSlice.actions.setUser(payload);

        return (
          thunkAPI.fulfillWithValue(payload),
          thunkAPI.dispatch(push("/verify-account"))
        );
      } else {
        throw new Error();
      }
    } catch (err) {
      const error: any = err;
      if (!error.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signIn = createAsyncThunk<{ user?: User; token?: string }, User>(
  "auth/signInUser",
  async (user, thunkAPI) => {
    try {
      const response = await authService.signIn(user);
      if (
        isHttpCodeSuccess(response.status) &&
        !!response?.data?.user &&
        !!response?.data?.accessToken
      ) {
        localStorageService.setItem(
          localStorageTokenKey,
          response?.data.accessToken
        );
        if (
          !!response?.data?.user?.workspaces?.length &&
          response?.data?.user?.workspaces?.length > 0 &&
          !!response?.data?.user?.workspaces[0]?.organization
        ) {
          thunkAPI.dispatch(
            fetchTodos({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
            fetchNotifications({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
              fetchSystemEvent({
                organizationId: response?.data?.user?.workspaces[0]?.organization,
              })
          );
          thunkAPI.dispatch(
            fetchCustomCogs({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
            fetchCustomCogsData({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
            fetchChannels({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
            fetchAccounts({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );

          thunkAPI.dispatch(
            fetchOrganization({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
          thunkAPI.dispatch(
            fetchUsersData(response?.data?.user?.workspaces[0]?.organization)
          );

          thunkAPI.dispatch(
            fetchIntegrations({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
            })
          );
            thunkAPI.dispatch(
                fetchPnlDataLanguage({
                    organizationId: response?.data?.user?.workspaces[0]?.organization,
                })
            );
            thunkAPI.dispatch(
                fetchPnlDataLanguageNames({
                    organizationId: response?.data?.user?.workspaces[0]?.organization,
                })
            );
            thunkAPI.dispatch(
                fetchFields({
                    organizationId: response?.data?.user?.workspaces[0]?.organization,
                })
            );
            thunkAPI.dispatch(
                fetchPnlsDisplayName({
                    organizationId: response?.data?.user?.workspaces[0]?.organization,
                })
            ); thunkAPI.dispatch(
                fetchTooltips({
                    organizationId: response?.data?.user?.workspaces[0]?.organization,
                })
            );
          thunkAPI.dispatch(
            fetchProducts({
              organizationId: response?.data?.user?.workspaces[0]?.organization,
              size: 20,
              page: 0,
            })
          );

            thunkAPI.dispatch(
                fetchProductsFieldsData({
                    organizationId: response?.data?.user?.workspaces[0]?.organization})
            );
        }
        return {
          user: {
            ...response?.data?.user,
            id: response?.data?.user?.id,
          },
          token: response?.data.accessToken,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      const error: any = err;
      if (!error.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const uploadAvatarImage = createAsyncThunk<
    any,
    { userId: string; data: FormData,organizationId :string }
    >(
    "users/uploadAvatarImage",
    async (
        { userId, data, organizationId}: { userId: string; data: FormData, organizationId: string },
        thunkAPI
    ) => {
        const response = await authService.uploadAvatarImage(
            userId,
            data
        );
        thunkAPI.dispatch(fetchUsersData(organizationId))
        return response.data;
    }
);

export const authenticate = createAsyncThunk<
  { user: User; token: string },
  { token: string | null; organizationIdFromStore?: string }
>("auth/authenticate", async ({ token, organizationIdFromStore }, thunkAPI) => {
  try {
    token = token || localStorageService.getItem(localStorageTokenKey);
    const dateRange =  localStorageService.getItem("dateRange");
    const channels =  localStorageService.getItem("channels");
    if(dateRange){
        thunkAPI.dispatch(setDateRangeState(JSON.parse(dateRange)));
    }
      if(channels){
          thunkAPI.dispatch(setChannels(JSON.parse(channels)));
      }
    const response: AxiosResponse<LoginResponse> =
      await authService.authenticate(token);

    const organizationId = response?.data?.organizationId;

    if (organizationId) {
      thunkAPI.dispatch(
        fetchOrganization({
          organizationId: organizationId,
        })
      );
      thunkAPI.dispatch(fetchChannels({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchTodos({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchNotifications({ organizationId }));
      thunkAPI.dispatch(fetchSystemEvent({ organizationId }));
      thunkAPI.dispatch(fetchCustomCogs({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchCustomCogsData({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchAccounts({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchPnlDataLanguageNames({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchFields({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchPnlDataLanguage({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchPnlsDisplayName({ organizationId: organizationId }));
      thunkAPI.dispatch(fetchTooltips({ organizationId: organizationId }));
      thunkAPI.dispatch(
        fetchIntegrations({
          organizationId: organizationId,
        })
      );
    }


    thunkAPI.dispatch(fetchUsersData(organizationId));

    thunkAPI.dispatch(
      fetchProducts({
        organizationId,
        size: 20,
        page: 0,
      })
    );
      thunkAPI.dispatch(fetchProductsFieldsData({ organizationId: organizationId }));

    if (isHttpCodeSuccess(response.status) && !!response?.data && !!token) {
      localStorageService.setItem(localStorageTokenKey, token);
      return {
        user: {
          ...response?.data?.user,
          id: response?.data?.user?.id,
        },
        token: token,
      };
    } else {
        thunkAPI.dispatch(push("/auth"));
        throw new Error();
    }
  } catch (err) {
      thunkAPI.dispatch(push("/auth"));
      return thunkAPI.rejectWithValue("Error authenticate");
  }
});

export const logout = createAsyncThunk<any, any>(
  "auth/logout",
  async (user, thunkAPI) => {
    thunkAPI.dispatch(clearOrganizationState());
    localStorageService.removeItem(localStorageTokenKey);
    localStorageService.removeItem("dateRange");
    localStorageService.removeItem("channels");
    thunkAPI.dispatch(push("/auth"));
  }
);

export const forgotPassword = createAsyncThunk<boolean, string>(
    "auth/forgot-password",
    async (email, thunkAPI) => {
      try {
        const response = await authService.forgotPassword(email);
        if (isHttpCodeSuccess(response.status) && response.data) {
          return true;
        } else {
          throw new Error();
        }
      } catch (err) {
        console.log("Error", err);
        return thunkAPI.rejectWithValue("Error verify user");
      }
    }
);

export const verifyUser = createAsyncThunk<boolean, string>(
  "auth/verify",
  async (token, thunkAPI) => {
    try {
      const response = await authService.verifyUser(token);
      if (isHttpCodeSuccess(response.status) && response.data) {
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log("Error", err);
      return thunkAPI.rejectWithValue("Error verify user");
    }
  }
);

export const fetchWorkspaces = createAsyncThunk<any, { userId: string }>(
  "auth/getWorkspaces",
  async ({ userId }: { userId: string }, thunkAPI) => {
    try {
      const response = await authService.getWorkspacesByUserId({ userId });
        console.log(response)
      if (isHttpCodeSuccess(response.status) && response.data) {
        return response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error verify user");
    }
  }
);

export const pinKpi = createAsyncThunk<
  any,
  { data: Workspace; userId: string; workspaceId: string }
>("kpi/pinKpi", async ({ data, userId, workspaceId }, thunkAPI) => {
  try {
    const response = await workspaceService.pinKpi(data, userId, workspaceId);
    if (isHttpCodeSuccess(response.status) && !!response?.data) {
      return {
        ...response?.data,
      };
    } else {
      throw new Error();
    }
  } catch (err) {
    return thunkAPI.rejectWithValue("Error add integration");
  }
});

export const assignKpi = createAsyncThunk<
  any,
  { data: any; userId: string; workspaceId: string; organizationId: string }
>(
  "kpi/assignKpi",
  async ({ data, userId, workspaceId, organizationId }, thunkAPI) => {
    try {
      const response = await workspaceService.assignKpi(
        data,
        userId,
        workspaceId,
        organizationId
      );
      !!organizationId && thunkAPI.dispatch(fetchUsersData(organizationId));
      if (isHttpCodeSuccess(response.status) && !!response?.data) {
        return {
          ...response?.data,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error add integration");
    }
  }
);

export const switchOrganization = createAsyncThunk<any, any>(
  "auth/switchOrganization",
  async ({ organizationId }, thunkAPI) => {
    try {
      const response = await authService.switchOrganization(organizationId);
      localStorageService.setItem(
        localStorageTokenKey,
        response.data.accessToken
      );
      thunkAPI.dispatch(clearKpiState());

      if (isHttpCodeSuccess(response.status) && !!response?.data) {

        window.location.reload();

        return {
          ...response?.data,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error add integration");
    }
  }
);

export const updateUser = createAsyncThunk<any, any>(
  "auth/updateUser",
  async ({ userId, user, organizationId }, thunkAPI) => {
    try {
      const response = await authService.updateUser(userId, user);
      thunkAPI.dispatch(fetchUsersData(organizationId));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Error update user");
    }
  }
);

export const resetPassword = createAsyncThunk<any, any>(
  "auth/resetPassword",
  async ({ email }, thunkAPI) => {
    try {
      const response = await authService.resetPassword(email);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Error");
    }
  }
);
export const changePassword = createAsyncThunk<any, any>(
  "auth/changePassword",
  async ({ password, token }, thunkAPI) => {
    try {
      const response = await authService.changePassword({ password, token });
      if (isHttpCodeSuccess(response.status)) {
        thunkAPI.dispatch(push("/auth"));

        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Error");
    }
  }
);

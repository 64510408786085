import * as styles from "./styles";

import { AiOutlineInfoCircle } from "react-icons/ai";

interface Props {
    onClick: () => void;
}

export const MoreInfoButton = (props: Props) => {

    // hooks

    // helpers

    // renderers

    return <styles.MoreInfoButtonWrapper>
        <AiOutlineInfoCircle
            width={12}
            height={12}
            onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                props?.onClick();
                }
            }>
        </AiOutlineInfoCircle>
    </styles.MoreInfoButtonWrapper>;

};

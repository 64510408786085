import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    height: 380px;
    width: 1200px;
  `;
  export const RowButton = styled.div`
    border-radius: 30px;
    width: 220px;
    margin: 35px 0 0 12px;
    height: 41px;
    border: solid 2px #e2e2e2;
    box-shadow: 0px 13px 16px #0000000a;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 25px;

    &:hover {
      cursor: pointer;
    }
  `;

  export const RowButtonText = styled.div`
    font-size: 19px;
    font-weight: bold;
  `;

  export const RowButtonIcon = styled.div`
    font-size: 14px;
  `;
}

export default styles;

import { styles } from "./styles";
import { ReactElement } from "react";
import {Box, Flex} from "@chakra-ui/react";

export interface Props {
    title?: string;
    icon?: ReactElement;
    callback?: () => void;
    comingSoon?: boolean;
}

export const AccountHealthButton = (props: Props) => {

    const ButtonProps = () => {
        return (
            <>
                <styles.Icon>
                    <Flex w={"100%"} justifyContent={"center"} alignItems={"center"}>
                    {props?.icon}
                    </Flex>
                </styles.Icon>
                <styles.Title>
                    {props?.title}
                </styles.Title></>
        );
    };
    if (props.comingSoon) {
        return (
            <styles.AccountHealthWrapperComingSoon>
                <styles.Text>
                    Coming Soon!
                </styles.Text>
                <styles.ComingSoonWrapperText>

                </styles.ComingSoonWrapperText>
                <styles.ComingSoonWrapper >

                </styles.ComingSoonWrapper>
                {ButtonProps()}
            </styles.AccountHealthWrapperComingSoon>
        );
    }
    return (
        <styles.AccountHealthWrapper
            onClick={() => props?.callback?.call({})}>
            {ButtonProps()}
        </styles.AccountHealthWrapper>
    );
};

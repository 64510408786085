import { useDispatch, useSelector } from "react-redux";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router";
import {
  authenticate,
  authLoadingSelector,
  organizationSelector,
  tokenSelector,
  userSelector,
} from "@store";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/loading/Loading";

export const AuthGuard = () => {
  const [loading, setLoading] = useState(true);
  const authLoading = useSelector(authLoadingSelector);
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);
  const currentOrganization = useSelector(organizationSelector);
  const location = useLocation();
  // hooks
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // effects
  useEffect(() => {
    if (!(location?.state as any)?.token) {
      if (!user?.id || !token) {
        dispatch(
          authenticate({
            token: null,
            organizationIdFromStore: currentOrganization?.id,
          })
        );
      }
      setLoading(false);
    }
  }, [currentOrganization?.id, dispatch, token, user, location?.state]);

  if ((location?.state as any)?.token) {
    return <Outlet />;
  }
  if (loading || !!authLoading) {
    return <Loading />;
  } else if (!user?.id || !token) {
    return <Outlet />;
  } else {
    /*  else if (!user?.isVerified) {
         return (<Outlet />);
     } */
    return <Navigate to="/admin" replace />;
  }
};

// state

import {Metric, PnlDataLanguageItem} from "models/pnl-language";

export enum Operator {
  PLUS = '+',
  MINUS = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
  EXPONENT = '^',
  MODULO = '%'
}
export interface Expression {
  isNumber: unknown;
  isOperator: boolean;
  order: number;
  operand?: string; //name of the field or a constant value
  operator?: Operator;
}
export interface Pnl{
  id: string;
  _id: string;
  createdAt: string;
  changedAt: string;
  fieldName: string;
  displayName: string;
  isDefault: boolean;
  isDisplayable?: boolean;
  isPercentage: boolean;
  expression: Expression[];
}

export interface Field{
  name: string;
  isEmpty: boolean;
  type: string;
}

export interface PnlState {
  data?: {data?: Metric[]},
  draftData?: {data?: Metric[]},
  names?: {data: string[]},
  displayNames?: any
  fields?: Field[];
  isFinishedMoveLoad: boolean;
}

// initial state
export const initialPnlState: PnlState = {
  data: undefined,
  names: undefined,
  displayNames: undefined,
  fields: undefined,
  isFinishedMoveLoad: false,
}


import styled from 'styled-components';

export namespace styles {

    export const Wrapper = styled.div`
      min-height: max-content;
      min-width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    `;

    export const OpenMenuButton = styled.button<{isOpen: boolean}>`
      background: ${(props => props.isOpen ? props.theme.primaryColor : "white")};
      color: ${(props => props.isOpen ? "white" : "black")};
      padding: 10px 20px;
      font-size: 14px;
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #EBEBEB;
      border-radius: 19px;
      
    `
    export const MenuItem = styled.button`
      color: black;
      width: 176px;
      padding: 10px;
      border-bottom: 1px solid #EBEBEB;
      text-align: start;
    `
}

export default styles;

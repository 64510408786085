import React, {useEffect, useState} from "react";
import {Cell, Row, useExpanded, useSortBy, useTable,} from "react-table";
import {styles} from "./styles";
import {BsThreeDotsVertical} from "react-icons/bs";
import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import {CarrierEnum} from "../../../../services/operations/operation.service";
import {getCarrierNameByEnum, getCarrierSvgByEnum,} from "../../../../utils/carrier/helpers";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {editAsnOrder, operationsStateSelector, TransportationStatusEnum,} from "../../../../store/operation";
import {organizationDataLanguageSelector, organizationStateSelector} from "@store";
import {currencyOptions} from "../../../../utils/currency/currencyOptions";
import {newPoIcon} from "../../../../utils/icons/opeartion-icons";
import {StatusChip} from "../../../../components/status-chip/StatusChip";
import {formatIsoDate} from "../../../../utils/date-format/dateFormat";
import {useCurrencySign} from "../../../../utils/custom-hooks/useCurrencySign";
import {dateSort} from "../../../../utils/sorting/sorting";
import {ShowBy} from "../../../wideviews-financial/WideViewsFinancial";

interface Props {
  tableData: TransportationData[];
  rowLoader?: {
    rowId?: string;
    loading?: boolean;
  };
  setRowLoader: any;
}

const TableHeaders = [
  {
    Header: "ASN ID",
    accessor: "asnId",
    Footer: <styles.TotalValueCell>Total</styles.TotalValueCell>,
  },
  {
    Header: "Related PO (ID)",
    accessor: "relatedPoId",
  },{
    Header: "Batch Number",
    accessor: "batchNumber",
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Issue Date",
    accessor: "issueDate",
    sortType: (rowA?: any,rowB?:any, columnId?: any ) => dateSort(rowA, rowB, columnId)
  },
  { Header: "Ships From", accessor: "shipsFrom" },
  { Header: "Ships To", accessor: "shipsTo" },
  {
    Header: "Number of Units",
    accessor: "numberOfUnits",
    Footer: (units: { rows: any[] }) => {
      const total = React.useMemo(
        () =>
          units.rows.reduce((sum: number, row) => {
            if (typeof row.values.numberOfUnits === "number") {
              return row.values.numberOfUnits + sum;
            } else {
              return 0 + sum;
            }
          }, 0),
        [units.rows]
      );

      return <>{total.toLocaleString("en-US")}</>;
    },
  },
  { Header: "ETA", accessor: "eta", sortType: (rowA?: any,rowB?:any, columnId?: any ) => dateSort(rowA, rowB, columnId)},
  { Header: "Actual Inbound Date", accessor: "inboundDate",  sortType: (rowA?: any,rowB?:any, columnId?: any ) => dateSort(rowA, rowB, columnId)},
  {
    Header: "Status",
    accessor: "status",
  },
 /* { Header: "", accessor: "options" },*/
];



export interface TransportationData {
  asnId?: string;
  poId?: string;
  carrier?: CarrierEnum;
  sku?: string;
  issueDate?: string;
  from?: string;
  to?: string;
  units?: number;
  shippingCost?: number;
  status?: TransportationStatusEnum | string;
  eta?: string;
  inboundDate?: string;
  id?: string;
  approveDate?: string;
  batchNumber?: string;
  description?: string;
}

interface TableDataInterface {
  asnId: string | number;
  relatedPoId: string | number;
  shipsFrom: string | number;
  shipsTo: string | number;
  numberOfUnits: string | number;
  batchNumber: string;
  shippingCost: string | number;
  status: string | TransportationStatusEnum;
  carrier: CarrierEnum | string;
  options: any;
  description: string;
  subRows?: {}[];
}

const ShippingTable = (props: Props) => {
  const operations = useSelector(operationsStateSelector);
  const dispatch = useDispatch();
  const organization = useSelector(organizationStateSelector);
  const currentCurrency = useCurrencySign();


  const dataToDataTable = (
    data: TransportationData[]
  ): TableDataInterface[] => {
    return data.map((item) => {
      return {
        asnId: item?.asnId !== undefined ? item?.asnId : "-",
        relatedPoId: item?.poId !== undefined ? item?.poId : "-",
        shipsFrom: item?.from !== undefined ? item?.from : "-",
        shipsTo: item?.to !== undefined ? item?.to : "-",
        sku: item?.sku !== undefined ? item?.sku : "-",
        batchNumber: !!item?.batchNumber ? item?.batchNumber : "-",
        numberOfUnits: item?.units !== undefined ? item?.units : "-",
        shippingCost:
          item?.shippingCost !== undefined ? item?.shippingCost : "-",
        status: item?.status !== undefined ? item?.status : "-",
        eta: item?.eta !== undefined ? formatIsoDate(item?.eta?.slice(0, 10)) : "-",
        issueDate: !!item?.issueDate ? formatIsoDate(item?.issueDate?.slice(0, 10))  : "-",
        inboundDate: !!item?.inboundDate ? formatIsoDate(item?.inboundDate?.slice(0, 10)) : "-",
        description: !!item?.description ? item?.description: "-",
        carrier:
          /* item?.carrier !== undefined ? item?.carrier : "-"*/ "unknown",
        options: "",
        id: item?.id,
        subRows: [],
      };
    });
  };

  useEffect(() => {
    if (!operations?.loading) {
      props.setRowLoader(undefined);
    }
  }, [operations?.loading]);

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          isAllRowsExpanded: any;
          getToggleAllRowsExpandedProps: any;
        }) => (
          <span /*{...getToggleAllRowsExpandedProps()}*/>
            {/*{isAllRowsExpanded ? "👇" : "👉"}*/}
          </span>
        ),
        Cell: ({ row }: { row: Row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? "👇" : "👉"}
            </span>
          ) : null,
      },
      ...TableHeaders,
    ],
    []
  );

  const data = React.useMemo(
    () => dataToDataTable(props.tableData),
    [props.tableData]
  );

  const handleFocusOut = (elementId: string, value: string) => {
    if (!isNaN(parseFloat(value))) {
      props?.setRowLoader({ rowId: elementId, loading: true });
      const dataToSubmit = {
        id: elementId,
        shippingCost: parseFloat(value),
      };
      !!organization?.organization?.id &&
        dispatch(
          editAsnOrder({
            organizationId: organization?.organization?.id,
            data: dataToSubmit,
          })
        );
    } else {
      return;
    }
  };

  const MenuListRender = () => {
    return (
      <MenuList>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Delete</MenuItem>
      </MenuList>
    );
  };

  const renderStatusChip = (status: TransportationStatusEnum) => {
    const width = 85;
    switch (status) {

      case TransportationStatusEnum.APPROVED:
        return StatusChip({width: `${width}px`, color: '#61C322', text: 'Approved'})
      case TransportationStatusEnum.PENDING_ARRIVAL:
        return StatusChip({width: `${width}px`, color: '#F0BB09', text: 'Pending Arrival'})
      case TransportationStatusEnum.DRAFT:
        return StatusChip({width: `${width}px`, color: '#F0BB09', text: 'Draft'})
      case TransportationStatusEnum.IN_REVIEW:
        return StatusChip({width: `${width}px`, color: '#F0BB09', text: 'In Review'})
      case TransportationStatusEnum.ON_HOLD:
        return StatusChip({width: `${width}px`, color: '#F0BB09', text: 'On Hold'})
      case TransportationStatusEnum.CANCELED:
        return StatusChip({width: `${width}px`, color: '#ef6c74', text: 'Cancelled'})
      default:
        return <></>
    }
  }


  function Table({ columns: userColumns, data }: { columns: any; data: any }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      state: { expanded },
    } = useTable(
      {
        columns: userColumns,
        data,
        initialState: {
          sortBy: [
            {
              id: 'issueDate',
              desc: true
            }
          ]
        },
      },
      useSortBy,
      useExpanded // Use the useExpanded plugin hook
    );

    return (
        <>
        <styles.TableWrapper>
          <styles.StyledTable {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup: any) => (
                <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    if (column.id === "expander") {
                      return <></>;
                    }
                    return (
                        <styles.HeaderTh
                            {...column.getHeaderProps(
                                column.getSortByToggleProps({ title: undefined })
                            )}  w={/*column?.Header === "Description" ||*/ column?.Header === "SKU"}>
                          <Flex
                              textAlign={"center"}
                              justifyContent={"center"}
                              alignItems={"center"}
                          >
                            {column.render("Header")}
                            <Box w={1} />
                            <span>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )
                        ) : (
                            ""
                        )}
                      </span>
                          </Flex>
                        </styles.HeaderTh>
                    );
                  })}
                </styles.HeaderTr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                  <styles.TableTr {...row.getRowProps()}>
                    {row.cells.map((cell: Cell) => {
                      if (cell.column.id === "expander") {
                        return <></>;
                      }
                      if (cell.column.id === "options") {
                        return (
                            <td {...cell.getCellProps()}>
                              <Menu placement="start-start">
                                {({ isOpen }) => (
                                    <>
                                      <MenuButton padding={0}>
                                        <styles.ThreeDotHolder>
                                          <BsThreeDotsVertical />
                                        </styles.ThreeDotHolder>
                                      </MenuButton>
                                      <MenuListRender />
                                    </>
                                )}
                              </Menu>
                            </td>
                        );
                      }
                      if (cell.column.id === "carrier") {
                        return (
                            <styles.FlexCell {...cell.getCellProps()}>
                              <styles.FlexDiv>
                                {getCarrierSvgByEnum(cell?.value)}
                                {getCarrierNameByEnum(cell?.value)}
                              </styles.FlexDiv>
                            </styles.FlexCell>
                        );
                      }

                      if (cell.column.id === "shippingCost") {
                        return (
                            <styles.InputTd {...cell.getCellProps()}>
                              {props?.rowLoader?.rowId === row?.original?.id &&
                              operations?.asnEditLoader ? (

                                  <styles.CogsTdDiv >
                                    <InputGroup width={"80%"} display="flex" justifyContent={"center"}>
                                      <InputLeftElement pointerEvents='none' color='gray.400' children={currentCurrency} />
                                      {/* <InputLeftAddon children={cogs.isPercentage ? "%" : currentCurrency} /> */}
                                      <styles.Loader />
                                      <styles.TableInput
                                          type={"number"}
                                          /* onBlur={(e:any) => {
                                             handleFocusOut(
                                                 row?.original?.id,
                                                 e?.target?.value
                                             );
                                           }}*/ />
                                    </InputGroup>
                                  </styles.CogsTdDiv>

                              ) : (
                                  <>

                                    <styles.CogsTdDiv >
                                      <Tooltip label={cell?.value}>
                                        <InputGroup width={"80%"} display="flex" justifyContent={"center"}>
                                          <InputLeftElement pointerEvents='none' color='gray.400' children={currentCurrency} />
                                          {/* <InputLeftAddon children={cogs.isPercentage ? "%" : currentCurrency} /> */}
                                          <styles.TableInput
                                              type={"number"}
                                              defaultValue={cell?.value !== undefined ? cell?.value : "-"}
                                              onBlur={(e:any) => {
                                                if(cell?.value !== parseFloat(e.target.value)){
                                                  handleFocusOut(
                                                      row?.original?.id,
                                                      e?.target?.value
                                                  );
                                                }
                                              }} />
                                        </InputGroup>


                                      </Tooltip>
                                    </styles.CogsTdDiv>
                                  </>
                              )}
                            </styles.InputTd>
                        );
                      }
                      if (cell.column.id === "shipsTo") {
                        return (
                            <styles.TableTd {...cell.getCellProps()}>
                              <Tooltip label={cell?.value}>{cell?.value}</Tooltip>
                            </styles.TableTd>
                        );
                      }
                      if (cell.column.id === "relatedPoId") {
                        return (
                            <styles.TableTd {...cell.getCellProps()}>
                              {!!cell?.value ? <Tooltip label={cell?.value}>{cell?.value}</Tooltip> : "-"}
                            </styles.TableTd>
                        );
                      } if (cell.column.id === "sku" || cell.column.id === "description") {
                        return (
                            <styles.DescriptionCell {...cell.getCellProps()}>
                              {!!cell?.value ? <Tooltip label={cell?.value}>{cell?.value}</Tooltip> : "-"}
                            </styles.DescriptionCell>
                        );
                      }
                      if(cell?.column?.id ==="status"){
                        return (
                            <styles.TableTd statusCell={true} {...cell.getCellProps()}>
                              <Flex w={"100%"} justifyContent={"center"}>
                                {renderStatusChip(cell.value)}
                              </Flex>

                            </styles.TableTd>
                        )
                      }
                      if (cell.column.id === "numberOfUnits") {
                        return (
                            <td {...cell.getCellProps()}>
                              {cell?.value.toLocaleString("en-US")}
                            </td>
                        );
                      }  if (cell.column.id === "status") {
                        return (
                            <td {...cell.getCellProps()}>
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            </td>
                        );
                      }
                      return (
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </styles.TableTr>
              );
            })}
            </tbody>
          </styles.StyledTable>
        </styles.TableWrapper>

          <styles.TableFooterWrapper>

            {footerGroups.map((group: any, index: number) => {
              return (
                  <styles.Footer key={index} {...group.getFooterGroupProps()}>
                    <div style={{width: '20px!important'}}/>
                    {group.headers.map((column: any) => {
                      if (column.id === "expander") {
                        return <></>;
                      } if (column.id === "options") {
                        return  <Box width={"45%"} h={"100%"}>

                        </Box>
                      }
                      return (
                          <styles.StyledTotalFooter {...column.getFooterProps()}>
                            {column.render("Footer")}
                          </styles.StyledTotalFooter>
                      );
                    })}
                    <div style={{width: '20px!important'}}/>

                  </styles.Footer>

              );
            })}
          </styles.TableFooterWrapper>

        </>

    );
  }

  return (
    <styles.PaddingAndTableWrapper>
      <styles.TablePadding />
      <styles.Wrapper>
        <Table columns={columns} data={data} />
      </styles.Wrapper>
    </styles.PaddingAndTableWrapper>
  );
};

export default ShippingTable;

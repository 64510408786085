// local
import {Integration, Organization} from "@models";
import { PercentageFrom, TransactionFeeItem } from "models/pnl-language";

export enum CurrencyCode {
  AED,
  AFN,
  ALL,
  AMD,
  ANG,
  AOA,
  ARS,
  AUD, //
  AWG,
  AZN,
  BAM,
  BBD,
  BDT,
  BGN,
  BHD,
  BIF,
  BMD,
  BND,
  BOB,
  BRL, //
  BSD,
  BTC,
  BTN,
  BWP,
  BYN,
  BZD,
  CAD, //
  CDF,
  CHF, //
  CLF,
  CLP,
  CNH,
  CNY, //
  COP,
  CRC,
  CUC,
  CUP,
  CVE,
  CZK, //
  DJF,
  DKK, //
  DOP,
  DZD,
  EGP,
  ERN,
  ETB,
  EUR, //
  FJD,
  FKP,
  GBP, //
  GEL, //
  GGP,
  GHS,
  GIP,
  GMD,
  GNF,
  GTQ,
  GYD,
  HKD, //
  HNL,
  HRK, //
  HTG,
  HUF, //
  IDR, //
  ILS, //
  IMP, //
  INR, //
  IQD,
  IRR,
  ISK, //
  JEP,
  JMD,
  JOD,
  JPY, //
  KES,
  KGS,
  KHR,
  KMF,
  KPW,
  KRW, //
  KWD,
  KYD,
  KZT,
  LAK,
  LBP,
  LKR,
  LRD,
  LSL,
  LYD,
  MAD,
  MDL,
  MGA,
  MKD,
  MMK,
  MNT,
  MOP,
  MRU,
  MUR,
  MVR,
  MWK,
  MXN, //
  MYR, //
  MZN,
  NAD,
  NGN,
  NIO,
  NOK, //
  NPR, //
  NZD, //
  OMR,
  PAB,
  PEN,
  PGK,
  PHP, //
  PKR,
  PLN, //
  PYG,
  QAR,
  RON, //
  RSD,
  RUB, //
  RWF,
  SAR,
  SBD,
  SCR,
  SDG,
  SEK, //
  SGD, //
  SHP,
  SLL,
  SOS,
  SRD,
  SSP, //
  STD,
  STN,
  SVC,
  SYP,
  SZL,
  THB, //
  TJS,
  TMT,
  TND,
  TOP, //
  TRY, //
  TTD,
  TWD,
  TZS,
  UAH,
  UGX,
  USD, //
  UYU,
  UZS,
  VEF,
  VES,
  VND,
  VUV,
  WST,
  XAF,
  XAG,
  XAU,
  XCD,
  XDR,
  XOF,
  XPD,
  XPF,
  XPT,
  YER,
  ZAR, //
  ZMW,
  ZWL,
}

export const currency = [
  { title: "USD", sign: "$", name: "US Dollar" },
  { title: "CAD", sign: "CA$", name: "" },
  { title: "EUR", sign: "€", name: "" },
  { title: "AUD", sign: "AU$", name: "" },
  { title: "BGN", sign: "BGN", name: "" },
  { title: "BRL", sign: "R$", name: "" },
  { title: "CHF", sign: "CHF", name: "" },
  { title: "CNY", sign: "CN¥", name: "" },
  { title: "CZK", sign: "Kč", name: "" },
  { title: "DKK", sign: "Dkr", name: "" },
  { title: "GBP", sign: "£", name: "" },
  { title: "HKD", sign: "HK$", name: "" },
  { title: "HRK", sign: "kn" },
  { title: "HUF", sign: "Ft" },
  { title: "IDR", sign: "Rp" },
  { title: "ILS", sign: "₪", name: "Israeli new Shekel" },
  { title: "INR", sign: "Rs" },
  { title: "ISK", sign: "Ikr" },
  { title: "JPY", sign: "¥" },
  { title: "KRW", sign: "₩" },
  { title: "MXN", sign: "MX$" },
  { title: "MYR", sign: "RM" },
  { title: "NOK", sign: "Nkr" },
  { title: "NZD", sign: "NZ$" },
  { title: "PHP", sign: "₱" },
  { title: "PLN", sign: "zł" },
  { title: "RON", sign: "RON" },
  { title: "RUB", sign: "RUB" },
  { title: "SEK", sign: "Skr" },
  { title: "SGD", sign: "S$" },
  { title: "THB", sign: "฿" },
  { title: "TRY", sign: "TL" },
  { title: "ZAR", sign: "R" },
];

export interface GeneralSettings {
  timezone: Timezone;
  chosenTimezone: Timezone;
  currency: CurrencyCode;
  chosenCurrency?: CurrencyCode;
  isDefault: boolean;
  updatedAt: string;
  isLoading?: {
    isLoad?: boolean;
    isSuccess?: boolean;
  };
}

export interface Timezone {
  value: string;
  abbreviation: string;
  offset: number;
  isDst: boolean;
  text: string;
  utc: string[];
  code: number;
}

export interface PnlElement {
  name?: string;
  amount?: number;
  isPercentage?: boolean;
  frequency?: number;
  isDefault?: boolean;
  percentageFrom: PercentageFrom;
  id?: string;
  channel?: string;
  updatedAt: string;
}

export interface PnlSettings {
  elements: PnlElement[];
  grossSales: PnlElement[];
  netSales: PnlElement[];
  grossProfit: PnlElement[];
  contributionProfit: PnlElement[];
  transactionFees: TransactionFeeItem[];
  isDefault: boolean;
  updatedAt?: string;
  isPostLoading?: {
    isPostRequestLoading?: boolean;
    isPostRequestSuccess?: boolean;
  };
  isPutLoading?: {
    isPutRequestLoading?: boolean;
    isPutRequestSuccess?: boolean;
  };
}

export interface Product {
  productTitle?: string;
  channel?: string;
  sku: string;
  id?: string;
}

export interface Account {
  name: string;
  channelAccountId: string;
}

export interface Campaign {
  title: string;
  channelCampaignId: string;
}

export interface AdSet {
  title: string;
  channelAdSetId: string;
}

export enum MarketingMappingSource {
  MANUAL = 0,
  AUTO = 1,
}

export interface Ad {
  title: string;
  channelAdId: string;
  url: string;
}

export interface CustomersSettings {
  active?: string;
  lapse?: string;
  churned?: string;
  returning?: string;
  isDefault: boolean;
}

export interface MarketingMappingElement {
  accountId?: string;
  campaignId?: string;
  adSetId?: string;
  adId?: string;
  integrationId?: string;
  source?: MarketingMappingSource;
  id: string;
  salesChannels?: string[];
}

export interface MarketingMapping {
  elements: MarketingMappingElement[];
  isDefault?: boolean;
  updatedAt?: string;
  id?: string;
}

export interface TopKpis {
  website: number[];
  marketing: number[];
  finance: number[];
  company: number[];
  isDefault?: boolean;
  updatedAt?: string;
}

export interface Holidays {
  name?: string;
  id?: string;
  date?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface PendingInvitations {
  isExpired: boolean;
  email: string;
  createdAt: string;
  id: string;
  loading?: boolean;
}

interface todos {
  code?: number;
  isDone?: boolean;
}

export interface DataLanguage {
  id?: string;
  generalSettings?: GeneralSettings;
  pnlSettings?: PnlSettings;
  customersSettings?: CustomersSettings;
  marketingMapping?: MarketingMapping;
  topKpis?: TopKpis;
  todos?: todos[];
  displayUpdate?: boolean;
}

// state
export interface OrganizationState {
  organization?: Organization;
  dataLanguage?: DataLanguage;
  loading: boolean;
  error?: string;
  integrationNameChangeLoader: {
    rowId?: string;
    loading: boolean;
  };
  pendingLoader: boolean;
}

// initial state
export const initialOrganizationState: OrganizationState = {
  organization: undefined,
  dataLanguage: undefined,
  loading: false,
  pendingLoader: false,
  integrationNameChangeLoader: {
    rowId: undefined,
    loading: false,
  },
  error: undefined,
};

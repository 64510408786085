import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    /* height: 443px; */
    height: 427px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
  `;

  export const Card = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
  `;
  export const TabButtonsContainer = styled.div`
    width: 98%;
    height: 56px;
    display: flex;
  `;

  export const FooterTd = styled.td`
    background-color: white;
    z-index: 8;
    font-weight: bold;
    
  `;

  export const footerTr = styled.tr`
    position: sticky;
    bottom: 0;
    z-index: 8;
    /*border-top: 1px solid #a9a4a4;*/

  `;

  export const StyledTotal = styled.div`
    background-color: #7031eb;
    color: white;

    border-radius: 10px;
    background-size: 100% 100%;
    /*transform: scale(0.9, 0.9);*/
    width: 40%;
    height: 125%;
    padding: 10px 0;
    transform-origin: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
  `;

  export const StyledFilterButton = styled.button`
    width: 45px;
    height: 45px;
    border-radius: 19px;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const TabButton = styled.div<{ selected?: boolean }>`
    width: 20%;
    width: 20%;
    border: 0.5px solid #695f9726;
    border-bottom: ${props => props?.selected ? " 4px solid #7031eb" : " 4px solid #bea8ec" };
    border-radius: 5px 30px 0px 0px;
    display: flex;
    align-items: center;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    
    &:hover {
      cursor: pointer;
    }
  `;

  export const StyledDiv = styled.div``;
  export const StyledDivSelected = styled.div<{ selected?: boolean }>`
    color: ${(props) => (props.selected ? "#7031eb" : "black")};
    min-width: 25px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.selected ? "#dac9fa" : "#CBCBCB")};
    font-size: 12px;
  `;

  export const StyledTable = styled.table`
    width: 100%;
    height: 100%;
    /* max-height: 300px; */
    overflow-y: scroll;
    table-layout: fixed;
    position: relative;
  `;
  export const TbodyCustom = styled.tbody`
    /* max-height: 238px; */

    /*  ::-webkit-scrollbar {
      display: none;
    } */
  `;
  export const ClearFiltersButtonWrapper = styled.div`
    width: 45px;
    height: 45px;
    border: 1px solid #ebebeb;
    border-radius: 19px;
    background: white;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;
    align-items: center;
    margin: 0 10px 0 10px;

    &:hover {
      cursor: pointer;
    }
  `;
  export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  export const Title = styled.div`
    width: 100%;
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${props => props?.theme?.fonts?.header};
    font-size: 21px;
    font-weight: bold;
  `;

  export const StyledTh = styled.th`
    text-align: left;
    color: #7a86a1;
    padding: 15px 0 10px;
    font-size: 14px;
    
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const HeaderTr = styled.tr`
    border-bottom: 1px solid #7a86a1;
  `;

  export const TrCustom = styled.tr<{ selected?: boolean }>`
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    &:hover {
      cursor: pointer;
    }
  `;

  export const TargetWrapper = styled.div<{ small?: boolean }>`
    width: ${(props) => (props?.small ? "30%" : "65%")};
    height: 7px;
    border-radius: 8px;
    background-color: #f6efff;
  `;
  export const TargetPercantageWrapper = styled.div<{
    width: number;
    color: string;
  }>`
    width: ${(props) => props.width + "%"};
    height: 7px;
    border-radius: 8px;
    background-color: ${(props) => props.color};
  `;

  export const StyledTd = styled.td`
    padding: 16px 0;
  `;
  export const StyledTdName = styled.td`
    padding: 20px 15px 20px 0;
    width: 100px;
  `;
  export const StyledShareTd = styled.td`
    color: #939cb2;
  `;
  export const TabButtonText = styled.div`
    margin: 0 0 0 23px;
    display: flex;
    width: 80%;
    justify-content: space-between;
  `;
}

export default styles;

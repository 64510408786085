// state

export interface AmazonSellerState {
    code?: string;
    loading: boolean;
    error?: string;
}

// initial state
export const initialAmazonSellerState: AmazonSellerState = {
    code: undefined,
    loading: false,
    error: undefined,
}

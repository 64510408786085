import styles from "./styles";
import React, {useEffect} from "react";
import {IToast, ToastId, useToast} from '@chakra-ui/react'
import {useDispatch, useSelector} from "react-redux";
import {removeToasts, toastsSelector} from "../../store/ui";
import {AlertStatus} from "@chakra-ui/alert";
import {FiX} from "react-icons/fi";
import {ToastErrorIcon, ToastInfoIcon, ToastSuccessIcon, ToastWarningIcon} from "../../utils/icons/toast";


export enum ToastStatus{
    SUCCESS = 0,
    INFO = 1,
    WARNING = 2,
    ERROR = 3
}
export interface Toast {
    id?: ToastId,
    title: string,
    description?: string,
    isClosable?: boolean,
    status: ToastStatus,
    duration?: number //in milliseconds

}

export const Toasts = () => {

    const toasts = useSelector(toastsSelector);

    const dispatch = useDispatch();

    const toast = useToast();
    const toastIdRef = React.useRef()

    //helpers

    const getColorByType = (toastStatus: ToastStatus) => {
        switch (toastStatus) {
            case ToastStatus.SUCCESS:
                return '#1AD598';
            case ToastStatus.INFO:
                return '#7031EB';
            case ToastStatus.WARNING:
                return '#F0BB0C';
            case ToastStatus.ERROR:
                return '#EA3A3D';
            default:
                return '#7031EB';
        }
    }
    const getIconByType = (toastStatus: ToastStatus) => {
        switch (toastStatus) {
            case ToastStatus.SUCCESS:
                return <ToastSuccessIcon/>;
            case ToastStatus.INFO:
                return <ToastInfoIcon/>;
            case ToastStatus.WARNING:
                return <ToastWarningIcon/>;
            case ToastStatus.ERROR:
                return <ToastErrorIcon/>;
            default:
                return <ToastInfoIcon/>;
        }
    }

    const closeToast = (toastItem: Toast) => {
        console.log(toastIdRef)
        if (!!toastItem?.id) {
            toast.close(toastItem.id!)
        }

    }

    const renderWideviewToast = (toast: Toast) => {
        const color = getColorByType(toast.status);
        const icon = getIconByType(toast.status);
        const duration = (toast?.duration ?? 3000) / 1000;
        return <styles.WideviewToastWrapper>
            <styles.WideviewToast>
                <styles.ToastIconAndText>
                    <styles.ToastIcon>
                        {icon}
                    </styles.ToastIcon>
                    <styles.ToastText>
                        {toast?.title}
                    </styles.ToastText>
                </styles.ToastIconAndText>
                <styles.CloseButton onClick={() => closeToast(toast)}>
                    <FiX size={22}></FiX>
                </styles.CloseButton>
            </styles.WideviewToast>
            <styles.ProgressBarWrapper>
                <styles.ProgressBar color={color} duration={duration}>
                </styles.ProgressBar>
            </styles.ProgressBarWrapper>
        </styles.WideviewToastWrapper>
    }

    useEffect(() => {
            console.log(toasts?.length)
            if (toasts?.length > 0) {
                console.log(toasts)
                toasts?.map((item) => {
                    const toastId = toast({
                        position: "bottom",
                        duration: item?.duration ?? 3000,
                        render: () => renderWideviewToast(item),
                        title: item?.title,
                        description: item?.description ?? null,
                        isClosable: item?.isClosable ?? true,
                        id: item?.id,
                    })

                    return toastId;

                })
                dispatch(removeToasts({toasts: toasts}))
            }
        },
        [toasts])
    return <></>;
};

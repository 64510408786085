// state

import {PnlBreakdown} from "../../models/pnl-breakdown";
import {PnlFinancialTable} from "../../models/pnl-financial-table";

export interface PnLWaterfallData {
  TGTs: number[],
  losses: number[]
}

export interface FinancialData {
  overview: PnLWaterfallData,
  financialTable: PnlFinancialTable[],
  breakdownTable: PnlBreakdown[]
}


export interface FinancialState {
  data?: FinancialData
}

// initial state
export const initalFinancialState: FinancialState = {
  data: undefined
}


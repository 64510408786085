import marketingIntegrationsStyles from "./styles";
import {useTranslation} from "react-i18next";
import {Flex, Text} from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform,
    IntegrationType,
} from "@models";
import {addIntegration, organizationSelector} from "@store";
import {useDispatch, useSelector} from "react-redux";
import {IntegrationButton} from "../../../components/integration-button";
import {integrationSelector} from "store/integrations";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import {createZohoUrl, ebayUrl, zohokCrmRoundLogo} from "../../../constants/integration";
import storeIntegrationsStyles from "../stores-integrations/styles";

export const CRMIntegrations = () => {
    // hooks
    const dispatch = useDispatch();

    const { t } = useTranslation(['translation', 'translation']);
    const integrations = useSelector(integrationSelector);
    const organization = useSelector(organizationSelector);

    // helpers
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    const sendIntegrationOAuthFromSearchParams = (params: URLSearchParams, platform: IntegrationPlatform) => {
        const ebayOAuthData = params.get('result');
        try {
            if (!!ebayOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.CRM,
                        platform: platform,
                        object: JSON.parse(ebayOAuthData),
                    },
                    organizationId: organization?.id
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    // renderers
    const renderCRMIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('CRM_OR_EMAIL_MARKETING', { ns: 'translation' })}
                </Text>
                <Flex direction={"row"}>
                    {renderKlaviyoConnector()}
                    {renderHubspotConnector()}
                    {renderZohoCrmIntegrations()}
                </Flex>
            </Flex>
        );
    };

    const renderKlaviyoConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.KLAVIYO);
        return (
            <marketingIntegrationsStyles.ButtonWrapper>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    premium={true}
                    badgeText={badgeText}>
                </IntegrationButton>
            </marketingIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderHubspotConnector = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.HUBSPOT);
        return (
            <marketingIntegrationsStyles.ButtonWrapper>
                <IntegrationButton
                    title={t(title, { ns: 'translation' })}
                    /* description={t(incText, { ns: 'translation' })} */
                    image={image}
                    premium={true}
                    badgeText={badgeText}>
                </IntegrationButton>
            </marketingIntegrationsStyles.ButtonWrapper>
        );
    };

    const renderZohoCrmIntegrations = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.ZOHO_CRM);
        return (
            /*<OAuthPopup
                title={'Zoho crm'}
                height={800}
                width={1000}
                url={createZohoUrl(IntegrationPlatform.ZOHO_CRM) ?? ''}
                onResponse={(params) => {
                    sendIntegrationOAuthFromSearchParams(params!, IntegrationPlatform.ZOHO_CRM);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <storeIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        premium={true}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </storeIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };

    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderCRMIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

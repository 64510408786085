import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertsService } from "services/alerts/alerts.service";
import { updateLocalAlerts } from "./actions";

export const fetchAlerts = createAsyncThunk<any, {organizationId :string}>(
  "alerts/fetchAlertsData",
  async ({organizationId},thunkAPI) => {
    const response = await alertsService.fetchAlerts({organizationId});
    return response.data;
  }
);
export const createAlerts = createAsyncThunk<any, {data: any, organizationId: string}>(
  "alerts/createAlertsData",
  async ({data, organizationId}, thunkAPI) => {
    const response = await alertsService.createAlert({organizationId, data});
    thunkAPI.dispatch(fetchAlerts({organizationId}));
    return response.data;
  }
);
export const deleteAlerts = createAsyncThunk<any, {organizationId: string, id: string}>(
  "alerts/deleteAlertsData",
  async ({organizationId, id}, thunkAPI) => {
    const response = await alertsService.deleteAlert({organizationId, id});
    thunkAPI.dispatch(fetchAlerts({organizationId}));

    return response.data;
  }
);
export const editAlerts = createAsyncThunk<any, {data: any, organizationId: string}>(
  "alerts/editAlertsData",
  async ({data, organizationId}, thunkAPI) => {
    const response = await alertsService.editAlert({data, organizationId});
    /*thunkAPI.dispatch(fetchAlerts({organizationId}));*/

    return response.data;
  }
);

import { KpisEnum } from "models/kpi-settings";

export const getKpiNameFromEnum = (kpiCode: KpisEnum) => {
  switch (kpiCode) {
    case KpisEnum.GROSS_REVENUE:
      return "Gross Revenue";
    case KpisEnum.GROSS_MARGIN:
      return "Gross Margin";
    case KpisEnum.CONVERSION_RATE:
      return "Conversion Rate (CR)";
    case KpisEnum.ORDERS:
      return "Orders";
    case KpisEnum.MARKETING_SPEND:
      return "Marketing Spend";
    case KpisEnum.RETURN_ON_AD_SPEND:
      return "Return on Ad Spend";
    case KpisEnum.MARKETING_EFFICIENCY_RATIO:
      return "Marketing Efficiency Ratio";
    case KpisEnum.ADVERTISING_COST_OF_SALES:
      return "Advertising Cost of Sales";
    case KpisEnum.COST_PER_CLICK:
      return "Cost per Click";
    case KpisEnum.COST_PER_ORDER:
      return "Cost per Order";
    case KpisEnum.COST_PER_MILE:
      return "Cost per Mile";
    case KpisEnum.CUSTOMER_ACQUISITION_COST:
      return "Customer Acquisition Cost";
    case KpisEnum.AIO:
      return "AIO";
    case KpisEnum.USERS:
      return "Users";
    case KpisEnum.NEW_USERS:
      return "New Users";
    case KpisEnum.CLICKS:
      return "Clicks";
    case KpisEnum.NET_REVENUE:
      return "Net Revenue";
    case KpisEnum.GROSS_PROFIT:
      return "Gross Profit";
    case KpisEnum.CONTRIBUTION_PROFIT:
      return "Contribution Profit";
    case KpisEnum.AVERAGE_ORDERS_VALUE:
      return "Average Orders Value";
    case KpisEnum.UNITS_SOLD:
      return "Units Sold";
    case KpisEnum.CUSTOMERS:
      return "Customers";
    case KpisEnum.NEW_CUSTOMER_ORDER:
      return "New Customer Order";
    case KpisEnum.RETURNING_CUSTOMER_ORDERS:
      return "Returning Customer Orders";
    case KpisEnum.CUSTOMER_LIFETIME_VALUE:
      return "Customer Lifetime Value";
    case KpisEnum.COST_OF_GOODS_SOLD:
      return "Cost of Good Sold";
    case KpisEnum.SHOPPING_CART_ABANDONMENT_RATE:
      return "Shopping Cart Abandonment Rate";
    case KpisEnum.RETURNS:
      return "Returns";
    case KpisEnum.REPLACEMENTS:
      return "Replacements";
    case KpisEnum.SESSIONS:
      return "Sessions";
    case KpisEnum.COST_PER_SESSION:
      return "Cost per Sessions";
    case KpisEnum.INVENTORY_TURNOVER:
      return "Inventory Turnover";
    case KpisEnum.AVERAGE_INVENTORY:
      return "Average Inventory";
    case KpisEnum.DAYS_SALES_OF_INVENTORY:
      return "Days Sales of Inventory";
    case KpisEnum.DAYS_SALE_OUTSTANDING:
      return "DSO";
    case KpisEnum.DAYS_PAYABLE_OUTSTANDING:
      return "DPO";
    case KpisEnum.CASH_CONVERSION_CYCLE:
      return "COC";
    case KpisEnum.BLENDED_ROAS:
      return "Blended ROAS";
    case KpisEnum.PENDING_DEVICES:
      return "Pending Devices";
    default:
      return "Not Found";
  }
};
export const getKpiNameFromNumber = (kpiCode: number) => {
  switch (kpiCode) {
    case KpisEnum.GROSS_REVENUE:
      return "Gross Revenue";
    case KpisEnum.GROSS_MARGIN:
      return "Gross Margin";
    case KpisEnum.CONVERSION_RATE:
      return "Conversion Rate (CR)";
    case KpisEnum.ORDERS:
      return "Orders";
    case KpisEnum.MARKETING_SPEND:
      return "Marketing Spend";
    case KpisEnum.RETURN_ON_AD_SPEND:
      return "Return on Ad Spend";
    case KpisEnum.MARKETING_EFFICIENCY_RATIO:
      return "Marketing Efficiency Ratio";
    case KpisEnum.ADVERTISING_COST_OF_SALES:
      return "Advertising Cost of Sales";
    case KpisEnum.COST_PER_CLICK:
      return "Cost per Click";
    case KpisEnum.COST_PER_ORDER:
      return "Cost per Order";
    case KpisEnum.COST_PER_MILE:
      return "Cost per Mile";
    case KpisEnum.CUSTOMER_ACQUISITION_COST:
      return "Customer Acquisition Cost";
    case KpisEnum.AIO:
      return "AIO";
    case KpisEnum.USERS:
      return "Users";
    case KpisEnum.NEW_USERS:
      return "New Users";
    case KpisEnum.CLICKS:
      return "Clicks";
    case KpisEnum.NET_REVENUE:
      return "Net Revenue";
    case KpisEnum.GROSS_PROFIT:
      return "Gross Profit";
    case KpisEnum.CONTRIBUTION_PROFIT:
      return "Contribution Profit";
    case KpisEnum.AVERAGE_ORDERS_VALUE:
      return "Average Orders Value";
    case KpisEnum.UNITS_SOLD:
      return "Units Sold";
    case KpisEnum.CUSTOMERS:
      return "Customers";
    case KpisEnum.NEW_CUSTOMER_ORDER:
      return "New Customer Order";
    case KpisEnum.RETURNING_CUSTOMER_ORDERS:
      return "Returning Customer Orders";
    case KpisEnum.CUSTOMER_LIFETIME_VALUE:
      return "Customer Lifetime Value";
    case KpisEnum.COST_OF_GOODS_SOLD:
      return "Cost of Good Sold";
    case KpisEnum.SHOPPING_CART_ABANDONMENT_RATE:
      return "Shopping Cart Abandonment Rate";
    case KpisEnum.RETURNS:
      return "Returns";
    case KpisEnum.REPLACEMENTS:
      return "Replacements";
    case KpisEnum.SESSIONS:
      return "Sessions";
    case KpisEnum.COST_PER_SESSION:
      return "Cost per Sessions";
    case KpisEnum.INVENTORY_TURNOVER:
      return "Inventory Turnover";
    case KpisEnum.AVERAGE_INVENTORY:
      return "Average Inventory";
    case KpisEnum.DAYS_SALES_OF_INVENTORY:
      return "Days Sales of Inventory";
    case KpisEnum.DAYS_SALE_OUTSTANDING:
      return "DSO";
    case KpisEnum.DAYS_PAYABLE_OUTSTANDING:
      return "DPO";
    case KpisEnum.CASH_CONVERSION_CYCLE:
      return "COC";
    case KpisEnum.BLENDED_ROAS:
      return "Blended ROAS";
    default:
      return "Not Found";
  }
};

export const getENUMByKpiName = (kpiCode: string) => {
  switch (kpiCode) {
    case "Marketing Spend":
      return KpisEnum.MARKETING_SPEND;
    case "Return on Ad Spend":
      return KpisEnum.RETURN_ON_AD_SPEND;
    case "Marketing Efficiency Ratio":
      return KpisEnum.MARKETING_EFFICIENCY_RATIO;
    case "Advertising Cost of Sales":
      return KpisEnum.ADVERTISING_COST_OF_SALES;
    case "Cost per Click":
      return KpisEnum.COST_PER_CLICK;
    case "Cost per Order":
      return KpisEnum.COST_PER_ORDER;
    case "Cost per Mile":
      return KpisEnum.COST_PER_MILE;
    case "Customer Acquisition Cost":
      return KpisEnum.CUSTOMER_ACQUISITION_COST;
    case "AIO":
      return KpisEnum.AIO;
    case "Users":
      return KpisEnum.USERS;
    case "New Users":
      return KpisEnum.NEW_USERS;
    case "Clicks":
      return KpisEnum.CLICKS;
    case "Gross Revenue":
      return KpisEnum.GROSS_REVENUE;
    case "Net Revenue":
      return KpisEnum.NET_REVENUE;
    case "Gross Profit":
      return KpisEnum.GROSS_PROFIT;
    case "Contribution Profit":
      return KpisEnum.CONTRIBUTION_PROFIT;
    case "Gross Margin":
      return KpisEnum.GROSS_MARGIN;
    case "Orders":
      return KpisEnum.ORDERS;
    case "Average Orders Value":
      return KpisEnum.AVERAGE_ORDERS_VALUE;
    case "Units Sold":
      return KpisEnum.UNITS_SOLD;
    case "Customers":
      return KpisEnum.CUSTOMERS;
    case "New Customer Order":
      return KpisEnum.NEW_CUSTOMER_ORDER;
    case "Returning Customer Orders":
      return KpisEnum.RETURNING_CUSTOMER_ORDERS;
    case "Customer Lifetime value":
      return KpisEnum.CUSTOMER_LIFETIME_VALUE;
    case "Cost of Goods Sold":
      return KpisEnum.COST_OF_GOODS_SOLD;
    case "Shoping Cart Abandoment Rate":
      return KpisEnum.SHOPPING_CART_ABANDONMENT_RATE;
    case "Returns":
      return KpisEnum.RETURNS;
    case "Replacements":
      return KpisEnum.REPLACEMENTS;
    case "Sessions":
      return KpisEnum.SESSIONS;
    case "Cost per Sessions":
      return KpisEnum.COST_PER_SESSION;
    case "Conversion Rate (CR)":
      return KpisEnum.CONVERSION_RATE;
    case "CR":
      return KpisEnum.CONVERSION_RATE;
    case "Inventory Turnover":
      return KpisEnum.INVENTORY_TURNOVER;
    case "Average Inventory":
      return KpisEnum.AVERAGE_INVENTORY;
    case "Days Sales of Inventory":
      return KpisEnum.DAYS_SALES_OF_INVENTORY;
    case "DSO":
      return KpisEnum.DAYS_SALE_OUTSTANDING;
    case "DPO":
      return KpisEnum.DAYS_PAYABLE_OUTSTANDING;
    case "COC":
      return KpisEnum.CASH_CONVERSION_CYCLE;
    case "Blended ROAS":
      return KpisEnum.BLENDED_ROAS;
    case "AOV":
      return KpisEnum.AVERAGE_ORDERS_VALUE;
    case "Pending Devices":
      return KpisEnum.PENDING_DEVICES;
    default:
      return KpisEnum.GROSS_REVENUE;
  }
};

export const  convertCamelCaseToString = (input?: string, displayNamesMapping?: {fieldName?: string, displayName?: string}[]): string => {
  const foundDisplayName = displayNamesMapping?.find((name: any) => name?.fieldName === input)?.displayName;
  if(!!foundDisplayName){
    return foundDisplayName
  }
  if(!!input){
    const words: string[] = [];
    let currentWord = '';

    for (let i = 0; i < input?.length; i++) {
      const char = input?.charAt(i);

      if (char === char.toUpperCase()) {
        // Start of a new word
        if (currentWord.length > 0) {
          words.push(currentWord);
        }
        currentWord = char;
      } else {
        // Append lowercase character to current word
        currentWord += char;
      }
    }

    if (currentWord.length > 0) {
      words.push(currentWord);
    }

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return capitalizedWords.join(' ');
  }
  return ''
}
import styled from 'styled-components';

export namespace styles {

    export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

    export const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.errorBackgroundColor};
  padding: 20px;
  border-radius: 12px;
`;

    export const Message = styled.span`
  font-size: 14px;
  color: ${props => props.theme.errorColor};
`;
}

export default styles;

import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {WebsiteState} from "./state";

export const websiteStateSelector = (state: RootState): WebsiteState =>
  state.website;

export const marketingDataSelector = createSelector(
  websiteStateSelector,
  (state) => state.data
);

import styled from "styled-components";
import {theme} from "@chakra-ui/react";
import Lottie from "lottie-react";

export namespace styles {
  export const Wrapper = styled.div<{close?:boolean}>`
    position: absolute;;
    top: 80vh;
    /*width: 38vw;*/
    max-width: 420px;
    width: calc(100% - 50px);
    height: 60px;
    /*padding: 15px;*/
    right: calc(100vh - 97vh);
    font-size: 24px;
    font-weight: bold;
    box-shadow: 0px 20px 30px #695f9740;
    color: ${props => props?.theme?.primaryColor};
    border-radius: 5px;
    border-left: ${props => `4px solid ${props?.theme?.primaryColor}`};
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
      /*
    background-color: ${props => props?.theme?.primaryColor};
*/
    background-color: #efe6ff;
    /*background-color: #DBCBFA;*/
    /*
    background-color: #71d18b;
    */
    z-index: 6;
    /*transition: right 1s;*/
    bottom: -50px;
    /* left: 0;
     right: 0;
     margin-left: auto;
     margin-right: auto;*/
    @keyframes slideIn {
      from {
        right: -40vw;
      }
      to {
        right: calc(100vh - 97vh);
      }
    }
    @keyframes slideOut {
      from {
        right: calc(100vh - 97vh);
      }
      to {
        right: -40vw;
      }
    }
    animation: ${props => props?.close ? `slideOut .7s ease-in-out forwards` : `slideIn .7s ease-in-out forwards`};

      /* @keyframes slideIn {
      from {top: 100vh;}
      to {top: 90vh;}
    }
    @keyframes slideOut {
      from {top: 90vh;}
      to {top: 100vh;}
    }
    animation: ${props => props?.close ? `slideOut .7s ease-out forwards` : `slideIn .5s ease-out forwards`};*/

  `;

  export const TextContainer = styled.div`
    font-size: 16px;
    color: white;
    color: ${props => props?.theme?.primaryColor};
    color: black;
    font-weight: 600;
    /*font-family: ${props => props?.theme?.fonts?.roboto?.regular};*/
  `

  export const LottieStyle = styled(Lottie)`
    width: 100%;
    height: 100%;
  `;
  export const ApplyChangesButton = styled.button<{clicked: boolean}>`
    border-radius: 50px;
    /*border-width: 0px;*/
    color: #7031EB;
    font-size: 14px;
    font-weight: bold;
    box-shadow: ${(props) => props.theme.fullShadow};
    /*background-color: #ffffff;*/
    border: 2px solid #7031EB;

    /*padding: 10px 20px;*/
   /* color: #71d18b;*/
    width: 131px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props?.clicked ? props?.theme?.primaryColor : ""};

    &:hover{
      background-color: ${props => props?.theme?.primaryColor};
      color:  ${props => props?.theme?.primaryColor};
      color:  white;
    }
  `;
}

export default styles;

import wideViewsStyles from "./styles";
import { OverTimeGraph } from "../../over-time-graph/OverTimeGraph";
import { SessionsTable } from "./SessionsTable/SessionsTable";
import { useTranslation } from "react-i18next";
import { Graph } from "../../graph-node-card/Graph";
import { EffortsTable } from "../../efforts-table/EffortsTable";
import {
    IconAcrossChannels,
    IconCalendar,
    IconClearFiltersArrow,
    IconDiamond,
    IconShare
} from "../../../utils/icons/explore";
import { TargetBadge } from "../../target-badge/TargetBadge";
import React, { useEffect, useState } from "react";
import styles from "../../../features/workspace/styles";
import { DownloadButton } from "../../download-button/DownloadButton";
import { useDispatch, useSelector } from "react-redux";
import { kpiStateSelector } from "store/kpis";
import { Kpi } from "models/kpi";
import { currency } from "components/general-overlay/GeneralOverlay";
import { CurrencyCode, organizationDataLanguageSelector, organizationSelector } from "@store";
import { AdData, IntegrationPlatform } from "@models";
import { marketingDataSelector } from "store/website";
import { SessionsAdTable } from "./SessionsAdTable/SessionsAdTable";
import { fetchMarketingData } from "store/marketing";
import { Row } from "react-table";
import { fakeDataSessions } from "components/graph-node-card/FakeDataTree";
import { effortsSelector, EffortsState } from "store/efforts";
import { getLastYear, groupDatesByWeek, sumData } from "utils/get-months/getLastYear";
import { dateRangeSelector, openDefaultExploreView } from "../../../store/ui";
import { IconCloseOverlay } from "../../../utils/icons/account-health";
import { useDisclosure } from "@chakra-ui/react";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { KpisEnum } from "models/kpi-settings";
import { GroupBy } from "features/workspace/Workspace";
import { getDatesInRange } from "utils/colors";
import {groupDataByKey} from "../../../utils/group-by/group-by";
import {columns} from "../../../utils/effortTableHeaders/headers"

export interface ExploreProps {
    title?: string;
}

interface ColumnData {
    website: string;
    sessions: number;
    sharesOfTotal: number;
    vsLastYear: number;
}

const test: AdData[] =
    [
        {
            id: 123,
            paid: true,
            platform: IntegrationPlatform.FACEBOOK_ADS,
            accountId: "account_1",
            campaignId: "campaign_1",
            adSetId: "adset_1",
            adId: "ad_1",
            channelName: "Facebook Ads",
            sessions: 54,
            website: "www.Facebook-Shop.com",
        },
        {
            id: 124, paid: true,
            platform: IntegrationPlatform.FACEBOOK_ADS,
            accountId: "account_1",
            campaignId: "campaign_1",
            adSetId: "adset_1",
            adId: "ad_2",
            channelName: "Facebook Ads",
            sessions: 91,
            website: "www.Facebook-Shop.com",
        },
        {
            id: 125,
            paid: true,
            platform: IntegrationPlatform.FACEBOOK_ADS,
            accountId: "account_1",
            campaignId: "campaign_1",
            adSetId: "adset_1",
            adId: "ad_3",
            channelName: "Facebook Ads",
            sessions: 143,
            website: "www.Facebook-Shop.com",
        },
        {
            id: 145,
            paid: true,
            platform: IntegrationPlatform.FACEBOOK_ADS,
            accountId: "account_1",
            campaignId: "campaign_1",
            adSetId: "adset_1",
            adId: "ad_10",
            channelName: "Facebook Ads",
            sessions: 43,
            website: "www.Facebook-Shop.com",
        },
        {
            id: 129,
            paid: true,
            platform: IntegrationPlatform.FACEBOOK_ADS,
            accountId: "account_2_facebook",
            campaignId: "campaign_8",
            adSetId: "adset_15",
            adId: "ad_6",
            channelName: "Facebook Ads",
            website: "www.Facebook-Shop.com",
            sessions: 143,
        },
        {
            id: 126,
            paid: true,
            platform: IntegrationPlatform.AMAZON_ADS,
            accountId: "account_216",
            campaignId: "campaign_452",
            adSetId: "adset_2",
            adId: "ad_4",
            channelName: "Amazon",
            website: "www.Amazon-Shop.com",
            sessions: 143,
        },
        {
            id: 127,
            paid: true,
            platform: IntegrationPlatform.AMAZON_ADS,
            accountId: "account_216",
            campaignId: "campaign_25",
            adSetId: "adset_23",
            adId: "ad_5",
            website: "www.Amazon-Shop.com",
            channelName: "Amazon",
            sessions: 101,
        }
    ];



interface Data {
    col_0: string;
    col_1: number;
    col_2: number | string;
    col_3: number;
}


export const SessionsExplore = (props: ExploreProps) => {
    // hooks
    const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);
    const [actualDataChart, setActualDataChart] = useState<number[]>([]);
    const [targetDataChart, setTargetDataChart] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const { isOpen: isEffortsOpen, onOpen: onEffortsOpen, onClose: onEffortsClose } = useDisclosure();  //
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();  //
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const { t } = useTranslation(['translation', 'translation']);
    const [kpiData, setKpiData] = useState<Kpi[]>([]);
    const kpis = useSelector(kpiStateSelector);
    const [filteredByGroupData, setFilteredByGroupData] = useState<{}>();
    const [totalSessions, setTotalSessions] = useState<number>(0);
    const [selectedRows, setSelectedRow] = useState([] as Row<Data>[]);
    const dispatch = useDispatch();
    const efforts: EffortsState = useSelector(effortsSelector);
    const [tableData, setTableData] = useState<{
        channelName: string;
        grossRevenue: number | string;
        shareOfTotal: number;
        vsTarget: number;
        impactOnTarget: number;
    }[]>([]);
    const [adTableData, setAdTableData] = useState<AdData[]>();
    const organizationId = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const dateSelector = useSelector(dateRangeSelector);
    const [filteredDataByDates, setFilteredByDatesData] = useState<any[]>([]);

    useEffect(() => {
        setAdTableData(test);
    }, []);

    useEffect(() => {
        if (!!kpis?.data?.data && !!dateSelector?.startDate && !!dateSelector?.endDate) {
            const dailyKpiData = JSON.parse(JSON.stringify(kpis?.data?.data));
            const keysToIgnore = ["wvChannelId", "purchasePoint", "date", "country", "createdAt", "updatedAt", "id", "activityDate", "market"];
            const datesArray = getDatesInRange(new Date(dateSelector?.startDate), new Date(dateSelector?.endDate));
            const weeks: string[] = [];
            const flatWeekData: any[] = [];
            const dataArray: any[] = [];

            dailyKpiData?.forEach((item: any) => {
                if (datesArray.includes(item?.activityDate.slice(0, 10))) {
                    dataArray.push(item);
                }
            });

            const sumAllDays = {};
            dataArray.forEach((day) => {
                if (day?.activityDate?.slice(0, 10) in sumAllDays) {
                    sumAllDays[day?.activityDate?.slice(0, 10)].push(day);
                }
                else {
                    sumAllDays[day?.activityDate?.slice(0, 10)] = [day];
                }
            });
            const newSummedDataArray: any[] = [];
            Object.keys(sumAllDays).forEach((key) => {
                newSummedDataArray.push({ ...sumData(sumAllDays[key], []), activityDate: key });

            });

            const missingDays = datesArray.filter((day) => {
                return !newSummedDataArray.some((item) => item.activityDate === day);
            });
            const missingDaysData = missingDays.map((item) => {
                return {
                    activityDate: item,
                };
            });
            const sortedByDates = [...missingDaysData, ...newSummedDataArray].sort((a: { activityDate: string | number | Date; }, b: { activityDate: string | number | Date; }) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()).reverse();
            let dataByYearAndWeeks: any;

            if (groupByStatus === GroupBy.YEAR) {
                const yearData = {};
                sortedByDates.forEach((item) => {
                    if (yearData[item?.activityDate.slice(0, 4)]) {
                        yearData[item?.activityDate.slice(0, 4)].push(item);
                    }
                    else {
                        yearData[item?.activityDate.slice(0, 4)] = [item];
                    }
                });
                const years: string[] = [];
                const flatData: any[] = [];
                const finalData: any[] = [];
                Object.keys(yearData).forEach(key => {
                    years.push(key);
                    const currentDay = { ...sumData(yearData[key], keysToIgnore), activityDate: key };
                    flatData.push(currentDay);
                });
                flatData.forEach((item: any) => {
                    if (!!item.sessions) {
                        finalData.push(item.sessions);
                    }
                    else {
                        finalData.push(0);
                    }
                }
                );
                setLabels(years);
                setActualDataChart(finalData);
                setTargetDataChart(finalData);
            }

            if (groupByStatus === GroupBy.MONTH) {
                const monthsData = {};
                sortedByDates.forEach((item) => {
                    if (monthsData[item?.activityDate.slice(0, 7)]) {
                        monthsData[item?.activityDate.slice(0, 7)].push(item);
                    }
                    else {
                        monthsData[item?.activityDate.slice(0, 7)] = [item];
                    }
                });
                const months: string[] = [];
                const flatData: any[] = [];
                const finalData: any[] = [];

                Object.keys(monthsData).forEach(key => {
                    months.push(key);
                    const currentDay = { ...sumData(monthsData[key], keysToIgnore), activityDate: key };
                    flatData.push(currentDay);
                });

                flatData.forEach((item: any) => {
                    if (!!item.sessions) {
                        finalData.push(item.sessions);
                    }
                    else {
                        finalData.push(0);
                    }
                }
                );
                setLabels(months);
                setActualDataChart(finalData);
                setTargetDataChart(finalData);
            }

            if (groupByStatus === GroupBy.WEEK) {
                dataByYearAndWeeks = groupDatesByWeek(sortedByDates);
                const finalData: any[] = [];
                Object.keys(dataByYearAndWeeks).forEach(key => {
                    Object.keys(dataByYearAndWeeks[key]).forEach((dateRange) => {
                        weeks.push(dateRange + "-" + key.slice(2));
                        dataByYearAndWeeks[key][dateRange] = { ...sumData(dataByYearAndWeeks[key][dateRange], keysToIgnore), activityDate: dateRange };
                        flatWeekData.push(dataByYearAndWeeks[key][dateRange]);
                    });
                });
                flatWeekData.forEach((item: any) => {
                    if (!!item.sessions) {
                        finalData.push(item.sessions);
                    }
                    else {
                        finalData.push(0);
                    }
                }
                );
                setLabels(weeks);
                setActualDataChart(finalData);
                setTargetDataChart(finalData);
            }
            if (groupByStatus === GroupBy.DAY) {
                /* dataByYearAndWeeks = groupDatesByWeek(sortedByDates); */
                const finalData: any[] = [];
                const days: string[] = [];
                /* Object.keys(dataByYearAndWeeks).forEach(key => {
                    Object.keys(dataByYearAndWeeks[key]).forEach((dateRange) => {
                        weeks.push(dateRange + "-" + key.slice(2));
                        dataByYearAndWeeks[key][dateRange] = { ...sumAllData(dataByYearAndWeeks[key][dateRange], keysToIgnore), activityDate: dateRange };
                        flatWeekData.push(dataByYearAndWeeks[key][dateRange]);
                    });
                }); */
                sortedByDates.forEach((item: any) => {
                    days.push(item.activityDate.slice(0, 10));
                    if (!!item.sessions) {
                        finalData.push(item.sessions);
                    }
                    else {
                        finalData.push(0);
                    }
                }
                );
                setLabels(days);
                setActualDataChart(finalData);
                setTargetDataChart(finalData);
            }



        }
    }, [kpis?.data?.data, dateSelector?.startDate, dateSelector?.endDate, groupByStatus]);

    useEffect(() => {
        if (!!kpis.data?.data) {
            if (!!startDate && !!endDate) {
                const datesRange = getDatesInRange(startDate, endDate);
                const filteredData = kpis?.data?.data.filter((item) => {
                    return datesRange.includes(item.activityDate.slice(0, 10));
                });
                setFilteredByDatesData(filteredData);
            }
            else {
                setFilteredByDatesData(kpis.data?.data);
            }
        }
    }, [kpis.data?.data, kpiData, startDate, endDate]);

    useEffect(() => {
        if (!!filteredDataByDates) {
            let totalSessions = 0;
            filteredDataByDates.forEach((item) => {
                totalSessions = totalSessions + item?.sessions;
            });
            setTotalSessions(totalSessions);
        }
    }, [filteredDataByDates]);


    useEffect(() => {
        if (kpis.data?.data !== undefined) {
            setKpiData(kpis.data?.data);
        }
    }, [kpis.data?.data]);


    // selectors

    useEffect(() => {
        const holder = {};
        if (kpiData !== undefined && dataLanguage?.generalSettings?.currency) {
            let grossRevenueCounter = 0;
            kpiData.forEach((item) => {
                if (item.sessions !== undefined) {
                    grossRevenueCounter = grossRevenueCounter + item.sessions;
                }
            });
            const currencySign = currency.filter((item) => {
                if (!!dataLanguage?.generalSettings) {
                    return (item.title === CurrencyCode[dataLanguage!.generalSettings.currency]);
                } return;
            });
            kpiData.forEach(function (d) {
                if (holder.hasOwnProperty(d.wvChannelId)) {
                    holder[d.wvChannelId] = holder[d.wvChannelId] + d.sessions;
                } else {
                    holder[d.wvChannelId] = d.sessions;
                }
            });
            setFilteredByGroupData(holder);
        }
    }, [kpiData, organizationId]);

    // helpers



    const selectedRow = (row: Row<Data>) => {
        row.toggleRowSelected();
        const isRowSelected = selectedRows.filter(selectedRow => selectedRow.id === row.id);
        if (!!isRowSelected && isRowSelected.length > 0) {
            setSelectedRow([...(selectedRows.filter(selectedRow => selectedRow.id !== row.id))]);
        } else {
            const newSelected = [...selectedRows];
            newSelected.push(row);
            setSelectedRow(newSelected);
        }
    };


    let total = 0;
    const keysToSum = ['sessions'];

    const summed: AdData[] = Object.values(test.reduce((obj, record) => {
        if (record.website !== undefined) {
            if (!obj[record.website]) {
                obj[record.website] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.website!][key] += record[key];
                });
            }
            return obj;
        }
        return obj;
    }, {}));

    summed.forEach((item) => {
        if (item.sessions !== undefined) {
            total = total + item.sessions;
        }
    });

    const mockData: ColumnData[] = summed.map((item) => {
        if (item.sessions !== undefined && item.website !== undefined) {
            return (
                { website: item.website, sessions: item.sessions, vsLastYear: 10, sharesOfTotal: item.sessions / total * 100 });
        }
        return { website: "", sessions: 0, vsLastYear: 10, sharesOfTotal: 10 };
    });



    useEffect(() => {
        let counter = 0;
        if (filteredByGroupData) {
            Object.keys(filteredByGroupData).forEach(key => {
                counter = filteredByGroupData[key] + counter;
            });
            const tableData = Object.keys(filteredByGroupData).map(key => {
                return {
                    channelName: IntegrationPlatform[key],
                    grossRevenue: (filteredByGroupData[key]),
                    shareOfTotal: filteredByGroupData[key] / counter * 100,
                    vsTarget: 105,
                    impactOnTarget: 1.5
                };
            });
            setTableData(tableData);
        }
    }, [filteredByGroupData]);

    const resetFilters = () => {
        if (!!dateSelector?.endDate && !!dateSelector?.startDate) {
            setStartDate(new Date(dateSelector.startDate));
            setEndDate(new Date(dateSelector.endDate));
        }
    };




    // renderers
    const renderShareButton = () => {
        return <wideViewsStyles.HeaderButton>
            <IconShare />
            <wideViewsStyles.HeaderButtonText>
                {t('SHARE', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };


    const renderResetFilters = () => {
        return <wideViewsStyles.HeaderButton>
            <IconClearFiltersArrow />
            <wideViewsStyles.HeaderButtonText>
                {t('RESET_FILTERS', { ns: 'translation' })}
            </wideViewsStyles.HeaderButtonText>
        </wideViewsStyles.HeaderButton>;
    };

    const renderCloseButton = () => {
        return (
                <button onClick={() => dispatch(openDefaultExploreView({ isOpen: false }))}>
                    <IconCloseOverlay />
                </button>
        );
    };

    const renderHeader = () => {
        return <wideViewsStyles.Header>
            <wideViewsStyles.TitleWrapper>
                <wideViewsStyles.TitleDynamic>
                    {t('SESSIONS', { ns: 'translation' })}
                </wideViewsStyles.TitleDynamic>
                <wideViewsStyles.TitleStatic>
                    {t('EXPLORATION', { ns: 'translation' })}
                </wideViewsStyles.TitleStatic>
                <wideViewsStyles.HeaderButtonsWrapper>
                    {renderResetFilters()}
                    <DatepickerRange onApply={(e) => { }} defaultsDate={dateSelector.preset} />
                    {renderShareButton()}
                    {renderCloseButton()}
                </wideViewsStyles.HeaderButtonsWrapper>

            </wideViewsStyles.TitleWrapper>
            <wideViewsStyles.ValueWrapper>
                <wideViewsStyles.Value>
                    {totalSessions}
                </wideViewsStyles.Value>
                {/* <wideViewsStyles.BadgeWrapper>
                    <TargetBadge color={'#57a11e'} percentage={100} />
                </wideViewsStyles.BadgeWrapper> //TODO .V2 */}
            </wideViewsStyles.ValueWrapper>
            <wideViewsStyles.Header>
                {'Drill down to the main drivers of this KPI (over time, channels, supporting KPIs) and assign an owner to this task accordingly.'}
            </wideViewsStyles.Header>
        </wideViewsStyles.Header>;
    };

    return (
        <wideViewsStyles.Wrapper>
            {renderHeader()}
            <wideViewsStyles.SectionOne>
                <OverTimeGraph clearFilters={resetFilters} groupByStatus={groupByStatus} setGroupBy={setGroupByStatus} kpiEnum={KpisEnum.SESSIONS} setStartDate={setStartDate} setEndDate={setEndDate} actual={actualDataChart} target={targetDataChart} labels={labels} />
            </wideViewsStyles.SectionOne>
            {tableData !== undefined ? <wideViewsStyles.SectionTwo>
                <SessionsTable
                    icon={<IconAcrossChannels />}
                    headers={[
                        t('WEBSITE', { ns: 'translation' }),
                        t('SESSIONS', { ns: 'translation' }),
                        t('SHARE_OF_TOTAL', { ns: 'translation' }),
                        t('VS_LAST_YEAR', { ns: 'translation' }),
                    ]}
                    data={mockData!}
                    selectedRows={selectedRows}
                    selectedRow={selectedRow}
                    title={t('ACROSS_WEBSITES', { ns: 'translation' })} />
                <div style={{ width: '20px' }} />
                {adTableData !== undefined ? <SessionsAdTable
                    headers={[
                        t('TYPE', { ns: 'translation' }),
                        t('SESSIONS', { ns: 'translation' }),
                        t('SHARES_OF_TOTAL', { ns: 'translation' }),
                        t('VS_LAST_YEAR', { ns: 'translation' }),
                        "platform",
                        "accountId",
                        "campaignId",
                        "adSetId",
                        "adId",
                    ]}
                    icon={<IconAcrossChannels />}
                    data={adTableData}
                    selectedFilters={selectedRows}
                    title={t('ACROSS_SOURCES', { ns: 'translation' })} /> : null}
            </wideViewsStyles.SectionTwo> : null}
            <wideViewsStyles.SectionThree>
                <Graph graphData={fakeDataSessions} title={t('SUPPORTING_KPIS', { ns: 'translation' })} />
            </wideViewsStyles.SectionThree>
            <wideViewsStyles.SectionFour>
                <styles.Card>
                    <styles.Header>
                        <styles.Title>
                            <span style={{ marginRight: '5px' }}>
                                {<IconDiamond />}
                            </span>
                            {t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        </styles.Title>
                        <DownloadButton onClick={() => console.log('download')} />
                    </styles.Header>
                    {efforts.data !== undefined ? <EffortsTable
                        title={t('EFFORTS_IN_PROGRESS', { ns: 'translation' })}
                        data={efforts.data}
                        headers={columns}
                    /> : <></>}
                </styles.Card>
            </wideViewsStyles.SectionFour>
        </wideViewsStyles.Wrapper>
    );
};

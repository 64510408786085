import styled from "styled-components";
import { Slide } from "@chakra-ui/react";

export namespace styles {
  export const Wrapper = styled.div`
    zoom: 1;
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  `;

  export const Page = styled.div`
    display: flex;
    flex-direction: column;
    /* height: calc(100% - 80px); */ // TODO check
    width: calc(100% - 220px);
  `;

  export const TopHeader = styled.div`
    background-color: ${(props) => props.theme.sidebarTopColor};
    height: 40px;
    width: 100%;
    display: flex;
    align-self: end;
  `;

  export const Header = styled.div`
    background: transparent linear-gradient(344deg, #1a0989 0%, #7031eb 100%) 0%
      0% no-repeat padding-box;
    width: 100%;
  `;

  export const BelowHeader = styled.div`
    background-color: ${(props) => props.theme.sidebarTopColor};
    display: flex;
    flex-direction: row;
    height: calc(100% - 40px);
    width: 100%;
  `;

  export const Content = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    height: 100%;
    width: 100%;
    scroll-behavior: auto !important;
    border-radius: 0;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 18px;
    /* overflow-y: hidden; */
    /* &:hover {
      overflow-y: scroll;
    } */
  `;

  // explore overlay
  export const ExploreOverlayWrapper = styled(Slide)`
    z-index: 10;
    display: flex;
    justify-content: end;
    align-items: flex-end;
  `;

  // explore overlay
  export const OverlayWrapper = styled(Slide)`
    z-index: 10;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
  `;
  export const ExploreOverlayContent = styled.div`
    height: 100%; //   CONTENT HEIGHT
    width: calc(100vw - 55px);
    overflow: auto;
    background-color: ${(props) => props.theme.backgroundColor};
    border-radius: 15px 0 0 15px;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */ //
    /* ::-webkit-scrollbar {
      display: none;
    } */
  `;

  export const OverlayContent = styled.div`
    height: 90%;
    width: 90%;
    overflow: auto;
    background-color: ${(props) => props.theme.backgroundColor};
    border-radius: 15px 0 0 15px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  export const BelowContent = styled.div`
    height: 90px;
  `
}

export default styles;

import marketingIntegrationsStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import {
    countIntegrationInOrganization,
    getIntegrationPlatformIncText,
    getIntegrationPlatformName,
    getIntegrationPlatformRoundLogo,
    IntegrationPlatform,
} from "@models";
import { organizationSelector } from "@store";
import { useSelector } from "react-redux";
import { IntegrationButton } from "../../../components/integration-button";
import { integrationSelector } from "store/integrations";
import OAuthPopup from "components/o-auth-popup/OAuthPopup";

export const ShippingIntegrations = () => {
    // hooks
    const { t } = useTranslation(['translation', 'translation']);

    const integrations = useSelector(integrationSelector);

    // helpers
    const getIntegrationButtonData = (integrationPlatform: IntegrationPlatform) => {
        const image = getIntegrationPlatformRoundLogo(integrationPlatform);
        const title = getIntegrationPlatformName(integrationPlatform) ?? '';
        const incText = getIntegrationPlatformIncText(integrationPlatform) ?? '';
        const integrationCount = countIntegrationInOrganization(integrationPlatform, integrations.integrations);
        const badgeText = integrationCount > 0 ? integrationCount?.toString() : undefined;
        return { image, title, incText, badgeText };
    };

    const getShipbobParams = (params: URLSearchParams) => {
    };
    const getFloshipParams = (params: URLSearchParams) => {
    };
    const getFedexParams = (params: URLSearchParams) => {
    };
    const getShipstationParams = (params: URLSearchParams) => {
    };


    // renderers
    const renderShippingIntegrations = () => {
        return (
            <Flex direction={'column'} marginBottom={10}>
                <Text fontWeight={'bold'}>
                    {t('SHIPPING', { ns: 'translation' })}
                </Text>
                <Flex direction={"row"}>
                    {renderShipbob()}
                    {renderFloship()}
                    {renderFedex()}
                    {renderShipstation()}
                </Flex>
            </Flex>
        );
    };

    const renderShipbob = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.SHIPBOB);
        return (
            /*<OAuthPopup
                title={'Shipbob'}
                height={800}
                width={1000}
                url={`https://auth.shipbob.com/connect/authorize?client_id=ExternalApplication_fa579fb5-48a1-4713-987a-9056e797765a&scope=products_read%20channels_read%20offline_access&redirect_uri=https%3A%2F%2Fcollectors.wideview.io%2Fshipbob%2oauth%2Fcallback`}
                onResponse={(params) => {
                    getShipbobParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        premium={true}
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };
    const renderFloship = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.FLOSHIP);
        return (
            /*<OAuthPopup
                title={'Flowship'}
                height={800}
                width={1000}
                url={"www.google.com"}
                onResponse={(params) => {
                    getFloshipParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        premium={true}
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };
    const renderFedex = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.FEDEX);
        return (
            /*<OAuthPopup
                title={'Fedex'}
                height={800}
                width={1000}
                url={"www.google.com"}
                onResponse={(params) => {
                    getFedexParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        premium={true}
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };
    const renderShipstation = () => {
        const { image, title, incText, badgeText } = getIntegrationButtonData(IntegrationPlatform.SHIPSTATION);
        return (
            /*<OAuthPopup
                title={'Shipstation'}
                height={800}
                width={1000}
                url={"www.google.com"}
                onResponse={(params) => {
                    getShipstationParams(params!);
                }}
                onClose={() => {
                    // console.log('closing!');
                }}
            >*/
                <marketingIntegrationsStyles.ButtonWrapper>
                    <IntegrationButton
                        premium={true}
                        title={t(title, { ns: 'translation' })}
                        /* description={t(incText, { ns: 'translation' })} */
                        image={image}
                        badgeText={badgeText}>
                    </IntegrationButton>
                </marketingIntegrationsStyles.ButtonWrapper>
            /*</OAuthPopup>*/
        );
    };



    return (
        <marketingIntegrationsStyles.Wrapper>
            {renderShippingIntegrations()}
        </marketingIntegrationsStyles.Wrapper>
    );
};

// imports
import { createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

// locals
import { initialOperationState, OperationState } from "./state";
import {
  createPurchaseOrder,
  editAsnOrder, fetchHandlingStats, fetchInventoryData, fetchLastMileData,
  fetchLastMileMetaData,
  fetchModalTrackingDataThunks,
  fetchOperationData,
  fetchPoData, fetchTransportationData,
  uploadFilesThunk,
} from "./thunks";
import { DepartmentsEnum } from "../../services/operations/operation.service";
import { clearEventModalData, clearOperation } from "./actions";

// reducers

// fetch operations
function buildClearOperationData(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(clearOperation, (state, action) => {
    state.data.inventoryData = undefined;
    state.data.manufacturingData = undefined;
    state.data.transportationData = undefined;
    state.data.purchaseStats = undefined;
    return state;
  });
}
function buildClearEventModalData(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(clearEventModalData, (state, action) => {
    state.modalTrackingEvents = [];
    return state;
  });
}

function buildFetchOperations(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchPoData.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state;
  });
  builder.addCase(fetchPoData.fulfilled, (state, action) => {
    state.error = undefined;
    state.loading = false;
    state.data.manufacturingData = action?.payload?.data;

    return state;
  });
  builder.addCase(fetchPoData.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    return state;
  });
}



function buildFetchTrackingEventData(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchModalTrackingDataThunks.pending, (state, action) => {
    state.modalLoader = true;
    return state;
  });
  builder.addCase(fetchModalTrackingDataThunks.fulfilled, (state, action) => {
    state.modalLoader = false;
    state.modalTrackingEvents = action?.payload?.events?.reverse();

    return state;
  });
  builder.addCase(fetchModalTrackingDataThunks.rejected, (state, action) => {
    state.modalLoader = false;
    return state;
  });
}

function buildFetchLastMileMetaData(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchLastMileMetaData.pending, (state, action) => {
    return state;
  });
  builder.addCase(fetchLastMileMetaData.fulfilled, (state, action) => {
    state.data.lastMileMetaData = action.payload;

    return state;
  });
  builder.addCase(fetchLastMileMetaData.rejected, (state, action) => {
    return state;
  });
}
function buildFetchTransportationData(
    builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchTransportationData.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    state.transportationFiltersFlag = false;
    return state
  });
  builder.addCase(fetchTransportationData.fulfilled, (state, action) => {
    state.data.transportationData = action.payload.data;
    if (!state?.filters) {
      state.filters = action.payload.filters;
    }
    return state;
  });
  builder.addCase(fetchTransportationData.rejected, (state, action) => {
    state.loading = false;
    state.transportationFiltersFlag = true;
    return state;
  });
}

function buildFetchInventoryData(
    builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchInventoryData.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;
    return state
  });
  builder.addCase(fetchInventoryData.fulfilled, (state, action) => {
    state.data.inventoryData = action.payload.data;
    if (!state?.filters) {
      state.filters = action.payload.filters;
    }
    return state;
  });
  builder.addCase(fetchInventoryData.rejected, (state, action) => {
    state.loading = false;
    return state;
  });
}

function fetchHandlingStatsSlice(
    builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchHandlingStats.pending, (state, action) => {
    state.error = undefined;
    state.purchaseStatsLoading = true;
    return state
  });
  builder.addCase(fetchHandlingStats.fulfilled, (state, action) => {
    state.purchaseStatsLoading = false;

    if (!!action.meta.arg.page && action.meta.arg.page > 0) {
      state.data.purchaseStats = state.data.purchaseStats?.concat(
          action.payload.data
      );
    } else {
      state.data.purchaseStats = action.payload.data;
    }
    state.metadata.purchaseStats = action.payload.metadata;
    return state;
  });
  builder.addCase(fetchHandlingStats.rejected, (state, action) => {
    state.purchaseStatsLoading = false;
    return state;
  });
}

function fetchLastMileDataReducer(
    builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchLastMileData.pending, (state, action) => {
    state.error = undefined;
    state.lastMileLoading = true;
    return state

  });
  builder.addCase(fetchLastMileData.fulfilled, (state, action) => {
    const formattedPayloadData = action.payload.data;
    if (!!action.meta.arg.tags && action.meta.arg.tags?.length > 0) {
      state.data.lastMileData = formattedPayloadData;
    }
    if (!!action.meta.arg.page && action.meta.arg.page > 0) {
      state.data.lastMileData =
          state.data.lastMileData?.concat(formattedPayloadData);
    } else {
      state.data.lastMileData = formattedPayloadData;
    }
    state.metadata.lastMileStats = action.payload.metadata;
    state.lastMileLoading = false;
    return state
  });
  builder.addCase(fetchLastMileData.rejected, (state, action) => {
    state.lastMileLoading = false;
    return state;
  });
}


function buildFetchOperationsData(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(fetchOperationData.pending, (state, action) => {
    state.error = undefined;
    state.loading = true;

    return state;
  });
  builder.addCase(fetchOperationData.fulfilled, (state, action) => {
    state.error = undefined;
    if (!state?.filters) {
      state.filters = action.payload.filters;
    }
    if (action.meta.arg.kpisType === DepartmentsEnum.LAST_MILE) {

    }
    state.loading = false;
    state.transportationFiltersFlag = true;

    return state;
  });
  builder.addCase(fetchOperationData.rejected, (state, action) => {
    state.error = action?.payload;
    state.loading = false;
    state.transportationFiltersFlag = true;

    return state;
  });
}

function buildCreatePurchaseOder(
  builder: ActionReducerMapBuilder<OperationState>
) {
  builder.addCase(createPurchaseOrder.pending, (state, action) => {
    state.error = undefined;
    state.createPoLoader = true;
    return state;
  });
  builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
    state.error = undefined;
    state.success = true;
    state.createPoLoader = false;

    return state;
  });
  builder.addCase(createPurchaseOrder.rejected, (state, action) => {
    state.error = action?.payload;
    state.success = false;
    /*state.createPoLoader = false;*/

    return state;
  });
}
function buildFilesUpload(builder: ActionReducerMapBuilder<OperationState>) {
  builder.addCase(uploadFilesThunk.pending, (state, action) => {
    state.filesUploadError = false;
    state.isFilesUploading = true;
    return state;
  });
  builder.addCase(uploadFilesThunk.fulfilled, (state, action) => {
    state.filesUploadError = false;
    state.isFilesUploading = false;

    return state;
  });
  builder.addCase(uploadFilesThunk.rejected, (state, action) => {
    state.filesUploadError = true;
    state.isFilesUploading = false;
    return state;
  });
}

function buildEditAsnOrder(builder: ActionReducerMapBuilder<OperationState>) {
  builder.addCase(editAsnOrder.pending, (state, action) => {
    state.asnEditLoader = true;
    return state;
  });
  builder.addCase(editAsnOrder.fulfilled, (state, action) => {
    state.asnEditLoader = false;
    const foundIndex = state.data.transportationData?.findIndex(
      (x) => x.id === action?.payload?.id
    );
    if (
      foundIndex !== undefined &&
      foundIndex > -1 &&
      !!state?.data?.transportationData
    ) {
      state.data.transportationData[foundIndex] = action?.payload;
    }
    return state;
  });
  builder.addCase(editAsnOrder.rejected, (state, action) => {
    state.asnEditLoader = false;

    return state;
  });
}

// slice
export const operationsSlice = createSlice({
  name: "operations",
  initialState: initialOperationState,
  reducers: {},
  extraReducers: (builder) => {
    // non async
    buildClearOperationData(builder);

    buildClearEventModalData(builder);

    // async
    buildFilesUpload(builder);

    buildFetchOperations(builder);

    fetchHandlingStatsSlice(builder);

    buildFetchLastMileMetaData(builder);

    buildFetchInventoryData(builder);

    buildFetchOperationsData(builder);

    buildFetchTransportationData(builder)

    buildCreatePurchaseOder(builder);

    buildEditAsnOrder(builder);

    buildFetchTrackingEventData(builder);

    fetchLastMileDataReducer(builder)
  },
});

// reducer
export const operationsReducer = operationsSlice.reducer;

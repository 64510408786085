/* eslint-disable jsx-a11y/anchor-is-valid */
import {Bottom, Card, ImageCircle, Line, NameInitials, NextButton, PrimaryTitle, SecondaryTitle, Text,} from "./styles";
import {useTranslation} from "react-i18next";
import {Flex} from "@chakra-ui/react";
import {useNavigate} from "react-router";

// export interface ConnectedSuccessfullyProps{
//     markAsDone: () => void;
// }

export const ShopifyConnected = () => {


    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const navigate = useNavigate();
    // selectors

    // effects

    // helpers
    const next = () => {
        navigate('/onboarding/creating-organization')
    }


    // renders

    const renderBottom = () => {
        return (
            <Bottom style={{justifyContent: "center"}}>
                <NextButton onClick={() => {
                    next()
                }}>
                    {t('NEXT', {ns: 'translation'})}
                </NextButton>
            </Bottom>
        )
    }

    const renderHeaderImage = () => {
       return <Flex flexDirection={"row"} alignItems={'center'}>
           <NameInitials>
               {'MS'}
           </NameInitials>
           <Flex flexDirection={"column"}>
               <Line style={{marginBottom: 20}}/>
               <Line/>
           </Flex>
            <ImageCircle
                src={'https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png'}
            >
            </ImageCircle>
        </Flex>
    }

    const renderCard = () => {
        return (
            <Card>
                <Text style={{marginBottom: 30}}>
                    <PrimaryTitle>
                        {`${t('WELL_THATS_A_STRONG_CONNECTION', {ns: 'translation'})}`}
                    </PrimaryTitle>
                    <PrimaryTitle>
                        {`${t('XXXXX_STORE_IS_NOW_CONNECTED', {ns: 'translation'})}  Shopify`}
                    </PrimaryTitle>
                </Text>
                {renderHeaderImage()}
                <Text style={{marginBottom: 30, marginTop: 30}}>
                    <SecondaryTitle style={{fontSize: 18}}>
                        {`${t('YOU_DID_AN_AMAZING_JOB_SO_FAR', {ns: 'translation'})}`}
                    </SecondaryTitle>
                    <SecondaryTitle style={{fontSize: 18}}>
                        {`${t('CANT_WAIT_TO_SEE_WHAT_YOULL_DO_NEXT', {ns: 'translation'})}`}
                    </SecondaryTitle>
                </Text>
                {renderBottom()}
            </Card>
        );
    }

    return renderCard();
};

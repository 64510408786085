import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin: 136px 0 0 0;
  `;
  export const InputFilter = styled.input`
    accent-color: #7031eb;
    height: 20px;
    border-radius: 10px;
    width: 20px;
  `;

  export const StyledFilterButton = styled.button`
    width: 45px;
    height: 45px;
    border-radius: 19px;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
  `;

  export const FilterText = styled.div`
    margin: 0 0 0 7px;
  `;

  export const Card = styled.div`
    background-color: white;
    width: 100%;
    padding: 20px;
    height: auto;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 13px 54px #695f9714;
    border-radius: 20px;
    /* overflow: scroll; */
  `;
  export const styledDiv = styled.div``;

  export const styledTd = styled.td`
    margin: 0;
    padding: 0.5rem;
    height: 50px;
    border-bottom: 0.5px solid #695f9726;
    border-right: 0.5px solid #695f9726;
    margin: 0 auto;
    font-size: 14px;
    width: max-content;
  `;
  export const PagesToggle = styled.div`
    width: 450px;
  `;

  export const StyledAButton = styled.a`
    color: #4a87fb;
    border-bottom: 1px solid #4a87fb;
    &:hover {
      opacity: 0.5;
    }
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: 0 0 50px;
  `;
  export const TabButtonsContainer = styled.div`
    max-width: 600px;
    height: 56px;
    display: flex;
    padding: 0 0 0 0px;
  `;
  export const StyledDiv = styled.div``;
  export const StyledDivSelected = styled.div<{ selected?: boolean }>`
    color: ${(props) => (props.selected ? "#7031eb" : "black")};
    width: 80px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.selected ? "#dac9fa" : "#CBCBCB")};
    font-size: 12px;
  `;
  export const TabButton = styled.div<{ selected?: boolean }>`
    width: 33%;
    border: 0.5px solid #695f9726;
    border-bottom: 4px solid #e5d5fa;
    border-radius: 5px 30px 0px 0px;
    display: flex;
    align-items: center;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    &:hover {
      cursor: pointer;
    }
  `;

  export const TabButtonText = styled.div`
    margin: 0 0 0 10px;
    display: flex;
    width: 80%;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 14px;
  `;
  export const TableScroll = styled.div`
    width: 100%;
    overflow: auto;
    max-height: 325px;
  `;
  export const TotalRows = styled.div`
    margin: 24px 0 0 0;
    font-size: 14px;
  `;

  export const Title = styled.div`
    display: flex;
    align-items: center;
  `;
  export const StyledTotal = styled.td`
    background-color: rgb(112, 49, 235);
    color: white;
    border-radius: 6px;
    white-space: nowrap;
    transform: scale(1.1, 1.1);
    font-size: 0.9rem;
  `;
  export const styledTh = styled.th`
    margin: 0;
    text-align: center;
    padding: 0.5rem;
    border-bottom: 0.5px solid #695f9726;
    border-right: 0.5px solid #695f9726;
    width: 150px;
    position: relative;

    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    background-color:white !important;
    z-index: 8;
    width: 110px;
  `;
  export const StyledHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
  `;
  export const FooterTd = styled.td`
    font-weight: bold;
    background-color: white;
    z-index: 0;
  `;

  export const footerTr = styled.tr`
    background-color: white;
    height: 65px;
    border-bottom: 1px solid #695f9726;
    border-top: 1px solid #695f9726;
    outline: 1px solid #695f9726;
  `;
  export const TrCustom = styled.tr<{ selected?: boolean }>`
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    &:hover {
      cursor: pointer;
    }
  `;
  export const StyledTable = styled.table`
  table-layout: fixed;
  `
  export const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 0.5px solid #695f9726;
      display: table;
      table-layout: fixed;
      height: 150px;
      &:last-of-type {
        td {
        }
      }
    }
    th {
      text-align: left;
      color: #7a86a1;
      position: relative;
      font-size: 1rem;
      font-weight: normal;
    }

    th,
    td {
      margin: 0;
      text-align: center;
      padding: 0.5rem;
      border-bottom: 0.5px solid #695f9726;
    }
  `;
}

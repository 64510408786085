import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    width: 100%;
    /*max-height: 400px;
    overflow-y: auto;*/
    font-size: 12px;

  `;
  export const HeaderTr = styled.tr`
    border-bottom: 1px solid #e3e6eb;
    height: 64px;
    position: sticky;
    top: 0;
  `;
  export const PaddingAndTableWrapper = styled.div`
    width: 100%;
    padding: 0 0 12px 0;
    position: relative;
  `;
  export const FlexTdCell = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
  `;
  export const Footer = styled.div`
    display: flex;
    width: 100%;
    left: -13px;
    position: absolute;
    border-radius: 10px;
    border-top: 1px solid #e7eaee;
    background-color: white;
    z-index: 5;
    box-shadow: 3px 17px 54px #695f9729;
  `;

  export const TableFooterWrapper = styled.div`
    position: relative;
    width: 102%;
    display: flex;
    flex-direction: column;
  `
  export const TableWrapper = styled.div`
    width: 100%;
    overflow-y: auto;
    max-height: 400px;
  `;
  export const TablePadding = styled.div`
    height: 8px;
    border-radius: 20px 20px 0 0;
    background-color: #ffffff;
  `;
  export const ThreeDotHolder = styled.div`
    &:hover {
      cursor: pointer;
    }
  `;
  export const FlexCell = styled.td``;
  export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  `;
  export const HeaderTh = styled.th`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 14px;
    background-color: #ffffff;
    max-width: 75px;
    padding: 0 10px;
    font-weight: lighter;
  `;
  export const TableTd = styled.td`
    max-width: 75px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  export const Title = styled.title`
    display: flex;
    //width: 100%;
    color: black;
    font-size: 21px;
    font-weight: bold;
    /* font-family: ${(props) => props.theme.light.fonts.h2.medium}; */
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.header};
    overflow: hidden;
    white-space: nowrap;
  `;
  export const HeaderWrapper = styled.div`
    display: flex;
    margin: 20px 0 40px 0;
    justify-content: space-between;
    align-items: center;
  `;
  export const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 10px 10px;
    background-color: white;
    position: absolute;
    bottom: -123px;
  `;
  export const TableTr = styled.tr`
    height: 84px;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #e7eaee;
    z-index: 2;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const StyledTable = styled.table`
    width: 100%;
  `;
  export const StyledTotalFooter = styled.td`
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const FooterTr = styled.tr`
    border-top: 1px solid #e7eaee;
    position: sticky;
    bottom: 0;
    background-color: white;
  `;
  export const TotalValueCell = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    border-radius: 5px;
    box-shadow: 3px 17px 54px #695f9721;
    margin-left: 40px;
  `;
  export const FooterRow = styled.tr``;


  export const DatepickerSmallWrapper = styled.div`
    background-color: white;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ebebeb;
    border-radius: 19px;
  `

  export const DatepickerTitle = styled.div`
    margin-left: 10px;
  
  `
}

export default styles;

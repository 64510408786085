import styles from './styles';
// import {useTranslation} from "react-i18next";
import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {IconMoreInfo} from "../../utils/icons/account-health";
import {CloseModalIcon} from "../../utils/icons/save-modal";
import Table, {ColumnDefinitionType} from "../../components/wideview-table/WideviewTable";
import {WideviewDataTable} from "../../components/wideview-data-table/WideviewDataTable";


// {
//     "activityDate": "2022-10-29T00:00:00.000Z",
//     "sku": "Methyl",
//     "wvChannelId": "63a98a6563ccfe5238e3492c",
//     "FacebookAdsCost": 0,
//     "GoogleAdsCost": 0,
//     "amazonCost": 0,
//     "contributionProfit": 26.350299999999997,
//     "count": 4,
//     "currency": "USD",
//     "customers": 2,
//     "discount": 2,
//     "displayName": "Amazon.com",
//     "estimatedOrderHandlingFeePerOrder": 0,
//     "estimatedPickPackFeePerUnit": 0,
//     "estimatedReferralFeePerUnit": 6,
//     "estimatedVariableClosingFee": 0,
//     "estimatedWeightHandlingFeePerUnit": 0,
//     "expectedFulfillmentFeePerUnit": 8.06,
//     "freeOnBoard": 10,
//     "grossProfit": 26.350299999999997,
//     "grossRevenue": 39.94,
//     "marketingCost": 0,
//     "netRevenue": 36.3503,
//     "orders": 2,
//     "pnl_SHIPPING": 0,
//     "quantity": 2,
//     "refunds": 0,
//     "sessions": 0,
//     "totalCogs": 10,
//     "totalDeduction": 13.5897,
//     "totalFba": 14.06,
//     "totalShipping": 0,
//     "totalTax": 1.19,
//     "transactionFees": 0.3997,
//     "wvProductId": "63a9aebc63ccfe5238ec808b",
//     "id": "63ad7f0d63ccfe5238f3d4e7"
// }

export interface DataDialogProps {
    title?: string;
    subtitle?: string;
    description?: string;
    tip?: string;
    data?: any[];
    // headers: Array<ColumnDefinitionType<any, keyof any>>;
}

export const DataDialog = (props: {
    props: DataDialogProps,
    isDialogOpen: boolean,
    onClose: () => void;
}) => {
    // hooks
    // const {t} = useTranslation(['translation', 'translation']);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // helpers
    const onCloseDialog = () => {
        props.onClose();
        onClose();
    };
    // renderers

    const renderTable = () => {
        return props.props.data && <WideviewDataTable data={props?.props?.data}></WideviewDataTable>
    };

    return (
        <Modal isOpen={props.isDialogOpen} onClose={onCloseDialog}>
            <ModalOverlay />
            <styles.CustomModalContent>
                <styles.Wrapper>
                    <styles.Header>
                        <styles.Title>
                            {props.props.title}
                            <styles.CloseIconHolder onClick={onCloseDialog}>
                                <CloseModalIcon  />
                            </styles.CloseIconHolder>
                        </styles.Title>
                        <styles.SubTitle>
                            {props.props.subtitle}
                        </styles.SubTitle>
                    </styles.Header>
                    <styles.Content>
                        {renderTable()}
                    </styles.Content>
                    {/*<styles.TipSection>*/}
                    {/*    {!!props?.props?.tip ? <><IconMoreInfo />&nbsp;{props?.props?.tip}</> : ""}*/}
                    {/*</styles.TipSection>*/}
                </styles.Wrapper>
            </styles.CustomModalContent>
        </Modal>
    );
};

import React, {useEffect, useState} from "react";
import {styles} from "./styles";
import {SearchInput} from "../../../components/search-input/SearchInput";
import {Box, Flex, Spinner, useDisclosure} from "@chakra-ui/react";

import {useDispatch, useSelector} from "react-redux";
import {clearOperation, fetchPoData, operationsStateSelector,} from "../../../store/operation";
import {organizationSelector} from "@store";
import {PurchaseOrder,} from "../../../services/operations/operation.service";
import ManufacturingTable, {ManufacturingStatus} from "./manufacturing-table/ManufacturingTable";
import {MoreInfoButton} from "../../../components/more-info-button/MoreInfoButton";
import {DownloadButton, Field} from "../../../components/download-button/DownloadButton";
import {dateRangeSelector} from "../../../store/ui";
import {formatIsoDate} from "../../../utils/date-format/dateFormat";
import OperationKpis from "../operation-kpis/OperationKpis";
import {KpisEnum} from "../../../models/kpi-settings";

export interface EditData {
  poId: string;
  issueDate: Date,
  supplier:string;
  units: number;
  totalCost: number;
  projectNumber: string;
  id:string;
}

const ManufacturingSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isQueryActive, setIsQueryActive] = useState<boolean>(false)
  const [tableDataToRender, setTableDataToRender] = useState<PurchaseOrder[]>([]);
  const [rowLoader, setRowLoader] = useState<{
    rowId?: string;
    loading?: boolean;
  }>();
  const operations = useSelector(operationsStateSelector);
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const dates = useSelector(dateRangeSelector);

  useEffect(() => {
    dispatch(clearOperation());

    !!organization?.id && !!dates.endDate && !!dates?.startDate && dispatch(fetchPoData({organizationId: organization?.id, query: searchQuery, fromDate: new Date(dates.startDate).toISOString(),
      toDate: new Date(dates.endDate).toISOString(),}));
  }, [organization?.id, searchQuery, dates.endDate, dates?.startDate]);

  useEffect(() => {
    setRowLoader(undefined);
  }, [isOpen]);

  useEffect(() => {
    if (!!operations?.data?.manufacturingData) {
      const dataToRender = operations?.data?.manufacturingData?.map((tableRow) => {
        if(tableRow?.unitsAmount !== undefined && tableRow?.totalDelivered !== undefined && !isNaN(tableRow?.unitsAmount - tableRow?.totalDelivered)){
          return {...tableRow, remainingUnits: tableRow?.unitsAmount - tableRow?.totalDelivered}
        }else {
          return {...tableRow, remainingUnits: "-"}
        }

      })
      setTableDataToRender(dataToRender);
    }
  }, [operations?.data?.manufacturingData]);

  const handleQueryChange = (e: string) => {
    setIsQueryActive(e.length > 0);
    setSearchQuery(e);
  };

  const getStatusNameFromEnum = (totalDelivered?: any, unitsAmount?:any) => {
    if(totalDelivered === 0){
      return "Pending"
    }
    if(totalDelivered >= unitsAmount){
      return "Completed"
    }
    if(totalDelivered > 0 && unitsAmount > 0){
      return "In Progress"
    }else {
      return ""
    }

  }


  const fields : Field[] = [
    {label: "PO's ID", value: "purchaseOrderId"},
    {label: "Issue Date", value: "issueDate", isoFormat: true},
    {label: "Supplier", value: "supplier"},
    {label: "Ordered Units", value: "unitsAmount"},
    {label: "Total Cost", value: "totalCost"},
    {label: "Paid To Date", value: "paid"},
    {label: "Status", value: "status"},
    {label: "Project Number", value: "projectNumber"},
    {label: "Delivered", value: "totalDelivered"},
    {label: "Remaining Units", value: "remainingUnits"},
  ]

  const header = () => {
    return (
      <styles.HeaderWrapper>
        <Flex>
          <styles.Title>Manufacturing Overview
            <div style={{alignSelf: 'start'}}><MoreInfoButton onClick={() => {return}}/></div>
          </styles.Title>
          <Box w={6} />
          <SearchInput
            value={searchQuery}
            placeholder={"SEARCH_BY_NAME_ID_SKU_ETC"}
            onChange={handleQueryChange}
            onSearch={(e) => {}}
          />
        </Flex>
        {/*<styles.CreateNewPoButton onClick={openModal}>*/}
        {/*  {newPoIcon("white")}Create New P.O*/}
        {/*</styles.CreateNewPoButton>*/}
        {<DownloadButton fields={fields} fileName={"manufacturing_overview"} data={tableDataToRender?.map((item) => {
          return {...item, status: getStatusNameFromEnum(item?.totalDelivered, item?.unitsAmount)}
        })} onClick={() => {}} />}
      </styles.HeaderWrapper>
    );
  };

  const table = (noData?: boolean) => {
    if (noData /*|| tableDataToRender.length === 0*/) {
      return (
        <ManufacturingTable
          rowLoader={rowLoader}
          setRowLoader={setRowLoader}
          tableData={[]}
          noData={true}
          isQueryActive={isQueryActive}
        />
      );
    }
    return (
      <>
        {
          <ManufacturingTable
            rowLoader={rowLoader}
            setRowLoader={setRowLoader}
            tableData={tableDataToRender}
            isQueryActive={isQueryActive}
          />
        }
      </>
    );
  };


  return (
    <styles.Wrapper>
     {/* <OperationKpis kpisToDisplay={[KpisEnum.REMAINING_COST]}/>*/}
      {header()}

      {!!operations?.data?.manufacturingData ? (
        <>
          {table()}
        </>
      ) : (
        <>
          {/*{table(true)}
          <Footer />*/}
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        </>
      )}
    </styles.Wrapper>
  );
};
export default ManufacturingSection;

/* eslint-disable jsx-a11y/anchor-is-valid */
import onboardingStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {authenticate, createOrganization, organizationSelector, organizationStateSelector, userSelector} from "@store";
import * as yup from "yup";
import { Organization } from "@models";
import { useNavigate } from "react-router";
import { SelectMenu } from "../../components/select-menu/SelectMenu";
import { timezones } from "utils/timezones/timezones";
import { SelectMenuFlag } from "components/select-menu-flags/SelectMenuFlags";

import { currencyOptions } from "utils/currency/currencyOptions";
import { push } from "redux-first-history";
import {Flex, Spinner} from "@chakra-ui/react";

// import {Navigate} from "react-router";

/* const schema = yup.object().shape({
    brandName: yup.string().required(),
    numberOfEmployees: yup.string(),
    industry: yup.string(),
    annualRevenue: yup.string(),
    currency: yup.string(),
    timeZone: yup.string(),
    displayOrUnify: yup.boolean(),
}); */

export const Onboarding = () => {

    // constants
    const numberOfEmployeesOptions: any[] = [
        { value: '1-5' },
        { value: '6-10' },
        { value: '26-50' },
        { value: '26-50' },
        { value: '50-100' },
        { value: '100+' },
    ];

    const industryOptions: any[] = [
        { value: 'Beauty' },
        { value: 'Clothing' },
        { value: 'Electronics' },
        { value: 'Furniture' },
        { value: 'Handcrafts' },
        { value: 'Jewelry' },
        { value: 'Painting' },
        { value: 'Photography' },
        { value: 'Restaurants' },
        { value: 'Groceries' },
        { value: 'Other food & Drink (Food Beverages)' },
        { value: 'Sports' },
        { value: 'Toys' },
        { value: 'Services' },
        { value: 'Virtual Services' },
    ];

    const annualRevenueOptions: any[] = [
        { value: 'Up to 200K$' },
        { value: '200K$ - 1M$' },
        { value: '1M$ - 5M$' },
        { value: '5M$ - 10M$' },
        { value: '10M$ - 20M$' },
        { value: '20M$ - 50M$' },
        { value: '50M$ +' },
    ];





    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const organization = useSelector(organizationSelector);
    const [currentStep, setCurrentStep] = useState(1);
    const currentUser = useSelector(userSelector);
    const currentOrganization = useSelector(organizationSelector);
    const organizationState = useSelector(organizationStateSelector);
    const [brandName, setBrandName] = useState<string>("");
    const [numberOfEployees, setNumberOfEployees] = useState(numberOfEmployeesOptions[0]);
    const [industry, setIndustry] = useState(industryOptions[0]);
    const [annualRevenue, setAnnualRevenue] = useState(annualRevenueOptions[0]);
    const [currency, setCurrency] = useState<any>(currencyOptions[0]);
    const [timezone, setTimezone] = useState<any>(timezones[0]);
    const [timezonesArray, setTimezonesArray] = useState<any>();
    const [loader, setLoader] = useState(true);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fixedArray = timezones.map((item) => { return { value: item.text }; });
        setTimezonesArray(fixedArray);

    }, []);

    // helpers
    const nextStep = ($event: any) => {
        $event?.preventDefault();
        if (currentStep === 1) {
            setCurrentStep(2);
        }
    };

    const handleOnChange = (e: any) => {
        setBrandName(e.target.value);
    };

    const refreshUser = () => {
        dispatch(authenticate({ token: null, organizationIdFromStore: currentOrganization?.id }));
        navigate('/admin/panel');
    };

    useEffect(() => {
        setLoader(true);
        if (!!organization) {
            if (!!organization?.id) {
                dispatch(push("/auth"));
            }

        }
        else {
            setTimeout(() => setLoader(false), 1000);
        }
    }, [dispatch, organization, organization?.id]);

    /* const submit = (data: any) => {
        const organization = {
            name: data?.brandName
        } as Organization;
        dispatch(createOrganization({
            organization: organization,
            onSuccess: refreshUser
        }));

    }; */

    const skip = () => {
    };

    const back = () => {
        setCurrentStep(1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        /*  console.log('tesss');
         const organization = {
             name: brandName
         } as Organization;
         if (currentStep === 2 && !!brandName) {
             dispatch(createOrganization({
                 organization: organization,
                 onSuccess: refreshUser
             }));
         } */
    };

    const handleClick = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        const foundTimeZoneCode = timezones?.find((tz) => tz?.text === timezone)?.code

        const organization = {
            currency: currency?.enum,
            name: brandName,
            numberOfEmployees: numberOfEployees?.value,
            industry: industry?.value,
            annualRevenue: annualRevenue?.value,
            timezone: foundTimeZoneCode
        } as Organization;
        if (currentStep === 2 && !!brandName) {
            dispatch(createOrganization({
                organization: organization,
                onSuccess: refreshUser
            }));
        }
    };

    // renderers


    const renderHeader = () => {
        return (
            <onboardingStyles.Header>
                <onboardingStyles.Step>
                    {`${t('STEP', { ns: 'translation' })?.toUpperCase()} ${currentStep}/2`}
                </onboardingStyles.Step>
                <onboardingStyles.HelloUser>
                    {`${t('HELLO', { ns: 'translation' })} ${currentUser?.email}`}
                </onboardingStyles.HelloUser>
                <onboardingStyles.Welcome>
                    {`${t('WELCOME', { ns: 'translation' })}!`}
                </onboardingStyles.Welcome>
                <onboardingStyles.Description>
                    {`${t('LET_START_BY_CUSTOMIZING_AND_COLLECTING_ALL_YOUR_DATA_FOR_YOU', { ns: 'translation' })}`}
                </onboardingStyles.Description>
            </onboardingStyles.Header>
        );
    };

    const renderFormStepOne = () => {
        return (
            <onboardingStyles.StepWrapper>
                {/*BRAND_NAME*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('BRAND_NAME', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <onboardingStyles.FormFieldInput required value={brandName} onChange={(e: any) => { handleOnChange(e); }} placeholder={''}>
                    </onboardingStyles.FormFieldInput>
                </onboardingStyles.FormFieldContainer>

                {/*NUMBER_OF_EMPLOYEES*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('NUMBER_OF_EMPLOYEES', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <SelectMenu
                        key={1}
                        setValueCallback={(value: any) => setNumberOfEployees(value)}
                        options={numberOfEmployeesOptions}
                        bold={true}
                    />

                </onboardingStyles.FormFieldContainer>

                {/*INDUSTRY*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('INDUSTRY', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <h3 style={{ display: "inline" }}>
                    </h3>
                    <SelectMenu
                        key={2}
                        setValueCallback={(value: any) => setIndustry(value)}
                        options={industryOptions}
                        bold={true}
                    />
                </onboardingStyles.FormFieldContainer>
            </onboardingStyles.StepWrapper>
        );
    };

    const renderFormStepTwo = () => {
        return (
            <onboardingStyles.StepWrapper>
                {/*ANNUAL_REVENUE*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('ANNUAL_REVENUE', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <SelectMenu
                        key={3}
                        setValueCallback={(value: any) => setAnnualRevenue(value)}
                        options={annualRevenueOptions}
                        bold={true} />
                </onboardingStyles.FormFieldContainer>

                {/*CURRENCY*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('CURRENCY', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <SelectMenuFlag setItem={setCurrency} data={currencyOptions} />
                    {/*  <SelectMenu
                        key={4}
                        setValueCallback={(value: any) => setCurrency(value)}
                        options={currencyOptions.map(option => {
                            return {
                                value: option.value,
                                label: `${option?.value.flag} ${option?.value.sign} ${option?.value.code} ${option?.value.name}`
                            };
                        })}
                        bold={true}
                        renderFlags={true}
                    /> */}
                </onboardingStyles.FormFieldContainer>

                {/*TIME_ZONE*/}
                <onboardingStyles.FormFieldContainer>
                    <onboardingStyles.FormFieldTitle>
                        {t('TIME_ZONE', { ns: 'translation' })}
                    </onboardingStyles.FormFieldTitle>
                    <SelectMenu
                        key={5}
                        setValueCallback={(value: any) => setTimezone(value)}
                        options={timezonesArray}
                        bold={true} />
                </onboardingStyles.FormFieldContainer>

                {/*DISPLAY*/}
                {/* <onboardingStyles.FormFieldContainer>

                    <onboardingStyles.FormFieldCheckbox size={'lg'}
                        placeholder={''}>
                        {`${t('DISPLAY', { ns: 'translation' })}/${t('UNIFY_ALL_TIME_ZONES', { ns: 'translation' })}`}
                    </onboardingStyles.FormFieldCheckbox>
                </onboardingStyles.FormFieldContainer> */}
            </onboardingStyles.StepWrapper>
        );
    };

    const renderBottom = () => {
        return (
            <Flex flexDirection={"column"}>
            <onboardingStyles.Bottom>
                {
                    currentStep === 2 ? <onboardingStyles.SkipButton onClick={() => back()}>
                        {t('BACK', { ns: 'translation' })}
                    </onboardingStyles.SkipButton> : <></>
                }
                <>
                    {currentStep === 2 ?
                        <>
                        <onboardingStyles.NextButton type="submit" onClick={(e: any) => handleClick(e)}>
                        {t('CREATE', { ns: 'translation' })}
                    </onboardingStyles.NextButton>
                            </>
                        :
                        !!brandName ?
                            (<onboardingStyles.NextButton onClick={($event: any) => nextStep($event)}>
                                {t('NEXT', { ns: 'translation' })}
                            </onboardingStyles.NextButton>
                            ) :
                            (<onboardingStyles.NextButton notactive={true}/* onClick={($event: any) => nextStep($event)} */ >
                                {t('NEXT', { ns: 'translation' })}
                            </onboardingStyles.NextButton>
                            )
                    }
                </>
            </onboardingStyles.Bottom >
        {currentStep ===  2 &&   <Flex color={"red"} alignSelf={"flex-end"}>{organizationState?.error}</Flex>  }
    </Flex>
        );
    };

    const renderContent = () => {
        return <onboardingStyles.FormWrapper onSubmit={(e) => { handleSubmit(e); }}>
            {currentStep === 1 ? renderFormStepOne() : renderFormStepTwo()}
            {renderBottom()}
        </onboardingStyles.FormWrapper>;
    };

    const renderCard = () => {
        return <onboardingStyles.Card>
            {renderHeader()}
            {renderContent()}
        </onboardingStyles.Card>;
    };

    return (

        loader ? <Spinner /> : <onboardingStyles.Wrapper img={process.env.PUBLIC_URL + '/assets/onboarding-background.png'}>
            <onboardingStyles.Content>
                {renderCard()}

            </onboardingStyles.Content>
        </onboardingStyles.Wrapper>


    );
};

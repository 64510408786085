import { MoreInfoButton } from 'components/more-info-button/MoreInfoButton';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ComparisonTable } from 'store/website';
import { PerformanceComparisonChart } from './PerformanceComparisonChart/PerformanceComparisonChart';
import { PerformanceComparisonTable } from './PerformanceComparisonTable/PerformanceComparisonTable';
import { styles } from './styles';
import {Box} from "@chakra-ui/react";
import {PerformanceComparisonNewTable} from "./PerformanceComparisonTable/PerformanceComparisonNewTable";
import websiteJsonData from "../../features/website/website-data.json";
import {sumData} from "../../utils/get-months/getLastYear";


interface Props {
    data: ComparisonTable[];
    displayMockData: boolean;
}

export const PerformanceComparisonWebsite = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [filteredData, setFilteredData] = useState<ComparisonTable[]>([]);
    const [newTableData, setNewTableData] = useState<any[]>([]);
    const [newChartData, setNewChartData] = useState<any[]>([]);

    function groupBy(array: any[], key1:string, key2:string, key3:string) {
        return array.reduce((objectsByKeyValue, obj) => {
            const value1 = obj[key1];
            const value2 = obj[key2];
            const value3 = obj[key3];
            objectsByKeyValue[value1] = objectsByKeyValue[value1] || {};
            objectsByKeyValue[value1][value2] = objectsByKeyValue[value1][value2] || {};
            objectsByKeyValue[value1][value2][value3] = (objectsByKeyValue[value1][value2][value3] || []).concat(obj);
            return objectsByKeyValue;
        }, {});
    }

    useEffect(() => {
        const dataArray:any[] = [];
        // @ts-ignore
        const data = groupBy(websiteJsonData, "traffic_source", "source", "medium");
        Object.keys(data)?.forEach((key) => {
            const trafficSource = key;
            Object.keys(data[key])?.forEach((sourceKey) => {
                const source = sourceKey;
                Object.keys(data[key][sourceKey])?.forEach((mediumKey) => {
                    const currentMediumArray = data[key][sourceKey][mediumKey];
                    if(Array.isArray(currentMediumArray)){
                        let totalTransactions = 0;
                        let totalSessions = 0;
                        const uniqueUserIds = currentMediumArray
                            .map(obj => obj.user_pseudu_id)
                            .filter((value, index, self) => self.indexOf(value) === index);
                        const fixedArray = currentMediumArray?.map((item) => {
                            if(item?.event_name === "purchase"){
                                totalTransactions++
                            }
                            if(item?.event_name === "session_start"){
                                totalSessions++
                            }
                            if(item?.revenue !== undefined && typeof item?.revenue === "string"){
                                return {...item, revenue: parseFloat(item?.revenue)}
                            }else {
                                return item
                            }
                        })
                        const summedData = sumData(fixedArray, ["purchase_revenue_in_usd", "purchase_revenue","region","transaction_id", "unique_items","total_item_quantity","user_pseudo_id","event_date", "continent", "event_name", "language", "medium", "traffic_source", "city", "country", "currency", "operating_system", "platform"]);
                        dataArray.push({...summedData, medium: mediumKey, sessions: totalSessions, transactions: totalTransactions,
                            traffic_source: trafficSource === "undefined" ? "Unknown" : trafficSource, source: source, users: uniqueUserIds?.length, cr: (totalTransactions / totalSessions) * 100, aov: (totalTransactions / summedData?.revenue) * 100
                        })
                    }
                })
            })
        })
        const chartData = dataArray?.map((item) => {
            return {...item, type: item?.source}
        })
        setNewChartData(chartData);
        setNewTableData(dataArray);

    }, [])


    useEffect(() => {
        if (!!props.data) {
            setFilteredData(props.data);
        }
    }, [props.data]);

    return ((
        <styles.WrapperTable>
            <styles.Card>
                <styles.Table>
                    <styles.Title>
                        {t('PERFORMANCE_COMPARISON_TABLE', { ns: 'translation' })}
                        <MoreInfoButton onClick={() => { }} />
                    </styles.Title>
                        {props?.displayMockData ? props.data ? <PerformanceComparisonTable
                            setFilteredData={setFilteredData}
                            selectedTab={selectedTab}
                            setSelectedTab={(selectedTab: number) => setSelectedTab(selectedTab)}
                            data={props.data} /> : null :
                    !!newTableData ? <PerformanceComparisonNewTable setFilteredData={setFilteredData}
                                                    headers={["Traffic Source", "Source", "Medium", "Sessions", "Users", "Transactions", "Revenue", "CR", "AOV"]}
                                                    data={newTableData} selectedTab={selectedTab}
                                                    setSelectedTab={(selectedTab: number) => setSelectedTab(selectedTab)}/> : null}

                </styles.Table>
                <styles.Chart>
                    { props?.displayMockData ?
                        !!filteredData ? <PerformanceComparisonChart data={filteredData} selectedTab={selectedTab} /> : null
                    :
                        !!newChartData ? <PerformanceComparisonChart data={newChartData} selectedTab={selectedTab} /> : null
                    }
                </styles.Chart>

            </styles.Card>
        </styles.WrapperTable>
    ));
};

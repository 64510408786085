import { createAsyncThunk } from "@reduxjs/toolkit";
import { effortsService } from "services/efforts/efforts.service";
import { Effort, EffortsState } from "./state";

interface effort {
  kpiMetric: string;
  description: string;
  assignee: string;
  startDate: string;
  endDate: string;
}
interface EffortUpdate {
  kpiMetric: string;
  description: string;
  assignee: string;
  startDate: string;
  endDate: string;
  id: string;
}

export const fetchEfforts = createAsyncThunk<any, { organizationId: string }>(
  "efforts/fetchEffortsData",
  async ({ organizationId }: { organizationId: string }, thunkAPI) => {
    const response = await effortsService.fetchEfforts({
      organizationId: organizationId,
    });
    return response.data;
  }
);

interface CreateEffort {
  data: effort;
  organizationId: string;
}
export const createEfforts = createAsyncThunk<any, CreateEffort>(
  "efforts/createEffortsData",
  async ({ data, organizationId }: CreateEffort, thunkAPI) => {
    const response = await effortsService.createEffort({
      data,
      organizationId,
    });
    /* thunkAPI.dispatch(fetchEfforts()); */
    return response.data;
  }
);
interface DeleteEffort {
  id: string;
  organizationId: string;
}
export const deleteEffort = createAsyncThunk<any, DeleteEffort>(
  "efforts/deleteEffortsData",
  async ({ id, organizationId }: DeleteEffort, thunkAPI) => {
    const response = await effortsService.deleteEffort({ id, organizationId });
    /* thunkAPI.dispatch(fetchEfforts()); */
    return response.data;
  }
);

interface EditData {
  data: EffortUpdate;
  array: any[];
  organizationId: string;
}

export const editEffort = createAsyncThunk<any, EditData>(
  "efforts/editEffortsData",
  async ({ data, array, organizationId }: EditData, thunkAPI) => {
    const response = await effortsService.editEffort({
      data,
      array,
      organizationId,
    });
    /* thunkAPI.dispatch(fetchEfforts()); */
    return response.data;
  }
);

import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import styles from "./styles";
import {
    Cell,
    Column, FooterProps, HeaderGroup,
    HeaderProps,
    Renderer,
    Row,
    useExpanded,
    useRowSelect, useSortBy,
    useTable,
} from "react-table";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Flex, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  Attribute,
  kpiStateSelector,
  TableMetaDataRows,
} from "../../store/kpis";
import { useCurrencySign } from "../../utils/custom-hooks/useCurrencySign";
import { selectedChannelsSelector } from "../../store/ui";
import { channelsStateSelector } from "../../store/channels";
import {fieldsSelector} from "../../store/pnl";

interface Props {
  data?: any[];
  allExpendedRows: { [key: string]: boolean }; // DO NOT REMOVE
  setAllExpendedRows: any;  // DO NOT REMOVE
  tableRef: React.RefObject<any>;  // DO NOT REMOVE
    setSelectedChannels: Dispatch<SetStateAction<any[]>>;
}


const CpoTable = (props: Props) => {

    const tableConfig = useSelector(kpiStateSelector)?.cpoTableConfig?.data;
    const currentCurrency = useCurrencySign();
    const fields = useSelector(fieldsSelector);

    const getHeaders = () => {
        if (!!props?.data && props?.data?.length > 0) {
            const headersArray = [...Object.keys(props?.data[0]), 'shareOfTotalRevenue',
                'CPO', 'shareOfTotalDigitalSpend', 'FacebookAdsCostShare',
                'GoogleAdsCostShare', 'AmazonAdsCostShare'];
            const order = ['name', 'orders','shareOfTotalRevenue',
                'digitalMarketingSpend', 'shareOfTotalDigitalSpend','CPO',
                'FacebookAdsCost', 'GoogleAdsCost', 'AmazonAdsCost',
                  'FacebookAdsCostShare',
                'GoogleAdsCostShare', 'AmazonAdsCostShare'];
            const displayAmazonAdsCostShare = fields?.some((field) => field?.name === 'AmazonAdsCost' && !field?.isEmpty);
            const displayGoogleAdsCostShare = fields?.some((field) => field?.name === 'GoogleAdsCost' && !field?.isEmpty);
            const displayFacebookAdsCostShare = fields?.some((field) => field?.name === 'FacebookAdsCost' && !field?.isEmpty);
            if(displayFacebookAdsCostShare && !(order?.includes('FacebookAdsCostShare'))){
                order?.push('FacebookAdsCostShare')
            }
            if(displayGoogleAdsCostShare && !(order?.includes('GoogleAdsCostShare'))){
                order?.push('GoogleAdsCostShare')
            }

            if(displayAmazonAdsCostShare && !(order?.includes('AmazonAdsCostShare'))){
                order?.push('AmazonAdsCostShare')
            }
            return order;
        } else {
            return [];
        }
    }
    const selectedChannels = useSelector(selectedChannelsSelector);
    const headers = getHeaders()
    const allChannels = useSelector(channelsStateSelector)?.channels;
    const [selectedRows, setSelectedRows] = useState([] as Row<any>[]);
    const [totalRevenueByKey, setTotalRevenueByKey] = useState(0);
    const [totalDigitalSpend, setTotalDigitalSpend] = useState(0);

    useEffect(() => {
        let total = 0;
        const allSelectedChannels = selectedRows?.map((item) => item?.original?.callbackId ?? item?.original?.name);
        const filteredDataByChannel = allSelectedChannels?.length === 0 ? props?.data :
            props?.data?.filter((item) => allSelectedChannels?.includes(item?.name))
            total = getSumByKey('orders', filteredDataByChannel);

        const sumOfDigitalSpend = getSumByKey('digitalMarketingSpend', filteredDataByChannel);
        setTotalDigitalSpend(sumOfDigitalSpend)
        setTotalRevenueByKey(total);
    }, [props?.data, selectedRows, allChannels]);

    const getSumByKey = (key: string, data?: any[]) => {
        const total = data?.reduce((acc, obj) => {
            if(key in obj){
                return acc + obj[key];
            }
            return acc
        }, 0);
        if(isNaN(total)){
            return 0
        }
        return total
    }


    useEffect(() => {
        console.log(props)

        if(!!props?.data){
            const fixedDataInRowFormat:any = props?.data?.map((item, i) => {
                return {id: i?.toString(), original: {callbackId: item?.name}, index: i?.toString()}
            })
            console.log(selectedChannels)
            if(selectedChannels?.length !== allChannels?.length){
                const filteredData = fixedDataInRowFormat?.filter((item:any) => selectedChannels?.includes(item?.original?.callbackId));
                console.log(filteredData)
                if(!!props?.setSelectedChannels ){
                    props?.setSelectedChannels(filteredData)
                }
                setSelectedRows(filteredData)
            }else{
                setSelectedRows([])
                if(!!props?.setSelectedChannels){
                    props?.setSelectedChannels([])
                }
            }
        }
    }, [selectedChannels, props?.data, props?.setSelectedChannels, allChannels])

    const renderExpandIcon = (isRowExpanded: boolean) : JSX.Element  => {
        if(isRowExpanded){
            return <MdOutlineKeyboardArrowDown fontSize={20} />
        }
        else {
            return <MdOutlineKeyboardArrowRight fontSize={20}/>
        }
    }

    const renderExpandIconPlaceholder = () : JSX.Element  => {
            return <MdOutlineKeyboardArrowRight opacity={0} fontSize={20}/>
    }

    const onRowExpandClick = (row: Row) => {
        if (!!props?.allExpendedRows && row?.canExpand) {
            if ( row?.id in props?.allExpendedRows) {
                props?.setAllExpendedRows((prev: any) => {
                    return { ...prev, [row.id]: !prev[row?.id] };
                });
            } else {
                props?.setAllExpendedRows((prev: any) => {
                    return { ...prev, [row.id]: true };
                });
            }
        }
    };


    const renderExpandColumn = (header: string) => {
        let displayHeader = header;

      return {
        id: "expander",
        Header: displayHeader,
        accessor: header,
        Cell: ({
          row,
          cell,
          getToggleAllRowsExpandedProps,
          isAllRowsExpanded,
        }: {
          row: any;
          cell: Cell;
          getToggleAllRowsExpandedProps: any;
          isAllRowsExpanded: boolean;
        }) => {
          return (
            <Flex
              marginLeft={row?.depth > 0 ? row?.depth * 20 + "px" : 0}
              alignItems={"center"}
              {...row.getToggleRowExpandedProps({title: undefined})}
            >
              <span>
                {row?.canExpand
                  ? renderExpandIcon(row?.isExpanded)
                  : renderExpandIconPlaceholder()}
              </span>
              <span>{cell?.value}</span>
            </Flex>
          );
        },
      };
    };

    function reorderHeaders(
      headers: {
        Header: string;
        accessor: string;
        id?: string;
      }[]
    ): {
        Header: string;
        accessor: string;
        id?: string;
    }[] {
      const expanderIndex = headers.findIndex(
        (header) => header?.id === "expander"
      );
      if (expanderIndex !== -1) {
        const expanderHeader = headers.splice(expanderIndex, 1)[0];
        headers.unshift(expanderHeader);
      }
      return headers;
    }

    const createColumns = () : Column<any>[] => {
        // @ts-ignore
        const headersToReturn =  headers.map((header: string) => {
            let displayHeader = header
                if(header === 'subRows'){
                    return
                }
                // expander row
                if(header === 'name'){
                    return renderExpandColumn(header)
                }

              return {Header: displayHeader, accessor: header, Footer: (cell: React.PropsWithChildren<FooterProps<{}>>) => {
                      return cell?.value ?? ''
                  }}
        }).filter((header) => !!header)


        // @ts-ignore
        return reorderHeaders(headersToReturn)
    }

    const sortColumnsByGrossRevenue = (rowData : {[key: string] : string}) : {[key: string] : string} => {
        const { name, total, ...rest } = rowData; // Destructure the object, excluding "name" and "total" keys
        const sortedColumns = Object.entries(rest).sort(([, value1], [, value2]) => {
            return Number(value2) - Number(value1); // Sort in descending order of numeric values
        });
        const reorderedObject = {
            name,
            ...Object.fromEntries(sortedColumns),
        };
        return reorderedObject;
    }

    const normalizeData = (data?: any[]) => {
        return data?.map((row: any) => {
            const newRow: any = {};

            for (let key in row) {
                if (row.hasOwnProperty(key)) {
                    let value = row[key];

                    if(Array.isArray(value)){
                       value = normalizeData(value)
                    }

                    newRow[key] = value;

                }
            }

            let revenueKey = 'orders';

            const rowDigitalSpend = 'digitalMarketingSpend' in newRow ? newRow['digitalMarketingSpend']  : 0;

            newRow['CPO'] =  !!newRow['digitalMarketingSpend'] && !!newRow[revenueKey] ?
                ( newRow['digitalMarketingSpend'] / newRow[revenueKey]) : 0 ;
            newRow['shareOfTotalRevenue'] = revenueKey in newRow ? (newRow[revenueKey] / totalRevenueByKey) * 100 : 0;
            newRow['shareOfTotalDigitalSpend'] =  !!totalDigitalSpend && !!rowDigitalSpend ? (rowDigitalSpend / totalDigitalSpend) * 100 : 0;
            let allSelectedChannels = selectedRows?.map((item) => {
                return item?.original?.name ?? item?.original?.callbackId
            })
            if(allSelectedChannels?.length === 0){
                allSelectedChannels = allChannels?.map((channel) =>  channel?.id)
            }
            if(!(allSelectedChannels?.includes(newRow['name']) && allSelectedChannels?.length !== 0)){
                newRow['shareOfTotalRevenue'] = 0;
                newRow['shareOfTotalDigitalSpend'] = 0;
            }
            if('FacebookAdsCost' in newRow){
                if(!!newRow['FacebookAdsCost'] && !!rowDigitalSpend){
                    newRow['FacebookAdsCostShare'] = (newRow['FacebookAdsCost'] / rowDigitalSpend) * 100
                }
                else {
                    newRow['FacebookAdsCostShare'] = 0
                }
            }
            if('GoogleAdsCost' in newRow){
                if(!!newRow['GoogleAdsCost'] && !!rowDigitalSpend){
                    newRow['GoogleAdsCostShare'] = (newRow['GoogleAdsCost'] / rowDigitalSpend) * 100
                }
                else {
                    newRow['GoogleAdsCostShare'] = 0
                }
            }
            if('AmazonAdsCost' in newRow){
                if(!!newRow['AmazonAdsCost'] && !!rowDigitalSpend){
                    newRow['AmazonAdsCostShare'] = (newRow['AmazonAdsCost'] / rowDigitalSpend) * 100
                }
                else {
                    newRow['AmazonAdsCostShare'] = 0
                }
            }
            for (let key in row) {
                if (row.hasOwnProperty(key)) {
                    let value = row[key];
                    /*if(typeof value === 'number'){
                        value = value?.toLocaleString('en-us', {maximumFractionDigits: 2})
                    }*/
                    newRow[key] = value;
                }
            }

            for (let key in newRow) {
                    let value = newRow[key];
                    /*if(typeof value === 'number'){
                        value = value?.toLocaleString('en-us', {maximumFractionDigits: 2})
                    }*/

                    newRow[key] = value;
            }

            return newRow;
        });
    };

    const columns = useMemo(() => createColumns() , [props?.data, totalRevenueByKey, totalDigitalSpend]);
    const data = useMemo(() => normalizeData(props?.data), [props?.data, totalRevenueByKey, totalDigitalSpend, selectedRows]);


    const handleClickOnCell = (cell : Cell) => {

    }

    const renderTableCell = (header?: Renderer<HeaderProps<object>> | undefined, opacity?: number)  => {
            return header
    }

    const RenderTable = ({data}: {data: any[]}) => {

        const handleSelectRow = (row: Row<any>) => {
            row.toggleRowSelected();
            const isRowSelected = selectedRows.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRows([...(selectedRows.filter(selectedRow => selectedRow.id !== row.id))]);
                const newSelected = selectedRows.filter(selectedRow => selectedRow.id !== row.id);
                const fixedSelectedForFilters = newSelected?.map((row: Row<{name?: string, callbackId?: string}>) => {
                    return {original: {callbackId: row?.original?.name ?? row?.original?.callbackId}}
                })
                props?.setSelectedChannels(fixedSelectedForFilters)
            } else {
                const newSelected = [...selectedRows];
                newSelected.push(row);
                setSelectedRows(newSelected);
                const fixedSelectedForFilters = newSelected?.map((row: Row<{name?: string, callbackId?: string}>) => {
                    return {original: {callbackId: row?.original?.name ?? row?.original?.callbackId}}
                })
                props?.setSelectedChannels(fixedSelectedForFilters)
            }
        };

        let tableRowsConfig : TableMetaDataRows[] | undefined = [];
        if(!!tableConfig && tableConfig?.length > 0){
            tableRowsConfig = tableConfig[0]?.rows;
        }

        const allRowsConfig = tableRowsConfig?.find((item) => item.fieldName === 'AllRows')?.attribute;
        const boldTotal = allRowsConfig?.find((item) => item.key === 'boldTotal')?.value === 'true'

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
            initialState: { expanded: props?.allExpendedRows,
                sortBy: [
                    {
                        id: 'grossRevenue',
                        desc: true
                    }
                ]},
        },
            useSortBy,
            useExpanded,
            useRowSelect,
        )

        const tableFooter = (footerGroups: HeaderGroup[], rows: Row[]) => {

            return (
                <tfoot>
                {footerGroups.map((group) => (
                    <styles.StyledTrFooter {...group.getFooterGroupProps()}>
                        {group.headers.map((column) => {
                            let columnAttributes :  Attribute[] | undefined = [];
                            const columnName = column?.Header;
                            const foundConfigForColumn = tableRowsConfig?.find((item) => item?.fieldName === columnName);
                            if(!!foundConfigForColumn){
                                columnAttributes = foundConfigForColumn?.attribute;
                            }
                            const revenueColumns = ['orders'];
                            const allSelectedChannels = selectedRows?.map((item) => {
                                return item?.original?.name ?? item?.original?.callbackId
                            })
                            const fixedData = rows?.map((row) => row?.original)?.filter((item: any) => {
                                if(allSelectedChannels?.length > 0){
                                    return allSelectedChannels?.includes(item?.name)
                                }else {
                                    return item
                                }
                            })


                            if(columnName === 'name'){
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    <styles.GrandTotalDiv>
                                        Total
                                    </styles.GrandTotalDiv>
                                </styles.FooterTd>)
                            }
                            // @ts-ignore
                            if(!!columnName && revenueColumns?.includes(columnName)){
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {totalRevenueByKey?.toLocaleString('en-us', {maximumFractionDigits: 2})}
                                </styles.FooterTd>)
                            }
                            if(columnName === 'shareOfTotalRevenue' ){
                                const sumShareOfTotal = getSumByKey('shareOfTotalRevenue', fixedData)
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {Math.round(sumShareOfTotal) + '%'}
                                </styles.FooterTd>)
                            }
                            if(columnName ==='shareOfTotalDigitalSpend'){
                                const sumShareOfTotal = getSumByKey('shareOfTotalDigitalSpend', fixedData)
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {Math.round(sumShareOfTotal) + '%'}
                                </styles.FooterTd>)
                            }
                            if(columnName ==='digitalMarketingSpend'){
                                const sumShareOfTotal = getSumByKey('digitalMarketingSpend', fixedData)
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {currentCurrency}{sumShareOfTotal?.toLocaleString('en-us', {maximumFractionDigits: 2})}
                                </styles.FooterTd>)
                            }
                            if(columnName ==='CPO'){
                                const sumShareOfTotalMarketing = getSumByKey('digitalMarketingSpend', fixedData);
                                const totalRoas = !!sumShareOfTotalMarketing && !!totalRevenueByKey ?  sumShareOfTotalMarketing / totalRevenueByKey : 0
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {totalRoas?.toLocaleString('en-us', {maximumFractionDigits: 2})}
                                </styles.FooterTd>)
                            }
                            if(columnName ==='FacebookAdsCostShare'){
                                const sumOfFacebookAdsCost = getSumByKey('FacebookAdsCost', fixedData);
                                const sumShareOfTotalMarketing = getSumByKey('digitalMarketingSpend', fixedData);
                                const shareOfFacebook = !!sumShareOfTotalMarketing && !!sumOfFacebookAdsCost ? sumOfFacebookAdsCost / sumShareOfTotalMarketing * 100 : 0
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {shareOfFacebook?.toLocaleString('en-us', {maximumFractionDigits: 2})}%
                                </styles.FooterTd>)
                            }
                            if(columnName ==='GoogleAdsCostShare'){
                                const sumOfGoogleAdsCost = getSumByKey('GoogleAdsCost', fixedData);
                                const sumShareOfTotalMarketing = getSumByKey('digitalMarketingSpend', fixedData);
                                const shareOfGoogle = !!sumShareOfTotalMarketing && !!sumOfGoogleAdsCost ? sumOfGoogleAdsCost / sumShareOfTotalMarketing * 100 : 0
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {shareOfGoogle?.toLocaleString('en-us', {maximumFractionDigits: 2})}%
                                </styles.FooterTd>)
                            }
                            if(columnName ==='AmazonAdsCostShare'){
                                const sumOfGoogleAdsCost = getSumByKey('AmazonAdsCost', fixedData);
                                const sumShareOfTotalMarketing = getSumByKey('digitalMarketingSpend', fixedData);
                                const shareOfGoogle =!!sumShareOfTotalMarketing && !!sumOfGoogleAdsCost ? sumOfGoogleAdsCost / sumShareOfTotalMarketing * 100 : 0
                                return (<styles.FooterTd  {...column.getFooterProps()}>
                                    {shareOfGoogle?.toLocaleString('en-us', {maximumFractionDigits: 2})}%
                                </styles.FooterTd>)
                            }


                            else {
                                return
                            }

                           /* return (
                                <styles.FooterTd  {...column.getFooterProps()}>

                                    {column.render("Footer")}
                                </styles.FooterTd>
                            );*/
                        })}
                    </styles.StyledTrFooter>
                ))}
                </tfoot>
            );
        };



        return (
          <styles.StyledTable ref={props.tableRef} {...getTableProps()} id='table'>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <styles.StyledTr selected={true} rowIndex={1} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {

                      let columnAttributes :  Attribute[] | undefined = [];
                      const columnName = column?.Header;
                      const foundConfigForColumn = tableRowsConfig?.find((item) => item?.fieldName === columnName);
                      if(!!foundConfigForColumn){
                          columnAttributes = foundConfigForColumn?.attribute;
                      }

                      const columnTooltip = columnAttributes?.find((attribute) => attribute?.key === 'tooltip')?.value;
                      const displayName = columnAttributes?.find((attribute) => attribute?.key === 'displayName')?.value;
                      let isDisplay = columnAttributes?.find((attribute) => attribute?.key === 'display')?.value !== 'false';
                      const backgroundColor = columnAttributes?.find((attribute) => attribute?.key === 'backgroundColor')?.value ?? 'white';

                      if(column.Header === 'name'){
                          return <styles.StyledThName  {...column.getHeaderProps()} />
                      }
                      // @ts-ignore
                    const maxWidthOfColumn = column?.Header?.length * 7 <= 250 ? column?.Header?.length * 7 : 250;
                   return (<styles.StyledTh backgroundColor={backgroundColor} isDisplay={isDisplay} minWidth={0} onClick={() => {
                   }}    {...column.getHeaderProps(column.getSortByToggleProps())}>
                       <Tooltip label={columnTooltip}>
                           {renderTableCell(displayName ?? column?.Header, 1)}
                       </Tooltip>
                    </styles.StyledTh>);
                  })}
                </styles.StyledTr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: Row<{name?: string}>, i) => {

                prepareRow(row);
                return (
                  <styles.StyledTr selected={selectedRows.length === 0 ||
                      selectedRows.some((item: { id: string; }) => item.id === row.id)}  rowIndex={i} {...row.getRowProps()} onClick={() => {
                      handleSelectRow(row)
                  }}>
                    {row.cells.map((cell) => {

                        let columnAttributes :  Attribute[] | undefined = [];
                        const columnName = cell?.column?.Header;
                        const foundConfigForColumn = tableRowsConfig?.find((item) => item?.fieldName === columnName);
                        if(!!foundConfigForColumn){
                            columnAttributes = foundConfigForColumn?.attribute;
                        }
                        let isDisplay = columnAttributes?.find((attribute) => attribute?.key === 'display')?.value !== 'false';
                        const displayCurrency = columnAttributes?.find((attribute) => attribute?.key === 'displayCurrency')?.value === 'true';
                        const displayPercentage = columnAttributes?.find((attribute) => attribute?.key === 'displayPercentage')?.value === 'true';
                        const backgroundColor = columnAttributes?.find((attribute) => attribute?.key === 'backgroundColor')?.value ?? 'white';

                        let isDisplayBoldText = false;
                        if(boldTotal && cell?.column?.Header === 'Total'){
                            isDisplayBoldText = true
                        }
                      if (cell.column.Header === "name") {
                          const channelDisplayName = allChannels?.find((channel) => channel?.id === cell?.value)?.displayName ?? cell?.value;
                        return (
                          <styles.StyledTdName onClick={() => {
                              onRowExpandClick(row)
                          }}  {...cell.getCellProps()}>
                                  <Flex>
                                      {channelDisplayName}
                                  </Flex>

                          </styles.StyledTdName>
                        );
                      }

                      let displayValue = cell?.value;
                      if(typeof (displayValue) === 'number'){
                          displayValue = displayValue?.toLocaleString('en-us', {maximumFractionDigits: 2})
                      }
                      return (
                        <styles.StyledTd backgroundColor={backgroundColor} isDisplay={isDisplay} boldText={isDisplayBoldText} onClick={() => {
                            handleClickOnCell(cell)
                        }} {...cell.getCellProps()}>
                            <Flex>
                                {displayCurrency && currentCurrency}{displayValue}{displayPercentage && '%'}
                            </Flex>
                        </styles.StyledTd>
                      );
                    })}
                  </styles.StyledTr>
                );
              })}
            </tbody>
            {tableFooter(footerGroups, rows)}
          </styles.StyledTable>
        );
    }


    return (
        // @ts-ignore
        <styles.TableHolder>
            {!!data && <RenderTable  data={data}/>}
        </styles.TableHolder>
    );
};

export default CpoTable;

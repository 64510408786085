import { MoreInfoButton } from 'components/more-info-button/MoreInfoButton'
import { MoreInfoDialog, MoreInfoDialogProps } from 'dialogs/more-info-dialog/MoreInfoDialog'
import React from 'react'


interface Props {
    moreInfoDialogProps: MoreInfoDialogProps;
    isMoreInfoDialogOpen: boolean;
    setIsMoreInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}



const MoreInformation = (props: Props) => {
    const openMoreInfoDialog = (isOpen: boolean) => props.setIsMoreInfoDialogOpen(isOpen)
    return (
        <>
            <MoreInfoButton onClick={() => openMoreInfoDialog(true)} />
            <MoreInfoDialog
                props={props.moreInfoDialogProps}
                isDialogOpen={props.isMoreInfoDialogOpen}
                onClose={() => openMoreInfoDialog(false)}
            />
        </>
    )
}

export default MoreInformation
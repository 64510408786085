import {MoreInfoButton} from "components/more-info-button/MoreInfoButton";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {AccountIntegrations} from "store/account/state";
import {Popover, PopoverContent, PopoverTrigger} from '@chakra-ui/react';
import {IconGear} from "utils/icons/account-health";
import {styles} from './styles';
import {useDispatch, useSelector} from "react-redux";
import {organizationDataLanguageSelector} from "@store";
import {ExploresEnum, openDefaultExploreView} from "store/ui";
import {channelsStateSelector} from "store/channels";
import {MoreInfoDialog, MoreInfoDialogProps} from "dialogs/more-info-dialog/MoreInfoDialog";

interface Props {
    data: AccountIntegrations[];
}

enum Titles {
    GENERAL,
    PNL,
    MARKETING,
    CUSTOMERS,
    SALES_CHANNELS
}


const dataLanguageMoreInfoDialogProps = {
    title: 'Data Language',
    //subtitle: 'How to use this view?',
    description: 'Set up top metrics for the whole organization in line with your business strategy.',
    //tip: 'Tip: More integrations = more data = more insights.',
    /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;


export const PortalLanguage = (props: Props) => {
    const {t} = useTranslation(['translation', 'translation']);
    const [activeIntegrations, setActiveIntegrations] = useState<number[]>([]);
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const generalLanguagedata = useSelector(organizationDataLanguageSelector);
    const channels = useSelector(channelsStateSelector);
    //hooks
    const [isPortalLanguageMoreInfoDialogOpen, setIsPortalLanguageMoreInfoDialogOpen] = useState(false);
    //helpers
    const openPortalLanguageMoreInfoDialog = (isOpen: boolean) => setIsPortalLanguageMoreInfoDialogOpen(isOpen);


    const handleClickNavigate = (index: number) => {
        if (index === Titles.GENERAL) {
            dispatch(openDefaultExploreView({isOpen: true, ExploresEnum: ExploresEnum.GENERAL}));
            return;
        }
        if (index === Titles.PNL) {
            dispatch(openDefaultExploreView({isOpen: true, ExploresEnum: ExploresEnum.PNL_SETTINGS}));
        }
        if (index === Titles.MARKETING) {
            dispatch(openDefaultExploreView({isOpen: true, ExploresEnum: ExploresEnum.MARKETING_MAPPING}));
            return;
        }
       /* if (index === Titles.SALES_CHANNELS) {
            dispatch(openDefaultExploreView({isOpen: true, ExploresEnum: ExploresEnum.SALES_CHANNELS}));
            return;
        }*/
        /* navigate("/admin/panel/data-language"); */
    };


    //renderers

    const customToolTip = (title: number, flag: boolean, lastUpdated: string) => {
        if (flag === false) {
            return (
                <PopoverContent w={205} borderRadius={30}>
                    <styles.StyledToolTip>{IconGear()}
                        <styles.ToolTipWrapper>
                            <styles.ToolTipText>
                                {t('DEFAULT_METRICS', {ns: 'translation'})}
                            </styles.ToolTipText>
                            <styles.ToolTipLink onClick={() => {
                                handleClickNavigate(title);
                            }}
                            >
                                {t('CUSTOMIZE', {ns: 'translation'})}
                            </styles.ToolTipLink>
                        </styles.ToolTipWrapper>
                    </styles.StyledToolTip>
                </PopoverContent>
            );
        } else {
            return (
                <PopoverContent w={235} borderRadius={30}>
                    <styles.StyledToolTip>
                        {IconGear()}
                        <styles.ToolTipWrapper>
                            <styles.ToolTipText>
                                {t('LAST_UPDATED', {ns: 'translation'})}
                            </styles.ToolTipText>
                            <styles.ToolTipText>
                                {lastUpdated}
                            </styles.ToolTipText>
                            <styles.ToolTipLink onClick={() => {
                                handleClickNavigate(title);
                            }}
                            >
                                {t('UPDATE', {ns: 'translation'})}
                            </styles.ToolTipLink>
                        </styles.ToolTipWrapper>
                    </styles.StyledToolTip>
                </PopoverContent>
            );
        }

    };

    const renderTitle = (number: number) => {
        switch (number) {
            case (Titles.GENERAL):
                return (`${t('GENERAL', {ns: 'translation'})}`);
            case (Titles.PNL):
                return (`${t('P&L', {ns: 'translation'})}`);
            case (Titles.MARKETING):
                return (`${t('MARKETING', {ns: 'translation'})}`);
            /*  case (Titles.CUSTOMERS):
                 return (`${t('CUSTOMERS', { ns: 'translation' })}`); */
            case (Titles.SALES_CHANNELS):
                return (`${t('SALES_CHANNELS', {ns: 'translation'})}`);
        }
        return;
    };
    const renderButton = (title: number) => {
        let flag = false;
        let lastUpdated = "01-01-2022";
        if (title === Titles.GENERAL && !!generalLanguagedata?.generalSettings && !!generalLanguagedata?.generalSettings?.updatedAt) {
            flag = !generalLanguagedata?.generalSettings?.isDefault;
            lastUpdated = generalLanguagedata.generalSettings?.updatedAt.slice(0, 10);
        }
        if (title === Titles.PNL && !!generalLanguagedata?.pnlSettings && !!generalLanguagedata?.pnlSettings?.updatedAt) {
            flag = !generalLanguagedata?.pnlSettings?.isDefault;
            lastUpdated = generalLanguagedata.pnlSettings?.updatedAt.slice(0, 10);

        }
        if (title === Titles.MARKETING && !!generalLanguagedata?.marketingMapping && !!generalLanguagedata?.marketingMapping?.updatedAt) {
            flag = !generalLanguagedata?.marketingMapping?.isDefault;
            lastUpdated = generalLanguagedata.marketingMapping?.updatedAt.slice(0, 10);

        }
        if (title === Titles.SALES_CHANNELS) {
            const updatedArray: any[] = [];
            flag = channels?.channels?.some((channel) => !channel.isDefault);
            channels?.channels.forEach((channel) => updatedArray.push(channel?.updatedAt));
            lastUpdated = updatedArray.map((x: string) => new Date(x)).sort().slice(-1)[0]?.toISOString().slice(0, 10);


        }
        /* if (title === Titles.CUSTOMERS && !!generalLanguagedata?.customersSettings) {
            flag = !generalLanguagedata?.customersSettings?.isDefault;
        } */
        return (
            <styles.ButtonWrapper onClick={() => {
                handleClickNavigate(title);
            }}>
                <Popover trigger="hover" placement='top'>
                    <PopoverTrigger>
                        <styles.Button {...{selected: flag}} onClick={() => {
                            if (flag) {
                                handleClickNavigate(title);
                            }
                        }}>
                            <styles.ButtonText>
                                {renderTitle(title)}
                            </styles.ButtonText>
                            <styles.Spacer></styles.Spacer>
                        </styles.Button>
                    </PopoverTrigger>
                    {customToolTip(title, flag, lastUpdated)}

                </Popover>
            </styles.ButtonWrapper>
        );
    };

    const renderButtons = () => {
        return (
            <styles.ButtonsContainer>
                {renderButton(Titles.GENERAL)}
                {renderButton(Titles.PNL)}
                {renderButton(Titles.MARKETING)}
                {/*{renderButton(Titles.SALES_CHANNELS)}*/}

                 <styles.HiddenButton />
                {/* {renderButton(Titles.CUSTOMERS)} */}
            </styles.ButtonsContainer>
        );
    };

    return (
        <styles.Wrapper>
            <styles.Title>
                {t('DATA_LANGUAGE', {ns: 'translation'})}
                <MoreInfoButton onClick={() => openPortalLanguageMoreInfoDialog(true)}/>
                <MoreInfoDialog
                    props={dataLanguageMoreInfoDialogProps}
                    isDialogOpen={isPortalLanguageMoreInfoDialogOpen}
                    onClose={() => openPortalLanguageMoreInfoDialog(false)}
                />
            </styles.Title>
            {renderButtons()}
        </styles.Wrapper>
    );
};


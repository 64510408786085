
export const fakeData2 = {
    title: "Gross Revenue1",
    value: "$190,000",
    vsTargetPercentage: 90,
    isRoot: true,
    children: [
        {
            title: "Gross Revenue2",
            value: "$190,000",
            vsTargetPercentage: 90,
            isRoot: false,
            children: [
                {
                    title: "Gross Revenue4",
                    value: "$190,000",
                    vsTargetPercentage: 90,
                    isRoot: false,
                    children: [
                        {
                            title: "Gross Revenue8",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        }, {
                            title: "Gross Revenue9",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        },
                    ]
                },
                {
                    title: "Gross Revenue5",
                    value: "$190,000",
                    vsTargetPercentage: 90,
                    isRoot: false,
                    children: [
                        {
                            title: "Gross Revenue10",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        }, {
                            title: "Gross Revenue11",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        },
                    ]
                },
            ]
        },
        {
            title: "Gross Revenue3",
            value: "$190,000",
            vsTargetPercentage: 90,
            isRoot: false,
            children: [
                {
                    title: "Gross Revenue6",
                    value: "$190,000",
                    vsTargetPercentage: 90,
                    isRoot: false,
                    children: [
                        {
                            title: "Gross Revenue12",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        }, {
                            title: "Gross Revenue13",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        },
                    ]
                },
                {
                    title: "Gross Revenue7",
                    value: "$190,000",
                    vsTargetPercentage: 90,
                    isRoot: false,
                    children: [
                        {
                            title: "Gross Revenue14",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        }, {
                            title: "Gross Revenue15",
                            value: "$190,000",
                            vsTargetPercentage: 90,
                            isRoot: false,
                            children: [

                            ]
                        },
                    ]
                },
            ]
        }
    ]
};


export const fakeDataSessions = {
    title: "Sessions",
    value: "120K",
    vsTargetPercentage: 50,
    isRoot: true,
    children: [
        {
            title: "Clicks",
            value: "500K",
            vsTargetPercentage: 101,
            isRoot: false,
            children: [
                {
                    title: "CTR",
                    value: "1.4%",
                    vsTargetPercentage: 100,
                    isRoot: false,
                    children: []
                },
                {
                    title: "Impressions",
                    value: "$190,000",
                    vsTargetPercentage: 100,
                    isRoot: false,
                    children: []
                },
            ]
        },
        {
            title: "Bounce Rate",
            value: "1.4%",
            vsTargetPercentage: 95,
            isRoot: false,
            children: []
        }
    ]
};

export const fakeDataMarketingSpend = {
    title: "Spend",
    value: "120K",
    vsTargetPercentage: 50,
    isRoot: true,
    children: [
        {
            title: "Gross Revenue",
            value: "504K",
            vsTargetPercentage: 101,
            isRoot: false,
            children: []
        },
        {
            title: "ACOS",
            value: "25%",
            vsTargetPercentage: 95,
            isRoot: false,
            children: []
        }
    ]
};

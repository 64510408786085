import styled from "styled-components";

export namespace styles {
  export const Wrapper = styled.div`
    min-height: max-content;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;


  export const OpenMenuButton = styled.button<{ isOpen: boolean, createMetricModal?: boolean; borderRadius?: number; backgroundColor?: string; isBold?: boolean }>`
    background: ${(props) =>
      props.isOpen ? props.theme.primaryColor : "white"};
    color: ${(props) => (props.isOpen ? "black" : "black")};
    background-color:  ${props => props?.backgroundColor ? props?.backgroundColor : 'white'};;
    padding: 10px 20px;
    font-size: 14px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ebebeb;
    font-weight: ${props=> props?.isBold ? '500' : ''};
    border-radius: ${props => props?.borderRadius ? props?.borderRadius + 'px' : '19px'};
    min-width: max-content;
    width: ${props => props?.createMetricModal ? "100%" : ''}
  `;
  export const MenuItem = styled.button`
    color: black;
    width: 176px;
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
    text-align: start;
  `;
  export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  `;

  export const CancelButton = styled.button`
    font-size: 14px;
    padding: 5px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 19px;
  `;
  export const ApplyButton = styled.button`
    background: ${(props) => props.theme.primaryColor};
    font-size: 14px;
    color: white;
    padding: 5px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 19px;
  `;
}

export default styles;

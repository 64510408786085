import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Avatar, Box, Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import { organizationSelector, shareKpiByEmail } from "@store";
import { usersDataSelector } from "store/users";
import { getENUMByKpiName, getKpiNameFromEnum, getKpiNameFromNumber } from "utils/kpi-names/kpiName";
import { EyeIconShare } from "utils/icons/explore";
import { ExploresEnum } from "store/ui";
import { KpisEnum } from "models/kpi-settings";
import { AiOutlineClose } from "react-icons/ai";
import {getUserColorById, gradientDictionary} from "../../utils/colors";
import {getExploreByEnum} from "../../utils/explore-by-enum/getExploreByEnum";

interface Props {
    isOpen: boolean, onOpen: () => void, onClose: () => void;
    imageToShare?: any;
    exploreEnum?: ExploresEnum
}

interface Users {
    email: string,
    id: string;
}

interface ExistUserOrPendingUser {
    email: string;
    firstName?: string;
    lastName?: string;
    id?: string;
}


export const  ShareKpi = (props: Props) => {
    const { t } = useTranslation(['translation', 'translation']);
    const [emailInput, setEmailInput] = useState<string>("");
    const [currentEmail, setCurrentEmail] = useState<string>();
    const [users, setUsers] = useState<Users[]>([]);
    const [existingUsers, setExistingUsers] = useState<any[]>([]);
    const [copyLink, setCopyLink] = useState("");
    const organization = useSelector(organizationSelector);
    const dispatch = useDispatch();
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const kpiEnum = params.get('explore');


    useEffect(() => {
        if (!!props?.exploreEnum) {
            setCopyLink(currentUrl.split("?")[0] + `?explore=${getExploreByEnum(props?.exploreEnum)}`);
        }
    }, [currentUrl, props?.exploreEnum]);

    useEffect(() => {
        setUsers([]);
    }, [props.isOpen]);

    useEffect(() => {
        if (!!organization?.users) {
            const usersData = organization?.users.map((user) => {
                if (!!user.email) {
                    return { email: user.email, firstName: user?.firstName, lastName: user?.lastName, id: user?.id };
                }
                else return;
            });
            setExistingUsers(usersData);
        }
    }, [organization?.users]);


    let kpiTitle = "";
    /*if (!!props?.kpi) {
        kpiTitle = props.kpi;
    }*/
    const onChange = (event: any) => {
        setEmailInput(event.target.value);

        if (event.target.checkValidity()) {
            setCurrentEmail(event.target.value);
        }
        else {
            setCurrentEmail(undefined);
        }
    };
    const handleClickDropdown = () => {
        if (!!currentEmail) {
            if (users.some((user) => user.email === currentEmail)) {

            } else {
                const user = { email: currentEmail, id: uuidv4() };
                setUsers(users => [user, ...users]);
                setCurrentEmail(undefined);
                setEmailInput("");
            }

        }
    };

    const submitForm = (e: any) => {
        e.preventDefault();
        const data = {
            recipients: users?.map((user) => user?.email),
            sharedLink: copyLink,
            kpiTitle: kpiTitle,
        };

        !!organization?.id && dispatch(shareKpiByEmail({ data, organizationId: organization?.id, image:props?.imageToShare }));
        setUsers([]);

    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!!currentEmail) {
                if (users.some((user) => user.email === currentEmail)) {

                } else {
                    const user = { email: currentEmail, id: uuidv4() };
                    setUsers(users => [user, ...users]);
                    setCurrentEmail(undefined);
                    setEmailInput("");
                }

            }
            if (emailInput === "" && users.length > 0) {
                submitForm(e);
            }
        }
    };

    const handleClickOnUser = (email: string) => {
        const user = { email: email, id: uuidv4() };
        if (users.some((user) => user.email === email)) {
            const filteredUsers = users.filter((user) => user?.email !== email);
            setUsers(filteredUsers);
        } else {
            setUsers(users => [user, ...users]);
        }
    };

    const removeUser = (currentUser: { email: string, id: string; }) => {
        const filteredUsers = users.filter((user) => user?.id !== currentUser?.id);
        setUsers(filteredUsers);
    };

    const handleCopyLinkClick = () => {
        navigator.clipboard.writeText(copyLink);
    };


    const renderNewEmailDropdown = () => {
        return (
            <styles.DropDownWrapper onClick={handleClickDropdown}>
                <styles.DropDownText>
                    {currentEmail}
                </styles.DropDownText>
            </styles.DropDownWrapper>
        );
    };

    const RenderUsersList = (props: { array: any[]; }) => {

        return (
            <styles.UsersWrapper>
                {props.array.map((user, index) => {
                    return <styles.UserContainer key={index}>
                        <styles.UserTitle>
                            <styles.UserName>
                                {/* {user?.firstName + ' ' + user?.lastName} */}
                            </styles.UserName>
                            <styles.UserEmail>
                                {user?.email}
                            </styles.UserEmail>
                        </styles.UserTitle>
                        <styles.UserDetails>
                            <styles.UserRole>
                                {/* {user?.workspaces![0].role} */}
                            </styles.UserRole>
                        </styles.UserDetails>
                        <AiOutlineClose onClick={() => removeUser(user)} />
                    </styles.UserContainer>;
                })}
            </styles.UsersWrapper>
        );
    };

    const RenderExistingUsers = (props: { array: any[]; }) => {

        return (
            <styles.UsersWrapperActive>
                {props.array.map((user, index) => {
                    return <styles.UserContainer onClick={() => { handleClickOnUser(user.email); }} key={index}>
                        {!!user?.firstName && !!user?.lastName ?
                            <>
                                <Avatar size={"sm"} name={user?.firstName + " " + user?.lastName} color={"white"} background={!!user?.id ? getUserColorById(user?.id) : gradientDictionary[0]} />
                            </>
                            : <></>}
                        {!!user?.firstName && !!user?.lastName ? <styles.CustomSpacer width={10} /> : <></>}
                        <styles.UserTitle>
                            <styles.UserName>
                                {!!user?.firstName && !!user?.lastName ? (user?.firstName + ' ' + user?.lastName) : <></>}
                            </styles.UserName>
                            <styles.UserEmail>
                                {user?.email}
                            </styles.UserEmail>
                        </styles.UserTitle>
                    </styles.UserContainer>;
                })
                }
            </styles.UsersWrapperActive >
        );
    };



    const renderInviteEmailInput = () => {
        return (
            <styles.InviteEmailInput onKeyPress={(e) => handleKeyPress(e)} value={emailInput} onChange={onChange} type={"email"} required placeholder={'Add Email'} />
        );
    };

    const renderActiveUsers = () => {
        return (
            <styles.UsersContainerActive /* onScroll={onScroll} ref={listInnerRef} */ >
                <RenderExistingUsers array={existingUsers} />
            </styles.UsersContainerActive>


        );
    };
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>
                    <styles.Title>
                        {t('SHARE', { ns: 'translation' })}
                        {" "}
                        <styles.TitleBold>'{t(kpiTitle, { ns: 'translation' })}'</styles.TitleBold>
                        {" "}
                        {t('EXPLORE', { ns: 'translation' })}

                    </styles.Title>
                </ModalHeader>
                <styles.StyledModalClose />
                <styles.ModalBodyWrapper>
                    <styles.InputWrapper>
                        {renderInviteEmailInput()}
                    </styles.InputWrapper>
                    {!!currentEmail ? renderNewEmailDropdown() : null}
                    <styles.UsersContainer /* onScroll={onScroll} ref={listInnerRef} */ >
                        <RenderUsersList array={users} />
                    </styles.UsersContainer>
                    <styles.SendButton notActive={users.length === 0} disabled={users.length === 0} onClick={(e) => submitForm(e)}>
                        {t('SHARE', { ns: 'translation' })}

                    </styles.SendButton>
                    <styles.Spacer />
                    <styles.StyledTitle>
                        <Flex mt={1} mb={2}>
                            {t('ACTIVE_TEAM_MEMBERS', { ns: 'translation' })}
                        </Flex>
                    </styles.StyledTitle>
                    {renderActiveUsers()}
                </styles.ModalBodyWrapper>

                <ModalFooter style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Flex justifyContent={"space-between"} w="100%" alignItems={"center"}>
                        <Box display={"flex"} w={"80%"} justifyContent="space-between">
                            <EyeIconShare />
                            <Flex flexDirection={"column"}>
                                <styles.FooterText>
                                    Get A Link
                                </styles.FooterText>
                                <styles.FooterSubtext>
                                    People getting this link will be able to view, not to edit
                                </styles.FooterSubtext>
                            </Flex>
                        </Box>
                        <styles.Link onClick={handleCopyLinkClick}>Copy Link</styles.Link>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};
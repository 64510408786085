// imports
import {createSlice} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";

// locals
import {clearAmazonState} from "./actions";
import {initialAmazonState, ShopifyState} from "./state";
import {fetchShopifyOAuthUrl} from "./thunks";

// reducers
function buildCleanAmazonStateReducer(builder: ActionReducerMapBuilder<ShopifyState>) {
    builder.addCase(clearAmazonState, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.code = undefined;
        return state;
    });
}

// fetch shopify OAuth url
function buildFetchShopifyOAuthUrlReducer(builder: ActionReducerMapBuilder<ShopifyState>) {
    builder.addCase(fetchShopifyOAuthUrl.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
        return state;
    });
    builder.addCase(fetchShopifyOAuthUrl.fulfilled, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.oAuthUrl = action.payload;
        return state;
    });
    builder.addCase(fetchShopifyOAuthUrl.rejected, (state, action) => {
        state.error = undefined;
        state.loading = false;
        return state;
    });
}

// slice
export const shopifySlice = createSlice({
    name: 'shopify',
    initialState: initialAmazonState,
    reducers: {},
    extraReducers: (builder => {
        // non async
        buildCleanAmazonStateReducer(builder);

        // async
        // fetch shopify OAuth url
        buildFetchShopifyOAuthUrlReducer(builder);
    }),
});

// reducer
export const shopifyReducer = shopifySlice.reducer;

import styled from 'styled-components';
import {Props} from './Button';

export const ButtonWrapper = styled.div<Props>`
  height: 24px;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    switch (props.buttonStyle) {
      case 'outlined':
        return props.theme.backgroundColor;
      default:
        return props.theme.primaryColor;
    }
  }};
  border:  ${props => {
    return `1px solid ${props.theme.primaryColor}`;
  }};

  > a {
    width: calc(100% - 20px);
    height: calc(100% - 4px);
    outline: none;
    border: none;
    background-color: transparent;
    color: ${props => {
      switch (props.buttonStyle) {
        case 'outlined':
          return props.theme.primaryColor;
        default:
          return props.theme.backgroundColor;
      }
    }};
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

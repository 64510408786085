import axios, {AxiosResponse} from "axios";
import {googleAdsConnectUrl} from "@constants";

export const initGoogleApi = () => {
    //loads the Google JavaScript Library
    (function () {
        const id = 'google-js';
        const src = 'https://apis.google.com/js/platform.js';
        //we have at least one script (React)
        const firstJs = document.getElementsByTagName('script')[0];
        //prevent script from loading twice
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = src;
        // @ts-ignore
        js.onload = window.onGoogleScriptLoad;
        firstJs.parentNode?.insertBefore(js, firstJs);
    }());
}

export const startGoogle = () => {
    gapi.load('auth2', () => {
        gapi.auth2.init({
            client_id: '285334961050-g2ccl7vblq4ic32kd43obcd1pf6ag3hu.apps.googleusercontent.com'
        }).then(() => {
            gapi.auth2.getAuthInstance().signIn({
                scope: 'https://www.googleapis.com/auth/adwords',
            }).then(res => {
            })
        })
    })
}

const getOAuthUrl = async (): Promise<AxiosResponse<{ oauthUrl: string, accessToken: string }>> => {
    return axios.get(
        googleAdsConnectUrl,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        })?.then((response: any) => {
        return response;
    });
}

export const googleService = {
    initGoogleApi,
    getOAuthUrl,
    startGoogle,
};

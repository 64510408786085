import styles from "./styles";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {isApplyBarOpenSelector, openApplyBar, openSecondaryApplyBar} from "../../store/ui";
import lottieLoader from "../../utils/lottie-animations/loading-success-white.json";


interface Props {
    changesCount?: number;
    show?: boolean;
    onApplyChanges: () => void;
    hideNumber?: boolean;
    title: string;
    onClose?: (e?:any) => void;
    showLoader?: true;
    isLoading?: boolean;
    closeApplyBar?: boolean;
    finishedLoading?: boolean;
}

export const ApplyChangesBar = ({ changesCount, onApplyChanges, title, show, hideNumber, showLoader, onClose, isLoading, finishedLoading }: Props) => {
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const [close, setClose] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displaySaved, setDisplaySaved] = useState(false);
    const isApplyBarOpen = useSelector(isApplyBarOpenSelector);
    const lottieRef = useRef<any>();

    useEffect(() => {
        setClose(false);
        setDisplayLoader(false);
        /*setTimeout(() => {
            dispatch((openApplyBar({isApplyBarOpen: false})))
        }, 3500)*/
    }, [isApplyBarOpen])

    useEffect(() => {
        if(!!finishedLoading){
            setDisplaySaved(true);
           /* setTimeout(() => {
            }, 100)*/
        }
    }, [finishedLoading])

    useEffect(() => {
        if(!!finishedLoading){
            setTimeout(() => {
                setClose(true);
            }, 3000)
            setTimeout(() => {
                if(!!onClose){
                    onClose();
                }
                dispatch((openApplyBar({isApplyBarOpen: false})))
                dispatch((openSecondaryApplyBar({isApplyBarOpen: false})))
            }, 3500)
        }
        return (
            setClose(false)
    )
    }, [finishedLoading]);

    const onSaveClick = () => {
        setDisplayLoader(true);
    }

const Loader = () => {
        return (
            <styles.LottieStyle lottieRef={lottieRef} initialSegment={close ? [181,181] : displaySaved ? [0,181] : [0,60]} loop={false} animationData={lottieLoader} />
        )
}

    if (changesCount === 0 && !showLoader  || show === false && !showLoader){
        return (<></>);
    }
    return (
        <styles.Wrapper close={close}>
            <Flex alignItems={"center"}>
                {/*<AiFillCheckCircle size={'35px'} opacity={1} color={'white'} />*/}
                <styles.TextContainer>
                {hideNumber ? t(title, { ns: 'translation' }) : (changesCount ? changesCount : '') + " " + t(title, { ns: 'translation' })}
                </styles.TextContainer>
            </Flex>

            <styles.ApplyChangesButton clicked={displayLoader} onClick={() => {
                onSaveClick();
                onApplyChanges();
            }}>
                {displayLoader ?
                    <Loader/>
                    : t("Apply Changes", { ns: 'translation' })}
            </styles.ApplyChangesButton>

        </styles.Wrapper>
    );
};

export default ApplyChangesBar;
import styled from "styled-components";
import {Avatar, Collapse, PopoverContent} from "@chakra-ui/react";

export namespace styles {
    // card
    export const Wrapper = styled.div`
      /* z-index: 0; */
      position: relative;
      display: flex;
      /* width: 100%; */
      /*height: 100%;*/
      margin: 15px;
    `;

    export const ValueGhost = styled.div`
      font-size: 32px;
      padding: 0px 10px 10px 10px;
      color: #fff0;
    `;
    export const NoDataText = styled.div`
      color: #7a86a1;
      font-family: ${(props) => props.theme.fonts.roboto.regular};
      font-size: 32px;
      white-space: nowrap;
    `;
    export const Tooltip = styled.div`
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
    `;

    export const TooltipHeader = styled.div`
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
    `;
    export const TooltipSection = styled.div`
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `;

    export const TooltipTitle = styled.div`
      color: ${(props) => props.theme.primaryColor};
      font-weight: bold;
      font-size: 12px;
    `;

    export const TooltipLastUpdate = styled.div`
      color: gray;
      font-size: 12px;
    `;

    export const TooltipMoreinfo = styled.div`
      color: blue;
      text-decoration: underline;
      font-size: 12px;
    `;

    export const DataModeIconWrapper = styled.div<{ color: string }>`
      padding: 5px;
      height: min-content;
      border-radius: 50%;
      background-color: ${(props) => props.color};
    `;

    export const Card = styled.div<{ noData: boolean }>`
      //height: 200px;
      /* z-index: 4; */
      min-width: 300px;
      width: 300px;
      height: 200px;
      display: block;
      position: relative;
      //display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 5px 31px 54px #695f9726;
      background-color: ${(props) => (props.noData ? "#F5F4F4" : "white")};
    `;

    // content
    export const Content = styled.div`
      /* z-index: 5; */
      height: 170px;
      width: 100%;
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;

      box-shadow: ${(props) => props.theme.primaryShadow};
    `;

    export const ContentFirstColumn = styled.div`
      width: 100%;
      /*padding: 10px 10px 30px;*/
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `;

    export const ContentSecondColumn = styled.div`
      height: 100%;
      width: 100%;
      /*padding: 10px 10px 0px 0px;*/
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `;

    export const ContentTools = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: end;
      position: relative;
    `;

    export const GraphWrapper = styled.div`
      height: 80px;
      //width: 110px;
      //margin: 10px;
    `;

    export const Title = styled.span`
      font-size: 18px;
      /*margin-left: 5px;*/
      color: gray;
      width: max-content;
      font-family: ${(props) => props.theme.fonts.roboto.regular};
      white-space: nowrap;
    `;

    export const Value = styled.span`
      font-size: 22px;
      font-weight: 500;
      color: black;
    `;

    export const Target = styled.div`
      height: 32px;
      padding: 5px 20px;
      background-color: ${(props) => props.theme.errorBackgroundColor};
      border-radius: 16px;
    `;

    // export const TargetText = styled.span`
    //   font-size: 14px;
    //   color: ${(props) => props.theme.errorColor};
    // `;

    export const UserAvatar = styled(Avatar)``;

    export const MenuButton = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    `;

    // footer
    export const Footer = styled.div<{ index?: number; isOpen: boolean }>`
      z-index: ${props => !!props?.index ? 50 - props?.index : 50};
      position: absolute;
      min-height: 30px;
        //height: ${props => !!props?.isOpen ? 'auto' : '30px'};
      //transition: height 10.5s;
      width: 100%;
      padding: 10px;
      background-color: #f2eff9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 10px 10px;
      border-width: 1px;
      box-shadow: ${(props) => props.theme.primaryShadow};
    `;

    export const FooterCollapseData = styled(Collapse)`
      /* width: 350px; */
      display: none;
      opacity: 0;
      height: 0px;
      z-index: 5;
      //position: absolute;
      width: 100%;
      //margin-top: 180px;
      //background-color: #f2eff9;
      //border-radius: 0 0 20px 20px;
      //width: 100%;
    `;

    export const FooterCollapseDataContent = styled.div`
      width: 100%;
      z-index: 8;
      /*  width: 350px; */
    `;

    // data table
    export const DataTable = styled.div`
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      max-height: 200px;
      overflow-y: auto;
    `;

    export const DataTableHeader = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `;

    export const DataTableHeaderSpacer = styled.span`
      font-size: 14px;
      width: 30%;
    `;

    export const DataTableHeaderTitle = styled.span`
      font-size: 14px;
      width: 25%;
    `;

    export const DataTableHeaderDivider = styled.div`
      border: solid 1px;
      border-color: transparent;
      height: 20px;
      padding: 10px 0;
      margin: auto 0;
    `;

    export const DataTableRow = styled.div<{ previewRow?: boolean }>`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: ${props => props?.previewRow ? 'center' : 'space-between'};
      align-items: center;
      position: relative;
      margin: 5px 0;
    `;

    export const DataTableRowTitle = styled.span`
      font-size: 11px;
      width: 30%;
      margin-left: 7px;
    `;
    export const CardHeader = styled.div`
      display: flex;
      width: 100%;
      align-self: flex-start;
      justify-content: space-between;
    `

    export const CardData = styled.div`
      display: flex;
      width: 100%;
      align-self: center;
      margin-top: 15px;
      //justify-content: space-between;
    `


    export const DataTableRowDivider = styled.div`
      border: solid 1px;
      border-color: lightgray;
      height: 20px;
      padding: 10px 0;
      margin: auto 0;
    `;

    export const ProgressWrapper = styled.div`
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 25%;
    `;

    export const ProgressText = styled.span`
      font-size: 11px;
    `;

    export const SneakPickButton = styled.a`
      width: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      line-height: 28px;
      cursor: pointer;
      text-align: center;
    `;

    export const ExploreButton = styled.a`
      //height: 32px;
      width: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      line-height: 28px;
      cursor: pointer;
      align-items: center;
      text-align: center;
      background-color: ${(props) => props.theme.backgroundColor};
      border: ${(props) => {
        return `1px solid ${props.theme.primaryColor}`;
      }};
      color: ${(props) => props.theme.primaryColor};

      &:hover {
        background-color: ${(props) => props.theme.primaryColor};
        border: ${(props) => {
          return `1px solid ${props.theme.primaryColor}`;
        }};
        color: ${(props) => props.theme.backgroundColor};
      }
    `;

    export const ExploreButtonCollapseTrigger = styled.a`
      width: calc(100% - 150px);
      height: 100%;
      position: absolute;
      left: 0;
    `;

    export const WideExploreButtonWrapper = styled.div`
      display: flex;
      width: 100%;
      padding: 10px;
    `;

    export const WideExploreButton = styled(ExploreButton)`
      width: 100%;
    `;

    export const TargetText = styled.span<{ color?: string, fontSize?: number }>`
      color: ${props => props.color};
      font-size: ${props => props?.fontSize ? props?.fontSize + "px" : `min(calc(4px + 0.8vw), 12px)`};
      text-align: center;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: block;
      line-height: 1em;
      max-height: 1em;
    `;
}

export default styles;

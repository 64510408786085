import styled from "styled-components";
import Lottie from "lottie-react";


export namespace styles {
    export const Wrapper = styled.div`
        border: 3px dashed ${props => props?.theme?.primaryColor};
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 185px;
        font-family: ${props => props?.theme.fonts.roboto.regular};
      &:focus-visible{
        outline: none !important;
      }
      &:focus{
        outline: none !important;
      }
    `
    export const StyledButton = styled.button`
      margin: 0 auto;   
      display: flex;
      text-decoration: underline;
      color: #71b1d9;
    `

    export const StyledText = styled.div`
      display: flex;
      align-items: center;
      font-family: ${props => props?.theme.fonts.roboto.regular};
    `

    export const ErrorText = styled.aside`
      color: #e13737;
      font-family: ${props => props?.theme.fonts.roboto.regular};

    `

    export const StyledIcon = styled.span`
    
    &:hover{
      cursor: pointer;
    }`

    export const DragText = styled.p`
    display: flex;
      margin: 0 auto;
      text-align: center;
      width: 100%;
      justify-content: center;
      
    `

    export const LottieStyle = styled(Lottie)`
    //height: 40vh;
    width: 25%;
  `;
    export const FileNameHolder = styled.div`
    width: 89%;
    margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `;

}

export default styles;

import React from "react";
import {getIntegrationPlatformRoundLogo, getIntegrationPlatformThemeColor, IntegrationPlatform} from "@models";
import wideViewsStyles from "../explore/metric-explore/styles";

interface Props {
    platform?: IntegrationPlatform;
    text?: string
}

const PlatformChip = ({platform, text}: Props) => {
        return (<wideViewsStyles.ChipWrapper color={getIntegrationPlatformThemeColor(platform)}>
            <wideViewsStyles.Chip color={getIntegrationPlatformThemeColor(platform)}>
                <wideViewsStyles.LogoIconWrapper>
                    <wideViewsStyles.LogoIcon src={getIntegrationPlatformRoundLogo(platform)}/>
                </wideViewsStyles.LogoIconWrapper>
                <wideViewsStyles.ChipText>
                    {text}
                </wideViewsStyles.ChipText>
            </wideViewsStyles.Chip>
        </wideViewsStyles.ChipWrapper>)
};


export default PlatformChip;

import styled from "styled-components";
import { Avatar, Icon, PopoverContent } from "@chakra-ui/react";

export namespace styles {
  // card
  export const Wrapper = styled.div<{ hover: boolean }>`
    position: relative;
    display: flex;
    height: 100%;
    &:hover {
      cursor: ${(props) => (props.hover ? "pointer" : "auto")};
    }
  `;
  export const Pin = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
  `;
  export const PinIcon = styled.div`
    text-align: left;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: fadeIn 1s ease-in-out;
    display: flex;
    justify-content: center;
  `;

  export const StyledPopoverContent = styled(PopoverContent)`
    min-width: 150px;
    max-width: 170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &:focus {
      // remove blue border from chakra
      box-shadow: unset !important;
    }
  `;
  export const NoDataText = styled.div`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 1.825em; //32px;
    padding-left: 0 !important;
  `;

  export const Card = styled.div<{
    hovered?: boolean;
    noData?: boolean;
    isSelected?: boolean;
    toClean?: boolean;
    reducedShadow?: boolean
  }>`
    min-width: 240px;
    height: 130px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: ${props => props?.reducedShadow ? "5px 10px 25px #695f9726" :"5px 31px 54px #695f9726"};
    background-color: ${(props) => (props.noData ? "#F5F4F4" : "white")};
    margin: 8px;
    /*margin: 5px;
    padding-left: 5px;*/
    padding: 10px;
    outline: ${(props) =>
      props.isSelected &&
      (props.toClean === false || props.toClean === undefined)
        ? "3px solid #7031EB"
        : "none"};
    transition: box-shadow .7s;
    
  &:hover{
    box-shadow:5px 31px 54px #695f9708;
  }
  `;

  export const Header = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //gap: 50px;
    /*padding: 13px 20px 5px 5px;*/
  `;
  export const TinyHeader = styled.div`
    flex: 1;
    padding-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
  export const TinyWrapper = styled.div`
    width: 96%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  // content
  export const Content = styled.div<{ noData?: boolean }>`
    flex: 4;
    display: flex;
    flex-direction: row;
    position: relative;
    /* align-items: ${(props) => (props.noData ? "flex-end" : "flex-start")}; */
    /* background-color: red; */
    /* filter: ${(props) => (props.noData ? "blur(0.3rem)" : null)}; */
  `;

  export const NoData = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    position: absolute;
    justify-content: start;
    align-items: start;
  `;

  export const IndicatorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  `;

  export const ValueGhost = styled.div`
    font-size: 32px;
    padding: 0px 5px 10px 0;
    margin-bottom: 4px;
    color: #fff0;
  `;

  export const DataModeIconWrapper = styled.div<{ color: string }>`
    padding: 5px;
    height: min-content;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  `;

  export const ContentFirstColumn = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*padding: 0px 10px 10px 10px;*/
    align-items: start;
  `;

  export const ContentSecondColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*padding: 0px 10px 10px 10px;*/
    align-self: flex-end;
  `;

  export const Title = styled.div`
    font-size: 15px;
    color: gray;
    font-weight: ${(props) => props.theme.fonts.roboto.regular};
    /*margin-left: 10px;*/
  `;

  export const UserAvatar = styled(Avatar)`
    width: 28px;
    font-size: 0.8125em;
  `;

  export const MenuButton = styled.div<{ broken?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  `;
  export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #c6c3d1;
  `;

  export const ExploreButtonWrapper = styled.div`
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  `;
  export const DotsButton = styled.button<{ isInside?: boolean }>`
    font-size: 15px;
    width: 100%;
    padding: 0 0 0 14px;
    height: 25px; //${(props) => (props?.isInside ? "25px" : "25px")};
    text-align: left;
    position: relative;
    color: #7a86a1;
    &:hover {
      cursor: pointer;
    }
    &:hover {
      background-color: #edf2f6;
    }
    &:first-of-type {
      border-radius: 5px 5px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 5px 5px;
    }
  `;

  export const ExploreButtonIcon = styled(Icon)`
    color: ${(props) => props.theme.primaryColor};
  `;

  export const ExploreButton = styled.a`
    color: ${(props) => props.theme.primaryColor};
    padding-left: 4px;
    font-size: 14px;
  `;

  export const Value = styled.span`
    font-size: 32px;
    color: black;
    padding: 0 5px 3px 0;
  `;
  //${(props) => (props.hovered ? "128px" : "125px")};
  export const TinyCard = styled.div<{ hovered?: boolean }>`
    width: 125px;
    height: 100%;
  
    padding: 5px;
    display: block;
    outline: 1px solid #e8e8e8;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.hovered ? "10px 10px 0 0" : "10px")};
    box-shadow: ${(props) => (props.hovered ? "5px 31px 54px #695F9726" : "")};
    border-bottom: ${(props) =>
      props.hovered ? `3px solid ${props.theme.primaryColor}` : ""};
    background-color: white;
  `;

  export const TinyContentFirstColumn = styled.div`
    flex: 5;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  `;

  export const TinyContentSecondColumn = styled.div`
    flex: 2;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /*margin-right: 10px;*/
  `;

  export const TinyTitle = styled.span`
    font-size: 12px;
    flex: 2;
    color: gray;
  `;

  export const TinyValue = styled.span`
    font-size: 18px;
    flex: 1;
    color: black;
    padding-left: 4px;
    font-weight: bold;
  `;

  export const Tooltip = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
  `;

  export const TooltipHeader = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  `;
  export const TooltipSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  export const TooltipTitle = styled.div`
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    font-size: 12px;
  `;

  export const TooltipLastUpdate = styled.div`
    color: gray;
    font-size: 12px;
  `;

  export const TooltipMoreinfo = styled.div`
    color: blue;
    text-decoration: underline;
    font-size: 12px;
  `;
}

export default styles;

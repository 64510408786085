// import
import React, { Suspense } from "react";
import "swiper/swiper-bundle.css";

// local
import "@i18n/i18n";
import { history, store } from "@store";
import { Navigate, Route, Routes } from "react-router-dom";
import { SignIn } from "./features/auth/sign-in/SignIn";
import { SignUp } from "./features/auth/sign-up/SignUp";
import { AdminPanel } from "./features/admin-panel/AdminPanel";
import { AuthGuard } from "./utils/routing/auth-route/AuthRoute";
import { AdminGuard } from "./utils/routing/admin-route/AdminRoute";
import { HistoryRouter } from "redux-first-history/rr6";
import { container, DependencyInjectionProvider } from "@di";
import { GlobalStyle, LightTheme, StyledThemeProvider } from "@styles";
import { ChakraProvider, extendTheme, Spinner } from "@chakra-ui/react";
import { VerifyAccountEmail } from "./features/verify-account/VerifyAccountEmail";
import { SuccessMessages } from "./components/success-message/SuccessMessage";
import { amazonSellerService, facebookService } from "@services";
import { Onboarding } from "./features/onboarding/Onboarding";
import { Provider, useSelector } from "react-redux";
import { Loading } from "./components/loading/Loading";
import { UserVerified } from "./components/user-verified/UserVerified";
import { googleService } from "./services/google/google.service";
import { NotFound } from "./components/not-found/NotFound";
import ReactDOM from "react-dom";
import { OnboardingGuard } from "./utils/routing/onboarding-route/OnboardingRoute";
import { FirstIntegration } from "./features/onboarding/first-integration/FirstIntegration";
import { FirstInvitation } from "./features/onboarding/first-invitation/FirstInvitation";
import { OrganizationCreatedLoader } from "./features/onboarding/organization-created-loader/OrganizationCreatedLoader";
import { InviteVerified } from "./components/invite-verified/InviteVerified";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import { ResetPassword } from "components/reset-password/ResetPassword";
import { isAppLoadingSelector } from "./store/ui";
import { RecoverPassword } from "./features/auth/recover-password/RecoverPassword";
import { ShopifyIntegrationMessage } from "./components/shopify-integration-message/ShopifyIntegrationMessage";
import {Toasts} from "./components/toast/Toast";

const lightThemeChakra = extendTheme(LightTheme);

const App = () => {
  TimeAgo.addDefaultLocale(en);
  const isGlobalLoading = useSelector(isAppLoadingSelector);

  return (
    <HistoryRouter history={history}>
      {isGlobalLoading ? (
        <div
          style={{
            backgroundColor: "white",
            zIndex: 9999,
            opacity: 0.4,
            position: "fixed",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <></>
      )}
      <Toasts/>
      <Routes>
        <Route path="/" element={<Navigate to={"/auth"} />} />
        <Route path="/auth/*" element={<AuthGuard />}>
          <Route path="" element={<Navigate replace to={"sign-in"} />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="recover-password" element={<RecoverPassword />} />
        </Route>
        <Route path="/admin/*" element={<AdminGuard />}>
          <Route path="" element={<Navigate replace to={"panel"} />} />
          <Route path="panel/*" element={<AdminPanel />} />
        </Route>
        <Route path="/onboarding/*" element={<OnboardingGuard />}>
          <Route path="setup" element={<Onboarding />} />
          <Route path="first-integration" element={<FirstIntegration />} />
          <Route path="first-invitation" element={<FirstInvitation />} />
          <Route
            path="creating-organization"
            element={<OrganizationCreatedLoader />}
          />
        </Route>
        <Route path="/verify-account" element={<VerifyAccountEmail />} />
        <Route path="/success" element={<SuccessMessages />} />

        <Route path="/loading" element={<Loading />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/user/verify" element={<UserVerified />} />
        <Route path="/user/reset-password" element={<ResetPassword />} />
        <Route path="/invite/verify" element={<InviteVerified />} />
        <Route
          path="/shopify-oauth-screen"
          element={<ShopifyIntegrationMessage />}
        />
        {/*<Route path="/invite/*" element={<AdminGuard/>}>*/}
        {/*    <Route path="verify" element={<InviteVerified/>}/>*/}
        {/*    <Route path="*" element={<Navigate to={'/not-found'}/>}/>*/}
        {/*</Route>*/}
        <Route path="*" element={<Navigate to={"/not-found"} />} />
      </Routes>
    </HistoryRouter>
  );
};

const disableLogging = () => {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
};

const startApp = () => {
  if (process.env.NODE_ENV === "production") {
    disableLogging();
  }

  const element: HTMLElement | null = document.getElementById("root");
  const connectedApp = (
    <React.StrictMode>
      <DependencyInjectionProvider container={container}>
        <StyledThemeProvider theme={LightTheme}>
          <GlobalStyle />
          <Provider store={store}>
            <ChakraProvider theme={lightThemeChakra}>
              <Suspense fallback={<Loading />}>
                <App />
              </Suspense>
            </ChakraProvider>
          </Provider>
        </StyledThemeProvider>
      </DependencyInjectionProvider>
    </React.StrictMode>
  );
  ReactDOM.render(connectedApp, element);
};

googleService.initGoogleApi();
amazonSellerService.initAmazonSdk();
facebookService.initFacebookSdk().then(startApp);

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {changeStateNotification, notificationSelector, NotificationState, NotificationStatus} from 'store/notifcations';
import {notificationClock} from 'utils/icons/notifcations-menu';
import {styles} from './styles';
import ReactTimeAgo from 'react-time-ago';
import {Box, Flex, Spinner, useDisclosure} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {fetchSystemEvent, systemEventStateSelector} from "../../../store/system-events";
import {ApplyChangesModal} from "../../apply-changes-modal/ApplyChangesModal";
import {organizationDataLanguageSelector, organizationSelector, triggerAirflow} from "@store";
import {isApplyModalOpenSelector, openApplyModal} from "../../../store/ui";

interface DotProps {
    status?: NotificationStatus;
    title?: string;
    text?: string;
    time?: string;
    state?: NotificationState;
    id?: string;
    createdAt?: string;
    isOpen?: boolean;
    onOpen?: (e?: any) => void;
    onClose?: (e?: any) => void;
}

interface Props {
    isOpen: boolean;
    onOpen: (e?: any) => void;
    onClose: (e?: any) => void;
    closeMenu: (e?: any) => void;
}

export const SystemEventsMenu = (props: Props) => {
    // hooks
    const {t} = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const systemEvents = useSelector(systemEventStateSelector);
    const organization = useSelector(organizationSelector);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const isApplyModalOpen = useSelector(isApplyModalOpenSelector);
    //helpers
    const RenderDotRow = (props: DotProps) => {
        let circleColor = "";
        let systemFlag = false;
        switch (props.status) {
            case (NotificationStatus.SYSTEM):
                circleColor = "#7031EB";
                systemFlag = true;
                break;
            case (NotificationStatus.WARNING):
                circleColor = "#F0BB09";
                break;
            case (NotificationStatus.BROKEN):
                circleColor = "#F7686A";
                break;
        }

        return (
            <styles.DotRow>
                <styles.Circle color={circleColor}/>
                <styles.Spacer width={12}/>
                <Flex direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <styles.RowText system={systemFlag}>
                        {!!props?.title ? (props.title.length > 35 ? props.title?.slice(0, 35)?.toString() : props.title) : ''}
                    </styles.RowText>
                    <styles.RowText system={systemFlag}>
                        {!!props?.time ? <ReactTimeAgo date={Date.parse(props.time)}/> : ''}
                    </styles.RowText>
                </Flex>
            </styles.DotRow>
        );
    };


    const Header = () => {
        return (
            <styles.SystemTitleWrapper>
                <Flex margin={["0px", "auto"]} justifyContent={"space-between"} w={"90%"}>
                    <Flex flexDirection={"column"}>
                        <Flex paddingBottom={"4px"}>
                            {notificationClock()}
                            <styles.Spacer width={5}/>
                            <styles.TextTitleUpdate> {t('SYSTEM_UPDATES', {ns: 'translation'})}</styles.TextTitleUpdate>
                        </Flex>
                        {/*<styles.NextUpdate>
                            Next data refresh: 00:28:35
                        </styles.NextUpdate>*/}
                    </Flex>
                    {/*<Box>
                        <styles.LastUpdate>Last full system sync: 2 hours ago</styles.LastUpdate>
                    </Box>*/}
                </Flex>
            </styles.SystemTitleWrapper>
        )
    }

    const RenderBody = () => (
        <Flex justifyContent={systemEvents?.loading ? "center" : ""} alignItems={systemEvents?.loading ? "center" : ""}
              h={"135px"} overflowY={!systemEvents?.loading ? "auto" : "hidden"} margin={["0px", "auto"]} w={"90%"}
              flexDirection={"column"} paddingTop={"15px"}>
            {!systemEvents?.loading ? systemEvents?.data?.map((item, index) => {
                return <RenderDotRow
                    status={NotificationStatus.SYSTEM}
                    title={item?.title}
                    text={item?.message}
                    time={item?.updatedAt}
                    key={index} id={item?._id}/>;
            }) : <Spinner/>}
        </Flex>
    )

    const RenderFooter = () => {
        const isInProgress = false;

        return (<Flex margin={["0px", "auto"]} w={"91%"} justifyContent={"flex-end"} paddingTop={"15px"}>
            <styles.ApplyChangesButton inProgress={isInProgress} onClick={() => {
                if (!isInProgress) {
                    /*dispatch(openApplyModal({isApplyModalOpen: true}))*/
                    props?.onOpen()
                }
            }}>
                {isInProgress ? <Spinner/> : "Apply Changes"}
            </styles.ApplyChangesButton>

        </Flex>)
    }

    const SystemUpdateNotification = () => {
        return (
            <styles.SystemUpdate>
                <Header/>
                <RenderBody/>
                <RenderFooter/>
            </styles.SystemUpdate>
        );
    };


    //render
    return (
        <styles.Wrapper>
            <ApplyChangesModal onOpen={props?.onOpen} onCancelClick={() => {
                props?.closeMenu()
            }} isOpen={props?.isOpen} nextUpdateModal onClose={props?.onClose} onApply={() => {
                !!organization?.id && !!dataLanguage?.id && dispatch(triggerAirflow({
                    organizationId: organization?.id,
                    dataLanguageId: dataLanguage?.id
                }));
                props?.onClose();
                props?.closeMenu();

            }}/>
            <styles.ContentWrapperSystem>
                <SystemUpdateNotification/>
            </styles.ContentWrapperSystem>
        </styles.Wrapper>
    );
};

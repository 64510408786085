import styled from "styled-components";

export namespace styles {
  export const Span = styled.div<{ color?: string }>`
    width: 13px;
    height: 13px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin: 0 3px 0 0;
  `;
  export const LabelsWrappers = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    flex-wrap: wrap;
    align-items: center;
  `;
  export const Label = styled.div`
    display: flex;
    color: #7a86a1;
    font-size: 14px;
    align-items: center;
    margin: 0 0 0 7px;
  `;
  export const Wrapper = styled.div`
    height: 230px;
    width: 230px;
    margin: 55px 0 0 0;
  `;
}

export default styles;

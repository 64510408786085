import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PnLWaterfallChart } from "./pnl-waterfall-chart/PnLWaterfallChart";
import React, {useEffect, useRef, useState} from "react";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import { PanelMenu } from "components/panel-menu/PanelMenu";
import { DatepickerRange } from "components/datepicker-range/DatepickerRange";
import { Toggle, ToggleOption } from "../../components/toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanGraphData, downloadPnlCsv,
  fetchGraphData,
  fetchKpiData,
  fetchPnlReports,
  fetchPnlTableConfig,
  kpiDataSelector,
  kpiPnlSelector,
  kpiStateSelector,
  resetPnlPageFilers,
  setPnlPageFilters,
} from "../../store/kpis";
import { organizationLoadingSelector, organizationSelector } from "@store";
import {Box, Flex, Spinner, toast, Tooltip} from "@chakra-ui/react";
import {
  addToasts,
  dateRangeSelector,
  ExploresEnum,
  openDefaultExploreView,
  selectedChannelsSelector,
} from "store/ui";
import { channelsStateSelector } from "store/channels";
import MoreInformation from "components/more-information/MoreInformation";
import { MenuButton } from "../../components/menu-generic/MenuGenericButton";
import StoreChannelFilter from "../../components/store-channel-filter/StoreChannelFilter";
import PnlTable from "../../components/pnl-table/PnlTable";
import {
  PnlReportGroupBy,
  PnlReportQueryDto,
  PnlReportShowBy,
} from "../../services/kpi/kpi.service";
import {BiCopyAlt} from "react-icons/bi";
import {Toast, ToastStatus} from "../../components/toast/Toast";
import {fetchProductsFieldsData, fetchProductsMetaData} from "../../store/products";
import {BsFileTextFill} from "react-icons/bs";
import {useCurrencySign} from "../../utils/custom-hooks/useCurrencySign";
import {pnlDataLanguageNamesSelector} from "../../store/pnl";
import {useExploreView} from "../../utils/custom-hooks/useExploreView";
import InformationWrapper from "../../components/InformationWrapper/InformationWrapper";

export enum Unit {
  MARGIN,
  VALUE,
}

enum GroupBy {
  DAY,
  WEEK,
  MONTH,
  NONE
}

export enum ShowBy {
  DATE = "DATE",
  CHANNEL = "CHANNEL",
  PRODUCT = "PRODUCT",
}

export const WideViewsFinancialNew = () => {
  // hooks helpers
  const { t } = useTranslation(["translation", "translation"]);
  const [isMoreInfoDialogOpen, setMoreInfoDialogOpen] = useState(false);
  const [tableShowBy, setTableShowBy] = useState<string>(ShowBy.DATE);
  const [unit, setUnit] = useState<Unit>(Unit.VALUE);
  const selectedChannels = useSelector(selectedChannelsSelector);
  const currency = useCurrencySign();
  const [groupByStatus, setGroupByStatus] = useState<GroupBy>(GroupBy.DAY);

  const organization = useSelector(organizationSelector);
  const pnlData = useSelector(kpiPnlSelector)?.data;
  // hooks redux
  const dispatch = useDispatch();
  const organizationId = useSelector(organizationSelector);
  const kpiData = useSelector(kpiDataSelector);
  const organizationLoader = useSelector(organizationLoadingSelector);
  const kpis = useSelector(kpiStateSelector);
  const datesSelector = useSelector(dateRangeSelector);
  const channels = useSelector(channelsStateSelector);
  const [
    isFinancialTableMoreInfoDialogOpen,
    setIsFinancialTableMoreInfoDialogOpen,
  ] = useState(false);

  const [allExpendedRows, setAllExpendedRows] = useState<{ [key: string]: boolean; }>({});
  const ref = useRef<HTMLTableElement>(null);
  const pnlNames = useSelector(pnlDataLanguageNamesSelector)?.data;
  const maxDailyDays = 365;
  const maxWeekDays = 365;
  const maxMonthDays = 365 * 2; // 2 years
  const minWeekDays = 7;
  const minMonthDays = 30;
  const tableRef = useRef<any>(null);

  useEffect(() => {
    dispatch(resetPnlPageFilers())
  }, [])


  // useEffect(() => {
  //   if(!!organization?.id && !!datesSelector?.startDate && !!datesSelector?.endDate && !!selectedChannels){
  //     dispatch(fetchGraphData({organizationId: organization?.id, channels: selectedChannels, fromDate: datesSelector?.startDate, toDate: datesSelector?.endDate}));
  //   }
  //   return () => {
  //     dispatch(cleanGraphData());
  //   }
  // }, [selectedChannels, organization?.id, datesSelector?.startDate, datesSelector?.endDate])



  useEffect(() => {
    const financeFields: string[] = ['grossRevenue', 'netRevenue', 'grossProfit', 'contributionProfit', 'freeOnBoard'
    , 'refunds', 'totalCogs', 'totalDeduction', 'totalFba', 'totalShipping', 'transactionFees', 'totalTax', 'marketplaceFees', 'refundCosts', "marketplaceFees",
      'fulfillmentFees', 'discount', 'marketingCost'];
    if (
      !!organizationId?.id &&
      !!datesSelector?.endDate &&
      !!datesSelector?.startDate &&
        !!channels?.channels
    ) {
      if(pnlNames){
        dispatch(
            fetchKpiData({
              organizationId: organizationId?.id,
              fromDate: datesSelector?.startDate,
              toDate: datesSelector?.endDate,
              kpisType: 0,
              channels: channels?.channels,
              cogsFields: true,
              pnlFields: true,
              fields: [...financeFields, ...pnlNames]


            })
        );
        dispatch(
            fetchKpiData({
              organizationId: organizationId?.id,
              fromDate: datesSelector?.startDate,
              toDate: datesSelector?.endDate,
              kpisType: 0,
              type: 2,
              channels: channels?.channels,
              cogsFields: true,
              pnlFields: true,
              fields: financeFields

            })
        );
      } else {
        dispatch(
            fetchKpiData({
              organizationId: organizationId?.id,
              fromDate: datesSelector?.startDate,
              toDate: datesSelector?.endDate,
              kpisType: 0,
              channels: channels?.channels,
              cogsFields: true,
              pnlFields: true,
              fields: financeFields


            })
        );
        dispatch(
            fetchKpiData({
              organizationId: organizationId?.id,
              fromDate: datesSelector?.startDate,
              toDate: datesSelector?.endDate,
              kpisType: 0,
              type: 2,
              channels: channels?.channels,
              cogsFields: true,
              pnlFields: true,
              fields: financeFields

            })
        );
      }

    }
  }, [
    datesSelector?.endDate,
    datesSelector?.startDate,
    dispatch,
    organizationId?.id,
    organizationId?.dataLanguage,
    channels?.channels,
    pnlNames
  ]);

  const getGroupByStatus = (groupByStatus: GroupBy) :PnlReportGroupBy => {
    switch (groupByStatus){
      case GroupBy.DAY:
        return PnlReportGroupBy.DAY
      case GroupBy.WEEK:
        return PnlReportGroupBy.WEEK
      case GroupBy.MONTH:
        return PnlReportGroupBy.MONTH
      default:
        return PnlReportGroupBy.DAY
    }
  }
  const getShowBy = (showByStatus: any) :PnlReportShowBy => {
    switch (showByStatus){
      case ShowBy.DATE:
        return PnlReportShowBy.DATE
      case ShowBy.CHANNEL:
        return PnlReportShowBy.CHANNEL
      case ShowBy.PRODUCT:
        return PnlReportShowBy.PRODUCT

      default:
        return PnlReportShowBy.DATE
    }
  }

/*  useEffect(() => {
    if(!!organization?.id){
      dispatch(fetchProductsFieldsData({organizationId: organization?.id}))
    }
  }, [organization?.id])*/

  useEffect(() => {


    fetchPnlTableData();
  }, [organization?.id,
    datesSelector?.startDate,
    datesSelector?.endDate,
    selectedChannels,
    tableShowBy,
    kpis?.pnlPageFilters?.groupBy])

  const fetchPnlTableData = () => {


    if(!!organization?.id && !!datesSelector?.startDate && !!datesSelector?.endDate && !!selectedChannels && kpis?.pnlPageFilters?.groupBy !== undefined && !!tableShowBy){

      console.log(organization?.id,
          datesSelector?.startDate,
          datesSelector?.endDate,
          tableShowBy,
          kpis?.pnlPageFilters?.groupBy)

      const showBy = getShowBy(tableShowBy)
      const query:PnlReportQueryDto = {
        fromDate: datesSelector?.startDate,
        toDate: datesSelector?.endDate,
        groupBy: kpis?.pnlPageFilters?.groupBy,
        showBy: showBy,
        channels: selectedChannels
      }
      dispatch(fetchPnlReports({organizationId: organization?.id, searchQueryDto: query}));
      dispatch(fetchPnlTableConfig({organizationId: organization?.id}))
    }
  }



  const pnlOverviewMoreInfoDialogProps = [
    {
      title: "P&L - Overview",
      subtitle:
        "Start with this overview to understand which sections drive profitability / loss",
      description:
        "Colors Indicate KPI performance Vs your set targets. Drill-down to reveal the underlying drivers of these KPIs using the ‘explore’ buttons.",
      tip: "TIP: Edit your organization KPI framework from the ‘Data Strategy’ page.",
      videoUrl: "https://www.youtube.com/watch?v=Rjm129AoRno",
      videoTitle: "Best Practices",
      videoSubtitle: "How to use this view?",
      videoDescription: "5 min explanatory video from our COO",
      videoLinkTitle: "Click to watch",
    },
    {
      title: "P&L - Financial Table",
      //subtitle: 'Start with this overview to understand which sections drive profitability / loss',
      description:
        "Use this overview to understand which sections in your financial funnel drive profitability/loss.\nIn this section, you can drill down to compare P&L performance over time and store channels. ",
      tip: "Tip: P&L structure can be edited on the `P&L` / `Account health` page.",
    },
    {
      title: "Expenses Breakdown",
      //subtitle: 'Start with this overview to understand which sections drive profitability / loss',
      description:
        "Use this overview to understand and explore how expenses are divided in your business.",
      tip: "Tip: You can adjust your COGS expenses on the `COGS` / `Account Health` page.",
    },
  ] as MoreInfoDialogProps[];
  const openPnlOverviewModeInfoDialog = (isOpen: boolean) =>
    setMoreInfoDialogOpen(isOpen);

  const setUnitHelper = (name: string) => {
    switch (name) {
      case "MARGIN":
        setUnit(Unit.MARGIN);
        break;
      case "VALUE":
        setUnit(Unit.VALUE);
        break;
      default:
        setUnit(Unit.MARGIN);
    }
  };

  const menuButtonsDataValue: MenuButton[] = [
    {
      title: "Margin",
      onClick: () => setUnitHelper("MARGIN"),
    },
    {
      title: "Value",
      onClick: () => setUnitHelper("VALUE"),
    },
  ];

  const menuButtonsDataShowBy: MenuButton[] = [
    {
      title: "Date",
      onClick: () => {
        setGroupByStatus(GroupBy.DAY);
        setTableShowBy(ShowBy.DATE);
      },
    },
    {
      title: "Sales Channel",
      onClick: () => {
        setGroupByStatus(GroupBy.NONE);
        setTableShowBy(ShowBy.CHANNEL);
      },
    },
    {
      title: "Product",
      onClick: () => {
        setGroupByStatus(GroupBy.NONE);
        setTableShowBy(ShowBy.PRODUCT);
      },
    },
  ];

  const renderPnlWaterfallHeader = () => {
      return (
          <styles.SectionHeader>
            <styles.Title>
              <InformationWrapper text={t('PNL_OVERVIEW', {ns: 'translation'})} displayLongText/>
            </styles.Title>
            <styles.ButtonsWrapper>
              <div style={{ width: "5px" }} />
              <Box>
                <styles.ShowByText>
                  {t("SHOW_BY", { ns: "translation" })}
                </styles.ShowByText>
              </Box>
              <div style={{ width: "5px" }} />
              <PanelMenu
                  data={menuButtonsDataValue}
                  title={unit === Unit.MARGIN ? "Margin" : "Value"}
              />
              <div style={{ width: "5px" }} />
              <StoreChannelFilter />
              <div style={{ width: "5px" }} />
              <DatepickerRange
                  fromPnlPage={true}
                  backgroundColor="white"
                  defaultsDate={datesSelector.preset}
                  onApply={(range) => {}}
              />
            </styles.ButtonsWrapper>
          </styles.SectionHeader>
      )
  }

  const renderWaterfallGraph = () => {

    const graphData = {}
    const graphDataTarget = {
      grossRevenue: 0,
      grossProfit: 0,
      netRevenue: 0,
      contributionProfit: 0,
    }

    kpis?.pnlReports?.data?.forEach((pnlRow) => {
      if(pnlRow?.name === 'Gross Revenue'){
        graphData['grossRevenue'] = pnlRow?.Total
      }
      if(pnlRow?.name === 'Gross Profit'){
        graphData['grossProfit'] = pnlRow?.Total
      }
      if(pnlRow?.name === 'Net Revenue'){
        graphData['netRevenue'] = pnlRow?.Total
      }
      if(pnlRow?.name === 'Contribution Profit'){
        graphData['contributionProfit'] = pnlRow?.Total
      }
    })

    return (
        <>
          {!kpis?.isPnlTableLoading || kpis?.isPnlTableLoading === undefined ? (
              <PnLWaterfallChart actual={graphData} targets={graphDataTarget} unit={unit} />
          ) : (
              <Flex h={"550px"} justifyContent="center" alignItems={"center"}>
                <Spinner />
              </Flex>
          )}
        </>
    )
  }



  useEffect(() => {
    handleChangingGroupBySelectedDates()
  }, [datesSelector?.startDate, datesSelector?.endDate]);

  const handleChangingGroupBySelectedDates = () => {
    const startDateString: string | undefined = datesSelector?.startDate;
    const endDateString: string | undefined = datesSelector?.endDate;

    if (startDateString && endDateString) {
      const startDate: Date = new Date(startDateString);
      const endDate: Date = new Date(endDateString);
      const timeDiff: number = Math.abs(endDate.getTime() - startDate.getTime());
      const daysBetween: number = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if(kpis?.pnlPageFilters?.groupBy === PnlReportGroupBy.WEEK){
        if(daysBetween < minWeekDays){
          setGroupByStatus(GroupBy.DAY)
        }
      }


    }
  }

  const checkIfToggleOptionIsDisabled = (groupBy: PnlReportGroupBy) : boolean => {


    const startDateString: string | undefined = datesSelector?.startDate;
    const endDateString: string | undefined = datesSelector?.endDate;

    if (startDateString && endDateString) {
      const startDate: Date = new Date(startDateString);
      const endDate: Date = new Date(endDateString);

      const timeDiff: number = Math.abs(endDate.getTime() - startDate.getTime());
      const daysBetween: number = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      
      if(groupBy === PnlReportGroupBy.DAY){
        if(daysBetween > maxDailyDays){
          return true
        }
      }
      if(groupBy === PnlReportGroupBy.WEEK){
        if(daysBetween < minWeekDays){
          return true
        }
        if(daysBetween > maxWeekDays){
          return true
        }
      }
      if(groupBy === PnlReportGroupBy.MONTH){
        if(daysBetween < minMonthDays){
          return true
        }
        if(daysBetween > maxMonthDays){
          return true
        }
      }

    }

    return false

  }

  const handleExpandAllClick = () => {
    const expendedObj: { [key: string]: boolean; } = {}
    pnlData?.forEach((row: any, i: number) => {
      if(row.hasOwnProperty('subRows') && row['subRows']?.length > 0){
        console.log(row)
      }
      expendedObj[`${i}`] = true
    })
    setAllExpendedRows(expendedObj)
  }

  const handleCollapseAllClick = () => {
    const expendedObj: { [key: string]: boolean; } = {}
    pnlData?.forEach((row: any, i: number) => {

      expendedObj[`${i}`] = false
    })
    setAllExpendedRows(expendedObj)
  }

  const downloadAsCsv = () => {
    const showBy = getShowBy(tableShowBy)
    dispatch(downloadPnlCsv({
      organizationId: organization?.id!,
      fromDate: datesSelector?.startDate!,
      toDate:  datesSelector?.endDate!,
      channels:  channels?.channels.map((channel: any) => channel.id),
      groupBy: kpis?.pnlPageFilters?.groupBy,
      showBy: showBy,
      fileName: 'pnl-report',
      currencySign: currency
    }))
  }

  const handleCopyTable = () => {
    const table = tableRef.current;
    if (!table) return;

    // Create a range to select the table contents
    const range = document.createRange();
    range.selectNode(table);

    // Clear any existing selection and select the table
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Copy the selected table contents to the clipboard
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        dispatch(addToasts({
          toasts: [
            {
              title: 'Copied to clipboard!',
              isClosable: true,
              status: ToastStatus.SUCCESS,
              duration: 2500 //in milliseconds
            } as Toast
          ]
        }))
        console.log('Table copied to clipboard!');
      } else {
        console.log('Table copy failed.');
      }
    } catch (error) {
      console.log('Table copy failed.', error);
    }

    // Clear the selection
    if (selection) {
      selection.removeAllRanges();
    }
  }



  const PnlTableHeader = () => {

    return (
        <styles.SectionHeader>
          <styles.Title>
            <InformationWrapper text={t('PNL_FINANCIAL_TABLE', {ns: 'translation'})} displayLongText/>


            <Flex marginLeft={'5px'} h={'100%'}  alignItems={'center'}>
              <Tooltip label={'Copy P&L Table'}>
                <styles.StyledHeaderButton onClick={handleCopyTable}><BiCopyAlt fontSize={'14px'} color={'white'}/></styles.StyledHeaderButton>
              </Tooltip>
            </Flex>
            <Flex marginLeft={'5px'} h={'100%'}  alignItems={'center'}>
              <Tooltip label={'Export to CSV'}>
                <styles.StyledHeaderButton onClick={downloadAsCsv}><BsFileTextFill fontSize={'14px'} color={'white'}/></styles.StyledHeaderButton>
              </Tooltip>
            </Flex>
          </styles.Title>

          <styles.ButtonsWrapper>

            {/*<Tooltip label={'Collapse All'}>*/}
            {/*  <styles.StyledHeaderButton onClick={handleCollapseAllClick}><BsArrowsCollapse color={'white'}/></styles.StyledHeaderButton>*/}
            {/*</Tooltip>*/}
            {/*<Tooltip label={'Expand All'}>*/}
            {/*  <styles.StyledHeaderButton onClick={handleExpandAllClick}><BsArrowsExpand color={'white'}/></styles.StyledHeaderButton>*/}
            {/*</Tooltip>*/}
            <Box w={3} />
            {tableShowBy === ShowBy.DATE ? (
                <>
                  <Flex alignItems={"center"}>
                    <styles.ShowByText>
                      {t("GROUP_BY", { ns: "translation" })}
                    </styles.ShowByText>
                    <Box w={5} />
                    <Toggle
                        fromPnlPage={true}
                        options={[
                          {
                            index: "day",
                            type: GroupBy.DAY,
                            title: t("DAY", { ns: "translation" }),
                            isDisabled: checkIfToggleOptionIsDisabled(PnlReportGroupBy.DAY)

                          },
                          {
                            index: "week",
                            type: GroupBy.WEEK,
                            title: t("WEEK", { ns: "translation" }),
                            isDisabled: checkIfToggleOptionIsDisabled(PnlReportGroupBy.WEEK)

                          },
                          {
                            index: "month",
                            type: GroupBy.MONTH,
                            title: t("MONTH", { ns: "translation" }),
                            isDisabled: checkIfToggleOptionIsDisabled(PnlReportGroupBy.MONTH)
                          },
                        ]}
                        onChange={(option: ToggleOption) => {
                            setGroupByStatus(option.type);
                            dispatch(setPnlPageFilters({groupBy: option?.type}))
                        }}
                    />
                  </Flex>
                </>
            ) : (
                <></>
            )}

            <Box w={5} />
            <Flex alignItems={"center"}>
              <styles.ShowByText>
                {t("SHOW_BY", { ns: "translation" })}
              </styles.ShowByText>
              <Box w={3} />
              <PanelMenu data={menuButtonsDataShowBy} title={tableShowBy} />
              <Box w={3} />

            </Flex>
          </styles.ButtonsWrapper>
        </styles.SectionHeader>
    )
  }

  const renderSpinner = () => {
    return (
        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} minH={'500px'}>
          <Spinner />
        </Flex>
    )
  }

  const renderPnlTableBody = () => {
    return (
        <styles.SectionHeader>
          {kpis?.isPnlTableLoading ? renderSpinner()  :
                <PnlTable groupBy={groupByStatus} showBy={tableShowBy} tableRef={tableRef} data={pnlData} allExpendedRows={allExpendedRows} setAllExpendedRows={setAllExpendedRows} />
          }
        </styles.SectionHeader>
    )
  }


  const renderPnLTableSection = () => {
    return (
      <>
        {PnlTableHeader()}
        <Box h={"20px"} />
        {renderPnlTableBody()}
        <Box h={"20px"} />

      </>
    );
  };


  const renderPnLWaterfallSection = () => {
    return (
        <>
          {renderPnlWaterfallHeader()}
          <Box h={"20px"} />
          {renderWaterfallGraph()}
        </>
    );
  };

  return (
    <styles.Wrapper>
      <styles.SmallerWrapperBackground>
        {renderPnLWaterfallSection()}
        <Box h={"50px"} />
        {renderPnLTableSection()}


      </styles.SmallerWrapperBackground>
    </styles.Wrapper>
  );
};

export const LightTheme = {
  id: "light",
  // primaryColor: '#484dff',
  // primaryColor: '#192089',
  primaryColor: "#7031EB",
  secondaryColor: "#1A0989",
  primaryShadow: `rgba(149, 157, 165, 0.3) 0px 8px 24px`,
  fullShadow: `rgba(149, 157, 165, 0.3) 0 0 24px`,

  accentColor: "#dcaa15",

  // general
  backgroundColor: "#f9f9ff",
  borderColor: "#f1f1f5",
  hoverColor: "#E4ECFC",

  // text
  textColor: "#161825",
  lightTextColor: "#f9f9ff",
  titleColor: "#161825",
  descriptionTextColor: "#92929e",

  // input
  inputBackgroundColor: "#fafafb",
  inputColor: "#92929e",
  inputBorderColor: "#f1f1f5",

  // link
  linkColor: "#097fff",

  // button
  buttonBackgroundColor: "#484dff",
  buttonColor: "#f9f9ff",

  infoColor: "#00529B",
  infoBackgroundColor: "#BDE5F8",
  successColor: "#4F8A10",
  successBackgroundColor: "#DFF2BF",
  warningColor: "#F0AA00",
  warningBackgroundColor: "#FEEFB3",
  errorColor: "#D8000C",
  errorBackgroundColor: "#FFD2D2",

  // sidebar
  sidebarTopColor: "#3E19B3",
  sidebarBottomColor: "#3E19B3",

  // table
  tableBorderColor: "#EDF2F7",
  tableEvenCellBackgroundColor: "#f9f9ff",
  tableOddCellBackgroundColor: "#ffffff",

  // header
  headerButtonBackgroundColor: "#7031EB",

  //chakra properties
  colors: {
    brand: {
      100: "rgba(115,49,236,1)",
      200: "rgba(115,49,236,0.9)",
      300: "rgba(115,49,236,0.8)",
      400: "rgba(115,49,236,0.7)",
      500: "rgba(115,49,236,0.6)",
      600: "rgba(115,49,236,0.5)",
      700: "rgba(115,49,236,0.4)",
      800: "rgba(115,49,236,0.3)",
      900: "rgba(115,49,236,0.2)",
    },
  },

  //fonts
  fonts: {
    roboto: {
      regular: "'Roboto', sans-serif",
    },
    header: "Poppins, sans-serif",
    /* menu: "'Manrope', sans-serif", */
    menu: "Poppins, sans-serif",
  },
};

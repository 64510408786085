import styled from 'styled-components';
import {Props} from "./ProgressBar";
import tinycolor from "tinycolor2";

export namespace styles {

    export const ProgressBarWrapper = styled.div<Props>`
      background-color: ${props => tinycolor(props.color).setAlpha(0.3).toHex8String()};
      border-radius: 6px;
      height: ${props => !!props?.height ? props.height :'6px' };
      width: 100%;
    `;

    export const ProgressBar = styled.div<Props>`
      width: ${props => (props.percentage.toString() + '%')};
      background-color: ${props => props.color};
      border-radius: 6px;
      height: ${props => !!props?.height ? props.height :'6px' };
    `;

}

export default styles;

import { Modal, ModalBody, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import {
    Box,
    Flex,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    Popover,
    PopoverTrigger,
    Radio,
    RadioGroup
} from "@chakra-ui/react";
import { styles } from './styles';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseAlertIcon } from "utils/icons/alert";
import { useDispatch, useSelector } from "react-redux";
import { kpiMarketingSelector } from "store/kpis";
import { CheckboxList } from "./CheckboxList";
import { MdArrowDropDown } from "react-icons/md";
import { KpisEnum } from "models/kpi-settings";
import DefaultCheckbox from "./DefaultCheckbox";
import { alertsSelector, createAlerts, editAlerts, fetchAlerts, updateLocalAlerts } from "store/alerts";
import { FaRegBell } from "react-icons/fa";
import { accountStateSelector } from "store/ui";
import {organizationSelector} from "@store";

enum Section {
    MARKETING,
    FINANCE,
    WEBSITE,
    CUSTOMERS_SUCCESS,

}

interface Form {
    name: string;
    trigger: {
        kpi: number;
        operator: string;
        value: number;
    }[];
    from: {
        platform?: string[];
        account?: string[];
        campaign?: string[];
        channel?: string[];
        product?: string[];
    };
    timeRange: string;
    isPrivate?: boolean;
    allChannels?: boolean;
    allProducts?: boolean;
}

interface Props {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void;
    kpi?: string;
    section?: Section;
    alertToEdit?: any;
}

interface Checkbox {
    name: string;
    selected: boolean;
    id?: string;
}

interface CheckboxData {
    platforms?: Checkbox[];
}

interface InputProps {
    options: string[];
    value: string,
    setValue: (e: any) => void;
}

export enum TriggerOperator {
    GreaterThen = "IS_GREATER_THEN",
    SmallerThen = "IS_SMALLER_THEN"
}

const getEnum = {
    "Gross Revenue": KpisEnum.GROSS_REVENUE,
    "Net Revenue": KpisEnum.NET_REVENUE,
    "ROAS": KpisEnum.RETURN_ON_AD_SPEND,
    "Spend": KpisEnum.MARKETING_SPEND,
    "CPO": KpisEnum.COST_PER_ORDER,
    "Gross Margin": KpisEnum.GROSS_MARGIN,
    "Contribution Profit": KpisEnum.CONTRIBUTION_PROFIT,
    "AOV": KpisEnum.AVERAGE_ORDERS_VALUE,
    "Orders": KpisEnum.ORDERS,
    "CR": KpisEnum.CONVERSION_RATE,
    "Sessions": KpisEnum.SESSIONS
};

enum PrivateRadioEnum {
    PRIVATE,
    PUBLIC
}


export const CreateAlert = (props: Props) => {

    const alerts = useSelector(alertsSelector);
    const timeRange = ["Yesterday", "Last 2 days", "Last 7 days", "Last 14 days", "Last month"];
    const defaultValues = {
        kpi: "",
        trigger: TriggerOperator.GreaterThen,
        number: "1.2",
        platform: "Platform",
        account: "Account",
        campaign: "Campaign",
        time: "Today",
        recipients: "1",
        channel: "Channel",
        product: "Product"
    };

    const [alertName, setAlertName] = useState<string>('');
    const [triggerKpi, setTriggerKpi] = useState<string>("ROAS");
    const [triggerValue, setTriggerValue] = useState("1.2");
    const [triggerOperator, setTriggerOperator] = useState<TriggerOperator>(TriggerOperator.GreaterThen);
    const [radioValue, setRadioValue] = useState<PrivateRadioEnum>(PrivateRadioEnum.PRIVATE);
    const [timeRangeState, setTimeRangeState] = useState("Yesterday");
    const [defaultChannels, setDefaultChannels] = useState<boolean>(true);
    const [defaultProducts, setDefaultProducts] = useState<boolean>(true);
    const [selectedPlatform, setSelectedPlatform] = useState<any>();
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState<any>();
    const [websiteDefaultChannels, setWebsiteDefaultChannels] = useState<boolean>(true);
    const [fromSection, setFromSection] = useState<number>(0);
    const [platformFlag, setPlatformFlag] = useState<boolean>(false);
    const [accountFlag, setAccountFlag] = useState<boolean>(false);
    const [platformFilter, setPlatformFilter] = useState<string[]>([]);
    const [accountFilter, setAccountFilter] = useState<string[]>([]);
    const [platformCheckbox, setPlatformCheckBox] = useState<CheckboxData>({});
    const [accountCheckbox, setAccountCheckBox] = useState<CheckboxData>({});
    const [campaignCheckbox, setCampaignCheckbox] = useState<CheckboxData>({});
    const [platformError, setPlatformError] = useState<boolean>(false);
    const [accountError, setAccountError] = useState<boolean>(false);
    const [formValidity, setFormValidity] = useState<boolean>(false);
    const organization = useSelector(organizationSelector);
    const [isPrivate, setIsPrivate] = useState(true);
    const marketingData = useSelector(kpiMarketingSelector);

    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();

    useEffect(() => {
        setAlertName("");
        setTriggerKpi("ROAS");
        setTriggerValue("1.2");
        setTriggerOperator(TriggerOperator.GreaterThen);
        setRadioValue(PrivateRadioEnum.PRIVATE);
        setTimeRangeState("Yesterday");

    }, [props.isOpen]);

    const prepareAlertToEditing = (oldAlert: any) => {
        setAlertName(oldAlert.name);
        setRadioValue(oldAlert.isPrivate ? PrivateRadioEnum.PRIVATE : PrivateRadioEnum.PUBLIC);
        const oldTrigger = oldAlert.trigger[0];
        const oldFrom = oldAlert.from;
        if (oldTrigger) {
            setIsPrivate(oldAlert.isPrivate);

            const kpiName = Object.entries(getEnum).filter((entry) => {
                return entry[1] === oldTrigger.kpi;
            });
            if (kpiName.length > 0) {
                setTriggerKpi(kpiName[0][0]);
            }
            if (oldTrigger.operator === "Is Greater Than" || oldTrigger.operator === "Is Smaller Than") {
                setTriggerOperator(oldTrigger.operator);
            }
            if (oldTrigger.value) {
                setTriggerValue(oldTrigger.value.toString());
            }
        }
        setTimeRangeState(oldAlert.timeRange);
        if (oldFrom) {
            if (oldFrom.platform && oldFrom.platform !== "") {

            }
        }

    };

    useEffect(() => {
        if (props.alertToEdit) {
            prepareAlertToEditing(props.alertToEdit);
        }
    }, [props.alertToEdit]);

    /*  useEffect(() => {
  if(!!props?.alertToEdit && !!setSelectedAccount && !!setSelectedPlatform){  // TODO fix at later date, when adding default to alert modal checkbox state cant be changed
      const platformFlag = selectedPlatform?.platforms.some((item: any) => item.selected === true);
      const accountFlag = selectedAccount?.platforms.some((item: any) => item.selected === true);

      if(!!props?.alertToEdit){
          const platform = JSON.parse(JSON.stringify(platformCheckbox));
          const accounts = JSON.parse(JSON.stringify(accountCheckbox));

          const defaultPlatformCheckbox = platformCheckbox?.platforms?.map((item:any) => {
              if(!!props?.alertToEdit?.from?.platform?.includes(item?.name)){
                  return {...item, selected: true}
              }else {
                  return item
              }
          })
          if("platforms" in platform){
              platform.platforms = defaultPlatformCheckbox;
          }
          const defaultAccountCheckbox = accountCheckbox?.platforms?.map((item:any) => {
              if(!!props?.alertToEdit?.from?.account?.includes(item?.name)){
                  return {...item, selected: true}
              }else {
                  return item
              }
          })
          if("platforms" in accounts){
              accounts.platforms = defaultAccountCheckbox;
          }
          setSelectedPlatform(platform);
          setSelectedAccount(accounts);
  }}

      }, [props?.alertToEdit,setSelectedAccount ,setSelectedPlatform])*/


    useEffect(() => {
        setFormValidity(false);
        if (fromSection === Section.MARKETING) {
            const platformFlag = selectedPlatform?.platforms.some((item: any) => item.selected === true);
            const accountFlag = selectedAccount?.platforms.some((item: any) => item.selected === true);
            /* const campaignFlag = selectedCampaign?.platforms.some((item: any) => item.selected === true); */

           /* if(!!props?.alertToEdit){  // TODO fix at later date, when adding default to alert modal checkbox state cant be changed
                const platform = JSON.parse(JSON.stringify(platformCheckbox));
                const accounts = JSON.parse(JSON.stringify(accountCheckbox));

                const defaultPlatformCheckbox = platformCheckbox?.platforms?.map((item:any) => {
                    if(!!props?.alertToEdit?.from?.platform?.includes(item?.name)){
                        return {...item, selected: true}
                    }else {
                        return item
                    }
                })
                if("platforms" in platform){
                    platform.platforms = defaultPlatformCheckbox;
                }
                const defaultAccountCheckbox = accountCheckbox?.platforms?.map((item:any) => {
                    if(!!props?.alertToEdit?.from?.account?.includes(item?.name)){
                        return {...item, selected: true}
                    }else {
                        return item
                    }
                })
                if("platforms" in accounts){
                    accounts.platforms = defaultAccountCheckbox;
                }
                /!*setSelectedPlatform(platform);
                setSelectedAccount(accounts);*!/
                const platformFlag = platform?.platforms.some((item: any) => item.selected === true);
                const accountFlag = accounts?.platforms.some((item: any) => item.selected === true);
                setFormValidity(platformFlag && accountFlag)
            }*/


            if (alertName.length > 0 && !!platformFlag && !!accountFlag/*  && !!campaignFlag */) {
                setFormValidity(true);
            }
            if (selectedPlatform?.platforms.length === 0 || selectedAccount?.platforms.length === 0 /* || selectedCampaign?.platforms.length === 0 */) {
                setFormValidity(true);
            }
        }
        if (fromSection === Section.FINANCE) {
            if (alertName.length > 0 && defaultChannels && defaultProducts) {
                setFormValidity(true);
            }
        }
        if (fromSection === Section.WEBSITE) {
            if (alertName.length > 0 && websiteDefaultChannels) {
                setFormValidity(true);
            }
        }
    }, [fromSection, alertName.length, selectedPlatform, selectedCampaign, selectedAccount, defaultChannels, defaultProducts, websiteDefaultChannels, props?.alertToEdit]);

    useEffect(() => {
        selectedData();
    }, [triggerKpi]);

    useEffect(() => {
        if (!!props.section) {
            setFromSection(props.section);
        }
    }, [props.section]);

    useEffect(() => {
        if (!!props?.kpi) {
            setTriggerKpi(props.kpi);
        }
    }, [props.kpi]);

    useEffect(() => {
        if(!props?.alertToEdit){
        setSelectedAccount(null);
        setSelectedCampaign(null);

        }
    }, [selectedPlatform, props?.alertToEdit]);

    useEffect(() => {
        setSelectedCampaign(null);
    }, [selectedAccount]);

    useEffect(() => {
        resetFilters();
    }, [props.onClose, props.isOpen, fromSection]);

    useEffect(() => {
        let flag;
        if (platformFlag) {
            flag = accountFlag;
        } else {
            flag = false;
        }
        if (platformFlag) {
            setPlatformError(false);
        }
        if (flag) {
            setAccountError(false);
        }
    }, [accountFlag, platformFlag, props.onClose, props.isOpen]);

    useEffect(() => {
        const platformFilter: string[] = [];
        const accountFilter: string[] = [];
        const platform = selectedPlatform?.platforms?.some((item: any) => item?.selected === true);
        const account = selectedAccount?.platforms?.some((item: any) => item?.selected === true);
        if (account === undefined) {
            setAccountFlag(false);
        }
        if (platform === undefined) {
            setPlatformFlag(false);
        }
        if (platform !== undefined) {
            setPlatformFlag(platform);
            selectedPlatform?.platforms?.forEach((item: any) => {
                if (platformFilter.includes(item.name)) {
                    const index = platformFilter.indexOf(item.name);
                    if (index > -1) {
                        platformFilter.splice(index, 1);
                        return;
                    }
                    return;
                }
                if (item.selected) {
                    platformFilter.push(item.name);
                }
            });
        }
        if (account !== undefined) {
            setAccountFlag(account);
            selectedAccount?.platforms?.forEach((item: any) => {
                if (accountFilter.includes(item.name)) {
                    const index = accountFilter.indexOf(item.name);
                    if (index > -1) {
                        accountFilter.splice(index, 1);
                        return;
                    }
                    return;
                }
                if (item.selected) {
                    accountFilter.push(item.name);
                }
            });
        }
        setPlatformFilter(platformFilter);
        setAccountFilter(accountFilter);
    }, [selectedAccount, selectedPlatform]);

    useEffect(() => {
        let newData = marketingData;
        if (accountFilter.length > 0) {
            newData = newData?.filter((item) => {
                if (!!item?.wvAccountId) {
                    return accountFilter.includes(item.wvAccountId);
                }
                return null;
            });
            setCampaignCheckbox(getDataFormat("campaignId", "platforms", newData));
            return;
        }
        if (platformFilter.length > 0) {
            newData = newData?.filter((item) => {
                if (!!item?.platform) {
                    return platformFilter.includes(item?.platform);
                }
                return null;
            });
            setAccountCheckBox(getDataFormat("wvAccountId", "platforms", marketingData));
        } else {
            setPlatformCheckBox(getDataFormat("platform", "platforms", marketingData));
            setAccountCheckBox(getDataFormat("wvAccountId", "platforms", marketingData));
            /* setCampaignCheckbox(getDataFormat("campaignId", "platforms", marketingData)); */
        }
    }, [accountFilter, marketingData, platformFilter, props.isOpen, props.onClose]);

    useEffect(() => {
        if (!!marketingData && !!getDataFormat("platform", "platforms", marketingData)) {
            setPlatformCheckBox(getDataFormat("platform", "platforms", marketingData));
            setAccountCheckBox(getDataFormat("wvAccountId", "platforms", marketingData));
            /* setCampaignCheckbox(getDataFormat("campaignId", "platforms", marketingData)); */
        }

    }, [marketingData]);

    const getDataFormat = (key: string, name: string, data: any) => {
        const checkIfExist: string[] = [];
        const dataToReturn: Checkbox[] = [];
        if (key === "wvAccountId") {
            data?.forEach((item: any) => {
               /* if(!!props?.alertToEdit){  // TODO fix at later date, when adding default to alert modal checkbox state cant be changed
                    if (!!item[key] && !checkIfExist?.includes(item["wvAccountId"])) {
                        checkIfExist.push(item["wvAccountId"]);
                        if(props?.alertToEdit?.from?.account?.includes(item[key])){
                            dataToReturn.push({ name: item[key], selected: true, id: item.id });
                        }else {
                        dataToReturn.push({ name: item[key], selected: false, id: item.id });
                        }
                    }
                    return
                }*/
                if (!!item[key] && !checkIfExist?.includes(item["wvAccountId"])) {
                    checkIfExist.push(item["wvAccountId"]);
                    dataToReturn.push({ name: item[key], selected: false, id: item.id });
                }
            });
        } else {
            data?.forEach((item: any) => {
               /* if(!!props?.alertToEdit){  // TODO fix at later date, when adding default to alert modal checkbox state cant be changed
                    if (!!item[key] && !checkIfExist?.includes(item[key])) {
                        checkIfExist.push(item[key]);
                        if(props?.alertToEdit?.from?.platform?.includes(item[key])){
                            dataToReturn.push({ name: item[key], selected: true, id: item.id });
                        }else {
                            dataToReturn.push({ name: item[key], selected: false, id: item.id });
                        }
                    }
                    return
                }*/
                if (!!item[key] && !checkIfExist?.includes(item[key])) {
                    checkIfExist.push(item[key]);
                    dataToReturn.push({ name: item[key], selected: false, id: item.id });
                }
            });
        }

        return ({ [name]: dataToReturn });
    };

    const resetFilters = () => {
        setPlatformError(false);
        setAccountError(false);
        setPlatformFilter([]);
        setAccountFilter([]);
        setPlatformFlag(false);
        setAccountFlag(false);
    };

    const getFormData = () => {
        const platforms = selectedPlatform?.platforms?.filter((item: any) => item.selected);
        const accounts = selectedAccount?.platforms?.filter((item: any) => item.selected);
        const campaigns = selectedCampaign?.platforms?.filter((item: any) => item.selected);
        let time = timeRangeState;
        if (timeRangeState === "") {
            time = "Today";
        }
        const formToSubmit: Form = {
            name: alertName,
            trigger: [{
                kpi: getEnum[triggerKpi],
                operator: triggerOperator,
                value: parseFloat(triggerValue)
            }],
            from: {
                platform: platforms?.map((item: any) => item.name),
                account: accounts?.map((item: any) => item.name),
                channel: [""],
                product: [""]
            },
            timeRange: time,
            isPrivate: radioValue === PrivateRadioEnum.PRIVATE,
            allChannels: fromSection === 1 ? defaultChannels : fromSection === 2 ? websiteDefaultChannels : false,
            allProducts: fromSection === 1 ? defaultProducts : false
        };
        return formToSubmit;
    };

    const performSubmission = (data: any) => {
        if (props.alertToEdit) {

            data.id = props.alertToEdit.id;
            !!organization?.id && dispatch(editAlerts({data, organizationId: organization?.id}));
            /* dispatch(fetchAlerts()); */
            /* dispatch(updateLocalAlerts({ data: [data, ...alerts.data] })); */

        } else {
           !!organization?.id && dispatch(createAlerts({data, organizationId: organization?.id }));
            /*  dispatch(fetchAlerts()); */

            dispatch(updateLocalAlerts({ data: [data, ...alerts.data] }));
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        const data = getFormData();
        if (fromSection === 0) {
            delete data["allChannels"];
            delete data["allProducts"];
            delete data.from["channel"];
            delete data.from["product"];
            performSubmission(data);
        }
        if (fromSection === 1) {
            delete data.from["account"];
            delete data.from["platform"];
            delete data.from["campaign"];
            performSubmission(data);

        }
        if (fromSection === 2) {
            delete data.from["account"];
            delete data.from["platform"];
            delete data.from["campaign"];
            delete data.from["product"];
            delete data["allProducts"];
            performSubmission(data);
        }
        props.onClose();
    };

    const selectedData = () => {
        if (triggerKpi === "ROAS" || triggerKpi === "Spend" || triggerKpi === "CPO") {
            setFromSection(Section.MARKETING);
        }
        if (triggerKpi === "Gross Revenue" || triggerKpi === "Net Revenue" || triggerKpi === "Gross Margin" || triggerKpi === "Contribution Profit") {
            setFromSection(Section.FINANCE);
        }
        if (triggerKpi === "AOV" || triggerKpi === "Orders" || triggerKpi === "CR" || triggerKpi === "Sessions") {
            setFromSection(Section.WEBSITE);
        }

        return;
    };

    const SelectInput = ({ options, value, setValue }: InputProps) => {

        return (
            < Popover>
                <PopoverTrigger>
                    <styles.ButtonPopover>
                        <Box w={90} overflow="hidden" textOverflow={"ellipsis"} whiteSpace="nowrap">
                            {t(value, { ns: "translation" })}
                        </Box>
                        <MdArrowDropDown />
                    </styles.ButtonPopover>
                </PopoverTrigger>
                <styles.StyledPopoverContent w="max-content">

                    {options.map((option, i) => {
                        return <styles.option onClick={() => {
                            if (!!setValue) {
                                setValue(option);
                            }
                        }} key={i}>{t(option, { ns: "translation" })}
                        </styles.option>;
                    })}

                </styles.StyledPopoverContent>
            </Popover>
        );
    };

    const TriggerValueInput = () => {
        const parse = (val: string) => val.replace(/^\$/, '');
        const format = (val: string) => /*`%` +*/ val;
        return (
            <styles.StyledNumberInput
                onChange={(e: any) => setTriggerValue(parse(e))}
                value={format(triggerValue)}
            >
                <styles.StyledNumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </styles.StyledNumberInput>
        );
    };

    const AlertRecipientsRadio = () => {
        return (
            <RadioGroup paddingTop={5} display={"flex"} width={"30%"} justifyContent="space-between"
                onChange={(e) => {
                    setRadioValue(e === "1" ? PrivateRadioEnum.PRIVATE : PrivateRadioEnum.PUBLIC)
                }} defaultValue={ isPrivate ? "1" : "2"}>
                <styles.StyledRadio marginRight={10} value='1'>{t("PRIVATE", { ns: "translation" })}</styles.StyledRadio>
                <styles.StyledRadio value='2'>{t("PUBLIC", { ns: "translation" })}</styles.StyledRadio>
            </RadioGroup>
        );
    };


    const renderFromSection = () => {
        switch (fromSection) {
            case (Section.MARKETING):
                let flag = false;
                if (platformFlag) {
                    flag = accountFlag;
                } else {
                    flag = false;
                }
                if (!!props?.alertToEdit) {
                    const platform = JSON.parse(JSON.stringify(platformCheckbox));
                    const accounts = JSON.parse(JSON.stringify(accountCheckbox));

                    const defaultPlatformCheckbox = platformCheckbox?.platforms?.map((item:any) => {
                        if(!!props?.alertToEdit?.from?.platform?.includes(item?.name)){
                            return {...item, selected: true}
                        }else {
                            return item
                        }
                    })
                    if("platforms" in platform){
                    platform.platforms = defaultPlatformCheckbox;
                    }
                    const defaultAccountCheckbox = accountCheckbox?.platforms?.map((item:any) => {
                        if(!!props?.alertToEdit?.from?.account?.includes(item?.name)){
                            return {...item, selected: true}
                        }else {
                            return item
                        }
                    })
                    if("platforms" in accounts){
                    accounts.platforms = defaultAccountCheckbox;
                    }

                    return (
                        <styles.SmallRow>
                            <CheckboxList
                                setData={setSelectedPlatform}
                                data={platformCheckbox}
                                title={"Platforms"}
                            />
                            <CheckboxList
                                setData={setSelectedAccount} data={accountCheckbox} title={"Account"} />
                            {/*<CheckboxList
                                setData={setSelectedCampaign}
                                data={campaignCheckbox} title={"Campaign"} />*/}
                        </styles.SmallRow>
                    );
                }
                return (
                    <styles.SmallRow>
                        <CheckboxList
                            platformDisable={!platformFlag}
                            platformError={platformError}
                            setData={setSelectedPlatform}
                            data={platformCheckbox}
                            title={"Platforms"}
                        />
                        <CheckboxList closePopover={!platformFlag} /* setPlatformError={setPlatformError} */
                            platformDisable={!flag} platformError={accountError} disabled={!platformFlag}
                            setData={setSelectedAccount} data={accountCheckbox} title={"Account"} />
                        {/*  <CheckboxList closePopover={!flag} disabled={!flag} setAccountError={setAccountError}
                            setPlatformError={setPlatformError} setData={setSelectedCampaign}
                            data={campaignCheckbox} title={"Campaign"} /> */}
                    </styles.SmallRow>
                );
            case (Section.FINANCE):
                return (
                    <styles.SmallRow>
                        <DefaultCheckbox setChecked={setDefaultChannels} title="Channels"
                            defaultChecked={defaultChannels} />
                        <DefaultCheckbox setChecked={setDefaultProducts} title="Products"
                            defaultChecked={defaultProducts} />
                    </styles.SmallRow>
                );
            case (Section.WEBSITE):
                return (
                    <styles.SmallRow>
                        <DefaultCheckbox setChecked={setWebsiteDefaultChannels} title="Channels"
                            defaultChecked={websiteDefaultChannels} />
                    </styles.SmallRow>
                );
            default:
                return <></>;
        }
    };


    return (
        <>
            <Modal blockScrollOnMount={true} isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <styles.StyledModelContent borderRadius={10}>
                    <ModalHeader borderTopRadius={"10px"} backgroundColor={"#FBF7FF"} style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: 20,
                        paddingLeft: 20,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}>
                        <Flex alignItems={"center"}>
                            <FaRegBell color="#7031EB" />
                            <Box width={2} />

                            {t((props.alertToEdit ? "EDIT" : "CREATE") + "_ALERT", { ns: "translation" })}
                        </Flex>
                        <styles.closeButton onClick={props.onClose}>
                            <CloseAlertIcon />
                        </styles.closeButton>
                    </ModalHeader>
                    <form onSubmit={onSubmit}>
                        <ModalBody>
                            <styles.alertSubTitle>
                                {t("DETAILS", { ns: "translation" })}
                            </styles.alertSubTitle>
                            <styles.Spacer />
                            <styles.InputHeader>
                                {t("ALERT_NAME", { ns: "translation" })}

                            </styles.InputHeader>
                            <styles.NameInput value={alertName} onChange={(e: any) => setAlertName(e.target.value)}
                                placeholder={t("TYPE_YOUR_ALERT_NAME", { ns: "translation" })}
                                size='md' required />
                            <styles.alertSubTitle>
                                {t("RULE", { ns: "translation" })}
                            </styles.alertSubTitle>
                            <styles.Spacer />
                            <styles.InputTitle>
                                {t("ALERT_ME_WHEN", { ns: "translation" })}
                            </styles.InputTitle>
                            <styles.SelectRow>
                                <SelectInput
                                    value={triggerKpi}
                                    setValue={setTriggerKpi}
                                    options={Object.keys(getEnum)}
                                />
                                <SelectInput
                                    value={triggerOperator}
                                    setValue={setTriggerOperator}
                                    options={Object.values(TriggerOperator)}
                                />
                                <TriggerValueInput />
                            </styles.SelectRow>
                            <styles.InputTitle>
                                {t("FROM", { ns: "translation" })}
                            </styles.InputTitle>
                            <styles.SelectRow>
                                {renderFromSection()}
                            </styles.SelectRow>
                            <styles.InputTitle>
                                {t("TIME_RANGE", { ns: "translation" })}
                            </styles.InputTitle>
                            <styles.SelectRow>
                                <SelectInput
                                    value={timeRangeState}
                                    setValue={setTimeRangeState}
                                    options={timeRange}
                                />
                            </styles.SelectRow>
                            <styles.alertSubTitle>
                                {t("RECIPIENTS", { ns: "translation" })}
                            </styles.alertSubTitle>
                            <styles.Spacer />
                            <AlertRecipientsRadio />
                        </ModalBody>
                        <ModalFooter
                            paddingRight={5} paddingLeft={0} paddingTop={0} paddingBottom={3}>
                            <styles.StyledSubmitButton
                                blocked={!formValidity}
                                disabled={!formValidity}
                                type="submit">
                                {t(props.alertToEdit ? "EDIT" : "CREATE", { ns: "translation" })}
                            </styles.StyledSubmitButton>
                        </ModalFooter>
                    </form>
                </styles.StyledModelContent>
            </Modal>
        </>
    );

};

import { Box } from '@chakra-ui/react';
import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { NoPinnedKpiIcon } from 'utils/icons/wideview-logo';
import { styles } from './styles';

export default function NoPinnedKpis() {
    return (
        <styles.Wrapper>
            <styles.ComponentBackground>
                <styles.DataWrapper>
                    <NoPinnedKpiIcon />
                    <Box w={4} />
                    <styles.TextWrapper>
                        <styles.Text>
                            You haven’t pinned any KPIs yet.
                        </styles.Text>
                        <styles.Text>
                            You can pin any KPI by clicking on the
                        </styles.Text>
                        <styles.Text>
                            Quick action {">"} pin this KPI
                        </styles.Text>
                    </styles.TextWrapper>
                    <Box mt={23}>
                        <MdMoreVert fontSize={20} color={"#7a86a1"} />
                    </Box>
                </styles.DataWrapper>
            </styles.ComponentBackground>
        </styles.Wrapper>
    );
}

export const getLastYear = () => {
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lastYearInMonths: string[] = [];
  var d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 11; i++) {
    lastYearInMonths.push(monthName[d.getMonth()] + " " + d.getFullYear());
    d.setMonth(d.getMonth() - 1);
  }
  return lastYearInMonths.reverse();
};

function getWeeksRange(date: any) {
  const curr = new Date(date.activityDate);
  const first = curr.getDate() - curr.getDay();
  const last = first + 6;
  const format: any = { year: "numeric", month: "2-digit", day: "2-digit" };
  const firstday = new Date(curr.setDate(first)).toLocaleDateString(
    "he-IL",
    format
  );
  const lastday = new Date(curr.setDate(last)).toLocaleDateString(
    "he-IL",
    format
  );
  return `${firstday.slice(0, 5).replace(".g", "/")}-${lastday
    .slice(0, 5)
    .replace(".g", "/")}`;
}

export function groupDatesByWeek(dates: any) {
  const groupedDates = {};

  dates.forEach((date: any) => {
    const range = getWeeksRange(date);
    /* const dayName = new Date(date.activityDate).toString().split(' ')[0]; */
    if (!groupedDates[date.activityDate.split("-")[0]]) {
      groupedDates[date.activityDate.split("-")[0]] = {};
      groupedDates[date.activityDate.split("-")[0]][range] = [];
    } else if (!groupedDates[date.activityDate.split("-")[0]][range]) {
      groupedDates[date.activityDate.split("-")[0]][range] = [];
    }
    groupedDates[date.activityDate.split("-")[0]][range].push(date);
  });

  return groupedDates;
}

/*export const sumAllData = (array: any[], keysToIgnore: string[]) => {
  return array.reduce((accum, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (!keysToIgnore.includes(key)) {
        if (key === "wvChannelId") {
          accum[key] = value;
          return;
        }
        if (value === null) {
          accum[key] = accum[key] || 0;
        } else {
          accum[key] = accum[key] + value || value;
        }
      }
    });
    return {
      ...accum,
    };
  }, {});
};*/

export const sumData = (array: any[], keysToIgnore: string[]) => {
  return array?.reduce((accum, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (key === "wvChannelId" || key === "wvProductId") {
        accum[key] = value;
        return;
      }
      if (!keysToIgnore.includes(key)) {

        if (typeof value === "object" && value !== null) {
          accum[key] = sumData([value], []);
        } else {
          if(!!value){
          accum[key] = accum[key] + value || value;

          }
        }
      }
    });
    return {
      ...accum,
    };
  }, {});
};

export const sumDataWithUniqueWvChannelId = (array: any[], keysToIgnore: string[]) => {
  const uniqueWvChannelIds :any[] = [];
  return array?.reduce((accum, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (key === "wvChannelId") {
        if(!!value && !uniqueWvChannelIds?.includes(value)){
          uniqueWvChannelIds?.push(value);
        }
        accum[key] = uniqueWvChannelIds
        return;
      }
      if (!keysToIgnore.includes(key)) {
        accum[key] = accum[key] + value || value;
      }
    });
    return {
      ...accum,
    };
  }, {});
};
import { KpisEnum } from "../../models/kpi-settings";

export const kpiOrder = [
  KpisEnum.GROSS_REVENUE,
  KpisEnum.NET_REVENUE,
  KpisEnum.GROSS_PROFIT,
  KpisEnum.CONTRIBUTION_PROFIT,
  KpisEnum.AVERAGE_ORDERS_VALUE,
  KpisEnum.ORDERS,
  KpisEnum.MARKETING_SPEND,
  KpisEnum.BLENDED_ROAS,
  KpisEnum.COST_PER_ORDER,
  KpisEnum.CUSTOMER_ACQUISITION_COST,
];

export const orderArray = (arrayToFilter: number[]) => {
    const newOrder = kpiOrder.map((kpi) => {
        if(arrayToFilter.includes(kpi)){
            return kpi
        }else {
            return
        }
        return
    })?.filter((item) => !!item)
    return newOrder
}
import styled from "styled-components";

export namespace styles {
  export const Item = styled.div<{ selected: boolean, disable?: boolean; }>`
    width: 20%;
    display: flex;
    position: relative;
    height: 100px;
    background-color: ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    /*border-radius: 5px 15px 15px 5px;*/
    position: relative;
    /*border: 1px solid #70707015;*/
    /*display: flex;*/
    /*outline: 1px solid red;*/
    border-bottom: 2px solid ${(props) => (props.selected ? props?.theme?.primaryColor : "unset")};
    white-space: nowrap;

    &:hover {
      cursor: ${props => props?.disable ? "blocked" :  "pointer"};
    }
  `;
  export const LeftBorderDiv = styled.div<{ selected: boolean }>`
  position: absolute;
    width: 50px;
    left: -50px;
    height: 5px;
    bottom: -2px;
    border-bottom: 2px solid ${(props) => (props.selected ? props?.theme?.primaryColor : "unset")};
  ;
  `

  export const ItemSuffix = styled.div<{ selected: boolean }>`
    height: 0;
    width: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-right: 50px solid transparent;
    border-left: 50px solid ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    position: absolute;
    right: -100px;
  `

  export const ItemSuffixRed = styled.div<{ selected: boolean }>`
    height: 0;
    width: 0;
    border-top: 25px solid ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    border-right: 25px solid ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    border-bottom: 25px solid transparent;
    border-left: 25px solid transparent;
    position: absolute;
    left: -50px;
  `

  export const ItemSuffixBottom = styled.div<{ selected: boolean }>`
    height: 0;
    width: 0;
    border-top: 25px solid transparent;
    border-right: 25px solid ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    border-bottom: 25px solid ${(props) => (props.selected ? "#ffffff" : "#e4e1f6")};
    border-left: 25px solid transparent;
    position: absolute;
    left: -50px;
    bottom: 0;
  `
  export const DotWrapper = styled.div<{ selected: boolean }>`
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: white;
    border: ${(props) =>
      props.selected ? "2px solid #D5D3DF" : " 2px solid #D5D3DF"};
    position: absolute;
    display: flex;
    bottom: -10.5px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
  `;

  export const SvgHolder = styled.div`
    width: 42px;
    height: 42px;
  `;
  export const ItemContentHolder = styled.div`
    display: flex;
    flex-direction: column;
    width: 94%;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
  `;

  export const ItemTitle = styled.div<{ selected: boolean }>`
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    color: ${(props) => (props.selected ? "#1A0989" : "#7A86A1")};
    font-size: 16px;
    font-weight: bold;
  `;
  export const Dot = styled.div<{ selected: boolean }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => (props.selected ? "#7031EB" : "#D5D3DF")};
  `;

  export const ItemWrapper = styled.div`
    display: flex;
    position: relative;
    width: 90%;
    justify-content: space-between;
    /*border-bottom: 4px solid #c0bdcc9a;*/
    /*overflow: hidden;*/
  `;
}

export default styles;

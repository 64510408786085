import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from "@chakra-ui/modal";
import {
    Box,
    Button,
    Flex,
    Input,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import {organizationSelector, userSelector} from "@store";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createEfforts, editEffort, effortsSelector, setNewEfforts} from "store/efforts";
import {fetchUsersData, usersDataSelector} from "store/users";
import {createEffortSvg} from "utils/icons/efforts";
import {styles} from "./styles";
import {addDays, format, isValid, parse} from "date-fns";
import {DatepickerSmall} from "../datepicker-small/DatepickerSmall";
import {KpisEnum} from "models/kpi-settings";
import {MdArrowDropDown} from "react-icons/md";
import {getENUMByKpiName} from "utils/kpi-names/kpiName";


interface Props {
    isOpen: any;
    onOpen: any;
    onClose: any;
    cameFromEdit?: boolean;
    dataToShow?: {
        kpi?: string;
        title?: string;
        description?: string;
        assignee?: string;
        startDate?: string;
        endDate?: string;
        id?: string;
        createdAt?: string;
    };
}

interface effort {
    kpiMetric: string;
    description: string;
    assignee: string;
    startDate: string;
    endDate: string;
    kpi: KpisEnum;
}

interface InputProps {
    options?: string[];
    valueSelect: string;
    setValueSelect: (e: any) => void;
    secondValue?: any[];
    thirdVal?: any;
    size?: number;
}

//TODO in order to make the Date input generic  
interface DateInputProps {
    options: string[];
    value: string;
    setValue: (e: any) => void;
}

interface EffortUpdate {
    kpiMetric: string;
    description: string;
    assignee: string;
    startDate: string;
    endDate: string;
    id: string;
}


const getEnum = {
    "Gross Revenue": KpisEnum.GROSS_REVENUE,
    "Net Revenue": KpisEnum.NET_REVENUE,
    "Blended ROAS": KpisEnum.BLENDED_ROAS,
    "Spend": KpisEnum.MARKETING_SPEND,
    "CPO": KpisEnum.COST_PER_ORDER,
    "Gross Margin": KpisEnum.GROSS_MARGIN,
    "Contribution Profit": KpisEnum.CONTRIBUTION_PROFIT,
    "AOV": KpisEnum.AVERAGE_ORDERS_VALUE,
    "Orders": KpisEnum.ORDERS,
    /* "CR": KpisEnum.CONVERSION_RATE,
    "Sessions": KpisEnum.SESSIONS, */
};

const options = Object.keys(getEnum);

export const EffortsModal = (props: Props) => {
    const organization = useSelector(organizationSelector);
    const user = useSelector(userSelector);
    const users = useSelector(usersDataSelector);
    const efforts = useSelector(effortsSelector);
    const todayDate = new Date()?.toISOString().slice(0, 10);
    const [kpi, setKpi] = useState(options[0]);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [owner, setOwner] = useState<string>("select");
    const [id, setId] = useState<string>("");
    const dispatch = useDispatch();
    const isFormValid = title.length > 0 && description.length > 0 && owner !== "select" && !!startDate && !!endDate && endDate > startDate;

    const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>();
    const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();

    useEffect(() => {
        if (!!organization?.id && props.isOpen) {
            dispatch(fetchUsersData(organization.id));
        }
    }, [dispatch, organization?.id, props.isOpen]);

    useEffect(() => {
        setKpi(options[0]);
        setTitle("");
        setDescription("");
        setOwner("select");
        setStartDate("");
        setEndDate("");
    }, [props.isOpen]);

    useEffect(() => {
        if (!!props?.dataToShow?.kpi) {
            if (props.cameFromEdit) {
                setKpi(props.dataToShow.kpi);

            } else {
                setKpi(props.dataToShow.kpi.split(" ")[0]);
            }

        }
        if (!!props?.dataToShow?.title) {
            setTitle(props.dataToShow.title);
        }
        if (!!props?.dataToShow?.description) {
            setDescription(props.dataToShow.description);
        }
        if (!!props?.dataToShow?.assignee) {
            setOwner(props.dataToShow.assignee);
        }
        if (!!props?.dataToShow?.startDate) {
            setStartDate(props.dataToShow.startDate);
        }
        if (!!props?.dataToShow?.endDate) {
            setEndDate(props.dataToShow.endDate);
        }
        if (!!props?.dataToShow?.id) {
            setId(props.dataToShow.id);
        }
    }, [props?.dataToShow, props?.dataToShow?.startDate, props?.dataToShow?.endDate]);

    //help
    const handleChange = (e: any) => {
        setKpi(e.target.value);
    };

    const titleChange = (e: any) => {
        setTitle(e.target.value);
    };

    const descriptionChange = (e: any) => {
        setDescription(e.target.value);
    };

    const ownerHandleChange = (id?: string) => {
        if (!!id) {
            setOwner(id);
        }
    };

    const resetForm = () => {
        setTitle("");
        setDescription("");
        setStartDate("");
        setEndDate("");
        setOwner("select");
        setKpi(options[0]);
        props.onClose();
    };

    const handleFindItemAndEdit = (id: string, data: any) => {
        //console.log(`handleFindItemAndEdit id = ${id} and data = ${data}`);
        //console.log(data);
        const copyOfEfforts = JSON.parse(JSON.stringify(efforts.data));
        const index = copyOfEfforts.findIndex(
            (item: { id: string; }) => item.id === id
        );

        if (index !== -1) {
            copyOfEfforts[index] = data;
        }
        dispatch(setNewEfforts({data: copyOfEfforts}));
    };

    const startDateChange = (e: any) => {
        setStartDate(e.currentTarget.value);
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date()); //Return the date parsed from string using the given format string.
        if (isValid(date)) {
            setSelectedStartDate(date);
        } else {
            setSelectedStartDate(new Date()); // default date
        }
    };

    const endDateChange = (e: any) => {
        setEndDate(e.currentTarget.value);
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date()); //Return the date parsed from string using the given format string.
        if (
            !!selectedStartDate &&
            isValid(date) &&
            date.getTime() - selectedStartDate.getTime() > 0
        ) {
            setSelectedEndDate(date);
        } else {
            setSelectedEndDate(undefined); //
            return;
        }

        if (!!selectedStartDate) {
            if (date.getTime() - selectedStartDate?.getTime() < 0) {
                setSelectedEndDate(undefined); //
                setEndDate("");
            }
        }
    };

    const handleStartDaySelect = (date: any) => {
        setSelectedStartDate(new Date(date)); //when we change the date in the DayPicker calender
        if (date) {
            setStartDate(format(date, "y-MM-dd"));
        } else {
            setStartDate("");
        }
    };

    const handleEndDaySelect = (date: any) => {
        if (!date) {
            setEndDate("");
            return;
        }
        if (!!selectedStartDate) {
            if (new Date(date).getTime() - new Date(selectedStartDate).getTime() < 0) {
                setEndDate("");
                return;
            }
        }

        setSelectedEndDate(new Date(date)); //when we change the date in the DayPicker calender
        if (date) {
            setEndDate(format(date, "y-MM-dd"));
        } else {
            setEndDate("");
        }
    };

    const handleSubmit = () => {
        if (isFormValid && props.cameFromEdit) {
            const data = {
                kpiMetric: kpi + " / " + title,
                description,
                assignee: owner,
                startDate,
                endDate,
                id,
                kpi: getENUMByKpiName(kpi)
            };
            //console.log("heollo world", owner);
            const dataToEdit = {
                kpiMetric: kpi + " / " + title,
                description,
                assignee: owner,
                startDate,
                endDate,
                createdAt: props.dataToShow?.createdAt,
                id,
                kpi: getENUMByKpiName(kpi)
            };
            //console.log("edit", props);

            !!organization?.id && dispatch(editEffort({
                data: data,
                array: efforts?.data,
                organizationId: organization?.id
            }));
            handleFindItemAndEdit(id, dataToEdit);
            resetForm();
            return;
        } //
        if (isFormValid) {
            const data: effort = {
                kpiMetric: kpi + " / " + title,
                description,
                assignee: owner,
                startDate,
                endDate,
                kpi: getENUMByKpiName(kpi)
            };
            /* const dataToDisplay: any = {
                kpiMetric: kpi + " / " + title,
                description,
                assignee: owner,
                startDate,
                endDate,
                createdAt: todayDate,

            }; // */

            //console.log("data", data);
            /* dispatch(setNewEfforts({ data: [...efforts.data, dataToDisplay] })); */
            !!organization?.id && dispatch(createEfforts({data, organizationId: organization?.id}));
            setTitle("");
            setDescription("");
            setStartDate(todayDate);
            setEndDate("");
            setOwner("select");
            setKpi(options[0]);
            props.onClose();
        }
    };


    const SelectInput = ({options, valueSelect, setValueSelect, secondValue, thirdVal, size}: InputProps) => {
        //console.log("value 1", secondValue);
        const {onOpen, onClose, isOpen} = useDisclosure();
        return (
            < Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <PopoverTrigger>
                    <styles.ButtonPopover>
                        <Box w={!!size ? size : 90} overflow="hidden" textOverflow={"ellipsis"} whiteSpace="nowrap">
                            {!!thirdVal ? thirdVal : valueSelect}
                        </Box>
                        <MdArrowDropDown/>
                    </styles.ButtonPopover>
                </PopoverTrigger>
                <styles.StyledPopoverContent w="max-content">

                    {options?.map((option, i) => {
                        return <styles.option onClick={() => {
                            if (!!secondValue) {
                                //console.log("here", secondValue[i].id);
                                setValueSelect(secondValue[i].id);
                                onClose();
                            } else if (!!setValueSelect) {
                                //console.log("here 22", option);
                                setValueSelect(option);
                                onClose();
                            }

                        }} key={i}>
                            {option}
                        </styles.option>;
                    })}

                </styles.StyledPopoverContent>
            </Popover>
        );
    };


    const kpiSelection = () => {
        return (
            <SelectInput valueSelect={kpi} setValueSelect={setKpi} options={options} size={130}/>
        );
    };


    const getUserNameById = (id?: string) => {

        if (!!users && !!id) {
            const currentUser = users?.filter((item) => item.id === id);
            if (!!currentUser[0]?.firstName && !!currentUser[0]?.lastName) {
                return currentUser[0]?.firstName + " " + currentUser[0]?.lastName;
            } else if (!!currentUser[0]?.firstName) {
                return currentUser[0]?.firstName;
            } else
                return "";
        }
        return "";
    };


    const ownersSelection = () => {
        const userString = users?.map((item, i) => `${item?.firstName} ${item?.lastName}`);
        const found = getUserNameById(owner);

        return (
            <SelectInput valueSelect={owner} setValueSelect={setOwner} options={userString} secondValue={users}
                         thirdVal={!!found ? found : "select"}/>
        );
    };

    return (
        <styles.WrapperModal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay/>
            <styles.StyledModalContent>
                <styles.BodyWrapper>
                    <ModalHeader paddingLeft={0}>
                        <styles.Title>Create New Effort</styles.Title>
                        <styles.Subtitle>
                            Based on these parameters and drivers:
                        </styles.Subtitle>
                    </ModalHeader>
                    <styles.StyledCloseButton/>
                    <ModalBody padding={0}>
                        <Flex width={"100%"} justifyContent={"space-between"}>
                            <Flex flexDirection={"column"}>
                                <Flex alignItems={"center"}>
                                    <Box>Title: </Box>
                                    <Box width={4}/>
                                    <styles.TitleInput
                                        value={title}
                                        onChange={(e: any) => titleChange(e)}
                                        placeholder={"Write your effort title here"}
                                        type={"text"}
                                    />
                                </Flex>
                                <Box height={2}/>
                                <Flex alignItems={"center"}>
                                    <Box>Description: </Box>
                                    <Box width={4}/>
                                    <styles.DescriptionInput
                                        value={description}
                                        onChange={(e: any) => descriptionChange(e)}
                                        placeholder="Write your effort description here"
                                        type={"text"}
                                    />
                                </Flex>
                                <Box height={2}/>

                                <Flex alignItems={"center"}>
                                    <Box>KPI: </Box>
                                    <Box width={4}/>
                                    {kpiSelection()}
                                </Flex>
                                <Box height={2}/>
                                <Flex alignItems={"center"}>
                                    <Box>Owner: </Box>
                                    <Box width={4}/>
                                    {ownersSelection()}
                                </Flex>
                                <Box height={2}/>
                                <Flex alignItems={"center"}>
                                    <Box>Start Date: </Box>
                                    <styles.DatePickerInput
                                        onChange={startDateChange}
                                        value={startDate}
                                        border={"none"}
                                        width={130}
                                        focusBorderColor="none"
                                        placeholder={"YYYY-MM-DD"}
                                        type={"date"}
                                        data-date-format="YYYY-MM-DD"
                                    />

                                    <DatepickerSmall
                                        onApply={handleStartDaySelect}
                                        onChange={handleStartDaySelect}
                                        date={selectedStartDate}
                                        max={selectedEndDate}
                                    />
                                </Flex>
                                <Box height={2}/>
                                <Flex alignItems={"center"}>
                                    <Box>End Date: </Box>

                                    <styles.DatePickerInput
                                        onChange={endDateChange}
                                        value={endDate}
                                        border={"none"}
                                        width={136}
                                        paddingLeft={6}
                                        focusBorderColor="none"
                                        placeholder={"YYYY-MM-DD"} //{format(new Date(), "yyyy-MM-dd")}
                                        type={"date"}
                                        min={"2021-10-04"}
                                    />
                                    <DatepickerSmall
                                        onApply={handleEndDaySelect}
                                        onChange={handleEndDaySelect}
                                        date={selectedEndDate}
                                        min={selectedStartDate}
                                    />
                                </Flex>
                            </Flex>
                            <Flex
                                flexDirection={"column"}
                                alignItems="flex-end"
                                justifyContent="space-between"
                            >
                                <Box width={"90%"} height={"90%"}>
                                    {createEffortSvg()}
                                </Box>
                                <styles.SubmitButton
                                    notValid={!isFormValid}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Create Effort
                                </styles.SubmitButton>
                            </Flex>
                        </Flex>
                    </ModalBody>

                    <ModalFooter padding={0}></ModalFooter>
                </styles.BodyWrapper>
            </styles.StyledModalContent>
        </styles.WrapperModal>
    );
};

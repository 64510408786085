import styled from "styled-components";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { Input, NumberInput } from '@chakra-ui/react';

export namespace styles {
    export const PaginationNav = styled.nav`
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
      justify-content: center;
    padding-top: 20px;
      
     gap: 15px;
    @media (max-width: 1280px) {
      gap: calc(10px + 10%);
    }
  `;

    export const AdsContext = styled.div`
    display: block;
      justify-self: flex-start;
  `;

    export const DisplayingAdsPagination = styled.p`
    font-size: 0.855em;
  `;

    export const PaginationList = styled.div`
    max-width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
  `;

    export const InputPaginationNumber = styled(Input)`
   width: 100%;
    border: #ebebeb solid 2px;
    padding: 5px;
    //border-radius: 100%;
    text-align: center;
    background-color: transparent;

    &:focus {
      border-color: #7031EB !important;
    box-shadow: 0 0 0 1px #7031EB !important;
    }
  `;

    export const PreviousArrow = styled(HiArrowLeft)`
    margin-top: 6px;
  `;
    export const NextArrow = styled(HiArrowRight)`
    margin-top: 6px;
  `;
}

export default styles;

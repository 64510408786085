import {Box, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay} from '@chakra-ui/react';
import {IntegrationType} from '@models';
import {
    createMarketingElement,
    editMarketingElement,
    fetchDataLanguage,
    organizationDataLanguageSelector,
    organizationSelector
} from '@store';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {channelsStateSelector} from 'store/channels';
import {accountStateSelector} from 'store/ui';
import {styles} from './styles';
import {AmazonAds, IconAmazon, IconFacebook, IconGoogleAds} from "../../../../utils/icons/platforms";
import {kpiMarketingSelector} from "../../../../store/kpis";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {RiCheckboxCircleLine} from "react-icons/ri";

interface Props {
    isOpen: any,
    onClose: any;
    row?: {
        original: {
            data: string[],
            id: number,
            title: string;
            platform?: boolean;
            account?: boolean;
            campaignId?: string;
            accountId?: string;
        };
    };
}

export interface UpdateMarketingMappingElement {
    element: MarketingMappingElement;
}

export interface MarketingMappingElement {
    campaign: {
        title: string;
        id: string;
    };
    salesChannel: string[];
}


export const MarketingModal = (props: Props) => {
    const {t} = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    //hooks
    /* const integrations = useSelector(integrationSelector); */
    const channelsStore = useSelector(channelsStateSelector);
    const [channels, setChannels] = useState<any[]>([]);
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const [channelsToAds, setChannelsToAds] = useState<any>();
    const [activeChannels, setActiveChannels] = useState<string[]>([]);
    const organization = useSelector(organizationSelector);
    const [selectedElement, setSelectedElement] = useState<any>();
    const accounts = useSelector(accountStateSelector);
    const [selectedSalesChannel, setSelectedSalesChannel] = useState<string | undefined>();
    const [createNewElement, setCreateNewElement] = useState(false)
    const marketingKpis: any[] | undefined = useSelector(kpiMarketingSelector);
    useEffect(() => {
        if (!!dataLanguage?.marketingMapping?.elements) {
            setChannelsToAds(dataLanguage?.marketingMapping?.elements);
        }
    }, [dataLanguage?.marketingMapping?.elements]);


    useEffect(() => {
        let foundElement;
        if(!!props?.row?.original?.accountId){
            foundElement = dataLanguage?.marketingMapping?.elements?.find((item) => item?.accountId === props?.row?.original?.accountId);
        }
        if(!!props?.row?.original?.campaignId){
            foundElement = dataLanguage?.marketingMapping?.elements?.find((item) => item?.campaignId === props?.row?.original?.campaignId);
        }
        if(!!foundElement?.salesChannels){
            setSelectedSalesChannel(foundElement?.salesChannels[0] )
        }
        else {
            setCreateNewElement(true);
            setSelectedSalesChannel(undefined)
        }
    }, [props?.row])


    useEffect(() => {
        if (!!channelsStore?.channels) {
            dataToRender();
        }
    }, [channelsStore]);


    useEffect(() => {
        if (!!channelsToAds) {
            const activeNames: any[] = [];
            const allChannelsToRow = channelsToAds?.filter((item: any) => item?.account === props?.row?.original?.title);
            allChannelsToRow?.forEach((item: any) => item?.salesChannels?.forEach((name: string) => {
                if (!!name) {
                    activeNames.push(getSalesChannelsById(name));
                }
            }));
            setActiveChannels(activeNames.flat());
        }
    }, [channelsToAds, props?.row]);


    const getSalesChannelsById = (id: string) => {
        const currentIntegration = channelsStore?.channels?.filter((item) => item?.id === id);
        return currentIntegration?.map((item: any) => item?.displayName);
    };


    //helpers

    const dataToRender = () => {
        const data = channelsStore?.channels?.filter((item) => {
            return (item.type === IntegrationType.STORES);
        });
        if (!!data) {
            const allUniqueChannelsNames:any[] = [];
            const allUniqueChannelsIds:any[] = [];
            data.forEach((channel) => {
                if(allUniqueChannelsNames?.some((item) => item?.displayName === channel?.displayName)){
                    return
                }else {
                    allUniqueChannelsNames?.push(channel);
                    allUniqueChannelsIds?.push((channel?.id));
                }
            })
            setChannels(allUniqueChannelsNames);
        }
    };

    const onCloseClick = () => {
        if (!!dataLanguage?.marketingMapping?.elements) {
            setChannelsToAds(dataLanguage?.marketingMapping?.elements);
        }
        props.onClose();
    };
    const submitItem = (currentItem: any, elementToDispatch: any) => {
        if (!!currentItem) {
            const newElement = elementToDispatch
            newElement[0]["id"] = currentItem.id;
            !!organization?.dataLanguage && !!organization?.id && dispatch(editMarketingElement({
                data: newElement,
                dataLanguageId: organization?.dataLanguage,
                organizationId: organization?.id,

            }));
            !!organization?.id && !!organization?.dataLanguage && dispatch(fetchDataLanguage({
                dataLanguageId: organization?.dataLanguage,
                organizationId: organization?.id
            }));
        } else {
            !!organization?.dataLanguage && !!organization?.id && dispatch(createMarketingElement({
                data: elementToDispatch,
                dataLanguageId: organization?.dataLanguage,
                organizationId: organization?.id
            }));
        }
    }
    const onSaveClick = () => {
        const foundChannelName = channelsStore?.channels?.find((channel) => channel?.id === selectedSalesChannel)?.displayName;
        const allChannelsWithSameName = channelsStore?.channels?.filter((channel) => channel?.displayName === foundChannelName)?.map((channel) => channel?.id);
            if (!!props?.row?.original?.accountId) {
                const currentItem = dataLanguage?.marketingMapping?.elements?.find((element) => element?.accountId === props?.row?.original?.accountId);
                const elementToDispatch = {
                    element: [{
                        salesChannels: allChannelsWithSameName,
                        accountId: props?.row?.original?.accountId

                    }]
                };

                submitItem(currentItem, elementToDispatch.element)
            }
            if (!!props?.row?.original?.campaignId && !!props?.row?.original?.data[1]) {
                const elementToDispatch = {
                    element: [{
                        salesChannels: allChannelsWithSameName,
                        campaignId: props?.row?.original?.campaignId,
                        accountId: props?.row?.original?.data[1]
                    }]
                };
                const currentItem = dataLanguage?.marketingMapping?.elements?.find((element) => element?.campaignId === props?.row?.original?.campaignId);
                submitItem(currentItem, elementToDispatch.element)

            }

            props.onClose();



    };


    //renders

    useEffect(() => {
        if (props?.isOpen) {
           /* if (!!props?.row?.original?.platform) {
                const currentElement = dataLanguage?.marketingMapping?.elements?.find((item) => item?.integration?.title === props?.row?.original?.title);
                if (!!currentElement) {
                    setSelectedElement(currentElement);
                }
            }*/
            if (!!props?.row?.original?.accountId) {

                const currentElement = dataLanguage?.marketingMapping?.elements?.find((item) => item?.accountId === props?.row?.original?.title);
                if (!!currentElement) {
                    setSelectedElement(currentElement?.accountId);
                }
            } else {

                const currentElement = dataLanguage?.marketingMapping?.elements?.find((item) => item?.campaignId === props?.row?.original?.title);
                if (!!currentElement) {
                    setSelectedElement(currentElement?.campaignId);
                }
            }
        }
    }, [dataLanguage?.marketingMapping?.elements, props?.row?.original?.title, props?.isOpen]);

    const handleClick = (item: any) => {
        setSelectedElement((prev: any) => {
            if(!!prev){
                return undefined
            }else {
                return item.name
            }
        });
    };


    const getIconByName = (name:string) => {
        switch(name){
            case("Google Ads"):
                return ( <IconGoogleAds />)
            case("Amazon Ads"):
                return ( <IconAmazon />)
            case("Facebook Ads"):
                return ( <IconFacebook />)
            default:
                return name
        }
    }



    const channelItem = ({item, i}: { item: any, i: number}) => {
        /*const flag = !!props?.row?.original.account ? props?.row?.original.data[1] : props?.row?.original?.title;*/
        return (
            <styles.ChannelItem onClick={() => {
                if(selectedSalesChannel === item?.id ){
                    setSelectedSalesChannel(undefined)
                }else {
                    setSelectedSalesChannel(item?.id)
                }
            }} key={i}>
                <Box w={2}/>
                {selectedSalesChannel === item?.id ? <RiCheckboxCircleLine size={23} color='#7031EB'/>
                    : <IoIosCloseCircleOutline size={23} color="#BAC0CE"/>}
                {item?.displayName}
            </styles.ChannelItem>);
    };

    if (props.row !== undefined) {
        const elements = props?.row?.original?.data;
        const account = accounts?.data?.find((account) => account.id === elements[1])?.displayName;
        const campaign = marketingKpis?.find((kpiItem) => kpiItem?.campaignId === elements[2])?.campaignName;
        const icon = <>{getIconByName(elements[0])}</>;
        const divToRender = <Flex>{icon}&nbsp;{`${elements[0]} | ${account} ${!!campaign ? `| ${campaign}` : ""} `}</Flex>


        return (
            <Modal size={"lg"} isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay/>
                <ModalContent borderRadius={10}>
                    <styles.StyledModalHeader>
                        <styles.StyledTitle>
                            {divToRender}
                        </styles.StyledTitle>
                        <styles.StyledRow>
                            {t("CHECK_THE_SALES_CHANNEL_AND_ITS_PRODUCTS", {ns: "translation"})}
                        </styles.StyledRow>
                    </styles.StyledModalHeader>
                    <ModalBody>
                        <Flex flexDirection={"column"} alignItems="center">
                            <Box alignSelf={"flex-start"} mb={4}>
                                {t("SALES_CHANNELS", {ns: "translation"})}
                            </Box>
                            <Box maxH={320} overflowY="auto" width={"100%"} height={"100%"} backgroundColor="#F9FAFB"
                                 minH={200}>
                                {channels.map((item: any, i) => {
                                     return channelItem({item, i});
                                })}
                            </Box>
                        </Flex>
                    </ModalBody>
                    <ModalFooter borderRadius={"0 0 10px 10px"} backgroundColor={"#F9F9FB"}>
                        <styles.styledButton onClick={onCloseClick}>
                            {t("CANCEL", {ns: "translation"})}
                        </styles.styledButton>
                        <styles.styledButton onClick={onSaveClick} save={"true"}>
                            {t("SAVE", {ns: "translation"})}
                        </styles.styledButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>);
    }
    return (<></>);


};






import { styles } from './styles';
import { useTranslation } from "react-i18next";
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { Column, Row, useRowSelect, useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { AdData } from "@models";
import { Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { FilterIcon } from '../../../../utils/icons/filter';


interface Props {
    title?: string;
    headers: string[];
    data: AdData[];
    icon: any;
    selectedFilters: any;
}

interface Data {
    col_0: string;
    col_1: number;
    col_2: number;
    col_3: number;
    col_4: number;
    col_5: string; // platform id
    col_6: string; // account id
    col_7: string; // campaign id
    col_8: string; // ad set id
}



export const SessionsAdTable = (props: Props) => {
    const { t } = useTranslation(['translation', 'translation']);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedRows, setSelectedRows] = useState([] as Row<Data>[]);
    const [selectedData, setSelectedData] = useState<AdData[]>(props.data);
    const [selectedRowTab0, setSelectedRowTab0] = useState([] as Row<Data>[]);
    const [selectedRowTab1, setSelectedRowTab1] = useState([] as Row<Data>[]);
    const [selectedRowTab2, setSelectedRowTab2] = useState([] as Row<Data>[]);
    const [selectedRowTab3, setSelectedRowTab3] = useState([] as Row<Data>[]);
    const [selectedRowTab4, setSelectedRowTab4] = useState([] as Row<Data>[]);
    const [selectedRowTab5, setSelectedRowTab5] = useState([] as Row<Data>[]);
    const [totalSpendBar, setTotalSpendBar] = useState(0);
    const [highestNumber, setHighestNumber] = useState(0);
    const [filters, setFilters] = useState<{ platform: number[], account: string[], campaign: string[], adSets: string[], ads: string[]; website: string[]; }>({ platform: [], account: [], campaign: [], adSets: [], ads: [], website: [] });
    const [mediumFilters, setMediumFilters] = useState<{ medium: string[]; }>({ medium: [] });


    const decimalFix = (number: number) => {
        return (Math.round(number * 100) / 100).toFixed(2);
    };

    const dataToDataTable = (data: AdData[]): Data[] => {
        return data.map((row, index) => {
            let currenctSessionsSum = 0;

            if (row.sessions !== undefined) {
                currenctSessionsSum = (selectedData.reduce((a, b) => +a + +b.sessions!, 0));
                return {
                    col_0: row.channelName,
                    col_1: row.sessions,
                    col_2: (row.sessions / currenctSessionsSum * 100),
                    col_3: 10,
                    col_4: row.platform,
                    col_5: row.accountId,
                    col_6: row.campaignId,
                    col_7: row.adSetId,
                    col_8: row.adId,
                };
            }
            else return {
                col_0: "",
                col_1: 0,
                col_2: 0,
                col_3: 10,
                col_4: row.platform,
                col_5: row.accountId,
                col_6: row.campaignId,
                col_7: row.adSetId,
                col_8: row.adId,
            };
        });
    };

    const headersToColumns = (headers: string[],): Column<Data>[] => {
        return headers.map((header, index) => {
            return {
                Header: header, accessor: 'col_' + index.toString(), sortType: 'basic',
                Footer: info => {
                    return <></>;
                }
            } as Column<Data>;
        });
    };
    const keysToSum = ['sessions'];

    let mediumPaidData = [{
        accountId: "Paid",
        adId: "Paid",
        adSetId: "Paid",
        campaignId: "Paid",
        channelName: "Paid",
        id: 0,
        paid: true,
        platform: 999,
        sessions: 0
    },
    {
        accountId: "Direct",
        adId: "Direct",
        adSetId: "Direct",
        campaignId: "Direct",
        channelName: "Direct",
        id: 9999,
        paid: false,
        platform: 1000,
        sessions: 26
    },
    {
        accountId: "Organic",
        adId: "Organic",
        adSetId: "Organic",
        campaignId: "Organic",
        channelName: "Organic",
        id: 8888,
        paid: false,
        platform: 1001,
        sessions: 888
    },
    ];

    // hooks
    useEffect(() => {
        let newData = props.data;

        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }
        const summed: AdData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.platform]) {
                obj[record.platform] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.platform][key] += record[key];
                });
            }
            return obj;
        }, {}));
        summed.forEach((item) => {
            if (item.sessions !== undefined) {
                mediumPaidData[0].sessions = mediumPaidData[0].sessions + item.sessions;
            }
        });

        setSelectedData(mediumPaidData);
    }, [filters.website, props.data]);


    useEffect(() => {
        const tempData: number[] = [];
        let temp = 0;
        tempData.forEach((element) => {
            if (temp < element) {
                temp = element;
            }
        });
        setHighestNumber(temp);
    }, [selectedData]);

    const data = useMemo<Data[]>(() => dataToDataTable(selectedData), [selectedData]);
    const columns = useMemo<Column<Data>[]>(() => headersToColumns(props.headers), [props.headers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
        allColumns,
    } = useTable({ columns, data }, useSortBy, useRowSelect);



    // helpers
    useEffect(() => {
        let websiteFilters = props.selectedFilters.map((item: { cells: any[]; }) => item.cells.find((cell: { column: { Header: string; }; }) => cell.column.Header === "Website")?.value);
        filters.website = websiteFilters;
    }, [props.selectedFilters]);

    switch (selectedTab) {
        case 0:
            mediumFilters.medium = selectedRowTab0.map((item) => item.cells.find((cell) => cell.column.Header === "Type")?.value);
            break;
        case 1: // platform tab
            filters.platform = selectedRowTab1.map((item) => item.cells.find((cell) => cell.column.Header === "platform")?.value);
            break;
        case 2: // acount tab
            filters.account = selectedRowTab2.map((item) => item.cells.find((cell) => cell.column.Header === "accountId")?.value);
            break;
        case 3:  // campaign 
            filters.campaign = selectedRowTab3.map((item) => item.cells.find((cell) => cell.column.Header === "campaignId")?.value);
            break;
        case 4: // ad sets
            filters.adSets = selectedRowTab4.map((item) => item.cells.find((cell) => cell.column.Header === "adSetId")?.value);
            break;
        case 5: // ads
            filters.ads = selectedRowTab5.map((item) => item.cells.find((cell) => cell.column.Header === "adId")?.value);

            break;
    }

    const filterByRow = (row: Row<Data>) => {
        if (selectedTab === 0) {
            const isRowSelected = selectedRowTab0.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab0([...(selectedRowTab0.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab0];
                newSelected.push(row);
                setSelectedRowTab0(newSelected);
            }
        }
        if (selectedTab === 1) {
            const isRowSelected = selectedRowTab1.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab1([...(selectedRowTab1.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab1];
                newSelected.push(row);
                setSelectedRowTab1(newSelected);
            }
        }
        if (selectedTab === 2) {
            const isRowSelected = selectedRowTab2.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab2([...(selectedRowTab2.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab2];
                newSelected.push(row);
                setSelectedRowTab2(newSelected);
            }
        }
        if (selectedTab === 3) {
            const isRowSelected = selectedRowTab3.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab3([...(selectedRowTab3.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab3];
                newSelected.push(row);
                setSelectedRowTab3(newSelected);
            }
        }
        if (selectedTab === 4) {
            const isRowSelected = selectedRowTab4.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab4([...(selectedRowTab4.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab4];
                newSelected.push(row);
                setSelectedRowTab4(newSelected);
            }
        }
        if (selectedTab === 5) {
            const isRowSelected = selectedRowTab5.filter(selectedRow => selectedRow.id === row.id);
            if (!!isRowSelected && isRowSelected.length > 0) {
                setSelectedRowTab5([...(selectedRowTab5.filter(selectedRow => selectedRow.id !== row.id))]);
            } else {
                const newSelected = [...selectedRowTab5];
                newSelected.push(row);
                setSelectedRowTab5(newSelected);
            }
        }
    };



    const mediumTabHelper = () => {
        let newData = props.data;

        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }
        const summed: AdData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.platform]) {
                obj[record.platform] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.platform][key] += record[key];
                });
            }
            return obj;
        }, {}));
        summed.forEach((item) => {
            if (item.sessions !== undefined) {
                mediumPaidData[0].sessions = mediumPaidData[0].sessions + item.sessions;
            }
        });

        setSelectedData(mediumPaidData);
    };
    const platformTabHelper = () => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }
        if (mediumFilters.medium.length !== 0 && !mediumFilters.medium.includes("Paid")) {
            newData = newData.filter((item) => item.paid === false);
        }
        const summed: AdData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.platform]) {
                obj[record.platform] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.platform][key] += record[key];
                });
            }
            return obj;
        }, {}));
        setSelectedData(summed);
    };
    const accountTabHelper = (rows: Row<Data>[]) => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }
        if (mediumFilters.medium.length !== 0 && !mediumFilters.medium.includes("Paid")) {
            newData = newData.filter((item) => item.paid === false);
        }
        const accountTotalRows: AdData[] = Object.values(newData.reduce((obj, record) => {
            if (!obj[record.accountId]) {
                obj[record.accountId] = { ...record };
            } else {
                keysToSum.forEach(key => {
                    obj[record.accountId][key] += record[key];
                });
            }
            return obj;
        }, {}));
        setSelectedData(accountTotalRows);
    };
    const campaignTabHelper = (rows: Row<Data>[]) => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }

        if (mediumFilters.medium.length !== 0 && !mediumFilters.medium.includes("Paid")) {
            newData = newData.filter((item) => item.paid === false);
        }
        if (rows.length === 0) {
            const campaginTotalRows: AdData[] = Object.values(
                newData.reduce((obj, record) => {
                    if (!obj[record.campaignId]) {
                        obj[record.campaignId] = { ...record };
                    } else {
                        keysToSum.forEach(key => {
                            obj[record.campaignId][key] += record[key];
                        });
                    }
                    return obj;
                }, {}));

            setSelectedData(campaginTotalRows);
        }
    };
    const adSetsTabHelper = (rows: Row<Data>[]) => {
        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });

        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.ads.length !== 0) {
            newData = newData.filter((item) => filters.ads.includes(item.adId));
        }
        if (mediumFilters.medium.length !== 0 && !mediumFilters.medium.includes("Paid")) {
            newData = newData.filter((item) => item.paid === false);
        }
        if (rows.length === 0) {
            const campaginTotalRows: AdData[] = Object.values(
                newData.reduce((obj, record) => {
                    if (!obj[record.campaignId]) {
                        obj[record.campaignId] = { ...record };
                    } else {
                        keysToSum.forEach(key => {
                            obj[record.campaignId][key] += record[key];
                        });
                    }
                    return obj;
                }, {}));
            setSelectedData(campaginTotalRows);
        }
    };
    const adsHelper = (rows: Row<Data>[]) => {


        let newData = props.data;
        if (filters.website.length !== 0) {
            newData = props.data.filter((item) => {
                if (item.website !== undefined) {
                    return (filters.website.includes(item.website));
                }
                return [];
            });
        }
        if (filters.platform.length !== 0) {
            newData = props.data.filter((item) => filters.platform.includes(item.platform));
        }
        if (filters.account.length !== 0) {
            newData = newData.filter((item) => filters.account.includes(item.accountId));
        }
        if (filters.campaign.length !== 0) {
            newData = newData.filter((item) => filters.campaign.includes(item.campaignId));
        }
        if (filters.adSets.length !== 0) {
            newData = newData.filter((item) => filters.adSets.includes(item.adSetId));
        }
        if (mediumFilters.medium.length !== 0 && !mediumFilters.medium.includes("Paid")) {
            newData = newData.filter((item) => item.paid === false);
        }
        if (rows.length === 0) {
            const adSetsTotalRows: AdData[] = Object.values(newData.reduce((obj, record) => {
                if (!obj[record.adId]) {
                    obj[record.adId] = { ...record };
                } else {
                    keysToSum.forEach(key => {
                        obj[record.adId][key] += record[key];
                    });
                }
                return obj;
            }, {}));
            setSelectedData(adSetsTotalRows);
        }
    };




    // renderers

    const spendBar = (percentage: number) => {
        return (
            <styles.StyledSpendBar >
                <styles.StyledSpendBarInside {...{ number: percentage }}></styles.StyledSpendBarInside>
            </styles.StyledSpendBar >
        );
    };

    const filterCheckers = () => {
        return (
            <Popover returnFocusOnClose closeOnEsc placement='bottom'>
                <PopoverTrigger>
                    <styles.StyledFilterButton>
                        <FilterIcon />
                    </styles.StyledFilterButton>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent borderColor='white' w={180} rootProps={{ style: { right: 0 } }}>
                        <PopoverCloseButton />
                        <PopoverBody>
                            {allColumns.map(column => {
                                return (<div key={column.id}>
                                    <styles.StyledLabel >
                                        <styles.InputFilter type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                        <styles.FilterText >{column.Header}</styles.FilterText>
                                    </styles.StyledLabel>
                                </div>);
                            })}
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        );
    };

    const tabButtonsContainer = () => {
        return (
            <styles.TabButtonsContainer>
                <styles.TabButton {...selectedTab === 0 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(0);
                    mediumTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('MEDIUM', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {mediumFilters.medium.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 0 ? { selected: true } : { selected: false }}>
                                {(mediumFilters.medium.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...selectedTab === 1 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(1);
                    platformTabHelper();
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('PLATFORMS', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.platform.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 1 ? { selected: true } : { selected: false }}>
                                {(filters.platform.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...selectedTab === 2 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(2);
                    accountTabHelper(selectedRows);
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('ACCOUNT', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.account.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 2 ? { selected: true } : { selected: false }}>
                                {(filters.account.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...selectedTab === 3 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(3);
                    campaignTabHelper(selectedRows);
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('CAMPAIGN', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.campaign.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 3 ? { selected: true } : { selected: false }}>
                                {(filters.campaign.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...selectedTab === 4 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(4);
                    adSetsTabHelper(selectedRows);
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('AD_SETS', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.adSets.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 4 ? { selected: true } : { selected: false }}>
                                {(filters.adSets.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
                <styles.TabButton {...selectedTab === 5 ? { selected: true } : { selected: false }} onClick={(() => {
                    setSelectedTab(5);
                    adsHelper(selectedRows);
                })}>
                    <styles.TabButtonText>
                        <styles.StyledDiv>
                            {t('ADS', { ns: 'translation' })}
                        </styles.StyledDiv>
                        {filters.ads.length > 0 ?
                            <styles.StyledDivSelected {...selectedTab === 5 ? { selected: true } : { selected: false }}>
                                {(filters.ads.length + " " + t('SELECTED', { ns: 'translation' }))}
                            </styles.StyledDivSelected> : null
                        }
                    </styles.TabButtonText>
                </styles.TabButton>
            </styles.TabButtonsContainer >
        );
    };

    const renderTable = () => {
        return (<table  {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <styles.TrCustom
                        {...{ selected: true, ...headerGroup.getHeaderGroupProps() }}>
                        {
                            headerGroup.headers.map(column => {

                                if (column.Header === "adId" || column.Header === "id" || column.Header === "adSetId" || column.Header === "platform" || column.Header === "accountId" || column.Header === "campaignId") {
                                    return;
                                }
                                if (column.Header === "Type") {
                                    return (<styles.styledTh style={{ display: "flex" }} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <styles.StyledTypeHeader>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <GoTriangleUp />
                                                    : <GoTriangleDown />
                                                : null}
                                        </styles.StyledTypeHeader>
                                    </styles.styledTh>);
                                }
                                return (<styles.styledTh style={{ display: "flex" }} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <styles.StyledHeaderWrapper>
                                        {column.render('Header')}
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <GoTriangleUp />
                                                : <GoTriangleDown />
                                            : null}
                                    </styles.StyledHeaderWrapper>
                                </styles.styledTh>);
                            })}
                    </styles.TrCustom>
                ))}
            </thead>
            <styles.TbodyCustom  {...getTableBodyProps()}>
                {
                    rows.map((row, i) => {
                        prepareRow(row);
                        switch (selectedTab) {
                            case 0:
                                return (<styles.TrCustom
                                    {...{ selected: selectedRowTab0.some((element) => element.id === row.id) || selectedRowTab0.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}>{row.cells.map(cell => {
                                        if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                            return null;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                    })}
                                </styles.TrCustom>);
                            case 1: return (<styles.TrCustom
                                {...{ selected: selectedRowTab1.some((element) => element.original.col_4 === row.original.col_4) || selectedRowTab1.length === 0, ...row.getRowProps() }}
                                onClick={() => {
                                    filterByRow(row);
                                }}>{row.cells.map(cell => {
                                    if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                        return null;
                                    }
                                    return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                })}
                            </styles.TrCustom>);
                            case 2: return (<styles.TrCustom
                                {...{ selected: selectedRowTab2.some((element) => element.original.col_5 === row.original.col_5) || selectedRowTab2.length === 0, ...row.getRowProps() }}
                                onClick={() => {
                                    filterByRow(row);
                                }}>{row.cells.map(cell => {
                                    if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                        return null;
                                    }
                                    return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                })}
                            </styles.TrCustom>);
                            case 3:
                                return (<styles.TrCustom
                                    {...{ selected: selectedRowTab3.some((element) => element.original.col_6 === row.original.col_6) || selectedRowTab3.length === 0, ...row.getRowProps() }}
                                    onClick={() => {
                                        filterByRow(row);
                                    }}>{row.cells.map(cell => {
                                        if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                            return null;
                                        }
                                        return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                    })}
                                </styles.TrCustom>);
                            case 4: return (<styles.TrCustom
                                {...{ selected: selectedRowTab4.some((element) => element.original.col_7 === row.original.col_7) || selectedRowTab4.length === 0, ...row.getRowProps() }}
                                onClick={() => {
                                    filterByRow(row);
                                }}>{row.cells.map(cell => {
                                    if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                        return null;
                                    }
                                    return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                })}
                            </styles.TrCustom>);
                            case 5: return (<styles.TrCustom
                                {...{ selected: selectedRowTab5.some((element) => element.original.col_8 === row.original.col_8) || selectedRowTab5.length === 0, ...row.getRowProps() }}
                                onClick={() => {
                                    filterByRow(row);
                                }}>{row.cells.map(cell => {
                                    if (cell.column.Header === "adSetId" || cell.column.Header === "adId" || cell.column.Header === "platform" || cell.column.Header === "accountId" || cell.column.Header === "campaignId") {
                                        return null;
                                    }
                                    return <styles.styledTd {...cell.getCellProps()}>{cell.render('Cell')}</styles.styledTd>;
                                })}
                            </styles.TrCustom>);
                        }
                        return;
                    })}
            </styles.TbodyCustom>
        </table>);
    };



    return <styles.Wrapper>
        <styles.Card>
            <styles.Header>
                <styles.Title>
                    <span style={{ marginRight: '5px' }}>
                        {props.icon}
                    </span>
                    {props.title}
                </styles.Title>
                {filterCheckers()}
            </styles.Header>
            {tabButtonsContainer()}
            <styles.Styles>
                {renderTable()}
            </styles.Styles>
        </styles.Card>
    </styles.Wrapper >;
};

export const dhlIcon = () => {
  return (
    <svg
      id="cn"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="20"
      viewBox="0 0 30 20"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_12566"
            data-name="Path 12566"
            d="M0,0H30V20H0Z"
            fill="#ee1c25"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_15"
        data-name="Mask Group 15"
        clip-path="url(#clip-path)"
      >
        <g
          id="_0eb6ba537aa8440"
          data-name="0eb6ba537aa8440"
          transform="translate(0 6.69)"
        >
          <path
            id="Path_12587"
            data-name="Path 12587"
            d="M30,0V6.621H0V0Z"
            fill="#fecc00"
          />
          <path
            id="Path_12588"
            data-name="Path 12588"
            d="M9.673,2.766c-.131.178-.35.486-.484.666-.068.092-.19.258.216.258h2.136l.633-.86c.393-.533.034-1.642-1.37-1.642H5.276l-.958,1.3H9.541C9.805,2.491,9.8,2.592,9.673,2.766ZM8.1,4.012c-.406,0-.283-.167-.216-.258.133-.18.356-.486.488-.663s.132-.275-.132-.275H5.854L3.929,5.431H8.624A3.324,3.324,0,0,0,11.3,4.012Zm3.054,1.419h2.754l1.044-1.42H12.2S11.157,5.431,11.157,5.431Zm7.105-4.243L17.206,2.624H15.977l1.056-1.435H14.28l-1.842,2.5h6.736l1.841-2.5ZM15.14,5.431h2.753l1.045-1.419H16.184S15.14,5.431,15.14,5.431ZM0,4.572v.3H3.835l.222-.3Zm4.469-.56H0v.3H4.247ZM0,5.431H3.424l.221-.3H0Zm25.959-.558H30v-.3H26.18Zm-.411.558H30v-.3H25.768Zm1.044-1.419-.221.3H30v-.3Zm-3.606-.321,1.842-2.5H21.912s-1.843,2.5-1.843,2.5Zm-3.153.321-.3.407c-.346.467-.04,1.012,1.088,1.012h4.421l1.045-1.419H19.833Z"
            fill="#d50029"
          />
        </g>
      </g>
      <path
        id="Path_12567"
        data-name="Path 12567"
        d="M0-3,1.764,2.427-2.856-.927H2.856l-4.62,3.354Z"
        transform="translate(5 5)"
        fill="#ff0"
      />
      <path
        id="Path_12568"
        data-name="Path 12568"
        d="M0-1,.588.809-.952-.309h1.9L-.588.809Z"
        transform="translate(10 2) rotate(23.036)"
        fill="#ff0"
      />
      <path
        id="Path_12569"
        data-name="Path 12569"
        d="M0-1,.588.809-.952-.309h1.9L-.588.809Z"
        transform="matrix(0.696, 0.718, -0.718, 0.696, 12, 4)"
        fill="#ff0"
      />
      <path
        id="Path_12570"
        data-name="Path 12570"
        d="M0-1,.588.809-.952-.309h1.9L-.588.809Z"
        transform="matrix(0.343, 0.939, -0.939, 0.343, 12, 7.001)"
        fill="#ff0"
      />
      <path
        id="Path_12571"
        data-name="Path 12571"
        d="M0-1,.588.809-.952-.309h1.9L-.588.809Z"
        transform="translate(10 9) rotate(20.66)"
        fill="#ff0"
      />
    </svg>
  );
};
export const upsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.411"
      height="30.24"
      viewBox="0 0 25.411 30.24"
    >
      <g id="bb4ab615175e4fd" transform="translate(-47.822 728.214)">
        <g
          id="Group_11540"
          data-name="Group 11540"
          transform="translate(47.822 -728.214)"
        >
          <path
            id="Path_12589"
            data-name="Path 12589"
            d="M48.7-716.226l.11,12.481,2.8,3.464,8.412,4.453,10.336-5.058L72.51-706l-.22-16.549-7.807-.22-8.137,1.32-7.422,4.288Z"
            transform="translate(-48.042 725.246)"
            fill="#301506"
            fill-rule="evenodd"
          />
          <path
            id="Path_12590"
            data-name="Path 12590"
            d="M12.7,0A26.913,26.913,0,0,0,0,2.819V17.776a9.48,9.48,0,0,0,3.419,7.612c2.077,1.7,8.5,4.511,9.286,4.852.746-.325,7.245-3.175,9.288-4.852a9.482,9.482,0,0,0,3.418-7.612V2.819A26.916,26.916,0,0,0,12.7,0ZM19.96,2.9c1.461.019,2.9.109,4.283.238V17.775a8.3,8.3,0,0,1-2.991,6.709c-1.76,1.449-7.067,3.827-8.547,4.48-1.5-.662-6.821-3.069-8.549-4.479a8.327,8.327,0,0,1-2.989-6.709V9.221C6.794,4.06,13.628,2.813,19.96,2.9ZM13.036,9.713a5.34,5.34,0,0,0-2.979.786V24.905h2.211V20.251a3.49,3.49,0,0,0,.995.126c2.453,0,3.863-2.211,3.863-5.44s-1.452-5.224-4.089-5.224Zm7.556,0a2.934,2.934,0,0,0-2.966,2.881c0,1.176.33,2.055,2.152,3.125.973.571,1.364.947,1.381,1.641a1.176,1.176,0,0,1-1.325,1.233,3.526,3.526,0,0,1-2.113-.9v2.035a4.84,4.84,0,0,0,2.429.686,2.989,2.989,0,0,0,3.2-2.957c.042-1.289-.315-2.264-2.162-3.349-.824-.484-1.476-.8-1.454-1.606.022-.785.674-1.061,1.3-1.056a2.96,2.96,0,0,1,1.981.908V10.432a3.779,3.779,0,0,0-2.425-.719ZM2.3,9.937v6.949c0,2.343,1.108,3.528,3.3,3.528a5.874,5.874,0,0,0,3.331-.887V9.937H6.716v8.332a1.855,1.855,0,0,1-1.046.27c-1.013,0-1.164-.929-1.164-1.555V9.937H2.3Zm10.755,1.556c1.283,0,1.814,1.024,1.814,3.5,0,2.416-.608,3.584-1.88,3.584a2.086,2.086,0,0,1-.718-.134v-6.79a1.914,1.914,0,0,1,.784-.16Z"
            transform="translate(0 0)"
            fill="#fab80a"
          />
        </g>
      </g>
    </svg>
  );
};

import React, {useEffect, useState} from "react";
import {
    Cell,
    Column,
    Row,
    useExpanded,
    useSortBy,
    useTable,
} from "react-table";
import {
    styles
} from "./styles";
import {BsThreeDotsVertical} from "react-icons/bs";
import {
    Box,
    Flex, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Tooltip, useDisclosure,
} from "@chakra-ui/react";
import {
    CarrierEnum,
    PurchaseOrder,
} from "../../../../services/operations/operation.service";
import {
    getCarrierNameByEnum,
    getCarrierSvgByEnum,
} from "../../../../utils/carrier/helpers";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {
    deletePurchaseOrder,
    editPurchaseOrder,
    operationsStateSelector,
} from "../../../../store/operation";
import {organizationDataLanguageSelector, organizationSelector} from "@store";
import {StatusChip} from "../../../../components/status-chip/StatusChip";
import {newPoIcon} from "../../../../utils/icons/opeartion-icons";
import AddRowModal from "../add-row-modal/AddRowModal";
import {currencyOptions} from "../../../../utils/currency/currencyOptions";
import {EditData} from "../ManufacturingSection";
import {Divider} from "../../../onboarding/first-invitation/styles";
import {formatIsoDate} from "../../../../utils/date-format/dateFormat";
import {useCurrencySign} from "../../../../utils/custom-hooks/useCurrencySign";

interface Props {
    tableData: PurchaseOrder[];
    rowLoader?: {
        rowId?: string;
        loading?: boolean;
    };
    setRowLoader: any;
    noData?: boolean;
    isQueryActive: boolean
}

export enum ManufacturingStatus {
    PENDING,
    IN_PROGRESS,
    COMPLETED
}



interface TableDataInterface {
    supplier?: string | number;
    poId?: string | number;
    cost?: number | string;
    paid?: number | string;
    issueDate?: string;
    status?: ManufacturingStatus;
    outbound?: string | number;
    orderedUnits?: number | string;
    options?: any;
    subRows?: {}[];
}

const ManufacturingTable = (props: Props) => {
    const operations = useSelector(operationsStateSelector);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const {isOpen, onOpen, onClose} = useDisclosure();

    const [dataToEdit, setDataToEdit] = useState<EditData>()
    const dataLanguage = useSelector(organizationDataLanguageSelector);
    const currentCurrency = useCurrencySign();


    const TableHeaders = [
        {
            Header: "PO's ID",
            accessor: "poId",
            Footer: <styles.TotalValueCell>Total</styles.TotalValueCell>,
        },
        {
            Header: "Project Number",
            accessor: "projectNumber",
        }, {
            Header: "Supplier Name",
            accessor: "supplier",
        },
        {Header: "Issue Date", accessor: "issueDate"},

        {
            Header: "Total Cost",
            accessor: "cost",
            Footer: (shippingCost: { rows: any[] }) => {
                const total = React.useMemo(
                    () =>
                        shippingCost.rows.reduce((sum: number, row: any) => {
                            if (typeof row.values.cost === "number") {
                                return row.values.cost + sum;
                            } else {
                                return sum;
                            }
                        }, 0),
                    [shippingCost.rows]
                );

                return <>{currentCurrency + total?.toLocaleString("en-US")}</>;
            },
        },
        {
            Header: "Paid To Date",
            accessor: "paid",
            Footer: (shippingCost: { rows: any[] }) => {
                const total = React.useMemo(
                    () =>
                        shippingCost.rows.reduce((sum: number, row: any) => {
                            if (typeof row.values.paid === "number") {
                                console.log(sum);
                                return row.values.paid + sum;
                            } else {
                                return sum;
                            }
                        }, 0),
                    [shippingCost.rows]
                );
                return <>{currentCurrency + total?.toLocaleString()}</>;
            },
        },

        {
            Header: "Ordered Units",
            accessor: "orderedUnits",
            Footer: (units: { rows: any[] }) => {
                const total = React.useMemo(
                    () =>
                        units.rows.reduce((sum: number, row) => {
                            if (typeof row.values.orderedUnits === "number") {
                                return row.values.orderedUnits + sum;
                            } else {
                                return sum;
                            }
                        }, 0),
                    [units.rows]
                );

                return <>{total?.toLocaleString("en-US")}</>;
            },
        },
        {
            Header: "Delivered",
            accessor: "delivered",
            Footer: (shippingCost: { rows: any[] }) => {
                const total = React.useMemo(
                    () =>
                        shippingCost.rows.reduce((sum: number, row: any) => {
                            if (typeof row.values.delivered === "number") {
                                return row.values.delivered + sum;
                            } else {
                                return sum;
                            }
                        }, 0),
                    [shippingCost.rows]
                );

                return <>{total?.toLocaleString("en-US")}</>;
            },
        },
        {
            Header: "Remaining Units",
            accessor: "remainingUnits",
            Footer: (shippingCost: { rows: any[] }) => {
                const total = React.useMemo(
                    () =>
                        shippingCost.rows.reduce((sum: number, row: any) => {
                            if (typeof row.values.remainingUnits === "number") {
                                return row.values.remainingUnits + sum;
                            } else {
                                return sum;
                            }
                        }, 0),
                    [shippingCost.rows]
                );

                return <>{total?.toLocaleString("en-US")}</>;
            },
        },
        {Header: "Status", accessor: "status"},
        {Header: "", accessor: "options", Footer: <styles.StyledEmptyFooter/>},
    ];


    const [rowLoader, setRowLoader] = useState<{
        rowId?: string;
        loading?: boolean;
    }>();

    useEffect(() => {
        setRowLoader(undefined);
    }, [isOpen]);

    const dataToDataTable = (data: PurchaseOrder[]): TableDataInterface[] => {
        if (props?.noData) {
            const noData = {
                supplier: "-",
                poId: "-",
                cost: "-",
                issueDate: "-",
                status: undefined,
                paid: "-",
                orderedUnits: "-",
                delivered: "-",
                id: "-",
                projectNumber: "-",
                remainingUnits: "-",
                subRows: [],
            };

            return [noData, noData, noData, noData];
        }
        return data.map((item) => {
            return {
                supplier: item?.supplier,
                projectNumber: item?.projectNumber,
                poId: item?.purchaseOrderId,
                cost: item?.totalCost,
                issueDate: !!item?.issueDate ? item?.issueDate?.slice(0, 10) : "-",
                status: getStatus(item?.totalDelivered, item?.unitsAmount),
                paid: item?.paid,
                orderedUnits: item?.unitsAmount,
                delivered: item?.totalDelivered,
                remainingUnits: item?.remainingUnits,
                options: "-",
                id: item?.id,
                subRows: [],
            };
        });
    };

    const getStatus =(delivered?: number, orderedUnits?: number) => {
        if(delivered !== undefined && orderedUnits !== undefined){
            if(delivered === 0){
                return ManufacturingStatus.PENDING
            }
            if(delivered >= orderedUnits){
                return ManufacturingStatus.COMPLETED
            }
            if(delivered > 0 && orderedUnits > 0){
                return ManufacturingStatus.IN_PROGRESS
            }
            else {
                return
            }
        }
        else {
            return
        }

    }

    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                id: "expander", // Make sure it has an ID
                Header: ({
                             getToggleAllRowsExpandedProps,
                             isAllRowsExpanded,
                         }: {
                    isAllRowsExpanded: any;
                    getToggleAllRowsExpandedProps: any;
                }) => (
                    <span /*{...getToggleAllRowsExpandedProps()}*/>
            {/*{isAllRowsExpanded ? "👇" : "👉"}*/}
          </span>
                ),
                Cell: ({row}: { row: Row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
              {row.isExpanded ? "👇" : "👉"}
            </span>
                    ) : null,
            },
            ...TableHeaders,
        ],
        [currentCurrency]
    );

    const data = React.useMemo(
        () => dataToDataTable(props.tableData),
        [props.tableData]
    );
    const handleDelete = (elementId: string) => {
        !!organization?.id &&
        dispatch(
            deletePurchaseOrder({organizationId: organization?.id, elementId})
        );
    };

    useEffect(() => {
        if (!operations?.loading) {
            props.setRowLoader(undefined);
        }
    }, [operations?.loading]);

    const openModal = () => {
        onOpen();
    };


    const handleFocusOut = (elementId: string, value: string) => {
        if (!isNaN(parseFloat(value))) {
            const foundItem = operations?.data?.manufacturingData?.find(
                (item) => item?.id === elementId
            );
            if (!!foundItem) {
                const copyOfFoundItem = {...foundItem};
                props?.setRowLoader({rowId: copyOfFoundItem?.id, loading: true});

                copyOfFoundItem["paid"] = parseFloat(value);
                const dataToSubmit = {
                    id: elementId,
                    paid: parseFloat(value),
                };
                !!organization?.id &&
                dispatch(
                    editPurchaseOrder({
                        organizationId: organization?.id,
                        data: dataToSubmit,
                    })
                );
            }
        } else {
            return;
        }
    };

    const handleEdit = ({row}: { row: any }) => {
        const dataToEdit: EditData = {
            poId: row?.original?.poId,
            issueDate: new Date(row?.original?.issueDate),
            supplier: row?.original?.supplier,
            units: row?.original?.orderedUnits,
            totalCost: row?.original?.cost,
            projectNumber: !!row?.original?.projectNumber ? row?.original?.projectNumber : "-",
            id: row?.original?.id
        }
        setDataToEdit(dataToEdit);
        openModal();
    }

    const MenuListRender = ({row}: { row: any }) => {
        return (
            <MenuList padding={0} zIndex={'100'}>
                <MenuItem onClick={() => handleEdit({row: row})}>
                    Edit
                </MenuItem>
                <styles.Divider />
                <MenuItem onClick={() => handleDelete(row?.original?.id)}>
                    Delete
                </MenuItem>
            </MenuList>
        );
    };

    //renderers
    const renderStatusChip = (status: ManufacturingStatus) => {
        switch (status) {
            case ManufacturingStatus.COMPLETED:
                return StatusChip({width: '110px', color: '#61C322', text: 'Completed'})
            case ManufacturingStatus.IN_PROGRESS:
                return StatusChip({width: '110px', color: '#F0BB09', text: 'In Progress'})
            case ManufacturingStatus.PENDING:
                return StatusChip({width: '110px', color: '#F0BB09', text: 'Pending'})
            default:
                return <></>
        }
    }

    function Table({columns: userColumns, data}: { columns: any; data: any }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow,
            state: {expanded},
        } = useTable(
            {
                columns: userColumns,
                data,
                initialState: {
                    sortBy: [
                        {
                            id: 'issueDate',
                            desc: true
                        }
                    ]
                },
            },
            useSortBy,
            useExpanded // Use the useExpanded plugin hook
        );

        return (
            <styles.TableWrapper>
                <styles.Wrapper>
                    {data.length === 0 ? <></> : <styles.StyledTable {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <styles.HeaderTr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => {
                                    if (column.id === "expander") {
                                        return <></>;
                                    }
                                    return (
                                        <styles.HeaderTh
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps({title: undefined})
                                            )}
                                        >
                                            <Flex
                                                textAlign={"center"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                            >
                                                {column.render("Header")}
                                                <Box w={1}/>
                                                <span>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                                <IoIosArrowDown/>
                            ) : (
                                <IoIosArrowUp/>
                            )
                        ) : (
                            ""
                        )}
                      </span>
                                            </Flex>
                                        </styles.HeaderTh>
                                    );
                                })}
                            </styles.HeaderTr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map((row: any, i: number) => {
                            prepareRow(row);
                            return (
                                <styles.TableTr {...row.getRowProps()}>
                                    {row.cells.map((cell: Cell) => {
                                        if (cell.column.id === "expander") {
                                            return <></>;
                                        }
                                        if (cell.column.id === "options" && cell?.value?.length > 0) {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    <Menu placement="start-start">
                                                        {({isOpen}) => (
                                                            <>
                                                                <MenuButton padding={0}>
                                                                    <styles.ThreeDotHolder>
                                                                        <BsThreeDotsVertical size={24} color={'grey'}/>
                                                                    </styles.ThreeDotHolder>
                                                                </MenuButton>
                                                                <MenuListRender row={row}/>
                                                            </>
                                                        )}
                                                    </Menu>
                                                </td>
                                            );
                                        }
                                        if (cell.column.id === "issueDate") {
                                            return (
                                                <td {...cell.getCellProps()}>{formatIsoDate(cell?.value)}</td>
                                            );
                                        }
                                        if (cell.column.id === "carrier") {
                                            return (
                                                <styles.FlexCell {...cell.getCellProps()}>
                                                    <styles.FlexDiv>
                                                        {getCarrierSvgByEnum(cell.value)}
                                                        {getCarrierNameByEnum(cell.value)}
                                                    </styles.FlexDiv>
                                                </styles.FlexCell>
                                            );
                                        }
                                        if (cell.column.id === "shipsTo") {
                                            return (
                                                <styles.TableTd {...cell.getCellProps()}>
                                                    <Tooltip label={cell.value}>{cell.value}</Tooltip>
                                                </styles.TableTd>
                                            );
                                        }
                                        if (cell.column.id === "numberOfUnits" || cell.column.id === "delivered" ||
                                            cell.column.id === "orderedUnits" || cell.column.Header === "Remaining Units") {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell?.value?.toLocaleString("en-US")}
                                                </td>
                                            );
                                        }
                                        if (cell.column.id === "paid") {
                                            return (
                                                <styles.InputTd {...cell.getCellProps()}>
                                                    {props?.rowLoader?.rowId === row?.original?.id ? (
                                                        <styles.CellWrapperLoader>
                                                            <styles.StyledInput size={10}/>
                                                            <styles.Loader/>
                                                        </styles.CellWrapperLoader>
                                                    ) : (
                                                        <styles.CogsTdDiv>
                                                            <InputGroup width={"80%"} display="flex"
                                                                        justifyContent={"center"}>
                                                                <InputLeftElement pointerEvents='none' color='gray.400'
                                                                                  children={currentCurrency}/>
                                                                {/* <InputLeftAddon children={cogs.isPercentage ? "%" : currentCurrency} /> */}
                                                                <styles.TableInput
                                                                    type={"number"}
                                                                    defaultValue={cell?.value !== undefined ? cell?.value : "-"}
                                                                    onBlur={(e: any) => {
                                                                        if (cell?.value !== parseFloat(e.target.value)) {
                                                                            handleFocusOut(
                                                                                row?.original?.id,
                                                                                e?.target?.value
                                                                            );
                                                                        }
                                                                    }}
                                                                    onWheel={(e:any) => e?.target?.blur()}/>
                                                            </InputGroup>

                                                        </styles.CogsTdDiv>

                                                    )}
                                                </styles.InputTd>
                                            );
                                        }
                                        if (cell.column.id === "status") {
                                            return (
                                                <styles.TableTd {...cell.getCellProps()}>
                                                    {renderStatusChip(cell?.value)}
                                                </styles.TableTd>
                                            );
                                        }
                                        if (cell.column.id === "cost") {
                                            return (
                                                <td {...cell.getCellProps()}>{currentCurrency + cell?.value?.toLocaleString("en-US")}</td>
                                            );
                                        }
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </styles.TableTr>
                            );
                        })}
                        </tbody>

                    </styles.StyledTable>}
                </styles.Wrapper>
                <styles.TableFooterWrapper>

                    {props?.tableData.length !== 0 ? footerGroups.map((group: any, index: number) => {
                        return (
                            <styles.Footer key={index} {...group.getFooterGroupProps()}>
                                <div style={{width: '20px!important'}}/>
                                {group.headers.map((column: any) => {
                                    if (column.id === "expander") {
                                        return <></>;
                                    } if (column.id === "options") {
                                        return  <Box width={"45%"} h={"100%"}>

                                        </Box>
                                    }
                                    return (
                                        <styles.StyledTotalFooter {...column.getFooterProps()}>
                                            {column.render("Footer")}
                                        </styles.StyledTotalFooter>
                                    );
                                })}
                                <div style={{width: '20px!important'}}/>

                            </styles.Footer>
                        );
                    }) : <></>}

                </styles.TableFooterWrapper>
            </styles.TableWrapper>

        );
    }

    const renderFooter = () => {
        return (
            <styles.FooterWrapper>
                <div>{props?.tableData?.length} orders in total</div>
                <styles.CreateNewPoButton onClick={() => {
                    setDataToEdit({} as any)
                    openModal();
                }}>
                    <Flex w={"100%"} justifyContent={"space-evenly"}>
                        <Box w={"1px"}/>

                        {newPoIcon("white")}
                        Create New P.O
                        <Box w={"1px"}/>
                    </Flex>
                </styles.CreateNewPoButton>
            </styles.FooterWrapper>
        );
    };
    const noDataFooter = () => {
        return (
            <styles.NoDataFooter>
                {/*<div>You don't have any P.Os create your first P.O</div>*/}
                <styles.NoDataCreatePo onClick={() => {
                    setDataToEdit({} as any)
                    openModal();
                }}>
                    <>
                        <Box w={"1px"}/>
                        {newPoIcon("white")}
                        <Box>
                            Create Your First P.O
                        </Box>
                        <Box w={"px"}/>

                    </>
                </styles.NoDataCreatePo>
            </styles.NoDataFooter>
        );
    };


    return (
        <styles.TableSection>
            {/*<styles.TableSectionLeft>*/}
            {/*    <styles.TableSectionLeftExtension/>*/}
            {/*</styles.TableSectionLeft>*/}
            <styles.PaddingAndTableWrapper noData={data.length === 0}>
                <AddRowModal data={dataToEdit} isOpen={isOpen} onClose={onClose}/>
                {/*<styles.TablePadding/>*/}

                <Table columns={columns} data={data}/>

                {data.length === 0 ? !props.isQueryActive && noDataFooter() : renderFooter()}
            </styles.PaddingAndTableWrapper>
            {/*<styles.TableSectionRight>*/}
            {/*    <styles.TableSectionRightExtension/>*/}
            {/*</styles.TableSectionRight>*/}
        </styles.TableSection>
    );
};

export default ManufacturingTable;

import styled from 'styled-components';


export const MoreInfoButtonWrapper = styled.div`

  margin: 3px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }


`;



import styled from "styled-components";
import {Input, Spinner} from "@chakra-ui/react";

export namespace styles {

  export const TableSection = styled.div`
    display: flex;
    flex-direction: row;
    
  `

  export const TableSectionLeft = styled.div`
    display: flex;
    align-items: end;
    max-height: 400px;
  `

  export const TableSectionRight = styled.div`
    display: flex;
    align-items: end;
    max-height: 400px;
  `
  export const TableSectionLeftExtension = styled.div`
    display: flex;
    height: 60px;
    width: 30px;
    background-color: white;
    border-radius: 20px 0 0 20px;
    box-shadow: 3px 17px 54px #3417bb45;
  `

  export const TableSectionRightExtension = styled.div`
    display: flex;
    height: 60px;
    width: 30px;
    background-color: white;
    border-radius: 0 20px 20px 0;
    box-shadow: 3px 17px 54px #3417bb45;
  `

  export const Wrapper = styled.div`
    width: 100%;
    border-radius: 30px 30px 0 0;   
    font-size: 12px !important;
   margin-bottom: 60px;
    max-height: 400px;
    overflow-y: auto;
    
    ::-webkit-scrollbar-track{
      margin-top: 65px!important;
    }
  `;
  export const HeaderTr = styled.tr`
    border-bottom: 1px solid #e3e6eb;
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 5;
  `;
  export const PaddingAndTableWrapper = styled.div<{noData?: boolean;}>`
    box-shadow: ${props => props?.noData ? "unset" : "2px 13px 54px #695f9714"};
    border-radius: 0 0 30px 30px;
    width: 100%;
  `;
  export const TablePadding = styled.div`
    height: 10px;
    border-radius: 30px 30px 0 0;
    background-color: #ffffff;
  `;
  export const TableInput = styled(Input)`
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: #ebebeb solid 2px;
    padding: 5px 0px;
    border-radius: 80px !important;
    font-size: 12px !important;
  `;
  export const CogsTdDiv = styled.div<{
    bgc?: string;
    isNumber?: boolean;
    isLastUpdate?: boolean;
  }>`
    display: flex;
    margin: 0 auto;
  width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    background-color: ${(props) => (props.bgc ? props.bgc : "transparent")};
    padding-left: ${(props) => (props.isLastUpdate ? "20px" : "0")};
    align-items: center;
    justify-content: ${(props) => (props.isLastUpdate ? "start" : "center")};
    z-index: 0;
  `;
  export const InputTd = styled.td`
    max-width: 65px;
    font-size: 12px;
  `
  export const ThreeDotHolder = styled.div`
    &:hover {
      cursor: pointer;
    }
  `;
  export const StyledInput = styled.input`
    border: 1px solid #ebebeb;
    border-radius: 19px;
    height: 45px;
    text-align: center;
  `;
  export const CellWrapperLoader = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  export const Loader = styled(Spinner)`
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 25px;
  `;

  export const FlexCell = styled.td``;
  export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  `;
  export const HeaderTh = styled.th`
    color: #7a86a1;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
    font-size: 14px;
    background-color: #ffffff;
    max-width: 75px;
    padding: 0 10px;
    font-weight: lighter;
  `;
  export const TableTd = styled.td`
    max-width: 75px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  export const TableTr = styled.tr`
    height: 84px;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #e7eaee;
    z-index: 2;
    font-family: ${(props) => props.theme.fonts.roboto.regular};
  `;
  export const StyledTable = styled.table`
    width: 100%;
  `;
  export const StyledTotalFooter = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const StyledEmptyFooter = styled.div`
    width: 130px!important;
  `;

  export const FooterTr = styled.tr`
    border-top: 1px solid #e7eaee;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 5;
    box-shadow: 3px 17px 54px #3417bb45;
  `;
  export const TotalValueCell = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    border-radius: 5px;
    box-shadow: 3px 17px 54px #695f9721;
    margin-left: 40px;
  `;
  export const FooterRow = styled.tr``;

  export const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
  `;
  export const NoDataFooter = styled.div`
    display: flex;
    width: 100%;
    padding: 25px;
    justify-content: center;
    align-items: center;
  `;

  export const CreateNewPoButton = styled.div`
    background-color: ${(props) => props.theme.primaryColor};
    width: 195px;
    height: 46px;
    box-shadow: 1px 8px 29px #695f9724;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `;
  export const NoDataCreatePo = styled.div`
    background-color: ${(props) => props.theme.primaryColor};
    width: 195px;
    height: 46px;
    box-shadow: 1px 8px 29px #695f9724;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `;


  export const TableWrapper = styled.div`
    
  `
  export const TableFooterWrapper = styled.div`
    position: relative;
    width: 102%;
  `
  export const Footer = styled.div`
    display: flex;
    width: 100%;
    left: -13px;
    position: absolute;
    border-radius: 10px;
    border-top: 1px solid #e7eaee;
    bottom: 0;
    background-color: white;
    z-index: 5;
    box-shadow: 3px 17px 54px #695f9729;
  `;

  export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #c6c3d1;
  `;
}


export default styles;

// imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

// locals
import { authReducer } from "./auth";
import { organizationReducer } from "./organization";
import { amazonReducer } from "./amazon-seller";
import { googleReducer } from "./google";
import { marketingReducer } from "./marketing";
import { facebookReducer } from "./facebook";
import { shopifyReducer } from "./shopify";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import { uiReducer } from "./ui";
import { websiteReducer } from "./website";
import { financialReducer } from "./financial";
import { accountReducer } from "./account";
import { kpiReducer } from "./kpis";
import { productsReducer } from "./products";
import { pnlReducer } from "./pnl";
import { effortsReducer } from "./efforts";
import { alertsReducer } from "./alerts";
import { usersReducer } from "./users";
import { integrationsReducer } from "./integrations";
import { todosReducer } from "./todos";
import { notifcationsReducer } from "./notifcations";
import { channelsReducer } from "./channels";
import { paymentsReducer } from "./payments";
import { operationsReducer } from "./operation";
import {systemEventsReducer} from "./system-events";
import {toolstipsReducer} from "./tooltips";

// routing
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

// root reducer
const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  organization: organizationReducer,
  amazon: amazonReducer,
  google: googleReducer,
  facebook: facebookReducer,
  shopify: shopifyReducer,
  router: routerReducer,
  marketing: marketingReducer,
  website: websiteReducer,
  financial: financialReducer,
  account: accountReducer,
  kpi: kpiReducer,
  efforts: effortsReducer,
  alerts: alertsReducer,
  products: productsReducer,
  pnl: pnlReducer,
  usersKpis: usersReducer,
  integrations: integrationsReducer,
  todos: todosReducer,
  notification: notifcationsReducer,
  channels: channelsReducer,
  payments: paymentsReducer,
  operations: operationsReducer,
  systemEvents: systemEventsReducer,
  tooltips: toolstipsReducer
});

// initial state
const preloadedState = {};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware),
  preloadedState: preloadedState,
});

// setup listeners
setupListeners(store.dispatch);

// root state
export type RootState = ReturnType<typeof store.getState>;

// dispatch
export type AppDispatch = typeof store.dispatch;

export const history = createReduxHistory(store);

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Bottom,
    Card,
    Dashes,
    ImageCircle,
    NameInitials,
    NextButton,
    PrimaryTitle,
    ShopifyForm,
    ShopifyStoreNameFormInput,
    ShopifyStoreNameFormInputPrefix,
    ShopifyStoreNameFormInputSuffix,
    SkipButton,
    Text,
} from "./styles";
import { useTranslation } from "react-i18next";
import OAuthPopup from "../../../components/o-auth-popup/OAuthPopup";
import { defaultShopifyShopName, fetchShopifyOAuthUrlUrl } from "@constants";
import { IntegrationPlatform, IntegrationType } from "@models";
import { addIntegration, organizationSelector } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { Flex } from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { ShopifyConnected } from "./ShopifyConnected";
import { ShopifyNameDialog } from "dialogs/shopify-name-dialog/ShopifyNameDialog";

export interface ExtraDetailsProps {
    back: () => void;
}

const schema = yup.object().shape({
    storeName: yup.string().required().min(1),
});

export const ShopifyExtraDetails = (props: ExtraDetailsProps) => {

    const sendShopifyOAuthFromSearchParams = (params: URLSearchParams) => {
        const shopifyOAuthData = params.get('result');
        try {
            if (!!shopifyOAuthData && organization?.id) {
                dispatch(addIntegration({
                    integration: {
                        name: '',
                        type: IntegrationType.STORES,
                        platform: IntegrationPlatform.SHOPIFY,
                        object: JSON.parse(shopifyOAuthData),
                    },
                    organizationId: organization?.id,
                }));
                setDone(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const integrationData = {
        url: `${fetchShopifyOAuthUrlUrl}?shop=${defaultShopifyShopName}`,
        title: 'Shopify',
        platform: IntegrationPlatform?.SHOPIFY,
        width: 800,
        height: 1000,
        onClose: () => console.log('shopify'),
        onResponse: (params?: URLSearchParams) => sendShopifyOAuthFromSearchParams(params!),
        children: null,
        imageUrl: 'https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png',
        isOAuthPopup: true,
    };

    // hooks
    const { t } = useTranslation(['translation', 'translation']);
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector);
    const [done, setDone] = useState(false);
    const { register, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    // selectors

    // effects
    useEffect(() => {
        setValue('storeName', '');
    }, [setValue]);

    // helpers
    const submitForm = (data: any) => {
        // data: { storeName: string }
    };
    // renders
    const renderAuthorizeButton = () => {
        return integrationData?.isOAuthPopup ? <OAuthPopup
            title={integrationData?.title}
            height={800}
            width={1000}
            url={integrationData?.url ?? ''}
            onResponse={
                (params) => {
                    integrationData?.onResponse(params);
                }
            }
            onClose={() => {
                console.log('closing!');
            }}
        >
            <NextButton disabled={!integrationData?.platform}>
                {t('CONNECT', { ns: 'translation' })}
            </NextButton>
        </OAuthPopup> : <NextButton onClick={() => {
            if (!!integrationData?.onResponse) {
                integrationData?.onResponse();
            }
        }} disabled={!integrationData?.platform}>
            {t('CONNECT', { ns: 'translation' })}
        </NextButton>;
    };

    const renderBottom = () => {
        return (
            <Bottom>
                <SkipButton onClick={() => {
                    if (!!props?.back) {
                        props.back();
                    }
                }}>
                    {t('BACK', { ns: 'translation' })}
                </SkipButton>
                {renderAuthorizeButton()}
            </Bottom>
        );
    };

    const renderHeaderImage = () => {
        return <Flex flexDirection={"row"} alignItems={'center'}>
            <NameInitials>
                {'MS'}
            </NameInitials>
            <Dashes />
            <ImageCircle
                src={'https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png'}
            >
            </ImageCircle>
        </Flex>;
    };

    const renderStoreNameInput = () => {
        return <ShopifyStoreNameFormInput>
            <ShopifyStoreNameFormInputPrefix>
                <input {...register('storeName')} placeholder={t('STORE_NAME', { ns: 'translation' })} />
            </ShopifyStoreNameFormInputPrefix>
            <ShopifyStoreNameFormInputSuffix>
                {`/myshopify.com`}
            </ShopifyStoreNameFormInputSuffix>
        </ShopifyStoreNameFormInput>;
    };

    const renderForm = () => {
        return <ShopifyForm onSubmit={handleSubmit(submitForm)}>
            {renderStoreNameInput()}
        </ShopifyForm>;
    };

    const renderCard = () => {
        return (
            <Card>
                <ShopifyNameDialog
                    isDialogOpen={true}
                    onClose={() => { }}
                    onAuthorization={(params) => sendShopifyOAuthFromSearchParams(params)}
                />
                {/* <Text style={{marginBottom: 30}}>
                    <PrimaryTitle>
                        {`${t('CONNECT_XXXXX_STORES_TO_SHOPIFY', {ns: 'translation'})}`}
                    </PrimaryTitle>
                </Text>
                {renderHeaderImage()}
                {renderForm()}
                {renderBottom()} */}
            </Card>
        );
    };

    return done ? <ShopifyConnected /> : renderCard();
};
